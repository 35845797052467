import { Intent } from '@blueprintjs/core'
import { TEXT_MESSAGE_PHONGBANCHUTRI } from 'constants/MessageForm'
import { isEmpty } from 'lodash'
import moment from 'moment'
import { useCallback, useEffect, useState } from 'react'
import * as MESSAGE from 'constants/MessageForm'

const useAssignWork = ({
  auth,
  actions,
  receiveDocument,
  onSubmit,
  departmentAndPersonalTree,
  units,
  congVanDenId,
  informationSteering,
  isUpdateAssignment,
}) => {
  const mainUnitId = auth?.mainUnitId
  const mainDepartmentId = auth?.mainDepartmentId
  const [formData, setFormData] = useState(null)
  const [formError, setFormError] = useState(null)
  const [isFetch, setIsFetch] = useState(false)
  const [isSubmitDisable, setIsSubmitDisable] = useState(true)

  const [chuTriData, setChuTriData] = useState(null)
  const [phoiHopData, setPhoiHopData] = useState([])
  const [xemDeBietData, setXemDeBietData] = useState([])
  const [chiDaoData, setChiDaoData] = useState(null)
  const [baoCaoData, setBaoCaoData] = useState([])
  const [dataOpen, setDataOpen] = useState([mainDepartmentId])
  const [receivingUnitSelections, setReceivingUnitSelections] = useState([])
  const [isOpenDonViNhan, setIsOpenDonViNhan] = useState(false)
  const [departmentAssigned, setDepartmentAssigned] = useState(null)
  const [isOpenPopupDelete, setIsOpenPopupDelete] = useState(false)
  const [isLoading, setIsLoading] = useState({
    isLoadingSubmit: false,
    isLoadingRemove: false,
  })
  const [isShowBcCd, setIsShowBcCd] = useState(false)

  const handleClickPopupDelete = () => {
    setIsOpenPopupDelete(!isOpenPopupDelete)
  }

  const handleClickArrowDvn = () => {
    setIsOpenDonViNhan(!isOpenDonViNhan)
  }

  const onFormChange = name => value => {
    setIsSubmitDisable(false)
    setFormData(prev => ({
      ...prev,
      [name]: value,
    }))
    setFormError(prev => ({
      ...prev,
      [name]: null,
    }))
  }

  const onChangeGridCheckBox = () => data => {
    if (!data || !Object.keys(data).length) {
      return
    }
    setIsSubmitDisable(false)
    if (data?.chuTriId) {
      setChuTriData(null)
    }
    return setFormData(prev => ({
      ...prev,
      donViChuTriId: data?.chuTriId,
      dsDonViPhoiHop: data?.dsPhoiHop,
      dsDonViXemDeBiet: data?.dsXemDeBiet,
    }))
  }

  const handleSubmitRemoveItem = e => {
    e.preventDefault()
    setIsOpenPopupDelete(false)
    setIsLoading({
      ...isLoading,
      isLoadingRemove: true,
    })

    actions
      .assignmentHistoryDelete(
        informationSteering.congVanDenId,
        informationSteering.chiDaoId
      )
      .then(res => {
        if (res.payload && res.payload.status === 200) {
          onSubmit(
            'HuyChiDao',
            MESSAGE.TEXT_DELETE_ASSIGN_SUCCESS,
            Intent.SUCCESS,
            true,
            null
          )
        } else {
          setIsLoading({
            ...isLoading,
            isLoadingRemove: false,
          })
          onSubmit(
            'HuyChiDao',
            MESSAGE.TOATS_CANCLE_FAIL,
            Intent.DANGER,
            false,
            null
          )
        }
      })
  }

  const handleSubmitReWorking = () => {
    if (!chuTriData && !formData?.donViChuTriId) {
      return onSubmit(
        'ChiDao',
        MESSAGE.TEXT_MESSAGE_PHONGBANCHUTRI,
        Intent.WARNING,
        false,
        null
      )
    }
    setIsOpenPopupDelete(false)
    setIsLoading({
      ...isLoading,
      isLoadingSubmit: true,
    })
    let hanGiaiQuyet = null
    if (formData.hanGiaiQuyet && formData.hanGiaiQuyet.toString().length > 0) {
      hanGiaiQuyet = moment(formData.hanGiaiQuyet)
        .set({ hour: 0, minute: 0, second: 0, millisecond: 0 })
        .format()
    }

    let dataRequest = {
      cong_van_den_id: receiveDocument?.congVanDenId,
      noi_dung: formData?.noiDung ? formData.noiDung.trim() : null,
      chu_tri_id: chuTriData?.maPhongBan ? chuTriData.phongBanId : null,
      nv_chu_tri_id: chuTriData?.chucDanhId ? chuTriData.chucDanhId : null,
      ds_phoi_hop: phoiHopData
        ?.filter(f => !f?.chucDanhId && f?.phongBanId)
        ?.map(elm => elm.phongBanId),
      ds_xem_de_biet: xemDeBietData
        ?.filter(f => !f?.chucDanhId && f?.phongBanId)
        ?.map(elm => elm.phongBanId),
      ds_nv_phoi_hop: phoiHopData
        ?.filter(f => f?.chucDanhId)
        ?.map(elm => ({
          phong_ban_id: elm.phongBanId,
          chuc_danh_id: elm.chucDanhId,
        })),
      nv_xem_de_biet: xemDeBietData
        ?.filter(f => f?.chucDanhId)
        ?.map(elm => elm.chucDanhId),
      nv_xem_bao_cao: baoCaoData,
      nv_chi_dao_id: chiDaoData?.chucDanhId,
      don_vi_chu_tri_id: formData?.donViChuTriId,
      ds_don_vi_phoi_hop: formData?.dsDonViPhoiHop,
      ds_don_vi_xem_de_biet: formData?.dsDonViXemDeBiet,
      han_giai_quyet: hanGiaiQuyet,
      luu_ban_goc: formData?.luuBanGoc || false,
    }

    if (informationSteering) {
      dataRequest = {
        ...dataRequest,
        old_chi_dao_id: informationSteering.chiDaoId,
      }
      actions.deleteWorkingDocument(congVanDenId, dataRequest).then(res => {
        if (res.payload && res.payload.status === 200) {
          onSubmit(
            'ChiDao',
            MESSAGE.TOATS_MESSAGE_SUCCESS,
            Intent.SUCCESS,
            true,
            null
          )
        } else {
          setIsLoading({
            ...isLoading,
            isLoadingSubmit: false,
          })
          onSubmit(
            'ChiDao',
            MESSAGE.TOATS_MESSAGE_FAIL,
            Intent.DANGER,
            false,
            null
          )
        }
      })
    }
  }

  const handleSubmit = () => {
    if (!chuTriData && !formData?.donViChuTriId) {
      return onSubmit(
        'ChiDao',
        MESSAGE.TEXT_MESSAGE_PHONGBANCHUTRI,
        Intent.WARNING,
        false,
        null
      )
    }

    setIsLoading({
      ...isLoading,
      isLoadingSubmit: true,
    })
    let hanGiaiQuyet = null
    if (formData.hanGiaiQuyet && formData.hanGiaiQuyet.toString().length > 0) {
      hanGiaiQuyet = moment(formData.hanGiaiQuyet)
        .set({ hour: 0, minute: 0, second: 0, millisecond: 0 })
        .format()
    }

    let dataRequest = {
      cong_van_den_id: receiveDocument?.congVanDenId,
      noi_dung: formData?.noiDung ? formData.noiDung.trim() : null,
      chu_tri_id: chuTriData?.maPhongBan ? chuTriData.phongBanId : null,
      nv_chu_tri_id: chuTriData?.chucDanhId ? chuTriData.chucDanhId : null,
      ds_phoi_hop: phoiHopData
        ?.filter(f => !f?.chucDanhId && f?.phongBanId)
        ?.map(elm => elm.phongBanId),
      ds_xem_de_biet: xemDeBietData
        ?.filter(f => !f?.chucDanhId && f?.phongBanId)
        ?.map(elm => elm.phongBanId),
      ds_nv_phoi_hop: phoiHopData
        ?.filter(f => f?.chucDanhId)
        ?.map(elm => ({
          phong_ban_id: elm.phongBanId,
          chuc_danh_id: elm.chucDanhId,
        })),
      nv_xem_de_biet: xemDeBietData
        ?.filter(f => f?.chucDanhId)
        ?.map(elm => elm.chucDanhId),
      nv_xem_bao_cao: baoCaoData,
      nv_chi_dao_id: chiDaoData?.chucDanhId,
      don_vi_chu_tri_id: formData?.donViChuTriId,
      ds_don_vi_phoi_hop: formData?.dsDonViPhoiHop,
      ds_don_vi_xem_de_biet: formData?.dsDonViXemDeBiet,
      han_giai_quyet: hanGiaiQuyet,
      luu_ban_goc: formData?.luuBanGoc || false,
    }

    if (informationSteering) {
      dataRequest = {
        ...dataRequest,
        chi_dao_id: informationSteering.chiDaoId,
      }
      actions.updateAssignmentHistory(congVanDenId, dataRequest).then(res => {
        if (res.payload && res.payload.status === 200) {
          onSubmit(
            'ChiDao',
            MESSAGE.TEXT_UPDATE_ASSIGN_SUCCESS,
            Intent.SUCCESS,
            true,
            null
          )
        } else {
          setIsLoading({
            ...isLoading,
            isLoadingSubmit: false,
          })
          onSubmit(
            'ChiDao',
            MESSAGE.TOATS_MESSAGE_FAIL,
            Intent.DANGER,
            false,
            null
          )
        }
      })
    } else {
      actions.postChiDaoGiaoViec(congVanDenId, dataRequest).then(res => {
        if (res.payload && res.payload.status === 200) {
          onSubmit(
            'ChiDao',
            MESSAGE.TEXT_ASSIGN_SUCCESS,
            Intent.SUCCESS,
            true,
            null
          )
        } else {
          setIsLoading({
            ...isLoading,
            isLoadingSubmit: false,
          })
          onSubmit(
            'ChiDao',
            MESSAGE.TOATS_MESSAGE_FAIL,
            Intent.DANGER,
            false,
            null
          )
        }
      })
    }
  }

  const onFormValidate = useCallback(
    e => {
      e.preventDefault()
      let error = null
      if (!chuTriData && !formData?.donViChuTriId) {
        error = {
          ...error,
          chuTri: TEXT_MESSAGE_PHONGBANCHUTRI,
        }
        actions.commonAddToasterMessage({
          message: TEXT_MESSAGE_PHONGBANCHUTRI,
          intent: Intent.WARNING,
        })
      }

      if (error) {
        return setFormError(error)
      } else {
        return onSubmit()
      }
    },
    [actions, formData, chuTriData, onSubmit]
  )

  const getAllChucDanhIds = data => {
    const chucDanhIds = []
    data.forEach(phongBan => {
      phongBan.dsNhanVien.forEach(nhanVien => {
        if (nhanVien.chucDanhId) {
          chucDanhIds.push(nhanVien.chucDanhId)
        }
      })
    })
    return chucDanhIds
  }

  const onChangeCheckbox = useCallback(
    (name, value, isCheckAll) => record => {
      if (name === 'CT') {
        if (value) {
          const isPersonal = record?.chucDanhId ? true : false
          const phongBanPhoiHop =
            phoiHopData?.filter(
              elm => elm.phongBanId === record.phongBanId && !elm?.chucDanhId
            ) || []
          const nhanVienPhongBanPhoiHop =
            phoiHopData?.filter(
              elm => elm.phongBanId === record.phongBanId && elm?.chucDanhId
            ) || []
          const phongBanXemDeBiet =
            xemDeBietData?.filter(
              elm => elm.phongBanId === record.phongBanId && !elm?.chucDanhId
            ) || []
          const nhanVienPhongBanXemDeBiet =
            xemDeBietData?.filter(
              elm => elm.phongBanId === record.phongBanId && elm?.chucDanhId
            ) || []
          let phoiHopDataTemp = [...phoiHopData]
          let xemDeBietDataTemp = [...xemDeBietData]
          if (isPersonal) {
            // Check người thì xóa phối hợp và xem để biết (phòng)
            // Xóa phối hợp và xem để biết (chức danh trùng với chức danh chủ trì hiện tại)
            if (phongBanPhoiHop?.length > 0 ?? false) {
              phoiHopDataTemp = phoiHopDataTemp?.filter(
                elm =>
                  !phongBanPhoiHop.some(e => e?.phongBanId === elm?.phongBanId)
              )
            }
            if (phongBanXemDeBiet?.length > 0 ?? false) {
              xemDeBietDataTemp = xemDeBietDataTemp?.filter(
                elm =>
                  !phongBanXemDeBiet.some(
                    e => e?.phongBanId === elm?.phongBanId
                  )
              )
            }

            const nhanVienPhongBanPhoiHop2 =
              phoiHopData?.filter(
                elm =>
                  elm.phongBanId === record.phongBanId &&
                  elm?.chucDanhId === record?.chucDanhId
              ) || []
            if (nhanVienPhongBanPhoiHop2?.length > 0 ?? false) {
              phoiHopDataTemp = phoiHopDataTemp?.filter(
                elm =>
                  !nhanVienPhongBanPhoiHop2.some(
                    e => e?.chucDanhId === elm?.chucDanhId
                  )
              )
            }
            const nhanVienXemDeBiet2 =
              xemDeBietData?.filter(
                elm =>
                  elm.phongBanId === record.phongBanId &&
                  elm?.chucDanhId === record?.chucDanhId
              ) || []
            if (nhanVienXemDeBiet2?.length > 0 ?? false) {
              xemDeBietDataTemp = xemDeBietDataTemp?.filter(
                elm =>
                  !nhanVienXemDeBiet2.some(
                    e => e?.chucDanhId === elm?.chucDanhId
                  )
              )
            }
            setPhoiHopData(phoiHopDataTemp)
            setXemDeBietData(xemDeBietDataTemp)
          } else {
            // Nếu chọn chủ trì là phòng thì xóa phối hợp và xem để biết (Phòng, cá nhân)
            if (phongBanPhoiHop?.length > 0 ?? false) {
              phoiHopDataTemp = phoiHopDataTemp?.filter(
                elm =>
                  !phongBanPhoiHop.some(e => e?.phongBanId === elm?.phongBanId)
              )
            }
            if (nhanVienPhongBanPhoiHop?.length > 0 ?? false) {
              phoiHopDataTemp = phoiHopDataTemp?.filter(
                elm =>
                  !nhanVienPhongBanPhoiHop.some(
                    e => e?.chucDanhId === elm?.chucDanhId
                  )
              )
            }
            if (phongBanXemDeBiet?.length > 0 ?? false) {
              xemDeBietDataTemp = xemDeBietDataTemp?.filter(
                elm =>
                  !phongBanXemDeBiet.some(
                    e => e?.phongBanId === elm?.phongBanId
                  )
              )
            }
            if (nhanVienPhongBanXemDeBiet?.length > 0 ?? false) {
              xemDeBietDataTemp = xemDeBietDataTemp?.filter(
                elm =>
                  !nhanVienPhongBanXemDeBiet.some(
                    e => e?.chucDanhId === elm?.chucDanhId
                  )
              )
            }
            setPhoiHopData(phoiHopDataTemp)
            setXemDeBietData(xemDeBietDataTemp)
          }

          setChuTriData(record)
          setFormData(prev => ({
            ...prev,
            donViChuTriId: null,
          }))
        } else {
          setChuTriData(null)
        }

        setIsSubmitDisable(false)
      } else if (name === 'PH') {
        if (isCheckAll) {
          if (value) {
            setPhoiHopData(
              departmentAndPersonalTree?.filter(
                item => item.phongBanId && !item?.chucDanhId
              )
            )
            setChuTriData(null)
            setXemDeBietData([])
          } else {
            setPhoiHopData([])
          }
        } else {
          const isPersonal = record?.chucDanhId ? true : false
          if (value) {
            if (
              (!isPersonal &&
                record?.phongBanId &&
                chuTriData?.phongBanId === record?.phongBanId) ||
              (isPersonal &&
                (chuTriData?.chucDanhId === record?.chucDanhId ||
                  (!chuTriData?.chucDanhId &&
                    chuTriData?.phongBanId === record?.phongBanId)))
            ) {
              setChuTriData(null)
            }

            if (isPersonal) {
              // Check cá nhân phối hợp thì thêm data cá nhân và xóa phòng ban phối hợp
              // Xóa phòng ban xem để biết và cá nhân xem để biết trùng với cá nhân phối hợp hiện tại
              const phongBanPhoiHop =
                phoiHopData?.filter(
                  elm =>
                    elm.phongBanId === record.phongBanId && !elm?.chucDanhId
                ) || []
              setPhoiHopData([
                ...phoiHopData?.filter(
                  elm =>
                    !phongBanPhoiHop.some(
                      e => e?.phongBanId === elm?.phongBanId
                    )
                ),
                record,
              ])

              let xemDeBietDataTemp = [...xemDeBietData]
              const phongBanXemDeBiet =
                xemDeBietData?.filter(
                  elm =>
                    elm.phongBanId === record.phongBanId && !elm?.chucDanhId
                ) || []
              if (phongBanXemDeBiet?.length > 0 ?? false) {
                xemDeBietDataTemp = xemDeBietDataTemp?.filter(
                  elm =>
                    !phongBanXemDeBiet.some(
                      e => e?.phongBanId === elm?.phongBanId
                    )
                )
              }

              const nhanVienXemDeBiet =
                xemDeBietData?.filter(
                  elm =>
                    elm.phongBanId === record.phongBanId &&
                    elm?.chucDanhId === record?.chucDanhId
                ) || []
              if (nhanVienXemDeBiet?.length > 0 ?? false) {
                xemDeBietDataTemp = xemDeBietDataTemp?.filter(
                  elm =>
                    !nhanVienXemDeBiet.some(
                      e => e?.chucDanhId === elm?.chucDanhId
                    )
                )
              }
              setXemDeBietData(xemDeBietDataTemp)
            } else {
              // Check phòng ban phối hợp thì thêm data phòng phối hợp và xóa nhân viên phối hợp
              // Xóa Phòng ban và nhân viên xem để biết
              const nhanVienPhongBanPhoiHop =
                phoiHopData?.filter(
                  elm => elm.phongBanId === record.phongBanId && elm?.chucDanhId
                ) || []
              let phoiHopDataTemp = [...phoiHopData]
              if (nhanVienPhongBanPhoiHop?.length > 0 ?? false) {
                phoiHopDataTemp = phoiHopDataTemp?.filter(
                  elm =>
                    !nhanVienPhongBanPhoiHop.some(
                      e => e?.chucDanhId === elm?.chucDanhId
                    )
                )
              }

              setPhoiHopData([...phoiHopDataTemp, record])

              const phongBanXemDeBiet =
                xemDeBietData?.filter(
                  elm =>
                    elm.phongBanId === record.phongBanId && !elm?.chucDanhId
                ) || []
              let xemDeBietDataTemp = [...xemDeBietData]
              if (phongBanXemDeBiet?.length > 0 ?? false) {
                xemDeBietDataTemp = xemDeBietDataTemp?.filter(
                  elm =>
                    !phongBanXemDeBiet.some(
                      e => e?.phongBanId === elm?.phongBanId
                    )
                )
              }

              const nhanVienPhongBanXemDeBiet =
                xemDeBietData?.filter(
                  elm => elm.phongBanId === record.phongBanId && elm?.chucDanhId
                ) || []
              if (nhanVienPhongBanXemDeBiet?.length > 0 ?? false) {
                xemDeBietDataTemp = xemDeBietDataTemp?.filter(
                  elm =>
                    !nhanVienPhongBanXemDeBiet.some(
                      e => e?.chucDanhId === elm?.chucDanhId
                    )
                )
              }

              setXemDeBietData(xemDeBietDataTemp)
            }
          } else {
            setPhoiHopData(
              phoiHopData?.filter(item =>
                isPersonal
                  ? item?.chucDanhId !== record?.chucDanhId
                  : item?.phongBanId !== record?.phongBanId
              )
            )
          }
        }
      } else if (name === 'XDB') {
        if (isCheckAll) {
          if (value) {
            setXemDeBietData(
              departmentAndPersonalTree?.filter(
                item => item.phongBanId && !item?.chucDanhId
              )
            )
            setChuTriData(null)
            setPhoiHopData([])
          } else {
            setXemDeBietData([])
          }
        } else {
          const isPersonal = record?.chucDanhId ? true : false
          if (value) {
            if (
              (!isPersonal &&
                record?.phongBanId &&
                chuTriData?.phongBanId === record?.phongBanId) ||
              (isPersonal &&
                (chuTriData?.chucDanhId === record?.chucDanhId ||
                  (!chuTriData?.chucDanhId &&
                    chuTriData?.phongBanId === record?.phongBanId)))
            ) {
              setChuTriData(null)
            }

            if (isPersonal) {
              // Check cá nhân xem để biết thêm data cá nhân và xóa phòng ban cá nhân xem để biết
              // Xóa phòng ban phối hợp và cá nhân phối hợp trùng cá nhân xem để biết hiện tại
              let phoiHopDataTemp = [...phoiHopData]
              const phongBanPhoiHop =
                phoiHopData?.filter(
                  elm =>
                    elm.phongBanId === record.phongBanId && !elm?.chucDanhId
                ) || []
              if (phongBanPhoiHop?.length > 0 ?? false) {
                phoiHopDataTemp = phoiHopDataTemp?.filter(
                  elm =>
                    !phongBanPhoiHop.some(
                      e => e?.phongBanId === elm?.phongBanId
                    )
                )
              }

              const nhanVienPhongBanPhoiHop =
                phoiHopData?.filter(
                  elm =>
                    elm.phongBanId === record.phongBanId &&
                    elm?.chucDanhId === record?.chucDanhId
                ) || []
              if (nhanVienPhongBanPhoiHop?.length > 0 ?? false) {
                phoiHopDataTemp = phoiHopDataTemp?.filter(
                  elm =>
                    !nhanVienPhongBanPhoiHop.some(
                      e => e?.chucDanhId === elm?.chucDanhId
                    )
                )
              }

              setPhoiHopData(phoiHopDataTemp)

              const phongBanXemDeBiet =
                xemDeBietData?.filter(
                  elm =>
                    elm.phongBanId === record.phongBanId && !elm?.chucDanhId
                ) || []
              setXemDeBietData([
                ...xemDeBietData?.filter(
                  elm =>
                    !phongBanXemDeBiet.some(
                      e => e?.phongBanId === elm?.phongBanId
                    )
                ),
                record,
              ])
            } else {
              // Check phòng ban xem để biết thì thêm data xem để biết và xóa nhân viên xem để biết
              // Xóa phòng ban và nhân viên phối hợp
              let phoiHopDataTemp = [...phoiHopData]
              const phongBanPhoiHop =
                phoiHopData?.filter(
                  elm =>
                    elm.phongBanId === record.phongBanId && !elm?.chucDanhId
                ) || []
              if (phongBanPhoiHop?.length > 0 ?? false) {
                phoiHopDataTemp = phoiHopDataTemp?.filter(
                  elm =>
                    !phongBanPhoiHop.some(
                      e => e?.phongBanId === elm?.phongBanId
                    )
                )
              }

              const nhanVienPhongBanPhoiHop =
                phoiHopData?.filter(
                  elm => elm.phongBanId === record.phongBanId && elm?.chucDanhId
                ) || []
              if (nhanVienPhongBanPhoiHop?.length > 0 ?? false) {
                phoiHopDataTemp = phoiHopDataTemp?.filter(
                  elm =>
                    !nhanVienPhongBanPhoiHop.some(
                      e => e?.chucDanhId === elm?.chucDanhId
                    )
                )
              }

              setPhoiHopData(phoiHopDataTemp)

              let xemDeBietDataTemp = [...xemDeBietData]
              const nhanVienPhongBanXemDeBiet =
                xemDeBietData?.filter(
                  elm => elm.phongBanId === record.phongBanId && elm?.chucDanhId
                ) || []
              if (nhanVienPhongBanXemDeBiet?.length > 0 ?? false) {
                xemDeBietDataTemp = xemDeBietDataTemp?.filter(
                  elm =>
                    !nhanVienPhongBanXemDeBiet.some(
                      e => e?.chucDanhId === elm?.chucDanhId
                    )
                )
              }

              setXemDeBietData([...xemDeBietDataTemp, record])
            }
          } else {
            setXemDeBietData(
              xemDeBietData?.filter(item =>
                isPersonal
                  ? item?.chucDanhId !== record?.chucDanhId
                  : item?.phongBanId !== record?.phongBanId
              )
            )
          }
        }
      }
      if (name === 'CD') {
        if (value) {
          setChiDaoData(record)
        } else {
          setChiDaoData(null)
        }
      }
      if (name === 'BC') {
        if (isCheckAll) {
          const chucDanhIds = getAllChucDanhIds(
            departmentAndPersonalTree?.filter(e => e?.phongBanLanhDao)
          )
          if (value) {
            setBaoCaoData(chucDanhIds)
            // Kiểm tra dữ liệu và mở tree item
            const dsPhongBanCoNhanVienDuocChon = []
            departmentAndPersonalTree.forEach(e => {
              if (
                e?.dsNhanVien?.findIndex(f =>
                  chucDanhIds.some(s => s === f.chucDanhId)
                ) !== -1
              ) {
                dsPhongBanCoNhanVienDuocChon.push(e)
              }
            })
            if (dsPhongBanCoNhanVienDuocChon?.length > 0 ?? false) {
              setDataOpen(dsPhongBanCoNhanVienDuocChon.map(e => e.phongBanId))
            }
          } else {
            setBaoCaoData([])
          }
        } else {
          if (value) {
            setBaoCaoData([...baoCaoData, record.chucDanhId])
          } else {
            setBaoCaoData(
              baoCaoData?.filter(item => item !== record.chucDanhId)
            )
          }
        }
      }
    },
    [
      phoiHopData,
      xemDeBietData,
      baoCaoData,
      chuTriData,
      departmentAndPersonalTree,
      setDataOpen,
    ]
  )

  const onClickArrow = phongBanId => {
    setDataOpen(prevDataOpen =>
      prevDataOpen.includes(phongBanId)
        ? prevDataOpen.filter(item => item !== phongBanId)
        : [...prevDataOpen, phongBanId]
    )
  }

  const onFetchDepartments = useCallback(() => {
    // Ds phòng ban
    actions.commonFetchDepartments()
  }, [actions])

  const onFetchStaffByDepartmentId = useCallback(() => {
    if (mainDepartmentId) {
      // Ds cá nhân trong phòng ban hiện tại
      actions.commonFetchStaffByDepartmentId(mainDepartmentId)
    }
  }, [actions, mainDepartmentId])

  const fetchContactFetchDepartmentByUnit = useCallback(() => {
    actions.contactFetchDepartmentAndPersonalTree(mainUnitId)
  }, [actions, mainUnitId])

  const handleCheckDonViCha = useCallback(() => {
    actions.getUnitItem(mainUnitId).then(res => {
      if (res?.payload?.data?.result?.donViCha === null) {
        setIsShowBcCd(true)
      }
    })
  }, [actions, mainUnitId])

  const fetchDefault = useCallback(() => {
    setIsFetch(true)
    Promise.all([onFetchDepartments(), onFetchStaffByDepartmentId()]).then(
      () => {
        setIsFetch(false)
      }
    )
  }, [onFetchDepartments, onFetchStaffByDepartmentId])

  useEffect(() => {
    fetchDefault()
  }, [fetchDefault])

  useEffect(() => {
    fetchContactFetchDepartmentByUnit()
  }, [fetchContactFetchDepartmentByUnit])

  useEffect(() => {
    handleCheckDonViCha()
  }, [handleCheckDonViCha])

  useEffect(() => {
    if (!isEmpty(units)) {
      const receivingUnitSelections = []
      units.forEach(item => {
        receivingUnitSelections.push({
          content: item.tenVietTat,
          value: item.donViId,
        })
      })
      setReceivingUnitSelections(receivingUnitSelections)
    }
    if (informationSteering && isUpdateAssignment) {
      setFormData({
        noiDung: informationSteering?.noiDung,
        hanGiaiQuyet: informationSteering?.hanGiaiQuyet
          ? moment(informationSteering?.hanGiaiQuyet)
          : null,
        luuBanGoc: informationSteering?.luuBanGoc,
        donViChuTriId: informationSteering?.donViChuTri?.donViId,
        dsDonViPhoiHop: informationSteering?.donViPhoiHop?.map(
          item => item?.donViId
        ),
        dsDonViXemDeBiet: informationSteering?.donViXemDeBiet?.map(
          item => item?.donViId
        ),
      })
      setChuTriData(
        informationSteering?.nvChuTriId
          ? { chucDanhId: informationSteering?.nvChuTriId }
          : informationSteering?.chuTri
      )
      setPhoiHopData([
        ...informationSteering?.phoiHop,
        ...informationSteering?.dsNvPhoiHop,
      ])
      setXemDeBietData([
        ...informationSteering?.xemDeBiet,
        ...informationSteering?.dsNvXemDeBiet,
      ])
      setChiDaoData(informationSteering?.nvChiDao)
      setBaoCaoData(
        informationSteering?.dsXemBaoCao?.map(item => item?.chucDanhId)
      )
      if (
        informationSteering?.donViPhoiHop?.length > 0 ||
        informationSteering.DonViXemDeBiet?.length > 0
      ) {
        setIsOpenDonViNhan(true)
      }
      if (informationSteering?.phongBanDaGiaoViec?.length > 0) {
        setDepartmentAssigned(informationSteering?.phongBanDaGiaoViec)
      }
    }
  }, [units, informationSteering, isUpdateAssignment])

  return {
    formData,
    formError,
    isFetch,
    isLoading,
    isSubmitDisable,
    chuTriData,
    phoiHopData,
    xemDeBietData,
    chiDaoData,
    baoCaoData,
    dataOpen,
    receivingUnitSelections,
    isOpenDonViNhan,
    departmentAssigned,
    isOpenPopupDelete,
    isShowBcCd,
    onFormChange,
    onChangeGridCheckBox,
    onFormValidate,
    onChangeCheckbox,
    onClickArrow,
    handleClickArrowDvn,
    handleClickPopupDelete,
    handleSubmitRemoveItem,
    handleSubmitReWorking,
    handleSubmit,
  }
}

export default useAssignWork
