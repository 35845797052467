import { useState, useEffect } from 'react'
import moment from 'moment'

import { FILE_URL } from 'constants/Api'

const useProfile = ({ auth }) => {
  const [avatar, setAvatar] = useState(
    `${process.env.PUBLIC_URL}/images/avatar.png`
  )
  const [profileInfos, setProfileInfos] = useState([
    {
      icon: '',
      name: '',
      value: '',
      link: '',
    },
  ])
  const [isEdit, setIsEdit] = useState(false)

  const onLink = data => {
    if (!data || !data?.link) {
      return
    }

    return window.open(data?.link, '_top')
  }

  useEffect(() => {
    const user = auth?.user
    const chucDanhChinh = user?.dsChucDanh?.find(e => e?.phongBanChinh)
    setAvatar(`${FILE_URL}${user?.anhDaiDien}`)
    setProfileInfos([
      {
        icon: 'icon2-date',
        name: 'Ngày sinh:',
        value: user?.ngaySinh
          ? moment(user.ngaySinh).format('DD/MM/YYYY')
          : '...',
      },
      // {
      //   icon: 'icon2-date',
      //   name: 'Trạng thái:',
      //   value: user?.tamTrang || '...',
      // },
      {
        icon: 'icon-app-mobile',
        name: 'Số điện thoại:',
        value: user?.soDienThoai || '...',
        link: user?.soDienThoai ? `tel:${user.soDienThoai}` : false,
      },
      {
        icon: 'icon-app-mobile',
        name: 'Số điện thoại khác:',
        value: user?.soDienThoaiKhac || '...',
        link: user?.soDienThoaiKhac ? `tel:${user.soDienThoaiKhac}` : false,
      },
      {
        icon: 'icon-email font-size-12',
        name: 'Email:',
        value: user?.email || '...',
        link: user?.email ? `mailto:${user.email}` : false,
      },
      {
        icon: 'icon-building',
        name: 'Đơn vị:',
        value: chucDanhChinh?.donVi?.tenVietTat || '...',
      },
      {
        icon: 'icon-suplier-type',
        name: 'Phòng ban:',
        value: chucDanhChinh?.phongBan?.maPhongBan || '...',
      },
    ])
  }, [auth])

  return { avatar, profileInfos, isEdit, setIsEdit, onLink }
}

export default useProfile
