import React from 'react'
import { MasterLayout } from '../../../components/layout'
import * as Actions from '../../../actions'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { Intent, Checkbox, Button } from '@blueprintjs/core'
import * as MESSAGER from '../../../constants/MessageForm'
import { isEmpty } from 'lodash'

const CHINH_SUA_THOIGIANLUU = 'Chỉnh sửa thời hạn bảo quản'
const THEM_MOI_THOIGIANLUU = 'Thêm mới thời hạn bảo quản'
class ThoiGianLuuChinhSua extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      isLoadingButton: false,
      id: this.props.match.params.id || undefined,
      model: {
        tenThoiHanLuu: '',
        isActive: true,
      },
    }
  }

  componentDidMount() {
    if (this.props.match.params && this.props.match.params.id) {
      this.getDetail()
    }
  }

  getDetail = async () => {
    const { id } = this.state
    try {
      await this.props.actions.getThoiGianLuuChiTiet(id).then(res => {
        if (res && res.payload && res.payload.data && res.payload.data.result) {
          this.setState({
            model: res.payload.data.result,
          })
        }
      })
    } catch (error) {
      return this.props.actions.commonAddToasterMessage({
        message: MESSAGER.KHONGCO_DULIEU_TRONG_HETHONG,
        intent: Intent.DANGER,
      })
    }
  }

  /**
   * Get data input và Set data
   * @memberof ThoiGianLuuChinhSua
   */
  handleInputChange = e => {
    let target = e.target
    let name = target.name
    let value = target.value
    let checked = target.checked
    if (target.type === 'checkbox') {
      return this.setState({
        model: {
          ...this.state.model,
          [name]: checked,
        },
      })
    }

    this.setState({
      model: {
        ...this.state.model,
        [name]: value,
      },
    })
  }

  checkData = async () => {
    const { model } = this.state
    const error = {}
    let result = true
    if (isEmpty(model)) {
      return
    }

    if (model.tenThoiHanLuu.trim().length === 0) {
      error.tenThoiHanLuu = MESSAGER.TEXT_MESSAGE_DEFAULT
      result = false
    }

    this.setState({
      error,
    })

    return result
  }

  handleSubmit = async e => {
    e.preventDefault()
    const { model, id } = this.state
    const isCheckData = await this.checkData()
    if (!isCheckData) {
      return
    }

    this.setState({ isLoadingButton: true })
    try {
      const params = {
        ten_thoi_han_luu: model.tenThoiHanLuu,
        is_active: model.isActive,
      }
      if (id) {
        // Hàm cập nhật dữ liệu
        const params = {
          thoi_han_luu_id: model.phuongPhapLuuId || id,
          ten_thoi_han_luu: model.tenThoiHanLuu,
          is_active: model.isActive,
        }
        await this.props.actions.putThoiGianLuu(params).then(res => {
          if (res && (res.error || !res.payload)) {
            return this.props.actions.commonAddToasterMessage({
              message: MESSAGER.TOATS_MESSAGE_FAIL,
              intent: Intent.DANGER,
            })
          }

          this.props.actions.commonAddToasterMessage({
            message: MESSAGER.TOATS_MESSAGE_SUCCESS,
            intent: Intent.SUCCESS,
          })
        })
      } else {
        // Hàm thêm mới dữ liệu
        await this.props.actions.postThoiGianLuu(params).then(res => {
          if (res && (res.error || !res.payload)) {
            return this.props.actions.commonAddToasterMessage({
              message: MESSAGER.TOATS_MESSAGE_FAIL,
              intent: Intent.DANGER,
            })
          }

          this.props.actions.commonAddToasterMessage({
            message: MESSAGER.TOATS_MESSAGE_SUCCESS,
            intent: Intent.SUCCESS,
          })
        })
      }

      return this.goBack()
    } catch (error) {
      return this.props.actions.commonAddToasterMessage({
        message: MESSAGER.TOATS_MESSAGE_FAIL,
        intent: Intent.DANGER,
      })
    } finally {
      this.setState({ isLoadingButton: false })
    }
  }

  goBack = () => {
    this.props.history.goBack()
  }

  render() {
    const { model, error } = this.state
    return (
      <MasterLayout typeSidebar="sidebarSystem" collapseSideBar={true}>
        <div className="heading-block">
          <span className="headding-inline">
            <span className="icon-headding icon-thoigianluu"></span>
            <span className="text-headding">
              {this.state.id ? CHINH_SUA_THOIGIANLUU : THEM_MOI_THOIGIANLUU}
            </span>
          </span>
        </div>
        <div className="systerm-main-content">
          <div className="form-container">
            <form
              className="main-form"
              onSubmit={e => {
                e.preventDefault()
              }}
            >
              <div className="form-block place-block">
                <div className="row">
                  <div className="col-xs-12">
                    <div className="form-group">
                      <label className="label required">
                        Thời hạn bảo quản
                      </label>
                      <div className="input-form-inline pt-form-content">
                        <input
                          type="text"
                          name="tenThoiHanLuu"
                          className="input-control"
                          placeholder="Nhập thời hạn bảo quản"
                          onChange={this.handleInputChange}
                          value={
                            model && model.tenThoiHanLuu
                              ? model.tenThoiHanLuu
                              : ''
                          }
                        />
                        {error && error.tenThoiHanLuu !== undefined && (
                          <div className="form-helper-text">
                            {error.tenThoiHanLuu}
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-xs-12">
                    <div className="form-group form-group-inline mt20">
                      <label className="label mr10">Sử dụng</label>
                      <Checkbox
                        name="isActive"
                        checked={model.isActive}
                        onChange={this.handleInputChange}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="form-block">
                <div className="buttton-action-footer">
                  <Button
                    type="button"
                    onClick={this.goBack}
                    className="pt-button button-default"
                    loading={this.state.isLoadingButton}
                    disabled={this.state.isLoadingButton}
                  >
                    <span className="text-content">Hủy</span>
                    <span className="pt-icon icon-Huy"></span>
                  </Button>
                  <Button
                    type="button"
                    onClick={this.handleSubmit}
                    className="pt-button btn-blue-color"
                    loading={this.state.isLoadingButton}
                    disabled={this.state.isLoadingButton}
                  >
                    <span className="text-content">Lưu</span>
                    <span className="pt-icon icon-save"></span>
                  </Button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </MasterLayout>
    )
  }
}

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(Actions, dispatch),
})

export default connect(null, mapDispatchToProps)(ThoiGianLuuChinhSua)
