import React from 'react'
import { Dialog } from '@blueprintjs/core'
import classNames from 'classnames'

const PopupModal = ({
  isOpen = false,
  canOutsideClickClose = false,
  title = 'Modal',
  overlayClass,
  contentClass,
  children,
  onClose = () => {},
}) => {
  return (
    <Dialog
      style={{ width: 400 }}
      isOpen={isOpen}
      canEscapeKeyClose={false}
      canOutsideClickClose={canOutsideClickClose}
      title={title}
      backdropClassName={classNames(overlayClass)}
      className={classNames('popup-modal__panel', contentClass)}
      onClose={onClose}
    >
      <div className="popup-modal__content">{children}</div>
    </Dialog>
  )
}

export default PopupModal
