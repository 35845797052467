import React, { Component } from 'react'
import PropTypes from 'prop-types'
// import { Link, Redirect } from 'react-router-dom';
import classnames from 'classnames'
import moment from 'moment'
// import {Authorization, Permission} from '../../../auth';
//import { FILE_URL } from '../../../../constants/Api';
import UIStatus from 'components/ui/status'

const LINE_HEIGHT = 18

class ListItemAssignedPersonal extends Component {
  constructor(props) {
    super(props)
    this.handleEnabledChange = this.handleEnabledChange.bind(this)
    this.handleClick = this.handleClick.bind(this)
    this.handleClickPopup = this.handleClickPopup.bind(this)
    this.handleClickShowDepartment = this.handleClickShowDepartment.bind(this)
    this.handleReadMore = this.handleReadMore.bind(this)
  }

  static propTypes = {
    item: PropTypes.object.isRequired,
    number: PropTypes.number.isRequired,
  }

  refHandlers = {
    toaster: (ref: Toaster) => (this.toaster = ref),
  }

  state = {
    isEnabled: false,
    isOpenPopup: false,
    dsPhoiHop: [],
    isShowCollapse: false,
    readMore: {
      trichYeu: false,
      giaoViec: false,
    },
  }

  handleEnabledChange = e => {
    this.setState({ isEnabled: !this.state.isEnabled })
  }

  handleClick = () => {
    if (!this.props.item) {
      return
    }

    this.props.onClickDetail &&
      this.props.onClickDetail({
        phieuGiaoViecId: this.props.item.phieuGiaoViecId,
        soCongVan: this.props.item.soCongVan,
        fileId: this.props.item.fileId,
      })
  }

  handleClickPopup = () => {
    this.setState({
      isOpenPopup: !this.state.isOpenPopup,
      error: '',
    })
  }

  handleClickShowDepartment = () => {
    this.setState({ isShowCollapse: !this.state.isShowCollapse })
  }

  componentDidMount() {
    if (this.props.item && this.props.item.dsPhoiHop) {
      const dsPhoiHop = []
      this.props.item.dsPhoiHop.split(',').forEach(item => {
        dsPhoiHop.push(item.trim())
      })
      this.setState({ dsPhoiHop })
    }
    let newReadMore = { ...this.state.readMore }
    if (this.trichYeu.offsetHeight / LINE_HEIGHT > 5) {
      newReadMore = {
        ...newReadMore,
        trichYeu: true,
      }
    }
    if (this.giaoViec.offsetHeight / LINE_HEIGHT > 4) {
      newReadMore = {
        ...newReadMore,
        giaoViec: true,
      }
    }
    this.setState({
      readMore: newReadMore,
    })
  }

  handleReadMore(name) {
    let newReadMore = { ...this.state.readMore }
    if (newReadMore[name]) {
      newReadMore = {
        ...newReadMore,
        [name]: false,
      }
      this.setState({ readMore: newReadMore })
    } else {
      this.handleClick()
    }
  }

  render() {
    //const token = this.props.auth && this.props.auth.token && this.props.auth.token.accessToken ? this.props.auth.token.accessToken : '';
    //let fileUrl = `${FILE_URL}/api/file/get/${this.props.item.fileId}?BearerToken=${token}`;
    if (!this.props.item) {
      return
    }

    let chuTri = []
    if (this.props.item.pbChuTri) {
      chuTri.push(this.props.item.pbChuTri)
    }
    if (this.props.item.nguoiChuTri) {
      chuTri.push(this.props.item.nguoiChuTri)
    }

    const { item, number } = this.props

    return (
      <tr>
        <td onClick={this.handleClick}>{number > 9 ? number : '0' + number}</td>
        <td onClick={this.handleClick}>
          {item.daLuuTuTaiLieu && item.daLuuTuTaiLieu === true ? (
            <span className="icon-Save-DocumentCabinet style-icon-DocumentCabinet"></span>
          ) : (
            <span>&nbsp;</span>
          )}
        </td>
        {/* <td onClick={this.handleClick}>
          {item.congVanDenId !== null &&
            <span className="icon-type-work icon-Cong_van_den1"></span>
          }
          {item.congVanNoiBoId !== null &&
            <span className="icon-type-work icon-Nhap_cong_van_noi_bo1"></span>
          }
          {item && !item.baoNham && giaoViecMoi &&
            <span className="icon-type-work icon-Cong_viec_no_cong_van"></span>
          }
        </td> */}
        <td onClick={this.handleClick}>{item.soCongVan}</td>
        <td className="textarea-content" onClick={this.handleClick}>
          <div ref={el => (this.trichYeu = el)}>
            <p
              className={classnames({
                // 'overflow-hidden-90px': this.state.readMore.trichYeu
              })}
            >
              {item.tieuDe ? item.tieuDe : ''}
            </p>
            {/* {this.state.readMore.trichYeu && <i className="icon-Group-8"onClick={this.handleReadMore.bind(this, 'trichYeu')}></i>} */}
          </div>
        </td>
        {/*<td className="text-center">
          {item.fileId &&
            <a className="view-file-attach" href={fileUrl} target="_blank" rel="noopener noreferrer">
              <i className="pt-icon icon-attachment-file"></i>
            </a>
          }
        </td>*/}
        <td onClick={this.handleClick}>
          <p>
            {item.giaoViec.nguoiGiaoViec
              ? item.giaoViec.nguoiGiaoViec.tenNhanVien
              : null}
          </p>
          <p>
            {item.giaoViec
              ? moment(item.giaoViec.ngayGiaoViec).format('DD/MM/YYYY HH:mm')
              : null}
          </p>
          <div className="assign-content textarea-content">
            <div ref={el => (this.giaoViec = el)}>
              <p
                className={classnames({
                  // 'overflow-hidden-72px': this.state.readMore.giaoViec
                })}
              >
                {item && item.giaoViec && item.giaoViec.noiDung
                  ? item.giaoViec.noiDung
                  : ''}
              </p>
              {/* { this.state.readMore.giaoViec && <i className="icon-Group-8" onClick={this.handleReadMore.bind(this, 'giaoViec')}></i>} */}
            </div>
          </div>
        </td>
        <td onClick={this.handleClick}>
          {item.hanGiaiQuyet
            ? moment(item.hanGiaiQuyet).format('DD/MM/YYYY')
            : null}
        </td>
        <td style={{ textAlign: 'center' }} onClick={this.handleClick}>
          {item.trangThai !== 2 && (
            <span
              className={classnames('deadline-date', {
                'style-qh': item.soNgayConLai < 0,
                'style-gqh': item.soNgayConLai === 0 || item.soNgayConLai === 1,
                'style-color': item.soNgayConLai > 1,
              })}
            >
              {item.soNgayConLai !== null ? item.soNgayConLai : ''}
            </span>
          )}
        </td>
        <td onClick={this.handleClick}>
          <UIStatus
            className="min-width-80 center"
            name={
              item.trangThai === 0
                ? 'Chưa xử lý'
                : item.trangThai === 1
                ? 'Đang xử lý'
                : item.trangThai === 2
                ? 'Đã xử lý'
                : ''
            }
            color={
              item.trangThai === 0
                ? '#df8e0a'
                : item.trangThai === 1
                ? '#1677ff'
                : item.trangThai === 2
                ? '#18a94d'
                : ''
            }
            theme="BACKGROUND"
          />
        </td>
        <td onClick={this.handleClick}>{chuTri.join(', ')}</td>
        {/* CHANGED: Bỏ cột phối hợp */}
        {/* <td onClick={this.handleClickShowDepartment}>
          {this.state.isShowCollapse &&
            <div className="department-collapse-box">
            {this.state.dsPhoiHop.map((content, i) => (
              <p className="department-collapse-item" key={i}>{content}</p>
            ))}
            </div>
          }
          {!this.state.isShowCollapse &&
            <div className="department-collapse-box">
              <span className="department-collapse-item">{this.state.dsPhoiHop[0]}</span>
              {this.state.dsPhoiHop.length > 1 &&
              <span className="count-department">(+{this.state.dsPhoiHop.length - 1})</span>
              }
            </div>
          }
        </td> */}
        <td onClick={this.handleClick} className="text-center-col">
          {item.canBaoCao && <span className="icon-Bao_Cao"></span>}
        </td>
      </tr>
    )
  }
}

export default ListItemAssignedPersonal
