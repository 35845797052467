import React from 'react'
import { PopupConfirm } from '../../components/common'
import {
  SortableContainer,
  SortableHandle,
  SortableElement,
  arrayMove,
} from 'react-sortable-hoc'
import { Popover, Position, PopoverInteractionKind } from '@blueprintjs/core'
import * as POPUP from '../../constants/Popup'
import { Link } from 'react-router-dom'
import { Intent } from '@blueprintjs/core'
import * as MESSAGER from '../../constants/MessageForm'

const LINK_EDIT = '/he-thong/nguoi-dung/chinh-sua'

class ListPermissionRole extends React.Component {
  constructor(props) {
    super(props)
    this.handleClickPopup = this.handleClickPopup.bind(this)
    this.handleSubmitRemoveItem = this.handleSubmitRemoveItem.bind(this)
  }

  state = {
    isOpenPopup: false,
    isEnabled: false,
    nhanVienId: null,
    items: [],
    isMove: false,
    isEmpty: false,
    isLoading: true,
  }

  handleClickPopup = id => {
    this.setState({
      isOpenPopup: !this.state.isOpenPopup,
      error: '',
      nhanVienId: id,
    })
  }

  onSortEnd = ({ oldIndex, newIndex }) => {
    if (oldIndex !== newIndex) {
      this.setState({
        items: arrayMove(this.state.items, oldIndex, newIndex),
        isMove: true,
      })
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.isMove !== this.state.isMove) {
      if (this.state.items.length === 0) {
        return
      }
      const array = []
      this.state.items.map(item => array.push(item.chucDanhId))
      if (array.length > 0 && this.state.isMove === true) {
        this.props.actions.updateArrayCommonFetchStaff(array).then(res => {
          if (
            res.payload &&
            res.payload.data.result &&
            res.payload.data.result === true
          ) {
            if (this.props.match.params.id !== undefined) {
              this.props.onSubmit &&
                this.props.onSubmit(this.props.match.params.id)
            }
            this.setState({ isMove: false })
            return this.props.actions.commonAddToasterMessage({
              message: MESSAGER.TOATS_MESSAGE_SUCCESS,
              intent: Intent.SUCCESS,
            })
          } else {
            this.setState({ isMove: false })
            return this.props.actions.commonAddToasterMessage({
              message: MESSAGER.TOATS_MESSAGE_FAIL,
              intent: Intent.DANGER,
            })
          }
        })
      }
    }
  }

  handleSubmitRemoveItem = () => {
    if (this.state.nhanVienId) {
      this.props.actions.deleteStaff(this.state.nhanVienId).then(res => {
        if (res.payload && res.payload.data.result === true) {
          if (this.props.match.params.id !== undefined) {
            this.props.onSubmit &&
              this.props.onSubmit(this.props.match.params.id)
          }
          return this.props.actions.commonAddToasterMessage({
            message: MESSAGER.TOATS_MESSAGE_DELETE_SUCCESS,
            intent: Intent.SUCCESS,
          })
        } else {
          this.props.actions.commonAddToasterMessage({
            message: MESSAGER.TOATS_MESSAGE_DELETE_FAIL,
            intent: Intent.DANGER,
          })
        }
      })
    }

    this.setState({
      isOpenPopup: false,
    })
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.items !== this.props.items) {
      this.setState({
        items: nextProps.items,
        isLoading: false,
      })
      if (nextProps.items.length === 0) {
        this.setState({
          isEmpty: true,
        })
      }
    }
  }

  render() {
    let ItemLoading = (
      <div className="loading-content">
        <span className="shape1"></span>
        <span className="shape2"></span>
        <span className="shape3"></span>
        <span className="shape4"></span>
        <span className="shape5"></span>
        <span className="shape6"></span>
        <span className="shape7"></span>
        <span className="shape8"></span>
        <span className="shape9"></span>
        <span className="shape10"></span>
        <span className="shape11"></span>
        <span className="shape12"></span>
        <span className="shape13"></span>
      </div>
    )
    //icon move
    const DragHandle = SortableHandle(() => (
      <span className="icon-move-drap"></span>
    ))
    //items move
    const SortableItem = SortableElement(({ i, value }) => {
      return (
        <div className="table-rows table-row-nolink">
          <div className="table-cols-content width-col-5">
            <DragHandle />
          </div>
          <div className="table-cols-content width-col-5">
            <span className="order-content">{i < 10 ? '0' + i : i}</span>
          </div>
          <div className="table-cols-content width-col-10">
            <span className="order-content">{value.maNhanVien}</span>
          </div>
          <div className="table-cols-content width-col-20">
            <span className="order-content">{value.tenNhanVien}</span>
          </div>
          <div className="table-cols-content width-col-20">
            <span className="order-content">{value.tenPhongBan}</span>
          </div>
          <div className="table-cols-content width-col-10">
            <span className="order-content">{value.chucVu}</span>
          </div>
          <div className="table-cols-content width-col-10">
            <span className="order-content">{value.username}</span>
          </div>
          <div className="table-cols-content width-col-10">
            <span className="order-content">{value.tenDoiTuong}</span>
          </div>
          <div className="table-cols-content width-col-10">
            <Popover
              popoverClassName="pt-popover-content-sizing pt-minimal"
              position={Position.BOTTOM_RIGHT}
              interactionKind={PopoverInteractionKind.HOVER}
              openOnTargetFocus={true}
              content={
                <div className="menu-popover">
                  <Link
                    className="item"
                    to={`${LINK_EDIT}/${value.nhanVienId}`}
                  >
                    <span className="pt-icon pt-icon-annotation"></span>
                    <span className="name_action">Chỉnh sửa</span>
                  </Link>
                  <span
                    className="item"
                    onClick={this.handleClickPopup.bind(this, value.chucDanhId)}
                  >
                    <span className="pt-icon pt-icon-trash"></span>
                    <span className="name_action">Xóa</span>
                  </span>
                </div>
              }
            >
              <span className="icon-More"></span>
            </Popover>
          </div>
        </div>
      )
    })
    //list foreach
    const SortableList = SortableContainer(({ items }) => {
      return (
        <div className="sortableList">
          {items.map((value, index) => (
            <SortableItem
              key={`item-${index}`}
              i={index + 1}
              index={index}
              value={value}
            />
          ))}
        </div>
      )
    })
    return (
      <div className="page-list-container page-list-role-container">
        <div className="table-container table-subcollap-container">
          <div className="table-scroll">
            <div className="collapse-panel-content">
              <div className="table-display">
                <div className="table-display-container">
                  {this.props.children}
                  <div className="table-body-container">
                    {this.state.isLoading ? (
                      <div className="table-loading-system">
                        {ItemLoading}
                        {ItemLoading}
                        {ItemLoading}
                      </div>
                    ) : (
                      <SortableList
                        helperClass="SortableHelper"
                        items={this.state.items}
                        onSortEnd={this.onSortEnd}
                        useDragHandle={true}
                        lockAxis="y"
                      />
                    )}
                    {this.state.isEmpty && (
                      <div className="error-page-container">
                        <div className="error-content">
                          <img
                            src={
                              process.env.PUBLIC_URL +
                              '/images/search-result-not-found.png'
                            }
                            alt="search-result-not-found"
                            className="img-thumb-error"
                          />
                          <p className="description-note">
                            Không có dữ liệu trong hệ thống
                          </p>
                        </div>
                      </div>
                    )}
                    {/* <ListFooter /> */}
                    <PopupConfirm
                      isOpen={this.state.isOpenPopup}
                      onClose={this.handleClickPopup}
                      title={POPUP.XOA_DANH_MUC}
                      text={POPUP.TEXT_BUTTON_SUBMIT}
                      onSubmit={this.handleSubmitRemoveItem}
                    >
                      {POPUP.TEXT_SUBMIT_BODY}
                    </PopupConfirm>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default ListPermissionRole
