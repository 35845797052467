import React, { Component, memo } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import withRouter from 'react-router/withRouter'

import * as Types from '../../constants/Api'
import classnames from 'classnames'
import Permission from '../auth/Permission'
import './style.scss'
import ChangePassword from './components/change-password'
import ChangeBackground from './components/change-background'
import Profile from './components/profile'
import { Utils } from 'helpers'

class HeaderUser extends Component {
  constructor(props) {
    super(props)
    this.state = {
      redirectTo: '/',
      random: Math.random(),
      isShowChangePassword: false,
      isShowChangeBackground: false,
      isShowProfile: false,
    }
    this.handleChoseRole = this.handleChoseRole.bind(this)
    this.handleShowChangePassword = this.handleShowChangePassword.bind(this)
    this.handleShowChangeBackground = this.handleShowChangeBackground.bind(this)
    this.handleShowProfile = this.handleShowProfile.bind(this)
  }
  static propTypes = {
    user: PropTypes.object.isRequired,
    actions: PropTypes.object,
    roleId: PropTypes.string,
    dotsData: PropTypes.array,
  }

  componentDidMount() {
    this.handleDotsChucDanh(this.props.user.nhanVienId)
    this.setState({
      random: Math.random(),
    })
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.roleId !== this.props.roleId) {
      this.props.actions.getNumberDocumentPage()
      // this.props.actions.getNumberEVNDocument()
      this.props.actions.getNumberSignNumberPage()
      this.props.actions.getNumberProjectSignNumberPage()
      this.props.actions.getNumberWorkPage()
      this.props.actions.getTongSoCongVanThuHoi()
      this.props.actions.getNumberPhieuYKien()
      this.props.actions.getNumberHSCV()
    }
  }

  handleChoseRole = id => {
    if (id === this.props.roleId) {
      return
    }

    Promise.all([
      () => {
        Utils.removeData('ListReceiveDocumentPage-0')
        Utils.removeData('ListReceiveDocumentPage-1')
        Utils.removeData('ListReceiveDocumentPage-2')
      },
    ]).then(() => {
      this.props.actions.switchRoleUser({ roleId: id })
      this.props.actions.userPermission(id).then(res => {
        let redirectTo = '/'
        let typeSidebar = 'HomePage'

        if (
          res.payload &&
          res.payload.data &&
          res.payload.data.result &&
          res.payload.data.result.items
        ) {
          const permission = res.payload.data.result.items.map(
            item => item.maQuyen
          )
          if (permission.indexOf(Permission.CONGVANDEN) !== -1) {
            redirectTo = '/cong-van/cong-van-den/danh-sach/chua-xu-ly'
            typeSidebar = 'documentary'
          } else {
            if (permission.indexOf(Permission.CONGVIEC_PHONGBAN) !== -1) {
              redirectTo = '/cong-viec/phong-ban/danh-sach/chua-giao'
              typeSidebar = 'work'
            } else if (permission.indexOf(Permission.CONGVIEC_CANHAN) !== -1) {
              redirectTo = '/cong-viec/ca-nhan/danh-sach/chua-thuc-hien'
              typeSidebar = 'work'
            }
          }

          if (permission.indexOf(Permission.TUTAILIEUPHONGBAN_QUANLY) !== -1) {
            this.props.actions.setCabinetType(Types.CABINET_DEPARTMENT)
          }
        }
        this.props.actions.commonSaveTypeSidebar(typeSidebar)
        if (
          this.props.location &&
          redirectTo === this.props.location?.pathname
        ) {
          return window.location.reload()
        }
        this.props.history.push(redirectTo)
      })
    }, [])
  }

  handleDotsChucDanh = nhanVienId => {
    const params = {
      nhan_vien_id: nhanVienId,
    }
    nhanVienId &&
      this.props.actions.getStatusDotsChucDanh(params).then(res => {
        if (res && res.payload && res.payload.data && res.payload.data.result) {
          this.setState({
            dotsData: res.payload.data.result,
          })
        }
      })
  }

  handleShowChangePassword = show => {
    this.setState({
      isShowChangePassword: show,
    })
  }

  handleShowChangeBackground = show => {
    this.setState({
      isShowChangeBackground: show,
    })
  }

  handleShowProfile = show => {
    this.setState({
      isShowProfile: show,
    })
  }

  showRole = () => {
    const role = this.props.user.dsChucDanh?.find(
      elm => elm.id === this.props.roleId
    )
    if (!role) {
      return
    }

    return `${role?.chucVu ? role.chucVu.tenChucVu : ''} ${
      role?.phongBan ? role.phongBan.maPhongBan : ''
    }`
  }

  render() {
    const { dotsData } = this.state
    return (
      <ul className="info-user">
        <li className="dropdown user user-menu">
          <span className="dropdown-toggle">
            <span
              className="user-avatar-content"
              style={{
                backgroundImage: `url(${
                  this.props.user && this.props.user.anhDaiDien
                    ? `${Types.IMAGE_URL}${this.props.user.anhDaiDien}?preventCache=${this.state.random}`
                    : '/images/default_avatar.png'
                })`,
              }}
            >
              {dotsData &&
                dotsData.length > 0 &&
                dotsData.findIndex(i => i.isThongBao === true) !== -1 && (
                  <span className="dots-chuc-danh" />
                )}
            </span>
            <div className="infos">
              <span className="name">{this.props.user.tenNhanVien}</span>
              <p className="role">{this.showRole()}</p>
            </div>
          </span>
        </li>
        <li className="userinfo-mobile pt-menu user-submenu-content">
          <div className="userinfo-content">
            <p className="label-text">Chức danh:</p>
            <ul className="user-titles">
              {this.props.user &&
                this.props.user.dsChucDanh &&
                this.props.user.dsChucDanh.map((item, i) => (
                  <li
                    key={i}
                    className={classnames('ut-item', {
                      active: this.props.roleId === item.id,
                    })}
                  >
                    <span
                      tabIndex="0"
                      className="text-link"
                      onClick={this.handleChoseRole.bind(this, item.id)}
                    >
                      {item.chucVu ? item.chucVu.tenChucVu + ' ' : ' '}
                      {item.phongBan ? item.phongBan.maPhongBan : ''}
                    </span>
                    {dotsData &&
                      dotsData.length > 0 &&
                      dotsData.findIndex(
                        i => i.chucDanhId === item.id && i.isThongBao === true
                      ) !== -1 && <span className="dots-chuc-danh" />}
                  </li>
                ))}
            </ul>
          </div>
        </li>
        <li className="cpc-menu">
          <ul className="pt-menu user-submenu-content">
            <li className="item">
              <h3 className="title-username">
                <span className="username" tabIndex="0">
                  <span className="icon-profile"></span>
                  <span className="name">{this.props.user.tenNhanVien}</span>
                </span>
              </h3>
              <div className="userinfo-content">
                <p className="label-text">Chức danh:</p>
                <ul className="user-titles">
                  {this.props.user &&
                    this.props.user.dsChucDanh &&
                    this.props.user.dsChucDanh.map((item, i) => (
                      <li
                        key={i}
                        className={classnames('ut-item', {
                          active: this.props.roleId === item.id,
                        })}
                      >
                        <span
                          tabIndex="0"
                          className="text-link"
                          onClick={this.handleChoseRole.bind(this, item.id)}
                        >
                          {item.chucVu ? item.chucVu.tenChucVu + ' ' : ' '}
                          {item.phongBan ? item.phongBan.maPhongBan : ''}
                        </span>
                        {dotsData &&
                          dotsData.length > 0 &&
                          dotsData.findIndex(
                            i =>
                              i.chucDanhId === item.id && i.isThongBao === true
                          ) !== -1 && <span className="dots-chuc-danh" />}
                      </li>
                    ))}
                </ul>
              </div>
            </li>
            <li className="item">
              <span
                className="pt-menu-item btn-logout"
                onClick={() => this.handleShowProfile(true)}
              >
                <span className="icon-info-circle color-blue vertical-align-text-bottom"></span>
                <span className="name">Thông tin cá nhân</span>
              </span>
            </li>
            <li className="item">
              <span
                className="pt-menu-item btn-logout"
                onClick={() => this.handleShowChangeBackground(true)}
              >
                <span className="icon-settings color-blue vertical-align-text-bottom"></span>
                <span className="name">Đổi hình nền</span>
              </span>
            </li>
            <li className="item">
              <span
                className="pt-menu-item btn-logout"
                onClick={() => this.handleShowChangePassword(true)}
              >
                <span className="icon-lock vertical-align-text-bottom"></span>
                <span className="name">Đổi mật khẩu</span>
              </span>
            </li>
            <li className="item">
              <span
                className="pt-menu-item btn-logout"
                onClick={this.props.handleLogout}
              >
                <span className="icon-logout"></span>
                <span className="name">Đăng xuất</span>
              </span>
            </li>
          </ul>
        </li>
        {this.state.isShowChangePassword && (
          <ChangePassword
            onClose={() => this.handleShowChangePassword(false)}
            logout={this.props.handleLogout}
          />
        )}
        {this.state.isShowChangeBackground && (
          <ChangeBackground
            onClose={() => this.handleShowChangeBackground(false)}
          />
        )}
        {this.state.isShowProfile && (
          <Profile onClose={() => this.handleShowProfile(false)} />
        )}
      </ul>
    )
  }
}

const mapStateToProps = state => {
  return {
    quyensMenu: state.common.quyensMenu,
  }
}

export default withRouter(connect(mapStateToProps, null)(memo(HeaderUser)))
