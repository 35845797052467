export const convertFileToUpload = file => ({
  uid: file?.uid,
  name: file?.name,
  mime: file?.type,
  size: file?.size,
})

export const getIconKieuFile = extension => {
  if (!extension) {
    return `${process.env.PUBLIC_URL}/images/file-unknow.svg`
  }

  switch (extension?.toUpperCase()) {
    case 'PDF':
    case '.PDF':
    case 'APPLICATION/PDF':
      return `${process.env.PUBLIC_URL}/images/pdf_file.svg`
    case 'PPT':
    case '.PPTX':
    case '.PPSX':
    case '.PPS':
    case 'APPLICATION/VND.MS-POWERPOINT':
    case 'APPLICATION/VND.OPENXMLFORMATS-OFFICEDOCUMENT.PRESENTATIONML.PRESENTATION':
      return `${process.env.PUBLIC_URL}/images/powerpoint_file.svg`
    case 'XLSX':
    case '.XLSX':
    case '.XLS':
    case 'APPLICATION/VND.MS-EXCEL':
    case 'APPLICATION/VND.OPENXMLFORMATS-OFFICEDOCUMENT.SPREADSHEETML.SHEET':
      return `${process.env.PUBLIC_URL}/images/excel_file.svg`
    case 'DOC':
    case '.DOC':
    case '.DOCX':
    case 'APPLICATION/MSWORD':
    case 'APPLICATION/VND.OPENXMLFORMATS-OFFICEDOCUMENT.WORDPROCESSINGML.DOCUMENT':
      return `${process.env.PUBLIC_URL}/images/doc_file.svg`
    case 'VIDEO/MP4':
    case 'VIDEO/QUICKTIME':
    case '.MP4':
      return `${process.env.PUBLIC_URL}/images/video.svg`
    case '.ZIP':
    case '.RAR':
    case 'APPLICATION/X-ZIP-COMPRESSED':
    case 'APPLICATION/OCTET-STREAM':
      return `${process.env.PUBLIC_URL}/images/zip_file.svg`
    case 'AUDIO/WAV':
    case 'AUDIO/MPEG':
    case 'AUDIO/MP4':
      return `${process.env.PUBLIC_URL}/images/sound.svg`
    case '.JPEG':
    case '.JPG':
    case '.GIF':
    case '.PJPEG':
    case 'IMAGE/JPEG':
    case 'IMAGE/JPG':
    case 'IMAGE/PJPEG':
    case 'IMAGE/GIF':
    case 'IMAGE/BMP':
    case 'IMAGE/X-WINDOWS-BMP':
      return `${process.env.PUBLIC_URL}/images/jpg_file.svg`
    case '.PNG':
    case 'IMAGE/PNG':
      return `${process.env.PUBLIC_URL}/images/png_file.svg`
    case '.SVG':
      return `${process.env.PUBLIC_URL}/images/svg.svg`
    default:
      return `${process.env.PUBLIC_URL}/images/file-unknow.svg`
  }
}

export const settupType = () => {
  return ['application/x-msdownload', '.exe']
}

export const imagesType = () => {
  return [
    'image/jpeg',
    'image/jpg',
    'application/jpg',
    'image/pjpeg',
    'image/pipeg',
    'image/gif',
    'image/bmp',
    'image/png',
    'image/x-windows-bmp',
  ]
}

export const ducumentsType = () => {
  return [
    'application/msword',
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
    'application/vnd.ms-excel',
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    '.xlsx',
    'text/comma-separated-values',
    'text/csv',
    'application/csv',
    'application/excel',
    'application/vnd.msexcel',
    'application/pdf',
    'application/x-pdf',
    '.pdf',
    'application/zip',
    'application/mspowerpoint',
    'application/powerpoint',
    '.pps',
    'application/vnd.ms-powerpoint',
    'application/x-mspowerpoint',
    'application/vnd.openxmlformats-officedocument.presentationml.presentation',
    'ppt',
    'pptx',
    '.ppt',
    '.pptx',
    'application/msword',
    'application/doc',
    'doc',
    '.doc',
    'docx',
    '.docx',
  ]
}

export const compressedsType = () => {
  return ['application/x-zip-compressed', 'application/octet-stream']
}

export default {
  convertFileToUpload,
  getIconKieuFile,
  imagesType,
  ducumentsType,
  compressedsType,
}
