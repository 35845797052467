import React from 'react'
import PropTypes from 'prop-types'
import { LoadingItem } from '../common/'
import {
  SortableContainer,
  SortableElement,
  arrayMove,
} from 'react-sortable-hoc'
import { PopupConfirm } from '../common/'
import * as POPUP from '../../constants/Popup'
import classnames from 'classnames'
import { MESSAGE_NOT_FOUND, MESSAGE_ERROR } from '../../constants/MessageForm'

const SortableItem = SortableElement(({ value, stt, handleClickPopup }) => (
  <div className="table-rows">
    <div className="table-cols-content width-col-5">
      <span className="order-content">{stt}</span>
    </div>
    <div className="table-cols-content width-col-30">
      <span className="text-tree-push">{value.tenNhanVien}</span>
    </div>
    <div className="table-cols-content width-col-30">
      <span className="text-tree-push">{value.soDienThoai}</span>
    </div>
    <div className="table-cols-content width-col-30">
      <span className="text-tree-push">{value.email}</span>
    </div>
    <div className="table-cols-content width-col-5"></div>
  </div>
))

const SortableList = SortableContainer(({ items, handleClickPopup }) => {
  return (
    <div className="sortableList">
      {items.map((item, k) => (
        <SortableItem
          stt={k + 1}
          key={k}
          index={k}
          value={item}
          handleClickPopup={handleClickPopup}
        />
      ))}
    </div>
  )
})

class ListDecentralizationUser extends React.Component {
  constructor(props) {
    super(props)
    this.handleClickPopup = this.handleClickPopup.bind(this)
    this.handleSubmitRemoveItem = this.handleSubmitRemoveItem.bind(this)
  }

  handleClickPopup = id => {
    this.setState({
      isOpenPopup: !this.state.isOpenPopup,
      error: '',
      nhanVienPhongBanId: id,
    })
  }

  handleSubmitRemoveItem() {
    if (this.state.nhanVienPhongBanId) {
      this.props.onSubmit && this.props.onSubmit(this.state.nhanVienPhongBanId)
    }

    this.setState({
      isOpenPopup: false,
    })
  }

  static propTypes = {
    isLoading: PropTypes.bool,
    isEmpty: PropTypes.bool,
    departments: PropTypes.array,
    users: PropTypes.array,
  }

  static defaultProps = {
    departments: [],
    users: [],
  }

  state = {
    users: [],
    isActive: this.props.auth.mainDepartmentId,
    isOpenPopup: false,
    nhanVienPhongBanId: null,
    isEmpty: false,
    isLoading: true,
    sort: {},
  }

  componentWillReceiveProps(props) {
    if (props.DepartmentId !== this.props.DepartmentId) {
      this.setState({ isActive: props.DepartmentId, sort: {} })
    }

    if (props.users !== this.props.users) {
      this.setState({
        users: props.users,
      })
    }

    if (props.users.length === 0 && this.props.isEmpty === true) {
      this.setState({
        isEmpty: true,
        isLoading: false,
      })
    }

    if (props.users.length > 0 && this.props.isEmpty === false) {
      this.setState({
        isEmpty: false,
        isLoading: false,
      })
    }

    if (props.isLanhDao !== this.props.isLanhDao) {
      this.setState({ sort: {} })
    }
  }

  handleClickDepartment(id, e) {
    e.preventDefault()
    this.setState({ isActive: id })
    this.props.onClickDepartment && this.props.onClickDepartment(id)
  }

  renderSubItem(items) {
    return (
      <ul className="sublist">
        {items &&
          items.map(item => {
            return (
              <li className="subitem" key={item.phongBanId}>
                <div
                  className={classnames('main-content', {
                    active: this.state.isActive === item.phongBanId,
                  })}
                >
                  <span className="left-icon icon-Back"></span>
                  <a
                    href="/#"
                    className="text-link"
                    onClick={this.handleClickDepartment.bind(
                      this,
                      item.phongBanId
                    )}
                  >
                    <span className="text-content">{item.tenPhongBan}</span>
                  </a>
                </div>
                {item.dsPhongBanCon && this.renderSubItem(item.dsPhongBanCon)}
              </li>
            )
          })}
      </ul>
    )
  }

  handleClickSort(i) {
    let state = this.state

    for (var v in state.sort) {
      if (v === i) {
        continue
      }

      delete state.sort[v]
    }

    state.sort[i] = !state.sort[i] || state.sort[i] === 'ASC' ? 'DESC' : 'ASC'
    this.setState(state, () => {
      let sort

      for (let sortField in this.state.sort) {
        sort = `${sortField} ${this.state.sort[sortField]}`
      }

      this.props.onSort && this.props.onSort(sort)
    })
  }

  handleSort({ oldIndex, newIndex }) {
    if (oldIndex !== newIndex) {
      this.setState(
        {
          users: arrayMove(this.state.users, oldIndex, newIndex),
        },
        () => {
          this.props.onSort && this.props.onSort(this.state.users)
        }
      )
    }
  }

  render() {
    return (
      <div className="container-list-permission-user">
        <div className="cols-group-content decentralized-users-content">
          <div className="aside-col-content">
            <div className="aside-nav">
              <div className="head-content-aside">
                <h3 className="main-heading heading-14">Phòng ban</h3>
              </div>
              <div className="aside-main-content">
                <div className="aside-list-content">
                  <ul className="departments-list">
                    {this.props.isLoading && this.state.isLoading ? (
                      <div className="table-loading">
                        <span className="colum-loading">
                          <LoadingItem />
                        </span>
                      </div>
                    ) : (
                      this.props.departments.map(item => (
                        <li className="item-list" key={item.phongBanId}>
                          <div
                            className={classnames('main-content', {
                              active: this.state.isActive === item.phongBanId,
                            })}
                          >
                            <a
                              href="/#"
                              className="text-link"
                              onClick={this.handleClickDepartment.bind(
                                this,
                                item.phongBanId
                              )}
                            >
                              <span className="text-content">
                                {item.tenPhongBan}
                              </span>
                            </a>
                          </div>
                          {item.dsPhongBanCon &&
                            this.renderSubItem(item.dsPhongBanCon)}
                        </li>
                      ))
                    )}
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div className="col-main-content listrole-container decentralized-users-main">
            <div className="table-container table-subcollap-container">
              <div className="table-display-container">
                <div className="table-display">
                  {!this.state.isEmpty && (
                    <div className="table-display table-head-container">
                      <div className="table-cols-content width-col-5">
                        <span className="head-label">STT</span>
                      </div>
                      <div className="table-cols-content width-col-30">
                        <span className="head-label">Tên nhân viên</span>
                        <span
                          className={`pt-icon ${
                            this.state.sort['TenNhanVien'] &&
                            this.state.sort['TenNhanVien'] === 'DESC'
                              ? 'pt-icon-chevron-up'
                              : 'pt-icon-chevron-down'
                          }`}
                          onClick={this.handleClickSort.bind(
                            this,
                            'TenNhanVien'
                          )}
                        ></span>
                      </div>
                      <div className="table-cols-content width-col-30">
                        <span className="head-label">Số điện thoại</span>
                        <span
                          className={`pt-icon ${
                            this.state.sort['SoDienThoai'] &&
                            this.state.sort['SoDienThoai'] === 'DESC'
                              ? 'pt-icon-chevron-up'
                              : 'pt-icon-chevron-down'
                          }`}
                          onClick={this.handleClickSort.bind(
                            this,
                            'SoDienThoai'
                          )}
                        ></span>
                      </div>
                      <div className="table-cols-content width-col-30">
                        <span className="head-label">Email</span>
                        <span
                          className={`pt-icon ${
                            this.state.sort['Email'] &&
                            this.state.sort['Email'] === 'DESC'
                              ? 'pt-icon-chevron-up'
                              : 'pt-icon-chevron-down'
                          }`}
                          onClick={this.handleClickSort.bind(this, 'Email')}
                        ></span>
                      </div>
                      <div className="table-cols-content width-col-5"></div>
                    </div>
                  )}
                  {this.props.isLoading ? (
                    <div className="table-loading">
                      <span className="colum-loading">
                        <LoadingItem />
                      </span>
                    </div>
                  ) : (
                    <div className="table-body-container">
                      <SortableList
                        items={this.state.users}
                        onSortEnd={this.handleSort.bind(this)}
                        handleClickPopup={this.handleClickPopup}
                        lockAxis="y"
                        useDragHandle={true}
                        helperClass="SortableHelper"
                      />
                    </div>
                  )}
                </div>
                {this.state.isEmpty &&
                  !this.props.isLoading &&
                  !this.props.isError && (
                    <div className="error-page-container">
                      <div className="error-content">
                        <img
                          src={
                            process.env.PUBLIC_URL +
                            '/images/search-result-not-found.png'
                          }
                          alt="search-result-not-found"
                          className="img-thumb-error"
                        />
                        <p className="description-note">{MESSAGE_NOT_FOUND}</p>
                      </div>
                    </div>
                  )}
                {this.props.isError && !this.props.isLoading && (
                  <div className="error-page-container">
                    <div className="error-content">
                      <img
                        src={process.env.PUBLIC_URL + '/images/Server.png'}
                        alt="error"
                        className="img-thumb-error"
                      />
                      <p className="description-note">{MESSAGE_ERROR}</p>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
        <PopupConfirm
          isOpen={this.state.isOpenPopup}
          onClose={this.handleClickPopup}
          title={POPUP.XOA_NGUOI_DUNG}
          text={POPUP.TEXT_BUTTON_SUBMIT}
          onSubmit={this.handleSubmitRemoveItem}
        >
          {POPUP.TEXT_SUBMIT_BODY}
        </PopupConfirm>
      </div>
    )
  }
}

export default ListDecentralizationUser
