import React, { memo } from 'react'
import { Dialog } from '@blueprintjs/core'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import AvatarEditor from 'react-avatar-editor'
import DatePicker from 'react-datepicker'

import {
  commonAddToasterMessage,
  avatarUpload,
  clearCacheAvatar,
  updateUserProfile,
  getUserProfile,
} from 'actions'
import UIButton from 'components/ui/button'
import UIInput from 'components/ui/input'
import useProfileEdit from './use-profile-edit'
import moment from 'moment'

const ProfileEdit = ({ actions, auth, onClose }) => {
  const {
    errorData,
    avatar,
    refEditor,
    isOpenCropImage,
    formData,
    isLoading,
    handlePositionChangeAvatar,
    handleScaleAvatar,
    handleSaveAvatar,
    onCloseAvatarCrop,
    onChangeForm,
    onChangeFile,
    onSave,
  } = useProfileEdit({
    actions,
    auth,
    onClose,
  })

  return (
    <div className="profile-wrapper">
      <div className="p20">
        <div className="d-flex flex-direction-column align-items-center gap-10">
          <div
            className="avatar"
            style={{ backgroundImage: `url(${avatar?.preview?.img})` }}
          ></div>
          <div className="avatar-change">
            <span className="text-12-500-16 text-uppercase">Chọn ảnh</span>
            <input type="file" onChange={onChangeFile} />
          </div>
        </div>
        <div className="d-flex flex-direction-column gap-15 pv10 mt30">
          <div style={{ display: 'flex', gap: 5, flexDirection: 'column' }}>
            <label
              style={{
                fontSize: 14,
                fontWeight: 600,
                lineHeight: '19px',
              }}
              htmlFor="ngaysinh"
            >
              Ngày sinh
            </label>
            <DatePicker
              selected={formData?.ngaySinh}
              onChange={e => onChangeForm('ngaySinh', e)}
              format="DD/MM/YYYY"
              maxDate={moment()}
              showMonthDropdown
              showYearDropdown
              dropdownMode="select"
              className="input-datepicker input-icon-datepicker"
              placeholderText="Chọn ngày..."
              opperPlacement="auto"
              popperModifiers={{
                offset: {
                  enabled: true,
                  offset: '5px, 10px',
                },
                preventOverflow: {
                  enabled: true,
                  escapeWithReference: false,
                  boundariesElement: 'viewport',
                },
              }}
            />
          </div>
          <UIInput
            label="Email"
            placeholder="Nhập email..."
            maxLength={50}
            error={errorData?.email}
            value={formData?.email || ''}
            onChange={e => onChangeForm('email', e?.target?.value)}
          />
          <UIInput
            label="Số điện thoại"
            placeholder="Nhập số điện thoại..."
            maxLength={50}
            error={errorData?.soDienThoai}
            value={formData?.soDienThoai || ''}
            onChange={e => onChangeForm('soDienThoai', e?.target?.value)}
          />
          <UIInput
            label="Số điện thoại khác"
            placeholder="Nhập số điện thoại..."
            maxLength={50}
            error={errorData?.soDienThoaiKhac}
            value={formData?.soDienThoaiKhac || ''}
            onChange={e => onChangeForm('soDienThoaiKhac', e?.target?.value)}
          />
        </div>
      </div>
      <div
        className={
          'd-flex align-items-center justify-content-center gap-15 bg-white border-radius-4 border-top ph15 pv20'
        }
      >
        <UIButton
          icon="icon-Huy font-size-10"
          text="HỦY"
          theme="grey-border"
          color="text-grey"
          textSize={12}
          isReverse
          isDisabled={isLoading}
          onClick={onClose}
        />
        <UIButton
          icon="icon-save font-size-14"
          text="CẬP NHẬT"
          color="blue"
          textSize={12}
          isDisabled={isLoading}
          isLoading={isLoading}
          onClick={onSave}
        />
      </div>
      <Dialog
        iconName="inbox"
        isOpen={isOpenCropImage}
        onClose={onCloseAvatarCrop}
        title="Chỉnh sửa ảnh đại diện"
      >
        <div className="pt-dialog-body box-dialog-image-body flex-center">
          <AvatarEditor
            ref={refEditor}
            scale={parseFloat(avatar?.scale)}
            width={avatar?.width}
            height={avatar?.height}
            position={avatar?.position}
            disableDrop={true}
            onPositionChange={handlePositionChangeAvatar}
            onSave={handleSaveAvatar}
            image={avatar?.image}
            style={{
              margin: '0 auto',
              border: 0,
              background: 'transparent',
            }}
            border={20}
            color={[10, 30, 50, 0.4]}
          />
          <div className="box-custom-avatar">
            <input
              name="scale"
              type="range"
              className="range"
              onChange={handleScaleAvatar}
              min="1"
              max="2"
              step="0.01"
              defaultValue="1"
            />
          </div>
        </div>
        <div className="d-flex justify-content-center">
          <UIButton
            text="Sử dụng ảnh"
            color="blue"
            textSize={12}
            isDisabled={false}
            isLoading={false}
            onClick={handleSaveAvatar}
          />
        </div>
      </Dialog>
    </div>
  )
}

ProfileEdit.defaultProps = {
  actions: null,
  auth: null,
  onClose: () => {},
}

ProfileEdit.propTypes = {
  actions: PropTypes.object,
  auth: PropTypes.object,
  onClose: PropTypes.func,
}

const mapStateToProps = state => {
  return {
    auth: state.auth,
  }
}

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(
    {
      commonAddToasterMessage,
      avatarUpload,
      clearCacheAvatar,
      updateUserProfile,
      getUserProfile,
    },
    dispatch
  ),
})

export default connect(mapStateToProps, mapDispatchToProps)(memo(ProfileEdit))
