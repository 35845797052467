import React from 'react'
import { Radio } from '@blueprintjs/core'
import { intersection } from 'lodash'
import classnames from 'classnames'

import {
  SidebarCabinet,
  ListFilter,
  TableCabinet,
  ButtonList,
} from 'components/DocumentCabinet'
import { Authorization, Permission } from 'components/auth'
// import { CABINET_UNIT, CABINET_DEPARTMENT, HOSO_CONGVIEC } from 'constants/Api'

// const checkBoxArray = [
//   {
//     id: 'TTL_DV',
//     name: 'Đơn vị',
//     value: CABINET_UNIT,
//     disabled: false,
//   },
//   {
//     id: 'TTL_PB',
//     name: 'Phòng ban',
//     value: CABINET_DEPARTMENT,
//     disabled: false,
//   },
// ]

class TabItem extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      radioData: this.props.type,
    }
  }

  /**
   * Render radio
   * @param {*} array danh sách radio button
   * @returns hiển thị danh sách các radio theo tab
   * @memberof TabItem
   */
  renderRadio(array) {
    return array
      .filter(
        item =>
          (item.id !== null && item.id === this.props.keyTab) ||
          (item.id === null &&
            (this.props.keyTab !== 'TTL_DV' ||
              intersection(this.props.auth.permission, [
                Permission.HSCV_VANTHU,
                Permission.HSCV_VANPHONG_DUYET,
              ]).length !== 0))
      )
      .map((elm, index) => {
        return (
          <Radio
            key={index}
            className="pt-large pt-text"
            label={elm.name}
            value={elm.value}
          />
        )
      })
  }

  /**
   * Change radio button
   * @memberof TabItem
   */
  handleChangeRadio = e => {
    const { target } = e
    this.setState(
      {
        [target.name]: target.value,
      },
      () => {
        this.props && this.props.handleChangeRadio(target.value)
      }
    )
  }

  render() {
    // const { radioData } = this.state
    const type = this.props?.type
    let permission = this.props.actionPermissions
    if (this.props.cabinetPermission.length > 0) {
      permission = this.props.cabinetPermission
    }

    return (
      <div>
        <Authorization.Element permission={this.props.actionPermissions}>
          <ButtonList
            type={type}
            units={this.props.units}
            departmentSelect={this.props.departmentSelect}
            onMultiDelete={this.props.onMultiDelete}
            disableMove={this.props.disableMove}
            disableDelete={this.props.disableDelete}
            onMoveFile={this.props.onMoveFile}
            onDownloadMultipleFiles={this.props.onDownloadMultipleFiles}
            onChangeOwner={this.props.onChangeOwner}
            auth={this.props.auth}
            isLoading={this.props.isLoading}
            isLoadingDownloadFiles={this.props.isLoadingDownloadFiles}
            onSubmitFilter={this.props.onSubmitFilter}
            updateCategories={this.props.updateCategories}
            phongBanId={this.props.phongBanId}
          />
        </Authorization.Element>
        <div className="show-asidenav-moblie">
          <button className="btn-open-aside-nav-rpw">
            <ul className="pt-breadcrumbs">
              <li>
                <span className="text">{this.props.nameTab}</span>
              </li>
              {this.props.tenNganTu && (
                <li>
                  <span className="text">{this.props.tenNganTu}</span>
                </li>
              )}
            </ul>
            <span
              className="icon-right icon-arrow-next"
              onClick={this.props.showContentSideBar}
            ></span>
          </button>
        </div>
        <div
          className={classnames('cols-group-content document-cabinet-content', {
            'show-sidebarcabinet-mobile': this.props.isShowTabItem,
          })}
        >
          <div className="aside-col-content">
            <span className="btn-close" onClick={this.props.showContentSideBar}>
              <span className="icon-close-line"></span>
            </span>
            <SidebarCabinet
              type={type}
              staffs={this.props.staffs}
              nameTab={this.props.nameTab}
              categories={this.props.categories}
              onCategoryClick={this.props.onCategoryClick}
              onSubmit={this.props.onSubmit}
              onSubmitRemoveItem={this.props.onSubmitRemoveItem}
              nganTuId={this.props.nganTuId}
              phongBanId={this.props.phongBanId}
              auth={this.props.auth}
              actionPermissions={permission}
              showContentSideBar={this.props.showContentSideBar}
              actions={this.props.actions}
              pathDocumentCabinet={this.props.pathDocumentCabinet}
            />
          </div>
          <TableCabinet
            {...this.props}
            type={type}
            list={this.props.list}
            onPageClick={this.props.onPageClick}
            onSort={this.props.onSort}
            isLoading={this.props.isLoading}
            onCheck={this.props.onCheck}
            isEmpty={this.props.isEmpty}
            phongBanId={this.props.phongBanId}
            forcePage={this.props.forcePage}
            isError={this.props.isError}
            actionPermissions={this.props.actionPermissions}
            units={this.props.units}
            handleCheckAll={this.props.handleCheckAll}
            listCheckIds={this.props.listCheckIds}
            isCheckAll={this.props.isCheckAll}
            isCheckAllData={this.props.isCheckAllData}
            keyTabTuTaiLieu={this.props.keyTabTuTaiLieu}
            page={this.props.page}
            filter={this.props.filter}
            filterColumn={this.props.filterColumn}
          >
            <ListFilter
              type={type}
              units={this.props.units}
              staffs={this.props.staffs}
              onSubmitFilter={this.props.onSubmitFilter}
              filter={this.props.filter}
              readOnly={this.props.readOnly}
              clearFilterColumn={this.props.clearFilterColumn}
              setFilter={this.props.setFilter}
              nganTuId={this.props.nganTuId}
              isCheckAllData={this.props.isCheckAllData}
              hanldCheckAllData={this.props.hanldCheckAllData}
            />
          </TableCabinet>
        </div>
      </div>
    )
  }
}

TabItem.defaultProps = {
  cabinetPermission: [],
  actionPermissions: [],
}

export default TabItem
