import React, { useEffect, useState, useCallback } from 'react'
import { HeadingPage } from 'components/common'
import { MasterLayout } from 'components/layout'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import * as Actions from 'actions'
import classNames from 'classnames'
import { Intent } from '@blueprintjs/core'
import moment from 'moment'

import Utils from 'helpers/utils'
import * as Types from 'constants/Api'
import * as MESSAGER from 'constants/MessageForm'
import DanhSachLichHop from './DanhSachLichHop'
import LichLamViecQuanLy from './LichLamViecQuanLy'

const LINK = '/lich-hop/quan-ly-lich-hop/them-moi'
const QuanLyLichHopPage = ({ actions, ...props }) => {
  const [tab, setTab] = useState('list')
  const [cuocHopId, setCuocHopId] = useState('')
  const [isOpenPopupConfirm, setIsOpenPopupConfirm] = useState(false)
  const [isOpenPopupConfirmCancel, setIsOpenPopupConfirmCancel] =
    useState(false)
  const [isOpenPopupKetThuc, setIsOpenPopupKetThuc] = useState(false)
  const [page, setPage] = useState(1)
  const [filter, setFilter] = useState({
    fromDate: undefined,
    toDate: undefined,
    theLoaiId: undefined,
    mucDoId: undefined,
    chuTriId: undefined,
    trangThai: undefined,
    textSearch: undefined,
  })

  const [isError, setIsError] = useState(false)
  const [isLoading, setIsLoading] = useState(false)

  const handleChangeTab = key => {
    setTab(key)
  }

  const handleClickPopup = id => {
    setCuocHopId(id)
    setIsOpenPopupConfirm(!isOpenPopupConfirm)
  }

  const handleClickPopupCancel = id => {
    setCuocHopId(id)
    setIsOpenPopupConfirmCancel(!isOpenPopupConfirmCancel)
  }

  const handleClickPopupKetThuc = id => {
    setCuocHopId(id)
    setIsOpenPopupKetThuc(!isOpenPopupKetThuc)
  }

  const handleGetDsLichHop = useCallback(
    (page = 1, params = null) => {
      setIsLoading(true)
      let paramsTemp = params
      let pageTemp = page
      const filterStore = Utils.preData('danh-sach-lich-hop')
      const pageStore = Utils.preData('danh-sach-lich-hop-page')
      if (filterStore && !params) {
        paramsTemp = filterStore
      }

      if (pageStore && !params) {
        pageTemp = pageStore
      }

      const newParams = {
        textSearch: paramsTemp?.textSearch
          ? paramsTemp.textSearch.trim()
          : undefined,
        fromDate: paramsTemp?.fromDate
          ? moment(paramsTemp.fromDate).format('YYYY-MM-DD')
          : null,
        toDate: paramsTemp?.toDate
          ? moment(paramsTemp.toDate).format('YYYY-MM-DD')
          : null,
        theLoaiId: paramsTemp?.theLoaiId || undefined,
        mucDoId: paramsTemp?.mucDoId || undefined,
        chuTriId: paramsTemp?.chuTriId || undefined,
        trangThai: paramsTemp?.trangThai || undefined,
        page: pageTemp,
        limit: 10,
      }

      actions
        .getDanhSachLichHop(newParams)
        .then(res => {
          Utils.preData('danh-sach-lich-hop', paramsTemp)
          Utils.preData('danh-sach-lich-hop-page', pageTemp)
          setPage(pageTemp)
          setFilter(paramsTemp)
          if (res.error || (res.payload && res.payload.status !== 200)) {
            setIsError(true)
          }
        })
        .finally(() => {
          setIsLoading(false)
        })
    },
    [actions]
  )

  const handleChangeFilter = useCallback(
    (name, value, isReload = true) => {
      let temp = filter
      temp = {
        ...temp,
        [name]: value,
      }

      Promise.all([setFilter(temp)]).then(() => {
        if (!isReload) {
          return
        }

        return handleGetDsLichHop(1, temp)
      })
    },
    [filter, handleGetDsLichHop]
  )

  const handleClearFilter = () => {
    const onReset = () => {
      Utils.preData('danh-sach-lich-hop')
      Utils.preData('danh-sach-lich-hop-page')
      setFilter({
        fromDate: undefined,
        toDate: undefined,
        theLoaiId: '',
        mucDoId: '',
        chuTriId: '',
        trangThai: '',
        textSearch: '',
      })
    }
    Promise.all([onReset()]).then(() => {
      handleGetDsLichHop()
    })
  }

  const handleClickSearch = useCallback(() => {
    return handleGetDsLichHop(1, filter)
  }, [filter, handleGetDsLichHop])

  const handleClickPage = useCallback(
    page => {
      let temp = filter
      Utils.preData('danh-sach-lich-hop-page')
      return handleGetDsLichHop(page, temp)
    },
    [filter, handleGetDsLichHop]
  )

  const handleSubmitRemoveItem = () => {
    if (cuocHopId) {
      actions.deleteLichHop(cuocHopId).then(res => {
        if (res.payload && res.payload.data.result === true) {
          handleGetDsLichHop()
          return actions.commonAddToasterMessage({
            message: MESSAGER.TOATS_MESSAGE_DELETE_SUCCESS,
            intent: Intent.SUCCESS,
          })
        } else {
          actions.commonAddToasterMessage({
            message: MESSAGER.TOATS_MESSAGE_DELETE_FAIL,
            intent: Intent.DANGER,
          })
        }
      })
    }
    setIsOpenPopupConfirm(false)
  }

  const handleSubmitCancelItem = () => {
    if (cuocHopId) {
      actions.putHuyLichHop(cuocHopId).then(res => {
        if (res.payload && res.payload.data.result === true) {
          handleGetDsLichHop()
          return actions.commonAddToasterMessage({
            message: MESSAGER.TOATS_CANCLE_SUCCESS,
            intent: Intent.SUCCESS,
          })
        } else {
          actions.commonAddToasterMessage({
            message: MESSAGER.TOATS_CANCLE_FAIL,
            intent: Intent.DANGER,
          })
        }
      })
    }
    setIsOpenPopupConfirmCancel(false)
  }

  const handleSubmitKetThucItem = () => {
    if (cuocHopId) {
      actions.putKetThucCuocHop(cuocHopId).then(res => {
        if (res.payload && res.payload.data.result === true) {
          handleGetDsLichHop()
          return actions.commonAddToasterMessage({
            message: MESSAGER.TOATS_STOP_SUCCESS,
            intent: Intent.SUCCESS,
          })
        } else {
          actions.commonAddToasterMessage({
            message: MESSAGER.TOATS_STOP_FAIL,
            intent: Intent.DANGER,
          })
        }
      })
    }
    setIsOpenPopupKetThuc(false)
  }

  const handleSubmitGuiCuocHop = id => {
    if (id) {
      actions.putGuiLichHop(id).then(res => {
        if (res.payload && res.payload.data.result === true) {
          handleGetDsLichHop()
          return actions.commonAddToasterMessage({
            message: MESSAGER.TOATS_SEND_MAIL_SUCCESS,
            intent: Intent.SUCCESS,
          })
        } else {
          actions.commonAddToasterMessage({
            message:
              res?.error?.response?.data?.message ||
              MESSAGER.TOATS_SEND_MAIL_FAIL,
            intent: Intent.DANGER,
          })
        }
      })
    }
  }

  const renderContentTab = tab => {
    if (tab === 'calendar') {
      return <LichLamViecQuanLy type="QUAN_LY" history={props.history} />
    } else {
      return (
        <DanhSachLichHop
          page={page}
          listData={props?.dsLichHop?.items}
          dsLoaiCuocHopCombobox={props?.dsLoaiCuocHopCombobox}
          dsCapDoCuocHopCombobox={props?.dsCapDoCuocHopCombobox}
          isError={isError}
          isLoading={isLoading}
          filter={filter}
          total={Math.ceil(props?.dsLichHop?.total / Types.PAGINATION_LIMIT)}
          textSearch={filter?.textSearch}
          handleClickPage={handleClickPage}
          handleChangeFilter={handleChangeFilter}
          hanldeChangeTextSearch={value =>
            handleChangeFilter('textSearch', value, false)
          }
          handleClearFilter={handleClearFilter}
          handleClickSearch={handleClickSearch}
          handleClickPopup={handleClickPopup}
          handleClickPopupCancel={handleClickPopupCancel}
          handleSubmitRemoveItem={handleSubmitRemoveItem}
          handleSubmitCancelItem={handleSubmitCancelItem}
          handleSubmitKetThucItem={handleSubmitKetThucItem}
          isOpenPopupConfirm={isOpenPopupConfirm}
          isOpenPopupConfirmCancel={isOpenPopupConfirmCancel}
          handleSubmitGuiCuocHop={handleSubmitGuiCuocHop}
          handleClickPopupKetThuc={handleClickPopupKetThuc}
          isOpenPopupKetThuc={isOpenPopupKetThuc}
          history={props.history}
        />
      )
    }
  }

  useEffect(() => {
    handleGetDsLichHop()
  }, [handleGetDsLichHop])

  useEffect(() => {
    actions.getDanhSachLoaiCuocHop({ isActive: true }).then(res => {
      if (res.error || (res.payload && res.payload.status !== 200)) {
        setIsError(true)
        setIsLoading(false)
      }
    })
    actions.getDanhSachCapDoCuocHop({ isActive: true }).then(res => {
      if (res.error || (res.payload && res.payload.status !== 200)) {
        setIsError(true)
        setIsLoading(false)
      }
    })
  }, [actions])

  return (
    <MasterLayout typeSidebar="sidebarCalendar">
      <HeadingPage
        namePage="Quản lý lịch họp"
        iconPage="icon2-date"
        showButton={true}
        linkButton={LINK}
        nameButton="Tạo lịch"
      />
      <div className="meeting-calendar" style={{ border: 'unset' }}>
        <div className="meeting-tabs">
          <div
            className={classNames('meeting-item', { active: tab === 'list' })}
            onClick={() => handleChangeTab('list')}
          >
            <span className="meeting-item__icon pt-icon icon-list"></span>
            <span className="meeting-item__text">DANH SÁCH</span>
          </div>
          <div
            className={classNames('meeting-item', {
              active: tab === 'calendar',
            })}
            onClick={() => handleChangeTab('calendar')}
          >
            <span className="meeting-item__icon pt-icon icon-calendar-3"></span>
            <span className="meeting-item__text">LỊCH THÁNG</span>
          </div>
        </div>
        <div className="meeting-tabs-content">{renderContentTab(tab)}</div>
      </div>
    </MasterLayout>
  )
}

const mapStateToProps = state => ({
  dsLichHop: {
    ...state.dsLichHop,
    page: state.lichHop.dsLichHop?.page,
    items: state.lichHop.dsLichHop?.items,
    total: state.lichHop.dsLichHop?.total,
  },
  dsLoaiCuocHopCombobox: state?.lichHop?.dsLoaiCuocHop?.map(item => ({
    value: item?.theLoaiId,
    name: item?.ten,
  })),
  dsCapDoCuocHopCombobox: state?.lichHop?.dsCapDoCuocHop?.map(item => ({
    value: item?.mucDoId,
    name: item?.ten,
  })),
})

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(Actions, dispatch),
})

export default connect(mapStateToProps, mapDispatchToProps)(QuanLyLichHopPage)
