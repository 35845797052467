import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Radio, RadioGroup, Button } from '@blueprintjs/core'

import { SidebarCabinet } from '../../components/DocumentCabinet'
import { Permission, Authorization } from '../auth'
import * as Types from '../../constants/Api'
import * as Tool from '../../helpers'

class MoveDocumentsCabinet extends Component {
  constructor(props) {
    super(props)
    this.state = {
      isLoading: false,
      form: {
        tuTaiLieuChuyenDen: this.props.type.toString(),
        loaiDiChuyen: '0',
        dsNganTu: [],
      },
    }
    this.handleInputChange = this.handleInputChange.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
    this.handleCheckCategory = this.handleCheckCategory.bind(this)
  }

  handleInputChange(e) {
    const target = e.target
    const value = target.type === 'checkbox' ? target.checked : target.value
    const name = target.name
    this.setState(
      {
        form: {
          ...this.state.form,
          [name]: value,
        },
      },
      () => {
        if (name === 'tuTaiLieuChuyenDen') {
          this.props.onChangeType && this.props.onChangeType(value)
        }
      }
    )
  }

  handleCheckCategory(data) {
    this.setState({
      form: {
        ...this.state.form,
        dsNganTu: data,
      },
    })
  }

  async handleSubmit() {
    this.setState({ isLoading: true })
    await this.props.onSubmit(this.state.form)
    this.setState({ isLoading: false })
  }

  getTypeName(type) {
    let typeName
    switch (type) {
      case Types.CABINET_PERSONAL:
        typeName = 'TTL CÁ NHÂN'
        break
      case Types.CABINET_DEPARTMENT:
        typeName = 'TTL PHÒNG BAN'
        break
      default:
        typeName = 'TTL CÔNG TY'
    }

    return typeName
  }

  render() {
    let { items, type, defaultType } = this.props
    let typeName = this.getTypeName(type)
    let defaultTypeName = this.getTypeName(defaultType)
    let disableIds = items.map(item => {
      return item.nganTuId
    })
    const token =
      this.props.auth &&
      this.props.auth.token &&
      this.props.auth.token.accessToken
        ? this.props.auth.token.accessToken
        : ''
    return (
      <div className="wrapper-box-container addDocumentsCabinet">
        <div className="container-box-addfile">
          <div className="box-action-detail">
            <div className="container-form-action">
              <div className="heading">
                <span className="click-go-back" onClick={this.props.goBack}>
                  <span className="pt-icon pt-icon-arrow-left pull-left"></span>
                </span>
                <h4 className="name-heading">Di chuyển</h4>
              </div>
              <div className="row section-content">
                <div className="col-left-form form-container">
                  {items.map((item, k) => {
                    let extension =
                      item &&
                      item.fileNoiDung &&
                      item.fileNoiDung.tenFile &&
                      item.fileNoiDung.tenFile
                        .slice(
                          ((item.fileNoiDung.tenFile.lastIndexOf('.') - 1) >>>
                            0) +
                            2
                        )
                        .toLowerCase()
                    let data = null
                    if (item.fileNoiDung) {
                      data = (
                        <div className="pt-form-group" key={k}>
                          <div className="row item-row-input">
                            <div className="col-md-12 col-xs-12 col-sm-12 form-inline-group">
                              <label className="pt-label label-inline-input">
                                File {k + 1}
                              </label>
                              <div className="pt-form-content">
                                <span className="detail-pdf-item">
                                  <span className="name_file">
                                    {!item.fileNoiDung.migrated && (
                                      <a
                                        style={{
                                          backgroundImage: `url(${Tool.Utils.typeFile(
                                            extension
                                          )})`,
                                        }}
                                        className="link-file"
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        href={`${Types.FILE_URL}/api/file/get/${item.fileNoiDung.fileId}?BearerToken=${token}`}
                                      >
                                        {item.fileNoiDung.tenFile} -{' '}
                                        {item.fileNoiDung.kichThuocStr}
                                      </a>
                                    )}
                                  </span>
                                  <span className="path-save">
                                    ({`Bạn đang lưu ở ${defaultTypeName}`})
                                  </span>
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>
                      )
                    }
                    return data
                  })}
                  <div className="pt-form-group">
                    <div className="row item-row-input">
                      <div className="col-md-6 col-xs-12 col-sm-6 form-inline-group">
                        <label className="pt-label label-inline-input">
                          Di chuyển đến
                        </label>
                        <div className="pt-form-content">
                          <RadioGroup
                            name="tuTaiLieuChuyenDen"
                            className="radio-group"
                            onChange={this.handleInputChange}
                            selectedValue={this.state.form.tuTaiLieuChuyenDen}
                          >
                            <Radio
                              className="pt-large item-radio"
                              disabled={
                                !Authorization.vaild(
                                  this.props.auth.permission,
                                  [Permission.TUTAILIEUDONVI_QUANLY]
                                )
                              }
                              label="TTL CÔNG TY"
                              value="1"
                            />
                            <Radio
                              className="pt-large item-radio"
                              disabled={
                                true
                                // !Authorization.vaild(this.props.auth.permission, [Permission.TUTAILIEUPHONGBAN])
                              }
                              label="TTL PHÒNG BAN"
                              value="2"
                            />
                            <Radio
                              className="pt-large item-radio"
                              label="TTL CÁ NHÂN"
                              value="3"
                            />
                          </RadioGroup>
                        </div>
                      </div>
                      <div className="col-md-6 col-xs-12 col-sm-6 form-inline-group">
                        <label className="pt-label label-inline-input">
                          Tuỳ chọn
                        </label>
                        <div className="pt-form-content">
                          <RadioGroup
                            name="loaiDiChuyen"
                            className="radio-group"
                            onChange={this.handleInputChange}
                            selectedValue={this.state.form.loaiDiChuyen}
                          >
                            <Radio
                              className="pt-large item-radio"
                              label="Copy"
                              value="0"
                            />
                            <Radio
                              className="pt-large item-radio"
                              label="Di chuyển"
                              value="1"
                            />
                          </RadioGroup>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-right-form document-cabinet-content">
                  <SidebarCabinet
                    disableIds={disableIds}
                    mutilSelect
                    nameTab={typeName}
                    auth={this.props.auth}
                    typeName={type}
                    categories={this.props.categories}
                    onCheckCategory={this.handleCheckCategory}
                    listDisable={[]}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="buttton-action-footer">
            <Button
              className="pt-button btn-cancel"
              onClick={this.props.goBack}
            >
              <span className=" pt-icon icon-back"></span>
              <span className="text-content">Quay lại</span>
            </Button>
            <Button
              className="pt-button btn-blue-color"
              onClick={this.handleSubmit}
              loading={this.state.isLoading}
            >
              <span className="text-content">Lưu</span>
              <span className="pt-icon icon-save"></span>
            </Button>
          </div>
        </div>
      </div>
    )
  }
}

MoveDocumentsCabinet.propTypes = {
  items: PropTypes.array.isRequired,
  categories: PropTypes.array.isRequired,
}

MoveDocumentsCabinet.defaultProps = {
  auth: {},
  items: [],
  categories: [],
  type: Types.HOSO_CONGVIEC,
}

export default MoveDocumentsCabinet
