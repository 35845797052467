import React, { useMemo } from 'react'
import PropTypes from 'prop-types'

const UIUser = ({ images, name, htmlName, date, hasAvatar }) => {
  const textAvatar = useMemo(() => {
    if (images || !name) {
      return ''
    }

    const arr = name.split(' ')
    const last = arr[arr.length - 1]
    return last.slice(0, 1).toUpperCase()
  }, [images, name])

  return (
    <div className="ui-user">
      {hasAvatar && (
        <div className="image" style={{ backgroundImage: `url('${images}')` }}>
          {textAvatar}
        </div>
      )}
      <div className="infos">
        {htmlName ? htmlName : name ? <p className="name">{name}</p> : ''}
        {date ? <span className="date">{date}</span> : ''}
      </div>
    </div>
  )
}

UIUser.defaultProps = {
  hasAvatar: true,
  images: '',
  name: '',
  htmlName: null,
  date: '',
}

UIUser.propTypes = {
  hasAvatar: PropTypes.bool,
  images: PropTypes.string,
  name: PropTypes.string,
  htmlName: PropTypes.element,
  date: PropTypes.string,
}

export default UIUser
