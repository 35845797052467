import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { Scrollbars } from 'react-custom-scrollbars'

import { getLuongVanBanDen, getXemthuchien, getLuongVanBanDi } from 'actions'
import { LoadingItem } from 'components/common'
import DocumentFlowItem from './item'
import UseDocumentFlow from './use-document-flow'

const DocumentFlow = ({
  actions,
  congVanDenId,
  congVanDiId,
  autoHeightMax,
}) => {
  const { isLoading, data, mission } = UseDocumentFlow({
    actions,
    congVanDenId,
    congVanDiId,
  })

  return (
    <Scrollbars
      autoHideTimeout={0}
      autoHideDuration={0}
      autoHeight
      autoHeightMin={0}
      autoHeightMax={autoHeightMax}
      // autoHide
    >
      <div className="document-flow">
        {isLoading && <LoadingItem />}
        {data.map((element, index) => (
          <DocumentFlowItem
            key={index}
            dataSource={element}
            mission={mission}
          />
        ))}
      </div>
    </Scrollbars>
  )
}

DocumentFlow.propTypes = {
  actions: PropTypes.object,
  autoHeightMax: PropTypes.number,
}

DocumentFlow.defaultProps = {
  actions: null,
  autoHeightMax: 500,
}

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(
    { getLuongVanBanDen, getXemthuchien, getLuongVanBanDi },
    dispatch
  ),
})

export default connect(null, mapDispatchToProps)(DocumentFlow)
