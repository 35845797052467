import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { LoginForm } from '../../components/auth'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import {
  VERSION,
  COPYRIGHT,
  SUMARY,
  FOLDER_NAME,
  COMPANY_NAME,
} from '../../constants/Api'
import * as AuthActions from '../../actions'

class Login extends Component {
  static propTypes = {
    actions: PropTypes.object.isRequired,
  }

  render() {
    const bg = `/config/${FOLDER_NAME}/images/background-login.png`
    const logo = `/config/${FOLDER_NAME}/images/logo.svg`
    return (
      <div
        className="page-login-wrapper"
        style={{
          backgroundImage: `url('${bg}')`,
        }}
      >
        <div className="page-login-block">
          <h1 className="page-login-logo">
            <img src={logo} alt={COMPANY_NAME} />
          </h1>
          <p className="page-login-sumary">
            <span>{VERSION}</span>
            <span>{SUMARY}</span>
          </p>
          <LoginForm actions={this.props.actions} />
        </div>
        <div>
          <div className="page-login-copyright">{COPYRIGHT}</div>
          <p className="page-login-policy">
            <a href="/policy.html" target="_blank" className="font-size-15">
              Chính sách bảo mật
            </a>
          </p>
        </div>
      </div>
    )
  }
}

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(AuthActions, dispatch),
})

export default connect(null, mapDispatchToProps)(Login)
