import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Scrollbars } from 'react-custom-scrollbars'
import classnames from 'classnames'
import { get, size, merge } from 'lodash'
import { Radio, RadioGroup, Button } from '@blueprintjs/core'

import { CabinetTree, JobProjectTree } from '../common'
import * as MESSAGE from '../../constants/MessageForm'
import * as Types from '../../constants/Api'
import { FileList } from '../../components/common'
import * as Tool from '../../helpers'
// import { Async } from 'react-select';

class SaveDocument extends Component {
  constructor(props) {
    super(props)
    this.state = {
      params: {
        categoryType: Types.CABINET_PERSONAL,
        nganTuIds: [],
        tenTaiLieu: '',
        ghiChu: '',
        dsFileDinhKem: [],
      },
      isCollapse: false,
      isOpenPopup: false,
      isLoading: false,
      isUploadProgressFile: false,
      fileDinhKemArray: [{}],
      dinhKemFiles: [],
      dinhKemFileNames: [],
      fileDinhKemTuCongViecPb: [],
      sizeDinhKemFiles: [],
      errorsDinhKemFiles: [],
    }
    this.handleSubmit = this.handleSubmit.bind(this)
    this.handleAreaChange = this.handleAreaChange.bind(this)
    this.handleCheck = this.handleCheck.bind(this)
  }

  componentDidMount() {
    this.handleRadio({ target: { value: this.state.params.categoryType } })
    if (this.state.params.tenTaiLieu === '') {
      this.setState({
        params: {
          ...this.state.params,
          tenTaiLieu: get(this.props.receiveDocument.item, 'trichYeu', ''),
        },
      })
    }
  }

  handleSubmit = () => {
    if (!this.props.onSubmit) {
      return
    }

    if (!this.state.params.categoryType) {
      return this.props.onSubmit(MESSAGE.CHON_NGAN_TU)
    }

    if (this.state.params.nganTuIds.length < 1) {
      return this.props.onSubmit(MESSAGE.CHON_NGAN_TU)
    }

    this.setState({ isLoading: true })
    this.props.onSubmit &&
      this.props
        .onSubmit(false, {
          ...this.state.params,
          files: this.state.dinhKemFiles,
        })
        .then(() => {
          this.setState({ isLoading: false })
        })
  }

  handleRadio = e => {
    this.setState({
      params: {
        ...this.state.params,
        categoryType: e.target.value,
        nganTuIds: [],
      },
    })
    this.props.onSelectSaveTo && this.props.onSelectSaveTo(e.target.value)
  }

  handleCheck(nganTuIds) {
    this.setState({
      params: {
        ...this.state.params,
        nganTuIds: nganTuIds,
      },
    })
  }

  handleAreaChange = e => {
    this.setState({
      params: {
        ...this.state.params,
        [e.target.name]: e.target.value,
      },
    })
  }

  /**
   * Change file input
   * @param {*} e this.file
   * @param {*} i number file
   * @memberof SaveDocument
   */
  handleFileInput = (e, i) => {
    const [name, id] = e.target.name.split('-')
    let state = this.state
    if (name === 'dinhKemFiles') {
      state.errorsDinhKemFiles.splice(i, 1)
      let reader = new FileReader()
      let file = e.target.files[0]
      const dinhKemFiles = e.target.value
      const extension =
        dinhKemFiles &&
        dinhKemFiles
          .slice(((dinhKemFiles.lastIndexOf('.') - 1) >>> 0) + 2)
          .toLowerCase()
      let sizeAttachedFile = file && file.size
      const extensionSupportArray = [
        'pdf',
        'doc',
        'docx',
        'xls',
        'xlsx',
        'ppt',
        'pptx',
        'ppsx',
        'pps',
      ]
      if (extensionSupportArray.indexOf(extension) === -1) {
        state.errorsDinhKemFiles[i] = `Hãy chọn file có định dạng
        '.pdf', '.doc', '.docx', '.xls', '.xlsx', '.ppt', '.pptx', '.ppsx', '.pps'.`
      } else if (sizeAttachedFile && sizeAttachedFile > 1024 * 1024 * 25) {
        state.errorsDinhKemFiles[i] = MESSAGE.TEXT_MAXIMUM_SIZE_FILE
      }

      this.setState(state)
      if (!state.errorsDinhKemFiles[i]) {
        reader.onloadend = () => {
          state[name][id] = file
          state.sizeDinhKemFiles[i] = file.size
          state.dinhKemFileNames[i] = file.name
          state.params.dsFileDinhKem[i] = null
          state.fileDinhKemTuCongViecPb[i] = null
          this.setState(state)
        }
      }

      if (file) {
        reader.readAsDataURL(file)
      }
    }
  }

  /**
   * Deleted file input
   * @param {*} i number file
   * @memberof SaveDocument
   */
  handleEventRemoveFile = i => {
    const state = this.state
    if (i === 0 && state.fileDinhKemArray.length === 1) {
      state.fileDinhKemArray[i] = ''
    } else {
      state.fileDinhKemArray.splice(i, 1)
    }

    state.dinhKemFiles.splice(i, 1)
    state.errorsDinhKemFiles.splice(i, 1)
    state.params.dsFileDinhKem.splice(i, 1)
    state.dinhKemFileNames.splice(i, 1)
    state.sizeDinhKemFiles.splice(i, 1)
    state.fileDinhKemTuCongViecPb.splice(i, 1)
    this.setState(state)
  }

  /**
   * Kích hoạt nút thêm files
   * @memberof SaveDocument
   */
  handleEventAddFile = () => {
    this.setState({
      fileDinhKemArray: this.state.fileDinhKemArray.concat(
        this.state.fileDinhKemArray.length + 1
      ),
    })
  }

  render() {
    const { params } = this.state
    const { auth } = this.props
    const congVanData = get(this.props, 'receiveDocument.item', {})
    const token = auth?.token?.accessToken ? auth.token.accessToken : ''
    const fileNoiDungFull =
      this.props.receiveDocument?.item?.fileNoiDung ||
      this.props.receiveDocument?.item?.fileCongVan
    const extension = fileNoiDungFull?.tenFile
      ? fileNoiDungFull.tenFile
          .slice(((fileNoiDungFull.tenFile.lastIndexOf('.') - 1) >>> 0) + 2)
          .toLowerCase()
      : ''
    return (
      <div className="container-form-action">
        <div className="heading">
          <span className="click-go-back" onClick={this.props.onClickGoBack}>
            <span className="pt-icon pt-icon-arrow-left pull-left"></span>
          </span>
          <h4 className="name-heading">Lưu tủ tài liệu</h4>
        </div>
        <div className="form-container documents-cabinet">
          <div className="checkbox-document mb0">
            <div className="row">
              <div className="col-md-5 col-sm-5 col-xs-12 checkbox-document-left">
                <Scrollbars
                  autoHide
                  autoHideTimeout={100}
                  autoHeight
                  autoHeightMin={0}
                  autoHeightMax={324}
                  autoHideDuration={200}
                >
                  <div className="type-document-list">
                    <RadioGroup
                      label="Lưu vào"
                      onChange={this.handleRadio.bind(this)}
                      selectedValue={this.state.params.categoryType}
                    >
                      {this.props.saveTo.map((item, i) => (
                        <Radio
                          key={i}
                          className="pt-large pt-text"
                          label={item.name}
                          value={item.id}
                          disabled={item.disabled}
                        />
                      ))}
                    </RadioGroup>
                  </div>
                </Scrollbars>
              </div>
              <div className="col-md-7 col-sm-7 col-xs-12 checkbox-document-right">
                {params.categoryType === Types.HOSO_CONGVIEC ? (
                  <JobProjectTree
                    data={this.props.archives}
                    listDisable={this.props.listCabinetOfDocument}
                    onCheck={this.handleCheck}
                    auth={auth}
                  />
                ) : (
                  <CabinetTree
                    data={this.props.archives}
                    listDisable={this.props.listCabinetOfDocument}
                    onCheck={this.handleCheck}
                  />
                )}
              </div>
            </div>
          </div>

          <div className="p10">
            <div className="pt-form-group mb0">
              <label className="pt-label m0 p0">Ghi chú</label>
              <div className="pt-form-content">
                <textarea
                  rows="1"
                  className="pt-input pt-fill"
                  placeholder="Nhập nội dung…"
                  dir="auto"
                  name="ghiChu"
                  onChange={this.handleAreaChange}
                  defaultValue={this.state.params.ghiChu}
                />
              </div>
            </div>

            {!fileNoiDungFull?.migrated && (
              <div className="mt15">
                <label className="pt-label m0 p0">File nội dung:</label>
                <div className="pt-form-content">
                  <div className="pt-form-content">
                    <span className="info">
                      <a
                        style={{
                          backgroundImage: `url(${Tool.Utils.typeFile(
                            extension
                          )})`,
                        }}
                        className="link-file"
                        target="_blank"
                        rel="noopener noreferrer"
                        href={`${Types.FILE_URL}/api/file/get/${fileNoiDungFull.fileId}?BearerToken=${token}`}
                      >
                        {fileNoiDungFull.tenFile +
                          ' - ' +
                          Tool.Utils.getFileSize(fileNoiDungFull.kichThuoc)}
                      </a>
                    </span>
                  </div>
                </div>
              </div>
            )}

            {!!size(
              merge(congVanData.fileDinhKem, congVanData.fileThamKhao)
            ) && (
              <div>
                {!!size(congVanData.fileDinhKem) && (
                  <div className="mt15">
                    <label className="pt-label m0 p0">File đính kèm:</label>
                    <div className="pt-form-content">
                      <FileList
                        auth={auth}
                        receiveDocument={congVanData}
                        field="fileDinhKem"
                      />
                    </div>
                  </div>
                )}

                {Tool.Utils.checkBanTongHop(auth.mainDepartmentId) &&
                  this.props.filesThamKhao &&
                  this.props.filesThamKhao.length !== 0 && (
                    <div className="mt15">
                      <label className="pt-label m0 p0 label-text-detail">
                        File tham khảo ({this.props.filesThamKhao.length}):
                      </label>
                      <div className="pt-form-content">
                        <FileList
                          auth={auth}
                          receiveDocument={{
                            fileThamKhao: this.props.filesThamKhao,
                          }}
                          field="fileThamKhao"
                        />
                      </div>
                    </div>
                  )}
              </div>
            )}
          </div>

          {params.categoryType === Types.HOSO_CONGVIEC && (
            <div className="form-save-document-content pt-form-content">
              <label className="pt-label">Thêm file đính kèm</label>
              {this.state.fileDinhKemArray.length > 0 &&
                this.state.fileDinhKemArray.map((item, i) => (
                  <div
                    key={i}
                    className={classnames('pt-form-group pt-intent-danger', {
                      'upload-have-close':
                        i > 0 ||
                        this.state.dinhKemFiles[0] ||
                        (this.state.params.dsFileDinhKem &&
                          this.state.params.dsFileDinhKem[0]),
                    })}
                  >
                    <label className="pt-file-upload">
                      <input
                        type="file"
                        name={`dinhKemFiles-${i}`}
                        onChange={e => this.handleFileInput(e, i)}
                        onClick={event => {
                          event.target.value = null
                        }}
                        disabled={
                          this.state.isUploadProgressFile ||
                          this.state.isLoading
                        }
                      />
                      <span className="pt-file-upload-input">
                        {this.state.dinhKemFileNames &&
                        this.state.dinhKemFileNames[i]
                          ? this.state.dinhKemFileNames[i]
                          : 'Chọn file đính kèm'}
                      </span>
                    </label>
                    {(i > 0 ||
                      (i === 0 &&
                        (this.state.fileDinhKemTuCongViecPb[i] ||
                          this.state.dinhKemFiles[0] ||
                          (this.state.params.dsFileDinhKem &&
                            this.state.params.dsFileDinhKem[0])))) &&
                      !this.state.isLoading && (
                        <span
                          onClick={() => this.handleEventRemoveFile(i)}
                          className="icon-bc icon-close icon-remove-file z-index-1"
                        />
                      )}
                    {this.state.sizeDinhKemFiles[i] !== undefined && (
                      <p className="pt-form-helper-text pt-form-helper-infomation">
                        Kích thước file đã upload:
                        <span className="size-file">
                          {' '}
                          {Tool.Utils.getFileSize(
                            this.state.sizeDinhKemFiles[i]
                          )}
                        </span>
                      </p>
                    )}
                    {this.state.errorsDinhKemFiles[i] !== undefined && (
                      <div className="pt-form-helper-text">
                        {this.state.errorsDinhKemFiles[i]}
                      </div>
                    )}
                    {this.state.isLoading &&
                      !!this.props.dinhKemFilesProgress &&
                      this.props.dinhKemFilesProgress[i] > 0 && (
                        <div className="progress-upload-content">
                          <span className="percent-content">
                            {this.props.dinhKemFilesProgress[i]}%
                          </span>
                          <div className="pt-progress-bar pt-intent-primary progress-bar-upload">
                            <div
                              className="pt-progress-meter"
                              style={{
                                width: this.props.dinhKemFilesProgress[i] + '%',
                              }}
                            />
                          </div>
                        </div>
                      )}
                    {/* {
                    !(this.state.dinhKemFiles[i]
                    || (this.state.params.dsFileDinhKem && this.state.params.dsFileDinhKem[i]) || this.state.isLoading) &&
                    <div className={`pt-form-content select-custom-input
                    select-menu-outer-z-index-3 select-hide-clear-btn
                    select-custom-absolute-icon select2-custom-iconsearch
                    position-absolute top-0 left-0 right-70px border-right-radius-0 height-39px`}
                    >
                      <Async
                        name="form-field-name"
                        value={this.state.fileDinhKemTuCongViecPb[i]}
                        loadOptions={this.getOptions}
                        onChange={(val) => this.handleSelect(val, i, true)}
                        onCloseResetsInput={false}
                        onBlurResetsInput={false}
                        autoload={false}
                        placeholder="Nhập số công văn hoặc trích yếu"
                        searchPromptText="Nhập số công văn hoặc trích yếu...."
                        loadingPlaceholder="Đang tìm kiếm...."
                        noResultsText="Không tìm thấy dữ liệu..."
                        arrowRenderer={()=>{}}
                        ignoreAccents={false}
                        disabled={this.state.isUploadProgressFile || this.state.isLoading}
                      />
                    </div>
                  } */}
                  </div>
                ))}
              <Button
                className="btn-add-file mt0 mr10"
                onClick={() => this.handleEventAddFile()}
                disabled={
                  this.state.isUploadProgressFile || this.state.isLoading
                }
              >
                <span>Thêm file</span>
                <span className="icon-bc icon-Plus"></span>
              </Button>
            </div>
          )}
        </div>
        {!this.props.status && (
          <div className="buttton-actionfooter-fixtop buttton-action-footer">
            <Button
              className="pt-button btn-blue-color"
              onClick={this.handleSubmit}
              loading={this.state.isLoading || false}
            >
              <span className="text-content">Lưu tủ tài liệu</span>
              <span className="pt-icon icon-Tu_Tai_Lieu"></span>
            </Button>
            <Button
              onClick={this.props.onClickGoBack}
              className="pt-button"
              disabled={this.state.isLoading === true ? true : false}
            >
              <span className="text-content">Hủy</span>
              <span className="pt-icon icon-close-line"></span>
            </Button>
          </div>
        )}
        <div className="buttton-action-footer">
          <Button
            onClick={this.props.onClickGoBack}
            className="pt-button"
            disabled={this.state.isLoading === true ? true : false}
          >
            <span className="text-content">Hủy</span>
            <span className="pt-icon icon-close-line"></span>
          </Button>
          <Button
            onClick={this.handleSubmit}
            className="pt-button btn-blue-color"
            loading={this.state.isLoading || false}
          >
            <span className="text-content">Lưu tủ tài liệu</span>
            <span className="pt-icon icon-Tu_Tai_Lieu"></span>
          </Button>
        </div>
      </div>
    )
  }
}

SaveDocument.propTypes = {
  file: PropTypes.object.isRequired,
  receiveDocument: PropTypes.object.isRequired,
  archives: PropTypes.array.isRequired,
  listCabinetOfDocument: PropTypes.array,
  saveTo: PropTypes.array.isRequired,
  onClickGoBack: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
}

export default SaveDocument
