import React, { Component } from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import * as Types from '../../constants/Api'

class AssignmentHistoryItem extends Component {
  static propTypes = {
    assignmentHistory: PropTypes.object.isRequired,
  }

  state = {
    chuTri: '',
    dsPhoiHop: '',
    dsXemDeBiet: '',
  }

  componentDidMount() {
    if (this.props.assignmentHistory) {
      let chuTri = ''
      let dsPhoiHop = []
      let dsXemDeBiet = []
      let theoDoiChiDao = ''
      let dsXemBaoCao = []

      if (this.props.assignmentHistory?.nvChuTri) {
        chuTri = this.props.assignmentHistory.nvChuTri?.tenNhanVien
      } else {
        if (this.props.assignmentHistory?.chuTri) {
          chuTri = this.props.assignmentHistory.chuTri.maPhongBan
        }

        if (this.props.assignmentHistory?.donViChuTri) {
          chuTri = this.props.assignmentHistory.donViChuTri.tenVietTat
        }
      }

      if (this.props.assignmentHistory?.nvChiDao) {
        theoDoiChiDao = this.props.assignmentHistory.nvChiDao.tenNhanVien
      }

      if (this.props.assignmentHistory?.phoiHop) {
        this.props.assignmentHistory.phoiHop.forEach(item => {
          dsPhoiHop.push(item.maPhongBan)
        })
      }

      if (this.props.assignmentHistory?.xemDeBiet) {
        this.props.assignmentHistory.xemDeBiet.forEach(item => {
          dsXemDeBiet.push(item.maPhongBan)
        })
      }

      if (this.props.assignmentHistory?.donViPhoiHop) {
        this.props.assignmentHistory.donViPhoiHop.forEach(item => {
          dsPhoiHop.push(item.tenVietTat)
        })
      }

      if (this.props.assignmentHistory?.dsNvPhoiHopInfo) {
        this.props.assignmentHistory.dsNvPhoiHopInfo.forEach(item => {
          dsPhoiHop.push(item?.tenNhanVien)
        })
      }

      if (this.props.assignmentHistory?.dsXemBaoCao) {
        this.props.assignmentHistory.dsXemBaoCao.forEach(item => {
          dsXemBaoCao.push(item?.tenNhanVien)
        })
      }

      if (this.props.assignmentHistory?.donViXemDeBiet) {
        this.props.assignmentHistory.donViXemDeBiet.forEach(item => {
          dsXemDeBiet.push(item.tenVietTat)
        })
      }

      if (this.props.assignmentHistory?.dsNvXemDeBiet) {
        this.props.assignmentHistory.dsNvXemDeBiet.forEach(item => {
          dsXemDeBiet.push(item.tenNhanVien)
        })
      }

      this.setState({
        chuTri,
        theoDoiChiDao,
        dsPhoiHop: dsPhoiHop.join(', '),
        dsXemBaoCao: dsXemBaoCao.join(', '),
        dsXemDeBiet: dsXemDeBiet.join(', '),
      })
    }
  }

  chuTri = () => {
    if (!this.state.chuTri) {
      return
    }

    return (
      <li>
        <span className="style-icon icon-setting"></span>
        <div className="content-detail">
          <span className="title-bold">Chủ trì: </span>
          <span className="detail">{this.state.chuTri}</span>
        </div>
      </li>
    )
  }

  theoDoiChiDao = () => {
    if (!this.state?.theoDoiChiDao) {
      return
    }

    return (
      <li>
        <span className="style-icon icon-danh-sach-tap-trung"></span>
        <div className="content-detail">
          <span className="title-bold">Theo dõi chỉ đạo: </span>
          <span className="detail">{this.state.theoDoiChiDao}</span>
        </div>
      </li>
    )
  }

  boSungPhoiHop = () => {
    if (!this.state?.dsPhoiHop) {
      return
    }

    return (
      <li>
        <span className="style-icon icon-Phoihop"></span>
        <div className="content-detail">
          <span className="title-bold">Bổ sung phối hợp: </span>
          <span className="detail">{this.state.dsPhoiHop}</span>
        </div>
      </li>
    )
  }

  xemBaoCao = () => {
    if (!this.state?.dsXemBaoCao) {
      return
    }

    return (
      <li>
        <span className="style-icon icon-quy-trinh-ca-nhan"></span>
        <div className="content-detail">
          <span className="title-bold">Xem báo cáo: </span>
          <span className="detail">{this.state.dsXemBaoCao}</span>
        </div>
      </li>
    )
  }

  xemDeBiet = () => {
    if (!this.state?.dsXemDeBiet) {
      return
    }

    return (
      <li>
        <span className="style-icon icon-Xemdebiet"></span>
        <div className="content-detail">
          <span className="title-bold">Xem để biết: </span>
          <span className="detail">{this.state.dsXemDeBiet}</span>
        </div>
      </li>
    )
  }

  hanGiaiQuyet = () => {
    if (
      !this.props.assignmentHistory?.hanGiaiQuyet ||
      (this.props.assignmentHistory?.hanGiaiQuyet &&
        !moment(this.props.assignmentHistory.hanGiaiQuyet).isValid())
    ) {
      return
    }

    return (
      <li>
        <span className="style-icon icon-calendar"></span>
        <div className="content-detail">
          <span className="title-bold">Hạn giải quyết: </span>
          <span className="detail">
            {' '}
            {moment(this.props.assignmentHistory.hanGiaiQuyet).format(
              'DD/MM/YYYY'
            )}
          </span>
        </div>
      </li>
    )
  }

  render() {
    return (
      <div className="item-content-history job-information-form">
        <div className="time-create">
          {this.props.assignmentHistory.ngayTao &&
            moment(this.props.assignmentHistory.ngayTao).format(
              'DD/MM/YYYY HH:mm'
            )}
        </div>
        <div className="avatar-user">
          <span
            className="avatar"
            style={{
              backgroundImage: `url(${
                this.props.assignmentHistory.nguoiChiDao &&
                this.props.assignmentHistory.nguoiChiDao.anhDaiDien
                  ? Types.IMAGE_URL +
                    this.props.assignmentHistory.nguoiChiDao.anhDaiDien
                  : '/images/default_avatar.png'
              })`,
            }}
          ></span>
          <span className="name_user">
            {' '}
            {this.props.assignmentHistory.nguoiChiDao
              ? this.props.assignmentHistory.nguoiChiDao.tenNhanVien
              : ''}
          </span>
        </div>
        {this.props.assignmentHistory.loaiChiDao === 4 && (
          <div className="info-content">
            <ul>
              <li className="remove-assignment">
                <span className="style-icon pt-icon pt-icon-trash"></span>
                <div className="content-detail">
                  <span className="title-bold">Hủy chỉ đạo</span>
                </div>
              </li>
            </ul>
          </div>
        )}

        {this.props.assignmentHistory.loaiChiDao === 2 && (
          <div className="info-content">
            <ul>
              <li className="status-assignment">
                <span className="style-icon icon-Chidaobosung"></span>
                <div className="content-detail">
                  <span className="title-bold">Chỉ đạo bổ sung: </span>
                  <span className="detail">
                    {' '}
                    {this.props.assignmentHistory.noiDung}{' '}
                  </span>
                </div>
              </li>
              {this.boSungPhoiHop()}
              {this.xemBaoCao()}
              {this.xemDeBiet()}
              {this.hanGiaiQuyet()}
            </ul>
          </div>
        )}

        {this.props.assignmentHistory.loaiChiDao === 0 && (
          <div className="info-content">
            <ul>
              <li className="status-assignment">
                <span className="style-icon icon-Chidao"></span>
                <div className="content-detail">
                  <span className="title-bold">Chỉ đạo: </span>
                  <span className="detail">
                    {' '}
                    {this.props.assignmentHistory.noiDung}
                  </span>
                </div>
              </li>
              {this.theoDoiChiDao()}
              {this.chuTri()}
              {this.boSungPhoiHop()}
              {this.xemBaoCao()}
              {this.xemDeBiet()}
              {this.hanGiaiQuyet()}
            </ul>
          </div>
        )}

        {this.props.assignmentHistory.loaiChiDao === 3 && (
          <div className="info-content">
            <ul>
              <li className="status-assignment">
                <span className="style-icon icon-Chidao"></span>
                <div className="content-detail">
                  <span className="title-bold">Sửa chỉ đạo: </span>
                  <span className="detail">
                    {' '}
                    {this.props.assignmentHistory.noiDung}
                  </span>
                </div>
              </li>
              {this.theoDoiChiDao()}
              {this.chuTri()}
              {this.boSungPhoiHop()}
              {this.xemBaoCao()}
              {this.xemDeBiet()}
              {this.hanGiaiQuyet()}
            </ul>
          </div>
        )}

        {this.props.assignmentHistory.loaiChiDao === 1 && (
          <div className="info-content">
            <ul>
              <li className="status-assignment">
                <span className="style-icon icon-Chuyenchidao"></span>
                <div className="content-detail">
                  <span className="detail">Chuyển chỉ đạo: </span>
                  <span className="title-bold">
                    {this.props.assignmentHistory.pbChiDaoChuyenDen &&
                      this.props.assignmentHistory.pbChiDaoChuyenDen.maPhongBan}
                  </span>
                </div>
              </li>
              {this.theoDoiChiDao()}
              <li>
                <span className="style-icon icon-content"></span>
                <div className="content-detail">
                  <span className="title-bold">Nội dung:</span>
                  <span className="detail textarea-content">
                    {' '}
                    {this.props.assignmentHistory.noiDung}
                  </span>
                </div>
              </li>
            </ul>
          </div>
        )}
      </div>
    )
  }
}

export default AssignmentHistoryItem
