/* eslint-disable max-len */
import { FILE_URL } from 'constants/Api'
import { Utils } from 'helpers'
import { get } from 'lodash'
import React from 'react'
import { connect } from 'react-redux'

const UploadMultiple = ({
  dataFiles,
  handleFileInput,
  handleRemoveFile,
  auth,
  isDetail,
}) => {
  const token = get(auth, 'token.accessToken', '')
  return (
    <div>
      <div className="row">
        <div className="col-xs-12 col-sm-12 col-md-12 form-item upload-multiple">
          <label className="pt-label">File văn bản</label>
          <div className="pt-form-content">
            {!isDetail && (
              <label className="upload-button">
                <input
                  type="file"
                  name="dsFileDinhKem"
                  multiple
                  style={{ display: 'none' }}
                  onChange={handleFileInput}
                />
                <span className="file-label ">
                  <span className="pt-icon icon-upload1"></span>Đính kèm
                </span>
                <p className="extra-text">
                  Hỗ trợ định dạng .pdf, .doc, .docx, .xls, .xlsx. Dung lượng
                  không được quá 50MB
                </p>
              </label>
            )}
            <div className="list-file">
              {dataFiles?.map((item, index) => {
                let extension
                if (item?.name) {
                  extension = item?.name?.toLowerCase().split('.').slice(-1)[0]
                }
                if (!item?.id) {
                  return (
                    <div className="file-item">
                      <img
                        className="file-extention"
                        src={Utils.typeFile(extension)}
                        alt=""
                      />
                      <span>{item.name}</span>
                      {!isDetail && (
                        <span
                          onClick={() => handleRemoveFile(index)}
                          className="pt-icon icon-close"
                        ></span>
                      )}
                    </div>
                  )
                }
                return (
                  <span key={item?.id} className="file-item">
                    <img
                      className="file-extention"
                      src={Utils.typeFile(extension)}
                      alt=""
                    />
                    <a
                      href={`${FILE_URL}/api/file/get/${item?.id}?BearerToken=${token}`}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {item.name}
                    </a>
                    {!isDetail && (
                      <span
                        onClick={() => handleRemoveFile(index)}
                        className="pt-icon icon-close"
                      ></span>
                    )}
                  </span>
                )
              })}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

const mapStateToProps = state => ({
  auth: {
    ...state.auth,
  },
})

export default connect(mapStateToProps, null)(UploadMultiple)
