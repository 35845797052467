import React from 'react'
import { bindActionCreators } from 'redux'
import { MasterLayout } from '../../components/layout'
import { ListFilter, ListItem } from '../../components/Work/FollowDirections'
import { GridViewFilter } from '../../components/common'
import { connect } from 'react-redux'
import { headers } from '../../constants/Table'
import * as Actions from '../../actions'
import * as Types from '../../constants/Api'
import * as Tool from '../../helpers'
import moment from 'moment'

import Pagination from 'components/Pagination'

const TAT_CA = '2'
class FollowDirections extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      sort: undefined,
      filter: {
        ngay_giao: {
          to: moment(),
          from: moment().subtract(1, 'months'),
        },
        text_search: '',
      },
      filterColumn: {
        trangThai: TAT_CA,
      },
      page: 1,
      isEmpty: false,
      isLoading: false,
      isError: false,
    }

    this.clickPagination = this.clickPagination.bind(this)
    this.submitFilter = this.submitFilter.bind(this)
    this.handleSort = this.handleSort.bind(this)
    this.onChangeGridViewFilter = this.onChangeGridViewFilter.bind(this)
    this.onChangeDateGridViewFilter = this.onChangeDateGridViewFilter.bind(this)
    this.clearFilterColumn = this.clearFilterColumn.bind(this)
    this.onRemoveText = this.onRemoveText.bind(this)
    this.onChangeGridViewFilter2 = this.onChangeGridViewFilter2.bind(this)
    this.removeTextSearch = this.removeTextSearch.bind(this)
    this.handleDateChange = this.handleDateChange.bind(this)
  }

  componentDidMount() {
    let state = this.state

    this.props.actions.getListPersonalAssignment()

    let preData = Tool.Utils.preData(`FollowDirections-${this.state.filter}`)

    if (preData) {
      state.filter = preData.filter
      state.page = preData.page
      state.sort = preData.sort
      state.filterColumn = {
        ...preData.filterColumn,
        ngayGiao:
          preData.filterColumn && preData.filterColumn.ngayGiao
            ? moment(preData.filterColumn.ngayGiao)
            : null,
      }
    } else {
      state.filter.ngay_giao = {
        from: moment().subtract(1, 'months').format('YYYY-MM-DD'),
        to: moment().format('YYYY-MM-DD'),
      }
    }

    this.setState(state, () => {
      this.clickPagination(this.state.page)
    })
  }

  clickPagination(page = 1) {
    let state = this.state
    state.page = page
    this.setState(state, () => {
      this.submitFilter(this.state.filter, this.state.page, this.state.sort)
    })
  }

  submitFilter(e = {}, page = 1, sort) {
    const filter = Object.assign({}, this.state.filter, e)
    const filterColumn = {
      ...this.state.filterColumn,
      ngayGiao: this.state.filterColumn.ngayGiao
        ? moment(this.state.filterColumn.ngayGiao).format('YYYY-MM-DD')
        : null,
      nguoiChuTri:
        this.state.filterColumn.nguoiChuTri &&
        this.state.filterColumn.nguoiChuTri !== ''
          ? this.state.filterColumn.nguoiChuTri
          : null,
      trichYeu:
        this.state.filterColumn.trichYeu &&
        this.state.filterColumn.trichYeu !== ''
          ? this.state.filterColumn.trichYeu
          : null,
      soCongVan:
        this.state.filterColumn.soCongVan &&
        this.state.filterColumn.soCongVan !== ''
          ? this.state.filterColumn.soCongVan
          : null,
      nguoiGiaoId:
        this.state.filterColumn.nguoiGiaoId &&
        this.state.filterColumn.nguoiGiaoId !== ''
          ? this.state.filterColumn.nguoiGiaoId
          : null,
      trangThai:
        this.state.filterColumn.trangThai &&
        this.state.filterColumn.trangThai !== '2'
          ? this.state.filterColumn.trangThai
          : null,
    }

    this.setState({ filter, sort, isLoading: true, page }, () => {
      Tool.Utils.preData(`FollowDirections-${this.state.filter}`, {
        filter,
        page,
        sort,
        filterColumn,
      })
      this.props.actions
        .getListFollowDirection({ filter }, page, sort, filterColumn)
        .then(res => {
          let isEmpty = false
          let isError = false
          if (
            !res.error &&
            res.payload &&
            res.payload.data &&
            res.payload.data.result &&
            res.payload.data.result.total === 0
          ) {
            isEmpty = true
          }

          if (res.error || (res.payload && res.payload.status !== 200)) {
            isError = true
          }
          this.setState({ isEmpty, isError })
        })
        .then(res => {
          this.setState({ isLoading: false })
        })
    })
  }

  handleSort(sort) {
    this.submitFilter({}, this.state.page, sort)
  }

  onChangeGridViewFilter(e) {
    const value = e.target.value
    const name = e.target.name

    this.setState(
      {
        filterColumn: {
          ...this.state.filterColumn,
          [name]: value,
        },
      },
      () => {
        if (name === 'nguoiGiaoId') {
          this.submitFilter()
        }
      }
    )
  }

  onChangeGridViewFilter2(e) {
    const value = e.target.value
    const name = e.target.name

    this.setState(
      {
        filterColumn: {
          ...this.state.filterColumn,
          [name]: value,
        },
      },
      () => {
        if (name === 'trangThai') {
          this.submitFilter()
        }
      }
    )
  }

  onChangeDateGridViewFilter(name, e) {
    this.setState(
      {
        filterColumn: {
          ...this.state.filterColumn,
          [name]: e,
        },
      },
      () => {
        this.submitFilter()
      }
    )
  }

  async clearFilterColumn(filter, check) {
    if (check) {
      await this.setState({
        filterColumn: {
          trangThai: TAT_CA,
        },
      })
    }
    await this.submitFilter(filter)
  }

  onRemoveText(name) {
    this.setState(
      {
        filterColumn: {
          ...this.state.filterColumn,
          [name]: '',
        },
      },
      () => {
        this.submitFilter()
      }
    )
  }

  removeTextSearch(name) {
    this.setState({
      filter: {
        ...this.state.filter,
        [name]: '',
      },
    })
  }

  handleDateChange(name, pos, e) {
    this.setState(
      {
        filter: {
          ...this.state.filter,
          [name]: {
            ...this.state.filter[name],
            [pos]: e ? moment(e).format('YYYY-MM-DD') : undefined,
          },
        },
      },
      () => {
        this.submitFilter()
      }
    )
  }

  render() {
    return (
      <MasterLayout typeSidebar="work">
        <div>
          {/* Heading */}
          <div className="heading-page">
            <div className="row">
              <div className="col-xs-12">
                <h3 className="text-heading-page">
                  <span className="icon2-follow-direction icon-heading"></span>
                  <span className="text-heading">
                    Công việc theo dõi chỉ đạo
                  </span>
                </h3>
              </div>
            </div>
          </div>

          {/* Table List */}
          <div className="page-list-container">
            <ListFilter
              filter={this.state.filter}
              onSubmit={this.submitFilter}
              listPersonal={this.props.listPersonal.items}
              readOnly={this.props.readOnly}
              actions={this.props.actions}
              clearFilterColumn={this.clearFilterColumn}
              removeTextSearch={this.removeTextSearch}
              handleDateChange={this.handleDateChange}
            />

            <div
              className="table-container"
              style={{
                paddingBottom: 0,
                border: 0,
                background:
                  Math.ceil(
                    this.props.personalWorks.total / Types.PAGINATION_LIMIT
                  ) > 1
                    ? '#fff'
                    : '#eff3f5',
              }}
            >
              <GridViewFilter
                isLoading={this.state.isLoading}
                classNames={'pt-table fix-table-before'}
                page={this.state.page ? this.state.page : 1}
                type={this.state.type}
                headers={headers.listSeeToKnowWorkPage}
                itemShape={ListItem}
                data={this.props.personalWorks.items}
                onFilter={this.handleSort}
                isEmpty={this.state.isEmpty}
                history={this.props.history}
                actions={this.props.actions}
                isError={this.state.isError}
                onChange={this.onChangeGridViewFilter}
                onChange2={this.onChangeGridViewFilter2}
                onChangeDate={this.onChangeDateGridViewFilter}
                nguoiGiaoId={this.props.listPersonal.items}
                filterColumn={this.state.filterColumn}
                onSubmit={this.submitFilter}
                onRemoveText={this.onRemoveText}
                pageCount={Math.ceil(
                  this.props.personalWorks.total / Types.PAGINATION_LIMIT
                )}
              />

              {!this.state.isLoading &&
                !this.state.isEmpty &&
                !this.state.isError && (
                  <Pagination
                    current={this.state.page}
                    total={Math.ceil(
                      this.props?.personalWorks?.total / Types.PAGINATION_LIMIT
                    )}
                    onChange={this.clickPagination}
                  />
                )}
            </div>
          </div>
        </div>
      </MasterLayout>
    )
  }
}

const mapStateToProps = state => ({
  auth: {
    ...state.auth,
  },

  personalWorks: {
    ...state.personalWorks,
    items: state.personalWorks.followDirection,
    total: state.personalWorks.totalFollowDirection,
  },

  listPersonal: {
    ...state.personalWorks,
    items: state.personalWorks.listPersonal,
  },

  readOnly: state.common.enableReadOnly,
})

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(Actions, dispatch),
})

export default connect(mapStateToProps, mapDispatchToProps)(FollowDirections)
