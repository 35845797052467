import React, { memo } from 'react'
import { withRouter } from 'react-router'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import moment from 'moment'
import { commonAddToasterMessage } from 'actions/index'
import {
  fetchNotificationCounter,
  getNotificationList,
  postNotificationRead,
} from 'actions/notifications'
import { MasterLayout } from 'components/layout'
import Title from 'components/ui/title'
import { TruncateTextTooltip } from 'components/common'
import { Table, Pagination } from 'components/newCommon2'

import useList from './hooks/use-list'
import Filter from './components/filter'

const Index = ({ history, actions }) => {
  const {
    filterKeys,
    filterData,
    listData,
    pagination,
    isFetching,
    onChangeFilter,
  } = useList({
    actions,
    history,
  })

  const columns = [
    {
      key: 'stt',
      width: 5,
      headClass: 'uppercase',
      className: 'align-center element-center max-width-80',
      title: 'Stt',
      render: (record, v, index) => {
        const num = (pagination?.page - 1) * 10 + index + 1
        return (
          <div style={{ color: record?.daDoc ? '#202020' : '#1677ff' }}>
            {`${num}`.padStart(num < 10 ? 2 : 1, '0')}
          </div>
        )
      },
    },
    {
      key: 'tieuDe',
      width: 20,
      headClass: 'uppercase',
      className: 'align-center',
      title: 'Tiêu đề',
      render: record => {
        return (
          <div style={{ color: record?.daDoc ? '#202020' : '#1677ff' }}>
            {record?.tieuDe?.length > 20 ? (
              <TruncateTextTooltip content={record?.tieuDe} />
            ) : (
              record?.tieuDe
            )}
          </div>
        )
      },
    },
    {
      key: 'ngayTao',
      headClass: 'uppercase max-width-200',
      className: 'align-center max-width-200',
      title: 'Ngày tạo',
      render: record => (
        <div style={{ color: record?.daDoc ? '#202020' : '#1677ff' }}>
          {record?.ngayTao
            ? moment(record?.ngayTao).format('DD/MM/YYYY HH:mm')
            : ''}
        </div>
      ),
    },
    {
      key: 'nguoiTao',
      headClass: 'uppercase max-width-200',
      className: 'align-center max-width-200',
      width: 5,
      title: 'Người tạo',
      render: record => (
        <div style={{ color: record?.daDoc ? '#202020' : '#1677ff' }}>
          {record?.nguoiTao}
        </div>
      ),
    },
  ]

  const onItemClick = id => {
    actions
      .postNotificationRead({
        thongBaoChungId: id,
      })
      .then(res => {
        if (res?.payload?.data?.result) {
          actions.fetchNotificationCounter()
        }
      })
  }

  return (
    <MasterLayout typeSidebar="notification">
      <div>
        <div className="d-flex align-items-center justify-content-space-between gap-10">
          <Title name={'Danh sách thông báo'} icon={'icon2-bars'} />
        </div>
        <div className="border mt15">
          <Filter
            filterKeys={filterKeys}
            dataValue={filterData}
            onChangeFilter={onChangeFilter}
          />
          <div>
            <Table
              loading={isFetching}
              error={false}
              autoHide={false}
              columns={columns}
              data={listData?.items}
              onRowClick={({ id, daDoc }) => {
                if (!daDoc) {
                  onItemClick(id)
                }
                history.push(`/he-thong/danh-sach-thong-bao/chi-tiet/${id}`)
              }}
              onContextMenu={() => {}}
              tableMinHeight={300}
              tableMinWidth={900}
            />
            {listData?.total > pagination?.limit && (
              <div className="text-center bg-white ph15 pv20 border-top">
                <Pagination
                  total={listData?.total}
                  current={pagination?.page}
                  pageSize={pagination?.limit}
                  onChange={onChangeFilter({ name: 'PAGE' })}
                />
              </div>
            )}
          </div>
        </div>
      </div>
    </MasterLayout>
  )
}

const mapStateToProps = state => {
  return {
    currentUserId: state.auth?.user?.nhanVienId,
  }
}

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(
    {
      commonAddToasterMessage,
      getNotificationList,
      postNotificationRead,
      fetchNotificationCounter,
    },
    dispatch
  ),
})

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(memo(Index))
)
