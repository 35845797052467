import React, { Component } from 'react'
import PropTypes from 'prop-types'
import FeedbackItem from './FeedbackItem'

class Feedback extends Component {
  constructor(props) {
    super(props)
    this.handleUpdateFeedback = this.handleUpdateFeedback.bind(this)
  }

  static propTypes = {
    title: PropTypes.string.isRequired,
    list: PropTypes.array.isRequired,
    auth: PropTypes.string,
  }

  handleUpdateFeedback = feedbackId => {
    this.props.onChangeFeedback && this.props.onChangeFeedback(feedbackId)
  }

  render() {
    return (
      <div className="history-steering-box show-note-box">
        <div className="heading noborder-bottom">
          <h3 className="heading-title">
            <span className="icon-GopY"></span>
            <span className="heading-title-text">{this.props.title}</span>
          </h3>
        </div>
        {this.props.list.map((item, i) => (
          <FeedbackItem
            item={item}
            key={i}
            auth={this.props.auth}
            isCanUpdate={this.props.isCanUpdate}
            onSubmit={this.props.onSubmit}
            loading={this.props.loading}
            feedbackId={this.props.feedbackId}
            onChangeFeedback={this.handleUpdateFeedback}
            isLoading={this.props.isLoading}
            isLoadingData={this.props.isLoadingData}
            className={this.props.className}
            isOfSecretary={this.props.isOfSecretary}
            onShowMessageCopySuccess={this.props.onShowMessageCopySuccess}
          />
        ))}
      </div>
    )
  }
}

export default Feedback
