import React, { useEffect, memo } from 'react'
import classNames from 'classnames'
import PropTypes from 'prop-types'
import { useCookies } from 'react-cookie'

import { FOLDER_NAME } from 'constants/Api'
import PopupModal from 'components/common/PopupModal'

const BACKGROUND_LIST = [
  {
    isLight: false,
    url: 'bg1.jpg',
  },
  {
    isLight: false,
    url: 'bg2.jpg',
  },
  {
    isLight: true,
    url: 'bg3.jpg',
  },
  {
    isLight: true,
    url: 'bg4.jpg',
  },
  {
    isLight: true,
    url: 'bg5.jpg',
  },
  {
    isLight: true,
    url: 'bg6.jpg',
  },
  {
    isLight: false,
    url: 'bg7.jpg',
  },
  {
    isLight: false,
    url: 'bg8.jpg',
  },
  {
    isLight: true,
    url: 'bg9.jpg',
  },
  {
    isLight: false,
    url: 'bg10.jpg',
  },
  {
    isLight: false,
    url: 'bg11.jpg',
  },
  {
    isLight: true,
    url: 'bg12.jpg',
  },
]

const Index = ({ onClose }) => {
  const [cookies, setCookie] = useCookies(['home_background_image'])
  const [cookiesTheme, setCookiesTheme] = useCookies(['theme_menu'])

  if (!cookies?.home_background_image) {
    setCookie('home_background_image', {
      url: 'bg1.jpg',
      is_light: false,
    })
  }

  const changeBackground = (image, isLight) => {
    setCookie('home_background_image', {
      url: image,
      is_light: isLight,
    })
  }

  useEffect(() => {
    if (!cookiesTheme?.theme_menu) {
      setCookiesTheme('theme_menu', 'HORIZONTAL')
    }
  }, [setCookiesTheme, cookiesTheme])

  return (
    <PopupModal
      isOpen={true}
      title="Hình nền"
      onClose={onClose}
      contentClass="width-400"
    >
      <div>
        <div className="change-background-list">
          {BACKGROUND_LIST.map((elm, index) => {
            let background = FOLDER_NAME
              ? `/backgrounds_home/${FOLDER_NAME}/thumbnail-${elm.url}`
              : `/backgrounds_home/thumbnail-${elm.url}`
            return (
              <div key={index} className="change-background-list-item">
                <span
                  style={{ backgroundImage: `url(${background})` }}
                  className={classNames({
                    active: cookies?.home_background_image?.url === elm.url,
                  })}
                  onClick={() => changeBackground(elm.url, elm.isLight)}
                />
              </div>
            )
          })}
        </div>
      </div>
    </PopupModal>
  )
}

Index.defaultProps = {
  onClose: () => {},
}

Index.propTypes = {
  onClose: PropTypes.func,
}

export default memo(Index)
