import React, { Component } from 'react'
import { Checkbox, Button, Collapse } from '@blueprintjs/core'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import { PopupConfirm, DepartmentPerform } from '../common/'
import moment from 'moment'
import { Intent } from '@blueprintjs/core'
import * as POPUP from '../../constants/Popup'
import * as MESSAGE from '../../constants/MessageForm'
import DatePicker from 'react-datepicker'
import * as Tool from '../../helpers'

class Assignment extends Component {
  static propTypes = {
    onClickGoBack: PropTypes.func.isRequired,
    departments: PropTypes.array.isRequired,
    units: PropTypes.array.isRequired,
    onSubmit: PropTypes.func.isRequired,
    actions: PropTypes.object.isRequired,
    proposeAssignment: PropTypes.object,
    informationSteering: PropTypes.object,
  }

  constructor(props) {
    super(props)
    this.handleSubmit = this.handleSubmit.bind(this)
    this.handleClickArrow = this.handleClickArrow.bind(this)
    this.handleInputChange = this.handleInputChange.bind(this)
    this.handleClickPopupDelete = this.handleClickPopupDelete.bind(this)
    this.handleGetValueGridCheckBox = this.handleGetValueGridCheckBox.bind(this)
    this.handleSubmitRemoveItem = this.handleSubmitRemoveItem.bind(this)
    //this.handleScroll = this.handleScroll.bind(this);
    this.handleChangeRaw = this.handleChangeRaw.bind(this)
    this.focousOut = this.focousOut.bind(this)
    this.handleSubmitReWorking = this.handleSubmitReWorking.bind(this)
  }

  state = {
    error: {},
    isOpen: false,
    congVanDenId: '',
    noiDung: '',
    chuTriId: '',
    dsPhoiHop: [],
    dsXemDeBiet: [],
    donViChuTriId: '',
    dsDonViPhoiHop: [],
    dsDonViXemDeBiet: [],
    hanGiaiQuyet: undefined,
    // docHanChe: false,
    luuBanGoc: false,
    departmentSelections: [],
    receivingUnitSelections: [],
    isOpenPopup: false,
    isOpenPopupDelete: false,
    departmentAssigned: null,
    isLoading: {
      isLoadingSubmit: false,
      isLoadingRemove: false,
    },
    dealineToString: '',
  }

  handleClickArrow = () => {
    this.setState({ isOpen: !this.state.isOpen })
  }

  handleClickPopupDelete = () => {
    this.setState({
      isOpenPopupDelete: !this.state.isOpenPopupDelete,
    })
  }

  handleDateChange = (name, nameToString, e) => {
    let value = e ? e : undefined
    let valueToString = ''
    if (value) {
      valueToString = moment(e).format('DD/MM/YYYY')
    }

    this.setState({
      [name]: value,
      [nameToString]: valueToString,
    })
  }

  handleChangeRaw(name, value) {
    this.setState({ [name]: value })
  }

  focousOut(name, nameToString, value) {
    if (
      Tool.Utils.isValidDate(value, {
        after: {
          date: moment(moment().format('YYYY-MM-DD')),
        },
      })
    ) {
      return
    }

    let oldValue = undefined
    let oldValueToString = ''
    if (
      this.props.informationSteering &&
      this.props.informationSteering.hanGiaiQuyet
    ) {
      oldValue = moment(this.props.informationSteering.hanGiaiQuyet)
      oldValueToString = moment(
        this.props.informationSteering.hanGiaiQuyet
      ).format('DD/MM/YYYY')
    }

    this.setState({
      [nameToString]: oldValueToString,
      [name]: oldValue,
    })
  }

  handleSubmitRemoveItem = e => {
    e.preventDefault()
    this.setState({
      isOpenPopupDelete: false,
      isLoading: {
        ...this.state.isLoading,
        isLoadingRemove: true,
      },
    })

    this.props.actions
      .assignmentHistoryDelete(
        this.props.informationSteering.congVanDenId,
        this.props.informationSteering.chiDaoId
      )
      .then(res => {
        if (res.payload && res.payload.status === 200) {
          // this.props.actions.getExecutingView(this.props.congVanDenId);
          this.props.onSubmit(
            'HuyChiDao',
            MESSAGE.TEXT_DELETE_ASSIGN_SUCCESS,
            Intent.SUCCESS,
            true,
            null
          )
        } else {
          this.setState({
            isLoading: {
              ...this.state.isLoading,
              isLoadingRemove: false,
            },
          })
          this.props.onSubmit(
            'HuyChiDao',
            MESSAGE.TOATS_CANCLE_FAIL,
            Intent.DANGER,
            false,
            null
          )
        }
      })
  }

  handleSubmit = () => {
    if (
      (!this.state.chuTriId || !this.state.chuTriId.length) &&
      (!this.state.donViChuTriId || !this.state.donViChuTriId.length)
    ) {
      return this.props.onSubmit(
        'ChiDao',
        MESSAGE.TEXT_MESSAGE_PHONGBANCHUTRI,
        Intent.WARNING,
        false,
        null
      )
    }

    this.setState({
      isLoading: {
        ...this.state.isLoading,
        isLoadingSubmit: true,
      },
    })
    let hanGiaiQuyet = null
    if (
      this.state.hanGiaiQuyet &&
      this.state.hanGiaiQuyet.toString().length > 0
    ) {
      hanGiaiQuyet = moment(this.state.hanGiaiQuyet)
        .set({ hour: 0, minute: 0, second: 0, millisecond: 0 })
        .format()
    }

    let dataRequest = {
      cong_van_den_id: this.state.congVanDenId,
      noi_dung: this.state.noiDung,
      chu_tri_id: this.state.chuTriId,
      ds_phoi_hop: this.state.dsPhoiHop,
      ds_xem_de_biet: this.state.dsXemDeBiet,
      don_vi_chu_tri_id: this.state.donViChuTriId,
      ds_don_vi_phoi_hop: this.state.dsDonViPhoiHop,
      ds_don_vi_xem_de_biet: this.state.dsDonViXemDeBiet,
      han_giai_quyet: hanGiaiQuyet,
      // doc_han_che: this.state.docHanChe,
      luu_ban_goc: this.state.luuBanGoc,
    }

    if (this.props.informationSteering) {
      dataRequest = {
        ...dataRequest,
        chi_dao_id: this.props.informationSteering.chiDaoId,
      }
      this.props.actions
        .updateAssignmentHistory(this.state.congVanDenId, dataRequest)
        .then(res => {
          if (res.payload && res.payload.status === 200) {
            // this.props.actions.getExecutingView(this.props.congVanDenId);
            this.props.onSubmit(
              'ChiDao',
              MESSAGE.TEXT_UPDATE_ASSIGN_SUCCESS,
              Intent.SUCCESS,
              true,
              null
            )
          } else {
            this.setState({
              isLoading: {
                ...this.state.isLoading,
                isLoadingSubmit: false,
              },
            })
            this.props.onSubmit(
              'ChiDao',
              MESSAGE.TOATS_MESSAGE_FAIL,
              Intent.DANGER,
              false,
              null
            )
          }
        })
    } else {
      this.props.actions
        .receiveDocumentSendAssignment(this.props.congVanDenId, dataRequest)
        .then(res => {
          if (res.payload && res.payload.status === 200) {
            // this.props.actions.getExecutingView(this.props.congVanDenId);
            this.props.onSubmit(
              'ChiDao',
              MESSAGE.TEXT_ASSIGN_SUCCESS,
              Intent.SUCCESS,
              true,
              null
            )
          } else {
            this.setState({
              isLoading: {
                ...this.state.isLoading,
                isLoadingSubmit: false,
              },
            })
            this.props.onSubmit(
              'ChiDao',
              MESSAGE.TOATS_MESSAGE_FAIL,
              Intent.DANGER,
              false,
              null
            )
          }
        })
    }
  }

  handleInputChange = e => {
    if (e.target.type === 'checkbox') {
      return this.setState({ [e.target.name]: e.target.checked })
    }
    this.setState({ [e.target.name]: e.target.value })
  }

  handleGetValueGridCheckBox = (type, data) => {
    if (!data || !Object.keys(data).length) {
      return
    }

    switch (type) {
      case 'units':
        let chuTriId = this.state.chuTriId
        if (!!this.state.donViChuTriId !== !!data.chuTriId) {
          chuTriId = ''
        }
        return this.setState({
          chuTriId,
          donViChuTriId: data.chuTriId,
          dsDonViPhoiHop: data.dsPhoiHop,
          dsDonViXemDeBiet: data.dsXemDeBiet,
        })

      default:
        let donViChuTriId = this.state.donViChuTriId
        if (this.state.chuTriId !== data.chuTriId) {
          donViChuTriId = ''
        }
        return this.setState({
          donViChuTriId,
          chuTriId: data.chuTriId,
          dsPhoiHop: data.dsPhoiHop,
          dsXemDeBiet: data.dsXemDeBiet,
        })
    }
  }

  handleSubmitReWorking() {
    if (
      (!this.state.chuTriId || !this.state.chuTriId.length) &&
      (!this.state.donViChuTriId || !this.state.donViChuTriId.length)
    ) {
      return this.props.onSubmit(
        'ChiDao',
        MESSAGE.TEXT_MESSAGE_PHONGBANCHUTRI,
        Intent.WARNING,
        false,
        null
      )
    }

    this.setState({
      isOpenPopupDelete: false,
      isLoading: {
        ...this.state.isLoading,
        isLoadingSubmit: true,
      },
    })
    let hanGiaiQuyet = null
    if (
      this.state.hanGiaiQuyet &&
      this.state.hanGiaiQuyet.toString().length > 0
    ) {
      hanGiaiQuyet = moment(this.state.hanGiaiQuyet)
        .set({ hour: 0, minute: 0, second: 0, millisecond: 0 })
        .format()
    }

    let dataRequest = {
      cong_van_den_id: this.state.congVanDenId,
      noi_dung: this.state.noiDung,
      chu_tri_id: this.state.chuTriId,
      ds_phoi_hop: this.state.dsPhoiHop,
      ds_xem_de_biet: this.state.dsXemDeBiet,
      don_vi_chu_tri_id: this.state.donViChuTriId,
      ds_don_vi_phoi_hop: this.state.dsDonViPhoiHop,
      ds_don_vi_xem_de_biet: this.state.dsDonViXemDeBiet,
      han_giai_quyet: hanGiaiQuyet,
      luu_ban_goc: this.state.luuBanGoc,
    }

    if (this.props.informationSteering) {
      dataRequest = {
        ...dataRequest,
        old_chi_dao_id: this.props.informationSteering.chiDaoId,
      }
      this.props.actions
        .deleteWorkingDocument(this.state.congVanDenId, dataRequest)
        .then(res => {
          if (res.payload && res.payload.status === 200) {
            this.props.onSubmit(
              'ChiDao',
              MESSAGE.TOATS_MESSAGE_SUCCESS,
              Intent.SUCCESS,
              true,
              null
            )
          } else {
            this.setState({
              isLoading: {
                ...this.state.isLoading,
                isLoadingSubmit: false,
              },
            })
            this.props.onSubmit(
              'ChiDao',
              MESSAGE.TOATS_MESSAGE_FAIL,
              Intent.DANGER,
              false,
              null
            )
          }
        })
    }
  }

  componentDidMount() {
    let chuTriId = ''
    let dsPhoiHop = []
    let dsXemDeBiet = []
    let donViChuTriId = ''
    let dsDonViPhoiHop = []
    let dsDonViXemDeBiet = []
    let error = this.state.error
    let departmentAssigned = null
    let currentDate = moment().format('YYYY-MM-DD')
    let hanGiaiQuyet = undefined
    let dealineToString = ''
    let isOpen = false
    let noiDung = ''
    let luuBanGoc = false

    // Sửa chỉ đạo
    if (this.props.informationSteering && this.props.isUpdateAssignment) {
      noiDung = this.props.informationSteering.noiDung || ''
      if (typeof this.props.informationSteering.luuBanGoc === 'boolean') {
        luuBanGoc = this.props.informationSteering.luuBanGoc
      }
      if (this.props.informationSteering.chuTri) {
        chuTriId = this.props.informationSteering.chuTri.phongBanId
      }
      if (this.props.informationSteering.donViChuTri) {
        donViChuTriId = this.props.informationSteering.donViChuTri.donViId
        isOpen = true
      }

      if (
        this.props.informationSteering.hanGiaiQuyet &&
        Tool.Utils.isValidDate(
          moment(this.props.informationSteering.hanGiaiQuyet).format(
            'DD/MM/YYYY'
          )
        )
      ) {
        hanGiaiQuyet = moment(this.props.informationSteering.hanGiaiQuyet)
        dealineToString = moment(
          this.props.informationSteering.hanGiaiQuyet
        ).format('DD/MM/YYYY')
        if (
          moment(this.props.informationSteering.hanGiaiQuyet).format(
            'YYYY-MM-DD'
          ) < currentDate
        ) {
          error.hanGiaiQuyet = MESSAGE.TEXT_MESSAGE_HANGIAIQUYET
        }
      }

      if (this.props.informationSteering.phoiHop) {
        this.props.informationSteering.phoiHop.forEach(item => {
          dsPhoiHop.push(item.phongBanId)
        })
      }
      if (this.props.informationSteering.xemDeBiet) {
        this.props.informationSteering.xemDeBiet.forEach(item => {
          dsXemDeBiet.push(item.phongBanId)
        })
      }
      if (this.props.informationSteering.donViPhoiHop) {
        this.props.informationSteering.donViPhoiHop.forEach(item => {
          dsDonViPhoiHop.push(item.donViId)
        })
      }
      if (this.props.informationSteering.donViXemDeBiet) {
        this.props.informationSteering.donViXemDeBiet.forEach(item => {
          dsDonViXemDeBiet.push(item.donViId)
        })
      }

      if (dsDonViPhoiHop.length > 0 || dsDonViXemDeBiet.length > 0) {
        isOpen = true
      }

      if (
        this.props.informationSteering.phongBanDaGiaoViec &&
        this.props.informationSteering.phongBanDaGiaoViec.length > 0
      ) {
        departmentAssigned = this.props.informationSteering.phongBanDaGiaoViec
      }
    }

    // Sửa đề xuất chỉ đạo
    if (
      !this.props.informationSteering &&
      this.props.proposeAssignment &&
      !this.props.isUpdateAssignment
    ) {
      noiDung = this.props.proposeAssignment.noiDung || ''
      if (typeof this.props.proposeAssignment.luuBanGoc === 'boolean') {
        luuBanGoc = this.props.proposeAssignment.luuBanGoc
      }
      chuTriId = this.props.proposeAssignment.chuTri
      if (this.props.proposeAssignment.donViChuTri) {
        donViChuTriId = this.props.proposeAssignment.donViChuTri.donViId
        isOpen = true
      }
      if (
        this.props.proposeAssignment.hanGiaiQuyet &&
        Tool.Utils.isValidDate(
          moment(this.props.proposeAssignment.hanGiaiQuyet).format('DD/MM/YYYY')
        )
      ) {
        hanGiaiQuyet = moment(this.props.proposeAssignment.hanGiaiQuyet)
        dealineToString = moment(
          this.props.proposeAssignment.hanGiaiQuyet
        ).format('DD/MM/YYYY')
        if (
          moment(this.props.proposeAssignment.hanGiaiQuyet).format(
            'YYYY-MM-DD'
          ) < currentDate
        ) {
          error.hanGiaiQuyet = MESSAGE.TEXT_MESSAGE_HANGIAIQUYET
        }
      }

      if (this.props.proposeAssignment.phoiHop) {
        dsPhoiHop = this.props.proposeAssignment.phoiHop
      }
      if (this.props.proposeAssignment.xemDeBiet) {
        dsXemDeBiet = this.props.proposeAssignment.xemDeBiet
      }
      if (this.props.proposeAssignment.donViPhoiHop) {
        dsDonViPhoiHop = this.props.proposeAssignment.donViPhoiHop
      }
      if (this.props.proposeAssignment.donViXemDeBiet) {
        dsDonViXemDeBiet = this.props.proposeAssignment.donViXemDeBiet
      }

      if (dsDonViPhoiHop.length > 0 || dsDonViXemDeBiet.length > 0) {
        isOpen = true
      }
    }

    const departmentSelections = []
    this.props.departments.forEach((item, i) => {
      departmentSelections.push({
        content: item.maPhongBan,
        value: item.phongBanId,
      })
    })

    const receivingUnitSelections = []
    this.props.units.forEach(item => {
      receivingUnitSelections.push({
        content: item.tenVietTat,
        value: item.donViId,
      })
    })

    this.setState({
      noiDung,
      luuBanGoc,
      dealineToString,
      hanGiaiQuyet,
      chuTriId,
      dsPhoiHop,
      dsXemDeBiet,
      donViChuTriId,
      dsDonViPhoiHop,
      dsDonViXemDeBiet,
      departmentAssigned,
      error,
      isOpen,
      congVanDenId: this.props.congVanDenId,
      departmentSelections,
      receivingUnitSelections,
    })
  }

  render() {
    return (
      <div className="container-form-action">
        <div className="heading">
          <span className="click-go-back" onClick={this.props.onClickGoBack}>
            <span className="pt-icon pt-icon-arrow-left pull-left"></span>
          </span>
          <h4 className="name-heading">
            {this.props.status
              ? 'Giao lại'
              : this.props.informationSteering
              ? 'Sửa/Hủy chỉ đạo'
              : 'Chỉ đạo'}
          </h4>
        </div>
        <div className="form-container container-detail">
          {this.props.proposeAssignment !== null &&
            this.props.informationSteering === null && (
              <p className="propose">
                Theo đề xuất của
                <span className="name">
                  &nbsp;{this.props.proposeAssignment.nguoiDeXuat.tenNhanVien}
                </span>
              </p>
            )}

          <div className="pt-form-group">
            <div className="row full-row">
              <div className="col-xs-12 col-md-12 col-form-item">
                <label className="pt-label">Nội dung chỉ đạo</label>
              </div>
              <div className="col-xs-12 col-md-12 col-form-item">
                <div className="pt-form-content">
                  <textarea
                    name="noiDung"
                    value={this.state.noiDung}
                    onChange={this.handleInputChange}
                    rows="1"
                    className="pt-input pt-fill"
                    dir="auto"
                    autoFocus={true}
                  ></textarea>
                </div>
              </div>
            </div>
          </div>

          {/* Phòng ban */}
          <div className="pt-form-group">
            <label className="pt-label pt0">Phòng ban thực hiện</label>
            <DepartmentPerform
              options={this.state.departmentSelections}
              onSubmit={this.handleGetValueGridCheckBox.bind(
                this,
                'departments'
              )}
              dsPhoiHop={this.state.dsPhoiHop}
              dsXemDeBiet={this.state.dsXemDeBiet}
              chuTriId={this.state.chuTriId}
              selectedCompositiveDepartments={[]}
              selectedOnlySeeDepartments={[]}
              titles={['CT', 'PH', 'XĐB']}
              isHasRadio={true}
              type="Phòng ban"
            />
          </div>

          {/* Đơn vị nhận */}
          {this.state.receivingUnitSelections &&
            this.state.receivingUnitSelections.length > 0 && (
              <div>
                <div className="disabled-checkbox">
                  <label className="pt-label pt0">
                    <span
                      className="pull-left name_group name_group_unit"
                      onClick={this.handleClickArrow}
                    >
                      Đơn vị nhận
                    </span>
                  </label>
                  <span
                    onClick={this.handleClickArrow}
                    className={classnames('name_group_unit', {
                      'pt-icon pt-icon-chevron-up': this.state.isOpen,
                      'pt-icon pt-icon-chevron-down': !this.state.isOpen,
                    })}
                  ></span>
                </div>
                <Collapse className="mt5" isOpen={this.state.isOpen}>
                  <div className="pt-form-group">
                    <DepartmentPerform
                      options={this.state.receivingUnitSelections}
                      onSubmit={this.handleGetValueGridCheckBox.bind(
                        this,
                        'units'
                      )}
                      dsPhoiHop={this.state.dsDonViPhoiHop}
                      dsXemDeBiet={this.state.dsDonViXemDeBiet}
                      chuTriId={this.state.donViChuTriId}
                      selectedCompositiveDepartments={[]}
                      selectedOnlySeeDepartments={[]}
                      titles={['CT', 'PH', 'XĐB']}
                      isHasRadio={true}
                      type="Đơn vị"
                    />
                  </div>
                </Collapse>
              </div>
            )}

          {/* Option Box */}
          <div className="pt-form-group pt-inline resolve-date pt-intent-danger">
            <div className="row full-row">
              <div className="col-xs-12 col-sm-12 resolve-date-padding0">
                <label className="pt-label">Hạn giải quyết</label>
                <div className="pt-form-content">
                  <div className="pt-input-group pt-large">
                    <DatePicker
                      isClearable={this.state.dealineToString ? true : false}
                      readOnly={this.props.readOnly}
                      selected={this.state.hanGiaiQuyet}
                      onChange={this.handleDateChange.bind(
                        this,
                        'hanGiaiQuyet',
                        'dealineToString'
                      )}
                      value={this.state.dealineToString}
                      onChangeRaw={e =>
                        this.handleChangeRaw('dealineToString', e.target.value)
                      }
                      onBlur={e =>
                        this.focousOut(
                          'hanGiaiQuyet',
                          'dealineToString',
                          e.target.value
                        )
                      }
                      format="DD/MM/YYYY"
                      showMonthDropdown
                      showYearDropdown
                      dropdownMode="select"
                      className="input-datepicker input-icon-datepicker"
                      placeholderText="Chọn ngày..."
                      minDate={moment()}
                      popperPlacement="auto"
                      popperModifiers={{
                        offset: {
                          enabled: true,
                          offset: '5px, 10px',
                        },
                        preventOverflow: {
                          enabled: true,
                          escapeWithReference: false,
                          boundariesElement: 'viewport',
                        },
                      }}
                    />
                  </div>
                </div>
              </div>
              <div className="note-deadline">
                <p className="text">
                  Nếu không chọn LĐ Phòng ban chủ trì sẽ xác định
                </p>
              </div>
              {this.state.error.hanGiaiQuyet !== undefined &&
                !this.props.workAssignInformations && (
                  <div className="col-xs-12 col-md-9 pt-form-helper-content">
                    <p className="pt-form-helper-text">
                      {this.state.error.hanGiaiQuyet}
                    </p>
                  </div>
                )}
            </div>
          </div>

          <div className="selectbox-group">
            <div className="row full-row">
              {/*<div className="col-xs-12 col-md-3 col-form-item">
                <Checkbox className="pt-inline" name="docHanChe" checked={this.state.docHanChe} onChange={this.handleInputChange}>
                  <span className="name_checkbox">Đọc hạn chế</span>
                </Checkbox>
              </div>*/}
              <div className="col-xs-12 col-md-9 col-form-item">
                <Checkbox
                  className="pt-inline"
                  name="luuBanGoc"
                  checked={this.state.luuBanGoc}
                  onChange={this.handleInputChange}
                >
                  <span className="name_checkbox">
                    Phòng ban chủ trì lưu bản giấy
                  </span>
                </Checkbox>
              </div>
            </div>
          </div>
          {/* Khi công văn đã được giao việc thì hiện dòng này*/}
          {this.state.departmentAssigned && (
            <p className="note-highline note-qoute-left">
              Chú ý công văn này đã được Lãnh đạo Phòng/Ban giao việc
            </p>
          )}
        </div>

        {/* Footer */}
        {!this.props.status && (
          <div id="groupButton" className="buttton-action-footer">
            {this.props.informationSteering && (
              <Button
                onClick={this.handleClickPopupDelete}
                className="pt-button pull-left btn-cancel btn-cancel-steering"
                disabled={
                  Object.values(this.state.isLoading).find(
                    item => item === true
                  ) !== undefined
                }
                loading={
                  this.state.isLoading &&
                  this.state.isLoading.isLoadingRemove === true
                }
              >
                <span className="text-content">HUỶ CHỈ ĐẠO</span>
                <span className=" pt-icon pt-icon-trash"></span>
              </Button>
            )}
            <Button
              onClick={this.props.onClickGoBack}
              className="pt-button btn-cancel"
              disabled={
                Object.values(this.state.isLoading).find(
                  item => item === true
                ) !== undefined
              }
            >
              <span className=" pt-icon icon-back"></span>
              <span className="text-content">Quay lại</span>
            </Button>
            <Button
              className="pt-button btn-blue-color"
              onClick={this.handleSubmit}
              loading={
                this.state.isLoading &&
                this.state.isLoading.isLoadingSubmit === true
              }
              disabled={
                Object.values(this.state.isLoading).find(
                  item => item === true
                ) !== undefined ||
                (this.props.informationSteering &&
                  this.state.departmentAssigned)
              }
            >
              <span className="text-content">Lưu</span>
              <span className="pt-icon icon-save"></span>
            </Button>
          </div>
        )}

        {!this.props.status && (
          <div className="buttton-actionfooter-fixtop buttton-action-footer">
            <Button
              className="pt-button btn-blue-color"
              onClick={this.handleSubmit}
              loading={
                this.state.isLoading &&
                this.state.isLoading.isLoadingSubmit === true
              }
              disabled={
                Object.values(this.state.isLoading).find(
                  item => item === true
                ) !== undefined ||
                (this.props.informationSteering &&
                  this.state.departmentAssigned)
              }
            >
              <span className="pt-icon icon-save"></span>
              <span className="text-content">Lưu</span>
            </Button>
            <Button
              onClick={this.props.onClickGoBack}
              className="pt-button btn-cancel"
              disabled={
                Object.values(this.state.isLoading).find(
                  item => item === true
                ) !== undefined
              }
            >
              <span className=" pt-icon icon-back"></span>
              <span className="text-content">Quay lại</span>
            </Button>
          </div>
        )}

        {this.props.status && (
          <div id="groupButton" className="buttton-action-footer">
            <Button
              onClick={this.props.onClickGoBack}
              className="pt-button btn-cancel"
              disabled={
                Object.values(this.state.isLoading).find(
                  item => item === true
                ) !== undefined
              }
            >
              <span className=" pt-icon icon-back"></span>
              <span className="text-content">Quay lại</span>
            </Button>
            <Button
              className="pt-button btn-blue-color"
              onClick={this.handleClickPopupDelete}
              loading={
                this.state.isLoading &&
                this.state.isLoading.isLoadingSubmit === true
              }
              disabled={
                Object.values(this.state.isLoading).find(
                  item => item === true
                ) !== undefined
              }
            >
              <span className="text-content">Giao lại</span>
              <span className="pt-icon icon-save"></span>
            </Button>
          </div>
        )}

        {this.props.status && (
          <div className="buttton-actionfooter-fixtop buttton-action-footer">
            <Button
              className="pt-button btn-blue-color"
              onClick={this.handleClickPopupDelete}
              loading={
                this.state.isLoading &&
                this.state.isLoading.isLoadingSubmit === true
              }
              disabled={
                Object.values(this.state.isLoading).find(
                  item => item === true
                ) !== undefined
              }
            >
              <span className="pt-icon icon-save"></span>
              <span className="text-content">Giao lại</span>
            </Button>

            <Button
              onClick={this.props.onClickGoBack}
              className="pt-button btn-cancel"
              disabled={
                Object.values(this.state.isLoading).find(
                  item => item === true
                ) !== undefined
              }
            >
              <span className=" pt-icon icon-back"></span>
              <span className="text-content">Quay lại</span>
            </Button>
          </div>
        )}

        {/* Hủy chỉ đạo */}
        <PopupConfirm
          isOpen={this.state.isOpenPopupDelete}
          onClose={this.handleClickPopupDelete}
          title={!this.props.status ? POPUP.HUY_CHI_DAO : POPUP.HUY_GIAO_LAI}
          text={POPUP.TEXT_BUTTON_SUBMIT}
          onSubmit={
            !this.props.status
              ? this.handleSubmitRemoveItem
              : this.handleSubmitReWorking
          }
        >
          {!this.props.status
            ? POPUP.TEXT_SUBMIT_BODY
            : POPUP.TEXT_SUBMIT_BODY_ALERTMISTAKE_DOCUMENT}
        </PopupConfirm>
      </div>
    )
  }
}

export default Assignment
