import React, { Component } from 'react'
import HeaderMenuItem from './HeaderMenuItem'
import PropTypes from 'prop-types'
import { Authorization } from 'components/auth'
import { checkPermissionMenu } from 'helpers/Helper'
import ChangePassword from './components/change-password'
import ChangeBackground from './components/change-background'
import Profile from './components/profile'

class HeaderMenu extends Component {
  constructor(props) {
    super(props)
    this.state = {
      isShowChangePassword: false,
      isShowChangeBackground: false,
      isShowProfile: false,
    }
    this.handleShowChangePassword = this.handleShowChangePassword.bind(this)
    this.handleShowChangeBackground = this.handleShowChangeBackground.bind(this)
    this.handleShowProfile = this.handleShowProfile.bind(this)
  }

  static propTypes = {
    menu: PropTypes.array.isRequired,
    quyensMenu: PropTypes.array,
  }

  handleShowChangePassword = show => {
    this.setState({
      isShowChangePassword: show,
    })
  }

  handleShowChangeBackground = show => {
    this.setState({
      isShowChangeBackground: show,
    })
  }

  handleShowProfile = show => {
    this.setState({
      isShowProfile: show,
    })
  }

  render() {
    const quyensMenu = this.props?.quyensMenu
    const Permission = (pers = []) => {
      return Authorization.Component(pers)(HeaderMenuItem)
    }

    return (
      <ul className="list-menu">
        {this.props.menu
          ?.filter(
            e =>
              !e?.permissionMenu ||
              (e?.permissionMenu &&
                checkPermissionMenu(quyensMenu, e.permissionMenu))
          )
          ?.map(item => {
            const Auth = Permission(item.permission)
            return (
              <Auth
                key={item.name}
                item={item}
                hideWhenclickMenu={this.props.hideWhenclickMenu}
                orPermission={item.orPermission}
                actions={this.props.actions}
              />
            )
          })}
        <li className="item logout-mobile">
          <span
            className="menu-link"
            onClick={() => this.handleShowProfile(true)}
          >
            <span className="icon-info-circle top-icon"></span>
            <span className="text-content">Thông tin cá nhân</span>
          </span>
        </li>
        <li className="item logout-mobile">
          <a
            className="menu-link"
            href="#/"
            onClick={() => this.handleShowChangeBackground(true)}
          >
            <span className="icon-settings top-icon"></span>
            <span className="text-content">Đổi hình nền</span>
          </a>
        </li>
        <li className="item logout-mobile">
          <a
            className="menu-link"
            href="#/"
            onClick={() => this.handleShowChangePassword(true)}
          >
            <span className="icon-lock top-icon"></span>
            <span className="text-content">ĐỔI MẬT KHẨU</span>
          </a>
        </li>
        <li className="item logout-mobile">
          <a className="menu-link" href="#/" onClick={this.props.handleLogout}>
            <span className="icon-logout top-icon"></span>
            <span className="text-content">ĐĂNG XUẤT</span>
          </a>
        </li>
        {this.state.isShowChangePassword && (
          <ChangePassword
            logout={this.props.handleLogout}
            onClose={() => this.handleShowChangePassword(false)}
          />
        )}
        {this.state.isShowChangeBackground && (
          <ChangeBackground
            onClose={() => this.handleShowChangeBackground(false)}
          />
        )}
        {this.state.isShowProfile && (
          <Profile onClose={() => this.handleShowProfile(false)} />
        )}
      </ul>
    )
  }
}

export default HeaderMenu
