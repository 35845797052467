import { Button, Checkbox, Intent } from '@blueprintjs/core'
import { HeadingTableSort } from 'components/common'
import { MasterLayout } from 'components/layout'
import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import * as Actions from 'actions'
import { headers } from 'constants/Table'
import { isEmpty } from 'lodash'
import Title from 'components/ui/title'

const ThemMoiLoaiVatTuPage = props => {
  const [vatTuChaId, setVatTuChaId] = useState(null)
  const [listNhomVatTu, setListNhomVatTu] = useState([])
  const [listLoaiVatTu, setListLoaiVatTu] = useState([
    {
      ten: '',
      moTa: '',
      isActive: true,
      isEdit: true,
    },
  ])

  const [checkValidate, setCheckValidate] = useState(false)
  const [isLoading, setIsLoading] = useState(false)

  const handleChangeVatTuChaId = value => {
    setCheckValidate(false)
    setVatTuChaId(value)
  }
  const handleBack = () => {
    props.history.goBack()
  }

  const handleSubmit = async () => {
    setCheckValidate(true)
    if (!vatTuChaId) {
      return
    }
    const payload = listLoaiVatTu
      ?.filter(i => !!i?.ten?.trim())
      ?.map(item => ({
        ten: item.ten?.trim(),
        mo_ta: item.moTa?.trim(),
        is_active: item.isActive,
        vat_tu_cha_id: vatTuChaId,
      }))
    if (isEmpty(payload)) {
      return props.actions.commonAddToasterMessage({
        message: 'Không được bỏ trống loại vật tư',
        intent: Intent.DANGER,
      })
    }
    setIsLoading(true)
    const response = await props.actions.postLoaiVatTu(payload)
    if (
      response?.payload?.data?.code === 200 ||
      response?.payload?.data?.code === 201
    ) {
      props.actions.commonAddToasterMessage({
        message: 'Lưu thành công',
        intent: Intent.SUCCESS,
      })
      handleBack()
    } else {
      props.actions.commonAddToasterMessage({
        message: 'Lưu không thành công',
        intent: Intent.DANGER,
      })
    }
    setIsLoading(false)
  }

  const handleGetNhomVatTu = async () => {
    const params = { isActive: true }
    const res = await props.actions.getDanhSachNhomVatTu(params)
    if (!res.error && res?.payload?.data?.result) {
      const dataNhomVatTu = res?.payload?.data?.result?.items?.map(item => ({
        name: item.ten,
        value: item.vatTuId,
      }))
      setListNhomVatTu(dataNhomVatTu)
    }
  }

  const handleAddRow = () => {
    setListLoaiVatTu([
      ...listLoaiVatTu,
      {
        ten: '',
        moTa: '',
        isActive: true,
        isEdit: true,
      },
    ])
  }

  const hanldeDeleteRow = index => {
    const result = listLoaiVatTu?.filter((item, i) => i !== index - 1)
    setListLoaiVatTu(result)
  }

  const handleCheckbox = index => {
    const result = listLoaiVatTu?.map((item, i) => {
      if (i === index - 1) {
        return {
          ...item,
          isActive: !item.isActive,
        }
      }
      return item
    })
    setListLoaiVatTu(result)
  }

  const handleChangeValue = (name, value, index) => {
    const result = listLoaiVatTu?.map((item, i) => {
      if (i === index - 1) {
        return {
          ...item,
          [name]: value,
        }
      }
      return item
    })
    setListLoaiVatTu(result)
  }

  useEffect(() => {
    handleGetNhomVatTu()
    // eslint-disable-next-line
  }, [])

  return (
    <MasterLayout typeSidebar="sidebarCalendar">
      <Title
        name="Thêm mới loại vật tư"
        icon="icon-s-plus"
        isGoBack
        history={props.history}
      />
      <div className="form-wrapper mt10">
        <div className="form-container">
          <div className="form-padding-box">
            <div className="pt-form-group pt-intent-danger">
              <div className="row">
                <div className="col-xs-12 col-sm-12 col-md-12 form-item">
                  <label className="pt-label">
                    Nhóm vật tư
                    <span className="pt-text-muted required-input">*</span>
                  </label>
                  <div className="pt-form-content">
                    <div className="pt-select">
                      <select
                        className="input"
                        value={vatTuChaId}
                        onChange={e => handleChangeVatTuChaId(e.target.value)}
                        style={{
                          width: '100%',
                          marginRight: '10px',
                          border: '1px solid #d2d2d2',
                          borderRadius: '4px',
                          height: '39px',
                          backgroundColor: '#fff',
                          boxShadow: '0 2px 2px 0 rgba(0, 0, 0, 0.05)',
                        }}
                      >
                        <option key="default" value="">
                          Chọn
                        </option>
                        {listNhomVatTu?.map((item, index) => (
                          <option key={index} value={item.value}>
                            {item.name}
                          </option>
                        ))}
                      </select>
                      {checkValidate && !vatTuChaId && (
                        <div className="pt-form-helper-text">
                          Trường này không được bỏ trống
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="list-supplies">
              <h3 className="title-supplies">Thêm mới loại vật tư</h3>
              <div className="table-display">
                <div className="table-display-container">
                  <HeadingTableSort data={headers.dsLoaiVatTuThemMoi} />
                  <div className="table-body-container">
                    <div className="sortableList">
                      {listLoaiVatTu.map((value, index) => (
                        <div className="table-rows" key={`item-${index}`}>
                          <div className="table-cols-content width-col-5">
                            <span className="order-content">
                              {index + 1 < 10 ? '0' + (index + 1) : index + 1}
                            </span>
                          </div>
                          <div className="table-cols-content width-col-30">
                            <input
                              type="text"
                              className="input-table"
                              placeholder="Nhập loại vật tư"
                              value={value.ten}
                              onChange={e =>
                                handleChangeValue(
                                  'ten',
                                  e.target.value,
                                  index + 1
                                )
                              }
                              maxLength={100}
                            />
                          </div>
                          <div className="table-cols-content width-col-55">
                            <input
                              type="text"
                              className="input-table"
                              placeholder="Nhập mô tả"
                              value={value.moTa}
                              onChange={e =>
                                handleChangeValue(
                                  'moTa',
                                  e.target.value,
                                  index + 1
                                )
                              }
                              maxLength={500}
                            />
                          </div>
                          <div className="table-cols-content width-col-5 text-center-col">
                            <span className="text-content center-check">
                              <Checkbox
                                onChange={() => handleCheckbox(index + 1)}
                                checked={value.isActive}
                              />
                            </span>
                          </div>
                          <div className="table-cols-content width-col-5">
                            <div className="group-action">
                              <div
                                className="action-icon"
                                onClick={() => hanldeDeleteRow(index + 1)}
                              >
                                <span className="pt-icon icon-delete-v2"></span>
                              </div>
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                  <div className="table-footer">
                    <button
                      className="add-button"
                      onClick={() => handleAddRow()}
                    >
                      <span className="pt-icon icon-s-plus"></span>
                      Thêm mới
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="buttton-action-footer">
          <Button
            onClick={() => handleBack()}
            className="pt-button btn-cancel"
            disabled={false}
          >
            <span className=" pt-icon icon-back"></span>
            <span className="text-content">Quay lại</span>
          </Button>
          <Button
            className="pt-button btn-blue-color"
            onClick={() => handleSubmit()}
            loading={isLoading}
            disabled={false}
          >
            <span className="text-content">Lưu</span>
            <span className="pt-icon icon-save"></span>
          </Button>
        </div>
      </div>
    </MasterLayout>
  )
}

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(Actions, dispatch),
})

export default connect(null, mapDispatchToProps)(ThemMoiLoaiVatTuPage)
