import React from 'react'
import PropTypes from 'prop-types'
import { bindActionCreators } from 'redux'
import { MasterLayout } from '../../components/layout'
import {
  ListFilter,
  ListFooter,
  ListItem,
} from '../../components/ReceiveDocument'
import { GridViewFilter } from '../../components/common'
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'
import { Intent, Button } from '@blueprintjs/core'
import { Authorization, Permission } from '../../components/auth'
import { headers } from '../../constants/Table'
import * as Actions from '../../actions'
import * as Types from '../../constants/Api'
import * as MESSAGE from '../../constants/MessageForm'
import * as Tool from '../../helpers'
import moment from 'moment'
import { Redirect } from 'react-router-dom'

const NOT_LEADER = '00000000-0000-0000-0000-000000000000'
const NO_REFRESH = false

class ListReceiveDocumentPage extends React.Component {
  constructor(props) {
    super(props)

    let status = this.getStatusNumber(props.match.params.status)
    let state = {
      filter: {
        status,
      },
      filterColumn: {
        phongBanTrinhId: '',
      },
      listStatus: [
        {
          name: 'Chưa xử lý',
          value: 0,
        },
        {
          name: 'Đang xử lý',
          value: 1,
        },
        {
          name: 'Đã xử lý',
          value: 2,
        },
      ],
      sort: undefined,
      isEmpty: false,
      isLoading: false,
      openFilter: undefined,
      check: false,
      page: 1,
      isRedirect: false,
      redirectTo: null,
      fileId: null,
      soCongVan: null,
      isError: false,
      listCheck: [],
      isAssign: false,
      actionTo: '',
      trichYeuSearched: '',
    }
    // Không truyền phòng ban trình khi quyền là ADMIN hoặc SUPPERADMIN
    if (
      !Authorization.vaild(this.props.auth.permission, [
        Permission.CONGVANDEN_QUANLY,
      ]) &&
      !this.props.auth.permission.includes(Permission.ADMIN) &&
      !this.props.auth.permission.includes(Permission.SUPERADMIN)
    ) {
      state.filterColumn.phongBanTrinhId = this.props.auth.mainDepartmentId
    }

    this.state = state

    this.handleSubmitRemoveItem = this.handleSubmitRemoveItem.bind(this)
    this.clickPagination = this.clickPagination.bind(this)
    this.submitFilter = this.submitFilter.bind(this)
    this.handleSort = this.handleSort.bind(this)
    this.handleClickDetail = this.handleClickDetail.bind(this)
    this.onChangeGridViewFilter = this.onChangeGridViewFilter.bind(this)
    this.onChangeDateGridViewFilter = this.onChangeDateGridViewFilter.bind(this)
    this.clearFilterColumn = this.clearFilterColumn.bind(this)
    this.onRemoveText = this.onRemoveText.bind(this)
    this.removeTextSearch = this.removeTextSearch.bind(this)
    this.handleDateChange = this.handleDateChange.bind(this)
  }

  static propTypes = {
    receiveDocuments: PropTypes.object.isRequired,
    units: PropTypes.array.isRequired,
    departments: PropTypes.array.isRequired,
  }

  componentDidMount() {
    this.props.actions.commonFetchDepartmentsLeader()
    this.props.actions.commonFetchUnitsRelative(this.props.auth.mainUnitId)

    let state = this.state
    // Không truyền phòng ban trình khi quyền là ADMIN hoặc SUPPERADMIN
    if (
      state.filter.status === Types.DOCUMENT_RECEIVE_NO_PROCESS &&
      !this.props.auth.permission.includes(Permission.ADMIN) &&
      !this.props.auth.permission.includes(Permission.SUPERADMIN)
    ) {
      state.filterColumn.phongBanTrinhId = NOT_LEADER
    }

    // Không truyền phòng ban trình khi quyền là ADMIN hoặc SUPPERADMIN
    if (
      !Authorization.vaild(this.props.auth.permission, [
        Permission.CONGVANDEN_QUANLY,
      ]) &&
      !this.props.auth.permission.includes(Permission.ADMIN) &&
      !this.props.auth.permission.includes(Permission.SUPERADMIN)
    ) {
      state.filterColumn.phongBanTrinhId = this.props.auth.mainDepartmentId
    }

    let preData = Tool.Utils.preData(
      `ListReceiveDocumentPage-${this.state.filter.status}`
    )

    if (preData) {
      state.filter = preData.filter
      state.page =
        (preData?.page?.selected || preData?.page) > 0
          ? preData?.page?.selected || preData?.page
          : 1
      state.sort = preData.sort
      state.filterColumn = {
        ...preData.filterColumn,
        ngayCongVan:
          preData.filterColumn && preData.filterColumn.ngayCongVan
            ? moment(preData.filterColumn.ngayCongVan)
            : null,
        ngayCapNhat:
          preData.filterColumn && preData.filterColumn.ngayCapNhat
            ? moment(preData.filterColumn.ngayCapNhat)
            : null,
        coQuanBanHanhId:
          preData.filterColumn && preData.filterColumn.coQuanBanHanhId
            ? preData.filterColumn.coQuanBanHanhId
            : '',
        coQuanBanHanh:
          preData.filterColumn && preData.filterColumn.coQuanBanHanhId === '#'
            ? preData.filterColumn.coQuanBanHanh
            : null,
      }
    } else {
      if (state.filter.status === 0) {
        state.filter.ngayCapNhat = {
          from: '',
          to: '',
        }
      } else {
        state.filter.ngayCapNhat = {
          from: moment().subtract(1, 'months').format('YYYY-MM-DD'),
          to: moment().format('YYYY-MM-DD'),
        }
      }
    }

    this.setState(state, () => {
      this.clickPagination(this.state.page)
    })
  }

  getStatusNumber(status) {
    let statusNumber

    if (status) {
      switch (status) {
        case 'dang-xu-ly':
          statusNumber = Types.DOCUMENT_RECEIVE_PROCESSING
          break
        case 'chua-xu-ly':
          statusNumber = Types.DOCUMENT_RECEIVE_NO_PROCESS
          break
        default:
          statusNumber = null
      }
    }

    return statusNumber
  }

  componentWillReceiveProps(props) {
    let status = this.getStatusNumber(props.match.params.status)

    if (status === this.state.filter.status) {
      return this.setState({
        openFilter: undefined,
      })
    }

    let state = {
      openFilter: false,
      sort: undefined,
      filter: {
        ...this.state.filter,
        status,
      },
    }

    if (
      Authorization.vaild(this.props.auth.permission, [
        Permission.CONGVANDEN_QUANLY,
      ]) &&
      state.filter.status === Types.DOCUMENT_RECEIVE_NO_PROCESS &&
      state.filterColumn
    ) {
      state.filterColumn.phongBanTrinhId = NOT_LEADER
    }

    this.setState(state)
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      prevState.filter.status !== this.state.filter.status &&
      this.state.filter.status === 0
    ) {
      this.setState({
        filter: {
          ...this.state.filter,
          ngayCapNhat: {
            from: '',
            to: '',
          },
        },
      })
    }
  }

  clickPagination(page) {
    this.setState(
      {
        page,
      },
      () => {
        this.submitFilter(this.state.filter, this.state.page, this.state.sort)
      }
    )
  }

  submitFilter(e = {}, page = 1, sort) {
    const filter = Object.assign({}, this.state.filter, {
      ...e,
      status: this.state.filter.status,
    })

    // filterColumn để lưu ở localStorage
    const filterColumn = {
      ...this.state.filterColumn,
      ngayCongVan: this.state.filterColumn.ngayCongVan
        ? moment(this.state.filterColumn.ngayCongVan).format('YYYY-MM-DD')
        : null,
      ngayCapNhat: this.state.filterColumn.ngayCapNhat
        ? moment(this.state.filterColumn.ngayCapNhat).format('YYYY-MM-DD')
        : null,
      coQuanBanHanhId: this.state.filterColumn.coQuanBanHanhId
        ? this.state.filterColumn.coQuanBanHanhId
        : null,
      coQuanBanHanh:
        this.state.filterColumn.coQuanBanHanhId &&
        this.state.filterColumn.coQuanBanHanhId === '#'
          ? this.state.filterColumn.coQuanBanHanh
          : null,
      soDen:
        this.state.filterColumn.soDen && this.state.filterColumn.soDen !== ''
          ? this.state.filterColumn.soDen
          : null,
      phongBanTrinhId:
        this.state.filterColumn.phongBanTrinhId &&
        this.state.filterColumn.phongBanTrinhId !== ''
          ? this.state.filterColumn.phongBanTrinhId
          : null,
      trichYeu:
        this.state.filterColumn.trichYeu &&
        this.state.filterColumn.trichYeu !== ''
          ? this.state.filterColumn.trichYeu
          : null,
      soCongVan:
        this.state.filterColumn.soCongVan &&
        this.state.filterColumn.soCongVan !== ''
          ? this.state.filterColumn.soCongVan
          : null,
    }

    // filterColumn request API
    const filterColumn1 = {
      ...this.state.filterColumn,
      ngayCongVan: this.state.filterColumn.ngayCongVan
        ? moment(this.state.filterColumn.ngayCongVan).format('YYYY-MM-DD')
        : null,
      ngayCapNhat: this.state.filterColumn.ngayCapNhat
        ? moment(this.state.filterColumn.ngayCapNhat).format('YYYY-MM-DD')
        : null,
      coQuanBanHanhId:
        this.state.filterColumn.coQuanBanHanhId &&
        this.state.filterColumn.coQuanBanHanhId !== '#'
          ? this.state.filterColumn.coQuanBanHanhId
          : null,
      coQuanBanHanh:
        this.state.filterColumn.coQuanBanHanhId &&
        this.state.filterColumn.coQuanBanHanhId === '#'
          ? !this.state.filterColumn.coQuanBanHanh
            ? ''
            : this.state.filterColumn.coQuanBanHanh
          : null,
      soDen:
        this.state.filterColumn.soDen && this.state.filterColumn.soDen !== ''
          ? this.state.filterColumn.soDen
          : null,
      phongBanTrinhId:
        this.state.filterColumn.phongBanTrinhId &&
        this.state.filterColumn.phongBanTrinhId !== ''
          ? this.state.filterColumn.phongBanTrinhId
          : null,
      trichYeu:
        this.state.filterColumn.trichYeu &&
        this.state.filterColumn.trichYeu !== ''
          ? this.state.filterColumn.trichYeu
          : null,
      soCongVan:
        this.state.filterColumn.soCongVan &&
        this.state.filterColumn.soCongVan !== ''
          ? this.state.filterColumn.soCongVan
          : null,
    }

    let isCallRequestAgain = false
    this.setState({ filter, page, isLoading: true, sort }, () => {
      Tool.Utils.preData(
        `ListReceiveDocumentPage-${this.state.filter.status}`,
        { filter, page, sort, filterColumn }
      )
      // tạm thời để refresh = false
      this.props.actions
        .receiveDocumentGet({ filter }, page, sort, NO_REFRESH, filterColumn1)
        .then(res => {
          if (
            page > 1 &&
            res.payload &&
            res.payload.data &&
            res.payload.data.result &&
            res.payload.data.result.result &&
            res.payload.data.result.result.total === 0
          ) {
            isCallRequestAgain = true
            return this.submitFilter(e, page - 1, sort)
          }
          let isEmpty = false
          let isError = false
          if (
            res &&
            !res.error &&
            res.payload &&
            res.payload.data &&
            res.payload.data.result &&
            res.payload.data.result.result &&
            res.payload.data.result.result.total === 0
          ) {
            isEmpty = true
          }

          if (
            res &&
            (res.error || (res.payload && res.payload.status !== 200))
          ) {
            isError = true
          }
          this.setState({ isEmpty, isError })
        })
        .then(() => {
          let isLoading = true
          if (isCallRequestAgain === false) {
            isLoading = false
          }
          this.setState({ isLoading, trichYeuSearched: filterColumn1.trichYeu })
          this.props.actions.refreshPage(false)
        })
    })
  }

  handleSubmitRemoveItem = id => {
    let total = 0
    let page = this.state.page
    if (
      this.props.receiveDocuments &&
      this.props.receiveDocuments.list &&
      this.props.receiveDocuments.list.total
    ) {
      total = this.props.receiveDocuments.list.total
    }

    this.props.actions
      .receiveDocumentDelete(id)
      .then(res => {
        if (res.error) {
          return this.props.actions.commonAddToasterMessage({
            message: MESSAGE.TOATS_MESSAGE_DELETE_FAIL,
            intent: Intent.DANGER,
          })
        }
        if (total > 0) {
          total -= 1
        }
        if (page > Math.ceil(total / Types.PAGINATION_LIMIT)) {
          page = Math.ceil(total / Types.PAGINATION_LIMIT)
        }

        if (page === 0) {
          page = 1
        }

        this.props.actions.refreshPage(true)
        this.props.actions.getNumberDocumentPage()
        // this.props.actions.getNumberEVNDocument()
        this.submitFilter(this.state.filter, page, this.state.sort)
        this.props.actions.commonAddToasterMessage({
          message: MESSAGE.TOATS_MESSAGE_DELETE_SUCCESS,
          intent: Intent.SUCCESS,
        })
      })
      .catch(err => {
        this.props.actions.commonAddToasterMessage({
          message: MESSAGE.TOATS_MESSAGE_DELETE_FAIL,
          intent: Intent.DANGER,
        })
      })
  }

  handleSort(sort) {
    this.submitFilter(this.state.filter, this.state.page, sort)
  }

  handleClickDetail = data => {
    if (!data) {
      return
    }

    if (!data?.daXem) {
      this.props.actions.postXemCongVanDen(data.congVanDenId)
    }

    this.setState({
      isRedirect: true,
      redirectTo: `/cong-van/cong-van-den/chi-tiet/${data.congVanDenId}`,
      fileId: data.fileId,
      soCongVan: data.soCongVan,
    })
  }

  onChangeGridViewFilter(e) {
    const value = e.target.value
    const name = e.target.name

    if (name === 'soDen' && !isNaN(value)) {
      return this.setState({
        filterColumn: {
          ...this.state.filterColumn,
          [name]: value,
        },
      })
    }

    if (name !== 'soDen') {
      this.setState(
        {
          filterColumn: {
            ...this.state.filterColumn,
            [name]: value,
          },
        },
        () => {
          if (
            name === 'coQuanBanHanhId' ||
            name === 'phongBanTrinhId' ||
            name === 'trangThai'
          ) {
            this.submitFilter()
          }
        }
      )
    }
  }

  onChangeDateGridViewFilter(name, e) {
    this.setState(
      {
        filterColumn: {
          ...this.state.filterColumn,
          [name]: e,
        },
      },
      () => {
        this.submitFilter()
      }
    )
  }

  async clearFilterColumn(filter, check) {
    let phongBanTrinhId = ''
    // Không truyền phòng ban trình khi quyền là ADMIN hoặc SUPPERADMIN
    if (
      this.state.filter.status === Types.DOCUMENT_RECEIVE_NO_PROCESS &&
      !this.props.auth.permission.includes(Permission.ADMIN) &&
      !this.props.auth.permission.includes(Permission.SUPERADMIN)
    ) {
      phongBanTrinhId = NOT_LEADER
    }

    // Không truyền phòng ban trình khi quyền là ADMIN hoặc SUPPERADMIN
    if (
      !Authorization.vaild(this.props.auth.permission, [
        Permission.CONGVANDEN_QUANLY,
      ]) &&
      !this.props.auth.permission.includes(Permission.ADMIN) &&
      !this.props.auth.permission.includes(Permission.SUPERADMIN)
    ) {
      phongBanTrinhId = this.props.auth.mainDepartmentId
    }

    if (check) {
      await this.setState({
        filterColumn: {
          phongBanTrinhId,
        },
        listCheck: [],
      })
    }
    await this.submitFilter(filter)
  }

  onRemoveText(name) {
    this.setState(
      {
        filterColumn: {
          ...this.state.filterColumn,
          [name]: '',
        },
      },
      () => {
        this.submitFilter()
      }
    )
  }

  removeTextSearch(name) {
    this.setState({
      filter: {
        ...this.state.filter,
        [name]: '',
      },
    })
  }

  handleDateChange(name, pos, e) {
    this.setState(
      {
        filter: {
          ...this.state.filter,
          [name]: {
            ...this.state.filter[name],
            [pos]: e ? moment(e).format('YYYY-MM-DD') : undefined,
          },
        },
      },
      () => {
        this.submitFilter()
      }
    )
  }

  handleCheck = id => {
    if (!id) {
      return
    }
    let listCheck = this.state.listCheck
    if (this.state.listCheck.find(item => item === id)) {
      listCheck = listCheck.filter(item => item !== id)
    } else {
      listCheck.push(id)
    }

    this.setState({ listCheck })
  }

  handleClickAssign = () => {
    if (this.state.listCheck.length > 1) {
      return this.setState({
        isAssign: true,
        redirectTo: `/cong-van-den/chi-dao`,
        actionTo: 'ChiDao',
      })
    }

    this.setState({
      isAssign: true,
      redirectTo: `/cong-van/cong-van-den/chi-tiet/${this.state.listCheck[0]}`,
      actionTo: 'ChiDao',
    })
  }

  checkHeaderTable = () => {
    if (this.props.match.params.status === 'chua-xu-ly') {
      return headers.listReceiveDocument
    } else if (this.props.match.params.status === 'dang-xu-ly') {
      return headers.listReceiveDocument2
    } else {
      return headers.listReceiveDocument3
    }
  }

  render() {
    if (this.state.isRedirect) {
      return (
        <Redirect
          push
          to={{
            pathname: this.state.redirectTo,
            state: {
              fileId: this.state.fileId,
              soCongVan: this.state.soCongVan,
              from: this.props.location.pathname,
            },
          }}
        />
      )
    }
    if (this.state.isAssign) {
      return (
        <Redirect
          push
          to={{
            pathname: this.state.redirectTo,
            state: {
              list: this.state.listCheck,
              action: this.state.actionTo,
            },
          }}
        />
      )
    }
    const AuthElement = Authorization.Element
    return (
      <MasterLayout typeSidebar="documentary">
        {/* Heading */}
        <div className="heading-page">
          <div className="row">
            <div className="col-xs-12">
              <h3 className="text-heading-page">
                <span className="icon-Cong_van icon-heading"></span>
                {this.props.match.params.status === 'chua-xu-ly' && (
                  <span className="text-heading">Công văn chưa xử lý</span>
                )}
                {this.props.match.params.status === 'dang-xu-ly' && (
                  <span className="text-heading">Công văn đang xử lý</span>
                )}
                {this.props.match.params.status === undefined && (
                  <span className="text-heading">Công văn đến</span>
                )}
              </h3>
              <AuthElement permission={[Permission.CONGVANDEN_QUANLY]}>
                <Link
                  to="/cong-van/cong-van-den/them-moi"
                  className="btn-action pull-right hidden-btn"
                >
                  <span className="name-btn">NHẬP CÔNG VĂN</span>
                  <span className="pt-icon pt-icon-small-plus"></span>
                </Link>
              </AuthElement>
              {this.state.listCheck.length > 0 && (
                <Button
                  className="btn-action pull-right mr10"
                  onClick={this.handleClickAssign}
                >
                  <span className="name-btn">Chỉ đạo</span>
                  <span className="pt-icon pt-icon-small-plus"></span>
                </Button>
              )}
            </div>
          </div>
        </div>

        {/* Table List */}
        <div className="page-list-container">
          <ListFilter
            filter={this.state.filter}
            units={this.props.units}
            status={this.state.listStatus}
            departments={this.props.departments}
            onSubmit={this.submitFilter}
            auth={this.props.auth}
            match={this.props.match}
            open={this.state.openFilter}
            readOnly={this.props.readOnly}
            actions={this.props.actions}
            clearFilterColumn={this.clearFilterColumn}
            removeTextSearch={this.removeTextSearch}
            handleDateChange={this.handleDateChange}
          />

          <div
            className="table-container"
            style={{
              paddingBottom: 0,
              border: 0,
              background:
                Math.ceil(
                  this.props.receiveDocuments.list.total /
                    Types.PAGINATION_LIMIT
                ) > 1
                  ? '#fff'
                  : '#eff3f5',
            }}
          >
            <GridViewFilter
              isLoading={this.state.isLoading}
              classNames={'pt-table fix-table-before'}
              page={this.state.page}
              headers={this.checkHeaderTable()}
              itemShape={ListItem}
              data={this.props.receiveDocuments.list.items}
              onHandleSubmitRemoveItem={this.handleSubmitRemoveItem}
              onHandleClickItem={this.HandleClickItem}
              onClickDetail={this.handleClickDetail}
              onFilter={this.handleSort}
              isEmpty={this.state.isEmpty}
              isError={this.state.isError}
              onChange={this.onChangeGridViewFilter}
              onChangeDate={this.onChangeDateGridViewFilter}
              coQuanBanHanhId={this.props.units}
              phongBanTrinhId={this.props.departments}
              filterColumn={this.state.filterColumn}
              onSubmit={this.submitFilter}
              trangThai={this.state.listStatus}
              onRemoveText={this.onRemoveText}
              auth={this.props.auth}
              pageCount={Math.ceil(
                this.props.receiveDocuments.list.total / Types.PAGINATION_LIMIT
              )}
              isType="CVD"
              match={this.props.match}
              isCVDQL={Authorization.vaild(this.props.auth.permission, [
                Permission.CONGVANDEN_QUANLY,
              ])}
              onCheck={this.handleCheck}
              list={this.state.listCheck}
              actions={this.props.actions}
              isCanCheckDocument={!!this.state.trichYeuSearched}
              quyensMenu={this.props.quyensMenu}
            />

            {!this.state?.isLoading &&
              !this.props?.isEmpty &&
              !this.props?.isError && (
                <ListFooter
                  current={this?.state?.page}
                  total={Math.ceil(
                    this?.props?.receiveDocuments?.list?.total /
                      Types.PAGINATION_LIMIT
                  )}
                  onChange={this.clickPagination}
                />
              )}
          </div>
        </div>
      </MasterLayout>
    )
  }
}

const mapStateToProps = state => ({
  config: state.common.config,

  auth: state.auth,

  receiveDocuments: {
    ...state.receiveDocuments,
    list: {
      ...state.receiveDocuments.list,
      items: state.receiveDocuments.list.items.map(
        id => state.entities.receiveDocuments[id]
      ),
    },
  },

  units: state.common.relativeUnits,

  departments: state.common.leaderDepartments,

  readOnly: state.common.enableReadOnly,
  quyensMenu: state.common.quyensMenu,
})

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(Actions, dispatch),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ListReceiveDocumentPage)
