import React from 'react'
import { MasterLayout } from '../../components/layout'
import {
  DetailBoxAction,
  SaveDocument,
  SendMail,
  Assign,
  AssignmentInformation,
  AdditionalAssign,
  ExecutingViewInternal,
  AlertMistake,
  TaskCreate,
} from '../../components/Work'
import {
  ExecutingView,
  InformationSteering,
  LoadingLine,
  LoadingItem,
  DetailFileContentV2,
} from 'components/common'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Intent } from '@blueprintjs/core'
import { bindActionCreators } from 'redux'
import { Permission, Authorization } from '../../components/auth'
import * as Actions from '../../actions'
import * as Tool from '../../helpers'
import * as Types from '../../constants/Api'
import * as MESSAGE from '../../constants/MessageForm'
import ReactDOM from 'react-dom'
import classnames from 'classnames'
import { Flowdocumentary } from '../../components/ReceiveDocument'
import { FlowSendDocumentary } from '../../components/SendDocument'
import { get, size } from 'lodash'
import { isValidCondition } from 'helpers/Helper'
import { FlowInternalDocumentary } from '../../components/InternalDocument'
import { variables } from 'constants/variables'
import UserViewed from 'components/common/user-viewed'

const CONGVAN_NOIBO = 2
const ACTIONS_VIEW = {
  TAO_CONG_VIEC: 'TAO_CONG_VIEC',
}
const DOCUMENT_MISTAKE = 1
const WORK_MISTAKE = 2
const cabinetTypes = {
  [Types.CABINET_UNIT]: 'unit',
  [Types.CABINET_DEPARTMENT]: 'department',
  [Types.HOSO_CONGVIEC]: 'profile',
  [Types.CABINET_PERSONAL]: 'personal',
}

class DetailWorkPage extends React.Component {
  static propTypes = {
    assignmentInformation: PropTypes.object.isRequired,
    archives: PropTypes.object.isRequired,
    staffs: PropTypes.array,
  }

  refHandlers = {
    detailFileContent: ref => (this.detailFileContent = ref),
  }

  constructor(props) {
    super(props)
    this.handleClickGoBack = this.handleClickGoBack.bind(this)
    this.handleClickAssign = this.handleClickAssign.bind(this)
    this.handleClickUpdateAssignment =
      this.handleClickUpdateAssignment.bind(this)
    this.handleClickSaveDocument = this.handleClickSaveDocument.bind(this)
    this.handleClickAssignmentHistory =
      this.handleClickAssignmentHistory.bind(this)
    this.handleClickSendMail = this.handleClickSendMail.bind(this)
    this.handleClickShowInfoMobile = this.handleClickShowInfoMobile.bind(this)
    this.closeModulesDetail = this.closeModulesDetail.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
    this.handleClickAdditionalAssign =
      this.handleClickAdditionalAssign.bind(this)
    // this.handleClickDetailInfo = this.handleClickDetailInfo.bind(this);
    this.handleSelectSaveTo = this.handleSelectSaveTo.bind(this)
    this.handleSaveArchive = this.handleSaveArchive.bind(this)
    this.handleSendMistake = this.handleSendMistake.bind(this)
    this.handleClickInformationWork = this.handleClickInformationWork.bind(this)
    this.handleClickInformationAssign =
      this.handleClickInformationAssign.bind(this)
    this.handleFinishLoadingMigratedFile =
      this.handleFinishLoadingMigratedFile.bind(this)
    this.handleClickComplete = this.handleClickComplete.bind(this)
    this.handleScroll = this.handleScroll.bind(this)
    this.handleClickShowSaveDocument =
      this.handleClickShowSaveDocument.bind(this)
    this.handleClickFlowDocument = this.handleClickFlowDocument.bind(this)
    this.handleClickCancelAlertMistake =
      this.handleClickCancelAlertMistake.bind(this)
    this.handleClickMoreDepartmentExecutingView =
      this.handleClickMoreDepartmentExecutingView.bind(this)
    this.handleSubmitRemoveItem = this.handleSubmitRemoveItem.bind(this)
  }

  state = {
    showResults: false,
    showListAction: true,
    showSection: 'noContent',
    isShowMobileAssignmentHistory: false,
    departmentWork: null,
    workDepartmentAssignInformations: null,
    isUpdateAssignment: false,
    workDepartmentAssignId: null,
    workDepartmentAssignItem: null,
    showInformationSteering: false,
    saveTo: [
      ...(Authorization.vaild(this.props.auth.permission, [
        Permission.TUTAILIEUDONVI_QUANLY,
      ])
        ? [
            {
              id: Types.CABINET_UNIT,
              name: 'Tủ công ty',
              disabled: !Authorization.vaild(this.props.auth.permission, [
                Permission.TUTAILIEUDONVI_QUANLY,
              ]),
            },
          ]
        : []),
      {
        id: Types.CABINET_DEPARTMENT,
        name: 'Tủ phòng ban',
      },
      // {
      //   id: Types.HOSO_CONGVIEC,
      //   name: 'Hồ sơ công việc',
      //   disabled: !Authorization.vaild(this.props.auth.permission, [
      //     Permission.TUTAILIEUPHONGBAN,
      //   ]),
      // },
      {
        id: Types.CABINET_PERSONAL,
        name: 'Tủ cá nhân',
      },
    ],
    showAssignmentInformation: false,
    showSendEmail: false,
    isShowMobile: false,
    isLoading: false,
    isLoadingSubData: false,
    workId: this.props.match.params.id,
    file: null,
    informationSteeringDepartment: null,
    assignmentInformation: null,
    isLoadingData: {
      work: true,
      file: true,
      documentNumber: true,
    },
    seeToKnow: null,
    isHasReceiveDocumentId: false,
    isLeaderUnit: false,
    isLoadingMigratedFile: true,
    fileFromList: null, // File từ danh sách
    documentNumberFromList: null, // Số công văn từ danh sách
    isLoadedApi: {
      departments: false,
      staffs: false,
      workPersonalInformation: false,
      workDepartmentInformation: false,
      steeringInformation: false,
      executingView: false,
      documentCabinetList: false,
      flowDocument: false,
    },
    isSubmit: false,
    boxMenuFixTop: false,
    isLoadingSaveDocument: false,
    isCheck: false,
    executingViewAssign: null,
    filesThamKhao: [],
  }

  onMenuItem = name => {
    if (!name) {
      return
    }

    this.setState({
      showResults: true,
      showListAction: false,
      showSection: name,
    })
  }

  handleFinishLoadingMigratedFile = () => {
    this.setState({ isLoadingMigratedFile: !this.state.isLoadingMigratedFile })
  }

  handleClickGoBack = e => {
    e && e.preventDefault()
    this.setState({
      showResults: false,
      showListAction: true,
      showSection: 'noContent',
    })
  }

  handleClickSaveDocument = e => {
    e.preventDefault()
    ReactDOM.findDOMNode(this).scrollIntoView({ behavior: 'smooth' })
    this.setState({
      showResults: false,
      showListAction: false,
      isLoadingSubData: true,
      showSection: 'LuuTuTaiLieu',
    })

    let isLoadedApi = this.state.isLoadedApi
    let { departmentWorks } = this.props
    let request

    // if (
    //   departmentWorks &&
    //   departmentWorks.item &&
    //   departmentWorks.item.congVanDenId &&
    //   isLoadedApi &&
    //   isLoadedApi.documentCabinetList === false
    // ) {
    //   request = this.props.actions.getListCabinetOfDocument(departmentWorks.item.congVanDenId);
    // }

    if (
      departmentWorks &&
      departmentWorks.item &&
      departmentWorks.item.congVanNoiBoId &&
      isLoadedApi &&
      isLoadedApi.documentCabinetList === false
    ) {
      request = this.props.actions.getListCabinetOfDocument(
        departmentWorks.item.congVanNoiBoId,
        CONGVAN_NOIBO
      )
    }

    if (request) {
      request.then(res => {
        if (res.payload && res.payload.status === 200) {
          isLoadedApi = {
            ...isLoadedApi,
            documentCabinetList: true,
          }
        }
        this.setState({
          showResults: true,
          showListAction: false,
          isLoadingSubData: false,
          isLoadedApi,
        })
      })
    } else {
      this.setState({
        showResults: true,
        showListAction: false,
        isLoadingSubData: false,
        isLoadedApi,
      })
    }
  }

  handleClickFlowDocument = async e => {
    e.preventDefault()
    ReactDOM.findDOMNode(this).scrollIntoView({ behavior: 'smooth' })
    this.setState({
      showResults: false,
      showListAction: false,
      isLoadingSubData: true,
      showSection: 'LuongCongVan',
    })

    let { isCheck, isLoadedApi } = this.state

    if (
      this.props.departmentWorks &&
      this.props.departmentWorks.item &&
      this.props.departmentWorks.item.congVanDiId &&
      isLoadedApi &&
      isLoadedApi.flowDocument === false
    ) {
      await this.props.actions
        .getFlowDocumentarySendDocument(
          this.props.departmentWorks.item.congVanDiId
        )
        .then(res => {
          if (res.payload && res.payload.status === 200) {
            isLoadedApi = {
              ...isLoadedApi,
              flowDocument: true,
            }
          }
          isCheck = true
        })
    }

    if (
      this.props.departmentWorks &&
      this.props.departmentWorks.item &&
      !this.props.departmentWorks.item.congVanDiId &&
      isLoadedApi &&
      isLoadedApi.flowDocument === false
    ) {
      await this.props.actions
        .getFlowDocumentaryReceiveDocument(
          this.props.departmentWorks.item.congVanDenId
        )
        .then(res => {
          if (res.payload && res.payload.status === 200) {
            isLoadedApi = {
              ...isLoadedApi,
              flowDocument: true,
            }
          }
        })
    }

    await this.setState({
      isCheck,
      showResults: true,
      isLoadingSubData: false,
      isLoadedApi,
    })
  }

  getListDisable = async type => {
    const {
      actions,
      departmentWorks: { item },
    } = this.props

    const id = item.congVanId || item.congVanNoiBoId
    const docType = item.congVanNoiBoId ? 2 : 1

    actions.getListCabinetOfDocument(id, docType)
  }

  handleClickAssignmentHistory = e => {
    e.preventDefault()
    ReactDOM.findDOMNode(this).scrollIntoView({ behavior: 'smooth' })
    this.setState({
      showResults: true,
      showListAction: false,
      showSection: 'LichSuGiaoViec',
    })
  }

  handleClickSendMail = e => {
    e.preventDefault()
    ReactDOM.findDOMNode(this).scrollIntoView({ behavior: 'smooth' })
    this.setState({
      showResults: true,
      showListAction: false,
      showSection: 'GuiEmail',
      showSendEmail: !this.state.showSendEmail,
    })
  }

  handleClickAlertMistake = e => {
    e.preventDefault()
    ReactDOM.findDOMNode(this).scrollIntoView({ behavior: 'smooth' })
    this.setState({
      showResults: true,
      showListAction: false,
      showSection: 'AlertMistake',
    })
  }

  handleClickShowSaveDocument = async () => {
    let { isLoadedApi } = this.state
    let { departmentWorks } = this.props
    let request

    if (
      departmentWorks &&
      departmentWorks.item &&
      departmentWorks.item.congVanDenId &&
      isLoadedApi &&
      isLoadedApi.documentCabinetList === false
    ) {
      request = this.props.actions.getListCabinetOfDocument(
        departmentWorks.item.congVanDenId
      )
    }

    if (
      departmentWorks &&
      departmentWorks.item &&
      departmentWorks.item.congVanNoiBoId &&
      isLoadedApi &&
      isLoadedApi.documentCabinetList === false
    ) {
      request = this.props.actions.getListCabinetOfDocument(
        departmentWorks.item.congVanNoiBoId,
        CONGVAN_NOIBO
      )
    }

    if (request) {
      await this.setState({ isLoadingSaveDocument: true })
      request
        .then(res => {
          if (res.payload && res.payload.status === 200) {
            isLoadedApi = {
              ...isLoadedApi,
              documentCabinetList: true,
            }
          }
          this.setState({
            isLoadedApi,
            isLoadingSaveDocument: false,
          })
        })
        .catch(err => {
          this.setState({ isLoadingSaveDocument: false })
        })
    }
  }

  handleClickAssign = async e => {
    e.preventDefault()
    ReactDOM.findDOMNode(this).scrollIntoView({ behavior: 'smooth' })
    await this.setState({
      showResults: false,
      showListAction: false,
      isLoadingSubData: true,
      showSection: 'GiaoViec',
    })
    let isLoadedApi = this.state.isLoadedApi
    if (this.state.isLeaderUnit) {
      // Lanh dao don vi
      if (isLoadedApi && isLoadedApi.departments === false) {
        await this.props.actions.commonFetchDepartments().then(res => {
          if (res.payload && res.payload.status === 200) {
            isLoadedApi = {
              ...isLoadedApi,
              departments: true,
            }
          }
        })
      }
      if (
        this.props.auth.mainDepartmentId &&
        isLoadedApi &&
        isLoadedApi.staffs === false
      ) {
        await this.props.actions
          .commonFetchStaffByDepartmentId(this.props.auth.mainDepartmentId)
          .then(res => {
            if (res.payload && res.payload.status === 200) {
              isLoadedApi = {
                ...isLoadedApi,
                staffs: true,
              }
            }
          })
      }
    } else {
      if (
        this.props.auth.mainDepartmentId &&
        isLoadedApi &&
        isLoadedApi.staffs === false
      ) {
        await this.props.actions
          .commonFetchStaffByDepartmentId(this.props.auth.mainDepartmentId)
          .then(res => {
            isLoadedApi = {
              ...isLoadedApi,
              staffs: true,
            }
          })
      }
    }
    this.setState({
      showResults: true,
      showListAction: false,
      showSection: 'GiaoViec',
      isLoadingSubData: false,
      isLoadedApi,
    })
  }

  handleClickAdditionalAssign = async () => {
    ReactDOM.findDOMNode(this).scrollIntoView({ behavior: 'smooth' })
    // Phieu giao viec
    await this.setState({
      showResults: false,
      showListAction: false,
      isLoadingSubData: true,
      showSection: 'GiaoViecBoSung',
    })

    let isLoadedApi = this.state.isLoadedApi
    if (this.state.isLeaderUnit) {
      // Lanh dao don vi
      if (isLoadedApi && isLoadedApi.departments === false) {
        await this.props.actions.commonFetchDepartments().then(res => {
          isLoadedApi = {
            ...isLoadedApi,
            departments: true,
          }
        })
      }
      if (
        this.props.auth.mainDepartmentId &&
        isLoadedApi &&
        isLoadedApi.staffs === false
      ) {
        await this.props.actions
          .commonFetchStaffByDepartmentId(this.props.auth.mainDepartmentId)
          .then(res => {
            isLoadedApi = {
              ...isLoadedApi,
              staffs: true,
            }
          })
      }
    } else {
      if (
        this.props.auth.mainDepartmentId &&
        isLoadedApi &&
        isLoadedApi.staffs === false
      ) {
        await this.props.actions
          .commonFetchStaffByDepartmentId(this.props.auth.mainDepartmentId)
          .then(res => {
            if (res.payload && res.payload.status === 200) {
              isLoadedApi = {
                ...isLoadedApi,
                staffs: true,
              }
            }
          })
      }
    }

    if (
      this.state.workDepartmentAssignId &&
      isLoadedApi &&
      isLoadedApi.workPersonalInformation === false
    ) {
      await this.props.actions
        .getInformationAssignToUpdate(this.state.workDepartmentAssignId)
        .then(res => {
          if (res.payload && res.payload.status === 200) {
            this.setState({
              workDepartmentAssignItem: res.payload.data.result
                ? res.payload.data.result
                : null,
            })
            isLoadedApi = {
              ...isLoadedApi,
              workPersonalInformation: true,
            }
          } else {
            this.setState({
              showResults: false,
              showListAction: true,
              isLoadingSubData: false,
              showSection: 'noContent',
            })
          }
        })
        .catch(e => {
          this.setState({
            showResults: false,
            showListAction: true,
            isLoadingSubData: false,
            showSection: 'noContent',
          })
        })
    }

    this.setState({
      showResults: true,
      showListAction: false,
      showSection: 'GiaoViecBoSung',
      isLoadingSubData: false,
      isLoadedApi,
    })
  }

  handleClickUpdateAssignment = async () => {
    ReactDOM.findDOMNode(this).scrollIntoView({ behavior: 'smooth' })
    // Phieu giao viec
    await this.setState({
      showResults: false,
      showListAction: false,
      isLoadingSubData: true,
      showSection: 'GiaoViec',
      isUpdateAssignment: true,
    })

    let isLoadedApi = this.state.isLoadedApi
    if (this.state.isLeaderUnit) {
      // Lanh dao don vi
      if (isLoadedApi && isLoadedApi.departments === false) {
        await this.props.actions.commonFetchDepartments().then(res => {
          if (res.payload && res.payload.status === 200) {
            isLoadedApi = {
              ...isLoadedApi,
              departments: true,
            }
          }
        })
      }
      if (
        this.props.auth.mainDepartmentId &&
        isLoadedApi &&
        isLoadedApi.staffs === false
      ) {
        await this.props.actions
          .commonFetchStaffByDepartmentId(this.props.auth.mainDepartmentId)
          .then(res => {
            if (res.payload && res.payload.status === 200) {
              isLoadedApi = {
                ...isLoadedApi,
                staffs: true,
              }
            }
          })
      }
    } else {
      if (
        this.props.auth.mainDepartmentId &&
        isLoadedApi &&
        isLoadedApi.staffs === false
      ) {
        await this.props.actions
          .commonFetchStaffByDepartmentId(this.props.auth.mainDepartmentId)
          .then(res => {
            if (res.payload && res.payload.status === 200) {
              isLoadedApi = {
                ...isLoadedApi,
                staffs: true,
              }
            }
          })
      }
    }
    if (
      this.state.workDepartmentAssignId &&
      isLoadedApi &&
      isLoadedApi.workPersonalInformation === false
    ) {
      await this.props.actions
        .getInformationAssignToUpdate(this.state.workDepartmentAssignId)
        .then(res => {
          if (res.payload && res.payload.status === 200) {
            this.setState({
              workDepartmentAssignItem: res.payload.data.result
                ? res.payload.data.result
                : null,
            })
            isLoadedApi = {
              ...isLoadedApi,
              workPersonalInformation: true,
            }
          } else {
            this.setState({
              showResults: false,
              showListAction: true,
              isLoadingSubData: false,
              showSection: 'noContent',
            })
          }
        })
        .catch(e => {
          this.setState({
            showResults: false,
            showListAction: true,
            isLoadingSubData: false,
            showSection: 'noContent',
          })
        })
    }

    this.setState({
      showResults: true,
      showListAction: false,
      showSection: 'GiaoViec',
      isLoadingSubData: false,
      isLoadedApi,
    })
  }

  handleClickExecutingView = () => {
    ReactDOM.findDOMNode(this).scrollIntoView({ behavior: 'smooth' })
    this.setState({
      showResults: false,
      showListAction: false,
      isLoadingSubData: true,
      showSection: 'XemThucHien',
    })

    let isLoadedApi = this.state.isLoadedApi
    let { departmentWorks } = this.props
    let request
    let executingViewAssign = null
    let isNewAssignDoc = false

    if (
      departmentWorks &&
      departmentWorks.item &&
      departmentWorks.item.dsPhieuGiaoViec &&
      isLoadedApi &&
      isLoadedApi.executingView === false
    ) {
      request = this.props.actions.getExecutingViewAssign(
        departmentWorks.item.phieuGiaoViecId,
        true
      )
      isNewAssignDoc = true
    }

    if (
      departmentWorks &&
      departmentWorks.item &&
      departmentWorks.item.congVanDenId &&
      isLoadedApi &&
      isLoadedApi.executingView === false
    ) {
      request = this.props.actions.getExecutingView(
        departmentWorks.item.congVanDenId
      )
    }

    if (
      departmentWorks &&
      departmentWorks.item &&
      departmentWorks.item.congVanNoiBoId &&
      isLoadedApi &&
      isLoadedApi.executingView === false
    ) {
      request = this.props.actions.getExecutingViewInternalDocument(
        departmentWorks.item.congVanNoiBoId
      )
    }

    if (request) {
      request.then(res => {
        if (res.payload && res.payload.status === 200) {
          isLoadedApi = {
            ...isLoadedApi,
            executingView: true,
          }
        }
        if (
          res.payload &&
          res.payload.data &&
          res.payload.data.result &&
          isNewAssignDoc
        ) {
          executingViewAssign = [res.payload.data.result]
        }
        this.setState({
          showResults: true,
          showListAction: false,
          isLoadingSubData: false,
          isLoadedApi,
          executingViewAssign,
        })
      })
    } else {
      this.setState({
        showResults: true,
        showListAction: false,
        isLoadingSubData: false,
        isLoadedApi,
      })
    }
  }

  handleClickInformationAssign = async e => {
    e.preventDefault()
    ReactDOM.findDOMNode(this).scrollIntoView({ behavior: 'smooth' })
    await this.setState({
      showResults: false,
      showListAction: false,
      isLoadingSubData: true,
      showSection: 'ThongTinChiDao',
    })

    let isLoadedApi = this.state.isLoadedApi
    if (
      this.state.departmentWork &&
      this.state.departmentWork.congVanDenId &&
      isLoadedApi &&
      isLoadedApi.steeringInformation === false
    ) {
      await this.props.actions
        .InformationSteering(this.state.departmentWork.congVanDenId)
        .then(res => {
          if (res.payload && res.payload.data && res.payload.data.result) {
            isLoadedApi = {
              ...isLoadedApi,
              steeringInformation: true,
            }
            this.setState({
              informationSteeringDepartment: res.payload.data.result,
            })
          }
        })
    }
    await this.setState({
      showResults: true,
      isLoadingSubData: false,
      isLoadedApi,
    })
  }

  handleClickInformationWork = async e => {
    e.preventDefault()
    ReactDOM.findDOMNode(this).scrollIntoView({ behavior: 'smooth' })
    await this.setState({
      showResults: false,
      showListAction: false,
      isLoadingSubData: true,
      showSection: 'ThongTinGiaoViec',
    })

    let isLoadedApi = this.state.isLoadedApi
    if (
      this.state.workId &&
      this.props.match &&
      this.props.match.params &&
      this.props.match.params.status === 'da-giao' &&
      isLoadedApi &&
      isLoadedApi.workDepartmentInformation === false
    ) {
      await this.props.actions
        .workFetchDepartmentAssignInformations(this.state.workId)
        .then(() => {
          let { workAssignInformations } = this.props
          if (!workAssignInformations || !workAssignInformations.department) {
            return
          }
          isLoadedApi = {
            ...isLoadedApi,
            workDepartmentInformation: true,
          }
          let workDepartmentAssignInformations =
            this.props.workAssignInformations.department || null
          this.setState({ workDepartmentAssignInformations })
        })
    }

    if (
      this.state.departmentWork &&
      this.state.departmentWork.phieuGiaoViecChinhId &&
      this.props.match &&
      this.props.match.params &&
      this.props.match.params.status === 'chua-giao' &&
      isLoadedApi &&
      isLoadedApi.workDepartmentInformation === false
    ) {
      await this.props.actions
        .getInformationAssign(this.state.departmentWork.phieuGiaoViecChinhId)
        .then(res => {
          if (res.payload && res.payload.data && res.payload.data.result) {
            isLoadedApi = {
              ...isLoadedApi,
              workDepartmentInformation: true,
            }
            let workDepartmentAssignInformations = res.payload.data.result
            this.setState({ workDepartmentAssignInformations })
          } else {
            isLoadedApi = {
              ...isLoadedApi,
              workDepartmentInformation: false,
            }
          }
        })
    }

    await this.setState({
      showResults: true,
      isLoadingSubData: false,
      isLoadedApi,
    })
  }

  handleClickShowInfoMobile = () => {
    this.setState({
      showDetailInfo: !this.state.showDetailInfo,
    })
  }

  showDetailInfo = () => {
    this.setState({
      showDetailInfo: !this.state.showDetailInfo,
    })
  }

  closeShowDetailInfo = () => {
    this.setState({
      showDetailInfo: false,
    })
  }

  closeModulesDetail = () => {
    this.setState({
      showListAction: true,
      showAssignmentInformation: false,
      showInformationSteering: false,
      showSendEmail: false,
    })
  }

  handleSubmit = (
    title,
    message,
    intent,
    isSuccess,
    id = 0,
    isRefreshList = false
  ) => {
    switch (title) {
      case 'GiaoViec':
        this.props.actions.commonAddToasterMessage({
          message: message,
          intent: intent,
        })
        if (isSuccess) {
          if (isRefreshList) {
            this.props.actions.refreshPage(true)
          }
          this.props.history.goBack()
        }
        break

      case 'GuiEmail':
      case 'XoaBaoCao':
      case 'XoaGiaoViec':
      case 'GiaoViecBoSung':
        this.props.actions.commonAddToasterMessage({
          message: message,
          intent: intent,
        })
        if (isSuccess) {
          this.props.actions.refreshPage(true)
          this.props.history.goBack()
        }
        break

      default:
        this.props.actions.commonAddToasterMessage({ message, intent })
    }
  }

  componentWillMount() {
    if (
      this.props.location &&
      this.props.location.state &&
      this.props.location.state.action === 'assign'
    ) {
      this.setState({
        showResults: false,
        showListAction: false,
        isLoadingSubData: true,
        showSection: 'GiaoViec',
      })
    }
  }

  async componentDidMount() {
    window.addEventListener('scroll', this.handleScroll)
    // Nếu có fileId, soCongVan lấy từ danh sách
    if (this.props.location && this.props.location.state) {
      // Gọi api get thông tin file => state.fileFromList
      let fileFromList = null
      let documentNumberFromList = null
      let isLoadingData = this.state.isLoadingData

      if (this.props.location.state.fileId) {
        await this.props.actions
          .getFileInformation(this.props.location.state.fileId)
          .then(res => {
            if (
              res &&
              res.payload &&
              res.payload.data &&
              res.payload.data.result
            ) {
              fileFromList = res.payload.data.result
              isLoadingData = {
                ...isLoadingData,
                file: false,
              }
            }
          })
      }

      // Lấy số công văn từ danh sách
      if (this.props.location.state.soCongVan) {
        documentNumberFromList = this.props.location.state.soCongVan
        isLoadingData = {
          ...isLoadingData,
          documentNumber: false,
        }
      }

      if (fileFromList || documentNumberFromList) {
        await this.setState({
          fileFromList,
          documentNumberFromList,
          isLoadingData,
        })
      }
    }

    // Kiem tra LD DV / LD PB
    if (this.props.auth && this.props.auth.permission) {
      await this.setState({
        isLeaderUnit: Authorization.vaild(this.props.auth.permission, [
          Permission.CONGVANDEN_CHIDAO,
        ]),
      })
    }

    // Thong tin giao viec
    // if (this.props.match && this.props.match.params && this.props.match.params.status === 'da-giao') {
    //   this.props.actions.workFetchDepartmentAssignInformations(this.state.workId).then(() => {
    //     let { workAssignInformations } = this.props;

    //     if (!workAssignInformations || !workAssignInformations.department) {
    //       this.setState({
    //         isLoadingData: {
    //           ...this.state.isLoadingData,
    //           workDepartmentAssignInformations: false
    //         }
    //       });
    //     } else {
    //       this.setState({
    //         workDepartmentAssignInformations: this.props.workAssignInformations.department ? this.props.workAssignInformations.department : null,
    //         workDepartmentAssignId: this.props.workAssignInformations.department.phieuGiaoViecId ? this.props.workAssignInformations.department.phieuGiaoViecId : null,
    //         isLoadingData: {
    //           ...this.state.isLoadingData,
    //           workDepartmentAssignInformations: false
    //         }
    //       });
    //     }
    //   });
    // } else {
    //   this.setState({
    //     isLoadingData: {
    //       ...this.state.isLoadingData,
    //       workDepartmentAssignInformations: false
    //     }
    //   });
    // }

    // Chi tiet cong viec phong ban
    await this.props.actions
      .getWorkItem(this.state.workId)
      .then(() => {
        let { departmentWorks } = this.props
        let isHasReceiveDocumentId = false

        if (!departmentWorks || !departmentWorks.item) {
          return this.setState({
            isLoadingData: {
              ...this.state.isLoadingData,
              work: false,
            },
          })
        }

        if (departmentWorks.item.congVanDenId) {
          isHasReceiveDocumentId = true
        }

        // Phieu giao viec cua cong viec phong ban da giao
        let workDepartmentAssignId =
          departmentWorks.item.phieuGiaoViecId || null

        // Thong tin chi dao
        // if (departmentWorks.item.congVanDenId) {
        //   this.props.actions.InformationSteering(departmentWorks.item.congVanDenId).then((res) => {
        //     this.setState({
        //       informationSteeringDepartment: this.props.departmentWorks.informationSteering ? this.props.departmentWorks.informationSteering : null,
        //       congVanDenId: true,
        //       isLoadingData: {
        //         ...this.state.isLoadingData,
        //         informationSteering: false
        //       }
        //     });
        //   });
        // } else {
        //   this.setState({
        //     isLoadingData: {
        //       ...this.state.isLoadingData,
        //       informationSteering: false
        //     },
        //     congVanDenId: false
        //   });
        // }

        this.setState({
          file: departmentWorks.item.fileThamKhao,
          departmentWork: departmentWorks.item,
          isLoadingData: {
            ...this.state.isLoadingData,
            work: false,
            file: false,
            documentNumber: false,
          },
          isHasReceiveDocumentId,
          workDepartmentAssignId,
        })
        // Gọi api get file tham khảo
        this.getFileThamKhao(departmentWorks.item).then(res => {
          if (res && res.length !== 0) {
            this.setState({
              filesThamKhao: res,
            })
          }
        })
      })
      .catch(err => {
        this.setState({
          isLoadingData: {
            ...this.state.isLoadingData,
            work: false,
            file: false,
            documentNumber: false,
          },
        })
      })

    // Giao viec tu check item ngoai danh sac
    if (
      this.props.location &&
      this.props.location.state &&
      this.props.location.state.action === 'assign'
    ) {
      await this.setState({
        showResults: false,
        showListAction: false,
        isLoadingSubData: true,
        showSection: 'GiaoViec',
      })
      let isLoadedApi = this.state.isLoadedApi
      if (this.state.isLeaderUnit) {
        // Lanh dao don vi
        if (isLoadedApi && isLoadedApi.departments === false) {
          await this.props.actions.commonFetchDepartments().then(res => {
            if (res.payload && res.payload.status === 200) {
              isLoadedApi = {
                ...isLoadedApi,
                departments: true,
              }
            }
          })
        }
        if (
          this.props.auth.mainDepartmentId &&
          isLoadedApi &&
          isLoadedApi.staffs === false
        ) {
          await this.props.actions
            .commonFetchStaffByDepartmentId(this.props.auth.mainDepartmentId)
            .then(res => {
              if (res.payload && res.payload.status === 200) {
                isLoadedApi = {
                  ...isLoadedApi,
                  staffs: true,
                }
              }
            })
        }
      } else {
        if (
          this.props.auth.mainDepartmentId &&
          isLoadedApi &&
          isLoadedApi.staffs === false
        ) {
          await this.props.actions
            .commonFetchStaffByDepartmentId(this.props.auth.mainDepartmentId)
            .then(res => {
              isLoadedApi = {
                ...isLoadedApi,
                staffs: true,
              }
            })
        }
      }
      this.setState({
        showResults: true,
        showListAction: false,
        showSection: 'GiaoViec',
        isLoadingSubData: false,
        isLoadedApi,
      })
    }
  }

  // Get danh sách files tham khảo
  getFileThamKhao = async data => {
    let params = {
      loai: 1,
    }
    let id = -1
    if (data.congVanId) {
      params = {
        ...params,
        type: 'CONG_VAN',
      }
      id = data.congVanId
    } else {
      return []
    }

    let filesThamKhao = []
    await this.props.actions.getDanhSachFiles(id, params).then(res => {
      if (
        res &&
        res.payload &&
        res.payload.data &&
        res.payload.data.result &&
        res.payload.data.result.items &&
        res.payload.data.result.items.length !== 0
      ) {
        res.payload.data.result.items.map(elm =>
          filesThamKhao.push({
            ...elm,
            is_existed: true,
          })
        )
      }
    })

    return filesThamKhao
  }

  goBack() {
    this.props.history.goBack()
  }

  componentWillUnmount() {
    this.setState({
      showListAction: true,
      isShowMobileAssignmentHistory: false,
      departmentWork: null,
      workDepartmentAssignInformations: null,
      isUpdateAssignment: false,
      workDepartmentAssignId: null,
      workDepartmentAssignItem: null,
      showInformationSteering: false,
      saveTo: [
        ...(Authorization.vaild(this.props.auth.permission, [
          Permission.TUTAILIEUDONVI_QUANLY,
        ])
          ? [
              {
                id: Types.CABINET_UNIT,
                name: 'Tủ công ty',
              },
            ]
          : []),
        {
          id: Types.CABINET_PERSONAL,
          name: 'Tủ cá nhân',
        },
        {
          id: Types.CABINET_DEPARTMENT,
          name: 'Tủ phòng ban',
        },
      ],
      showAssignmentInformation: false,
      showSendEmail: false,
      isShowMobile: false,
      workId: this.props.match.params.id,
      file: null,
      informationSteeringDepartment: null,
      assignmentInformation: null,
      isLoadingData: {
        work: true,
        file: true,
      },
      executingViews: [],
    })
    window.removeEventListener('scroll', this.handleScroll)
  }

  uploadFile = async files => {
    const { fileUpload } = this.props.actions
    let response = {}
    if (size(files)) {
      response = await fileUpload(files, this.changeUploadProgress)
    }
    if (response.error) {
      return this.showValidateMessage(MESSAGE.TEXT_MESSAGE_UPLOAD_FAIL, true)
    }

    return get(response, 'payload.data.result', []).map(file =>
      Tool.Utils.convertFileResponse(file)
    )
  }

  handleSaveJobProject = async (err, params) => {
    const {
      actions,
      departmentWorks: { item },
    } = this.props
    const { commonAddToasterMessage, postTaiLieuHoSoCongViec } = actions

    if (err) {
      return commonAddToasterMessage({
        message: err,
        inten: Intent.DANGER,
      })
    }

    const files = await this.uploadFile(get(params, 'files', []))
    const data = Tool.Utils.toDecamelizeKeys({
      tenTaiLieu: item.trichYeu,
      ghiChu: get(params, 'ghiChu'),
      congVanId: item.congVanId,
      congVanNoiBoId: item.congVanNoiBoId,
      dsHoSoCongViecId: get(params, 'nganTuIds', []),
      soCongVan: item.soCongVan,
      ngayCongVan: item.ngayCongVan,
      fileNoiDung: item.fileNoiDung,
      fileDinhKem: [
        ...item.fileDinhKemEvn.map(i => ({ ...i, is_existed: true })),
      ],
      fileThamKhao: [...this.state.filesThamKhao],
      fileDinhKemBenNgoai: [...files],
    })

    try {
      const response = await postTaiLieuHoSoCongViec(data)

      if (response.error) {
        return commonAddToasterMessage({
          message: MESSAGE.TOATS_MESSAGE_FAIL,
          inten: Intent.DANGER,
        })
      }

      if (get(response, 'payload.status') === 200) {
        commonAddToasterMessage({
          message: MESSAGE.TOATS_MESSAGE_SUCCESS,
          intent: Intent.SUCCESS,
        })
        ReactDOM.findDOMNode(this).scrollIntoView({ behavior: 'smooth' })
        this.setState({
          showResults: false,
          showListAction: true,
          showSection: 'noContent',
          isLoadedApi: {
            ...this.state.isLoadedApi,
            documentCabinetList: false,
          },
        })
      }
    } catch (error) {
      return commonAddToasterMessage({
        message: MESSAGE.TOATS_MESSAGE_FAIL,
        inten: Intent.DANGER,
      })
    }
  }

  handleSaveArchive = (err, params) => {
    if (err) {
      return this.props.actions.commonAddToasterMessage({
        message: err,
        intent: Intent.DANGER,
      })
    }

    if (params.categoryType === Types.HOSO_CONGVIEC) {
      return this.handleSaveJobProject(err, params)
    }

    const item = this.props.departmentWorks.item

    params = {
      ds_ngan_tu: params.nganTuIds,
      loai_tu_tai_lieu: parseInt(params.categoryType, 10),
      ghi_chu: params.ghiChu,
      ds_file_cong_van: [
        {
          file_id: item.fileThamKhao
            ? item.fileThamKhao.fileId
            : item.fileCongVan.fileId,
          cong_van_den_id: item.congVanDenId,
          cong_van_noi_bo_id: item.congVanNoiBoId,
          ten_tai_lieu: params.tenTaiLieu,
        },
      ],
    }

    return this.props.actions
      .documentsCabinetSaveReceiveDocument(params)
      .then(res => {
        if (res.error) {
          return this.props.actions.commonAddToasterMessage({
            message: MESSAGE.TOATS_MESSAGE_FAIL,
            intent: Intent.DANGER,
          })
        }

        if (res.payload && res.payload.status === 200) {
          this.props.actions.commonAddToasterMessage({
            message: MESSAGE.TOATS_MESSAGE_SUCCESS,
            intent: Intent.SUCCESS,
          })
          ReactDOM.findDOMNode(this).scrollIntoView({ behavior: 'smooth' })
          this.setState({
            showResults: false,
            showListAction: true,
            showSection: 'noContent',
            isLoadedApi: {
              ...this.state.isLoadedApi,
              documentCabinetList: false,
            },
          })
        }
      })
  }

  handleSelectSaveTo(v, isFromAssignmentForm = true) {
    const {
      setCabinetType,
      documentsCabinetGetListCategoriesUnit,
      documentsCabinetGetListCategoriesPersonal,
      documentsCabinetGetListCategoriesDepartment,
      getLoaiLuuTaiLieu,
    } = this.props.actions

    setCabinetType(v)
    this.getListDisable(v)
    switch (v) {
      case Types.CABINET_UNIT:
        documentsCabinetGetListCategoriesUnit()
        break
      case Types.CABINET_PERSONAL:
        documentsCabinetGetListCategoriesPersonal()
        break
      case Types.CABINET_DEPARTMENT:
        documentsCabinetGetListCategoriesDepartment()
        break
      case Types.HOSO_CONGVIEC:
        const params = {
          loai: 1,
          phanLoaiTu: 'PHONGBAN',
        }
        getLoaiLuuTaiLieu(params)
        break
      default:
    }
  }

  handleClickDepartmentExecutingView = id => {
    this.props.actions.getMoreExecutingView(id)
  }

  handleSendMistake(content) {
    if (!content) {
      return this.props.actions.commonAddToasterMessage({
        message: MESSAGE.TEXT_MESSAGE_ALERT_MISTAKE_REQUIRE_WARNING,
        intent: Intent.WARNING,
      })
    }
    this.setState({ isLoading: true })
    let item = this.props.departmentWorks.item

    if (item.phieuGiaoViecChinhId) {
      return this.props.actions
        .postAlertMistake({
          noi_dung: content,
          cong_van_cong_viec_id: item.phieuGiaoViecChinhId,
          cong_viec_phong_ban_id: null,
          loai: WORK_MISTAKE,
        })
        .then(res => {
          if (res.payload && res.payload.status === 200) {
            return this.props.actions
              .getWorkItem(this.state.workId)
              .then(res => {
                if (res.payload && res.payload.status === 200) {
                  let { departmentWorks } = this.props
                  let isHasReceiveDocumentId = false

                  if (departmentWorks.item.congVanDenId) {
                    isHasReceiveDocumentId = true
                  }

                  let workDepartmentAssignId =
                    departmentWorks.item.phieuGiaoViecId || null
                  this.setState({
                    file: departmentWorks.item.fileThamKhao,
                    departmentWork: departmentWorks.item,
                    isHasReceiveDocumentId,
                    workDepartmentAssignId,
                  })
                  this.handleClickGoBack()
                  return this.props.actions.commonAddToasterMessage({
                    message: MESSAGE.TEXT_MESSAGE_ALERT_MISTAKE_SUCCESS,
                    intent: Intent.SUCCESS,
                  })
                }
              })
          }

          this.props.actions.commonAddToasterMessage({
            message: MESSAGE.TEXT_MESSAGE_ALERT_MISTAKE_FAILED,
            intent: Intent.DANGER,
          })
        })
        .then(() => {
          this.setState({ isLoading: false })
        })
    }

    return this.props.actions
      .postAlertMistake({
        noi_dung: content,
        cong_van_cong_viec_id: item.congVanDenId,
        cong_viec_phong_ban_id: item.congViecPhongBanId,
        loai: DOCUMENT_MISTAKE,
      })
      .then(res => {
        if (res.payload && res.payload.status === 200) {
          return this.props.actions.getWorkItem(this.state.workId).then(res => {
            if (res.payload && res.payload.status === 200) {
              let { departmentWorks } = this.props
              let isHasReceiveDocumentId = false

              if (departmentWorks.item.congVanDenId) {
                isHasReceiveDocumentId = true
              }

              let workDepartmentAssignId =
                departmentWorks.item.phieuGiaoViecId || null
              this.setState({
                file: departmentWorks.item.fileThamKhao,
                departmentWork: departmentWorks.item,
                isHasReceiveDocumentId,
                workDepartmentAssignId,
              })
              this.handleClickGoBack()
              return this.props.actions.commonAddToasterMessage({
                message: MESSAGE.TEXT_MESSAGE_ALERT_MISTAKE_SUCCESS,
                intent: Intent.SUCCESS,
              })
            }
          })
        }

        this.props.actions.commonAddToasterMessage({
          message: MESSAGE.TEXT_MESSAGE_ALERT_MISTAKE_FAILED,
          intent: Intent.DANGER,
        })
      })
      .then(() => {
        this.setState({ isLoading: false })
      })
  }

  handleClickCancelAlertMistake() {
    this.setState({ isLoading: true })
    let item = this.props.departmentWorks.item

    if (item.phieuGiaoViecChinhId) {
      return this.props.actions
        .cancelAlertMistake(item.baoNhamId, WORK_MISTAKE)
        .then(res => {
          if (res.payload && res.payload.status === 200) {
            return this.props.actions
              .getWorkItem(this.state.workId)
              .then(res => {
                if (res.payload && res.payload.status === 200) {
                  let { departmentWorks } = this.props
                  let isHasReceiveDocumentId = false

                  if (departmentWorks.item.congVanDenId) {
                    isHasReceiveDocumentId = true
                  }

                  let workDepartmentAssignId =
                    departmentWorks.item.phieuGiaoViecId || null
                  this.setState({
                    file: departmentWorks.item.fileThamKhao,
                    departmentWork: departmentWorks.item,
                    isHasReceiveDocumentId,
                    workDepartmentAssignId,
                  })
                  return this.props.actions.commonAddToasterMessage({
                    message: MESSAGE.TEXT_MESSAGE_CANCEL_ALERT_MISTAKE_SUCCESS,
                    intent: Intent.SUCCESS,
                  })
                }
              })
          }

          this.props.actions.commonAddToasterMessage({
            message: MESSAGE.TEXT_MESSAGE_CANCEL_ALERT_MISTAKE_FAILED,
            intent: Intent.DANGER,
          })
        })
        .then(() => {
          this.setState({ isLoading: false })
        })
    }

    return this.props.actions
      .cancelAlertMistake(item.baoNhamId, DOCUMENT_MISTAKE)
      .then(res => {
        if (res.payload && res.payload.status === 200) {
          return this.props.actions.getWorkItem(this.state.workId).then(res => {
            if (res.payload && res.payload.status === 200) {
              let { departmentWorks } = this.props
              let isHasReceiveDocumentId = false

              if (departmentWorks.item.congVanDenId) {
                isHasReceiveDocumentId = true
              }

              let workDepartmentAssignId =
                departmentWorks.item.phieuGiaoViecId || null
              this.setState({
                file: departmentWorks.item.fileThamKhao,
                departmentWork: departmentWorks.item,
                isHasReceiveDocumentId,
                workDepartmentAssignId,
              })
              return this.props.actions.commonAddToasterMessage({
                message: MESSAGE.TEXT_MESSAGE_CANCEL_ALERT_MISTAKE_SUCCESS,
                intent: Intent.SUCCESS,
              })
            }
          })
        }

        this.props.actions.commonAddToasterMessage({
          message: MESSAGE.TEXT_MESSAGE_CANCEL_ALERT_MISTAKE_FAILED,
          intent: Intent.DANGER,
        })
      })
      .then(() => {
        this.setState({ isLoading: false })
      })
  }

  handleClickComplete = id => {
    this.setState({ isSubmit: true }, () => {
      this.props.actions
        .workCompleteDepartment({
          ds_cong_viec_phong_ban_id: [id],
        })
        .then(res => {
          this.setState({ isSubmit: false })
          if (res.error || res.payload.status !== 200) {
            return this.props.actions.commonAddToasterMessage({
              message: 'Đã có lỗi xảy ra, vui lòng thử lại!',
              intent: Intent.DANGER,
            })
          }
          this.props.actions.commonAddToasterMessage({
            message: MESSAGE.TEXT_WORK_COMPLETE_SUCCESS,
            intent: Intent.SUCCESS,
          })
          this.props.actions.refreshPage(true)
          this.props.history.goBack()
        })
    })
  }

  handleScroll(event) {
    let scrollTop = window.pageYOffset
    if (scrollTop > 62) {
      this.setState({
        boxMenuFixTop: true,
      })
    } else {
      this.setState({
        boxMenuFixTop: false,
      })
    }
  }

  handleClickMoreDepartmentExecutingView = id => {
    this.props.actions.getMoreFlowDocumentaryReceiveDocument(id)
  }

  handleSubmitRemoveItem = (check, noiDung) => {
    let id = this.props.departmentWorks.item.phieuGiaoViecId
    if (check === 1) {
      this.props.actions
        .deleteAlertMistake(id, WORK_MISTAKE)
        .then(res => {
          if (res.error) {
            return this.props.actions.commonAddToasterMessage({
              message: MESSAGE.TEXT_MESSAGE_ALERTMISTAKE_FAIL,
              intent: Intent.DANGER,
            })
          }
          // this.props.actions.commonFetchAlertMistakeTotal(Types.ALERT_MISTAKE_WORK);
          this.props.actions.commonAddToasterMessage({
            message: MESSAGE.TEXT_MESSAGE_ALERTMISTAKE_SUCCESS,
            intent: Intent.SUCCESS,
          })
          this.props.history.goBack()
        })
        .catch(err => {
          this.props.actions.commonAddToasterMessage({
            message: MESSAGE.TEXT_MESSAGE_ALERTMISTAKE_FAIL,
            intent: Intent.DANGER,
          })
        })
    }

    if (check === 2) {
      this.props.actions
        .postAlertMistakeWork(id, {
          noi_dung: noiDung,
          cong_van_cong_viec_id: id,
        })
        .then(res => {
          if (res.error) {
            return this.props.actions.commonAddToasterMessage({
              message: MESSAGE.TEXT_MESSAGE_ALERTMISTAKE_FAIL,
              intent: Intent.DANGER,
            })
          }
          // this.props.actions.commonFetchAlertMistakeTotal(Types.ALERT_MISTAKE_WORK);
          this.props.actions.commonAddToasterMessage({
            message: MESSAGE.TEXT_MESSAGE_ALERTMISTAKE_SUCCESS,
            intent: Intent.SUCCESS,
          })
          this.props.history.goBack()
        })
        .catch(err => {
          this.props.actions.commonAddToasterMessage({
            message: MESSAGE.TEXT_MESSAGE_ALERTMISTAKE_FAIL,
            intent: Intent.DANGER,
          })
        })
    }
  }

  goGiaoViecPhienBanMoi = () => {
    this.setState({
      showListAction: false,
      showResults: true,
      showSection: ACTIONS_VIEW.TAO_CONG_VIEC,
    })
  }

  handleClickFlowdocumentary = async () => {
    this.setState({
      showResults: false,
      showListAction: false,
      showSection: 'LuongCongVanNoiBo',
    })
    if (
      this.props.departmentWorks &&
      this.props.departmentWorks.item &&
      this.props.departmentWorks.item.congVanNoiBoId
    ) {
      await this.props.actions.getLuongCongVanNoiBo(
        this.props.departmentWorks.item.congVanNoiBoId
      )
      this.setState({
        showResults: true,
      })
    }
  }

  sendToChat = e => {
    e.preventDefault()
    if (
      this.props.departmentWorks &&
      this.props.departmentWorks.item &&
      this.props.departmentWorks.item.fileThamKhao
    ) {
      // Truyền param id của công văn, công việc hoặc ký số. Có thêm kiểu của loại tài liệu
      let infoGetFileDinhKem = this.props.departmentWorks.item.congVanId
      let infoGetFileDinhKemType = 'CONG_VAN'
      if (!infoGetFileDinhKem) {
        infoGetFileDinhKem = this.props.departmentWorks.item.congVanNoiBoId
        infoGetFileDinhKemType = 'CONG_VAN_NOI_BO'
      }
      const url = `?fileId=${this.props.departmentWorks.item.fileThamKhao.fileId}&infoGetFileDinhKem=${infoGetFileDinhKem}&infoGetFileDinhKemType=${infoGetFileDinhKemType}`
      return this.props.history.push(`${variables.CHAT_URL}${url}`)
    }
  }

  render() {
    let isNewAssignDoc =
      this.state.departmentWork &&
      this.state.departmentWork.dsPhieuGiaoViec &&
      this.state.departmentWork.dsPhieuGiaoViec.length > 0
        ? true
        : false
    let isLoadingAll = !Object.values(this.state.isLoadingData).find(
      item => item === true
    )
    let fileContent = this.props.departmentWorks.item
    let fileThamKhao = fileContent.fileThamKhao
      ? fileContent.fileThamKhao
      : fileContent.fileCongVan || {}
    fileThamKhao.kichThuocStr = Tool.Utils.getFileSize(fileThamKhao.kichThuoc)

    // Nếu có file từ danh sách thì dùng file, soCongVan từ danh sách,
    // ngược lại dùng file, soCongVan get từ api chi tiết
    const fileDetail = this.state.fileFromList || this.state.file
    let documentNumberDetail = this.state.documentNumberFromList

    if (
      !this.state.documentNumberFromList &&
      this.state.departmentWork &&
      this.state.departmentWork.soCongVan
    ) {
      documentNumberDetail = this.state.departmentWork.soCongVan
    }

    if (isNewAssignDoc) {
      documentNumberDetail = this.state.departmentWork.trichYeu
    }

    const { archives, auth } = this.props
    const cabinetCategories = get(
      archives,
      `type[${
        cabinetTypes[get(auth, 'defaultCabinetType', Types.CABINET_PERSONAL)]
      }]`
    )

    return (
      <MasterLayout typeSidebar="work" collapseSideBar={true}>
        <div className="detail-receive-document">
          {this.state.isLoadingData &&
          this.state.isLoadingData.documentNumber === true ? (
            <div className="row-detail-container clearfix loading-line-detail">
              <div className="col-md-4 col-xs-12">
                <LoadingLine />
              </div>
            </div>
          ) : (
            <div className="dispatch-code">
              <button
                onClick={this.goBack.bind(this)}
                type="button"
                className={classnames(
                  'pt-button pt-button-back button-back-fixed',
                  {
                    'fixed-button-back':
                      this.state.boxMenuFixTop &&
                      !this.props.enableScrollFixedMenu &&
                      window.innerWidth >= 1024,
                    'fixed-button-hasSidebar':
                      this.state.boxMenuFixTop &&
                      this.props.enableScrollFixedMenu &&
                      window.innerWidth >= 1024,
                  }
                )}
              >
                <span className="pt-icon pt-icon-chevron-left pt-align-left"></span>
                <span className="text-content">Quay lại</span>
              </button>
              <span className="number-code">
                <span className="name">
                  {isNewAssignDoc ? 'Tiêu đề:' : 'Công văn:'}
                </span>
                <span className="code">{documentNumberDetail}</span>
              </span>
            </div>
          )}
          <div className="detail-container">
            <div className="row-detail-container clearfix">
              <div className="box-left">
                {isNewAssignDoc &&
                  this.state.departmentWork.dsPhieuGiaoViec.map(
                    (item, index) => (
                      <div className="assign-info" key={index}>
                        <AssignmentInformation
                          auth={this.props.auth}
                          firstChild={index === 0 ? true : false}
                          assignmentInformation={item}
                          isNewAssignDoc={isNewAssignDoc}
                        />
                      </div>
                    )
                  )}
                {!isNewAssignDoc && (
                  <DetailFileContentV2
                    auth={this.props.auth}
                    actions={this.props.actions}
                    file={fileDetail}
                  />
                )}
              </div>
              <div
                className={classnames('box-right', {
                  fixtopMenuRight:
                    this.state.boxMenuFixTop &&
                    !this.state.showResults &&
                    !this.props.enableScrollFixedMenu &&
                    window.innerWidth >= 1024,
                  fixtopMenuRightHasSideBar:
                    this.state.boxMenuFixTop &&
                    !this.state.showResults &&
                    this.props.enableScrollFixedMenu &&
                    window.innerWidth >= 1024,
                })}
                style={{ background: 'unset' }}
              >
                <div className="full-box-content">
                  {this.state.isLoadingData &&
                    !isLoadingAll &&
                    !this.state.isLoadingSubData && (
                      <div className="loading-container">
                        <LoadingItem />
                      </div>
                    )}
                  {this.state.showListAction && isLoadingAll && (
                    <DetailBoxAction
                      isNewAssignDoc={isNewAssignDoc}
                      user={this.props.auth}
                      work={this.state.departmentWork}
                      assignmentInformation={
                        this.state.workDepartmentAssignInformations
                      }
                      onClickSaveDocument={this.handleClickSaveDocument}
                      archives={this.props.archives.items}
                      onClickSendMail={this.handleClickSendMail}
                      onClickAlertMistake={this.handleClickAlertMistake}
                      onClickAssign={this.handleClickAssign}
                      onClickUpdateAssignment={this.handleClickUpdateAssignment}
                      onClickAdditionalAssign={this.handleClickAdditionalAssign}
                      onClickExecutingView={this.handleClickExecutingView}
                      actions={this.props.actions}
                      onHandleSubmit={this.handleSubmit}
                      // onClickDetailInfo={this.handleClickDetailInfo}
                      informationSteering={
                        typeof this.state.informationSteeringDepartment ===
                        'object'
                          ? this.state.informationSteeringDepartment
                          : null
                      }
                      type="department"
                      isLoadingData={this.state.isLoadingData}
                      auth={this.props.auth}
                      isLeaderUnit={this.state.isLeaderUnit}
                      isLoadingAction={this.state.isSubmit}
                      onClickInformationAssign={
                        this.handleClickInformationAssign
                      }
                      onClickInformationWork={this.handleClickInformationWork}
                      handleClickComplete={this.handleClickComplete}
                      departmentWorks={this.props.departmentWorks}
                      disableAlertMistake={
                        this.props.departmentWorks.item.baoNham || false
                      }
                      match={this.props.match}
                      onClickFlowDocument={this.handleClickFlowDocument}
                      status={
                        this.props.location?.state?.status === 'chuyen-nham'
                          ? true
                          : false
                      }
                      onClickCancelAlertMistake={
                        this.handleClickCancelAlertMistake
                      }
                      onHandleSubmitRemoveItem={this.handleSubmitRemoveItem}
                      sendToChat={this.sendToChat}
                      filesThamKhao={this.state.filesThamKhao}
                      onClickFlowdocumentary={this.handleClickFlowdocumentary}
                      onMenuItem={this.onMenuItem}
                    />
                  )}

                  {this.state.isLoadingSubData && <LoadingItem />}

                  {this.state.showResults &&
                    this.state.showSection === 'LuuTuTaiLieu' && (
                      <SaveDocument
                        auth={this.props.auth}
                        work={this.state.departmentWork}
                        file={fileThamKhao}
                        onClickGoBack={this.handleClickGoBack}
                        archives={cabinetCategories}
                        saveTo={this.state.saveTo}
                        onSelectSaveTo={this.handleSelectSaveTo}
                        onSubmit={this.handleSaveArchive}
                        defaultType={this.props.auth.defaultCabinetType}
                        listCabinetOfDocument={this.props.listCabinetOfDocument}
                        filesThamKhao={this.state.filesThamKhao}
                      />
                    )}

                  {this.state.showResults &&
                    this.state.showSection === 'GuiEmail' && (
                      <SendMail
                        onClickGoBack={this.handleClickGoBack}
                        onSubmit={this.handleSubmit}
                        action={this.props.actions}
                        isShowMobile={this.state.showSendEmail}
                        closeModulesDetail={this.closeModulesDetail}
                        work={this.state.departmentWork}
                        auth={this.props.auth}
                      />
                    )}

                  {this.state.showResults &&
                    this.state.showSection === 'GiaoViec' &&
                    this.state.isLoadingData &&
                    isLoadingAll && (
                      <Assign
                        auth={this.props.auth}
                        userRoleId={this.props.auth.roleId}
                        isNewAssignDoc={isNewAssignDoc}
                        onClickGoBack={this.handleClickGoBack}
                        onSubmit={this.handleSubmit}
                        actions={this.props.actions}
                        staffs={this.props.staffs}
                        departmentWork={this.state.departmentWork}
                        workAssignInformations={
                          this.state.isUpdateAssignment &&
                          this.state.workDepartmentAssignItem
                            ? this.state.workDepartmentAssignItem
                            : null
                        }
                        readOnly={this.props.readOnly}
                        departments={this.props.departments}
                        isLeaderUnit={this.state.isLeaderUnit}
                        mainDepartmentId={
                          this.props.auth && this.props.auth.mainDepartmentId
                        }
                        // Props lưu tủ tài liệu
                        archives={cabinetCategories}
                        saveTo={this.state.saveTo}
                        onSelectSaveTo={this.handleSelectSaveTo}
                        defaultType={this.props.auth.defaultCabinetType}
                        listCabinetOfDocument={this.props.listCabinetOfDocument}
                        onShowSaveDocument={this.handleClickShowSaveDocument}
                        isLoadingSaveDocument={this.state.isLoadingSaveDocument}
                        status={
                          this.props.location &&
                          this.props.location.state &&
                          this.props.location.state.status === 'chuyen-nham'
                            ? true
                            : false
                        }
                        dsPhieuGiaoViec={
                          this.state.departmentWork
                            ? this.state.departmentWork.dsPhieuGiaoViec
                            : []
                        }
                      />
                    )}

                  {this.state.showResults &&
                    this.state.showSection === 'GiaoViecBoSung' && (
                      <AdditionalAssign
                        onClickGoBack={this.handleClickGoBack}
                        onSubmit={this.handleSubmit}
                        actions={this.props.actions}
                        staffs={this.props.staffs}
                        departmentWork={this.state.departmentWork}
                        assignmentInformation={this.state.assignmentInformation}
                        workAssignInformations={
                          this.state.workDepartmentAssignItem
                            ? this.state.workDepartmentAssignItem
                            : null
                        }
                        departments={this.props.departments}
                        isLeaderUnit={this.state.isLeaderUnit}
                        mainDepartmentId={
                          this.props.auth && this.props.auth.mainDepartmentId
                        }
                      />
                    )}

                  {/*
                    this.state.showResults &&
                    this.state.showAssignmentInformation &&
                    this.state.showSection === 'ThongTinGiaoViec' &&
                    isLoadingAll &&
                    <AssignmentInformation
                      isShowMobile={this.state.showAssignmentInformation}
                      closeModulesDetail={this.closeModulesDetail}
                      assignmentInformation={this.state.workDepartmentAssignInformations}
                      onHandleSubmit={this.handleSubmit}
                      type="department"
                      isLeaderUnit={this.state.isLeaderUnit}
                    />
                  */}

                  {this.state.showResults &&
                    this.state.showSection === 'XemThucHien' && (
                      <div>
                        {this.state.isHasReceiveDocumentId === true &&
                          !isNewAssignDoc && (
                            <ExecutingView
                              onClickGoBack={this.handleClickGoBack}
                              onClickDepartment={
                                this.handleClickDepartmentExecutingView
                              }
                              executingViews={
                                this.props.executingViews.items || []
                              }
                              auth={this.props.auth}
                              actions={this.props.actions}
                            />
                          )}
                        {this.state.isHasReceiveDocumentId === false &&
                          !isNewAssignDoc && (
                            <ExecutingViewInternal
                              onClickGoBack={this.handleClickGoBack}
                              executingViews={
                                this.props.executingViewInternal
                                  .items_internalDocument || []
                              }
                              auth={this.props.auth}
                              actions={this.props.actions}
                            />
                          )}
                        {isNewAssignDoc && (
                          <ExecutingView
                            onClickGoBack={this.handleClickGoBack}
                            executingViews={
                              this.state.executingViewAssign || []
                            }
                            auth={this.props.auth}
                            actions={this.props.actions}
                            isNewAssignDoc={true}
                          />
                        )}
                      </div>
                    )}

                  {this.state.showResults &&
                    this.state.showSection === 'AlertMistake' && (
                      <div>
                        <AlertMistake
                          isLoading={this.state.isLoading}
                          onClickGoBack={this.handleClickGoBack}
                          onSubmit={this.handleSendMistake}
                        />
                      </div>
                    )}

                  {this.state.showResults &&
                    this.state.showSection === 'ThongTinChiDao' && (
                      <InformationSteering
                        isShowMobile={true}
                        onClickGoBack={this.handleClickGoBack}
                        closeModulesDetail={this.closeModulesDetail}
                        informationSteering={
                          this.state.informationSteeringDepartment
                        }
                      />
                    )}

                  {this.state.showResults &&
                    this.state.showSection === 'ThongTinGiaoViec' && (
                      <AssignmentInformation
                        assignmentInformation={
                          this.state.workDepartmentAssignInformations
                        }
                        type="department"
                        isShowMobile={true}
                        auth={this.props.auth}
                        isLeaderUnit={this.state.isLeaderUnit}
                        onClickGoBack={this.handleClickGoBack}
                      />
                    )}

                  {this.state.showResults &&
                    this.state.showSection === 'LuongCongVan' && (
                      <div className="box-action-item">
                        {!this.state.isCheck && (
                          <Flowdocumentary
                            auth={this.props.auth}
                            onClickGoBack={this.handleClickGoBack}
                            flowDocument={
                              this.props.executingViews
                                .flowDocument_ReceiveDocument
                            }
                            onClickDepartment={
                              this.handleClickMoreDepartmentExecutingView
                            }
                            actions={this.props.actions}
                          />
                        )}
                        {this.state.isCheck && (
                          <FlowSendDocumentary
                            auth={this.props.auth}
                            onClickGoBack={this.handleClickGoBack}
                            flowDocument={
                              this.props.executingViews.flowSendDocument
                            }
                            onClickDepartment={
                              this.handleClickMoreDepartmentExecutingView
                            }
                            flowReceiveDocument={
                              this.props.executingViews.flowDocuments
                            }
                            actions={this.props.actions}
                          />
                        )}
                      </div>
                    )}

                  {isValidCondition(
                    auth.permission,
                    [Permission.SUDUNG_QLCV],
                    'AND'
                  ) &&
                    this.state.showResults &&
                    this.state.showSection === ACTIONS_VIEW.TAO_CONG_VIEC && (
                      <TaskCreate
                        goBack={this.handleClickGoBack}
                        documentDetail={this.state.departmentWork || {}}
                      />
                    )}
                  {this.state.showResults &&
                    this.state.showSection === 'LuongCongVanNoiBo' && (
                      <div className="box-action-item">
                        <FlowInternalDocumentary
                          auth={this.props.auth}
                          onClickGoBack={this.handleClickGoBack}
                          flowDocument={this.props.luongCongVanNoiBo}
                          flowReceiveDocument={
                            this.props.executingViews.flowDocuments
                          }
                          actions={this.props.actions}
                        />
                      </div>
                    )}

                  {this.state.showResults &&
                    this.state.showSection === 'USER_VIEWED' && (
                      <UserViewed
                        id={this.state.departmentWork?.congVanId}
                        onGoBack={this.handleClickGoBack}
                      />
                    )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </MasterLayout>
    )
  }
}

const mapStateToProps = state => ({
  departmentWorks: {
    ...state.departmentWorks,
    item: state.departmentWorks.detailId
      ? state.entities.departmentWorks[state.departmentWorks.detailId]
      : {},
    informationSteering: state.receiveDocuments.item,
  },
  personalWorks: {
    ...state.personalWorks,
    item: state.personalWorks.detailId
      ? state.entities.personalWorks[state.personalWorks.detailId]
      : {},
    informationSteering: state.receiveDocuments.item,
  },
  assignmentInformation: {
    ...state.assignmentInformation,
    items: state.assignmentInformation.list.map(
      id => state.entities.assignmentInformation[id]
    ),
  },
  auth: {
    ...state.auth,
    user: state.auth,
  },
  archives: {
    ...state.documentsCabinet,
    categories: state.documentsCabinet.categories.map(
      id => state.entities.documentCabinetCategories[id]
    ),
    type: {
      unit: state.documentsCabinet.type.unit.map(
        id => state.entities.documentCabinetCategories[id]
      ),
      department: state.documentsCabinet.type.department.map(
        id => state.entities.documentCabinetCategories[id]
      ),
      personal: state.documentsCabinet.type.personal.map(
        id => state.entities.documentCabinetCategories[id]
      ),
      profile: state.documentsCabinet.type.profile.map(
        id => state.entities.documentCabinetCategories[id]
      ),
    },
    storage: {
      ...state.documentsCabinet.storage,
      used: Tool.Utils.getFileSize(state.documentsCabinet.storage.used),
    },
  },
  executingViews: {
    ...state.executingViews,
    items:
      state.executingViews.items &&
      state.executingViews.items.map(id => {
        let data = state.entities.executingViews[id]
        const getDSDonVi = dsDonViTrucThuocResponse => {
          return dsDonViTrucThuocResponse.map(item => {
            let subItem = state.entities.executingViews[item.chiDaoId]

            return {
              ...item,
              executingViews: [
                subItem
                  ? {
                      ...subItem,
                      dsDonViTrucThuocResponse: getDSDonVi(
                        subItem.dsDonViTrucThuocResponse
                      ),
                    }
                  : undefined,
              ],
            }
          })
        }

        return {
          ...data,
          dsDonViTrucThuocResponse: getDSDonVi(data.dsDonViTrucThuocResponse),
        }
      }),
    flowDocuments: Object.keys(state.executingViews.flowDocuments).map(key => {
      const item = state.executingViews.flowDocuments[key]
      return {
        ...item,
        nguoiTaoCongVan: state.entities.users[item.nguoiTaoCongVan],
        chiDao: {
          ...state.entities.executingViews[item.chiDao],
          dsDonViTrucThuocResponse: (() => {
            if (!state.entities.executingViews[item.chiDao]) {
              return []
            }
            const getDSDonVi = dsDonViTrucThuocResponse => {
              return dsDonViTrucThuocResponse.map(item => {
                let subItem = state.entities.executingViews[item.chiDaoId]
                let flowDocument =
                  state.executingViews.flowDocuments[item.chiDaoId]
                return {
                  ...item,
                  executingViews: [
                    subItem
                      ? {
                          ...subItem,
                          dsDonViTrucThuocResponse: getDSDonVi(
                            subItem.dsDonViTrucThuocResponse
                          ),
                        }
                      : undefined,
                  ],
                  flowDocument: {
                    ...flowDocument,
                    nguoiTaoCongVan: flowDocument
                      ? state.entities.users[flowDocument.nguoiTaoCongVan]
                      : undefined,
                  },
                }
              })
            }

            return getDSDonVi(
              state.entities.executingViews[item.chiDao]
                .dsDonViTrucThuocResponse
            )
          })(),
        },
      }
    }),
    flowDocument_ReceiveDocument: {
      ...state.executingViews.flowDocument_ReceiveDocument,
      nguoiTaoCongVan:
        state.entities.users[
          state.executingViews.flowDocument_ReceiveDocument.nguoiTaoCongVan
        ],
      chiDao: {
        ...state.entities.executingViews[
          state.executingViews.flowDocument_ReceiveDocument.chiDao
        ],
        dsDonViTrucThuocResponse: (() => {
          if (
            !state.entities.executingViews[
              state.executingViews.flowDocument_ReceiveDocument.chiDao
            ]
          ) {
            return []
          }
          const getDSDonVi = dsDonViTrucThuocResponse => {
            return dsDonViTrucThuocResponse.map(item => {
              let subItem = state.entities.executingViews[item.chiDaoId]
              let flowDocument =
                state.executingViews.flowDocuments[item.congVanDenId]
              return {
                ...item,
                executingViews: [
                  subItem
                    ? {
                        ...subItem,
                        dsDonViTrucThuocResponse: getDSDonVi(
                          subItem.dsDonViTrucThuocResponse
                        ),
                      }
                    : undefined,
                ],
                flowDocument: {
                  ...flowDocument,
                  nguoiTaoCongVan: flowDocument
                    ? state.entities.users[flowDocument.nguoiTaoCongVan]
                    : undefined,
                },
              }
            })
          }

          return getDSDonVi(
            state.entities.executingViews[
              state.executingViews.flowDocument_ReceiveDocument.chiDao
            ].dsDonViTrucThuocResponse
          )
        })(),
      },
    },
  },
  executingViewInternal: {
    ...state.executingViews,
    items_internalDocument:
      state.executingViews.items_internalDocument !== undefined &&
      state.executingViews.items_internalDocument.map(
        id => state.entities.executingViews[id]
      ),
  },
  staffs: state.common.staffs,
  workAssignInformations: state.workAssignInformations,
  listCabinetOfDocument: state.common.listCabinetOfDocument.map(
    item => item.id
  ),
  readOnly: state.common.enableReadOnly,
  departments: state.common.departments,
  enableScrollFixedMenu: state.common.enableScrollFixedMenu,
  luongCongVanNoiBo: state.detailInternalDocuments.luongCongVanNoiBo,
  quyensMenu: state.common.quyensMenu,
})

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(Actions, dispatch),
})

export default connect(mapStateToProps, mapDispatchToProps)(DetailWorkPage)
