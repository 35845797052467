//TOATS MESSAGE
export const TOATS_MESSAGE_WARNING = 'Hãy nhập các trường bắt buộc!'
export const TOATS_MESSAGE_SUCCESS = 'Lưu thành công'
export const TOATS_MESSAGE_FAIL = 'Lưu không thành công'
export const TOATS_MESSAGE_DELETE_SUCCESS = 'Xóa thành công'
export const TOATS_MESSAGE_DELETE_FAIL = 'Xóa thất bại'
export const TOATS_SEND_MAIL_SUCCESS = 'Gửi thành công'
export const TOATS_SEND_MAIL_FAIL = 'Gửi không thành công'
export const TOATS_CANCLE_SUCCESS = 'Hủy thành công'
export const TOATS_CANCLE_FAIL = 'Hủy không thành công'
export const TOATS_CONFIRM_JOIN_MEETING = 'Đã xác nhận tham gia cuộc họp'
export const TOATS_CONFIRM_CANCLE_MEETING =
  'Đã xác nhận không tham gia cuộc họp'
export const TOATS_ERROR_SYSTEM = 'Lỗi hệ thống, vui lòng thử lại sau'
export const TOATS_STOP_SUCCESS = 'Kết thúc cuộc họp thành công'
export const TOATS_STOP_FAIL = 'Kết thúc cuộc họp không thành công'
//MESSAGE FROM
export const TEXT_ASSIGN_SUCCESS = 'Chỉ đạo thành công'
export const TEXT_MESSAGE_ALERTMISTAKE_SUCCESS = 'Huỷ chuyển nhầm thành công'
export const TEXT_BAO_NHAM_CAP_TREN_SUCCESS = 'Báo nhầm cấp trên thành công'
export const TEXT_MESSAGE_ALERTMISTAKE_FAIL = 'Huỷ chuyển nhầm thất bại'
export const TEXT_MESSAGE_DEFAULT = 'Bạn không được bỏ trống trường này.'
export const TEXT_MESSAGE_ERROR_UPLOAD = 'Đã xảy ra lỗi upload file'
export const TEXT_MESSAGE_TYPE_FILE = 'Hãy nhập đúng định dạng file!'
export const TEXT_MESSAGE_UPLOAD_FAIL = 'Upload file không thành công!'
export const TEXT_TYPE_FILE_UPLOAD = `Hãy chọn file có định dạng '.pdf', '.doc', hoặc '.docx'`
export const TEXT_TYPE_FILE_UPLOAD_IMAGE = `Hãy chọn file có định dạng '.png', '.jpg', hoặc '.jpeg'`
export const TEXT_TYPE_FILE_UPLOAD_SIGNNUMBER = `Hãy chọn file có định dạng '.pdf'.`
export const TEXT_TYPE_FILE_UPLOAD_REFERENCES = `Hãy chọn file có định dạng '.pdf', '.doc', '.docx', '.xls' hoặc '.xlsx'.`
export const TEXT_MAXIMUM_SIZE_FILE = 'Dung lượng file tối đa là 25MB'
export const TEXT_MAXIMUM_SIZE_FILE_SUBMISSION =
  'Dung lượng file tối đa là 100MB'
export const TEXT_MAXIMUM_SIZE_FILE_AVATAR = 'Dung lượng file tối đa là 2MB'
export const TEXT_MESSAGE_TYPE_EMAIL = 'Định dạng email vừa nhập không đúng'
export const TEXT_TYPE_FILE_IMAGE_UPLOAD = `Hãy chọn file có định dạng '.png', '.jpg'`
export const TEXT_MESSAGE_TYPE_PHONE =
  'Định dạng số điện thoại vừa nhập không đúng'
export const TEXT_MESSAGE_INPUT_DUPLICATE =
  'Hãy chọn tên khác, tên này đã tồn tại'
export const TEXT_MESSAGE_MIN_INPUT =
  'Vui lòng nhập nội dung hoặc chọn phòng ban/đơn vị/cá nhân!'
export const TEXT_MESSAGE_REQUIRE_FILE = 'Hãy chọn một văn bản hoặc một file!'
export const TEXT_MESSAGE_MAX_LENGHT = 'Độ dài tối đa là 50 ký tự!'
export const TEXT_MESSAGE_MAX_LENGHT_NAME_PROJECT =
  'Độ dài tối đa là 1000 ký tự!'
export const TEXT_MESSAGE_MAX_LENGHT_NUMBER_DOCUMENT =
  'Độ dài tối đa số công văn là 50 ký tự!'
export const TEXT_PROPOSE_ASSIGN_SUCCESS = 'Đề xuất chỉ đạo thành công'
export const TEXT_UPDATE_ASSIGN_SUCCESS = 'Chỉnh sửa chỉ đạo thành công'
export const TEXT_DELETE_ASSIGN_SUCCESS = 'Hủy chỉ đạo thành công'
export const TEXT_REPORT_WORK_SUCCESS = 'Báo cáo thành công'
export const TEXT_UPDATE_REPORT_WORK_SUCCESS = 'Sửa báo cáo thành công'
export const TEXT_DELETE_REPORT_WORK_SUCCESS = 'Xóa báo cáo thành công'
//DIFT
export const TEXT_MESSAGE_HANGIAIQUYET =
  'Hạn giải quyết công văn đến nhỏ hơn ngày hiện tại!'
export const TEXT_MESSAGE_HANGIAIQUYET_GIAOVIEC =
  'Công việc này đã quá hạn giải quyết theo chỉ đạo! Anh/chị chỉ giao được công việc này cho chính mình'
export const TEXT_MESSAGE_PHONGBANCHUTRI = 'Hãy chọn phòng ban/đơn vị chủ trì!'
export const TEXT_MESSAGE_CHUTRI = 'Hãy chọn phòng ban/cá nhân chủ trì!'
export const TEXT_MESSAGE_HOANTHANHCONGVIEC = 'Hoàn thành công việc thành công!'
export const TEXT_MESSAGE_KHONGHOANTHANHCONGVIEC =
  'Hoàn thành công việc không thành công!'
export const TEXT_MESSAGE_GOPY = 'Bạn chưa nhập góp ý!'
export const TEXT_YEUCAUTHUHOICONGVAN_KHONGTHANHCONG =
  'Gửi yêu cầu thu hồi công văn không thành công!'
export const TEXT_YEUCAUTHUHOICONGVAN_THANHCONG =
  'Gửi yêu cầu thu hồi công văn thành công!'
export const TEXT_THUHOICONGVAN_KHONGTHANHCONG =
  'Thu hồi công văn không thành công!'
export const TEXT_THUHOICONGVAN_THANHCONG = 'Thu hồi công văn thành công!'
export const TEXT_DE_XUAT_THUHOI_CONGVAN_THANHCONG =
  'Hủy đề xuất thu hồi công văn thành công!'
export const TEXT_DE_XUAT_THUHOI_CONGVAN_KHONGTHANHCONG =
  'Hủy đề xuất thu hồi công văn không thành công!'
export const TEXT_GUILAI_KHONGTHANHCONG = 'Gửi lại công văn không thành công!'
export const TEXT_GUILAI_THANHCONG = 'Gửi lại công văn thành công!'
export const TEXT_REQUIRED_SEND_ADDITIONAL =
  'Hãy chọn ít nhất một phòng ban hoặc một đơn vị!'
export const TEXT_CHUYENCHIDAOTHANHCONG = 'Chuyển chỉ đạo thành công'
export const TEXT_CHUYENCHIDAOTHATBAI = 'Chuyển chỉ đạo thất bại'
export const TEXT_GIATRI_LONHON_0 = 'Hãy nhập số có giá trị lớn hơn 0'
export const CHON_NGAN_TU = 'Anh/Chị vui lòng chọn ngăn tủ'
export const CHON_LOAI_NGAN_TU = 'Anh/Chị vui lòng chọn loại ngăn tủ'
export const TEN_TAI_LIEU = 'Vui lòng nhập tên tài liệu'
export const TEXT_MESSAGE_DISSAGREE_SIGNNUMBER = 'Vui lòng nhập góp ý!'
export const TEXT_MESSAGE_TIME = 'Thống kê thất bại!. Bạn phải chọn thời gian'
export const TEXT_MESSAGE_END_TIME =
  'Thống kê thất bại! Anh/chị phải chọn ngày đến'
export const TEXT_MESSAGE_RELATIVE_SIGN_NUMBER =
  'Vui lòng chọn ít nhất 1 phòng ban phối hợp cấp trên hoặc lãnh đạo đơn vị cấp trên!'
export const TEXT_WORK_COMPLETE_SUCCESS = 'Hoàn thành công việc thành công'
export const TEXT_GIAO_VIEC_SUCCESS = 'Giao việc thành công'
export const TEXT_UPDATE_GIAO_VIEC_SUCCESS = 'Chỉnh sửa giao việc thành công'
export const TEXT_DELETE_GIAO_VIEC_SUCCESS = 'Hủy giao việc thành công'
//SIGN NUMBER
export const SIGN_NUMBER_RESET_SUCCESS = 'Reset ký số token thành công'
export const SIGN_NUMBER_RESET_FAILED = 'Reset ký số token thất bại'
export const SIGN_NUMBER_INVALID_PROGRESSION = 'Số công văn đã được cấp'
export const UNIQUE_MEMBER_BY_STEP_PROJECT =
  'Mỗi thành viên chỉ được chọn một lần trong mỗi bước!'
export const VALIDATE_MEMBERS_PROJECT =
  'Anh/chị phải chọn danh sách thành viên hợp lệ!'
export const UNIQ_SIGN_FIELD = 'Mỗi trường ký chỉ được chọn một lần duy nhất!'
export const NOT_ALLOW_CHANGE_STEP =
  'Anh/chị không được thay đổi bước đã/đang ký!'
export const UNIQ_USER_PROGRESSION = 'Chỉ được chọn một thành viên cấp số!'
export const REQUIRED_SIGN_WAY = 'Hãy chọn hình thức ký!'
export const REQUIRED_CONDITION_SIGN_WAY_PROGRESSION =
  'Anh/chị cần chọn ít nhất 1 bước ký có dấu!'
export const REQUIRED_PAGE_SIGN = 'Anh/Chị vui lòng nhập trang ký'

export const TEXT_MESSAGE_ALERT_MISTAKE_SUCCESS = 'Báo nhầm thành công'
export const TEXT_MESSAGE_ALERT_MISTAKE_FAILED = 'Báo nhầm thất bại'
export const TEXT_MESSAGE_CANCEL_ALERT_MISTAKE_SUCCESS =
  'Hủy báo nhầm thành công'
export const TEXT_MESSAGE_CANCEL_ALERT_MISTAKE_FAILED = 'Hủy báo nhầm thất bại'
export const TEXT_MESSAGE_ALERT_MISTAKE_REQUIRE_WARNING =
  'Anh/chị chưa nhập nội dung báo nhầm'
export const TEXT_VALIDATE_FIELD_Y_KIEN = 'Vui lòng nhập ý kiến!'

// Thong bao
export const TEXT_REQUIRE_TITILE_NOTIFICATION = 'Vui lòng nhập tiêu đề!'
export const TEXT_REQUIRE_CONTENT_NOTIFICATION = 'Vui lòng nhập nội dung!'
export const TEXT_REQUIRE_UNIT_NOTIFICATION = 'Vui lòng chọn nơi đăng!'

export const CREATE_SUCCESS_DOCUMENT =
  'Nhập thành công. Anh/Chị có thể nhập văn bản tiếp theo'

export const MESSAGE_NOT_FOUND = 'Không có dữ liệu trong hệ thống'
export const MESSAGE_ERROR = 'Có lỗi xảy ra trong quá trình tải dữ liệu'
export const MESSAGE_REQUIRED_STATISTICS =
  'Anh/Chị chưa chọn khoảng thời gian thống kê'
export const MESSAGE_DEFAULT_STATISTICS = 'Anh/Chị vui lòng chọn thống kê'
export const MESSAGE_COPY_FEEDBACK_SUCCESS =
  'Lưu góp ý thành công. Anh/chị có thể dán vào nơi cần thiết'
export const MESSAGE_INVALID_SIGN_FIELD =
  'Số ký hiệu không được chứa ký tự "_" và ký tự "/"'
export const MESSAGE_SEND_EVN_FAIL = 'Gửi văn bản đến EVN không thành công'
export const MESSAGE_SEND_EVN_SUCCESS = 'Gửi văn bản đến EVN thành công'
export const MESSAGE_UPLOAD_AVATAR_FAIL = 'Lưu ảnh đại diện không thành công'
export const MESSAGE_UPLOAD_PROFILE_FAIL =
  'Lưu thông tin đính kèm không thành công'
export const MESSAGE_LIMIT_ASSIGN = 'Anh/chị được chọn tối đa 5 công văn'

export const XOA_SERVICE_KHONGTHANHCONG = 'Xóa service không thành công!'
export const XOA_SERVICE_THANHCONG = 'Xóa service thành công!'

export const NHAP_NOI_DUNG_Y_KIEN = 'Hãy nhập ý kiến!'

//phieu yeu cau gop y
export const TEXT_XOAPHIEUYEUCAUGOPY_KHONGTHANHCONG =
  'Xóa phiếu yêu cầu góp ý không thành công!'
export const TEXT_XOAPHIEUYEUCAUGOPY_THANHCONG =
  'Xóa phiếu yêu cầu góp ý thành công!'
export const TEXT_MESSAGE_ALERT_DISSAGREE_REQUIRE_WARNING =
  'Anh/chị chưa nhập nội dung không duyệt góp ý'
export const TEXT_MESSAGE_ALERT_AGREE_THANHCONG = 'Duyệt thành công'
export const TEXT_MESSAGE_ALERT_AGREE_KHONGTHANHCONG = 'Duyệt không thành công'

export const TEXT_MESSAGE_ALERT_TU_CHOI_THANHCONG = 'Từ chối thành công'
export const TEXT_MESSAGE_ALERT_TU_CHOI_KHONGTHANHCONG =
  'Từ chối không thành công'

export const TEXT_MESSAGE_ALERT_CANCEL_AGREE_THANHCONG =
  ' Hủy xác nhận phiếu yêu cầu góp ý thành công'
export const TEXT_MESSAGE_ALERT_CANCEL_AGREE_KHONGTHANHCONG =
  'Hủy xác nhận phiếu yêu cầu góp ý  không thành công'

export const TEXT_MESSAGE_ALERT_HOAN_THANH_THANHCONG =
  'Hoàn thành phiếu yêu cầu góp ý thành công'
export const TEXT_MESSAGE_ALERT_HOAN_THANH_KHONGTHANHCONG =
  'Hoàn thành phiếu yêu cầu góp ý  không thành công'

export const TEXT_MESSAGE_ALERT_DEPARTMENT_REQUIRE_WARNING =
  'Anh/chị chưa nhập phòng ban chỉ đạo góp ý'
export const TEXT_MESSAGE_CHI_DAO_GOP_Y_THANHCONG = 'Chỉ đạo góp ý thành công'
export const TEXT_MESSAGE_CHI_DAO_GOP_Y_KHONGTHANHCONG =
  'Chỉ đạo góp ý không thành công'

export const TEXT_MESSAGE_SUA_CHI_DAO_GOP_Y_THANHCONG =
  'Sửa chỉ đạo góp ý thành công'
export const TEXT_MESSAGE_SUA_CHI_DAO_GOP_Y_KHONGTHANHCONG =
  'Sửa chỉ đạo góp ý không thành công'

export const TEXT_MESSAGE_ALERT_PERSONAL_REQUIRE_WARNING =
  'Anh/chị chưa nhập cá nhân thực hiện góp ý'
export const TEXT_MESSAGE_GIAO_GOP_Y_THANHCONG = 'Giao góp ý thành công'
export const TEXT_MESSAGE_GIAO_GOP_Y_KHONGTHANHCONG =
  'Giao góp ý không thành công'

export const TEXT_MESSAGE_SUA_GIAO_GOP_Y_THANHCONG = 'Sửa giao góp ý thành công'
export const TEXT_MESSAGE_SUA_GIAO_GOP_Y_KHONGTHANHCONG =
  'Sửa giao góp ý không thành công'

export const TEXT_MESSAGE_THUC_HIEN_GOP_Y_THANHCONG =
  'Thực hiện góp ý thành công'
export const TEXT_MESSAGE_THUC_HIEN_GOP_Y_KHONGTHANHCONG =
  'Thực hiện góp ý không thành công'

export const TEXT_MESSAGE_SUA_GOP_Y_THANHCONG = 'Sửa góp ý thành công'
export const TEXT_MESSAGE_SUA_GOP_Y_KHONGTHANHCONG =
  'Sửa góp ý không thành công'

export const TEXT_MESSAGE_ALERT_CANCEL_APPROVE_THANHCONG =
  'Hủy xác nhận góp ý thành công'
export const TEXT_MESSAGE_ALERT_CANCEL_APPROVE_KHONGTHANHCONG =
  'Hủy xác nhận góp ý không thành công'

export const TEXT_MESSAGE_ALERT_HUY_CHI_DAO_THANHCONG =
  'Hủy chỉ đạo góp ý thành công'
export const TEXT_MESSAGE_ALERT_HUY_CHI_DAO_KHONGTHANHCONG =
  'Hủy chỉ đạo góp ý không thành công'

export const TEXT_MESSAGE_ALERT_HUY_GIAO_THANHCONG = 'Hủy giao góp ý thành công'
export const TEXT_MESSAGE_ALERT_HUY_GIAO_KHONGTHANHCONG =
  'Hủy giao góp ý không thành công'

export const TEXT_MESSAGE_ALERT_HUY_GOP_Y_THANHCONG = 'Hủy  góp ý thành công'
export const TEXT_MESSAGE_ALERT_HUY_GOP_Y_KHONGTHANHCONG =
  'Hủy  góp ý không thành công'

export const TEXT_MESSAGE_REQUIRE_CHON_DON_VI_GOP_Y =
  'Bạn cần chọn ít nhất 1 phòng ban hoặc 1 đơn vị góp ý.'
export const MESSAGE_SERVICE_CREATE_SUCCESS = 'Tạo Service thành công'

export const KHONGCO_DULIEU_TRONG_HETHONG = 'Không có dữ liệu trong hệ thống!'
export const XOA_THOIGIANLUU = 'Xóa thời hạn bảo quản thành công!'
export const XOA_THOIGIANLUU_KHONGTHANHCONG =
  'Xóa thời hạn bảo quản không thành công!'
export const UPDATE_lY_DO_THU_hOI = 'Anh/Chị vui lòng cập nhật lý do trả lại.'
export const CONFIRM_THUHOI_BINHLUAN =
  'Anh/Chị chắc chắn muốn thu hồi ý kiến này?'
export const XOA_BINHLUAN = 'Anh/Chị chắc chắn muốn xóa ý kiến này?'
export const DROP_DRAP_KANBAN_FAILED =
  'Anh/Chị không có quyền cập nhật công việc này!'
export const TEXT_HOANTHANH_CONGVIEC = 'Anh/Chị đã hoàn thành công việc!'

// Validation
export const ValidateForm = {
  IS_REQUIRED: 'Hãy nhập trường bắt buộc!',
  MAX_LENGTH: limit => `Độ dài tối đa là ${limit} ký tự!`,
  FILE_TYPE: (type_list = []) => {
    if (type_list.length)
      return `Hãy chọn file có định dạng ${type_list
        .map(item => `'.${item}'`)
        .join(', ')}`
    return 'File không đúng định dạng'
  },
  MAX_FILE_SIZE: limit => `Dung lượng tối đa là ${limit} MB`,
  SELECT_REQUIRED: value =>
    value ? `Hãy chọn một ${value}` : 'Hãy chọn một giá trị',
  UNIQUE: name =>
    name ? `${name} không được trùng nhau` : 'Dữ liệu đã tồn tại',
  FILE_REQUIRE: 'Hãy tải file lên!',
  MAX_NUMBER: limit =>
    limit || limit === 0 ? `Giá trị tối đa là ${limit}` : 'Giá trị quá lớn',
  MIN_NUMBER: limit =>
    limit || limit === 0 ? `Giá trị tối thiểu là ${limit}` : 'Giá trị quá nhỏ',
  FILE_TYPE_ALLOW: allowTypeArray =>
    `Hệ thống hỗ trợ định dạng file ${allowTypeArray.join(', ')}`,
  FILE_TYPE_NOT_ALLOW: notAllowTypeArray =>
    `Hệ thống không hỗ trợ định dạng file ${notAllowTypeArray.join(', ')}`,
  FILE_SIZE_ALLOW: sizeAllow =>
    `Dung lượng upload file vượt quá ${sizeAllow}MB`,
}

// Action
export const Action = {
  CREATE: 'Tạo',
  CREATE_TAGS: 'Tạo loại công việc',
  CREATE_FAVORITE: 'Tạo nhóm thường giao việc',
  UPDATE_FAVORITE: 'Cập nhật nhóm thường giao việc',
  UPDATE: 'Cập nhật',
  UPDATE_TAGS: 'Sửa loại công việc',
  DELETE: 'Xóa',
  SORT: 'Sắp xếp',
  FETCH: 'Tải dữ liệu',
  UPLOAD: 'Upload',
  ADD: 'Thêm',
  COMMENT: 'Ý kiến',
  EVICTION: 'Thu hồi',
  EVICTION_COMMENT: 'Thu hồi ý kiến',
  DELETE_COMMENT: 'Xóa ý kiến',
  ASSIGN: 'Giao việc',
  REPORT: 'Báo cáo',
  EXPORT_EXCEL: 'Xuất excel',
  SAVE: 'Lưu',
  ACCEPT: 'Xác nhận',
}

// Toast
export const Toast = {
  SUCCESS: action => `${action} thành công`,
  FAIL: action => `${action} thất bại`,
  INCOMPLETE: 'Hãy hoàn tất biểu mẫu',
  NOT_FOUND: info => `Không tìm thấy ${info}`,
}

export const validateMessage = {
  input: name => `Vui lòng nhập ${name}`,
  select: name => `Vui lòng chọn ${name}`,
  fileSize: name => `${TEXT_MAXIMUM_SIZE_FILE}. ${name}`,
}

// In Process
export const inProcess = 'Tác vụ đang thực hiện'

export const CHECKBOX_REQUIRED = value =>
  !!value ? `Hãy chọn ít nhất một ${value}` : 'Hãy chọn ít nhất một giá trị'

// Công văn đi thu hồi
export const DE_XUAT_THU_HOI_THANH_CONG = 'Đề xuất thu hồi thành công'
export const DE_XUAT_THU_HOI_KHONG_THANH_CONG =
  'Đề xuất thu hồi không thành công'
export const CHINH_SUA_DE_XUAT_THU_HOI_THANH_CONG =
  'Chỉnh sửa đề xuất thu hồi thành công'
export const CHINH_SUA_DE_XUAT_THU_HOI_KHONG_THANH_CONG =
  'Chỉnh sửa đề xuất thu hồi không thành công'
export const KHONG_CO_FILE_TRONG_HE_THONG = 'Không có file trong hệ thống'
export const DA_XAY_RA_LOI = 'Đã xảy ra lỗi'
export const SAPXEP_THANHCONG = 'Sắp xếp thành công'
export const SAPXEP_KHONGTHANHCONG = 'Sắp xếp không thành công'
export const DICHUYEN_THANHCONG = 'Di chuyển thành công'
export const DICHUYEN_KHONG_THANHCONG = 'Di chuyển không thành công'
export const CHUA_CO_THONG_TIN_DUYET = 'Chưa có thông tin duyệt'
export const CONG_VIEC_DA_XOA = 'Công việc đã được xóa'
export const CONG_VIEC_DA_DUOC_CHI_DAO_THUC_HIEN =
  'Công việc đang chọn đã được chỉ đạo thực hiện'

// TEXT
export const HOAN_THANH_NHANH = 'Hoàn thành nhanh'
