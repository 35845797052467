import { Button, Intent } from '@blueprintjs/core'
import { MasterLayout } from 'components/layout'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import * as Actions from 'actions'
import UserItem from './UserItem'
import ModalDanhSachNguoiThamGia from './ModalDanhSachNguoiThamGia'
import * as MESSAGER from 'constants/MessageForm'
import ChonLoaiVatTu from './ChonLoaiVatTu'
import Title from 'components/ui/title'

const CapNhatHauCanPage = props => {
  const [detail, setDetail] = useState(null)
  const [isLoading, setIsLoading] = useState(false)
  const [showPopupThamGia, setShowPopupThamGia] = useState(false)
  const [dsVatTuSelected, setDsVatTuSelected] = useState([])

  const idDetail = props.match.params.id

  const handleBack = () => {
    props.history.goBack()
  }

  const handleGetChiTietLichHop = async () => {
    const res = await props.actions.getChiTietLichHop(idDetail)
    if (!res.error && res?.payload?.data?.result) {
      const dataDetail = res.payload.data.result
      setDetail(dataDetail)
    }
  }

  const handleGetDsVatTuCuocHop = async () => {
    const res = await props.actions.getDanhSachVatTuCuocHop(idDetail)
    if (!res.error && res?.payload?.data?.result) {
      const data = res.payload.data.result
      setDsVatTuSelected(
        data?.map(item => ({
          donViTinh: item?.donViTinh,
          ghiChu: item?.ghiChu,
          soLuong: item?.soLuong,
          tenVatTu: item?.tenVatTu,
          vatTuId: item?.vatTuId,
        }))
      )
    }
  }

  const handleSubmitCapNhatHauCan = () => {
    setIsLoading(true)
    const payload = dsVatTuSelected?.map(item => ({
      vat_tu_id: item?.vatTuId,
      so_luong: item?.soLuong ? Number(item?.soLuong) : 0,
      don_vi_tinh: item?.donViTinh || undefined,
      ghi_chu: item?.ghiChu?.trim() || undefined,
    }))
    props.actions.putVatTuCuocHop(detail?.cuocHopId, payload).then(res => {
      if (res.payload && res.payload.data.result === true) {
        setIsLoading(false)
        handleBack()
        return props.actions.commonAddToasterMessage({
          message: MESSAGER.TOATS_MESSAGE_SUCCESS,
          intent: Intent.SUCCESS,
        })
      } else {
        setIsLoading(false)
        props.actions.commonAddToasterMessage({
          message: MESSAGER.TOATS_MESSAGE_FAIL,
          intent: Intent.DANGER,
        })
      }
    })
  }

  useEffect(() => {
    if (idDetail) {
      handleGetChiTietLichHop()
      handleGetDsVatTuCuocHop()
    }
    // eslint-disable-next-line
  }, [idDetail])

  const dsNguoiThamGia = detail?.cuocHopThanhPhan?.map(item => ({
    ...item?.nhanVien,
    thamGia: item.thamGia,
  }))

  return (
    <MasterLayout typeSidebar="sidebarCalendar">
      <Title
        name="Cập nhật hậu cần"
        icon="icon-Plus"
        isGoBack
        history={props.history}
      />
      <div className="form-wrapper form-calendar mt10">
        <div className="form-container">
          <h3 className="title-block-form">Thông tin cuộc họp</h3>
          <div className="form-padding-box">
            <div className="row">
              <div className="col-xs-12 col-sm-6 col-md-6 form-item-wrap">
                <label className="pt-label">Tiêu đề</label>
                <div className="pt-form-view">{detail?.tieuDe}</div>
              </div>
              <div className="col-xs-12 col-sm-6 col-md-6 form-item-wrap">
                <label className="pt-label">Địa điểm</label>
                <div className="pt-form-view">{detail?.diaDiem?.ten}</div>
              </div>
            </div>
            <div className="row">
              <div className="col-xs-12 col-sm-6 col-md-6 form-item-wrap">
                <label className="pt-label">Loại cuộc họp</label>
                <div className="pt-form-view">{detail?.theLoai?.ten}</div>
              </div>
              <div className="col-xs-12 col-sm-6 col-md-6 form-item-wrap">
                <label className="pt-label">Cấp độ cuộc họp</label>
                <div className="pt-form-view">{detail?.mucDo?.ten}</div>
              </div>
            </div>
            <div className="row">
              <div className="col-xs-12 col-sm-12 col-md-3 form-item-wrap">
                <label className="pt-label">Thời gian diễn ra</label>
                <div className="pt-form-view">
                  {detail?.ketThuc
                    ? moment(detail?.batDau).format('DD/MM/YYYY HH:mm') +
                      ' - ' +
                      moment(detail?.ketThuc).format('DD/MM/YYYY HH:mm')
                    : moment(detail?.batDau).format('DD/MM/YYYY HH:mm')}
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-xs-12 col-sm-12 col-md-3 form-item-wrap">
                <label className="pt-label">Chủ trì</label>
                <div className="pt-form-content">
                  <UserItem name={detail?.chuTri} />
                </div>
              </div>
              <div className="col-xs-4 col-sm-4 col-md-3 form-item-wrap">
                <label className="pt-label">Danh sách người mời họp</label>
                <div className="pt-form-content">
                  <span className="total-person">
                    <span className="total-person__number">
                      {dsNguoiThamGia?.length || 0}
                    </span>
                    {dsNguoiThamGia?.length > 0 && (
                      <span
                        className="total-person__more"
                        onClick={() => setShowPopupThamGia(true)}
                      >
                        Xem danh sách
                      </span>
                    )}
                  </span>
                </div>
              </div>
            </div>
          </div>
          <h3 className="title-block-form bt">Thông tin hậu cần</h3>
          <div className="form-padding-box">
            <div className="row">
              <div className="col-xs-12 col-sm-12 col-md-12 form-item-wrap">
                <label className="pt-label">Người chuẩn bị</label>
                <div className="pt-form-content">
                  <div className="row">
                    {detail?.cuocHopHauCan?.map((item, index) => (
                      <div key={index} className="col-xs-6 col-sm-4 col-md-3">
                        <UserItem name={item?.nhanVien?.tenNhanVien} />
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-xs-12 col-sm-12 col-md-12 form-item-wrap">
                <label className="pt-label">Loại vật tư</label>
                <div className="pt-form-content">
                  <ChonLoaiVatTu
                    actions={props.actions}
                    dsVatTuSelected={dsVatTuSelected}
                    setDsVatTuSelected={setDsVatTuSelected}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="buttton-action-footer">
          <Button
            onClick={() => handleBack()}
            className="pt-button btn-cancel"
            disabled={false}
            loading={isLoading}
          >
            <span className=" pt-icon icon-back"></span>
            <span className="text-content">Quay lại</span>
          </Button>
          <div>
            <Button
              className="pt-button btn-blue-color"
              onClick={() => handleSubmitCapNhatHauCan()}
              loading={isLoading}
              disabled={false}
            >
              <span className="text-content">Lưu</span>
              <span className="pt-icon icon-save"></span>
            </Button>
          </div>
        </div>
      </div>
      <ModalDanhSachNguoiThamGia
        data={dsNguoiThamGia}
        isOpen={showPopupThamGia}
        onClose={() => setShowPopupThamGia(false)}
      />
    </MasterLayout>
  )
}

const mapStateToProps = state => ({
  auth: {
    ...state.auth,
  },
})

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(Actions, dispatch),
})

export default connect(mapStateToProps, mapDispatchToProps)(CapNhatHauCanPage)
