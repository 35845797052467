import React, { Component } from 'react'
import {
  ContentHeader,
  LoadingTable,
  ErrorLoadingTable,
} from '../../../components/common'
import { MasterLayout } from '../../../components/layout'
import { connect } from 'react-redux'
import { get } from 'lodash'
import { Button } from '@blueprintjs/core'
import GoBackButton from '../../../components/Button/GoBackButton'
import { Link } from 'react-router-dom'
import { Utils } from '../../../helpers'
import * as procedureText from '../../../constants/Procedure'
import { FILE_URL } from '../../../constants/Api'

const EDIT_PATH = '/he-thong/quy-trinh/chinh-sua'
const EDIT_GROUP_PATH = id => `/he-thong/quy-trinh/${id}/don-vi-ap-dung`
const PAGE_ICON = 'icon-quan-ly-quy-trinh'
class ProcedureDetailsPage extends Component {
  state = {
    loading: {
      data: false,
    },
    data: {},
    fetchFailed: false,
  }

  loading = async (part, action) => {
    const { loading } = this.state
    await this.setState({
      loading: {
        ...loading,
        [part]: true,
      },
    })
    const result = await action()
    await this.setState({
      loading: {
        ...loading,
        [part]: false,
      },
    })
    return result
  }

  componentDidMount() {
    const { fetchData, match } = this.props
    const { id } = match.params
    this.loading('data', () => fetchData(id)).then(res => {
      const status = get(res, 'payload.status') === 200
      if (status) {
        this.setState({
          data: get(res, 'payload.data.result', {}),
        })
      } else {
        this.setState({
          fetchFailed: true,
        })
      }
    })
  }

  render() {
    const { token, permission } = this.props.auth
    const { loading, data, fetchFailed } = this.state

    return (
      <MasterLayout typeSidebar="sidebarSystem">
        <ContentHeader
          pageIcon={PAGE_ICON}
          pageName={this.state.data.tenQuyTrinh || ''}
          showGoBackButton
        />
        <main>
          {loading.data ? (
            <div style={{ background: '#fff' }}>
              <LoadingTable />
            </div>
          ) : fetchFailed ? (
            <div style={{ background: '#fff' }}>
              <ErrorLoadingTable />
            </div>
          ) : (
            <main className="procedure-details-content">
              <section className="block">
                <section className="sub-block">
                  <section className="row">
                    <label className="head">Danh mục:</label>
                    <label className="content">{data.tenDanhMuc || ''}</label>
                  </section>
                  <section className="row">
                    <label className="head">Mã quy trình:</label>
                    <label className="content">{data.maQuyTrinh || ''}</label>
                  </section>
                  <section className="row">
                    <label className="head">Tên quy trình:</label>
                    <label className="content">{data.tenQuyTrinh || ''}</label>
                  </section>
                  <section className="row">
                    <label className="head">Sử dụng:</label>
                    <label className="content">
                      {data.isActive ? 'Có' : 'Không'}
                    </label>
                  </section>
                  <section className="row">
                    <label className="head">File tài liệu hướng dẫn:</label>
                    <label className="content">
                      {data.fileHuongDan && (
                        <a
                          style={{
                            backgroundImage: `url(${Utils.typeFile(
                              data.fileHuongDan.kieuFile
                                .substring(1)
                                .toLowerCase()
                            )})`,
                          }}
                          className="file-type"
                          target="_blank"
                          rel="noopener noreferrer"
                          href={`${FILE_URL}/api/file/get/${
                            data.fileHuongDan.fileId
                          }?BearerToken=${token.accessToken || ''}`}
                        >
                          {data.fileHuongDan.tenFile} -{' '}
                          {Utils.getFileSize(data.fileHuongDan.kichThuoc)}
                        </a>
                      )}
                    </label>
                  </section>
                  <section className="row">
                    <label className="head">File đề xuất quy trình:</label>
                    <label className="content">
                      {data.fileDeXuat && (
                        <a
                          style={{
                            backgroundImage: `url(${Utils.typeFile(
                              data.fileDeXuat.kieuFile
                                .substring(1)
                                .toLowerCase()
                            )})`,
                          }}
                          className="file-type"
                          target="_blank"
                          rel="noopener noreferrer"
                          href={`${FILE_URL}/api/file/get/${
                            data.fileDeXuat.fileId
                          }?BearerToken=${token.accessToken || ''}`}
                        >
                          {data.fileDeXuat.tenFile} -{' '}
                          {Utils.getFileSize(data.fileDeXuat.kichThuoc)}
                        </a>
                      )}
                    </label>
                  </section>
                  <section className="row">
                    <label className="head">
                      Xác định thành phần ký duyệt:
                    </label>
                    <label className="content">
                      {procedureText.thanhPhanKy[data.xacDinhThanhPhanKyDuyet]}
                    </label>
                  </section>
                </section>
              </section>
              <section className="block">
                <section className="sub-block">
                  {data.dsBuocKyDuyet && (
                    <div>
                      <section className="row title">
                        QUY TRÌNH KÝ DUYỆT
                      </section>
                      {React.Children.toArray(
                        data.dsBuocKyDuyet.map(step => (
                          <section className="block" key={step.buocId}>
                            <section className="sub-block title">
                              <section className="row">
                                Bước {step.buoc}
                              </section>
                            </section>
                            <section className="sub-block">
                              <section className="row">
                                <label className="head">
                                  Định nghĩa bước ký duyệt:
                                </label>
                                <label className="content">
                                  {step.dinhNghia || ''}
                                </label>
                              </section>
                              <section className="row">
                                <label className="head">
                                  Số lượng người ký:
                                </label>
                                <label className="content">
                                  {procedureText.quantitySignature[
                                    step.soLuongNguoiKy
                                  ] || ''}
                                </label>
                              </section>
                              <section className="row">
                                <label className="head">
                                  Loại chữ ký áp dụng:
                                </label>
                                <label className="content">
                                  {procedureText.signatureType[
                                    step.loaiChuKy
                                  ] || ''}
                                </label>
                              </section>
                              <section className="row">
                                <label className="head">
                                  Cách xác định vùng ký:
                                </label>
                                <label className="content">
                                  {procedureText.signatureLocationType[
                                    step.xacDinhVungKy
                                  ] || ''}
                                </label>
                              </section>
                              <section className="row">
                                <label className="head">Đối tượng ký:</label>
                                <label className="content">
                                  {procedureText.signSubject[step.doiTuongKy] ||
                                    ''}
                                </label>
                              </section>
                              <section className="row">
                                <label className="head">Vai trò:</label>
                                <label className="content">
                                  {step.dsVaiTro &&
                                    step.dsVaiTro
                                      .map(
                                        vaiTro => procedureText.signRole[vaiTro]
                                      )
                                      .join(', ')}
                                </label>
                              </section>
                            </section>
                          </section>
                        ))
                      )}
                    </div>
                  )}
                </section>
                <section className="sub-block">
                  <div className="buttton-action-footer">
                    <GoBackButton />
                    {permission.includes('SUPERADMIN') && (
                      <span>
                        <Link
                          to={`${EDIT_GROUP_PATH(data.quyTrinhId)}`}
                          className="mr20"
                        >
                          <Button
                            type="button"
                            className="pt-button btn-blue-color"
                          >
                            <span className="text-content">Đơn vị áp dụng</span>
                            <i className="pt-icon icon-Ket_Qua_Bao_Cao" />
                          </Button>
                        </Link>
                        <Link to={`${EDIT_PATH}/${data.quyTrinhId}`}>
                          <Button
                            type="button"
                            className="pt-button btn-blue-color"
                          >
                            <span className="text-content">Sửa</span>
                            <span className="pt-icon pt-icon-annotation" />
                          </Button>
                        </Link>
                      </span>
                    )}
                  </div>
                </section>
              </section>
            </main>
          )}
        </main>
      </MasterLayout>
    )
  }
}

const mapStateToProps = state => ({
  auth: state.auth,
})

export default connect(mapStateToProps, null)(ProcedureDetailsPage)
