import React from 'react'
import { Intent } from '@blueprintjs/core'
import ReactDOM from 'react-dom'
import classnames from 'classnames'
import PropTypes from 'prop-types'
import { get, isEmpty } from 'lodash'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import { MasterLayout } from '../../components/layout'
import {
  DetailBoxAction,
  EditDocumentsCabinet,
  DetailInfo,
} from '../../components/DocumentCabinet'
import { SendMail } from '../../components/Work'
import * as Actions from '../../actions'
import * as Tool from '../../helpers'
import * as MESSAGE from '../../constants/MessageForm'
import * as Types from '../../constants/Api'
import {
  HeadingPage,
  DetailFileContent,
  DetailFileContentV2,
} from 'components/common'
import { variables } from 'constants/variables'

class DetailDocumentsCabinet extends React.Component {
  static propTypes = {
    departments: PropTypes.array.isRequired,
    actions: PropTypes.object.isRequired,
  }

  refHandlers = {
    toaster: (ref: Toaster) => (this.toaster = ref),
    detailFileContent: (ref: DetailFileContent) =>
      (this.detailFileContent = ref),
  }

  constructor(props) {
    super(props)

    let type = this.switchType()
    this.state = {
      id: this.props.match.params.id,
      type,
      showResults: false,
      showListAction: true,
      showSection: 'noContent',
      showDetailInfo: false,
      isRedirect: false,
      redirectTo: null,
      isLoadingData: {
        documentsCabinet: true,
      },
      boxMenuFixTop: false,
      path: '',
      isGettingPath: false,
    }

    this.handleClickDetailInfo = this.handleClickDetailInfo.bind(this)
    this.handleSaveItem = this.handleSaveItem.bind(this)
    this.updateItem = this.updateItem.bind(this)
    this.handleScroll = this.handleScroll.bind(this)
    this.sendToChat = this.sendToChat.bind(this)
  }

  switchType = () => {
    switch (this.props.match.params.type) {
      case 'ca-nhan':
        return Types.CABINET_PERSONAL
      case 'phong-ban':
        return Types.CABINET_DEPARTMENT
      default:
        return Types.CABINET_UNIT
    }
  }

  async componentDidUpdate() {
    if (
      !this.state.path &&
      !this.state.isGettingPath &&
      this.props.documentsCabinet &&
      this.props.documentsCabinet.item &&
      this.props.documentsCabinet.item.nganTuId
    ) {
      await this.setState({ isGettingPath: true })
      this.props.actions
        .getPathDocumentCabinet({
          loaiTuTaiLieu: this.switchType(),
          nganTuId: this.props.documentsCabinet.item.nganTuId,
        })
        .then(res => {
          if (res && res.payload && res.payload.data) {
            this.setState({
              path: res.payload.data.result,
            })
          }
        })
    }
  }

  componentWillMount() {
    this.updateItem()
  }

  componentDidMount() {
    window.addEventListener('scroll', this.handleScroll)
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll)
  }

  updateItem() {
    switch (this.state.type) {
      case Types.CABINET_PERSONAL:
        this.props.actions
          .documentsCabinetGetItemPersonal(this.state.id)
          .then(() => {
            this.setState({
              isLoadingData: {
                documentsCabinet: false,
              },
            })
          })
        break

      case Types.CABINET_DEPARTMENT:
        this.props.actions
          .documentsCabinetGetItemDepartment(this.state.id)
          .then(() => {
            this.setState({
              isLoadingData: {
                documentsCabinet: false,
              },
            })
          })
        break

      default:
        this.props.actions
          .documentsCabinetGetItemUnit(this.state.id)
          .then(() => {
            this.setState({
              isLoadingData: {
                documentsCabinet: false,
              },
            })
          })
    }
  }

  handleClickGoBack = e => {
    e.preventDefault()

    this.setState({
      showResults: false,
      showListAction: true,
      showSection: 'noContent',
    })
  }

  handleClickSendMail = e => {
    e.preventDefault()
    ReactDOM.findDOMNode(this).scrollIntoView({ behavior: 'smooth' })
    this.setState({
      showResults: true,
      showListAction: false,
      showSection: 'GuiEmail',
    })
  }

  handleClickEditDocumentsCabinet = e => {
    e.preventDefault()
    ReactDOM.findDOMNode(this).scrollIntoView({ behavior: 'smooth' })
    this.setState({
      showResults: true,
      showListAction: false,
      showSection: 'SuaTuTaiLieu',
    })
  }

  handleClickDetailInfo = e => {
    e.preventDefault()
    ReactDOM.findDOMNode(this).scrollIntoView({ behavior: 'smooth' })
    this.setState({
      showResults: true,
      showListAction: false,
      showSection: 'ThongTinCongVan',
    })
  }

  sendToChat(e) {
    e.preventDefault()
    let { documentsCabinet } = this.props
    if (documentsCabinet && documentsCabinet.item) {
      let fileNoiDung = documentsCabinet.item.fileNoiDung
        ? documentsCabinet.item.fileNoiDung.fileId
        : ''
      // Truyền param id của công văn, công việc hoặc ký số. Có thêm kiểu của loại tài liệu
      let infoGetFileDinhKem = documentsCabinet.item.congVanId
      let infoGetFileDinhKemType = 'TU_TAI_LIEU'
      const url = `?fileId=${fileNoiDung}&infoGetFileDinhKem=${infoGetFileDinhKem}&infoGetFileDinhKemType=${infoGetFileDinhKemType}`
      return this.props.history.push(`${variables.CHAT_URL}${url}`)
    }
  }

  handleSaveItem(data) {
    if (isNaN(Number(data.stt))) {
      return this.props.actions.commonAddToasterMessage({
        message: 'Vui lòng nhập đúng định dạng số  thứ tự',
        intent: Intent.DANGER,
      })
    }

    if (!data.tenTaiLieu) {
      return this.props.actions.commonAddToasterMessage({
        message: MESSAGE.TEXT_MESSAGE_DEFAULT,
        intent: Intent.DANGER,
      })
    }

    data = Tool.Utils.linkObjectKey(data)
    let request

    switch (this.state.type) {
      case Types.CABINET_PERSONAL:
        request = this.props.actions.documentsCabinetEditItemPersonal(data)
        break

      case Types.CABINET_DEPARTMENT:
        request = this.props.actions.documentsCabinetEditItemDepartment(data)
        break

      default:
        request = this.props.actions.documentsCabinetEditItemUnit(data)
    }

    request
      .then(res => {
        if (res.error) {
          return this.props.actions.commonAddToasterMessage({
            message: MESSAGE.TOATS_MESSAGE_FAIL,
            intent: Intent.DANGER,
          })
        }
        this.setState(
          {
            showResults: false,
            showListAction: true,
            showSection: 'noContent',
          },
          () => {
            // Refresh danh sach tai tieu
            this.props.actions.refreshDocumentCabinet(true)
            this.props.actions.commonAddToasterMessage({
              message: MESSAGE.TOATS_MESSAGE_SUCCESS,
              intent: Intent.SUCCESS,
            })
          }
        )
      })
      .then(this.updateItem)
  }

  goBack() {
    this.props.history.push({
      pathname: '/tu-tai-lieu/danh-sach',
      state: {
        type: this.state.type,
        keyTabTuTaiLieu:
          get(this.props.location, 'state.keyTabTuTaiLieu') || '1',
        page: get(this.props.location, 'state.page') || 1,
        filter: !isEmpty(get(this.props.location, 'state.filter'))
          ? get(this.props.location, 'state.filter')
          : {},
      },
    })
  }

  handleScroll(event) {
    let scrollTop = window.pageYOffset
    if (scrollTop > 160) {
      this.setState({
        boxMenuFixTop: true,
      })
    } else {
      this.setState({
        boxMenuFixTop: false,
      })
    }
  }

  handleSubmit = (title, msg, intent, isSuccess, id = 0) => {
    this.props.actions.commonAddToasterMessage({ message: msg, intent: intent })
    if (isSuccess) {
      this.props.actions.refreshPage(false)
      this.goBack()
    }
  }

  render() {
    let { type } = this.state
    let { item } = this.props.documentsCabinet

    if (item) {
      let fileNoiDung = item.fileNoiDung || {}
      fileNoiDung.kichThuocStr = Tool.Utils.getFileSize(fileNoiDung.kichThuoc)
    }

    return (
      <MasterLayout collapseSideBar={true} typeSidebar="documentsCabinet">
        <div className="detail-receive-document page-DetailDocumentsCabinet">
          <div className="dispatch-code">
            <button
              onClick={this.goBack.bind(this)}
              type="button"
              className={classnames(
                'pt-button pt-button-back button-back-fixed',
                {
                  'fixed-button-back':
                    this.state.boxMenuFixTop &&
                    !this.props.enableScrollFixedMenu &&
                    window.innerWidth >= 1024,
                  'fixed-button-hasSidebar':
                    this.state.boxMenuFixTop &&
                    this.props.enableScrollFixedMenu &&
                    window.innerWidth >= 1024,
                }
              )}
            >
              <span className="pt-icon pt-icon-chevron-left pt-align-left"></span>
              <span className="text-content">Quay lại</span>
            </button>
          </div>
          <HeadingPage namePage="Tủ tài liệu" iconPage="icon-Tu_Tai_Lieu" />
          <div className="detail-container">
            <div className="tabs-detail-page">
              <div className="pt-tabs tab-cus-container pd-none">
                <ul className="pt-tab-list" role="tablist">
                  <li
                    className={classnames('pt-tab cursor-default', {
                      'pt-tab-color-cabinet': type !== Types.CABINET_UNIT,
                    })}
                    role="tab"
                    aria-selected={type === Types.CABINET_UNIT}
                  >
                    TTL CÔNG TY
                  </li>
                  <li
                    className={classnames('pt-tab cursor-default', {
                      'pt-tab-color-cabinet': type !== Types.CABINET_DEPARTMENT,
                    })}
                    role="tab"
                    aria-selected={type === Types.CABINET_DEPARTMENT}
                  >
                    TTL PHÒNG BAN
                  </li>
                  <li
                    className={classnames('pt-tab cursor-default', {
                      'pt-tab-color-cabinet': type !== Types.CABINET_PERSONAL,
                    })}
                    role="tab"
                    aria-selected={type === Types.CABINET_PERSONAL}
                  >
                    TTL CÁ NHÂN
                  </li>
                </ul>
              </div>
            </div>
            <div className="wrapper-box-container row-detail-container clearfix">
              <div className="box-left">
                {!isEmpty(this.props.documentsCabinet.item.fileNoiDung) && (
                  <DetailFileContentV2
                    auth={this.props.auth}
                    actions={this.props.actions}
                    file={this.props.documentsCabinet.item.fileNoiDung}
                    bufferHeight={80}
                  />
                )}
              </div>
              <div
                className={classnames('box-right', {
                  fixtopMenuRight:
                    this.state.boxMenuFixTop &&
                    !this.state.showResults &&
                    !this.props.enableScrollFixedMenu &&
                    window.innerWidth >= 1024,
                  fixtopMenuRightHasSideBar:
                    this.state.boxMenuFixTop &&
                    !this.state.showResults &&
                    this.props.enableScrollFixedMenu &&
                    window.innerWidth >= 1024,
                })}
              >
                <div className="box-action-detail full-box-content">
                  <div className="content-action-click" ref="boxAction">
                    {this.state.showListAction && (
                      <DetailBoxAction
                        action={this.props.actions}
                        documentsCabinet={
                          this.props.documentsCabinet &&
                          this.props.documentsCabinet.item
                            ? this.props.documentsCabinet.item
                            : {}
                        }
                        onClickSendMail={this.handleClickSendMail}
                        onHandleSubmit={this.handleSubmit}
                        departments={this.props.departments}
                        onClickEditDocumentsCabinet={
                          this.handleClickEditDocumentsCabinet
                        }
                        onClickDetailInfo={this.handleClickDetailInfo}
                        sendToChat={this.sendToChat}
                        auth={this.props.auth}
                        type={this.state.type}
                        phongBanId={
                          this.props.location &&
                          this.props.location.state &&
                          this.props.location.state.phongBanId
                            ? this.props.location.state.phongBanId
                            : null
                        }
                      />
                    )}
                    {this.state.showResults &&
                      this.state.showSection === 'SuaTuTaiLieu' && (
                        <EditDocumentsCabinet
                          item={item}
                          onClickGoBack={this.handleClickGoBack}
                          onSubmit={this.handleSaveItem}
                          action={this.props.actions}
                        />
                      )}
                    {this.state.showResults &&
                      this.state.showSection === 'ThongTinCongVan' && (
                        <div className="box-action-item">
                          <DetailInfo
                            item={item}
                            path={this.state.path}
                            onClickGoBack={this.handleClickGoBack}
                            auth={this.props.auth}
                          />
                        </div>
                      )}
                    {this.state.showResults &&
                      this.state.showSection === 'GuiEmail' && (
                        <SendMail
                          onClickGoBack={this.handleClickGoBack}
                          action={this.props.actions}
                          work={this.props.documentsCabinet.item}
                          auth={this.props.auth}
                          onSubmit={this.handleSubmit}
                        />
                      )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </MasterLayout>
    )
  }
}

const mapStateToProps = state => ({
  auth: state.auth,

  documentsCabinet: {
    ...state.documentsCabinet,
    item:
      state.entities.documentsCabinet[state.documentsCabinet.detailId] || {},
  },

  departments: state.common.departments,

  enableScrollFixedMenu: state.common.enableScrollFixedMenu,
})

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(Actions, dispatch),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DetailDocumentsCabinet)
