import React, { memo, useCallback } from 'react'
import { withRouter } from 'react-router'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import moment from 'moment'
import { Tooltip } from '@blueprintjs/core'

import { commonAddToasterMessage } from 'actions/index'
import {
  getDSThongBaoChung,
  deleteNotification,
  putNotificationActive,
} from 'actions/notifications'
import { MasterLayout } from 'components/layout'
import Title from 'components/ui/title'
import UIButton from 'components/ui/button'
import MenuPopover from 'components/ui/menu-popover'
import UICheckbox from 'components/ui/checkbox'
import { TruncateTextTooltip } from 'components/common'
import { Table, Pagination } from 'components/newCommon2'

import useList from './hooks/use-list'
import Filter from './components/filter'
import Delete from './components/delete'

const Index = ({ history, actions }) => {
  const {
    filterKeys,
    filterData,
    listData,
    pagination,
    isFetching,
    deleteData,
    onChangeFilter,
    setDeleteData,
    onActive,
  } = useList({
    actions,
    history,
  })

  const getMenuAction = useCallback(
    ({ id }) => {
      return [
        ...(true
          ? [
              {
                icon: 'icon-s-edit',
                text: 'Chỉnh sửa',
                onClick: () =>
                  history.push(`/he-thong/thong-bao/chinh-sua/${id}`),
              },
            ]
          : []),
        ...(true
          ? [
              {
                icon: 'icon-s-delete',
                text: 'Xóa',
                onClick: () => setDeleteData({ id: id }),
              },
            ]
          : []),
      ]
    },

    [history, setDeleteData]
  )

  const columns = [
    {
      key: 'stt',
      width: 5,
      headClass: 'uppercase',
      className: 'align-center element-center max-width-80',
      title: 'Stt',
      render: (v, r, index) => {
        const num = (pagination?.page - 1) * 10 + index + 1
        return `${num}`.padStart(num < 10 ? 2 : 1, '0')
      },
    },
    {
      key: 'tieuDe',
      width: 20,
      headClass: 'uppercase',
      className: 'align-center min-width-200',
      title: 'Tiêu đề',
      dataIndex: 'tieuDe',
      render: value => {
        return (
          <div>
            {value?.length > 20 ? (
              <TruncateTextTooltip content={value} />
            ) : (
              value
            )}
          </div>
        )
      },
    },
    // {
    //   key: 'hienThi',
    //   headClass: 'uppercase',
    //   className: 'align-center max-width-100 element-center align-center',
    //   width: 5,
    //   title: 'Hiển thị',
    //   dataIndex: 'isActive',
    //   render: value =>
    //     value ? <span className="icon-Bao_Cao font-size-10"></span> : '',
    // },
    {
      key: 'ngayTao',
      headClass: 'uppercase',
      className: 'align-center max-width-160',
      width: 5,
      title: 'Ngày tạo',
      dataIndex: 'ngayTao',
      render: value => (
        <div>{value ? moment(value).format('DD/MM/YYYY HH:mm') : ''}</div>
      ),
    },
    {
      key: 'nguoiTao',
      headClass: 'uppercase',
      className: 'align-center max-width-160',
      width: 5,
      title: 'Người tạo',
      dataIndex: 'nguoiTao',
      render: value => <div>{value}</div>,
    },
    {
      key: 'noiDang',
      headClass: 'uppercase',
      className: 'align-center max-width-160',
      width: 5,
      title: 'Nơi đăng',
      render: record => <div>{record.noiDang}</div>,
    },
    {
      key: 'hienThi',
      headClass: 'uppercase',
      className: 'align-center max-width-120 element-center align-center',
      width: 5,
      title: 'Hiển thị',
      render: record => (
        <div onClick={e => e.stopPropagation()}>
          <UICheckbox
            checked={record?.isActive || false}
            onChange={e => onActive(record, e.target.checked)}
          />
        </div>
      ),
    },
    {
      key: 'thaoTac',
      className: 'align-center element-center max-width-60 align-center',
      width: 5,
      render: record => {
        const menu = getMenuAction(record) || []
        if (menu?.length === 0) return null
        return <MenuPopover dataSource={menu} />
      },
    },
  ]

  return (
    <MasterLayout typeSidebar="notification">
      <div>
        <div className="d-flex align-items-center justify-content-space-between gap-10">
          <Title name={'Quản lý thông báo'} icon={'icon2-bell'} />
          <Tooltip
            content={
              <span className="font-size-12">Tạo nội dung thông báo</span>
            }
          >
            <UIButton
              text="TẠO THÔNG BÁO"
              icon="icon-Plus"
              color="blue"
              onClick={() => history.push('/he-thong/thong-bao/them-moi')}
            />
          </Tooltip>
        </div>
        <div className="border mt15">
          <Filter
            filterKeys={filterKeys}
            dataValue={filterData}
            onChangeFilter={onChangeFilter}
          />
          <div>
            <Table
              loading={isFetching}
              error={false}
              autoHide={false}
              columns={columns}
              data={listData?.items}
              onRowClick={({ id }) =>
                history.push(`/he-thong/thong-bao/chi-tiet/${id}`)
              }
              onContextMenu={() => {}}
              tableMinHeight={300}
              tableMinWidth={900}
            />
            {listData?.total > pagination?.limit && (
              <div className="text-center bg-white ph15 pv20 border-top">
                <Pagination
                  total={listData?.total}
                  current={pagination?.page}
                  pageSize={pagination?.limit}
                  onChange={onChangeFilter({ name: 'PAGE' })}
                />
              </div>
            )}
          </div>
        </div>
        {deleteData ? (
          <Delete
            history={history}
            actions={actions}
            dataSource={deleteData}
            onClose={() => setDeleteData(null)}
            onSuccess={() => onChangeFilter({ name: 'RESET' })()}
          />
        ) : (
          ''
        )}
      </div>
    </MasterLayout>
  )
}

const mapStateToProps = state => {
  return {
    currentUserId: state.auth?.user?.nhanVienId,
  }
}

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(
    {
      commonAddToasterMessage,
      getDSThongBaoChung,
      deleteNotification,
      putNotificationActive,
    },
    dispatch
  ),
})

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(memo(Index))
)
