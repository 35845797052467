import React from 'react'
import { PopupConfirm } from 'components/common'
import { Checkbox } from '@blueprintjs/core'
import { Popover, Position, PopoverInteractionKind } from '@blueprintjs/core'
import * as POPUP from 'constants/Popup'
import { Link } from 'react-router-dom'
import { Intent } from '@blueprintjs/core'
import * as MESSAGER from 'constants/MessageForm'
import { HeadingTableSort } from 'components/common'
import { headers } from 'constants/Table'
import { MESSAGE_NOT_FOUND, MESSAGE_ERROR } from 'constants/MessageForm'
import * as Types from 'constants/Api'

const LINK = '/lich-hop/danh-muc/loai-vat-tu/chinh-sua'
class DanhSachLoaiVatTu extends React.Component {
  constructor(props) {
    super(props)
    this.handleClickPopup = this.handleClickPopup.bind(this)
    this.handleSubmitRemoveItem = this.handleSubmitRemoveItem.bind(this)
    this.handleEnabledChange = this.handleEnabledChange.bind(this)
  }

  state = {
    isOpenPopup: false,
    isEnabled: false,
    items: [],
    vatTuId: null,
    vatTuChaId: null,
    stt: null,
    isActive: undefined,
    isMove: false,
    isEmpty: false,
    isError: false,
  }

  handleClickPopup = id => {
    this.setState({
      isOpenPopup: !this.state.isOpenPopup,
      error: '',
      vatTuId: id,
    })
  }

  handleEnabledChange = (value, e) => {
    this.setState({ isEnabled: false })
    if (e.target.type === 'checkbox') {
      this.setState({
        isActive: e.target.checked,
        vatTuId: value.vatTuId,
        vatTuChaId: value.vatTuChaId,
        ten: value.ten,
        moTa: value.moTa,
        isEnabled: true,
      })
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      prevState.isActive !== this.state.isActive ||
      prevState.vatTuId !== this.state.vatTuId
    ) {
      const filter = {
        textSearch: this.props.textSearch?.trim() || undefined,
        nhomVatTuId: this.props.nhomVatTuId || undefined,
        page: this.props.page || 1,
        limit: Types.PAGINATION_LIMIT,
      }
      if (this.state.isEnabled === true) {
        this.props.actions
          .putLoaiVatTu(this.state.vatTuId, {
            ten: this.state.ten,
            mo_ta: this.state.moTa,
            is_active: this.state.isActive,
            vat_tu_cha_id: this.state.vatTuChaId,
          })
          .then(res => {
            if (
              res.payload &&
              res.payload.data.result &&
              res.payload.data.result === true
            ) {
              this.props.actions.getDanhSachLoaiVatTu(filter).then(res => {
                if (res.error || (res.payload && res.payload.status !== 200)) {
                  this.setState({ isError: true })
                }
              })
              return this.props.actions.commonAddToasterMessage({
                message: MESSAGER.TOATS_MESSAGE_SUCCESS,
                intent: Intent.SUCCESS,
              })
            } else {
              this.props.actions.commonAddToasterMessage({
                message: MESSAGER.TOATS_MESSAGE_FAIL,
                intent: Intent.DANGER,
              })
            }
          })
      }
    }
  }

  handleSubmitRemoveItem = () => {
    if (this.state.vatTuId) {
      this.props.actions.deleteNhomVatTu(this.state.vatTuId).then(res => {
        if (res.payload && res.payload.data.result === true) {
          const filter = {
            textSearch: this.props.textSearch?.trim() || undefined,
            nhomVatTuId: this.props.nhomVatTuId || undefined,
            page: this.props.page || 1,
            limit: Types.PAGINATION_LIMIT,
          }
          this.props.actions.getDanhSachLoaiVatTu(filter, true).then(res => {
            if (res.error || (res.payload && res.payload.status !== 200)) {
              this.setState({ isError: true })
            }
          })
          return this.props.actions.commonAddToasterMessage({
            message: MESSAGER.TOATS_MESSAGE_DELETE_SUCCESS,
            intent: Intent.SUCCESS,
          })
        } else {
          this.props.actions.commonAddToasterMessage({
            message: MESSAGER.TOATS_MESSAGE_DELETE_FAIL,
            intent: Intent.DANGER,
          })
        }
      })
    }

    this.setState({
      isOpenPopup: false,
    })
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.items !== this.props.items) {
      this.setState({
        items: nextProps.items,
      })
      if (nextProps.items.length === 0) {
        this.setState({
          isEmpty: true,
        })
      }
      if (nextProps.items.length > 0) {
        this.setState({
          isEmpty: false,
        })
      }
    }
  }

  render() {
    let ItemLoading = (
      <div className="loading-content">
        <span className="shape1"></span>
        <span className="shape2"></span>
        <span className="shape3"></span>
        <span className="shape4"></span>
        <span className="shape5"></span>
        <span className="shape6"></span>
        <span className="shape7"></span>
        <span className="shape8"></span>
        <span className="shape9"></span>
        <span className="shape10"></span>
        <span className="shape11"></span>
        <span className="shape12"></span>
        <span className="shape13"></span>
      </div>
    )
    //items move
    const TableItem = ({ value, i }) => {
      return (
        <div className="table-rows">
          <div className="table-cols-content width-col-5">
            <span className="order-content">{i < 10 ? '0' + i : i}</span>
          </div>
          <div className="table-cols-content width-col-25">
            <span className="order-content">{value.ten}</span>
          </div>
          <div className="table-cols-content width-col-25">
            <span className="order-content">{value.tenNhomVatTu}</span>
          </div>
          <div className="table-cols-content width-col-35">
            <span className="text-content">{value.moTa}</span>
          </div>
          <div className="table-cols-content width-col-5">
            <span className="text-content">
              <Checkbox
                className="checkbox-item"
                onChange={this.handleEnabledChange.bind(this, value)}
                defaultChecked={value.isActive}
              />
            </span>
          </div>
          <div className="table-cols-content width-col-5">
            <Popover
              popoverClassName="pt-popover-content-sizing pt-minimal"
              position={Position.BOTTOM_RIGHT}
              interactionKind={PopoverInteractionKind.HOVER}
              openOnTargetFocus={true}
              content={
                <div className="menu-popover">
                  <Link className="item" to={`${LINK}/${value.vatTuId}`}>
                    <span className="pt-icon pt-icon-annotation"></span>
                    <span className="name_action">Chỉnh sửa</span>
                  </Link>
                  <span
                    className="item"
                    onClick={this.handleClickPopup.bind(this, value.vatTuId)}
                  >
                    <span className="pt-icon pt-icon-trash"></span>
                    <span className="name_action">Xóa</span>
                  </span>
                </div>
              }
            >
              <span className="icon-More"></span>
            </Popover>
          </div>
        </div>
      )
    }
    //list foreach
    const TableList = ({ items }) => {
      return (
        <div className="sortableList">
          {items.map((value, index) => (
            <TableItem
              key={`item-${index}`}
              i={(this.props.page - 1) * 10 + index + 1}
              value={value}
            />
          ))}
        </div>
      )
    }
    return (
      <div className="page-list-container page-list-role-container">
        <div className="table-container table-subcollap-container">
          <div className="table-scroll">
            <div className="collapse-panel-content">
              <div className="table-display">
                <div className="table-display-container">
                  {this.props.items.length > 0 &&
                    !this.props.isError &&
                    !this.state.isError && (
                      <HeadingTableSort data={headers.dsLoaiVatTu} />
                    )}
                  <div className="table-body-container">
                    {this.props.isLoading ? (
                      <div className="table-loading-system">
                        {ItemLoading}
                        {ItemLoading}
                        {ItemLoading}
                      </div>
                    ) : (
                      !this.props.isError &&
                      !this.state.isError && (
                        <TableList items={this.state.items} />
                      )
                    )}
                    {this.state.isEmpty &&
                      !this.props.isLoading &&
                      (!this.props.isError || !this.state.isError) && (
                        <div className="error-page-container">
                          <div className="error-content">
                            <img
                              src={
                                process.env.PUBLIC_URL +
                                '/images/search-result-not-found.png'
                              }
                              alt="search-result-not-found"
                              className="img-thumb-error"
                            />
                            <p className="description-note">
                              {MESSAGE_NOT_FOUND}
                            </p>
                          </div>
                        </div>
                      )}
                    {(this.props.isError || this.state.isError) &&
                      !this.props.isLoading && (
                        <div className="error-page-container">
                          <div className="error-content">
                            <img
                              src={
                                process.env.PUBLIC_URL + '/images/Server.png'
                              }
                              alt="error"
                              className="img-thumb-error"
                            />
                            <p className="description-note">{MESSAGE_ERROR}</p>
                          </div>
                        </div>
                      )}
                    {/* <ListFooter /> */}
                    <PopupConfirm
                      isOpen={this.state.isOpenPopup}
                      onClose={this.handleClickPopup}
                      title={POPUP.XOA_DANH_MUC}
                      text={POPUP.TEXT_BUTTON_SUBMIT}
                      onSubmit={this.handleSubmitRemoveItem}
                    >
                      {POPUP.TEXT_SUBMIT_BODY}
                    </PopupConfirm>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default DanhSachLoaiVatTu
