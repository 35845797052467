import React from 'react'
import { Link } from 'react-router-dom'
import classnames from 'classnames'
import { Button } from '@blueprintjs/core'

import { PopupConfirm, SelectTree } from '../common/'
import { Permission, Authorization } from '../../components/auth'
const DEPARTMENT = '2'
const PERSONAL = '3'
class ButtonList extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      isOpenPopup: false,
      departmentSelect: this.props.auth.mainDepartmentId,
    }
    this.handleDelete = this.handleDelete.bind(this)
    this.handleChangeSelect = this.handleChangeSelect.bind(this)
    this.handleMoveFile = this.handleMoveFile.bind(this)
    this.handleDownloadMultipleFiles =
      this.handleDownloadMultipleFiles.bind(this)
    this.handleClickPopup = this.handleClickPopup.bind(this)
    this.handleChange = this.handleChange.bind(this)
  }

  componentWillReceiveProps(props) {
    if (props.phongBanId !== this.props.phongBanId) {
      this.setState({
        departmentSelect: props.phongBanId,
      })
    }
  }

  handleDelete() {
    this.props.onMultiDelete && this.props.onMultiDelete()
    this.setState({
      isOpenPopup: false,
    })
  }

  handleMoveFile() {
    this.props.onMoveFile && this.props.onMoveFile()
  }

  handleChangeSelect(e) {
    this.props.onChangeOwner && this.props.onChangeOwner(e.target.value)
  }

  handleClickPopup() {
    this.setState({
      isOpenPopup: !this.state.isOpenPopup,
    })
  }

  handleDownloadMultipleFiles() {
    this.props.onDownloadMultipleFiles && this.props.onDownloadMultipleFiles()
  }

  handleChange(e) {
    if (e !== undefined) {
      this.setState(
        {
          departmentSelect: e,
        },
        () => {
          this.props.updateCategories &&
            this.props.updateCategories(e, 'Switch').then(() => {
              this.props.onSubmitFilter &&
                this.props.onSubmitFilter({ phong_ban_id: e })
            })
        }
      )
    }
  }

  render() {
    let typeName
    let isDisplayAddFile = true
    switch (this.props.type) {
      case PERSONAL:
        typeName = 'ca-nhan'
        isDisplayAddFile = false
        break
      case DEPARTMENT:
        typeName = 'phong-ban'
        break
      default:
        typeName = 'don-vi'
    }

    return (
      <div className="head-action-group form-container">
        <div className="list-button">
          {isDisplayAddFile && (
            <Link
              className="btn-action pt-button btn-addfile"
              to={`/tu-tai-lieu/them-file/${typeName}`}
            >
              <span className="name-btn">Thêm file</span>
              <span className="pt-icon icon-upload"></span>
            </Link>
          )}
          <Button
            className={classnames('btn-action btn-movefile', {
              'cursor-default': this.props.disableMove,
            })}
            onClick={this.handleMoveFile}
            disabled={this.props.disableMove}
          >
            <span className="name-btn">Di chuyển tài liệu</span>
            <span className="pt-icon icon-move"></span>
          </Button>
          <Button
            className={classnames('btn-action btn-delfile', {
              'cursor-default': this.props.disableDelete,
            })}
            onClick={this.handleClickPopup}
            disabled={
              this.props.disableDelete
              // || this.props.type === DEPARTMENT
            }
          >
            <span className="name-btn">Xóa tài liệu</span>
            <span className="pt-icon icon-trash"></span>
          </Button>
          <Button
            className={classnames('btn-action btn-delfile', {
              'cursor-default': this.props.disableDelete,
            })}
            onClick={this.handleDownloadMultipleFiles}
            disabled={this.props.disableDelete}
            loading={this.props.isLoadingDownloadFiles}
          >
            <span className="name-btn">Tải file</span>
            <span className="pt-icon icon2-download"></span>
          </Button>
        </div>
        {/* TTL phòng ban hiển thị select */}
        {this.props.type === DEPARTMENT &&
          Authorization.vaild(this.props.auth.permission, [
            Permission.ADMIN,
          ]) && (
            <div className="select-tab-item">
              <SelectTree
                data={this.props.departmentSelect}
                type="Department"
                permission={false}
                value={
                  this.props.isLoading === false
                    ? this.state.departmentSelect
                    : ''
                }
                handleChange={this.handleChange}
                isLoading={this.props.isLoading}
              />
            </div>
          )}
        <div className="clear-fix"></div>
        <PopupConfirm
          isOpen={this.state.isOpenPopup}
          onClose={this.handleClickPopup}
          title="Xóa tài liệu"
          text="Xóa"
          onSubmit={this.handleDelete}
        >
          Anh/chị có muốn xóa tài liệu này?
        </PopupConfirm>
      </div>
    )
  }
}

ButtonList.defaultProps = {
  units: [],
}

export default ButtonList
