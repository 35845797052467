import React, { Component } from 'react'
import { Button } from '@blueprintjs/core'
import PropTypes from 'prop-types'
import { SelectTree } from 'components/common'

class ListFilter extends Component {
  constructor(props) {
    super(props)
    this.handleSubmit = this.handleSubmit.bind(this)
  }

  handleSubmit(e) {
    if (e) {
      e.preventDefault()
    }

    this.props.onSubmit && this.props.onSubmit()
  }

  render() {
    const units = this.props?.units
    const filterData = this.props.filterData
    return (
      <form
        onSubmit={this.handleSubmit}
        className="search-container search-DocumentarySearch py-12 px-sm-15"
      >
        <div className="d-flex wrap align-items-center column-gap-20 row-gap-10">
          <div className="flex-0 min-width-200">
            <SelectTree
              data={units}
              type="Unit"
              permission={false}
              value={filterData?.donViId ? filterData.donViId : ''}
              placeholder="Chọn đơn vị"
              handleChange={value =>
                this.props.onChangeFilter('donViId', value, true)
              }
              className="tree-custom-meeting"
            />
          </div>
          <div className="flex-1 min-width-300 align-items-end">
            <div className="form-input-filter flex-auto border-radius-3 position-gridview">
              <input
                autoFocus={false}
                autoComplete="off"
                placeholder="Tìm kiếm địa điểm họp"
                type="text"
                className="input border-transparent pr-40 input-search-filter"
                onChange={e =>
                  this.props.onChangeFilter('textSearch', e.target.value)
                }
                name="textSearch"
                value={filterData?.textSearch ? filterData.textSearch : ''}
              />
              {filterData?.textSearch && (
                <i
                  className="icon-close pt-close-gridview-search"
                  onClick={() =>
                    this.props.onChangeFilter('textSearch', '', true)
                  }
                ></i>
              )}
              <Button
                type="submit"
                className="form-input-btn border-radius-lelf-0 pt-intent-btn-green-shadow btn-icon"
              >
                <span className="pt-icon pt-icon-search mr0"></span>
              </Button>
            </div>
            <Button
              onClick={this.props.onClearFilter}
              type="button"
              className="btn-icon flex-solid ml10 pt-intent-btn-white"
            >
              <span className="pt-icon icon-Loading mr0"></span>
            </Button>
          </div>
        </div>
      </form>
    )
  }
}

ListFilter.propTypes = {
  onSubmit: PropTypes.func,
}

export default ListFilter
