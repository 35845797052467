import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Popover, Position, PopoverInteractionKind } from '@blueprintjs/core'
import { Link, Redirect } from 'react-router-dom'
import moment from 'moment'
import classnames from 'classnames'
import { Intent } from '@blueprintjs/core'
import get from 'lodash/get'

import { PopupConfirm } from '../common/'
import { Authorization, Permission } from '../auth'
import * as POPUP from '../../constants/Popup'
import { isValidCondition } from '../../helpers/Helper'
import {
  DE_XUAT_THU_HOI_THANH_CONG,
  DE_XUAT_THU_HOI_KHONG_THANH_CONG,
} from '../../constants/MessageForm'

class ListItem extends Component {
  constructor(props) {
    super(props)
    this.handleClick = this.handleClick.bind(this)
    this.handleSubmitRemoveItem = this.handleSubmitRemoveItem.bind(this)
    this.state = {
      isRedirect: false,
      isOpenPopup: false,
      modelPopup: {},
      buttonLoading: false,
      isOpenConfirmRecall: false,
      lyDoThuHoi: '',
      isLoadingRecall: false,
    }
  }

  refHandlers = {
    toaster: (ref: Toaster) => (this.toaster = ref),
  }

  handleClick = () => {
    const item = this.props.item
    if (item?.congVanDiId) {
      this.props.actions.postXemCongVanDi(item.congVanDiId)
    }

    this.setState({
      isRedirect: true,
    })
  }

  handleClickPopup = key => {
    switch (key) {
      case 'DE_XUAT_THU_HOI':
        return this.setState({
          modelPopup: {
            title: POPUP.DE_XUAT_THU_HOI,
            text: POPUP.TEXT_BUTTON_SUBMIT,
            onSubmit: this.handleSubmitDeXuatThuHoi,
            body: '',
            showTextarea: true,
            showTextWarning: false,
            acceptSpace: true,
          },
          isOpenPopup: true,
        })
      case 'THU_HOI':
        return this.setState({
          modelPopup: {
            title: POPUP.THU_HOI_VAN_BAN_CONG_VAN,
            text: POPUP.TEXT_BUTTON_SUBMIT,
            onSubmit: this.handleSubmitRemoveItem,
            body: POPUP.LY_DO_THU_HOI,
            showTextarea: true,
            acceptSpace: false,
          },
          isOpenPopup: true,
        })
      default:
        return this.setState({
          isOpenPopup: false,
        })
    }
  }

  handleSubmitRemoveItem = (lyDo, e) => {
    e.preventDefault()
    this.props.onHandleSubmitRemoveItem(lyDo, this.props.item.congVanDiId)
  }

  handleSubmitDeXuatThuHoi = async (lyDo, e) => {
    e.preventDefault()
    try {
      this.setState({
        buttonLoading: true,
      })
      const params = {
        cong_van_di_id: this.props.item.congVanDiId,
        ly_do_thu_hoi: lyDo,
      }
      const data = await this.props.actions.postDeXuatThuHoiCongVanDi(params)
      if (
        data &&
        (data.error ||
          (data.payload.status !== 200 && data.payload.status !== 201))
      ) {
        if (data.error.response.status === 400) {
          return this.props.actions.commonAddToasterMessage({
            message: data.error.response.data.message,
            intent: Intent.DANGER,
          })
        }

        return this.props.actions.commonAddToasterMessage({
          message: DE_XUAT_THU_HOI_KHONG_THANH_CONG,
          intent: Intent.DANGER,
        })
      }
      this.props.actions.getTongSoCongVanThuHoi()
      this.props.reloadData && this.props.reloadData()
      return this.props.actions.commonAddToasterMessage({
        message: DE_XUAT_THU_HOI_THANH_CONG,
        intent: Intent.SUCCESS,
      })
    } catch (error) {
      this.props.actions.commonAddToasterMessage({
        message: DE_XUAT_THU_HOI_KHONG_THANH_CONG,
        intent: Intent.DANGER,
      })
    } finally {
      this.setState({
        buttonLoading: false,
        isOpenPopup: false,
      })
    }
  }

  onThuHoi = () => {
    this.setState({
      isOpenConfirmRecall: !this.state.isOpenConfirmRecall,
      lyDoThuHoi: '',
    })
  }

  onSubmitRecall = e => {
    e.preventDefault()
    this.setState({ isLoadingRecall: true })
    this.props.actions
      .putRecall({
        cong_van_di_id: this.props.item.congVanDiId,
        ly_do_thu_hoi: this.state?.lyDoThuHoi
          ? this.state.lyDoThuHoi.trim()
          : '',
      })
      .then(res => {
        this.setState({ isLoadingRecall: false })
        this.onThuHoi()
        if (res?.error) {
          return this.props.actions.commonAddToasterMessage({
            message: res.error?.response?.data?.message
              ? res.error.response.data.message
              : 'Thu hồi công văn không thành công!',
            intent: Intent.WARNING,
          })
        }

        this.props.actions.commonAddToasterMessage({
          message: 'Thu hồi công văn thành công!',
          intent: Intent.SUCCESS,
        })
        return this.props.reloadData && this.props.reloadData()
      })
  }

  render() {
    const { item, number } = this.props
    const { modelPopup, isOpenPopup } = this.state
    if (!item || !number) {
      return
    }
    if (this.state.isRedirect) {
      return (
        <Redirect
          push
          to={`/cong-van/cong-van-di/chi-tiet/${item.congVanDiId}`}
        />
      )
    }

    // const checkTongCongTy = this.props.auth.user && this.props.auth.user.dsChucDanh && this.props.auth.user.dsChucDanh.filter(item => item.donVi.donViCha === null);
    // Check hiển thị action đề xuất thu hồi
    let checkPhongBanLap = []
    let checkDisabledDeXuatThuHoi = true
    if (get(item.phongBanLap, 'phongBanId')) {
      checkPhongBanLap =
        this.props.auth.user &&
        this.props.auth.user.dsChucDanh &&
        this.props.auth.user.dsChucDanh.filter(
          element => element.phongBanId === item.phongBanLap.phongBanId
        )
    }
    const isDaThuHoi = item?.trangThai === 1

    if (
      isValidCondition(
        this.props.auth.permission,
        [Permission.CONGVANDI_QUANLY],
        'AND'
      ) &&
      !item.isDeXuatThuHoi
    ) {
      checkDisabledDeXuatThuHoi = false
    } else if (
      isValidCondition(
        this.props.auth.permission,
        [Permission.DEXUAT_THUHOI],
        'AND'
      ) &&
      !item.isDeXuatThuHoi
    ) {
      checkDisabledDeXuatThuHoi = false
    }

    return (
      <tr
        className={classnames({
          'status-urgency-nomarl':
            this.props.item.doKhan && this.props.item.doKhan.maDoKhan === 'BT',
          'status-urgency':
            this.props.item.doKhan && this.props.item.doKhan.maDoKhan === 'KH',
          'status-urgency-hight':
            this.props.item.doKhan && this.props.item.doKhan.maDoKhan === 'TK',
        })}
      >
        <td onClick={this.handleClick}>{number > 9 ? number : '0' + number}</td>
        <td onClick={this.handleClick}>
          {item.ngayCongVan
            ? moment(item.ngayCongVan).format('DD/MM/YYYY')
            : ''}
        </td>
        <td onClick={this.handleClick}>
          {item.soCongVan ? item.soCongVan : ''}
        </td>
        <td onClick={this.handleClick} className="textarea-content">
          {item.trichYeu ? item.trichYeu : ''}
        </td>
        <td onClick={this.handleClick}>
          {item.phongBanLap && item.phongBanLap.maPhongBan
            ? item.phongBanLap.maPhongBan
            : ''}
        </td>
        <td onClick={this.handleClick}>
          {item.nguoiKy && item.nguoiKy.tenNhanVien
            ? item.nguoiKy.tenNhanVien
            : ''}
        </td>
        {/* <td>
          <ListTooltipShowMore
            data={get(item, 'dsDonViNhan') || []}
            dataKey={{ name: 'tenVietTat' }}
          />
        </td> */}
        <td>
          {(isValidCondition(
            this.props.auth.permission,
            [Permission.CONGVANDI_QUANLY],
            'AND'
          ) ||
            (isValidCondition(
              this.props.auth.permission,
              [Permission.DEXUAT_THUHOI],
              'AND'
            ) &&
              checkPhongBanLap.length !== 0)) && (
            <Popover
              popoverClassName="pt-popover-content-sizing pt-minimal"
              position={Position.BOTTOM_RIGHT}
              interactionKind={PopoverInteractionKind.HOVER}
              onInteraction={this.handleCheckActionThuHoi}
              openOnTargetFocus={true}
              content={
                <div className="menu-popover">
                  <Authorization.Element
                    permission={[Permission.CONGVANDI_QUANLY]}
                  >
                    <Link
                      className="item"
                      to={`/cong-van/cong-van-di/chinh-sua/${item.congVanDiId}`}
                    >
                      <span className="pt-icon pt-icon-annotation"></span>
                      <span className="name_action">Chỉnh sửa</span>
                    </Link>
                  </Authorization.Element>
                  <span
                    className={classnames('item', {
                      disabled: checkDisabledDeXuatThuHoi,
                    })}
                    onClick={() => this.handleClickPopup('DE_XUAT_THU_HOI')}
                  >
                    <span className="pt-icon icon-recalled font-13"></span>
                    <span className="name_action">Đề xuất thu hồi</span>
                  </span>
                  <Authorization.Element
                    permission={[Permission.THUHOI_CONGVANDI]}
                  >
                    <span
                      className={classnames('item', {
                        disabled: isDaThuHoi,
                      })}
                      onClick={this.onThuHoi}
                    >
                      <span className="pt-icon icon-recalled font-13"></span>
                      <span className="name_action">Thu hồi</span>
                    </span>
                  </Authorization.Element>
                </div>
              }
            >
              <span className="icon-More"></span>
            </Popover>
          )}
          <PopupConfirm
            isOpen={this.state.isOpenConfirmRecall}
            title={'Thu hồi'}
            text={POPUP.TEXT_BUTTON_SUBMIT}
            isLoading={this.state.isLoadingRecall}
            onClose={this.onThuHoi}
            onSubmit={this.onSubmitRecall}
          >
            <label className="text-14-600-21">
              Lý do thu hồi
              <span className="color-red ml5">*</span>
            </label>
            <textarea
              className="pt-input mt5"
              style={{ resize: 'vertical' }}
              name="lyDo"
              cols="36"
              rows="3"
              value={this.state.lyDoThuHoi}
              onChange={e => this.setState({ lyDoThuHoi: e.target.value })}
              placeholder={'Nhập nội dung...'}
              dir="auto"
              maxLength="500"
            ></textarea>
            {/* {'Anh/chị có muốn thu hồi công văn này?'} */}
          </PopupConfirm>
          <PopupConfirm
            isOpen={isOpenPopup}
            textarea={get(modelPopup, 'showTextarea', '')}
            onClose={this.handleClickPopup}
            title={get(modelPopup, 'title', '')}
            text={get(modelPopup, 'text', '')}
            onSubmit={get(modelPopup, 'onSubmit', () => {})}
            showTextWarning={get(modelPopup, 'showTextWarning', true)}
            isLoading={this.state.buttonLoading}
            acceptSpace={get(modelPopup, 'acceptSpace', false)}
          >
            {get(modelPopup, 'body', '')}
          </PopupConfirm>
        </td>
      </tr>
    )
  }
}

ListItem.defaultProps = {
  item: {},
  number: 0,
}

ListItem.propTypes = {
  actions: PropTypes.object.isRequired,
  item: PropTypes.object.isRequired,
  number: PropTypes.number.isRequired,
  disableAction: PropTypes.bool,
}

export default ListItem
