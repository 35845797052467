import React, { Component } from 'react'
import PropTypes from 'prop-types'
// import AssignmentInformation from './AssignmentInformation';
// import InformationSteering from '../common/InformationSteering';
import { LoadingItem } from '../common/'
import classnames from 'classnames'
import { Authorization, Permission } from '../auth'
import { Scrollbars } from 'react-custom-scrollbars'
import * as Types from '../../constants/Api'
import * as Tool from '../../helpers'
import { FileList } from '../../components/common'
import { HOAN_THANH_NHANH } from 'constants/MessageForm'
import { PopupConfirm } from 'components/common'
import * as POPUP from 'constants/Popup'

class DetailBoxActionPersonal extends Component {
  static propTypes = {
    assignmentInformation: PropTypes.object,
    auth: PropTypes.object.isRequired,
    archives: PropTypes.array.isRequired,
    onClickSaveDocument: PropTypes.func,
    onClickSendMail: PropTypes.func,
    onClickReport: PropTypes.func.isRequired,
    onHandleSubmit: PropTypes.func.isRequired,
    path: PropTypes.string.isRequired,
    onClickExecutingView: PropTypes.func.isRequired,
    disableAlertMistake: PropTypes.bool,
  }

  state = {
    showBoxAction: false,
    chucDanhId: null,
    isSubmit: false,
    isOpenPopupHoanThanhNhanh: false,
    phieuGiaoViecId: '',
  }

  constructor(props) {
    super(props)
    this.handleSubmitReportWork = this.handleSubmitReportWork.bind(this)
  }

  onMenu = (name = '') => {
    this.props.onMenuItem && this.props.onMenuItem(name)
  }

  componentDidMount() {
    let chucDanhId = this.props.auth ? this.props.auth.roleId : null
    this.setState({ chucDanhId })
  }

  showBoxAction(e) {
    e.preventDefault()
    this.setState({
      showBoxAction: !this.state.showBoxAction,
    })
  }

  handleSubmitReportWork = () => {
    // TODO: Thay Thế: Sử dụng field dsNguoiDaBaoCao để kiểm tra user đã báo cáo chưa
    // this.props.onHandleSubmit && this.props.onHandleSubmit('SuaBaoCao', null, null, null, this.props.reportWork.phieuGiaoViecId)

    if (this.props.work && this.props.work.phieuGiaoViecId) {
      this.props.onHandleSubmit &&
        this.props.onHandleSubmit(
          'SuaBaoCao',
          null,
          null,
          null,
          this.props.work.phieuGiaoViecId
        )
    }
  }

  handleClickComplete(id) {
    this.setState({ isOpenPopupHoanThanhNhanh: true, phieuGiaoViecId: id })
  }

  onClosePopupComplete = () => {
    this.setState({ isOpenPopupHoanThanhNhanh: false, phieuGiaoViecId: '' })
  }

  onSubmitComplete = () => {
    const phieuGiaoViecId = this.state?.phieuGiaoViecId
    if (this.props.handleClickComplete && phieuGiaoViecId) {
      this.props.handleClickComplete(phieuGiaoViecId)
    }
  }

  render() {
    const token =
      this.props.auth &&
      this.props.auth.token &&
      this.props.auth.token.accessToken
        ? this.props.auth.token.accessToken
        : ''
    let showReport = true
    let dsNguoiDaBaoCao = []
    // TODO: Thay Thế: Sử dụng field dsNguoiDaBaoCao để kiểm tra user đã báo cáo chưa
    if (this.props.work && this.props.work.dsNguoiDaBaoCao) {
      dsNguoiDaBaoCao = this.props.work.dsNguoiDaBaoCao
      showReport =
        this.props.work.dsNguoiDaBaoCao.find(
          item => item.chucDanhId === this.state.chucDanhId
        ) === undefined
    }

    // if (this.props.reportWork.length > 0) {
    //   for (let i = 0; i < this.props.reportWork.length; i++) {
    //     if (this.props.reportWork[i].nguoiThucHien.chucDanhId === this.state.chucDanhId) {
    //       showReport = false;
    //       break;
    //     }
    //   }
    // }
    return (
      <div className="list-action">
        {this.props.isLoadingData &&
        Object.values(this.props.isLoadingData).find(item => item === true) !==
          undefined ? (
          <LoadingItem />
        ) : (
          <div>
            <p className="title">Thực Hiện</p>
            <span
              className="icon-More show-th-mobile"
              onClick={this.showBoxAction.bind(this)}
            ></span>
            {this.props.work &&
              this.props.work.fileDinhKemEvn &&
              this.props.work.fileDinhKemEvn.length > 0 && (
                <div className="detail-info-container section-box-info">
                  <div className="list-items">
                    <div className="row item-value">
                      <div className="col-xs-3 left-content">
                        <span className="label-text">
                          File đính kèm ({this.props.work.fileDinhKemEvn.length}
                          ):
                        </span>
                      </div>
                      <div className="col-xs-9 right-content">
                        <Scrollbars
                          autoHeight
                          autoHeightMin={0}
                          autoHeightMax={200}
                        >
                          <span className="detail">
                            {this.props.work.fileDinhKemEvn.map(
                              (value, item) => {
                                let extensionTK = null
                                const fileDinhKem = value.fileName
                                if (fileDinhKem) {
                                  extensionTK = fileDinhKem
                                    .slice(
                                      ((value.fileName.lastIndexOf('.') - 1) >>>
                                        0) +
                                        2
                                    )
                                    .toLowerCase()
                                }
                                return (
                                  <div className="info-file" key={item}>
                                    <span className="info">
                                      {!value.migrated && (
                                        <a
                                          style={{
                                            backgroundImage: `url(${Tool.Utils.typeFile(
                                              extensionTK
                                            )})`,
                                          }}
                                          className="link-file"
                                          target="_blank"
                                          rel="noopener noreferrer"
                                          href={`${Types.FILE_URL}/api/file/get/${value.fileId}?BearerToken=${token}`}
                                        >
                                          {value.tenFile} -{' '}
                                          {Tool.Utils.getFileSize(
                                            value.kichThuoc
                                          )}
                                        </a>
                                      )}
                                    </span>
                                  </div>
                                )
                              }
                            )}
                          </span>
                        </Scrollbars>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            {Tool.Utils.checkBanTongHop(this.props.auth.mainDepartmentId) &&
              this.props.filesThamKhao &&
              this.props.filesThamKhao.length !== 0 && (
                <div className="pl20 pr20">
                  <div className="row">
                    <label className="pt-label col-md-3 col-xs-12 label-text-detail">
                      File tham khảo ({this.props.filesThamKhao.length}):
                    </label>
                    <div className="pt-form-content col-md-9 col-xs-12">
                      <div className="pl10">
                        <FileList
                          auth={this.props.auth}
                          receiveDocument={{
                            fileThamKhao: this.props.filesThamKhao,
                          }}
                          field="fileThamKhao"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              )}
            <div
              className={classnames('box-action', {
                'box-action-open': this.state.showBoxAction,
              })}
            >
              <div className="close-mobile-menu-detail">
                <button
                  className="click-item"
                  onClick={this.showBoxAction.bind(this)}
                >
                  <span className="pt-icon pt-icon-cross"></span>
                </button>
              </div>
              {/*Action menu da-thuc-hien && dang-thuc-hien*/}
              {(this.props.match.params.status === 'da-thuc-hien' ||
                this.props.match.params.status === 'dang-thuc-hien') && (
                <div className="row list-action-detail">
                  <div className="col-xs-4 col-sm-6 col-md-6 item">
                    <button
                      className="click-item"
                      onClick={this.props.onClickExecutingView}
                    >
                      <i className="icon-Xemthuchien icon-cpc"></i>
                      <span className="action-name">Xem thực hiện</span>
                    </button>
                  </div>

                  {!(
                    this.props.isNewAssignDoc !== undefined &&
                    this.props.isNewAssignDoc === true
                  ) && (
                    <div className="col-xs-4 col-sm-6 col-md-6 item">
                      <button
                        className="click-item"
                        onClick={this.props.onClickInformationAssign}
                      >
                        <i className="icon-Chidao icon-cpc"></i>
                        <span className="action-name">Thông tin chỉ đạo</span>
                      </button>
                    </div>
                  )}
                  {this.props.assignmentInformation &&
                    !(
                      this.props.isNewAssignDoc !== undefined &&
                      this.props.isNewAssignDoc === true
                    ) && (
                      <div className="col-xs-4 col-sm-6 col-md-6 item">
                        <button
                          className="click-item"
                          onClick={this.props.onClickInformationWork}
                        >
                          <i className="icon-Thong_Tin_Giao_Viec icon-cpc"></i>
                          <span className="action-name">
                            Thông tin giao việc
                          </span>
                        </button>
                      </div>
                    )}

                  {dsNguoiDaBaoCao &&
                    dsNguoiDaBaoCao.length > 0 &&
                    !showReport && (
                      <div className="col-xs-4 col-sm-6 col-md-6 item">
                        <button
                          className="click-item"
                          onClick={this.handleSubmitReportWork}
                        >
                          <i className="icon-Ban_Bao_Cao icon-cpc"></i>
                          <span className="action-name">Sửa/Hủy báo cáo</span>
                        </button>
                      </div>
                    )}
                  {!(
                    this.props.isNewAssignDoc !== undefined &&
                    this.props.isNewAssignDoc === true
                  ) && (
                    <div className="col-xs-4 col-sm-6 col-md-6 item">
                      <button
                        className="click-item"
                        onClick={this.props.onClickSaveDocument}
                      >
                        <i className="icon-Luututailieu icon-cpc"></i>
                        <span className="action-name">Lưu tủ tài liệu</span>
                      </button>
                    </div>
                  )}
                  {!(
                    this.props.isNewAssignDoc !== undefined &&
                    this.props.isNewAssignDoc === true
                  ) && (
                    <div className="col-xs-4 col-sm-6 col-md-6 item">
                      <button
                        className="click-item"
                        onClick={this.props.onClickSendMail}
                      >
                        <i className="icon-Gmail icon-cpc"></i>
                        <span className="action-name">Gửi email</span>
                      </button>
                    </div>
                  )}
                  {(this.props.work.congVanNoiBoId ||
                    !(
                      this.props.isNewAssignDoc !== undefined &&
                      this.props.isNewAssignDoc === true
                    )) && (
                    <div className="col-xs-4 col-sm-6 col-md-6 item">
                      <button
                        className="click-item"
                        onClick={
                          this.props.work.congVanNoiBoId
                            ? this.props.onClickFlowdocumentary
                            : this.props.onClickFlowDocument
                        }
                      >
                        <i className="icon-organization icon-cpc"></i>
                        <span className="action-name">Luồng công văn</span>
                      </button>
                    </div>
                  )}
                  {!(
                    this.props.isNewAssignDoc !== undefined &&
                    this.props.isNewAssignDoc === true
                  ) && (
                    <div className="col-xs-4 col-sm-6 col-md-6 item">
                      <button
                        className="click-item"
                        onClick={this.props.sendToChat}
                      >
                        <i className="icon-speech-bubble icon-cpc"></i>
                        <span className="action-name">Gửi tin nhắn</span>
                      </button>
                    </div>
                  )}
                  {this.props.anLayYKien && (
                    <Authorization.Element
                      permission={[Permission.HDTV_LAYYKIEN]}
                    >
                      <div className="col-xs-4 col-sm-6 col-md-6 item">
                        <button
                          className="click-item"
                          onClick={this.props.onClickLayYKien}
                        >
                          <i className="icon-bubble-cuoc-tro-chuyen icon-cpc"></i>
                          <span className="action-name">Lấy ý kiến</span>
                        </button>
                      </div>
                    </Authorization.Element>
                  )}
                  <div className="col-xs-4 col-sm-6 col-md-6 item">
                    <button
                      className="click-item"
                      onClick={() => this.onMenu('USER_VIEWED')}
                    >
                      <span className="icon-quy-trinh-ca-nhan icon-cpc"></span>
                      <span className="action-name">
                        Danh sách người đã xem
                      </span>
                    </button>
                  </div>
                </div>
              )}

              {/*Action menu chua-thuc-hien*/}
              {this.props.match.params.status === 'chua-thuc-hien' && (
                <div className="row list-action-detail">
                  <div className="col-xs-4 col-sm-6 col-md-6 item">
                    <button
                      className="click-item"
                      onClick={this.props.onClickExecutingView}
                    >
                      <i className="icon-Xemthuchien icon-cpc"></i>
                      <span className="action-name">Xem thực hiện</span>
                    </button>
                  </div>
                  {!(
                    this.props.isNewAssignDoc !== undefined &&
                    this.props.isNewAssignDoc === true
                  ) && (
                    <div className="col-xs-4 col-sm-6 col-md-6 item">
                      <button
                        className="click-item"
                        onClick={this.props.onClickInformationAssign}
                      >
                        <i className="icon-Chidao icon-cpc"></i>
                        <span className="action-name">Thông tin chỉ đạo</span>
                      </button>
                    </div>
                  )}
                  {this.props.assignmentInformation &&
                    !(
                      this.props.isNewAssignDoc !== undefined &&
                      this.props.isNewAssignDoc === true
                    ) && (
                      <div className="col-xs-4 col-sm-6 col-md-6 item">
                        <button
                          className="click-item"
                          onClick={this.props.onClickInformationWork}
                        >
                          <i className="icon-Thong_Tin_Giao_Viec icon-cpc"></i>
                          <span className="action-name">
                            Thông tin giao việc
                          </span>
                        </button>
                      </div>
                    )}

                  {showReport && this.props.work && (
                    <div className="col-xs-4 col-sm-6 col-md-6 item">
                      <button
                        className="click-item"
                        onClick={this.props.onClickReport}
                      >
                        <i className="icon-Ban_Bao_Cao icon-cpc"></i>
                        <span className="action-name">Báo cáo</span>
                      </button>
                    </div>
                  )}

                  {this.props.isLoadingData &&
                    Object.values(this.props.isLoadingData).find(
                      item => item === true
                    ) === undefined &&
                    this.props.personalWorks &&
                    this.props.personalWorks.item &&
                    !this.props.personalWorks.item.baoCaoLai && (
                      <div className="col-xs-4 col-sm-6 col-md-6 item">
                        <button
                          className="click-item"
                          disabled={this.state.isSubmit}
                          onClick={this.handleClickComplete.bind(
                            this,
                            this.props.phieuGiaoViecId
                          )}
                        >
                          <i className="icon-cpc icon-edit-line icon-done-work"></i>
                          <span className="action-name">
                            {HOAN_THANH_NHANH}
                          </span>
                        </button>
                      </div>
                    )}

                  {showReport && this.props.work && (
                    <Authorization.Element
                      permission={[Permission.CONGVIEC_GIAOVIEC]}
                    >
                      <div className="col-xs-4 col-sm-6 col-md-6 item">
                        <button
                          className="click-item"
                          onClick={this.props.onClickAssign}
                        >
                          <i className="icon-Giao_Viec icon-cpc"></i>
                          <span className="action-name">Giao việc</span>
                        </button>
                      </div>
                    </Authorization.Element>
                  )}
                  {!(
                    this.props.isNewAssignDoc !== undefined &&
                    this.props.isNewAssignDoc === true
                  ) && (
                    <div className="col-xs-4 col-sm-6 col-md-6 item">
                      <button
                        className="click-item"
                        onClick={this.props.onClickSaveDocument}
                      >
                        <i className="icon-Luututailieu icon-cpc"></i>
                        <span className="action-name">Lưu tủ tài liệu</span>
                      </button>
                    </div>
                  )}
                  {!this.props.personalWorks.item.baoNham &&
                    !(
                      this.props.isNewAssignDoc !== undefined &&
                      this.props.isNewAssignDoc === true
                    ) && (
                      <div className="col-xs-4 col-sm-6 col-md-6 item">
                        <button
                          className="click-item"
                          onClick={this.props.onClickAlertMistake}
                        >
                          <i className="icon-attention-warning icon-cpc"></i>
                          <span className="action-name">Báo nhầm</span>
                        </button>
                      </div>
                    )}

                  {this.props.personalWorks.item.baoNham &&
                    !(
                      this.props.isNewAssignDoc !== undefined &&
                      this.props.isNewAssignDoc === true
                    ) && (
                      <div className="col-xs-4 col-sm-6 col-md-6 item">
                        <button
                          className="click-item"
                          onClick={this.props.onClickCancelAlertMistake}
                        >
                          <i className="icon-attention-warning icon-cpc"></i>
                          <span className="action-name">Hủy báo nhầm</span>
                        </button>
                      </div>
                    )}
                  {!(
                    this.props.isNewAssignDoc !== undefined &&
                    this.props.isNewAssignDoc === true
                  ) && (
                    <div className="col-xs-4 col-sm-6 col-md-6 item">
                      <button
                        className="click-item"
                        onClick={this.props.onClickSendMail}
                      >
                        <i className="icon-Gmail icon-cpc"></i>
                        <span className="action-name">Gửi email</span>
                      </button>
                    </div>
                  )}
                  {(this.props.work.congVanNoiBoId ||
                    !(
                      this.props.isNewAssignDoc !== undefined &&
                      this.props.isNewAssignDoc === true
                    )) && (
                    <div className="col-xs-4 col-sm-6 col-md-6 item">
                      <button
                        className="click-item"
                        onClick={
                          this.props.work.congVanNoiBoId
                            ? this.props.onClickFlowdocumentary
                            : this.props.onClickFlowDocument
                        }
                      >
                        <i className="icon-organization icon-cpc"></i>
                        <span className="action-name">Luồng công văn</span>
                      </button>
                    </div>
                  )}

                  {/* TODO: Chuyển sang gọi api khi click action Thông tin chỉ đạo */}
                  {/* { this.props.informationSteering &&
                    <div className="col-xs-4 col-sm-6 col-md-6 item">
                      <button className="click-item" onClick={this.props.onClickInformationAssign}>
                        <i className="icon-Chidao icon-cpc"></i>
                      <span className="action-name">Thông tin chỉ đạo</span>
                      </button>
                    </div>
                  } */}
                  {!(
                    this.props.isNewAssignDoc !== undefined &&
                    this.props.isNewAssignDoc === true
                  ) && (
                    <div className="col-xs-4 col-sm-6 col-md-6 item">
                      <button
                        className="click-item"
                        onClick={this.props.sendToChat}
                      >
                        <i className="icon-speech-bubble icon-cpc"></i>
                        <span className="action-name">Gửi tin nhắn</span>
                      </button>
                    </div>
                  )}
                  {this.props.anLayYKien && (
                    <Authorization.Element
                      permission={[Permission.HDTV_LAYYKIEN]}
                    >
                      <div className="col-xs-4 col-sm-6 col-md-6 item">
                        <button
                          className="click-item"
                          onClick={this.props.onClickLayYKien}
                        >
                          <i className="icon-bubble-cuoc-tro-chuyen icon-cpc"></i>
                          <span className="action-name">Lấy ý kiến</span>
                        </button>
                      </div>
                    </Authorization.Element>
                  )}
                  <div className="col-xs-4 col-sm-6 col-md-6 item">
                    <button
                      className="click-item"
                      onClick={() => this.onMenu('USER_VIEWED')}
                    >
                      <span className="icon-quy-trinh-ca-nhan icon-cpc"></span>
                      <span className="action-name">
                        Danh sách người đã xem
                      </span>
                    </button>
                  </div>
                </div>
              )}
            </div>
          </div>
        )}
        {/* this.props.isLoadingData &&
          Object.values(this.props.isLoadingData).find(item => item === true) === undefined &&
          this.props.assignmentInformation &&
          this.props.assignmentInformation.hasOwnProperty('phieuGiaoViecId') &&
          <AssignmentInformation
            assignmentInformation={this.props.assignmentInformation}
            onHandleSubmit={this.props.onHandleSubmit}
            type={this.props.type}
            auth={this.props.auth}
            isLeaderUnit={this.props.isLeaderUnit}
          />
        }

        { this.props.isLoadingData &&
          Object.values(this.props.isLoadingData).find(item => item === true) === undefined &&
          this.props.informationSteering &&
          this.props.informationSteering.hasOwnProperty('chiDaoId') &&
          <InformationSteering
            closeModulesDetail={this.closeModulesDetail}
            informationSteering={this.props.informationSteering}
          />
        */}
        <PopupConfirm
          isOpen={this.state.isOpenPopupHoanThanhNhanh}
          onClose={this.onClosePopupComplete}
          title={HOAN_THANH_NHANH}
          text={POPUP.TEXT_BUTTON_SUBMIT}
          onSubmit={this.onSubmitComplete}
          isLoading={this.props?.isLoadingAction}
        >
          {'Anh/chị có muốn hoàn thành nhanh công việc?'}
        </PopupConfirm>
      </div>
    )
  }
}

export default DetailBoxActionPersonal
