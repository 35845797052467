import React from 'react'
import PropTypes from 'prop-types'
import Select from 'react-select'

const UISelect = ({ clearable, clearAllText, ...props }) => {
  return <Select {...props} clearable={clearable} clearAllText={clearAllText} />
}

UISelect.defaultProps = {
  clearable: false,
  clearAllText: 'Xóa tất cả',
}

UISelect.propTypes = {
  clearable: PropTypes.bool,
  clearAllText: PropTypes.string,
}

export default UISelect
