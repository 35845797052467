import React from 'react'
import HeaderMenu from './HeaderMenu'
import HeaderUser from './HeaderUser'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import { FOLDER_NAME } from 'constants/Api'

class Header extends React.Component {
  static propTypes = {
    user: PropTypes.object.isRequired,
    menu: PropTypes.array.isRequired,
    isNavigation: PropTypes.bool,
    onHandleClick: PropTypes.func.isRequired,
  }

  constructor(props) {
    super(props)
    this.handleClickMenuHeader = this.handleClickMenuHeader.bind(this)
    this.handleLogout = this.handleLogout.bind(this)
    this.handleOutsideClick = this.handleOutsideClick.bind(this)
    this.state = {
      isShow: false,
      windowWidth: window.innerWidth,
    }
  }

  handleOutsideClick(e) {
    if (!this.state.isShow || (this.node && this.node.contains(e.target))) {
      return
    }

    this.handleClickMenuHeader()
  }

  handleClickMenuHeader(e) {
    if (!this.state.isShow) {
      document.addEventListener('click', this.handleOutsideClick, false)
    } else {
      document.removeEventListener('click', this.handleOutsideClick, false)
    }

    this.setState({
      isShow: !this.state.isShow,
    })
  }

  componentWillUnmount() {
    this.setState({
      isShow: false,
    })
  }

  handleLogout(e) {
    e.preventDefault()
    this.props.handleLogout && this.props.handleLogout()
  }

  resize = () => {
    this.setState({ windowWidth: window.innerWidth })
  }

  componentDidMount() {
    window.addEventListener('resize', this.resize)
  }

  componentWillMount() {
    window.removeEventListener('resize', this.resize)
  }

  render() {
    const { isNavigation } = this.props
    const windowWidth = this.state.windowWidth
    return (
      <header className="header">
        <div className="header-container" ref={el => (this.header = el)}>
          <div
            className={classnames('logo-mobilemenu-container', {
              'hideMenusider-homepage': this.props.hiddenMenuHome,
            })}
          >
            <div
              className="menu-sidebar-mobile"
              onClick={this.props.onHandleClick}
            >
              <span className="ml5 icon2-arrow-right font-size-20 opacity-05"></span>
            </div>
            <div className="logo pull-left">
              <a
                href="/"
                className="logo-link"
                style={{
                  backgroundImage: `url('/config/${FOLDER_NAME}/images/logo.svg?cache=1')`,
                }}
              >
                <img
                  src={`/config/${FOLDER_NAME}/images/logo.svg?cache=1`}
                  alt="logo"
                />
              </a>
            </div>
            <div
              className="menu-header-mobile"
              onClick={this.handleClickMenuHeader}
            >
              <span className="pt-icon pt-icon-menu"></span>
            </div>
          </div>
          <div
            className={classnames('nav-container control-header', {
              'control-header-open': this.state.isShow,
            })}
            ref={node => (this.node = node)}
          >
            <div className="block-menu pull-left">
              <div className="icon-close-menu">
                <span onClick={this.handleClickMenuHeader}>
                  <span className="pt-icon pt-icon-cross"></span>
                </span>
              </div>
              <div className="user-mobile">
                {windowWidth <= 1200 && (
                  <HeaderUser
                    user={this.props.user}
                    handleLogout={this.handleLogout}
                    roleId={this.props.roleId}
                    actions={this.props.actions}
                  />
                )}
              </div>
              {(!isNavigation || (isNavigation && windowWidth <= 1200)) && (
                <HeaderMenu
                  menu={this.props.menu}
                  quyensMenu={this.props.quyensMenu}
                  hideWhenclickMenu={this.handleClickMenuHeader}
                  handleLogout={this.handleLogout}
                  actions={this.props.actions}
                />
              )}
            </div>
          </div>
          <div className="header-right-container hidden-sm">
            {windowWidth > 1200 && (
              <HeaderUser
                user={this.props.user}
                handleLogout={this.handleLogout}
                roleId={this.props.roleId}
                actions={this.props.actions}
              />
            )}
          </div>
          {/* <Notifications history={this.props.history} /> */}
        </div>
      </header>
    )
  }
}

export default Header
