import React from 'react'
import PropTypes from 'prop-types'

const NoteFooter = ({ isCVPBCG, isCVPBDG, isHienThiLoaiCongVan }) => {
  const loaiCongVan = [
    ...(!isCVPBDG && !isCVPBCG
      ? [
          {
            icon: 'symbol-square blue',
            name: 'Công việc phòng ban chủ trì',
          },
        ]
      : []),
    {
      icon: 'symbol-square red',
      name: 'Công việc quá hạn',
    },
    {
      icon: 'symbol-square yellow',
      name: 'Công việc gần quá hạn',
    },
    {
      icon: 'icon-Save-DocumentCabinet',
      name: 'Đã lưu TTL',
    },
    {
      icon: 'icon-attention-warning',
      name: 'Đã báo nhầm',
    },
    ...(isHienThiLoaiCongVan
      ? [
          {
            icon: 'icon2-file-down color-blue',
            name: 'Công văn đến',
          },
          {
            icon: 'icon2-file-up color-green',
            name: 'Công văn đi',
          },
          {
            icon: 'icon2-file-brick color-blue-light',
            name: 'Công việc tự tạo',
          },
        ]
      : []),
  ]

  return (
    <div className={'note-footer'}>
      <p className="sumary">Chú Thích</p>
      <ul className="list">
        {loaiCongVan.map((elm, index) => (
          <li key={index}>
            <span className={elm.icon} />
            <span className="text-qh">{elm.name}</span>
          </li>
        ))}
      </ul>
    </div>
  )
}

NoteFooter.defaultProps = {
  isCVPBCG: true,
  isCVPBDG: true,
  isHienThiLoaiCongVan: true,
}

NoteFooter.propTypes = {
  isCVPBCG: PropTypes.bool,
  isCVPBDG: PropTypes.bool,
  isHienThiLoaiCongVan: PropTypes.bool,
}

export default NoteFooter
