import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import moment from 'moment'
import { Scrollbars } from 'react-custom-scrollbars'

import { getDSNhanVienDaXemCongVan } from 'actions'
import { IMAGE_URL } from 'constants/Api'
import UIUser from 'components/ui/user'
import UIEmpty from 'components/ui/empty'
import { LoadingItem } from 'components/common'
import UseUserViewed from './use-user-viewed'

const UserViewed = ({ actions, id, onGoBack }) => {
  const { isError, isFetch, listData, onScrollFrame } = UseUserViewed({
    actions,
    id,
  })

  const hasTotal = useMemo(() => {
    return listData?.total > 0 ?? false
  }, [listData])

  return (
    <div className="container-form-action">
      <div className="heading">
        <span className="click-go-back" onClick={onGoBack}>
          <span className="pt-icon pt-icon-arrow-left pull-left"></span>
        </span>
        <h4 className="name-heading">Danh sách người đã xem</h4>
      </div>
      <div className="p20">
        {!isFetch && (!hasTotal || isError) && (
          <UIEmpty text={isError ? 'Lỗi hệ thống' : 'Không có dữ liệu'} />
        )}
        {!isError && hasTotal && (
          <Scrollbars
            style={{ width: '100%', height: '100%' }}
            onScrollFrame={onScrollFrame}
            autoHeight
            autoHeightMin={0}
            autoHeightMax={400}
            autoHide
          >
            <ul className="d-flex flex-direction-column align-items-flex-start row-gap-15">
              {listData.items.map((elm, index) => (
                <li key={index}>
                  <UIUser
                    images={
                      elm?.anhDaiDien ? `${IMAGE_URL}${elm?.anhDaiDien}` : ''
                    }
                    name={elm?.tenNhanVien}
                    date={
                      moment(elm?.ngayDoc).isValid()
                        ? moment(elm?.ngayDoc).format('DD/MM/YYYY HH:mm')
                        : ''
                    }
                  />
                </li>
              ))}
            </ul>
          </Scrollbars>
        )}
        {isFetch && <LoadingItem />}
      </div>
    </div>
  )
}

UserViewed.defaultProps = {
  id: '', // Công văn id
  onGoBack: () => {},
}

UserViewed.propTypes = {
  id: PropTypes.string,
  onGoBack: PropTypes.func,
}

const mapStateToProps = state => ({
  auth: state.auth,
})

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators({ getDSNhanVienDaXemCongVan }, dispatch),
})

export default connect(mapStateToProps, mapDispatchToProps)(UserViewed)
