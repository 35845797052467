/* eslint-disable max-len */
import Calendar from 'components/Calendar/Calendar'
import React, { useEffect, useState, useMemo } from 'react'
import { bindActionCreators } from 'redux'
import * as Actions from 'actions'
import { connect } from 'react-redux'
import moment from 'moment'
import PopupModal from 'components/common/PopupModal'
import { Link } from 'react-router-dom'
import { Button, Intent } from '@blueprintjs/core'
import * as MESSAGER from 'constants/MessageForm'
import classNames from 'classnames'

const LichLamViec = props => {
  const filterKeys = {
    from: 'fromDate',
    to: 'toDate',
  }
  const LINK_DETAIL = '/lich-hop/quan-ly-lich-hop/chi-tiet'
  const LINK_DETAIL_PERSONAL = '/lich-hop/chi-tiet-nguoi-tham-gia'
  const LINK_DETAIL_HAU_CAN = '/lich-hop/hau-can/chi-tiet'
  const LINK_UPDATE_HAU_CAN = '/lich-hop/hau-can/cap-nhat'
  const calendarRef = React.createRef()
  const [filter, setFilter] = useState(null)
  const [isLoadingSubmit, setIsLoadingSubmit] = useState(false)
  const [isLoadingSubmitKhongThamGia, setIsLoadingSubmitKhongThamGia] =
    useState(false)
  const [dataSource, setDataSource] = useState()
  const [itemSelected, setItemSelected] = useState(null)

  const hanldeGetDsLichHopCaNhan = () => {
    const filterNew = {
      fromDate: filter?.fromDate
        ? moment(filter?.fromDate).format('YYYY-MM-DD')
        : null,
      toDate: filter?.toDate
        ? moment(filter?.toDate).format('YYYY-MM-DD')
        : null,
    }
    props.actions.getDanhSachLichHopCaNhan(filterNew).then(res => {
      if (res.error || (res.payload && res.payload.status !== 200)) {
        return
      } else {
        setDataSource(res?.payload?.data?.result?.items)
      }
    })
  }

  const handleSubmitXacNhanThamGia = thamGia => {
    const setLoading =
      thamGia === 1 ? setIsLoadingSubmit : setIsLoadingSubmitKhongThamGia
    setLoading(true)
    props.actions
      .putXacNhanThamGia(itemSelected?.cuocHopId, thamGia)
      .then(res => {
        if (res.payload && res.payload.data.result === true) {
          setItemSelected(null)
          setLoading(false)
          props.actions.getMeetingCounter()
          return props.actions.commonAddToasterMessage({
            message:
              thamGia === 1
                ? MESSAGER.TOATS_CONFIRM_JOIN_MEETING
                : MESSAGER.TOATS_CONFIRM_CANCLE_MEETING,
            intent: Intent.SUCCESS,
          })
        } else {
          setLoading(false)
          props.actions.commonAddToasterMessage({
            message:
              res?.error?.response?.data?.message ||
              MESSAGER.TOATS_ERROR_SYSTEM,
            intent: Intent.DANGER,
          })
        }
      })
  }

  const onEventItemClick = item => {
    if (props.type === 'QUAN_LY') {
      props.history.push(`${LINK_DETAIL}/${item?.cuocHopId}`)
    } else {
      setItemSelected(item)
    }
  }

  useEffect(() => {
    hanldeGetDsLichHopCaNhan()
    // eslint-disable-next-line
  }, [filter])

  const isEnd = moment(itemSelected?.batDau).isBefore(moment())

  const dsNguoiChuTri = useMemo(() => {
    return itemSelected?.cuocHopThanhPhan
      ?.filter(elm => elm?.nhanVien && elm?.chuTri)
      ?.map(item => item?.nhanVien?.tenNhanVien)
  }, [itemSelected])

  return (
    <div>
      <Calendar
        calendarRef={calendarRef}
        viewType="dayGridMonth"
        filterKeys={filterKeys}
        dataSource={dataSource}
        onEventItemClick={onEventItemClick}
        setFilter={setFilter}
      />
      <PopupModal
        isOpen={itemSelected !== null}
        title={itemSelected?.tieuDe}
        canOutsideClickClose={true}
        onClose={() => setItemSelected(null)}
        contentClass="popup-meeting"
      >
        <div className="popup-meeting-wrap">
          <div className="popup-meeting-body">
            <div className="row">
              <div className="col-xs-6 col-sm-6 col-md-6">
                <div className="item-view">
                  <span className="item-view__label">Loại cuộc họp</span>
                  <span className="item-view__value">
                    {itemSelected?.theLoai?.ten}
                  </span>
                </div>
              </div>
              <div className="col-xs-6 col-sm-6 col-md-6">
                <div className="item-view">
                  <span className="item-view__label">Cấp độ</span>
                  <span className="item-view__value">
                    {itemSelected?.mucDo?.ten}
                  </span>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-xs-6 col-sm-6 col-md-6">
                <div className="item-view">
                  <span className="item-view__label">Thời gian diễn ra</span>
                  <span className="item-view__value">
                    {itemSelected?.ketThuc
                      ? moment(itemSelected?.batDau).format(
                          'DD/MM/YYYY HH:mm'
                        ) +
                        ' - ' +
                        moment(itemSelected?.ketThuc).format('DD/MM/YYYY HH:mm')
                      : moment(itemSelected?.batDau).format('DD/MM/YYYY HH:mm')}
                  </span>
                </div>
              </div>
              {(dsNguoiChuTri?.length > 0 ?? false) && (
                <div className="col-xs-6 col-sm-6 col-md-6">
                  <div className="item-view">
                    <span className="item-view__label">Chủ trì</span>
                    <span className="item-view__value">
                      {dsNguoiChuTri.join(', ')}
                    </span>
                  </div>
                </div>
              )}
            </div>
            <div className="row">
              <div className="col-xs-12 col-sm-12 col-md-12">
                <div className="item-view">
                  <span className="item-view__label">Địa điểm</span>
                  <span className="item-view__value">
                    {itemSelected?.diaDiem?.ten}
                  </span>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-xs-6 col-sm-6 col-md-6">
                <div className="item-view">
                  <span className="item-view__label">Tổng mời họp</span>
                  <span className="item-view__value">
                    {itemSelected?.tongMoiHop}
                  </span>
                </div>
              </div>
              <div className="col-xs-6 col-sm-6 col-md-6">
                <div className="item-view">
                  <span className="item-view__label">Tổng tham gia</span>
                  <span className="item-view__value">
                    {itemSelected?.tongThamGia}
                  </span>
                </div>
              </div>
            </div>
          </div>
          {props.type === 'CA_NHAN' && (
            <div
              className={classNames('popup-meeting-footer', { center: isEnd })}
            >
              <Link
                className="link-view-detail"
                to={`${LINK_DETAIL_PERSONAL}/${itemSelected?.cuocHopId}`}
              >
                <span>Xem chi tiết</span>
              </Link>
              {!isEnd && (
                <div className="wrap-button">
                  <Button
                    onClick={() => handleSubmitXacNhanThamGia(2)}
                    className="pt-button btn-white-color"
                    loading={isLoadingSubmitKhongThamGia}
                    disabled={
                      itemSelected?.cuocHopThanhPhan?.[0]?.thamGia === 2 ||
                      isLoadingSubmit
                    }
                  >
                    <span className="text-content">Không tham gia</span>
                  </Button>
                  <Button
                    className="pt-button btn-orange-color"
                    onClick={() => handleSubmitXacNhanThamGia(1)}
                    loading={isLoadingSubmit}
                    disabled={
                      itemSelected?.cuocHopThanhPhan?.[0]?.thamGia === 1 ||
                      isLoadingSubmitKhongThamGia
                    }
                  >
                    <span className="text-content">Tham gia</span>
                  </Button>
                </div>
              )}
            </div>
          )}
          {props.type === 'HAU_CAN' && (
            <div
              className={classNames('popup-meeting-footer', { center: isEnd })}
            >
              <Link
                className="link-view-detail"
                to={`${LINK_DETAIL_HAU_CAN}/${itemSelected?.cuocHopId}`}
              >
                <span>Xem chi tiết</span>
              </Link>
              {!isEnd && (
                <div className="wrap-button">
                  <Link
                    className="pt-button btn-orange-color"
                    to={`${LINK_UPDATE_HAU_CAN}/${itemSelected?.cuocHopId}`}
                  >
                    <span>Cập nhật hậu cần</span>
                  </Link>
                </div>
              )}
            </div>
          )}
        </div>
      </PopupModal>
    </div>
  )
}

const mapStateToProps = state => ({
  auth: {
    ...state.auth,
  },
})

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(Actions, dispatch),
})

export default connect(mapStateToProps, mapDispatchToProps)(LichLamViec)
