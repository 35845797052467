import React, { Component } from 'react'
import PropTypes from 'prop-types'
import {
  CheckboxGroup,
  PopupConfirm,
  DepartmentPerform,
  NotFound,
} from '../common/'
import { Checkbox, Button, Intent } from '@blueprintjs/core'
import * as POPUP from '../../constants/Popup'
import * as MESSAGE from '../../constants/MessageForm'
import _ from 'lodash'

class SendAdditional extends Component {
  static propTypes = {
    onClickGoBack: PropTypes.func.isRequired,
    receivingUnit: PropTypes.array.isRequired,
    attachedUnits: PropTypes.array.isRequired,
    otherUnits: PropTypes.array,
    connectedUnit: PropTypes.array,
    connectedUnitEVN: PropTypes.array,
    departments: PropTypes.array.isRequired,
    onSubmit: PropTypes.func.isRequired,
    actions: PropTypes.object.isRequired,
    sendDocument: PropTypes.object,
    sendDocumentId: PropTypes.string,
  }

  constructor(props) {
    super(props)
    this.handleClickPopup = this.handleClickPopup.bind(this)
    this.handleGetValueDepartment = this.handleGetValueDepartment.bind(this)
    this.handleCheck = this.handleCheck.bind(this)
    this.handleCheckAll = this.handleCheckAll.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
    this.handleClickArrow = this.handleClickArrow.bind(this)
    this.handleChangeOtherUnits = this.handleChangeOtherUnits.bind(this)
    this.handleChangeConnectedUnit = this.handleChangeConnectedUnit.bind(this)
    this.handleChangeConnectedUnitEVN =
      this.handleChangeConnectedUnitEVN.bind(this)
    this.handleChange = this.handleChange.bind(this)
    this.guiLaiEVN = this.guiLaiEVN.bind(this)
  }

  state = {
    dsPhoiHop: [],
    dsXemDeBiet: [],
    dsDonViNhan: [],
    dsDonViKhac: [],
    dsDonViLienThong: [],
    dsDonViLienThongLoc: this.props.connectedUnit || [],
    dsDonViLienThongEVN: [],
    dsDonViLienThongLocEVN: this.props.connectedUnitEVN || [],
    guiEvn: false,
    isOpenPopup: false,
    congVanDiId: '',
    departmentSelections: [],
    receivingUnitSelection: [],
    isCheckAllReceivingUnit: false,
    isCheckedAllAttachedUnits: false,
    isLoading: false,
    disabledListCompositive: [],
    disabledListOnlySee: [],
    disabledReceivingUnit: [], // Danh sách đơn vị nhận disbled
    disabledAttachedUnits: [], // Danh sách đơn vị trực thuộc disbled
    disabledOtherUnits: [], // Danh sách đơn vị khác disbled
    disabledConnectedUnit: [], // Danh sách đơn vị liên thông disbled
    disabledConnectedUnitEVN: [], // Danh sách đơn vị liên thông EVN disbled
    selectedCompositiveDepartments: [],
    selectedOnlySeeDepartments: [],
    error: '',
    isOpen: false,
    receivingUnitSelectionIds: [],
    isOpenConnectedUnit: false,
    isOpenConnectedUnitEVN: true,
    timDonViLienThong: '',
    timDonViLienThongEVN: '',
  }

  handleClickPopup = () => {
    this.setState({
      isOpenPopup: !this.state.isOpenPopup,
    })
    this.props.onClickGoBack && this.props.onClickGoBack()
  }

  handleClickArrow = () => {
    this.setState({ isOpen: !this.state.isOpen })
  }

  handleCheckAll = (
    nameCheckBoxAll,
    nameSelectedArray,
    nameOptionsArray,
    disabledArray = []
  ) => {
    let list = this.state[nameSelectedArray]
    let disabledList = []
    if (_.isArray(disabledArray)) {
      disabledList = _.uniq(disabledArray)
    }
    if (!this.state[nameCheckBoxAll]) {
      this.state[nameOptionsArray].forEach(item => {
        if (
          list.indexOf(item.value) === -1 &&
          disabledList.indexOf(item.value) === -1
        ) {
          list.push(item.value)
        }
      })
    } else {
      list = []
    }
    this.setState({
      [nameSelectedArray]: list,
      [nameCheckBoxAll]: !this.state[nameCheckBoxAll],
    })
  }

  handleCheck = (
    nameCheckBoxAll,
    nameSelectedArray,
    nameSelectionsArray,
    disabledArray = [],
    e
  ) => {
    const newSelection = e.target.value
    let newList = []
    let oldList = this.state[nameSelectedArray]
    let isCheckAll = false
    let selectionArray = this.state[nameSelectionsArray]

    // Aray options chỉ chứa id
    let selectionsList = []
    selectionArray &&
      selectionArray.forEach(item => {
        selectionsList.push(item.value)
      })

    // Array disabled chỉ nằm trong danh sách options
    let disabledList = []
    if (_.isArray(disabledArray)) {
      disabledList = _.uniq(disabledArray)
      disabledList = disabledList.filter(s => selectionsList.indexOf(s) > -1)
    }

    if (oldList && oldList.length > 0 && oldList.indexOf(newSelection) > -1) {
      newList = oldList.filter(s => s !== newSelection)
    } else {
      newList = [...oldList, newSelection]
    }
    if (
      selectionsList &&
      newList.length + disabledList.length === selectionsList.length
    ) {
      isCheckAll = true
    }
    this.setState({
      [nameSelectedArray]: newList,
      [nameCheckBoxAll]: isCheckAll,
    })
  }

  handleGetValueDepartment = data => {
    if (data && Object.keys(data).length > 0) {
      this.setState({
        dsPhoiHop: data.dsPhoiHop,
        dsXemDeBiet: data.dsXemDeBiet,
      })
    }
  }

  handleSubmit = () => {
    let error = ''
    let guiEvnHaveChange = this.state.guiEvn !== this.props.sendDocument.guiEvn
    if (
      !this.state.dsPhoiHop.length &&
      !this.state.dsDonViNhan.length &&
      !this.state.dsXemDeBiet.length &&
      !this.state.dsDonViKhac.length &&
      !this.state.dsDonViLienThong.length &&
      !this.state.dsDonViLienThongEVN.length &&
      !guiEvnHaveChange
    ) {
      error = MESSAGE.TEXT_REQUIRED_SEND_ADDITIONAL
    }
    this.setState({ error, isOpenPopup: false })
    if (error.trim().length > 0) {
      return (
        this.props.onSubmit &&
        this.props.onSubmit({
          title: 'GuiBoSung',
          isSuccess: false,
          message: error,
          intent: Intent.WARNING,
        })
      )
    }

    this.setState({ isLoading: true })
    const dataRequest = {
      ds_phoi_hop: this.state.dsPhoiHop,
      ds_xem_de_biet: this.state.dsXemDeBiet,
      ds_don_vi_nhan: this.state.dsDonViNhan,
      ds_don_vi_khac: this.state.dsDonViKhac.filter(
        item => this.state.disabledOtherUnits.indexOf(item) === -1
      ),
      gui_evn: this.state.guiEvn,
      ds_don_vi_lien_thong: this.state.dsDonViLienThong
        .filter(item => this.state.disabledConnectedUnit.indexOf(item) === -1)
        .map(item => {
          let itemArray = item.split(',')
          return {
            don_vi_id: itemArray[0],
            ma_don_vi: itemArray[1],
            ten_don_vi: itemArray[2],
          }
        }),
      ds_don_vi_lien_thong_evn: this.state.dsDonViLienThongEVN
        .filter(
          item => this.state.disabledConnectedUnitEVN.indexOf(item) === -1
        )
        .map(item => {
          let itemArray = item.split(',')
          return {
            don_vi_id: itemArray[0],
            ma_don_vi: itemArray[1],
            ten_don_vi: itemArray[2],
          }
        }),
    }

    this.props.actions
      .sendDocumentSendAdditional(this.state.congVanDiId, dataRequest)
      .then(res => {
        if (res.payload && res.payload.status === 200) {
          this.props.onSubmit &&
            this.props.onSubmit({
              title: 'GuiBoSung',
              isSuccess: true,
              message: MESSAGE.TOATS_MESSAGE_SUCCESS,
              intent: Intent.SUCCESS,
            })
        } else if (res.payload && res.payload.status === 202) {
          this.setState({ isLoading: false, isOpenPopup: true })
          this.props.onSubmit &&
            this.props.onSubmit({
              title: 'GuiBoSung',
              isSuccess: true,
              message: MESSAGE.TOATS_MESSAGE_SUCCESS,
              intent: Intent.SUCCESS,
              sendEVN: false,
            })
        } else {
          this.setState({ isLoading: false })
          this.props.onSubmit &&
            this.props.onSubmit({
              title: 'GuiBoSung',
              isSuccess: false,
              message: MESSAGE.TOATS_MESSAGE_FAIL,
              intent: Intent.DANGER,
            })
        }
      })
  }

  guiLaiEVN = () => {
    this.setState({ isLoading: true, isOpenPopup: false }, () => {
      this.props.actions
        .resendEVNSendDocument(this.state.congVanDiId)
        .then(res => {
          if (!res || res.error || !res.payload || res.payload.status !== 200) {
            this.props.onSubmit &&
              this.props.onSubmit({
                title: 'GuiBoSung',
                isSuccess: false,
                message: MESSAGE.TOATS_MESSAGE_FAIL,
                intent: Intent.DANGER,
              })
            return this.setState({ isOpenPopup: true })
          }
          this.props.onSubmit &&
            this.props.onSubmit({
              title: 'GuiLai',
              isSuccess: true,
              message: MESSAGE.TEXT_GUILAI_THANHCONG,
              intent: Intent.SUCCESS,
            })
        })
        .catch(e => {
          return this.setState({ isOpenPopup: true })
        })
        .then(() => {
          this.setState({ isLoading: false })
        })
    })
  }

  handleChangeOtherUnits = data => {
    if (data) {
      this.setState({ dsDonViKhac: data })
    }
  }

  handleChangeConnectedUnit = data => {
    if (data) {
      this.setState({ dsDonViLienThong: data })
    }
  }

  handleChangeConnectedUnitEVN = data => {
    if (data) {
      this.setState({ dsDonViLienThongEVN: data })
    }
  }

  handleChange = () => {
    this.setState({
      guiEvn: !this.state.guiEvn,
    })
  }

  componentDidMount() {
    if (this.props.sendDocumentId) {
      this.setState({ congVanDiId: this.props.sendDocumentId })
    }

    if (this.props.departments && this.props.departments.length) {
      const departmentSelections = []
      this.props.departments.forEach((item, i) => {
        departmentSelections.push({
          content: item.maPhongBan,
          value: item.phongBanId,
        })
      })
      this.setState({ departmentSelections })
    }

    // Đơn vị nhận
    const receivingUnitSelectionIds = []
    if (this.props.receivingUnit && this.props.receivingUnit.length) {
      const receivingUnitSelection = []
      this.props.receivingUnit.forEach((item, i) => {
        receivingUnitSelection.push({
          content: item.tenVietTat,
          value: item.donViId,
        })
        receivingUnitSelectionIds.push(item.donViId)
      })
      this.setState({ receivingUnitSelection, receivingUnitSelectionIds })
    }

    if (this.props.sendDocument) {
      const dsPhongBanXemDeBiet = [] // Danh sach phog ban disbled cot xem de biet
      const dsPhongBanPhoiHop = [] // Danh sach phog ban disbled cot phoi hop
      const disabledReceivingUnit = [] // Danh sach don vi nhan disabled
      const disabledAttachedUnits = [] // Danh sach don vi truc thuoc disabled
      const disabledOtherUnits = [] // Danh sach don vi khac disabled
      const disabledConnectedUnit = [] // Danh sach don vi liên thông disabled
      const disabledConnectedUnitEVN = [] // Danh sach don vi liên thông EVN disabled
      const selectedCompositiveDepartments = []
      const selectedOnlySeeDepartments = []
      let isOpen = false,
        isOpenConnectedUnit = false,
        isOpenConnectedUnitEVN = true

      this.props.sendDocument.dsPhoiHop &&
        this.props.sendDocument.dsPhoiHop.forEach(item => {
          dsPhongBanXemDeBiet.push(item.phongBanId)
          dsPhongBanPhoiHop.push(item.phongBanId)
          selectedCompositiveDepartments.push(item.phongBanId)
        })

      this.props.sendDocument.dsXemDeBiet &&
        this.props.sendDocument.dsXemDeBiet.forEach(item => {
          dsPhongBanXemDeBiet.push(item.phongBanId)
          selectedOnlySeeDepartments.push(item.phongBanId)
        })

      if (this.props.sendDocument.phongBanLap) {
        dsPhongBanXemDeBiet.push(this.props.sendDocument.phongBanLap.phongBanId)
        dsPhongBanPhoiHop.push(this.props.sendDocument.phongBanLap.phongBanId)
      }

      this.props.sendDocument.dsDonViNhan &&
        this.props.sendDocument.dsDonViNhan.forEach(item => {
          if (_.indexOf(receivingUnitSelectionIds, item.donViId) > -1) {
            disabledReceivingUnit.push(item.donViId)
          } else {
            disabledAttachedUnits.push(item.donViId)
          }
        })

      if (this.props.sendDocument.dsDonViKhac) {
        this.props.sendDocument.dsDonViKhac.forEach(item => {
          disabledOtherUnits.push(item.donViId)
        })
        if (this.props.sendDocument.dsDonViKhac.length) {
          isOpen = true
        }
      }

      if (this.props.sendDocument.dsDonViLienThong) {
        this.props.sendDocument.dsDonViLienThong.forEach(item => {
          disabledConnectedUnit.push(
            `${item.donViId},${item.maDonVi},${item.tenDonVi}`
          )
        })
        if (this.props.sendDocument.dsDonViLienThong.length) {
          isOpenConnectedUnit = true
        }
      }

      if (this.props.sendDocument.dsDonViLienThongEvn) {
        this.props.sendDocument.dsDonViLienThongEvn.forEach(item => {
          disabledConnectedUnitEVN.push(
            `${item.donViId},${item.maDonVi},${item.tenDonVi}`
          )
        })
        if (this.props.sendDocument.dsDonViLienThongEvn.length) {
          isOpenConnectedUnitEVN = true
        }
      }

      this.setState(
        {
          disabledReceivingUnit,
          disabledAttachedUnits,
          disabledOtherUnits,
          disabledConnectedUnit,
          disabledConnectedUnitEVN,
          disabledListOnlySee: dsPhongBanXemDeBiet,
          disabledListCompositive: dsPhongBanPhoiHop,
          selectedCompositiveDepartments,
          selectedOnlySeeDepartments,
          guiEvn: this.props.sendDocument.guiEvn,
        },
        () => {
          this.setState({ isOpen, isOpenConnectedUnit, isOpenConnectedUnitEVN })
        }
      )
    }
  }

  componentWillReceiveProps(nextProps) {
    if (
      nextProps.sendDocumentId !== this.props.sendDocumentId &&
      nextProps.sendDocumentId
    ) {
      this.setState({ congVanDiId: nextProps.sendDocumentId })
    }

    if (
      nextProps.departments !== this.props.departments &&
      nextProps.departments.length
    ) {
      const departmentSelections = []
      nextProps.departments.forEach((item, i) => {
        departmentSelections.push({
          content: item.maPhongBan,
          value: item.phongBanId,
        })
      })
      this.setState({ departmentSelections })
    }

    // Đơn vị nhận
    if (
      nextProps.receivingUnit !== this.props.receivingUnit &&
      nextProps.receivingUnit.length
    ) {
      const receivingUnitSelection = []
      const receivingUnitSelectionIds = []
      nextProps.receivingUnit.forEach((item, i) => {
        receivingUnitSelection.push({
          content: item.tenVietTat,
          value: item.donViId,
        })
        receivingUnitSelectionIds.push(item.donViId)
      })
      this.setState({ receivingUnitSelection, receivingUnitSelectionIds })
    }
  }

  handleClickArrowConnectedUnit = () => {
    this.setState({ isOpenConnectedUnit: !this.state.isOpenConnectedUnit })
  }

  handleClickArrowConnectedUnitEVN = () => {
    this.setState({
      isOpenConnectedUnitEVN: !this.state.isOpenConnectedUnitEVN,
    })
  }

  handleSearchTree = (searchTxt, arr) => {
    let newArray = arr.reduce((curArr, curVal) => {
      if (
        curVal.tenDonVi.toLowerCase().indexOf(searchTxt.toLowerCase()) !== -1
      ) {
        return [...curArr, curVal]
      } else {
        if (curVal.dsDonViCon.length) {
          let childArray = this.handleSearchTree(searchTxt, curVal.dsDonViCon)
          if (childArray.length) {
            return [...curArr, { ...curVal, dsDonViCon: childArray }]
          } else {
            return [...curArr]
          }
        } else {
          return [...curArr]
        }
      }
    }, [])
    return [...newArray]
  }

  handleKeyUpConnectedUnit = e => {
    const name = e.target.name

    this.setState({
      [name]: e.target.value,
    })

    this.deboundKeyUpConnectedUnit(e.target)
  }

  removeTextSearch(name) {
    this.setState({
      [name]: '',
    })
    if (name === 'timDonViLienThong') {
      this.setState({ dsDonViLienThongLoc: this.props.connectedUnit })
    }
    if (name === 'timDonViLienThongEVN') {
      this.setState({ dsDonViLienThongLocEVN: this.props.connectedUnitEVN })
    }
  }

  deboundKeyUpConnectedUnit = _.debounce(input => {
    const value = input.value
    const name = input.name

    if (value.trim().length >= 3) {
      if (name === 'timDonViLienThong') {
        this.setState({
          dsDonViLienThongLoc: this.handleSearchTree(
            value,
            this.props.connectedUnit
          ),
        })
      }
      if (name === 'timDonViLienThongEVN') {
        this.setState({
          dsDonViLienThongLocEVN: this.handleSearchTree(
            value,
            this.props.connectedUnitEVN
          ),
        })
      }
    } else {
      if (name === 'timDonViLienThong') {
        this.setState({ dsDonViLienThongLoc: this.props.connectedUnit })
      }
      if (name === 'timDonViLienThongEVN') {
        this.setState({ dsDonViLienThongLocEVN: this.props.connectedUnitEVN })
      }
    }
  }, 500)

  render() {
    return (
      <div className="container-form-action">
        <div className="heading">
          <span className="click-go-back" onClick={this.props.onClickGoBack}>
            <span className="pt-icon pt-icon-arrow-left pull-left"></span>
          </span>
          <h4 className="name-heading">Gửi bổ sung</h4>
        </div>

        <div className="form-container container-detail">
          <div className="pt-form-group">
            <label className="pt-label pt0">Phòng ban thực hiện</label>
            <DepartmentPerform
              options={this.state.departmentSelections}
              onSubmit={this.handleGetValueDepartment}
              dsPhoiHop={this.state.dsPhoiHop}
              dsXemDeBiet={this.state.dsXemDeBiet}
              disabledListCompositive={this.state.disabledListCompositive}
              disabledListOnlySee={this.state.disabledListOnlySee}
              selectedCompositiveDepartments={
                this.state.selectedCompositiveDepartments
              }
              selectedOnlySeeDepartments={this.state.selectedOnlySeeDepartments}
              titles={['', 'PH', 'XĐB']}
              isHasRadio={false}
              type="Phòng ban"
            />
          </div>

          {/* Đơn vị nhận */}
          <div className="selectbox-group">
            <div className="heading-group-select disabled-checkbox">
              <span
                className="pull-left name_group name_group_unit"
                onClick={this.handleClickArrow}
              >
                Đơn vị nhận
              </span>
              <Checkbox
                className="pull-right"
                name="allUnit"
                checked={this.state.isCheckAllReceivingUnit}
                onChange={this.handleCheckAll.bind(
                  this,
                  'isCheckAllReceivingUnit',
                  'dsDonViNhan',
                  'receivingUnitSelection',
                  _.concat(
                    this.state.dsDonViKhac,
                    this.state.disabledOtherUnits,
                    this.state.disabledConnectedUnit,
                    this.state.disabledConnectedUnitEVN,
                    this.state.disabledAttachedUnits,
                    this.props.mainUnitId,
                    this.state.disabledReceivingUnit
                  )
                )}
                disabled={
                  !this.state.receivingUnitSelection ||
                  !this.state.receivingUnitSelection.length ||
                  (this.state.disabledReceivingUnit.length ===
                    this.state.receivingUnitSelection.length - 1 &&
                    this.state.receivingUnitSelection.findIndex(
                      dv => dv.value === this.props.mainUnitId
                    ) !== -1) ||
                  this.state.disabledReceivingUnit.length ===
                    this.state.receivingUnitSelection.length
                }
              >
                <span className="name_checkbox">Tất cả</span>
              </Checkbox>
            </div>
            <div className="container-checkbox">
              {this.state.receivingUnitSelection &&
                this.state.receivingUnitSelection.length > 0 && (
                  <CheckboxGroup
                    name="donViNhan"
                    options={this.state.receivingUnitSelection}
                    selectedOptions={this.state.dsDonViNhan}
                    onChange={this.handleCheck.bind(
                      this,
                      'isCheckAllReceivingUnit',
                      'dsDonViNhan',
                      'receivingUnitSelection',
                      _.concat(
                        this.state.dsDonViKhac,
                        this.state.disabledOtherUnits,
                        this.state.disabledConnectedUnit,
                        this.state.disabledConnectedUnitEVN,
                        this.state.disabledAttachedUnits,
                        this.props.mainUnitId,
                        this.state.disabledReceivingUnit
                      )
                    )}
                    disabledList={this.state.disabledReceivingUnit}
                    disabledListNotChecked={_.concat(
                      this.state.dsDonViKhac,
                      this.state.disabledOtherUnits,
                      this.state.disabledConnectedUnit,
                      this.state.disabledConnectedUnitEVN,
                      this.state.disabledAttachedUnits,
                      this.props.mainUnitId
                    )}
                    colum="col-xs-6 col-sm-6 col-md-6"
                  />
                )}
              {(!this.state.receivingUnitSelection ||
                this.state.receivingUnitSelection.length === 0) && <NotFound />}
            </div>
          </div>
        </div>

        {/* Footer */}
        <div className="buttton-action-footer">
          <Button
            onClick={this.props.onClickGoBack}
            className="pt-button btn-cancel"
            disabled={this.state.isLoading === true ? true : false}
          >
            <span className=" pt-icon icon-back"></span>
            <span className="text-content">Quay lại</span>
          </Button>
          <Button
            className="pt-button btn-blue-color"
            onClick={this.handleSubmit}
            loading={this.state.isLoading}
          >
            <span className="text-content">Lưu</span>
            <span className="pt-icon icon-save"></span>
          </Button>
        </div>
        <PopupConfirm
          isOpen={this.state.isOpenPopup}
          onClose={this.handleClickPopup}
          title={POPUP.GUI_LAI_EVN}
          text={POPUP.TEXT_BUTTON_SUBMIT}
          onSubmit={this.guiLaiEVN}
          isWarning
        >
          {POPUP.XAC_NHAN_GUI_LAI_EVN}
        </PopupConfirm>
      </div>
    )
  }
}

export default SendAdditional
