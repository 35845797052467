import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Checkbox } from '@blueprintjs/core'
import moment from 'moment'
import classnames from 'classnames'
import DatePicker from 'react-datepicker'
import { Scrollbars } from 'react-custom-scrollbars'
import { get, size, merge, isEmpty, includes } from 'lodash'
import {
  PopupConfirm,
  DepartmentPerform,
  CabinetTree,
  LoadingItem,
  JobProjectTree,
  Fragment,
  FileList,
} from '../../components/common/'
import { Intent, Button, Radio, RadioGroup } from '@blueprintjs/core'
import 'moment/locale/vi'
import * as POPUP from '../../constants/Popup'
import * as MESSAGE from '../../constants/MessageForm'
import allowFileTypes from '../../constants/fileTypes'
import { fileType } from '../../helpers/validation'
import * as Tool from '../../helpers'
import * as Types from '../../constants/Api'

const IS_REFRESH_LIST = true
const maxMb = 25 * 2 ** 20
let fileId = 0

class Assign extends Component {
  constructor(props) {
    super(props)
    // this.handleClickPopup = this.handleClickPopup.bind(this);
    this.handleClickPopupDelete = this.handleClickPopupDelete.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
    this.handleDateChange = this.handleDateChange.bind(this)
    this.handleInputChange = this.handleInputChange.bind(this)
    // this.handleCompositiveStaffSelection = this.handleCompositiveStaffSelection.bind(this);
    // this.handleCheckAllCompositive = this.handleCheckAllCompositive.bind(this);
    // this.handleOnlySeeStaffSelection = this.handleOnlySeeStaffSelection.bind(this);
    // this.handleCheckAllOnlySee = this.handleCheckAllOnlySee.bind(this);
    this.handleFileChange = this.handleFileChange.bind(this)
    this.handleGetValueGridCheckBox = this.handleGetValueGridCheckBox.bind(this)
    this.handleSubmitRemoveItem = this.handleSubmitRemoveItem.bind(this)
    //this.handleScroll = this.handleScroll.bind(this);
    this.handleEventRemoveFile = this.handleEventRemoveFile.bind(this)
    this.getUploadProgress = this.getUploadProgress.bind(this)
    this.handleChangeRaw = this.handleChangeRaw.bind(this)
    this.focousOut = this.focousOut.bind(this)
    this.handleRadio = this.handleRadio.bind(this)
    this.handleShowSaveDocument = this.handleShowSaveDocument.bind(this)
    this.handleCheckDocumentCabinet = this.handleCheckDocumentCabinet.bind(this)
    this.handleSubmitReWorking = this.handleSubmitReWorking.bind(this)
  }

  static propTypes = {
    onClickGoBack: PropTypes.func.isRequired,
    staffs: PropTypes.array.isRequired,
    onSubmit: PropTypes.func.isRequired,
    workAssignInformations: PropTypes.object,
    departmentWork: PropTypes.object,
    personalWork: PropTypes.object,
    actions: PropTypes.object,
    isLeaderUnit: PropTypes.bool,
    departments: PropTypes.array,
  }

  state = {
    isOutDate: false,
    isOpen: false,
    isOpenPopup: false,
    error: {},
    tieuDe: '',
    noiDung: '',
    chuTriId: null,
    dsPhoiHop: [],
    dsXemDeBiet: [],
    pbChuTriId: null, //Danh cho lanh dao don vi
    pbPhoiHop: [], //Danh cho lanh dao don vi
    pbXemDeBiet: [], //Danh cho lanh dao don vi
    baoCaoLai: false,
    hanGiaiQuyet: undefined,
    fileDinhKem: null,
    fileName: '',
    fileUpload: null,
    dsCongViec: [],
    staffSelections: [],
    departmentSelections: [],
    // isCheckedAllCompositive: false,
    // isCheckedAllOnlySee: false,
    phieuGiaoViecId: '',
    isLoading: {
      isLoadingSubmit: false,
      isLoadingRemove: false,
      isLoadingLuuHSCV: false,
    },
    isOpenPopupDelete: false,
    sizeFile: null,
    isDisabledButton: false,
    //isHideButton: false,
    minDate: undefined,
    maxDate: undefined,
    isDisabledDateInput: false,
    progress: 0,
    dealineToString: '',
    phieuGiaoViecGocId: null,
    congVanDenGocId: null,
    congVanNoiBoGocId: null,
    isShowSaveDocument: false, // Hiển thị lưu tủ tài liệu
    documentCabinetRequest: {
      categoryType: Types.HOSO_CONGVIEC,
      nganTuIds: [],
      tenTaiLieu: '',
      ghiChu: '',
      congVanDenId: null,
      congVanNoiBoId: null,
      fileId: null,
    },
    params: {
      categoryType: Types.HOSO_CONGVIEC,
      nganTuIds: [],
      tenTaiLieu: '',
      ghiChu: '',
      dsFileDinhKem: [
        {
          fileId: fileId++,
        },
      ],
    },
  }

  getUploadProgress = () => progress => {
    this.setState({
      progress: Math.floor((progress.loaded * 100) / progress.total),
    })
  }

  // handleClickPopup = () => {
  //   this.setState({
  //     isOpenPopup: !this.state.isOpenPopup
  //   });
  // }

  handleClickPopupDelete = () => {
    this.setState({
      isOpenPopupDelete: !this.state.isOpenPopupDelete,
    })
  }

  handleSubmitRemoveItem = e => {
    e.preventDefault()
    this.setState({
      isOpenPopupDelete: false,
      isLoading: {
        ...this.state.isLoading,
        isLoadingRemove: true,
      },
    })

    this.props.actions
      .deleteWorkAssign(this.props.workAssignInformations.phieuGiaoViecId)
      .then(res => {
        if (res.payload && res.payload.status === 200) {
          this.setState({
            isLoading: {
              ...this.state.isLoading,
              isLoadingRemove: false,
            },
          })
          this.props.onSubmit(
            'XoaGiaoViec',
            MESSAGE.TEXT_DELETE_GIAO_VIEC_SUCCESS,
            Intent.SUCCESS,
            true,
            null
          )
        } else {
          this.setState({
            isLoading: {
              ...this.state.isLoading,
              isLoadingRemove: false,
            },
          })
          this.props.onSubmit(
            'XoaGiaoViec',
            MESSAGE.TOATS_CANCLE_FAIL,
            Intent.DANGER,
            false,
            null
          )
        }
      })
  }

  handleEventRemoveFile = () => {
    let state = this.state
    delete state.error.fileName
    state.fileName = ''
    state.fileDinhKem = null
    state.fileUpload = null
    state.sizeFile = null

    this.setState(state)
  }

  handleSubmit = async e => {
    e.preventDefault()
    // CHANGED: Loại bỏ trường hợp chủ trì rỗng
    let chuTriId = this.state.chuTriId
    let pbChuTriId = this.state.pbChuTriId
    if (!this.state.chuTriId || !this.state.chuTriId.trim().length) {
      chuTriId = null
    }
    if (!this.state.pbChuTriId || !this.state.pbChuTriId.trim().length) {
      pbChuTriId = null
    }

    if (
      (this.props.isLeaderUnit === false &&
        (!chuTriId || !chuTriId.trim().length)) ||
      (this.props.isLeaderUnit === true &&
        (!pbChuTriId || !pbChuTriId.trim().length) &&
        (!chuTriId || !chuTriId.trim().length))
    ) {
      return this.props.onSubmit(
        'GiaoViec',
        MESSAGE.TEXT_MESSAGE_CHUTRI,
        Intent.WARNING,
        false
      )
    }

    this.setState({
      isLoading: {
        ...this.state.isLoading,
        isLoadingSubmit: true,
      },
    })
    const error = {}
    let sizeFile = this.state.sizeFile

    if (!this.state.hanGiaiQuyet || this.state.hanGiaiQuyet.length === 0) {
      error.hanGiaiQuyet = MESSAGE.TEXT_MESSAGE_DEFAULT
    } else {
      delete error.hanGiaiQuyet
    }

    if (this.state.error.fileName) {
      sizeFile = null
    }

    if (Object.keys(error).length !== 0) {
      this.setState({
        isLoading: {
          ...this.state.isLoading,
          isLoadingSubmit: false,
        },
        error,
      })
      return this.props.onSubmit(
        'GiaoViec',
        MESSAGE.TOATS_MESSAGE_WARNING,
        Intent.WARNING,
        false
      )
    }

    if (this.state.fileUpload) {
      await this.props.actions
        .fileUpload(this.state.fileUpload, this.getUploadProgress())
        .then(res => {
          if (res.error || !res.payload.data.result) {
            error.fileName = MESSAGE.TEXT_MESSAGE_ERROR_UPLOAD
            return this.setState({
              error,
              isLoading: {
                ...this.state.isLoading,
                isLoadingSubmit: true,
              },
              fileUpload: null,
              fileName: '',
              sizeFile: null,
            })
          }

          let fileDinhKem = null
          if (
            res.payload.data &&
            res.payload.data.result &&
            res.payload.data.result.length
          ) {
            fileDinhKem = res.payload.data.result[0]
          }
          this.setState({
            fileDinhKem: {
              file_id: fileDinhKem.id,
              ten_file: fileDinhKem.originalName,
              url: fileDinhKem.path,
              kieu_file: fileDinhKem.type,
              kich_thuoc: fileDinhKem.length,
            },
          })
        })
    }

    this.setState({ error, sizeFile })

    if (Object.keys(error).length !== 0) {
      this.setState({
        isLoading: {
          ...this.state.isLoading,
          isLoadingSubmit: false,
        },
      })
      return this.props.onSubmit(
        'GiaoViec',
        MESSAGE.TOATS_MESSAGE_WARNING,
        Intent.WARNING,
        false
      )
    }

    this.setState({
      isLoading: {
        ...this.state.isLoading,
        isLoadingSubmit: true,
      },
    })

    let hanGiaiQuyet = null
    if (
      this.state.hanGiaiQuyet &&
      this.state.hanGiaiQuyet.toString().length > 0
    ) {
      hanGiaiQuyet = moment(this.state.hanGiaiQuyet)
        .set({ hour: 0, minute: 0, second: 0, millisecond: 0 })
        .format()
    }

    if (
      this.state.params.categoryType === Types.HOSO_CONGVIEC &&
      this.state.params.nganTuIds.length > 0
    ) {
      const resultLuuHSCV = this.submitLuuHoSoCongViec()
      if (resultLuuHSCV) {
        this.props.actions.commonAddToasterMessage({
          message: MESSAGE.TOATS_MESSAGE_SUCCESS,
          intent: Intent.SUCCESS,
        })
      }
    }

    let documentCabinetRequest = null
    if (
      this.state.isShowSaveDocument &&
      this.state.documentCabinetRequest.categoryType &&
      this.state.documentCabinetRequest.nganTuIds.length > 0 &&
      this.state.documentCabinetRequest.fileId &&
      this.state.documentCabinetRequest.categoryType !== Types.HOSO_CONGVIEC
    ) {
      documentCabinetRequest = {
        ds_ngan_tu: this.state.documentCabinetRequest.nganTuIds,
        loai_tu_tai_lieu: parseInt(
          this.state.documentCabinetRequest.categoryType,
          10
        ),
        ghi_chu: this.state.documentCabinetRequest.ghiChu,
        ds_file_cong_van: [
          {
            file_id: this.state.documentCabinetRequest.fileId,
            cong_van_den_id: this.state.documentCabinetRequest.congVanDenId,
            cong_van_noi_bo_id:
              this.state.documentCabinetRequest.congVanNoiBoId,
            ten_tai_lieu: this.state.documentCabinetRequest.tenTaiLieu,
          },
        ],
      }
    }

    let dataRequest = {
      tieu_de: this.state.tieuDe,
      noi_dung: this.state.noiDung,
      bao_cao_lai: this.state.baoCaoLai,
      han_giai_quyet: hanGiaiQuyet,
      file_dinh_kem: this.state.fileDinhKem,
      nhan_vien_chu_tri_id: chuTriId,
      nhan_vien_phoi_hop_ids: this.state.dsPhoiHop,
      nhan_vien_xem_de_biet_ids: this.state.dsXemDeBiet,
      tutai_lieu_request: documentCabinetRequest,
    }

    // Cong viec phong ban
    if (
      this.props.departmentWork &&
      this.props.departmentWork.congViecPhongBanId
    ) {
      dataRequest = {
        ...dataRequest,
        ds_cong_viec: this.state.dsCongViec,
      }
    }

    // Lanh dao don vi
    if (this.props.isLeaderUnit === true) {
      dataRequest = {
        ...dataRequest,
        pb_chu_tri_id: pbChuTriId,
        pb_phoi_hop: this.state.pbPhoiHop,
        pb_xem_de_biet: this.state.pbXemDeBiet,
      }
    }

    // Cong viec ca nhan hoặc giao việc mới
    if (this.props.personalWork || this.props.isNewAssignDoc) {
      if (this.state.phieuGiaoViecGocId) {
        dataRequest = {
          ...dataRequest,
          phieu_giao_viec_id: this.state.phieuGiaoViecGocId,
        }
      }
      if (this.state.congVanDenGocId) {
        dataRequest = {
          ...dataRequest,
          cong_van_den_id: this.state.congVanDenGocId,
        }
      }
      if (this.state.congVanNoiBoGocId) {
        dataRequest = {
          ...dataRequest,
          cong_van_noi_bo_id: this.state.congVanNoiBoGocId,
        }
      }
    }

    if (!this.props.workAssignInformations) {
      let request =
        this.props.isNewAssignDoc !== undefined &&
        this.props.isNewAssignDoc === true
          ? this.props.actions.addWorkAssign(dataRequest)
          : this.props.actions.workAssign(dataRequest)
      request.then(res => {
        if (res.payload && res.payload.status === 200) {
          return this.props.onSubmit(
            'GiaoViec',
            MESSAGE.TEXT_GIAO_VIEC_SUCCESS,
            Intent.SUCCESS,
            true,
            null,
            IS_REFRESH_LIST
          )
        }
        this.setState({
          isLoading: {
            ...this.state.isLoading,
            isLoadingSubmit: false,
          },
        })
        this.props.onSubmit(
          'GiaoViec',
          MESSAGE.TOATS_MESSAGE_FAIL,
          Intent.DANGER,
          false
        )
      })
    } else {
      dataRequest = {
        ...dataRequest,
        phieu_giao_viec_id: this.state.phieuGiaoViecId,
      }

      // CHANGED: Sửa giao việc dùng congVanDenId / congVanNoiBoId
      if (this.props.departmentWork && this.props.departmentWork.congVanDenId) {
        dataRequest = {
          ...dataRequest,
          cong_van_den_id: this.props.departmentWork.congVanDenId,
        }
      }
      if (
        this.props.departmentWork &&
        this.props.departmentWork.congVanNoiBoId
      ) {
        dataRequest = {
          ...dataRequest,
          cong_van_noi_bo_id: this.props.departmentWork.congVanNoiBoId,
        }
      }
      let request =
        this.props.isNewAssignDoc !== undefined &&
        this.props.isNewAssignDoc === true
          ? this.props.actions.updateNewWorkAssign(dataRequest)
          : this.props.actions.updateWorkAssign(dataRequest)
      request.then(res => {
        if (res && res.payload && res.payload.status === 200) {
          return this.props.onSubmit(
            'GiaoViec',
            MESSAGE.TEXT_UPDATE_GIAO_VIEC_SUCCESS,
            Intent.SUCCESS,
            true,
            null,
            IS_REFRESH_LIST
          )
        }
        this.setState({
          isLoading: {
            ...this.state.isLoading,
            isLoadingSubmit: false,
          },
        })
        this.props.onSubmit(
          'GiaoViec',
          MESSAGE.TOATS_MESSAGE_FAIL,
          Intent.DANGER,
          false
        )
      })
    }
  }

  handleAreaChange = e => {
    this.setState({
      params: {
        ...this.state.params,
        [e.target.name]: e.target.value,
      },
    })
  }

  // Thêm file đính kèm hscv
  addInputFile = () => {
    this.setState(prev => ({
      params: {
        ...prev.params,
        dsFileDinhKem: [
          ...prev.params.dsFileDinhKem,
          {
            fileId: fileId++,
          },
        ],
      },
    }))
  }

  // remove file đính kèm hscv
  removeInputFile = id => {
    const { params } = this.state
    let filesCheck = params.dsFileDinhKem.filter(file => file.fileId !== id)
    if (filesCheck && filesCheck.length === 0) {
      return this.setState({
        params: {
          ...this.state.params,
          dsFileDinhKem: [
            {
              fileId: 0,
              data: null,
            },
          ],
        },
      })
    }

    this.setState(prev => ({
      params: {
        ...prev.params,
        dsFileDinhKem: prev.params.dsFileDinhKem.filter(
          file => file.fileId !== id
        ),
      },
    }))
  }

  // Change file lưu hscv
  changeFile = id => e => {
    const reader = new FileReader()
    const { files } = e.target
    const targetFile = files[0]
    let error = false
    reader.onloadend = () => {
      if (targetFile) {
        const extension = Tool.Utils.getExtensionFile(targetFile.name)
        if (get(targetFile, 'size') > maxMb) {
          error = MESSAGE.ValidateForm.MAX_FILE_SIZE(25)
        }
        if (fileType(extension, allowFileTypes)) {
          error = MESSAGE.ValidateForm.FILE_TYPE(allowFileTypes)
        }
        if (!!error) {
          return this.setState(prev => ({
            fileValidate: {
              ...prev.fileValidate,
              [id]: error,
            },
          }))
        }
        this.setState(prev => ({
          params: {
            ...prev.params,
            dsFileDinhKem: prev.params.dsFileDinhKem.map(file =>
              file.fileId === id
                ? {
                    ...file,
                    data: targetFile,
                  }
                : file
            ),
          },
          fileValidate: {
            ...prev.validate,
            [id]: false,
          },
        }))
      }
    }

    if (targetFile && !error) {
      reader.readAsDataURL(targetFile)
    }
  }

  showValidateMessage = (message, danger = false, success = false) => {
    return this.props.actions.commonAddToasterMessage({
      message: message,
      inten: success ? Intent.SUCCESS : danger ? Intent.DANGER : Intent.WARNING,
    })
  }

  changeUploadProgress = name => progress => {
    this.setState(prev => ({
      uploadProgress: {
        ...prev.uploadProgress,
        [name]: ~~((progress.loaded * 100) / progress.total),
      },
    }))
  }

  // Upload file đính kèm
  uploadFile = async files => {
    const { fileUpload } = this.props.actions
    let response = {}
    if (files && size(files.filter(f => f.data))) {
      response = await fileUpload(
        files.filter(f => f.data).map(f => f.data),
        this.changeUploadProgress
      )
    }
    if (response.error) {
      return this.showValidateMessage(MESSAGE.TEXT_MESSAGE_UPLOAD_FAIL, true)
    }
    return get(response, 'payload.data.result', []).map(file =>
      Tool.Utils.convertFileResponse(file)
    )
  }

  // Lưu hồ sơ công việc
  submitLuuHoSoCongViec = async () => {
    const { actions, departmentWork, personalWork } = this.props
    const { params } = this.state
    const { postTaiLieuHoSoCongViec } = actions
    const files = await this.uploadFile(get(params, 'dsFileDinhKem', []))
    let item = {}
    let fileDinhKemEvn = []
    if (!isEmpty(departmentWork)) {
      item = departmentWork
    } else if (!isEmpty(personalWork)) {
      item = personalWork
    } else {
      return true
    }

    if (item && item.fileDinhKemEvn && item.fileDinhKemEvn.length !== 0) {
      fileDinhKemEvn = item.fileDinhKemEvn.map(i => ({
        ...i,
        is_existed: true,
      }))
    }

    const data = Tool.Utils.toDecamelizeKeys({
      tenTaiLieu: get(item, 'trichYeu') || get(params, 'tenTaiLieu'),
      ghiChu: get(params, 'ghiChu'),
      congVanId: get(item, 'congVanId'),
      congVanNoiBoId: get(item, 'congVanNoiBoId'),
      dsHoSoCongViecId: get(params, 'nganTuIds', []),
      soCongVan: get(item, 'soCongVan'),
      ngayCongVan: get(item, 'ngayCongVan'),
      fileNoiDung: item.fileThamKhao
        ? get(item, 'fileThamKhao', {})
        : get(item, 'fileCongVan', {}),
      fileDinhKem: [...fileDinhKemEvn, ...files],
    })
    const response = await postTaiLieuHoSoCongViec(data)
    if (get(response, 'payload.status') === 200) {
      return true
    } else {
      return false
    }
  }

  handleSubmitReWorking = async e => {
    e.preventDefault()
    // CHANGED: Loại bỏ trường hợp chủ trì rỗng
    let chuTriId = this.state.chuTriId
    let pbChuTriId = this.state.pbChuTriId
    if (!this.state.chuTriId || !this.state.chuTriId.trim().length) {
      chuTriId = null
    }
    if (!this.state.pbChuTriId || !this.state.pbChuTriId.trim().length) {
      pbChuTriId = null
    }

    if (
      (this.props.isLeaderUnit === false &&
        (!chuTriId || !chuTriId.trim().length)) ||
      (this.props.isLeaderUnit === true &&
        (!pbChuTriId || !pbChuTriId.trim().length) &&
        (!chuTriId || !chuTriId.trim().length))
    ) {
      return this.props.onSubmit(
        'GiaoViec',
        MESSAGE.TEXT_MESSAGE_CHUTRI,
        Intent.WARNING,
        false
      )
    }

    this.setState({
      isLoading: {
        ...this.state.isLoading,
        isLoadingSubmit: true,
      },
      isOpenPopupDelete: false,
    })
    const error = {}
    let sizeFile = this.state.sizeFile

    if (!this.state.hanGiaiQuyet || this.state.hanGiaiQuyet.length === 0) {
      error.hanGiaiQuyet = MESSAGE.TEXT_MESSAGE_DEFAULT
    } else {
      delete error.hanGiaiQuyet
    }

    if (this.state.error.fileName) {
      sizeFile = null
    }

    if (Object.keys(error).length !== 0) {
      this.setState({
        isLoading: {
          ...this.state.isLoading,
          isLoadingSubmit: false,
        },
        error,
      })
      return this.props.onSubmit(
        'GiaoViec',
        MESSAGE.TOATS_MESSAGE_WARNING,
        Intent.WARNING,
        false
      )
    }

    if (this.state.fileUpload) {
      await this.props.actions
        .fileUpload(this.state.fileUpload, this.getUploadProgress())
        .then(res => {
          if (res.error || !res.payload.data.result) {
            error.fileName = MESSAGE.TEXT_MESSAGE_ERROR_UPLOAD
            return this.setState({
              error,
              isLoading: {
                ...this.state.isLoading,
                isLoadingSubmit: true,
              },
              fileUpload: null,
              fileName: '',
              sizeFile: null,
            })
          }

          let fileDinhKem = null
          if (
            res.payload.data &&
            res.payload.data.result &&
            res.payload.data.result.length
          ) {
            fileDinhKem = res.payload.data.result[0]
          }
          this.setState({
            fileDinhKem: {
              file_id: fileDinhKem.id,
              ten_file: fileDinhKem.originalName,
              url: fileDinhKem.path,
              kieu_file: fileDinhKem.type,
              kich_thuoc: fileDinhKem.length,
            },
          })
        })
    }

    this.setState({ error, sizeFile })

    if (Object.keys(error).length !== 0) {
      this.setState({
        isLoading: {
          ...this.state.isLoading,
          isLoadingSubmit: false,
        },
      })
      return this.props.onSubmit(
        'GiaoViec',
        MESSAGE.TOATS_MESSAGE_WARNING,
        Intent.WARNING,
        false
      )
    }

    this.setState({
      isLoading: {
        ...this.state.isLoading,
        isLoadingSubmit: true,
      },
    })

    let hanGiaiQuyet = null
    if (
      this.state.hanGiaiQuyet &&
      this.state.hanGiaiQuyet.toString().length > 0
    ) {
      hanGiaiQuyet = moment(this.state.hanGiaiQuyet)
        .set({ hour: 0, minute: 0, second: 0, millisecond: 0 })
        .format()
    }

    let documentCabinetRequest = null
    if (
      this.state.isShowSaveDocument &&
      this.state.documentCabinetRequest.categoryType &&
      this.state.documentCabinetRequest.nganTuIds.length > 0 &&
      this.state.documentCabinetRequest.fileId
    ) {
      documentCabinetRequest = {
        ds_ngan_tu: this.state.documentCabinetRequest.nganTuIds,
        loai_tu_tai_lieu: parseInt(
          this.state.documentCabinetRequest.categoryType,
          10
        ),
        ghi_chu: this.state.documentCabinetRequest.ghiChu,
        ds_file_cong_van: [
          {
            file_id: this.state.documentCabinetRequest.fileId,
            cong_van_den_id: this.state.documentCabinetRequest.congVanDenId,
            cong_van_noi_bo_id:
              this.state.documentCabinetRequest.congVanNoiBoId,
            ten_tai_lieu: this.state.documentCabinetRequest.tenTaiLieu,
          },
        ],
      }
    }

    let dataRequest = {
      tieu_de: this.state.tieuDe,
      noi_dung: this.state.noiDung,
      bao_cao_lai: this.state.baoCaoLai,
      han_giai_quyet: hanGiaiQuyet,
      file_dinh_kem: this.state.fileDinhKem,
      nhan_vien_chu_tri_id: chuTriId,
      nhan_vien_phoi_hop_ids: this.state.dsPhoiHop,
      nhan_vien_xem_de_biet_ids: this.state.dsXemDeBiet,
      tutai_lieu_request: documentCabinetRequest,
    }

    // Cong viec phong ban
    if (
      this.props.departmentWork &&
      this.props.departmentWork.congViecPhongBanId
    ) {
      dataRequest = {
        ...dataRequest,
        ds_cong_viec: this.state.dsCongViec,
      }
    }

    // Lanh dao don vi
    if (this.props.isLeaderUnit === true) {
      dataRequest = {
        ...dataRequest,
        pb_chu_tri_id: pbChuTriId,
        pb_phoi_hop: this.state.pbPhoiHop,
        pb_xem_de_biet: this.state.pbXemDeBiet,
      }
    }

    // Cong viec ca nhan hoặc giao việc mới
    if (this.props.personalWork || this.props.isNewAssignDoc) {
      if (this.state.phieuGiaoViecGocId) {
        dataRequest = {
          ...dataRequest,
          phieu_giao_viec_id: this.state.phieuGiaoViecGocId,
        }
      }
      if (this.state.congVanDenGocId) {
        dataRequest = {
          ...dataRequest,
          cong_van_den_id: this.state.congVanDenGocId,
        }
      }
      if (this.state.congVanNoiBoGocId) {
        dataRequest = {
          ...dataRequest,
          cong_van_noi_bo_id: this.state.congVanNoiBoGocId,
        }
      }
    }

    if (this.props.workAssignInformations) {
      dataRequest = {
        ...dataRequest,
        old_phieu_giao_viec_id: this.state.phieuGiaoViecId,
      }

      // CHANGED: Sửa giao việc dùng congVanDenId / congVanNoiBoId
      if (this.props.departmentWork && this.props.departmentWork.congVanDenId) {
        dataRequest = {
          ...dataRequest,
          cong_van_den_id: this.props.departmentWork.congVanDenId,
        }
      }
      if (
        this.props.departmentWork &&
        this.props.departmentWork.congVanNoiBoId
      ) {
        dataRequest = {
          ...dataRequest,
          cong_van_noi_bo_id: this.props.departmentWork.congVanNoiBoId,
        }
      }
      this.props.actions
        .deleteReWorkingWork(this.state.phieuGiaoViecId, dataRequest)
        .then(res => {
          if (res.payload && res.payload.status === 200) {
            return this.props.onSubmit(
              'GiaoViec',
              MESSAGE.TOATS_MESSAGE_SUCCESS,
              Intent.SUCCESS,
              true,
              null,
              IS_REFRESH_LIST
            )
          }
          this.setState({
            isLoading: {
              ...this.state.isLoading,
              isLoadingSubmit: false,
            },
          })
          this.props.onSubmit(
            'GiaoViec',
            MESSAGE.TOATS_MESSAGE_FAIL,
            Intent.DANGER,
            false
          )
        })
    }
  }

  handleFileChange = e => {
    const error = this.state.error
    delete error.fileName

    const fileName = e.target.value
    const extension =
      fileName &&
      fileName.slice(((fileName.lastIndexOf('.') - 1) >>> 0) + 2).toLowerCase()
    let sizeFile = e.target.files && e.target.files[0] && e.target.files[0].size
    const typesFile = [
      'pdf',
      'doc',
      'docx',
      'xls',
      'xlsx',
      'ppt',
      'pptx',
      'ppsx',
      'pps',
    ]
    if (!includes(typesFile, extension)) {
      error.fileName = MESSAGE.ValidateForm.FILE_TYPE(typesFile)
    } else if (sizeFile > 1024 * 1024 * 25) {
      error.fileName = MESSAGE.TEXT_MAXIMUM_SIZE_FILE
    }

    this.setState({ error })

    if (!error.fileName) {
      let reader = new FileReader()
      let file = e.target.files[0]

      reader.onloadend = () => {
        this.setState({
          fileUpload: file,
          fileName: file.name,
          fileDinhKem: null,
          sizeFile,
        })
      }

      if (file) {
        reader.readAsDataURL(file)
      }
    }
  }

  handleDateChange = (name, nameToString, e) => {
    const error = this.state.error
    delete error[name]
    let value = e ? e : undefined
    let valueToString = ''
    if (value) {
      valueToString = moment(e).format('DD/MM/YYYY')
    }

    this.setState({
      [name]: value,
      [nameToString]: valueToString,
      error,
    })
  }

  handleChangeRaw(name, value) {
    this.setState({ [name]: value })
  }

  focousOut(name, nameToString, value) {
    if (this.props.isNewAssignDoc === true) {
      return
    }
    let checkMin = false
    let checkMax = false

    if (!this.state.maxDate && Tool.Utils.isValidDate(value)) {
      checkMax = true
    }

    if (
      this.state.maxDate &&
      Tool.Utils.isValidDate(value, {
        before: {
          date: moment(moment(this.state.maxDate).format('YYYY-MM-DD')),
        },
      })
    ) {
      checkMax = true
    }

    if (!this.state.minDate && Tool.Utils.isValidDate(value)) {
      checkMin = true
    }

    if (
      this.state.minDate &&
      Tool.Utils.isValidDate(value, {
        after: {
          date: moment(moment(this.state.minDate).format('YYYY-MM-DD')),
        },
      })
    ) {
      checkMin = true
    }

    if (checkMax && checkMin) {
      return
    }

    let oldValue = undefined
    let oldValueToString = ''
    if (
      this.props.workAssignInformations &&
      this.props.workAssignInformations.hanGiaiQuyet
    ) {
      oldValue = moment(this.props.workAssignInformations.hanGiaiQuyet)
      oldValueToString = moment(
        this.props.workAssignInformations.hanGiaiQuyet
      ).format('DD/MM/YYYY')
    }

    this.setState({
      [nameToString]: oldValueToString,
      [name]: oldValue,
    })
  }

  handleInputChange = e => {
    if (e.target.type === 'checkbox') {
      return this.setState({ [e.target.name]: e.target.checked })
    }

    const error = this.state.error
    delete error[e.target.name]
    this.setState({ [e.target.name]: e.target.value, error })
  }

  // Change checkbox group to grid

  // handleCheckAllCompositive = () => {
  //   this.setState({isCheckedAllCompositive: !this.state.isCheckedAllCompositive});
  //   let dsPhoiHop = this.state.dsPhoiHop;
  //   if (!this.state.isCheckedAllCompositive) {
  //     this.state.staffSelections.forEach(item => {
  //       if (dsPhoiHop.indexOf(item.value) === -1 && this.state.dsXemDeBiet.indexOf(item.value) === -1 && this.state.chuTriId !== item.value) {
  //         dsPhoiHop.push(item.value);
  //       }
  //     });
  //     return this.setState({dsPhoiHop});
  //   }

  //   dsPhoiHop = [];
  //   this.setState({dsPhoiHop});
  // }

  // handleCompositiveStaffSelection = (e) => {
  //   const newSelection = e.target.value;
  //   let dsPhoiHop;
  //   if (this.state.dsPhoiHop.length > 0 && this.state.dsPhoiHop.indexOf(newSelection) > -1) {
  //     dsPhoiHop = this.state.dsPhoiHop.filter(s => s !== newSelection);
  //   } else {
  //     dsPhoiHop = [...this.state.dsPhoiHop, newSelection];
  //   }
  //   this.setState({dsPhoiHop});
  // }

  // handleCheckAllOnlySee = () => {
  //   this.setState({isCheckedAllOnlySee: !this.state.isCheckedAllOnlySee});
  //   let dsXemDeBiet = this.state.dsXemDeBiet;
  //   if (!this.state.isCheckedAllOnlySee) {
  //     this.state.staffSelections.forEach(item => {
  //       if (dsXemDeBiet.indexOf(item.value) === -1 && this.state.dsPhoiHop.indexOf(item.value) === -1 && this.state.chuTriId !== item.value) {
  //         dsXemDeBiet.push(item.value);
  //       }
  //     });
  //     return this.setState({dsXemDeBiet});
  //   }

  //   dsXemDeBiet = [];
  //   this.setState({dsXemDeBiet});
  // }

  // handleOnlySeeStaffSelection = (e) => {
  //   const newSelection = e.target.value;
  //   let dsXemDeBiet;
  //   if (this.state.dsXemDeBiet.length > 0 && this.state.dsXemDeBiet.indexOf(newSelection) > -1) {
  //     dsXemDeBiet = this.state.dsXemDeBiet.filter(s => s !== newSelection);
  //   } else {
  //     dsXemDeBiet = [...this.state.dsXemDeBiet, newSelection];
  //   }
  //   this.setState({dsXemDeBiet: dsXemDeBiet});
  // }

  handleGetValueGridCheckBox = (type, data) => {
    if (!data || !Object.keys(data).length) {
      return
    }

    switch (type) {
      case 'departments':
        let chuTriId = this.state.chuTriId
        if (this.state.pbChuTriId !== data.chuTriId) {
          chuTriId = null
        }
        return this.setState({
          chuTriId,
          pbChuTriId: data.chuTriId,
          pbPhoiHop: data.dsPhoiHop,
          pbXemDeBiet: data.dsXemDeBiet,
        })

      default:
        let pbChuTriId = this.state.pbChuTriId
        if (this.state.chuTriId !== data.chuTriId) {
          pbChuTriId = null
        }
        return this.setState({
          pbChuTriId,
          chuTriId: data.chuTriId,
          dsPhoiHop: data.dsPhoiHop,
          dsXemDeBiet: data.dsXemDeBiet,
        })
    }
  }

  handleRadio = e => {
    this.setState({
      documentCabinetRequest: {
        ...this.state.documentCabinetRequest,
        categoryType: e.target.value,
        nganTuIds: [],
      },
      params: {
        ...this.state.params,
        categoryType: e.target.value,
        nganTuIds: [],
      },
    })

    this.props.onSelectSaveTo &&
      this.props.onSelectSaveTo(e.target.value, false)
  }

  handleShowSaveDocument = () => {
    this.setState(
      { isShowSaveDocument: !this.state.isShowSaveDocument },
      () => {
        if (this.state.isShowSaveDocument && this.props.onShowSaveDocument) {
          this.handleRadio({
            target: { value: this.state.documentCabinetRequest.categoryType },
          })
          this.props.onShowSaveDocument()
        }
        if (!this.state.isShowSaveDocument) {
          this.setState({
            documentCabinetRequest: {
              ...this.state.documentCabinetRequest,
              categoryType: Types.HOSO_CONGVIEC,
              nganTuIds: [],
            },
          })
        }
      }
    )
  }

  handleCheckDocumentCabinet(nganTuIds) {
    if (
      this.state.params &&
      this.state.params.categoryType === Types.HOSO_CONGVIEC
    ) {
      this.setState({
        params: {
          ...this.state.params,
          nganTuIds: nganTuIds,
        },
      })
    } else {
      this.setState({
        documentCabinetRequest: {
          ...this.state.documentCabinetRequest,
          nganTuIds: nganTuIds,
        },
      })
    }
  }

  componentDidMount() {
    const staffSelections = []
    const departmentSelections = []
    this.props.staffs.forEach(item => {
      staffSelections.push({
        content: item.tenNhanVien,
        value: item.chucDanhId,
      })
    })

    // TODO: Hot fix: show tat ca phong ban
    // const { mainDepartmentId } = this.props;
    // const currentDepartment = this.props.departments.find(item => item.phongBanId === mainDepartmentId);
    // const levelDepartment = currentDepartment && currentDepartment.loai;
    // levelDepartment && this.props.departments.forEach((item,i) => {
    //   if (item.phongBanId === mainDepartmentId || item.loai > levelDepartment) {
    //     departmentSelections.push({content: item.maPhongBan, value: item.phongBanId});
    //   }
    // });

    this.props.departments.forEach((item, i) => {
      departmentSelections.push({
        content: item.maPhongBan,
        value: item.phongBanId,
      })
    })

    this.setState({ staffSelections, departmentSelections })

    const dsCongViec = []

    if (this.props.departmentWork) {
      let error = this.state.error
      let currentDate = moment().format('YYYY-MM-DD')
      let hanGiaiQuyet = this.state.hanGiaiQuyet
      let minDate = this.state.minDate
      let maxDate = this.state.maxDate
      let isDisabledDateInput = this.state.isDisabledDateInput
      let documentCabinetRequest = this.state.documentCabinetRequest
      let { isOutDate } = this.state

      if (
        !this.props.departmentWork.hanGiaiQuyet ||
        !Tool.Utils.isValidDate(
          moment(this.props.departmentWork.hanGiaiQuyet).format('DD/MM/YYYY')
        )
      ) {
        hanGiaiQuyet = moment().add(7, 'days')
        minDate = moment()
        // TODO: Khi han giai quyet chi dao null => maxDate = undefined
        // maxDate = moment().add(7, 'days');
      } else if (
        moment(this.props.departmentWork.hanGiaiQuyet).format('YYYY-MM-DD') <
        currentDate
      ) {
        error.hanGiaiQuyet = MESSAGE.TEXT_MESSAGE_HANGIAIQUYET_GIAOVIEC
        hanGiaiQuyet = moment(this.props.departmentWork.hanGiaiQuyet)
        isDisabledDateInput = true
        isOutDate = true
      } else {
        hanGiaiQuyet = moment(this.props.departmentWork.hanGiaiQuyet)
        minDate = moment()
        maxDate = moment(this.props.departmentWork.hanGiaiQuyet)
      }

      if (this.props.departmentWork.congVanNoiBoId) {
        documentCabinetRequest.congVanNoiBoId =
          this.props.departmentWork.congVanNoiBoId
        dsCongViec.push({
          cong_viec_phong_ban_id: this.props.departmentWork.congViecPhongBanId,
          cong_van_noi_bo_id: this.props.departmentWork.congVanNoiBoId,
        })
      } else {
        documentCabinetRequest.congVanDenId =
          this.props.departmentWork.congVanDenId
        dsCongViec.push({
          cong_viec_phong_ban_id: this.props.departmentWork.congViecPhongBanId,
          cong_van_den_id: this.props.departmentWork.congVanDenId,
        })
      }
      if (
        this.props.departmentWork.fileThamKhao &&
        this.props.departmentWork.fileThamKhao.fileId
      ) {
        documentCabinetRequest.fileId =
          this.props.departmentWork.fileThamKhao.fileId
      }

      this.setState({
        dsCongViec,
        tieuDe: this.props.departmentWork.trichYeu || '',
        documentCabinetRequest: {
          ...documentCabinetRequest,
          tenTaiLieu: this.props.departmentWork.trichYeu || '',
        },
        phieuGiaoViecGocId: this.props.departmentWork.phieuGiaoViecId, // phieu giao viec goc
        dealineToString: hanGiaiQuyet
          ? moment(hanGiaiQuyet).format('DD/MM/YYYY')
          : '',
        hanGiaiQuyet,
        error,
        minDate,
        maxDate,
        isDisabledDateInput,
        isOutDate,
      })

      // window.addEventListener('scroll', this.handleScroll);
      // let windowSize = window.innerHeight;
      // let gb = document.getElementById("groupButton");
      // let self = this;
      // setTimeout(function() {
      //   if (windowSize > gb.getBoundingClientRect().top) {
      //     self.setState({
      //       isHideButton: false
      //     });
      //   } else {
      //     self.setState({
      //       isHideButton: true
      //     });
      //   }
      // }, 100);
    }

    if (this.props.personalWork) {
      let error = this.state.error
      let hanGiaiQuyet = this.state.hanGiaiQuyet
      let minDate = this.state.minDate
      if (
        !this.props.personalWork.hanGiaiQuyet ||
        !Tool.Utils.isValidDate(
          moment(this.props.personalWork.hanGiaiQuyet).format('DD/MM/YYYY')
        )
      ) {
        hanGiaiQuyet = moment().add(7, 'days')
        minDate = moment()
        // TODO: Khi han giai quyet chi dao null => maxDate = undefined
        // maxDate = moment().add(7, 'days');
      } else {
        hanGiaiQuyet = moment().add(7, 'days')
        minDate = moment()
      }

      this.setState({
        dsCongViec,
        tieuDe:
          this.props.isNewAssignDoc !== undefined &&
          this.props.isNewAssignDoc === true
            ? this.props.personalWork.tieuDe
            : this.props.personalWork.trichYeu || '',
        documentCabinetRequest: {
          ...this.state.documentCabinetRequest,
          tenTaiLieu: this.props.personalWork.trichYeu || '',
          congVanDenId: this.props.personalWork.congVanDenId || null,
          congVanNoiBoId: this.props.personalWork.congVanNoiBoId || null,
          fileId:
            this.props.personalWork.fileCongVan &&
            this.props.personalWork.fileCongVan.fileId,
        },
        phieuGiaoViecGocId: this.props.personalWork.phieuGiaoViecId, // phieu giao viec goc
        congVanDenGocId: this.props.personalWork.congVanDenId, // congVanDenId goc
        congVanNoiBoGocId: this.props.personalWork.congVanNoiBoId, // congVanNoiBoId goc
        dealineToString: hanGiaiQuyet
          ? moment(hanGiaiQuyet).format('DD/MM/YYYY')
          : '',
        hanGiaiQuyet,
        error,
        minDate,
      })

      // window.addEventListener('scroll', this.handleScroll);
      // let windowSize = window.innerHeight;
      // let gb = document.getElementById("groupButton");
      // let self = this;
      // setTimeout(function() {
      //   if (windowSize > gb.getBoundingClientRect().top) {
      //     self.setState({
      //       isHideButton: false
      //     });
      //   } else {
      //     self.setState({
      //       isHideButton: true
      //     });
      //   }
      // }, 100);
    }

    // Sua giao viec
    if (this.props.workAssignInformations) {
      const error = this.state.error
      const currentDate = moment().format('YYYY-MM-DD')
      let hanGiaiQuyet = this.state.hanGiaiQuyet
      let dealineToString = this.state.dealineToString
      let { isOutDate } = this.state
      let isDisabledDateInput = this.state.isDisabledDateInput
      if (
        this.props.workAssignInformations.hanGiaiQuyet &&
        Tool.Utils.isValidDate(
          moment(this.props.workAssignInformations.hanGiaiQuyet).format(
            'DD/MM/YYYY'
          )
        )
      ) {
        hanGiaiQuyet = this.props.departmentWork
          ? moment(this.props.workAssignInformations.hanGiaiQuyet)
          : moment().add(7, 'days')
        dealineToString = this.props.departmentWork
          ? moment(this.props.workAssignInformations.hanGiaiQuyet).format(
              'DD/MM/YYYY'
            )
          : moment().add(7, 'days').format('DD/MM/YYYY')
        if (
          moment(this.props.workAssignInformations.hanGiaiQuyet).format(
            'YYYY-MM-DD'
          ) < currentDate
        ) {
          error.hanGiaiQuyet = this.props.departmentWork
            ? MESSAGE.TEXT_MESSAGE_HANGIAIQUYET_GIAOVIEC
            : ''
          isOutDate = !!this.props.departmentWork
          isDisabledDateInput = !!this.props.departmentWork
        }
      }

      let noiDung = ''
      let dsPhoiHop = []
      let dsXemDeBiet = []
      let pbPhoiHop = []
      let pbXemDeBiet = []
      let chuTriId = this.state.chuTriId
      let pbChuTriId = this.state.pbChuTriId
      if (this.props.workAssignInformations.nguoiChuTri) {
        chuTriId = this.props.workAssignInformations.nguoiChuTri.chucDanhId
      }
      if (this.props.workAssignInformations.pbChuTri) {
        pbChuTriId = this.props.workAssignInformations.pbChuTri.phongBanId
      }
      this.props.workAssignInformations.phoiHop &&
        this.props.workAssignInformations.phoiHop.forEach(item => {
          dsPhoiHop.push(item.chucDanhId)
        })
      this.props.workAssignInformations.xemDeBiet &&
        this.props.workAssignInformations.xemDeBiet.forEach(item => {
          dsXemDeBiet.push(item.chucDanhId)
        })

      // CHANGED: Lọc LDPB của các phòng ban xem để biết khỏi ds cá nhân xem để biết
      if (staffSelections && dsXemDeBiet) {
        dsXemDeBiet = dsXemDeBiet.filter(item =>
          staffSelections.find(s => s.value === item)
        )
      }

      this.props.workAssignInformations.pbPhoiHops &&
        this.props.workAssignInformations.pbPhoiHops.forEach(item => {
          pbPhoiHop.push(item.phongBanId)
        })
      this.props.workAssignInformations.pbXemDeBiets &&
        this.props.workAssignInformations.pbXemDeBiets.forEach(item => {
          pbXemDeBiet.push(item.phongBanId)
        })

      let fileDinhKem = null
      if (this.props.workAssignInformations.documentStore) {
        fileDinhKem = {
          file_id: this.props.workAssignInformations.documentStore.fileId,
          ten_file: this.props.workAssignInformations.documentStore.tenFile,
          url: this.props.workAssignInformations.documentStore.url,
          kieu_file: this.props.workAssignInformations.documentStore.kieuFile,
          kich_thuoc: this.props.workAssignInformations.documentStore.kichThuoc,
        }
      }

      let dsCongViec = []
      if (this.props.workAssignInformations.congVanNoiBoId) {
        dsCongViec.push({
          cong_viec_phong_ban_id: !this.props.status
            ? this.props.workAssignInformations.congViecPhongBanId
            : this.props.departmentWork &&
              this.props.departmentWork.congViecPhongBanId,
          cong_van_noi_bo_id: this.props.workAssignInformations.congVanNoiBoId,
        })
      } else {
        dsCongViec.push({
          cong_viec_phong_ban_id: !this.props.status
            ? this.props.workAssignInformations.congViecPhongBanId
            : this.props.departmentWork &&
              this.props.departmentWork.congViecPhongBanId,
          cong_van_den_id: this.props.workAssignInformations.congVanDenId,
        })
      }

      if (this.props.workAssignInformations.noiDung) {
        noiDung = this.props.workAssignInformations.noiDung
      }

      this.setState({
        tieuDe: this.props.workAssignInformations.tieuDe,
        noiDung,
        phieuGiaoViecId: this.props.workAssignInformations.phieuGiaoViecId,
        baoCaoLai: this.props.workAssignInformations.baoCaoLai,
        chuTriId,
        pbChuTriId,
        fileDinhKem,
        fileName:
          this.props.workAssignInformations.documentStore &&
          this.props.workAssignInformations.documentStore.tenFile,
        dealineToString,
        dsCongViec,
        dsPhoiHop,
        dsXemDeBiet,
        hanGiaiQuyet,
        pbPhoiHop,
        pbXemDeBiet,
        isOutDate,
        isDisabledDateInput,
      })
    }
  }

  componentWillReceiveProps(props) {
    if (this.state.params.tenTaiLieu === '') {
      this.setState({
        params: {
          ...this.state.params,
          tenTaiLieu: get(props.personalWork, 'trichYeu'),
        },
      })
    }
  }

  componentDidUpdate(prevProps, prevState) {
    // Change checkbox group to grid
    // if (prevState.dsPhoiHop !== this.state.dsPhoiHop) {
    //   if (this.state.dsPhoiHop.length + this.state.dsXemDeBiet.length + 1 === this.state.staffSelections.length) {
    //     return this.setState({isCheckedAllCompositive: true});
    //   }
    //   this.setState({isCheckedAllCompositive: false});
    // }
    // if (prevState.dsXemDeBiet !== this.state.dsXemDeBiet) {
    //   if (this.state.dsPhoiHop.length + this.state.dsXemDeBiet.length + 1 === this.state.staffSelections.length) {
    //     return this.setState({isCheckedAllOnlySee: true});
    //   }
    //   this.setState({isCheckedAllOnlySee: false});
    // }
    // if (prevState.isCheckedAllOnlySee !== this.state.isCheckedAllOnlySee) {
    //   if (this.state.dsPhoiHop.length) {
    //     this.setState({isCheckedAllCompositive: true});
    //   } else {
    //     this.setState({isCheckedAllCompositive: false});
    //   }
    //   if (this.state.dsXemDeBiet.length + this.state.dsPhoiHop.length + 1 !== this.state.staffSelections.length) {
    //     this.setState({isCheckedAllCompositive: false});
    //   }
    // }
    // if (prevState.isCheckedAllCompositive !== this.state.isCheckedAllCompositive) {
    //   if (this.state.dsXemDeBiet.length) {
    //     this.setState({isCheckedAllOnlySee: true});
    //   } else {
    //     this.setState({isCheckedAllOnlySee: false});
    //   }
    //   if (this.state.dsXemDeBiet.length + this.state.dsPhoiHop.length + 1 !== this.state.staffSelections.length) {
    //     this.setState({isCheckedAllOnlySee: false});
    //   }
    // }
  }

  // componentWillUnmount() {
  //   window.removeEventListener('scroll', this.handleScroll);
  // }
  //
  // handleScroll (event) {
  //   let windowSize = window.innerHeight;
  //   let gb = document.getElementById("groupButton");
  //   if (windowSize >= gb.getBoundingClientRect().top) {
  //     this.setState({
  //       isHideButton: false
  //     });
  //   }else{
  //     this.setState({
  //       isHideButton: true
  //     });
  //   }
  // }

  render() {
    let dsNguoiDaBaoCao = ''
    if (
      this.props.workAssignInformations &&
      this.props.workAssignInformations.dsNguoiDaBaoCao
    ) {
      let nhanVienBaoCao = {}
      for (
        let i = 0;
        i < this.props.workAssignInformations.dsNguoiDaBaoCao.length;
        i++
      ) {
        nhanVienBaoCao[
          this.props.workAssignInformations.dsNguoiDaBaoCao[i].chucDanhId
        ] = this.props.workAssignInformations.dsNguoiDaBaoCao[i]
      }
      let tmp = []
      this.props.staffs.forEach(item => {
        if (nhanVienBaoCao[item.chucDanhId]) {
          tmp.push(item.tenNhanVien)
        }
      })
      dsNguoiDaBaoCao = tmp.join(', ')
    }
    const isLoadingAll =
      Object.values(this.state.isLoading).find(item => item === true) !==
      undefined
    let phieuGiaoViecId = this.props.workAssignInformations
      ? this.props.workAssignInformations.phieuGiaoViecId
      : null
    let index =
      phieuGiaoViecId &&
      this.props.dsPhieuGiaoViec &&
      this.props.dsPhieuGiaoViec.findIndex(
        item => item.phieuGiaoViecId === phieuGiaoViecId
      ) > 0
        ? this.props.dsPhieuGiaoViec.findIndex(
            item => item.phieuGiaoViecId === phieuGiaoViecId
          ) - 1
        : null
    let hanGiaiQuyet =
      phieuGiaoViecId &&
      index !== null &&
      index >= 0 &&
      this.props.dsPhieuGiaoViec[index]
        ? this.props.dsPhieuGiaoViec[index].hanGiaiQuyet
        : null
    const maxDate =
      this.props.isNewAssignDoc !== undefined &&
      this.props.isNewAssignDoc === true &&
      this.props.workAssignInformations &&
      hanGiaiQuyet
        ? moment(hanGiaiQuyet)
        : this.state.maxDate
    const { params, isLoading, fileValidate = {} } = this.state
    const { auth, personalWork: congViecData = {} } = this.props
    return (
      <div className="container-form-action">
        <div className="heading">
          <span className="click-go-back" onClick={this.props.onClickGoBack}>
            <span className="pt-icon pt-icon-arrow-left pull-left"></span>
          </span>
          <h4 className="name-heading">
            {this.props.status
              ? 'Giao lại'
              : this.props.workAssignInformations
              ? 'Sửa/Hủy giao việc'
              : 'Phiếu giao việc'}
          </h4>
        </div>
        <div className="form-container">
          <div className="container-detail form-assign-work">
            {/* <div className="pt-form-group pt-intent-danger">
              <div className="row full-row">
                <label className="pt-label tt-label col-md-4 col-xs-12">
                  Tiêu đề
                </label>
                <div className="pt-form-content col-md-8 col-xs-12">
                  <p className="title-assign">
                    {this.props.departmentWork.trichYeu}
                  </p>
                </div>
              </div>
            </div> */}

            <div className="pt-form-group">
              <div className="row full-row">
                <div className="col-xs-12 col-md-12 form-item">
                  <label className="pt-label">Nội dung</label>
                  <div className="pt-form-content">
                    <textarea
                      name="noiDung"
                      placeholder="Nhập nội dung…"
                      dir="auto"
                      value={this.state.noiDung}
                      onChange={this.handleInputChange}
                      rows="1"
                      className="pt-input pt-fill"
                      autoFocus={true}
                    ></textarea>
                  </div>
                </div>
              </div>
            </div>

            <div className="row full-row">
              <label className="pt-label tt-label col-md-5 col-xs-5">
                Báo cáo lại kết quả
              </label>
              <div className="pt-form-content col-md-6 col-xs-6">
                <Checkbox
                  className="pt-large"
                  checked={this.state.baoCaoLai}
                  onChange={this.handleInputChange}
                  name="baoCaoLai"
                />
              </div>
            </div>
            <div className="pt-form-group resolve-date pt-intent-danger">
              <div className="row full-row">
                <div className="col-xs-12 col-sm-12 col-md-12 form-item">
                  <label className="pt-label">
                    Hạn giải quyết
                    <span className="pt-text-muted required-input">*</span>
                  </label>
                  <div className="pt-form-content w-100">
                    <div className="pt-input-group">
                      <DatePicker
                        isClearable={
                          this.state.dealineToString &&
                          !this.state.isDisabledDateInput
                            ? true
                            : false
                        }
                        readOnly
                        selected={this.state.hanGiaiQuyet}
                        onChange={this.handleDateChange.bind(
                          this,
                          'hanGiaiQuyet',
                          'dealineToString'
                        )}
                        value={this.state.dealineToString}
                        onChangeRaw={e =>
                          this.handleChangeRaw(
                            'dealineToString',
                            e.target.value
                          )
                        }
                        onBlur={e =>
                          this.focousOut(
                            'hanGiaiQuyet',
                            'dealineToString',
                            e.target.value
                          )
                        }
                        format="DD/MM/YYYY"
                        showMonthDropdown
                        showYearDropdown
                        dropdownMode="select"
                        className="input-datepicker input-icon-datepicker"
                        placeholderText="Chọn ngày..."
                        minDate={this.state.minDate}
                        maxDate={
                          index === null &&
                          this.props.workAssignInformations &&
                          this.props.isNewAssignDoc !== undefined &&
                          this.props.isNewAssignDoc === true
                            ? null
                            : maxDate
                        }
                        disabled={this.state.isDisabledDateInput}
                        popperPlacement="auto"
                        popperModifiers={{
                          offset: {
                            enabled: true,
                            offset: '5px, 10px',
                          },
                          preventOverflow: {
                            enabled: true,
                            escapeWithReference: false,
                            boundariesElement: 'viewport',
                          },
                        }}
                      />
                    </div>
                  </div>
                </div>
                {this.props.departmentWork &&
                  this.props.departmentWork.hanGiaiQuyet && (
                    <div className="note-deadline pt-inline col-md-12">
                      <p className="text">
                        Hạn giải quyết công văn{' '}
                        {moment(this.props.departmentWork.hanGiaiQuyet).format(
                          'DD/MM/YYYY'
                        )}
                      </p>
                    </div>
                  )}
                {this.props.personalWork &&
                  this.props.personalWork.hanGiaiQuyet && (
                    <div className="note-deadline pt-inline col-md-6 col-sm-8 col-xs-8">
                      <p className="text">
                        Hạn giải quyết công việc{' '}
                        {moment(this.props.personalWork.hanGiaiQuyet).format(
                          'DD/MM/YYYY'
                        )}
                      </p>
                    </div>
                  )}
                {this.state.error.hanGiaiQuyet !== undefined && (
                  <div
                    className={classnames('col-xs-12 pt-form-helper-content', {
                      'col-md-8':
                        this.state.error.hanGiaiQuyet !==
                        MESSAGE.TEXT_MESSAGE_HANGIAIQUYET_GIAOVIEC,
                      'col-md-offset-3':
                        this.state.error.hanGiaiQuyet !==
                        MESSAGE.TEXT_MESSAGE_HANGIAIQUYET_GIAOVIEC,
                      'font-weight-bold':
                        this.state.error.hanGiaiQuyet ===
                        MESSAGE.TEXT_MESSAGE_HANGIAIQUYET_GIAOVIEC,
                      'text-fresh-red':
                        this.state.error.hanGiaiQuyet ===
                        MESSAGE.TEXT_MESSAGE_HANGIAIQUYET_GIAOVIEC,
                    })}
                  >
                    <p className="pt-form-helper-text">
                      {this.state.error.hanGiaiQuyet}
                    </p>
                  </div>
                )}
              </div>
            </div>

            <div className="pt-form-group pt-intent-danger">
              <div className="row full-row">
                <label className="pt-label col-md-12 col-xs-12">
                  File đính kèm
                </label>
                <div className="pt-form-content col-md-12 col-xs-12">
                  <div
                    className={classnames('pt-form-content', {
                      'upload-have-close':
                        this.state.fileName && this.state.fileName.length > 0,
                    })}
                  >
                    <label className="pt-file-upload">
                      <input
                        type="file"
                        name="fileName"
                        onChange={this.handleFileChange}
                        disabled={this.state.isLoading && isLoadingAll}
                        onClick={event => {
                          event.target.value = null
                        }}
                      />
                      <span className="pt-file-upload-input">
                        {!this.state.fileName ||
                        this.state.fileName.length === 0
                          ? 'Chọn file đính kèm'
                          : this.state.fileName}
                      </span>
                    </label>
                    {this.state.fileName &&
                      this.state.fileName.length > 0 &&
                      !isLoadingAll && (
                        <span
                          onClick={this.handleEventRemoveFile}
                          className="icon-bc icon-close icon-remove-file"
                        ></span>
                      )}
                    {this.state.sizeFile !== null &&
                      !isNaN(this.state.sizeFile) && (
                        <p className="pt-form-helper-text pt-form-helper-infomation">
                          Kích thước file đã upload:
                          <span className="size-file">
                            {' '}
                            {Tool.Utils.getFileSize(this.state.sizeFile)}
                          </span>
                        </p>
                      )}
                    {this.state.error.fileName !== undefined && (
                      <div className="pt-form-helper-text">
                        {this.state.error.fileName}
                      </div>
                    )}
                    {isLoadingAll && this.state.progress > 0 && (
                      <div className="progress-upload-content">
                        <span className="percent-content">
                          {this.state.progress}%
                        </span>
                        <div className="pt-progress-bar pt-intent-primary progress-bar-upload">
                          <div
                            className="pt-progress-meter"
                            style={{ width: this.state.progress + '%' }}
                          ></div>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>

            {/* Design Update*/}
            {/* CHANGED: Tách cá nhân và phòng ban riêng  */}
            {/* <div className="pt-form-group">
              <label className="pt-label pt0">
                {this.props.isLeaderUnit === false ? 'Cá nhân ': 'Phòng ban '} thực hiện
              </label>
              <DepartmentPerform
                options={this.props.isLeaderUnit === false ? this.state.staffSelections : this.state.departmentSelections}
                onSubmit={this.handleGetValueGridCheckBox}
                dsPhoiHop={this.props.isLeaderUnit === false ? this.state.dsPhoiHop : this.state.pbPhoiHop}
                dsXemDeBiet={this.props.isLeaderUnit === false ? this.state.dsXemDeBiet : this.state.pbXemDeBiet}
                chuTriId={this.props.isLeaderUnit === false ? this.state.chuTriId : this.state.pbChuTriId}
                selectedCompositiveDepartments={[]}
                selectedOnlySeeDepartments={[]}
                titles={['CT', 'PH', 'XĐB']}
                isHasRadio={true}
                type={this.props.isLeaderUnit === false ? 'Cá nhân' : 'Phòng ban'}
              />
            </div> */}

            {/* Phòng ban */}
            {this.props.isLeaderUnit === true && (
              <div className="pt-form-group">
                <label className="pt-label pt0">Phòng ban thực hiện</label>
                <DepartmentPerform
                  userRoleId={this.props.userRoleId}
                  isOutDate={this.state.isOutDate}
                  isDisabledAll={this.state.isOutDate}
                  options={this.state.departmentSelections}
                  onSubmit={this.handleGetValueGridCheckBox.bind(
                    this,
                    'departments'
                  )}
                  dsPhoiHop={this.state.pbPhoiHop}
                  dsXemDeBiet={this.state.pbXemDeBiet}
                  chuTriId={this.state.pbChuTriId}
                  selectedCompositiveDepartments={[]}
                  selectedOnlySeeDepartments={[]}
                  titles={['CT', 'PH', 'XĐB']}
                  isHasRadio={true}
                  type="Phòng ban"
                />
              </div>
            )}

            {/* Cá nhân */}
            <div className="pt-form-group">
              <label className="pt-label pt0">Cá nhân thực hiện</label>
              <DepartmentPerform
                userRoleId={this.props.userRoleId}
                isOutDate={this.state.isOutDate}
                isDisabledAll={this.state.isOutDate}
                options={this.state.staffSelections}
                onSubmit={this.handleGetValueGridCheckBox.bind(this, 'staffs')}
                dsPhoiHop={this.state.dsPhoiHop}
                dsXemDeBiet={this.state.dsXemDeBiet}
                chuTriId={
                  this.state.isOutDate && !this.props.workAssignInformations
                    ? this.props.userRoleId
                    : this.state.chuTriId
                }
                selectedCompositiveDepartments={[]}
                selectedOnlySeeDepartments={[]}
                titles={['CT', 'PH', 'XĐB']}
                isHasRadio={true}
                type="Cá nhân"
              />
            </div>

            {/* Design Update*/}

            {/* <div className="pt-form-group">
              <div className="row full-row">
                <div className="col-md-4 col-xs-12 ">
                  <label className="pt-label">
                    Chủ trì
                    <span className="pt-text-muted required-input">*</span>
                  </label>
                </div>
                <div className="col-md-8 col-xs-12 text-padding-ct">
                  <div className="pt-form-content">
                    <div className="pt-select">
                      <select name="chuTriId" value={this.state.chuTriId} onChange={this.handleInputChange}>
                        {this.props.staffs.map((item, i) => (
                          <option key={i} value={item.chucDanhId}>
                            {item.tenNhanVien}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/* Phối hợp*/}
            {/*<div className="selectbox-group">
              <div className="heading-group-select disabled-checkbox">
                <span className="pull-left name_group">Phối hợp</span>
                <Checkbox
                  className="pull-right"
                  name="allCompositive"
                  checked={this.state.isCheckedAllCompositive}
                  onChange={this.handleCheckAllCompositive}
                  disabled={this.state.dsXemDeBiet.length + 1 === this.state.staffSelections.length || !this.state.staffSelections.length}
                >
                  <span className="name_checkbox">Chọn tất cả</span>
                </Checkbox>
              </div>
              <div className="container-checkbox">
                <CheckboxGroup
                  name="phoiHop"
                  options={this.state.staffSelections}
                  selectedOptions={this.state.dsPhoiHop}
                  onChange={this.handleCompositiveStaffSelection}
                  disabledList={this.state.dsXemDeBiet.concat(this.state.chuTriId)}
                  colum="col-xs-6 col-sm-6 col-md-6"
                />
              </div>
            </div>*/}

            {/* Xem để biết*/}
            {/*<div className="selectbox-group">
              <div className="heading-group-select disabled-checkbox">
                <span className="pull-left name_group">Xem để biết</span>
                <Checkbox
                  className="pull-right"
                  name="allOnlySee"
                  checked={this.state.isCheckedAllOnlySee}
                  onChange={this.handleCheckAllOnlySee}
                  disabled={this.state.dsPhoiHop.length + 1 === this.state.staffSelections.length || !this.state.staffSelections.length}
                >
                  <span className="name_checkbox">Chọn tất cả</span>
                </Checkbox>
              </div>
              <div className="container-checkbox">
                <CheckboxGroup
                  name="xemDeBiet"
                  options={this.state.staffSelections}
                  selectedOptions={this.state.dsXemDeBiet}
                  onChange={this.handleOnlySeeStaffSelection}
                  isCheckFull={this.state.isCheckedAllOnlySee}
                  disabledList={this.state.dsPhoiHop.concat(this.state.chuTriId)}
                  colum="col-xs-6 col-sm-6 col-md-6"
                />
              </div>
            </div>*/}

            {/*<div className="pt-form-group pt-intent-danger">
              <div className="row full-row">
                <label className="pt-label col-md-4 col-xs-12 col-sm-12">
                  Lưu tủ tài liệu
                  <span className="pt-text-muted required-input">*</span>
                </label>
                <div className="pt-form-content col-md-8 col-xs-12 col-sm-12">
                  <Button className="btn-add-document" >
                    <span className="text-btn">Chọn tủ tài liệu</span>
                    <span className="icon-ttl icon-Tu_Tai_Lieu"></span>
                  </Button>
                  <p className="info-save-document">
                    Congtactochuc/hosonhanvien/<span className="year-style">nam2017</span>
                    <span className="pt-icon pt-icon-cross"></span>
                  </p>
                </div>
              </div>
            </div>*/}
            {!this.props.workAssignInformations &&
              !(
                this.props.departmentWork &&
                this.props.departmentWork.congVanDenId === null &&
                this.props.departmentWork.congVanNoiBoId === null
              ) &&
              !(
                this.props.isNewAssignDoc !== undefined &&
                this.props.isNewAssignDoc === true
              ) && (
                <div className="pt-form-group pt-intent-danger documents-cabinet-assign-work">
                  <div className="section-box-cabinet">
                    <Checkbox
                      className="name-checkbox"
                      name="isShowSaveDocument"
                      checked={this.state.isShowSaveDocument}
                      onChange={this.handleShowSaveDocument}
                      disabled={
                        this.props.isLoadingSaveDocument || isLoadingAll
                      }
                    >
                      <label className="pt-label">Lưu tủ tài liệu</label>
                    </Checkbox>
                    {this.state.isShowSaveDocument && (
                      <div className="documents-cabinet">
                        {this.props.isLoadingSaveDocument === true && (
                          <div className="loading-container">
                            <LoadingItem />
                            <LoadingItem />
                          </div>
                        )}
                        {this.props.isLoadingSaveDocument === false && (
                          <div className="checkbox-document">
                            <div className="row">
                              <div className="col-md-5 col-sm-5 col-xs-12">
                                <Scrollbars
                                  autoHide
                                  autoHideTimeout={100}
                                  autoHeight
                                  autoHeightMin={0}
                                  autoHeightMax={324}
                                  autoHideDuration={200}
                                >
                                  <div className="type-document-list">
                                    <RadioGroup
                                      label="Lưu vào"
                                      onChange={this.handleRadio.bind(this)}
                                      selectedValue={
                                        this.state.documentCabinetRequest
                                          .categoryType
                                      }
                                    >
                                      {this.props.saveTo.map((item, i) => (
                                        <Radio
                                          key={i}
                                          className="pt-large pt-text"
                                          label={item.name}
                                          value={item.id}
                                          disabled={item.disabled}
                                        />
                                      ))}
                                    </RadioGroup>
                                  </div>
                                </Scrollbars>
                              </div>
                              <div className="col-md-7 col-sm-7 col-xs-12">
                                {this.state.documentCabinetRequest
                                  .categoryType === Types.HOSO_CONGVIEC ? (
                                  <JobProjectTree
                                    data={this.props.archives}
                                    listDisable={
                                      this.props.listCabinetOfDocument
                                    }
                                    onCheck={this.handleCheckDocumentCabinet}
                                    auth={this.props.auth}
                                  />
                                ) : (
                                  <CabinetTree
                                    data={this.props.archives}
                                    listDisable={
                                      this.props.listCabinetOfDocument
                                    }
                                    onCheck={this.handleCheckDocumentCabinet}
                                  />
                                )}
                              </div>
                            </div>
                          </div>
                        )}
                      </div>
                    )}
                  </div>
                  {/* Form nội dung tủ tài liệu */}
                  {params.categoryType === Types.HOSO_CONGVIEC &&
                    this.state.isShowSaveDocument && (
                      <div className="form-input form-save-document-content">
                        <div className="pt-form-group">
                          <div className="row">
                            <label className="pt-label col-md-4 col-xs-12">
                              Ghi Chú
                            </label>
                            <div className="pt-form-content col-md-8 col-xs-12">
                              <textarea
                                rows="1"
                                className="pt-input pt-fill"
                                placeholder="Nhập nội dung…"
                                dir="auto"
                                name="ghiChu"
                                onChange={this.handleAreaChange}
                                defaultValue={get(this.state.params, 'ghiChu')}
                              />
                            </div>
                          </div>
                        </div>

                        {!!size(
                          merge(
                            congViecData.fileDinhKemEvn,
                            congViecData.fileThamKhao
                          )
                        ) && (
                          <Fragment>
                            {!!size(congViecData.fileDinhKemEvn) && (
                              <div className="row">
                                <label className="pt-label col-md-4 col-xs-12">
                                  File đính kèm:
                                </label>
                                <div className="pt-form-content col-md-8 col-xs-12">
                                  <FileList
                                    auth={auth}
                                    receiveDocument={congViecData}
                                    field="fileDinhKemEvn"
                                  />
                                </div>
                              </div>
                            )}
                            {!!size(congViecData.fileThamKhao) && (
                              <div className="row">
                                <label className="pt-label col-md-4 col-xs-12">
                                  File tham khảo:
                                </label>
                                <div className="pt-form-content col-md-8 col-xs-12">
                                  <FileList
                                    auth={auth}
                                    receiveDocument={congViecData}
                                    field="fileThamKhao"
                                  />
                                </div>
                              </div>
                            )}
                          </Fragment>
                        )}
                        <div className="pt-form-content">
                          <label className="pt-label">Thêm file đính kèm</label>
                          {get(params, 'dsFileDinhKem', []).map(
                            (fileInput, index) => (
                              <div
                                key={index}
                                className={classnames(
                                  'pt-form-group pt-intent-danger',
                                  {
                                    'upload-have-close': !!index,
                                  }
                                )}
                              >
                                <label className="pt-file-upload">
                                  <input
                                    type="file"
                                    name={'fileDinhKem'}
                                    onChange={this.changeFile(fileInput.fileId)}
                                    disabled={get(
                                      isLoading,
                                      'isLoadingLuuHSCV',
                                      false
                                    )}
                                  />
                                  <span className="pt-file-upload-input">
                                    {get(fileInput, 'data.name') ||
                                      'Chọn file đính kèm'}
                                  </span>
                                </label>
                                {fileInput &&
                                  fileInput.data &&
                                  get(fileInput, 'data.name') !== '' && (
                                    <span
                                      onClick={() =>
                                        this.removeInputFile(fileInput.fileId)
                                      }
                                      className="icon-bc icon-close icon-remove-file z-index-1"
                                    />
                                  )}
                                {get(fileInput, 'data.size') && (
                                  <p className="pt-form-helper-text pt-form-helper-infomation">
                                    Kích thước file đã upload:
                                    <span className="size-file">
                                      {Tool.Utils.getFileSize(
                                        get(fileInput, 'data.size')
                                      )}
                                    </span>
                                  </p>
                                )}
                                {get(fileValidate, fileInput.fileId) && (
                                  <div className="pt-form-helper-text">
                                    {get(fileValidate, fileInput.fileId)}
                                  </div>
                                )}
                              </div>
                            )
                          )}
                          <Button
                            className="btn-add-file mt0 mr10"
                            onClick={this.addInputFile}
                            disabled={get(isLoading, 'isLoadingLuuHSCV', false)}
                          >
                            <span>Thêm file</span>
                            <span className="icon-bc icon-Plus" />
                          </Button>
                        </div>
                      </div>
                    )}
                  {/* END Form nội dung tủ tài liệu */}
                </div>
              )}

            {/* Khi công việc đã được báo cáo thì hiện dòng này*/}
            {this.props.workAssignInformations &&
              this.props.workAssignInformations.daCoBaoCao === true && (
                <p className="note-highline note-qoute-left">
                  Chú ý công việc này đã được báo cáo bởi: {dsNguoiDaBaoCao}
                </p>
              )}

            {/* Khi công việc đã được phòng ban giao việc thì hiện dòng này*/}
            {this.props.workAssignInformations &&
              this.props.workAssignInformations.daCoPhongBanThucHien ===
                true && (
                <p className="note-highline note-qoute-left">
                  Chú ý công văn này đã được Lãnh đạo Phòng/Ban giao việc
                </p>
              )}
          </div>

          {!this.props.status && (
            <div id="groupButton" className="buttton-action-footer">
              {this.props.workAssignInformations && (
                <Button
                  onClick={this.handleClickPopupDelete}
                  className="pull-left btn-cancel btn-cancel-steering"
                  disabled={isLoadingAll}
                  loading={
                    this.state.isLoading &&
                    this.state.isLoading.isLoadingRemove === true
                  }
                >
                  <span className="text-content">HỦY GIAO VIỆC</span>
                  <span className=" pt-icon pt-icon-cross"></span>
                </Button>
              )}
              <Button
                className="btn-cancel"
                onClick={this.props.onClickGoBack}
                disabled={isLoadingAll}
              >
                <span className=" pt-icon icon-back"></span>
                <span className="text-content">Quay lại</span>
              </Button>
              <Button
                className="btn-blue-color"
                onClick={this.handleSubmit}
                loading={
                  this.state.isLoading &&
                  this.state.isLoading.isLoadingSubmit === true
                }
                disabled={
                  (this.state.isLoading && isLoadingAll) ||
                  (this.props.workAssignInformations &&
                    this.props.workAssignInformations.daCoBaoCao === true) ||
                  (this.props.workAssignInformations &&
                    this.props.workAssignInformations.daCoPhongBanThucHien ===
                      true)
                }
              >
                <span className="text-content">Lưu</span>
                <span className="pt-icon icon-save"></span>
              </Button>
            </div>
          )}

          {!this.props.status && (
            <div className="buttton-actionfooter-fixtop buttton-action-footer">
              <Button
                className="pt-button btn-blue-color"
                onClick={this.handleSubmit}
                loading={
                  this.state.isLoading &&
                  this.state.isLoading.isLoadingSubmit === true
                }
                disabled={
                  (this.state.isLoading && isLoadingAll) ||
                  (this.props.workAssignInformations &&
                    this.props.workAssignInformations.daCoBaoCao === true) ||
                  (this.props.workAssignInformations &&
                    this.props.workAssignInformations.daCoPhongBanThucHien ===
                      true)
                }
              >
                <span className="pt-icon icon-save"></span>
                <span className="text-content">Lưu</span>
              </Button>
              <Button
                className="pt-button btn-cancel"
                onClick={this.props.onClickGoBack}
                disabled={this.state.isLoading && isLoadingAll}
              >
                <span className="pt-icon icon-back"></span>
                <span className="text-content">Quay lại</span>
              </Button>
            </div>
          )}

          {this.props.status && (
            <div id="groupButton" className="buttton-action-footer">
              <Button
                className="btn-cancel"
                onClick={this.props.onClickGoBack}
                disabled={isLoadingAll}
              >
                <span className=" pt-icon icon-back"></span>
                <span className="text-content">Quay lại</span>
              </Button>
              <Button
                className="btn-blue-color"
                onClick={this.handleClickPopupDelete}
                loading={
                  this.state.isLoading &&
                  this.state.isLoading.isLoadingSubmit === true
                }
                disabled={this.state.isLoading && isLoadingAll}
              >
                <span className="text-content">Giao lại</span>
                <span className="pt-icon icon-save"></span>
              </Button>
            </div>
          )}

          {this.props.status && (
            <div className="buttton-actionfooter-fixtop buttton-action-footer">
              <Button
                className="pt-button btn-blue-color"
                onClick={this.handleClickPopupDelete}
                loading={
                  this.state.isLoading &&
                  this.state.isLoading.isLoadingSubmit === true
                }
                disabled={this.state.isLoading && isLoadingAll}
              >
                <span className="pt-icon icon-save"></span>
                <span className="text-content">Lưu</span>
              </Button>
              <Button
                className="pt-button btn-cancel"
                onClick={this.props.onClickGoBack}
                disabled={this.state.isLoading && isLoadingAll}
              >
                <span className="pt-icon icon-back"></span>
                <span className="text-content">Quay lại</span>
              </Button>
            </div>
          )}

          {/* Hủy gửi giao việc, trở về trang chi tiết */}
          {/*<PopupConfirm
            isOpen={this.state.isOpenPopup}
            onClose={this.handleClickPopup}
            title={POPUP.HUY_GUI_GIAO_VIEC}
            text={POPUP.TEXT_BUTTON_SUBMIT}
            onSubmit={this.props.onClickGoBack}
          >
            {POPUP.TEXT_BODY}
          </PopupConfirm>*/}

          {/* Hủy giao việc */}
          <PopupConfirm
            isOpen={this.state.isOpenPopupDelete}
            onClose={this.handleClickPopupDelete}
            title={
              !this.props.status ? POPUP.HUY_GIAO_VIEC : POPUP.HUY_GIAO_VIEC_LAI
            }
            text={POPUP.TEXT_BUTTON_SUBMIT}
            onSubmit={
              !this.props.status
                ? this.handleSubmitRemoveItem
                : this.handleSubmitReWorking
            }
          >
            {!this.props.status
              ? POPUP.TEXT_SUBMIT_BODY
              : POPUP.TEXT_SUBMIT_BODY_ALERTMISTAKE_WORK}
          </PopupConfirm>
        </div>
      </div>
    )
  }
}

export default Assign
