import React, { Component } from 'react'
import {
  Button,
  Collapse,
  //Checkbox,
  Dialog,
} from '@blueprintjs/core'
import PropTypes from 'prop-types'
import { Redirect } from 'react-router'
import {
  CheckboxGroup,
  ListUnitRadioGroupTwoColumns,
  CheckboxGroupTwoColums,
  // PopupConfirm
} from '../common/'
import withRouter from 'react-router/withRouter'
import classnames from 'classnames'
// import * as POPUP from '../../constants/Popup';
import * as MESSAGE from '../../constants/MessageForm'
import * as Tool from '../../helpers'
import * as Types from '../../constants/Api'
import { Async } from 'react-select'
import { decamelizeKeys } from 'humps'
import { PhieuYKienChon, PrevewFileTrinhKy } from '../SignNumber'
// import { Authorization, Permission } from '../auth'
import { get, isEmpty, size } from 'lodash'

const signNumberType = {
  KYSODI: 'kySoDi',
  KYSONOIBO: 'kySoNoiBo',
}
class InputForm extends Component {
  referFile
  attachFile

  constructor(props) {
    super(props)
    this.handleInputChange = this.handleInputChange.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)

    this.handleClickCancel = this.handleClickCancel.bind(this)
    // this.handleClickPopup = this.handleClickPopup.bind(this);
    this.handleCheckCompositiveDepartments =
      this.handleCheckCompositiveDepartments.bind(this)
    this.handleCheckParentCompositiveDepartments =
      this.handleCheckParentCompositiveDepartments.bind(this)
    //this.handleCheckAllCompositiveDepartments = this.handleCheckAllCompositiveDepartments.bind(this);
    this.handleMoveToDepartmentSelection =
      this.handleMoveToDepartmentSelection.bind(this)
    // this.handleCheckAllEqualUnits = this.handleCheckAllEqualUnits.bind(this);
    // this.handleCheckEqualUnits = this.handleCheckEqualUnits.bind(this);
    this.handleCheckDepartmentOtherUnits =
      this.handleCheckDepartmentOtherUnits.bind(this)
    this.checkCompositiveDepartmentUnit =
      this.checkCompositiveDepartmentUnit.bind(this)
    this.checkReceivedDepartmentUnit =
      this.checkReceivedDepartmentUnit.bind(this)
    this.handleToogleOtherUnits = this.handleToogleOtherUnits.bind(this)
    this.handleGetValueGridCheckBoxCompositive =
      this.handleGetValueGridCheckBoxCompositive.bind(this)
    this.handleGetValueGridCheckBoxParentCompositive =
      this.handleGetValueGridCheckBoxParentCompositive.bind(this)
    this.handleToggleCheckboxParent = this.handleToggleCheckboxParent.bind(this)
    //this.handleScroll = this.handleScroll.bind(this);
    this.refFile = React.createRef()
  }

  static propTypes = {
    departments: PropTypes.array.isRequired,
    leaderCombinations: PropTypes.array.isRequired,
    departmentSigned: PropTypes.array.isRequired,
    unitsEqual: PropTypes.array.isRequired,
    unitsLeader: PropTypes.array.isRequired,
    cityName: PropTypes.string,
    onSubmit: PropTypes.func.isRequired,
    mainDepartmentId: PropTypes.string.isRequired,
    signNumber: PropTypes.object,
    isUpdate: PropTypes.bool.isRequired,
    isLoadingData: PropTypes.object.isRequired,
    isSubmitFail: PropTypes.bool.isRequired,
    type: PropTypes.string.isRequired,
    parentUnitId: PropTypes.string,
    parentDepartments: PropTypes.array,
    parentUnitsLeaders: PropTypes.array,
    handlePreview: PropTypes.func,
    fileTrinhKy: PropTypes.object,
  }

  state = {
    attachQty: [''],
    fileDinhKemQty: [''],
    form: {
      soKyHieu: '',
      tenThanhPho: '',
      trichYeu: '',
      lanhDaoPhongBanChuTriId: '',
      lanhDaoDonViId: '',
      lanhDaoDonViPhoiHopId: '',
      lanhDaoDonViCapTrenId: '',
      noiDungTrinh: '',
      guiBanGiay: false,
      fileNoiDung: {},
      fileThamKhao: [],
      dsFileDinhKem: [],
      vanBanGocId: '',
    },
    dinhKemFiles: [],
    isCheckedParentUnit: false,
    isHasSignParentUnit: false,
    isHasReceivedParentDepartment: false,
    attachFiles: [],
    fileThamKhaoTuCongViecPb: [],
    fileDinhKemTuCongViecPb: [],
    contentFiles: [],
    phoiHopIds: [],
    lanhDaoPhoiHopIds: [],
    phoiHopCapTrenIds: [],
    chuyenDenIds: [],
    lanhDaoKyPhoiHopIds: [],
    // donViCungKyIds: [],
    chuyenDenPhongBanCapTrenIds: [],
    chuyenDenPhongBanDonViKhacIds: [],
    dsDonViCungKy: [],
    dsDonViCungKyIds: [],
    departmentSelections: [],
    receiveDepartmentSelections: [],
    parentDepartmentSelections: [],
    unitsEqualSelections: [],
    lanhDaoKyPhoiHopSelections: [],
    isCheckedAllCompositive: false,
    isCheckedAllUnitsEqual: false,
    isOpenPopup: false,
    isRedirect: false,
    redirectTo: null,
    error: {},
    errorsAttachFiles: [],
    errorsDinhKemFiles: [],
    contentFileName: '',
    attachFileNames: [],
    dinhKemFileNames: [],
    sizeContentFile: null,
    sizeAttachedFiles: [],
    sizeDinhKemFiles: [],
    isLoading: false,
    isCanDelete: true,
    isCanUpdate: true,
    isCanUpdateFile: true,
    isCanUpdateLeaderDepartment: true,
    isCanUpdateCompositive: true,
    isCanUpdateCompositiveElectric: true,
    isCanUpdateParentCompositive: true,
    isCanUpdateLeaderUnit: true,
    isCanUpdateOtherLeaderUnit: true,
    isCanUpdateParentLeaderUnit: true,
    disabledList: this.props.mainDepartmentId
      ? new Array(this.props.mainDepartmentId)
      : [],
    leaderUnitDisabledList: [],
    parentLeaderUnitDisabledList: [],
    selectedDepartmentOtherUnits: {}, // Luu phong ban don vi khac theo donViId
    //isHideButton: false,
    // isChooseReceivedDepartment: false,
    isChooseDepartmentOtherUnits: false,
    compositiveCheckedDataTypeList: [],
    receivedCheckedDataTypeList: [],
    dialogModel: {
      isOpen: false,
      title: 'Chọn phiếu ý kiến',
    },
    yeuCauLanhDaoKyPhoiHop: false,
    isOpenDialog: false,
    isPreviewLoading: false,
  }

  componentDidMount() {
    let state = this.state

    if (
      this.props.departments &&
      this.props.isCanGetLeaderDepartment !== undefined
    ) {
      const departmentSelections = []
      const receiveDepartmentSelections = []
      const lanhDaoKyPhoiHopSelections = []

      if (this.props.type === signNumberType.KYSODI) {
        this.props.leaderCombinations.forEach(item => {
          let data = {
            content: item.maPhongBan,
            value: item.phongBanId,
            isCanGetLeaderDepartment: this.props.isCanGetLeaderDepartment,
            isCanShowCompositive:
              this.props.isCanGetLeaderDepartment ||
              item.phongBanLanhDao === false,
          }
          lanhDaoKyPhoiHopSelections.push(data)
        })
      }

      this.props.departments.forEach(item => {
        let data = {
          content: item.maPhongBan,
          value: item.phongBanId,
          phongBanLanhDao: item.phongBanLanhDao,
          isCanGetLeaderDepartment: this.props.isCanGetLeaderDepartment,
          isCanShowCompositive:
            this.props.isCanGetLeaderDepartment ||
            item.phongBanLanhDao === false,
        }
        if (this.props.type === signNumberType.KYSONOIBO) {
          data = {
            ...data,
            codeSignNumber: item.maKySo,
          }
        }
        receiveDepartmentSelections.push(data)
        if (
          (this.props.isCanGetLeaderDepartment === false &&
            item.phongBanLanhDao === false) ||
          this.props.isCanGetLeaderDepartment
        ) {
          departmentSelections.push(data)
        }
      })
      state.departmentSelections = departmentSelections
      state.lanhDaoKyPhoiHopSelections = lanhDaoKyPhoiHopSelections
      state.receiveDepartmentSelections = receiveDepartmentSelections
    }

    if (this.props.parentDepartments) {
      const parentDepartmentSelections = []
      this.props.parentDepartments.forEach((item, i) => {
        let data = {
          content: item.maPhongBan,
          value: item.phongBanId,
          phongBanLanhDao: item.phongBanLanhDao,
          isCanGetLeaderDepartment: this.props.isCanGetLeaderDepartment,
          isCanShowCompositive:
            this.props.isCanGetLeaderDepartment ||
            item.phongBanLanhDao === false,
        }
        if (this.props.type === signNumberType.KYSONOIBO) {
          data = {
            ...data,
            codeSignNumber: `ct_${item.maKySo}`,
          }
        }
        parentDepartmentSelections.push(data)
      })
      state.parentDepartmentSelections = parentDepartmentSelections
    }

    if (this.props.unitsEqual) {
      const unitsEqualSelections = []
      this.props.unitsEqual.forEach((item, i) => {
        unitsEqualSelections.push({
          content: item.tenVietTat,
          value: item.donViId,
        })
      })
      state.unitsEqualSelections = unitsEqualSelections
    }

    if (this.props.cityName) {
      state.form.tenThanhPho = this.props.cityName
    }

    if (this.props.isSubmitFail === true) {
      state.isLoading = false
    }

    this.setState(state)
  }

  componentWillReceiveProps(nextProps) {
    let state = this.state
    if (
      nextProps.departments &&
      nextProps.departments !== this.props.departments &&
      nextProps.isCanGetLeaderDepartment !== undefined
    ) {
      const departmentSelections = []
      const receiveDepartmentSelections = []
      nextProps.departments.forEach(item => {
        let data = {
          content: item.maPhongBan,
          value: item.phongBanId,
          phongBanLanhDao: item.phongBanLanhDao,
          isCanGetLeaderDepartment: nextProps.isCanGetLeaderDepartment,
          isCanShowCompositive:
            nextProps.isCanGetLeaderDepartment ||
            item.phongBanLanhDao === false,
        }
        if (nextProps.type === signNumberType.KYSONOIBO) {
          data = {
            ...data,
            codeSignNumber: item.maKySo,
          }
        }
        receiveDepartmentSelections.push(data)
        if (
          (nextProps.isCanGetLeaderDepartment === false &&
            item.phongBanLanhDao === false) ||
          nextProps.isCanGetLeaderDepartment
        ) {
          departmentSelections.push(data)
        }
      })
      state.departmentSelections = departmentSelections
      state.receiveDepartmentSelections = receiveDepartmentSelections
    }

    if (
      nextProps.leaderCombinations &&
      nextProps.leaderCombinations !== this.props.leaderCombinations
    ) {
      const lanhDaoKyPhoiHopSelections = []
      nextProps.leaderCombinations.forEach(item => {
        let data = {
          content: item.maPhongBan,
          value: item.phongBanId,
          isCanGetLeaderDepartment: this.props.isCanGetLeaderDepartment,
          isCanShowCompositive:
            this.props.isCanGetLeaderDepartment ||
            item.phongBanLanhDao === false,
        }
        lanhDaoKyPhoiHopSelections.push(data)
      })

      state.lanhDaoKyPhoiHopSelections = lanhDaoKyPhoiHopSelections
    }

    if (
      nextProps.parentDepartments &&
      nextProps.parentDepartments !== this.props.parentDepartments
    ) {
      const parentDepartmentSelections = []
      nextProps.parentDepartments.forEach((item, i) => {
        let data = {
          content: item.maPhongBan,
          value: item.phongBanId,
          phongBanLanhDao: item.phongBanLanhDao,
          isCanGetLeaderDepartment: nextProps.isCanGetLeaderDepartment,
          isCanShowCompositive:
            nextProps.isCanGetLeaderDepartment ||
            item.phongBanLanhDao === false,
        }
        if (nextProps.type === signNumberType.KYSONOIBO) {
          data = {
            ...data,
            codeSignNumber: `ct_${item.maKySo}`,
          }
        }
        parentDepartmentSelections.push(data)
      })
      state.parentDepartmentSelections = parentDepartmentSelections
    }

    if (
      nextProps.unitsEqual &&
      nextProps.unitsEqual !== this.props.unitsEqual
    ) {
      const unitsEqualSelections = []
      nextProps.unitsEqual.forEach((item, i) => {
        unitsEqualSelections.push({
          content: item.tenVietTat,
          value: item.donViId,
        })
      })
      state.unitsEqualSelections = unitsEqualSelections
    }

    if (
      nextProps.departmentSigned &&
      nextProps.departmentSigned.length &&
      nextProps.departmentSigned !== this.props.departmentSigned &&
      !this.props.isUpdate
    ) {
      const phongBanLanhDao = nextProps.departmentSigned.find(
        f => f?.phongBan?.phongBanLanhDao
      )
      if (phongBanLanhDao?.chucDanhId) {
        state.form.lanhDaoPhongBanChuTriId = phongBanLanhDao.chucDanhId
      } else {
        state.form.lanhDaoPhongBanChuTriId =
          nextProps.departmentSigned[0].chucDanhId
      }
    }

    // if (nextProps.unitsLeader.length && nextProps.location.pathname && nextProps.location.pathname === '/ky-so/cong-van-di/them-moi') {
    //   state.form.lanhDaoDonViId = nextProps.unitsLeader[0].chucDanhId;
    // }

    if (nextProps.cityName && nextProps.cityName !== this.props.cityName) {
      state.form.tenThanhPho = nextProps.cityName
    }

    if (nextProps.isSubmitFail === true) {
      state.isLoading = false
    }

    this.setState(state)
  }

  handleFileInput(i, e) {
    const [name, id] = e.target.name.split('-')
    let state = this.state
    if (name === 'dinhKemFiles') {
      state.errorsDinhKemFiles.splice(i, 1)

      let reader = new FileReader()
      let file = e.target.files[0]

      const attachFiles = e.target.value
      const extension =
        attachFiles &&
        attachFiles
          .slice(((attachFiles.lastIndexOf('.') - 1) >>> 0) + 2)
          .toLowerCase()
      let sizeAttachedFile = file && file.size

      const extensionSupportArray = [
        'pdf',
        'doc',
        'docx',
        'xls',
        'xlsx',
        'ppt',
        'pptx',
        'ppsx',
        'pps',
      ]
      if (extensionSupportArray.indexOf(extension) === -1) {
        state.errorsDinhKemFiles[i] =
          "Hãy chọn file có định dạng '.pdf', '.doc', '.docx', '.xls', '.xlsx', '.ppt', '.pptx', '.ppsx', '.pps'."
      } else if (sizeAttachedFile && sizeAttachedFile > 1024 * 1024 * 25) {
        state.errorsDinhKemFiles[i] = MESSAGE.TEXT_MAXIMUM_SIZE_FILE
      }

      this.setState(state)

      if (!state.errorsDinhKemFiles[i]) {
        reader.onloadend = () => {
          state[name][id] = file
          state.sizeDinhKemFiles[i] = file.size
          state.dinhKemFileNames[i] = file.name
          state.form.dsFileDinhKem[i] = null
          state.fileDinhKemTuCongViecPb[i] = null
          this.setState(state)
        }
      }

      if (file) {
        reader.readAsDataURL(file)
      }
    } else {
      state.errorsAttachFiles.splice(i, 1)

      let reader = new FileReader()
      let file = e.target.files[0]

      const attachFiles = e.target.value
      const extension =
        attachFiles &&
        attachFiles
          .slice(((attachFiles.lastIndexOf('.') - 1) >>> 0) + 2)
          .toLowerCase()
      let sizeAttachedFile = file && file.size

      const extensionSupportArray = [
        'pdf',
        'doc',
        'docx',
        'xls',
        'xlsx',
        'ppt',
        'pptx',
        'ppsx',
        'pps',
      ]
      if (extensionSupportArray.indexOf(extension) === -1) {
        state.errorsAttachFiles[i] =
          "Hãy chọn file có định dạng '.pdf', '.doc', '.docx', '.xls', '.xlsx', '.ppt', '.pptx', '.ppsx', '.pps'."
      } else if (sizeAttachedFile && sizeAttachedFile > 1024 * 1024 * 25) {
        state.errorsAttachFiles[i] = MESSAGE.TEXT_MAXIMUM_SIZE_FILE
      }

      this.setState(state)

      if (!state.errorsAttachFiles[i]) {
        reader.onloadend = () => {
          state[name][id] = file
          state.sizeAttachedFiles[i] = file.size
          state.attachFileNames[i] = file.name
          state.form.fileThamKhao[i] = null
          state.fileThamKhaoTuCongViecPb[i] = null
          this.setState(state)
        }
      }

      if (file) {
        reader.readAsDataURL(file)
      }
    }
  }

  handleFileInputContentFiles(e) {
    const [name, id] = e.target.name.split('-')
    let state = this.state
    delete state.error.contentFile
    let reader = new FileReader()
    let file = e.target.files && e.target.files[0]
    const contentFiles = e.target.value
    const extension =
      contentFiles &&
      contentFiles
        .slice(((contentFiles.lastIndexOf('.') - 1) >>> 0) + 2)
        .toLowerCase()
    let sizeContentFile = file && file.size
    if (!contentFiles && state.contentFileName.length === 0) {
      state.error.contentFile = MESSAGE.TEXT_MESSAGE_DEFAULT
    } else if (extension && extension !== 'pdf') {
      state.error.contentFile = MESSAGE.TEXT_TYPE_FILE_UPLOAD_SIGNNUMBER
    } else if (
      sizeContentFile &&
      sizeContentFile > Types.MAX_SIZE_FILE_SUBMISSION
    ) {
      state.error.contentFile = MESSAGE.TEXT_MAXIMUM_SIZE_FILE_SUBMISSION
    }

    if (state.error.contentFile && this.props.isUpdate) {
      state.isCanUpdateLeaderDepartment = false
      state.isCanUpdateCompositive = false
      state.isCanUpdateCompositiveElectric = false
      state.isCanUpdateParentCompositive = false
      state.isCanUpdateLeaderUnit = false
      state.isCanUpdateOtherLeaderUnit = false
      state.isCanUpdateParentLeaderUnit = false
    }

    this.setState(state)
    if (!state.error.contentFile) {
      reader.onloadend = () => {
        state[name][id] = file
        state.sizeContentFile = file.size
        state.contentFileName = file.name
        state.isCanUpdateLeaderDepartment = true
        state.isCanUpdateCompositive = true
        state.isCanUpdateCompositiveElectric = true
        state.isCanUpdateParentCompositive = true
        state.isCanUpdateLeaderUnit = true
        state.isCanUpdateOtherLeaderUnit = true
        state.isCanUpdateParentLeaderUnit = true
        delete state.form.fileNoiDung
        this.setState(state)
      }
    }

    if (file) {
      reader.readAsDataURL(file)
    }
  }

  handleChangeFile = e => {
    const fileValue = e.target.files
    if (size(fileValue) === 0) {
      return false
    }

    if (get(this.refFile, 'current')) {
      this.refFile.current.value = ''
    }
  }

  handleInputChange(e) {
    const target = e.target
    const value = target.type === 'checkbox' ? target.checked : target.value
    const name = target.name
    let state = this.state
    state.form[name] = value
    delete state.error[name]

    if (name === 'lanhDaoDonViId') {
      if (this.props.departments) {
        const lanhDaoDonVi =
          this.props.unitsLeader &&
          this.props.unitsLeader.find(item => item.chucDanhId === value)
        const phongBanId =
          lanhDaoDonVi &&
          lanhDaoDonVi.phongBan &&
          lanhDaoDonVi &&
          lanhDaoDonVi.phongBan.phongBanId
        if (lanhDaoDonVi && lanhDaoDonVi.yeuCauLanhDaoKyPhoiHop) {
          state.yeuCauLanhDaoKyPhoiHop = true
        } else {
          state.yeuCauLanhDaoKyPhoiHop = false
          state.lanhDaoKyPhoiHopIds = []
        }
        state.leaderUnitDisabledList = [phongBanId]
        state.phoiHopIds =
          state.phoiHopIds && state.phoiHopIds.filter(s => s !== phongBanId)
        state.lanhDaoPhoiHopIds =
          state.lanhDaoPhoiHopIds &&
          state.lanhDaoPhoiHopIds.filter(s => s !== phongBanId)
        state.chuyenDenIds =
          state.chuyenDenIds && state.chuyenDenIds.filter(s => s !== phongBanId)
      }
    }
    if (name === 'lanhDaoDonViCapTrenId') {
      if (this.props.departments) {
        const lanhDaoDonViCapTren =
          this.props.parentUnitsLeaders &&
          this.props.parentUnitsLeaders.find(item => item.chucDanhId === value)
        const phongBanId =
          lanhDaoDonViCapTren &&
          lanhDaoDonViCapTren.phongBan &&
          lanhDaoDonViCapTren &&
          lanhDaoDonViCapTren.phongBan.phongBanId
        state.parentLeaderUnitDisabledList = [phongBanId]
        state.phoiHopCapTrenIds =
          state.phoiHopCapTrenIds &&
          state.phoiHopCapTrenIds.filter(s => s !== phongBanId)
        state.chuyenDenPhongBanCapTrenIds =
          state.chuyenDenPhongBanCapTrenIds &&
          state.chuyenDenPhongBanCapTrenIds.filter(s => s !== phongBanId)
        state.isHasSignParentUnit = value !== ''
      }
    }
    this.setState(state)
  }

  handleToggleCheckboxParent() {
    let state = this.state
    state.isCheckedParentUnit = !state.isCheckedParentUnit
    // Khong reset lanh dao cap tren khi dong collapse
    // if (!state.isCheckedParentUnit) {
    //   state.isHasSignParentUnit = false;
    //   state.isHasReceivedParentDepartment = false;
    //   state.form.lanhDaoDonViCapTrenId = '';
    //   state.phoiHopCapTrenIds = [];
    //   state.chuyenDenPhongBanCapTrenIds = [];
    // }
    this.setState(state)
  }

  // handleClickPopup = () => {
  //   this.setState({
  //     isOpenPopup: !this.state.isOpenPopup
  //   });
  // }

  handleClickCancel = () => {
    let redirectTo = '/ky-so/cong-van-di/danh-sach'
    if (this.props.type === signNumberType.KYSONOIBO) {
      redirectTo = '/ky-so/cong-van-noi-bo/danh-sach'
    }

    this.setState({
      isRedirect: true,
      redirectTo: redirectTo,
    })
  }

  // handleCheckAllCompositiveDepartments = () => {
  //   this.setState({isCheckedAllCompositive: !this.state.isCheckedAllCompositive});
  //   let phoiHopIds = this.state.phoiHopIds;
  //   if (!this.state.isCheckedAllCompositive) {
  //     this.state.departmentSelections.forEach(item => {
  //       if (phoiHopIds.indexOf(item.value) === -1 && this.props.mainDepartmentId !== item.value) {
  //         phoiHopIds.push(item.value);
  //       }
  //     });
  //   } else {
  //     phoiHopIds = [];
  //   }
  //   this.setState({phoiHopIds});
  // }

  handleCheckCompositiveDepartments = e => {
    const newSelection = e.target.value
    let name = 'phoiHopIds'
    if (this.props.departments) {
      const phongBan = this.props.departments.find(
        item => item.phongBanId === e.target.value
      )
      if (phongBan && phongBan.phongBanLanhDao === true) {
        name = 'lanhDaoPhoiHopIds'
      }
    }
    if (e.target.name === 'lanhDaoKyPhoiHop') {
      name = 'lanhDaoKyPhoiHopIds'
    }
    let data = this.state[name]
    let listSelected = []
    if (data && data.length > 0 && data.indexOf(newSelection) > -1) {
      listSelected = data.filter(s => s !== newSelection)
    } else {
      listSelected = [...data, newSelection]
    }
    if (listSelected.length === data.length) {
      this.setState({ isCheckedAllCompositive: true })
    }
    this.setState({ [name]: listSelected })
  }

  handleCheckParentCompositiveDepartments = e => {
    const newSelection = e.target.value
    let phoiHopCapTrenIds
    if (
      this.state.phoiHopCapTrenIds.length > 0 &&
      this.state.phoiHopCapTrenIds.indexOf(newSelection) > -1
    ) {
      phoiHopCapTrenIds = this.state.phoiHopCapTrenIds.filter(
        s => s !== newSelection
      )
    } else {
      phoiHopCapTrenIds = [...this.state.phoiHopCapTrenIds, newSelection]
    }
    this.setState({ phoiHopCapTrenIds })
  }

  handleMoveToDepartmentSelection = e => {
    const newSelection = e.target.value
    let chuyenDenIds
    if (
      this.state.chuyenDenIds.length > 0 &&
      this.state.chuyenDenIds.indexOf(newSelection) > -1
    ) {
      chuyenDenIds = this.state.chuyenDenIds.filter(s => s !== newSelection)
    } else {
      chuyenDenIds = [...this.state.chuyenDenIds, newSelection]
    }
    this.setState({ chuyenDenIds })
  }

  // handleCheckAllEqualUnits = () => {
  //   this.setState({isCheckedAllUnitsEqual: !this.state.isCheckedAllUnitsEqual});
  //   let donViCungKyIds = this.state.donViCungKyIds;
  //   if (!this.state.isCheckedAllUnitsEqual) {
  //     this.state.unitsEqualSelections.forEach(item => {
  //       if (donViCungKyIds.indexOf(item.value) === -1) {
  //         donViCungKyIds.push(item.value);
  //       }
  //     });
  //   } else {
  //     donViCungKyIds = [];
  //   }
  //   this.setState({donViCungKyIds});
  // }

  // handleCheckEqualUnits = (e) => {
  //   const newSelection = e.target.value;
  //   let donViCungKyIds;
  //   if (this.state.donViCungKyIds.length > 0 && this.state.donViCungKyIds.indexOf(newSelection) > -1) {
  //     donViCungKyIds = this.state.donViCungKyIds.filter(s => s !== newSelection);
  //   } else {
  //     donViCungKyIds = [...this.state.donViCungKyIds, newSelection];
  //   }
  //   if (donViCungKyIds.length === this.state.donViCungKyIds.length) {
  //     this.setState({isCheckedAllUnitsEqual: true});
  //   }
  //   this.setState({donViCungKyIds});
  // }

  handleEventAddFile = (isFileThamKhao = false) => {
    if (isFileThamKhao) {
      this.setState({
        fileDinhKemQty: this.state.fileDinhKemQty.concat(
          this.state.fileDinhKemQty.length + 1
        ),
      })
    } else {
      this.setState({
        attachQty: this.state.attachQty.concat(this.state.attachQty.length + 1),
      })
    }
  }

  handleEventRemoveFile = (i, isFileDinhKem = false) => {
    const state = this.state
    if (isFileDinhKem) {
      if (i === 0 && state.fileDinhKemQty.length === 1) {
        state.fileDinhKemQty[i] = ''
      } else {
        state.fileDinhKemQty.splice(i, 1)
      }
      state.dinhKemFiles.splice(i, 1)
      state.errorsDinhKemFiles.splice(i, 1)
      state.form.dsFileDinhKem.splice(i, 1)
      state.dinhKemFileNames.splice(i, 1)
      state.sizeDinhKemFiles.splice(i, 1)
      state.fileDinhKemTuCongViecPb.splice(i, 1)
      this.setState(state)
    } else {
      if (i === 0 && state.attachQty.length === 1) {
        state.attachQty[i] = ''
      } else {
        state.attachQty.splice(i, 1)
      }
      state.attachFiles.splice(i, 1)
      state.errorsAttachFiles.splice(i, 1)
      state.form.fileThamKhao.splice(i, 1)
      state.attachFileNames.splice(i, 1)
      state.sizeAttachedFiles.splice(i, 1)
      state.fileThamKhaoTuCongViecPb.splice(i, 1)
      this.setState(state)
    }
  }

  checkFormValidate = () => {
    const error = {}
    let sizeContentFile = this.state.sizeContentFile
    let sizeAttachedFiles = this.state.sizeAttachedFiles
    if (!this.state.form.soKyHieu || this.state.form.soKyHieu.length === 0) {
      error.soKyHieu = MESSAGE.TEXT_MESSAGE_DEFAULT
    }

    if (
      this.props.type === signNumberType.KYSODI &&
      this.state.form.soKyHieu &&
      (this.state.form.soKyHieu.indexOf('_') > -1 ||
        this.state.form.soKyHieu.indexOf('/') > -1)
    ) {
      error.soKyHieu = MESSAGE.MESSAGE_INVALID_SIGN_FIELD
    }

    if (!this.state.form.trichYeu || this.state.form.trichYeu.length === 0) {
      error.trichYeu = MESSAGE.TEXT_MESSAGE_DEFAULT
    }

    if (
      this.props.type === signNumberType.KYSODI ||
      this.state.isHasSignParentUnit === true ||
      (this.state.form.lanhDaoDonViPhoiHopId &&
        this.state.form.lanhDaoDonViPhoiHopId.trim().length > 0)
    ) {
      if (
        !this.state.form.lanhDaoDonViId ||
        this.state.form.lanhDaoDonViId.length === 0
      ) {
        error.lanhDaoDonViId = MESSAGE.TEXT_MESSAGE_DEFAULT
      }
    }

    if (
      !this.state.contentFileName ||
      this.state.contentFileName.length === 0
    ) {
      error.contentFile = MESSAGE.TEXT_MESSAGE_DEFAULT
      sizeContentFile = null
    }

    this.setState({
      error,
      sizeContentFile,
      sizeAttachedFiles,
    })
    if (Object.keys(error).length !== 0) {
      this.setState(
        {
          isLoading: false,
          error,
        },
        () => {
          this.props.onSubmit && this.props.onSubmit({}, false)
          return false
        }
      )
    } else {
      return true
    }
  }

  convertFormData = () => {
    const {
      phoiHopIds,
      lanhDaoPhoiHopIds,
      lanhDaoKyPhoiHopIds,
      chuyenDenIds,
      phoiHopCapTrenIds,
      chuyenDenPhongBanCapTrenIds,
      chuyenDenPhongBanDonViKhacIds,
      dsDonViCungKy,
      // donViCungKyIds,
      contentFiles,
      form,
    } = this.state
    let formData = {}
    const files = [...this.state.attachFiles]
    let dinhKemFiles = [...this.state.dinhKemFiles]
    let fileThamKhaoTuCongViecPb = decamelizeKeys(
      this.state.fileThamKhaoTuCongViecPb
        .filter(item => !!item)
        .map(item => item.value)
    )
    let fileDinhKemTuCongViecPb = decamelizeKeys(
      this.state.fileDinhKemTuCongViecPb
        .filter(item => !!item)
        .map(item => item.value)
    )
    formData = {
      ...form,
      phoiHopIds: phoiHopIds,
      lanhDaoPhoiHopIds: lanhDaoPhoiHopIds,
      lanhDaoKyPhoiHopIds: lanhDaoKyPhoiHopIds,
      // donViCungKyIds: donViCungKyIds,
      chuyenDenIds: chuyenDenIds,
      phoiHopCapTrenIds: phoiHopCapTrenIds,
      chuyenDenPhongBanCapTrenIds: chuyenDenPhongBanCapTrenIds,
      chuyenDenPhongBanDonViKhacIds: chuyenDenPhongBanDonViKhacIds,
      dsDonViCungKy: dsDonViCungKy,
      files: {
        attachFiles: files,
        fileThamKhaoTuCongViecPb,
        dinhKemFiles,
        fileDinhKemTuCongViecPb,
        contentFiles: contentFiles,
      },
    }

    return formData
  }

  handleSubmit = async e => {
    e.preventDefault()
    if (this.state.isLoading === true) {
      return
    }

    await this.setState({ isLoading: true })
    const isCheckForm = this.checkFormValidate()
    if (!isCheckForm) {
      return
    }

    this.setState({ isLoading: true, errorsAttachFiles: [] })
    let formData = null
    formData = this.convertFormData()
    this.props.onSubmit &&
      this.props.onSubmit(formData, true, null, this.props.isUpdate)
  }

  handlePreview = async () => {
    const { isOpenDialog } = this.state
    let newOpenDialog = !isOpenDialog
    if (!isOpenDialog) {
      const isCheckForm = this.checkFormValidate()
      if (!isCheckForm) {
        return
      }

      this.setState({
        isPreviewLoading: true,
      })
      let formData = null
      formData = this.convertFormData()
      if (this.props) {
        const response = await this.props.handlePreview(formData)
        if (response) {
          this.setState({
            isPreviewLoading: false,
          })
        }
      }
    }

    this.setState({
      isOpenDialog: newOpenDialog,
    })
  }

  componentDidUpdate(prevProps, prevState) {
    // if (prevState.phoiHopIds !== this.state.phoiHopIds) {
    //   if (this.state.phoiHopIds.length + 1 === this.state.departmentSelections.length) {
    //     this.setState({isCheckedAllCompositive: true});
    //   } else {
    //     this.setState({isCheckedAllCompositive: false});
    //   }
    // }

    // if (prevState.donViCungKyIds !== this.state.donViCungKyIds) {
    //   if (this.state.donViCungKyIds.length === this.state.unitsEqualSelections.length) {
    //     this.setState({isCheckedAllUnitsEqual: true});
    //   } else {
    //     this.setState({isCheckedAllUnitsEqual: false});
    //   }
    // }

    if (
      this.props.isUpdate &&
      this.props.signNumber &&
      this.props.signNumber !== prevProps.signNumber
    ) {
      let state = this.state

      if (this.props.signNumber.tinhTrang) {
        let tinhTrang = this.props.signNumber.tinhTrang
        // LD PB CT da duyet
        if (tinhTrang >= Types.LEADER_DEPARTMENT) {
          state.isCanUpdateLeaderDepartment = false
        }
        // LD PH PH da duyet ->
        if (tinhTrang > Types.LEADER_DEPARTMENT) {
          state.isCanUpdateCompositive = false
        }

        if (
          this.props.signNumber.donViCungKy &&
          this.props.signNumber.donViCungKy.find(
            item => item.trangThai !== Types.NOT_YET_AGREE
          )
        ) {
          state.isCanUpdateCompositiveElectric = false
        }

        if (
          tinhTrang > Types.COMPOSITIVE_DEPARTMENT &&
          tinhTrang !== Types.COMPOSITIVE_DEPARTMENT_SIGNING &&
          tinhTrang !== Types.COMPOSITIVE_DEPARTMENT_ELECTRIC_UNIT_SIGNING &&
          tinhTrang !== Types.COMPOSITIVE_DEPARTMENT_ELECTRIC_UNIT &&
          this.props.signNumber.thuKy
        ) {
          state.isCanUpdateLeaderUnit = false
          state.isCanUpdateOtherLeaderUnit = false
        }

        if (
          tinhTrang > Types.SECRETARY &&
          tinhTrang !== Types.COMPOSITIVE_DEPARTMENT_SIGNING &&
          tinhTrang !== Types.COMPOSITIVE_DEPARTMENT_ELECTRIC_UNIT_SIGNING &&
          tinhTrang !== Types.COMPOSITIVE_DEPARTMENT_ELECTRIC_UNIT &&
          !this.props.signNumber.thuKy
        ) {
          state.isCanUpdateLeaderUnit = false
          state.isCanUpdateOtherLeaderUnit = false
        }

        if (
          tinhTrang >= Types.SECRETARY &&
          tinhTrang <= Types.RELEASED &&
          this.props.signNumber.lanhDaoDonViPhoiHop
        ) {
          state.isCanUpdate = false
        }

        if (tinhTrang <= Types.LEADER_UNIT) {
          if (
            this.props.signNumber.phoiHopCapTren &&
            this.props.signNumber.phoiHopCapTren.find(
              item => item.trangThai === Types.AGREED
            ) !== undefined
          ) {
            state.isCanUpdateParentCompositive = false
          }
        }

        if (
          (tinhTrang === Types.PARENT_SECRETARY &&
            this.props.signNumber.thuKyCapTren) ||
          tinhTrang === Types.PROGRESSION ||
          tinhTrang === Types.RELEASED
        ) {
          state.isCanUpdateParentLeaderUnit = false
        }

        if (
          tinhTrang > Types.LEADER_UNIT &&
          tinhTrang !== Types.COMPOSITIVE_DEPARTMENT_SIGNING
        ) {
          state.isCanUpdateParentCompositive = false
        }

        if (
          tinhTrang === Types.LEADER_UNIT ||
          tinhTrang === Types.PROGRESSION ||
          tinhTrang === Types.RELEASED
        ) {
          state.isCanUpdateFile = false
          state.isCanDelete = false
        }

        if (
          tinhTrang === Types.PROGRESSION ||
          tinhTrang === Types.RELEASED ||
          (this.props.type === signNumberType.KYSODI &&
            tinhTrang === Types.LEADER_UNIT)
        ) {
          state.isCanUpdate = false
        }
      }

      state.form.soKyHieu = this.props.signNumber.soKyHieu
      state.form.tenThanhPho = this.props.signNumber.tenThanhPho
      state.form.trichYeu = this.props.signNumber.trichYeu
      state.form.noiDungTrinh = this.props.signNumber.noiDungTrinh
        ? this.props.signNumber.noiDungTrinh
        : ''
      state.form.guiBanGiay = this.props.signNumber.guiBanGiay
      state.form.vanBanGocId = this.props.signNumber.vanBanGocId

      if (this.props.signNumber?.lanhDaoPhongBanChuTri) {
        state.form.lanhDaoPhongBanChuTriId =
          this.props.signNumber?.lanhDaoPhongBanChuTri?.chucDanhId
      }

      if (this.props.signNumber.lanhDaoDonVi) {
        state.form.lanhDaoDonViId =
          this.props.signNumber.lanhDaoDonVi.chucDanhId
        const lanhDaoDonVi =
          this.props.unitsLeader &&
          this.props.unitsLeader.find(
            item => item.chucDanhId === state.form.lanhDaoDonViId
          )
        const phongBanId =
          lanhDaoDonVi &&
          lanhDaoDonVi.phongBan &&
          lanhDaoDonVi.phongBan.phongBanId
        state.leaderUnitDisabledList = [phongBanId]

        if (
          this.props.signNumber.lanhDaoDonVi.yeuCauLanhDaoKyPhoiHop === true
        ) {
          state.yeuCauLanhDaoKyPhoiHop = true
        }
      }

      if (
        this.props.type === signNumberType.KYSODI &&
        this.props.signNumber.dsLanhDaoPhoiHop
      ) {
        let lanhDaoKyPhoiHopIds = []
        this.props.signNumber.dsLanhDaoPhoiHop.forEach(item => {
          if (item.phongBan) {
            lanhDaoKyPhoiHopIds.push(item.phongBan.phongBanId)
          }
        })
        state.lanhDaoKyPhoiHopIds = lanhDaoKyPhoiHopIds
        state.yeuCauLanhDaoKyPhoiHop = true
      }

      if (this.props.signNumber.lanhDaoDonViPhoiHop) {
        state.form.lanhDaoDonViPhoiHopId =
          this.props.signNumber.lanhDaoDonViPhoiHop.chucDanhId
      }

      if (this.props.signNumber.lanhDaoDonViCapTren) {
        state.form.lanhDaoDonViCapTrenId =
          this.props.signNumber.lanhDaoDonViCapTren.chucDanhId
        state.isHasSignParentUnit = true
      }

      if (this.props.signNumber.phoiHop) {
        let phoiHopIds = []
        this.props.signNumber.phoiHop.forEach(item => {
          phoiHopIds.push(item.phongBanId)
        })
        state.phoiHopIds = phoiHopIds
        if (phoiHopIds.length + 1 === this.props.departments.length) {
          state.isCheckedAllCompositive = true
        }
      }

      if (this.props.signNumber.lanhDaoPhoiHop) {
        let lanhDaoPhoiHopIds = []
        this.props.signNumber.lanhDaoPhoiHop.forEach(item => {
          if (item.phongBan) {
            lanhDaoPhoiHopIds.push(item.phongBan.phongBanId)
          }
        })
        state.lanhDaoPhoiHopIds = lanhDaoPhoiHopIds
      }

      if (this.props.signNumber.phoiHopCapTren) {
        let phoiHopCapTrenIds = []
        this.props.signNumber.phoiHopCapTren.forEach(item => {
          phoiHopCapTrenIds.push(item.phongBanId)
        })
        state.phoiHopCapTrenIds = phoiHopCapTrenIds
        state.isHasSignParentUnit = phoiHopCapTrenIds.length > 0
      }

      if (this.props.signNumber.chuyenDen) {
        let chuyenDenIds = []
        this.props.signNumber.chuyenDen.forEach(item => {
          chuyenDenIds.push(item.phongBanId)
        })
        state.chuyenDenIds = chuyenDenIds
      }

      if (this.props.signNumber.chuyenDenCapTren) {
        let chuyenDenPhongBanCapTrenIds = []
        this.props.signNumber.chuyenDenCapTren.forEach(item => {
          if (item.dsPhongBan) {
            item.dsPhongBan.forEach(subItem => {
              chuyenDenPhongBanCapTrenIds.push(subItem.phongBanId)
            })
          }
        })
        state.chuyenDenPhongBanCapTrenIds = chuyenDenPhongBanCapTrenIds
        state.isHasReceivedParentDepartment =
          chuyenDenPhongBanCapTrenIds.length > 0
      }
      state.isCheckedParentUnit =
        state.isHasSignParentUnit || state.isHasReceivedParentDepartment

      // if (this.props.signNumber.donViCungKy) {
      //   const donViCungKyIds = [];
      //   this.props.signNumber.donViCungKy.forEach(item => {
      //     donViCungKyIds.push(item.donViId);
      //   });
      //   state.donViCungKyIds = donViCungKyIds;
      //   if (donViCungKyIds.length === this.props.unitsEqual.length) {
      //     state.isCheckedAllUnitsEqual = true;
      //   }
      // }

      let selectedDepartmentOtherUnits = {}
      if (this.props.signNumber.chuyenDenPhongBanDonViKhac) {
        let chuyenDenPhongBanDonViKhacIds = []
        let receivedCheckedDataTypeList = []
        this.props.signNumber.chuyenDenPhongBanDonViKhac.forEach(item => {
          if (item.dsPhongBan && item.donViId) {
            let tmp = []
            item.dsPhongBan.forEach(subItem => {
              tmp.push(subItem.phongBanId)
            })
            selectedDepartmentOtherUnits[item.donViId] = tmp
            chuyenDenPhongBanDonViKhacIds =
              chuyenDenPhongBanDonViKhacIds.concat(tmp)
            receivedCheckedDataTypeList.push(item.donViId)
          }
        })
        state.chuyenDenPhongBanDonViKhacIds = chuyenDenPhongBanDonViKhacIds
        state.receivedCheckedDataTypeList = receivedCheckedDataTypeList
        if (chuyenDenPhongBanDonViKhacIds.length > 0) {
          state.isChooseDepartmentOtherUnits = true
        }
      }

      if (this.props.signNumber.donViCungKy) {
        let dsDonViCungKy = []
        let dsDonViCungKyIds = []
        let compositiveCheckedDataTypeList = []
        this.props.signNumber.donViCungKy.forEach(item => {
          if (item.phongBan && item.donViId) {
            selectedDepartmentOtherUnits[item.donViId] = [
              item.phongBan.phongBanId,
            ]
            dsDonViCungKy.push({
              don_vi_id: item.donViId,
              phong_ban_id: item.phongBan.phongBanId,
              lanh_dao_id: null,
            })
            dsDonViCungKyIds.push(item.phongBan.phongBanId)
            compositiveCheckedDataTypeList.push(item.donViId)
          }
        })
        state.dsDonViCungKy = dsDonViCungKy
        state.dsDonViCungKyIds = dsDonViCungKyIds
        state.compositiveCheckedDataTypeList = compositiveCheckedDataTypeList
        if (dsDonViCungKy.length > 0) {
          state.isChooseDepartmentOtherUnits = true
        }
      }
      state.selectedDepartmentOtherUnits = selectedDepartmentOtherUnits

      if (this.props.signNumber.fileNoiDung) {
        const fileNoiDung = {}
        fileNoiDung.file_id = this.props.signNumber.fileNoiDung.fileId
        fileNoiDung.ten_file = this.props.signNumber.fileNoiDung.tenFile
        fileNoiDung.url = this.props.signNumber.fileNoiDung.url
        fileNoiDung.kieu_file = this.props.signNumber.fileNoiDung.kieuFile
        fileNoiDung.kich_thuoc = this.props.signNumber.fileNoiDung.kichThuoc
        state.form.fileNoiDung = fileNoiDung
        state.contentFileName = this.props.signNumber.fileNoiDung.tenFile
      }

      if (
        this.props.signNumber.fileThamKhao &&
        this.props.signNumber.fileThamKhao.length
      ) {
        const fileThamKhao = []
        const attachQty = []
        const attachFileNames = []
        this.props.signNumber.fileThamKhao.forEach((item, i) => {
          fileThamKhao.push({
            file_id: item.fileId,
            ten_file: item.tenFile,
            url: item.url,
            kieu_file: item.kieuFile,
            kich_thuoc: item.kichThuoc,
          })
          attachFileNames.push(item.tenFile)
          attachQty.push(i + 1)
        })

        state.form.fileThamKhao = fileThamKhao
        state.attachFileNames = attachFileNames
        state.attachQty = attachQty
      }
      if (
        this.props.signNumber.dsFileDinhKem &&
        this.props.signNumber.dsFileDinhKem.length
      ) {
        const dsFileDinhKem = []
        const fileDinhKemQty = []
        const dinhKemFileNames = []
        this.props.signNumber.dsFileDinhKem.forEach((item, i) => {
          dsFileDinhKem.push({
            file_id: item.fileId,
            ten_file: item.tenFile,
            url: item.url,
            kieu_file: item.kieuFile,
            kich_thuoc: item.kichThuoc,
          })
          dinhKemFileNames.push(item.tenFile)
          fileDinhKemQty.push(i + 1)
        })

        state.form.dsFileDinhKem = dsFileDinhKem
        state.dinhKemFileNames = dinhKemFileNames
        state.fileDinhKemQty = fileDinhKemQty
      }
      this.setState(state)
    }
  }

  handleCheckDepartmentOtherUnits = (selectedListName, data) => {
    if (!data) {
      return
    }
    const newSelection = data.phongBanId
    let selectedDataById = []
    let selectedData = this.state[selectedListName]
    if (selectedData && selectedData[data.donViId]) {
      selectedDataById = selectedData[data.donViId]
    }
    if (
      selectedDataById &&
      selectedDataById.length > 0 &&
      selectedDataById.indexOf(newSelection) > -1
    ) {
      selectedDataById = []
    } else {
      selectedDataById = [newSelection]
    }
    selectedData = {
      ...selectedData,
      [data.donViId]: selectedDataById,
    }
    this.setState({
      [selectedListName]: selectedData,
    })
  }

  checkCompositiveDepartmentUnit(data) {
    if (!data) {
      return
    }
    let { chuyenDenPhongBanDonViKhacIds, dsDonViCungKyIds, dsDonViCungKy } =
      this.state
    let selectedDataById = []
    if (
      this.state.selectedDepartmentOtherUnits &&
      this.state.selectedDepartmentOtherUnits[data.donViId]
    ) {
      selectedDataById = this.state.selectedDepartmentOtherUnits[data.donViId]
    }
    dsDonViCungKyIds = dsDonViCungKyIds.filter(
      item => selectedDataById.indexOf(item) === -1
    )
    if (
      selectedDataById &&
      selectedDataById.length > 0 &&
      selectedDataById.indexOf(data.phongBanId) > -1
    ) {
      dsDonViCungKy = dsDonViCungKy.filter(
        item =>
          item.phong_ban_id !== data.phongBanId &&
          item.don_vi_id !== data.donViId
      )
      dsDonViCungKyIds = dsDonViCungKyIds.filter(
        item => item !== data.phongBanId
      )
    } else {
      dsDonViCungKyIds.push(data.phongBanId)
      dsDonViCungKy = dsDonViCungKy.filter(
        item => item.don_vi_id !== data.donViId
      )
      dsDonViCungKy.push({
        don_vi_id: data.donViId,
        phong_ban_id: data.phongBanId,
        lanh_dao_id: null,
      })
    }
    chuyenDenPhongBanDonViKhacIds = chuyenDenPhongBanDonViKhacIds.filter(
      item => item !== data.phongBanId
    )
    this.setState(
      { dsDonViCungKyIds, dsDonViCungKy, chuyenDenPhongBanDonViKhacIds },
      () => {
        this.handleCheckDepartmentOtherUnits(
          'selectedDepartmentOtherUnits',
          data
        )
      }
    )
  }

  checkReceivedDepartmentUnit(data) {
    if (!data) {
      return
    }
    let { chuyenDenPhongBanDonViKhacIds, dsDonViCungKyIds, dsDonViCungKy } =
      this.state
    let selectedDataById = []
    if (
      this.state.selectedDepartmentOtherUnits &&
      this.state.selectedDepartmentOtherUnits[data.donViId]
    ) {
      selectedDataById = this.state.selectedDepartmentOtherUnits[data.donViId]
    }
    chuyenDenPhongBanDonViKhacIds = chuyenDenPhongBanDonViKhacIds.filter(
      item => selectedDataById.indexOf(item) === -1
    )
    if (
      selectedDataById &&
      selectedDataById.length > 0 &&
      selectedDataById.indexOf(data.phongBanId) > -1
    ) {
      chuyenDenPhongBanDonViKhacIds = chuyenDenPhongBanDonViKhacIds.filter(
        item => item !== data.phongBanId
      )
    } else {
      chuyenDenPhongBanDonViKhacIds.push(data.phongBanId)
    }
    dsDonViCungKyIds = dsDonViCungKyIds.filter(item => item !== data.phongBanId)
    dsDonViCungKy = dsDonViCungKy.filter(
      item =>
        item.phong_ban_id !== data.phongBanId && item.don_vi_id !== data.donViId
    )
    this.setState(
      { dsDonViCungKy, dsDonViCungKyIds, chuyenDenPhongBanDonViKhacIds },
      () => {
        this.handleCheckDepartmentOtherUnits(
          'selectedDepartmentOtherUnits',
          data
        )
      }
    )
  }

  handleToogleOtherUnits = (
    listNames = [],
    selectedListName,
    isCheckedName
  ) => {
    let state = this.state
    state[isCheckedName] = !state[isCheckedName]
    // TODO: Khong reset khi dong collapse
    // state[selectedListName] = {};
    // listNames.forEach(listName => {
    //   state[listName] = [];
    // });
    this.setState(state)
  }

  handleGetValueGridCheckBoxCompositive = data => {
    if (!data || !Object.keys(data).length) {
      return
    }
    let phoiHopIds = []
    let lanhDaoPhoiHopIds = []
    if (this.props.departments && data.listResultData1) {
      data.listResultData1.forEach(selectedDepartment => {
        const phongBan = this.props.departments.find(
          item => item.phongBanId === selectedDepartment
        )
        if (phongBan && phongBan.phongBanLanhDao === true) {
          lanhDaoPhoiHopIds.push(selectedDepartment)
        } else {
          phoiHopIds.push(selectedDepartment)
        }
      })
    }
    return this.setState({
      phoiHopIds,
      lanhDaoPhoiHopIds,
      chuyenDenIds: data.listResultData2,
    })
  }

  handleGetValueGridCheckBoxParentCompositive = data => {
    if (!data || !Object.keys(data).length) {
      return
    }
    let state = this.state
    state.isHasSignParentUnit = false
    state.isHasReceivedParentDepartment = false
    state.phoiHopCapTrenIds = data.listResultData1
    state.chuyenDenPhongBanCapTrenIds = data.listResultData2
    if (data.listResultData1 && data.listResultData1.length > 0) {
      state.isHasSignParentUnit = true
    }
    if (data.listResultData2 && data.listResultData2.length > 0) {
      state.isHasReceivedParentDepartment = true
    }
    return this.setState(state)
  }

  handleResetDepartmentOtherUnit = donViId => {
    let {
      selectedDepartmentOtherUnits,
      dsDonViCungKy,
      dsDonViCungKyIds,
      chuyenDenPhongBanDonViKhacIds,
    } = this.state
    let removeIds = []
    if (selectedDepartmentOtherUnits && selectedDepartmentOtherUnits[donViId]) {
      removeIds = removeIds.concat(selectedDepartmentOtherUnits[donViId])
    }
    dsDonViCungKyIds = dsDonViCungKyIds.filter(
      item => removeIds.indexOf(item) === -1
    )
    chuyenDenPhongBanDonViKhacIds = chuyenDenPhongBanDonViKhacIds.filter(
      item => removeIds.indexOf(item) === -1
    )
    dsDonViCungKy = dsDonViCungKy.filter(
      item => removeIds.indexOf(item.phong_ban_id) === -1
    )
    this.setState({
      selectedDepartmentOtherUnits: {
        ...selectedDepartmentOtherUnits,
        [donViId]: [],
      },
      dsDonViCungKy,
      dsDonViCungKyIds,
      chuyenDenPhongBanDonViKhacIds,
    })
  }

  getOptions = (input, callback) => {
    if (input.length > 0) {
      if (this.promiseGetEmail) {
        clearTimeout(this.promiseGetEmail)
      }
      this.promiseGetEmail = setTimeout(() => {
        this.props.actions.searchFileCongVan(`${input}`).then(res => {
          if (
            res &&
            res.payload &&
            res.payload.data &&
            res.payload.data.result
          ) {
            let data = res.payload.data.result.map(item => ({
              value: {
                ...item.fileCongVan,
                tenFile: `${item.soCongVan}.${
                  item.fileCongVan && item.fileCongVan.fileName
                    ? item.fileCongVan.fileName.split('.').pop()
                    : ''
                }`,
                isExisted: true,
              },
              label: `${item.soCongVan} - ${item.trichYeu}`,
            }))
            callback(null, {
              options: data,
              complete: true,
            })
          } else {
            callback(null, {
              options: null,
              complete: true,
            })
          }
        })
      }, 800)
    } else if (input.length < 3) {
      callback(null, {
        options: null,
        complete: true,
      })
    } else {
      callback(null, {
        options: null,
        complete: true,
      })
    }
  }

  handleSelect = (val, index, isFileThamKhao) => {
    if (isFileThamKhao) {
      let fileThamKhaoTuCongViecPb = [...this.state.fileThamKhaoTuCongViecPb]
      fileThamKhaoTuCongViecPb[index] = { ...val, label: val.value.tenFile }
      this.setState({ fileThamKhaoTuCongViecPb })
    } else {
      let fileDinhKemTuCongViecPb = [...this.state.fileDinhKemTuCongViecPb]
      fileDinhKemTuCongViecPb[index] = { ...val, label: val.value.tenFile }
      this.setState({ fileDinhKemTuCongViecPb })
    }
  }

  /**
   * click open chon phieu y kien
   * @memberof InputForm
   */
  clickOpenPopupChonPhieuYKien = () => {
    this.setState({
      dialogModel: {
        ...this.state.dialogModel,
        isOpen: true,
      },
    })
  }

  /**
   * toggle Dialog close
   * @memberof InputForm
   */
  toggleDialog = () => {
    this.setState({
      dialogModel: {
        ...this.state.dialogModel,
        isOpen: false,
      },
    })
  }

  /**
   * Lưu phiếu đã chọn và input
   * @memberof InputForm
   */
  handleChoosePhieu = async (param, dataState) => {
    if (isEmpty(param)) {
      return false
    }
    let fileTuPhieu = {}
    // Get API chi tiết phiếu ý kiến
    await this.props.actions.opinionDetail(param).then(res => {
      if (res.error) {
        this.setState({ isError: true })
      }
      if (res.payload && res.payload.data && res.payload.data.result) {
        fileTuPhieu = {
          dsFileDinhKem: res.payload.data.result.dsFileDinhKem,
          dsFileThamKhao: res.payload.data.result.dsFileThamKhao,
          dsThanhVien: res.payload.data.result.dsThanhVien,
          fileBaoCao: res.payload.data.result.fileBaoCao,
          fileTongHop: res.payload.data.result.fileTongHop,
          fileNoiDung: res.payload.data.result.fileNoiDung,
        }
      }
    })
    if (!isEmpty(fileTuPhieu)) {
      // TODO: Chon file tu phieu
      // Lọc ra các file đang có từ phiếu ý kiến
      if (!isEmpty(fileTuPhieu.fileNoiDung)) {
        dataState.form.fileThamKhao.push({
          file_id: fileTuPhieu.fileNoiDung.fileId,
          ten_file: fileTuPhieu.fileNoiDung.tenFile,
          url: fileTuPhieu.fileNoiDung.url,
          kieu_file: fileTuPhieu.fileNoiDung.kieuFile,
          kich_thuoc: fileTuPhieu.fileNoiDung.kichThuoc,
        })
        dataState.attachFileNames.push(fileTuPhieu.fileNoiDung.tenFile)
        dataState.attachQty.push(dataState.attachQty.length + 1)
      }
      if (!isEmpty(fileTuPhieu.fileBaoCao)) {
        dataState.form.fileThamKhao.push({
          file_id: fileTuPhieu.fileBaoCao.fileId,
          ten_file: fileTuPhieu.fileBaoCao.tenFile,
          url: fileTuPhieu.fileBaoCao.url,
          kieu_file: fileTuPhieu.fileBaoCao.kieuFile,
          kich_thuoc: fileTuPhieu.fileBaoCao.kichThuoc,
        })
        dataState.attachFileNames.push(fileTuPhieu.fileBaoCao.tenFile)
        dataState.attachQty.push(dataState.attachQty.length + 1)
      }
      if (fileTuPhieu.dsFileDinhKem.length !== 0) {
        fileTuPhieu.dsFileDinhKem.forEach((item, i) => {
          dataState.form.fileThamKhao.push({
            file_id: item.fileId,
            ten_file: item.tenFile,
            url: item.url,
            kieu_file: item.kieuFile,
            kich_thuoc: item.kichThuoc,
          })
          dataState.attachFileNames.push(item.tenFile)
          dataState.attachQty.push(dataState.attachQty.length + 1)
        })
      }
      if (fileTuPhieu.dsFileThamKhao.length !== 0) {
        fileTuPhieu.dsFileThamKhao.forEach((item, i) => {
          dataState.form.fileThamKhao.push({
            file_id: item.fileId,
            ten_file: item.tenFile,
            url: item.url,
            kieu_file: item.kieuFile,
            kich_thuoc: item.kichThuoc,
          })
          dataState.attachFileNames.push(item.tenFile)
          dataState.attachQty.push(dataState.attachQty.length + 1)
        })
      }
      if (fileTuPhieu.dsThanhVien.length !== 0) {
        fileTuPhieu.dsThanhVien.forEach((item, i) => {
          dataState.form.fileThamKhao.push({
            file_id: item.fileYKien.fileId,
            ten_file: item.fileYKien.tenFile,
            url: item.fileYKien.url,
            kieu_file: item.fileYKien.kieuFile,
            kich_thuoc: item.fileYKien.kichThuoc,
          })
          dataState.attachFileNames.push(item.fileYKien.tenFile)
          dataState.attachQty.push(dataState.attachQty.length + 1)
        })
      }
      if (!isEmpty(fileTuPhieu.fileTongHop)) {
        dataState.form.fileThamKhao.push({
          file_id: fileTuPhieu.fileTongHop.fileId,
          ten_file: fileTuPhieu.fileTongHop.tenFile,
          url: fileTuPhieu.fileTongHop.url,
          kieu_file: fileTuPhieu.fileTongHop.kieuFile,
          kich_thuoc: fileTuPhieu.fileTongHop.kichThuoc,
        })
        dataState.attachFileNames.push(fileTuPhieu.fileTongHop.tenFile)
        dataState.attachQty.push(dataState.attachQty.length + 1)
      }
      this.setState({
        attachFileNames: dataState.attachFileNames,
        attachQty: dataState.attachQty,
      })
    }
  }

  // onClickTutorial = () => {
  //   const url = 'https://firebasestorage.googleapis.com/v0/b/cpc-eoffice.appspot.com/o/Instruction%20Docs%2FHu%CC%9Bo%CC%9B%CC%81ng%20da%CC%82%CC%83n.pdf?alt=media&token=8670f634-5af4-4fe2-9a62-e747dd0eb7d9';
  //   const newWindow = window.open(url, '_blank', 'noopener,noreferrer');
  //   if (newWindow) newWindow.opener = null;
  // }

  render() {
    if (this.state.isCanUpdate === false) {
      return null
    }

    const leaderUnits = this.props.unitsLeader.filter(
      item => item.chucDanhId !== this.state.form.lanhDaoDonViPhoiHopId
    )
    const otherLeaderUnits = this.props.unitsLeader.filter(
      item => item.chucDanhId !== this.state.form.lanhDaoDonViId
    )
    const { contentFiles } = this.state
    const { fileTrinhKy } = this.props
    return (
      <div className="addSignNumber">
        <div className="form-container">
          <div className="pt-form-group pt-intent-danger">
            <div className="row">
              {/* So ky hieu */}
              <div className="col-md-6 col-xs-6 col-sm-6 colcus-mb-50">
                <label className="pt-label">
                  Số ký hiệu
                  <span className="pt-text-muted required-input">*</span>
                </label>
                <div className="pt-form-content">
                  <input
                    name="soKyHieu"
                    className={classnames('pt-input', {
                      'loading-input':
                        this.props.isLoadingData &&
                        Object.values(this.props.isLoadingData).find(
                          item => item === true
                        ) !== undefined,
                    })}
                    placeholder="Nhập số ký hiệu"
                    type="text"
                    dir="auto"
                    value={this.state.form.soKyHieu}
                    onChange={this.handleInputChange}
                    autoFocus={true}
                    disabled={
                      this.props.isLoadingData &&
                      Object.values(this.props.isLoadingData).find(
                        item => item === true
                      ) !== undefined
                    }
                  />
                  {this.state.error.soKyHieu !== undefined && (
                    <div className="pt-form-helper-text">
                      {this.state.error.soKyHieu}
                    </div>
                  )}
                </div>
              </div>
              {/* Ten thanh pho*/}
              <div className="col-md-6 col-xs-6 col-sm-6 colcus-mb-50">
                <label className="pt-label">Tên thành phố</label>
                <div className="pt-form-content">
                  <input
                    className={classnames('pt-input', {
                      'loading-input':
                        this.props.isLoadingData &&
                        this.props.isLoadingData.tenThanhPho === true,
                    })}
                    placeholder="Nhập tên thành phố…"
                    type="text"
                    dir="auto"
                    name="tenThanhPho"
                    value={this.state.form.tenThanhPho}
                    onChange={this.handleInputChange}
                    disabled={
                      this.props.isLoadingData &&
                      this.props.isLoadingData.tenThanhPho === true
                    }
                  />
                  {/* this.state.error.tenThanhPho !== undefined &&
                    <div className="pt-form-helper-text">{this.state.error.tenThanhPho}</div>
                  */}
                  {/*<p className="text-noted-medium">
                    Thể hiện khi cấp số công văn, ngày công văn. Ví dụ: Đà Nẵng, ngày...tháng...năm
                  </p>*/}
                </div>
              </div>
            </div>
          </div>
          {/* Trich yeu */}
          <div className="pt-form-group pt-intent-danger">
            <div className="row">
              <div className="col-md-12 col-xs-12 col-sm-12">
                <label className="pt-label">
                  Trích yếu
                  <span className="pt-text-muted required-input">*</span>
                </label>
                <div className="pt-form-content">
                  <textarea
                    name="trichYeu"
                    className={classnames('pt-input pt-fill text-abridgment', {
                      'loading-input':
                        this.props.isLoadingData &&
                        Object.values(this.props.isLoadingData).find(
                          item => item === true
                        ) !== undefined,
                    })}
                    rows="3"
                    placeholder="Nội dung…"
                    dir="auto"
                    value={this.state.form.trichYeu}
                    onChange={this.handleInputChange}
                    disabled={
                      this.props.isLoadingData &&
                      Object.values(this.props.isLoadingData).find(
                        item => item === true
                      ) !== undefined
                    }
                  ></textarea>
                  {this.state.error.trichYeu !== undefined && (
                    <div className="pt-form-helper-text">
                      {this.state.error.trichYeu}
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
          {/* Noi dung trinh */}
          <div className="pt-form-group pt-intent-danger">
            <div className="row">
              <div className="col-md-12 col-xs-12 col-sm-12">
                <label className="pt-label">Nội dung trình</label>
                <div className="pt-form-content">
                  <textarea
                    name="noiDungTrinh"
                    className={classnames('pt-input pt-fill', {
                      'loading-input':
                        this.props.isLoadingData &&
                        Object.values(this.props.isLoadingData).find(
                          item => item === true
                        ) !== undefined,
                    })}
                    rows="4"
                    dir="auto"
                    value={this.state.form.noiDungTrinh}
                    onChange={this.handleInputChange}
                    disabled={
                      this.props.isLoadingData &&
                      Object.values(this.props.isLoadingData).find(
                        item => item === true
                      ) !== undefined
                    }
                  ></textarea>
                </div>
              </div>
            </div>
          </div>
          {/* File noi dung */}
          <div className="pt-form-group pt-intent-danger">
            <div className="row">
              <div className="col-md-12 col-xs-12 col-sm-12">
                <label className="pt-label pt20 mt10 border-top-secondary-2px">
                  File nội dung trình ký
                  <span className="pt-text-muted required-input">*</span>
                  {/* {this.props.type === signNumberType.KYSODI &&
                    <span
                      className="ml5 text-regular-label"
                    ><i
                      className="cursor-pointer color-blue"
                      onClick={this.onClickTutorial}
                    >{`(Hướng dẫn tạo file ký số không kẻ vùng ký)`}</i></span>
                  } */}
                </label>
                <div className="pt-form-content">
                  <label className="pt-file-upload">
                    <input
                      ref={this.refFile}
                      type="file"
                      name="contentFiles-0"
                      onChange={this.handleFileInputContentFiles.bind(this)}
                      onClick={this.handleChangeFile}
                      disabled={
                        !(
                          this.state.isCanUpdate && this.state.isCanUpdateFile
                        ) ||
                        this.state.isLoading ||
                        Object.values(this.props.isLoadingData).find(
                          item => item === true
                        ) !== undefined
                      }
                    />
                    <span className="pt-file-upload-input">
                      {this.state.contentFileName &&
                      this.state.contentFileName.length
                        ? this.state.contentFileName
                        : 'Chọn file nội dung'}
                    </span>
                  </label>
                  {this.state.sizeContentFile && (
                    <p className="pt-form-helper-text pt-form-helper-infomation">
                      Kích thước file đã upload:
                      <span className="size-file">
                        {' '}
                        {Tool.Utils.getFileSize(this.state.sizeContentFile)}
                      </span>
                    </p>
                  )}
                  {this.state.error.contentFile !== undefined && (
                    <div className="pt-form-helper-text">
                      {this.state.error.contentFile}
                    </div>
                  )}
                  {this.state.isLoading &&
                    this.props.contentFileProgress &&
                    this.props.contentFileProgress[0] > 0 && (
                      <div className="progress-upload-content">
                        <span className="percent-content">
                          {this.props.contentFileProgress[0]}%
                        </span>
                        <div className="pt-progress-bar pt-intent-primary progress-bar-upload">
                          <div
                            className="pt-progress-meter"
                            style={{
                              width: this.props.contentFileProgress[0] + '%',
                            }}
                          ></div>
                        </div>
                      </div>
                    )}
                </div>
              </div>
            </div>
          </div>
          {/* File dinh kem */}
          <div className="pt-form-group pt-intent-danger">
            <div className="row">
              <div className="col-md-12 col-xs-12 col-sm-12">
                <label className="pt-label">
                  Các file đính kèm
                  <span className="text-regular-label">
                    <i> (các văn bản đính kèm theo công văn)</i>
                  </span>
                </label>
                <div className="pt-form-content pb20 mb10 border-bottom-secondary-2px">
                  {this.state.fileDinhKemQty.map((item, i) => (
                    <div
                      key={i}
                      className={classnames('pt-form-group pt-intent-danger', {
                        'upload-have-close':
                          i > 0 ||
                          this.state.dinhKemFiles[0] ||
                          (this.state.form.dsFileDinhKem &&
                            this.state.form.dsFileDinhKem[0]),
                      })}
                    >
                      <label className="pt-file-upload">
                        <input
                          type="file"
                          name={`dinhKemFiles-${i}`}
                          onChange={this.handleFileInput.bind(this, i)}
                          onClick={event => {
                            event.target.value = null
                          }}
                          disabled={
                            !(
                              this.state.isCanUpdate &&
                              this.state.isCanUpdateFile
                            ) ||
                            this.state.isLoading ||
                            Object.values(this.props.isLoadingData).find(
                              item => item === true
                            ) !== undefined
                          }
                        />
                        <span className="pt-file-upload-input">
                          {this.state.dinhKemFileNames &&
                          this.state.dinhKemFileNames[i]
                            ? this.state.dinhKemFileNames[i]
                            : 'Chọn file tham khảo'}
                        </span>
                      </label>
                      {(i > 0 ||
                        (i === 0 &&
                          (this.state.fileDinhKemTuCongViecPb[i] ||
                            this.state.dinhKemFiles[0] ||
                            (this.state.form.dsFileDinhKem &&
                              this.state.form.dsFileDinhKem[0])))) &&
                        !this.state.isLoading && (
                          <span
                            onClick={() => this.handleEventRemoveFile(i, true)}
                            className="icon-bc icon-close icon-remove-file z-index-1"
                          ></span>
                        )}
                      {this.state.sizeDinhKemFiles[i] !== undefined && (
                        <p className="pt-form-helper-text pt-form-helper-infomation">
                          Kích thước file đã upload:
                          <span className="size-file">
                            {' '}
                            {Tool.Utils.getFileSize(
                              this.state.sizeDinhKemFiles[i]
                            )}
                          </span>
                        </p>
                      )}
                      {this.state.errorsDinhKemFiles[i] !== undefined && (
                        <div className="pt-form-helper-text">
                          {this.state.errorsDinhKemFiles[i]}
                        </div>
                      )}
                      {this.state.isLoading &&
                        this.props.dinhKemFilesProgress &&
                        this.props.dinhKemFilesProgress[i] > 0 && (
                          <div className="progress-upload-content">
                            <span className="percent-content">
                              {this.props.dinhKemFilesProgress[i]}%
                            </span>
                            <div className="pt-progress-bar pt-intent-primary progress-bar-upload">
                              <div
                                className="pt-progress-meter"
                                style={{
                                  width:
                                    this.props.dinhKemFilesProgress[i] + '%',
                                }}
                              ></div>
                            </div>
                          </div>
                        )}
                      {!(
                        this.state.dinhKemFiles[i] ||
                        (this.state.form.dsFileDinhKem &&
                          this.state.form.dsFileDinhKem[i]) ||
                        this.state.isLoading ||
                        Object.values(this.props.isLoadingData).find(
                          item => item === true
                        ) !== undefined
                      ) && (
                        <div className="pt-form-content select-custom-input select-menu-outer-z-index-3 select-hide-clear-btn select-custom-absolute-icon select2-custom-iconsearch position-absolute top-0 left-0 right-70px border-right-radius-0 height-39px">
                          <Async
                            name="form-field-name"
                            value={this.state.fileDinhKemTuCongViecPb[i]}
                            loadOptions={this.getOptions}
                            onChange={val => this.handleSelect(val, i, false)}
                            onCloseResetsInput={false}
                            onBlurResetsInput={false}
                            autoload={false}
                            placeholder="Nhập số công văn hoặc trích yếu"
                            searchPromptText="Nhập số công văn hoặc trích yếu...."
                            loadingPlaceholder="Đang tìm kiếm...."
                            noResultsText="Không tìm thấy dữ liệu..."
                            arrowRenderer={() => {}}
                            ignoreAccents={false}
                            disabled={
                              !(
                                this.state.isCanUpdate &&
                                this.state.isCanUpdateFile
                              )
                            }
                          />
                          <span className="Select-arrow-zone"></span>
                        </div>
                      )}
                    </div>
                  ))}
                  <Button
                    className="btn-add-file mt0"
                    onClick={() => this.handleEventAddFile(true)}
                    disabled={
                      !(this.state.isCanUpdate && this.state.isCanUpdateFile) ||
                      this.state.isLoading
                    }
                  >
                    <span>Thêm file</span>
                    <span className="icon-bc icon-Plus"></span>
                  </Button>
                </div>
              </div>
            </div>
          </div>
          {/* File tham khao */}
          <div className="pt-form-group pt-intent-danger">
            <div className="row">
              <div className="col-md-12 col-xs-12 col-sm-12">
                <label className="pt-label">
                  Các file tham khảo
                  <span className="text-regular-label">
                    <i>
                      {' '}
                      (các văn bản tham khảo trong quá trình ký duyệt văn bản ký
                      số, không đính kèm theo công văn khi phát hành)
                    </i>
                  </span>
                </label>
                <div className="pt-form-content">
                  {this.state.attachQty.map((item, i) => (
                    <div
                      key={i}
                      className={classnames('pt-form-group pt-intent-danger', {
                        'upload-have-close':
                          i > 0 ||
                          this.state.attachFiles[0] ||
                          (this.state.form.fileThamKhao &&
                            this.state.form.fileThamKhao[0]),
                      })}
                    >
                      <label className="pt-file-upload">
                        <input
                          type="file"
                          name={`attachFiles-${i}`}
                          onChange={this.handleFileInput.bind(this, i)}
                          onClick={event => {
                            event.target.value = null
                          }}
                          disabled={
                            !(
                              this.state.isCanUpdate &&
                              this.state.isCanUpdateFile
                            ) ||
                            this.state.isLoading ||
                            Object.values(this.props.isLoadingData).find(
                              item => item === true
                            ) !== undefined
                          }
                        />
                        <span className="pt-file-upload-input">
                          {this.state.attachFileNames &&
                          this.state.attachFileNames[i]
                            ? this.state.attachFileNames[i]
                            : 'Chọn file tham khảo'}
                        </span>
                      </label>
                      {(i > 0 ||
                        (i === 0 &&
                          (this.state.fileThamKhaoTuCongViecPb[i] ||
                            this.state.attachFiles[0] ||
                            (this.state.form.fileThamKhao &&
                              this.state.form.fileThamKhao[0])))) &&
                        !this.state.isLoading && (
                          <span
                            onClick={() => this.handleEventRemoveFile(i, false)}
                            className="icon-bc icon-close icon-remove-file z-index-1"
                          ></span>
                        )}
                      {this.state.sizeAttachedFiles[i] !== undefined && (
                        <p className="pt-form-helper-text pt-form-helper-infomation">
                          Kích thước file đã upload:
                          <span className="size-file">
                            {' '}
                            {Tool.Utils.getFileSize(
                              this.state.sizeAttachedFiles[i]
                            )}
                          </span>
                        </p>
                      )}
                      {this.state.errorsAttachFiles[i] !== undefined && (
                        <div className="pt-form-helper-text">
                          {this.state.errorsAttachFiles[i]}
                        </div>
                      )}
                      {this.state.isLoading &&
                        this.props.attachFilesProgress &&
                        this.props.attachFilesProgress[i] > 0 && (
                          <div className="progress-upload-content">
                            <span className="percent-content">
                              {this.props.attachFilesProgress[i]}%
                            </span>
                            <div className="pt-progress-bar pt-intent-primary progress-bar-upload">
                              <div
                                className="pt-progress-meter"
                                style={{
                                  width:
                                    this.props.attachFilesProgress[i] + '%',
                                }}
                              ></div>
                            </div>
                          </div>
                        )}
                      {!(
                        this.state.attachFiles[i] ||
                        (this.state.form.fileThamKhao &&
                          this.state.form.fileThamKhao[i]) ||
                        this.state.isLoading ||
                        Object.values(this.props.isLoadingData).find(
                          item => item === true
                        ) !== undefined
                      ) && (
                        <div className="pt-form-content select-custom-input select-menu-outer-z-index-3 select-hide-clear-btn select-custom-absolute-icon select2-custom-iconsearch position-absolute top-0 left-0 right-70px border-right-radius-0 height-39px">
                          <Async
                            name="form-field-name"
                            value={this.state.fileThamKhaoTuCongViecPb[i]}
                            loadOptions={this.getOptions}
                            onChange={val => this.handleSelect(val, i, true)}
                            onCloseResetsInput={false}
                            onBlurResetsInput={false}
                            autoload={false}
                            placeholder="Nhập số công văn hoặc trích yếu"
                            searchPromptText="Nhập số công văn hoặc trích yếu...."
                            loadingPlaceholder="Đang tìm kiếm...."
                            noResultsText="Không tìm thấy dữ liệu..."
                            arrowRenderer={() => {}}
                            ignoreAccents={false}
                            disabled={
                              !(
                                this.state.isCanUpdate &&
                                this.state.isCanUpdateFile
                              )
                            }
                          />
                          <span className="Select-arrow-zone"></span>
                        </div>
                      )}
                    </div>
                  ))}
                  <Button
                    className="btn-add-file mt0 mr10"
                    onClick={() => this.handleEventAddFile(false)}
                    disabled={
                      !(this.state.isCanUpdate && this.state.isCanUpdateFile) ||
                      this.state.isLoading
                    }
                  >
                    <span>Thêm file</span>
                    <span className="icon-bc icon-Plus"></span>
                  </Button>
                  {/* {this.props.type === signNumberType.KYSODI && (
                    <Authorization.Element
                      permission={[Permission.HDTV_LAYYKIEN]}
                    >
                      <Button
                        className="btn-add-file blue mt0"
                        onClick={this.clickOpenPopupChonPhieuYKien}
                        disabled={false}
                      >
                        <span>Thêm từ phiếu ý kiến</span>
                        <span className="icon-bc icon-Plus"></span>
                      </Button>
                    </Authorization.Element>
                  )} */}
                  <Dialog
                    isOpen={this.state.dialogModel.isOpen}
                    onClose={this.toggleDialog}
                    title={this.state.dialogModel.title}
                    className="size-container-dialog dialog-chonphieu"
                  >
                    <PhieuYKienChon
                      handleSubmit={this.handleChoosePhieu}
                      dataState={this.state}
                      toggleDialog={this.toggleDialog}
                      actions={this.props.actions}
                    />
                  </Dialog>
                </div>
              </div>
            </div>
          </div>
          {/* Trinh lanh dao phong ban */}
          <div className="pt-form-group pt-intent-danger">
            <div className="row">
              <div className="col-md-6 col-xs-6 col-sm-6 colcus-mb-50">
                <label className="pt-label">Trình LĐ phòng ban</label>
                <div className="pt-form-content">
                  <div className="pt-select">
                    <select
                      name="lanhDaoPhongBanChuTriId"
                      onChange={this.handleInputChange}
                      value={this.state.form.lanhDaoPhongBanChuTriId}
                      className={classnames({
                        'loading-input':
                          this.props.isLoadingData &&
                          this.props.isLoadingData.lanhDaoPhongBanChuTriId ===
                            true,
                      })}
                      disabled={
                        (this.props.isLoadingData &&
                          this.props.isLoadingData.lanhDaoPhongBanChuTriId ===
                            true) ||
                        this.state.isCanUpdateLeaderDepartment === false
                      }
                    >
                      <option value={''}></option>
                      {this.props.departmentSigned.map((item, i) => (
                        <option key={i} value={item.chucDanhId}>
                          {item.tenNhanVien}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* Phong ban ky phoi hop */}
          <div className="selectbox-group">
            <div className="heading-group-select disabled-checkbox">
              <span className="pull-left name_group">
                {this.props.type === signNumberType.KYSODI &&
                  'Phòng ban ký phối hợp'}
                {this.props.type === signNumberType.KYSONOIBO &&
                  'Phòng ban ký phối hợp và chuyển đến'}
                {this.props.type === signNumberType.KYSONOIBO && (
                  <span className="text-regular-label">
                    {' '}
                    (Trong ngoặc là trường ký của phòng ban)
                  </span>
                )}
              </span>
              {/*<Checkbox
                className="pull-right"
                name="allCompositive"
                checked={this.state.isCheckedAllCompositive}
                onChange={this.handleCheckAllCompositiveDepartments}
                disabled={!(this.state.isCanUpdate && this.state.isCanUpdateCompositive)}
              >
                <span className="name_checkbox">Chọn tất cả</span>
              </Checkbox>*/}
            </div>
            {this.props.type === signNumberType.KYSODI && (
              <div className="container-checkbox">
                <CheckboxGroup
                  name="phoiHop"
                  options={this.state.departmentSelections}
                  selectedOptions={this.state.phoiHopIds.concat(
                    this.state.lanhDaoPhoiHopIds
                  )}
                  onChange={this.handleCheckCompositiveDepartments}
                  disabledList={this.state.chuyenDenIds.concat(
                    this.state.disabledList,
                    this.state.leaderUnitDisabledList
                  )}
                  isNotCheckDisabledItem
                  isDisabledAll={!this.state.isCanUpdateCompositive}
                  isLoadingData={
                    this.props.isLoadingData &&
                    this.props.isLoadingData.departments
                  }
                  colum="col-xs-6 col-sm-4 col-md-4"
                />
              </div>
            )}
            {this.props.type === signNumberType.KYSONOIBO && (
              <CheckboxGroupTwoColums
                options={this.state.receiveDepartmentSelections}
                onSubmit={this.handleGetValueGridCheckBoxCompositive}
                listDistabledData1={this.state.chuyenDenIds.concat(
                  this.state.disabledList,
                  this.state.leaderUnitDisabledList
                )}
                listDistabledData2={this.state.phoiHopIds.concat(
                  this.state.disabledList,
                  this.state.lanhDaoPhoiHopIds
                )}
                listSelectedData1={this.state.phoiHopIds.concat(
                  this.state.lanhDaoPhoiHopIds
                )}
                listSelectedData2={this.state.chuyenDenIds}
                isDisabledAllData1={!this.state.isCanUpdateCompositive}
                isLoadingData={
                  this.props.isLoadingData &&
                  this.props.isLoadingData.departments
                }
                titles={['KÝ PHỐI HƠP', 'CHUYỂN ĐẾN']}
              />
            )}
          </div>
          {/* Phong ban don vi ky phoi hop*/}
          {this.props.type === signNumberType.KYSONOIBO &&
            this.props.isAllowShowDepartmentsOfOtherUnits === true && (
              <div className="selectbox-group name_group">
                {/* TODO: Doi checkbox sang collapse arrow */}
                {/* <label className={classnames('pt-control pt-checkbox pt-label', {
                'cursor-not-allowed': !this.state.isCanUpdateCompositiveElectric
              })}>
                <input
                  type="checkbox"
                  name="isChooseDepartmentOtherUnits"
                  onChange={this.handleToogleOtherUnits.bind(this, ['dsDonViCungKyIds', 'dsDonViCungKy', 'chuyenDenPhongBanDonViKhacIds', 'compositiveCheckedDataTypeList', 'receivedCheckedDataTypeList'], 'selectedDepartmentOtherUnits')}
                  checked={this.state.isChooseDepartmentOtherUnits}
                  disabled={!this.state.isCanUpdateCompositiveElectric}
                />
                <span className="pt-control-indicator"></span>
                <span className="name_group">
                  {this.props.isPc === true ? 'Phòng ban đơn vị ký phối hợp và chuyển đến' : 'Phòng ban đơn vị chuyển đến'}
                  {this.props.isPc === true &&
                    <span className="text-regular-label"> (Trong ngoặc là trường ký của đơn vị)</span>
                  }
                </span>
              </label> */}
                <div className="heading-group-select disabled-checkbox">
                  <label
                    className={classnames(
                      'pt-control pt-checkbox pt-label pt0 pl0',
                      {
                        'cursor-not-allowed':
                          !this.state.isCanUpdateCompositiveElectric,
                      }
                    )}
                  >
                    <span
                      onClick={this.handleToogleOtherUnits.bind(
                        this,
                        [
                          'dsDonViCungKyIds',
                          'dsDonViCungKy',
                          'chuyenDenPhongBanDonViKhacIds',
                          'compositiveCheckedDataTypeList',
                          'receivedCheckedDataTypeList',
                        ],
                        'selectedDepartmentOtherUnits',
                        'isChooseDepartmentOtherUnits'
                      )}
                      className={classnames('name_group_unit text-bold', {
                        'pt-icon pt-icon-chevron-up':
                          this.state.isChooseDepartmentOtherUnits,
                        'pt-icon pt-icon-chevron-down':
                          !this.state.isChooseDepartmentOtherUnits,
                      })}
                    ></span>
                    <span
                      className="name_group"
                      onClick={this.handleToogleOtherUnits.bind(
                        this,
                        [
                          'dsDonViCungKyIds',
                          'dsDonViCungKy',
                          'chuyenDenPhongBanDonViKhacIds',
                          'compositiveCheckedDataTypeList',
                          'receivedCheckedDataTypeList',
                        ],
                        'selectedDepartmentOtherUnits',
                        'isChooseDepartmentOtherUnits'
                      )}
                    >
                      {this.props.isPc === true
                        ? 'Phòng ban đơn vị ký phối hợp và chuyển đến'
                        : 'Đơn vị chuyển đến'}
                      {this.props.isPc === true && (
                        <span className="text-regular-label">
                          {' '}
                          (Trong ngoặc là trường ký của đơn vị)
                        </span>
                      )}
                    </span>
                  </label>
                </div>
              </div>
            )}
          {this.props.type === signNumberType.KYSONOIBO &&
            this.props.isAllowShowDepartmentsOfOtherUnits === true && (
              <Collapse isOpen={this.state.isChooseDepartmentOtherUnits}>
                <ListUnitRadioGroupTwoColumns
                  data={this.props.electricUnits}
                  requestApi={this.props.actions.commonFetchDepartments}
                  onCheckDepartmentList1={this.checkCompositiveDepartmentUnit}
                  onCheckDepartmentList2={this.checkReceivedDepartmentUnit}
                  selectedList={this.state.dsDonViCungKyIds.concat(
                    this.state.chuyenDenPhongBanDonViKhacIds
                  )}
                  disabledList1={
                    !this.state.isCanUpdateCompositiveElectric
                      ? this.state.compositiveCheckedDataTypeList
                      : []
                  }
                  checkedDataTypeList1={
                    this.state.compositiveCheckedDataTypeList
                  }
                  checkedDataTypeList2={this.state.receivedCheckedDataTypeList}
                  selectedListObject={this.state.selectedDepartmentOtherUnits}
                  mainUnitId={this.props.mainUnitId}
                  conditionField="phongBanLanhDao"
                  conditionValue={true}
                  disabledAllList1={!this.state.isCanUpdateCompositiveElectric}
                  isShowUnitCode
                  isCanShowCompositive={this.props.isPc === true}
                  isLoadingData={this.props.isLoadingData.electricUnits}
                  onResetDepartmentOtherUnit={
                    this.handleResetDepartmentOtherUnit
                  }
                />
              </Collapse>
            )}
          {/* Trinh lanh dao don vi */}
          <div className="pt-form-group pt-intent-danger">
            <div className="row">
              <div className="col-md-6 col-xs-6 col-sm-6 colcus-mb-50">
                <label className="pt-label">
                  Trình lãnh đạo đơn vị
                  {(this.props.type === signNumberType.KYSODI ||
                    this.state.isHasSignParentUnit === true ||
                    (this.state.form.lanhDaoDonViPhoiHopId &&
                      this.state.form.lanhDaoDonViPhoiHopId.length > 0)) && (
                    <span className="pt-text-muted required-input">*</span>
                  )}
                  <span className="text-regular-label">
                    {' '}
                    (Trường ký là <span className="text-bold-label">chuky</span>
                    )
                  </span>
                </label>
                <div className="pt-form-content">
                  <div className="pt-select">
                    <select
                      name="lanhDaoDonViId"
                      onChange={this.handleInputChange}
                      value={this.state.form.lanhDaoDonViId}
                      className={classnames({
                        'loading-input':
                          this.props.isLoadingData &&
                          this.props.isLoadingData.lanhDaoDonViId === true,
                      })}
                      disabled={
                        (this.props.isLoadingData &&
                          this.props.isLoadingData.lanhDaoDonViId === true) ||
                        this.state.isCanUpdateLeaderUnit === false
                      }
                    >
                      <option value=""></option>
                      {leaderUnits.map((item, i) => (
                        <option key={i} value={item.chucDanhId}>
                          {item.tenNhanVien}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
                {this.state.error.lanhDaoDonViId !== undefined && (
                  <div className="pt-form-helper-text">
                    {this.state.error.lanhDaoDonViId}
                  </div>
                )}
              </div>

              {/* Lãnh đạo khác cùng ký */}
              {this.props.type === signNumberType.KYSODI && (
                <div className="col-md-6 col-xs-6 col-sm-6 colcus-mb-50">
                  <label className="pt-label">
                    Lãnh đạo khác cùng ký
                    <span className="text-regular-label">
                      {' '}
                      (Trường ký là{' '}
                      <span className="text-bold-label">chuky_ph</span>)
                    </span>
                  </label>
                  <div className="pt-form-content">
                    <div className="pt-select">
                      <select
                        name="lanhDaoDonViPhoiHopId"
                        onChange={this.handleInputChange}
                        value={this.state.form.lanhDaoDonViPhoiHopId}
                        className={classnames({
                          'loading-input':
                            this.props.isLoadingData &&
                            this.props.isLoadingData.lanhDaoDonViId === true,
                        })}
                        disabled={
                          (this.props.isLoadingData &&
                            this.props.isLoadingData.lanhDaoDonViId === true) ||
                          this.state.isCanUpdateOtherLeaderUnit === false
                        }
                      >
                        <option value=""></option>
                        {otherLeaderUnits.map((item, i) => (
                          <option key={i} value={item.chucDanhId}>
                            {item.tenNhanVien}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                  {this.state.error.lanhDaoDonViPhoiHopId !== undefined && (
                    <div className="pt-form-helper-text">
                      {this.state.error.lanhDaoDonViPhoiHopId}
                    </div>
                  )}
                </div>
              )}
            </div>
          </div>
          {/* PTGĐ phụ trách lĩnh vực thông qua(Đối với tờ trình HĐTV) */}
          {/* {this.props.type === signNumberType.KYSODI &&
            this.state.yeuCauLanhDaoKyPhoiHop && (
              <div className="selectbox-group">
                <div className="heading-group-select disabled-checkbox">
                  <span className="pull-left name_group">
                    PTGĐ phụ trách lĩnh vực thông qua{' '}
                    <span className="text-regular-label">
                      {' '}
                      <i>(Đối với tờ trình HĐTV)</i>{' '}
                    </span>
                  </span>
                </div>
                <div className="container-checkbox">
                  <CheckboxGroup
                    name="lanhDaoKyPhoiHop"
                    options={this.state.lanhDaoKyPhoiHopSelections}
                    selectedOptions={this.state.lanhDaoKyPhoiHopIds.concat(
                      this.state.lanhDaoPhoiHopIds
                    )}
                    onChange={this.handleCheckCompositiveDepartments}
                    isNotCheckDisabledItem
                    isDisabledAll={!this.state.isCanUpdateCompositive}
                    isLoadingData={
                      this.props.isLoadingData &&
                      this.props.isLoadingData.departments
                    }
                    colum="col-xs-6 col-sm-4 col-md-4"
                  />
                </div>
              </div>
            )} */}
          {/* Các LĐ khác cùng ký: tạm thời ẩn đi */}
          {/*<div className="selectbox-group">
            <div className="heading-group-select disabled-checkbox">
              <span className="pull-left name_group">Các LĐ khác cùng ký</span>
              <Checkbox
                className="pull-right"
                name="allEqualUnits"
                checked={this.state.isCheckedAllUnitsEqual}
                onChange={this.handleCheckAllEqualUnits}
              >
                <span className="name_checkbox">Chọn tất cả</span>
              </Checkbox>
            </div>
            <div className="container-checkbox">
              <CheckboxGroup
                name="donViCungKy"
                options={this.state.unitsEqualSelections}
                selectedOptions={this.state.donViCungKyIds}
                onChange={this.handleCheckEqualUnits}
                colum="col-xs-6 col-sm-6 col-md-4"
              />
            </div>
          </div>*/}
          {/* Phòng ban chuyển đến */}
          {/* {this.props.type === signNumberType.KYSONOIBO &&
            <div className="selectbox-group">
              <div className="heading-group-select disabled-checkbox">
                <span className="pull-left name_group">
                  Phòng ban chuyển đến
                  <span className="text-regular-label"> (Ngoài các phòng ban ký phối hợp ở trên)</span>
                </span>
              </div>
              <div className="container-checkbox">
                <CheckboxGroup
                  name="chuyenDen"
                  options={this.state.receiveDepartmentSelections}
                  selectedOptions={this.state.chuyenDenIds}
                  onChange={this.handleMoveToDepartmentSelection}
                  disabledList={this.state.phoiHopIds.concat(this.state.disabledList, this.state.lanhDaoPhoiHopIds)}
                  isNotCheckDisabledItem
                  colum="col-xs-6 col-sm-4 col-md-4"
                />
              </div>
            </div>
          } */}
          {/* Phong ban don vi chuyen den */}
          {/* {this.props.type === signNumberType.KYSONOIBO && this.props.isAllowShowDepartmentsOfOtherUnits === true &&
            <div className="selectbox-group name_group">
              <label className="pt-control pt-checkbox pt-label">
                <input
                  type="checkbox"
                  name="isChooseReceivedDepartment"
                  onChange={this.handleToogleOtherUnits.bind(this, ['chuyenDenPhongBanDonViKhacIds'], 'selectedReceivedDepartmentOtherUnits')}
                  checked={this.state.isChooseReceivedDepartment}
                />
                <span className="pt-control-indicator"></span>
                <span className="name_group">
                  Phòng ban đơn vị chuyển đến
                </span>
              </label>
            </div>
          }
          {this.props.type === signNumberType.KYSONOIBO && this.props.isAllowShowDepartmentsOfOtherUnits === true && this.state.isChooseReceivedDepartment &&
            <Collapse isOpen={this.state.isChooseReceivedDepartment}>
              <ListUnitRadioGroupTwoColumns
                data={this.props.electricUnits}
                requestApi={this.props.actions.commonFetchDepartments}
                onCheckDepartment={this.checkReceivedDepartmentUnit}
                selectedList={this.state.chuyenDenPhongBanDonViKhacIds}
                selectedListObject={this.state.selectedReceivedDepartmentOtherUnits}
                disabledUnitsList={Object.keys(this.state.selectedDepartmentOtherUnits)}
                mainUnitId={this.props.mainUnitId}
                conditionField="phongBanLanhDao"
                conditionValue={true}
              />
            </Collapse>
          } */}
          {/* Trinh don vi cap tren*/}
          {/* Doi checkbox sang collapse arrow */}
          {/* { this.props.type === signNumberType.KYSONOIBO &&
            this.props.parentUnitId !== null &&
            this.props.isCanChoseSignNumberRelativeUnit === true &&
             <div className="selectbox-group">
               <label className="pt-control pt-checkbox pt-inline">
                 <input
                   type="checkbox"
                   name="checkParentUnit"
                   onChange={this.handleToggleCheckboxParent}
                   checked={this.state.isCheckedParentUnit || this.state.isHasSignParentUnit || this.state.isHasReceivedParentDepartment}
                   disabled={this.state.isCanUpdateParentCompositive === false}
                 />
                 <span className="pt-control-indicator"></span>
                 <span className="name_checkbox">Trình đơn vị cấp trên</span>
               </label>
             </div>
          } */}
          {/* Doi checkbox sang collapse arrow */}
          {this.props.type === signNumberType.KYSONOIBO &&
            this.props.parentUnitId !== null &&
            this.props.isCanChoseSignNumberRelativeUnit === true && (
              <div className="heading-group-select disabled-checkbox">
                <label className="pt-control pt-checkbox pt-label pt0 pl0">
                  <span
                    onClick={this.handleToggleCheckboxParent}
                    className={classnames('name_group_unit text-bold', {
                      'pt-icon pt-icon-chevron-up':
                        this.state.isCheckedParentUnit,
                      'pt-icon pt-icon-chevron-down':
                        !this.state.isCheckedParentUnit,
                    })}
                  ></span>
                  <span
                    className="name_group"
                    onClick={this.handleToggleCheckboxParent}
                  >
                    Trình đơn vị cấp trên
                  </span>
                </label>
              </div>
            )}
          {this.props.type === signNumberType.KYSONOIBO &&
            this.props.parentUnitId !== null &&
            this.props.isCanChoseSignNumberRelativeUnit === true && (
              <Collapse isOpen={this.state.isCheckedParentUnit}>
                <div className="box-parent-unit">
                  {/* Phong ban don vi cap tren phoi hop */}
                  <div className="selectbox-group">
                    <div className="heading-group-select disabled-checkbox">
                      <span className="pull-left name_group">
                        Phòng ban cấp trên ký phối hợp và chuyển đến
                        <span className="text-regular-label">
                          {' '}
                          (Trong ngoặc là trường ký của phòng ban)
                        </span>
                      </span>
                    </div>
                    {/* <div className="container-checkbox">
                    <CheckboxGroup
                      name="phoiHopCapTren"
                      options={this.state.parentDepartmentSelections}
                      selectedOptions={this.state.phoiHopCapTrenIds}
                      onChange={this.handleCheckParentCompositiveDepartments}
                      isDisabledAll={!this.state.isCanUpdateParentCompositive}
                      disabledList={this.state.parentLeaderUnitDisabledList}
                      isNotCheckDisabledItem
                      colum="col-xs-6 col-sm-4 col-md-4"
                    />
                  </div> */}
                    <CheckboxGroupTwoColums
                      options={this.state.parentDepartmentSelections}
                      onSubmit={
                        this.handleGetValueGridCheckBoxParentCompositive
                      }
                      listDistabledData1={this.state.chuyenDenPhongBanCapTrenIds.concat(
                        this.state.parentLeaderUnitDisabledList
                      )}
                      listDistabledData2={this.state.phoiHopCapTrenIds.concat(
                        this.state.parentLeaderUnitDisabledList
                      )}
                      listSelectedData1={this.state.phoiHopCapTrenIds}
                      listSelectedData2={this.state.chuyenDenPhongBanCapTrenIds}
                      isDisabledAllData1={
                        !this.state.isCanUpdateParentCompositive
                      }
                      isLoadingData={
                        this.props.isLoadingData &&
                        this.props.isLoadingData.parentDepartments
                      }
                      titles={['KÝ PHỐI HƠP', 'CHUYỂN ĐẾN']}
                    />
                  </div>
                  {/* Trinh lanh dao don vi cap tren */}
                  <div className="pt-form-group pt-intent-danger">
                    <div className="row">
                      <div className="col-md-6 col-xs-6 col-sm-6 colcus-mb-50">
                        <label className="pt-label">
                          Trình lãnh đạo đơn vị cấp trên
                          <span className="text-regular-label">
                            {' '}
                            (Trường ký là{' '}
                            <span className="text-bold-label">ct_chuky</span>)
                          </span>
                        </label>
                        <div className="pt-form-content">
                          <div className="pt-select">
                            <select
                              name="lanhDaoDonViCapTrenId"
                              onChange={this.handleInputChange}
                              value={this.state.form.lanhDaoDonViCapTrenId}
                              className={classnames({
                                'loading-input':
                                  this.props.isLoadingData &&
                                  this.props.isLoadingData
                                    .lanhDaoDonViCapTrenId === true,
                              })}
                              disabled={
                                (this.props.isLoadingData &&
                                  this.props.isLoadingData
                                    .lanhDaoDonViCapTrenId === true) ||
                                this.state.isCanUpdateParentLeaderUnit === false
                              }
                            >
                              <option value=""></option>
                              {this.props.parentUnitsLeaders.map((item, i) => (
                                <option key={i} value={item.chucDanhId}>
                                  {item.tenNhanVien}
                                </option>
                              ))}
                            </select>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </Collapse>
            )}
          {/* Gui ban giay */}
          <div className="selectbox-group">
            <label className="pt-control pt-checkbox pt-inline">
              <input
                type="checkbox"
                name="guiBanGiay"
                onChange={this.handleInputChange}
                checked={this.state.form.guiBanGiay}
              />
              <span className="pt-control-indicator"></span>
              <span className="name_checkbox">Gửi bản giấy</span>
            </label>
          </div>
        </div>
        <div id="groupButton" className="buttton-action-footer">
          <div className="row">
            <div className="col-xs-12">
              <div className="full-mobile">
                <Button
                  onClick={this.handleClickCancel}
                  className="pt-button full-btn-left btn-cancel"
                  disabled={this.state.isLoading}
                >
                  <span className=" pt-icon icon-back"></span>
                  <span className="text-content">Quay lại</span>
                </Button>
                <Button
                  onClick={this.handlePreview}
                  className="pt-button btn-save full-btn-right mr20"
                  disabled={
                    this.state.isLoading === true ||
                    size(contentFiles) === 0 ||
                    this.state.isPreviewLoading === true
                  }
                  loading={this.state.isPreviewLoading}
                >
                  <span className="text-content">Preview</span>
                  <span className="pt-icon icon-Xemthuchien font-size-10"></span>
                </Button>
                <Button
                  onClick={this.handleSubmit}
                  className="pt-button btn-save full-btn-right"
                  disabled={
                    Object.values(this.props.isLoadingData).find(
                      item => item === true
                    ) !== undefined || this.state.isLoading === true
                  }
                  loading={this.state.isLoading}
                >
                  <span className="text-content">Lưu</span>
                  <span className="pt-icon icon-save"></span>
                </Button>
              </div>
            </div>
          </div>
        </div>
        {/* Button hiển thị bên góc phải */}
        <div className="buttton-actionfooter-fixtop buttton-action-footer">
          <Button
            className="pt-button btn-blue-color"
            onClick={this.handleSubmit}
            disabled={
              Object.values(this.props.isLoadingData).find(
                item => item === true
              ) !== undefined || this.state.isLoading === true
            }
            loading={this.state.isLoading}
          >
            <span className="pt-icon icon-save"></span>
            <span className="text-content">Lưu</span>
          </Button>
          <Button
            className="pt-button btn-blue-color"
            onClick={this.handlePreview}
            disabled={
              this.state.isLoading === true ||
              size(contentFiles) === 0 ||
              this.state.isPreviewLoading === true
            }
            loading={this.state.isPreviewLoading}
          >
            <span className="pt-icon icon-Xemthuchien font-size-10"></span>
            <span className="text-content">Preview</span>
          </Button>
          <Button
            className="pt-button btn-cancel"
            onClick={this.handleClickCancel}
            disabled={this.state.isLoading}
          >
            <span className=" pt-icon icon-back"></span>
            <span className="text-content">Quay lại</span>
          </Button>
        </div>
        {/*<PopupConfirm
          isOpen={this.state.isOpenPopup}
          onClose={this.handleClickPopup}
          title={POPUP.HUY_TAO_CONG_VAN_DI_KY_SO}
          text={POPUP.TEXT_BUTTON_SUBMIT}
          onSubmit={this.handleClickCancel}
        >
          {POPUP.TEXT_BODY}
        </PopupConfirm>*/}
        {this.state.isRedirect && (
          <Redirect
            push
            to={{
              pathname: this.state.redirectTo,
            }}
          />
        )}
        <PrevewFileTrinhKy
          isOpenDialog={this.state.isOpenDialog}
          closeDialog={this.handlePreview}
          file={fileTrinhKy}
        />
      </div>
    )
  }
}

export default withRouter(InputForm)
