// Document
const CONGVAN = 'CONGVAN'
const CONGVAN_XEMTATCA = 'CONGVAN_XEMTATCA'
const CONGVAN_XEMPHONGBANKHAC = 'CONGVAN_XEMPHONGBANKHAC'
const CONGVANDEN = 'CONGVANDEN'
const CONGVANDEN_QUANLY = 'CONGVANDEN_QUANLY'
const CONGVANDEN_CHUYENCHIDAO = 'CONGVANDEN_CHUYENCHIDAO'
const CONGVANDEN_CHIDAO = 'CONGVANDEN_CHIDAO'
const CONGVANDEN_CHIDAO_DVTRUCTHUOC = 'CONGVANDEN_CHIDAO_DVTRUCTHUOC'
const CONGVANDEN_DEXUATCHIDAO = 'CONGVANDEN_DEXUATCHIDAO'
const CONGVANDEN_LICHSUCHIDAO = 'CONGVANDEN_LICHSUCHIDAO'
const CONGVANDEN_LICHSUCONGVAN = 'CONGVANDEN_LICHSUCONGVAN'
const CONGVANDI = 'CONGVANDI'
const CONGVANDI_QUANLY = 'CONGVANDI_QUANLY'
const CONGVANNOIBO = 'CONGVANNOIBO'
const CONGVANNOIBO_QUANLY = 'CONGVANNOIBO_QUANLY'

// Work
const CONGVIEC = 'CONGVIEC'
const CONGVIEC_PHONGBAN = 'CONGVIEC_PHONGBAN'
const CONGVIEC_PHONGBAN_HOANTAT = 'CONGVIEC_PHONGBAN_HOANTAT'
const CONGVIEC_CANHAN = 'CONGVIEC_CANHAN'
const CONGVIEC_GIAOVIEC = 'CONGVIEC_GIAOVIEC'
const CONGVAN_XEMBAOCAO = 'CONGVAN_XEMBAOCAO'

// Sign number
const KYSO_QUANLY = 'KYSO_QUANLY'
const KYSO_KYBANHANH = 'KYSO_KYBANHANH'
const KYSO_KYDUYET_GOPY = 'KYSO_KYDUYET_GOPY'
const KYSO_CAPSO = 'KYSO_CAPSO'
const KYSO_KHONGXACTHUC = 'KYSO_KHONGXACTHUC'

// Cabinet
const TUTAILIEU = 'TUTAILIEU'
const TUTAILIEUDONVI = 'TUTAILIEUDONVI'
const TUTAILIEUDONVI_QUANLY = 'TUTAILIEUDONVI_QUANLY'
const TUTAILIEUPHONGBAN = 'TUTAILIEUPHONGBAN'
const TUTAILIEUPHONGBAN_QUANLY = 'TUTAILIEUPHONGBAN_QUANLY'

const DANHBA = 'DANHBA'
const HETHONG = 'HETHONG'
const DOKHAN_QUANLY = 'DOKHAN_QUANLY'
const DOBAOMAT_QUANLY = 'DOBAOMAT_QUANLY'
const DOITUONGCONGVAN_QUANLY = 'DOITUONGCONGVAN_QUANLY'
const SOLUUTRU_QUANLY = 'SOLUUTRU_QUANLY'
const ADMIN = 'ADMIN'
const SUPERADMIN = 'SUPERADMIN'
const DUAN_QUANLY = 'DUAN_QUANLY'
const HDTV_LAYYKIEN = 'HDTV_LAYYKIEN'
const HDTV_LAYYKIEN_KYDUYET = 'HDTV_LAYYKIEN_KYDUYET'
const HDTV_LAYYKIEN_THANHVIEN = 'HDTV_LAYYKIEN_THANHVIEN'
const THONGBAO_QUANLY = 'THONGBAO_QUANLY'

//lay y kien don vi
const LAYYKIENDONVI_CHIDAO = 'LAYYKIENDONVI_CHIDAO'
const LAYYKIENDONVI_GIAOVIEC = 'LAYYKIENDONVI_GIAOVIEC'
const LAYYKIENDONVI_PHIEUGUI = 'LAYYKIENDONVI_PHIEUGUI'
const LAYYKIENDONVI_PHIEUNHAN = 'LAYYKIENDONVI_PHIEUNHAN'
const LAYYKIENDONVI_XACNHAN_DONVI = 'LAYYKIENDONVI_XACNHAN_DONVI'
const LAYYKIENDONVI_XACNHAN_PHONGBAN = 'LAYYKIENDONVI_XACNHAN_PHONGBAN'

// Ky so tap trung
const KY_SO_TAP_TRUNG = 'KY_SO_TAP_TRUNG'

// Công văn đi thu hồi
const DEXUAT_THUHOI = 'DEXUAT_THUHOI'
const DONGY_THUHOI = 'DONGY_THUHOI'
const THUHOI_CONGVANDI = 'THUHOI_CONGVANDI'
// Tìm kiếm công văn 6.1
const HSCV_PHONGBAN_DUYET = 'HSCV_PHONGBAN_DUYET'
const HSCV_VANPHONG_DUYET = 'HSCV_VANPHONG_DUYET'
const HSCV_VANTHU = 'HSCV_VANTHU'
// Quản lý công việc
const SUDUNG_QLCV = 'SUDUNG_QLCV'
const THONGBAOKETLUANHDTV = 'THONGBAOKETLUANHDTV'
// Chat
const SUDUNG_CHAT = 'SUDUNG_CHAT'
// Lịch họp
const LICHHOP_CANHAN = 'LICHHOP_CANHAN'
const LICHHOP_HAUCAN = 'LICHHOP_HAUCAN'
const LICHHOP_QUANLY = 'LICHHOP_QUANLY'
const LICHHOP_QUANLY_XEM = 'LICHHOP_QUANLY_XEM'
// Chỉ đạo giao việc
const CONGVANDEN_CHIDAO_GIAOVIEC = 'CONGVANDEN_CHIDAO_GIAOVIEC'

const permissionTree = {
  [CONGVAN]: {
    CONGVAN_XEMTATCA,
    CONGVAN_XEMPHONGBANKHAC,
    [CONGVANDEN]: {
      CONGVANDEN_QUANLY,
      CONGVANDEN_CHUYENCHIDAO,
      CONGVANDEN_CHIDAO,
      CONGVANDEN_CHIDAO_DVTRUCTHUOC,
      CONGVANDEN_DEXUATCHIDAO,
      CONGVANDEN_LICHSUCHIDAO,
      CONGVANDEN_LICHSUCONGVAN,
    },
    [CONGVANDI]: {
      CONGVANDI_QUANLY,
    },
    [CONGVANNOIBO]: {
      CONGVANNOIBO_QUANLY,
    },
  },
  [CONGVIEC]: {
    CONGVIEC_PHONGBAN,
    CONGVIEC_PHONGBAN_HOANTAT,
    CONGVIEC_CANHAN,
    CONGVIEC_GIAOVIEC,
    CONGVAN_XEMBAOCAO,
  },
  [KYSO_QUANLY]: {
    KYSO_KYBANHANH,
    KYSO_KYDUYET_GOPY,
    KYSO_CAPSO,
  },
  [TUTAILIEU]: {
    [TUTAILIEUDONVI]: {
      TUTAILIEUDONVI_QUANLY,
    },
    [TUTAILIEUPHONGBAN]: {
      TUTAILIEUPHONGBAN_QUANLY,
    },
  },
  DANHBA,
  HETHONG,
  DOKHAN_QUANLY,
  DOBAOMAT_QUANLY,
  DOITUONGCONGVAN_QUANLY,
  SOLUUTRU_QUANLY,
  ADMIN,
  SUPERADMIN,
  DUAN_QUANLY,
  KY_SO_TAP_TRUNG,
}

const MENU_SIDEBAR = {
  // Công văn lưu tủ
  SACTION_CVAN_LUUTU: 'SACTION-CVAN_LUUTU',
  // Tủ tài liệu
  SMENU_TTL: 'SMENU-TTL',
  SMENU_TTL_HSCV: 'SMENU-TTL_HSCV',
}

export default {
  CONGVAN,
  CONGVAN_XEMTATCA,
  CONGVAN_XEMPHONGBANKHAC,
  CONGVANDEN,
  CONGVANDEN_QUANLY,
  CONGVANDEN_CHUYENCHIDAO,
  CONGVANDEN_CHIDAO,
  CONGVANDEN_CHIDAO_DVTRUCTHUOC,
  CONGVANDEN_DEXUATCHIDAO,
  CONGVANDEN_LICHSUCHIDAO,
  CONGVANDEN_LICHSUCONGVAN,
  CONGVANDI,
  CONGVANDI_QUANLY,
  CONGVANNOIBO,
  CONGVANNOIBO_QUANLY,
  CONGVIEC,
  CONGVIEC_PHONGBAN,
  CONGVIEC_PHONGBAN_HOANTAT,
  CONGVIEC_CANHAN,
  CONGVAN_XEMBAOCAO,
  KYSO_QUANLY,
  KYSO_KYBANHANH,
  KYSO_KYDUYET_GOPY,
  KYSO_CAPSO,
  KYSO_KHONGXACTHUC,
  DANHBA,
  TUTAILIEU,
  TUTAILIEUDONVI,
  TUTAILIEUDONVI_QUANLY,
  TUTAILIEUPHONGBAN,
  TUTAILIEUPHONGBAN_QUANLY,
  HETHONG,
  DOKHAN_QUANLY,
  DOBAOMAT_QUANLY,
  DOITUONGCONGVAN_QUANLY,
  SOLUUTRU_QUANLY,
  ADMIN,
  SUPERADMIN,
  permissionTree,
  DUAN_QUANLY,
  KY_SO_TAP_TRUNG,
  HDTV_LAYYKIEN,
  HDTV_LAYYKIEN_KYDUYET,
  HDTV_LAYYKIEN_THANHVIEN,
  LAYYKIENDONVI_CHIDAO,
  LAYYKIENDONVI_GIAOVIEC,
  LAYYKIENDONVI_PHIEUGUI,
  LAYYKIENDONVI_PHIEUNHAN,
  LAYYKIENDONVI_XACNHAN_DONVI,
  LAYYKIENDONVI_XACNHAN_PHONGBAN,
  DEXUAT_THUHOI,
  DONGY_THUHOI,
  THUHOI_CONGVANDI,
  HSCV_PHONGBAN_DUYET,
  HSCV_VANPHONG_DUYET,
  HSCV_VANTHU,
  SUDUNG_QLCV,
  SUDUNG_CHAT,
  MENU_SIDEBAR,
  THONGBAOKETLUANHDTV,
  LICHHOP_CANHAN,
  LICHHOP_HAUCAN,
  LICHHOP_QUANLY,
  LICHHOP_QUANLY_XEM,
  THONGBAO_QUANLY,
  CONGVANDEN_CHIDAO_GIAOVIEC,
  CONGVIEC_GIAOVIEC,
}
