import _ from 'lodash'
import * as types from '../constants/ActionTypes'
import * as utils from '../constants/Api'
import * as Tool from '../helpers'

import {
  UserSchema,
  ReceiveDocumentSchema,
  ReceiveDocumentSchemaItem,
  SendDocumentSchema,
  ExecutingViewSchema,
  DigitalDocumentArraySchema,
  PersonalWorkSchema,
  DepartmentWorkSchema,
  DepartmentWorkAssignedSchema,
  SignNumberSchema,
  SignNumberItemSchema,
  SignNumberFeedbackSchema,
  SignNumberProjectMemberArraySchema,
  SignNumberProjectArraySchema,
  SignNumberProjectItemSchema,
  ProjectArraySchema,
  DepartmentWorkSchemaItem,
  // AssignmentHistorySchema,
  InternalDocumentSchema,
  AssignmentInformationSchema,
  PersonalWorkSchemaItem,
  SendDocumentItemSchema,
  ReportWorkItem,
  DocumentCabinetArraySchema,
  DocumentCabinetCategoryArraySchema,
  InternalDocumentSchemaItem,
  ExecutingViewInternalDocumentSchema,
  DocumentCabinetSchema,
  FileArraySchema,
  UserArraySchema,
  AlertMistakeArraySchema,
  FlowdocumentReceivedocumentSchema,
  EvnDocumentArraySchema,
  AlertMistakeDocumentArraySchema,
  RequestCommentSchema,
  RequestCommentItemSchema,
  // SaveCodeItem
} from '../schemas'

export const authLogin = login => {
  login.grant_type = 'password'
  login.scope = 'eoffice offline_access'

  return {
    types: [
      types.API_REQUEST_SEND,
      types.AUTH_SET_TOKEN,
      types.AUTH_DISCARD_TOKEN,
    ],
    payload: {
      client: 'auth',
      request: {
        url: '/connect/token',
        method: 'POST',
        headers: {
          Authorization: 'Basic ZW9mZmljZS5yby5jbGllbnQ6dGN4LnNlY3JldA==',
        },
        data: login,
      },
    },
  }
}

export const setCabinetType = cabinetType => ({
  type: types.CONFIG_CABINET_TYPE,
  cabinetType,
})
export const refreshPage = status => ({
  type: types.CONFIG_PAGE_REFRESH,
  status,
})
export const refreshSendSignNumber = status => ({
  type: types.CONFIG_REFRESH_SEND_SIGN_NUMBER,
  status,
})
export const refreshInternalSignNumber = status => ({
  type: types.CONFIG_REFRESH_INTERNAL_SIGN_NUMBER,
  status,
})
export const refreshSendDocument = status => ({
  type: types.CONFIG_REFRESH_SEND_DOCUMENT,
  status,
})
export const refreshDocumentCabinet = status => ({
  type: types.CONFIG_REFRESH_DOCUMENT_CABINET,
  status,
})
export const authLogout = () => ({ type: types.AUTH_DISCARD_TOKEN })
export const clearUsers = () => ({ type: types.CLEAR_USERS })
export const refreshStatistic = status => ({
  type: types.CONFIG_STATISTIC_REFRESH,
  status,
})
export const refreshServiceDocument = status => ({
  type: types.CONFIG_REFRESH_SERVICE_DOCUMENT,
  status,
})
export const refreshOpinion = status => ({
  type: types.CONFIG_REFRESH_OPINION,
  status,
})
export const refreshRevokeDocument = status => ({
  type: types.CONFIG_REFRESH_REVOKE_DOCUMENT,
  status,
})

export const setTokenWithQuery = token => ({
  type: types.AUTH_SET_TOKEN,
  payload: {
    data: token,
  },
})

export const userAuthenticated =
  (roleId = null) =>
  (dispatch, getState) =>
    dispatch({
      types: [
        types.API_REQUEST_SEND,
        types.AUTH_SET_USER,
        types.AUTH_DISCARD_TOKEN,
      ],
      schema: UserSchema,
      payload: {
        client: 'default',
        request: {
          url: '/api/me',
          method: 'GET',
          headers: {
            Authorization:
              getState().auth.token.tokenType +
              ' ' +
              getState().auth.token.accessToken,
          },
        },
      },
      roleId,
    })

export const userPermission = id => (dispatch, getState) =>
  dispatch({
    types: [
      types.API_REQUEST_SEND,
      types.AUTH_GET_PERMISSION,
      types.AUTH_DISCARD_TOKEN,
    ],
    payload: {
      request: {
        url: `/api/quyen/nhanvien/${id}`,
        method: 'GET',
        headers: {
          Authorization:
            getState().auth.token.tokenType +
            ' ' +
            getState().auth.token.accessToken,
        },
      },
    },
  })

export const userPermissionDocument = id => (dispatch, getState) =>
  dispatch({
    types: [
      types.API_REQUEST_SEND,
      types.AUTH_GET_PERMISSION_DOCUMENT,
      types.AUTH_DOCUMENT_DISCARD_TOKEN,
    ],
    payload: {
      request: {
        url: `/api/quyen/nhanvien/${id}`,
        method: 'GET',
        headers: {
          Authorization:
            getState().auth.token.tokenType +
            ' ' +
            getState().auth.token.accessToken,
        },
      },
    },
  })

export const contactFetchDepartmentByUnit = unitId => (dispatch, getState) =>
  dispatch({
    types: [
      types.API_REQUEST_SEND,
      types.CONTACT_FETCH_DEPARTMENTS,
      types.API_REQUEST_ERROR,
    ],
    unitId: unitId,
    payload: {
      request: {
        url: `/api/donvi/${unitId}/phongban`,
        method: 'GET',
        headers: {
          Authorization:
            getState().auth.token.tokenType +
            ' ' +
            getState().auth.token.accessToken,
        },
      },
    },
  })

export const contactFetchDepartmentAndPersonalTree =
  unitId => (dispatch, getState) =>
    dispatch({
      types: [
        types.API_REQUEST_SEND,
        types.CONTACT_DEPARTMENT_AND_PERSONAL_TREE,
        types.API_REQUEST_ERROR,
      ],
      payload: {
        request: {
          url: `/api/donvi/${unitId}/danhba/chidao`,
          method: 'GET',
          headers: {
            Authorization:
              getState().auth.token.tokenType +
              ' ' +
              getState().auth.token.accessToken,
          },
        },
      },
    })

export const contactFetchUnits =
  (
    params = {
      page: 1,
      limit: 0,
    }
  ) =>
  (dispatch, getState) =>
    dispatch({
      types: [
        types.API_REQUEST_SEND,
        types.CONTACT_FETCH_UNITS,
        types.API_REQUEST_ERROR,
      ],
      payload: {
        request: {
          url: '/api/donvi',
          method: 'GET',
          headers: {
            Authorization:
              getState().auth.token.tokenType +
              ' ' +
              getState().auth.token.accessToken,
          },
          params,
        },
      },
    })

export const contactFetchUnitsEqual = id => (dispatch, getState) =>
  dispatch({
    types: [
      types.API_REQUEST_SEND,
      types.CONTACT_FETCH_UNITS_EQUAL,
      types.API_REQUEST_ERROR,
    ],
    payload: {
      request: {
        url: `/api/donvi/${id}/ngangcap`,
        method: 'GET',
        headers: {
          Authorization:
            getState().auth.token.tokenType +
            ' ' +
            getState().auth.token.accessToken,
        },
      },
    },
  })

export const receiveDocumentGet =
  (filter = {}, page = 1, sort = null, refresh = false, filterColumn = {}) =>
  (dispatch, getState) => {
    let params = {
      refresh,
      sort,
      page: page,
      limit: utils.PAGINATION_LIMIT,
    }

    if (filter.filter) {
      if (filter.filter.status === undefined) {
        params.is_congvanden_tab = true
      }
      if (filter.filter.status !== 0) {
        filter.filter = {
          ...filter.filter,
          ngayCongVan: {
            from: filter.filter.ngayCapNhat.from,
            to: filter.filter.ngayCapNhat.to,
          },
          ngayCapNhat: {
            from: null,
            to: null,
          },
        }
      }
    }

    params = Object.assign(Tool.Utils.linkObjectKey(filter), params)
    params = Object.assign(
      Tool.Utils.linkObjectKey({ filter_column: filterColumn }),
      params
    )

    return dispatch({
      types: [
        types.API_REQUEST_INIT_DOCUMENT_RECEIVE_SEND,
        types.DOCUMENT_RECEIVE,
        types.API_REQUEST_ERROR,
      ],
      schema: ReceiveDocumentSchema,
      page: page,
      payload: {
        request: {
          url: '/api/congvanden',
          method: 'GET',
          headers: {
            'api-version': 2.0,
            Authorization:
              getState().auth.token.tokenType +
              ' ' +
              getState().auth.token.accessToken,
          },
          params,
        },
      },
    })
  }

export const sendDocumentGet =
  (filter = {}, page = 1, sort, refresh = false, filterColumn = {}) =>
  (dispatch, getState) => {
    let params = {
      refresh,
      sort,
      page: page,
      limit: utils.PAGINATION_LIMIT,
    }

    params = Object.assign(params, Tool.Utils.linkObjectKey(filter))
    params = Object.assign(Tool.Utils.linkObjectKey({ filterColumn }), params)
    return dispatch({
      types: [
        types.API_REQUEST_SEND,
        types.DOCUMENT_SEND,
        types.API_REQUEST_ERROR,
      ],
      schema: SendDocumentSchema,
      page: page,
      payload: {
        request: {
          url: '/api/congvandi',
          method: 'GET',
          headers: {
            'api-version': 2.0,
            Authorization:
              getState().auth.token.tokenType +
              ' ' +
              getState().auth.token.accessToken,
          },
          params,
        },
      },
    })
  }

export const sendDocumentDetail = id => (dispatch, getState) =>
  dispatch({
    types: [
      types.API_REQUEST_SEND,
      types.DOCUMENT_SEND_DETAIL,
      types.API_REQUEST_ERROR,
    ],
    schema: SendDocumentItemSchema,
    payload: {
      request: {
        url: `/api/congvandi/${id}/rutgon`,
        method: 'GET',
        headers: {
          'api-version': 2.0,
          Authorization:
            getState().auth.token.tokenType +
            ' ' +
            getState().auth.token.accessToken,
        },
      },
    },
  })

export const sendDocumentDetailItem = id => (dispatch, getState) =>
  dispatch({
    types: [
      types.API_REQUEST_SEND,
      types.DOCUMENT_SEND_DETAIL,
      types.API_REQUEST_ERROR,
    ],
    payload: {
      request: {
        url: `/api/congvandi/${id}`,
        method: 'GET',
        headers: {
          Authorization:
            getState().auth.token.tokenType +
            ' ' +
            getState().auth.token.accessToken,
        },
      },
    },
  })

export const internalDocumentGet =
  (
    filter = {},
    page = 1,
    sort,
    refresh = false,
    filterColumn = {},
    isSearchNoiBoDi = true
  ) =>
  (dispatch, getState) => {
    let params = {
      refresh,
      sort,
      page: page,
      limit: utils.PAGINATION_LIMIT,
      is_search_noi_bo_di: isSearchNoiBoDi,
    }

    params = Object.assign(params, Tool.Utils.linkObjectKey(filter))
    params = Object.assign(
      Tool.Utils.linkObjectKey({ filter_column: filterColumn }),
      params
    )
    return dispatch({
      types: [
        types.API_REQUEST_SEND,
        types.DOCUMENT_INTERNAL_GET,
        types.API_REQUEST_ERROR,
      ],
      schema: InternalDocumentSchema,
      page: page,
      payload: {
        request: {
          url: '/api/congvannoibo',
          method: 'GET',
          headers: {
            'api-version': 2.0,
            Authorization:
              getState().auth.token.tokenType +
              ' ' +
              getState().auth.token.accessToken,
          },
          params,
        },
      },
    })
  }

export const receiveDocumentDelete = id => (dispatch, getState) =>
  dispatch({
    types: [
      types.API_REQUEST_SEND,
      types.DOCUMENT_SEND_DELETE,
      types.API_REQUEST_ERROR,
    ],
    payload: {
      request: {
        url: `/api/congvanden/${id}`,
        method: 'DELETE',
        headers: {
          Authorization:
            getState().auth.token.tokenType +
            ' ' +
            getState().auth.token.accessToken,
        },
      },
    },
  })

export const sendDocumentDelete = (id, data) => (dispatch, getState) =>
  dispatch({
    types: [
      types.API_REQUEST_SEND,
      types.DOCUMENT_SEND_DELETE,
      types.API_REQUEST_ERROR,
    ],
    payload: {
      request: {
        url: `/api/congvandi/${id}?ly_do_xoa=${data}`,
        method: 'DELETE',
        headers: {
          Authorization:
            getState().auth.token.tokenType +
            ' ' +
            getState().auth.token.accessToken,
        },
      },
    },
  })

export const getReceiveDocumentItem = id => (dispatch, getState) =>
  dispatch({
    types: [
      types.API_REQUEST_SEND,
      types.RECEIVE_DOCUMENT_ITEM,
      types.API_REQUEST_ERROR,
    ],
    schema: ReceiveDocumentSchemaItem,
    payload: {
      request: {
        url: `/api/congvanden/${id}`,
        method: 'GET',
        headers: {
          Authorization:
            getState().auth.token.tokenType +
            ' ' +
            getState().auth.token.accessToken,
        },
      },
    },
  })

// CHANGED: Đổi api /api/congvanden/${id} => /api/congvanden/${id}/rutgon
export const getReceiveDocumentItemShorten = id => (dispatch, getState) =>
  dispatch({
    types: [
      types.API_REQUEST_SEND,
      types.RECEIVE_DOCUMENT_ITEM_SHORTEN,
      types.API_REQUEST_ERROR,
    ],
    schema: ReceiveDocumentSchemaItem,
    payload: {
      request: {
        url: `/api/congvanden/${id}/rutgon`,
        method: 'GET',
        headers: {
          Authorization:
            getState().auth.token.tokenType +
            ' ' +
            getState().auth.token.accessToken,
        },
      },
    },
  })

export const personalWorkGet =
  (filter = {}, page = 1, sort, refresh = false, filterColumn = {}) =>
  (dispatch, getState) => {
    let params = {
      refresh,
      sort,
      page: page,
      limit: utils.PAGINATION_LIMIT,
    }

    params = Object.assign(Tool.Utils.linkObjectKey(filter), params)
    params = Object.assign(
      Tool.Utils.linkObjectKey({ filter_column: filterColumn }),
      params
    )

    return dispatch({
      types: [
        types.PERSONAL_WORK_REQUEST_SEND,
        types.PERSONAL_WORK,
        types.API_REQUEST_ERROR,
      ],
      schema: PersonalWorkSchema,
      page: page,
      payload: {
        request: {
          url: `/api/nhanvien/congviec`,
          method: 'GET',
          headers: {
            'api-version': 2.0,
            Authorization:
              getState().auth.token.tokenType +
              ' ' +
              getState().auth.token.accessToken,
          },
          params,
        },
      },
    })
  }

export const departmentWorkAssignedGet =
  (
    filter = {},
    page = 1,
    sort = null,
    isCaNhanDaGiao = false,
    refresh = false,
    filterColumn
  ) =>
  (dispatch, getState) => {
    let params = {
      sort,
      page,
      refresh,
      limit: utils.PAGINATION_LIMIT,
      is_ca_nhan_da_giao: isCaNhanDaGiao,
    }

    params = Object.assign(params, Tool.Utils.linkObjectKey(filter))
    params = Object.assign(
      Tool.Utils.linkObjectKey({ filter_column: filterColumn }),
      params
    )

    return dispatch({
      types: [
        types.API_REQUEST_SEND,
        types.DEPARTMENT_WORK_ASSIGNED,
        types.API_REQUEST_ERROR,
      ],
      schema: DepartmentWorkAssignedSchema,
      page: page,
      payload: {
        request: {
          url: `/api/phongban/congviecdagiao`,
          method: 'GET',
          headers: {
            'api-version': 2.0,
            Authorization:
              getState().auth.token.tokenType +
              ' ' +
              getState().auth.token.accessToken,
          },
          params,
        },
      },
    })
  }

export const departmentPersionalWorkAssignedGet =
  (
    filter = {},
    page = 1,
    sort = null,
    isCaNhanDaGiao = false,
    refresh = false,
    filterColumn
  ) =>
  (dispatch, getState) => {
    let params = {
      sort,
      page,
      refresh,
      limit: utils.PAGINATION_LIMIT,
      is_ca_nhan_da_giao: isCaNhanDaGiao,
    }

    params = Object.assign(params, Tool.Utils.linkObjectKey(filter))
    params = Object.assign(
      Tool.Utils.linkObjectKey({
        filter_column: filterColumn,
      }),
      params
    )

    return dispatch({
      types: [
        types.API_REQUEST_SEND,
        types.DEPARTMENT_WORK_ASSIGNED,
        types.API_REQUEST_ERROR,
      ],
      schema: DepartmentWorkAssignedSchema,
      page: page,
      payload: {
        request: {
          url: `/api/phongban/congviecdagiao`,
          method: 'GET',
          headers: {
            'api-version': 1.0,
            Authorization:
              getState().auth.token.tokenType +
              ' ' +
              getState().auth.token.accessToken,
          },
          params,
        },
      },
    })
  }

export const departmentWorkNotAssignGet =
  (filter = {}, page = 1, sort, refresh = false, filterColumn = {}) =>
  (dispatch, getState) => {
    let params = {
      sort,
      page,
      refresh,
      limit: utils.PAGINATION_LIMIT,
    }

    params = Object.assign(Tool.Utils.linkObjectKey(filter), params)
    params = Object.assign(
      Tool.Utils.linkObjectKey({ filter_column: filterColumn }),
      params
    )

    return dispatch({
      types: [
        types.API_REQUEST_SEND,
        types.DEPARTMENT_WORK_NOT_ASSIGN,
        types.API_REQUEST_ERROR,
      ],
      schema: DepartmentWorkSchema,
      page: page,
      payload: {
        request: {
          url: `/api/phongban/congviecchuagiao`,
          method: 'GET',
          headers: {
            'api-version': '2.0',
            Authorization:
              getState().auth.token.tokenType +
              ' ' +
              getState().auth.token.accessToken,
          },
          params,
        },
      },
    })
  }

export const signNumberInternalGet =
  (
    filter = {},
    page = 1,
    sort,
    canKySo = true,
    limit = utils.PAGINATION_LIMIT,
    refresh = false,
    filterColumn
  ) =>
  (dispatch, getState) => {
    let params = {
      refresh,
      sort,
      page: page,
      limit: limit,
      canKySo: canKySo,
    }

    params = Object.assign(params, Tool.Utils.linkObjectKey(filter))
    params = Object.assign(
      Tool.Utils.linkObjectKey({ filter_column: filterColumn }),
      params
    )

    return dispatch({
      types: [
        types.API_REQUEST_SEND_SIGNNUMBER,
        types.SIGN_NUMBER_INTERNAL,
        types.API_REQUEST_ERROR,
      ],
      schema: SignNumberSchema,
      page: page,
      payload: {
        request: {
          url: `/api/kysonoibo`,
          method: 'GET',
          headers: {
            Authorization:
              getState().auth.token.tokenType +
              ' ' +
              getState().auth.token.accessToken,
            'api-version': 2.1,
          },
          params,
        },
      },
    })
  }

export const signNumberSendGet =
  (
    filter = {},
    page = 1,
    sort = null,
    canKySo = true,
    limit = utils.PAGINATION_LIMIT,
    refresh = false,
    filterColumn
  ) =>
  (dispatch, getState) => {
    let params = {
      refresh,
      sort,
      page: page,
      limit: limit,
      canKySo: canKySo,
    }

    params = Object.assign(params, Tool.Utils.linkObjectKey(filter))
    params = Object.assign(
      Tool.Utils.linkObjectKey({ filter_column: filterColumn }),
      params
    )

    return dispatch({
      types: [
        types.API_REQUEST_SEND_SIGNNUMBER,
        types.SIGN_NUMBER_SEND,
        types.API_REQUEST_ERROR,
      ],
      schema: SignNumberSchema,
      page: page,
      payload: {
        request: {
          url: `/api/kysodi`,
          method: 'GET',
          headers: {
            Authorization:
              getState().auth.token.tokenType +
              ' ' +
              getState().auth.token.accessToken,
            'api-version': 2.1,
          },
          params,
        },
      },
    })
  }

export const signNumberDetail = id => (dispatch, getState) =>
  dispatch({
    types: [
      types.API_REQUEST_SEND,
      types.SIGN_NUMBER_DETAIL,
      types.API_REQUEST_ERROR,
    ],
    schema: SignNumberItemSchema,
    payload: {
      request: {
        url: `/api/kysodi/${id}`,
        method: 'GET',
        headers: {
          Authorization:
            getState().auth.token.tokenType +
            ' ' +
            getState().auth.token.accessToken,
        },
      },
    },
  })

export const signNumberInternalDetail = id => (dispatch, getState) =>
  dispatch({
    types: [
      types.API_REQUEST_SEND,
      types.SIGN_NUMBER_INTERNAL_DETAIL,
      types.API_REQUEST_ERROR,
    ],
    schema: SignNumberItemSchema,
    payload: {
      request: {
        url: `/api/kysonoibo/${id}`,
        method: 'GET',
        headers: {
          Authorization:
            getState().auth.token.tokenType +
            ' ' +
            getState().auth.token.accessToken,
        },
      },
    },
  })

export const signNumberFeedbackGet =
  (id, page = 1) =>
  (dispatch, getState) =>
    dispatch({
      types: [
        types.API_REQUEST_SEND,
        types.SIGN_NUMBER_DETAIL_FEEDBACK,
        types.API_REQUEST_ERROR,
      ],
      schema: SignNumberFeedbackSchema,
      page: page,
      payload: {
        request: {
          url: `/api/kysodi/${id}/gopy`,
          method: 'GET',
          headers: {
            Authorization:
              getState().auth.token.tokenType +
              ' ' +
              getState().auth.token.accessToken,
          },
          params: {
            page: page,
            limit: 50,
          },
        },
      },
    })

export const signNumberFeedbackPost = (id, data) => (dispatch, getState) =>
  dispatch({
    types: [
      types.API_REQUEST_SEND,
      types.SIGN_NUMBER_DETAIL_FEEDBACK_POST,
      types.API_REQUEST_ERROR,
    ],
    schema: SignNumberFeedbackSchema,
    payload: {
      request: {
        url: `/api/kysodi/${id}/gopy`,
        method: 'POST',
        headers: {
          Authorization:
            getState().auth.token.tokenType +
            ' ' +
            getState().auth.token.accessToken,
        },
        data: data,
      },
    },
  })

export const signNumberCreate =
  (data = {}) =>
  (dispatch, getState) =>
    dispatch({
      types: [
        types.API_REQUEST_SEND,
        types.SIGN_NUMBER_SEND_CREATE,
        types.API_REQUEST_ERROR,
      ],
      schema: SignNumberSchema,
      payload: {
        request: {
          url: `/api/kysodi`,
          method: 'POST',
          headers: {
            Authorization:
              getState().auth.token.tokenType +
              ' ' +
              getState().auth.token.accessToken,
          },
          data: data,
        },
      },
    })

export const signNumberInternalCreate =
  (data = {}) =>
  (dispatch, getState) =>
    dispatch({
      types: [
        types.API_REQUEST_SEND,
        types.SIGN_NUMBER_INTERNAL_CREATE,
        types.API_REQUEST_ERROR,
      ],
      payload: {
        request: {
          url: '/api/kysonoibo',
          method: 'POST',
          headers: {
            Authorization:
              getState().auth.token.tokenType +
              ' ' +
              getState().auth.token.accessToken,
          },
          data: data,
        },
      },
    })

export const commonSignNumberDepartmentLeader =
  (id, typeLeader = null) =>
  (dispatch, getState) =>
    dispatch({
      types: [
        types.API_REQUEST_SEND,
        types.COMMON_FETCH_SIGN_NUMBER_DEPARTMENT_LEADER,
        types.API_REQUEST_ERROR,
      ],
      payload: {
        request: {
          url: `/api/donvi/${id}/lanhdaokyso`,
          method: 'GET',
          headers: {
            Authorization:
              getState().auth.token.tokenType +
              ' ' +
              getState().auth.token.accessToken,
          },
        },
      },
      typeLeader,
    })

export const commonPersonalLeaderSignNumber = id => (dispatch, getState) =>
  dispatch({
    types: [
      types.API_REQUEST_SEND,
      types.COMMON_FETCH_PERSONAL_LEADER_SIGN_NUMBER,
      types.API_REQUEST_ERROR,
    ],
    payload: {
      request: {
        url: `/api/phongban/${id}/nhanvien/lanhdao`,
        method: 'GET',
        headers: {
          Authorization:
            getState().auth.token.tokenType +
            ' ' +
            getState().auth.token.accessToken,
        },
      },
    },
  })

export const commonFetchObjects =
  (donViId = null, isAll = false) =>
  (dispatch, getState) =>
    dispatch({
      types: [
        types.API_REQUEST_SEND,
        types.COMMON_FETCH_OBJECTS,
        types.API_REQUEST_ERROR,
      ],
      payload: {
        request: {
          url: '/api/doituongcongvan',
          method: 'GET',
          headers: {
            Authorization:
              getState().auth.token.tokenType +
              ' ' +
              getState().auth.token.accessToken,
          },
          params: {
            donViId,
            isAll,
          },
        },
      },
    })

export const commonFetchSecurities =
  (donViId = null, isAll = false) =>
  (dispatch, getState) =>
    dispatch({
      types: [
        types.API_REQUEST_SEND,
        types.COMMON_FETCH_SECURITIES,
        types.API_REQUEST_ERROR,
      ],
      payload: {
        request: {
          url: '/api/dobaomat',
          method: 'GET',
          headers: {
            Authorization:
              getState().auth.token.tokenType +
              ' ' +
              getState().auth.token.accessToken,
          },
          params: {
            donViId,
            isAll,
          },
        },
      },
    })

export const commonFetchPriorities =
  (donViId = null, isAll = false) =>
  (dispatch, getState) =>
    dispatch({
      types: [
        types.API_REQUEST_SEND,
        types.COMMON_FETCH_PRIORITIES,
        types.API_REQUEST_ERROR,
      ],
      payload: {
        request: {
          url: '/api/dokhan',
          method: 'GET',
          headers: {
            Authorization:
              getState().auth.token.tokenType +
              ' ' +
              getState().auth.token.accessToken,
          },
          params: {
            donViId,
            isAll,
          },
        },
      },
    })

export const commonFetchSaveCodes =
  (page = 1, donViId = null, isAll = false, loai = 1) =>
  (dispatch, getState) =>
    dispatch({
      types: [
        types.API_REQUEST_SEND,
        types.COMMON_FETCH_SAVECODES,
        types.API_REQUEST_ERROR,
      ],
      page: page,
      payload: {
        request: {
          url: '/api/soluu',
          method: 'GET',
          headers: {
            Authorization:
              getState().auth.token.tokenType +
              ' ' +
              getState().auth.token.accessToken,
          },
          params: {
            page: page,
            limit: utils.PAGINATION_LIMIT,
            donViId,
            isAll,
            loai,
          },
        },
      },
    })

export const commonFetchUnitsRelative =
  (id, donViHienTai = true, nhapCongVanDen = false) =>
  (dispatch, getState) =>
    dispatch({
      types: [
        types.API_REQUEST_SEND,
        types.COMMON_FETCH_RELATIVE_UNITS,
        types.API_REQUEST_ERROR,
      ],
      payload: {
        request: {
          url: `/api/donvi/${id}/lienhe`,
          method: 'GET',
          headers: {
            Authorization:
              getState().auth.token.tokenType +
              ' ' +
              getState().auth.token.accessToken,
          },
          params: {
            limit: 0,
            donViHienTai,
            nhapCongVanDen,
          },
        },
      },
    })

export const commonFetchDepartments =
  (
    page = 1,
    donViId = null,
    isAll = false,
    phongBanLanhDao = null,
    isParent = false
  ) =>
  (dispatch, getState) =>
    dispatch({
      types: [
        types.API_REQUEST_SEND,
        types.COMMON_FETCH_DERPATMENTS,
        types.API_REQUEST_ERROR,
      ],
      page: page,
      payload: {
        request: {
          url: '/api/phongban',
          method: 'GET',
          headers: {
            Authorization:
              getState().auth.token.tokenType +
              ' ' +
              getState().auth.token.accessToken,
          },
          params: {
            page: page,
            limit: 0,
            donViId,
            isAll,
            phongBanLanhDao,
          },
        },
      },
      donViId,
      isParent,
    })

export const commonFetchLeaderCombination = id => (dispatch, getState) =>
  dispatch({
    types: [
      types.API_REQUEST_SEND,
      types.COMMON_FETCH_LEADERCOMBINATION,
      types.API_REQUEST_ERROR,
    ],
    payload: {
      request: {
        url: `/api/donvi/${id}/phongban/lanhdaokyphoihop`,
        method: 'GET',
        headers: {
          Authorization:
            getState().auth.token.tokenType +
            ' ' +
            getState().auth.token.accessToken,
        },
      },
    },
  })

export const commonFetchOtherDepartments = id => (dispatch, getState) =>
  dispatch({
    types: [
      types.API_REQUEST_SEND,
      types.COMMON_FETCH_DERPATMENTS,
      types.API_REQUEST_ERROR,
    ],
    donViId: null,
    payload: {
      request: {
        url: `/api/nhanvien/${id}/phongbanquanly`,
        method: 'GET',
        headers: {
          Authorization:
            getState().auth.token.tokenType +
            ' ' +
            getState().auth.token.accessToken,
        },
      },
    },
  })

export const fetchDepartments = (id, params) => (dispatch, getState) =>
  dispatch({
    types: [
      types.API_REQUEST_SEND,
      types.FETCH_DEPARTMENTS,
      types.API_REQUEST_ERROR,
    ],
    payload: {
      request: {
        url: '/api/phongban',
        method: 'GET',
        headers: {
          Authorization:
            getState().auth.token.tokenType +
            ' ' +
            getState().auth.token.accessToken,
        },
        params: {
          ...params,
          donViID: id,
          limit: 0,
        },
      },
    },
  })

export const commonFetchDepartmentsLeader =
  (page = 1) =>
  (dispatch, getState) =>
    dispatch({
      types: [
        types.API_REQUEST_SEND,
        types.COMMON_FETCH_LEADER_DERPATMENTS,
        types.API_REQUEST_ERROR,
      ],
      page: page,
      payload: {
        request: {
          url: '/api/phongban/lanhdao',
          method: 'GET',
          headers: {
            Authorization:
              getState().auth.token.tokenType +
              ' ' +
              getState().auth.token.accessToken,
          },
          params: {
            page: page,
            limit: 0,
          },
        },
      },
    })

export const fetchInternalDepartments =
  (isSearchNoiBoDi, isPhongBanLap) => (dispatch, getState) =>
    dispatch({
      types: [
        types.API_REQUEST_SEND,
        types.FETCH_INTERNAL_DERPATMENTS,
        types.API_REQUEST_ERROR,
      ],
      payload: {
        request: {
          url: '/api/phongban/congvannoibo',
          method: 'GET',
          headers: {
            Authorization:
              getState().auth.token.tokenType +
              ' ' +
              getState().auth.token.accessToken,
          },
          params: {
            isSearchNoiBoDi,
            isPhongBanLap,
          },
        },
      },
    })

export const receiveDocumentAdd = data => (dispatch, getState) =>
  dispatch({
    types: [
      types.API_REQUEST_SEND,
      types.DOCUMENT_RECEIVE_ADD,
      types.API_REQUEST_ERROR,
    ],
    payload: {
      request: {
        url: '/api/congvanden',
        method: 'POST',
        headers: {
          Authorization:
            getState().auth.token.tokenType +
            ' ' +
            getState().auth.token.accessToken,
        },
        data: data,
      },
    },
  })

export const getExecutingView = id => (dispatch, getState) =>
  dispatch({
    types: [
      types.API_REQUEST_SEND,
      types.EXECUTING_VIEW,
      types.API_REQUEST_ERROR,
    ],
    schema: ExecutingViewSchema,
    payload: {
      request: {
        url: `/api/congvanden/${id}/xemthuchien`,
        method: 'GET',
        headers: {
          'api-version': 2.0,
          Authorization:
            getState().auth.token.tokenType +
            ' ' +
            getState().auth.token.accessToken,
        },
      },
    },
  })

export const getExecutingViewEvn = id => (dispatch, getState) =>
  dispatch({
    types: [
      types.API_REQUEST_SEND,
      types.EXECUTING_VIEW_EVN,
      types.API_REQUEST_ERROR,
    ],
    payload: {
      request: {
        url: `/api/vanbanevn/${id}/xemthuchien`,
        method: 'GET',
        headers: {
          'api-version': 1.0,
          Authorization:
            getState().auth.token.tokenType +
            ' ' +
            getState().auth.token.accessToken,
        },
      },
    },
  })

export const getMoreExecutingView = id => (dispatch, getState) =>
  dispatch({
    types: [
      types.API_REQUEST_SEND,
      types.EXECUTING_VIEW_MORE,
      types.API_REQUEST_ERROR,
    ],
    schema: ExecutingViewSchema,
    payload: {
      request: {
        url: `/api/congvanden/${id}/xemthuchien`,
        method: 'GET',
        headers: {
          'api-version': 2.0,
          Authorization:
            getState().auth.token.tokenType +
            ' ' +
            getState().auth.token.accessToken,
        },
      },
    },
  })

export const receiveDocumentFetchDigitalDocuments =
  typeDocument => (dispatch, getState) =>
    dispatch({
      types: [
        types.API_REQUEST_SEND,
        types.DOCUMENT_RECEIVE_FETCH_DIGITAL_DOCUMENTS,
        types.API_REQUEST_ERROR,
      ],
      schema: DigitalDocumentArraySchema,
      payload: {
        request: {
          url: `/api/vanbansohoa/loaivanban/${typeDocument}`,
          method: 'GET',
          headers: {
            Authorization:
              getState().auth.token.tokenType +
              ' ' +
              getState().auth.token.accessToken,
          },
        },
      },
    })

export const receiveDocumentUpdate = (id, data) => (dispatch, getState) =>
  dispatch({
    types: [
      types.API_REQUEST_SEND,
      types.DOCUMENT_RECEIVE_UPDATE,
      types.API_REQUEST_ERROR,
    ],
    payload: {
      request: {
        url: `/api/congvanden/${id}`,
        method: 'PUT',
        headers: {
          Authorization:
            getState().auth.token.tokenType +
            ' ' +
            getState().auth.token.accessToken,
        },
        data,
      },
    },
  })

// API get đơn vị trực thuộc
export const commonFetchAttachedUnits = id => (dispatch, getState) =>
  dispatch({
    types: [
      types.API_REQUEST_SEND,
      types.COMMON_FETCH_ATTACHED_UNITS,
      types.API_REQUEST_ERROR,
    ],
    payload: {
      request: {
        url: `/api/donvi/${id}/tructhuoc`,
        method: 'GET',
        headers: {
          Authorization:
            getState().auth.token.tokenType +
            ' ' +
            getState().auth.token.accessToken,
        },
        params: {
          limit: 0,
        },
      },
    },
  })

// API get đơn bị nhận công văn đến
export const commonFetchReceivingUnit = () => (dispatch, getState) =>
  dispatch({
    types: [
      types.API_REQUEST_SEND,
      types.COMMON_FETCH_RECEIVING_UNIT,
      types.API_REQUEST_ERROR,
    ],
    payload: {
      request: {
        url: '/api/donvi/donvinhan',
        method: 'GET',
        headers: {
          Authorization:
            getState().auth.token.tokenType +
            ' ' +
            getState().auth.token.accessToken,
        },
        params: {
          limit: 0,
        },
      },
    },
  })

export const receiveDocumentSendAssignment =
  (id, data) => (dispatch, getState) =>
    dispatch({
      types: [
        types.API_REQUEST_SEND,
        types.DOCUMENT_RECEIVE_SEND_ASSIGNMENT,
        types.API_REQUEST_ERROR,
      ],
      payload: {
        request: {
          url: `/api/congvanden/${id}/chidao`,
          method: 'POST',
          headers: {
            Authorization:
              getState().auth.token.tokenType +
              ' ' +
              getState().auth.token.accessToken,
          },
          data: data,
        },
      },
    })

export const postForwardAssignment = (id, data) => (dispatch, getState) =>
  dispatch({
    types: [
      types.API_REQUEST_SEND,
      types.FORWARD_ASSIGNMENT,
      types.API_REQUEST_ERROR,
    ],
    payload: {
      request: {
        url: `/api/congvanden/${id}/chuyenchidao`,
        method: 'POST',
        headers: {
          Authorization:
            getState().auth.token.tokenType +
            ' ' +
            getState().auth.token.accessToken,
        },
        data: data,
      },
    },
  })

export const receiveDocumentFetchProposeAssigment =
  id => (dispatch, getState) =>
    dispatch({
      types: [
        types.API_REQUEST_SEND,
        types.DOCUMENT_RECEIVE_FETCH_PROPOSE_ASSIGNMENT,
        types.API_REQUEST_ERROR,
      ],
      payload: {
        request: {
          url: `/api/congvanden/${id}/dexuatchidao`,
          method: 'GET',
          headers: {
            Authorization:
              getState().auth.token.tokenType +
              ' ' +
              getState().auth.token.accessToken,
          },
        },
      },
    })

export const getWorkItem = id => (dispatch, getState) =>
  dispatch({
    types: [types.API_REQUEST_SEND, types.WORK_ITEM, types.API_REQUEST_ERROR],
    schema: DepartmentWorkSchemaItem,
    payload: {
      request: {
        url: `/api/congviec/${id}`,
        method: 'GET',
        headers: {
          Authorization:
            getState().auth.token.tokenType +
            ' ' +
            getState().auth.token.accessToken,
          'api-version': 3.0,
        },
      },
    },
  })

export const receiveDocumentSendProposeAssignment =
  (id, data) => (dispatch, getState) =>
    dispatch({
      types: [
        types.API_REQUEST_SEND,
        types.DOCUMENT_RECEIVE_SEND_PROPOSE_ASSIGNMENT,
        types.API_REQUEST_ERROR,
      ],
      payload: {
        request: {
          url: `/api/congvanden/${id}/dexuatchidao`,
          method: 'POST',
          headers: {
            Authorization:
              getState().auth.token.tokenType +
              ' ' +
              getState().auth.token.accessToken,
          },
          data: data,
        },
      },
    })

export const getAssignmentHistory = id => (dispatch, getState) =>
  dispatch({
    types: [
      types.API_REQUEST_SEND,
      types.DOCUMENT_RECEIVE_ASSIGNMENT_HISTORY,
      types.API_REQUEST_ERROR,
    ],
    // schema: AssignmentHistorySchema,
    payload: {
      request: {
        url: `/api/congvanden/${id}/chidao`,
        method: 'GET',
        headers: {
          Authorization:
            getState().auth.token.tokenType +
            ' ' +
            getState().auth.token.accessToken,
        },
      },
    },
  })

export const assignmentHistoryDelete = (id, chiDaoId) => (dispatch, getState) =>
  dispatch({
    types: [
      types.API_REQUEST_SEND,
      types.DOCUMENT_RECEIVE_DELETE_ASSIGNMENT_HISTORY,
      types.API_REQUEST_ERROR,
    ],
    payload: {
      request: {
        url: `/api/congvanden/${id}/chidao/${chiDaoId}`,
        method: 'DELETE',
        headers: {
          Authorization:
            getState().auth.token.tokenType +
            ' ' +
            getState().auth.token.accessToken,
        },
      },
    },
  })

export const updateAssignmentHistory = (id, data) => (dispatch, getState) =>
  dispatch({
    types: [
      types.API_REQUEST_SEND,
      types.DOCUMENT_RECEIVE_UPDATE_ASSIGNMENT_HISTORY,
      types.API_REQUEST_ERROR,
    ],
    payload: {
      request: {
        url: `/api/congvanden/${id}/chidao`,
        method: 'PUT',
        headers: {
          Authorization:
            getState().auth.token.tokenType +
            ' ' +
            getState().auth.token.accessToken,
        },
        data: data,
      },
    },
  })

export const receiveDocumentSendAdditionalAssignment =
  (id, data) => (dispatch, getState) =>
    dispatch({
      types: [
        types.API_REQUEST_SEND,
        types.DOCUMENT_RECEIVE_SEND_ADDITIONAL_ASSIGNMENT,
        types.API_REQUEST_ERROR,
      ],
      payload: {
        request: {
          url: `/api/congvanden/${id}/chidaobosung`,
          method: 'POST',
          headers: {
            Authorization:
              getState().auth.token.tokenType +
              ' ' +
              getState().auth.token.accessToken,
          },
          data: data,
        },
      },
    })

export const fileUpload = (images, progressEvent) => (dispatch, getState) => {
  let data = {
    image: images,
  }

  if (Array.isArray(images)) {
    data = {}
    images.map((item, k) => {
      return (data[`images-${k}`] = item)
    })
  }

  return dispatch({
    types: [types.API_REQUEST_SEND, types.FILE_UPLOAD, types.API_REQUEST_ERROR],
    payload: {
      client: 'upload',
      request: {
        url: `/api/file/uploadfile`,
        method: 'POST',
        headers: {
          Authorization:
            getState().auth.token.tokenType +
            ' ' +
            getState().auth.token.accessToken,
        },
        data,
        onUploadProgress: progressEvent,
      },
    },
  })
}

export const avatarUpload = (images, userName) => (dispatch, getState) => {
  let data = {
    image: images,
  }

  if (Array.isArray(images)) {
    data = {}
    images.map((item, k) => {
      return (data[`images-${k}`] = item)
    })
  }

  return dispatch({
    types: [
      types.API_REQUEST_SEND,
      types.AVATAR_UPLOAD,
      types.API_REQUEST_ERROR,
    ],
    payload: {
      client: 'upload',
      request: {
        url: `/api/file/avatar?userName=${userName}&complexFileName=${true}`,
        method: 'POST',
        headers: {
          Authorization:
            getState().auth.token.tokenType +
            ' ' +
            getState().auth.token.accessToken,
        },
        data,
      },
    },
  })
}

export const profileFileUpload = files => (dispatch, getState) => {
  let data = {
    file: files,
  }

  return dispatch({
    types: [
      types.API_REQUEST_SEND,
      types.PROFILE_FILE_UPLOAD,
      types.API_REQUEST_ERROR,
    ],
    payload: {
      client: 'upload',
      request: {
        url: `/api/file/profile`,
        method: 'POST',
        headers: {
          Authorization:
            getState().auth.token.tokenType +
            ' ' +
            getState().auth.token.accessToken,
        },
        data,
      },
    },
  })
}

export const clearCacheAvatar = path => (dispatch, getState) =>
  dispatch({
    types: [
      types.API_REQUEST_SEND,
      types.CLEAR_CACHE_AVATAR,
      types.API_REQUEST_ERROR,
    ],
    payload: {
      client: 'image',
      request: {
        url: `${path}?nocache=true`,
        method: 'GET',
      },
    },
  })

export const downloadFile = filename => (dispatch, getState) =>
  dispatch({
    types: [
      types.API_REQUEST_SEND,
      types.DOWNLOADFILE,
      types.API_REQUEST_ERROR,
    ],
    payload: {
      client: 'fileServer',
      request: {
        url: `api/file/downloadfile?filename=${filename}`,
        method: 'GET',
        headers: {
          Authorization:
            getState().auth.token.tokenType +
            ' ' +
            getState().auth.token.accessToken,
        },
      },
    },
  })

export const fileTotalPages = filename => (dispatch, getState) =>
  dispatch({
    types: [
      types.API_REQUEST_SEND,
      types.API_REQUEST_SUCCESS,
      types.API_REQUEST_ERROR,
    ],
    payload: {
      client: 'fileServer',
      request: {
        url: `api/file/totalpage?filename=${filename}`,
        method: 'GET',
        headers: {
          Authorization:
            getState().auth.token.tokenType +
            ' ' +
            getState().auth.token.accessToken,
        },
      },
    },
  })

export const fileViewFile =
  (fileId, page = 1) =>
  (dispatch, getState) =>
    dispatch({
      types: [
        types.API_REQUEST_SEND,
        types.API_REQUEST_SUCCESS,
        types.API_REQUEST_ERROR,
      ],
      payload: {
        client: 'fileServer',
        request: {
          url: `/api/file/viewpage/${fileId}?page=${page}`,
          method: 'GET',
          responseType: 'arraybuffer',
          headers: {
            'Content-Type': 'image/jpeg',
            Authorization:
              getState().auth.token.tokenType +
              ' ' +
              getState().auth.token.accessToken,
          },
        },
      },
    })

export const commonAddToasterMessage = data => ({
  type: types.COMMON_ADD_TOASTER_MESSAGE,
  data: data,
})

export const commonRemoveToasterMessage = () => ({
  type: types.COMMON_REMOVE_TOASTER_MESSAGE,
})

export const getAssignmentHistoryItem =
  (id, chiDaoId) => (dispatch, getState) =>
    dispatch({
      types: [
        types.API_REQUEST_SEND,
        types.DOCUMENT_RECEIVE_ASSIGNMENT_HISTORY_ITEM,
        types.API_REQUEST_ERROR,
      ],
      payload: {
        request: {
          url: `/api/congvanden/${id}/chidao/${chiDaoId}`,
          method: 'GET',
          headers: {
            Authorization:
              getState().auth.token.tokenType +
              ' ' +
              getState().auth.token.accessToken,
          },
        },
      },
    })

export const getWorkAssignmentInformation = id => (dispatch, getState) =>
  dispatch({
    types: [
      types.API_REQUEST_SEND,
      types.WORK_ASSIGNMENT_INFORMATION,
      types.API_REQUEST_ERROR,
    ],
    schema: AssignmentInformationSchema,
    payload: {
      request: {
        url: `/api/congviec/${id}/danhsachgiaoviec`,
        method: 'GET',
        headers: {
          Authorization:
            getState().auth.token.tokenType +
            ' ' +
            getState().auth.token.accessToken,
        },
      },
    },
  })

export const commonFetchReceiveDocumentTotal = status => (dispatch, getState) =>
  dispatch({
    types: [
      types.API_REQUEST_SEND,
      types.COMMON_FETCH_RECEIVEDOCUMENT_TOTAL,
      types.API_REQUEST_ERROR,
    ],
    payload: {
      request: {
        url: `/api/congvanden/tongso/${status}`,
        method: 'GET',
        headers: {
          Authorization:
            getState().auth.token.tokenType +
            ' ' +
            getState().auth.token.accessToken,
        },
      },
    },
    status: status,
  })

export const postWorkReport = (id, data) => (dispatch, getState) =>
  dispatch({
    types: [
      types.API_REQUEST_SEND,
      types.WORK_POST_REPORT,
      types.API_REQUEST_ERROR,
    ],
    payload: {
      request: {
        url: `/api/congviec/${id}/baocao`,
        method: 'POST',
        headers: {
          Authorization:
            getState().auth.token.tokenType +
            ' ' +
            getState().auth.token.accessToken,
        },
        data: data,
      },
    },
  })

export const searchSendDocument = data => (dispatch, getState) =>
  dispatch({
    types: [
      types.API_REQUEST_SEND,
      types.SEARCH_DOCUMENT_SEND,
      types.API_REQUEST_ERROR,
    ],
    payload: {
      request: {
        url: `api/congvandi?filter.text_search=${data}`,
        method: 'GET',
        headers: {
          Authorization:
            getState().auth.token.tokenType +
            ' ' +
            getState().auth.token.accessToken,
        },
      },
    },
  })

export const commonFetchStaffByDepartmentId = id => (dispatch, getState) =>
  dispatch({
    types: [
      types.API_REQUEST_SEND,
      types.COMMON_FETCH_STAFFS_BY_DEPARTMENT_ID,
      types.API_REQUEST_ERROR,
    ],
    payload: {
      request: {
        url: `/api/phongban/${id}/nhanvien`,
        method: 'GET',
        headers: {
          Authorization:
            getState().auth.token.tokenType +
            ' ' +
            getState().auth.token.accessToken,
        },
        params: {
          include_phong_ban_con: false,
          limit: 0,
        },
      },
    },
  })

export const fetchStaffByDepartmentId = id => (dispatch, getState) =>
  dispatch({
    types: [
      types.API_REQUEST_SEND,
      types.FETCH_STAFFS_BY_DEPARTMENT_ID,
      types.API_REQUEST_ERROR,
    ],
    payload: {
      request: {
        url: `/api/phongban/${id}/nhanvienphongbanchinh`,
        method: 'GET',
        headers: {
          Authorization:
            getState().auth.token.tokenType +
            ' ' +
            getState().auth.token.accessToken,
        },
      },
    },
  })

export const getStaffByDepartmentId = (id, sorter) => (dispatch, getState) =>
  dispatch({
    types: [
      types.API_REQUEST_SEND,
      types.GET_STAFFS_IN_DEPARTMENT,
      types.API_REQUEST_ERROR,
    ],
    schema: UserArraySchema,
    payload: {
      request: {
        url: `/api/quyen/phongban/${id}/nhanvien`,
        method: 'GET',
        headers: {
          Authorization:
            getState().auth.token.tokenType +
            ' ' +
            getState().auth.token.accessToken,
        },
        params: {
          sorter,
        },
      },
    },
  })

export const commonFetchStaffByUnitId = id => (dispatch, getState) =>
  dispatch({
    types: [
      types.API_REQUEST_SEND,
      types.COMMON_FETCH_STAFFS_BY_UNIT_ID,
      types.API_REQUEST_ERROR,
    ],
    payload: {
      request: {
        url: `/api/donvi/${id}/nhanvien`,
        method: 'GET',
        headers: {
          Authorization:
            getState().auth.token.tokenType +
            ' ' +
            getState().auth.token.accessToken,
        },
        params: {
          limit: 500,
        },
      },
    },
  })

export const getPersonalWorkItem = id => (dispatch, getState) =>
  dispatch({
    types: [
      types.API_RESET_PERSONAL_WORK_ITEM,
      types.PERSONAL_WORK_ITEM,
      types.API_REQUEST_ERROR,
    ],
    schema: PersonalWorkSchemaItem,
    payload: {
      request: {
        url: `/api/congviec/${id}/canhan`,
        method: 'GET',
        headers: {
          'api-version': 3.0,
          Authorization:
            getState().auth.token.tokenType +
            ' ' +
            getState().auth.token.accessToken,
        },
      },
    },
  })

export const getInformationAssign = id => (dispatch, getState) =>
  dispatch({
    types: [
      types.API_REQUEST_SEND,
      types.PERSONAL_WORK_ASSIGN,
      types.API_REQUEST_ERROR,
    ],
    payload: {
      request: {
        url: `/api/congviec/${id}/canhan/chitiet`,
        method: 'GET',
        headers: {
          'api-version': 2.0,
          Authorization:
            getState().auth.token.tokenType +
            ' ' +
            getState().auth.token.accessToken,
        },
      },
    },
  })

export const getInformationAssignToUpdate = id => (dispatch, getState) =>
  dispatch({
    types: [
      types.API_RESET_PERSONAL_WORK_ITEM,
      types.PERSONAL_WORK_ITEM,
      types.API_REQUEST_ERROR,
    ],
    payload: {
      request: {
        url: `/api/congviec/${id}/canhan/chitietchinhsua`,
        method: 'GET',
        headers: {
          'api-version': 2.0,
          Authorization:
            getState().auth.token.tokenType +
            ' ' +
            getState().auth.token.accessToken,
        },
      },
    },
  })

export const workAssign = data => (dispatch, getState) =>
  dispatch({
    types: [types.API_REQUEST_SEND, types.WORK_ASSIGN, types.API_REQUEST_ERROR],
    payload: {
      request: {
        url: '/api/congviec',
        method: 'POST',
        headers: {
          Authorization:
            getState().auth.token.tokenType +
            ' ' +
            getState().auth.token.accessToken,
        },
        data: data,
      },
    },
  })

export const addWorkAssign = data => (dispatch, getState) =>
  dispatch({
    types: [
      types.API_REQUEST_SEND,
      types.ADD_WORK_ASSIGN,
      types.API_REQUEST_ERROR,
    ],
    payload: {
      request: {
        url: '/api/congviec',
        method: 'POST',
        headers: {
          Authorization:
            getState().auth.token.tokenType +
            ' ' +
            getState().auth.token.accessToken,
          'api-version': '3.0',
        },
        data: data,
      },
    },
  })

export const commonFetchDepartmentWorkTotal =
  (status, workExpired, workExpiringSoon) => (dispatch, getState) =>
    dispatch({
      types: [
        types.SET_DEFAULT_NUMBER_DEPARTMENT,
        types.COMMON_FETCH_DERPATMENT_WORK_TOTAL,
        types.API_REQUEST_ERROR,
      ],
      payload: {
        request: {
          url: `/api/phongban/tongsocongviec`,
          method: 'GET',
          headers: {
            Authorization:
              getState().auth.token.tokenType +
              ' ' +
              getState().auth.token.accessToken,
          },
          params: {
            status: status,
            qua_han: workExpired,
            gan_qua_han: workExpiringSoon,
          },
        },
      },
      filter: {
        status,
        workExpired,
        workExpiringSoon,
      },
    })

export const commonFetchPersonalWorkTotal =
  (status, workExpired, workExpiringSoon) => (dispatch, getState) =>
    dispatch({
      types: [
        types.SET_DEFAULT_NUMBER_WORK,
        types.COMMON_FETCH_PERSONAL_WORK_TOTAL,
        types.API_REQUEST_ERROR,
      ],
      payload: {
        request: {
          url: '/api/nhanvien/tongsocongviec/',
          method: 'GET',
          headers: {
            Authorization:
              getState().auth.token.tokenType +
              ' ' +
              getState().auth.token.accessToken,
          },
          params: {
            status: status,
            qua_han: workExpired,
            gan_qua_han: workExpiringSoon,
          },
        },
      },
      filter: {
        status,
        workExpired,
        workExpiringSoon,
      },
    })

export const getReportWork = id => (dispatch, getState) =>
  dispatch({
    types: [
      types.API_REQUEST_SEND,
      types.WORK_GET_REPORT,
      types.API_REQUEST_ERROR,
    ],
    schema: ReportWorkItem,
    payload: {
      request: {
        url: `/api/congviec/${id}/baocao`,
        method: 'GET',
        headers: {
          Authorization:
            getState().auth.token.tokenType +
            ' ' +
            getState().auth.token.accessToken,
        },
      },
    },
  })

export const getMail = data => (dispatch, getState) =>
  dispatch({
    types: [types.API_REQUEST_SEND, types.GET_MAIL, types.API_REQUEST_ERROR],
    payload: {
      request: {
        url: `/api/email/${data}`,
        method: 'GET',
        headers: {
          Authorization:
            getState().auth.token.tokenType +
            ' ' +
            getState().auth.token.accessToken,
        },
      },
    },
  })

export const workAdditionalAssign = (id, data) => (dispatch, getState) =>
  dispatch({
    types: [
      types.API_REQUEST_SEND,
      types.WORK_ADDITIONAL_ASSIGN,
      types.API_REQUEST_ERROR,
    ],
    payload: {
      request: {
        url: `/api/congviec/${id}/giaoviecbosung`,
        method: 'POST',
        headers: {
          Authorization:
            getState().auth.token.tokenType +
            ' ' +
            getState().auth.token.accessToken,
        },
        data: data,
      },
    },
  })

export const postMail = data => (dispatch, getState) =>
  dispatch({
    types: [types.API_REQUEST_SEND, types.POST_MAIL, types.API_REQUEST_ERROR],
    payload: {
      request: {
        url: `/api/email`,
        method: 'POST',
        headers: {
          Authorization:
            getState().auth.token.tokenType +
            ' ' +
            getState().auth.token.accessToken,
        },
        data: data,
      },
    },
  })

export const workCompleteDepartment = data => (dispatch, getState) =>
  dispatch({
    types: [
      types.API_REQUEST_SEND,
      types.WORK_COMPLETE_DEPARTMENT,
      types.API_REQUEST_ERROR,
    ],
    payload: {
      request: {
        url: '/api/congviec/hoanthanhphongban',
        method: 'PATCH',
        headers: {
          Authorization:
            getState().auth.token.tokenType +
            ' ' +
            getState().auth.token.accessToken,
        },
        data: data,
      },
    },
  })

export const updateAdditionalAssignment = (id, data) => (dispatch, getState) =>
  dispatch({
    types: [
      types.API_REQUEST_SEND,
      types.DOCUMENT_RECEIVE_UPDATE_ADDITIONAL_ASSIGNMENT,
      types.API_REQUEST_ERROR,
    ],
    payload: {
      request: {
        url: `/api/congvanden/${id}/chidaobosung`,
        method: 'PUT',
        headers: {
          Authorization:
            getState().auth.token.tokenType +
            ' ' +
            getState().auth.token.accessToken,
        },
        data: data,
      },
    },
  })

export const workCompletePersonal = id => (dispatch, getState) =>
  dispatch({
    types: [
      types.API_REQUEST_SEND,
      types.WORK_COMPLETE_PERSONAL,
      types.API_REQUEST_ERROR,
    ],
    payload: {
      request: {
        url: `/api/congviec/${id}/hoanthanhcanhan`,
        method: 'PATCH',
        headers: {
          Authorization:
            getState().auth.token.tokenType +
            ' ' +
            getState().auth.token.accessToken,
        },
      },
    },
  })

export const deleteSignNumber = (lydo, id) => (dispatch, getState) =>
  dispatch({
    types: [
      types.API_REQUEST_SEND,
      types.SIGN_NUMBER_DELETE,
      types.API_REQUEST_ERROR,
    ],
    payload: {
      request: {
        url: `/api/kysodi/${id}?ly_do_xoa=${lydo}`,
        method: 'DELETE',
        headers: {
          Authorization:
            getState().auth.token.tokenType +
            ' ' +
            getState().auth.token.accessToken,
        },
      },
    },
  })

export const deleteSignNumberInternal = (lydo, id) => (dispatch, getState) =>
  dispatch({
    types: [
      types.API_REQUEST_SEND,
      types.SIGN_NUMBER_INTERNAL_DELETE,
      types.API_REQUEST_ERROR,
    ],
    payload: {
      request: {
        url: `/api/kysonoibo/${id}?ly_do_xoa=${lydo}`,
        method: 'DELETE',
        headers: {
          Authorization:
            getState().auth.token.tokenType +
            ' ' +
            getState().auth.token.accessToken,
        },
      },
    },
  })

export const sendDocumentAdd = data => (dispatch, getState) =>
  dispatch({
    types: [
      types.API_REQUEST_SEND,
      types.DOCUMENT_SEND_ADD,
      types.API_REQUEST_ERROR,
    ],
    payload: {
      request: {
        url: '/api/congvandi',
        method: 'POST',
        headers: {
          Authorization:
            getState().auth.token.tokenType +
            ' ' +
            getState().auth.token.accessToken,
        },
        data: data,
      },
    },
  })

export const sendSignNumberUpdate = (id, data) => (dispatch, getState) =>
  dispatch({
    types: [
      types.API_REQUEST_SEND,
      types.SIGN_NUMBER_SEND_UPDATE,
      types.API_REQUEST_ERROR,
    ],
    payload: {
      request: {
        url: `/api/kysodi/${id}`,
        method: 'PUT',
        headers: {
          Authorization:
            getState().auth.token.tokenType +
            ' ' +
            getState().auth.token.accessToken,
        },
        data: data,
      },
    },
  })

export const getReportWorkPersonal = id => (dispatch, getState) =>
  dispatch({
    types: [
      types.API_REQUEST_SEND,
      types.WORK_GET_REPORT_PERSONAL,
      types.API_REQUEST_ERROR,
    ],
    payload: {
      request: {
        url: `/api/congviec/${id}/baocaocanhan`,
        method: 'GET',
        headers: {
          Authorization:
            getState().auth.token.tokenType +
            ' ' +
            getState().auth.token.accessToken,
        },
      },
    },
  })

export const updateReportWorkPersonal = (id, data) => (dispatch, getState) =>
  dispatch({
    types: [
      types.API_REQUEST_SEND,
      types.WORK_GET_REPORT_PERSONAL,
      types.API_REQUEST_ERROR,
    ],
    payload: {
      request: {
        url: `/api/congviec/${id}/baocao`,
        method: 'PUT',
        headers: {
          Authorization:
            getState().auth.token.tokenType +
            ' ' +
            getState().auth.token.accessToken,
        },
        data: data,
      },
    },
  })

export const internalSignNumberUpdate = (id, data) => (dispatch, getState) =>
  dispatch({
    types: [
      types.API_REQUEST_SEND,
      types.SIGN_NUMBER_INTERNAL_UPDATE,
      types.API_REQUEST_ERROR,
    ],
    payload: {
      request: {
        url: `/api/kysonoibo/${id}`,
        method: 'PUT',
        headers: {
          Authorization:
            getState().auth.token.tokenType +
            ' ' +
            getState().auth.token.accessToken,
        },
        data: data,
      },
    },
  })

export const deleteDocumentInternal = (id, params) => (dispatch, getState) =>
  dispatch({
    types: [
      types.API_REQUEST_SEND,
      types.DOCUMENT_INTERNAL_DELETE,
      types.API_REQUEST_ERROR,
    ],
    payload: {
      request: {
        url: `/api/congvannoibo/${id}`,
        method: 'DELETE',
        headers: {
          Authorization:
            getState().auth.token.tokenType +
            ' ' +
            getState().auth.token.accessToken,
        },
        params,
      },
    },
  })

export const deleteWorkAssign = id => (dispatch, getState) =>
  dispatch({
    types: [
      types.API_REQUEST_SEND,
      types.WORK_DELETE_ASSIGN,
      types.API_REQUEST_ERROR,
    ],
    payload: {
      request: {
        url: `/api/congviec/giaoviec/${id}`,
        method: 'DELETE',
        headers: {
          Authorization:
            getState().auth.token.tokenType +
            ' ' +
            getState().auth.token.accessToken,
        },
      },
    },
  })

export const updateAdditionalAssign = (id, data) => (dispatch, getState) =>
  dispatch({
    types: [
      types.API_REQUEST_SEND,
      types.WORK_UPDATE_ADDITIONAL_ASSIGN,
      types.API_REQUEST_ERROR,
    ],
    payload: {
      request: {
        url: `/api/congviec/${id}/giaoviecbosung`,
        method: 'PUT',
        headers: {
          Authorization:
            getState().auth.token.tokenType +
            ' ' +
            getState().auth.token.accessToken,
        },
        data: data,
      },
    },
  })

export const updateWorkAssign = data => (dispatch, getState) =>
  dispatch({
    types: [
      types.API_REQUEST_SEND,
      types.WORK_UPDATE_ASSIGN,
      types.API_REQUEST_ERROR,
    ],
    payload: {
      request: {
        url: `/api/congviec`,
        method: 'PUT',
        headers: {
          Authorization:
            getState().auth.token.tokenType +
            ' ' +
            getState().auth.token.accessToken,
        },
        data: data,
      },
    },
  })

export const updateNewWorkAssign = data => (dispatch, getState) =>
  dispatch({
    types: [
      types.API_REQUEST_SEND,
      types.WORK_UPDATE_ASSIGN,
      types.API_REQUEST_ERROR,
    ],
    payload: {
      request: {
        url: `/api/congviec`,
        method: 'PUT',
        headers: {
          Authorization:
            getState().auth.token.tokenType +
            ' ' +
            getState().auth.token.accessToken,
          'api-version': 3.0,
        },
        data: data,
      },
    },
  })

export const InformationSteering =
  (id, typeAssign = null) =>
  (dispatch, getState) =>
    dispatch({
      types: [
        types.API_REQUEST_SEND,
        types.INFOMATION_STEERING,
        types.API_REQUEST_ERROR,
      ],
      payload: {
        request: {
          url: `/api/congvanden/${id}/thongtinchidao`,
          method: 'GET',
          headers: {
            Authorization:
              getState().auth.token.tokenType +
              ' ' +
              getState().auth.token.accessToken,
            'api-version': 2.0,
          },
        },
      },
      typeAssign,
    })

export const commonFetchChildDepartments = id => (dispatch, getState) =>
  dispatch({
    types: [
      types.API_REQUEST_SEND,
      types.COMMON_FETCH_CHILD_DERPATMENTS,
      types.API_REQUEST_ERROR,
    ],
    payload: {
      request: {
        url: `/api/phongban/${id}/phongban`,
        method: 'GET',
        headers: {
          Authorization:
            getState().auth.token.tokenType +
            ' ' +
            getState().auth.token.accessToken,
        },
      },
    },
  })

export const deleteWorkReport = id => (dispatch, getState) =>
  dispatch({
    types: [
      types.API_REQUEST_SEND,
      types.WORK_DELETE_REPORT,
      types.API_REQUEST_ERROR,
    ],
    payload: {
      request: {
        url: `/api/congviec/${id}/baocao`,
        method: 'DELETE',
        headers: {
          Authorization:
            getState().auth.token.tokenType +
            ' ' +
            getState().auth.token.accessToken,
        },
      },
    },
  })

export const commonFetchStatistics = () => (dispatch, getState) =>
  dispatch({
    types: [
      types.API_REQUEST_SEND,
      types.COMMON_FETCH_STATISTICS,
      types.API_REQUEST_ERROR,
    ],
    payload: {
      request: {
        url: '/api/thongke/canxuly',
        method: 'GET',
        headers: {
          Authorization:
            getState().auth.token.tokenType +
            ' ' +
            getState().auth.token.accessToken,
        },
      },
    },
  })

export const internalDocumentAdd = data => (dispatch, getState) =>
  dispatch({
    types: [
      types.API_REQUEST_SEND,
      types.DOCUMENT_INTERNAL_ADD,
      types.API_REQUEST_ERROR,
    ],
    payload: {
      request: {
        url: '/api/congvannoibo',
        method: 'POST',
        headers: {
          Authorization:
            getState().auth.token.tokenType +
            ' ' +
            getState().auth.token.accessToken,
        },
        data: data,
      },
    },
  })

export const workFetchDepartmentAssignInformations =
  id => (dispatch, getState) =>
    dispatch({
      types: [
        types.API_REQUEST_SEND,
        types.WORK_DEPARTMENT_ASSIGN_INFORMATIONS,
        types.API_REQUEST_ERROR,
      ],
      payload: {
        request: {
          url: `/api/congviec/${id}/canhantonghop`,
          method: 'GET',
          headers: {
            Authorization:
              getState().auth.token.tokenType +
              ' ' +
              getState().auth.token.accessToken,
          },
        },
      },
    })

export const workFetchPersonalAssignInformations = id => (dispatch, getState) =>
  dispatch({
    types: [
      types.API_REQUEST_SEND,
      types.WORK_PERSONAL_ASSIGN_INFORMATIONS,
      types.API_REQUEST_ERROR,
    ],
    payload: {
      request: {
        url: `/api/congviec/${id}/canhan`,
        method: 'GET',
        headers: {
          Authorization:
            getState().auth.token.tokenType +
            ' ' +
            getState().auth.token.accessToken,
        },
      },
    },
  })

export const documentsCabinetGetListCategoriesUnit =
  (type, refresh = false) =>
  (dispatch, getState) =>
    dispatch({
      types: [
        types.DOCUMENTS_CABINET_CATEGORIES_SET_INITIAL,
        types.DOCUMENTS_CABINET_UNIT_CATEGORIES,
        types.API_REQUEST_ERROR,
      ],
      schema: DocumentCabinetCategoryArraySchema,
      payload: {
        request: {
          url: `/api/tutailieu/donvi`,
          method: 'GET',
          headers: {
            Authorization:
              getState().auth.token.tokenType +
              ' ' +
              getState().auth.token.accessToken,
            'api-version': 2.0,
          },
          params: {
            refresh,
          },
        },
      },
    })

export const documentsCabinetGetByUnit =
  (filter = {}, page = 1, sort, refresh = false, filterColumn = {}) =>
  (dispatch, getState) => {
    let params = {
      refresh,
      sort,
      page: page,
      limit: utils.PAGINATION_LIMIT,
    }

    if (filter.filter) {
      if (filter.filter.noiGuiId === '#') {
        params['filter.noi_gui_id'] = ''
      }
    }

    params = Object.assign(Tool.Utils.linkObjectKey(filter), params)
    params = Object.assign(
      Tool.Utils.linkObjectKey({ filter_column: filterColumn }),
      params
    )

    return dispatch({
      types: [
        types.DOCUMENTS_CABINET_ITEM_SET_INITIAL,
        types.DOCUMENTS_CABINET_BY_UNIT,
        types.API_REQUEST_ERROR,
      ],
      schema: DocumentCabinetArraySchema,
      payload: {
        request: {
          url: `/api/tutailieu/donvi/dstailieu`,
          method: 'GET',
          headers: {
            'api-version': 2.0,
            Authorization:
              getState().auth.token.tokenType +
              ' ' +
              getState().auth.token.accessToken,
          },
          params,
        },
      },
    })
  }

export const getInternalDocumentItem = id => (dispatch, getState) =>
  dispatch({
    types: [
      types.API_REQUEST_SEND,
      types.GET_DOCUMENT_INTERNAL_ITEM,
      types.API_REQUEST_ERROR,
    ],
    schema: InternalDocumentSchemaItem,
    payload: {
      request: {
        url: `/api/congvannoibo/${id}`,
        method: 'GET',
        headers: {
          Authorization:
            getState().auth.token.tokenType +
            ' ' +
            getState().auth.token.accessToken,
        },
      },
    },
  })

export const documentsCabinetGetListCategoriesPersonal =
  (type, refresh = false) =>
  (dispatch, getState) =>
    dispatch({
      types: [
        types.DOCUMENTS_CABINET_CATEGORIES_SET_INITIAL,
        types.DOCUMENTS_CABINET_PERSONAL_CATEGORIES,
        types.API_REQUEST_ERROR,
      ],
      schema: DocumentCabinetCategoryArraySchema,
      payload: {
        request: {
          url: `/api/tutailieu/canhan`,
          method: 'GET',
          headers: {
            Authorization:
              getState().auth.token.tokenType +
              ' ' +
              getState().auth.token.accessToken,
            'api-version': 2.0,
          },
          params: {
            refresh,
          },
        },
      },
    })

export const documentsCabinetGetByPersonal =
  (filter = {}, page = 1, sort, refresh = false, filterColumn = {}) =>
  (dispatch, getState) => {
    let params = {
      refresh,
      sort,
      page: page,
      limit: utils.PAGINATION_LIMIT,
    }

    params = Object.assign(params, Tool.Utils.linkObjectKey(filter))
    params = Object.assign(
      params,
      Tool.Utils.linkObjectKey({ filter_column: filterColumn }, params)
    )

    return dispatch({
      types: [
        types.DOCUMENTS_CABINET_ITEM_SET_INITIAL,
        types.DOCUMENTS_CABINET_BY_PERSONAL,
        types.API_REQUEST_ERROR,
      ],
      schema: DocumentCabinetArraySchema,
      payload: {
        request: {
          url: `/api/tutailieu/canhan/dstailieu`,
          method: 'GET',
          headers: {
            'api-version': 2.0,
            Authorization:
              getState().auth.token.tokenType +
              ' ' +
              getState().auth.token.accessToken,
          },
          params,
        },
      },
    })
  }

export const getExecutingViewInternalDocument = id => (dispatch, getState) =>
  dispatch({
    types: [
      types.API_REQUEST_SEND,
      types.DOCUMENT_INTERNAL_EXECUTINGVIEW,
      types.API_REQUEST_ERROR,
    ],
    schema: ExecutingViewInternalDocumentSchema,
    payload: {
      request: {
        url: `/api/congvannoibo/${id}/xemthuchien`,
        method: 'GET',
        headers: {
          'api-version': 2.0,
          Authorization:
            getState().auth.token.tokenType +
            ' ' +
            getState().auth.token.accessToken,
        },
      },
    },
  })

export const documentsCabinetGetListCategoriesDepartment =
  (id = null, type, refresh = false) =>
  (dispatch, getState) =>
    dispatch({
      types: [
        types.DOCUMENTS_CABINET_CATEGORIES_SET_INITIAL,
        types.DOCUMENTS_CABINET_DEPARTMENT_CATEGORIES,
        types.API_REQUEST_ERROR,
      ],
      schema: DocumentCabinetCategoryArraySchema,
      payload: {
        request: {
          url: `/api/tutailieu/phongban`,
          method: 'GET',
          headers: {
            Authorization:
              getState().auth.token.tokenType +
              ' ' +
              getState().auth.token.accessToken,
            'api-version': 2.0,
          },
          params: {
            phong_ban_id: id,
            refresh,
          },
        },
      },
    })

export const documentsCabinetGetByDepartment =
  (filter = {}, page = 1, sort, refresh = false, filterColumn = {}) =>
  (dispatch, getState) => {
    let params = {
      refresh,
      sort,
      page: page,
      limit: utils.PAGINATION_LIMIT,
    }

    if (filter.filter) {
      if (filter.filter.noiGuiId === '#') {
        params['filter.noi_gui_id'] = ''
      }
    }

    params = Object.assign(Tool.Utils.linkObjectKey(filter), params)
    params = Object.assign(
      Tool.Utils.linkObjectKey({ filter_column: filterColumn }),
      params
    )

    return dispatch({
      types: [
        types.DOCUMENTS_CABINET_ITEM_SET_INITIAL,
        types.DOCUMENTS_CABINET_BY_DEPARTMENT,
        types.API_REQUEST_ERROR,
      ],
      schema: DocumentCabinetArraySchema,
      payload: {
        request: {
          url: `/api/tutailieu/phongban/dstailieu`,
          method: 'GET',
          headers: {
            'api-version': 2.0,
            Authorization:
              getState().auth.token.tokenType +
              ' ' +
              getState().auth.token.accessToken,
          },
          params,
        },
      },
    })
  }

export const documentsCabinetSaveReceiveDocument =
  data => (dispatch, getState) =>
    dispatch({
      types: [
        types.API_REQUEST_SEND,
        types.DOCUMENTS_CABINET_RECEIVE_DOCUMENT_POST,
        types.API_REQUEST_ERROR,
      ],
      payload: {
        request: {
          url: `/api/tutailieu/congvandencongviec`,
          method: 'POST',
          headers: {
            Authorization:
              getState().auth.token.tokenType +
              ' ' +
              getState().auth.token.accessToken,
          },
          data,
        },
      },
    })

export const documentsCabinetSaveWork = (id, data) => (dispatch, getState) =>
  dispatch({
    types: [
      types.API_REQUEST_SEND,
      types.DOCUMENTS_CABINET_WORK_POST,
      types.API_REQUEST_ERROR,
    ],
    payload: {
      request: {
        url: `/api/congvanden/${id}/luututailieu`,
        method: 'POST',
        headers: {
          Authorization:
            getState().auth.token.tokenType +
            ' ' +
            getState().auth.token.accessToken,
        },
        data,
      },
    },
  })

export const documentsCabinetSaveUnitCategory = data => (dispatch, getState) =>
  dispatch({
    types: [
      types.API_REQUEST_SEND,
      types.DOCUMENTS_CABINET_SAVE_UNIT_CATEGORY,
      types.API_REQUEST_ERROR,
    ],
    payload: {
      request: {
        url: `/api/tutailieu/donvi`,
        method: 'POST',
        headers: {
          Authorization:
            getState().auth.token.tokenType +
            ' ' +
            getState().auth.token.accessToken,
        },
        data,
      },
    },
  })

export const documentsCabinetUpdateUnitCategory =
  data => (dispatch, getState) =>
    dispatch({
      types: [
        types.API_REQUEST_SEND,
        types.DOCUMENTS_CABINET_UPDATE_UNIT_CATEGORY,
        types.API_REQUEST_ERROR,
      ],
      payload: {
        request: {
          url: `/api/tutailieu/donvi`,
          method: 'PUT',
          headers: {
            Authorization:
              getState().auth.token.tokenType +
              ' ' +
              getState().auth.token.accessToken,
          },
          data,
        },
      },
    })

export const documentsCabinetSavePersonalCategory =
  data => (dispatch, getState) =>
    dispatch({
      types: [
        types.API_REQUEST_SEND,
        types.DOCUMENTS_CABINET_SAVE_PERSONAL_CATEGORY,
        types.API_REQUEST_ERROR,
      ],
      payload: {
        request: {
          url: `/api/tutailieu/canhan`,
          method: 'POST',
          headers: {
            Authorization:
              getState().auth.token.tokenType +
              ' ' +
              getState().auth.token.accessToken,
          },
          data,
        },
      },
    })

export const documentsCabinetUpdatePersonalCategory =
  data => (dispatch, getState) =>
    dispatch({
      types: [
        types.API_REQUEST_SEND,
        types.DOCUMENTS_CABINET_UPDATE_PERSONAL_CATEGORY,
        types.API_REQUEST_ERROR,
      ],
      payload: {
        request: {
          url: `/api/tutailieu/canhan`,
          method: 'PUT',
          headers: {
            Authorization:
              getState().auth.token.tokenType +
              ' ' +
              getState().auth.token.accessToken,
          },
          data,
        },
      },
    })

export const documentsCabinetSaveDepartmentCategory =
  data => (dispatch, getState) =>
    dispatch({
      types: [
        types.API_REQUEST_SEND,
        types.DOCUMENTS_CABINET_SAVE_DEPARTMENT_CATEGORY,
        types.API_REQUEST_ERROR,
      ],
      payload: {
        request: {
          url: `/api/tutailieu/phongban`,
          method: 'POST',
          headers: {
            Authorization:
              getState().auth.token.tokenType +
              ' ' +
              getState().auth.token.accessToken,
          },
          data,
        },
      },
    })

export const documentsCabinetUpdateDepartmentCategory =
  data => (dispatch, getState) =>
    dispatch({
      types: [
        types.API_REQUEST_SEND,
        types.DOCUMENTS_CABINET_UPDATE_DEPARTMENT_CATEGORY,
        types.API_REQUEST_ERROR,
      ],
      payload: {
        request: {
          url: `/api/tutailieu/phongban`,
          method: 'PUT',
          headers: {
            Authorization:
              getState().auth.token.tokenType +
              ' ' +
              getState().auth.token.accessToken,
          },
          data,
        },
      },
    })

export const documentsCabinetDeleteUnitCategory = id => (dispatch, getState) =>
  dispatch({
    types: [
      types.API_REQUEST_SEND,
      types.DOCUMENTS_CABINET_SAVE_UNIT_CATEGORY,
      types.API_REQUEST_ERROR,
    ],
    payload: {
      request: {
        url: `/api/tutailieu/donvi`,
        method: 'DELETE',
        headers: {
          Authorization:
            getState().auth.token.tokenType +
            ' ' +
            getState().auth.token.accessToken,
        },
        params: {
          id,
        },
      },
    },
  })

export const documentsCabinetDeletePersonalCategory =
  id => (dispatch, getState) =>
    dispatch({
      types: [
        types.API_REQUEST_SEND,
        types.DOCUMENTS_CABINET_SAVE_PERSONAL_CATEGORY,
        types.API_REQUEST_ERROR,
      ],
      payload: {
        request: {
          url: `/api/tutailieu/canhan`,
          method: 'DELETE',
          headers: {
            Authorization:
              getState().auth.token.tokenType +
              ' ' +
              getState().auth.token.accessToken,
          },
          params: {
            id,
          },
        },
      },
    })

export const documentsCabinetDeleteDepartmentCategory =
  id => (dispatch, getState) =>
    dispatch({
      types: [
        types.API_REQUEST_SEND,
        types.DOCUMENTS_CABINET_SAVE_DEPARTMENT_CATEGORY,
        types.API_REQUEST_ERROR,
      ],
      payload: {
        request: {
          url: `/api/tutailieu/phongban`,
          method: 'DELETE',
          headers: {
            Authorization:
              getState().auth.token.tokenType +
              ' ' +
              getState().auth.token.accessToken,
          },
          params: {
            id,
          },
        },
      },
    })

export const internalDocumentUpdate = (id, data) => (dispatch, getState) =>
  dispatch({
    types: [
      types.API_REQUEST_SEND,
      types.DOCUMENT_INTERNAL_UPDATE,
      types.API_REQUEST_ERROR,
    ],
    payload: {
      request: {
        url: `/api/congvannoibo/${id}`,
        method: 'PUT',
        headers: {
          Authorization:
            getState().auth.token.tokenType +
            ' ' +
            getState().auth.token.accessToken,
        },
        data,
      },
    },
  })

// export const getSendDocumentExecutingView = (id) => (dispatch, getState) => dispatch({
//   types: [ types.API_REQUEST_SEND, types.SEND_DOCUMENT_EXECUTING_VIEW, types.API_REQUEST_ERROR ],
//   payload: {
//     request: {
//       url: `/api/congvandi/${id}/xemthuchien`,
//       method: 'GET',
//       headers: {
//         'Authorization': getState().auth.token.tokenType + ' ' + getState().auth.token.accessToken
//       }
//     }
//   }
// });

export const sendDocumentUpdate = (id, data) => (dispatch, getState) =>
  dispatch({
    types: [
      types.API_REQUEST_SEND,
      types.DOCUMENT_SEND_UPDATE,
      types.API_REQUEST_ERROR,
    ],
    payload: {
      request: {
        url: `/api/congvandi/${id}`,
        method: 'PUT',
        headers: {
          Authorization:
            getState().auth.token.tokenType +
            ' ' +
            getState().auth.token.accessToken,
        },
        data,
      },
    },
  })

export const sendDocumentRetrieve = (id, donViId) => (dispatch, getState) =>
  dispatch({
    types: [
      types.API_REQUEST_SEND,
      types.DOCUMENT_SEND_RETRIEVE,
      types.API_REQUEST_ERROR,
    ],
    payload: {
      request: {
        url: `/api/congvandi/${id}/thuhoi`,
        method: 'DELETE',
        headers: {
          Authorization:
            getState().auth.token.tokenType +
            ' ' +
            getState().auth.token.accessToken,
        },
        params: {
          id: id,
          don_vi_id: donViId,
        },
      },
    },
  })

export const putRecall = data => (dispatch, getState) =>
  dispatch({
    types: [
      types.API_REQUEST_SEND,
      types.API_REQUEST_SUCCESS,
      types.API_REQUEST_ERROR,
    ],
    payload: {
      request: {
        url: '/api/thuhoicongvandi',
        method: 'POST',
        headers: {
          Authorization:
            getState().auth.token.tokenType +
            ' ' +
            getState().auth.token.accessToken,
        },
        data,
      },
    },
  })

export const receiveDocumentUpdateProposeAssigment =
  (id, data) => (dispatch, getState) =>
    dispatch({
      types: [
        types.API_REQUEST_SEND,
        types.DOCUMENT_RECEIVE_UPDATE_PROPOSE_ASSIGNMENT,
        types.API_REQUEST_ERROR,
      ],
      payload: {
        request: {
          url: `/api/congvanden/${id}/dexuatchidao`,
          method: 'PUT',
          headers: {
            Authorization:
              getState().auth.token.tokenType +
              ' ' +
              getState().auth.token.accessToken,
          },
          data: data,
        },
      },
    })

export const updateCommonFetchSaveCodes = data => (dispatch, getState) =>
  dispatch({
    types: [
      types.API_REQUEST_SEND,
      types.COMMON_FETCH_UPDATE_SAVECODES,
      types.API_REQUEST_ERROR,
    ],
    payload: {
      request: {
        url: `/api/soluu`,
        method: 'PUT',
        headers: {
          Authorization:
            getState().auth.token.tokenType +
            ' ' +
            getState().auth.token.accessToken,
        },
        data,
      },
    },
  })

export const postCommonFetchSaveCodes = data => (dispatch, getState) =>
  dispatch({
    types: [
      types.API_REQUEST_SEND,
      types.COMMON_FETCH_POST_SAVECODES,
      types.API_REQUEST_ERROR,
    ],
    payload: {
      request: {
        url: `/api/soluu`,
        method: 'POST',
        headers: {
          Authorization:
            getState().auth.token.tokenType +
            ' ' +
            getState().auth.token.accessToken,
        },
        data: data,
      },
    },
  })

export const getCommonFetchSaveCodeItem = id => (dispatch, getState) =>
  dispatch({
    types: [
      types.API_REQUEST_SEND,
      types.COMMON_FETCH_SAVECODES_ITEM,
      types.API_REQUEST_ERROR,
    ],
    // schema: SaveCodeItem,
    payload: {
      request: {
        url: `/api/soluu/${id}`,
        method: 'GET',
        headers: {
          Authorization:
            getState().auth.token.tokenType +
            ' ' +
            getState().auth.token.accessToken,
        },
      },
    },
  })

export const deleteCommonFetchSaveCodeItem = id => (dispatch, getState) =>
  dispatch({
    types: [
      types.API_REQUEST_SEND,
      types.COMMON_FETCH_DELETE_SAVECODES,
      types.API_REQUEST_ERROR,
    ],
    payload: {
      request: {
        url: `/api/soluu/${id}`,
        method: 'DELETE',
        headers: {
          Authorization:
            getState().auth.token.tokenType +
            ' ' +
            getState().auth.token.accessToken,
        },
      },
    },
  })

export const updateCommonFetchSecurityItem = data => (dispatch, getState) =>
  dispatch({
    types: [
      types.API_REQUEST_SEND,
      types.COMMON_FETCH_UPDATE_SECURITIES,
      types.API_REQUEST_ERROR,
    ],
    payload: {
      request: {
        url: `/api/dobaomat`,
        method: 'PUT',
        headers: {
          Authorization:
            getState().auth.token.tokenType +
            ' ' +
            getState().auth.token.accessToken,
        },
        data: data,
      },
    },
  })

export const sendDocumentSendAdditional = (id, data) => (dispatch, getState) =>
  dispatch({
    types: [
      types.API_REQUEST_SEND,
      types.DOCUMENT_SEND_ADDITIONAL,
      types.API_REQUEST_ERROR,
    ],
    payload: {
      request: {
        url: `/api/congvandi/${id}/guibosung`,
        method: 'PATCH',
        headers: {
          Authorization:
            getState().auth.token.tokenType +
            ' ' +
            getState().auth.token.accessToken,
        },
        data: data,
      },
    },
  })

export const getCommonFetchSecurityItem = id => (dispatch, getState) =>
  dispatch({
    types: [
      types.API_REQUEST_SEND,
      types.COMMON_FETCH_GET_SECURITIES_ITEM,
      types.API_REQUEST_ERROR,
    ],
    payload: {
      request: {
        url: `/api/dobaomat/${id}`,
        method: 'GET',
        headers: {
          Authorization:
            getState().auth.token.tokenType +
            ' ' +
            getState().auth.token.accessToken,
        },
      },
    },
  })

export const postCommonFetchSecurity = data => (dispatch, getState) =>
  dispatch({
    types: [
      types.API_REQUEST_SEND,
      types.COMMON_FETCH_POST_SECURITIES,
      types.API_REQUEST_ERROR,
    ],
    payload: {
      request: {
        url: `/api/dobaomat`,
        method: 'POST',
        headers: {
          Authorization:
            getState().auth.token.tokenType +
            ' ' +
            getState().auth.token.accessToken,
        },
        data: data,
      },
    },
  })

export const postCommonFetchUnit = data => (dispatch, getState) =>
  dispatch({
    types: [
      types.API_REQUEST_SEND,
      types.COMMON_FETCH_POST_UNIT,
      types.API_REQUEST_ERROR,
    ],
    payload: {
      request: {
        url: `/api/donvi`,
        method: 'POST',
        headers: {
          Authorization:
            getState().auth.token.tokenType +
            ' ' +
            getState().auth.token.accessToken,
        },
        data: data,
      },
    },
  })

export const deleteCommonFetchSecurity = id => (dispatch, getState) =>
  dispatch({
    types: [
      types.API_REQUEST_SEND,
      types.COMMON_FETCH_DELETE_SECURITIES,
      types.API_REQUEST_ERROR,
    ],
    payload: {
      request: {
        url: `/api/dobaomat/${id}`,
        method: 'DELETE',
        headers: {
          Authorization:
            getState().auth.token.tokenType +
            ' ' +
            getState().auth.token.accessToken,
        },
      },
    },
  })

export const updateCommonFetchObject = data => (dispatch, getState) =>
  dispatch({
    types: [
      types.API_REQUEST_SEND,
      types.COMMON_FETCH_UPDATE_OBJECT,
      types.API_REQUEST_ERROR,
    ],
    payload: {
      request: {
        url: `/api/doituongcongvan`,
        method: 'PUT',
        headers: {
          Authorization:
            getState().auth.token.tokenType +
            ' ' +
            getState().auth.token.accessToken,
        },
        data: data,
      },
    },
  })

export const postCommonFetchObject = data => (dispatch, getState) =>
  dispatch({
    types: [
      types.API_REQUEST_SEND,
      types.COMMON_FETCH_POST_OBJECT,
      types.API_REQUEST_ERROR,
    ],
    payload: {
      request: {
        url: `/api/doituongcongvan`,
        method: 'POST',
        headers: {
          Authorization:
            getState().auth.token.tokenType +
            ' ' +
            getState().auth.token.accessToken,
        },
        data: data,
      },
    },
  })

export const deleteCommonFetchObject = id => (dispatch, getState) =>
  dispatch({
    types: [
      types.API_REQUEST_SEND,
      types.COMMON_FETCH_DELETE_OBJECT,
      types.API_REQUEST_ERROR,
    ],
    payload: {
      request: {
        url: `/api/doituongcongvan/${id}`,
        method: 'DELETE',
        headers: {
          Authorization:
            getState().auth.token.tokenType +
            ' ' +
            getState().auth.token.accessToken,
        },
      },
    },
  })

export const getCommonFetchObjectItem = id => (dispatch, getState) =>
  dispatch({
    types: [
      types.API_REQUEST_SEND,
      types.COMMON_FETCH_GET_OBEJCT_ITEM,
      types.API_REQUEST_ERROR,
    ],
    payload: {
      request: {
        url: `/api/doituongcongvan/${id}`,
        method: 'GET',
        headers: {
          Authorization:
            getState().auth.token.tokenType +
            ' ' +
            getState().auth.token.accessToken,
        },
      },
    },
  })

export const deleteCommonFetchPriorities = id => (dispatch, getState) =>
  dispatch({
    types: [
      types.API_REQUEST_SEND,
      types.COMMON_FETCH_DELETE_PRIORITIES,
      types.API_REQUEST_ERROR,
    ],
    payload: {
      request: {
        url: `/api/dokhan/${id}`,
        method: 'DELETE',
        headers: {
          Authorization:
            getState().auth.token.tokenType +
            ' ' +
            getState().auth.token.accessToken,
        },
      },
    },
  })

export const postCommonFetchPriorities = data => (dispatch, getState) =>
  dispatch({
    types: [
      types.API_REQUEST_SEND,
      types.COMMON_FETCH_POST_PRIORITIES,
      types.API_REQUEST_ERROR,
    ],
    payload: {
      request: {
        url: `/api/dokhan`,
        method: 'POST',
        headers: {
          Authorization:
            getState().auth.token.tokenType +
            ' ' +
            getState().auth.token.accessToken,
        },
        data: data,
      },
    },
  })

export const getCommonFetchPrioritiesItem = id => (dispatch, getState) =>
  dispatch({
    types: [
      types.API_REQUEST_SEND,
      types.COMMON_FETCH_GET_PRIORITIES_ITEM,
      types.API_REQUEST_ERROR,
    ],
    payload: {
      request: {
        url: `/api/dokhan/${id}`,
        method: 'GET',
        headers: {
          Authorization:
            getState().auth.token.tokenType +
            ' ' +
            getState().auth.token.accessToken,
        },
      },
    },
  })

export const updateCommonFetchPriorities = data => (dispatch, getState) =>
  dispatch({
    types: [
      types.API_REQUEST_SEND,
      types.COMMON_FETCH_UPDATE_PRIORITIES,
      types.API_REQUEST_ERROR,
    ],
    payload: {
      request: {
        url: `/api/dokhan`,
        method: 'PUT',
        headers: {
          Authorization:
            getState().auth.token.tokenType +
            ' ' +
            getState().auth.token.accessToken,
        },
        data: data,
      },
    },
  })

export const commonFetchPosition =
  (donViId = null, isAll = false) =>
  (dispatch, getState) =>
    dispatch({
      types: [
        types.API_REQUEST_SEND,
        types.COMMON_FETCH_POSITION,
        types.API_REQUEST_ERROR,
      ],
      payload: {
        request: {
          url: `/api/chucvu`,
          method: 'GET',
          headers: {
            Authorization:
              getState().auth.token.tokenType +
              ' ' +
              getState().auth.token.accessToken,
          },
          params: {
            donViId,
            isAll,
          },
        },
      },
    })

export const updateCommonFetchPosition = data => (dispatch, getState) =>
  dispatch({
    types: [
      types.API_REQUEST_SEND,
      types.COMMON_FETCH_UPDATE_POSITION,
      types.API_REQUEST_ERROR,
    ],
    payload: {
      request: {
        url: `/api/chucvu`,
        method: 'PUT',
        headers: {
          Authorization:
            getState().auth.token.tokenType +
            ' ' +
            getState().auth.token.accessToken,
        },
        data: data,
      },
    },
  })

export const deleteCommonFetchPosition = id => (dispatch, getState) =>
  dispatch({
    types: [
      types.API_REQUEST_SEND,
      types.COMMON_FETCH_DELETE_POSITION,
      types.API_REQUEST_ERROR,
    ],
    payload: {
      request: {
        url: `/api/chucvu/${id}`,
        method: 'DELETE',
        headers: {
          Authorization:
            getState().auth.token.tokenType +
            ' ' +
            getState().auth.token.accessToken,
        },
      },
    },
  })

export const postCommonFetchPosition = data => (dispatch, getState) =>
  dispatch({
    types: [
      types.API_REQUEST_SEND,
      types.COMMON_FETCH_POST_POSITION,
      types.API_REQUEST_ERROR,
    ],
    payload: {
      request: {
        url: `/api/chucvu`,
        method: 'POST',
        headers: {
          Authorization:
            getState().auth.token.tokenType +
            ' ' +
            getState().auth.token.accessToken,
        },
        data: data,
      },
    },
  })

export const getCommonFetchPositionItem = id => (dispatch, getState) =>
  dispatch({
    types: [
      types.API_REQUEST_SEND,
      types.COMMON_FETCH_GET_POSITION_ITEM,
      types.API_REQUEST_ERROR,
    ],
    payload: {
      request: {
        url: `/api/chucvu/${id}`,
        method: 'GET',
        headers: {
          Authorization:
            getState().auth.token.tokenType +
            ' ' +
            getState().auth.token.accessToken,
        },
      },
    },
  })

export const signNumberSendAgree = (id, data) => (dispatch, getState) =>
  dispatch({
    types: [
      types.API_REQUEST_SEND,
      types.SIGN_NUMBER_SEND_AGREE,
      types.API_REQUEST_ERROR,
    ],
    payload: {
      request: {
        url: `/api/kysodi/${id}/kyduyet`,
        method: 'PATCH',
        headers: {
          Authorization:
            getState().auth.token.tokenType +
            ' ' +
            getState().auth.token.accessToken,
        },
        data: data,
      },
    },
  })

export const documentsCabinetDeleteUnit = data => (dispatch, getState) =>
  dispatch({
    types: [
      types.API_REQUEST_SEND,
      types.DOCUMENTS_CABINET_UNIT_DELETE,
      types.API_REQUEST_ERROR,
    ],
    payload: {
      request: {
        url: `/api/tailieudonvi`,
        method: 'DELETE',
        headers: {
          Authorization:
            getState().auth.token.tokenType +
            ' ' +
            getState().auth.token.accessToken,
        },
        data,
      },
    },
  })

export const getCommonFetchUnitItem = id => (dispatch, getState) =>
  dispatch({
    types: [
      types.API_REQUEST_SEND,
      types.COMMON_FETCH_GET_UNIT_ITEM,
      types.API_REQUEST_ERROR,
    ],
    payload: {
      request: {
        url: `/api/donvi/${id}`,
        method: 'GET',
        headers: {
          Authorization:
            getState().auth.token.tokenType +
            ' ' +
            getState().auth.token.accessToken,
        },
      },
    },
  })

export const getUnitItem = id => (dispatch, getState) =>
  dispatch({
    types: [
      types.API_REQUEST_SEND,
      types.API_REQUEST_SUCCESS,
      types.API_REQUEST_ERROR,
    ],
    payload: {
      request: {
        url: `/api/donvi/${id}`,
        method: 'GET',
        headers: {
          Authorization:
            getState().auth.token.tokenType +
            ' ' +
            getState().auth.token.accessToken,
        },
      },
    },
  })

export const documentsCabinetDeletePersonal = data => (dispatch, getState) =>
  dispatch({
    types: [
      types.API_REQUEST_SEND,
      types.DOCUMENTS_CABINET_PERSONAL_DELETE,
      types.API_REQUEST_ERROR,
    ],
    payload: {
      request: {
        url: `/api/tailieucanhan`,
        method: 'DELETE',
        headers: {
          Authorization:
            getState().auth.token.tokenType +
            ' ' +
            getState().auth.token.accessToken,
        },
        data,
      },
    },
  })

export const updateCommonFetchUnit = data => (dispatch, getState) =>
  dispatch({
    types: [
      types.API_REQUEST_SEND,
      types.COMMON_FETCH_UPDATE_UNIT,
      types.API_REQUEST_ERROR,
    ],
    payload: {
      request: {
        url: `/api/donvi`,
        method: 'PUT',
        headers: {
          Authorization:
            getState().auth.token.tokenType +
            ' ' +
            getState().auth.token.accessToken,
        },
        data: data,
      },
    },
  })

export const signNumberSendDisagree = (id, data) => (dispatch, getState) =>
  dispatch({
    types: [
      types.API_REQUEST_SEND,
      types.SIGN_NUMBER_SEND_DISAGREE,
      types.API_REQUEST_ERROR,
    ],
    payload: {
      request: {
        url: `/api/kysodi/${id}/khongdongy`,
        method: 'PATCH',
        headers: {
          Authorization:
            getState().auth.token.tokenType +
            ' ' +
            getState().auth.token.accessToken,
        },
        data: data,
      },
    },
  })

export const documentsCabinetDeleteDepartment = data => (dispatch, getState) =>
  dispatch({
    types: [
      types.API_REQUEST_SEND,
      types.DOCUMENTS_CABINET_DEPARTMENT_DELETE,
      types.API_REQUEST_ERROR,
    ],
    payload: {
      request: {
        url: `/api/tailieuphongban`,
        method: 'DELETE',
        headers: {
          Authorization:
            getState().auth.token.tokenType +
            ' ' +
            getState().auth.token.accessToken,
        },
        data,
      },
    },
  })

export const deleteCommonFetchUnit = id => (dispatch, getState) =>
  dispatch({
    types: [
      types.API_REQUEST_SEND,
      types.COMMON_FETCH_DELETE_UNIT,
      types.API_REQUEST_ERROR,
    ],
    payload: {
      request: {
        url: `/api/donvi/${id}`,
        method: 'DELETE',
        headers: {
          Authorization:
            getState().auth.token.tokenType +
            ' ' +
            getState().auth.token.accessToken,
        },
      },
    },
  })

export const getStorageCapacity = type => (dispatch, getState) =>
  dispatch({
    types: [
      types.API_REQUEST_SEND,
      types.STORAGE_CAPACITY,
      types.API_REQUEST_ERROR,
    ],
    payload: {
      request: {
        url: `/api/tutailieu/${type}/dungluong`,
        method: 'GET',
        headers: {
          Authorization:
            getState().auth.token.tokenType +
            ' ' +
            getState().auth.token.accessToken,
        },
      },
    },
  })

export const commonFetchUnit =
  (page = 1) =>
  (dispatch, getState) =>
    dispatch({
      types: [
        types.API_REQUEST_SEND,
        types.COMMON_FETCH_UNIT,
        types.API_REQUEST_ERROR,
      ],
      payload: {
        request: {
          url: `/api/donvi`,
          method: 'GET',
          headers: {
            Authorization:
              getState().auth.token.tokenType +
              ' ' +
              getState().auth.token.accessToken,
          },
          params: {
            page: page,
            limit: 0,
          },
        },
      },
    })

export const getCommonFetchDeparthmentItems = id => (dispatch, getState) =>
  dispatch({
    types: [
      types.API_REQUEST_SEND,
      types.COMMON_FETCH_GET_DEPARTMENTS_ITEM,
      types.API_REQUEST_ERROR,
    ],
    payload: {
      request: {
        url: `/api/danhmuc/phongban/${id}`,
        method: 'GET',
        headers: {
          Authorization:
            getState().auth.token.tokenType +
            ' ' +
            getState().auth.token.accessToken,
        },
      },
    },
  })

export const updateCommonFetchDepartment = data => (dispatch, getState) =>
  dispatch({
    types: [
      types.API_REQUEST_SEND,
      types.COMMON_FETCH_UPDATE_DEPARTMENTS,
      types.API_REQUEST_ERROR,
    ],
    payload: {
      request: {
        url: `/api/danhmuc/phongban`,
        method: 'PUT',
        headers: {
          Authorization:
            getState().auth.token.tokenType +
            ' ' +
            getState().auth.token.accessToken,
        },
        data: data,
      },
    },
  })

export const deleteCommonFetchDepartment = id => (dispatch, getState) =>
  dispatch({
    types: [
      types.API_REQUEST_SEND,
      types.COMMON_FETCH_DELETE_DEPARTMENTS,
      types.API_REQUEST_ERROR,
    ],
    payload: {
      request: {
        url: `/api/danhmuc/phongban/${id}`,
        method: 'DELETE',
        headers: {
          Authorization:
            getState().auth.token.tokenType +
            ' ' +
            getState().auth.token.accessToken,
        },
      },
    },
  })

export const postCommonFetchDepartment = data => (dispatch, getState) =>
  dispatch({
    types: [
      types.API_REQUEST_SEND,
      types.COMMON_FETCH_POST_DEPARTMENTS,
      types.API_REQUEST_ERROR,
    ],
    payload: {
      request: {
        url: `/api/danhmuc/phongban`,
        method: 'POST',
        headers: {
          Authorization:
            getState().auth.token.tokenType +
            ' ' +
            getState().auth.token.accessToken,
        },
        data: data,
      },
    },
  })

export const signNumberInternalAgree = (id, data) => (dispatch, getState) =>
  dispatch({
    types: [
      types.API_REQUEST_SEND,
      types.SIGN_NUMBER_INTERNAL_AGREE,
      types.API_REQUEST_ERROR,
    ],
    payload: {
      request: {
        url: `/api/kysonoibo/${id}/kyduyet`,
        method: 'PATCH',
        headers: {
          Authorization:
            getState().auth.token.tokenType +
            ' ' +
            getState().auth.token.accessToken,
        },
        data: data,
      },
    },
  })

export const updateArrayCommonFetchSecurity = data => (dispatch, getState) =>
  dispatch({
    types: [
      types.API_REQUEST_SEND,
      types.COMMON_FETCH_UPDATE_ARRAY_SECURITY,
      types.API_REQUEST_ERROR,
    ],
    payload: {
      request: {
        url: `/api/dobaomat/sort`,
        method: 'PUT',
        headers: {
          Authorization:
            getState().auth.token.tokenType +
            ' ' +
            getState().auth.token.accessToken,
        },
        data: data,
      },
    },
  })

export const signNumberInternalDisagree = (id, data) => (dispatch, getState) =>
  dispatch({
    types: [
      types.API_REQUEST_SEND,
      types.SIGN_NUMBER_INTERNAL_DISAGREE,
      types.API_REQUEST_ERROR,
    ],
    payload: {
      request: {
        url: `/api/kysonoibo/${id}/khongdongy`,
        method: 'PATCH',
        headers: {
          Authorization:
            getState().auth.token.tokenType +
            ' ' +
            getState().auth.token.accessToken,
        },
        data: data,
      },
    },
  })

export const updateArrayCommonFetchSaveNote = data => (dispatch, getState) =>
  dispatch({
    types: [
      types.API_REQUEST_SEND,
      types.COMMON_FETCH_UPDATE_ARRAY_SAVENOTE,
      types.API_REQUEST_ERROR,
    ],
    payload: {
      request: {
        url: `/api/soluu/sort`,
        method: 'PUT',
        headers: {
          Authorization:
            getState().auth.token.tokenType +
            ' ' +
            getState().auth.token.accessToken,
        },
        data: data,
      },
    },
  })

export const updateArrayCommonFetchPosition = data => (dispatch, getState) =>
  dispatch({
    types: [
      types.API_REQUEST_SEND,
      types.COMMON_FETCH_UPDATE_ARRAY_POSITION,
      types.API_REQUEST_ERROR,
    ],
    payload: {
      request: {
        url: `/api/chucvu/sort`,
        method: 'PUT',
        headers: {
          Authorization:
            getState().auth.token.tokenType +
            ' ' +
            getState().auth.token.accessToken,
        },
        data: data,
      },
    },
  })

export const signNumberInternalFeedbackGet =
  (id, page = 1) =>
  (dispatch, getState) =>
    dispatch({
      types: [
        types.API_REQUEST_SEND,
        types.SIGN_NUMBER_INTERNAL_DETAIL_FEEDBACK,
        types.API_REQUEST_ERROR,
      ],
      schema: SignNumberFeedbackSchema,
      page: page,
      payload: {
        request: {
          url: `/api/kysonoibo/${id}/gopy`,
          method: 'GET',
          headers: {
            Authorization:
              getState().auth.token.tokenType +
              ' ' +
              getState().auth.token.accessToken,
          },
          params: {
            page: page,
            limit: 50,
          },
        },
      },
    })

export const updateArrayCommonFetchUrgency = data => (dispatch, getState) =>
  dispatch({
    types: [
      types.API_REQUEST_SEND,
      types.COMMON_FETCH_UPDATE_ARRAY_URGENCY,
      types.API_REQUEST_ERROR,
    ],
    payload: {
      request: {
        url: `/api/dokhan/sort`,
        method: 'PUT',
        headers: {
          Authorization:
            getState().auth.token.tokenType +
            ' ' +
            getState().auth.token.accessToken,
        },
        data: data,
      },
    },
  })

export const signNumberInternalFeedbackPost =
  (id, data) => (dispatch, getState) =>
    dispatch({
      types: [
        types.API_REQUEST_SEND,
        types.SIGN_NUMBER_INTERNAL_DETAIL_FEEDBACK_POST,
        types.API_REQUEST_ERROR,
      ],
      schema: SignNumberFeedbackSchema,
      payload: {
        request: {
          url: `/api/kysonoibo/${id}/gopy`,
          method: 'POST',
          headers: {
            Authorization:
              getState().auth.token.tokenType +
              ' ' +
              getState().auth.token.accessToken,
          },
          data: data,
        },
      },
    })

export const updateArrayCommonFetchSubject = data => (dispatch, getState) =>
  dispatch({
    types: [
      types.API_REQUEST_SEND,
      types.COMMON_FETCH_UPDATE_ARRAY_SUBJECT,
      types.API_REQUEST_ERROR,
    ],
    payload: {
      request: {
        url: `/api/doituongcongvan/sort`,
        method: 'PUT',
        headers: {
          Authorization:
            getState().auth.token.tokenType +
            ' ' +
            getState().auth.token.accessToken,
        },
        data: data,
      },
    },
  })

export const updateArrayCommonFetchUnit = data => (dispatch, getState) =>
  dispatch({
    types: [
      types.API_REQUEST_SEND,
      types.COMMON_FETCH_UPDATE_ARRAY_UNIT,
      types.API_REQUEST_ERROR,
    ],
    payload: {
      request: {
        url: `/api/donvi/sort`,
        method: 'PUT',
        headers: {
          Authorization:
            getState().auth.token.tokenType +
            ' ' +
            getState().auth.token.accessToken,
        },
        data: data,
      },
    },
  })

export const signNumberSendFeedbackUpdate =
  (id, feedbackId, data) => (dispatch, getState) =>
    dispatch({
      types: [
        types.API_REQUEST_SEND,
        types.SIGN_NUMBER_SEND_UPDATE_FEEDBACK,
        types.API_REQUEST_ERROR,
      ],
      payload: {
        request: {
          url: `/api/kysodi/${id}/gopy/${feedbackId}`,
          method: 'PUT',
          headers: {
            Authorization:
              getState().auth.token.tokenType +
              ' ' +
              getState().auth.token.accessToken,
          },
          data: data,
        },
      },
    })

export const updateArrayCommonFetchDepartment = data => (dispatch, getState) =>
  dispatch({
    types: [
      types.API_REQUEST_SEND,
      types.COMMON_FETCH_UPDATE_ARRAY_DEPARTMENT,
      types.API_REQUEST_ERROR,
    ],
    payload: {
      request: {
        url: `/api/phongban/sort`,
        method: 'PUT',
        headers: {
          Authorization:
            getState().auth.token.tokenType +
            ' ' +
            getState().auth.token.accessToken,
        },
        data: data,
      },
    },
  })

export const signNumberInternalFeedbackUpdate =
  (id, feedbackId, data) => (dispatch, getState) =>
    dispatch({
      types: [
        types.API_REQUEST_SEND,
        types.SIGN_NUMBER_INTERNAL_UPDATE_FEEDBACK,
        types.API_REQUEST_ERROR,
      ],
      payload: {
        request: {
          url: `/api/kysonoibo/${id}/gopy/${feedbackId}`,
          method: 'PUT',
          headers: {
            Authorization:
              getState().auth.token.tokenType +
              ' ' +
              getState().auth.token.accessToken,
          },
          data: data,
        },
      },
    })

export const documentsCabinetNewItemUnit = data => (dispatch, getState) =>
  dispatch({
    types: [
      types.API_REQUEST_SEND,
      types.DOCUMENTS_CABINET_NEW_ITEM_UNIT,
      types.API_REQUEST_ERROR,
    ],
    payload: {
      request: {
        url: `/api/tailieudonvi`,
        method: 'POST',
        headers: {
          Authorization:
            getState().auth.token.tokenType +
            ' ' +
            getState().auth.token.accessToken,
        },
        data: data,
      },
    },
  })

export const documentsCabinetNewItemDepartment = data => (dispatch, getState) =>
  dispatch({
    types: [
      types.API_REQUEST_SEND,
      types.DOCUMENTS_CABINET_NEW_ITEM_DEPARTMENT,
      types.API_REQUEST_ERROR,
    ],
    payload: {
      request: {
        url: `/api/tailieuphongban`,
        method: 'POST',
        headers: {
          Authorization:
            getState().auth.token.tokenType +
            ' ' +
            getState().auth.token.accessToken,
        },
        data: data,
      },
    },
  })

export const documentsCabinetNewItemPersonal = data => (dispatch, getState) =>
  dispatch({
    types: [
      types.API_REQUEST_SEND,
      types.DOCUMENTS_CABINET_NEW_ITEM_PERSONAL,
      types.API_REQUEST_ERROR,
    ],
    payload: {
      request: {
        url: `/api/tailieucanhan`,
        method: 'POST',
        headers: {
          Authorization:
            getState().auth.token.tokenType +
            ' ' +
            getState().auth.token.accessToken,
        },
        data: data,
      },
    },
  })

export const documentsCabinetGetItemUnit = id => (dispatch, getState) =>
  dispatch({
    types: [
      types.API_REQUEST_SEND,
      types.DOCUMENTS_CABINET_GET_ITEM_UNIT,
      types.API_REQUEST_ERROR,
    ],
    schema: DocumentCabinetSchema,
    payload: {
      request: {
        url: `/api/tailieudonvi/${id}`,
        method: 'GET',
        headers: {
          Authorization:
            getState().auth.token.tokenType +
            ' ' +
            getState().auth.token.accessToken,
        },
      },
    },
  })

export const documentsCabinetGetItemDepartment = id => (dispatch, getState) =>
  dispatch({
    types: [
      types.API_REQUEST_SEND,
      types.DOCUMENTS_CABINET_GET_ITEM_DEPARTMENT,
      types.API_REQUEST_ERROR,
    ],
    schema: DocumentCabinetSchema,
    payload: {
      request: {
        url: `/api/tailieuphongban/${id}`,
        method: 'GET',
        headers: {
          Authorization:
            getState().auth.token.tokenType +
            ' ' +
            getState().auth.token.accessToken,
        },
      },
    },
  })

export const documentsCabinetGetItemPersonal = id => (dispatch, getState) =>
  dispatch({
    types: [
      types.API_REQUEST_SEND,
      types.DOCUMENTS_CABINET_GET_ITEM_PERSONAL,
      types.API_REQUEST_ERROR,
    ],
    schema: DocumentCabinetSchema,
    payload: {
      request: {
        url: `/api/tailieucanhan/${id}`,
        method: 'GET',
        headers: {
          Authorization:
            getState().auth.token.tokenType +
            ' ' +
            getState().auth.token.accessToken,
        },
      },
    },
  })

export const documentsCabinetEditItemUnit = data => (dispatch, getState) =>
  dispatch({
    types: [
      types.API_REQUEST_SEND,
      types.DOCUMENTS_CABINET_EDIT_ITEM_UNIT,
      types.API_REQUEST_ERROR,
    ],
    payload: {
      request: {
        url: `/api/tailieudonvi`,
        method: 'PUT',
        headers: {
          Authorization:
            getState().auth.token.tokenType +
            ' ' +
            getState().auth.token.accessToken,
        },
        data,
      },
    },
  })

export const documentsCabinetEditItemDepartment =
  data => (dispatch, getState) =>
    dispatch({
      types: [
        types.API_REQUEST_SEND,
        types.DOCUMENTS_CABINET_EDIT_ITEM_DEPARTMENT,
        types.API_REQUEST_ERROR,
      ],
      payload: {
        request: {
          url: `/api/tailieuphongban`,
          method: 'PUT',
          headers: {
            Authorization:
              getState().auth.token.tokenType +
              ' ' +
              getState().auth.token.accessToken,
          },
          data,
        },
      },
    })

export const documentsCabinetEditItemPersonal = data => (dispatch, getState) =>
  dispatch({
    types: [
      types.API_REQUEST_SEND,
      types.DOCUMENTS_CABINET_EDIT_ITEM_PERSONAL,
      types.API_REQUEST_ERROR,
    ],
    payload: {
      request: {
        url: `/api/tailieucanhan`,
        method: 'PUT',
        headers: {
          Authorization:
            getState().auth.token.tokenType +
            ' ' +
            getState().auth.token.accessToken,
        },
        data,
      },
    },
  })

export const documentsCabinetMoveItemsUnit = data => (dispatch, getState) =>
  dispatch({
    types: [
      types.API_REQUEST_SEND,
      types.DOCUMENTS_CABINET_MOVE_ITEM_UNIT,
      types.API_REQUEST_ERROR,
    ],
    payload: {
      request: {
        url: `/api/tailieudonvi/dichuyen`,
        method: 'POST',
        headers: {
          'api-version': 2.0,
          Authorization:
            getState().auth.token.tokenType +
            ' ' +
            getState().auth.token.accessToken,
        },
        data,
      },
    },
  })

export const getCommonFetchRole =
  (id, trangThai = null) =>
  (dispatch, getState) =>
    dispatch({
      types: [
        types.API_REQUEST_SEND,
        types.COMMON_FETCH_GET_ROLE,
        types.API_REQUEST_ERROR,
      ],
      payload: {
        request: {
          url: `/api/donvi/${id}/doituong`,
          method: 'GET',
          headers: {
            Authorization:
              getState().auth.token.tokenType +
              ' ' +
              getState().auth.token.accessToken,
          },
          params: {
            trangThai,
          },
        },
      },
    })

export const documentsCabinetMoveItemsDepartment =
  data => (dispatch, getState) =>
    dispatch({
      types: [
        types.API_REQUEST_SEND,
        types.DOCUMENTS_CABINET_MOVE_ITEM_DEPARTMENT,
        types.API_REQUEST_ERROR,
      ],
      payload: {
        request: {
          url: `/api/tailieuphongban/dichuyen`,
          method: 'POST',
          headers: {
            'api-version': 2.0,
            Authorization:
              getState().auth.token.tokenType +
              ' ' +
              getState().auth.token.accessToken,
          },
          data,
        },
      },
    })

export const updateArrayCommonFetchRole = data => (dispatch, getState) =>
  dispatch({
    types: [
      types.API_REQUEST_SEND,
      types.COMMON_FETCH_UPDATE_ARRAY_ROLE,
      types.API_REQUEST_ERROR,
    ],
    payload: {
      request: {
        url: `/api/doituong/sort`,
        method: 'PUT',
        headers: {
          Authorization:
            getState().auth.token.tokenType +
            ' ' +
            getState().auth.token.accessToken,
        },
        data,
      },
    },
  })

export const documentsCabinetMoveItemsPersonal = data => (dispatch, getState) =>
  dispatch({
    types: [
      types.API_REQUEST_SEND,
      types.DOCUMENTS_CABINET_MOVE_ITEM_PERSONAL,
      types.API_REQUEST_ERROR,
    ],
    payload: {
      request: {
        url: `/api/tailieucanhan/dichuyen`,
        method: 'POST',
        headers: {
          'api-version': 2.0,
          Authorization:
            getState().auth.token.tokenType +
            ' ' +
            getState().auth.token.accessToken,
        },
        data,
      },
    },
  })

export const deleteCommonFetchRole = (id, doiTuongId) => (dispatch, getState) =>
  dispatch({
    types: [
      types.API_REQUEST_SEND,
      types.COMMON_FETCH_DELETE_ROLE,
      types.API_REQUEST_ERROR,
    ],
    payload: {
      request: {
        url: `/api/donvi/${id}/doituong/${doiTuongId}`,
        method: 'DELETE',
        headers: {
          Authorization:
            getState().auth.token.tokenType +
            ' ' +
            getState().auth.token.accessToken,
        },
      },
    },
  })

export const documentsCabinetGetItemsUnit = params => (dispatch, getState) =>
  dispatch({
    types: [
      types.API_REQUEST_SEND,
      types.DOCUMENTS_CABINET_GET_ITEMS_UNIT,
      types.API_REQUEST_ERROR,
    ],
    schema: DocumentCabinetArraySchema,
    payload: {
      request: {
        url: `/api/tailieudonvi/dschitiet`,
        method: 'GET',
        headers: {
          Authorization:
            getState().auth.token.tokenType +
            ' ' +
            getState().auth.token.accessToken,
        },
        params: {
          ds_id: params,
        },
      },
    },
  })

export const getCommonFetchRoleItem =
  (id, doiTuongId) => (dispatch, getState) =>
    dispatch({
      types: [
        types.API_REQUEST_SEND,
        types.COMMON_FETCH_GET_ROLE_ITEM,
        types.API_REQUEST_ERROR,
      ],
      payload: {
        request: {
          url: `/api/donvi/${id}/doituong/${doiTuongId}`,
          method: 'GET',
          headers: {
            Authorization:
              getState().auth.token.tokenType +
              ' ' +
              getState().auth.token.accessToken,
          },
        },
      },
    })

export const documentsCabinetGetItemsDepartment =
  params => (dispatch, getState) =>
    dispatch({
      types: [
        types.API_REQUEST_SEND,
        types.DOCUMENTS_CABINET_GET_ITEMS_DEPARTMENT,
        types.API_REQUEST_ERROR,
      ],
      schema: DocumentCabinetArraySchema,
      payload: {
        request: {
          url: `/api/tailieuphongban/dschitiet`,
          method: 'GET',
          headers: {
            Authorization:
              getState().auth.token.tokenType +
              ' ' +
              getState().auth.token.accessToken,
          },
          params: {
            ds_id: params,
          },
        },
      },
    })

export const postCommonFetchRole = (id, data) => (dispatch, getState) =>
  dispatch({
    types: [
      types.API_REQUEST_SEND,
      types.COMMON_FETCH_POST_ROLE_ITEM,
      types.API_REQUEST_ERROR,
    ],
    payload: {
      request: {
        url: `/api/donvi/${id}/doituong`,
        method: 'POST',
        headers: {
          Authorization:
            getState().auth.token.tokenType +
            ' ' +
            getState().auth.token.accessToken,
        },
        data: data,
      },
    },
  })

export const documentsCabinetGetItemsPersonal =
  params => (dispatch, getState) =>
    dispatch({
      types: [
        types.API_REQUEST_SEND,
        types.DOCUMENTS_CABINET_GET_ITEMS_PERSONAL,
        types.API_REQUEST_ERROR,
      ],
      schema: DocumentCabinetArraySchema,
      payload: {
        request: {
          url: `/api/tailieucanhan/dschitiet`,
          method: 'GET',
          headers: {
            Authorization:
              getState().auth.token.tokenType +
              ' ' +
              getState().auth.token.accessToken,
          },
          params: {
            ds_id: params,
          },
        },
      },
    })

export const updateCommonFetchRole = (id, data) => (dispatch, getState) =>
  dispatch({
    types: [
      types.API_REQUEST_SEND,
      types.COMMON_FETCH_UPDATE_ROLE,
      types.API_REQUEST_ERROR,
    ],
    payload: {
      request: {
        url: `/api/donvi/${id}/doituong`,
        method: 'PUT',
        headers: {
          Authorization:
            getState().auth.token.tokenType +
            ' ' +
            getState().auth.token.accessToken,
        },
        data: data,
      },
    },
  })

export const getCategoryCommonFetchRole = id => (dispatch, getState) =>
  dispatch({
    types: [
      types.API_REQUEST_SEND,
      types.COMMON_FETCH_CATEGORY_ROLE,
      types.API_REQUEST_ERROR,
    ],
    payload: {
      request: {
        url: `/api/donvi/${id}/dsdoituong`,
        method: 'GET',
        headers: {
          Authorization:
            getState().auth.token.tokenType +
            ' ' +
            getState().auth.token.accessToken,
        },
      },
    },
  })

export const deleteCategoryCommonFetchRole =
  (id, data) => (dispatch, getState) =>
    dispatch({
      types: [
        types.API_REQUEST_SEND,
        types.COMMON_FETCH_DELETE_CATEGORY_ROLE,
        types.API_REQUEST_ERROR,
      ],
      payload: {
        request: {
          url: `/api/doituong/${id}/quyen`,
          method: 'DELETE',
          headers: {
            Authorization:
              getState().auth.token.tokenType +
              ' ' +
              getState().auth.token.accessToken,
          },
          data: `"${data.ma_quyen}"`,
        },
      },
    })

export const deleteCategoryCommonFetchRoles =
  (id, data) => (dispatch, getState) =>
    dispatch({
      types: [
        types.API_REQUEST_SEND,
        types.COMMON_FETCH_DELETE_CATEGORY_ROLE,
        types.API_REQUEST_ERROR,
      ],
      payload: {
        request: {
          url: `/api/doituong/${id}/dsquyen`,
          method: 'DELETE',
          headers: {
            Authorization:
              getState().auth.token.tokenType +
              ' ' +
              getState().auth.token.accessToken,
          },
          data,
        },
      },
    })

export const postCategoryCommonFetchRole = (id, data) => (dispatch, getState) =>
  dispatch({
    types: [
      types.API_REQUEST_SEND,
      types.COMMON_FETCH_POST_CATEGORY_ROLE,
      types.API_REQUEST_ERROR,
    ],
    payload: {
      request: {
        url: `/api/doituong/${id}/quyen`,
        method: 'POST',
        headers: {
          Authorization:
            getState().auth.token.tokenType +
            ' ' +
            getState().auth.token.accessToken,
        },
        data: `"${data.ma_quyen}"`,
      },
    },
  })

export const postCategoryCommonFetchRoles =
  (id, data) => (dispatch, getState) =>
    dispatch({
      types: [
        types.API_REQUEST_SEND,
        types.COMMON_FETCH_POST_CATEGORY_ROLE,
        types.API_REQUEST_ERROR,
      ],
      payload: {
        request: {
          url: `/api/doituong/${id}/dsquyen`,
          method: 'POST',
          headers: {
            Authorization:
              getState().auth.token.tokenType +
              ' ' +
              getState().auth.token.accessToken,
          },
          data,
        },
      },
    })

export const getCommonFetchCategoryPermission =
  (id, filter = {}, page = 1, sort = null) =>
  (dispatch, getState) => {
    let params = {
      sort,
      page: page,
      limit: utils.PAGINATION_LIMIT,
    }

    params = Object.assign(params, Tool.Utils.linkObjectKey(filter))

    return dispatch({
      types: [
        types.API_REQUEST_SEND,
        types.COMMON_FETCH_GET_CATEGORY_PERMISSION,
        types.API_REQUEST_ERROR,
      ],
      page: page,
      payload: {
        request: {
          url: `/api/donvi/${id}/dsphanquyen`,
          method: 'GET',
          headers: {
            Authorization:
              getState().auth.token.tokenType +
              ' ' +
              getState().auth.token.accessToken,
          },
          params,
        },
      },
    })
  }

export const utilityReceiveDocumentGet =
  (filter = {}, page = 1, sort = null, filterColumn = {}) =>
  (dispatch, getState) => {
    let params = {
      sort,
      page: page,
      limit: utils.PAGINATION_LIMIT,
    }

    params = Object.assign(Tool.Utils.linkObjectKey(filter), params)
    params = Object.assign(
      Tool.Utils.linkObjectKey({ filter_column: filterColumn }),
      params
    )

    return dispatch({
      types: [
        types.API_REQUEST_INIT_DOCUMENT_RECEIVE_SEND,
        types.DOCUMENT_RECEIVE,
        types.API_REQUEST_ERROR,
      ],
      schema: ReceiveDocumentSchema,
      page: page,
      payload: {
        request: {
          url: '/api/tienich/congvan',
          method: 'GET',
          headers: {
            'api-version': 2.0,
            Authorization:
              getState().auth.token.tokenType +
              ' ' +
              getState().auth.token.accessToken,
          },
          params,
        },
      },
    })
  }

export const postAddStaff = data => (dispatch, getState) =>
  dispatch({
    types: [
      types.API_REQUEST_SEND,
      types.POST_ADD_STAFF,
      types.API_REQUEST_ERROR,
    ],
    payload: {
      request: {
        url: `/api/nhanvien`,
        method: 'POST',
        headers: {
          Authorization:
            getState().auth.token.tokenType +
            ' ' +
            getState().auth.token.accessToken,
        },
        data,
      },
    },
  })

export const deleteStaff = id => (dispatch, getState) =>
  dispatch({
    types: [
      types.API_REQUEST_SEND,
      types.DELETE_STAFF,
      types.API_REQUEST_ERROR,
    ],
    payload: {
      request: {
        url: `/api/nhanvienphongban/${id}`,
        method: 'DELETE',
        headers: {
          Authorization:
            getState().auth.token.tokenType +
            ' ' +
            getState().auth.token.accessToken,
        },
      },
    },
  })

export const getStaffItem = id => (dispatch, getState) =>
  dispatch({
    types: [
      types.API_REQUEST_SEND,
      types.GET_STAFF_ITEM,
      types.API_REQUEST_ERROR,
    ],
    payload: {
      request: {
        url: `/api/nhanvien/${id}/phanquyen`,
        method: 'GET',
        headers: {
          Authorization:
            getState().auth.token.tokenType +
            ' ' +
            getState().auth.token.accessToken,
        },
      },
    },
  })

export const utilityExportReceiveDocumentGet =
  (filter = {}, page = 1, sort = null, filterColumn = {}) =>
  (dispatch, getState) => {
    let params = {
      sort,
      page: page,
      limit: utils.PAGINATION_LIMIT,
    }

    params = Object.assign(params, Tool.Utils.linkObjectKey(filter))
    params = Object.assign(
      Tool.Utils.linkObjectKey({ filter_column: filterColumn }),
      params
    )

    return dispatch({
      types: [
        types.API_REQUEST_SEND,
        types.DOCUMENT_RECEIVE_EXPORT,
        types.API_REQUEST_ERROR,
      ],
      page: page,
      payload: {
        client: 'download',
        request: {
          url: '/api/tienich/congvan/export',
          method: 'GET',
          headers: {
            'api-version': 2.0,
            Authorization:
              getState().auth.token.tokenType +
              ' ' +
              getState().auth.token.accessToken,
          },
          params,
        },
      },
    })
  }

export const utilityPrintSendDocumentGet =
  (filter = {}, page = 1, sort = null) =>
  (dispatch, getState) => {
    let params = {
      sort,
      page: page,
      limit: utils.PAGINATION_LIMIT,
    }

    params = Object.assign(params, Tool.Utils.linkObjectKey(filter))

    return dispatch({
      types: [
        types.API_REQUEST_SEND,
        types.DOCUMENT_SEND,
        types.API_REQUEST_ERROR,
      ],
      schema: SendDocumentSchema,
      page: page,
      payload: {
        request: {
          url: '/api/socongvan/congvandi',
          method: 'GET',
          headers: {
            Authorization:
              getState().auth.token.tokenType +
              ' ' +
              getState().auth.token.accessToken,
          },
          params: _.pickBy(params),
        },
      },
    })
  }

export const utilityPrintExportSendDocumentGet =
  (filter = {}, page = 1, sort = null) =>
  (dispatch, getState) => {
    let params = {
      sort,
      page: page,
      limit: utils.PAGINATION_LIMIT,
    }

    params = Object.assign(params, Tool.Utils.linkObjectKey(filter))

    return dispatch({
      types: [
        types.API_REQUEST_SEND,
        types.DOCUMENT_SEND_EXPORT,
        types.API_REQUEST_ERROR,
      ],
      page: page,
      payload: {
        client: 'download',
        request: {
          url: '/api/socongvan/congvandi/in',
          method: 'GET',
          headers: {
            Authorization:
              getState().auth.token.tokenType +
              ' ' +
              getState().auth.token.accessToken,
          },
          params: _.pickBy(params),
        },
      },
    })
  }

export const utilityPrintReceiveDocumentGet =
  (filter = {}, page = 1, sort = null) =>
  (dispatch, getState) => {
    let params = {
      sort,
      page: page,
      limit: utils.PAGINATION_LIMIT,
    }

    params = Object.assign(params, Tool.Utils.linkObjectKey(filter))

    return dispatch({
      types: [
        types.API_REQUEST_INIT_DOCUMENT_RECEIVE_SEND,
        types.DOCUMENT_RECEIVE,
        types.API_REQUEST_ERROR,
      ],
      schema: ReceiveDocumentSchema,
      page: page,
      payload: {
        request: {
          url: '/api/socongvan/congvanden',
          method: 'GET',
          headers: {
            Authorization:
              getState().auth.token.tokenType +
              ' ' +
              getState().auth.token.accessToken,
          },
          params: _.pickBy(params),
        },
      },
    })
  }

export const utilityPrintExportReceiveDocumentGet =
  (filter = {}, page = 1, sort = null) =>
  (dispatch, getState) => {
    let params = {
      sort,
      page: page,
      limit: utils.PAGINATION_LIMIT,
    }

    params = Object.assign(params, Tool.Utils.linkObjectKey(filter))

    return dispatch({
      types: [
        types.API_REQUEST_SEND,
        types.DOCUMENT_RECEIVE_EXPORT,
        types.API_REQUEST_ERROR,
      ],
      page: page,
      payload: {
        client: 'download',
        request: {
          url: '/api/socongvan/congvanden/in',
          method: 'GET',
          headers: {
            Authorization:
              getState().auth.token.tokenType +
              ' ' +
              getState().auth.token.accessToken,
          },
          params: _.pickBy(params),
        },
      },
    })
  }

export const signNumberSendAgreeBySIM =
  (id, type = 0) =>
  (dispatch, getState) =>
    dispatch({
      types: [
        types.API_REQUEST_SEND,
        types.SIGN_NUMBER_SEND_AGREE_BY_SIM,
        types.API_REQUEST_ERROR,
      ],
      payload: {
        request: {
          url: `/api/kysodi/${id}/kysim?loai=${type}`,
          method: 'PATCH',
          headers: {
            Authorization:
              getState().auth.token.tokenType +
              ' ' +
              getState().auth.token.accessToken,
          },
        },
      },
    })

export const updateStaffItem = data => (dispatch, getState) =>
  dispatch({
    types: [
      types.API_REQUEST_SEND,
      types.UPDATE_STAFF_ITEM,
      types.API_REQUEST_ERROR,
    ],
    payload: {
      request: {
        url: `/api/nhanvien`,
        method: 'PUT',
        headers: {
          Authorization:
            getState().auth.token.tokenType +
            ' ' +
            getState().auth.token.accessToken,
        },
        data,
      },
    },
  })

export const signNumberInternalAgreeBySIM =
  (id, type) => (dispatch, getState) =>
    dispatch({
      types: [
        types.API_REQUEST_SEND,
        types.SIGN_NUMBER_INTERNAL_AGREE_BY_SIM,
        types.API_REQUEST_ERROR,
      ],
      payload: {
        request: {
          url: `/api/kysonoibo/${id}/kysim?loai=${type}`,
          method: 'PATCH',
          headers: {
            Authorization:
              getState().auth.token.tokenType +
              ' ' +
              getState().auth.token.accessToken,
          },
        },
      },
    })

export const getListContact =
  (id, filter = {}, sorter = null) =>
  (dispatch, getState) => {
    let params = {
      sorter,
    }

    params = Object.assign(params, Tool.Utils.linkObjectKey(filter))

    return dispatch({
      types: [
        types.API_REQUEST_SEND,
        types.GET_LIST_CONTACT,
        types.API_REQUEST_ERROR,
      ],
      payload: {
        request: {
          url: `/api/danhba/phongban/${id}/nhanvien`,
          method: 'GET',
          headers: {
            Authorization:
              getState().auth.token.tokenType +
              ' ' +
              getState().auth.token.accessToken,
          },
          params,
        },
      },
    })
  }

export const getStatisticCabinetDocument =
  (filter = {}, refresh) =>
  (dispatch, getState) => {
    let params = {
      refresh,
    }
    params = Object.assign(params, Tool.Utils.linkObjectKey(filter))

    return dispatch({
      types: [
        types.API_REQUEST_INIT_DOCUMENT_RECEIVE_SEND,
        types.GET_STATISTIC_CABINET_DOCUMENT,
        types.API_REQUEST_ERROR,
      ],
      payload: {
        request: {
          url: `/api/thongke/tutailieu`,
          method: 'GET',
          headers: {
            'api-version': 2.0,
            Authorization:
              getState().auth.token.tokenType +
              ' ' +
              getState().auth.token.accessToken,
          },
          params,
        },
      },
    })
  }

export const exportStatisticCabinetDocument =
  (filter = {}, page = 1, sort = null) =>
  (dispatch, getState) => {
    let params = {
      // sort,
      // page: page,
      // limit: utils.PAGINATION_LIMIT
    }

    params = Object.assign(params, Tool.Utils.linkObjectKey(filter))

    return dispatch({
      types: [
        types.API_REQUEST_SEND,
        types.EXPORT_STATISTIC_CABINET_DOCUMENT,
        types.API_REQUEST_ERROR,
      ],
      page: page,
      payload: {
        client: 'download',
        request: {
          url: `/api/thongke/tutailieu/in`,
          method: 'GET',
          headers: {
            'api-version': 2.0,
            Authorization:
              getState().auth.token.tokenType +
              ' ' +
              getState().auth.token.accessToken,
          },
          params: _.pickBy(params),
        },
      },
    })
  }

export const getGeneralWork =
  (filter = {}, refresh) =>
  (dispatch, getState) => {
    let params = {
      refresh,
    }
    params = Object.assign(params, Tool.Utils.linkObjectKey(filter))

    return dispatch({
      types: [
        types.API_REQUEST_INIT_DOCUMENT_RECEIVE_SEND,
        types.GET_GENERAL_WORK,
        types.API_REQUEST_ERROR,
      ],
      payload: {
        request: {
          url: `/api/tonghop/congviec`,
          method: 'GET',
          headers: {
            Authorization:
              getState().auth.token.tokenType +
              ' ' +
              getState().auth.token.accessToken,
          },
          params,
        },
      },
    })
  }

export const exportGeneralWork =
  (filter = {}, page = 1, sort = null) =>
  (dispatch, getState) => {
    let params = {
      sort,
      page: page,
      limit: utils.PAGINATION_LIMIT,
    }

    params = Object.assign(params, Tool.Utils.linkObjectKey(filter))

    return dispatch({
      types: [
        types.API_REQUEST_SEND,
        types.EXPORT_GENERAL_WORK,
        types.API_REQUEST_ERROR,
      ],
      page: page,
      payload: {
        client: 'download',
        request: {
          url: `/api/tonghop/congviec/in`,
          method: 'GET',
          headers: {
            Authorization:
              getState().auth.token.tokenType +
              ' ' +
              getState().auth.token.accessToken,
          },
          params: _.pickBy(params),
        },
      },
    })
  }

export const getHandleDocument =
  (filter = {}, page = 1, sort = null, refresh) =>
  (dispatch, getState) => {
    let params = {
      sort,
      page: page,
      limit: 0,
      refresh,
    }
    params = Object.assign(params, Tool.Utils.linkObjectKey(filter))

    return dispatch({
      types: [
        types.API_REQUEST_INIT_DOCUMENT_RECEIVE_SEND,
        types.GET_HANDLE_DOCUMENT,
        types.API_REQUEST_ERROR,
      ],
      payload: {
        request: {
          url: `/api/tonghop/congvan`,
          method: 'GET',
          headers: {
            Authorization:
              getState().auth.token.tokenType +
              ' ' +
              getState().auth.token.accessToken,
          },
          params,
        },
      },
    })
  }

export const exportHandleDocument =
  (filter = {}, page = 1, sort = null) =>
  (dispatch, getState) => {
    let params = {
      sort,
      page: page,
      limit: utils.PAGINATION_LIMIT,
    }

    params = Object.assign(params, Tool.Utils.linkObjectKey(filter))

    return dispatch({
      types: [
        types.API_REQUEST_SEND,
        types.EXPORT_HANDLE_DOCUMENT,
        types.API_REQUEST_ERROR,
      ],
      page: page,
      payload: {
        client: 'download',
        request: {
          url: `/api/tonghop/congvan/in`,
          method: 'GET',
          headers: {
            Authorization:
              getState().auth.token.tokenType +
              ' ' +
              getState().auth.token.accessToken,
          },
          params: _.pickBy(params),
        },
      },
    })
  }

export const postReSend = (id, donViId) => (dispatch, getState) =>
  dispatch({
    types: [
      types.API_REQUEST_SEND,
      types.POST_RE_SEND,
      types.API_REQUEST_ERROR,
    ],
    payload: {
      request: {
        url: `/api/congvandi/${id}/guilai`,
        method: 'PATCH',
        headers: {
          Authorization:
            getState().auth.token.tokenType +
            ' ' +
            getState().auth.token.accessToken,
        },
        params: {
          id: id,
          don_vi_id: donViId,
        },
      },
    },
  })

export const printUtilityPrintReceiveDocumentGet =
  (filter = {}, page = 1, sort = null, limit = utils.PAGINATION_LIMIT) =>
  (dispatch, getState) => {
    let params = {
      sort,
      page: page,
      limit,
    }

    params = Object.assign(params, Tool.Utils.linkObjectKey(filter))

    return dispatch({
      types: [
        types.API_REQUEST_SEND,
        types.PRINT_DOCUMENT_RECEIVE,
        types.API_REQUEST_ERROR,
      ],
      payload: {
        request: {
          url: '/api/socongvan/congvanden',
          method: 'GET',
          headers: {
            Authorization:
              getState().auth.token.tokenType +
              ' ' +
              getState().auth.token.accessToken,
          },
          params,
        },
      },
    })
  }

export const printUtilityPrintSendDocumentGet =
  (filter = {}, page = 1, sort = null, limit = utils.PAGINATION_LIMIT) =>
  (dispatch, getState) => {
    let params = {
      sort,
      page: page,
      limit,
    }

    params = Object.assign(params, Tool.Utils.linkObjectKey(filter))

    return dispatch({
      types: [
        types.API_REQUEST_SEND,
        types.PRINT_DOCUMENT_SEND,
        types.API_REQUEST_ERROR,
      ],
      payload: {
        request: {
          url: '/api/socongvan/congvandi',
          method: 'GET',
          headers: {
            Authorization:
              getState().auth.token.tokenType +
              ' ' +
              getState().auth.token.accessToken,
          },
          params,
        },
      },
    })
  }

export const signNumberSendFetchHistory =
  (id, vanBanGocId) => (dispatch, getState) =>
    dispatch({
      types: [
        types.API_REQUEST_SEND,
        types.SIGN_NUMBER_SEND_FETCH_HISTORY,
        types.API_REQUEST_ERROR,
      ],
      payload: {
        request: {
          url: `/api/kysodi/${id}/lichsu`,
          method: 'GET',
          headers: {
            Authorization:
              getState().auth.token.tokenType +
              ' ' +
              getState().auth.token.accessToken,
          },
          params: {
            vanBanGocId: vanBanGocId,
          },
        },
      },
    })

export const signNumberInternalFetchHistory =
  (id, vanBanGocId) => (dispatch, getState) =>
    dispatch({
      types: [
        types.API_REQUEST_SEND,
        types.SIGN_NUMBER_INTERNAL_FETCH_HISTORY,
        types.API_REQUEST_ERROR,
      ],
      payload: {
        request: {
          url: `/api/kysonoibo/${id}/lichsu`,
          method: 'GET',
          headers: {
            Authorization:
              getState().auth.token.tokenType +
              ' ' +
              getState().auth.token.accessToken,
          },
          params: {
            vanBanGocId: vanBanGocId,
          },
        },
      },
    })

export const getRole = () => (dispatch, getState) =>
  dispatch({
    types: [types.API_REQUEST_SEND, types.GET_ROLE, types.API_REQUEST_ERROR],
    payload: {
      request: {
        url: `/api/quyen`,
        method: 'GET',
        headers: {
          Authorization:
            getState().auth.token.tokenType +
            ' ' +
            getState().auth.token.accessToken,
        },
      },
    },
  })

export const updateArrayCommonFetchStaff = data => (dispatch, getState) =>
  dispatch({
    types: [
      types.API_REQUEST_SEND,
      types.UPDATE_DEPARTMENT_PERMISSION,
      types.API_REQUEST_ERROR,
    ],
    payload: {
      request: {
        url: `/api/nhanvienphongban/sort`,
        method: 'PUT',
        headers: {
          Authorization:
            getState().auth.token.tokenType +
            ' ' +
            getState().auth.token.accessToken,
        },
        data: data,
      },
    },
  })

export const getCategoryDepartmentChild =
  (id, isAll = false) =>
  (dispatch, getState) =>
    dispatch({
      types: [
        types.API_REQUEST_SEND,
        types.CATEGORY_FETCH_DEPARTMENT_CHILD,
        types.API_REQUEST_ERROR,
      ],
      payload: {
        request: {
          url: `/api/danhmuc/phongban/${id}/phongbancon`,
          method: 'GET',
          headers: {
            Authorization:
              getState().auth.token.tokenType +
              ' ' +
              getState().auth.token.accessToken,
          },
          params: {
            isAll,
          },
        },
      },
    })

export const getCategoryStaffSubject = id => (dispatch, getState) =>
  dispatch({
    types: [
      types.API_REQUEST_SEND,
      types.CATEGORY_STAFF_SUBJECT,
      types.API_REQUEST_ERROR,
    ],
    payload: {
      request: {
        url: `/api/phongban/${id}/nhanviendoituong`,
        method: 'GET',
        headers: {
          Authorization:
            getState().auth.token.tokenType +
            ' ' +
            getState().auth.token.accessToken,
        },
      },
    },
  })

export const getCategoryUnit =
  (isAll = false) =>
  (dispatch, getState) =>
    dispatch({
      types: [
        types.API_REQUEST_SEND,
        types.CATEGORY_UNIT,
        types.API_REQUEST_ERROR,
      ],
      payload: {
        request: {
          url: `/api/danhmuc/donvi`,
          method: 'GET',
          headers: {
            Authorization:
              getState().auth.token.tokenType +
              ' ' +
              getState().auth.token.accessToken,
          },
          params: {
            limit: 0,
            isAll,
          },
        },
      },
    })

export const getCategoryUnitChild =
  (id, isAll = false) =>
  (dispatch, getState) =>
    dispatch({
      types: [
        types.API_REQUEST_SEND,
        types.CATEGORY_UNIT_CHILD,
        types.API_REQUEST_ERROR,
      ],
      payload: {
        request: {
          url: `/api/danhmuc/donvi/${id}/donvicon`,
          method: 'GET',
          headers: {
            Authorization:
              getState().auth.token.tokenType +
              ' ' +
              getState().auth.token.accessToken,
          },
          params: {
            limit: 0,
            isAll,
          },
        },
      },
    })

export const getListCabinetOfDocument =
  (id, type = 1) =>
  (dispatch, getState) =>
    dispatch({
      types: [
        types.COMMON_FETCH_CABINET_OF_DOCUMENT_CLEAR,
        types.COMMON_FETCH_CABINET_OF_DOCUMENT,
        types.API_REQUEST_ERROR,
      ],
      payload: {
        request: {
          url: `/api/tutailieu/danhsach`,
          method: 'GET',
          headers: {
            Authorization:
              getState().auth.token.tokenType +
              ' ' +
              getState().auth.token.accessToken,
          },
          params: {
            loai_cong_van: type,
            cong_van_id: id,
          },
        },
      },
    })

export const getCategoryDepartmentMultiLevel =
  (donViId = null, isAll = true) =>
  (dispatch, getState) =>
    dispatch({
      types: [
        types.API_REQUEST_SEND,
        types.CATEGORY_FETCH_DEPARTMENT_MULTILEVEL,
        types.API_REQUEST_ERROR,
      ],
      payload: {
        request: {
          url: `/api/phongban/multilevel`,
          method: 'GET',
          headers: {
            Authorization:
              getState().auth.token.tokenType +
              ' ' +
              getState().auth.token.accessToken,
          },
          params: {
            don_vi_id: donViId,
            is_all: isAll,
          },
        },
      },
    })

export const getListFileInfoOfDocuments = ids => (dispatch, getState) =>
  dispatch({
    types: [
      types.API_REQUEST_SEND,
      types.DOCUMENT_RECEIVE_FILE_INFO,
      types.API_REQUEST_ERROR,
    ],
    schema: FileArraySchema,
    payload: {
      request: {
        url: `/api/tutailieu/dsfile`,
        method: 'GET',
        headers: {
          Authorization:
            getState().auth.token.tokenType +
            ' ' +
            getState().auth.token.accessToken,
        },
        params: {
          file_id: ids,
        },
      },
    },
  })

export const getOtherUnit =
  (donViId = null) =>
  (dispatch, getState) =>
    dispatch({
      types: [
        types.API_REQUEST_SEND,
        types.CATEGORY_FETCH_UNIT_MULTILEVEL,
        types.API_REQUEST_ERROR,
      ],
      payload: {
        request: {
          url: `/api/donvi/${donViId}/donvikhac`,
          method: 'GET',
          headers: {
            Authorization:
              getState().auth.token.tokenType +
              ' ' +
              getState().auth.token.accessToken,
          },
        },
      },
    })

export const getConnectedUnit =
  (donViId = null) =>
  (dispatch, getState) =>
    dispatch({
      types: [
        types.API_REQUEST_SEND,
        types.CATEGORY_FETCH_CONNECTED_UNIT,
        types.API_REQUEST_ERROR,
      ],
      payload: {
        // client: 'connectedUnit',
        request: {
          url: `/api/donvi/${donViId}/donvilienthong`,
          method: 'GET',
          headers: {
            'api-version': 1.0,
            Authorization:
              getState().auth.token.tokenType +
              ' ' +
              getState().auth.token.accessToken,
          },
        },
      },
    })

export const getConnectedUnitEVN =
  (donViId = null) =>
  (dispatch, getState) =>
    dispatch({
      types: [
        types.API_REQUEST_SEND,
        types.CATEGORY_FETCH_CONNECTED_UNIT_EVN,
        types.API_REQUEST_ERROR,
      ],
      payload: {
        // client: 'connectedUnit',
        request: {
          url: `/api/donvi/${donViId}/donvilienthongevn`,
          method: 'GET',
          headers: {
            'api-version': 1.0,
            Authorization:
              getState().auth.token.tokenType +
              ' ' +
              getState().auth.token.accessToken,
          },
        },
      },
    })

export const getCategoryUnitMultiLevel =
  (donViId = null) =>
  (dispatch, getState) =>
    dispatch({
      types: [
        types.API_REQUEST_SEND,
        types.CATEGORY_FETCH_UNIT_MULTILEVEL,
        types.API_REQUEST_ERROR,
      ],
      payload: {
        request: {
          url: `/api/donvi/multilevel`,
          method: 'GET',
          headers: {
            Authorization:
              getState().auth.token.tokenType +
              ' ' +
              getState().auth.token.accessToken,
          },
          params: {
            don_vi_id: donViId,
          },
        },
      },
    })

export const getSeeToKnowWork =
  (filter = {}, page = 1, sort, filterColumn = {}) =>
  (dispatch, getState) => {
    let params = {
      sort,
      page: page,
      limit: utils.PAGINATION_LIMIT,
    }

    params = Object.assign(params, Tool.Utils.linkObjectKey(filter))
    params = Object.assign(
      params,
      Tool.Utils.linkObjectKey({ filter_column: filterColumn }, params)
    )

    return dispatch({
      types: [
        types.API_REQUEST_SEND,
        types.WORK_SEE_TO_KNOW,
        types.API_REQUEST_ERROR,
      ],
      payload: {
        request: {
          url: '/api/xemdebiet',
          method: 'GET',
          headers: {
            'api-version': 2.0,
            Authorization:
              getState().auth.token.tokenType +
              ' ' +
              getState().auth.token.accessToken,
          },
          params,
        },
      },
    })
  }

export const updateSeeToKnowWork = id => (dispatch, getState) =>
  dispatch({
    types: [
      types.API_REQUEST_SEND,
      types.WORK_UPDATE_SEE_TO_KNOW,
      types.API_REQUEST_ERROR,
    ],
    payload: {
      request: {
        url: `/api/xemdebiet/${id}/updatetrangthai`,
        method: 'PUT',
        headers: {
          Authorization:
            getState().auth.token.tokenType +
            ' ' +
            getState().auth.token.accessToken,
        },
      },
    },
  })

export const updateStatusReport = id => (dispatch, getState) =>
  dispatch({
    types: [
      types.API_REQUEST_SEND,
      types.WORK_UPDATE_SEE_TO_KNOW,
      types.API_REQUEST_ERROR,
    ],
    payload: {
      request: {
        url: `/api/xembaocao/${id}/updatetrangthai`,
        method: 'PUT',
        headers: {
          Authorization:
            getState().auth.token.tokenType +
            ' ' +
            getState().auth.token.accessToken,
        },
      },
    },
  })

export const getItemReceiveDocument = id => (dispatch, getState) =>
  dispatch({
    types: [
      types.API_REQUEST_SEND,
      types.GET_RECEIVE_DOCUMENT_ITEM,
      types.API_REQUEST_ERROR,
    ],
    payload: {
      request: {
        url: `/api/congvanden/${id}/rutgon`,
        method: 'GET',
        headers: {
          Authorization:
            getState().auth.token.tokenType +
            ' ' +
            getState().auth.token.accessToken,
        },
      },
    },
  })

// export const getItemPersonalWork = (id) => (dispatch, getState) => dispatch({
//   types: [ types.API_REQUEST_SEND, types.GET_PERSONAL_WORK_ITEM, types.API_REQUEST_ERROR ],
//   payload: {
//     request: {
//       url: `/api/congviec/${id}/canhan`,
//       method: 'GET',
//       headers: {
//         'api-version': 2.0,
//         'Authorization': getState().auth.token.tokenType + ' ' + getState().auth.token.accessToken
//       }
//     }
//   }
// });

export const getTotalSeeToKnow = () => (dispatch, getState) =>
  dispatch({
    types: [
      types.API_REQUEST_SEND,
      types.TOTAL_SEE_TO_KNOW,
      types.API_REQUEST_ERROR,
    ],
    payload: {
      request: {
        url: `/api/xemdebiet/totalchuadoc`,
        method: 'GET',
        headers: {
          Authorization:
            getState().auth.token.tokenType +
            ' ' +
            getState().auth.token.accessToken,
        },
      },
    },
  })

export const getListPersonalAssignment = () => (dispatch, getState) =>
  dispatch({
    types: [
      types.API_REQUEST_SEND,
      types.GET_LIST_PERSONAL_ASSIGNMENT,
      types.API_REQUEST_ERROR,
    ],
    payload: {
      request: {
        url: `/api/nhanvien/lanhdao`,
        method: 'GET',
        headers: {
          Authorization:
            getState().auth.token.tokenType +
            ' ' +
            getState().auth.token.accessToken,
        },
      },
    },
  })

export const getListFollowDirection =
  (filter = {}, page = 1, sort, filterColumn = {}) =>
  (dispatch, getState) => {
    let params = {
      sort,
      'filter.page': page,
      'filter.limit': utils.PAGINATION_LIMIT,
    }

    params = Object.assign(params, Tool.Utils.linkObjectKey(filter))
    params = Object.assign(
      params,
      Tool.Utils.linkObjectKey({ filter_column: filterColumn }, params)
    )

    return dispatch({
      types: [
        types.API_REQUEST_SEND,
        types.GET_PERSONAL_FOLLOW_DIRECTIONS,
        types.API_REQUEST_ERROR,
      ],
      payload: {
        request: {
          url: '/api/xembaocao/chidao',
          method: 'GET',
          headers: {
            'api-version': 1.0,
            Authorization:
              getState().auth.token.tokenType +
              ' ' +
              getState().auth.token.accessToken,
          },
          params,
        },
      },
    })
  }

export const getViewReport =
  (filter = {}, page = 1, sort, filterColumn = {}) =>
  (dispatch, getState) => {
    let params = {
      sort,
      'filter.page': page,
      'filter.limit': utils.PAGINATION_LIMIT,
    }

    params = Object.assign(params, Tool.Utils.linkObjectKey(filter))
    params = Object.assign(
      params,
      Tool.Utils.linkObjectKey({ filter_column: filterColumn }, params)
    )

    return dispatch({
      types: [
        types.API_REQUEST_SEND,
        types.GET_VIEW_REPORT,
        types.API_REQUEST_ERROR,
      ],
      payload: {
        request: {
          url: '/api/xembaocao/baocao',
          method: 'GET',
          headers: {
            'api-version': 1.0,
            Authorization:
              getState().auth.token.tokenType +
              ' ' +
              getState().auth.token.accessToken,
          },
          params,
        },
      },
    })
  }

export const printUtilityReceiveDocumentGet =
  (
    filter = {},
    page = 1,
    sort = null,
    limit = utils.PAGINATION_LIMIT,
    filterColumn
  ) =>
  (dispatch, getState) => {
    let params = {
      sort,
      page: page,
      limit,
    }

    params = Object.assign(params, Tool.Utils.linkObjectKey(filter))
    params = Object.assign(
      params,
      Tool.Utils.linkObjectKey({ filter_column: filterColumn }, params)
    )

    return dispatch({
      types: [
        types.API_REQUEST_SEND,
        types.PRINT_SEARCH_DOCUMENT_RECEIVE,
        types.API_REQUEST_ERROR,
      ],
      payload: {
        request: {
          url: '/api/tienich/congvan',
          method: 'GET',
          headers: {
            'api-version': 2.0,
            Authorization:
              getState().auth.token.tokenType +
              ' ' +
              getState().auth.token.accessToken,
          },
          params,
        },
      },
    })
  }

export const switchRoleUser = data => ({
  type: types.AUTH_SWITCH_ROLE,
  data: data,
})

export const digitalDocumentsFetchItem = id => (dispatch, getState) =>
  dispatch({
    types: [
      types.API_REQUEST_SEND,
      types.DIGITAL_DOCUMENTS_FETCH_ITEM,
      types.API_REQUEST_ERROR,
    ],
    payload: {
      client: 'binaryFile',
      request: {
        url: `/api/vanbansohoa/${id}/file`,
        method: 'GET',
        headers: {
          Authorization:
            getState().auth.token.tokenType +
            ' ' +
            getState().auth.token.accessToken,
        },
      },
    },
  })

export const workAuthorityCreate = data => (dispatch, getState) =>
  dispatch({
    types: [
      types.API_REQUEST_SEND,
      types.WORK_AUTHORITY_CREATE,
      types.API_REQUEST_ERROR,
    ],
    payload: {
      request: {
        url: '/api/congviec/uyquyen',
        method: 'POST',
        headers: {
          Authorization:
            getState().auth.token.tokenType +
            ' ' +
            getState().auth.token.accessToken,
        },
        data,
      },
    },
  })

export const workAuthorityUpdate = data => (dispatch, getState) =>
  dispatch({
    types: [
      types.API_REQUEST_SEND,
      types.WORK_AUTHORITY_UPDATE,
      types.API_REQUEST_ERROR,
    ],
    payload: {
      request: {
        url: '/api/congviec/uyquyen',
        method: 'PUT',
        headers: {
          Authorization:
            getState().auth.token.tokenType +
            ' ' +
            getState().auth.token.accessToken,
        },
        data,
      },
    },
  })

export const workAuthorityFetch = () => (dispatch, getState) =>
  dispatch({
    types: [
      types.API_REQUEST_SEND,
      types.WORK_AUTHORITY_FETCH,
      types.API_REQUEST_ERROR,
    ],
    payload: {
      request: {
        url: '/api/congviec/uyquyen',
        method: 'GET',
        headers: {
          Authorization:
            getState().auth.token.tokenType +
            ' ' +
            getState().auth.token.accessToken,
        },
      },
    },
  })

export const workAuthorityDelete = () => (dispatch, getState) =>
  dispatch({
    types: [
      types.API_REQUEST_SEND,
      types.WORK_AUTHORITY_DELETE,
      types.API_REQUEST_ERROR,
    ],
    payload: {
      request: {
        url: '/api/congviec/uyquyen',
        method: 'DELETE',
        headers: {
          Authorization:
            getState().auth.token.tokenType +
            ' ' +
            getState().auth.token.accessToken,
        },
      },
    },
  })

export const resetSignNumberToken = userId => (dispatch, getState) =>
  dispatch({
    types: [
      types.API_REQUEST_SEND,
      types.API_RESET_SIGNNUMBER_TOKEN,
      types.API_REQUEST_ERROR,
    ],
    payload: {
      request: {
        url: `/api/nhanvien/${userId}/resettoken`,
        method: 'PATCH',
        headers: {
          Authorization:
            getState().auth.token.tokenType +
            ' ' +
            getState().auth.token.accessToken,
        },
      },
    },
  })

export const getAlertMistakes =
  (filter = {}, loai, page = 1, sort = null, refresh = false) =>
  (dispatch, getState) => {
    let params = {
      refresh,
      sorter: sort,
      page: page,
      loai,
      limit: utils.PAGINATION_LIMIT,
    }

    if (filter.textSearch !== '') {
      params = Object.assign(params, Tool.Utils.linkObjectKey(filter))
    }

    return dispatch({
      types: [
        types.API_REQUEST_SEND,
        types.ALERT_MISTAKE_GET_LIST,
        types.API_REQUEST_ERROR,
      ],
      schema: AlertMistakeArraySchema,
      payload: {
        request: {
          url: `/api/baonham`,
          method: 'GET',
          headers: {
            'api-version': 3.0,
            Authorization:
              getState().auth.token.tokenType +
              ' ' +
              getState().auth.token.accessToken,
          },
          params,
        },
      },
    })
  }

export const postAlertMistake = data => (dispatch, getState) =>
  dispatch({
    types: [
      types.API_REQUEST_SEND,
      types.API_REQUEST_SUCCESS,
      types.API_REQUEST_ERROR,
    ],
    payload: {
      request: {
        url: `/api/baonham`,
        method: 'POST',
        headers: {
          Authorization:
            getState().auth.token.tokenType +
            ' ' +
            getState().auth.token.accessToken,
        },
        data,
      },
    },
  })

export const getFlowDocumentaryReceiveDocument = id => (dispatch, getState) =>
  dispatch({
    types: [
      types.API_REQUEST_SEND,
      types.FLOWDOCUMENTARY_RECEIVEDOCUMENT,
      types.API_REQUEST_ERROR,
    ],
    schema: FlowdocumentReceivedocumentSchema,
    payload: {
      request: {
        url: `/api/congvanden/${id}/luongvanban`,
        method: 'GET',
        headers: {
          'api-version': 2.0,
          Authorization:
            getState().auth.token.tokenType +
            ' ' +
            getState().auth.token.accessToken,
        },
        params: {
          is_full: true,
        },
      },
    },
  })

export const getFlowDocumentaryReceiveDocumentReset = status => ({
  type: types.FLOWDOCUMENTARY_RECEIVEDOCUMENT_RESET,
  status,
})

export const getMoreFlowDocumentaryReceiveDocument =
  id => (dispatch, getState) =>
    dispatch({
      types: [
        types.API_REQUEST_SEND,
        types.FLOWDOCUMENTARY_MORE_RECEIVEDOCUMENT,
        types.API_REQUEST_ERROR,
      ],
      schema: FlowdocumentReceivedocumentSchema,
      payload: {
        request: {
          url: `/api/congvanden/${id}/luongvanban`,
          method: 'GET',
          headers: {
            'api-version': 2.0,
            Authorization:
              getState().auth.token.tokenType +
              ' ' +
              getState().auth.token.accessToken,
          },
        },
      },
    })

export const getMoreFlowDocumentaryReceiveDocumentReset = status => ({
  type: types.FLOWDOCUMENTARY_MORE_RECEIVEDOCUMENT_RESET,
  status,
})

export const getFlowDocumentarySendDocument = id => (dispatch, getState) =>
  dispatch({
    types: [
      types.API_REQUEST_SEND,
      types.FLOWDOCUMENTART_SENDDOCUMENT,
      types.API_REQUEST_ERROR,
    ],
    payload: {
      request: {
        url: `/api/congvandi/${id}/luongvanban`,
        method: 'GET',
        headers: {
          Authorization:
            getState().auth.token.tokenType +
            ' ' +
            getState().auth.token.accessToken,
        },
      },
    },
  })

export const evnDocumentsFetchList =
  (page = 1, limit = 0, status = null, textSearch = '') =>
  (dispatch, getState) =>
    dispatch({
      types: [
        types.API_REQUEST_SEND,
        types.DOCUMENTS_EVN_FETCH_LIST,
        types.API_REQUEST_ERROR,
      ],
      schema: EvnDocumentArraySchema,
      payload: {
        request: {
          url: '/api/vanbanevn',
          method: 'GET',
          headers: {
            Authorization:
              getState().auth.token.tokenType +
              ' ' +
              getState().auth.token.accessToken,
          },
          params: {
            page,
            limit,
            status,
            text_search: textSearch,
          },
        },
      },
    })

export const evnDocumentsFetchListNoSchema =
  (page = 1, limit = 0, status = null, textSearch = '') =>
  (dispatch, getState) =>
    dispatch({
      types: [
        types.API_REQUEST_SEND,
        types.DOCUMENTS_EVN_FETCH_LIST_NO_SCHEMA,
        types.API_REQUEST_ERROR,
      ],
      payload: {
        request: {
          url: '/api/vanbanevn',
          method: 'GET',
          headers: {
            Authorization:
              getState().auth.token.tokenType +
              ' ' +
              getState().auth.token.accessToken,
          },
          params: {
            page,
            limit,
            status,
            text_search: textSearch,
          },
        },
      },
    })

export const evnDocumentsFetchFileItem = (id, fileId) => (dispatch, getState) =>
  dispatch({
    types: [
      types.API_REQUEST_SEND,
      types.DOCUMENTS_EVN_FETCH_FILE_ITEM,
      types.API_REQUEST_ERROR,
    ],
    payload: {
      client: 'binaryFile',
      request: {
        url: `/api/vanbanevn/${id}/file/${fileId}`,
        method: 'GET',
        headers: {
          Authorization:
            getState().auth.token.tokenType +
            ' ' +
            getState().auth.token.accessToken,
        },
      },
    },
  })

export const evnDocumentsFetchFileList = id => (dispatch, getState) =>
  dispatch({
    types: [
      types.API_REQUEST_SEND,
      types.DOCUMENTS_EVN_FETCH_FILE_LIST,
      types.API_REQUEST_ERROR,
    ],
    payload: {
      request: {
        url: `/api/vanbanevn/${id}/files`,
        method: 'GET',
        headers: {
          Authorization:
            getState().auth.token.tokenType +
            ' ' +
            getState().auth.token.accessToken,
        },
      },
    },
  })

export const commonSaveTypeSidebar = data => ({
  type: types.COMMON_SAVE_TYPE_SIDEBAR,
  data: data,
})

export const commonFetchAlertMistakeTotal =
  typeAlertMistake => (dispatch, getState) =>
    dispatch({
      types: [
        types.API_REQUEST_SEND,
        types.COMMON_FETCH_ALERT_MISTAKE_TOTAL,
        types.API_REQUEST_ERROR,
      ],
      payload: {
        request: {
          url: `/api/baonham/total`,
          method: 'GET',
          headers: {
            Authorization:
              getState().auth.token.tokenType +
              ' ' +
              getState().auth.token.accessToken,
          },
          params: {
            loai: typeAlertMistake,
          },
        },
      },
      filter: {
        typeAlertMistake,
      },
    })

export const notificationMarkAsReaded =
  ({ thongBaoChungId, isTinTongCongTy }) =>
  (dispatch, getState) =>
    dispatch({
      types: [
        types.API_REQUEST_SEND,
        types.NOTIFICATION_POST_READED,
        types.API_REQUEST_ERROR,
      ],
      payload: {
        request: {
          url: `/api/thongbaochung/moinhat`,
          method: 'post',
          headers: {
            Authorization:
              getState().auth.token.tokenType +
              ' ' +
              getState().auth.token.accessToken,
          },
          params: {
            thongBaoChungId,
            isTinTongCongTy,
          },
        },
      },
    })

export const documentFetchMigratedFile =
  (url, fileName) => (dispatch, getState) =>
    dispatch({
      types: [
        types.API_REQUEST_SEND,
        types.DOCUMENTS_FETCH_MIGRATED_FILE,
        types.API_REQUEST_ERROR,
      ],
      payload: {
        client: 'binaryFile',
        request: {
          url: `${url}/api/oldfile/downloadfile`,
          method: 'GET',
          headers: {
            Authorization:
              getState().auth.token.tokenType +
              ' ' +
              getState().auth.token.accessToken,
          },
          params: {
            filename: fileName,
          },
        },
      },
    })

export const getGerenalDocumentOverTime =
  (filter = {}, page = 1, sort = null) =>
  (dispatch, getState) => {
    let params = {
      sort,
      page: page,
      limit: utils.PAGINATION_LIMIT,
    }

    params = Object.assign(params, Tool.Utils.linkObjectKey(filter))

    return dispatch({
      types: [
        types.API_REQUEST_SEND,
        types.GET_GERENAL_DOCUMENT_OVER_TIME,
        types.API_REQUEST_ERROR,
      ],
      schema: DepartmentWorkSchema,
      payload: {
        request: {
          url: `/api/tonghop/congviec/quahan`,
          method: 'GET',
          headers: {
            Authorization:
              getState().auth.token.tokenType +
              ' ' +
              getState().auth.token.accessToken,
          },
          params,
        },
      },
    })
  }

export const commonReadOnlyDatePicker = data => ({
  type: types.READONLY_ENABLE,
  data: data,
})

export const commonScrollFixtopMenu = data => ({
  type: types.SCROLL_FIXTOP_MENU,
  data: data,
})

export const documentAuthorityCreate = data => (dispatch, getState) =>
  dispatch({
    types: [
      types.API_REQUEST_SEND,
      types.DOCUMENT_AUTHORITY_CREATE,
      types.API_REQUEST_ERROR,
    ],
    payload: {
      request: {
        url: '/api/congvan/uyquyen',
        method: 'POST',
        headers: {
          Authorization:
            getState().auth.token.tokenType +
            ' ' +
            getState().auth.token.accessToken,
        },
        data,
      },
    },
  })

export const documentAuthorityUpdate = data => (dispatch, getState) =>
  dispatch({
    types: [
      types.API_REQUEST_SEND,
      types.DOCUMENT_AUTHORITY_UPDATE,
      types.API_REQUEST_ERROR,
    ],
    payload: {
      request: {
        url: '/api/congvan/uyquyen',
        method: 'PUT',
        headers: {
          Authorization:
            getState().auth.token.tokenType +
            ' ' +
            getState().auth.token.accessToken,
        },
        data,
      },
    },
  })

export const documentAuthorityFetch = () => (dispatch, getState) =>
  dispatch({
    types: [
      types.API_REQUEST_SEND,
      types.DOCUMENT_AUTHORITY_FETCH,
      types.API_REQUEST_ERROR,
    ],
    payload: {
      request: {
        url: '/api/congvan/uyquyen',
        method: 'GET',
        headers: {
          Authorization:
            getState().auth.token.tokenType +
            ' ' +
            getState().auth.token.accessToken,
        },
      },
    },
  })

export const documentAuthorityDelete = () => (dispatch, getState) =>
  dispatch({
    types: [
      types.API_REQUEST_SEND,
      types.DOCUMENT_AUTHORITY_DELETE,
      types.API_REQUEST_ERROR,
    ],
    payload: {
      request: {
        url: '/api/congvan/uyquyen',
        method: 'DELETE',
        headers: {
          Authorization:
            getState().auth.token.tokenType +
            ' ' +
            getState().auth.token.accessToken,
        },
      },
    },
  })

export const getLeaderStaffCanAssign = () => (dispatch, getState) =>
  dispatch({
    types: [
      types.API_REQUEST_SEND,
      types.GET_LEADER_STAFF_CAN_ASSIGN,
      types.API_REQUEST_ERROR,
    ],
    schema: UserArraySchema,
    payload: {
      request: {
        url: '/api/nhanvien/lanhdao/chidao',
        method: 'GET',
        headers: {
          Authorization:
            getState().auth.token.tokenType +
            ' ' +
            getState().auth.token.accessToken,
        },
      },
    },
  })

export const getWorkDocumentOverTime =
  (filter = {}, page = 1, sort = null, type) =>
  (dispatch, getState) => {
    let params = {
      sort,
      page: page,
      limit: utils.PAGINATION_LIMIT,
    }

    params = Object.assign(params, Tool.Utils.linkObjectKey(filter))

    return dispatch({
      types: [
        types.API_REQUEST_SEND,
        types.GET_WORK_DOCUMENT_OVER_TIME,
        types.API_REQUEST_ERROR,
      ],
      schema: PersonalWorkSchema,
      payload: {
        request: {
          url: `/api/tonghop/nhanvien/${type}`,
          method: 'GET',
          headers: {
            Authorization:
              getState().auth.token.tokenType +
              ' ' +
              getState().auth.token.accessToken,
          },
          params,
        },
      },
    })
  }

export const deleteAlertMistake = (id, loai) => (dispatch, getState) =>
  dispatch({
    types: [
      types.API_REQUEST_SEND,
      types.DELETE_ALERT_MISTAKE,
      types.API_REQUEST_ERROR,
    ],
    payload: {
      request: {
        url: `/api/baonham/${id}`,
        method: 'DELETE',
        headers: {
          'api-version': 2.0,
          Authorization:
            getState().auth.token.tokenType +
            ' ' +
            getState().auth.token.accessToken,
        },
        params: {
          loai,
        },
      },
    },
  })

export const deleteDigitizingItem = id => (dispatch, getState) =>
  dispatch({
    types: [
      types.API_REQUEST_SEND,
      types.DELETE_DIGITIZING_ITEM,
      types.API_REQUEST_ERROR,
    ],
    payload: {
      request: {
        url: `/api/vanbansohoa/${id}`,
        method: 'DELETE',
        headers: {
          Authorization:
            getState().auth.token.tokenType +
            ' ' +
            getState().auth.token.accessToken,
        },
      },
    },
  })

export const commonFetchSignUserList = () => (dispatch, getState) =>
  dispatch({
    types: [
      types.API_REQUEST_SEND,
      types.COMMON_FETCH_SIGN_USER_LIST,
      types.API_REQUEST_ERROR,
    ],
    payload: {
      request: {
        url: '/api/nhanvien/dsnguoiky',
        method: 'GET',
        headers: {
          Authorization:
            getState().auth.token.tokenType +
            ' ' +
            getState().auth.token.accessToken,
        },
      },
    },
  })

export const getReceiveDocumentItemInformation = id => (dispatch, getState) =>
  dispatch({
    types: [
      types.API_REQUEST_SEND,
      types.RECEIVE_DOCUMENT_ITEM_INFORMATION,
      types.API_REQUEST_ERROR,
    ],
    payload: {
      request: {
        url: `/api/congvanden/${id}/chitiet`,
        method: 'GET',
        headers: {
          'api-version': 2.0,
          Authorization:
            getState().auth.token.tokenType +
            ' ' +
            getState().auth.token.accessToken,
        },
      },
    },
  })

export const commonFetchInternalUnits = () => (dispatch, getState) =>
  dispatch({
    types: [
      types.API_REQUEST_SEND,
      types.COMMON_FETCH_INTERNAL_UNITS,
      types.API_REQUEST_ERROR,
    ],
    payload: {
      request: {
        url: '/api/donvi/congvannoiboliendonvi',
        method: 'GET',
        headers: {
          Authorization:
            getState().auth.token.tokenType +
            ' ' +
            getState().auth.token.accessToken,
        },
      },
    },
  })

export const signNumberCheckProgression =
  (id, soVanBan, ngayCongVan) => (dispatch, getState) =>
    dispatch({
      types: [
        types.API_REQUEST_SEND,
        types.SIGN_NUMBER_CHECK_PROGRESSION,
        types.API_REQUEST_ERROR,
      ],
      payload: {
        request: {
          url: `/api/kysodi/${id}/checksovanban`,
          method: 'GET',
          headers: {
            Authorization:
              getState().auth.token.tokenType +
              ' ' +
              getState().auth.token.accessToken,
          },
          params: {
            id,
            soVanBan,
            ngayCongVan,
          },
        },
      },
    })

export const progressionBySIM =
  (id, soVanBan, ngayCongVan, type) => (dispatch, getState) =>
    dispatch({
      types: [
        types.API_REQUEST_SEND,
        types.PROGRESSION_BY_SIM,
        types.API_REQUEST_ERROR,
      ],
      payload: {
        request: {
          url: `/api/kysodi/${id}/capsosim?loai=${type ? 0 : 1}`,
          method: 'POST',
          headers: {
            'api-version': 1.0,
            Authorization:
              getState().auth.token.tokenType +
              ' ' +
              getState().auth.token.accessToken,
          },
          data: {
            so_cong_van: soVanBan,
            ngay_cong_van: ngayCongVan,
          },
        },
      },
    })

export const postCapSoKhongXacThuc =
  (id, soVanBan, ngayCongVan) => (dispatch, getState) =>
    dispatch({
      types: [
        types.API_REQUEST_SEND,
        types.API_REQUEST_SUCCESS,
        types.API_REQUEST_ERROR,
      ],
      payload: {
        request: {
          url: `/api/kysodi/${id}/capsokhongxacthuc`,
          method: 'POST',
          headers: {
            'api-version': 1.0,
            Authorization:
              getState().auth.token.tokenType +
              ' ' +
              getState().auth.token.accessToken,
          },
          data: {
            so_cong_van: soVanBan,
            ngay_cong_van: ngayCongVan,
          },
        },
      },
    })

export const getFileInformation = id => (dispatch, getState) =>
  dispatch({
    types: [
      types.API_REQUEST_SEND,
      types.GET_FILE_INFORMATION,
      types.API_REQUEST_ERROR,
    ],
    payload: {
      client: 'fileInfo',
      request: {
        url: `/api/file/${id}/info`,
        method: 'GET',
        headers: {
          Authorization:
            getState().auth.token.tokenType +
            ' ' +
            getState().auth.token.accessToken,
        },
      },
    },
  })

export const getSendDocumentItem = id => (dispatch, getState) =>
  dispatch({
    types: [
      types.API_REQUEST_SEND,
      types.GET_SENDDOCUMENT_ITEM,
      types.API_REQUEST_ERROR,
    ],
    payload: {
      request: {
        url: `/api/congvandi/${id}`,
        method: 'GET',
        headers: {
          'api-version': 2.0,
          Authorization:
            getState().auth.token.tokenType +
            ' ' +
            getState().auth.token.accessToken,
        },
      },
    },
  })

export const getSendDocumentAdditionalInformation =
  id => (dispatch, getState) =>
    dispatch({
      types: [
        types.API_REQUEST_SEND,
        types.GET_SENDDOCUMENT_ADDITIONAL_INFORMATION,
        types.API_REQUEST_ERROR,
      ],
      payload: {
        request: {
          url: `/api/congvandi/${id}/bosung`,
          method: 'GET',
          headers: {
            'api-version': 2.0,
            Authorization:
              getState().auth.token.tokenType +
              ' ' +
              getState().auth.token.accessToken,
          },
        },
      },
    })

export const getNumberHomePage = () => (dispatch, getState) =>
  dispatch({
    types: [
      types.API_REQUEST_SEND,
      types.GET_NUMBER_HOME_PAGE,
      types.API_REQUEST_ERROR,
    ],
    payload: {
      request: {
        url: '/api/thongke/canxuly',
        method: 'GET',
        headers: {
          Authorization:
            getState().auth.token.tokenType +
            ' ' +
            getState().auth.token.accessToken,
        },
      },
    },
  })

export const getNumberDocumentPage =
  (refresh = false) =>
  (dispatch, getState) =>
    dispatch({
      types: [
        types.API_REQUEST_SEND,
        types.GET_NUMBER_DOCUMENT_PAGE,
        types.API_REQUEST_ERROR,
      ],
      payload: {
        request: {
          url: '/api/thongke/congvan',
          method: 'GET',
          headers: {
            'api-version': 2.0,
            Authorization:
              getState().auth.token.tokenType +
              ' ' +
              getState().auth.token.accessToken,
          },
          params: {
            refresh,
          },
        },
      },
    })

export const getNumberEVNDocument = () => (dispatch, getState) =>
  dispatch({
    types: [
      types.API_REQUEST_SEND,
      types.GET_NUMBER_EVN_DOCUMENT,
      types.API_REQUEST_ERROR,
    ],
    payload: {
      request: {
        url: '/api/thongke/evn',
        method: 'GET',
        headers: {
          'api-version': 2.0,
          Authorization:
            getState().auth.token.tokenType +
            ' ' +
            getState().auth.token.accessToken,
        },
      },
    },
  })

export const getNumberSignNumberPage = () => (dispatch, getState) =>
  dispatch({
    types: [
      types.API_REQUEST_SEND,
      types.GET_NUMBER_SIGNNUMBER_PAGE,
      types.API_REQUEST_ERROR,
    ],
    payload: {
      request: {
        url: '/api/thongke/kyso',
        method: 'GET',
        headers: {
          'api-version': 2.0,
          Authorization:
            getState().auth.token.tokenType +
            ' ' +
            getState().auth.token.accessToken,
        },
      },
    },
  })

export const getNumberProjectSignNumberPage = () => (dispatch, getState) =>
  dispatch({
    types: [
      types.API_REQUEST_SEND,
      types.GET_NUMBER_PROJECT_SIGNNUMBER_PAGE,
      types.API_REQUEST_ERROR,
    ],
    payload: {
      request: {
        url: '/api/kysoduan/count',
        method: 'GET',
        headers: {
          'api-version': 1.0,
          Authorization:
            getState().auth.token.tokenType +
            ' ' +
            getState().auth.token.accessToken,
        },
      },
    },
  })

export const getNumberWorkPage =
  (refresh = false) =>
  (dispatch, getState) =>
    dispatch({
      types: [
        types.API_REQUEST_SEND,
        types.GET_NUMBER_WORK_PAGE,
        types.API_REQUEST_ERROR,
      ],
      payload: {
        request: {
          url: '/api/thongke/congviec',
          method: 'GET',
          headers: {
            'api-version': 2.0,
            Authorization:
              getState().auth.token.tokenType +
              ' ' +
              getState().auth.token.accessToken,
          },
          params: {
            refresh,
          },
        },
      },
    })

export const sendDocumentCheckDocumentNumber =
  (id, soCongVan, ngayCongVan) => (dispatch, getState) =>
    dispatch({
      types: [
        types.API_REQUEST_SEND,
        types.DOCUMENT_SEND_VALIDATE_DOCUMENT_NUMBER,
        types.API_REQUEST_ERROR,
      ],
      payload: {
        request: {
          url: `/api/congvandi/checksocongvan`,
          method: 'GET',
          headers: {
            Authorization:
              getState().auth.token.tokenType +
              ' ' +
              getState().auth.token.accessToken,
          },
          params: {
            id,
            soCongVan,
            ngayCongVan,
          },
        },
      },
    })

export const getExecutingViewAssign =
  (id, is_full = false) =>
  (dispatch, getState) =>
    dispatch({
      types: [
        types.API_REQUEST_SEND,
        types.GET_EXECUTING_VIEW_ASSIGN,
        types.API_REQUEST_ERROR,
      ],
      payload: {
        request: {
          url: `/api/phieugiaoviec/${id}/xemthuchien`,
          method: 'GET',
          headers: {
            'api-version': 2.0,
            Authorization:
              getState().auth.token.tokenType +
              ' ' +
              getState().auth.token.accessToken,
          },
          params: {
            is_full,
          },
        },
      },
    })

export const commonFetchReceiveUnits = id => (dispatch, getState) =>
  dispatch({
    types: [
      types.API_REQUEST_SEND,
      types.COMMON_FETCH_RECEIVE_UNITS,
      types.API_REQUEST_ERROR,
    ],
    payload: {
      request: {
        url: `/api/donvi/${id}/donvinhan`,
        method: 'GET',
        headers: {
          Authorization:
            getState().auth.token.tokenType +
            ' ' +
            getState().auth.token.accessToken,
        },
        params: {
          id,
        },
      },
    },
  })

export const signNumberProjectGetListMember =
  projectId => (dispatch, getState) =>
    dispatch({
      types: [
        types.API_REQUEST_SEND,
        types.SIGN_NUMBER_PROJECT_GET_MEMBER_BY_ID,
        types.API_REQUEST_ERROR,
      ],
      schema: SignNumberProjectMemberArraySchema,
      payload: {
        request: {
          url: `/api/duan/${projectId}/thanhvien`,
          method: 'GET',
          headers: {
            Authorization:
              getState().auth.token.tokenType +
              ' ' +
              getState().auth.token.accessToken,
          },
          params: {
            id: projectId,
          },
        },
      },
    })

export const getListStaffByDepartmentId =
  (id, sorter) => (dispatch, getState) =>
    dispatch({
      types: [
        types.API_REQUEST_SEND,
        types.GET_LIST_STAFFS_IN_DEPARTMENT,
        types.API_REQUEST_ERROR,
      ],
      payload: {
        request: {
          url: `/api/quyen/phongban/${id}/nhanvien`,
          method: 'GET',
          headers: {
            Authorization:
              getState().auth.token.tokenType +
              ' ' +
              getState().auth.token.accessToken,
          },
          params: {
            sorter,
          },
        },
      },
    })

export const signNumberProjectCreate = data => (dispatch, getState) =>
  dispatch({
    types: [
      types.API_REQUEST_SEND,
      types.SIGN_NUMBER_PROJECT_CREATE,
      types.API_REQUEST_ERROR,
    ],
    payload: {
      request: {
        url: `/api/kysoduan`,
        method: 'POST',
        headers: {
          Authorization:
            getState().auth.token.tokenType +
            ' ' +
            getState().auth.token.accessToken,
        },
        data,
      },
    },
  })

export const postCreateProject = data => (dispatch, getState) =>
  dispatch({
    types: [
      types.API_REQUEST_SEND,
      types.POST_CREATE_PROJECT,
      types.API_REQUEST_ERROR,
    ],
    payload: {
      request: {
        url: `/api/duan`,
        method: 'POST',
        headers: {
          Authorization:
            getState().auth.token.tokenType +
            ' ' +
            getState().auth.token.accessToken,
        },
        data,
      },
    },
  })

export const signNumberProjectUpdate = (id, data) => (dispatch, getState) =>
  dispatch({
    types: [
      types.API_REQUEST_SEND,
      types.SIGN_NUMBER_PROJECT_UPDATE,
      types.API_REQUEST_ERROR,
    ],
    payload: {
      request: {
        url: `/api/kysoduan/${id}`,
        method: 'PUT',
        headers: {
          Authorization:
            getState().auth.token.tokenType +
            ' ' +
            getState().auth.token.accessToken,
        },
        data,
        params: {
          id,
        },
      },
    },
  })

export const signNumberProjectGet =
  (
    filter = {},
    page = 1,
    sort = null,
    canKySo = true,
    limit = utils.PAGINATION_LIMIT
  ) =>
  (dispatch, getState) => {
    let params = {
      sort,
      page: page,
      limit: limit,
      canKySo: canKySo,
    }

    params = Object.assign(params, Tool.Utils.linkObjectKey(filter))

    return dispatch({
      types: [
        types.API_REQUEST_SEND_SIGNNUMBER,
        types.SIGN_NUMBER_PROJECTS_GET,
        types.API_REQUEST_ERROR,
      ],
      schema: SignNumberProjectArraySchema,
      page: page,
      payload: {
        request: {
          url: `/api/kysoduan`,
          method: 'GET',
          headers: {
            Authorization:
              getState().auth.token.tokenType +
              ' ' +
              getState().auth.token.accessToken,
          },
          params,
        },
      },
    })
  }

export const exportSendDocument =
  (filter = {}, sort = null) =>
  (dispatch, getState) => {
    let params = {
      sort,
    }

    params = Object.assign(params, Tool.Utils.linkObjectKey(filter))

    return dispatch({
      types: [
        types.API_REQUEST_SEND,
        types.EXPORT_SENDDOCUMENT_EXCEL,
        types.API_REQUEST_ERROR,
      ],
      payload: {
        client: 'download',
        request: {
          url: `/api/socongvan/congvandi/excel`,
          method: 'GET',
          headers: {
            Authorization:
              getState().auth.token.tokenType +
              ' ' +
              getState().auth.token.accessToken,
          },
          params: _.pickBy(params),
        },
      },
    })
  }

export const getListProjectPage =
  (donViId, isActive, page = 1, sort = null) =>
  (dispatch, getState) => {
    let params = {
      donViId,
      isActive,
      page: page,
      limit: utils.PAGINATION_LIMIT,
    }
    return dispatch({
      types: [
        types.API_REQUEST_SEND,
        types.GET_LIST_PROJECT,
        types.API_REQUEST_ERROR,
      ],
      page: page,
      payload: {
        request: {
          url: '/api/duan',
          method: 'GET',
          headers: {
            Authorization:
              getState().auth.token.tokenType +
              ' ' +
              getState().auth.token.accessToken,
          },
          params,
        },
      },
    })
  }

export const signNumberProjectDelete = id => (dispatch, getState) =>
  dispatch({
    types: [
      types.API_REQUEST_SEND,
      types.SIGN_NUMBER_PROJECTS_DELETE,
      types.API_REQUEST_ERROR,
    ],
    payload: {
      request: {
        url: `/api/kysoduan/${id}`,
        method: 'DELETE',
        headers: {
          Authorization:
            getState().auth.token.tokenType +
            ' ' +
            getState().auth.token.accessToken,
        },
        params: {
          id,
        },
      },
    },
  })

export const getCabinetUnitItem = id => (dispatch, getState) =>
  dispatch({
    types: [
      types.API_REQUEST_SEND,
      types.CABINET_UNIT_ITEM,
      types.API_REQUEST_ERROR,
    ],
    payload: {
      request: {
        url: `/api/tutailieu/donvi/${id}`,
        method: 'GET',
        headers: {
          Authorization:
            getState().auth.token.tokenType +
            ' ' +
            getState().auth.token.accessToken,
        },
      },
    },
  })

export const signNumberProjectGetDetail = id => (dispatch, getState) =>
  dispatch({
    types: [
      types.API_REQUEST_SEND,
      types.SIGN_NUMBER_PROJECTS_GET_DETAIL,
      types.API_REQUEST_ERROR,
    ],
    schema: SignNumberProjectItemSchema,
    payload: {
      request: {
        url: `/api/kysoduan/${id}`,
        method: 'GET',
        headers: {
          Authorization:
            getState().auth.token.tokenType +
            ' ' +
            getState().auth.token.accessToken,
        },
      },
    },
  })

export const getCabinetDepartmentItem = id => (dispatch, getState) =>
  dispatch({
    types: [
      types.API_REQUEST_SEND,
      types.CABINET_DEPARTMENT_ITEM,
      types.API_REQUEST_ERROR,
    ],
    payload: {
      request: {
        url: `/api/tutailieuphongban/${id}`,
        method: 'GET',
        headers: {
          Authorization:
            getState().auth.token.tokenType +
            ' ' +
            getState().auth.token.accessToken,
        },
      },
    },
  })

export const signNumberProjectGetDetailUpdate = id => (dispatch, getState) =>
  dispatch({
    types: [
      types.API_REQUEST_SEND,
      types.SIGN_NUMBER_PROJECTS_GET_DETAIL_UPDATE,
      types.API_REQUEST_ERROR,
    ],
    schema: SignNumberProjectItemSchema,
    payload: {
      request: {
        url: `/api/kysoduan/${id}/chinhsua`,
        method: 'GET',
        headers: {
          Authorization:
            getState().auth.token.tokenType +
            ' ' +
            getState().auth.token.accessToken,
        },
      },
    },
  })

export const getCabinetPersonalItem = id => (dispatch, getState) =>
  dispatch({
    types: [
      types.API_REQUEST_SEND,
      types.CABINET_PERSONAL_ITEM,
      types.API_REQUEST_ERROR,
    ],
    payload: {
      request: {
        url: `/api/tutailieucanhan/${id}`,
        method: 'GET',
        headers: {
          Authorization:
            getState().auth.token.tokenType +
            ' ' +
            getState().auth.token.accessToken,
        },
      },
    },
  })

export const signNumberProjectFeedbackGet =
  (id, page = 1) =>
  (dispatch, getState) =>
    dispatch({
      types: [
        types.API_REQUEST_SEND,
        types.SIGN_NUMBER_PROJECTS_DETAIL_FEEDBACK_GET,
        types.API_REQUEST_ERROR,
      ],
      schema: SignNumberFeedbackSchema,
      page: page,
      payload: {
        request: {
          url: `/api/kysoduan/${id}/gopy`,
          method: 'GET',
          headers: {
            Authorization:
              getState().auth.token.tokenType +
              ' ' +
              getState().auth.token.accessToken,
          },
          params: {
            page: page,
            limit: 50,
          },
        },
      },
    })

export const postAlertMistakeWork = (id, data) => (dispatch, getState) =>
  dispatch({
    types: [
      types.API_REQUEST_SEND,
      types.ALERT_MISTAKE_WORK,
      types.API_REQUEST_ERROR,
    ],
    payload: {
      request: {
        url: `/api/baonham/${id}/captren`,
        method: 'POST',
        headers: {
          'api-version': 2.0,
          Authorization:
            getState().auth.token.tokenType +
            ' ' +
            getState().auth.token.accessToken,
        },
        data,
      },
    },
  })

export const deleteProjectItem = id => (dispatch, getState) =>
  dispatch({
    types: [
      types.API_REQUEST_SEND,
      types.DELETE_PROJECT_ITEM,
      types.API_REQUEST_ERROR,
    ],
    payload: {
      request: {
        url: `/api/duan/${id}`,
        method: 'DELETE',
        headers: {
          Authorization:
            getState().auth.token.tokenType +
            ' ' +
            getState().auth.token.accessToken,
        },
      },
    },
  })

export const signNumberProjectFeedbackCreate =
  (id, data) => (dispatch, getState) =>
    dispatch({
      types: [
        types.API_REQUEST_SEND,
        types.SIGN_NUMBER_PROJECTS_DETAIL_FEEDBACK_CREATE,
        types.API_REQUEST_ERROR,
      ],
      schema: SignNumberFeedbackSchema,
      payload: {
        request: {
          url: `/api/kysoduan/${id}/gopy`,
          method: 'POST',
          headers: {
            Authorization:
              getState().auth.token.tokenType +
              ' ' +
              getState().auth.token.accessToken,
          },
          data: data,
        },
      },
    })

export const signNumberProjectFeedbackUpdate =
  (id, feedbackId, data) => (dispatch, getState) =>
    dispatch({
      types: [
        types.API_REQUEST_SEND,
        types.SIGN_NUMBER_PROJECTS_DETAIL_FEEDBACK_UPDATE,
        types.API_REQUEST_ERROR,
      ],
      payload: {
        request: {
          url: `/api/kysoduan/${id}/gopy/${feedbackId}`,
          method: 'PUT',
          headers: {
            Authorization:
              getState().auth.token.tokenType +
              ' ' +
              getState().auth.token.accessToken,
          },
          data: data,
        },
      },
    })

export const getAlertMistakesDocument =
  (filter = {}, page = 1, sort = null, refresh = false) =>
  (dispatch, getState) => {
    let params = {
      refresh,
      sorter: sort,
      page: page,
      loai: filter.loai,
      limit: utils.PAGINATION_LIMIT,
    }
    if (filter.textSearch !== '') {
      params.textSearch = filter.textSearch
    }
    params = Tool.Utils.linkObjectKey(params)

    return dispatch({
      types: [
        types.API_REQUEST_SEND,
        types.ALERT_MISTAKE_GET_LIST,
        types.API_REQUEST_ERROR,
      ],
      schema: AlertMistakeDocumentArraySchema,
      payload: {
        request: {
          url: `/api/baonham`,
          method: 'GET',
          headers: {
            'api-version': 3.0,
            Authorization:
              getState().auth.token.tokenType +
              ' ' +
              getState().auth.token.accessToken,
          },
          params,
        },
      },
    })
  }

export const exportStatisticsSignNumber =
  (filter = {}, loaiThongKe = 1) =>
  (dispatch, getState) => {
    let params = {
      loai_thong_ke: loaiThongKe,
    }

    params = Object.assign(params, Tool.Utils.linkObjectKey(filter))

    return dispatch({
      types: [
        types.API_REQUEST_SEND,
        types.EXPORT_STATISTICS_SIGN_NUMBER,
        types.API_REQUEST_ERROR,
      ],
      payload: {
        client: 'download',
        request: {
          url: `/api/tonghop/kyso/in`,
          method: 'GET',
          headers: {
            Authorization:
              getState().auth.token.tokenType +
              ' ' +
              getState().auth.token.accessToken,
          },
          params: _.pickBy(params),
        },
      },
    })
  }

export const deleteReWorkingWork = (id, data) => (dispatch, getState) =>
  dispatch({
    types: [
      types.API_REQUEST_SEND,
      types.WORK_DELETE_REWORKING,
      types.API_REQUEST_ERROR,
    ],
    payload: {
      request: {
        url: `/api/congviec/${id}/giaovieclai`,
        method: 'POST',
        headers: {
          Authorization:
            getState().auth.token.tokenType +
            ' ' +
            getState().auth.token.accessToken,
        },
        data: data,
      },
    },
  })

export const updateProjectItem = (id, data) => (dispatch, getState) =>
  dispatch({
    types: [
      types.API_REQUEST_SEND,
      types.UPDATE_PROJECT_ITEM,
      types.API_REQUEST_ERROR,
    ],
    payload: {
      request: {
        url: `/api/duan/${id}`,
        method: 'PUT',
        headers: {
          Authorization:
            getState().auth.token.tokenType +
            ' ' +
            getState().auth.token.accessToken,
        },
        data: data,
      },
    },
  })

export const deleteWorkingDocument = (id, data) => (dispatch, getState) =>
  dispatch({
    types: [
      types.API_REQUEST_SEND,
      types.DOCUMENT_DELETE_REWORKING,
      types.API_REQUEST_ERROR,
    ],
    payload: {
      request: {
        url: `/api/congvanden/${id}/chidaolai`,
        method: 'POST',
        headers: {
          Authorization:
            getState().auth.token.tokenType +
            ' ' +
            getState().auth.token.accessToken,
        },
        data: data,
      },
    },
  })

export const signNumberProjectAgreeBySIM =
  (id, type = 0, data) =>
  (dispatch, getState) =>
    dispatch({
      types: [
        types.API_REQUEST_SEND,
        types.SIGN_NUMBER_PROJECTS_AGREE_BY_SIM,
        types.API_REQUEST_ERROR,
      ],
      payload: {
        request: {
          url: `/api/kysoduan/${id}/kysim?loai=${type}`,
          method: 'PATCH',
          headers: {
            Authorization:
              getState().auth.token.tokenType +
              ' ' +
              getState().auth.token.accessToken,
          },
          data: data,
        },
      },
    })

export const cancelAlertMistake = (id, loai) => (dispatch, getState) =>
  dispatch({
    types: [
      types.API_REQUEST_SEND,
      types.CANCEL_ALERT_MISTAKE,
      types.API_REQUEST_ERROR,
    ],
    payload: {
      request: {
        url: `/api/baonham/${id}/congviec`,
        method: 'DELETE',
        headers: {
          'api-version': 2.0,
          Authorization:
            getState().auth.token.tokenType +
            ' ' +
            getState().auth.token.accessToken,
        },
        params: {
          loai,
        },
      },
    },
  })

export const getProjectItem = id => (dispatch, getState) =>
  dispatch({
    types: [
      types.API_REQUEST_SEND,
      types.GET_PROJECT_ITEM,
      types.API_REQUEST_ERROR,
    ],
    payload: {
      request: {
        url: `/api/duan/${id}`,
        method: 'GET',
        headers: {
          Authorization:
            getState().auth.token.tokenType +
            ' ' +
            getState().auth.token.accessToken,
        },
      },
    },
  })

export const getProjectInfo = id => (dispatch, getState) =>
  dispatch({
    types: [
      types.API_REQUEST_SEND,
      types.GET_PROJECT_INFO,
      types.API_REQUEST_ERROR,
    ],
    payload: {
      request: {
        url: `/api/duan/${id}/info`,
        method: 'GET',
        headers: {
          Authorization:
            getState().auth.token.tokenType +
            ' ' +
            getState().auth.token.accessToken,
        },
      },
    },
  })

export const deleteAlertMistakeDocument = (id, loai) => (dispatch, getState) =>
  dispatch({
    types: [
      types.API_REQUEST_SEND,
      types.DELETE_ALERT_MISTAKE,
      types.API_REQUEST_ERROR,
    ],
    payload: {
      request: {
        url: `/api/baonham/${id}`,
        method: 'DELETE',
        headers: {
          'api-version': 2.0,
          Authorization:
            getState().auth.token.tokenType +
            ' ' +
            getState().auth.token.accessToken,
        },
        params: {
          loai,
        },
      },
    },
  })

export const signNumberProjectDisagree = (id, data) => (dispatch, getState) =>
  dispatch({
    types: [
      types.API_REQUEST_SEND,
      types.SIGN_NUMBER_PROJECTS_DISAGREE,
      types.API_REQUEST_ERROR,
    ],
    payload: {
      request: {
        url: `/api/kysoduan/${id}/khongdongy`,
        method: 'PATCH',
        headers: {
          Authorization:
            getState().auth.token.tokenType +
            ' ' +
            getState().auth.token.accessToken,
        },
        data: data,
      },
    },
  })

export const postMoveDrawer = data => (dispatch, getState) =>
  dispatch({
    types: [types.API_REQUEST_SEND, types.MOVE_DRAWER, types.API_REQUEST_ERROR],
    payload: {
      request: {
        url: `/api/tutailieu/dichuyen`,
        method: 'POST',
        headers: {
          'api-version': 2.0,
          Authorization:
            getState().auth.token.tokenType +
            ' ' +
            getState().auth.token.accessToken,
        },
        data: data,
      },
    },
  })

export const signNumberProjectReverse = (id, data) => (dispatch, getState) =>
  dispatch({
    types: [
      types.API_REQUEST_SEND,
      types.SIGN_NUMBER_PROJECTS_REVERSE,
      types.API_REQUEST_ERROR,
    ],
    payload: {
      request: {
        url: `/api/kysoduan/${id}/baoluu`,
        method: 'PATCH',
        headers: {
          Authorization:
            getState().auth.token.tokenType +
            ' ' +
            getState().auth.token.accessToken,
        },
        data: data,
      },
    },
  })

export const updateProjectStatus = (id, data) => (dispatch, getState) =>
  dispatch({
    types: [
      types.API_REQUEST_SEND,
      types.GET_PROJECT_STATUS,
      types.API_REQUEST_ERROR,
    ],
    payload: {
      request: {
        url: `/api/duan/${id}/trangthai?is_active=${data}`,
        method: 'PATCH',
        headers: {
          Authorization:
            getState().auth.token.tokenType +
            ' ' +
            getState().auth.token.accessToken,
        },
      },
    },
  })

// GET thống kê chưa lưu tủ
export const getDocumentCabinetOverTime =
  (filter = {}, page = 1, sort = null) =>
  (dispatch, getState) => {
    let params = {
      sort,
      page: page,
      limit: utils.PAGINATION_LIMIT,
    }

    params = Object.assign(params, Tool.Utils.linkObjectKey(filter))
    return dispatch({
      types: [
        types.API_REQUEST_SEND,
        types.DOCUMENTS_CABINET_OVERTIME,
        types.API_REQUEST_ERROR,
      ],
      payload: {
        request: {
          url: `/api/thongke/congvanchualuutu`,
          method: 'GET',
          headers: {
            'api-version': 2.0,
            Authorization:
              getState().auth.token.tokenType +
              ' ' +
              getState().auth.token.accessToken,
          },
          params,
        },
      },
    })
  }

export const getStagisticalSignNumberOverTime =
  (filter = {}, page = 1, sort = null) =>
  (dispatch, getState) => {
    let params = {
      sort,
      page: page,
      limit: utils.PAGINATION_LIMIT,
    }

    params = Object.assign(params, Tool.Utils.linkObjectKey(filter))

    return dispatch({
      types: [
        types.API_REQUEST_SEND,
        types.STAGISTICAL_SIGNNUMBER_OVERTIME,
        types.API_REQUEST_ERROR,
      ],
      payload: {
        request: {
          url: `/api/thongke/congvankytay`,
          method: 'GET',
          headers: {
            'api-version': 2.0,
            Authorization:
              getState().auth.token.tokenType +
              ' ' +
              getState().auth.token.accessToken,
          },
          params,
        },
      },
    })
  }

export const resendEVNSendDocument = id => (dispatch, getState) =>
  dispatch({
    types: [
      types.API_REQUEST_SEND,
      types.DOCUMENT_SEND_RESEND_EVN,
      types.API_REQUEST_ERROR,
    ],
    payload: {
      request: {
        url: `/api/congvandi/${id}/guilaievn`,
        method: 'POST',
        headers: {
          Authorization:
            getState().auth.token.tokenType +
            ' ' +
            getState().auth.token.accessToken,
        },
      },
    },
  })

export const signNumberProjectUpdateHistoryGet =
  (id, vanBanGocId) => (dispatch, getState) =>
    dispatch({
      types: [
        types.API_REQUEST_SEND,
        types.SIGN_NUMBER_PROJECTS_UPDATE_HISTORY_GET,
        types.API_REQUEST_ERROR,
      ],
      payload: {
        request: {
          url: `/api/kysoduan/${id}/lichsu`,
          method: 'GET',
          headers: {
            Authorization:
              getState().auth.token.tokenType +
              ' ' +
              getState().auth.token.accessToken,
          },
          params: {
            vanBanGocId: vanBanGocId,
          },
        },
      },
    })

export const checkDuplicateProject =
  (donViId, maDuAn, id) => (dispatch, getState) => {
    let params = {
      don_vi_id: donViId,
      ma_du_an: maDuAn,
      id,
    }
    return dispatch({
      types: [
        types.API_REQUEST_SEND,
        types.GET_LIST_PROJECT,
        types.API_REQUEST_ERROR,
      ],
      payload: {
        request: {
          url: '/api/duan/checkma',
          method: 'GET',
          headers: {
            Authorization:
              getState().auth.token.tokenType +
              ' ' +
              getState().auth.token.accessToken,
          },
          params,
        },
      },
    })
  }

export const getGeneralDocument =
  (filter = {}, page = 1, sort = null, refresh) =>
  (dispatch, getState) => {
    let params = {
      sort,
      page: page,
      limit: 0,
      refresh,
    }
    params = Object.assign(params, Tool.Utils.linkObjectKey(filter))

    return dispatch({
      types: [
        types.API_REQUEST_INIT_DOCUMENT_RECEIVE_SEND,
        types.GET_GENERAL_DOCUMENT,
        types.API_REQUEST_ERROR,
      ],
      payload: {
        request: {
          url: `/api/thongke/congvan/donvi`,
          method: 'GET',
          headers: {
            'api-version': 2.0,
            Authorization:
              getState().auth.token.tokenType +
              ' ' +
              getState().auth.token.accessToken,
          },
          params,
        },
      },
    })
  }

export const exportGeneralDocument =
  (filter = {}, page = 1, sort = null) =>
  (dispatch, getState) => {
    let params = {
      sort,
      page: page,
      limit: utils.PAGINATION_LIMIT,
    }

    params = Object.assign(params, Tool.Utils.linkObjectKey(filter))

    return dispatch({
      types: [
        types.API_REQUEST_SEND,
        types.EXPORT_GENERAL_DOCUMENT,
        types.API_REQUEST_ERROR,
      ],
      page: page,
      payload: {
        client: 'download',
        request: {
          url: `/api/thongke/congvan/donvi/in`,
          method: 'GET',
          headers: {
            'api-version': 2.0,
            Authorization:
              getState().auth.token.tokenType +
              ' ' +
              getState().auth.token.accessToken,
          },
          params: _.pickBy(params),
        },
      },
    })
  }

export const getStatisticsSignNumberUnits =
  (filter = {}, refresh = false) =>
  (dispatch, getState) => {
    let params = {
      refresh,
    }
    params = Object.assign(params, Tool.Utils.linkObjectKey(filter))

    return dispatch({
      types: [
        types.API_REQUEST_SEND,
        types.GET_STATISTIC_SIGN_NUMBER_UNITS,
        types.API_REQUEST_ERROR,
      ],
      payload: {
        request: {
          url: `/api/tonghop/kyso/donvi`,
          method: 'GET',
          headers: {
            Authorization:
              getState().auth.token.tokenType +
              ' ' +
              getState().auth.token.accessToken,
            'api-version': 2.0,
          },
          params,
        },
      },
    })
  }

export const getStatisticsSignNumberDepartments =
  (filter = {}, refresh = false) =>
  (dispatch, getState) => {
    let params = {
      refresh,
    }
    params = Object.assign(params, Tool.Utils.linkObjectKey(filter))

    return dispatch({
      types: [
        types.API_REQUEST_SEND,
        types.GET_STATISTIC_SIGN_NUMBER_DEPARTMENTS,
        types.API_REQUEST_ERROR,
      ],
      payload: {
        request: {
          url: `/api/tonghop/kyso/phongban`,
          method: 'GET',
          headers: {
            Authorization:
              getState().auth.token.tokenType +
              ' ' +
              getState().auth.token.accessToken,
            'api-version': 2.0,
          },
          params,
        },
      },
    })
  }

export const getStatisticsSignNumberPersonal =
  (filter = {}, refresh = false) =>
  (dispatch, getState) => {
    let params = {
      refresh,
    }
    params = Object.assign(params, Tool.Utils.linkObjectKey(filter))

    return dispatch({
      types: [
        types.API_REQUEST_SEND,
        types.GET_STATISTIC_SIGN_NUMBER_PERSONAL,
        types.API_REQUEST_ERROR,
      ],
      payload: {
        request: {
          url: `/api/tonghop/kyso/canhan`,
          method: 'GET',
          headers: {
            Authorization:
              getState().auth.token.tokenType +
              ' ' +
              getState().auth.token.accessToken,
            'api-version': 2.0,
          },
          params,
        },
      },
    })
  }

export const getStatisticsSignNumberPersonalInChildUnits =
  (filter = {}, refresh = false) =>
  (dispatch, getState) => {
    let params = {
      refresh,
    }
    params = Object.assign(params, Tool.Utils.linkObjectKey(filter))

    return dispatch({
      types: [
        types.API_REQUEST_SEND,
        types.GET_STATISTIC_SIGN_NUMBER_PERSONAL_IN_CHILD_UNITS,
        types.API_REQUEST_ERROR,
      ],
      payload: {
        request: {
          url: `/api/tonghop/kyso/donvicon`,
          method: 'GET',
          headers: {
            Authorization:
              getState().auth.token.tokenType +
              ' ' +
              getState().auth.token.accessToken,
            'api-version': 2.0,
          },
          params,
        },
      },
    })
  }

export const getGeneralWorkUnit =
  (filter = {}, refresh) =>
  (dispatch, getState) => {
    let params = {
      refresh,
    }
    params = Object.assign(params, Tool.Utils.linkObjectKey(filter))

    return dispatch({
      types: [
        types.API_REQUEST_SEND,
        types.GET_GENERAL_WORK_UNIT,
        types.API_REQUEST_ERROR,
      ],
      payload: {
        request: {
          url: `/api/tonghop/congviec/quyenadmin`,
          method: 'GET',
          headers: {
            Authorization:
              getState().auth.token.tokenType +
              ' ' +
              getState().auth.token.accessToken,
            'api-version': 2.0,
          },
          params,
        },
      },
    })
  }

export const getStatisticUsers =
  (filter = {}, refresh = false) =>
  (dispatch, getState) => {
    let params = {
      refresh,
    }
    params = Object.assign(params, Tool.Utils.linkObjectKey(filter))

    return dispatch({
      types: [
        types.API_REQUEST_SEND,
        types.GET_STATISTIC_USER,
        types.API_REQUEST_ERROR,
      ],
      payload: {
        request: {
          url: `/api/thongke/nhanvien`,
          method: 'GET',
          headers: {
            Authorization:
              getState().auth.token.tokenType +
              ' ' +
              getState().auth.token.accessToken,
          },
          params,
        },
      },
    })
  }

export const exportStatisticUsers =
  (filter = {}) =>
  (dispatch, getState) => {
    let params = {}
    params = Object.assign(params, Tool.Utils.linkObjectKey(filter))

    return dispatch({
      types: [
        types.API_REQUEST_SEND,
        types.EXPORT_STATISTIC_USER,
        types.API_REQUEST_ERROR,
      ],
      payload: {
        client: 'download',
        request: {
          url: `/api/thongke/nhanvien/in`,
          method: 'GET',
          headers: {
            Authorization:
              getState().auth.token.tokenType +
              ' ' +
              getState().auth.token.accessToken,
          },
          params: _.pickBy(params),
        },
      },
    })
  }

export const getStatisticMessenger =
  (filter = {}, refresh = false) =>
  (dispatch, getState) => {
    let params = {
      refresh,
    }
    params = Object.assign(params, Tool.Utils.linkObjectKey(filter))

    return dispatch({
      types: [
        types.API_REQUEST_SEND,
        types.GET_STATISTIC_MESSENGER,
        types.API_REQUEST_ERROR,
      ],
      payload: {
        client: 'profile',
        request: {
          url: `/api/thongke/tinnhan`,
          method: 'GET',
          headers: {
            Authorization:
              getState().auth.token.tokenType +
              ' ' +
              getState().auth.token.accessToken,
          },
          params,
        },
      },
    })
  }

export const getStatisticNotMessenger =
  (filter = {}, refresh = false) =>
  (dispatch, getState) => {
    let params = {
      refresh,
    }
    params = Object.assign(params, Tool.Utils.linkObjectKey(filter))

    return dispatch({
      types: [
        types.API_REQUEST_SEND,
        types.GET_STATISTIC_NOT_MESSENGER,
        types.API_REQUEST_ERROR,
      ],
      payload: {
        client: 'profile',
        request: {
          url: `/api/thongke/chuaguitin`,
          method: 'GET',
          headers: {
            Authorization:
              getState().auth.token.tokenType +
              ' ' +
              getState().auth.token.accessToken,
          },
          params,
        },
      },
    })
  }

export const exportStatisticMessenger =
  (filter = {}) =>
  (dispatch, getState) => {
    let params = {}
    params = Object.assign(params, Tool.Utils.linkObjectKey(filter))

    return dispatch({
      types: [
        types.API_REQUEST_SEND,
        types.EXPORT_STATISTIC_MESSENGER,
        types.API_REQUEST_ERROR,
      ],
      payload: {
        client: 'downloadFromChat',
        request: {
          url: `/api/thongke/tinnhan/in`,
          method: 'GET',
          headers: {
            Authorization:
              getState().auth.token.tokenType +
              ' ' +
              getState().auth.token.accessToken,
          },
          params: _.pickBy(params),
        },
      },
    })
  }

export const exportStatisticNotMessenger =
  (filter = {}) =>
  (dispatch, getState) => {
    let params = {}
    params = Object.assign(params, Tool.Utils.linkObjectKey(filter))

    return dispatch({
      types: [
        types.API_REQUEST_SEND,
        types.EXPORT_STATISTIC_NOT_MESSENGER,
        types.API_REQUEST_ERROR,
      ],
      payload: {
        client: 'downloadFromChat',
        request: {
          url: `/api/thongke/chuaguitin/in`,
          method: 'GET',
          headers: {
            Authorization:
              getState().auth.token.tokenType +
              ' ' +
              getState().auth.token.accessToken,
          },
          params: _.pickBy(params),
        },
      },
    })
  }

export const getDocumentDigitizi =
  (filter = {}, refresh = false) =>
  (dispatch, getState) => {
    let params = {
      refresh,
    }
    params = Object.assign(params, Tool.Utils.linkObjectKey(filter))

    return dispatch({
      types: [
        types.API_REQUEST_SEND,
        types.GET_DOCUMENT_DIGITIZE,
        types.API_REQUEST_ERROR,
      ],
      payload: {
        request: {
          url: `/api/tonghop/sohoa`,
          method: 'GET',
          headers: {
            Authorization:
              getState().auth.token.tokenType +
              ' ' +
              getState().auth.token.accessToken,
          },
          params,
        },
      },
    })
  }

export const exportGeneralWorkUnit =
  (filter = {}, page = 1, sort = null) =>
  (dispatch, getState) => {
    let params = {
      sort,
      page: page,
      limit: utils.PAGINATION_LIMIT,
    }

    params = Object.assign(params, Tool.Utils.linkObjectKey(filter))

    return dispatch({
      types: [
        types.API_REQUEST_SEND,
        types.EXPORT_GENERAL_WORK,
        types.API_REQUEST_ERROR,
      ],
      page: page,
      payload: {
        client: 'download',
        request: {
          url: `/api/tonghop/congviec/quyenadmin/in`,
          method: 'GET',
          headers: {
            Authorization:
              getState().auth.token.tokenType +
              ' ' +
              getState().auth.token.accessToken,
            'api-version': 2.0,
          },
          params: _.pickBy(params),
        },
      },
    })
  }

export const getDocumentRecovery =
  (filter = {}, refresh = false) =>
  (dispatch, getState) => {
    let params = {
      refresh,
    }
    params = Object.assign(params, Tool.Utils.linkObjectKey(filter))

    return dispatch({
      types: [
        types.API_REQUEST_SEND,
        types.GET_DOCUMENT_RECOVERY,
        types.API_REQUEST_ERROR,
      ],
      payload: {
        request: {
          url: `/api/tonghop/thuhoi`,
          method: 'GET',
          headers: {
            Authorization:
              getState().auth.token.tokenType +
              ' ' +
              getState().auth.token.accessToken,
          },
          params,
        },
      },
    })
  }

export const exportDocumentRecovery =
  (filter = {}, page = 1, sort = null) =>
  (dispatch, getState) => {
    let params = {
      sort,
      page: page,
      limit: utils.PAGINATION_LIMIT,
    }

    params = Object.assign(params, Tool.Utils.linkObjectKey(filter))

    return dispatch({
      types: [
        types.API_REQUEST_SEND,
        types.EXPORT_DOCUMENT_RECOVERY,
        types.API_REQUEST_ERROR,
      ],
      page: page,
      payload: {
        client: 'download',
        request: {
          url: `/api/tonghop/thuhoi/in`,
          method: 'GET',
          headers: {
            Authorization:
              getState().auth.token.tokenType +
              ' ' +
              getState().auth.token.accessToken,
          },
          params: _.pickBy(params),
        },
      },
    })
  }

export const exportDocumentDigitize =
  (filter = {}, page = 1, sort = null) =>
  (dispatch, getState) => {
    let params = {
      sort,
      page: page,
      limit: utils.PAGINATION_LIMIT,
    }

    params = Object.assign(params, Tool.Utils.linkObjectKey(filter))

    return dispatch({
      types: [
        types.API_REQUEST_SEND,
        types.EXPORT_DOCUMENT_DIGITIZE,
        types.API_REQUEST_ERROR,
      ],
      page: page,
      payload: {
        client: 'download',
        request: {
          url: `/api/tonghop/sohoa/in`,
          method: 'GET',
          headers: {
            Authorization:
              getState().auth.token.tokenType +
              ' ' +
              getState().auth.token.accessToken,
          },
          params: _.pickBy(params),
        },
      },
    })
  }

export const getStatisticAlertMistakesUnit =
  (filter = {}, refresh = false) =>
  (dispatch, getState) => {
    let params = {
      refresh,
    }
    params = Object.assign(params, Tool.Utils.linkObjectKey(filter))

    return dispatch({
      types: [
        types.API_REQUEST_SEND,
        types.GET_STATISTIC_ALERT_MISTAKE_UNIT,
        types.API_REQUEST_ERROR,
      ],
      payload: {
        request: {
          url: `api/tonghop/baonhamdonvi`,
          method: 'GET',
          headers: {
            Authorization:
              getState().auth.token.tokenType +
              ' ' +
              getState().auth.token.accessToken,
          },
          params,
        },
      },
    })
  }

export const getStatisticAlertMistakesDepartment =
  (filter = {}, refresh = false) =>
  (dispatch, getState) => {
    let params = {
      refresh,
    }
    params = Object.assign(params, Tool.Utils.linkObjectKey(filter))

    return dispatch({
      types: [
        types.API_REQUEST_SEND,
        types.GET_STATISTIC_ALERT_MISTAKE_DEPARTMENT,
        types.API_REQUEST_ERROR,
      ],
      payload: {
        request: {
          url: `api/tonghop/baonhamphongban`,
          method: 'GET',
          headers: {
            Authorization:
              getState().auth.token.tokenType +
              ' ' +
              getState().auth.token.accessToken,
          },
          params,
        },
      },
    })
  }

export const getStatisticAlertMistakesPersonal =
  (filter = {}, refresh = false) =>
  (dispatch, getState) => {
    let params = {
      refresh,
    }
    params = Object.assign(params, Tool.Utils.linkObjectKey(filter))

    return dispatch({
      types: [
        types.API_REQUEST_SEND,
        types.GET_STATISTIC_ALERT_MISTAKE_PERSONAL,
        types.API_REQUEST_ERROR,
      ],
      payload: {
        request: {
          url: `/api/tonghop/baonhamcanhanphongban`,
          method: 'GET',
          headers: {
            Authorization:
              getState().auth.token.tokenType +
              ' ' +
              getState().auth.token.accessToken,
          },
          params,
        },
      },
    })
  }

export const projectsListByMemberIdGet = id => (dispatch, getState) =>
  dispatch({
    types: [
      types.API_REQUEST_SEND,
      types.PROJECTS_GET_LIST_BY_MEMBER_ID,
      types.API_REQUEST_ERROR,
    ],
    schema: ProjectArraySchema,
    payload: {
      request: {
        url: `/api/nhanvien/${id}/duan`,
        method: 'GET',
        headers: {
          Authorization:
            getState().auth.token.tokenType +
            ' ' +
            getState().auth.token.accessToken,
        },
        params: {
          id,
          isActiveOnly: true,
        },
      },
    },
  })

export const exportStatisticAlertMistakesUnit =
  (filter = {}) =>
  (dispatch, getState) => {
    let params = {}
    params = Object.assign(params, Tool.Utils.linkObjectKey(filter))

    return dispatch({
      types: [
        types.API_REQUEST_SEND,
        types.EXPORT_STATISTIC_ALERT_MISTAKE,
        types.API_REQUEST_ERROR,
      ],
      payload: {
        client: 'download',
        request: {
          url: `/api/tonghop/baonhamdonvi/in`,
          method: 'GET',
          headers: {
            Authorization:
              getState().auth.token.tokenType +
              ' ' +
              getState().auth.token.accessToken,
          },
          params: _.pickBy(params),
        },
      },
    })
  }

export const exportStatisticAlertMistakesDepartment =
  (filter = {}) =>
  (dispatch, getState) => {
    let params = {}
    params = Object.assign(params, Tool.Utils.linkObjectKey(filter))

    return dispatch({
      types: [
        types.API_REQUEST_SEND,
        types.EXPORT_STATISTIC_ALERT_MISTAKE,
        types.API_REQUEST_ERROR,
      ],
      payload: {
        client: 'download',
        request: {
          url: `/api/tonghop/baonhamphongban/in`,
          method: 'GET',
          headers: {
            Authorization:
              getState().auth.token.tokenType +
              ' ' +
              getState().auth.token.accessToken,
          },
          params: _.pickBy(params),
        },
      },
    })
  }

export const exportStatisticAlertMistakesPersonal =
  (filter = {}) =>
  (dispatch, getState) => {
    let params = {}
    params = Object.assign(params, Tool.Utils.linkObjectKey(filter))

    return dispatch({
      types: [
        types.API_REQUEST_SEND,
        types.EXPORT_STATISTIC_ALERT_MISTAKE,
        types.API_REQUEST_ERROR,
      ],
      payload: {
        client: 'download',
        request: {
          url: `/api/tonghop/baonhamcanhanphongban/in`,
          method: 'GET',
          headers: {
            Authorization:
              getState().auth.token.tokenType +
              ' ' +
              getState().auth.token.accessToken,
          },
          params: _.pickBy(params),
        },
      },
    })
  }

export const releasedSignNumberGet =
  (refresh = false) =>
  (dispatch, getState) =>
    dispatch({
      types: [
        types.API_REQUEST_SEND,
        types.SIGN_NUMBER_RELEASED_SEND,
        types.API_REQUEST_ERROR,
      ],
      schema: SignNumberSchema,
      payload: {
        request: {
          url: '/api/kysodi/vanbandacapso',
          method: 'GET',
          headers: {
            Authorization:
              getState().auth.token.tokenType +
              ' ' +
              getState().auth.token.accessToken,
          },
          params: {
            refresh,
          },
        },
      },
    })

export const exportStatisticExpireWork =
  (filter = {}, type) =>
  (dispatch, getState) => {
    let params = {}
    params = Object.assign(params, Tool.Utils.linkObjectKey(filter))

    return dispatch({
      types: [
        types.API_REQUEST_SEND,
        types.EXPORT_STATISTIC_EXPIRE_WORK,
        types.API_REQUEST_ERROR,
      ],
      payload: {
        client: 'download',
        request: {
          url: `/api/tonghop/nhanvien/${type}/in`,
          method: 'GET',
          headers: {
            Authorization:
              getState().auth.token.tokenType +
              ' ' +
              getState().auth.token.accessToken,
          },
          params: _.pickBy(params),
        },
      },
    })
  }

export const getPathDocumentCabinet = data => (dispatch, getState) => {
  let params = {}
  params = Object.assign(params, Tool.Utils.linkObjectKey(data))

  return dispatch({
    types: [
      types.API_REQUEST_SEND,
      types.GET_PATH_DOCUMENTCABINET,
      types.API_REQUEST_ERROR,
    ],
    payload: {
      request: {
        url: `/api/tutailieu/duongdan`,
        method: 'GET',
        headers: {
          'api-version': 2.0,
          Authorization:
            getState().auth.token.tokenType +
            ' ' +
            getState().auth.token.accessToken,
        },
        params: _.pickBy(params),
      },
    },
  })
}
export const commonFetchElectricUnits = id => (dispatch, getState) =>
  dispatch({
    types: [
      types.API_REQUEST_SEND,
      types.COMMON_FETCH_ELECTRIC_UNITS,
      types.API_REQUEST_ERROR,
    ],
    payload: {
      request: {
        url: `/api/donvi/${id}/dienluckyphoihop`,
        method: 'GET',
        headers: {
          Authorization:
            getState().auth.token.tokenType +
            ' ' +
            getState().auth.token.accessToken,
        },
      },
    },
  })

export const receiveDocumentSendAssignmentv2 = data => (dispatch, getState) =>
  dispatch({
    types: [
      types.API_REQUEST_SEND,
      types.DOCUMENT_RECEIVE_SEND_ASSIGNMENT,
      types.API_REQUEST_ERROR,
    ],
    payload: {
      request: {
        url: `/api/congvanden/chidao`,
        method: 'POST',
        headers: {
          'api-version': 2.0,
          Authorization:
            getState().auth.token.tokenType +
            ' ' +
            getState().auth.token.accessToken,
        },
        data: data,
      },
    },
  })

export const getDepartmentUnit = (id, isAll) => (dispatch, getState) =>
  dispatch({
    types: [
      types.API_REQUEST_SEND,
      types.GET_DEPARTMENT_UNIT,
      types.API_REQUEST_ERROR,
    ],
    payload: {
      request: {
        url: `/api/donvi/${id}/phongbanlap`,
        method: 'GET',
        headers: {
          'api-version': 1.0,
          Authorization:
            getState().auth.token.tokenType +
            ' ' +
            getState().auth.token.accessToken,
        },
        params: {
          isAll,
        },
      },
    },
  })

export const getDepartment = id => (dispatch, getState) =>
  dispatch({
    types: [
      types.API_REQUEST_SEND,
      types.GET_DEPARTMENT_UNIT,
      types.API_REQUEST_ERROR,
    ],
    payload: {
      request: {
        url: `/api/donvi/${id}/dsphongban`,
        method: 'GET',
        headers: {
          'api-version': 1.0,
          Authorization:
            getState().auth.token.tokenType +
            ' ' +
            getState().auth.token.accessToken,
        },
      },
    },
  })

export const moveUser = data => (dispatch, getState) =>
  dispatch({
    types: [types.API_REQUEST_SEND, types.MOVE_USER, types.API_REQUEST_ERROR],
    payload: {
      request: {
        url: '/api/nhanvien/dichuyen',
        method: 'POST',
        headers: {
          Authorization:
            getState().auth.token.tokenType +
            ' ' +
            getState().auth.token.accessToken,
          'api-version': '1.0',
        },
        data: data,
      },
    },
  })

export const searchFileCongVan = trichYeu => (dispatch, getState) =>
  dispatch({
    types: [
      types.API_REQUEST_SEND,
      types.SEARCH_FILE_CONG_VAN,
      types.API_REQUEST_ERROR,
    ],
    payload: {
      request: {
        url: '/api/congvan/file',
        method: 'GET',
        headers: {
          Authorization:
            getState().auth.token.tokenType +
            ' ' +
            getState().auth.token.accessToken,
          'api-version': '1.0',
        },
        params: {
          trich_yeu: trichYeu,
        },
      },
    },
  })

export const getDsFileDinhKem = idKySoDi => (dispatch, getState) =>
  dispatch({
    types: [
      types.API_REQUEST_SEND,
      types.GET_ATTACH_FILE_FROM_SIGN_NUMBER,
      types.API_REQUEST_ERROR,
    ],
    payload: {
      request: {
        url: `/api/kysodi/${idKySoDi}/filedinhkem`,
        method: 'GET',
        headers: {
          Authorization:
            getState().auth.token.tokenType +
            ' ' +
            getState().auth.token.accessToken,
          'api-version': '1.0',
        },
      },
    },
  })

// Get status dots list chuc danh
export const getStatusDotsChucDanh = nhanVienId => (dispatch, getState) =>
  dispatch({
    types: [
      types.API_REQUEST_SEND,
      types.GET_DOTS_WITH_CHUCDANH,
      types.API_REQUEST_ERROR,
    ],
    payload: {
      request: {
        url: `/api/thongke/canxuly/thongbao`,
        method: 'GET',
        headers: {
          Authorization:
            getState().auth.token.tokenType +
            ' ' +
            getState().auth.token.accessToken,
          'api-version': '1.0',
        },
        params: nhanVienId,
      },
    },
  })

export const setDocumentCabinetPath = path => ({
  type: types.SET_DOCUMENT_CABINET_PATH,
  path,
})

// lay y kien don vi tạo
export const requestCommentCreate =
  (data = {}) =>
  (dispatch, getState) =>
    dispatch({
      types: [
        types.API_REQUEST_SEND,
        types.REQUEST_COMMENT_CREATE,
        types.API_REQUEST_ERROR,
      ],
      payload: {
        request: {
          url: `/api/phieuguiyeucau`,
          method: 'POST',
          headers: {
            Authorization:
              getState().auth.token.tokenType +
              ' ' +
              getState().auth.token.accessToken,
          },
          data: data,
        },
      },
    })

// lay y kien don vi chinh sua
export const requestCommentUpdate =
  (id, data = {}) =>
  (dispatch, getState) =>
    dispatch({
      types: [
        types.API_REQUEST_SEND,
        types.REQUEST_COMMENT_CREATE,
        types.API_REQUEST_ERROR,
      ],
      payload: {
        request: {
          url: `/api/phieuguiyeucau/${id}`,
          method: 'PUT',
          headers: {
            Authorization:
              getState().auth.token.tokenType +
              ' ' +
              getState().auth.token.accessToken,
          },
          data: data,
        },
      },
    })

// lay y kien don vi get danh sach
export const getDsYeuCauGopY =
  (
    filter = {},
    page = 1,
    sort,
    canXuLy = true,
    refresh = false,
    filterColumn = {}
  ) =>
  (dispatch, getState) => {
    let params = {
      canXuLy: canXuLy,
      refresh,
      sort,
      page: page,
      limit: utils.PAGINATION_LIMIT,
    }

    params = Object.assign(params, Tool.Utils.linkObjectKey(filter))
    params = Object.assign(Tool.Utils.linkObjectKey({ filterColumn }), params)
    return dispatch({
      types: [
        types.API_REQUEST_SEND,
        types.REQUEST_COMMENTS,
        types.API_REQUEST_ERROR,
      ],
      schema: RequestCommentSchema,
      page: page,
      payload: {
        request: {
          url: '/api/phieuguiyeucau',
          method: 'GET',
          headers: {
            'api-version': 1.0,
            Authorization:
              getState().auth.token.tokenType +
              ' ' +
              getState().auth.token.accessToken,
          },
          params,
        },
      },
    })
  }

// lay y kien don vi tong hop
export const tongHopGopY =
  (id, page = 1, sort, refresh = false) =>
  (dispatch, getState) => {
    let params = {
      refresh,
      sort,
      page: page,
      limit: utils.PAGINATION_LIMIT,
    }

    return dispatch({
      types: [
        types.API_REQUEST_SEND,
        types.REQUEST_COMMENT_SUMMARY,
        types.API_REQUEST_ERROR,
      ],
      schema: RequestCommentSchema,
      page: page,
      payload: {
        request: {
          url: `/api/phieuguiyeucau/${id}/gopy`,
          method: 'GET',
          headers: {
            'api-version': 1.0,
            Authorization:
              getState().auth.token.tokenType +
              ' ' +
              getState().auth.token.accessToken,
          },
          params,
        },
      },
    })
  }

//lay y kien don vi thong ke
export const thongKeGopY = id => (dispatch, getState) =>
  dispatch({
    types: [
      types.API_REQUEST_SEND,
      types.REQUEST_COMMENT_STATISTICS,
      types.API_REQUEST_ERROR,
    ],
    payload: {
      request: {
        url: `/api/phieuguiyeucau/${id}/thongke`,
        method: 'GET',
        headers: {
          Authorization:
            getState().auth.token.tokenType +
            ' ' +
            getState().auth.token.accessToken,
        },
      },
    },
  })

//xóa phiếu gửi yêu cầu
export const requestCommentDelete = (id, data) => (dispatch, getState) =>
  dispatch({
    types: [
      types.API_REQUEST_SEND,
      types.REQUEST_COMMENT_DELETE,
      types.API_REQUEST_ERROR,
    ],
    payload: {
      request: {
        url: `/api/phieuguiyeucau/${id}?ly_do_xoa=${data}`,
        method: 'DELETE',
        headers: {
          Authorization:
            getState().auth.token.tokenType +
            ' ' +
            getState().auth.token.accessToken,
        },
      },
    },
  })

//detail phiếu gửi yêu cầu
export const requestCommentDetail = id => (dispatch, getState) =>
  dispatch({
    types: [
      types.API_REQUEST_SEND,
      types.REQUEST_COMMENT_DETAIL,
      types.API_REQUEST_ERROR,
    ],
    schema: RequestCommentItemSchema,
    payload: {
      request: {
        url: `/api/phieuguiyeucau/${id}`,
        method: 'GET',
        headers: {
          Authorization:
            getState().auth.token.tokenType +
            ' ' +
            getState().auth.token.accessToken,
        },
      },
    },
  })

//duyệt phiếu gửi yêu cầu
export const requestCommentDuyet = (id, data) => (dispatch, getState) =>
  dispatch({
    types: [
      types.API_REQUEST_SEND,
      types.REQUEST_COMMENT_DUYET,
      types.API_REQUEST_ERROR,
    ],
    payload: {
      request: {
        url: `/api/phieuguiyeucau/${id}/duyet`,
        method: 'POST',
        headers: {
          Authorization:
            getState().auth.token.tokenType +
            ' ' +
            getState().auth.token.accessToken,
        },
        data,
      },
    },
  })

//hoàn tất phiếu gửi yêu cầu
export const requestCommentHoanThanh = (id, data) => (dispatch, getState) =>
  dispatch({
    types: [
      types.API_REQUEST_SEND,
      types.REQUEST_COMMENT_DUYET,
      types.API_REQUEST_ERROR,
    ],
    payload: {
      request: {
        url: `/api/phieuguiyeucau/${id}/hoantat`,
        method: 'POST',
        headers: {
          Authorization:
            getState().auth.token.tokenType +
            ' ' +
            getState().auth.token.accessToken,
        },
        data,
      },
    },
  })

// lay y kien don vi get danh sách góp ý
export const getDsGopY =
  (
    filter = {},
    page = 1,
    sort,
    canXuLy = true,
    refresh = false,
    filterColumn = {}
  ) =>
  (dispatch, getState) => {
    let params = {
      canXuLy: canXuLy,
      refresh,
      sort,
      page: page,
      limit: utils.PAGINATION_LIMIT,
    }

    params = Object.assign(params, Tool.Utils.linkObjectKey(filter))
    params = Object.assign(Tool.Utils.linkObjectKey({ filterColumn }), params)
    return dispatch({
      types: [
        types.API_REQUEST_SEND,
        types.REQUEST_COMMENTS,
        types.API_REQUEST_ERROR,
      ],
      schema: RequestCommentSchema,
      page: page,
      payload: {
        request: {
          url: '/api/phieunhanyeucau',
          method: 'GET',
          headers: {
            'api-version': 1.0,
            Authorization:
              getState().auth.token.tokenType +
              ' ' +
              getState().auth.token.accessToken,
          },
          params,
        },
      },
    })
  }

//detail phiếu nhận yêu cầu
export const receiveCommentDetail = id => (dispatch, getState) =>
  dispatch({
    types: [
      types.API_REQUEST_SEND,
      types.REQUEST_COMMENT_DETAIL,
      types.API_REQUEST_ERROR,
    ],
    schema: RequestCommentItemSchema,
    payload: {
      request: {
        url: `/api/phieunhanyeucau/${id}`,
        method: 'GET',
        headers: {
          Authorization:
            getState().auth.token.tokenType +
            ' ' +
            getState().auth.token.accessToken,
        },
      },
    },
  })

//export excel góp ý đơn vị
export const exportGopYDonVi = id => (dispatch, getState) => {
  return dispatch({
    types: [
      types.API_REQUEST_SEND,
      types.EXPORT_REQUEST_COMMENT_SUMMARY,
      types.API_REQUEST_ERROR,
    ],
    payload: {
      client: 'download',
      request: {
        url: `/api/phieuguiyeucau/${id}/gopy/excel`,
        method: 'GET',
        headers: {
          Authorization:
            getState().auth.token.tokenType +
            ' ' +
            getState().auth.token.accessToken,
        },
      },
    },
  })
}

// lay y kien don vi export excel
export const exportDsYeuCauGopY =
  (filter = {}, sort, canXuLy = true, refresh = false, filterColumn = {}) =>
  (dispatch, getState) => {
    let params = {
      canXuLy: canXuLy,
      refresh,
      sort,
    }

    params = Object.assign(params, Tool.Utils.linkObjectKey(filter))
    params = Object.assign(Tool.Utils.linkObjectKey({ filterColumn }), params)
    return dispatch({
      types: [
        types.API_REQUEST_SEND,
        types.EXPORT_REQUEST_COMMENTS,
        types.API_REQUEST_ERROR,
      ],
      payload: {
        client: 'download',
        request: {
          url: '/api/phieuguiyeucau/export/excel',
          method: 'GET',
          headers: {
            'api-version': 1.0,
            Authorization:
              getState().auth.token.tokenType +
              ' ' +
              getState().auth.token.accessToken,
          },
          params,
        },
      },
    })
  }

// lay y kien don vi get danh sách góp ý  export excel
export const exportDsNhanGopY =
  (filter = {}, sort, canXuLy = true, refresh = false, filterColumn = {}) =>
  (dispatch, getState) => {
    let params = {
      canXuLy: canXuLy,
      refresh,
      sort,
    }

    params = Object.assign(params, Tool.Utils.linkObjectKey(filter))
    params = Object.assign(Tool.Utils.linkObjectKey({ filterColumn }), params)
    return dispatch({
      types: [
        types.API_REQUEST_SEND,
        types.EXPORT_REQUEST_COMMENTS,
        types.API_REQUEST_ERROR,
      ],
      payload: {
        client: 'download',
        request: {
          url: '/api/phieunhanyeucau/export/excel',
          method: 'GET',
          headers: {
            'api-version': 1.0,
            Authorization:
              getState().auth.token.tokenType +
              ' ' +
              getState().auth.token.accessToken,
          },
          params,
        },
      },
    })
  }

// luồng góp ý đi
export const getFlowPhieuGoiYeuCauGopY = id => (dispatch, getState) =>
  dispatch({
    types: [
      types.API_REQUEST_SEND,
      types.FLOW_REQUEST_COMMENTS,
      types.API_REQUEST_ERROR,
    ],
    payload: {
      request: {
        url: `/api/phieuguiyeucau/${id}/luong`,
        method: 'GET',
        headers: {
          'api-version': 2.0,
          Authorization:
            getState().auth.token.tokenType +
            ' ' +
            getState().auth.token.accessToken,
        },
      },
    },
  })

// luồng góp ý thực hiện
export const getMoreReceivedComment = (id, loai) => (dispatch, getState) =>
  dispatch({
    types: [
      types.API_REQUEST_SEND,
      types.FLOW_RECEIVE_COMMENT,
      types.API_REQUEST_ERROR,
    ],
    payload: {
      request: {
        url: `/api/phieunhanyeucau/${id}/luong`,
        method: 'GET',
        headers: {
          Authorization:
            getState().auth.token.tokenType +
            ' ' +
            getState().auth.token.accessToken,
        },
        params: {
          loai: loai,
        },
      },
    },
  })

//chỉ đạo góp ý
export const chiDaoGopY = (id, data) => (dispatch, getState) =>
  dispatch({
    types: [
      types.API_REQUEST_SEND,
      types.RECEIVE_COMMENT_CHIDAO,
      types.API_REQUEST_ERROR,
    ],
    payload: {
      request: {
        url: `/api/phieunhanyeucau/${id}/chidao`,
        method: 'POST',
        headers: {
          Authorization:
            getState().auth.token.tokenType +
            ' ' +
            getState().auth.token.accessToken,
        },
        data: {
          y_kien: data.yKien,
          phong_ban_id: data.phongBanId,
        },
      },
    },
  })

// sửa chỉ đạo góp ý
export const suaChiDaoGopY = (id, data) => (dispatch, getState) =>
  dispatch({
    types: [
      types.API_REQUEST_SEND,
      types.RECEIVE_COMMENT_CHIDAO,
      types.API_REQUEST_ERROR,
    ],
    payload: {
      request: {
        url: `/api/phieunhanyeucau/${id}/chidao`,
        method: 'PUT',
        headers: {
          Authorization:
            getState().auth.token.tokenType +
            ' ' +
            getState().auth.token.accessToken,
        },
        data: {
          y_kien: data.yKien,
          phong_ban_id: data.phongBanId,
        },
      },
    },
  })

//giao viec
export const giaoViecGopY = (id, data) => (dispatch, getState) =>
  dispatch({
    types: [
      types.API_REQUEST_SEND,
      types.RECEIVE_COMMENT_GIAOVIEC,
      types.API_REQUEST_ERROR,
    ],
    payload: {
      request: {
        url: `/api/phieunhanyeucau/${id}/giaoviec`,
        method: 'POST',
        headers: {
          Authorization:
            getState().auth.token.tokenType +
            ' ' +
            getState().auth.token.accessToken,
        },
        data: {
          y_kien: data.yKien,
          ca_nhan_id: data.chucDanhId,
        },
      },
    },
  })

// sửa giao viec góp ý
export const suaGiaoViecGopY = (id, data) => (dispatch, getState) =>
  dispatch({
    types: [
      types.API_REQUEST_SEND,
      types.RECEIVE_COMMENT_GIAOVIEC,
      types.API_REQUEST_ERROR,
    ],
    payload: {
      request: {
        url: `/api/phieunhanyeucau/${id}/giaoviec`,
        method: 'PUT',
        headers: {
          Authorization:
            getState().auth.token.tokenType +
            ' ' +
            getState().auth.token.accessToken,
        },
        data: {
          y_kien: data.yKien,
          ca_nhan_id: data.chucDanhId,
        },
      },
    },
  })

//góp ý
export const thucHienGopY = (id, data) => (dispatch, getState) =>
  dispatch({
    types: [
      types.API_REQUEST_SEND,
      types.THUC_HIEN_GOP_Y,
      types.API_REQUEST_ERROR,
    ],
    payload: {
      request: {
        url: `/api/phieunhanyeucau/${id}/thuchien`,
        method: 'POST',
        headers: {
          Authorization:
            getState().auth.token.tokenType +
            ' ' +
            getState().auth.token.accessToken,
        },
        data: data,
      },
    },
  })

//chỉnh sửa góp ý
export const chinhSuaGopY = (id, data) => (dispatch, getState) =>
  dispatch({
    types: [
      types.API_REQUEST_SEND,
      types.THUC_HIEN_GOP_Y,
      types.API_REQUEST_ERROR,
    ],
    payload: {
      request: {
        url: `/api/phieunhanyeucau/${id}/thuchien`,
        method: 'PUT',
        headers: {
          Authorization:
            getState().auth.token.tokenType +
            ' ' +
            getState().auth.token.accessToken,
        },
        data: data,
      },
    },
  })

//duyệt phiếu nhận yêu cầu
export const requestCommentDuyetPhieuNhan =
  (id, data) => (dispatch, getState) =>
    dispatch({
      types: [
        types.API_REQUEST_SEND,
        types.REQUEST_COMMENT_DUYET,
        types.API_REQUEST_ERROR,
      ],
      payload: {
        request: {
          url: `/api/phieunhanyeucau/${id}/xacnhan`,
          method: 'POST',
          headers: {
            Authorization:
              getState().auth.token.tokenType +
              ' ' +
              getState().auth.token.accessToken,
          },
          data,
        },
      },
    })

//hủy duyệt phiếu yêu cầu góp ý
export const requestCommentHuyDuyet = id => (dispatch, getState) =>
  dispatch({
    types: [
      types.API_REQUEST_SEND,
      types.CANCEL_REQUEST_COMMENT_APPROVE,
      types.API_REQUEST_ERROR,
    ],
    payload: {
      request: {
        url: `/api/phieuguiyeucau/${id}/duyet`,
        method: 'DELETE',
        headers: {
          Authorization:
            getState().auth.token.tokenType +
            ' ' +
            getState().auth.token.accessToken,
        },
      },
    },
  })

//hủy xác nhận phiếu góp ý
export const receivedCommentHuyXacNhan = id => (dispatch, getState) =>
  dispatch({
    types: [
      types.API_REQUEST_SEND,
      types.CANCEL_REQUEST_COMMENT_APPROVE,
      types.API_REQUEST_ERROR,
    ],
    payload: {
      request: {
        url: `/api/phieunhanyeucau/${id}/xacnhan`,
        method: 'DELETE',
        headers: {
          Authorization:
            getState().auth.token.tokenType +
            ' ' +
            getState().auth.token.accessToken,
        },
      },
    },
  })

//hủy chỉ đạo góp ý
export const receivedCommentHuyChiDao = id => (dispatch, getState) =>
  dispatch({
    types: [
      types.API_REQUEST_SEND,
      types.CANCEL_REQUEST_COMMENT_APPROVE,
      types.API_REQUEST_ERROR,
    ],
    payload: {
      request: {
        url: `/api/phieunhanyeucau/${id}/chidao`,
        method: 'DELETE',
        headers: {
          Authorization:
            getState().auth.token.tokenType +
            ' ' +
            getState().auth.token.accessToken,
        },
      },
    },
  })

//hủy chỉ giao góp ý
export const receivedCommentHuyGiaoGopY = id => (dispatch, getState) =>
  dispatch({
    types: [
      types.API_REQUEST_SEND,
      types.CANCEL_REQUEST_COMMENT_APPROVE,
      types.API_REQUEST_ERROR,
    ],
    payload: {
      request: {
        url: `/api/phieunhanyeucau/${id}/giaoviec`,
        method: 'DELETE',
        headers: {
          Authorization:
            getState().auth.token.tokenType +
            ' ' +
            getState().auth.token.accessToken,
        },
      },
    },
  })

//hủy chỉ  góp ý
export const receivedCommentHuyGopY = id => (dispatch, getState) =>
  dispatch({
    types: [
      types.API_REQUEST_SEND,
      types.CANCEL_REQUEST_COMMENT_APPROVE,
      types.API_REQUEST_ERROR,
    ],
    payload: {
      request: {
        url: `/api/phieunhanyeucau/${id}/thuchien`,
        method: 'DELETE',
        headers: {
          Authorization:
            getState().auth.token.tokenType +
            ' ' +
            getState().auth.token.accessToken,
        },
      },
    },
  })

export const opinionSendGet =
  (
    filter = {},
    page = 1,
    sort = null,
    canXuLy = true,
    limit = utils.PAGINATION_LIMIT,
    filterColumn
  ) =>
  (dispatch, getState) => {
    let params = {
      sort,
      page: page,
      limit: limit,
      canXuLy: canXuLy,
    }
    params = Object.assign(params, Tool.Utils.linkObjectKey(filter))
    params = Object.assign(
      Tool.Utils.linkObjectKey({ filter_column: filterColumn }),
      params
    )
    return dispatch({
      types: [
        types.API_REQUEST_OPINION,
        types.OPINION_SEND,
        types.API_REQUEST_ERROR,
      ],
      page: page,
      payload: {
        request: {
          url: `/api/phieulayykien`,
          method: 'GET',
          headers: {
            Authorization:
              getState().auth.token.tokenType +
              ' ' +
              getState().auth.token.accessToken,
            'api-version': 1.0,
          },
          params,
        },
      },
    })
  }

export const opinionDetail = id => (dispatch, getState) =>
  dispatch({
    types: [
      types.API_REQUEST_SEND,
      types.OPINION_DETAIL,
      types.API_REQUEST_ERROR,
    ],
    payload: {
      request: {
        url: `/api/phieulayykien/${id}`,
        method: 'GET',
        headers: {
          Authorization:
            getState().auth.token.tokenType +
            ' ' +
            getState().auth.token.accessToken,
        },
      },
    },
  })

export const deleteOpinion = data => (dispatch, getState) =>
  dispatch({
    types: [types.API_REQUEST_SEND, types.API_REQUEST_ERROR],
    payload: {
      request: {
        url: `/api/phieulayykien/${data.id}`,
        method: 'DELETE',
        headers: {
          Authorization:
            getState().auth.token.tokenType +
            ' ' +
            getState().auth.token.accessToken,
        },
        ly_do_xoa: data.lyDoXoa,
      },
    },
  })

export const getLanhDaoPhongBan = (id, params) => (dispatch, getState) =>
  dispatch({
    types: [
      types.API_REQUEST_SEND,
      types.API_REQUEST_SUCCESS,
      types.API_REQUEST_ERROR,
    ],
    payload: {
      request: {
        url: `/api/phongban/${id}/nhanvien/phanquyen`,
        method: 'GET',
        headers: {
          Authorization:
            getState().auth.token.tokenType +
            ' ' +
            getState().auth.token.accessToken,
        },
        params,
      },
    },
  })

export const getHoiDongThanhVien = (id, params) => (dispatch, getState) =>
  dispatch({
    types: [
      types.API_REQUEST_SEND,
      types.API_REQUEST_SUCCESS,
      types.API_REQUEST_ERROR,
    ],
    payload: {
      request: {
        url: `/api/donvi/${id}/nhanvien/phanquyen`,
        method: 'GET',
        headers: {
          Authorization:
            getState().auth.token.tokenType +
            ' ' +
            getState().auth.token.accessToken,
        },
        params,
      },
    },
  })

export const postPhieuLayYKien = data => (dispatch, getState) =>
  dispatch({
    types: [
      types.API_REQUEST_SEND,
      types.API_REQUEST_SUCCESS,
      types.API_REQUEST_ERROR,
    ],
    payload: {
      request: {
        url: `/api/phieulayykien`,
        method: 'POST',
        headers: {
          Authorization:
            getState().auth.token.tokenType +
            ' ' +
            getState().auth.token.accessToken,
        },
        data,
      },
    },
  })

export const updatePhieuLayYKien =
  ({ id, ...data }) =>
  (dispatch, getState) =>
    dispatch({
      types: [
        types.API_REQUEST_SEND,
        types.API_REQUEST_SUCCESS,
        types.API_REQUEST_ERROR,
      ],
      payload: {
        request: {
          url: `/api/phieulayykien/${id}`,
          method: 'PUT',
          headers: {
            Authorization:
              getState().auth.token.tokenType +
              ' ' +
              getState().auth.token.accessToken,
          },
          data,
        },
      },
    })

export const postLDPBDongY = (id, data) => (dispatch, getState) =>
  dispatch({
    types: [
      types.API_REQUEST_SEND,
      types.API_REQUEST_SUCCESS,
      types.API_REQUEST_ERROR,
    ],
    payload: {
      request: {
        url: `/api/phieulayykien/${id}/ldpbduyet`,
        method: 'PATCH',
        headers: {
          Authorization:
            getState().auth.token.tokenType +
            ' ' +
            getState().auth.token.accessToken,
          'api-version': 1.0,
        },
        data,
      },
    },
  })

export const postLDPBKhongDongY = (id, data) => (dispatch, getState) =>
  dispatch({
    types: [
      types.API_REQUEST_SEND,
      types.API_REQUEST_SUCCESS,
      types.API_REQUEST_ERROR,
    ],
    payload: {
      request: {
        url: `/api/phieulayykien/${id}/ldpbkhongduyet`,
        method: 'PATCH',
        headers: {
          Authorization:
            getState().auth.token.tokenType +
            ' ' +
            getState().auth.token.accessToken,
          'api-version': 1.0,
        },
        data,
      },
    },
  })

export const postKySim = (id, data, params) => (dispatch, getState) =>
  dispatch({
    types: [
      types.API_REQUEST_SEND,
      types.API_REQUEST_SUCCESS,
      types.API_REQUEST_ERROR,
    ],
    payload: {
      request: {
        url: `/api/phieulayykien/${id}/kysim`,
        method: 'PATCH',
        headers: {
          Authorization:
            getState().auth.token.tokenType +
            ' ' +
            getState().auth.token.accessToken,
          'api-version': 1.0,
        },
        params,
        data,
      },
    },
  })

export const getNumberPhieuYKien = () => (dispatch, getState) =>
  dispatch({
    types: [
      types.API_REQUEST_SEND,
      types.GET_NUMBER_PHIEU_Y_KIEN,
      types.API_REQUEST_ERROR,
    ],
    payload: {
      request: {
        url: '/api/phieulayykien/count',
        method: 'GET',
        headers: {
          'api-version': 1.0,
          Authorization:
            getState().auth.token.tokenType +
            ' ' +
            getState().auth.token.accessToken,
        },
      },
    },
  })

export const postGopYHDTVLDPB = (id, data) => (dispatch, getState) =>
  dispatch({
    types: [
      types.API_REQUEST_SEND,
      types.API_REQUEST_SUCCESS,
      types.API_REQUEST_ERROR,
    ],
    payload: {
      request: {
        url: `/api/phieulayykien/${id}/ldpbgopy`,
        method: 'POST',
        headers: {
          'api-version': 1.0,
          Authorization:
            getState().auth.token.tokenType +
            ' ' +
            getState().auth.token.accessToken,
        },
        data,
      },
    },
  })

export const postYKienHDTV = (id, data) => (dispatch, getState) =>
  dispatch({
    types: [
      types.API_REQUEST_SEND,
      types.API_REQUEST_SUCCESS,
      types.API_REQUEST_ERROR,
    ],
    payload: {
      request: {
        url: `/api/phieulayykien/${id}/ykien`,
        method: 'POST',
        headers: {
          'api-version': 1.0,
          Authorization:
            getState().auth.token.tokenType +
            ' ' +
            getState().auth.token.accessToken,
        },
        data,
      },
    },
  })

export const serviceDocumentGetAPI =
  (filter = {}, page = 1, sort, refresh = false, filterColumn = {}) =>
  (dispatch, getState) => {
    let params = {
      refresh,
      sort,
      page: page,
      limit: utils.PAGINATION_LIMIT,
    }
    params = Object.assign(params, Tool.Utils.linkObjectKey(filter))
    params = Object.assign(Tool.Utils.linkObjectKey({ filterColumn }), params)
    return dispatch({
      types: [
        types.API_REQUEST_SEND,
        types.SERVICE_DOCUMENTS,
        types.API_REQUEST_ERROR,
      ],
      page: page,
      payload: {
        request: {
          url: '/api/service/search',
          method: 'GET',
          headers: {
            'api-version': 1.0,
            Authorization:
              getState().auth.token.tokenType +
              ' ' +
              getState().auth.token.accessToken,
          },
          params,
        },
      },
    })
  }

export const serviceDeleteAPI = id => (dispatch, getState) =>
  dispatch({
    types: [
      types.API_REQUEST_SEND,
      types.API_REQUEST_SUCCESS,
      types.API_REQUEST_ERROR,
    ],
    payload: {
      request: {
        url: `/api/service/${id}`,
        method: 'DELETE',
        headers: {
          Authorization:
            getState().auth.token.tokenType +
            ' ' +
            getState().auth.token.accessToken,
        },
      },
    },
  })

export const serviceAddAPI = data => (dispatch, getState) =>
  dispatch({
    types: [
      types.API_REQUEST_SEND,
      types.API_REQUEST_SUCCESS,
      types.API_REQUEST_ERROR,
    ],
    payload: {
      request: {
        url: `/api/service`,
        method: 'POST',
        headers: {
          Authorization:
            getState().auth.token.tokenType +
            ' ' +
            getState().auth.token.accessToken,
        },
        data,
      },
    },
  })

export const serviceDetailAPI = id => (dispatch, getState) =>
  dispatch({
    types: [
      types.API_REQUEST_SEND,
      types.SERVICE_DOCUMENTS_DETAIL,
      types.API_REQUEST_ERROR,
    ],
    payload: {
      request: {
        url: `/api/service/${id}`,
        method: 'GET',
        headers: {
          'api-version': 1.0,
          Authorization:
            getState().auth.token.tokenType +
            ' ' +
            getState().auth.token.accessToken,
        },
      },
    },
  })

export const serviceUpdateAPI = data => (dispatch, getState) =>
  dispatch({
    types: [
      types.API_REQUEST_SEND,
      types.API_REQUEST_SUCCESS,
      types.API_REQUEST_ERROR,
    ],
    payload: {
      request: {
        url: '/api/service',
        method: 'PUT',
        headers: {
          'api-version': 1.0,
          Authorization:
            getState().auth.token.tokenType +
            ' ' +
            getState().auth.token.accessToken,
        },
        data,
      },
    },
  })

export const chatSearchMessages =
  (filter = {}, page) =>
  (dispatch, getState) => {
    let params = {
      page: page,
      limit: utils.PAGINATION_LIMIT,
    }
    params = Object.assign(params, filter)
    return dispatch({
      types: [types.API_REQUEST_SEND, types.CHAT_GET, types.API_REQUEST_ERROR],
      page: page,
      payload: {
        client: 'profile',
        request: {
          url: '/api/tinnhan/timkiem',
          method: 'GET',
          headers: {
            Authorization:
              getState().auth.token.tokenType +
              ' ' +
              getState().auth.token.accessToken,
          },
          params,
        },
      },
    })
  }

export const chatSearchMessages2 =
  (serviceId, filter = {}, page) =>
  (dispatch, getState) => {
    let params = {
      page: page,
      limit: utils.PAGINATION_LIMIT,
    }
    params = Object.assign(params, Tool.Utils.linkObjectKey(filter))
    return dispatch({
      types: [types.API_REQUEST_SEND, types.CHAT_GET, types.API_REQUEST_ERROR],
      page: page,
      payload: {
        client: 'profile',
        request: {
          url: `/api/tinnhan/thongke/${serviceId}`,
          method: 'GET',
          headers: {
            Authorization:
              getState().auth.token.tokenType +
              ' ' +
              getState().auth.token.accessToken,
          },
          params,
        },
      },
    })
  }

export const exportThongKeService =
  (filter = {}, page = 1, sort = null) =>
  (dispatch, getState) => {
    let params = {
      sort,
      page: page,
      limit: utils.PAGINATION_LIMIT,
    }

    params = Object.assign(params, Tool.Utils.linkObjectKey(filter))

    return dispatch({
      types: [
        types.API_REQUEST_SEND,
        types.EXPORT_GENERAL_WORK,
        types.API_REQUEST_ERROR,
      ],
      page: page,
      payload: {
        client: 'download',
        request: {
          url: `/api/service/thongke/excel`,
          method: 'GET',
          headers: {
            Authorization:
              getState().auth.token.tokenType +
              ' ' +
              getState().auth.token.accessToken,
          },
          params: _.pickBy(params),
        },
      },
    })
  }

export const thongKeServiceAPI =
  (filter = {}, page = 1, sort, refresh = false, filterColumn = {}) =>
  (dispatch, getState) => {
    filter = filter && filter.filter

    let params = {
      refresh,
      sort,
      page: page,
      limit: utils.PAGINATION_LIMIT,
      start_date:
        filter.ngayTao && filter.ngayTao.from ? filter.ngayTao.from : null,
      end_date: filter.ngayTao && filter.ngayTao.to ? filter.ngayTao.to : null,
      text_search: filter.textSearch || null,
    }
    return dispatch({
      types: [
        types.API_REQUEST_SEND,
        types.STATISTICAL_SERVICE_DOCUMENTS,
        types.API_REQUEST_ERROR,
      ],
      page: page,
      payload: {
        request: {
          url: '/api/service/thongke',
          method: 'GET',
          headers: {
            'api-version': 1.0,
            Authorization:
              getState().auth.token.tokenType +
              ' ' +
              getState().auth.token.accessToken,
          },
          params,
        },
      },
    })
  }

// Tìm kiếm công văn 6.1
export const timKiemCongVan61 =
  (filter = {}, page = 1, sort = null, filterColumn = {}) =>
  (dispatch, getState) => {
    let params = {
      sort,
      page: page,
      limit: utils.PAGINATION_LIMIT,
    }
    params = Object.assign(Tool.Utils.linkObjectKey(filter), params)
    params = Object.assign(
      Tool.Utils.linkObjectKey({ filter_column: filterColumn }),
      params
    )
    return dispatch({
      types: [
        types.API_REQUEST_SEND,
        types.DANH_SACH_CONG_VAN_61,
        types.API_REQUEST_ERROR,
      ],
      page: page,
      payload: {
        request: {
          url: '/api/tienich/congvan6_1',
          method: 'GET',
          headers: {
            'api-version': 1.0,
            Authorization:
              getState().auth.token.tokenType +
              ' ' +
              getState().auth.token.accessToken,
          },
          params,
        },
      },
    })
  }

// POST đề xuất thu hồi công văn đi
export const postDeXuatThuHoiCongVanDi = data => (dispatch, getState) =>
  dispatch({
    types: [
      types.API_REQUEST_SEND,
      types.API_REQUEST_SUCCESS,
      types.API_REQUEST_ERROR,
    ],
    payload: {
      request: {
        url: '/api/dexuatthuhoicongvandi',
        method: 'POST',
        headers: {
          Authorization:
            getState().auth.token.tokenType +
            ' ' +
            getState().auth.token.accessToken,
          'api-version': '1.0',
        },
        data,
      },
    },
  })

// GET kiểm tra đề xuất thu hồi
export const checkCongVanDaDeXuatThuHoi = data => (dispatch, getState) =>
  dispatch({
    types: [
      types.API_REQUEST_SEND,
      types.API_REQUEST_SUCCESS,
      types.API_REQUEST_ERROR,
    ],
    payload: {
      request: {
        url: '/api/congvandithuhoi/checkdexuatthuhoi',
        method: 'GET',
        headers: {
          Authorization:
            getState().auth.token.tokenType +
            ' ' +
            getState().auth.token.accessToken,
          'api-version': '1.0',
        },
        params: data,
      },
    },
  })

// PUT chỉnh sửa công văn đi thu hồi
export const putDongYThuHoiCongVanDi = id => (dispatch, getState) =>
  dispatch({
    types: [
      types.API_REQUEST_SEND,
      types.API_REQUEST_SUCCESS,
      types.API_REQUEST_ERROR,
    ],
    payload: {
      request: {
        url: `/api/congvandithuhoi/${id}`,
        method: 'PUT',
        headers: {
          Authorization:
            getState().auth.token.tokenType +
            ' ' +
            getState().auth.token.accessToken,
          'api-version': '1.0',
        },
      },
    },
  })

// GET công văn đi thu hồi
export const revokeDocumentGet =
  (filter = {}, page = 1, sort, refresh = false, filterColumn = {}) =>
  (dispatch, getState) => {
    let params = {
      refresh,
      sort,
      page: page,
      limit: utils.PAGINATION_LIMIT,
    }
    params = Object.assign(params, Tool.Utils.linkObjectKey(filter))
    params = Object.assign(Tool.Utils.linkObjectKey({ filterColumn }), params)
    return dispatch({
      types: [
        types.API_REQUEST_SEND,
        types.REVOKE_DOCUMENTS,
        types.API_REQUEST_ERROR,
      ],
      page: page,
      payload: {
        request: {
          url: '/api/congvandithuhoi',
          method: 'GET',
          headers: {
            'api-version': 1.0,
            Authorization:
              getState().auth.token.tokenType +
              ' ' +
              getState().auth.token.accessToken,
          },
          params,
        },
      },
    })
  }

// GET công văn đi thu hồi xuất excel
export const getCongVanDiThuHoiXuatExcel =
  (filter = {}, page = 1, sort = null, filterColumn = {}) =>
  (dispatch, getState) => {
    let params = {
      sort,
      page: page,
      limit: utils.PAGINATION_LIMIT,
    }
    params = Object.assign(params, Tool.Utils.linkObjectKey(filter))
    params = Object.assign(Tool.Utils.linkObjectKey({ filterColumn }), params)
    return dispatch({
      types: [
        types.API_REQUEST_SEND,
        types.API_REQUEST_SUCCESS,
        types.API_REQUEST_ERROR,
      ],
      page: page,
      payload: {
        client: 'download',
        request: {
          url: '/api/congvandithuhoi/export',
          method: 'GET',
          headers: {
            'api-version': 1.0,
            Authorization:
              getState().auth.token.tokenType +
              ' ' +
              getState().auth.token.accessToken,
          },
          params,
        },
      },
    })
  }

// GET chi tiết công văn đi thu hồi
export const revokeDocumentDetail = id => (dispatch, getState) =>
  dispatch({
    types: [
      types.API_REQUEST_SEND,
      types.REVOKE_DOCUMENTS_DETAIL,
      types.API_REQUEST_ERROR,
    ],
    payload: {
      request: {
        url: `/api/congvandithuhoi/${id}`,
        method: 'GET',
        headers: {
          'api-version': 1.0,
          Authorization:
            getState().auth.token.tokenType +
            ' ' +
            getState().auth.token.accessToken,
        },
      },
    },
  })

// GET tổng số công văn thu hồi
export const getTongSoCongVanThuHoi = () => (dispatch, getState) =>
  dispatch({
    types: [
      types.API_REQUEST_SEND,
      types.GET_NUMBER_CONGVANTHUHOI,
      types.API_REQUEST_ERROR,
    ],
    payload: {
      request: {
        url: '/api/congvandithuhoi/tongsochoxuly',
        method: 'GET',
        headers: {
          'api-version': 1.0,
          Authorization:
            getState().auth.token.tokenType +
            ' ' +
            getState().auth.token.accessToken,
        },
      },
    },
  })

// Cập nhật hủy hoặc chỉnh sửa đề xuất thu hồi
export const putCapNhatDeXuatThuHoi = (params, data) => (dispatch, getState) =>
  dispatch({
    types: [types.API_REQUEST_SEND, types.API_REQUEST_ERROR],
    payload: {
      request: {
        url: `/api/congvandithuhoi/update`,
        method: 'PUT',
        headers: {
          'api-version': 1.0,
          Authorization:
            getState().auth.token.tokenType +
            ' ' +
            getState().auth.token.accessToken,
        },
        params,
        data,
      },
    },
  })

// GET danh sách công văn đến bị thu hồi
export const getListCongVanDenBiThuHoi =
  (filter = {}, page = 1, sort, refresh = false, filterColumn = {}) =>
  (dispatch, getState) => {
    let params = {
      refresh,
      sort,
      page: page,
      limit: utils.PAGINATION_LIMIT,
    }
    params = Object.assign(params, Tool.Utils.linkObjectKey(filter))
    params = Object.assign(Tool.Utils.linkObjectKey({ filterColumn }), params)
    return dispatch({
      types: [
        types.API_REQUEST_SEND,
        types.CONGVANDENTHUHOI_GET,
        types.API_REQUEST_ERROR,
      ],
      page: page,
      payload: {
        request: {
          url: '/api/congvandenthuhoi',
          method: 'GET',
          headers: {
            'api-version': 1.0,
            Authorization:
              getState().auth.token.tokenType +
              ' ' +
              getState().auth.token.accessToken,
          },
          params,
        },
      },
    })
  }

// Công văn thu hồi xuất excel
export const congVanDenThuHoiXuatExcel =
  (filter = {}, page = 1, sort = null, filterColumn) =>
  (dispatch, getState) => {
    let params = {
      sort,
      page: page,
      limit: utils.PAGINATION_LIMIT,
    }
    params = Object.assign(params, Tool.Utils.linkObjectKey(filter))
    params = Object.assign(Tool.Utils.linkObjectKey({ filterColumn }), params)
    return dispatch({
      types: [
        types.API_REQUEST_SEND,
        types.CONGVANDENTHUHOI_XUAT_EXCEL,
        types.API_REQUEST_ERROR,
      ],
      page: page,
      payload: {
        client: 'download',
        request: {
          url: '/api/congvandenthuhoi/export',
          method: 'GET',
          headers: {
            'api-version': 1.0,
            Authorization:
              getState().auth.token.tokenType +
              ' ' +
              getState().auth.token.accessToken,
          },
          params,
        },
      },
    })
  }

// Thời gian lưu
export const getThoiGianLuuDanhSach = params => (dispatch, getState) => {
  return dispatch({
    types: [
      types.API_REQUEST_SEND,
      types.GET_THOIGIANLUU_DANHSACH,
      types.API_REQUEST_ERROR,
    ],
    payload: {
      request: {
        url: '/api/danhmuc/thoihanluu',
        method: 'GET',
        headers: {
          'api-version': 1.0,
          Authorization:
            getState().auth.token.tokenType +
            ' ' +
            getState().auth.token.accessToken,
        },
        params,
      },
    },
  })
}

export const putThoiGianLuuSapXepDanhSach = data => (dispatch, getState) => {
  return dispatch({
    types: [
      types.API_REQUEST_SEND,
      types.API_REQUEST_SUCCESS,
      types.API_REQUEST_ERROR,
    ],
    payload: {
      request: {
        url: '/api/danhmuc/thoihanluu/sort',
        method: 'PUT',
        headers: {
          'api-version': 1.0,
          Authorization:
            getState().auth.token.tokenType +
            ' ' +
            getState().auth.token.accessToken,
        },
        data,
      },
    },
  })
}

export const deleteThoiGianLuu = id => (dispatch, getState) =>
  dispatch({
    types: [
      types.API_REQUEST_SEND,
      types.API_REQUEST_SUCCESS,
      types.API_REQUEST_ERROR,
    ],
    payload: {
      request: {
        url: `/api/danhmuc/thoihanluu/${id}`,
        method: 'DELETE',
        headers: {
          Authorization:
            getState().auth.token.tokenType +
            ' ' +
            getState().auth.token.accessToken,
        },
      },
    },
  })

export const putThoiGianLuuCapNhatTrangThai =
  (id, params) => (dispatch, getState) =>
    dispatch({
      types: [
        types.API_REQUEST_SEND,
        types.API_REQUEST_SUCCESS,
        types.API_REQUEST_ERROR,
      ],
      payload: {
        request: {
          url: `/api/danhmuc/thoihanluu/${id}/trangthai`,
          method: 'PUT',
          headers: {
            'api-version': 1.0,
            Authorization:
              getState().auth.token.tokenType +
              ' ' +
              getState().auth.token.accessToken,
          },
          params,
        },
      },
    })

export const getThoiGianLuuChiTiet = id => (dispatch, getState) =>
  dispatch({
    types: [
      types.API_REQUEST_SEND,
      types.API_REQUEST_SUCCESS,
      types.API_REQUEST_ERROR,
    ],
    payload: {
      request: {
        url: `/api/danhmuc/thoihanluu//${id}`,
        method: 'GET',
        headers: {
          'api-version': 1.0,
          Authorization:
            getState().auth.token.tokenType +
            ' ' +
            getState().auth.token.accessToken,
        },
      },
    },
  })

export const postThoiGianLuu = data => (dispatch, getState) =>
  dispatch({
    types: [
      types.API_REQUEST_SEND,
      types.API_REQUEST_SUCCESS,
      types.API_REQUEST_ERROR,
    ],
    payload: {
      request: {
        url: '/api/danhmuc/thoihanluu',
        method: 'POST',
        headers: {
          Authorization:
            getState().auth.token.tokenType +
            ' ' +
            getState().auth.token.accessToken,
        },
        data,
      },
    },
  })

export const putThoiGianLuu = data => (dispatch, getState) =>
  dispatch({
    types: [
      types.API_REQUEST_SEND,
      types.API_REQUEST_SUCCESS,
      types.API_REQUEST_ERROR,
    ],
    payload: {
      request: {
        url: '/api/danhmuc/thoihanluu',
        method: 'PUT',
        headers: {
          'api-version': 1.0,
          Authorization:
            getState().auth.token.tokenType +
            ' ' +
            getState().auth.token.accessToken,
        },
        data,
      },
    },
  })

// GET danh sách hồ sơ công việc
export const getDanhSachHoSoCongViec =
  (filter = {}, page = 1, sorter = null, filterColumn = {}) =>
  (dispatch, getState) => {
    let params = {
      sorter,
      page: page,
      limit: utils.PAGINATION_LIMIT,
    }
    params = Object.assign(Tool.Utils.linkObjectKey(filter), params)
    params = Object.assign(
      Tool.Utils.linkObjectKey({ filter_column: filterColumn }),
      params
    )
    return dispatch({
      types: [
        types.API_REQUEST_SEND,
        types.API_REQUEST_SUCCESS,
        types.API_REQUEST_ERROR,
      ],
      page: page,
      payload: {
        request: {
          url: '/api/hosocongviec',
          method: 'GET',
          headers: {
            'api-version': 1.0,
            Authorization:
              getState().auth.token.tokenType +
              ' ' +
              getState().auth.token.accessToken,
          },
          params,
        },
      },
    })
  }

// POST tạo thư mục hồ sơ công việc
export const postTaoThuMucHSCV = data => (dispatch, getState) =>
  dispatch({
    types: [
      types.API_REQUEST_SEND,
      types.API_REQUEST_SUCCESS,
      types.API_REQUEST_ERROR,
    ],
    payload: {
      request: {
        url: '/api/hosocongviec/thumuc',
        method: 'POST',
        headers: {
          Authorization:
            getState().auth.token.tokenType +
            ' ' +
            getState().auth.token.accessToken,
        },
        data,
      },
    },
  })

// DELETE xóa hồ sơ công việc
export const deleteHoSoCongViec = id => (dispatch, getState) =>
  dispatch({
    types: [
      types.API_REQUEST_SEND,
      types.API_REQUEST_SUCCESS,
      types.API_REQUEST_ERROR,
    ],
    payload: {
      request: {
        url: `/api/hosocongviec/${id}`,
        method: 'DELETE',
        headers: {
          Authorization:
            getState().auth.token.tokenType +
            ' ' +
            getState().auth.token.accessToken,
        },
      },
    },
  })

// POST thêm hồ sơ công việc
export const postTaoHSCV = data => (dispatch, getState) =>
  dispatch({
    types: [
      types.API_REQUEST_SEND,
      types.API_REQUEST_SUCCESS,
      types.API_REQUEST_ERROR,
    ],
    payload: {
      request: {
        url: '/api/hosocongviec',
        method: 'POST',
        headers: {
          Authorization:
            getState().auth.token.tokenType +
            ' ' +
            getState().auth.token.accessToken,
        },
        data,
      },
    },
  })

// GET chi tiết hồ sơ công việc
export const getChiTietHoSoCongViec = id => (dispatch, getState) =>
  dispatch({
    types: [
      types.API_REQUEST_SEND,
      types.API_REQUEST_SUCCESS,
      types.API_REQUEST_ERROR,
    ],
    payload: {
      request: {
        url: `/api/hosocongviec/${id}`,
        method: 'GET',
        headers: {
          'api-version': 1.0,
          Authorization:
            getState().auth.token.tokenType +
            ' ' +
            getState().auth.token.accessToken,
        },
      },
    },
  })

// GET chi tiết thư mục hồ sơ công việc
export const getChiTietThuMucHoSoCongViec = id => (dispatch, getState) =>
  dispatch({
    types: [
      types.API_REQUEST_SEND,
      types.API_REQUEST_SUCCESS,
      types.API_REQUEST_ERROR,
    ],
    payload: {
      request: {
        url: `/api/hosocongviec/thumuc/${id}`,
        method: 'GET',
        headers: {
          'api-version': 1.0,
          Authorization:
            getState().auth.token.tokenType +
            ' ' +
            getState().auth.token.accessToken,
        },
      },
    },
  })

// PUT cập nhật thư mục hồ sơ công việc
export const putCapNhatThuMucHSCV = (id, data) => (dispatch, getState) =>
  dispatch({
    types: [
      types.API_REQUEST_SEND,
      types.API_REQUEST_SUCCESS,
      types.API_REQUEST_ERROR,
    ],
    payload: {
      request: {
        url: `/api/hosocongviec/thumuc/${id}`,
        method: 'PUT',
        headers: {
          'api-version': 1.0,
          Authorization:
            getState().auth.token.tokenType +
            ' ' +
            getState().auth.token.accessToken,
        },
        data,
      },
    },
  })

// PUT cập nhật hồ sơ công việc
export const putCapNhatHSCV = (id, data) => (dispatch, getState) =>
  dispatch({
    types: [
      types.API_REQUEST_SEND,
      types.API_REQUEST_SUCCESS,
      types.API_REQUEST_ERROR,
    ],
    payload: {
      request: {
        url: `/api/hosocongviec/${id}`,
        method: 'PUT',
        headers: {
          'api-version': 1.0,
          Authorization:
            getState().auth.token.tokenType +
            ' ' +
            getState().auth.token.accessToken,
        },
        data,
      },
    },
  })

// GET danh sách tài liệu hồ sơ công việc
export const getDanhSachTaiLieuHoSoCongViec =
  (id, page = 1, sorter = null, filterColumn = {}) =>
  (dispatch, getState) => {
    let params = {
      sorter,
      page: page,
      limit: utils.PAGINATION_LIMIT,
    }
    params = Object.assign(
      Tool.Utils.linkObjectKey({ filter_column: filterColumn }),
      params
    )
    return dispatch({
      types: [
        types.API_REQUEST_SEND,
        types.API_REQUEST_SUCCESS,
        types.API_REQUEST_ERROR,
      ],
      page: page,
      payload: {
        request: {
          url: `/api/hosocongviec/${id}/tailieu`,
          method: 'GET',
          headers: {
            'api-version': 1.0,
            Authorization:
              getState().auth.token.tokenType +
              ' ' +
              getState().auth.token.accessToken,
          },
          params,
        },
      },
    })
  }

// DELETE xóa tài liệu hồ sơ công việc
export const deleteTaiLieuHoSoCongViec = data => (dispatch, getState) =>
  dispatch({
    types: [
      types.API_REQUEST_SEND,
      types.API_REQUEST_SUCCESS,
      types.API_REQUEST_ERROR,
    ],
    payload: {
      request: {
        url: '/api/hosocongviec/tailieu/xoatailieu',
        method: 'DELETE',
        headers: {
          Authorization:
            getState().auth.token.tokenType +
            ' ' +
            getState().auth.token.accessToken,
        },
        data,
      },
    },
  })

// PUT sắp xếp tài liệu hồ sơ công việc
export const putSapXepTaiLieuHSCV = (id, params) => (dispatch, getState) =>
  dispatch({
    types: [
      types.API_REQUEST_SEND,
      types.API_REQUEST_SUCCESS,
      types.API_REQUEST_ERROR,
    ],
    payload: {
      request: {
        url: `/api/hosocongviec/tailieu/${id}/sort`,
        method: 'PUT',
        headers: {
          'api-version': 1.0,
          Authorization:
            getState().auth.token.tokenType +
            ' ' +
            getState().auth.token.accessToken,
        },
        params: {
          ...params,
        },
      },
    },
  })

// PUT nộp hồ sơ công việc
export const putXuLyHoSoCongViec = (id, data) => (dispatch, getState) =>
  dispatch({
    types: [
      types.API_REQUEST_SEND,
      types.API_REQUEST_SUCCESS,
      types.API_REQUEST_ERROR,
    ],
    payload: {
      request: {
        url: `/api/hosocongviec/${id}/xulyhoso`,
        method: 'PUT',
        headers: {
          'api-version': 1.0,
          Authorization:
            getState().auth.token.tokenType +
            ' ' +
            getState().auth.token.accessToken,
        },
        data,
      },
    },
  })

// GET chi tiết tài liệu hồ sơ công việc
export const getChiTietTaiLieuHoSoCongViec = id => (dispatch, getState) =>
  dispatch({
    types: [
      types.API_REQUEST_SEND,
      types.API_REQUEST_SUCCESS,
      types.API_REQUEST_ERROR,
    ],
    payload: {
      request: {
        url: `/api/hosocongviec/tailieu/${id}`,
        method: 'GET',
        headers: {
          'api-version': 1.0,
          Authorization:
            getState().auth.token.tokenType +
            ' ' +
            getState().auth.token.accessToken,
        },
      },
    },
  })

// PUT chỉnh sửa tài liệu hồ sơ công việc
export const putChinhSuaTaiLieuHoSoCongViec =
  (id, data) => (dispatch, getState) =>
    dispatch({
      types: [
        types.API_REQUEST_SEND,
        types.API_REQUEST_SUCCESS,
        types.API_REQUEST_ERROR,
      ],
      payload: {
        request: {
          url: `/api/hosocongviec/tailieu/${id}`,
          method: 'PUT',
          headers: {
            'api-version': 1.0,
            Authorization:
              getState().auth.token.tokenType +
              ' ' +
              getState().auth.token.accessToken,
          },
          data,
        },
      },
    })

// POST thêm tài liệu hồ sơ công việc
export const postTaiLieuHoSoCongViec = data => (dispatch, getState) =>
  dispatch({
    types: [
      types.API_REQUEST_SEND,
      types.API_REQUEST_SUCCESS,
      types.API_REQUEST_ERROR,
    ],
    payload: {
      request: {
        url: '/api/tailieu/hosocongviec',
        method: 'POST',
        headers: {
          Authorization:
            getState().auth.token.tokenType +
            ' ' +
            getState().auth.token.accessToken,
        },
        data,
      },
    },
  })

// GET danh sách loại lưu tài liệu
export const getLoaiLuuTaiLieu = params => (dispatch, getState) =>
  dispatch({
    types: [
      types.DOCUMENTS_CABINET_CATEGORIES_SET_INITIAL,
      types.DOCUMENTS_CABINET_PROFILE_CATEGORIES,
      types.API_REQUEST_ERROR,
    ],
    schema: DocumentCabinetCategoryArraySchema,
    payload: {
      request: {
        url: '/api/hosocongviec/loaihoso',
        method: 'GET',
        headers: {
          Authorization:
            getState().auth.token.tokenType +
            ' ' +
            getState().auth.token.accessToken,
        },
        params,
      },
    },
  })

// PUT xử lý tiếp nhận/trả lại hồ sơ công việc
export const putTiepNhanHSCV =
  ({ id, ...data }) =>
  (dispatch, getState) =>
    dispatch({
      types: [
        types.API_REQUEST_SEND,
        types.API_REQUEST_SUCCESS,
        types.API_REQUEST_ERROR,
      ],
      payload: {
        request: {
          url: `/api/hosocongviec/${id}/xulyhoso`,
          method: 'PUT',
          headers: {
            Authorization:
              getState().auth.token.tokenType +
              ' ' +
              getState().auth.token.accessToken,
          },
          data,
        },
      },
    })

// GET lịch sử hồ sơ công việc
export const getLichSuHSCV = id => (dispatch, getState) =>
  dispatch({
    types: [
      types.API_REQUEST_SEND,
      types.API_REQUEST_SUCCESS,
      types.API_REQUEST_ERROR,
    ],
    payload: {
      request: {
        url: `/api/hosocongviec/${id}/lichsu`,
        method: 'GET',
        headers: {
          Authorization:
            getState().auth.token.tokenType +
            ' ' +
            getState().auth.token.accessToken,
        },
      },
    },
  })

// GET thống kê hồ sơ công việc
export const getDanhSachThongKeHoSoCongViec =
  params => (dispatch, getState) => {
    return dispatch({
      types: [
        types.API_REQUEST_SEND,
        types.API_REQUEST_SUCCESS,
        types.API_REQUEST_ERROR,
      ],
      payload: {
        request: {
          url: `/api/thongke/hosocongviec`,
          method: 'GET',
          headers: {
            'api-version': 1.0,
            Authorization:
              getState().auth.token.tokenType +
              ' ' +
              getState().auth.token.accessToken,
          },
          params,
        },
      },
    })
  }

// GET counter trạng thái hồ sơ công việc
export const getNumberHSCV = () => (dispatch, getState) =>
  dispatch({
    types: [
      types.API_REQUEST_SEND,
      types.GET_NUMBER_HSCV,
      types.API_REQUEST_ERROR,
    ],
    payload: {
      request: {
        url: '/api/hosocongviec/countertrangthaihoso',
        method: 'GET',
        headers: {
          'api-version': 1.0,
          Authorization:
            getState().auth.token.tokenType +
            ' ' +
            getState().auth.token.accessToken,
        },
      },
    },
  })

// GET xuất excel thống kê hscv
export const getXuatExcelThongKeHSCV =
  (filter = {}) =>
  (dispatch, getState) => {
    let params = {}
    params = Object.assign(params, Tool.Utils.linkObjectKey(filter))
    return dispatch({
      types: [
        types.API_REQUEST_SEND,
        types.API_REQUEST_SUCCESS,
        types.API_REQUEST_ERROR,
      ],
      payload: {
        client: 'download',
        request: {
          url: '/api/thongke/hosocongviec/export',
          method: 'GET',
          headers: {
            'api-version': 1.0,
            Authorization:
              getState().auth.token.tokenType +
              ' ' +
              getState().auth.token.accessToken,
          },
          params,
        },
      },
    })
  }

// GET danh sách công văn chưa lưu
export const getDanhSachCongVanChuaLuu = params => (dispatch, getState) =>
  dispatch({
    types: [
      types.API_REQUEST_SEND,
      types.API_REQUEST_SUCCESS,
      types.API_REQUEST_ERROR,
    ],
    payload: {
      request: {
        url: '/api/thongke/congvanchualuutu',
        method: 'GET',
        headers: {
          'api-version': 3.0,
          Authorization:
            getState().auth.token.tokenType +
            ' ' +
            getState().auth.token.accessToken,
        },
        params,
      },
    },
  })

// GET Xuất excel danh sách công văn chưa lưu
export const xuatExcelDanhSachCongVanChuaLuu =
  (filter = {}) =>
  (dispatch, getState) => {
    let params = {}
    params = Object.assign(params, Tool.Utils.linkObjectKey(filter))
    return dispatch({
      types: [
        types.API_REQUEST_SEND,
        types.API_REQUEST_SUCCESS,
        types.API_REQUEST_ERROR,
      ],
      payload: {
        client: 'download',
        request: {
          url: '/api/thongke/congvanchualuutu/export',
          method: 'GET',
          headers: {
            'api-version': 2.0,
            Authorization:
              getState().auth.token.tokenType +
              ' ' +
              getState().auth.token.accessToken,
          },
          params,
        },
      },
    })
  }

// PUT di chuyển hồ sơ công việc hoặc thư mục hồ sơ công việc
export const putDiChuyenThuMucHoacHoSoCongViec =
  (id, params) => (dispatch, getState) =>
    dispatch({
      types: [
        types.API_REQUEST_SEND,
        types.API_REQUEST_SUCCESS,
        types.API_REQUEST_ERROR,
      ],
      payload: {
        request: {
          url: `/api/hosocongviec/${id}/dichuyen`,
          method: 'PUT',
          headers: {
            Authorization:
              getState().auth.token.tokenType +
              ' ' +
              getState().auth.token.accessToken,
          },
          params,
        },
      },
    })

// GET danh sách thông tin duyệt
export const getDanhSachThongTinDuyet = id => (dispatch, getState) =>
  dispatch({
    types: [
      types.API_REQUEST_SEND,
      types.API_REQUEST_SUCCESS,
      types.API_REQUEST_ERROR,
    ],
    payload: {
      request: {
        url: `/api/hosocongviec/${id}/thongtinduyet`,
        method: 'GET',
        headers: {
          'api-version': 1.0,
          Authorization:
            getState().auth.token.tokenType +
            ' ' +
            getState().auth.token.accessToken,
        },
      },
    },
  })

// GET danh sách file từ công văn id
export const getDanhSachFiles = (id, params) => (dispatch, getState) =>
  dispatch({
    types: [
      types.API_REQUEST_SEND,
      types.API_REQUEST_SUCCESS,
      types.API_REQUEST_ERROR,
    ],
    payload: {
      request: {
        url: `/api/congvan/${id}/filedinhkem`,
        method: 'GET',
        headers: {
          'api-version': 1.0,
          Authorization:
            getState().auth.token.tokenType +
            ' ' +
            getState().auth.token.accessToken,
        },
        params,
      },
    },
  })

// GET danh sách file tham khảo từ ký số
export const getFileThamKhaoTuKySo = id => (dispatch, getState) =>
  dispatch({
    types: [
      types.API_REQUEST_SEND,
      types.API_REQUEST_SUCCESS,
      types.API_REQUEST_ERROR,
    ],
    payload: {
      request: {
        url: `/api/kysodi/${id}/filethamkhao`,
        method: 'GET',
        headers: {
          Authorization:
            getState().auth.token.tokenType +
            ' ' +
            getState().auth.token.accessToken,
        },
      },
    },
  })

// Kiểm tra file trình ký có đúng định dạng hoặc tràn chữ ký không?
export const postFileTrinhKy = data => (dispatch, getState) => {
  return dispatch({
    types: [
      types.API_REQUEST_SEND,
      types.API_REQUEST_SUCCESS,
      types.API_REQUEST_ERROR,
    ],
    payload: {
      client: 'formDataGetFileBinary',
      request: {
        url: '/api/kysodi/preview',
        method: 'POST',
        headers: {
          Authorization:
            getState().auth.token.tokenType +
            ' ' +
            getState().auth.token.accessToken,
        },
        data,
      },
    },
  })
}
export const postFileTrinhKyNoiBo = data => (dispatch, getState) => {
  return dispatch({
    types: [
      types.API_REQUEST_SEND,
      types.API_REQUEST_SUCCESS,
      types.API_REQUEST_ERROR,
    ],
    payload: {
      client: 'formDataGetFileBinary',
      request: {
        url: '/api/kysonoibo/preview',
        method: 'POST',
        headers: {
          Authorization:
            getState().auth.token.tokenType +
            ' ' +
            getState().auth.token.accessToken,
        },
        data,
      },
    },
  })
}
export const postFileTrinhKySoDuAn = data => (dispatch, getState) => {
  return dispatch({
    types: [
      types.API_REQUEST_SEND,
      types.API_REQUEST_SUCCESS,
      types.API_REQUEST_ERROR,
    ],
    payload: {
      client: 'formDataGetFileBinary',
      request: {
        url: '/api/kysoduan/preview',
        method: 'POST',
        headers: {
          Authorization:
            getState().auth.token.tokenType +
            ' ' +
            getState().auth.token.accessToken,
        },
        data,
      },
    },
  })
}

// Get danh sách hscv trả lại
export const getDanhSachHSCVTraLai = params => (dispatch, getState) => {
  return dispatch({
    types: [
      types.API_REQUEST_SEND,
      types.API_REQUEST_SUCCESS,
      types.API_REQUEST_ERROR,
    ],
    payload: {
      request: {
        url: '/api/thongke/hosocongviec/tralai',
        method: 'GET',
        headers: {
          'api-version': 1.0,
          Authorization:
            getState().auth.token.tokenType +
            ' ' +
            getState().auth.token.accessToken,
        },
        params,
      },
    },
  })
}

export const getLuongCongVanNoiBo = id => (dispatch, getState) =>
  dispatch({
    types: [
      types.API_REQUEST_SEND,
      types.LUONG_CONG_VAN_NOI_BO,
      types.API_REQUEST_ERROR,
    ],
    schema: FlowdocumentReceivedocumentSchema,
    payload: {
      request: {
        url: `/api/congvannoibo/${id}/luongcongvannoibo`,
        method: 'GET',
        headers: {
          'api-version': 1.0,
          Authorization:
            getState().auth.token.tokenType +
            ' ' +
            getState().auth.token.accessToken,
        },
      },
    },
  })

// File covert PDF
export const fileConvertPDF = params => (dispatch, getState) => {
  return dispatch({
    types: [
      types.API_REQUEST_SEND,
      types.API_REQUEST_SUCCESS,
      types.API_REQUEST_ERROR,
    ],
    payload: {
      client: 'defaultV2',
      request: {
        baseURL: utils.FILE_URL,
        responseType: 'blob',
        url: '/api/file/getpdf',
        method: 'GET',
        headers: {
          Authorization:
            getState().auth.token.tokenType +
            ' ' +
            getState().auth.token.accessToken,
        },
        params,
      },
    },
  })
}

export const getDashboard = params => (dispatch, getState) =>
  dispatch({
    types: [
      types.API_REQUEST_SEND,
      types.GET_REQUEST_DASHBOARD,
      types.API_REQUEST_ERROR,
    ],
    payload: {
      request: {
        url: '/api/dashboard',
        method: 'GET',
        headers: {
          Authorization:
            getState().auth.token.tokenType +
            ' ' +
            getState().auth.token.accessToken,
        },
        params,
      },
    },
  })

export const searchFileCongVanV2 = trichYeu => (dispatch, getState) =>
  dispatch({
    types: [
      types.API_REQUEST_SEND,
      types.API_REQUEST_SUCCESS,
      types.API_REQUEST_ERROR,
    ],
    payload: {
      request: {
        url: '/api/congvan/fileklch',
        method: 'GET',
        headers: {
          Authorization:
            getState().auth.token.tokenType +
            ' ' +
            getState().auth.token.accessToken,
          'api-version': '1.0',
        },
        params: {
          trich_yeu: trichYeu,
        },
      },
    },
  })

export const changePassword = data => (dispatch, getState) =>
  dispatch({
    types: [
      types.API_REQUEST_SEND,
      types.API_REQUEST_SUCCESS,
      types.API_REQUEST_ERROR,
    ],
    payload: {
      request: {
        url: `/api/nhanvien/changepassword`,
        method: 'PUT',
        headers: {
          Authorization:
            getState().auth.token.tokenType +
            ' ' +
            getState().auth.token.accessToken,
        },
        data,
      },
    },
  })

// Danh mục loại cuộc họp
export const getDanhSachLoaiCuocHop = params => (dispatch, getState) =>
  dispatch({
    types: [
      types.API_REQUEST_SEND,
      types.GET_DANH_SACH_LOAI_CUOC_HOP,
      types.API_REQUEST_ERROR,
    ],
    payload: {
      client: 'meeting',
      request: {
        url: '/api/theloai',
        method: 'GET',
        headers: {
          Authorization:
            getState().auth.token.tokenType +
            ' ' +
            getState().auth.token.accessToken,
        },
        params,
      },
    },
  })

export const putSapXepDanhSachLoaiCuocHop =
  (id, data) => (dispatch, getState) =>
    dispatch({
      types: [
        types.API_REQUEST_SEND,
        types.API_REQUEST_SUCCESS,
        types.API_REQUEST_ERROR,
      ],
      payload: {
        client: 'meeting',
        request: {
          url: `/api/theloai/${id}/sort`,
          method: 'PUT',
          headers: {
            Authorization:
              getState().auth.token.tokenType +
              ' ' +
              getState().auth.token.accessToken,
          },
          data: data,
        },
      },
    })

export const postLoaiCuocHop = data => (dispatch, getState) =>
  dispatch({
    types: [
      types.API_REQUEST_SEND,
      types.API_REQUEST_SUCCESS,
      types.API_REQUEST_ERROR,
    ],
    payload: {
      client: 'meeting',
      request: {
        url: '/api/theloai',
        method: 'POST',
        headers: {
          Authorization:
            getState().auth.token.tokenType +
            ' ' +
            getState().auth.token.accessToken,
        },
        data: data,
      },
    },
  })

export const putLoaiCuocHop = (id, data) => (dispatch, getState) =>
  dispatch({
    types: [
      types.API_REQUEST_SEND,
      types.API_REQUEST_SUCCESS,
      types.API_REQUEST_ERROR,
    ],
    payload: {
      client: 'meeting',
      request: {
        url: `/api/theloai/${id}`,
        method: 'PUT',
        headers: {
          Authorization:
            getState().auth.token.tokenType +
            ' ' +
            getState().auth.token.accessToken,
        },
        data: data,
      },
    },
  })

export const deleteLoaiCuocHop = id => (dispatch, getState) =>
  dispatch({
    types: [
      types.API_REQUEST_SEND,
      types.API_REQUEST_SUCCESS,
      types.API_REQUEST_ERROR,
    ],
    payload: {
      client: 'meeting',
      request: {
        url: `/api/theloai/${id}`,
        method: 'DELETE',
        headers: {
          Authorization:
            getState().auth.token.tokenType +
            ' ' +
            getState().auth.token.accessToken,
        },
      },
    },
  })

export const getChiTietLoaiCuocHop = id => (dispatch, getState) =>
  dispatch({
    types: [
      types.API_REQUEST_SEND,
      types.API_REQUEST_SUCCESS,
      types.API_REQUEST_ERROR,
    ],
    payload: {
      client: 'meeting',
      request: {
        url: `/api/theloai/${id}`,
        method: 'GET',
        headers: {
          Authorization:
            getState().auth.token.tokenType +
            ' ' +
            getState().auth.token.accessToken,
        },
      },
    },
  })

// Danh mục cấp độ cuộc họp
export const getDanhSachCapDoCuocHop = params => (dispatch, getState) =>
  dispatch({
    types: [
      types.API_REQUEST_SEND,
      types.GET_DANH_SACH_CAP_DO_CUOC_HOP,
      types.API_REQUEST_ERROR,
    ],
    payload: {
      client: 'meeting',
      request: {
        url: '/api/mucdo',
        method: 'GET',
        headers: {
          Authorization:
            getState().auth.token.tokenType +
            ' ' +
            getState().auth.token.accessToken,
        },
        params,
      },
    },
  })

export const putSapXepDanhSachCapDoCuocHop =
  (id, data) => (dispatch, getState) =>
    dispatch({
      types: [
        types.API_REQUEST_SEND,
        types.API_REQUEST_SUCCESS,
        types.API_REQUEST_ERROR,
      ],
      payload: {
        client: 'meeting',
        request: {
          url: `/api/mucdo/${id}/sort`,
          method: 'PUT',
          headers: {
            Authorization:
              getState().auth.token.tokenType +
              ' ' +
              getState().auth.token.accessToken,
          },
          data: data,
        },
      },
    })

export const postCapDoCuocHop = data => (dispatch, getState) =>
  dispatch({
    types: [
      types.API_REQUEST_SEND,
      types.API_REQUEST_SUCCESS,
      types.API_REQUEST_ERROR,
    ],
    payload: {
      client: 'meeting',
      request: {
        url: '/api/mucdo',
        method: 'POST',
        headers: {
          Authorization:
            getState().auth.token.tokenType +
            ' ' +
            getState().auth.token.accessToken,
        },
        data: data,
      },
    },
  })

export const putCapDoCuocHop = (id, data) => (dispatch, getState) =>
  dispatch({
    types: [
      types.API_REQUEST_SEND,
      types.API_REQUEST_SUCCESS,
      types.API_REQUEST_ERROR,
    ],
    payload: {
      client: 'meeting',
      request: {
        url: `/api/mucdo/${id}`,
        method: 'PUT',
        headers: {
          Authorization:
            getState().auth.token.tokenType +
            ' ' +
            getState().auth.token.accessToken,
        },
        data: data,
      },
    },
  })

export const deleteCapDoCuocHop = id => (dispatch, getState) =>
  dispatch({
    types: [
      types.API_REQUEST_SEND,
      types.API_REQUEST_SUCCESS,
      types.API_REQUEST_ERROR,
    ],
    payload: {
      client: 'meeting',
      request: {
        url: `/api/mucdo/${id}`,
        method: 'DELETE',
        headers: {
          Authorization:
            getState().auth.token.tokenType +
            ' ' +
            getState().auth.token.accessToken,
        },
      },
    },
  })

export const getChiTietCapDoCuocHop = id => (dispatch, getState) =>
  dispatch({
    types: [
      types.API_REQUEST_SEND,
      types.API_REQUEST_SUCCESS,
      types.API_REQUEST_ERROR,
    ],
    payload: {
      client: 'meeting',
      request: {
        url: `/api/mucdo/${id}`,
        method: 'GET',
        headers: {
          Authorization:
            getState().auth.token.tokenType +
            ' ' +
            getState().auth.token.accessToken,
        },
      },
    },
  })

// Danh mục địa điểm họp
export const getDanhSachDiaDiemHop = params => (dispatch, getState) =>
  dispatch({
    types: [
      types.API_REQUEST_SEND,
      types.GET_DANH_SACH_DIA_DIEM_HOP,
      types.API_REQUEST_ERROR,
    ],
    payload: {
      client: 'meeting',
      request: {
        url: '/api/diadiem',
        method: 'GET',
        headers: {
          Authorization:
            getState().auth.token.tokenType +
            ' ' +
            getState().auth.token.accessToken,
        },
        params,
      },
    },
  })

export const putSapXepDanhSachDiaDiemHop = (id, data) => (dispatch, getState) =>
  dispatch({
    types: [
      types.API_REQUEST_SEND,
      types.API_REQUEST_SUCCESS,
      types.API_REQUEST_ERROR,
    ],
    payload: {
      client: 'meeting',
      request: {
        url: `/api/diadiem/${id}/sort`,
        method: 'PUT',
        headers: {
          Authorization:
            getState().auth.token.tokenType +
            ' ' +
            getState().auth.token.accessToken,
        },
        data: data,
      },
    },
  })

export const postDiaDiemHop = data => (dispatch, getState) =>
  dispatch({
    types: [
      types.API_REQUEST_SEND,
      types.API_REQUEST_SUCCESS,
      types.API_REQUEST_ERROR,
    ],
    payload: {
      client: 'meeting',
      request: {
        url: '/api/diadiem',
        method: 'POST',
        headers: {
          Authorization:
            getState().auth.token.tokenType +
            ' ' +
            getState().auth.token.accessToken,
        },
        data: data,
      },
    },
  })

export const putDiaDiemHop = (id, data) => (dispatch, getState) =>
  dispatch({
    types: [
      types.API_REQUEST_SEND,
      types.API_REQUEST_SUCCESS,
      types.API_REQUEST_ERROR,
    ],
    payload: {
      client: 'meeting',
      request: {
        url: `/api/diadiem/${id}`,
        method: 'PUT',
        headers: {
          Authorization:
            getState().auth.token.tokenType +
            ' ' +
            getState().auth.token.accessToken,
        },
        data: data,
      },
    },
  })

export const deleteDiaDiemHop = id => (dispatch, getState) =>
  dispatch({
    types: [
      types.API_REQUEST_SEND,
      types.API_REQUEST_SUCCESS,
      types.API_REQUEST_ERROR,
    ],
    payload: {
      client: 'meeting',
      request: {
        url: `/api/diadiem/${id}`,
        method: 'DELETE',
        headers: {
          Authorization:
            getState().auth.token.tokenType +
            ' ' +
            getState().auth.token.accessToken,
        },
      },
    },
  })

export const getChiTietDiaDiemHop = id => (dispatch, getState) =>
  dispatch({
    types: [
      types.API_REQUEST_SEND,
      types.API_REQUEST_SUCCESS,
      types.API_REQUEST_ERROR,
    ],
    payload: {
      client: 'meeting',
      request: {
        url: `/api/diadiem/${id}`,
        method: 'GET',
        headers: {
          Authorization:
            getState().auth.token.tokenType +
            ' ' +
            getState().auth.token.accessToken,
        },
      },
    },
  })

// Danh mục nhóm vật tư
export const getDanhSachNhomVatTu = params => (dispatch, getState) =>
  dispatch({
    types: [
      types.API_REQUEST_SEND,
      types.GET_DANH_SACH_NHOM_VAT_TU,
      types.API_REQUEST_ERROR,
    ],
    payload: {
      client: 'meeting',
      request: {
        url: '/api/nhomvattu',
        method: 'GET',
        headers: {
          Authorization:
            getState().auth.token.tokenType +
            ' ' +
            getState().auth.token.accessToken,
        },
        params,
      },
    },
  })

export const putSapXepDanhSachNhomVatTu = (id, data) => (dispatch, getState) =>
  dispatch({
    types: [
      types.API_REQUEST_SEND,
      types.API_REQUEST_SUCCESS,
      types.API_REQUEST_ERROR,
    ],
    payload: {
      client: 'meeting',
      request: {
        url: `/api/nhomvattu/${id}/sort`,
        method: 'PUT',
        headers: {
          Authorization:
            getState().auth.token.tokenType +
            ' ' +
            getState().auth.token.accessToken,
        },
        data: data,
      },
    },
  })

export const postNhomVatTu = data => (dispatch, getState) =>
  dispatch({
    types: [
      types.API_REQUEST_SEND,
      types.API_REQUEST_SUCCESS,
      types.API_REQUEST_ERROR,
    ],
    payload: {
      client: 'meeting',
      request: {
        url: '/api/nhomvattu',
        method: 'POST',
        headers: {
          Authorization:
            getState().auth.token.tokenType +
            ' ' +
            getState().auth.token.accessToken,
        },
        data: data,
      },
    },
  })

export const putNhomVatTu = (id, data) => (dispatch, getState) =>
  dispatch({
    types: [
      types.API_REQUEST_SEND,
      types.API_REQUEST_SUCCESS,
      types.API_REQUEST_ERROR,
    ],
    payload: {
      client: 'meeting',
      request: {
        url: `/api/vattu/${id}`,
        method: 'PUT',
        headers: {
          Authorization:
            getState().auth.token.tokenType +
            ' ' +
            getState().auth.token.accessToken,
        },
        data: data,
      },
    },
  })

export const deleteNhomVatTu = id => (dispatch, getState) =>
  dispatch({
    types: [
      types.API_REQUEST_SEND,
      types.API_REQUEST_SUCCESS,
      types.API_REQUEST_ERROR,
    ],
    payload: {
      client: 'meeting',
      request: {
        url: `/api/vattu/${id}`,
        method: 'DELETE',
        headers: {
          Authorization:
            getState().auth.token.tokenType +
            ' ' +
            getState().auth.token.accessToken,
        },
      },
    },
  })

export const getChiTietNhomVatTu = id => (dispatch, getState) =>
  dispatch({
    types: [
      types.API_REQUEST_SEND,
      types.API_REQUEST_SUCCESS,
      types.API_REQUEST_ERROR,
    ],
    payload: {
      client: 'meeting',
      request: {
        url: `/api/vattu/${id}`,
        method: 'GET',
        headers: {
          Authorization:
            getState().auth.token.tokenType +
            ' ' +
            getState().auth.token.accessToken,
        },
      },
    },
  })

// Danh mục loại vật tư
export const getDanhSachLoaiVatTu = params => (dispatch, getState) =>
  dispatch({
    types: [
      types.API_REQUEST_SEND,
      types.GET_DANH_SACH_LOAI_VAT_TU,
      types.API_REQUEST_ERROR,
    ],
    payload: {
      client: 'meeting',
      request: {
        url: '/api/vattu',
        method: 'GET',
        headers: {
          Authorization:
            getState().auth.token.tokenType +
            ' ' +
            getState().auth.token.accessToken,
        },
        params,
      },
    },
  })

export const postLoaiVatTu = data => (dispatch, getState) =>
  dispatch({
    types: [
      types.API_REQUEST_SEND,
      types.API_REQUEST_SUCCESS,
      types.API_REQUEST_ERROR,
    ],
    payload: {
      client: 'meeting',
      request: {
        url: '/api/vattu',
        method: 'POST',
        headers: {
          Authorization:
            getState().auth.token.tokenType +
            ' ' +
            getState().auth.token.accessToken,
        },
        data: data,
      },
    },
  })

export const putLoaiVatTu = (id, data) => (dispatch, getState) =>
  dispatch({
    types: [
      types.API_REQUEST_SEND,
      types.API_REQUEST_SUCCESS,
      types.API_REQUEST_ERROR,
    ],
    payload: {
      client: 'meeting',
      request: {
        url: `/api/vattu/${id}`,
        method: 'PUT',
        headers: {
          Authorization:
            getState().auth.token.tokenType +
            ' ' +
            getState().auth.token.accessToken,
        },
        data: data,
      },
    },
  })

export const deleteLoaiVatTu = id => (dispatch, getState) =>
  dispatch({
    types: [
      types.API_REQUEST_SEND,
      types.API_REQUEST_SUCCESS,
      types.API_REQUEST_ERROR,
    ],
    payload: {
      client: 'meeting',
      request: {
        url: `/api/vattu/${id}`,
        method: 'DELETE',
        headers: {
          Authorization:
            getState().auth.token.tokenType +
            ' ' +
            getState().auth.token.accessToken,
        },
      },
    },
  })

export const getChiTietLoaiVatTu = id => (dispatch, getState) =>
  dispatch({
    types: [
      types.API_REQUEST_SEND,
      types.API_REQUEST_SUCCESS,
      types.API_REQUEST_ERROR,
    ],
    payload: {
      client: 'meeting',
      request: {
        url: `/api/vattu/${id}`,
        method: 'GET',
        headers: {
          Authorization:
            getState().auth.token.tokenType +
            ' ' +
            getState().auth.token.accessToken,
        },
      },
    },
  })

// Quản lý lịch họp
export const getDanhSachLichHop = params => (dispatch, getState) =>
  dispatch({
    types: [
      types.API_REQUEST_SEND,
      types.GET_DANH_SACH_LICH_HOP,
      types.API_REQUEST_ERROR,
    ],
    payload: {
      client: 'meeting',
      request: {
        url: '/api/cuochop/quanly',
        method: 'GET',
        headers: {
          Authorization:
            getState().auth.token.tokenType +
            ' ' +
            getState().auth.token.accessToken,
        },
        params,
      },
    },
  })
export const deleteLichHop = id => (dispatch, getState) =>
  dispatch({
    types: [
      types.API_REQUEST_SEND,
      types.API_REQUEST_SUCCESS,
      types.API_REQUEST_ERROR,
    ],
    payload: {
      client: 'meeting',
      request: {
        url: `/api/cuochop/${id}`,
        method: 'DELETE',
        headers: {
          Authorization:
            getState().auth.token.tokenType +
            ' ' +
            getState().auth.token.accessToken,
        },
      },
    },
  })

export const getChiTietLichHop = id => (dispatch, getState) =>
  dispatch({
    types: [
      types.API_REQUEST_SEND,
      types.API_REQUEST_SUCCESS,
      types.API_REQUEST_ERROR,
    ],
    payload: {
      client: 'meeting',
      request: {
        url: `/api/cuochop/${id}`,
        method: 'GET',
        headers: {
          Authorization:
            getState().auth.token.tokenType +
            ' ' +
            getState().auth.token.accessToken,
        },
      },
    },
  })

export const postLichHop = data => (dispatch, getState) =>
  dispatch({
    types: [
      types.API_REQUEST_SEND,
      types.API_REQUEST_SUCCESS,
      types.API_REQUEST_ERROR,
    ],
    payload: {
      client: 'meeting',
      request: {
        url: '/api/cuochop',
        method: 'POST',
        headers: {
          Authorization:
            getState().auth.token.tokenType +
            ' ' +
            getState().auth.token.accessToken,
        },
        data: data,
      },
    },
  })

export const putLichHop = (id, data) => (dispatch, getState) =>
  dispatch({
    types: [
      types.API_REQUEST_SEND,
      types.API_REQUEST_SUCCESS,
      types.API_REQUEST_ERROR,
    ],
    payload: {
      client: 'meeting',
      request: {
        url: `/api/cuochop/${id}`,
        method: 'PUT',
        headers: {
          Authorization:
            getState().auth.token.tokenType +
            ' ' +
            getState().auth.token.accessToken,
        },
        data: data,
      },
    },
  })

export const putHuyLichHop = id => (dispatch, getState) =>
  dispatch({
    types: [
      types.API_REQUEST_SEND,
      types.API_REQUEST_SUCCESS,
      types.API_REQUEST_ERROR,
    ],
    payload: {
      client: 'meeting',
      request: {
        url: `api/cuochop/${id}/huy`,
        method: 'PUT',
        headers: {
          Authorization:
            getState().auth.token.tokenType +
            ' ' +
            getState().auth.token.accessToken,
        },
      },
    },
  })

export const putGuiLichHop = id => (dispatch, getState) =>
  dispatch({
    types: [
      types.API_REQUEST_SEND,
      types.API_REQUEST_SUCCESS,
      types.API_REQUEST_ERROR,
    ],
    payload: {
      client: 'meeting',
      request: {
        url: `api/cuochop/${id}/gui`,
        method: 'PUT',
        headers: {
          Authorization:
            getState().auth.token.tokenType +
            ' ' +
            getState().auth.token.accessToken,
        },
      },
    },
  })

export const getDanhSachLichHopCaNhan = params => (dispatch, getState) =>
  dispatch({
    types: [
      types.API_REQUEST_SEND,
      types.API_REQUEST_SUCCESS,
      types.API_REQUEST_ERROR,
    ],
    payload: {
      client: 'meeting',
      request: {
        url: `/api/cuochop/canhan`,
        method: 'GET',
        headers: {
          Authorization:
            getState().auth.token.tokenType +
            ' ' +
            getState().auth.token.accessToken,
        },
        params: params,
      },
    },
  })

export const getDanhSachLichHopQuanLy = params => (dispatch, getState) =>
  dispatch({
    types: [
      types.API_REQUEST_SEND,
      types.API_REQUEST_SUCCESS,
      types.API_REQUEST_ERROR,
    ],
    payload: {
      client: 'meeting',
      request: {
        url: `/api/cuochop/quanly/lichlamviec`,
        method: 'GET',
        headers: {
          Authorization:
            getState().auth.token.tokenType +
            ' ' +
            getState().auth.token.accessToken,
        },
        params: params,
      },
    },
  })

export const getDanhSachLichHopHauCan = params => (dispatch, getState) =>
  dispatch({
    types: [
      types.API_REQUEST_SEND,
      types.API_REQUEST_SUCCESS,
      types.API_REQUEST_ERROR,
    ],
    payload: {
      client: 'meeting',
      request: {
        url: `/api/cuochop/haucan`,
        method: 'GET',
        headers: {
          Authorization:
            getState().auth.token.tokenType +
            ' ' +
            getState().auth.token.accessToken,
        },
        params: params,
      },
    },
  })

// Get danh sách người tham dự theo đơn vị id
export const getDanhSachNguoiThamGiaTheoDonVi =
  (id, params) => (dispatch, getState) =>
    dispatch({
      types: [
        types.API_REQUEST_SEND,
        types.API_REQUEST_SUCCESS,
        types.API_REQUEST_ERROR,
      ],
      payload: {
        request: {
          url: `/api/donvi/${id}/nhanvien`,
          method: 'GET',
          headers: {
            Authorization:
              getState().auth.token.tokenType +
              ' ' +
              getState().auth.token.accessToken,
          },
          params: params,
        },
      },
    })

export const putXacNhanThamGia =
  (id, thamGia = 0) =>
  (dispatch, getState) =>
    dispatch({
      types: [
        types.API_REQUEST_SEND,
        types.API_REQUEST_SUCCESS,
        types.API_REQUEST_ERROR,
      ],
      payload: {
        client: 'meeting',
        request: {
          url: `/api/cuochop/${id}/xacnhan?thamGia=${thamGia}`,
          method: 'PUT',
          headers: {
            Authorization:
              getState().auth.token.tokenType +
              ' ' +
              getState().auth.token.accessToken,
          },
        },
      },
    })

export const getLoaiVatTuMultilevel = params => (dispatch, getState) =>
  dispatch({
    types: [
      types.API_REQUEST_SEND,
      types.API_REQUEST_SUCCESS,
      types.API_REQUEST_ERROR,
    ],
    payload: {
      client: 'meeting',
      request: {
        url: '/api/vattu/multilevel',
        method: 'GET',
        headers: {
          Authorization:
            getState().auth.token.tokenType +
            ' ' +
            getState().auth.token.accessToken,
        },
        params: params,
      },
    },
  })

export const putVatTuCuocHop = (id, data) => (dispatch, getState) =>
  dispatch({
    types: [
      types.API_REQUEST_SEND,
      types.API_REQUEST_SUCCESS,
      types.API_REQUEST_ERROR,
    ],
    payload: {
      client: 'meeting',
      request: {
        url: `/api/cuochop/${id}/vattu`,
        method: 'PUT',
        headers: {
          Authorization:
            getState().auth.token.tokenType +
            ' ' +
            getState().auth.token.accessToken,
        },
        data: data,
      },
    },
  })

export const getDanhSachVatTuCuocHop = id => (dispatch, getState) =>
  dispatch({
    types: [
      types.API_REQUEST_SEND,
      types.API_REQUEST_SUCCESS,
      types.API_REQUEST_ERROR,
    ],
    payload: {
      client: 'meeting',
      request: {
        url: `/api/cuochop/${id}/vattu`,
        method: 'GET',
        headers: {
          Authorization:
            getState().auth.token.tokenType +
            ' ' +
            getState().auth.token.accessToken,
        },
      },
    },
  })

export const getDanhSachDonViCapMot = () => (dispatch, getState) =>
  dispatch({
    types: [
      types.API_REQUEST_SEND,
      types.API_REQUEST_SUCCESS,
      types.API_REQUEST_ERROR,
    ],
    payload: {
      client: 'meeting',
      request: {
        url: '/api/donvi/rootlevel',
        method: 'GET',
        headers: {
          Authorization:
            getState().auth.token.tokenType +
            ' ' +
            getState().auth.token.accessToken,
        },
      },
    },
  })

export const getDanhDonViPhongBanNhanVienById = id => (dispatch, getState) =>
  dispatch({
    types: [
      types.API_REQUEST_SEND,
      types.API_REQUEST_SUCCESS,
      types.API_REQUEST_ERROR,
    ],
    payload: {
      client: 'meeting',
      request: {
        url: `/api/donvi/detail/${id}`,
        method: 'GET',
        headers: {
          Authorization:
            getState().auth.token.tokenType +
            ' ' +
            getState().auth.token.accessToken,
        },
      },
    },
  })

export const putKetThucCuocHop = id => (dispatch, getState) =>
  dispatch({
    types: [
      types.API_REQUEST_SEND,
      types.API_REQUEST_SUCCESS,
      types.API_REQUEST_ERROR,
    ],
    payload: {
      client: 'meeting',
      request: {
        url: `/api/cuochop/${id}/ketthuc`,
        method: 'PUT',
        headers: {
          Authorization:
            getState().auth.token.tokenType +
            ' ' +
            getState().auth.token.accessToken,
        },
      },
    },
  })

export const postChiDaoGiaoViec =
  (congvandenid, data) => (dispatch, getState) =>
    dispatch({
      types: [
        types.API_REQUEST_SEND,
        types.API_REQUEST_SUCCESS,
        types.API_REQUEST_ERROR,
      ],
      payload: {
        request: {
          url: `api/congvanden/${congvandenid}/chidaogiaoviec`,
          method: 'POST',
          headers: {
            Authorization:
              getState().auth.token.tokenType +
              ' ' +
              getState().auth.token.accessToken,
          },
          data,
        },
      },
    })

export const updateUserProfile = data => (dispatch, getState) =>
  dispatch({
    types: [
      types.API_REQUEST_SEND,
      types.API_REQUEST_SUCCESS,
      types.API_REQUEST_ERROR,
    ],
    payload: {
      request: {
        url: '/api/nhanvien',
        method: 'PATCH',
        headers: {
          Authorization:
            getState().auth.token.tokenType +
            ' ' +
            getState().auth.token.accessToken,
        },
        data,
      },
    },
  })

export const getUserProfile = id => (dispatch, getState) =>
  dispatch({
    types: [
      types.API_REQUEST_SEND,
      types.AUTH_UPDATE_PROFILE,
      types.API_REQUEST_ERROR,
    ],
    payload: {
      request: {
        url: `/api/nhanvien/${id}`,
        method: 'GET',
        headers: {
          Authorization:
            getState().auth.token.tokenType +
            ' ' +
            getState().auth.token.accessToken,
        },
      },
    },
  })

export const downloadMultipleFiles = data => (dispatch, getState) =>
  dispatch({
    types: [
      types.API_REQUEST_SEND,
      types.API_REQUEST_SUCCESS,
      types.API_REQUEST_ERROR,
    ],
    payload: {
      client: 'downloadPost',
      request: {
        url: '/api/tailieu/downloadfile',
        method: 'POST',
        headers: {
          Authorization:
            getState().auth.token.tokenType +
            ' ' +
            getState().auth.token.accessToken,
        },
        data,
      },
    },
  })

export const getMeetingCounter = () => (dispatch, getState) =>
  dispatch({
    types: [
      types.API_REQUEST_SEND,
      types.GET_COUNTER_MEETING,
      types.API_REQUEST_ERROR,
    ],
    payload: {
      request: {
        baseURL: utils.QLLH_URL,
        url: '/api/cuochop/counter/chuaxacnhan',
        method: 'GET',
        headers: {
          Authorization:
            getState().auth.token.tokenType +
            ' ' +
            getState().auth.token.accessToken,
        },
      },
    },
  })

export const postXemCongVanDen = congVanDenId => (dispatch, getState) =>
  dispatch({
    types: [
      types.API_REQUEST_SEND,
      types.API_REQUEST_SUCCESS,
      types.API_REQUEST_ERROR,
    ],
    payload: {
      request: {
        url: `/api/congvanden/${congVanDenId}/daxem`,
        method: 'POST',
        headers: {
          Authorization:
            getState().auth.token.tokenType +
            ' ' +
            getState().auth.token.accessToken,
        },
      },
    },
  })

export const postXemCongVanDi = congVanDiId => (dispatch, getState) =>
  dispatch({
    types: [
      types.API_REQUEST_SEND,
      types.API_REQUEST_SUCCESS,
      types.API_REQUEST_ERROR,
    ],
    payload: {
      request: {
        url: `/api/congvandi/${congVanDiId}/daxem`,
        method: 'POST',
        headers: {
          Authorization:
            getState().auth.token.tokenType +
            ' ' +
            getState().auth.token.accessToken,
        },
      },
    },
  })

export const postXemCongViecPhongBan =
  congViecPhongBanId => (dispatch, getState) =>
    dispatch({
      types: [
        types.API_REQUEST_SEND,
        types.API_REQUEST_SUCCESS,
        types.API_REQUEST_ERROR,
      ],
      payload: {
        request: {
          url: `/api/congviec/phongban/${congViecPhongBanId}/daxem`,
          method: 'POST',
          headers: {
            Authorization:
              getState().auth.token.tokenType +
              ' ' +
              getState().auth.token.accessToken,
          },
        },
      },
    })

export const postXemCongViecCaNhan = congViecCaNhanId => (dispatch, getState) =>
  dispatch({
    types: [
      types.API_REQUEST_SEND,
      types.API_REQUEST_SUCCESS,
      types.API_REQUEST_ERROR,
    ],
    payload: {
      request: {
        url: `/api/congviec/canhan/${congViecCaNhanId}/daxem`,
        method: 'POST',
        headers: {
          Authorization:
            getState().auth.token.tokenType +
            ' ' +
            getState().auth.token.accessToken,
        },
      },
    },
  })

export const getDSNhanVienDaXemCongVan =
  (congVanId, params) => (dispatch, getState) =>
    dispatch({
      types: [
        types.API_REQUEST_SEND,
        types.API_REQUEST_SUCCESS,
        types.API_REQUEST_ERROR,
      ],
      payload: {
        request: {
          url: `/api/congvan/${congVanId}/nhanviendaxem`,
          method: 'GET',
          headers: {
            Authorization:
              getState().auth.token.tokenType +
              ' ' +
              getState().auth.token.accessToken,
          },
          params,
        },
      },
    })

export const getLuongVanBanDen = (id, params) => (dispatch, getState) =>
  dispatch({
    types: [
      types.API_REQUEST_SEND,
      types.API_REQUEST_SUCCESS,
      types.API_REQUEST_ERROR,
    ],
    payload: {
      request: {
        url: `/api/congvanden/${id}/luongvanban`,
        method: 'GET',
        headers: {
          'api-version': 2.0,
          Authorization:
            getState().auth.token.tokenType +
            ' ' +
            getState().auth.token.accessToken,
        },
        params,
      },
    },
  })

export const getXemthuchien = id => (dispatch, getState) =>
  dispatch({
    types: [
      types.API_REQUEST_SEND,
      types.API_REQUEST_SUCCESS,
      types.API_REQUEST_ERROR,
    ],
    payload: {
      request: {
        url: `/api/phieugiaoviec/${id}/xemthuchien`,
        method: 'GET',
        headers: {
          'api-version': 2.0,
          Authorization:
            getState().auth.token.tokenType +
            ' ' +
            getState().auth.token.accessToken,
        },
        params: {
          is_full: false,
        },
      },
    },
  })

export const getLuongVanBanDi = id => (dispatch, getState) =>
  dispatch({
    types: [
      types.API_REQUEST_SEND,
      types.API_REQUEST_SUCCESS,
      types.API_REQUEST_ERROR,
    ],
    payload: {
      request: {
        url: `/api/congvandi/${id}/luongvanban`,
        method: 'GET',
        headers: {
          Authorization:
            getState().auth.token.tokenType +
            ' ' +
            getState().auth.token.accessToken,
        },
      },
    },
  })

export const getLanhDaoCuocHop = () => (dispatch, getState) =>
  dispatch({
    types: [
      types.API_REQUEST_SEND,
      types.API_REQUEST_SUCCESS,
      types.API_REQUEST_ERROR,
    ],
    payload: {
      request: {
        baseURL: utils.QLLH_URL,
        url: '/api/nhanvien/lanhdao',
        method: 'GET',
        headers: {
          Authorization:
            getState().auth.token.tokenType +
            ' ' +
            getState().auth.token.accessToken,
        },
      },
    },
  })
