import { useState, useEffect, useCallback, useMemo } from 'react'

const UseDocumentFlow = ({ actions, congVanDenId, congVanDiId }) => {
  const [isLoading, setIsLoading] = useState(false)
  const [data, setData] = useState([])
  const mission = useMemo(() => {
    return {
      CHI_DAO: 'CHI_DAO',
      CHU_TRI: 'CHU_TRI',
      PHOI_HOP: 'PHOI_HOP',
      XEM_BAO_CAO: 'XEM_BAO_CAO',
      XEM_DE_BIET: 'XEM_DE_BIET',
    }
  }, [])

  const fetchData = useCallback(() => {
    const getLuongVanBanDen = async id => {
      const response = await actions.getLuongVanBanDen(id)
      const result = response?.payload?.data?.result
      return result
    }

    const getXemThucHien = async id => {
      const response = await actions.getXemthuchien(id)
      const result = response?.payload?.data?.result
      return result
    }

    const handleData = async data => {
      const handleXemBaoCao = (dsXemBaoCao = []) => {
        if (dsXemBaoCao?.length > 0 ?? false) {
          return dsXemBaoCao.map(elm => ({
            mission: mission.XEM_BAO_CAO,
            profile: elm,
          }))
        }

        return []
      }

      const handleDonViTrucThuoc = async (dsDonViTrucThuocResponse = []) => {
        if (dsDonViTrucThuocResponse?.length > 0 ?? false) {
          return await Promise.all(
            dsDonViTrucThuocResponse.map(async elm => {
              let dataAPI = null
              if (elm?.chiDaoId) {
                dataAPI = await getLuongVanBanDen(elm?.congVanDenId)
              }

              return {
                mission: elm?.chuTri ? mission.CHU_TRI : mission.PHOI_HOP,
                profile: elm,
                ...(dataAPI ? { children: await handleData(dataAPI) } : {}),
              }
            })
          )
        }

        return []
      }

      const handleDonViNhan = async (dsDonViNhan = []) => {
        if (dsDonViNhan?.length > 0 ?? false) {
          return await Promise.all(
            dsDonViNhan.map(async elm => {
              let dataAPI = null
              if (elm?.chiDaoId) {
                dataAPI = await getLuongVanBanDen(elm?.congVanDenId)
              }

              return {
                profile: elm,
                ...(dataAPI ? { children: await handleData(dataAPI) } : {}),
              }
            })
          )
        }

        return []
      }

      const handleDonViXemDeBiet = (dsDonViXemDeBiet = []) => {
        if (dsDonViXemDeBiet?.length > 0 ?? false) {
          return dsDonViXemDeBiet.map(elm => ({
            mission: mission.XEM_DE_BIET,
            profile: elm,
          }))
        }

        return []
      }

      const handlePhongBanXemDeBiet = (dsPhongBanXemDeBiet = []) => {
        if (dsPhongBanXemDeBiet?.length > 0 ?? false) {
          return dsPhongBanXemDeBiet.map(elm => ({
            mission: mission.XEM_DE_BIET,
            profile: elm,
          }))
        }

        return []
      }

      const handleCaNhanXemDeBiet = (dsCaNhanXemDeBiet = []) => {
        if (dsCaNhanXemDeBiet?.length > 0 ?? false) {
          return dsCaNhanXemDeBiet.map(elm => ({
            mission: mission.XEM_DE_BIET,
            profile: elm,
          }))
        }

        return []
      }

      const handleNhanVienXemDeBiet = (dsNvXemDeBiet = []) => {
        if (dsNvXemDeBiet?.length > 0 ?? false) {
          return dsNvXemDeBiet.map(elm => ({
            mission: mission.XEM_DE_BIET,
            profile: elm,
          }))
        }

        return []
      }

      const handleCongViecPhongBan = async (dsCongViecPhongBan = []) => {
        if (
          !dsCongViecPhongBan ||
          (dsCongViecPhongBan?.length === 0 ?? false)
        ) {
          return []
        }

        const resultPromises = dsCongViecPhongBan?.map(async cvPB => {
          const data = cvPB?.phieuGiaoViecId
            ? await getXemThucHien(cvPB?.phieuGiaoViecId)
            : null

          if (data) {
            return {
              mission: cvPB?.chuTri ? mission.CHU_TRI : mission.PHOI_HOP,
              profile: cvPB?.phongBanThucHien,
              children: [
                {
                  profile: data?.nguoiGiaoViec,
                  date: data?.ngayGiaoViec,
                  children: [
                    ...(data?.dsCongViecCaNhan?.length > 0 ?? false
                      ? data.dsCongViecCaNhan.map(cvCN => ({
                          mission: cvCN?.chuTri
                            ? mission.CHU_TRI
                            : mission.PHOI_HOP,
                          profile: cvCN?.caNhanThucHien,
                          status: cvCN?.trangThai,
                          date: cvCN?.ngayThucHien,
                          statusContent: cvCN?.thucHien,
                        }))
                      : []),
                    ...(await handleCongViecPhongBan(data?.dsCongViecPhongBan)),
                    ...handleCaNhanXemDeBiet(data?.dsCaNhanXemDeBiet),
                    ...handleNhanVienXemDeBiet(data?.dsNvXemDeBiet),
                  ],
                },
              ],
            }
          }

          return {
            mission: cvPB?.chuTri ? mission.CHU_TRI : mission.PHOI_HOP,
            profile: cvPB?.phongBanThucHien,
          }
        })

        return await Promise.all(resultPromises)
      }

      const chiDao = data?.chiDao
      const chuyenChiDao = data?.chuyenChiDao
      const chuyenChiDaoData = []
      if (chuyenChiDao?.length > 0 ?? false) {
        chuyenChiDao.forEach(element => {
          if (element?.nguoiChuyenChiDao) {
            chuyenChiDaoData.push({
              profile: element?.nguoiChuyenChiDao,
              date: element?.nguoiChuyenChiDao?.ngayTao,
              chuyenChiDao: element?.phongBanChuyenDen?.tenPhongBan,
              content: element?.noiDung,
            })
          }

          if (element?.phongBanChuyenDen) {
            chuyenChiDaoData.push({
              profile: element?.phongBanChuyenDen,
            })
          }
        })
      }

      return [
        ...(data?.nguoiTaoCongVan
          ? [
              {
                profile: data?.nguoiTaoCongVan,
                date: data?.nguoiTaoCongVan?.ngayTao,
              },
            ]
          : []),
        ...(data?.nguoiTaoCongVanDi
          ? [
              {
                profile: data?.nguoiTaoCongVanDi,
                date: data?.nguoiTaoCongVanDi?.ngayTao,
              },
            ]
          : []),
        ...chuyenChiDaoData,
        ...(chiDao
          ? [
              {
                profile: chiDao?.nguoiChiDao,
                date: chiDao?.ngayChiDao,
                content: chiDao?.noiDung,
                children: [
                  ...(chiDao?.nvChiDao
                    ? [
                        {
                          profile: chiDao?.nvChiDao,
                          mission: mission.CHI_DAO,
                        },
                      ]
                    : []),
                  ...(await handleCongViecPhongBan(chiDao?.dsCongViecPhongBan)),
                  ...(await handleDonViTrucThuoc(
                    chiDao?.dsDonViTrucThuocResponse
                  )),
                  ...handleXemBaoCao(chiDao?.dsXemBaoCao),
                  ...handleDonViXemDeBiet(chiDao?.dsDonViXemDeBiet),
                  ...handlePhongBanXemDeBiet(chiDao?.dsPhongBanXemDeBiet),
                  ...handleNhanVienXemDeBiet(chiDao?.dsNvXemDeBiet),
                ],
              },
            ]
          : []),
        ...(await handleDonViNhan(data?.dsDonViNhan)),
        ...(data?.phongBanTrinh && (!chiDao || (chiDao && !chiDao.chiDaoId))
          ? [
              {
                profile: data.phongBanTrinh,
              },
            ]
          : []),
      ]
    }
    if (congVanDenId) {
      setIsLoading(true)
      return actions
        .getLuongVanBanDen(congVanDenId, { is_full: true })
        .then(async res => {
          setIsLoading(false)
          const result = res?.payload?.data?.result
          if (result) {
            setData(await handleData(result))
          }
        })
    } else if (congVanDiId) {
      setIsLoading(true)
      return actions.getLuongVanBanDi(congVanDiId).then(async res => {
        setIsLoading(false)
        const result = res?.payload?.data?.result
        if (result) {
          setData(await handleData(result))
        }
      })
    }
  }, [actions, congVanDenId, congVanDiId, mission])

  useEffect(() => {
    fetchData()
  }, [fetchData])

  return {
    isLoading,
    data,
    mission,
  }
}

export default UseDocumentFlow
