import React, { useEffect, useState, useCallback, useMemo } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import {
  Button,
  Intent,
  Popover,
  PopoverInteractionKind,
  Position,
} from '@blueprintjs/core'
import moment from 'moment'

import { PopupConfirm, UploadMultiple } from 'components/common'
import { MasterLayout } from 'components/layout'
import * as Actions from 'actions'
import variables from 'constants/variables'
import { ducumentsType } from 'helpers/file'
import { showToast } from 'actions/task'
import { renderClassStatus, renderTrangThai } from 'helpers/Helper'
import UserItem from './UserItem'
import ModalDanhSachNguoiThamGia from './ModalDanhSachNguoiThamGia'
import Title from 'components/ui/title'
import * as MESSAGER from 'constants/MessageForm'
import * as POPUP from 'constants/Popup'
const LINK = '/lich-hop/quan-ly-lich-hop/chinh-sua'

const ChiTietLichHopPage = props => {
  const idDetail = props.match.params.id
  const [detail, setDetail] = useState(null)
  const [isOpenPopupKetThuc, setIsOpenPopupKetThuc] = useState(false)
  const [dataFiles, setDataFiles] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const [isLoadingPopup, setIsLoadingPopup] = useState(false)
  const [status, setStatus] = useState(0)
  const [showPopupThamGia, setShowPopupThamGia] = useState(false)
  const [cuocHopId, setCuocHopId] = useState('')
  const [isOpenPopupConfirm, setIsOpenPopupConfirm] = useState(false)
  const [isOpenPopupConfirmCancel, setIsOpenPopupConfirmCancel] =
    useState(false)

  const handleBack = () => {
    props.history.goBack()
  }

  const handleGetChiTietLichHop = useCallback(async () => {
    if (!idDetail) {
      return
    }

    const res = await props.actions.getChiTietLichHop(idDetail)
    if (!res.error && res?.payload?.data?.result) {
      const dataDetail = res.payload.data.result
      setDetail(dataDetail)
      setStatus(dataDetail?.trangThai)
      setDataFiles(dataDetail?.dsFileDinhKem ?? [])
    }
  }, [idDetail, props.actions])

  const handleClickPopup = id => {
    setCuocHopId(id)
    setIsOpenPopupConfirm(!isOpenPopupConfirm)
  }

  const handleSubmitRemoveItem = () => {
    if (!cuocHopId) {
      setIsOpenPopupConfirm(false)
      return
    }

    setIsLoadingPopup(true)
    props.actions.deleteLichHop(cuocHopId).then(res => {
      setCuocHopId('')
      if (res.payload && res.payload.data.result === true) {
        props.actions.commonAddToasterMessage({
          message: MESSAGER.TOATS_MESSAGE_DELETE_SUCCESS,
          intent: Intent.SUCCESS,
        })
        return handleBack()
      } else {
        props.actions.commonAddToasterMessage({
          message: MESSAGER.TOATS_MESSAGE_DELETE_FAIL,
          intent: Intent.DANGER,
        })
      }

      setIsLoadingPopup(false)
      setIsOpenPopupConfirm(false)
    })
  }

  const handleSubmitGuiCuocHop = id => {
    if (id) {
      props.actions.putGuiLichHop(id).then(res => {
        if (res.payload && res.payload.data.result === true) {
          handleGetChiTietLichHop()
          return props.actions.commonAddToasterMessage({
            message: MESSAGER.TOATS_SEND_MAIL_SUCCESS,
            intent: Intent.SUCCESS,
          })
        } else {
          return props.actions.commonAddToasterMessage({
            message:
              res?.error?.response?.data?.message ||
              MESSAGER.TOATS_SEND_MAIL_FAIL,
            intent: Intent.DANGER,
          })
        }
      })
    }
  }

  const handleSubmitCancelItem = () => {
    if (!cuocHopId) {
      return setIsOpenPopupConfirmCancel(false)
    }

    setIsLoadingPopup(true)
    props.actions.putHuyLichHop(cuocHopId).then(res => {
      setCuocHopId('')
      if (res.payload && res.payload.data.result === true) {
        handleGetChiTietLichHop()
        props.actions.commonAddToasterMessage({
          message: MESSAGER.TOATS_CANCLE_SUCCESS,
          intent: Intent.SUCCESS,
        })
      } else {
        props.actions.commonAddToasterMessage({
          message: MESSAGER.TOATS_CANCLE_FAIL,
          intent: Intent.DANGER,
        })
      }

      setIsLoadingPopup(false)
      return setIsOpenPopupConfirmCancel(false)
    })
  }

  const handleRemoveFile = index => {
    const result = dataFiles?.filter((item, i) => index !== i)
    setDataFiles(result)
  }

  const handleFileInput = e => {
    let dataFilesNew = []
    Array.from(e.target.files).forEach(item => {
      const type = item.type
      const originalSize = item.size
      if (!ducumentsType()?.some(s => s === type)) {
        return showToast({
          message: variables.VALIDATE.FILE_TYPE_ALLOW([
            'pdf',
            'doc',
            'docx',
            'xls',
            'xlsx',
          ]),
          intent: Intent.WARNING,
        })
      } else if (originalSize > 1024 * 1024 * 50) {
        return showToast({
          message: variables.VALIDATE.FILE_SIZE_ALLOW(50),
          intent: Intent.WARNING,
        })
      } else {
        dataFilesNew.push(item)
      }
    })
    setDataFiles([...dataFilesNew, ...dataFiles])
  }

  const handleSubmitKetThuc = () => {
    setIsLoading(true)
    props.actions.putKetThucCuocHop(detail?.cuocHopId).then(res => {
      if (res.payload && res.payload.data.result === true) {
        setIsLoading(false)
        handleBack()
        return props.actions.commonAddToasterMessage({
          message: 'Kết thúc cuộc họp thành công',
          intent: Intent.SUCCESS,
        })
      } else {
        setIsLoading(false)
        props.actions.commonAddToasterMessage({
          message:
            res?.error?.response?.data?.message || MESSAGER.TOATS_ERROR_SYSTEM,
          intent: Intent.DANGER,
        })
      }
    })
  }

  const handleClickPopupCancel = id => {
    setCuocHopId(id)
    setIsOpenPopupConfirmCancel(true)
  }

  const isShowEditButton = moment(detail?.batDau)
    .subtract(detail?.nhacNho || 0, 'minutes')
    .isAfter(moment())

  useEffect(() => {
    handleGetChiTietLichHop()
  }, [handleGetChiTietLichHop])

  const dsNguoiThamGia = useMemo(() => {
    return detail?.cuocHopThanhPhan
      ?.filter(elm => elm?.nhanVien && !elm?.chuTri)
      ?.map(item => ({
        ...item?.nhanVien,
        thamGia: item.thamGia,
      }))
  }, [detail])

  const dsNguoiChuTri = useMemo(() => {
    return detail?.cuocHopThanhPhan
      ?.filter(elm => elm?.nhanVien && elm?.chuTri)
      ?.map(item => item?.nhanVien?.tenNhanVien)
  }, [detail])

  const trangThai1 = detail?.trangThai === 1
  const trangThai0 = detail?.trangThai === 0
  const isChinhSua = useMemo(() => {
    return trangThai0 || (trangThai1 && isShowEditButton)
  }, [trangThai0, trangThai1, isShowEditButton])

  return (
    <MasterLayout typeSidebar="sidebarCalendar">
      <Title
        name="Chi tiết lịch họp"
        icon="icon-info-circle"
        isGoBack
        history={props.history}
      />
      <div className="form-wrapper form-calendar mt10">
        <div className="form-container">
          <div className="d-flex align-items-center justify-content-space-between min-height-40 ph10 pv20 border-bottom">
            <h3 className="text-14-500-17 color-main text-uppercase ph5">
              Thông tin cuộc họp
            </h3>
            {(trangThai1 || trangThai0 || isChinhSua) && (
              <div>
                <Popover
                  popoverClassName="pt-popover-content-sizing pt-minimal"
                  className="line-height-0"
                  position={Position.BOTTOM_RIGHT}
                  interactionKind={PopoverInteractionKind.HOVER}
                  openOnTargetFocus={true}
                  content={
                    <div className="menu-popover">
                      {trangThai0 && (
                        <span
                          className="item"
                          onClick={() =>
                            handleSubmitGuiCuocHop(detail?.cuocHopId)
                          }
                        >
                          <span className="pt-icon icon-send-2"></span>
                          <span className="name_action">Gửi</span>
                        </span>
                      )}
                      {isChinhSua && (
                        <Link
                          className="item"
                          to={`${LINK}/${detail?.cuocHopId}`}
                        >
                          <span className="pt-icon icon-edit-v2"></span>
                          <span className="name_action">Chỉnh sửa</span>
                        </Link>
                      )}
                      {trangThai1 && (
                        <span
                          className="item"
                          onClick={() =>
                            handleClickPopupCancel(detail.cuocHopId)
                          }
                        >
                          <span className="pt-icon icon-delete-v2"></span>
                          <span className="name_action">Hủy</span>
                        </span>
                      )}
                      {trangThai1 && (
                        <span
                          className="item"
                          onClick={() => setIsOpenPopupKetThuc(true)}
                        >
                          <span className="pt-icon icon2-check-circle"></span>
                          <span className="name_action">Kết thúc</span>
                        </span>
                      )}
                      {trangThai0 && (
                        <span
                          className="item"
                          onClick={() => handleClickPopup(detail.cuocHopId)}
                        >
                          <span className="pt-icon icon-delete-v2"></span>
                          <span className="name_action">Xóa</span>
                        </span>
                      )}
                    </div>
                  }
                >
                  <span className="icon-More cursor-pointer font-size-20"></span>
                </Popover>
              </div>
            )}
          </div>
          <div className="form-padding-box">
            <div className="row">
              <div className="col-xs-12 col-sm-12 col-md-6 form-item-wrap">
                <label className="pt-label">Tiêu đề</label>
                <div className="pt-form-view">{detail?.tieuDe}</div>
              </div>
              <div className="col-xs-12 col-sm-12 col-md-6 form-item-wrap">
                <label className="pt-label">Địa điểm</label>
                <div className="pt-form-view">{detail?.diaDiem?.ten}</div>
              </div>
            </div>
            <div className="row">
              <div className="col-xs-6 col-sm-6 col-md-3 form-item-wrap">
                <label className="pt-label">Loại</label>
                <div className="pt-form-view">{detail?.theLoai?.ten}</div>
              </div>
              <div className="col-xs-6 col-sm-6 col-md-3 form-item-wrap">
                <label className="pt-label">Cấp độ</label>
                <div className="pt-form-view">{detail?.mucDo?.ten}</div>
              </div>
              <div className="col-xs-12 col-sm-12 col-md-3 form-item-wrap">
                <label className="pt-label">Thời gian diễn ra</label>
                <div className="pt-form-view">
                  {detail?.ketThuc
                    ? moment(detail?.batDau).format('DD/MM/YYYY HH:mm') +
                      ' - ' +
                      moment(detail?.ketThuc).format('DD/MM/YYYY HH:mm')
                    : moment(detail?.batDau).format('DD/MM/YYYY HH:mm')}
                </div>
              </div>
            </div>
            <div className="row">
              {(dsNguoiChuTri?.length > 0 ?? false) && (
                <div className="col-xs-12 col-sm-12 col-md-3 form-item-wrap">
                  <label className="pt-label">Chủ trì</label>
                  <div className="pt-form-view">{dsNguoiChuTri.join(', ')}</div>
                </div>
              )}
              <div className="col-xs-3 col-sm-3 col-md-3 form-item-wrap">
                <label className="pt-label">Thông báo email</label>
                <div className="pt-form-view">
                  {detail?.loaiThongBao === 1 || detail?.loaiThongBao === 3
                    ? 'Có'
                    : 'Không'}
                </div>
              </div>
              <div className="col-xs-6 col-sm-6 col-md-3 form-item-wrap">
                <label className="pt-label">Thông báo mobile</label>
                <div className="pt-form-view">
                  {detail?.loaiThongBao === 2 || detail?.loaiThongBao === 3
                    ? 'Có'
                    : 'Không'}
                </div>
              </div>
              <div className="col-xs-6 col-sm-6 col-md-3 form-item-wrap">
                <label className="pt-label">Thời gian nhắc nhở</label>
                <div className="pt-form-view">
                  {detail?.nhacNho ? `Trước ${detail?.nhacNho} phút` : ''}
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-xs-12 col-sm-12 col-md-12 form-item-wrap">
                <label className="pt-label">Nội dung</label>
                <div className="pt-form-view">{detail?.noiDung}</div>
              </div>
            </div>
            <div className="pt-form-group pt-intent-danger">
              <UploadMultiple
                isDetail
                dataFiles={dataFiles}
                handleFileInput={handleFileInput}
                handleRemoveFile={handleRemoveFile}
              />
            </div>
            <div className="row">
              <div className="col-xs-3 col-sm-3 col-md-3 form-item-wrap">
                <label className="pt-label">Trạng thái</label>
                <div className="pt-form-content">
                  <span
                    className={`status-calendar text-content ${renderClassStatus(
                      status
                    )}`}
                  >
                    {renderTrangThai(status)}
                  </span>
                </div>
              </div>
              {(dsNguoiThamGia?.length > 0 ?? false) && (
                <div className="col-xs-3 col-sm-3 col-md-3 form-item-wrap">
                  <label className="pt-label">Danh sách người mời họp</label>
                  <div className="pt-form-content">
                    <span className="total-person">
                      <span className="total-person__number">
                        {dsNguoiThamGia?.length || 0}
                      </span>
                      {dsNguoiThamGia?.length > 0 && (
                        <span
                          className="total-person__more"
                          onClick={() => setShowPopupThamGia(true)}
                        >
                          Xem danh sách
                        </span>
                      )}
                    </span>
                  </div>
                </div>
              )}
            </div>
          </div>
          <h3 className="title-block-form bt">Thông tin hậu cần</h3>
          <div className="form-padding-box">
            <div className="row">
              <div className="col-xs-12 col-sm-12 col-md-12 form-item-wrap">
                <label className="pt-label">Người chuẩn bị</label>
                <div className="pt-form-content">
                  <div className="row">
                    {detail?.cuocHopHauCan?.map((item, i) => (
                      <div key={i} className="col-xs-3 col-sm-3 col-md-3">
                        <UserItem name={item?.nhanVien?.tenNhanVien} />
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="buttton-action-footer">
          <Button
            onClick={() => handleBack()}
            className="pt-button btn-cancel"
            disabled={false}
            loading={isLoading}
          >
            <span className=" pt-icon icon-back"></span>
            <span className="text-content">Quay lại</span>
          </Button>
          {status === 1 && (
            <Button
              className="pt-button btn-orange-color"
              onClick={() => setIsOpenPopupKetThuc(true)}
              loading={isLoading}
            >
              <span className="text-content">Kết thúc</span>
              <span className="pt-icon icon2-check-circle"></span>
            </Button>
          )}
        </div>
      </div>
      <PopupConfirm
        isOpen={isOpenPopupConfirm}
        onClose={handleClickPopup}
        title={POPUP.XOA_LICH_HOP}
        text={POPUP.TEXT_BUTTON_SUBMIT}
        isLoading={isLoadingPopup}
        onSubmit={handleSubmitRemoveItem}
      >
        {POPUP.TEXT_SUBMIT_BODY}
      </PopupConfirm>
      <ModalDanhSachNguoiThamGia
        data={dsNguoiThamGia}
        isOpen={showPopupThamGia}
        onClose={() => setShowPopupThamGia(false)}
      />

      <PopupConfirm
        isOpen={isOpenPopupConfirmCancel}
        onClose={() => setIsOpenPopupConfirmCancel(false)}
        title={POPUP.HUY_LICH_HOP}
        text={POPUP.TEXT_BUTTON_SUBMIT}
        isLoading={isLoadingPopup}
        onSubmit={handleSubmitCancelItem}
      >
        {POPUP.TEXT_SUBMIT_BODY}
      </PopupConfirm>

      <PopupConfirm
        isOpen={isOpenPopupKetThuc}
        onClose={() => setIsOpenPopupKetThuc(false)}
        title={POPUP.KET_THUC_LICH_HOP}
        text={POPUP.TEXT_BUTTON_SUBMIT}
        onSubmit={handleSubmitKetThuc}
        isLoading={isLoading}
      >
        {POPUP.TEXT_SUBMIT_BODY}
      </PopupConfirm>
    </MasterLayout>
  )
}

const mapStateToProps = state => ({
  auth: {
    ...state.auth,
  },
})

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(Actions, dispatch),
})

export default connect(mapStateToProps, mapDispatchToProps)(ChiTietLichHopPage)
