import { Button, Checkbox, Intent } from '@blueprintjs/core'
import { MasterLayout } from 'components/layout'
import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import * as Actions from 'actions'
import Title from 'components/ui/title'

const ChinhSuaLoaiVatTuPage = props => {
  const id = props?.match?.params?.id
  const [formValues, setFormValues] = useState({
    ten: '',
    moTa: '',
    vatTuChaId: '',
    isActive: false,
  })
  const [checkValidate, setCheckValidate] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [listNhomVatTu, setListNhomVatTu] = useState([])

  const handleChange = (name, value) => {
    setCheckValidate(false)
    setFormValues({
      ...formValues,
      [name]: value,
    })
  }
  const handleBack = () => {
    props.history.goBack()
  }

  const handleSubmit = async () => {
    setCheckValidate(true)
    if (!formValues.ten) {
      return
    }
    setIsLoading(true)
    const payload = {
      ten: formValues.ten?.trim(),
      mo_ta: formValues.moTa?.trim(),
      is_active: formValues.isActive,
      vat_tu_cha_id: formValues.vatTuChaId,
    }
    const response = await props.actions.putLoaiVatTu(id, payload)
    if (
      response?.payload?.data?.code === 200 ||
      response?.payload?.data?.code === 201
    ) {
      props.actions.commonAddToasterMessage({
        message: 'Lưu thành công',
        intent: Intent.SUCCESS,
      })
      handleBack()
    } else {
      props.actions.commonAddToasterMessage({
        message: 'Lưu không thành công',
        intent: Intent.DANGER,
      })
    }
    setIsLoading(false)
  }

  const handleGetNhomVatTu = async () => {
    const res = await props.actions.getDanhSachNhomVatTu()
    if (!res.error && res?.payload?.data?.result) {
      const dataNhomVatTu = res?.payload?.data?.result?.items?.map(item => ({
        name: item.ten,
        value: item.vatTuId,
      }))
      setListNhomVatTu(dataNhomVatTu)
    }
  }

  const handleGetChiTietLoaiVatTu = async () => {
    const res = await props.actions.getChiTietLoaiVatTu(id)
    if (!res.error && res?.payload?.data?.result) {
      const dataDetail = res.payload.data.result
      setFormValues({
        ten: dataDetail?.ten,
        moTa: dataDetail?.moTa,
        isActive: dataDetail?.isActive,
        vatTuChaId: dataDetail?.vatTuChaId,
      })
    }
  }

  useEffect(() => {
    handleGetNhomVatTu()
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    if (id) {
      handleGetChiTietLoaiVatTu()
    }
    // eslint-disable-next-line
  }, [id])

  const { ten, moTa, isActive } = formValues
  return (
    <MasterLayout typeSidebar="sidebarCalendar">
      <Title
        name="Chỉnh sửa loại vật tư"
        icon="icon-edit-v2"
        isGoBack
        history={props.history}
      />
      <div className="form-wrapper mt10">
        <div className="form-container">
          <div className="form-padding-box">
            <div className="pt-form-group pt-intent-danger">
              <div className="row">
                <div className="col-xs-12 col-sm-12 col-md-12 form-item">
                  <label className="pt-label">
                    Loại vật tư
                    <span className="pt-text-muted required-input">*</span>
                  </label>
                  <div className="pt-form-content">
                    <input
                      name="ten"
                      value={ten}
                      className="pt-input"
                      placeholder="Nhập nhóm vật tư"
                      type="text"
                      dir="auto"
                      onChange={e => {
                        handleChange('ten', e.target.value)
                      }}
                      autoFocus={true}
                      disabled={false}
                      maxLength={100}
                    />
                    {checkValidate && !ten && (
                      <div className="pt-form-helper-text">
                        Trường này không được bỏ trống
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div className="pt-form-group pt-intent-danger">
              <div className="row">
                <div className="col-xs-12 col-sm-12 col-md-12 form-item">
                  <label className="pt-label" style={{ marginBottom: '5px' }}>
                    Nhóm vật tư
                  </label>
                  <div className="pt-form-content">
                    <select
                      className="input disabled"
                      value={formValues?.vatTuChaId}
                      disabled
                      style={{
                        width: '100%',
                        marginRight: '10px',
                        border: '1px solid #d2d2d2',
                        borderRadius: '4px',
                        height: '39px',
                        boxShadow: '0 2px 2px 0 rgba(0, 0, 0, 0.05)',
                        background: '#F5F5F5',
                      }}
                    >
                      <option key="default" value="">
                        Chọn
                      </option>
                      {listNhomVatTu?.map((item, index) => (
                        <option key={index} value={item.value}>
                          {item.name}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
              </div>
            </div>
            <div className="pt-form-group pt-intent-danger">
              <div className="row">
                <div className="col-xs-12 col-sm-12 col-md-12 form-item">
                  <label className="pt-label">Mô tả</label>
                  <div className="pt-form-content">
                    <textarea
                      name="moTa"
                      value={moTa}
                      rows="5"
                      className="pt-input pt-fill"
                      placeholder="Nhập mô tả"
                      dir="auto"
                      onChange={e => {
                        handleChange('moTa', e.target.value)
                      }}
                      disabled={false}
                      maxLength={500}
                    ></textarea>
                  </div>
                </div>
              </div>
            </div>
            <div className="form-checkbox">
              <label className="pt-label pt-label-inline" htmlFor="isActive">
                Sử dụng
              </label>
              <div className="input-form-inline pt-form-content mb10">
                <Checkbox
                  name="isActive"
                  className="system-checkbox checkbox-custom"
                  checked={isActive}
                  onChange={e => {
                    handleChange('isActive', e.target.checked)
                  }}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="buttton-action-footer">
          <Button
            onClick={() => handleBack()}
            className="pt-button btn-cancel"
            disabled={false}
          >
            <span className=" pt-icon icon-back"></span>
            <span className="text-content">Quay lại</span>
          </Button>
          <Button
            className="pt-button btn-blue-color"
            onClick={() => handleSubmit()}
            loading={isLoading}
            disabled={false}
          >
            <span className="text-content">Lưu</span>
            <span className="pt-icon icon-save"></span>
          </Button>
        </div>
      </div>
    </MasterLayout>
  )
}

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(Actions, dispatch),
})

export default connect(null, mapDispatchToProps)(ChinhSuaLoaiVatTuPage)
