import { Checkbox } from '@blueprintjs/core'
import classNames from 'classnames'
import React from 'react'

const ItemCheckboxToggle = ({
  checked,
  title,
  children,
  handleToggle,
  idsToggle,
  data,
  indeterminate = false,
  disabled = false,
  handleCheckboxChange,
}) => {
  const isToggle = data?.phongBanId
    ? idsToggle?.includes(data?.phongBanId)
    : idsToggle?.includes(data?.donViId)
  return (
    <div className="selection-item-toggle">
      {isToggle ? (
        <i
          className="icon-round-delete-button has-action"
          onClick={() => {
            handleToggle(
              false,
              data?.phongBanId ? data?.phongBanId : data?.donViId
            )
          }}
        />
      ) : (
        <i
          className="icon-rounded-add-button has-action"
          onClick={() => {
            handleToggle(
              true,
              data?.phongBanId ? data?.phongBanId : data?.donViId
            )
          }}
        />
      )}
      <div className="group-data-list">
        <div className={classNames('item-parent', { open: isToggle })}>
          <Checkbox
            label={title}
            checked={checked}
            disabled={disabled}
            indeterminate={indeterminate}
            onChange={e => handleCheckboxChange(data, e.target.checked)}
          />
        </div>
        {isToggle && <div className="list-child">{children}</div>}
      </div>
    </div>
  )
}

export default ItemCheckboxToggle
