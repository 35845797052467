import React, { PureComponent } from 'react'
import { Button, Checkbox, Intent } from '@blueprintjs/core'
import classnames from 'classnames'
import { CheckboxGroup } from '../common'
import * as Tool from '../../helpers'
import _ from 'lodash'
import * as MESSAGE from '../../constants/MessageForm'
import { decamelizeKeys } from 'humps'

const DEFAULT_FILE = {
  sizeFile: undefined,
  error: undefined,
  file: undefined,
  filesProgress: undefined,
}
class LayYKien extends PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      ctCongViec: _.get(props, 'ctCongViec', {}),
      dsFileBaoCao: [{ ...DEFAULT_FILE }],
      dsFileDinhKem: [{ ...DEFAULT_FILE }],
      dsFileThamKhao: [{ ...DEFAULT_FILE }],
      noiDungTrinh: '',
      lanhDaoPhongBanChuTriId: '',
      lanhDaoPhongBanChuTriNhanVienId: '',
      dsThanhVien: [],
      error: {},
      isLoading: {
        hoiDongThanhVien: true,
        lanhDaoPhongBan: true,
        isLoadingSubmit: false,
      },
      checkAll: false,
      dsTrinhLanhDao: [],
      dsHDTV: [],
    }
  }

  componentDidMount() {
    this.initDataLanhDaoPhongBan()
    this.initDataHoiDongThanhVien()
    this.handleGetFileDinhKem(
      _.get(this.props, 'ctCongViec.fileDinhKemEvn', [])
    )
  }

  handleGetFileDinhKem = async data => {
    if (data.length === 0) {
      return
    }

    const dsFileDinhKem = []
    await Promise.all(
      data.map(item => {
        return dsFileDinhKem.push({
          sizeFile: item.kichThuoc,
          error: undefined,
          file: item,
          filesProgress: undefined,
        })
      })
    )

    this.setState({ dsFileDinhKem })
  }

  initDataLanhDaoPhongBan = () => {
    const { auth } = this.props
    const params = {
      quyen: 'HDTV_LAYYKIEN_KYDUYET',
    }
    this.props.actions
      .getLanhDaoPhongBan(auth.mainDepartmentId, params)
      .then(res => {
        if (res.error) {
          return this.setState({
            isLoading: {
              ...this.state.isLoading,
              lanhDaoPhongBan: false,
            },
          })
        }

        this.setState({
          dsTrinhLanhDao: _.get(res, 'payload.data.result.items', []),
          isLoading: {
            ...this.state.isLoading,
            lanhDaoPhongBan: false,
          },
        })
      })
  }

  initDataHoiDongThanhVien = () => {
    const { auth } = this.props
    const params = {
      quyen: 'HDTV_LAYYKIEN_THANHVIEN',
    }
    this.props.actions
      .getHoiDongThanhVien(auth.mainUnitId, params)
      .then(res => {
        if (res.error) {
          return this.setState({
            isLoading: {
              ...this.state.isLoading,
              hoiDongThanhVien: false,
            },
          })
        }

        this.setState({
          dsHDTV: this.convertData(_.get(res, 'payload.data.result.items', [])),
          isLoading: {
            ...this.state.isLoading,
            hoiDongThanhVien: false,
          },
        })
      })
  }

  convertData = data => {
    let newData = []
    data.map((item, i) => {
      return (newData = [
        ...newData,
        {
          data: {
            chucDanhId: item.chucDanhId,
            nhanVienId: item.nhanVienId,
            phongBanId: item.phongBan.phongBanId,
            username: item.username,
            tenNhanVien: item.tenNhanVien,
            stt: i,
          },
          content: item.tenNhanVien,
          value: item.chucDanhId,
        },
      ])
    })

    return newData
  }

  onChangeText = e => {
    const name = e.target.name
    const value = e.target.value
    this.setState({
      [name]: value,
      error: {
        ...this.state.error,
        noiDungTrinh: undefined,
      },
    })
  }

  handleFile = async e => {
    const [name, id] = e.target.name.split('-')
    let reader = new FileReader()
    let file = e.target.files[0]
    const newArray = [...this.state[name]]
    const attachFiles = e.target.value
    const extension = attachFiles
      .slice(((attachFiles.lastIndexOf('.') - 1) >>> 0) + 2)
      .toLowerCase()
    const sizeFile = _.get(file, 'size')
    const typesFile = ['pdf', 'doc', 'docx', 'xls', 'xlsx', 'ppt', 'pptx']
    if (!_.includes(typesFile, extension)) {
      newArray[id].error = MESSAGE.ValidateForm.FILE_TYPE(typesFile)
      newArray[id].file = undefined
      newArray[id].sizeFile = undefined
      newArray[id].filesProgress = undefined
    } else if (sizeFile && sizeFile > 1024 * 1024 * 25) {
      newArray[id].error = MESSAGE.TEXT_MAXIMUM_SIZE_FILE
      newArray[id].file = undefined
      newArray[id].sizeFile = undefined
      newArray[id].filesProgress = undefined
    } else {
      newArray[id].error = undefined
    }

    if (!newArray[id].error) {
      reader.onloadend = () => {
        newArray[id].file = file
        newArray[id].sizeFile = file.size
        this.setState({
          [name]: newArray,
        })
      }
    } else {
      this.setState({
        [name]: newArray,
      })
    }

    if (file) {
      reader.readAsDataURL(file)
    }
  }

  handleRemoveFile = (i, name) => e => {
    const newArray = [...this.state[name]]
    newArray.splice(i, 1)
    this.setState({ [name]: newArray })
  }

  handleAddFile = name => e => {
    const newArray = [...this.state[name]]
    newArray.push({ ...DEFAULT_FILE })
    this.setState({ [name]: newArray })
  }

  handleChangeSelect = e => {
    const value = e.target.value
    this.setState({
      lanhDaoPhongBanChuTriId: value,
      error: {
        ...this.state.error,
        trinhLanhDaoId: undefined,
      },
    })
  }

  onChangeCheckBox = e => {
    const value = e.target.value
    let dsThanhVien = [...this.state.dsThanhVien]
    let { checkAll, dsHDTV } = this.state
    const exist = !_.includes(dsThanhVien, value)
    if (exist) {
      dsThanhVien.push(value)
    } else {
      dsThanhVien = dsThanhVien.filter(item => item !== value)
    }

    if (dsThanhVien.length === dsHDTV.length) {
      checkAll = true
    } else {
      checkAll = false
    }

    this.setState({
      dsThanhVien,
      checkAll,
      error: {
        ...this.state.error,
        dsThanhVien: undefined,
      },
    })
  }

  onChangeCheckAll = e => {
    const checked = e.target.checked
    if (!checked) {
      this.setState({
        dsThanhVien: [],
        checkAll: false,
        error: {
          ...this.state.error,
          dsThanhVien: undefined,
        },
      })
    } else {
      this.setState({
        dsThanhVien: _.map(this.state.dsHDTV, item => item.value),
        checkAll: true,
        error: {
          ...this.state.error,
          dsThanhVien: undefined,
        },
      })
    }
  }

  handleSubmit = async e => {
    e.preventDefault()
    if (
      [
        ...this.state.dsFileDinhKem,
        ...this.state.dsFileThamKhao,
        ...this.state.dsFileBaoCao,
      ].find(item => item.error)
    ) {
      return
    }

    await this.setState({
      error: {},
      isLoading: {
        ...this.state.isLoading,
        isLoadingSubmit: true,
      },
    })
    const {
      noiDungTrinh,
      error,
      dsFileDinhKem,
      dsFileThamKhao,
      dsFileBaoCao,
      lanhDaoPhongBanChuTriId,
      dsThanhVien,
      ctCongViec,
      dsHDTV,
      dsTrinhLanhDao,
    } = this.state
    if (_.trim(lanhDaoPhongBanChuTriId).length === 0) {
      error.trinhLanhDaoId = MESSAGE.TEXT_MESSAGE_DEFAULT
    }

    if (dsThanhVien.length === 0) {
      error.dsThanhVien = MESSAGE.TEXT_MESSAGE_DEFAULT
    }

    if (Object.keys(error).length !== 0) {
      await this.setState({
        isLoading: {
          ...this.state.isLoading,
          isLoadingSubmit: false,
        },
      })
      return this.props.onSubmit(
        'LayYKien',
        MESSAGE.TOATS_MESSAGE_WARNING,
        Intent.WARNING,
        false
      )
    }

    let newFileDinhKem = []
    let newFileThamKhao = []
    let newFileBaoCao = []
    if (dsFileDinhKem.length || dsFileThamKhao.length || dsFileBaoCao.length) {
      try {
        await Promise.all(
          dsFileBaoCao.map(async (item, i) => {
            if (!item.file) {
              return newFileBaoCao
            }
            if (item.file) {
              const data = await this.props.actions.fileUpload(
                item.file,
                this.getUploadProgress('dsFileBaoCao', i)
              )
              return newFileBaoCao.push({
                file_id: _.get(data.payload.data.result[0], 'id'),
                kieu_file: _.get(data.payload.data.result[0], 'extension'),
                ten_file: _.get(data.payload.data.result[0], 'originalName'),
                url: _.get(data.payload.data.result[0], 'path'),
                kich_thuoc: _.get(data.payload.data.result[0], 'length'),
              })
            }
          })
        )
        await Promise.all(
          dsFileDinhKem.map(async (item, i) => {
            if (!item.file) {
              return newFileDinhKem
            }
            if (item.file.fileId) {
              return newFileDinhKem.push({
                file_id: item.file.fileId,
                kieu_file: item.file.kieuFile,
                ten_file: item.file.tenFile,
                url: item.file.url,
                kich_thuoc: item.file.kichThuoc,
                is_existed: true,
              })
            }
            if (item.file) {
              const data = await this.props.actions.fileUpload(
                item.file,
                this.getUploadProgress('dsFileDinhKem', i)
              )
              return newFileDinhKem.push({
                file_id: _.get(data.payload.data.result[0], 'id'),
                kieu_file: _.get(data.payload.data.result[0], 'extension'),
                ten_file: _.get(data.payload.data.result[0], 'originalName'),
                url: _.get(data.payload.data.result[0], 'path'),
                kich_thuoc: _.get(data.payload.data.result[0], 'length'),
              })
            }
          })
        )
        await Promise.all(
          dsFileThamKhao.map(async (item, i) => {
            if (!item.file) {
              return newFileThamKhao
            }
            if (item.file) {
              const data = await this.props.actions.fileUpload(
                item.file,
                this.getUploadProgress('dsFileThamKhao', i)
              )
              return newFileThamKhao.push({
                file_id: _.get(data.payload.data.result[0], 'id'),
                kieu_file: _.get(data.payload.data.result[0], 'extension'),
                ten_file: _.get(data.payload.data.result[0], 'originalName'),
                url: _.get(data.payload.data.result[0], 'path'),
                kich_thuoc: _.get(data.payload.data.result[0], 'length'),
              })
            }
          })
        )
      } catch (error) {
        this.setState({
          isLoading: {
            ...this.state.isLoading,
            isLoadingSubmit: false,
          },
        })
        return this.props.onSubmit(
          'LayYKien',
          MESSAGE.TOATS_MESSAGE_FAIL,
          Intent.DANGER,
          false
        )
      }
    }

    const data = {
      congVanId: ctCongViec.congVanId,
      congVanNoiBoId: ctCongViec.congVanNoiBoId,
      congViecCaNhanId: ctCongViec.congViecCaNhanId,
      soKyHieu: ctCongViec.soKyHieu,
      ngayCongVan: ctCongViec.ngayCongVan,
      trichYeu: ctCongViec.trichYeu,
      fileNoiDung: ctCongViec.fileCongVan,
      dsFileDinhKem: newFileDinhKem,
      dsFileThamKhao: newFileThamKhao,
      fileBaoCao: newFileBaoCao[0],
      noiDungTrinh,
      lanhDaoPhongBanChuTriId,
      lanhDaoPhongBanChuTriNhanVienId: dsTrinhLanhDao.find(
        item => item.chucDanhId === lanhDaoPhongBanChuTriId
      ).nhanVienId,
      dsThanhVien: _.map(
        dsHDTV.filter(item => _.includes(dsThanhVien, item.value)),
        item => item.data
      ),
    }
    this.props.actions.postPhieuLayYKien(decamelizeKeys(data)).then(res => {
      if (!res || res.error) {
        this.setState({
          isLoading: {
            ...this.state.isLoading,
            isLoadingSubmit: false,
          },
        })
        return this.props.onSubmit(
          'LayYKien',
          MESSAGE.TOATS_MESSAGE_FAIL,
          Intent.DANGER,
          false
        )
      } else if (res.payload && res.payload.status === 200) {
        this.setState({
          isLoading: {
            ...this.state.isLoading,
            isLoadingSubmit: false,
          },
        })
        this.props.onSubmit(
          'LayYKien',
          MESSAGE.TEXT_REPORT_WORK_SUCCESS,
          Intent.SUCCESS,
          true
        )
      }
    })
  }

  getUploadProgress = (name, i) => progress => {
    const newArray = [...this.state[name]]
    if (!isNaN(i) && name) {
      newArray[i].filesProgress = Math.floor(
        (progress.loaded * 100) / progress.total
      )
    }

    if (Math.floor((progress.loaded * 100) / progress.total === 100)) {
      newArray[i].filesProgress = undefined
    }

    this.setState({ [name]: newArray })
  }

  render() {
    const {
      noiDungTrinh,
      dsFileDinhKem,
      dsFileThamKhao,
      dsFileBaoCao,
      lanhDaoPhongBanChuTriId,
      error,
      isLoading,
      checkAll,
      dsTrinhLanhDao,
      dsHDTV,
      dsThanhVien,
    } = this.state
    return (
      <div className="container-form-action">
        <div className="heading">
          <span onClick={this.props.onClickGoBack}>
            <span className="pt-icon pt-icon-arrow-left pull-left"></span>
          </span>
          <h4>Lấy ý kiến</h4>
        </div>
        <div className="form-container form-send-mail lay-y-kien-container">
          <div className="pt-form-group pt-intent-danger">
            <div className="row full-row">
              <div className="col-sx-12 col-md-12 col-sm-12">
                <label className="pt-label">Nội dung trình HĐTV</label>
              </div>
            </div>
            <div className="row full-row">
              <div className="col-sx-12 col-md-12 col-sm-12">
                <textarea
                  name="noiDungTrinh"
                  rows="4"
                  className="pt-input pt-fill"
                  placeholder="Nhập nội dung…"
                  dir="auto"
                  autoFocus={true}
                  value={noiDungTrinh}
                  disabled={
                    Object.values(isLoading).find(item => item === true) !==
                    undefined
                  }
                  onChange={this.onChangeText}
                  maxLength={255}
                />
                <div className="pt-form-helper-text pt-report-work">
                  {error.noiDungTrinh && (
                    <div className="pt-form-helper-text pt-report-work">
                      {error.noiDungTrinh}
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div className="border-line"></div>
            <div className="row full-row">
              <div className="col-xs-12 col-md-12 col-sm-12">
                <label className="pt-label">
                  File báo cáo
                  <span className="text-not-bold">
                    {` (file báo cáo của Ban TH)`}
                  </span>
                </label>
              </div>
              {dsFileBaoCao.map((item, i) => (
                <div className="col-xs-12 col-md-12 col-sm-12" key={i}>
                  <div
                    className={classnames('pt-form-content', {
                      'upload-have-close':
                        i > 0 || _.get(dsFileBaoCao[0], 'file'),
                    })}
                  >
                    <label className="pt-file-upload">
                      <input
                        type="file"
                        name={`dsFileBaoCao-${i}`}
                        onChange={this.handleFile}
                        disabled={
                          Object.values(isLoading).find(
                            item => item === true
                          ) !== undefined
                        }
                        onClick={event => {
                          event.target.value = null
                        }}
                      />
                      <span className="pt-file-upload-input">
                        {_.get(dsFileBaoCao[i], 'file.name') ||
                          _.get(dsFileBaoCao[i], 'file.tenFile') ||
                          'Chọn file đính kèm'}
                      </span>
                    </label>
                    {i > 0 &&
                      Object.values(isLoading).find(item => item === true) ===
                        undefined && (
                        <span
                          onClick={this.handleRemoveFile(i, 'dsFileBaoCao')}
                          className="icon-bc icon-close icon-remove-file"
                        />
                      )}
                    {_.get(dsFileBaoCao[i], 'sizeFile') && (
                      <div className="pt-form-helper-text pt-form-helper-infomation">
                        Kích thước file đã upload:
                        <span className="size-file">
                          {' '}
                          {Tool.Utils.getFileSize(
                            _.get(dsFileBaoCao[i], 'sizeFile')
                          )}
                        </span>
                      </div>
                    )}
                    {_.get(dsFileBaoCao[i], 'filesProgress') && (
                      <div className="progress-upload-content">
                        <span className="percent-content">
                          {_.get(dsFileBaoCao[i], 'filesProgress')}%
                        </span>
                        <div className="pt-progress-bar pt-intent-primary progress-bar-upload">
                          <div
                            className="pt-progress-meter"
                            style={{
                              width:
                                _.get(dsFileBaoCao[i], 'filesProgress') + '%',
                            }}
                          ></div>
                        </div>
                      </div>
                    )}
                    <div className="pt-form-helper-text">
                      {_.get(dsFileBaoCao[i], 'error') && (
                        <div className="pt-form-helper-text mb5">
                          {_.get(dsFileBaoCao[i], 'error')}
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              ))}
            </div>
            <div className="row full-row">
              <div className="col-xs-12 col-md-12 col-sm-12">
                <label className="pt-label">
                  Các file đính kèm
                  <span className="text-not-bold">
                    {` (các văn bản đính kèm theo công văn)`}
                  </span>
                </label>
              </div>
              {dsFileDinhKem.map((item, i) => (
                <div className="col-xs-12 col-md-12 col-sm-12" key={i}>
                  <div
                    className={classnames('pt-form-content', {
                      'upload-have-close':
                        i > 0 || _.get(dsFileDinhKem[0], 'file'),
                    })}
                  >
                    <label className="pt-file-upload">
                      <input
                        type="file"
                        name={`dsFileDinhKem-${i}`}
                        onChange={this.handleFile}
                        disabled={
                          Object.values(isLoading).find(
                            item => item === true
                          ) !== undefined
                        }
                        onClick={event => {
                          event.target.value = null
                        }}
                      />
                      <span className="pt-file-upload-input">
                        {_.get(dsFileDinhKem[i], 'file.name') ||
                          _.get(dsFileDinhKem[i], 'file.tenFile') ||
                          'Chọn file đính kèm'}
                      </span>
                    </label>
                    {i > 0 &&
                      Object.values(isLoading).find(item => item === true) ===
                        undefined && (
                        <span
                          onClick={this.handleRemoveFile(i, 'dsFileDinhKem')}
                          className="icon-bc icon-close icon-remove-file"
                        />
                      )}
                    {_.get(dsFileDinhKem[i], 'sizeFile') && (
                      <div className="pt-form-helper-text pt-form-helper-infomation">
                        Kích thước file đã upload:
                        <span className="size-file">
                          {' '}
                          {Tool.Utils.getFileSize(
                            _.get(dsFileDinhKem[i], 'sizeFile')
                          )}
                        </span>
                      </div>
                    )}
                    {_.get(dsFileDinhKem[i], 'filesProgress') && (
                      <div className="progress-upload-content">
                        <span className="percent-content">
                          {_.get(dsFileDinhKem[i], 'filesProgress')}%
                        </span>
                        <div className="pt-progress-bar pt-intent-primary progress-bar-upload">
                          <div
                            className="pt-progress-meter"
                            style={{
                              width:
                                _.get(dsFileDinhKem[i], 'filesProgress') + '%',
                            }}
                          ></div>
                        </div>
                      </div>
                    )}
                    <div className="pt-form-helper-text">
                      {_.get(dsFileDinhKem[i], 'error') && (
                        <div className="pt-form-helper-text mb5">
                          {_.get(dsFileDinhKem[i], 'error')}
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              ))}
              <div className="col-xs-12 col-md-12 col-sm-12 mt10">
                <div className="pt-form-content">
                  <Button
                    className="btn-add-file"
                    onClick={this.handleAddFile('dsFileDinhKem')}
                    disabled={
                      Object.values(isLoading).find(item => item === true) !==
                      undefined
                    }
                  >
                    <span>Thêm file</span>
                    <span className="icon-bc icon-Plus"></span>
                  </Button>
                </div>
              </div>
            </div>
            <div className="row full-row mt10">
              <div className="col-xs-12 col-md-12 col-sm-12">
                <label className="pt-label">Các file tham khảo</label>
              </div>
              {dsFileThamKhao.map((item, i) => (
                <div className="col-xs-12 col-md-12 col-sm-12" key={i}>
                  <div
                    className={classnames('pt-form-content', {
                      'upload-have-close':
                        i > 0 || _.get(dsFileThamKhao[0], 'file'),
                    })}
                  >
                    <label className="pt-file-upload">
                      <input
                        type="file"
                        name={`dsFileThamKhao-${i}`}
                        onChange={this.handleFile}
                        disabled={
                          Object.values(isLoading).find(
                            item => item === true
                          ) !== undefined
                        }
                        onClick={event => {
                          event.target.value = null
                        }}
                      />
                      <span className="pt-file-upload-input">
                        {_.get(dsFileThamKhao[i], 'file.name') ||
                          'Chọn file đính kèm'}
                      </span>
                    </label>
                    {i > 0 &&
                      Object.values(isLoading).find(item => item === true) ===
                        undefined && (
                        <span
                          onClick={this.handleRemoveFile(i, 'dsFileThamKhao')}
                          className="icon-bc icon-close icon-remove-file"
                        />
                      )}
                    {_.get(dsFileThamKhao[i], 'sizeFile') && (
                      <div className="pt-form-helper-text pt-form-helper-infomation">
                        Kích thước file đã upload:
                        <span className="size-file">
                          {' '}
                          {Tool.Utils.getFileSize(
                            _.get(dsFileThamKhao[i], 'sizeFile')
                          )}
                        </span>
                      </div>
                    )}
                    {_.get(dsFileThamKhao[i], 'filesProgress') && (
                      <div className="progress-upload-content">
                        <span className="percent-content">
                          {_.get(dsFileThamKhao[i], 'filesProgress')}%
                        </span>
                        <div className="pt-progress-bar pt-intent-primary progress-bar-upload">
                          <div
                            className="pt-progress-meter"
                            style={{
                              width:
                                _.get(dsFileThamKhao[i], 'filesProgress') + '%',
                            }}
                          ></div>
                        </div>
                      </div>
                    )}
                    <div className="pt-form-helper-text">
                      {_.get(dsFileThamKhao[i], 'error') && (
                        <div className="pt-form-helper-text mb5">
                          {_.get(dsFileThamKhao[i], 'error')}
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              ))}
              <div className="col-xs-12 col-md-12 col-sm-12 mt10">
                <div className="pt-form-content">
                  <Button
                    className="btn-add-file"
                    onClick={this.handleAddFile('dsFileThamKhao')}
                    disabled={
                      Object.values(isLoading).find(item => item === true) !==
                      undefined
                    }
                  >
                    <span>Thêm file</span>
                    <span className="icon-bc icon-Plus"></span>
                  </Button>
                </div>
              </div>
            </div>
            <div className="row full-row mt10">
              <div className="col-xs-12 col-md-12 col-sm-12">
                <label className="pt-label">
                  Trình LĐ phòng ban
                  <span className="pt-text-muted required-input">*</span>
                </label>
              </div>
              <div className="col-xs-12 col-md-12 col-sm-12">
                <div className="pt-form-content">
                  <div className="pt-select">
                    <select
                      className={classnames({
                        'loading-input': _.get(
                          isLoading,
                          'lanhDaoPhongBan',
                          false
                        ),
                      })}
                      name="trinhLanhDaoId"
                      onChange={this.handleChangeSelect}
                      value={lanhDaoPhongBanChuTriId}
                      disabled={
                        Object.values(isLoading).find(item => item === true) !==
                        undefined
                      }
                    >
                      <option value="">Chọn lãnh đạo phòng ban</option>
                      {dsTrinhLanhDao.map((item, i) => (
                        <option key={i} value={item.chucDanhId}>
                          {item.tenNhanVien}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
                <div className="pt-form-helper-text pt-report-work">
                  {error.trinhLanhDaoId && (
                    <div className="pt-form-helper-text pt-report-work">
                      {error.trinhLanhDaoId}
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div className="row full-row mt10 checkbox-hdtv">
              <div className="col-xs-12 col-md-6 col-xs-12 col-sm-6">
                <label className="pt-label">
                  Hội đồng thành viên
                  <span className="pt-text-muted required-input">*</span>
                </label>
              </div>
              <div className="col-xs-12 col-md-6 col-xs-12 col-sm-6 checkbox-item">
                <Checkbox
                  checked={checkAll}
                  disabled={
                    Object.values(isLoading).find(item => item === true) !==
                    undefined
                  }
                  onChange={this.onChangeCheckAll}
                >
                  <span className="name_checkbox">Chọn tất cả</span>
                </Checkbox>
              </div>
            </div>
            {!_.isEmpty(dsHDTV) && (
              <div className="row full-row p10 checkbox-hdtv">
                <div className="col-xs-12 col-md-12 col-sm-12 container-checkbox">
                  <CheckboxGroup
                    className="checkbox-group"
                    name="hoiDongThanhVien"
                    colum="col-xs-12 col-sm-12 col-md-12"
                    onChange={this.onChangeCheckBox}
                    options={dsHDTV}
                    selectedOptions={dsThanhVien}
                    isDisabledAll={
                      Object.values(isLoading).find(item => item === true) !==
                      undefined
                    }
                  />
                </div>
                <div className="col-sx-12 col-md-12 col-sm-12">
                  <div className="pt-form-helper-text pt-report-work">
                    {error.dsThanhVien && (
                      <div className="pt-form-helper-text pt-report-work">
                        {error.dsThanhVien}
                      </div>
                    )}
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
        <div className="buttton-action-footer">
          <Button
            className="pt-button btn-cancel"
            onClick={this.props.onClickGoBack}
            disabled={
              Object.values(isLoading).find(item => item === true) !== undefined
            }
          >
            <span className=" pt-icon icon-back"></span>
            <span className="text-content">Quay lại</span>
          </Button>
          <Button
            className="pt-button btn-blue-color"
            onClick={this.handleSubmit}
            loading={
              Object.values(isLoading).find(item => item === true) !== undefined
            }
          >
            <span className="text-content">Lưu</span>
            <span className="pt-icon icon-save"></span>
          </Button>
        </div>
      </div>
    )
  }
}

export default LayYKien
