import React from 'react'
import { Intent, Checkbox, Button } from '@blueprintjs/core'
import classnames from 'classnames'
import * as MESSAGER from '../../constants/MessageForm'
import { SelectTree } from '../common/'
import { Utils } from '../../helpers'

const PATH = '/he-thong/don-vi/chinh-sua/:id'

class FormAddUnitCategory extends React.Component {
  constructor(props) {
    super(props)

    this.handleChange = this.handleChange.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
    this.handleGoBack = this.handleGoBack.bind(this)
    this.handleSelect = this.handleSelect.bind(this)
  }

  state = {
    donViId: '',
    donViCha: '',
    tenDonVi: '',
    tenVietTat: '',
    maDonVi: '',
    thanhPho: '',
    isActive: true,
    isLoading: false,
    error: {},
    stt: null,
    isOpenPopup: false,
    unit: null,
    unitSelect: [],
    isLoadingData: {
      tenDonVi: true,
      tenVietTat: true,
      maDonVi: true,
      unitSelect: true,
      thanhPho: true,
    },
    // dongYKhongCanKySo: true,
  }

  componentDidMount() {
    let isUnit = Utils.getData('UnitCategoryPage', {})
    let header = Utils.getData('UnitCategoryPage-Header', [])

    this.setState({ donViCha: header[header.length - 1].id, donViId: isUnit })
  }

  handleChange(e) {
    if (e.target.type === 'text') {
      this.setState({ [e.target.name]: e.target.value })
    } else if (e.target.type === 'checkbox') {
      this.setState({ [e.target.name]: e.target.checked })
    } else if (e.target.name === 'donViCha') {
      this.setState({ [e.target.name]: e.target.value })
    }
  }

  handleSelect(e) {
    if (e !== undefined && this.props.match.params.id !== e) {
      this.setState({
        donViCha: e,
      })
    }
  }

  componentWillReceiveProps = async nextProps => {
    if (nextProps.unit !== this.props.unit) {
      if (!nextProps.unit) {
        return
      }
      this.setState(
        {
          isActive: nextProps.unit.isActive ? true : false,
          donViId: nextProps.unit.donViId || '',
          donViCha: nextProps.unit.donViCha || '',
          tenDonVi: nextProps.unit.tenDonVi || '',
          tenVietTat: nextProps.unit.tenVietTat || '',
          maDonVi: nextProps.unit.maDonVi || '',
          thanhPho: nextProps.unit.thanhPho || '',
          unit: nextProps.unit,
          stt: nextProps.unit.stt,
          // dongYKhongCanKySo: nextProps.unit.dongYKhongCanKySo ? true : false,
        },
        () => {
          this.setState({
            isLoadingData: {
              tenDonVi: false,
              tenVietTat: false,
              maDonVi: false,
              unitSelect: false,
              thanhPho: false,
            },
          })
        }
      )
    }

    if (nextProps.unitSelect !== this.props.unitSelect) {
      const unitSelect = { donViId: '', tenVietTat: 'Không chọn đơn vị cha' }
      nextProps.unitSelect.push(unitSelect)
      await this.setState({
        unitSelect: nextProps.unitSelect,
        isLoadingData: {
          ...this.state.isLoadingData,
          unitSelect: false,
        },
      })
    }

    if (nextProps.unit === this.props.unit && this.props.match.path !== PATH) {
      await this.setState({
        isLoadingData: {
          ...this.state.isLoadingData,
          tenDonVi: false,
          maDonVi: false,
          tenVietTat: false,
          thanhPho: false,
        },
      })
    }
  }

  handleSubmit(e) {
    e.preventDefault()
    this.setState({ error: {} })
    const error = {}

    if (this.state.tenDonVi.trim().length === 0) {
      error.tenDonVi = MESSAGER.TEXT_MESSAGE_DEFAULT
    }

    if (this.state.tenVietTat.trim().length === 0) {
      error.tenVietTat = MESSAGER.TEXT_MESSAGE_DEFAULT
    }

    if (this.state.maDonVi.trim().length === 0) {
      error.maDonVi = MESSAGER.TEXT_MESSAGE_DEFAULT
    }

    if (this.state.thanhPho.trim().length === 0) {
      error.thanhPho = MESSAGER.TEXT_MESSAGE_DEFAULT
    }

    this.setState({ error, isLoading: true })
    if (Object.keys(error).length !== 0) {
      this.setState({ isLoading: false })
      return this.props.actions.commonAddToasterMessage({
        message: MESSAGER.TOATS_MESSAGE_WARNING,
        intent: Intent.WARNING,
      })
    }

    this.setState({ isLoading: true })
    if (this.props.unit) {
      this.props.actions
        .updateCommonFetchUnit({
          is_active: this.state.isActive,
          don_vi_id: this.state.donViId,
          don_vi_cha:
            this.state.donViCha.trim().length > 0 ? this.state.donViCha : null,
          ten_don_vi: this.state.tenDonVi,
          ten_viet_tat: this.state.tenVietTat,
          ma_don_vi: this.state.maDonVi,
          thanh_pho: this.state.thanhPho,
          stt: this.state.stt,
          // dong_y_khong_can_ky_so: this.state.dongYKhongCanKySo,
        })
        .then(res => {
          if (
            res.payload &&
            res.payload.data.result &&
            res.payload.data.result === true
          ) {
            this.setState({ isLoading: false })
            this.props.onSubmit && this.props.onSubmit(true)
          } else {
            this.setState({ isLoading: false })
            this.props.actions.commonAddToasterMessage({
              message: MESSAGER.TOATS_MESSAGE_FAIL,
              intent: Intent.DANGER,
            })
          }
        })
    } else {
      this.props.actions
        .postCommonFetchUnit({
          is_active: this.state.isActive,
          don_vi_cha:
            this.state.donViCha.trim().length > 0 ? this.state.donViCha : null,
          ten_don_vi: this.state.tenDonVi,
          ten_viet_tat: this.state.tenVietTat,
          ma_don_vi: this.state.maDonVi,
          thanh_pho: this.state.thanhPho,
          // dong_y_khong_can_ky_so: this.state.dongYKhongCanKySo,
        })
        .then(res => {
          if (
            res.payload &&
            res.payload.data.result &&
            res.payload.data.result === true
          ) {
            this.setState({ isLoading: false })
            this.props.onSubmit && this.props.onSubmit(true)
          } else {
            this.setState({ isLoading: false })
            this.props.actions.commonAddToasterMessage({
              message: MESSAGER.TOATS_MESSAGE_FAIL,
              intent: Intent.DANGER,
            })
          }
        })
    }
  }

  handleGoBack() {
    this.props.onSubmit && this.props.onSubmit()
  }

  render() {
    return (
      <div className="systerm-main-content">
        <div className="form-container form-inline-container systerm-form-container">
          <form className="main-form systerm-form">
            <div className="form-block">
              <div className="form-subblock">
                <div className="form-row">
                  <div className="form-cols form-col5">
                    <div className="form-group-item pt-form-group pt-intent-danger">
                      <label
                        className="pt-label pt-label-inline"
                        htmlFor="tenDonVi"
                      >
                        Tên đơn vị
                        <span className="pt-text-muted required-input">*</span>
                      </label>
                      <div className="input-form-inline pt-form-content">
                        <input
                          type="text"
                          value={this.state.tenDonVi}
                          className={classnames('pt-input', {
                            'loading-input':
                              Object.values(this.state.isLoadingData).find(
                                item => item === true
                              ) !== undefined,
                          })}
                          disabled={
                            Object.values(this.state.isLoadingData).find(
                              item => item === true
                            ) !== undefined
                          }
                          name="tenDonVi"
                          placeholder="Nhập tên đơn vị"
                          onChange={this.handleChange}
                        />
                        {this.state.error.tenDonVi !== undefined && (
                          <div className="pt-form-content pt-form-helper-text">
                            {this.state.error.tenDonVi}
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="form-cols form-col5">
                    <div className="form-group-item pt-form-group pt-intent-danger">
                      <label
                        className="pt-label pt-label-inline"
                        htmlFor="tenDonVi"
                      >
                        Tên viết tắt
                        <span className="pt-text-muted required-input">*</span>
                      </label>
                      <div className="input-form-inline pt-form-content">
                        <input
                          type="text"
                          value={this.state.tenVietTat}
                          className={classnames('pt-input', {
                            'loading-input':
                              Object.values(this.state.isLoadingData).find(
                                item => item === true
                              ) !== undefined,
                          })}
                          disabled={
                            Object.values(this.state.isLoadingData).find(
                              item => item === true
                            ) !== undefined
                          }
                          name="tenVietTat"
                          placeholder="Nhập tên viết tắt"
                          onChange={this.handleChange}
                        />
                        {this.state.error.tenVietTat !== undefined && (
                          <div className="pt-form-content pt-form-helper-text">
                            {this.state.error.tenVietTat}
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="form-row">
                  <div className="form-cols form-col5">
                    <div className="form-group-item pt-form-group pt-intent-danger">
                      <label
                        className="pt-label pt-label-inline"
                        htmlFor="tenVietTat"
                      >
                        Đơn vị cha
                      </label>
                      <div className="input-form-inline pt-form-content">
                        <SelectTree
                          className="select-tree"
                          data={this.state.unitSelect}
                          type="Unit"
                          permission={false}
                          value={
                            Object.values(this.state.isLoadingData).find(
                              item => item === true
                            ) === undefined
                              ? this.state.donViCha
                              : ''
                          }
                          handleChange={this.handleSelect}
                          isLoading={
                            Object.values(this.state.isLoadingData).find(
                              item => item === true
                            ) !== undefined
                          }
                          match={this.props.match}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="form-cols form-col5">
                    <div className="form-group-item pt-form-group pt-intent-danger">
                      <label
                        className="pt-label pt-label-inline"
                        htmlFor="tenDonVi"
                      >
                        Mã đơn vị
                        <span className="pt-text-muted required-input">*</span>
                      </label>
                      <div className="input-form-inline pt-form-content">
                        <input
                          type="text"
                          value={this.state.maDonVi}
                          className={classnames('pt-input', {
                            'loading-input':
                              Object.values(this.state.isLoadingData).find(
                                item => item === true
                              ) !== undefined,
                          })}
                          disabled={
                            Object.values(this.state.isLoadingData).find(
                              item => item === true
                            ) !== undefined
                          }
                          name="maDonVi"
                          maxLength={10}
                          placeholder="Nhập mã đơn vị"
                          onChange={this.handleChange}
                        />
                        {this.state.error.maDonVi !== undefined && (
                          <div className="pt-form-content pt-form-helper-text">
                            {this.state.error.maDonVi}
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="form-cols form-col5">
                    <div className="form-group-item pt-form-group pt-intent-danger">
                      <label className="pt-label pt-label-inline">
                        Tên thành phố
                        <span className="pt-text-muted required-input">*</span>
                      </label>
                      <div className="input-form-inline pt-form-content">
                        <input
                          type="text"
                          value={this.state.thanhPho}
                          className={classnames('pt-input', {
                            'loading-input':
                              Object.values(this.state.isLoadingData).find(
                                item => item === true
                              ) !== undefined,
                          })}
                          disabled={
                            Object.values(this.state.isLoadingData).find(
                              item => item === true
                            ) !== undefined
                          }
                          name="thanhPho"
                          placeholder="Nhập tên thành phố"
                          onChange={this.handleChange}
                        />
                        {this.state.error.thanhPho !== undefined && (
                          <div className="pt-form-content pt-form-helper-text">
                            {this.state.error.thanhPho}
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="form-row">
                  <div className="form-cols form-col5">
                    <div className="form-group-item form-group-inline">
                      <label
                        className="pt-label pt-label-inline"
                        htmlFor="sudung"
                      >
                        Sử dụng
                      </label>
                      <div className="input-form-inline">
                        <Checkbox
                          name="isActive"
                          onChange={this.handleChange}
                          disabled={
                            Object.values(this.state.isLoadingData).find(
                              item => item === true
                            ) !== undefined
                          }
                          checked={this.state.isActive}
                        />
                      </div>
                    </div>
                  </div>
                  {/* <div className="form-cols form-col5">
                    <div className="form-group-item form-group-inline">
                      <label
                        className="pt-label pt-label-inline"
                        htmlFor="sudung"
                      >
                        Đồng ý không cần ký số
                      </label>
                      <div className="input-form-inline">
                        <Checkbox
                          name="dongYKhongCanKySo"
                          onChange={this.handleChange}
                          disabled={
                            Object.values(this.state.isLoadingData).find(
                              item => item === true
                            ) !== undefined
                          }
                          checked={this.state.dongYKhongCanKySo}
                        />
                      </div>
                    </div>
                  </div> */}
                </div>
              </div>
            </div>
            <div className="form-block bottom-form">
              <div className="buttton-action-footer">
                <Button
                  type="button"
                  className="pt-button pt-button btn-cancel"
                  onClick={this.handleGoBack}
                  disabled={this.state.isLoading === true ? true : false}
                >
                  <span className=" pt-icon icon-back"></span>
                  <span className="text-content">Quay lại</span>
                </Button>
                <Button
                  type="button"
                  className="pt-button pt-button btn-blue-color"
                  loading={this.state.isLoading}
                  onClick={this.handleSubmit}
                  disabled={
                    Object.values(this.state.isLoadingData).find(
                      item => item === true
                    ) !== undefined
                  }
                >
                  <span className="text-content">Lưu</span>
                  <span className="pt-icon icon-save"></span>
                </Button>
              </div>
            </div>
          </form>
        </div>
      </div>
    )
  }
}

export default FormAddUnitCategory
