import React, { Component } from 'react'
import classnames from 'classnames'
import { Button, Intent } from '@blueprintjs/core'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { get, join, intersection, intersectionBy, isEmpty, size } from 'lodash'
import { MasterLayout } from '../../../components/layout'
import {
  DanhSachTaiLieu,
  CapNhatChiTietHoSoCongViec,
  TiepNhan,
  ThongTinDuyet,
  LichSuLuuTru,
} from '../../../components/tutailieu'
import { HeadingPage, Fragment, LoadingItem } from '../../../components/common'
import { Authorization, Permission } from '../../../components/auth'
import {
  CABINET_UNIT,
  CABINET_DEPARTMENT,
  CABINET_PERSONAL,
  STATUS_HSCV,
} from '../../../constants/Api'
import {
  commonFetchUnitsRelative,
  commonAddToasterMessage,
  deleteTaiLieuHoSoCongViec,
  getChiTietHoSoCongViec,
  getDanhSachTaiLieuHoSoCongViec,
  getLanhDaoPhongBan,
  getNumberHSCV,
  getDanhSachThongTinDuyet,
  putSapXepTaiLieuHSCV,
  putXuLyHoSoCongViec,
  putTiepNhanHSCV,
} from '../../../actions'
import { Utils } from '../../../helpers'
import {
  TOATS_MESSAGE_SUCCESS,
  TOATS_MESSAGE_FAIL,
} from '../../../constants/MessageForm'

const popups = {
  tiepNhan: TiepNhan,
  lichSu: LichSuLuuTru,
  default: () => null,
}

class TuTaiLieuChiTiet extends Component {
  constructor(props) {
    super(props)
    window.scrollTo(0, 0)
    let type
    switch (this.props.match.params.type) {
      case 'ca-nhan':
        type = CABINET_PERSONAL
        break
      case 'phong-ban':
        type = CABINET_DEPARTMENT
        break
      default:
        type = CABINET_UNIT
    }
    this.state = {
      id: this.props.match.params.id,
      isLoading: false,
      type,
      data: {},
      dataPopup: {
        textClose: 'Đóng',
        textSubmit: 'Nộp',
        isOpenPopup: false,
        titlePopup: 'Nộp hồ sơ',
        data: {},
        key: '',
      },
      popupActiveKey: 'default',
      isDisableDocurmentList: get(
        this.props,
        'history.location.state.isHSCVPhongBanDangLap',
        false
      ),
    }
  }

  async componentDidMount() {
    this.props.actions.commonFetchUnitsRelative(this.props.auth.mainUnitId)
    await this.fetchDetail()
  }

  /**
   * Lấy chi tiết hồ sơ - tách ra để gọi lại
   * @memberof TuTaiLieuChiTiet
   */
  fetchDetail = async () => {
    this.setState({ isLoading: true })
    try {
      await this.props.actions
        .getChiTietHoSoCongViec(this.state.id)
        .then(res => {
          if (
            res &&
            res.payload &&
            res.payload.data &&
            res.payload.data.result
          ) {
            this.setState({
              data: res.payload.data.result,
            })
          }
        })
    } catch (error) {
    } finally {
      this.setState({ isLoading: false })
    }
  }

  /**
   * Click vào các nút action
   * @memberof TuTaiLieuChiTiet
   */
  onClickButtonAction = (key, data) => {
    switch (key) {
      case 'NOP_HOSO':
        this.setState({
          dataPopup: {
            ...this.state.dataPopup,
            isOpenPopup: true,
            data,
            key,
          },
        })
        break
      case 'DUYET_HOSO':
        this.setState({
          dataPopup: {
            ...this.state.dataPopup,
            textSubmit: 'Duyệt',
            isOpenPopup: true,
            titlePopup: 'Duyệt hồ sơ',
            data,
            key,
          },
        })
        break
      case 'HUY_NOP':
        this.setState({
          dataPopup: {
            ...this.state.dataPopup,
            textSubmit: 'Hủy nộp',
            isOpenPopup: true,
            titlePopup: 'Hủy nộp',
            data,
            key,
          },
        })
        break
      case 'DUYET_LAI':
        this.setState({
          dataPopup: {
            ...this.state.dataPopup,
            textSubmit: 'Duyệt lại',
            isOpenPopup: true,
            titlePopup: 'Duyệt lại hồ sơ',
            data,
            key,
          },
        })
        break
      case 'TIEP_NHAN_LAI':
        this.setState({
          dataPopup: {
            ...this.state.dataPopup,
            textSubmit: 'Tiếp nhận lại',
            isOpenPopup: true,
            titlePopup: 'Tiếp nhận lại',
            data,
            key,
          },
        })
        break
      default:
        break
    }
  }

  /**
   * Click đóng popup
   * @memberof TuTaiLieuChiTiet
   */
  onClosePopup = () => {
    this.setState({
      dataPopup: {
        ...this.state.dataPopup,
        isOpenPopup: false,
        data: {},
        key: '',
      },
    })
  }

  /**
   * Gọi khi hoàn hành submit popup model
   * @memberof TuTaiLieuChiTiet
   */
  onSuccessPopup = type => {
    if (type === 'GO_BACK') {
      this.goBack()
    }
  }

  /**
   * Bật/tắt popup (khác với Nộp Hồ Sơ)
   * @memberof TuTaiLieuChiTiet
   */
  togglePopup = key => () => {
    this.setState(prev => ({
      popupActiveKey: prev.popupActiveKey !== key ? key : 'default',
    }))
  }

  /**
   * render popup được bật
   * @memberof TuTaiLieuChiTiet
   */
  renderPopup = () => {
    const { popupActiveKey, data } = this.state
    const props = {
      onClose: this.togglePopup(popupActiveKey),
      information: data,
    }
    // Thêm action nếu là tiếp nhận
    if (popupActiveKey === 'tiepNhan') {
      props.submit = this.tiepNhan
    }
    const Container = popups[popupActiveKey]
    return <Container {...props} />
  }

  /**
   * Action Tiếp nhận
   * @memberof TuTaiLieuChiTiet
   */
  tiepNhan = async data => {
    const { match, auth, actions } = this.props
    const { popupActiveKey } = this.state

    const response = await actions.putTiepNhanHSCV(
      Utils.toDecamelizeKeys({
        ...data,
        id: get(match, 'params.id'),
        nguoiDuyetId: get(auth, 'roleId'),
      })
    )

    if (response.error) {
      return actions.commonAddToasterMessage({
        message: TOATS_MESSAGE_FAIL,
        intent: Intent.DANGER,
      })
    }

    actions.commonAddToasterMessage({
      message: TOATS_MESSAGE_SUCCESS,
      intent: Intent.SUCCESS,
    })
    this.togglePopup(popupActiveKey)()
    this.goBack()
  }

  /**
   * Render danh sách người phối hợp
   * @memberof TuTaiLieuChiTiet
   */
  renderNguoiPhoiHop = data => {
    if (!data) {
      return
    }

    let dsNguoiPhoiHop = []
    data &&
      data.length !== 0 &&
      data.map(elm => {
        return dsNguoiPhoiHop.push(elm.tenNhanVien)
      })
    return join(dsNguoiPhoiHop, ', ')
  }

  goBack = () => {
    const prevPath = get(this.props, 'location.state.prevPath')
    const store = get(this.props, 'location.state.store')

    if (store) {
      Utils.saveData(store.key, store.value)
    }

    if (prevPath) {
      return this.props.history.push(prevPath)
    }

    this.props.history.push({
      pathname: '/tu-tai-lieu/danh-sach',
      state: {
        type: this.state.type,
        keyTabTuTaiLieu: '3',
      },
    })
  }

  render() {
    const { type, data, dataPopup, isLoading, isDisableDocurmentList } =
      this.state
    const { auth, units, match, counterHSCV } = this.props
    // Check nếu người chịu trách nhiệm hoặc người phối hơp, hoặc theo trạng thái hscv thì sẽ được thực hiện các action
    let disabledButton = true
    if (
      (data.trangThai === 0 || data.trangThai === 5) &&
      ((!isEmpty(data) &&
        data.nguoiChiuTrachNhiem &&
        intersection([auth.roleId], [data.nguoiChiuTrachNhiem.chucDanhId])
          .length !== 0) ||
        (!isEmpty(data) &&
          data.dsNguoiPhoiHop &&
          data.dsNguoiPhoiHop.length !== 0 &&
          intersectionBy(
            [{ chucDanhId: auth.roleId }],
            data.dsNguoiPhoiHop,
            'chucDanhId'
          ).length !== 0))
    ) {
      disabledButton = false
    }
    // Check disble button nộp hồ sơ đối với người phối hợp
    let disabledButtonNopHoSo = true
    if (
      (data.trangThai === 0 || data.trangThai === 5) &&
      !isEmpty(data) &&
      data.nguoiChiuTrachNhiem &&
      intersection([auth.roleId], [data.nguoiChiuTrachNhiem.chucDanhId])
        .length !== 0
    ) {
      disabledButtonNopHoSo = false
    }
    // Check hủy nộp
    let isHienThiHuyNop = false
    if (
      !isEmpty(data) &&
      data.trangThai === STATUS_HSCV.LDPB_CHO_DUYET &&
      data.nguoiChiuTrachNhiem &&
      intersection([auth.roleId], [data.nguoiChiuTrachNhiem.chucDanhId])
        .length !== 0
    ) {
      isHienThiHuyNop = true
    }
    // Check duyệt lại
    let isHienThiDuyetLai = false
    if (
      !isEmpty(data) &&
      data.trangThai === STATUS_HSCV.TRA_LAI &&
      intersection(auth.permission, [
        Permission.HSCV_PHONGBAN_DUYET,
        Permission.HSCV_VANPHONG_DUYET,
      ]).length !== 0 &&
      data.nguoiDuyet &&
      data.nguoiDuyet.chucDanhId === auth.roleId
    ) {
      isHienThiDuyetLai = true
    }
    // Check tiếp nhận lại
    let isHienThiTiepNhanLai = false
    if (
      !isEmpty(data) &&
      data.trangThai === STATUS_HSCV.TRA_LAI &&
      intersection(auth.permission, [Permission.HSCV_VANTHU]).length !== 0 &&
      data.nguoiDuyet &&
      data.nguoiDuyet.chucDanhId === auth.roleId
    ) {
      isHienThiTiepNhanLai = true
    }

    return (
      <MasterLayout typeSidebar="documentsCabinet">
        <HeadingPage namePage="Tủ tài liệu" iconPage="icon-Tu_Tai_Lieu" />
        <div className="page-list-container page-list-document-cabinet-container mb20">
          <div className="detail-container">
            <div className="tabs-detail-page">
              <div className="pt-tabs tab-cus-container pd-none">
                <ul className="pt-tab-list" role="tablist">
                  <li
                    className={classnames('pt-tab cursor-default', {
                      'pt-tab-color-cabinet': type !== CABINET_UNIT,
                    })}
                    role="tab"
                    aria-selected={type === CABINET_UNIT}
                  >
                    <span
                      className={classnames({
                        'has-new':
                          (Authorization.vaild(auth.permission, [
                            Permission.HSCV_VANTHU,
                          ]) &&
                            get(counterHSCV, 'donVi[2]', 0) > 0) ||
                          (Authorization.vaild(auth.permission, [
                            Permission.HSCV_VANPHONG_DUYET,
                          ]) &&
                            get(counterHSCV, 'donVi[3]', 0) > 0),
                      })}
                    >
                      TTL CÔNG TY
                    </span>
                  </li>
                  <li
                    className={classnames('pt-tab cursor-default', {
                      'pt-tab-color-cabinet': type !== CABINET_DEPARTMENT,
                    })}
                    role="tab"
                    aria-selected={type === CABINET_DEPARTMENT}
                  >
                    <span
                      className={classnames({
                        'has-new':
                          Authorization.vaild(auth.permission, [
                            Permission.HSCV_PHONGBAN_DUYET,
                          ]) && get(counterHSCV, 'phongBan[1]', 0) > 0,
                      })}
                    >
                      TTL PHÒNG BAN
                    </span>
                  </li>
                  <li
                    className={classnames('pt-tab cursor-default', {
                      'pt-tab-color-cabinet': type !== CABINET_PERSONAL,
                    })}
                    role="tab"
                    aria-selected={type === CABINET_PERSONAL}
                  >
                    TTL CÁ NHÂN
                  </li>
                </ul>
              </div>
            </div>
            <div className="hscv-action">
              <div className="hscv-action-col">
                <div className="group-button">
                  <div className="group-button--content">
                    <Button
                      className="text-uppercase"
                      loading={false}
                      disabled={false}
                      onClick={this.goBack}
                    >
                      <span className="icon icon-arrow-prev mr8"></span>
                      Quay lại
                    </Button>
                  </div>
                </div>
              </div>
              <div className="hscv-action-col">
                <div className="group-button">
                  {get(match, 'params.type') === 'don-vi' && (
                    <Fragment>
                      <div className="group-button--content">
                        <Button
                          className="btn-blue text-uppercase"
                          onClick={this.togglePopup('lichSu')}
                          disabled={!size(data)}
                        >
                          Lịch sử HSCV
                          <span className="icon icon-hisory ml8" />
                        </Button>
                      </div>
                      {get(data, 'trangThai') === STATUS_HSCV.LDPB_DA_DUYET &&
                        intersection(this.props.auth.permission, [
                          Permission.HSCV_VANTHU,
                        ]).length !== 0 && (
                          <Authorization.Element
                            permission={[Permission.HSCV_VANTHU]}
                          >
                            <div className="group-button--content">
                              <Button
                                className="btn-blue text-uppercase"
                                onClick={this.togglePopup('tiepNhan')}
                                disabled={!size(data)}
                              >
                                Tiếp nhận
                                <span className="icon icon-ttl-tiepnhan ml8" />
                              </Button>
                            </div>
                          </Authorization.Element>
                        )}
                    </Fragment>
                  )}
                  {isHienThiHuyNop && (
                    <div className="group-button--content">
                      <Button
                        className="btn-blue text-uppercase"
                        onClick={() =>
                          this.onClickButtonAction('HUY_NOP', data)
                        }
                      >
                        Hủy nộp
                        <span className="icon icon-Huy ml8" />
                      </Button>
                    </div>
                  )}
                  {this.props.match.params.type !== 'don-vi' &&
                    !isHienThiHuyNop && (
                      <div className="group-button--content">
                        <Button
                          className="btn-blue text-uppercase"
                          loading={false}
                          disabled={disabledButtonNopHoSo}
                          onClick={() =>
                            this.onClickButtonAction('NOP_HOSO', data)
                          }
                        >
                          Nộp hồ sơ
                          <span className="icon icon-fly ml8" />
                        </Button>
                      </div>
                    )}
                  {((get(data, 'trangThai') === 1 &&
                    intersection(auth.permission, [
                      Permission.HSCV_PHONGBAN_DUYET,
                    ]).length !== 0 &&
                    data.nguoiDuyet &&
                    data.nguoiDuyet.chucDanhId === auth.roleId) ||
                    (get(data, 'trangThai') === 3 &&
                      intersection(auth.permission, [
                        Permission.HSCV_VANPHONG_DUYET,
                      ]).length !== 0)) && (
                    <div className="group-button--content">
                      <Button
                        className="btn-blue text-uppercase"
                        loading={false}
                        onClick={() =>
                          this.onClickButtonAction('DUYET_HOSO', data)
                        }
                      >
                        Duyệt hồ sơ
                        <span className="icon icon-xac-nhan-lai ml8"></span>
                      </Button>
                    </div>
                  )}
                  {isHienThiDuyetLai && (
                    <div className="group-button--content">
                      <Button
                        className="btn-blue text-uppercase"
                        loading={false}
                        onClick={() =>
                          this.onClickButtonAction('DUYET_LAI', data)
                        }
                      >
                        Duyệt lại
                        <span className="icon icon-xac-nhan-lai ml8"></span>
                      </Button>
                    </div>
                  )}
                  {isHienThiTiepNhanLai && (
                    <div className="group-button--content">
                      <Button
                        className="btn-blue text-uppercase"
                        loading={false}
                        onClick={() =>
                          this.onClickButtonAction('TIEP_NHAN_LAI', data)
                        }
                      >
                        Tiếp nhận lại
                        <span className="icon icon-xac-nhan-lai ml8"></span>
                      </Button>
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div className="panel-wrapper border-top">
              <div className="row no-gutters">
                <div className="col-lg-7 col-md-7 col-sm-12 border-right">
                  <div className="panel-heading">
                    <h1 className="text-uppercase mb5">
                      Thông tin hồ sơ công việc
                    </h1>
                  </div>
                  <div className="panel-body pt20 pr20 pl20 pb5">
                    {!isLoading && (
                      <div className="panel-body-content">
                        <div className="row">
                          <div className="col-content col-md-8 col-sm-12 col-xs-12 mb15">
                            <label>Tên hồ sơ công việc</label>
                            <div className="content">
                              {get(data, 'ten') && <span>{data.ten}</span>}
                            </div>
                          </div>
                          <div className="col-content col-md-4 col-sm-12 col-xs-12 mb15">
                            <label>Người chịu trách nhiệm</label>
                            <div className="content">
                              <p>
                                {get(
                                  data.nguoiChiuTrachNhiem,
                                  'tenNhanVien',
                                  ''
                                )}
                              </p>
                              <span className="text-uppercase">
                                {get(data.phongBanLap, 'maPhongBan', '')}
                                {get(data.donVi, 'tenVietTat')
                                  ? ` - ${get(data.donVi, 'tenVietTat')}`
                                  : ''}
                              </span>
                            </div>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-content col-sm-4 col-xs-12 mb15">
                            <label>Người phối hợp</label>
                            <div className="content">
                              <span>
                                {this.renderNguoiPhoiHop(data.dsNguoiPhoiHop)}
                              </span>
                            </div>
                          </div>
                          <div className="col-content col-sm-4 col-xs-12 mb15">
                            <label>Thời hạn bảo quản</label>
                            <div className="content">
                              <span>
                                {get(data.thoiHanBaoQuan, 'tenThoiHanLuu', '')}
                              </span>
                            </div>
                          </div>
                          <div className="col-content col-sm-4 col-xs-12 mb15">
                            <label>Mã ký hiệu</label>
                            <div className="content">
                              <span>{get(data, 'maKyHieu', '')}</span>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                    {isLoading && <LoadingItem />}
                  </div>
                </div>
                <div className="col-lg-5 col-md-5 col-sm-12">
                  <div className="panel-heading">
                    <h1 className="text-uppercase mb5">Thông tin duyệt</h1>
                  </div>
                  <div className="panel-body pt20 pr10 pb20 pl20">
                    <div className="panel-body-content">
                      <ThongTinDuyet
                        actions={this.props.actions}
                        match={this.props.match}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <DanhSachTaiLieu
          actions={this.props.actions}
          auth={this.props.auth}
          coQuanBanHanh={units}
          history={this.props.history}
          type={this.props.match.params.type}
          id={this.state.id}
          params={this.props.match.params}
          disabledButton={disabledButton}
          dataHoSoCongViec={data}
          isDisableDocurmentList={!!isDisableDocurmentList}
        />
        <CapNhatChiTietHoSoCongViec
          actions={this.props.actions}
          auth={this.props.auth}
          dataPopup={dataPopup}
          onClose={this.onClosePopup}
          onSuccess={this.onSuccessPopup}
        />
        {this.renderPopup()}
      </MasterLayout>
    )
  }
}

const mapStateToProps = state => ({
  auth: state.auth,
  units: state.common.relativeUnits,
  counterHSCV: state.common.numberSideBar.hoSoCongViec,
})

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(
    {
      commonFetchUnitsRelative,
      commonAddToasterMessage,
      deleteTaiLieuHoSoCongViec,
      getChiTietHoSoCongViec,
      getDanhSachTaiLieuHoSoCongViec,
      getLanhDaoPhongBan,
      getNumberHSCV,
      getDanhSachThongTinDuyet,
      putSapXepTaiLieuHSCV,
      putXuLyHoSoCongViec,
      putTiepNhanHSCV,
    },
    dispatch
  ),
})

export default connect(mapStateToProps, mapDispatchToProps)(TuTaiLieuChiTiet)
