import { isEmpty } from 'lodash'
import moment from 'moment'
import { useCallback, useEffect, useState } from 'react'
import { Intent } from '@blueprintjs/core'
import * as MESSAGE from 'constants/MessageForm'

const UseAssignWorkAdditional = ({
  auth,
  actions,
  receiveDocument,
  onSubmit,
  departmentAndPersonalTree,
  units,
  informationSteering,
}) => {
  const mainUnitId = auth?.mainUnitId
  const mainDepartmentId = auth?.mainDepartmentId
  const [formData, setFormData] = useState({ noiDung: '', hanGiaiQuyet: null })
  const [formError, setFormError] = useState(null)
  const [isFetch, setIsFetch] = useState(false)
  const [phoiHopData, setPhoiHopData] = useState([])
  const [xemDeBietData, setXemDeBietData] = useState([])
  const [baoCaoData, setBaoCaoData] = useState([])
  const [disableData, setDisableData] = useState({
    chuTri: null,
    phoiHop: [],
    xemDeBiet: [],
    baoCao: [],
    allDisabled: [],
    idsDonViPhoiHop: [],
    idsDonViXemDeBiet: [],
    disabledDonViPhoiHop: [],
    disabledDonViXemDeBiet: [],
  })
  const [donViPhoiHopData, setDonViPhoiHopData] = useState([])
  const [donViXemDeBietData, setDonViXemDeBietData] = useState([])
  const [isDisabledButtonDefault, setIsDisabledButtonDefault] = useState(true)
  const [dataOpen, setDataOpen] = useState([mainDepartmentId])
  const [receivingUnitSelections, setReceivingUnitSelections] = useState([])
  const [isOpenDonViNhan, setIsOpenDonViNhan] = useState(false)
  const [isShowBcCd, setIsShowBcCd] = useState(false)
  const [isSubmit, setIsSubmit] = useState(false)

  const handleClickArrowDvn = () => {
    setIsOpenDonViNhan(!isOpenDonViNhan)
  }

  const onFormChange = name => value => {
    setFormData(prev => ({
      ...prev,
      [name]: value,
    }))
    setFormError(prev => ({
      ...prev,
      [name]: null,
    }))
  }

  const handleSubmit = useCallback(() => {
    const dataRequest = {
      cong_van_den_id: receiveDocument.congVanDenId,
      noi_dung: formData?.noiDung ? formData?.noiDung.trim() : '',
      ds_phoi_hop: phoiHopData
        ?.filter(f => !f?.chucDanhId && f?.phongBanId)
        ?.map(elm => elm.phongBanId),
      ds_xem_de_biet: xemDeBietData
        ?.filter(f => !f?.chucDanhId && f?.phongBanId)
        ?.map(elm => elm.phongBanId),
      ds_nv_phoi_hop: phoiHopData
        ?.filter(f => f?.chucDanhId)
        ?.map(elm => ({
          phong_ban_id: elm.phongBanId,
          chuc_danh_id: elm.chucDanhId,
        })),
      nv_xem_de_biet: xemDeBietData
        ?.filter(f => f?.chucDanhId)
        ?.map(elm => elm.chucDanhId),
      nv_xem_bao_cao: baoCaoData?.map(elm => elm.chucDanhId),
      ds_don_vi_phoi_hop: donViPhoiHopData,
      ds_don_vi_xem_de_biet: donViXemDeBietData,
      han_giai_quyet: moment(formData.hanGiaiQuyet).isValid()
        ? moment(formData.hanGiaiQuyet)
            .set({ hour: 0, minute: 0, second: 0, millisecond: 0 })
            .format()
        : null,
    }
    setIsSubmit(true)
    actions
      .receiveDocumentSendAdditionalAssignment(
        receiveDocument.congVanDenId,
        dataRequest
      )
      .then(res => {
        setIsSubmit(false)
        if (res.payload && res.payload.status === 200) {
          onSubmit(
            'ChiDaoBoSung',
            MESSAGE.TOATS_MESSAGE_SUCCESS,
            Intent.SUCCESS,
            true,
            null
          )
        } else {
          this.setState({ isLoading: false })
          onSubmit(
            'ChiDaoBoSung',
            MESSAGE.TOATS_MESSAGE_FAIL,
            Intent.DANGER,
            false,
            null
          )
        }
      })
  }, [
    actions,
    formData,
    phoiHopData,
    xemDeBietData,
    baoCaoData,
    donViPhoiHopData,
    donViXemDeBietData,
    receiveDocument,
    onSubmit,
  ])

  const onFormValidate = useCallback(
    e => {
      e.preventDefault()
      let error = null
      if (error) {
        return setFormError(error)
      } else {
        return handleSubmit()
      }
    },
    [handleSubmit]
  )

  const getAllChucDanhIds = data => {
    const chucDanhIds = []
    data.forEach(phongBan => {
      phongBan.dsNhanVien.forEach(nhanVien => {
        if (nhanVien.chucDanhId) {
          chucDanhIds.push(nhanVien.chucDanhId)
        }
      })
    })
    return chucDanhIds
  }

  const onChangeCheckbox = useCallback(
    (name, value, isCheckAll) => record => {
      setIsDisabledButtonDefault(false)
      if (name === 'PH') {
        if (isCheckAll) {
          if (value) {
            setPhoiHopData(
              departmentAndPersonalTree?.map(item => item?.phongBanId)
            )
            setXemDeBietData([])
          } else {
            setPhoiHopData([])
          }
        } else {
          const isPersonal = record?.chucDanhId ? true : false
          if (value) {
            if (isPersonal) {
              // Check cá nhân phối hợp thì thêm data cá nhân và xóa phòng ban phối hợp
              // Xóa phòng ban xem để biết và cá nhân xem để biết trùng với cá nhân phối hợp hiện tại
              const phongBanPhoiHop =
                phoiHopData?.filter(
                  elm =>
                    elm.phongBanId === record.phongBanId && !elm?.chucDanhId
                ) || []
              setPhoiHopData([
                ...phoiHopData?.filter(
                  elm =>
                    !phongBanPhoiHop.some(
                      e => e?.phongBanId === elm?.phongBanId
                    )
                ),
                record,
              ])

              let xemDeBietDataTemp = [...xemDeBietData]
              const phongBanXemDeBiet =
                xemDeBietData?.filter(
                  elm =>
                    elm.phongBanId === record.phongBanId && !elm?.chucDanhId
                ) || []
              if (phongBanXemDeBiet?.length > 0 ?? false) {
                xemDeBietDataTemp = xemDeBietDataTemp?.filter(
                  elm =>
                    !phongBanXemDeBiet.some(
                      e => e?.phongBanId === elm?.phongBanId
                    )
                )
              }

              const nhanVienXemDeBiet =
                xemDeBietData?.filter(
                  elm =>
                    elm.phongBanId === record.phongBanId &&
                    elm?.chucDanhId === record?.chucDanhId
                ) || []
              if (nhanVienXemDeBiet?.length > 0 ?? false) {
                xemDeBietDataTemp = xemDeBietDataTemp?.filter(
                  elm =>
                    !nhanVienXemDeBiet.some(
                      e => e?.chucDanhId === elm?.chucDanhId
                    )
                )
              }
              setXemDeBietData(xemDeBietDataTemp)
            } else {
              // Check phòng ban phối hợp thì thêm data phòng phối hợp và xóa nhân viên phối hợp
              // Xóa Phòng ban và nhân viên xem để biết
              const nhanVienPhongBanPhoiHop =
                phoiHopData?.filter(
                  elm => elm.phongBanId === record.phongBanId && elm?.chucDanhId
                ) || []
              let phoiHopDataTemp = [...phoiHopData]
              if (nhanVienPhongBanPhoiHop?.length > 0 ?? false) {
                phoiHopDataTemp = phoiHopDataTemp?.filter(
                  elm =>
                    !nhanVienPhongBanPhoiHop.some(
                      e => e?.chucDanhId === elm?.chucDanhId
                    )
                )
              }

              setPhoiHopData([...phoiHopDataTemp, record])

              const phongBanXemDeBiet =
                xemDeBietData?.filter(
                  elm =>
                    elm.phongBanId === record.phongBanId && !elm?.chucDanhId
                ) || []
              let xemDeBietDataTemp = [...xemDeBietData]
              if (phongBanXemDeBiet?.length > 0 ?? false) {
                xemDeBietDataTemp = xemDeBietDataTemp?.filter(
                  elm =>
                    !phongBanXemDeBiet.some(
                      e => e?.phongBanId === elm?.phongBanId
                    )
                )
              }

              const nhanVienPhongBanXemDeBiet =
                xemDeBietData?.filter(
                  elm => elm.phongBanId === record.phongBanId && elm?.chucDanhId
                ) || []
              if (nhanVienPhongBanXemDeBiet?.length > 0 ?? false) {
                xemDeBietDataTemp = xemDeBietDataTemp?.filter(
                  elm =>
                    !nhanVienPhongBanXemDeBiet.some(
                      e => e?.chucDanhId === elm?.chucDanhId
                    )
                )
              }

              setXemDeBietData(xemDeBietDataTemp)
            }
          } else {
            setPhoiHopData(
              phoiHopData?.filter(item =>
                isPersonal
                  ? item?.chucDanhId !== record?.chucDanhId
                  : item?.phongBanId !== record?.phongBanId
              )
            )
          }
        }
      } else if (name === 'XDB') {
        if (isCheckAll) {
          if (value) {
            setXemDeBietData(
              departmentAndPersonalTree?.map(item => item?.phongBanId)
            )
            setPhoiHopData([])
          } else {
            setXemDeBietData([])
          }
        } else {
          const isPersonal = record?.chucDanhId ? true : false
          if (value) {
            if (isPersonal) {
              // Check cá nhân xem để biết thêm data cá nhân và xóa phòng ban cá nhân xem để biết
              // Xóa phòng ban phối hợp và cá nhân phối hợp trùng cá nhân xem để biết hiện tại
              let phoiHopDataTemp = [...phoiHopData]
              const phongBanPhoiHop =
                phoiHopData?.filter(
                  elm =>
                    elm.phongBanId === record.phongBanId && !elm?.chucDanhId
                ) || []
              if (phongBanPhoiHop?.length > 0 ?? false) {
                phoiHopDataTemp = phoiHopDataTemp?.filter(
                  elm =>
                    !phongBanPhoiHop.some(
                      e => e?.phongBanId === elm?.phongBanId
                    )
                )
              }

              const nhanVienPhongBanPhoiHop =
                phoiHopData?.filter(
                  elm =>
                    elm.phongBanId === record.phongBanId &&
                    elm?.chucDanhId === record?.chucDanhId
                ) || []
              if (nhanVienPhongBanPhoiHop?.length > 0 ?? false) {
                phoiHopDataTemp = phoiHopDataTemp?.filter(
                  elm =>
                    !nhanVienPhongBanPhoiHop.some(
                      e => e?.chucDanhId === elm?.chucDanhId
                    )
                )
              }

              setPhoiHopData(phoiHopDataTemp)

              const phongBanXemDeBiet =
                xemDeBietData?.filter(
                  elm =>
                    elm.phongBanId === record.phongBanId && !elm?.chucDanhId
                ) || []
              setXemDeBietData([
                ...xemDeBietData?.filter(
                  elm =>
                    !phongBanXemDeBiet.some(
                      e => e?.phongBanId === elm?.phongBanId
                    )
                ),
                record,
              ])
            } else {
              // Check phòng ban xem để biết thì thêm data xem để biết và xóa nhân viên xem để biết
              // Xóa phòng ban và nhân viên phối hợp
              let phoiHopDataTemp = [...phoiHopData]
              const phongBanPhoiHop =
                phoiHopData?.filter(
                  elm =>
                    elm.phongBanId === record.phongBanId && !elm?.chucDanhId
                ) || []
              if (phongBanPhoiHop?.length > 0 ?? false) {
                phoiHopDataTemp = phoiHopDataTemp?.filter(
                  elm =>
                    !phongBanPhoiHop.some(
                      e => e?.phongBanId === elm?.phongBanId
                    )
                )
              }

              const nhanVienPhongBanPhoiHop =
                phoiHopData?.filter(
                  elm => elm.phongBanId === record.phongBanId && elm?.chucDanhId
                ) || []
              if (nhanVienPhongBanPhoiHop?.length > 0 ?? false) {
                phoiHopDataTemp = phoiHopDataTemp?.filter(
                  elm =>
                    !nhanVienPhongBanPhoiHop.some(
                      e => e?.chucDanhId === elm?.chucDanhId
                    )
                )
              }

              setPhoiHopData(phoiHopDataTemp)

              let xemDeBietDataTemp = [...xemDeBietData]
              const nhanVienPhongBanXemDeBiet =
                xemDeBietData?.filter(
                  elm => elm.phongBanId === record.phongBanId && elm?.chucDanhId
                ) || []
              if (nhanVienPhongBanXemDeBiet?.length > 0 ?? false) {
                xemDeBietDataTemp = xemDeBietDataTemp?.filter(
                  elm =>
                    !nhanVienPhongBanXemDeBiet.some(
                      e => e?.chucDanhId === elm?.chucDanhId
                    )
                )
              }

              setXemDeBietData([...xemDeBietDataTemp, record])
            }
          } else {
            setXemDeBietData(
              xemDeBietData?.filter(item =>
                isPersonal
                  ? item?.chucDanhId !== record?.chucDanhId
                  : item?.phongBanId !== record?.phongBanId
              )
            )
          }
        }
      }
      if (name === 'BC') {
        if (isCheckAll) {
          const chucDanhIds = getAllChucDanhIds(
            departmentAndPersonalTree?.filter(e => e?.phongBanLanhDao)
          )
          if (value) {
            setBaoCaoData(chucDanhIds)
            // Kiểm tra dữ liệu và mở tree item
            const dsPhongBanCoNhanVienDuocChon = []
            departmentAndPersonalTree.forEach(e => {
              if (
                e?.dsNhanVien?.findIndex(f =>
                  chucDanhIds.some(s => s === f.chucDanhId)
                ) !== -1
              ) {
                dsPhongBanCoNhanVienDuocChon.push(e)
              }
            })
            if (dsPhongBanCoNhanVienDuocChon?.length > 0 ?? false) {
              setDataOpen(dsPhongBanCoNhanVienDuocChon.map(e => e.phongBanId))
            }
          } else {
            setBaoCaoData([])
          }
        } else {
          if (value) {
            setBaoCaoData([...baoCaoData, record])
          } else {
            setBaoCaoData(
              baoCaoData?.filter(item => item?.chucDanhId !== record.chucDanhId)
            )
          }
        }
      }
    },
    [
      phoiHopData,
      xemDeBietData,
      baoCaoData,
      departmentAndPersonalTree,
      setDataOpen,
    ]
  )

  const onChangeCheckboxDonViNhan = useCallback(
    (name, value, isCheckAll = false) =>
      id => {
        const disabledDonViPhoiHop = disableData?.disabledDonViPhoiHop
        const disabledDonViXemDeBiet = disableData?.disabledDonViXemDeBiet
        if (name === 'PH') {
          if (isCheckAll) {
            let idsDonViPH = []
            if (value) {
              const temp = receivingUnitSelections?.map(e => e.value)
              idsDonViPH = temp?.filter(
                f =>
                  !donViXemDeBietData?.some(s => s === f) &&
                  !disabledDonViPhoiHop?.some(s => s === f)
              )
            }

            setDonViPhoiHopData(idsDonViPH)
          } else {
            if (value) {
              setDonViPhoiHopData([...donViPhoiHopData, id])
              setDonViXemDeBietData(prev => prev?.filter(f => f !== id))
            } else {
              setDonViPhoiHopData(prev => prev?.filter(f => f !== id))
            }
          }
        } else if (name === 'XDB') {
          if (isCheckAll) {
            let idsDonViXemDeBiet = []
            if (value) {
              const temp = receivingUnitSelections?.map(e => e.value)
              idsDonViXemDeBiet = temp?.filter(
                f =>
                  !donViPhoiHopData?.some(s => s === f) &&
                  !disabledDonViXemDeBiet?.some(s => s === f)
              )
            }

            setDonViXemDeBietData(idsDonViXemDeBiet)
          } else {
            if (value) {
              setDonViXemDeBietData([...donViXemDeBietData, id])
              setDonViPhoiHopData(prev => prev?.filter(f => f !== id))
            } else {
              setDonViXemDeBietData(prev => prev?.filter(f => f !== id))
            }
          }
        }
      },
    [receivingUnitSelections, donViPhoiHopData, donViXemDeBietData, disableData]
  )

  const onClickArrow = phongBanId => {
    setDataOpen(prevDataOpen =>
      prevDataOpen.includes(phongBanId)
        ? prevDataOpen.filter(item => item !== phongBanId)
        : [...prevDataOpen, phongBanId]
    )
  }

  const onFetchDepartments = useCallback(() => {
    // Ds phòng ban
    actions.commonFetchDepartments()
  }, [actions])

  const fetchContactFetchDepartmentByUnit = useCallback(() => {
    actions.contactFetchDepartmentAndPersonalTree(mainUnitId)
  }, [actions, mainUnitId])

  const handleCheckDonViCha = useCallback(() => {
    actions.getUnitItem(mainUnitId).then(res => {
      if (res?.payload?.data?.result?.donViCha === null) {
        setIsShowBcCd(true)
      }
    })
  }, [actions, mainUnitId])

  const fetchDefault = useCallback(() => {
    setIsFetch(true)
    Promise.all([onFetchDepartments()]).then(() => {
      setIsFetch(false)
    })
  }, [onFetchDepartments])

  const handleDataDetail = useCallback(() => {
    if (!informationSteering) {
      return
    }

    const allDisabled = []
    const chuTri = informationSteering?.chuTri
    const phoiHop = [
      ...informationSteering?.phoiHop,
      ...informationSteering?.dsNvPhoiHop,
    ]
    const xemDeBiet = [
      ...informationSteering?.xemDeBiet,
      ...informationSteering?.dsNvXemDeBiet,
    ]
    const baoCao = informationSteering?.dsXemBaoCao || []
    allDisabled.push(chuTri, ...phoiHop, ...xemDeBiet)
    if (chuTri?.chucDanhId) {
      allDisabled.push({ phongBanId: chuTri?.phongBanId })
    } else if (chuTri?.phongBanId && !chuTri?.chucDanhId) {
      departmentAndPersonalTree.forEach(elm => {
        if (
          elm?.phongBanId === chuTri?.phongBanId &&
          (elm?.dsNhanVien?.length > 0 ?? false)
        ) {
          allDisabled.push(...elm.dsNhanVien)
        }
      })
    }

    phoiHop.forEach(elm => {
      allDisabled.push(elm)
      if (elm?.phongBanId && !elm?.chucDanhId) {
        const phongBan = departmentAndPersonalTree.find(
          e => e.phongBanId === elm?.phongBanId
        )
        if (phongBan && (phongBan?.dsNhanVien?.length > 0 ?? false)) {
          allDisabled.push(...phongBan.dsNhanVien)
        }
      } else {
        allDisabled.push({
          phongBanId: elm?.phongBanId,
        })
      }
    })

    xemDeBiet.forEach(elm => {
      allDisabled.push(elm)
      if (elm?.phongBanId && !elm?.chucDanhId) {
        const phongBan = departmentAndPersonalTree.find(
          e => e.phongBanId === elm?.phongBanId
        )
        if (phongBan && (phongBan?.dsNhanVien?.length > 0 ?? false)) {
          allDisabled.push(...phongBan.dsNhanVien)
        }
      } else {
        allDisabled.push({
          phongBanId: elm?.phongBanId,
        })
      }
    })

    setDisableData(prev => ({
      ...prev,
      chuTri: chuTri,
      phoiHop: phoiHop,
      xemDeBiet: xemDeBiet,
      baoCao: baoCao,
      idsDonViPhoiHop:
        informationSteering?.donViPhoiHop?.map(e => e?.donViId) || [],
      idsDonViXemDeBiet:
        informationSteering?.donViXemDeBiet?.map(e => e?.donViId) || [],
      allDisabled,
      disabledDonViPhoiHop: [
        ...(informationSteering?.donViChuTri?.donViId
          ? [informationSteering?.donViChuTri?.donViId]
          : []),
        ...(informationSteering?.donViPhoiHop?.length > 0 ?? false
          ? informationSteering.donViPhoiHop.map(e => e.donViId)
          : []),
      ],
      disabledDonViXemDeBiet: [
        ...(informationSteering?.donViChuTri?.donViId
          ? [informationSteering?.donViChuTri?.donViId]
          : []),
        ...(informationSteering?.donViPhoiHop?.length > 0 ?? false
          ? informationSteering.donViPhoiHop.map(e => e.donViId)
          : []),
        ...(informationSteering?.donViXemDeBiet?.length > 0 ?? false
          ? informationSteering.donViXemDeBiet.map(e => e.donViId)
          : []),
      ],
    }))

    if (
      informationSteering?.donViPhoiHop?.length > 0 ||
      informationSteering.DonViXemDeBiet?.length > 0
    ) {
      setIsOpenDonViNhan(true)
    }
  }, [departmentAndPersonalTree, informationSteering])

  useEffect(() => {
    if (!isEmpty(units)) {
      const receivingUnitSelections = []
      units.forEach(item => {
        receivingUnitSelections.push({
          content: item.tenVietTat,
          value: item.donViId,
        })
      })
      setReceivingUnitSelections(receivingUnitSelections)
    }
  }, [units])

  useEffect(() => {
    handleDataDetail()
  }, [handleDataDetail])

  useEffect(() => {
    fetchDefault()
  }, [fetchDefault])

  useEffect(() => {
    fetchContactFetchDepartmentByUnit()
  }, [fetchContactFetchDepartmentByUnit])

  useEffect(() => {
    handleCheckDonViCha()
  }, [handleCheckDonViCha])

  return {
    formData,
    formError,
    isFetch,
    phoiHopData,
    xemDeBietData,
    baoCaoData,
    disableData,
    donViPhoiHopData,
    donViXemDeBietData,
    dataOpen,
    receivingUnitSelections,
    isOpenDonViNhan,
    isShowBcCd,
    isDisabledButtonDefault,
    isSubmit,
    onFormChange,
    onChangeCheckbox,
    onChangeCheckboxDonViNhan,
    onClickArrow,
    handleClickArrowDvn,
    onFormValidate,
  }
}

export default UseAssignWorkAdditional
