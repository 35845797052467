import React from 'react'
import PropTypes from 'prop-types'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { Redirect } from 'react-router-dom'
import moment from 'moment'

import { MasterLayout } from '../../components/layout'
import {
  ListFilterAssignedOld,
  ListItemAssignedPersonal,
} from '../../components/Work/DepartmentWork'
import { GridView } from '../../components/common'
import { headers } from '../../constants/Table'
import * as Actions from '../../actions'
import * as Types from '../../constants/Api'
import * as Tool from '../../helpers'
import NoteFooter from './cong-viec/note-footer'
import Pagination from 'components/Pagination'

// const DA_GIAO = 1;

class ListAssignedPersonalWorkPage extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      filter: {},
      isCaNhanDaGiao: true,
      sort: null,
      listStatus: [
        {
          name: 'Chưa xử lý',
          value: 0,
        },
        {
          name: 'Đang xử lý',
          value: 1,
        },
        {
          name: 'Đã xử lý',
          value: 2,
        },
      ],
      isEmpty: false,
      page: 1,
      isLoading: false,
      workExpiringSoon: 0,
      workExpired: 0,
      isRedirect: false,
      redirectTo: null,
      fileId: null,
      soCongVan: null,
      isError: false,
    }

    this.clickPagination = this.clickPagination.bind(this)
    this.submitFilter = this.submitFilter.bind(this)
    this.handleSort = this.handleSort.bind(this)
    this.handleClickDetail = this.handleClickDetail.bind(this)
  }

  static propTypes = {
    auth: PropTypes.object.isRequired,
    departmentWorksAssigned: PropTypes.object.isRequired,
  }

  clickPagination(page = 1) {
    this.setState({ page })
    this.submitFilter({}, page, this.state.sort, this.state.isCaNhanDaGiao)
  }

  componentWillReceiveProps(props) {
    if (props.departmentWorkTotals) {
      this.setState({
        workExpiringSoon: props.departmentWorkTotals.workExpiringSoon || 0,
        workExpired: props.departmentWorkTotals.workExpired || 0,
      })
    }
  }

  submitFilter(e = {}, page = 1, sort = null, isCaNhanDaGiao = true) {
    const filter = Object.assign({}, this.state.filter, e)
    let isCallRequestAgain = false
    this.setState({ filter, sort, isLoading: true, page }, () => {
      Tool.Utils.preData('ListAssignedPersonalWorkPage', {
        filter,
        page,
        sort,
        isCaNhanDaGiao,
      })
      this.props.actions
        .departmentPersionalWorkAssignedGet(
          { filter },
          page,
          sort,
          isCaNhanDaGiao
        )
        .then(res => {
          if (
            page > 1 &&
            res.payload &&
            res.payload.data &&
            res.payload.data.result &&
            res.payload.data.result.result &&
            res.payload.data.result.result.total === 0
          ) {
            isCallRequestAgain = true
            return this.submitFilter(e, page - 1, sort, isCaNhanDaGiao)
          }
          let isEmpty = false
          let isError = false
          if (
            !res.error &&
            res.payload &&
            res.payload.data &&
            res.payload.data.result &&
            res.payload.data.result.result &&
            res.payload.data.result.result.total === 0
          ) {
            isEmpty = true
          }

          if (res.error || (res.payload && res.payload.status !== 200)) {
            isError = true
          }
          this.setState({ isEmpty, isError })
        })
        .then(() => {
          if (isCallRequestAgain === false) {
            this.setState({ isLoading: false })
          }
        })
    })
  }

  handleSort(sort) {
    this.submitFilter({}, this.state.page, sort, this.state.isCaNhanDaGiao)
  }

  componentDidMount() {
    // TODO: tạm thời ẩn đi
    // this.props.actions.commonFetchDepartmentWorkTotal(DA_GIAO, true, false);
    // this.props.actions.commonFetchDepartmentWorkTotal(DA_GIAO, false, true);
    this.props.actions.commonFetchStaffByDepartmentId(
      this.props.auth.mainDepartmentId
    )

    let preData = Tool.Utils.preData('ListAssignedPersonalWorkPage')
    let state = this.state

    if (preData) {
      state.filter = preData.filter
      state.page = preData.page
      state.sort = preData.sort
    } else {
      state.filter.ngayGiaoViec = {
        from: moment().subtract(1, 'months').format('YYYY-MM-DD'),
        to: moment().format('YYYY-MM-DD'),
      }
    }

    this.setState(state, () => {
      this.clickPagination(this.state.page)
    })
  }

  handleClickDetail = data => {
    if (!data) {
      return
    }
    this.setState({
      isRedirect: true,
      redirectTo: `/cong-viec/ca-nhan/chi-tiet/da-giao/${data.phieuGiaoViecId}`,
      fileId: data.fileId,
      soCongVan: data.soCongVan,
    })
  }

  render() {
    if (this.state.isRedirect) {
      return (
        <Redirect
          push
          to={{
            pathname: this.state.redirectTo,
            state: {
              fileId: this.state.fileId,
              soCongVan: this.state.soCongVan,
              from: this.props.location.pathname,
            },
          }}
        />
      )
    }

    return (
      <MasterLayout typeSidebar="work">
        <div>
          {/* Heading */}
          <div className="heading-page">
            <div className="row">
              <div className="col-xs-12">
                <h3 className="text-heading-page">
                  <span className="icon-Da_Giao icon-heading"></span>
                  <span className="text-heading">Công việc đã giao</span>
                </h3>
              </div>
            </div>
          </div>

          {/* Table List */}
          <div className="page-list-container">
            <ListFilterAssignedOld
              filter={this.state.filter}
              staffs={this.props.staffs}
              status={this.state.listStatus}
              onSubmit={this.submitFilter}
              workExpired={this.state.workExpired}
              workExpiringSoon={this.state.workExpiringSoon}
              readOnly={this.props.readOnly}
              actions={this.props.actions}
            />

            <div className="table-container">
              <GridView
                isLoading={this.state.isLoading}
                classNames={'pt-table fix-table-before'}
                headers={headers.listPersonalWorkAssigned}
                itemShape={ListItemAssignedPersonal}
                data={this.props.departmentWorksAssigned.list.items}
                onFilter={this.handleSort}
                onClickDetail={this.handleClickDetail}
                page={this.state.page ? this.state.page : 1}
                isEmpty={this.state.isEmpty}
                auth={this.props.auth}
                isError={this.state.isError}
              />

              {!this.state.isLoading &&
                !this.state.isEmpty &&
                !this.state.isError && (
                  <div className="p10">
                    <NoteFooter isHienThiLoaiCongVan={false} />
                    <Pagination
                      current={this.state.page}
                      total={Math.ceil(
                        this.props?.departmentWorksAssigned?.list?.total /
                          Types.PAGINATION_LIMIT
                      )}
                      onChange={this.clickPagination}
                    />
                  </div>
                )}
            </div>
          </div>
        </div>
      </MasterLayout>
    )
  }
}

const mapStateToProps = state => ({
  auth: {
    ...state.auth,
  },

  departmentWorksAssigned: {
    ...state.departmentWorksAssigned,
    list: {
      ...state.departmentWorksAssigned.list,
      items: state.departmentWorksAssigned.list.items.map(
        id => state.entities.departmentWorksAssigned[id]
      ),
    },
  },

  staffs: state.common.staffs,

  departmentWorkTotals: state.common.departmentWorkTotals,

  readOnly: state.common.enableReadOnly,
})

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(Actions, dispatch),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ListAssignedPersonalWorkPage)
