import React from 'react'
import { Intent } from '@blueprintjs/core'
import { MasterLayout } from '../../components/layout'
import {
  DetailBoxAction,
  ForwardAssignment,
  // Assignment,
  ProposeAssignment,
  // AdditionalAssignment,
  SendMail,
  SaveDocument,
  AssignmentHistory,
  DetailInfo,
  // Flowdocumentary,
} from '../../components/ReceiveDocument'
import AssignWork from 'components/ReceiveDocument/assign-work'
import AssignWorkAdditional from 'components/ReceiveDocument/assign-work-additional'
import UserViewed from 'components/common/user-viewed'
// import { FlowSendDocumentary } from '../../components/SendDocument'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { Permission, Authorization } from '../../components/auth'
import DocumentFlow from 'components/document-flow'
import * as Actions from '../../actions'
import * as Tool from '../../helpers'
import * as Types from '../../constants/Api'
import * as MESSAGE from '../../constants/MessageForm'
import ReactDOM from 'react-dom'
import classnames from 'classnames'
import { get, size, isEmpty } from 'lodash'
import {
  InformationSteering,
  ExecutingView,
  LoadingLine,
  LoadingItem,
  DetailFileContentV2,
} from 'components/common'
import { variables } from 'constants/variables'

const TYPE_INFORMATION_STEERING = 'parent'
const CONG_VAN = 1
const cabinetTypes = {
  [Types.CABINET_UNIT]: 'unit',
  [Types.CABINET_DEPARTMENT]: 'department',
  [Types.HOSO_CONGVIEC]: 'profile',
  [Types.CABINET_PERSONAL]: 'personal',
}
const ACTIONS_VIEW = {
  TAO_CONG_VIEC: 'TAO_CONG_VIEC',
}

class DetailReceiveDocumentPage extends React.Component {
  static propTypes = {
    receiveDocument: PropTypes.object.isRequired,
    departments: PropTypes.array.isRequired,
    attachedUnits: PropTypes.array.isRequired,
    archives: PropTypes.object.isRequired,
    executingViews: PropTypes.object.isRequired,
    assignmentHistory: PropTypes.object.isRequired,
    actions: PropTypes.object.isRequired,
    auth: PropTypes.object.isRequired,
  }

  refHandlers = {
    detailFileContent: ref => (this.detailFileContent = ref),
  }

  constructor(props) {
    super(props)
    this.handleClickShowInfoMobile = this.handleClickShowInfoMobile.bind(this)
    this.handleClickForwardAssignment =
      this.handleClickForwardAssignment.bind(this)
    this.handleClickAssignment = this.handleClickAssignment.bind(this)
    this.handleClickUpdateAssignment =
      this.handleClickUpdateAssignment.bind(this)
    this.handleClickProposeAssignment =
      this.handleClickProposeAssignment.bind(this)
    this.handleClickSendMail = this.handleClickSendMail.bind(this)
    this.handleClickSaveArchives = this.handleClickSaveArchives.bind(this)
    this.handleClickAdditionalAssignment =
      this.handleClickAdditionalAssignment.bind(this)
    this.handleClickGoBack = this.handleClickGoBack.bind(this)
    //this.closeShowDetailInfo = this.closeShowDetailInfo.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this)
    this.handleClickAssignmentHistory =
      this.handleClickAssignmentHistory.bind(this)
    this.handleClickFlowdocumentary = this.handleClickFlowdocumentary.bind(this)
    this.handleClickDetailInfo = this.handleClickDetailInfo.bind(this)
    this.handleSelectSaveTo = this.handleSelectSaveTo.bind(this)
    this.handleSaveArchive = this.handleSaveArchive.bind(this)
    this.handleRedirectToEditForm = this.handleRedirectToEditForm.bind(this)
    this.handleFinishLoadingMigratedFile =
      this.handleFinishLoadingMigratedFile.bind(this)
    this.handleClickInformationAssign =
      this.handleClickInformationAssign.bind(this)
    this.handleScroll = this.handleScroll.bind(this)
    this.handleSubmitRemoveItem = this.handleSubmitRemoveItem.bind(this)
  }

  state = {
    showResults: false,
    showListAction: true,
    showSection: 'noContent',
    showDetailInfo: false,
    file: null,
    isUpdateAssignment: false,
    receiveDocumentId: this.props.match.params.id,
    receiveDocument: null,
    saveTo: [
      ...(Authorization.vaild(this.props.auth.permission, [
        Permission.TUTAILIEUDONVI_QUANLY,
      ])
        ? [
            {
              id: Types.CABINET_UNIT,
              name: 'Tủ công ty',
              disabled: !Authorization.vaild(this.props.auth.permission, [
                Permission.TUTAILIEUDONVI_QUANLY,
              ]),
            },
          ]
        : []),
      {
        id: Types.CABINET_DEPARTMENT,
        name: 'Tủ phòng ban',
      },
      // {
      //   id: Types.HOSO_CONGVIEC,
      //   name: 'Hồ sơ công việc',
      //   disabled: !Authorization.vaild(this.props.auth.permission, [
      //     Permission.TUTAILIEUPHONGBAN,
      //   ]),
      // },
      {
        id: Types.CABINET_PERSONAL,
        name: 'Tủ cá nhân',
      },
    ],
    isLoadingData: {
      receiveDocument: true,
      file: true,
      documentNumber: true,
      // CHANGED: Chuyển sang gọi api khi click action "Thông tin chỉ đạo"
      // informationSteering: true,
      // parentInformationSteering: true
    },
    isLoadingSubData: false,
    informationSteering: null,
    parentInformationSteering: null,
    informationAssign: null,
    isCheck: false, // Dự đoán dùng để check công văn đi/đến
    isLoadingMigratedFile: true,
    fileFromList: null, // File từ danh sách
    documentNumberFromList: null, // Số công văn từ danh sách
    isLoadedApi: {
      departments: false,
      staffs: false,
      units: false, // Don vi truc thuoc
      leaderDepartments: false, // Phong ban lanh dao
      proposeAssignment: false,
      informationSteering: false, // Thong tin chi dao
      parentInformationSteering: false, // Thong tin chi dao cap tren
      documentInformation: false, // Thong tin cong van
      assignmentHistory: false, // Lich su chi dao
      executingView: false,
      documentCabinetList: false,
      flowDocument: false, // Luong cong van
    },
    boxMenuFixTop: false,
    isLoading: false,
    titleEVN:
      this.props.match &&
      this.props.match.params &&
      this.props.match.params.from &&
      this.props.match.params.from === 'evn',
    documentData: {},
    dinhKemFilesProgress: 0,
    filesThamKhao: [],
    documentDetail: null,
    isLoadingDSDonViNhan: false,
    heightDocumentFile: 500,
  }

  onMenuItem = name => {
    if (!name) {
      return
    }

    this.setState({
      showResults: true,
      showListAction: false,
      showSection: name,
    })
  }

  getDSDonViNhan = () => {
    this.props.actions.commonFetchReceivingUnit()
  }

  componentWillUnmount() {
    this.setState({
      showResults: false,
      showListAction: true,
      showSection: 'noContent',
      showDetailInfo: false,
      isRedirect: false,
      redirectTo: null,
      file: null,
      isCheck: false,
    })
    window.removeEventListener('scroll', this.handleScroll)
  }

  handleFinishLoadingMigratedFile = () => {
    this.setState({ isLoadingMigratedFile: !this.state.isLoadingMigratedFile })
  }

  handleRedirectToEditForm = () => {
    this.setState({ isLoading: true }, () => {
      if (this.state.receiveDocumentId && this.props.history) {
        this.props.history.push(
          `/cong-van/cong-van-den/chinh-sua/${this.state.receiveDocumentId}`
        )
      }
    })
  }

  handleClickGoBack = e => {
    e.preventDefault()
    this.setState({
      showResults: false,
      showListAction: true,
      showSection: 'noContent',
      isUpdateAssignment: false,
    })
  }

  onSuccess = () => {
    this.props.actions.refreshPage(true)
    this.goBack()
  }

  handleClickForwardAssignment = async e => {
    e.preventDefault()
    ReactDOM.findDOMNode(this).scrollIntoView({ behavior: 'smooth' })
    this.setState({
      showResults: true,
      showListAction: false,
      showSection: 'ChuyenChiDao',
    })

    let isLoadedApi = this.state.isLoadedApi
    if (isLoadedApi && isLoadedApi.leaderDepartments === false) {
      await this.props.actions.commonFetchDepartmentsLeader().then(res => {
        if (res.payload && res.payload.status === 200) {
          isLoadedApi = {
            ...isLoadedApi,
            leaderDepartments: true,
          }
        }
      })
    }

    await this.setState({
      showResults: true,
      isLoadingSubData: false,
      isLoadedApi,
    })
  }

  handleClickUpdateAssignment = async e => {
    e.preventDefault()
    ReactDOM.findDOMNode(this).scrollIntoView({ behavior: 'smooth' })
    this.setState({
      showResults: false,
      showListAction: false,
      isLoadingSubData: true,
      showSection: 'ChiDao',
      isUpdateAssignment: true,
    })

    let isLoadedApi = this.state.isLoadedApi
    if (isLoadedApi && isLoadedApi.informationSteering === false) {
      await this.props.actions
        .InformationSteering(this.state.receiveDocumentId)
        .then(res => {
          if (res.payload && res.payload.data && res.payload.data.result) {
            isLoadedApi = {
              ...isLoadedApi,
              informationSteering: true,
            }
            this.setState({
              informationSteering: res.payload.data.result,
            })
          }
        })
    }

    if (isLoadedApi && isLoadedApi.departments === false) {
      await this.props.actions.commonFetchDepartments().then(res => {
        if (res.payload && res.payload.status === 200) {
          isLoadedApi = {
            ...isLoadedApi,
            departments: true,
          }
        }
      })
    }

    if (
      isLoadedApi &&
      isLoadedApi.units === false &&
      this.props.auth &&
      this.props.auth.mainUnitId
    ) {
      await this.props.actions
        .commonFetchAttachedUnits(this.props.auth.mainUnitId)
        .then(res => {
          if (res.payload && res.payload.status === 200) {
            isLoadedApi = {
              ...isLoadedApi,
              units: true,
            }
          }
        })
    }

    await this.setState({
      showResults: true,
      isLoadingSubData: false,
      isLoadedApi,
    })
  }

  handleClickAssignment = async e => {
    e.preventDefault()
    ReactDOM.findDOMNode(this).scrollIntoView({ behavior: 'smooth' })
    this.setState({
      showResults: false,
      showListAction: false,
      isLoadingSubData: true,
      showSection: 'ChiDao',
    })

    let isLoadedApi = this.state.isLoadedApi
    if (isLoadedApi && isLoadedApi.departments === false) {
      await this.props.actions.commonFetchDepartments().then(res => {
        if (res.payload && res.payload.status === 200) {
          isLoadedApi = {
            ...isLoadedApi,
            departments: true,
          }
        }
      })
    }

    if (
      isLoadedApi &&
      isLoadedApi.units === false &&
      this.props.auth &&
      this.props.auth.mainUnitId
    ) {
      await this.props.actions
        .commonFetchAttachedUnits(this.props.auth.mainUnitId)
        .then(res => {
          if (res.payload && res.payload.status === 200) {
            isLoadedApi = {
              ...isLoadedApi,
              units: true,
            }
          }
        })
    }

    if (
      isLoadedApi &&
      isLoadedApi.proposeAssignment === false &&
      this.state.receiveDocument &&
      this.state.receiveDocument.trangThai ===
        Types.DOCUMENT_RECEIVE_NO_PROCESS &&
      this.state.receiveDocument.daDeXuatChiDao === true
    ) {
      await this.props.actions
        .receiveDocumentFetchProposeAssigment(this.state.receiveDocumentId)
        .then(res => {
          if (res.payload && res.payload.status === 200) {
            isLoadedApi = {
              ...isLoadedApi,
              proposeAssignment: true,
            }
          }
        })
    }

    await this.setState({
      showResults: true,
      isLoadingSubData: false,
      isLoadedApi,
    })
  }

  handleClickProposeAssignment = async e => {
    e.preventDefault()
    ReactDOM.findDOMNode(this).scrollIntoView({ behavior: 'smooth' })
    this.setState({
      showResults: false,
      showListAction: false,
      isLoadingSubData: true,
      showSection: 'DeXuatChiDao',
    })

    let isLoadedApi = this.state.isLoadedApi
    if (isLoadedApi && isLoadedApi.departments === false) {
      await this.props.actions.commonFetchDepartments().then(res => {
        if (res.payload && res.payload.status === 200) {
          isLoadedApi = {
            ...isLoadedApi,
            departments: true,
          }
        }
      })
    }

    if (
      isLoadedApi &&
      isLoadedApi.units === false &&
      this.props.auth &&
      this.props.auth.mainUnitId
    ) {
      await this.props.actions
        .commonFetchAttachedUnits(this.props.auth.mainUnitId)
        .then(res => {
          if (res.payload && res.payload.status === 200) {
            isLoadedApi = {
              ...isLoadedApi,
              units: true,
            }
          }
        })
    }

    if (
      isLoadedApi &&
      isLoadedApi.proposeAssignment === false &&
      this.state.receiveDocument &&
      this.state.receiveDocument.trangThai ===
        Types.DOCUMENT_RECEIVE_NO_PROCESS &&
      this.state.receiveDocument.daDeXuatChiDao === true
    ) {
      await this.props.actions
        .receiveDocumentFetchProposeAssigment(this.state.receiveDocumentId)
        .then(res => {
          if (res.payload && res.payload.status === 200) {
            isLoadedApi = {
              ...isLoadedApi,
              proposeAssignment: true,
            }
          }
        })
    }

    await this.setState({
      showResults: true,
      isLoadingSubData: false,
      isLoadedApi,
    })
  }

  handleClickAdditionalAssignment = async e => {
    e.preventDefault()
    ReactDOM.findDOMNode(this).scrollIntoView({ behavior: 'smooth' })
    this.setState({
      showResults: false,
      showListAction: false,
      isLoadingSubData: true,
      showSection: 'ChiDaoBoSung',
    })

    let isLoadedApi = this.state.isLoadedApi
    if (isLoadedApi && isLoadedApi.informationSteering === false) {
      await this.props.actions
        .InformationSteering(this.state.receiveDocumentId)
        .then(res => {
          if (res.payload && res.payload.data && res.payload.data.result) {
            isLoadedApi = {
              ...isLoadedApi,
              informationSteering: true,
            }
            this.setState({
              informationSteering: res.payload.data.result,
            })
          }
        })
    }

    if (isLoadedApi && isLoadedApi.departments === false) {
      await this.props.actions.commonFetchDepartments().then(res => {
        if (res.payload && res.payload.status === 200) {
          isLoadedApi = {
            ...isLoadedApi,
            departments: true,
          }
        }
      })
    }

    if (
      isLoadedApi &&
      isLoadedApi.units === false &&
      this.props.auth &&
      this.props.auth.mainUnitId
    ) {
      await this.props.actions
        .commonFetchAttachedUnits(this.props.auth.mainUnitId)
        .then(res => {
          if (res.payload && res.payload.status === 200) {
            isLoadedApi = {
              ...isLoadedApi,
              units: true,
            }
          }
        })
    }

    await this.setState({
      showResults: true,
      isLoadingSubData: false,
      isLoadedApi,
    })
  }

  handleClickExecutingView = async e => {
    e.preventDefault()
    ReactDOM.findDOMNode(this).scrollIntoView({ behavior: 'smooth' })
    this.setState({
      showResults: false,
      showListAction: false,
      isLoadingSubData: true,
      showSection: 'XemThucHien',
    })

    let isLoadedApi = this.state.isLoadedApi
    if (isLoadedApi && isLoadedApi.executingView === false) {
      await this.props.actions
        .getExecutingView(this.state.receiveDocumentId)
        .then(res => {
          if (res.payload && res.payload.status === 200) {
            isLoadedApi = {
              ...isLoadedApi,
              executingView: true,
            }
          }
        })
    }
    await this.setState({
      showResults: true,
      isLoadingSubData: false,
      isLoadedApi,
    })
  }

  handleClickInformationAssign = async e => {
    e.preventDefault()
    ReactDOM.findDOMNode(this).scrollIntoView({ behavior: 'smooth' })
    this.setState({
      showResults: false,
      showListAction: false,
      isLoadingSubData: true,
      showSection: 'InformationAssign',
    })

    let isLoadedApi = this.state.isLoadedApi

    // Thong tin chi dao
    if (
      this.props.receiveDocument &&
      this.props.receiveDocument.item &&
      this.props.receiveDocument.item.congVanCapTrenChiDaoId &&
      isLoadedApi &&
      isLoadedApi.parentInformationSteering === false
    ) {
      await this.props.actions
        .InformationSteering(
          this.props.receiveDocument.item.congVanCapTrenChiDaoId,
          TYPE_INFORMATION_STEERING
        )
        .then(res => {
          if (res.payload && res.payload.data && res.payload.data.result) {
            isLoadedApi = {
              ...isLoadedApi,
              parentInformationSteering: true,
            }
            this.setState({
              parentInformationSteering: res.payload.data.result,
            })
          }
        })
    }

    // Thong tin chi dao cap tren
    if (
      this.props.receiveDocument &&
      this.props.receiveDocument.item &&
      this.props.receiveDocument.item.trangThai !==
        Types.DOCUMENT_RECEIVE_NO_PROCESS &&
      isLoadedApi &&
      isLoadedApi.informationSteering === false
    ) {
      await this.props.actions
        .InformationSteering(this.state.receiveDocumentId)
        .then(res => {
          if (res.payload && res.payload.data && res.payload.data.result) {
            isLoadedApi = {
              ...isLoadedApi,
              informationSteering: true,
            }
            this.setState({
              informationSteering: res.payload.data.result,
            })
          }
        })
    }

    await this.setState({
      showResults: true,
      isLoadingSubData: false,
      isLoadedApi,
    })
  }

  handleClickSendMail = e => {
    e.preventDefault()
    ReactDOM.findDOMNode(this).scrollIntoView({ behavior: 'smooth' })
    this.setState({
      showResults: true,
      showListAction: false,
      showSection: 'GuiEmail',
    })
  }

  handleClickSaveArchives = async e => {
    e.preventDefault()
    ReactDOM.findDOMNode(this).scrollIntoView({ behavior: 'smooth' })
    this.setState({
      showResults: false,
      showListAction: false,
      isLoadingSubData: true,
      showSection: 'LuuTuTaiLieu',
    })
    let isLoadedApi = this.state.isLoadedApi
    await this.setState({
      showResults: true,
      isLoadingSubData: false,
      isLoadedApi,
    })
  }

  getListDisable = async type => {
    const {
      actions,
      receiveDocument: { item },
    } = this.props
    const id = item.congVanId || item.congVanNoiBoId
    const docType = item.congVanNoiBoId ? 2 : 1

    actions.getListCabinetOfDocument(id, docType)
  }

  handleClickAssignmentHistory = async e => {
    e.preventDefault()
    ReactDOM.findDOMNode(this).scrollIntoView({ behavior: 'smooth' })
    this.setState({
      showResults: false,
      showListAction: false,
      isLoadingSubData: true,
      showSection: 'LichSuThongTinChiDao',
    })

    let isLoadedApi = this.state.isLoadedApi
    if (isLoadedApi && isLoadedApi.assignmentHistory === false) {
      await this.props.actions
        .getAssignmentHistory(this.state.receiveDocumentId)
        .then(res => {
          if (res.payload && res.payload.status === 200) {
            isLoadedApi = {
              ...isLoadedApi,
              assignmentHistory: true,
            }
          }
        })
    }

    await this.setState({
      showResults: true,
      isLoadingSubData: false,
      isLoadedApi,
    })
  }

  handleClickFlowdocumentary = async e => {
    e.preventDefault()
    ReactDOM.findDOMNode(this).scrollIntoView({ behavior: 'smooth' })
    this.setState({
      showResults: false,
      showListAction: false,
      // isLoadingSubData: true,
      showSection: 'LuongCongVan',
    })

    // let { isCheck, isLoadedApi } = this.state
    // if (
    //   this.props.receiveDocument &&
    //   this.props.receiveDocument.item &&
    //   this.props.receiveDocument.item.congVanDiId &&
    //   isLoadedApi &&
    //   isLoadedApi.flowDocument === false
    // ) {
    //   await this.props.actions
    //     .getFlowDocumentarySendDocument(
    //       this.props.receiveDocument.item.congVanDiId
    //     )
    //     .then(res => {
    //       if (res.payload && res.payload.status === 200) {
    //         isLoadedApi = {
    //           ...isLoadedApi,
    //           flowDocument: true,
    //         }
    //       }
    //       isCheck = true
    //     })
    // }

    // if (
    //   this.props.receiveDocument &&
    //   this.props.receiveDocument.item &&
    //   !this.props.receiveDocument.item.congVanDiId &&
    //   this.state.receiveDocumentId &&
    //   isLoadedApi &&
    //   isLoadedApi.flowDocument === false
    // ) {
    //   await this.props.actions
    //     .getFlowDocumentaryReceiveDocument(this.state.receiveDocumentId)
    //     .then(res => {
    //       if (res.payload && res.payload.status === 200) {
    //         isLoadedApi = {
    //           ...isLoadedApi,
    //           flowDocument: true,
    //         }
    //       }
    //     })
    // }

    await this.setState({
      // isCheck,
      showResults: true,
      // isLoadingSubData: false,
      // isLoadedApi,
    })
  }

  handleClickDetailInfo = async e => {
    e.preventDefault()
    ReactDOM.findDOMNode(this).scrollIntoView({ behavior: 'smooth' })
    this.setState({
      showResults: false,
      showListAction: false,
      isLoadingSubData: true,
      showSection: 'ThongTinCongVan',
    })

    let isLoadedApi = this.state.isLoadedApi
    if (isLoadedApi && isLoadedApi.documentInformation === false) {
      await this.props.actions
        .getReceiveDocumentItemInformation(this.state.receiveDocumentId)
        .then(res => {
          if (res.payload && res.payload.status === 200) {
            isLoadedApi = {
              ...isLoadedApi,
              documentInformation: true,
            }
          }
        })
    }

    await this.setState({
      showResults: true,
      isLoadingSubData: false,
      isLoadedApi,
    })
  }

  handleClickShowInfoMobile = e => {
    e.preventDefault()
    this.setState({
      showDetailInfo: !this.state.showDetailInfo,
    })
  }

  showDetailInfo = e => {
    e.preventDefault()
    this.setState({
      showDetailInfo: !this.state.showDetailInfo,
    })
  }

  closeShowDetailInfo = e => {
    e.preventDefault()
    this.setState({
      showDetailInfo: false,
    })
  }

  changeUploadProgress = progress => {
    this.setState({
      dinhKemFilesProgress: ~~((progress.loaded * 100) / progress.total),
    })
  }

  uploadFile = async files => {
    const { fileUpload } = this.props.actions
    let response = {}
    if (size(files)) {
      response = await fileUpload(files, this.changeUploadProgress)
    }
    if (response.error) {
      return this.showValidateMessage(MESSAGE.TEXT_MESSAGE_UPLOAD_FAIL, true)
    }

    return get(response, 'payload.data.result', []).map(file =>
      Tool.Utils.convertFileResponse(file)
    )
  }

  handleSaveJobProject = async (err, params) => {
    const {
      actions,
      receiveDocument: { item },
    } = this.props
    const { commonAddToasterMessage, postTaiLieuHoSoCongViec } = actions

    if (err) {
      return commonAddToasterMessage({
        message: err,
        inten: Intent.DANGER,
      })
    }

    const files = await this.uploadFile(get(params, 'files', []))
    const data = Tool.Utils.toDecamelizeKeys({
      tenTaiLieu: item.trichYeu,
      ghiChu: params.ghiChu,
      congVanId: item.congVanId,
      congVanNoiBoId: item.congVanNoiBoId,
      dsHoSoCongViecId: params.nganTuIds,
      soCongVan: item.soCongVan,
      ngayCongVan: item.ngayCongVan,
      fileNoiDung: item.fileNoiDung,
      fileDinhKem: [
        ...item.fileDinhKem.map(i => ({ ...i, is_existed: true })),
        ...files,
      ],
      fileThamKhao: [...this.state.filesThamKhao],
    })

    try {
      const response = await postTaiLieuHoSoCongViec(data)
      if (response.error) {
        return commonAddToasterMessage({
          message: MESSAGE.TOATS_MESSAGE_FAIL,
          inten: Intent.DANGER,
        })
      }

      if (get(response, 'payload.status') === 200) {
        commonAddToasterMessage({
          message: MESSAGE.TOATS_MESSAGE_SUCCESS,
          intent: Intent.SUCCESS,
        })
        ReactDOM.findDOMNode(this).scrollIntoView({ behavior: 'smooth' })
        this.setState({
          showResults: false,
          showListAction: true,
          showSection: 'noContent',
          isLoadedApi: {
            ...this.state.isLoadedApi,
            documentCabinetList: false,
          },
        })
      }
    } catch (error) {
      return commonAddToasterMessage({
        message: MESSAGE.TOATS_MESSAGE_FAIL,
        inten: Intent.DANGER,
      })
    }
  }

  handleSaveArchive = (err, params) => {
    if (err) {
      return this.props.actions.commonAddToasterMessage({
        message: err,
        intent: Intent.DANGER,
      })
    }

    if (params.categoryType === Types.HOSO_CONGVIEC) {
      return this.handleSaveJobProject(err, params)
    }

    const { id } = this.props.match.params
    params = {
      ds_ngan_tu: params.nganTuIds,
      loai_tu_tai_lieu: parseInt(params.categoryType, 10),
      ghi_chu: params.ghiChu,
      ds_file_cong_van: [
        {
          file_id: this.props.receiveDocument.item.fileNoiDung.fileId,
          cong_van_den_id: id,
          ten_tai_lieu: params.tenTaiLieu,
        },
      ],
    }

    return this.props.actions
      .documentsCabinetSaveReceiveDocument(params)
      .then(res => {
        if (res.error) {
          return this.props.actions.commonAddToasterMessage({
            message: MESSAGE.TOATS_MESSAGE_FAIL,
            intent: Intent.DANGER,
          })
        }
        if (res.payload && res.payload.status === 200) {
          this.props.actions.commonAddToasterMessage({
            message: MESSAGE.TOATS_MESSAGE_SUCCESS,
            intent: Intent.SUCCESS,
          })
          ReactDOM.findDOMNode(this).scrollIntoView({ behavior: 'smooth' })
          this.setState({
            showResults: false,
            showListAction: true,
            showSection: 'noContent',
            isLoadedApi: {
              ...this.state.isLoadedApi,
              documentCabinetList: false,
            },
          })
        }
      })
  }

  handleSubmit = (title, msg, intent, isSuccess, id = 0) => {
    this.props.actions.commonAddToasterMessage({ message: msg, intent: intent })
    if (isSuccess) {
      this.props.actions.refreshPage(true)
      this.goBack()
    }
  }

  fetchContactFetchDepartmentByUnit = () => {
    this.props.actions.contactFetchDepartmentAndPersonalTree(
      this.props.auth.mainUnitId
    )
  }

  async componentDidMount() {
    window.addEventListener('scroll', this.handleScroll)

    if (!this.props.objects) {
      this.props.actions.commonFetchObjects()
    }

    if (!this.props.securities) {
      this.props.actions.commonFetchSecurities()
    }

    if (!this.props.priorities) {
      this.props.actions.commonFetchPriorities()
    }

    // Nếu có fileId, soCongVan lấy từ danh sách
    if (this.props.location && this.props.location.state) {
      // Gọi api get thông tin file => state.fileFromList
      let fileFromList = null
      let documentNumberFromList = null
      let isLoadingData = this.state.isLoadingData

      if (this.props.location.state.fileId) {
        await this.props.actions
          .getFileInformation(this.props.location.state.fileId)
          .then(res => {
            if (
              res &&
              res.payload &&
              res.payload.data &&
              res.payload.data.result
            ) {
              fileFromList = res.payload.data.result
              isLoadingData = {
                ...isLoadingData,
                file: false,
              }
            }
          })
      }

      // Lấy số công văn từ danh sách
      if (this.props.location.state.soCongVan) {
        documentNumberFromList = this.props.location.state.soCongVan
        isLoadingData = {
          ...isLoadingData,
          documentNumber: false,
        }
      }

      if (fileFromList || documentNumberFromList) {
        await this.setState({
          fileFromList,
          documentNumberFromList,
          isLoadingData,
        })
      }
    }
    if (this.state.receiveDocumentId) {
      // CHANGED: Đổi api get chi tiết công văn lúc đầu tiên
      this.props.actions
        .getReceiveDocumentItemShorten(this.state.receiveDocumentId)
        .then(res => {
          if (!this.props.receiveDocument || !this.props.receiveDocument.item) {
            return
          }

          // Gọi api get file tham khảo
          this.getFileThamKhao(this.props.receiveDocument.item).then(res => {
            if (res && res.length !== 0) {
              this.setState({
                filesThamKhao: res,
              })
            }
          })

          this.setState({
            file: this.props.receiveDocument.item.fileNoiDung,
            receiveDocument: this.props.receiveDocument.item,
            isLoadingData: {
              ...this.state.isLoadingData,
              receiveDocument: false,
              file: false,
              documentNumber: false,
            },
          })
        })
        .catch(err => {
          this.setState({
            isLoadingData: {
              ...this.state.isLoadingData,
              receiveDocument: false,
              file: false,
              documentNumber: false,
            },
          })
        })
    }

    // chi dao tu check item ngoai danh sach
    if (
      this.props.location &&
      this.props.location.state &&
      this.props.location.state.action === 'ChiDao'
    ) {
      ReactDOM.findDOMNode(this).scrollIntoView({ behavior: 'smooth' })
      await this.setState({
        showResults: false,
        showListAction: false,
        isLoadingSubData: true,
        showSection: 'ChiDao',
      })

      let isLoadedApi = this.state.isLoadedApi
      if (isLoadedApi && isLoadedApi.departments === false) {
        await this.props.actions.commonFetchDepartments().then(res => {
          if (res.payload && res.payload.status === 200) {
            isLoadedApi = {
              ...isLoadedApi,
              departments: true,
            }
          }
        })
      }

      if (
        isLoadedApi &&
        isLoadedApi.units === false &&
        this.props.auth &&
        this.props.auth.mainUnitId
      ) {
        await this.props.actions
          .commonFetchAttachedUnits(this.props.auth.mainUnitId)
          .then(res => {
            if (res.payload && res.payload.status === 200) {
              isLoadedApi = {
                ...isLoadedApi,
                units: true,
              }
            }
          })
      }

      if (
        isLoadedApi &&
        isLoadedApi.proposeAssignment === false &&
        this.state.receiveDocument &&
        this.state.receiveDocument.trangThai ===
          Types.DOCUMENT_RECEIVE_NO_PROCESS &&
        this.state.receiveDocument.daDeXuatChiDao === true
      ) {
        await this.props.actions
          .receiveDocumentFetchProposeAssigment(this.state.receiveDocumentId)
          .then(res => {
            if (res.payload && res.payload.status === 200) {
              isLoadedApi = {
                ...isLoadedApi,
                proposeAssignment: true,
              }
            }
          })
      }

      await this.setState({
        showResults: true,
        isLoadingSubData: false,
        isLoadedApi,
      })
    }

    this.getDSDonViNhan()
    this.fetchContactFetchDepartmentByUnit()
  }

  getFileThamKhao = async data => {
    let params = {
      loai: 1,
    }
    let id = -1
    if (data.congVanId) {
      params = {
        ...params,
        type: 'CONG_VAN',
      }
      id = data.congVanId
    } else {
      return []
    }

    let filesThamKhao = []
    await this.props.actions.getDanhSachFiles(id, params).then(res => {
      if (
        res &&
        res.payload &&
        res.payload.data &&
        res.payload.data.result &&
        res.payload.data.result.items &&
        res.payload.data.result.items.length !== 0
      ) {
        res.payload.data.result.items.map(elm =>
          filesThamKhao.push({
            ...elm,
            is_existed: true,
          })
        )
      }
    })

    return filesThamKhao
  }

  goBack() {
    this.props.history.goBack()
  }

  handleSelectSaveTo(v) {
    const {
      setCabinetType,
      documentsCabinetGetListCategoriesUnit,
      documentsCabinetGetListCategoriesPersonal,
      documentsCabinetGetListCategoriesDepartment,
      getLoaiLuuTaiLieu,
    } = this.props.actions

    setCabinetType(v)
    this.getListDisable(v)
    switch (v) {
      case Types.CABINET_UNIT:
        documentsCabinetGetListCategoriesUnit()
        break
      case Types.CABINET_PERSONAL:
        documentsCabinetGetListCategoriesPersonal()
        break
      case Types.CABINET_DEPARTMENT:
        documentsCabinetGetListCategoriesDepartment()
        break
      case Types.HOSO_CONGVIEC:
        const params = {
          loai: 1,
          phanLoaiTu: 'PHONGBAN',
        }
        getLoaiLuuTaiLieu(params)
        break
      default:
    }
  }

  handleClickDepartmentExecutingView = id => {
    this.props.actions.getMoreExecutingView(id)
  }

  handleClickMoreDepartmentExecutingView = id => {
    this.props.actions.getMoreFlowDocumentaryReceiveDocument(id)
  }

  handleScroll(event) {
    let scrollTop = window.pageYOffset
    if (scrollTop > 62) {
      this.setState({
        boxMenuFixTop: true,
      })
    } else {
      this.setState({
        boxMenuFixTop: false,
      })
    }
  }

  handleSubmitRemoveItem() {
    this.props.actions
      .deleteAlertMistakeDocument(this.state.receiveDocumentId, CONG_VAN)
      .then(res => {
        if (res && res.payload && res.payload.data && res.payload.data.result) {
          this.props.actions.commonAddToasterMessage({
            message: MESSAGE.TEXT_MESSAGE_ALERTMISTAKE_SUCCESS,
            intent: Intent.SUCCESS,
          })
          this.props.history.goBack()
        } else {
          this.props.actions.commonAddToasterMessage({
            message: MESSAGE.TEXT_MESSAGE_ALERTMISTAKE_FAIL,
            intent: Intent.DANGER,
          })
        }
      })
  }

  goGiaoViecPhienBanMoi = () => {
    this.setState({
      showListAction: false,
      showResults: true,
      showSection: ACTIONS_VIEW.TAO_CONG_VIEC,
    })
  }

  /**
   * Gửi file đính kèm qua Chat
   * @memberof DetailReceiveDocumentPage
   */
  sendToChat = e => {
    e.preventDefault()
    let { receiveDocument } = this.props
    if (receiveDocument && receiveDocument.item) {
      let fileNoiDung = receiveDocument.item.fileNoiDung
        ? receiveDocument.item.fileNoiDung.fileId
        : ''
      // Truyền param id của công văn, công việc hoặc ký số. Có thêm kiểu của loại tài liệu
      let infoGetFileDinhKem = receiveDocument.item.congVanId
      let infoGetFileDinhKemType = 'CONG_VAN'
      if (!infoGetFileDinhKem) {
        infoGetFileDinhKem = receiveDocument.item.congVanNoiBoId
        infoGetFileDinhKemType = 'CONG_VAN_NOI_BO'
      }
      const url = `?fileId=${fileNoiDung}&infoGetFileDinhKem=${infoGetFileDinhKem}&infoGetFileDinhKemType=${infoGetFileDinhKemType}`
      return this.props.history.push(`${variables.CHAT_URL}${url}`)
    }
  }

  componentDidUpdate() {
    if (
      this.refLeft?.offsetHeight &&
      this.refLeft?.offsetHeight !== this.state.heightDocumentFile
    ) {
      this.setState({
        heightDocumentFile: this.refLeft?.offsetHeight,
      })
    }
  }

  UNSAFE_componentWillReceiveProps(props) {
    const receiveDocument = props?.receiveDocument?.item
    if (receiveDocument) {
      const documentDetail = {
        congVanId: receiveDocument?.congVanId || null,
        trichYeu: receiveDocument?.trichYeu || null,
        fileThamKhao: receiveDocument?.fileNoiDung || null,
        fileDinhKemEvn: receiveDocument?.fileDinhKem || [],
      }
      this.setState({ documentDetail })
    }
  }

  render() {
    let fileNoiDung =
      this.props.receiveDocument && this.props.receiveDocument.item
        ? this.props.receiveDocument.item.fileNoiDung
        : {}
    if (fileNoiDung) {
      fileNoiDung.kichThuocStr = Tool.Utils.getFileSize(fileNoiDung.kichThuoc)
    }

    // Nếu có file từ danh sách thì dùng file, soCongVan từ danh sách,
    // ngược lại dùng file, soCongVan get từ api chi tiết
    const fileDetail = this.state.fileFromList || this.state.file
    let documentNumberDetail = this.state.documentNumberFromList

    if (
      !this.state.documentNumberFromList &&
      this.state.receiveDocument &&
      this.state.receiveDocument.soCongVan
    ) {
      documentNumberDetail = this.state.receiveDocument.soCongVan
    }

    const { archives, auth } = this.props
    const cabinetCategories = get(
      archives,
      `type[${
        cabinetTypes[get(auth, 'defaultCabinetType', Types.CABINET_PERSONAL)]
      }]`
    )

    return (
      <MasterLayout typeSidebar="documentary" collapseSideBar={true}>
        <div className="detail-receive-document">
          {this.state.isLoadingData &&
          this.state.isLoadingData.documentNumber === true ? (
            <div className="row-detail-container clearfix loading-line-detail">
              <div className="col-md-4 col-xs-12">
                <LoadingLine />
              </div>
            </div>
          ) : (
            <div className="dispatch-code">
              <button
                onClick={this.goBack.bind(this)}
                type="button"
                className={classnames(
                  'pt-button pt-button-back button-back-fixed',
                  {
                    'fixed-button-back':
                      this.state.boxMenuFixTop &&
                      !this.props.enableScrollFixedMenu &&
                      window.innerWidth >= 1024,
                    'fixed-button-hasSidebar':
                      this.state.boxMenuFixTop &&
                      this.props.enableScrollFixedMenu &&
                      window.innerWidth >= 1024,
                  }
                )}
              >
                <span className="pt-icon pt-icon-chevron-left pt-align-left"></span>
                <span className="text-content">Quay lại</span>
              </button>
              {this.state.receiveDocumentId &&
                this.state.receiveDocument &&
                this.state.receiveDocument.trangThai ===
                  Types.DOCUMENT_RECEIVE_NO_PROCESS &&
                window.innerWidth <= 530 && (
                  <Authorization.Element
                    permission={[Permission.CONGVANDEN_QUANLY]}
                  >
                    <button
                      onClick={this.handleRedirectToEditForm}
                      type="button"
                      className="btn-edit-blue"
                      disabled={this.state.isLoading}
                    >
                      <span className="cpc-icon-left icon-edit-line"></span>
                      <span className="text-content">Sửa/Trình công văn</span>
                    </button>
                  </Authorization.Element>
                )}
              {documentNumberDetail && (
                <span className="number-code">
                  <span className="name">
                    {this.state.titleEVN ? 'Công văn từ EVN' : 'Công văn đến'}:
                  </span>
                  <span className="code">{documentNumberDetail}</span>
                </span>
              )}

              {this.state.receiveDocumentId &&
                this.state.receiveDocument &&
                this.state.receiveDocument.trangThai ===
                  Types.DOCUMENT_RECEIVE_NO_PROCESS &&
                window.innerWidth > 530 && (
                  <Authorization.Element
                    permission={[Permission.CONGVANDEN_QUANLY]}
                  >
                    <button
                      onClick={this.handleRedirectToEditForm}
                      type="button"
                      className="btn-edit-blue"
                      disabled={this.state.isLoading}
                    >
                      <span className="cpc-icon-left icon-edit-line"></span>
                      <span className="text-content">Sửa/Trình công văn</span>
                    </button>
                  </Authorization.Element>
                )}
            </div>
          )}
          <div className="detail-container">
            <div className="row-detail-container clearfix">
              <div className="box-left" ref={e => (this.refLeft = e)}>
                {!isEmpty(fileDetail) && (
                  <DetailFileContentV2
                    auth={this.props.auth}
                    actions={this.props.actions}
                    file={fileDetail}
                  />
                )}
              </div>
              <div
                className={classnames('box-right', {
                  fixtopMenuRight:
                    this.state.boxMenuFixTop &&
                    !this.state.showResults &&
                    !this.props.enableScrollFixedMenu &&
                    window.innerWidth >= 1024,
                  fixtopMenuRightHasSideBar:
                    this.state.boxMenuFixTop &&
                    !this.state.showResults &&
                    this.props.enableScrollFixedMenu &&
                    window.innerWidth >= 1024,
                })}
              >
                <div className="full-box-content">
                  {this.state.isLoadingData &&
                    Object.values(this.state.isLoadingData).find(
                      item => item === true
                    ) && (
                      <div className="loading-container">
                        <LoadingItem />
                      </div>
                    )}
                  {this.state.showListAction &&
                    !Object.values(this.state.isLoadingData).find(
                      item => item === true
                    ) && (
                      <DetailBoxAction
                        onClickForwardAssignment={
                          this.handleClickForwardAssignment
                        }
                        onClickAssignment={this.handleClickAssignment}
                        onClickProposeAssignment={
                          this.handleClickProposeAssignment
                        }
                        onClickExecutingView={this.handleClickExecutingView}
                        onClickSendMail={this.handleClickSendMail}
                        onClickSaveArchives={this.handleClickSaveArchives}
                        onClickAssignmentHistory={
                          this.handleClickAssignmentHistory
                        }
                        onClickFlowdocumentary={this.handleClickFlowdocumentary}
                        onClickDetailInfo={this.handleClickDetailInfo}
                        onClickReportWork={this.handleReportWork}
                        onClickAdditionalAssignment={
                          this.handleClickAdditionalAssignment
                        }
                        onClickUpdateAssignment={
                          this.handleClickUpdateAssignment
                        }
                        assignmentHistory={this.props.assignmentHistory.items}
                        receiveDocument={
                          this.state.receiveDocument
                            ? this.state.receiveDocument
                            : {}
                        }
                        onHandleSubmit={this.handleSubmit}
                        departments={this.props.departments}
                        auth={this.props.auth}
                        actions={this.props.actions}
                        informationSteering={this.state.informationSteering}
                        parentInformationSteering={
                          this.state.parentInformationSteering
                        }
                        isLoadingData={this.state.isLoadingData}
                        onClickInformationAssign={
                          this.handleClickInformationAssign
                        }
                        status={
                          this.props.location &&
                          this.props.location.state &&
                          this.props.location.state.status === 'chuyen-nham'
                            ? true
                            : false
                        }
                        handleSubmitRemoveItem={this.handleSubmitRemoveItem}
                        sendToChat={this.sendToChat}
                        filesThamKhao={this.state.filesThamKhao}
                        quyensMenu={this.props.quyensMenu}
                        onMenuItem={this.onMenuItem}
                      />
                    )}

                  {!this.state.showListAction &&
                    this.state.isLoadingSubData && (
                      <div className="loading-container">
                        <LoadingItem />
                      </div>
                    )}

                  {this.state.showResults &&
                    this.state.showSection === 'ChuyenChiDao' && (
                      <ForwardAssignment
                        onClickGoBack={this.handleClickGoBack}
                        departments={this.props.leaderDepartments}
                        onSubmit={this.handleSubmit}
                        receiveDocument={
                          this.state.receiveDocument
                            ? this.state.receiveDocument
                            : {}
                        }
                        action={this.props.actions}
                        isLoadingData={this.state.isLoadingData}
                      />
                    )}

                  {/* Giao lại */}
                  {this.state.showResults &&
                    this.state.showSection === 'ChiDao' && (
                      <div className="box-action-item">
                        <AssignWork
                          receiveDocument={this.state?.receiveDocument}
                          onGoBack={this.handleClickGoBack}
                          onSubmit={this.handleSubmit}
                          units={this.props.receivingUnit}
                          status={
                            this.props.location &&
                            this.props.location.state &&
                            this.props.location.state.status === 'chuyen-nham'
                              ? true
                              : false
                          }
                          congVanDenId={this.state.receiveDocumentId}
                          informationSteering={this.state.informationSteering}
                          proposeAssignment={
                            this.state.receiveDocument &&
                            this.state.receiveDocument.daDeXuatChiDao === true
                              ? this.props.proposeAssignment &&
                                this.props.proposeAssignment.item
                              : null
                          }
                          isUpdateAssignment={this.state.isUpdateAssignment}
                        />
                      </div>
                    )}

                  {this.state.showResults &&
                    this.state.showSection === 'DeXuatChiDao' && (
                      <div className="box-action-item">
                        <ProposeAssignment
                          onClickGoBack={this.handleClickGoBack}
                          departments={this.props.departments}
                          units={this.props.receivingUnit}
                          onSubmit={this.handleSubmit}
                          actions={this.props.actions}
                          congVanDenId={this.state.receiveDocumentId}
                          proposeAssignment={
                            this.state.receiveDocument &&
                            this.state.receiveDocument.daDeXuatChiDao === true
                              ? this.props.proposeAssignment &&
                                this.props.proposeAssignment.item
                              : null
                          }
                          readOnly={this.props.readOnly}
                          mainDepartmentId={
                            this.props.auth && this.props.auth.mainDepartmentId
                          }
                        />
                      </div>
                    )}

                  {this.state.showResults &&
                    this.state.showSection === 'ChiDaoBoSung' && (
                      <div className="box-action-item">
                        <AssignWorkAdditional
                          receiveDocument={this.state?.receiveDocument}
                          informationSteering={this.state.informationSteering}
                          units={this.props.receivingUnit}
                          onGoBack={this.handleClickGoBack}
                          onSubmit={this.handleSubmit}
                        />
                        {/* <AdditionalAssignment
                          auth={this.props.auth}
                          onClickGoBack={this.handleClickGoBack}
                          departments={this.props.departments}
                          units={this.props.receivingUnit}
                          informationSteering={this.state.informationSteering}
                          onSubmit={this.handleSubmit}
                          actions={this.props.actions}
                          congVanDenId={this.state.receiveDocumentId}
                          receiveDocument={
                            this.state.receiveDocument
                              ? this.state.receiveDocument
                              : {}
                          }
                          readOnly={this.props.readOnly}
                          mainDepartmentId={
                            this.props.auth && this.props.auth.mainDepartmentId
                          }
                          departmentAndPersonalTree={
                            this.props.departmentAndPersonalTree
                          }
                        /> */}
                      </div>
                    )}

                  {this.state.showResults &&
                    this.state.showSection === 'XemThucHien' && (
                      <div className="box-action-item">
                        <ExecutingView
                          onClickGoBack={this.handleClickGoBack}
                          onClickDepartment={
                            this.handleClickDepartmentExecutingView
                          }
                          executingViews={this.props.executingViews.items}
                          assignmentHistory={this.props.assignmentHistory.items}
                          departments={this.props.departments}
                          actions={this.props.actions}
                          onHandleSubmit={this.handleSubmit}
                          auth={this.props.auth}
                        />
                      </div>
                    )}

                  {this.state.showResults &&
                    this.state.showSection === 'GuiEmail' && (
                      <div className="box-action-item">
                        <SendMail
                          auth={this.props.auth}
                          onClickGoBack={this.handleClickGoBack}
                          onSubmit={this.handleSubmit}
                          action={this.props.actions}
                          receiveDocument={
                            this.state.receiveDocument
                              ? this.state.receiveDocument
                              : {}
                          }
                        />
                      </div>
                    )}

                  {this.state.showResults &&
                    this.state.showSection === 'LuuTuTaiLieu' && (
                      <div className="box-action-item">
                        <SaveDocument
                          file={fileNoiDung ? fileNoiDung : {}}
                          storage={this.props.archives.storage}
                          onClickGoBack={this.handleClickGoBack}
                          archives={cabinetCategories}
                          saveTo={this.state.saveTo}
                          onSelectSaveTo={this.handleSelectSaveTo}
                          onSubmit={this.handleSaveArchive}
                          receiveDocument={
                            this.props.receiveDocument
                              ? this.props.receiveDocument
                              : {}
                          }
                          listCabinetOfDocument={
                            this.props.listCabinetOfDocument
                          }
                          defaultType={this.props.auth.defaultCabinetType}
                          auth={this.props.auth}
                          dinhKemFilesProgress={this.state.dinhKemFilesProgress}
                          filesThamKhao={this.state.filesThamKhao}
                        />
                      </div>
                    )}

                  {this.state.showResults &&
                    this.state.showSection === 'LichSuThongTinChiDao' && (
                      <div className="box-action-item">
                        <AssignmentHistory
                          onClickGoBack={this.handleClickGoBack}
                          assignmentHistory={this.props.assignmentHistory.items}
                          departments={this.props.departments}
                          onHandleSubmit={this.handleSubmit}
                        />
                      </div>
                    )}

                  {this.state.showResults &&
                    this.state.showSection === 'ThongTinCongVan' && (
                      <div className="box-action-item">
                        <DetailInfo
                          onClickGoBack={this.handleClickGoBack}
                          receiveDocument={
                            this.props.receiveDocument
                              ? this.props.receiveDocument.information
                              : {}
                          }
                          securities={this.props.securities}
                          priorities={this.props.priorities}
                          objects={this.props.objects}
                        />
                      </div>
                    )}

                  {this.state.showResults &&
                    this.state.showSection === 'LuongCongVan' && (
                      <div className="box-action-item">
                        <div className="container-form-action">
                          <div className="heading">
                            <span
                              className="click-go-back"
                              onClick={this.handleClickGoBack}
                            >
                              <span className="pt-icon pt-icon-arrow-left pull-left"></span>
                            </span>
                            <h4 className="name-heading">Luồng công văn</h4>
                          </div>
                          <div className="p10">
                            <DocumentFlow
                              autoHeightMax={this.state.heightDocumentFile - 80}
                              congVanDenId={
                                this.props.receiveDocument?.item?.congVanDenId
                              }
                            />
                          </div>
                        </div>
                        {/* {!this.state.isCheck && (
                          <Flowdocumentary
                            auth={this.props.auth}
                            onClickGoBack={this.handleClickGoBack}
                            flowDocument={
                              this.props.executingViews
                                .flowDocument_ReceiveDocument
                            }
                            onClickDepartment={
                              this.handleClickMoreDepartmentExecutingView
                            }
                            actions={this.props.actions}
                          />
                        )}
                        {this.state.isCheck && (
                          <FlowSendDocumentary
                            auth={this.props.auth}
                            onClickGoBack={this.handleClickGoBack}
                            flowDocument={
                              this.props.executingViews.flowSendDocument
                            }
                            onClickDepartment={
                              this.handleClickMoreDepartmentExecutingView
                            }
                            flowReceiveDocument={
                              this.props.executingViews.flowDocuments
                            }
                            actions={this.props.actions}
                          />
                        )} */}
                      </div>
                    )}

                  {this.state.showResults &&
                    this.state.showSection === 'InformationAssign' && (
                      <InformationSteering
                        onClickGoBack={this.handleClickGoBack}
                        informationSteering={this.state.informationSteering}
                        isShowMobile={true}
                        isShowMobileLevel={true}
                        informationSteeringLevel={
                          this.state.parentInformationSteering
                        }
                      />
                    )}

                  {this.state.showResults &&
                    this.state.showSection === 'USER_VIEWED' && (
                      <UserViewed
                        id={this.props.receiveDocument?.item?.congVanId}
                        onGoBack={this.handleClickGoBack}
                      />
                    )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </MasterLayout>
    )
  }
}

const mapStateToProps = state => ({
  auth: state.auth,

  receiveDocument: {
    ...state.receiveDocuments,
    item: state.entities.receiveDocuments[state.receiveDocuments.detailId],
    informationSteering: state.receiveDocuments.item,
    parentInformationSteering: state.receiveDocuments.parentInformationSteering,
    information: state.receiveDocuments.information,
  },

  proposeAssignment: {
    ...state.proposeAssignment,
    item: state.proposeAssignment.item,
  },

  departments: state.common.departments,

  leaderDepartments: state.common.leaderDepartments,

  attachedUnits: state.common.attachedUnits,

  archives: {
    ...state.documentsCabinet,
    categories: state.documentsCabinet.categories.map(
      id => state.entities.documentCabinetCategories[id]
    ),
    type: {
      unit: state.documentsCabinet.type.unit.map(
        id => state.entities.documentCabinetCategories[id]
      ),
      department: state.documentsCabinet.type.department.map(
        id => state.entities.documentCabinetCategories[id]
      ),
      personal: state.documentsCabinet.type.personal.map(
        id => state.entities.documentCabinetCategories[id]
      ),
      profile: state.documentsCabinet.type.profile.map(
        id => state.entities.documentCabinetCategories[id]
      ),
    },
    storage: {
      ...state.documentsCabinet.storage,
      used: Tool.Utils.getFileSize(state.documentsCabinet.storage.used),
    },
  },

  executingViews: {
    ...state.executingViews,
    items:
      state.executingViews.items &&
      state.executingViews.items.map(id => {
        let data = state.entities.executingViews[id]
        const getDSDonVi = dsDonViTrucThuocResponse => {
          return dsDonViTrucThuocResponse.map(item => {
            let subItem = state.entities.executingViews[item.chiDaoId]

            return {
              ...item,
              executingViews: [
                subItem
                  ? {
                      ...subItem,
                      dsDonViTrucThuocResponse: getDSDonVi(
                        subItem.dsDonViTrucThuocResponse
                      ),
                    }
                  : undefined,
              ],
            }
          })
        }

        return {
          ...data,
          dsDonViTrucThuocResponse: getDSDonVi(data.dsDonViTrucThuocResponse),
        }
      }),

    flowDocuments: Object.keys(state.executingViews.flowDocuments).map(key => {
      const item = state.executingViews.flowDocuments[key]
      return {
        ...item,
        nguoiTaoCongVan: state.entities.users[item.nguoiTaoCongVan],
        chiDao: {
          ...state.entities.executingViews[item.chiDao],
          dsDonViTrucThuocResponse: (() => {
            if (!state.entities.executingViews[item.chiDao]) {
              return []
            }
            const getDSDonVi = dsDonViTrucThuocResponse => {
              return dsDonViTrucThuocResponse.map(item => {
                let subItem = state.entities.executingViews[item.chiDaoId]
                let flowDocument =
                  state.executingViews.flowDocuments[item.chiDaoId]
                return {
                  ...item,
                  executingViews: [
                    subItem
                      ? {
                          ...subItem,
                          dsDonViTrucThuocResponse: getDSDonVi(
                            subItem.dsDonViTrucThuocResponse
                          ),
                        }
                      : undefined,
                  ],
                  flowDocument: {
                    ...flowDocument,
                    nguoiTaoCongVan: flowDocument
                      ? state.entities.users[flowDocument.nguoiTaoCongVan]
                      : undefined,
                  },
                }
              })
            }

            return getDSDonVi(
              state.entities.executingViews[item.chiDao]
                .dsDonViTrucThuocResponse
            )
          })(),
        },
      }
    }),

    flowDocument_ReceiveDocument: {
      ...state.executingViews.flowDocument_ReceiveDocument,
      nguoiTaoCongVan:
        state.entities.users[
          state.executingViews.flowDocument_ReceiveDocument.nguoiTaoCongVan
        ],
      chiDao: {
        ...state.entities.executingViews[
          state.executingViews.flowDocument_ReceiveDocument.chiDao
        ],
        dsDonViTrucThuocResponse: (() => {
          if (
            !state.entities.executingViews[
              state.executingViews.flowDocument_ReceiveDocument.chiDao
            ]
          ) {
            return []
          }
          const getDSDonVi = dsDonViTrucThuocResponse => {
            return dsDonViTrucThuocResponse.map(item => {
              let subItem = state.entities.executingViews[item.chiDaoId]
              let flowDocument =
                state.executingViews.flowDocuments[item.congVanDenId]
              return {
                ...item,
                executingViews: [
                  subItem
                    ? {
                        ...subItem,
                        dsDonViTrucThuocResponse: getDSDonVi(
                          subItem.dsDonViTrucThuocResponse
                        ),
                      }
                    : undefined,
                ],
                flowDocument: {
                  ...flowDocument,
                  nguoiTaoCongVan: flowDocument
                    ? state.entities.users[flowDocument.nguoiTaoCongVan]
                    : undefined,
                },
              }
            })
          }

          return getDSDonVi(
            state.entities.executingViews[
              state.executingViews.flowDocument_ReceiveDocument.chiDao
            ].dsDonViTrucThuocResponse
          )
        })(),
      },
    },
  },

  assignmentHistory: {
    ...state.assignmentHistory,
    items: state.assignmentHistory.list,
  },
  listCabinetOfDocument: state.common.listCabinetOfDocument.map(
    item => item.id
  ),
  readOnly: state.common.enableReadOnly,
  securities: state.common.securities,
  priorities: state.common.priorities,
  objects: state.common.objects,
  enableScrollFixedMenu: state.common.enableScrollFixedMenu,
  quyensMenu: state.common.quyensMenu,
  receivingUnit: state.common.receivingUnit,
  departmentAndPersonalTree: state.contact.departmentAndPersonalTree,
})

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(Actions, dispatch),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DetailReceiveDocumentPage)
