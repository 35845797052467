import { useState, useEffect, useRef } from 'react'
import { Intent } from '@blueprintjs/core'
import * as MESSAGE from '../../../../constants/MessageForm'

import Utils from 'helpers/utils'
import {
  ValidateForm,
  MESSAGE_UPLOAD_AVATAR_FAIL,
  TOATS_MESSAGE_SUCCESS,
  TOATS_MESSAGE_FAIL,
} from 'constants/MessageForm'
import { imagesType } from 'helpers/file'
import { IMAGE_URL } from 'constants/Api'
import moment from 'moment'

const useProfileEdit = ({ actions, auth, onClose }) => {
  const maxSize = 2
  const size = 1024 * 1024 * maxSize
  const refEditor = useRef(null)
  const [avatar, setAvatar] = useState({
    preview: {
      img: `${process.env.PUBLIC_URL}/images/avatar.png`,
    },
  })
  const [isLoading, setIsLoading] = useState(false)
  const [formData, setFormData] = useState(null)
  const [isOpenCropImage, setIsOpenCropImage] = useState(false)
  const [errorData, setErrorData] = useState({
    email: '',
  })

  const handlePositionChangeAvatar = position => {
    setAvatar(prev => ({
      ...prev,
      position: position,
    }))
  }

  const handleScaleAvatar = e => {
    const scale = parseFloat(e.target.value) ?? 1
    setAvatar(prev => ({
      ...prev,
      scale: scale,
    }))
  }

  const handleSaveAvatar = () => {
    if (refEditor?.current) {
      let img = refEditor.current.getImageScaledToCanvas().toDataURL()
      const rect = refEditor.current.getCroppingRect()
      let tempAvatar = avatar
      fetch(img)
        .then(res => res.blob())
        .then(blob => {
          tempAvatar.fileRequest = Utils.blobToFile(blob, tempAvatar?.name)
          tempAvatar.size = blob.size
        })
      tempAvatar.preview = {
        img,
        rect,
        scale: tempAvatar?.scale,
        width: tempAvatar?.width,
        height: tempAvatar?.height,
      }
      setAvatar(tempAvatar)
      setIsOpenCropImage(false)
    } else {
      setIsOpenCropImage(false)
    }
  }

  const onCloseAvatarCrop = () => {
    setIsOpenCropImage(false)
    setAvatar(prev => ({
      ...prev,
      image: null,
      name: '',
      size: null,
      fileRequest: null,
      error: null,
      position: { x: 0.5, y: 0.5 },
      scale: 1,
      width: 201,
      height: 268,
    }))
  }

  const onChangeForm = (name, value) => {
    setFormData(prev => ({
      ...prev,
      [name]: value,
    }))
  }

  const onChangeFile = e => {
    const { files } = e.target
    const originalFile = files[0]
    if (originalFile) {
      const originalType = originalFile.type
      const originalSize = originalFile.size
      if (
        imagesType().length !== 0 &&
        !imagesType()?.some(s => s === originalType)
      ) {
        return actions.commonAddToasterMessage({
          message: ValidateForm.FILE_TYPE_ALLOW(['jpeg', 'jpg', 'png']),
          intent: Intent.WARNING,
        })
      }

      if (originalSize > size) {
        return actions.commonAddToasterMessage({
          message: ValidateForm.FILE_SIZE_ALLOW(maxSize),
          intent: Intent.WARNING,
        })
      }
      setAvatar(prev => ({
        ...prev,
        image: originalFile,
        name: originalFile.name.replace(/ /g, ''),
        fileRequest: null,
        error: null,
        position: { x: 0.5, y: 0.5 },
        scale: 1,
        width: 201,
        height: 268,
      }))
      setIsOpenCropImage(true)
    }
  }

  const onSave = () => {
    const re =
      /^(([^<>()\]\\.,;:\s@"]+(\.[^<>()\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

    if (formData.email.trim().length > 0 && !re.test(formData.email)) {
      setErrorData({ email: MESSAGE.TEXT_MESSAGE_TYPE_EMAIL })

      return
    }
    setIsLoading(true)

    Promise.all([
      avatar?.fileRequest
        ? actions.avatarUpload(avatar.fileRequest, formData.username)
        : {
            payload: {
              data: {
                result: [
                  {
                    url: avatar?.preview?.oldUrl,
                  },
                ],
              },
            },
          },
    ]).then(res => {
      let avatarFile = avatar?.preview?.oldUrl
      if (res?.[0]?.error) {
        actions.commonAddToasterMessage({
          message: MESSAGE_UPLOAD_AVATAR_FAIL,
          intent: Intent.DANGER,
        })
      } else {
        avatarFile = res?.[0]?.payload?.data?.result?.[0]?.url
        actions.clearCacheAvatar(avatarFile)
      }

      actions
        .updateUserProfile({
          so_dien_thoai: formData?.soDienThoai
            ? formData.soDienThoai.trim()
            : '',
          so_dien_thoai_khac: formData?.soDienThoaiKhac
            ? formData.soDienThoaiKhac.trim()
            : '',
          tam_trang: formData?.tamTrang ? formData.tamTrang.trim() : '',
          ngay_sinh: formData?.ngaySinh
            ? moment(formData.ngaySinh).format('YYYY-MM-DD')
            : null,
          email: formData?.email ? formData.email.trim() : '',
          anh_dai_dien: avatarFile,
        })
        .then(res => {
          setIsLoading(false)
          if (res?.payload?.data?.result) {
            actions.commonAddToasterMessage({
              message: res.payload.data?.message || TOATS_MESSAGE_SUCCESS,
              intent: Intent.SUCCESS,
            })

            return actions.getUserProfile(formData?.nhanVienId).then(() => {
              onClose()
            })
          }

          return actions.commonAddToasterMessage({
            message: res?.error?.message || TOATS_MESSAGE_FAIL,
            intent: Intent.SUCCESS,
          })
        })
    })
  }

  useEffect(() => {
    const user = auth?.user
    if (user) {
      setFormData({
        nhanVienId: user?.nhanVienId,
        email: user?.email,
        username: user?.username,
        tamTrang: user?.tamTrang,
        ngaySinh: user?.ngaySinh ? moment(user?.ngaySinh) : null,
        soDienThoai: user?.soDienThoai,
        soDienThoaiKhac: user?.soDienThoaiKhac,
      })
      setAvatar({
        preview: {
          img: user?.anhDaiDien
            ? `${IMAGE_URL}${user.anhDaiDien}`
            : `${process.env.PUBLIC_URL}/images/avatar.png`,
          oldUrl: user?.anhDaiDien,
        },
      })
    }
  }, [auth])

  return {
    errorData,
    avatar,
    isOpenCropImage,
    refEditor,
    formData,
    isLoading,
    handlePositionChangeAvatar,
    handleScaleAvatar,
    handleSaveAvatar,
    onCloseAvatarCrop,
    onChangeForm,
    onChangeFile,
    onSave,
  }
}

export default useProfileEdit
