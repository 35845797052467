import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import * as Actions from 'actions'

import DanhSachDiaDiemHop from './DanhSachDiaDiemHop'
import { HeadingPage } from 'components/common'
import { MasterLayout } from 'components/layout'
import ListFilter from './ListFilter'

const LINK = '/lich-hop/danh-muc/dia-diem-hop/them-moi'

class DanhSachDiaDiemHopPage extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      dsDiaDiemHop: [],
      isLoading: true,
      isError: false,
      textSearch: '',
      filterData: {
        textSearch: '',
        donViId: this.props?.auth?.mainUnitId,
      },
    }

    this.removeTextSearch = this.removeTextSearch.bind(this)
    this.submitFilter = this.submitFilter.bind(this)
  }

  handleGetDsDonViMultilevel = () => {
    this.props.actions.getCategoryUnitMultiLevel(this.props.auth.mainUnitId)
  }

  componentDidMount() {
    this.handleGetDsDonViMultilevel()
    this.props.actions
      .getDanhSachDiaDiemHop({
        donViId: this.props?.auth?.mainUnitId,
      })
      .then(res => {
        if (res.error || (res.payload && res.payload.status !== 200)) {
          this.setState({ isError: true, isLoading: false })
        }
      })
  }

  // eslint-disable-next-line react/no-deprecated
  componentWillReceiveProps(nextProps) {
    if (nextProps.dsDiaDiemHop.items !== this.props.dsDiaDiemHop.items) {
      this.setState(
        {
          dsDiaDiemHop: nextProps.dsDiaDiemHop.items,
        },
        () => {
          this.setState({ isLoading: false })
        }
      )
    } else {
      this.setState({ isLoading: false })
    }
  }

  removeTextSearch(name) {
    this.setState({
      [name]: '',
    })
  }

  submitFilter = () => {
    const requestData = {
      textSearch: this.state.filterData?.textSearch
        ? this.state.filterData.textSearch.trim()
        : undefined,
      donViId: this.state.filterData?.donViId,
    }

    this.setState({ isLoading: true })
    this.props.actions
      .getDanhSachDiaDiemHop(requestData)
      .then(res => {
        let isEmpty = false
        let isError = false
        if (
          !res.error &&
          res.payload &&
          res.payload.data &&
          res.payload.data.result &&
          res.payload.data.result.result
        ) {
          isEmpty = true
        }

        if (res.error || (res.payload && res.payload.status !== 200)) {
          isError = true
        }

        this.props.actions.getNumberDocumentPage()
        this.setState({ isEmpty, isError })
      })
      .then(() => {
        this.setState({ isLoading: false })
        this.props.actions.refreshPage(false)
      })
  }

  onChangeFilter = (name, value, isReload = false) => {
    this.setState(
      {
        filterData: {
          ...this.state.filterData,
          [name]: value,
        },
      },
      () => {
        if (isReload) {
          this.submitFilter()
        }
      }
    )
  }

  onClearFilter = () => {
    this.setState(
      {
        filterData: {
          textSearch: '',
          donViId: this.props?.auth?.mainUnitId,
        },
      },
      () => {
        this.submitFilter()
      }
    )
  }

  render() {
    return (
      <MasterLayout typeSidebar="sidebarCalendar">
        <HeadingPage
          namePage="Danh mục địa điểm họp"
          iconPage="icon-address-meeting"
          showButton={true}
          linkButton={LINK}
          nameButton="Thêm Mới"
        />
        <ListFilter
          readOnly={false}
          auth={this.props.auth}
          units={this.props.unit?.items}
          filterData={this.state.filterData}
          onClearFilter={this.onClearFilter}
          onChangeFilter={this.onChangeFilter}
          onSubmit={this.submitFilter}
        />
        <div className="listrole-container">
          <DanhSachDiaDiemHop
            items={this.state.dsDiaDiemHop}
            actions={this.props.actions}
            isLoading={this.state.isLoading}
            isError={this.state.isError}
          />
        </div>
      </MasterLayout>
    )
  }
}

const mapStateToProps = state => ({
  dsDiaDiemHop: {
    ...state.dsDiaDiemHop,
    items: state.lichHop.dsDiaDiemHop,
  },
  auth: {
    ...state.auth,
  },
  unit: {
    ...state.category,
    items: state.category.categoryUnitMultiLevel,
  },
})

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(Actions, dispatch),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DanhSachDiaDiemHopPage)
