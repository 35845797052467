import React, { Component } from 'react'
import { Button, Collapse } from '@blueprintjs/core'
import classnames from 'classnames'
import PropTypes from 'prop-types'
import moment from 'moment'
import DatePicker from 'react-datepicker'
import * as Tool from '../../../helpers'

class ListFilter extends Component {
  static propTypes = {
    objects: PropTypes.array,
    departments: PropTypes.array,
    onSubmit: PropTypes.func,
  }

  constructor(props) {
    super(props)
    this.handleClick = this.handleClick.bind(this)
    this.clearFilter = this.clearFilter.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
    this.handleInputChange = this.handleInputChange.bind(this)
    this.handleDateChange = this.handleDateChange.bind(this)
    this.handleChangeRaw = this.handleChangeRaw.bind(this)
    this.focousOut = this.focousOut.bind(this)
  }

  state = {
    dateUpdate: moment(),
    isOpen: false,
    filter: {
      textSearch: '',
      ngayCongVan: {
        from: moment().subtract(1, 'months'),
        to: moment(),
      },
      phongBanId: '',
      doiTuongId: '',
    },
    dateFromToString: moment().subtract(1, 'months').format('DD/MM/YYYY'),
    dateToToString: moment().format('DD/MM/YYYY'),
  }

  clearFilter(e) {
    this.setState(
      {
        filter: {
          textSearch: '',
          ngayCongVan: {
            from: moment().subtract(1, 'months'),
            to: moment(),
          },
          phongBanId: '',
          doiTuongId: '',
        },
        dateFromToString: moment().subtract(1, 'months').format('DD/MM/YYYY'),
        dateToToString: moment().format('DD/MM/YYYY'),
      },
      () => {
        this.handleSubmit()
      }
    )
  }

  handleDateChange(name, pos, nameToString, e) {
    let value = ''
    if (e) {
      value = moment(e).format('DD/MM/YYYY')
    }

    this.setState({
      filter: {
        ...this.state.filter,
        [name]: {
          ...this.state.filter[name],
          [pos]: e ? e : undefined,
        },
      },
      [nameToString]: value,
    })
  }

  componentWillReceiveProps(props) {
    const { filter } = props

    if (filter) {
      const { ngayCongVan } = filter
      this.setState({
        filter: {
          textSearch: filter.textSearch || '',
          ngayCongVan: {
            from:
              ngayCongVan &&
              ngayCongVan.from &&
              ngayCongVan.from.trim().length > 0
                ? moment(ngayCongVan.from)
                : '',
            to:
              ngayCongVan && ngayCongVan.to && ngayCongVan.to.trim().length > 0
                ? moment(ngayCongVan.to)
                : '',
          },
          phongBanId: filter.phongBanId || '',
          doiTuongId: filter.doiTuongId || '',
        },
        dateFromToString:
          ngayCongVan && ngayCongVan.from && ngayCongVan.from.trim().length > 0
            ? moment(ngayCongVan.from)
            : '',
        dateToToString:
          ngayCongVan && ngayCongVan.to && ngayCongVan.to.trim().length > 0
            ? moment(ngayCongVan.to)
            : '',
      })
    }
  }

  handleChangeRaw(name, value) {
    this.setState({ [name]: value })
  }

  focousOut(name, pos, nameToString, value) {
    if (
      pos === 'from' &&
      !this.state.filter[name].to &&
      Tool.Utils.isValidDate(value)
    ) {
      return
    }

    if (
      pos === 'from' &&
      this.state.filter[name].to &&
      Tool.Utils.isValidDate(value, {
        before: {
          date: this.state.filter[name].to,
        },
      })
    ) {
      return
    }

    if (
      pos === 'to' &&
      !this.state.filter[name].from &&
      Tool.Utils.isValidDate(value)
    ) {
      return
    }

    if (
      pos === 'to' &&
      this.state.filter[name].from &&
      Tool.Utils.isValidDate(value, {
        after: {
          date: this.state.filter[name].from,
        },
      })
    ) {
      return
    }

    this.setState({
      [nameToString]: '',
      filter: {
        ...this.state.filter,
        [name]: {
          ...this.state.filter[name],
          [pos]: undefined,
        },
      },
    })
  }

  handleInputChange(e) {
    const target = e.target
    const value = target.type === 'checkbox' ? target.checked : target.value
    const name = target.name

    this.setState({
      filter: {
        ...this.state.filter,
        [name]: value,
      },
    })
  }

  handleSubmit(e) {
    if (e) {
      e.preventDefault()
    }
    const filter = {
      ...this.state.filter,
      ngayCongVan: {
        from: this.state.filter.ngayCongVan.from
          ? moment(this.state.filter.ngayCongVan.from).format('YYYY-MM-DD')
          : '',
        to: this.state.filter.ngayCongVan.to
          ? moment(this.state.filter.ngayCongVan.to).format('YYYY-MM-DD')
          : '',
      },
    }
    this.props.onSubmit && this.props.onSubmit(filter)
  }

  handleClick(e) {
    e.preventDefault()
    this.setState({
      isOpen: !this.state.isOpen,
    })
  }

  render() {
    return (
      <div className="search-container search-PrintSendDocument">
        <form className="form-search-basic" onSubmit={this.handleSubmit}>
          <div className="seach-form">
            <div className="row">
              <div className="col-xs-12 col-sm-12 col-md-12 end-xs search-header-right-content">
                <div className="search-header-group pull-right">
                  <div className="search-filter-date">
                    <div className="group-datetime">
                      <label className="pt-label-inline">
                        <span className="text-label"> Từ ngày </span>
                      </label>
                      <div className="daterangepicker-group">
                        <DatePicker
                          readOnly={this.props.readOnly}
                          isClearable={
                            this.state.dateFromToString ? true : false
                          }
                          selected={this.state.filter.ngayCongVan.from}
                          selectsStart
                          startDate={this.state.filter.ngayCongVan.from}
                          endDate={this.state.filter.ngayCongVan.to}
                          onChange={this.handleDateChange.bind(
                            this,
                            'ngayCongVan',
                            'from',
                            'dateFromToString'
                          )}
                          value={this.state.dateFromToString}
                          onChangeRaw={e =>
                            this.handleChangeRaw(
                              'dateFromToString',
                              e.target.value
                            )
                          }
                          onBlur={e =>
                            this.focousOut(
                              'ngayCongVan',
                              'from',
                              'dateFromToString',
                              e.target.value
                            )
                          }
                          format="DD/MM/YYYY"
                          peekNextMonth
                          showMonthDropdown
                          showYearDropdown
                          dropdownMode="select"
                          className="input-datepicker input-icon-datepicker"
                          placeholderText="Từ ngày"
                          maxDate={this.state.filter.ngayCongVan.to}
                          popperPlacement="auto"
                          popperModifiers={{
                            offset: {
                              enabled: true,
                              offset: '5px, 10px',
                            },
                            preventOverflow: {
                              enabled: true,
                              escapeWithReference: false,
                              boundariesElement: 'viewport',
                            },
                          }}
                        />
                      </div>
                    </div>
                    <div className="group-datetime">
                      <label className="pt-label-inline">
                        <span className="text-label"> Đến ngày </span>
                      </label>
                      <div className="daterangepicker-group">
                        <DatePicker
                          readOnly={this.props.readOnly}
                          isClearable={this.state.dateToToString ? true : false}
                          selected={this.state.filter.ngayCongVan.to}
                          selectsEnd
                          startDate={this.state.filter.ngayCongVan.from}
                          endDate={this.state.filter.ngayCongVan.to}
                          onChange={this.handleDateChange.bind(
                            this,
                            'ngayCongVan',
                            'to',
                            'dateToToString'
                          )}
                          value={this.state.dateToToString}
                          onChangeRaw={e =>
                            this.handleChangeRaw(
                              'dateToToString',
                              e.target.value
                            )
                          }
                          onBlur={e =>
                            this.focousOut(
                              'ngayCongVan',
                              'to',
                              'dateToToString',
                              e.target.value
                            )
                          }
                          format="DD/MM/YYYY"
                          peekNextMonth
                          showMonthDropdown
                          showYearDropdown
                          dropdownMode="select"
                          className="input-datepicker input-icon-datepicker"
                          placeholderText="Đến ngày"
                          minDate={this.state.filter.ngayCongVan.from}
                          popperPlacement="auto"
                          popperModifiers={{
                            offset: {
                              enabled: true,
                              offset: '5px, 10px',
                            },
                            preventOverflow: {
                              enabled: true,
                              escapeWithReference: false,
                              boundariesElement: 'viewport',
                            },
                          }}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="search-filter-select">
                    <div className="filter-search-list">
                      <div className="search-keyword">
                        <input
                          autoFocus={false}
                          autoComplete="off"
                          className="pt-input search"
                          placeholder="Tìm số cv, trích yếu (hoặc số cv:trích yếu)..."
                          type="text"
                          dir="auto"
                          name="textSearch"
                          onChange={this.handleInputChange}
                          value={this.state.filter.textSearch}
                        />
                      </div>
                      <div className="btn-reload-group">
                        <Button className="btn-search" type="submit">
                          <span className="pt-icon pt-icon-search"></span>
                        </Button>
                        <Button
                          type="button"
                          className="btn-reload"
                          onClick={this.clearFilter}
                        >
                          <span className="icon-Loading"></span>
                        </Button>
                        <Button
                          type="button"
                          className="btn-toogle"
                          onClick={this.handleClick}
                        >
                          <span
                            className={classnames('pt-icon', {
                              'pt-icon-double-chevron-up': this.state.isOpen,
                              'pt-icon-double-chevron-down': !this.state.isOpen,
                            })}
                          ></span>
                        </Button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <Collapse isOpen={this.state.isOpen}>
            <div className="search-advanced seach-form-container">
              <div className="row row-box-container end-xs">
                {/* <div className="group-datetime">
                  <label className="pt-label label-datepicker">
                    Thời gian
                  </label>
                  <div className="daterangepicker-group">
                    <DatePicker
                      readOnly={this.props.readOnly}
                      selected={this.state.filter.ngayCongVan.from}
                      selectsStart
                      startDate={this.state.filter.ngayCongVan.from}
                      endDate={this.state.filter.ngayCongVan.to}
                      onChange={this.handleDateChange.bind(this, 'ngayCongVan', 'from', 'dateFromToString')}
                      value={this.state.dateFromToString}
                      onChangeRaw={(e) => this.handleChangeRaw('dateFromToString', e.target.value)}
                      onBlur={e => this.focousOut('ngayCongVan', 'from', 'dateFromToString', e.target.value)}
                      format="DD/MM/YYYY"
                      peekNextMonth
                      showMonthDropdown
                      showYearDropdown
                      dropdownMode="select"
                      className="input-datepicker input-icon-datepicker"
                      placeholderText="Từ ngày"
                      maxDate={this.state.filter.ngayCongVan.to}
                      popperPlacement="auto"
                      popperModifiers={{
                        offset: {
                          enabled: true,
                          offset: '5px, 10px'
                        },
                        preventOverflow: {
                          enabled: true,
                          escapeWithReference: false,
                          boundariesElement: 'viewport'
                        }
                      }}
                    />
                    <DatePicker
                      readOnly={this.props.readOnly}
                      selected={this.state.filter.ngayCongVan.to}
                      selectsEnd
                      startDate={this.state.filter.ngayCongVan.from}
                      endDate={this.state.filter.ngayCongVan.to}
                      onChange={this.handleDateChange.bind(this, 'ngayCongVan', 'to', 'dateToToString')}
                      value={this.state.dateToToString}
                      onChangeRaw={(e) => this.handleChangeRaw('dateToToString', e.target.value)}
                      onBlur={e => this.focousOut('ngayCongVan', 'to', 'dateToToString', e.target.value)}
                      format="DD/MM/YYYY"
                      peekNextMonth
                      showMonthDropdown
                      showYearDropdown
                      dropdownMode="select"
                      className="input-datepicker input-icon-datepicker"
                      placeholderText="Đến ngày"
                      minDate={this.state.filter.ngayCongVan.from}
                      popperPlacement="auto"
                      popperModifiers={{
                        offset: {
                          enabled: true,
                          offset: '5px, 10px'
                        },
                        preventOverflow: {
                          enabled: true,
                          escapeWithReference: false,
                          boundariesElement: 'viewport'
                        }
                      }}
                    />
                  </div>
                </div> */}
                <div className="group-select">
                  <label className="pt-label">
                    Phòng ban
                    <div className="pt-select">
                      <select
                        className="select-content"
                        name="phongBanId"
                        onChange={this.handleInputChange}
                        value={this.state.filter.phongBanId}
                      >
                        <option value="">Tất cả</option>
                        {this.props.departments.map((item, i) => (
                          <option key={i} value={item.phongBanId}>
                            {item.maPhongBan}
                          </option>
                        ))}
                      </select>
                    </div>
                  </label>
                </div>
                <div className="group-select">
                  <label className="pt-label">
                    Loại công văn
                    <div className="pt-select">
                      <select
                        className="select-content"
                        name="doiTuongId"
                        onChange={this.handleInputChange}
                        value={this.state.filter.doiTuongId}
                      >
                        <option value="">Tất cả</option>
                        {this.props.objects.map((item, i) => (
                          <option key={i} value={item.doiTuongCongVanId}>
                            {item.tenDoiTuongCongVan}
                          </option>
                        ))}
                      </select>
                    </div>
                  </label>
                </div>
              </div>
            </div>
          </Collapse>
        </form>
      </div>
    )
  }
}

export default ListFilter
