import React, { Component } from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import classnames from 'classnames'
import { intersection } from 'lodash'
// import * as Types from '../../constants/Api';

class DetailStatus extends Component {
  static propTypes = {
    item: PropTypes.object,
  }

  state = {
    data: [],
  }

  componentDidMount() {
    let data = []
    let isHasParentUnit = false
    let isHasElectricUnit = false
    if (
      (this.props.item.phoiHopCapTren &&
        this.props.item.phoiHopCapTren.length > 0) ||
      (this.props.item.lanhDaoDonViCapTren &&
        this.props.item.lanhDaoDonViCapTren.chucDanhId)
    ) {
      isHasParentUnit = true
    }
    if (this.props.item.donViCungKy && this.props.item.donViCungKy.length > 0) {
      isHasElectricUnit = true
    }
    let mainUnitId = this.props.auth && this.props.auth.mainUnitId
    let shortNameUnit = null
    if (
      mainUnitId &&
      (isHasParentUnit || isHasElectricUnit) &&
      this.props.item &&
      this.props.item.donVi &&
      this.props.item.donVi.tenVietTat &&
      this.props.item.donVi.donViId !== mainUnitId
    ) {
      shortNameUnit = this.props.item.donVi.tenVietTat
    }
    // LDPB Chu tri
    this.props.item.lanhDaoPhongBanChuTri &&
      data.push({
        maPhongBan:
          shortNameUnit && this.props.item.phongBanLap
            ? `${shortNameUnit}_${this.props.item.phongBanLap.maPhongBan}`
            : this.props.item.phongBanLap
            ? this.props.item.phongBanLap.maPhongBan
            : null,
        tenNhanVien:
          this.props.item.lanhDaoPhongBanChuTri &&
          this.props.item.lanhDaoPhongBanChuTri?.tenNhanVien
            ? this.props.item.lanhDaoPhongBanChuTri?.tenNhanVien
            : null,
        trangThai:
          this.props.item.lanhDaoPhongBanChuTri &&
          this.props.item.lanhDaoPhongBanChuTri?.trangThai
            ? this.props.item.lanhDaoPhongBanChuTri?.trangThai
            : 0,
        thoiGianDuyet: this.props.item.lanhDaoPhongBanChuTri?.thoiGianDuyet
          ? moment(this.props.item.lanhDaoPhongBanChuTri?.thoiGianDuyet).format(
              'DD/MM/YYYY HH:mm'
            )
          : '',
      })
    // LDPB Phoi Hop
    this.props.item.phoiHop &&
      this.props.item.phoiHop.forEach(item => {
        data.push({
          maPhongBan:
            shortNameUnit && item.maPhongBan
              ? `${shortNameUnit}_${item.maPhongBan}`
              : item.maPhongBan
              ? item.maPhongBan
              : null,
          tenNhanVien:
            item.nguoiKy && item.nguoiKy.tenNhanVien
              ? item.nguoiKy.tenNhanVien
              : '',
          trangThai: item.trangThai ? item.trangThai : 0,
          thoiGianDuyet: item.thoiGianDuyet
            ? moment(item.thoiGianDuyet).format('DD/MM/YYYY HH:mm')
            : '',
        })
      })

    // Ds lanh dao ky phoi hop
    this.props.item.dsLanhDaoPhoiHop &&
      this.props.item.dsLanhDaoPhoiHop.forEach(item => {
        if (item.thuKy) {
          data.push({
            maPhongBan: item.phongBan.maPhongBan
              ? `${item.phongBan.maPhongBan}`
              : '',
            tenNhanVien:
              item.thuKy && item.thuKy.tenNhanVien
                ? item.thuKy.tenNhanVien
                : '',
            trangThai: item.thuKy.trangThai ? item.thuKy.trangThai : 0,
            thoiGianDuyet:
              item.thuKy && item.thuKy.thoiGianDuyet
                ? moment(item.thuKy.thoiGianDuyet).format('DD/MM/YYYY HH:mm')
                : '',
          })
        }
        data.push({
          maPhongBan: item.phongBan.maPhongBan
            ? `${item.phongBan.maPhongBan}`
            : '',
          tenNhanVien:
            item.lanhDao && item.lanhDao.tenNhanVien
              ? item.lanhDao.tenNhanVien
              : '',
          trangThai: item.lanhDao.trangThai ? item.lanhDao.trangThai : 0,
          thoiGianDuyet: item.lanhDao.thoiGianDuyet
            ? moment(item.lanhDao.thoiGianDuyet).format('DD/MM/YYYY HH:mm')
            : '',
        })
      })

    // LD Dien luc phoi hop
    this.props.item.donViCungKy &&
      this.props.item.donViCungKy.forEach(item => {
        data.push({
          maPhongBan: item.phongBan
            ? `${item.tenVietTat}_${item.phongBan.maPhongBan}`
            : '',
          tenNhanVien:
            item.nguoiKy && item.nguoiKy.tenNhanVien
              ? item.nguoiKy.tenNhanVien
              : '',
          trangThai: item.trangThai ? item.trangThai : 0,
          thoiGianDuyet: item.thoiGianDuyet
            ? moment(item.thoiGianDuyet).format('DD/MM/YYYY HH:mm')
            : '',
        })
      })
    // LDPB lanh dao Phoi hop
    this.props.item.lanhDaoPhoiHop &&
      this.props.item.lanhDaoPhoiHop.forEach(item => {
        item.thuKy &&
          data.push({
            maPhongBan:
              shortNameUnit && item.phongBan
                ? `${shortNameUnit}_${item.phongBan.maPhongBan}`
                : item.phongBan
                ? item.phongBan.maPhongBan
                : null,
            tenNhanVien: item.thuKy.tenNhanVien,
            trangThai: item.thuKy.trangThai,
            thoiGianDuyet: item.thuKy.thoiGianDuyet
              ? moment(item.thuKy.thoiGianDuyet).format('DD/MM/YYYY HH:mm')
              : '',
          })
        item.lanhDao &&
          data.push({
            maPhongBan:
              shortNameUnit && item.phongBan
                ? `${shortNameUnit}_${item.phongBan.maPhongBan}`
                : item.phongBan
                ? item.phongBan.maPhongBan
                : null,
            tenNhanVien: item.lanhDao.tenNhanVien,
            trangThai: item.lanhDao.trangThai,
            thoiGianDuyet: item.lanhDao.thoiGianDuyet
              ? moment(item.lanhDao.thoiGianDuyet).format('DD/MM/YYYY HH:mm')
              : '',
          })
      })
    // Thu ky LDDV khac cung ky
    this.props.item.thuKyPhoiHop &&
      data.push({
        maPhongBan:
          shortNameUnit && this.props.item.thuKyPhoiHop.phongBan
            ? `${shortNameUnit}_${this.props.item.thuKyPhoiHop.phongBan.maPhongBan}`
            : this.props.item.thuKyPhoiHop.phongBan
            ? this.props.item.thuKyPhoiHop.phongBan.maPhongBan
            : null,
        tenNhanVien: this.props.item.thuKyPhoiHop.tenNhanVien,
        trangThai: this.props.item.thuKyPhoiHop.trangThai,
        thoiGianDuyet: this.props.item.thuKyPhoiHop.thoiGianDuyet
          ? moment(this.props.item.thuKyPhoiHop.thoiGianDuyet).format(
              'DD/MM/YYYY HH:mm'
            )
          : '',
      })
    // LDDV khac cung ky
    this.props.item.lanhDaoDonViPhoiHop &&
      data.push({
        maPhongBan:
          shortNameUnit && this.props.item.lanhDaoDonViPhoiHop.phongBan
            ? `${shortNameUnit}_${this.props.item.lanhDaoDonViPhoiHop.phongBan.maPhongBan}`
            : this.props.item.lanhDaoDonViPhoiHop.phongBan
            ? this.props.item.lanhDaoDonViPhoiHop.phongBan.maPhongBan
            : null,
        tenNhanVien: this.props.item.lanhDaoDonViPhoiHop.tenNhanVien,
        trangThai: this.props.item.lanhDaoDonViPhoiHop.trangThai,
        thoiGianDuyet: this.props.item.lanhDaoDonViPhoiHop.thoiGianDuyet
          ? moment(this.props.item.lanhDaoDonViPhoiHop.thoiGianDuyet).format(
              'DD/MM/YYYY HH:mm'
            )
          : '',
      })
    // Thu ky LDDV
    if (this.props.item.thuKy) {
      data.push({
        maPhongBan:
          shortNameUnit && this.props.item.thuKy.phongBan
            ? `${shortNameUnit}_${this.props.item.thuKy.phongBan.maPhongBan}`
            : this.props.item.thuKy.phongBan
            ? this.props.item.thuKy.phongBan.maPhongBan
            : null,
        tenNhanVien: this.props.item.thuKy.tenNhanVien,
        trangThai: this.props.item.thuKy.trangThai,
        thoiGianDuyet: this.props.item.thuKy.thoiGianDuyet
          ? moment(this.props.item.thuKy.thoiGianDuyet).format(
              'DD/MM/YYYY HH:mm'
            )
          : '',
      })
    } else {
      let statusArr =
        this.props.item.banThuKy &&
        this.props.item.banThuKy.dsThuKy &&
        this.props.item.banThuKy.dsThuKy.length > 0
          ? this.props.item.banThuKy.dsThuKy.map(user => user.trangThai)
          : []
      let isAllSecretaryNotAgreeYet =
        statusArr.length > 0 && intersection(statusArr, [1, 2]).length === 0
      if (isAllSecretaryNotAgreeYet) {
        let statusArr =
          this.props.item.banThuKy.dsThuKy.length > 0
            ? this.props.item.banThuKy.dsThuKy.map(user => user.trangThai)
            : []
        let isAllNotAgreeYet =
          statusArr.length > 0 && intersection(statusArr, [1, 2]).length === 0
        if (isAllNotAgreeYet) {
          data.push({
            tenNhanVien: `TK${
              this.props.item.banThuKy && this.props.item.banThuKy.phongBan
                ? ` - ${this.props.item.banThuKy.phongBan.maPhongBan}`
                : ''
            }`,
            trangThai: 0,
            thoiGianDuyet: '',
          })
        }
      }
    }
    // LDDV
    this.props.item.lanhDaoDonVi &&
      data.push({
        maPhongBan:
          shortNameUnit && this.props.item.lanhDaoDonVi.phongBan
            ? `${shortNameUnit}_${this.props.item.lanhDaoDonVi.phongBan.maPhongBan}`
            : this.props.item.lanhDaoDonVi.phongBan
            ? this.props.item.lanhDaoDonVi.phongBan.maPhongBan
            : null,
        tenNhanVien: this.props.item.lanhDaoDonVi.tenNhanVien,
        trangThai: this.props.item.lanhDaoDonVi.trangThai,
        thoiGianDuyet: this.props.item.lanhDaoDonVi.thoiGianDuyet
          ? moment(this.props.item.lanhDaoDonVi.thoiGianDuyet).format(
              'DD/MM/YYYY HH:mm'
            )
          : '',
      })
    // LDPB Phoi hop cap tren
    this.props.item.phoiHopCapTren &&
      this.props.item.phoiHopCapTren.forEach(item => {
        data.push({
          maPhongBan: item.maPhongBan,
          tenNhanVien:
            item.nguoiKy && item.nguoiKy.tenNhanVien
              ? item.nguoiKy.tenNhanVien
              : '',
          trangThai: item.trangThai ? item.trangThai : 0,
          thoiGianDuyet: item.thoiGianDuyet
            ? moment(item.thoiGianDuyet).format('DD/MM/YYYY HH:mm')
            : '',
        })
      })
    // Thu ky LDDV cap tren
    this.props.item.thuKyCapTren &&
      data.push({
        maPhongBan: this.props.item.thuKyCapTren.phongBan
          ? this.props.item.thuKyCapTren.phongBan.maPhongBan
          : null,
        tenNhanVien: this.props.item.thuKyCapTren.tenNhanVien,
        trangThai: this.props.item.thuKyCapTren.trangThai,
        thoiGianDuyet: this.props.item.thuKyCapTren.thoiGianDuyet
          ? moment(this.props.item.thuKyCapTren.thoiGianDuyet).format(
              'DD/MM/YYYY HH:mm'
            )
          : '',
      })
    // LDDV cap tren
    this.props.item.lanhDaoDonViCapTren &&
      data.push({
        maPhongBan: this.props.item.lanhDaoDonViCapTren.phongBan
          ? this.props.item.lanhDaoDonViCapTren.phongBan.maPhongBan
          : null,
        tenNhanVien: this.props.item.lanhDaoDonViCapTren.tenNhanVien,
        trangThai: this.props.item.lanhDaoDonViCapTren.trangThai,
        thoiGianDuyet: this.props.item.lanhDaoDonViCapTren.thoiGianDuyet
          ? moment(this.props.item.lanhDaoDonViCapTren.thoiGianDuyet).format(
              'DD/MM/YYYY HH:mm'
            )
          : '',
      })
    this.setState({ data })
  }

  render() {
    if (
      !this.props.item ||
      (!this.props.item.lanhDaoPhongBanChuTri &&
        (!this.props.item.phoiHop || this.props.item?.phoiHop.length === 0) &&
        !this.props.item.thuKy &&
        !this.props.item.lanhDaoDonVi)
    ) {
      return null
    }

    return (
      <div className="detail-status-box">
        <div className="table-status table-scroll table-scroll-hidden">
          <div className="heading-table">
            <div className="cell-table colum-stt">
              <p className="name-heading-table">STT</p>
            </div>
            <div className="cell-table column-department">
              <p className="name-heading-table">Phòng ban/Cá nhân</p>
            </div>
            <div className="cell-table colum-status">
              <p className="name-heading-table">Trạng Thái</p>
            </div>
            {this.props.item && this.props.item.tinhTrang > 0 && (
              <div className="cell-table colum-status">
                <p className="name-heading-table">Thời gian ký duyệt</p>
              </div>
            )}
          </div>

          {this.state.data &&
            this.state.data.map((value, i) => (
              <div className="row-table" key={i}>
                <div className="cell-table">
                  <p className="content">
                    {i + 1 < 10 ? '0' + (i + 1) : i + 1}
                  </p>
                </div>
                <div className="cell-table column-department">
                  {value.maPhongBan && (
                    <p className="content department-name">
                      {value.maPhongBan}
                    </p>
                  )}
                  {value.tenNhanVien && (
                    <p className="content">{value.tenNhanVien}</p>
                  )}
                </div>
                <div
                  className={classnames('cell-table', {
                    'cell-status-warning':
                      this.props.isShowIconWarningForLeader,
                  })}
                >
                  {value.trangThai === 0 && (
                    <span className="status-job no-process">Chưa đồng ý</span>
                  )}
                  {value.trangThai === 1 && (
                    <span className="status-job no-approval">Không đồng ý</span>
                  )}
                  {value.trangThai === 2 && (
                    <span className="status-job approved">Đồng ý</span>
                  )}
                </div>
                {this.props.item && this.props.item.tinhTrang > 0 && (
                  <div className="cell-table">
                    {value.thoiGianDuyet ? (
                      <p className="content">{value.thoiGianDuyet}</p>
                    ) : (
                      <p className="content">&nbsp;</p>
                    )}
                  </div>
                )}
              </div>
            ))}

          {/* Don vi cung ky : tam thoi an di*/}
          {/*this.props.item.thuKy && this.props.item.donViCungKy && this.props.item.donViCungKy.map((value, i) => {
            return (
              <div className="row-table" key={i}>
                <div className="cell-table">
                  <p className="content">{(lengthOfCompositive + i + 4) < 10 ? '0'+ (lengthOfCompositive + i + 4) : (lengthOfCompositive + i + 4)}</p>
                </div>
                <div className="cell-table">
                  <p className="content">{value.tenVietTat}</p>
                </div>
                <div className="cell-table">
                  <p className="content">
                    {value.trangThai !== 0 && value.nguoiKy ? value.nguoiKy.tenNhanVien : null}
                  </p>
                </div>
                <div className="cell-table">
                  { value.trangThai === 0 &&
                   <span className="status-job no-process">Chưa đồng ý</span>
                  }
                  { value.trangThai === 1 &&
                   <span className="status-job no-approval">Không đồng ý</span>
                  }
                  { value.trangThai === 2 &&
                   <span className="status-job approved">Đồng ý</span>
                  }
                </div>
              </div>
            );
          })*/}

          {/*!this.props.item.thuKy && this.props.item.donViCungKy && this.props.item.donViCungKy.map((value, i) => {
            return (
              <div className="Row" key={i}>
                <div className="cell-table">
                  <p className="content">{(lengthOfCompositive + i + 3) < 10 ? '0'+ (lengthOfCompositive + i + 3) : (lengthOfCompositive + i + 3)}</p>
                </div>
                <div className="cell-table">
                  <p className="content">{value.tenVietTat}</p>
                </div>
                <div className="cell-table">
                  <p className="content">
                    {value.trangThai !== 0 && value.nguoiKy ? value.nguoiKy.tenNhanVien : null}
                  </p className="content">
                </div>
                <div className="cell-table">
                  { value.trangThai === 0 &&
                   <span className="status-job no-process">Chưa đồng ý</span>
                  }
                  { value.trangThai === 1 &&
                   <span className="status-job no-approval">Không đồng ý</span>
                  }
                  { value.trangThai === 2 &&
                   <span className="status-job approved">Đồng ý</span>
                  }
                </div>
              </div>
            );
          })*/}
        </div>
      </div>
    )
  }
}

export default DetailStatus
