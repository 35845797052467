import React, { Component } from 'react'
import PropTypes from 'prop-types'
import {
  // PopupConfirm,
  DepartmentPerform,
} from '../../components/common/'
import { Intent, Button } from '@blueprintjs/core'
// import * as POPUP from '../../constants/Popup';
import * as MESSAGE from '../../constants/MessageForm'
import moment from 'moment'
class AdditionalAssign extends Component {
  constructor(props) {
    super(props)
    // this.handleClickPopup = this.handleClickPopup.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this)
    this.handleInputChange = this.handleInputChange.bind(this)
    // this.handleCompositiveStaffSelection = this.handleCompositiveStaffSelection.bind(this);
    // this.handleCheckAllCompositive = this.handleCheckAllCompositive.bind(this);
    // this.handleOnlySeeStaffSelection = this.handleOnlySeeStaffSelection.bind(this);
    // this.handleCheckAllOnlySee = this.handleCheckAllOnlySee.bind(this);
    this.handleGetValueGridCheckBox = this.handleGetValueGridCheckBox.bind(this)
    //this.handleScroll = this.handleScroll.bind(this);
  }

  static propTypes = {
    onClickGoBack: PropTypes.func.isRequired,
    staffs: PropTypes.array.isRequired,
    onSubmit: PropTypes.func.isRequired,
    workAssignInformations: PropTypes.object,
    personalWork: PropTypes.object,
    isLeaderUnit: PropTypes.bool,
    departments: PropTypes.array,
  }

  state = {
    isOpen: false,
    isOpenPopup: false,
    error: {},
    congViecPhongBanId: '',
    noiDung: '',
    chuTriId: null,
    dsPhoiHop: [],
    dsXemDeBiet: [],
    pbChuTriId: null, //Danh cho lanh dao don vi
    pbPhoiHop: [], //Danh cho lanh dao don vi
    pbXemDeBiet: [], //Danh cho lanh dao don vi
    dsCongViec: [],
    staffSelections: [],
    departmentSelections: [],
    disabledListCompositiveDepartments: [],
    disabledListOnlySeeDepartments: [],
    selectedCompositiveDepartments: [],
    selectedOnlySeeDepartments: [],

    disabledListCompositiveStaffs: [],
    disabledListOnlySeeStaffs: [],
    selectedCompositiveStaffs: [],
    selectedOnlySeeStaffs: [],
    // isCheckedAllCompositive: false,
    // isCheckedAllOnlySee: false,
    phieuGiaoViecId: '',
    isLoading: false,
    //isHideButton: false,
    isOutDate: false,
  }

  // handleClickPopup = () => {
  //   this.setState({
  //     isOpenPopup: !this.state.isOpenPopup
  //   });
  // }

  handleSubmit = async () => {
    this.setState({ error: {} })
    const error = this.state.error
    if (this.state.isOutDate) {
      this.setState({ isLoading: false })
      return this.props.onSubmit(
        'GiaoViecBoSung',
        MESSAGE.TEXT_MESSAGE_HANGIAIQUYET_GIAOVIEC,
        Intent.WARNING,
        false
      )
    }
    this.setState({ error, isLoading: true })
    if (Object.keys(error).length !== 0) {
      this.setState({ isLoading: false })
      return this.props.onSubmit(
        'GiaoViecBoSung',
        MESSAGE.TOATS_MESSAGE_WARNING,
        Intent.WARNING,
        false
      )
    }
    this.setState({ isLoading: true })
    let dataRequest = {
      tieu_de: '',
      noi_dung: this.state.noiDung,
      bao_cao_lai: false,
      han_giai_quyet: '',
      file_id: '',
      nhan_vien_chu_tri_id: this.state.chuTriId,
      nhan_vien_phoi_hop_ids: this.state.dsPhoiHop,
      nhan_vien_xem_de_biet_ids: this.state.dsXemDeBiet,
    }

    let phieuGiaoViecId = null

    // Lanh dao don vi
    if (this.props.isLeaderUnit === true) {
      dataRequest = {
        ...dataRequest,
        pb_chu_tri_id: this.state.pbChuTriId,
        pb_phoi_hop: this.state.pbPhoiHop,
        pb_xem_de_biet: this.state.pbXemDeBiet,
      }
    }

    // Cong viec phong ban
    if (
      this.props.departmentWork &&
      this.props.departmentWork.congViecPhongBanId
    ) {
      dataRequest = {
        ...dataRequest,
        phieu_giao_viec_id: this.state.phieuGiaoViecId,
      }
      phieuGiaoViecId = this.state.phieuGiaoViecId

      // LD PB
      if (this.props.isLeaderUnit === false) {
        dataRequest = {
          ...dataRequest,
          ds_cong_viec: this.state.dsCongViec,
        }
      }

      // LD DV
      if (this.props.isLeaderUnit === true) {
        if (
          this.props.departmentWork &&
          this.props.departmentWork.congVanDenId
        ) {
          dataRequest = {
            ...dataRequest,
            cong_van_den_id: this.props.departmentWork.congVanDenId,
          }
        }
        if (
          this.props.departmentWork &&
          this.props.departmentWork.congVanNoiBoId
        ) {
          dataRequest = {
            ...dataRequest,
            cong_van_noi_bo_id: this.props.departmentWork.congVanNoiBoId,
          }
        }
      }
    }

    // Cong viec ca nhan
    if (this.props.personalWork) {
      if (this.state.phieuGiaoViecGocId) {
        dataRequest = {
          ...dataRequest,
          phieu_giao_viec_id: this.state.phieuGiaoViecGocId,
        }
        phieuGiaoViecId = this.state.phieuGiaoViecGocId
      }
      if (this.state.congVanDenGocId) {
        dataRequest = {
          ...dataRequest,
          cong_van_den_id: this.state.congVanDenGocId,
        }
      }
      if (this.state.congVanNoiBoGocId) {
        dataRequest = {
          ...dataRequest,
          cong_van_noi_bo_id: this.state.congVanNoiBoGocId,
        }
      }
    }

    this.props.actions
      .workAdditionalAssign(phieuGiaoViecId, dataRequest)
      .then(res => {
        if (res.payload && res.payload.status === 200) {
          return this.props.onSubmit(
            'GiaoViecBoSung',
            MESSAGE.TOATS_MESSAGE_SUCCESS,
            Intent.SUCCESS,
            true
          )
        }
        this.setState({ isLoading: false })
        this.props.onSubmit(
          'GiaoViecBoSung',
          MESSAGE.TOATS_MESSAGE_FAIL,
          Intent.DANGER,
          false
        )
      })
  }

  handleInputChange = e => {
    if (e.target.type === 'checkbox') {
      return this.setState({ [e.target.name]: e.target.checked })
    }

    const error = this.state.error
    delete error[e.target.name]

    this.setState({ [e.target.name]: e.target.value, error })
  }

  // Change checkbox group to grid
  // handleCheckAllCompositive = () => {
  //   this.setState({isCheckedAllCompositive: !this.state.isCheckedAllCompositive});
  //   let dsPhoiHop = this.state.dsPhoiHop;
  //   if (!this.state.isCheckedAllCompositive) {
  //     const list = dsPhoiHop.concat(this.state.dsXemDeBiet, this.state.disabledList);
  //     this.state.staffSelections.forEach(item => {
  //       if (list.indexOf(item.value) === -1) {
  //         dsPhoiHop.push(item.value);
  //       }
  //     });
  //     return this.setState({dsPhoiHop});
  //   }

  //   dsPhoiHop = [];
  //   this.setState({dsPhoiHop});
  // }

  // handleCompositiveStaffSelection = (e) => {
  //   const newSelection = e.target.value;
  //   let dsPhoiHop;
  //   if (this.state.dsPhoiHop.length > 0 && this.state.dsPhoiHop.indexOf(newSelection) > -1) {
  //     dsPhoiHop = this.state.dsPhoiHop.filter(s => s !== newSelection);
  //   } else {
  //     dsPhoiHop = [...this.state.dsPhoiHop, newSelection];
  //   }
  //   this.setState({dsPhoiHop});
  // }

  // handleCheckAllOnlySee = () => {
  //   this.setState({isCheckedAllOnlySee: !this.state.isCheckedAllOnlySee});
  //   let dsXemDeBiet = this.state.dsXemDeBiet;
  //   if (!this.state.isCheckedAllOnlySee) {
  //     const list = dsXemDeBiet.concat(this.state.dsPhoiHop, this.state.disabledList);
  //     this.state.staffSelections.forEach(item => {
  //       if (list.indexOf(item.value) === -1) {
  //         dsXemDeBiet.push(item.value);
  //       }
  //     });
  //     return this.setState({dsXemDeBiet});
  //   }

  //   dsXemDeBiet = [];
  //   this.setState({dsXemDeBiet});
  // }

  // handleOnlySeeStaffSelection = (e) => {
  //   const newSelection = e.target.value;
  //   let dsXemDeBiet;
  //   if (this.state.dsXemDeBiet.length > 0 && this.state.dsXemDeBiet.indexOf(newSelection) > -1) {
  //     dsXemDeBiet = this.state.dsXemDeBiet.filter(s => s !== newSelection);
  //   } else {
  //     dsXemDeBiet = [...this.state.dsXemDeBiet, newSelection];
  //   }
  //   this.setState({dsXemDeBiet: dsXemDeBiet});
  // }

  handleGetValueGridCheckBox = (type, data) => {
    if (!data || !Object.keys(data).length) {
      return
    }

    switch (type) {
      case 'departments':
        return this.setState({
          pbPhoiHop: data.dsPhoiHop,
          pbXemDeBiet: data.dsXemDeBiet,
        })

      default:
        return this.setState({
          dsPhoiHop: data.dsPhoiHop,
          dsXemDeBiet: data.dsXemDeBiet,
        })
    }
  }

  componentDidMount() {
    const staffSelections = []
    const departmentSelections = []
    const dsCongViec = []
    const currentDate = moment().format('YYYY-MM-DD')
    this.props.staffs.forEach(item => {
      staffSelections.push({
        content: item.tenNhanVien,
        value: item.chucDanhId,
      })
    })

    // TODO: Hot fix: show tat ca phong ban
    // const { mainDepartmentId } = this.props;
    // const currentDepartment = this.props.departments.find(item => item.phongBanId === mainDepartmentId);
    // const levelDepartment = currentDepartment && currentDepartment.loai;
    // levelDepartment && this.props.departments.forEach((item,i) => {
    //   if (item.phongBanId === mainDepartmentId || item.loai > levelDepartment) {
    //     departmentSelections.push({content: item.maPhongBan, value: item.phongBanId});
    //   }
    // });

    this.props.departments.forEach((item, i) => {
      departmentSelections.push({
        content: item.maPhongBan,
        value: item.phongBanId,
      })
    })

    this.setState({ staffSelections, departmentSelections })

    if (this.props.departmentWork) {
      let { isOutDate } = this.state
      const congViecPhongBanId = this.props.departmentWork.congViecPhongBanId
      if (
        moment(this.props.departmentWork.hanGiaiQuyet).format('YYYY-MM-DD') <
        currentDate
      ) {
        isOutDate = true
      }
      if (this.props.departmentWork.congVanNoiBoId) {
        dsCongViec.push({
          cong_viec_phong_ban_id: this.props.departmentWork.congViecPhongBanId,
          cong_van_noi_bo_id: this.props.departmentWork.congVanNoiBoId,
        })
      } else {
        dsCongViec.push({
          cong_viec_phong_ban_id: this.props.departmentWork.congViecPhongBanId,
          cong_van_den_id: this.props.departmentWork.congVanDenId,
        })
      }
      this.setState({ dsCongViec, congViecPhongBanId, isOutDate })
    }

    // Giao viec bo sung cho cong viec tu CVCN
    if (this.props.personalWork) {
      this.setState({
        phieuGiaoViecGocId: this.props.personalWork.phieuGiaoViecId, // phieu giao viec goc
        congVanDenGocId: this.props.personalWork.congVanDenId, // congVanDenId goc
        congVanNoiBoGocId: this.props.personalWork.congVanNoiBoId, // congVanNoiBoId goc
      })
    }

    if (this.props.workAssignInformations) {
      const dsPhongBanXemDeBiet = [] // Danh sach phog ban disbled cot xem de biet
      const dsPhongBanPhoiHop = [] // Danh sach phog ban disbled cot phoi hop
      const selectedCompositiveDepartments = [] // Danh sach phog ban selected cot phoi hop
      const selectedOnlySeeDepartments = [] // Danh sach phog ban selected cot xem de biet

      const dsCaNhanXemDeBiet = [] // Danh sach ca nhan disbled cot xem de biet
      const dsCaNhanPhoiHop = [] // Danh sach ca nhan disbled cot phoi hop
      const selectedCompositiveStaffs = [] // Danh sach ca nhan selected cot phoi hop
      const selectedOnlySeeStaffs = [] // Danh sach ca nhan selected cot xem de biet
      let { isOutDate } = this.state
      let phieuGiaoViecId = this.props.workAssignInformations.phieuGiaoViecId

      if (
        moment(this.props.workAssignInformations.hanGiaiQuyet).format(
          'YYYY-MM-DD'
        ) < currentDate &&
        this.props.departmentWork
      ) {
        isOutDate = true
      }
      // Ca nhan
      this.props.workAssignInformations.phoiHop &&
        this.props.workAssignInformations.phoiHop.forEach(item => {
          dsCaNhanPhoiHop.push(item.chucDanhId)
          dsCaNhanXemDeBiet.push(item.chucDanhId)
          selectedCompositiveStaffs.push(item.chucDanhId)
        })

      this.props.workAssignInformations.xemDeBiet &&
        this.props.workAssignInformations.xemDeBiet.forEach(item => {
          dsCaNhanXemDeBiet.push(item.chucDanhId)
          selectedOnlySeeStaffs.push(item.chucDanhId)
        })

      if (this.props.workAssignInformations.nguoiChuTri) {
        dsCaNhanPhoiHop.push(
          this.props.workAssignInformations.nguoiChuTri.chucDanhId
        )
        dsCaNhanXemDeBiet.push(
          this.props.workAssignInformations.nguoiChuTri.chucDanhId
        )
      }

      // Phong Ban
      this.props.workAssignInformations.pbPhoiHops &&
        this.props.workAssignInformations.pbPhoiHops.forEach(item => {
          dsPhongBanPhoiHop.push(item.phongBanId)
          dsPhongBanXemDeBiet.push(item.phongBanId)
          selectedCompositiveDepartments.push(item.phongBanId)
        })

      this.props.workAssignInformations.pbXemDeBiets &&
        this.props.workAssignInformations.pbXemDeBiets.forEach(item => {
          dsPhongBanXemDeBiet.push(item.phongBanId)
          selectedOnlySeeDepartments.push(item.phongBanId)
        })

      if (this.props.workAssignInformations.pbChuTri) {
        dsPhongBanPhoiHop.push(
          this.props.workAssignInformations.pbChuTri.phongBanId
        )
        dsPhongBanXemDeBiet.push(
          this.props.workAssignInformations.pbChuTri.phongBanId
        )
      }

      this.setState({
        phieuGiaoViecId,
        disabledListOnlySeeDepartments: dsPhongBanXemDeBiet,
        disabledListCompositiveDepartments: dsPhongBanPhoiHop,
        selectedCompositiveDepartments,
        selectedOnlySeeDepartments,
        disabledListOnlySeeStaffs: dsCaNhanXemDeBiet,
        disabledListCompositiveStaffs: dsCaNhanPhoiHop,
        selectedCompositiveStaffs,
        selectedOnlySeeStaffs,
        isOutDate,
      })
    }

    // window.addEventListener('scroll', this.handleScroll);
    // let windowSize = window.innerHeight;
    // let gb = document.getElementById("groupButton");
    // let self = this;
    // setTimeout(function() {
    //   if (windowSize > gb.getBoundingClientRect().top) {
    //     self.setState({
    //       isHideButton: false
    //     });
    //   } else {
    //     self.setState({
    //       isHideButton: true
    //     });
    //   }
    // }, 100);
  }

  // componentWillUnmount() {
  //   window.removeEventListener('scroll', this.handleScroll);
  // }
  //
  // handleScroll (event) {
  //   let windowSize = window.innerHeight;
  //   let gb = document.getElementById("groupButton");
  //   if (windowSize >= gb.getBoundingClientRect().top) {
  //     this.setState({
  //       isHideButton: false
  //     });
  //   }else{
  //     this.setState({
  //       isHideButton: true
  //     });
  //   }
  // }

  // componentDidUpdate(prevProps, prevState) {
  // // Change checkbox group to grid

  //   if (prevState.dsPhoiHop !== this.state.dsPhoiHop) {
  //     if (this.state.dsPhoiHop.length + this.state.dsXemDeBiet.length + this.state.disabledList.length === this.state.staffSelections.length) {
  //       return this.setState({isCheckedAllCompositive: true});
  //     }
  //     this.setState({isCheckedAllCompositive: false});
  //   }
  //   if (prevState.dsXemDeBiet !== this.state.dsXemDeBiet) {
  //     if (this.state.dsPhoiHop.length + this.state.dsXemDeBiet.length + this.state.disabledList.length === this.state.staffSelections.length) {
  //       return this.setState({isCheckedAllOnlySee: true});
  //     }
  //     this.setState({isCheckedAllOnlySee: false});
  //   }
  //   if (prevState.isCheckedAllOnlySee !== this.state.isCheckedAllOnlySee) {
  //     if (this.state.dsPhoiHop.length) {
  //       this.setState({isCheckedAllCompositive: true});
  //     } else {
  //       this.setState({isCheckedAllCompositive: false});
  //     }
  //     if (this.state.dsXemDeBiet.length + this.state.dsPhoiHop.length + this.state.disabledList.length !== this.state.staffSelections.length) {
  //       this.setState({isCheckedAllCompositive: false});
  //     }
  //   }
  //   if (prevState.isCheckedAllCompositive !== this.state.isCheckedAllCompositive) {
  //     if (this.state.dsXemDeBiet.length) {
  //       this.setState({isCheckedAllOnlySee: true});
  //     } else {
  //       this.setState({isCheckedAllOnlySee: false});
  //     }
  //     if (this.state.dsXemDeBiet.length + this.state.dsPhoiHop.length + this.state.disabledList.length!== this.state.staffSelections.length) {
  //       this.setState({isCheckedAllOnlySee: false});
  //     }
  //   }
  // }

  render() {
    return (
      <div className="container-form-action">
        <div className="heading">
          <span className="click-go-back" onClick={this.props.onClickGoBack}>
            <span className="pt-icon pt-icon-arrow-left pull-left"></span>
          </span>
          <h4 className="name-heading">Giao việc bổ sung</h4>
        </div>
        <div className="form-container">
          <div className="container-detail form-assign-work">
            <div className="pt-form-group pt-intent-danger">
              <div className="row full-row">
                <div className="col-xs-12 col-md-12 col-form-item">
                  <label className="pt-label">Nội dung giao việc bổ sung</label>
                </div>
                <div className="col-xs-12 col-md-12 col-form-item">
                  <div className="pt-form-content">
                    <textarea
                      name="noiDung"
                      value={this.state.noiDung}
                      placeholder="Nhập nội dung..."
                      onChange={this.handleInputChange}
                      rows="1"
                      className="pt-input pt-fill"
                      dir="auto"
                      autoFocus={true}
                    ></textarea>
                    {this.state.error.noiDung !== undefined && (
                      <div className="pt-form-helper-text">
                        {this.state.error.noiDung}
                      </div>
                    )}
                  </div>
                </div>
              </div>
              {this.state.isOutDate && (
                <p className="pt-form-helper-text font-weight-bold text-fresh-red">
                  {MESSAGE.TEXT_MESSAGE_HANGIAIQUYET_GIAOVIEC}
                </p>
              )}
            </div>

            {/*Update design*/}
            {/* CHANGED: Tách cá nhân và phòng ban riêng  */}
            {/* <div id="pb" className="pt-form-group">
              <label className="pt-label pt0">
                {this.props.isLeaderUnit === false ? 'Cá nhân ': 'Phòng ban '} thực hiện
              </label>
              <DepartmentPerform
                options={this.props.isLeaderUnit === false ? this.state.staffSelections : this.state.departmentSelections}
                onSubmit={this.handleGetValueGridCheckBox}
                dsPhoiHop={this.props.isLeaderUnit === false ? this.state.dsPhoiHop : this.state.pbPhoiHop}
                dsXemDeBiet={this.props.isLeaderUnit === false ? this.state.dsXemDeBiet : this.state.pbXemDeBiet}
                chuTriId={this.props.isLeaderUnit === false ? this.state.chuTriId : this.state.pbChuTriId}
                disabledListCompositive={this.state.disabledListCompositive}
                disabledListOnlySee={this.state.disabledListOnlySee}
                selectedCompositiveDepartments={this.state.selectedCompositiveDepartments}
                selectedOnlySeeDepartments={this.state.selectedOnlySeeDepartments}
                titles={['', 'PH', 'XĐB']}
                isHasRadio={false}
                type={this.props.isLeaderUnit === false ? 'Cá nhân' : 'Phòng ban'}
              />
            </div> */}

            {/* Phòng ban */}
            {this.props.isLeaderUnit === true && (
              <div id="pb" className="pt-form-group">
                <label className="pt-label pt0">Phòng ban thực hiện</label>
                <DepartmentPerform
                  isOutDate={this.state.isOutDate}
                  isDisabledAll={this.state.isOutDate}
                  options={this.state.departmentSelections}
                  onSubmit={this.handleGetValueGridCheckBox.bind(
                    this,
                    'departments'
                  )}
                  dsPhoiHop={this.state.pbPhoiHop}
                  dsXemDeBiet={this.state.pbXemDeBiet}
                  chuTriId={this.state.pbChuTriId}
                  disabledListCompositive={
                    this.state.disabledListCompositiveDepartments
                  }
                  disabledListOnlySee={
                    this.state.disabledListOnlySeeDepartments
                  }
                  selectedCompositiveDepartments={
                    this.state.selectedCompositiveDepartments
                  }
                  selectedOnlySeeDepartments={
                    this.state.selectedOnlySeeDepartments
                  }
                  titles={['', 'PH', 'XĐB']}
                  isHasRadio={false}
                  type="Phòng ban"
                />
              </div>
            )}

            {/* Cá nhân */}
            <div id="pb" className="pt-form-group">
              <label className="pt-label pt0">Cá nhân thực hiện</label>
              <DepartmentPerform
                isOutDate={this.state.isOutDate}
                isDisabledAll={this.state.isOutDate}
                options={this.state.staffSelections}
                onSubmit={this.handleGetValueGridCheckBox.bind(this, 'staffs')}
                dsPhoiHop={this.state.dsPhoiHop}
                dsXemDeBiet={this.state.dsXemDeBiet}
                chuTriId={this.state.chuTriId}
                disabledListCompositive={
                  this.state.disabledListCompositiveStaffs
                }
                disabledListOnlySee={this.state.disabledListOnlySeeStaffs}
                selectedCompositiveDepartments={
                  this.state.selectedCompositiveStaffs
                }
                selectedOnlySeeDepartments={this.state.selectedOnlySeeStaffs}
                titles={['', 'PH', 'XĐB']}
                isHasRadio={false}
                type="Cá nhân"
              />
            </div>

            {/* Phối hợp*/}
            {/* <div className="selectbox-group">
              <div className="heading-group-select disabled-checkbox">
                <span className="pull-left name_group">Phối hợp</span>
                <Checkbox
                  className="pull-right"
                  name="allCompositive"
                  checked={this.state.isCheckedAllCompositive}
                  onChange={this.handleCheckAllCompositive}
                  disabled={this.state.dsXemDeBiet.length + 1 + this.state.disabledList.length === this.state.staffSelections.length || !this.state.staffSelections.length || this.state.disabledList.length === this.state.staffSelections.length}
                >
                  <span className="name_checkbox">Chọn tất cả</span>
                </Checkbox>
              </div>
              <div className="container-checkbox">
                <CheckboxGroup
                  name="phoiHop"
                  options={this.state.staffSelections}
                  selectedOptions={this.state.dsPhoiHop}
                  onChange={this.handleCompositiveStaffSelection}
                  disabledList={this.state.dsXemDeBiet.concat(this.state.chuTriId).concat(this.state.disabledList)}
                  colum="col-xs-6 col-sm-6 col-md-6"
                />
              </div>
            </div> */}

            {/* Xem để biết*/}
            {/* <div className="selectbox-group">
              <div className="heading-group-select disabled-checkbox">
                <span className="pull-left name_group">Xem để biết</span>
                <Checkbox
                  className="pull-right"
                  name="allOnlySee"
                  checked={this.state.isCheckedAllOnlySee}
                  onChange={this.handleCheckAllOnlySee}
                  disabled={this.state.dsPhoiHop.length + 1 + this.state.disabledList.length === this.state.staffSelections.length || !this.state.staffSelections.length || this.state.disabledList.length === this.state.staffSelections.length}
                >
                  <span className="name_checkbox">Chọn tất cả</span>
                </Checkbox>
              </div>
              <div className="container-checkbox">
                <CheckboxGroup
                  name="xemDeBiet"
                  options={this.state.staffSelections}
                  selectedOptions={this.state.dsXemDeBiet}
                  onChange={this.handleOnlySeeStaffSelection}
                  isCheckFull={this.state.isCheckedAllOnlySee}
                  disabledList={this.state.dsPhoiHop.concat(this.state.chuTriId).concat(this.state.disabledList)}
                  colum="col-xs-6 col-sm-6 col-md-6"
                />
              </div>
            </div> */}

            {/*<div className="pt-form-group pt-intent-danger">
              <div className="row full-row">
                <label className="pt-label col-md-3 col-xs-12 col-sm-12">
                  Lưu tủ tài liệu
                  <span className="pt-text-muted required-input">*</span>
                </label>
                <div className="pt-form-content col-md-9 col-xs-12 col-sm-12">
                  <Button className="btn-add-document" >
                    <span className="text-btn">Chọn tủ tài liệu</span>
                    <span className="icon-ttl icon-Tu_Tai_Lieu"></span>
                  </Button>
                  <p className="info-save-document">
                    Congtactochuc/hosonhanvien/<span className="year-style">nam2017</span>
                    <span className="pt-icon pt-icon-cross"></span>
                  </p>
                </div>
              </div>
            </div>*/}
          </div>
          <div id="groupButton" className="buttton-action-footer">
            <Button
              className="pt-button btn-cancel"
              onClick={this.props.onClickGoBack}
              disabled={this.state.isLoading === true ? true : false}
            >
              <span className=" pt-icon icon-back"></span>
              <span className="text-content">Quay lại</span>
            </Button>
            <Button
              className="pt-button btn-blue-color"
              onClick={this.handleSubmit}
              loading={this.state.isLoading}
            >
              <span className="text-content">Lưu</span>
              <span className="pt-icon icon-save"></span>
            </Button>
          </div>

          <div className="buttton-actionfooter-fixtop buttton-action-footer">
            <Button
              className="pt-button btn-blue-color"
              onClick={this.handleSubmit}
              loading={this.state.isLoading}
            >
              <span className="pt-icon icon-save"></span>
              <span className="text-content">Lưu</span>
            </Button>
            <Button
              className="pt-button btn-cancel"
              onClick={this.props.onClickGoBack}
              disabled={this.state.isLoading === true ? true : false}
            >
              <span className=" pt-icon icon-back"></span>
              <span className="text-content">Quay lại</span>
            </Button>
          </div>

          {/*<PopupConfirm
            isOpen={this.state.isOpenPopup}
            onClose={this.handleClickPopup}
            title={POPUP.HUY_GUI_GIAO_VIEC}
            text={POPUP.TEXT_BUTTON_SUBMIT}
            onSubmit={this.props.onClickGoBack}
            >
            {POPUP.TEXT_BODY}
          </PopupConfirm>*/}
        </div>
      </div>
    )
  }
}

export default AdditionalAssign
