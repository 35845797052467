import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { Intent } from '@blueprintjs/core'
import { commonAddToasterMessage } from 'actions'
import { ValidateForm } from 'constants/MessageForm'
import { FileGroup } from 'components/newCommon'

const UploadFileInputSingle = ({
  actions,
  className,
  placeholder,
  textSumary,
  maxSize,
  fileTypeAllow,
  fileTypeAllowShortcut,
  fileTypeNotAllow,
  dataSource,
  isDisabled,
  onChange,
  onRemove,
}) => {
  const size = 1024 * 1024 * maxSize

  const onChangeFile = e => {
    const { files } = e.target
    let tempFiles = []
    for (let index = 0; index < files.length; index++) {
      const originalFile = files[index]
      if (originalFile) {
        const originalType = originalFile.type
        const originalSize = originalFile.size
        if (fileTypeAllow?.length !== 0 || fileTypeNotAllow?.length !== 0) {
          if (
            fileTypeAllow?.length !== 0 &&
            !fileTypeAllow?.some(s => s === originalType)
          ) {
            actions.commonAddToasterMessage({
              message: ValidateForm.FILE_TYPE_ALLOW(
                fileTypeAllowShortcut?.length !== 0
                  ? fileTypeAllowShortcut
                  : fileTypeAllow
              ),
              intent: Intent.WARNING,
            })
            break
          } else if (
            fileTypeNotAllow?.length !== 0 &&
            fileTypeNotAllow?.some(s => s === originalType)
          ) {
            actions.commonAddToasterMessage({
              message: ValidateForm.FILE_TYPE_NOT_ALLOW(fileTypeNotAllow),
              intent: Intent.WARNING,
            })
            break
          }
        }

        if (originalSize > size) {
          actions.commonAddToasterMessage({
            message: ValidateForm.FILE_SIZE_ALLOW(maxSize),
            intent: Intent.WARNING,
          })
          break
        }

        tempFiles = [
          ...tempFiles,
          {
            name: originalFile.name,
            size: originalSize,
            file: originalFile,
          },
        ]
      }
    }

    return onChange(tempFiles)
  }

  return (
    <div className={className}>
      <div className="comp-upload-file single">
        <input type="text" placeholder={placeholder} />
        <input
          type="file"
          accept={
            fileTypeAllowShortcut?.length > 0 ?? false
              ? fileTypeAllowShortcut.join(', ')
              : '*'
          }
          title={placeholder}
          multiple
          disabled={isDisabled}
          onChange={e => onChangeFile(e)}
        />
        <button type="button">Chọn file</button>
      </div>
      {textSumary ? (
        <p className="text-13-300-i opacity-05 mt5">{textSumary}</p>
      ) : (
        ''
      )}
      <p className="text-13-300-i opacity-05 mt5"></p>
      <FileGroup
        list={dataSource}
        smallIcon
        readOnly
        showClose
        disabled={false}
        onClose={onRemove}
      />
    </div>
  )
}

UploadFileInputSingle.propTypes = {
  actions: PropTypes.object,
  className: PropTypes.string,
  placeholder: PropTypes.string,
  textSumary: PropTypes.string,
  maxSize: PropTypes.number,
  fileTypeAllow: PropTypes.array,
  fileTypeAllowShortcut: PropTypes.array,
  fileTypeNotAllow: PropTypes.array,
  dataSource: PropTypes.array,
  isDisabled: PropTypes.bool,
  onChange: PropTypes.func,
  onRemove: PropTypes.func,
}

UploadFileInputSingle.defaultProps = {
  actions: null,
  className: '',
  placeholder: 'Chọn file đính kèm',
  textSumary: 'Chọn file đính kèm',
  maxSize: 25,
  fileTypeAllow: [],
  fileTypeAllowShortcut: [],
  fileTypeNotAllow: [],
  dataSource: [],
  isDisabled: false,
  onChange: () => {},
  onRemove: () => {},
}

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(
    {
      commonAddToasterMessage,
    },
    dispatch
  ),
})

export default connect(null, mapDispatchToProps)(UploadFileInputSingle)
