import React, { useMemo, Fragment } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import UIDepartmentAndPersonalTreeItemChild from './item'

const UIDepartmentAndPersonalTreeItem = ({
  keyChildren,
  header,
  dataSource,
  dataOpen,
  level,
}) => {
  const id = dataSource?.phongBanId
  const isOpen = useMemo(() => {
    if (dataOpen?.length === 0 ?? false) {
      return
    }

    return dataOpen?.some(s => s === id)
  }, [dataOpen, id])

  return (
    <Fragment>
      <div
        className={classNames('row-item', {
          [`level-${level}`]: level,
        })}
      >
        {header?.length > 0 ?? false
          ? header.map((elmTd, indexTd) => (
              <div
                key={indexTd}
                className={classNames('cell', {
                  [`${elmTd?.className}`]: elmTd?.className,
                })}
              >
                <div className="item">
                  {elmTd?.bodyRender
                    ? elmTd.bodyRender(dataSource)
                    : elmTd?.keyName
                    ? dataSource?.[elmTd.keyName]
                    : ''}
                </div>
              </div>
            ))
          : ''}
      </div>
      {isOpen &&
      dataSource?.[keyChildren] &&
      (dataSource?.[keyChildren]?.length > 0 ?? false)
        ? dataSource?.[keyChildren].map((elmChild, indexChildTr) => (
            <UIDepartmentAndPersonalTreeItemChild
              key={indexChildTr}
              keyChildren={keyChildren}
              header={header}
              dataSource={elmChild}
              dataOpen={dataOpen}
              level={level + 1}
            />
          ))
        : ''}
    </Fragment>
  )
}

UIDepartmentAndPersonalTreeItem.defaultProps = {
  header: [],
  dataOpen: [],
  dataSource: null,
  level: 1,
}

UIDepartmentAndPersonalTreeItem.propTypes = {
  header: PropTypes.array,
  dataOpen: PropTypes.array,
  dataSource: PropTypes.object,
  level: PropTypes.number,
}

export default UIDepartmentAndPersonalTreeItem
