import React, { Component } from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import classnames from 'classnames'
import { Checkbox } from '@blueprintjs/core'
import { Popover, Position, PopoverInteractionKind } from '@blueprintjs/core'
import { Link } from 'react-router-dom'
import { PopupConfirm } from '../common/'
import { Authorization, Permission } from '../auth'
import * as POPUP from '../../constants/Popup'
import UIStatus from 'components/ui/status'

class ListItem extends Component {
  static propTypes = {
    item: PropTypes.object.isRequired,
    number: PropTypes.number.isRequired,
    disableAction: PropTypes.bool,
  }

  constructor(props) {
    super(props)
    this.handleClick = this.handleClick.bind(this)
    this.handleClickPopup = this.handleClickPopup.bind(this)
    this.handleSubmitRemoveItem = this.handleSubmitRemoveItem.bind(this)
  }

  state = {
    isRedirect: false,
    isOpenPopup: false,
    isEnabled: false,
    isDisable: false,
  }

  componentDidMount() {
    if (this.props.list.find(item => item === this.props.item.congVanDenId)) {
      this.setState({ isEnabled: true })
    }
    if (
      this.props.auth.mainDepartmentId !== this.props.item.phongBanTrinhId ||
      !this.props.isCanCheckDocument
    ) {
      this.setState({ isDisable: true })
    }
  }

  handleClick = () => {
    if (!this.props.item) {
      return
    }

    this.props.onClickDetail &&
      this.props.onClickDetail({
        congVanDenId: this.props.item.congVanDenId,
        soCongVan: this.props.item.soCongVan,
        fileId: this.props.item.fileId,
        daXem: this.props.item?.daXem,
      })
  }

  handleClickPopup = () => {
    this.setState({
      isOpenPopup: !this.state.isOpenPopup,
      error: '',
    })
  }

  handleSubmitRemoveItem = e => {
    e.preventDefault()
    this.setState({
      isOpenPopup: false,
    })

    const id = this.props.item.congVanDenId
      ? this.props.item.congVanDenId
      : this.props.item.congVanDiId
    this.props.onHandleSubmitRemoveItem &&
      this.props.onHandleSubmitRemoveItem(id)
  }

  handleEnabledChange = e => {
    const id = this.props.item.congVanDenId
    this.setState({ isEnabled: !this.state.isEnabled })
    this.props.onCheck(id)
  }

  render() {
    if (!this.props.item) {
      return
    }

    const nguoiTaoId = this.props.item?.nguoiTaoId
    const roleId = this.props.auth?.roleId
    const isDelete = nguoiTaoId && nguoiTaoId === roleId ? true : false
    return (
      <tr
        className={classnames({
          'status-urgency-nomarl':
            this.props.item.doKhan && this.props.item.doKhan.maDoKhan === 'BT',
          'status-urgency':
            this.props.item.doKhan && this.props.item.doKhan.maDoKhan === 'KH',
          'status-urgency-hight':
            this.props.item.doKhan && this.props.item.doKhan.maDoKhan === 'TK',
          'is-unread-table-row': !this.props.item?.daXem,
        })}
      >
        {this.props.match &&
          this.props.match.params &&
          this.props.match.params.status === 'chua-xu-ly' && (
            <td>
              {this.props.auth &&
              this.props.auth.permission &&
              this.props.auth.permission.indexOf('CHIDAO_HANGLOAT') !== -1 &&
              this.props.auth.permission.indexOf('CONGVANDEN_CHIDAO') !== -1 ? (
                <Checkbox
                  checked={this.state.isEnabled}
                  onChange={this.handleEnabledChange}
                  disabled={this.state.isDisable}
                />
              ) : null}
            </td>
          )}
        <td onClick={this.handleClick}>
          {this.props.item.soLuuId === null && (
            <span className="pt-icon-star pt-icon check-star"></span>
          )}
        </td>
        <td onClick={this.handleClick}>
          {this.props.number > 9 ? this.props.number : '0' + this.props.number}
        </td>
        <td onClick={this.handleClick}>{this.props.item.soDen}</td>
        {this.props.match.params.status === 'chua-xu-ly' ? (
          <td onClick={this.handleClick}>
            {moment(this.props.item.ngayCapNhat).format('DD/MM/YYYY HH:mm')}
          </td>
        ) : (
          <td onClick={this.handleClick}>
            {moment(this.props.item.ngayCongVan).format('DD/MM/YYYY')}
          </td>
        )}
        <td onClick={this.handleClick}>{this.props.item.soCongVan}</td>
        <td onClick={this.handleClick} className="textarea-content">
          {this.props.item.trichYeu}
        </td>
        <td onClick={this.handleClick}>{this.props.item.coQuanBanHanh}</td>
        <td onClick={this.handleClick}>{this.props.item.phongBanTrinh}</td>
        {this.props.match.params.status !== 'chua-xu-ly' &&
          this.props.match.params.status !== 'dang-xu-ly' && (
            <td onClick={this.handleClick}>
              <UIStatus
                className="min-width-80 center"
                name={
                  this.props.item.trangThai === 0
                    ? 'Chưa xử lý'
                    : this.props.item.trangThai === 1
                    ? 'Đang xử lý'
                    : this.props.item.trangThai === 2
                    ? 'Đã xử lý'
                    : ''
                }
                color={
                  this.props.item.trangThai === 0
                    ? '#df8e0a'
                    : this.props.item.trangThai === 1
                    ? '#1677ff'
                    : this.props.item.trangThai === 2
                    ? '#18a94d'
                    : ''
                }
                theme="BACKGROUND"
              />
            </td>
          )}
        {this.props.item.trangThai !== undefined &&
        this.props.item.trangThai === 0 ? (
          <Authorization.Element permission={[Permission.CONGVANDEN_QUANLY]}>
            <td>
              {!this.props.auth.permission.includes(Permission.ADMIN) &&
                !this.props.auth.permission.includes(Permission.SUPERADMIN) && (
                  <div>
                    <Popover
                      popoverClassName="pt-popover-content-sizing pt-minimal"
                      position={Position.BOTTOM_RIGHT}
                      interactionKind={PopoverInteractionKind.HOVER}
                      openOnTargetFocus={true}
                      content={
                        <div className="menu-popover">
                          <Link
                            className="item"
                            to={`/cong-van/cong-van-den/chinh-sua/${this.props.item.congVanDenId}`}
                          >
                            <span className="pt-icon pt-icon-annotation"></span>
                            <span className="name_action">Chỉnh sửa</span>
                          </Link>
                          {isDelete && (
                            <span
                              className="item"
                              onClick={this.handleClickPopup}
                            >
                              <span className="pt-icon pt-icon-trash"></span>
                              <span className="name_action">Xóa</span>
                            </span>
                          )}
                        </div>
                      }
                    >
                      <span className="icon-More"></span>
                    </Popover>
                    <PopupConfirm
                      isOpen={this.state.isOpenPopup}
                      onClose={this.handleClickPopup}
                      title={POPUP.XOA_CONG_VAN_DEN}
                      text={POPUP.TEXT_BUTTON_SUBMIT}
                      onSubmit={this.handleSubmitRemoveItem}
                    >
                      {POPUP.TEXT_SUBMIT_BODY}
                    </PopupConfirm>
                  </div>
                )}
            </td>
          </Authorization.Element>
        ) : (
          <Authorization.Element permission={[Permission.CONGVANDEN_QUANLY]}>
            <td className="none-status">&nbsp;</td>
          </Authorization.Element>
        )}
      </tr>
    )
  }
}

export default ListItem
