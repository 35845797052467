import { Checkbox } from '@blueprintjs/core'
import { HeadingTableSort } from 'components/common'
import { Input } from 'components/newCommon'
import { headers } from 'constants/Table'
import { debounce, isEmpty, uniqBy } from 'lodash'
import React, { useEffect, useState, useCallback } from 'react'

const ChonLoaiVatTu = ({
  actions,
  dsVatTuSelected,
  setDsVatTuSelected,
  isDetail,
}) => {
  const [filter, setfilter] = useState({
    textSearch: '',
    nhomVatTuId: '',
  })
  const [listNhomVatTu, setListNhomVatTu] = useState([])
  const [nhomVatTuMultilevel, setNhomVatTuMultilevel] = useState([])

  const handleGetNhomVatTu = useCallback(async () => {
    const params = {
      limit: 2000,
      isActive: true,
    }
    const res = await actions.getDanhSachNhomVatTu(params)
    if (!res.error && res?.payload?.data?.result) {
      const dataNhomVatTu = res?.payload?.data?.result?.items?.map(item => ({
        name: item.ten,
        value: item.vatTuId,
      }))
      setListNhomVatTu(dataNhomVatTu)
    }
  }, [actions])

  const handleGetLoaiVatTuMultilevel = useCallback(async () => {
    const filterConverter = {
      textSearch: filter?.textSearch?.trim() || undefined,
      nhomVatTuId: filter?.nhomVatTuId || undefined,
    }
    const res = await actions.getLoaiVatTuMultilevel(filterConverter)
    if (!res.error && res?.payload?.data?.result?.items) {
      const data = res.payload.data.result?.items
      setNhomVatTuMultilevel(
        data?.map((item, index) => ({ ...item, expanded: true }))
      )
    }
  }, [filter, actions])

  const handleToggleExpanded = id => {
    const result = nhomVatTuMultilevel?.map(item => {
      if (item.vatTuId === id) {
        return {
          ...item,
          expanded: !item.expanded,
        }
      }
      return item
    })
    setNhomVatTuMultilevel(result)
  }

  const handleCheckboxs = (check, item, parent) => {
    let resultLoaiVatTu = [...dsVatTuSelected]
    if (parent) {
      if (check) {
        if (!isEmpty(item.dsVatTuCon)) {
          item.dsVatTuCon.forEach(child => {
            resultLoaiVatTu.push({
              tenVatTu: child?.ten,
              vatTuId: child?.vatTuId,
              soLuong: 1,
              donViTinh: 'Cái',
              ghiChu: '',
            })
          })
        }
      } else {
        if (!isEmpty(item?.dsVatTuCon)) {
          const listIdVatTuConRemove = item?.dsVatTuCon?.map(
            child => child?.vatTuId
          )
          resultLoaiVatTu = resultLoaiVatTu?.filter(
            child => !listIdVatTuConRemove.includes(child.vatTuId)
          )
        }
      }
    } else {
      if (check) {
        resultLoaiVatTu.push({
          tenVatTu: item?.ten,
          vatTuId: item?.vatTuId,
          soLuong: 1,
          donViTinh: 'Cái',
          ghiChu: '',
        })
      } else {
        resultLoaiVatTu = resultLoaiVatTu?.filter(
          i => i.vatTuId !== item.vatTuId
        )
      }
    }
    setDsVatTuSelected(uniqBy(resultLoaiVatTu, 'vatTuId'))
  }

  const handleChangeValueSelected = (name, value, index) => {
    const result = dsVatTuSelected?.map((item, i) => {
      if (i === index - 1) {
        return {
          ...item,
          [name]: value,
        }
      }
      return item
    })
    setDsVatTuSelected(result)
  }

  const hanldeDeleteRow = index => {
    const result = dsVatTuSelected?.filter((item, i) => i !== index - 1)
    setDsVatTuSelected(result)
  }

  const handleChangeFilter = debounce((name, value) => {
    setfilter({
      ...filter,
      [name]: value,
    })
  }, 500)

  useEffect(() => {
    handleGetNhomVatTu()
  }, [handleGetNhomVatTu])

  useEffect(() => {
    handleGetLoaiVatTuMultilevel()
  }, [handleGetLoaiVatTuMultilevel])

  return (
    <div className="supplies-type">
      {isDetail ? (
        <div className="row">
          <div className="col-xs-12 col-sm-12 col-md-12">
            <div className="supplies-type-right">
              <div className="list-supplies">
                <div className="table-display">
                  <div className="table-display-container">
                    <HeadingTableSort
                      data={
                        isDetail
                          ? headers.chiTietDsLoaiVatTuHauCan
                          : headers.dsLoaiVatTuHauCan
                      }
                    />
                    <div className="table-body-container">
                      {isEmpty(dsVatTuSelected) ? (
                        <div className="error-page-container bb1">
                          <div className="error-content">
                            <img
                              src={
                                process.env.PUBLIC_URL +
                                '/images/search-result-not-found.png'
                              }
                              alt="search-result-not-found"
                              className="img-thumb-error"
                            />
                            <p className="description-note">
                              {isDetail
                                ? 'Không có dữ liệu'
                                : 'Hãy chọn vật tư'}
                            </p>
                          </div>
                        </div>
                      ) : (
                        <div className="sortableList">
                          {dsVatTuSelected.map((value, index) => (
                            <div className="table-rows" key={`item-${index}`}>
                              <div className="table-cols-content width-col-10">
                                <span className="order-content">
                                  {index + 1 < 10
                                    ? '0' + (index + 1)
                                    : index + 1}
                                </span>
                              </div>
                              <div className="table-cols-content width-col-25">
                                <span>{value?.tenVatTu}</span>
                              </div>
                              <div className="table-cols-content width-col-15">
                                {isDetail ? (
                                  <span>{value?.donViTinh}</span>
                                ) : (
                                  <input
                                    type="text"
                                    className="input-table-border"
                                    placeholder="Nhập"
                                    value={value.donViTinh}
                                    onChange={e =>
                                      handleChangeValueSelected(
                                        'donViTinh',
                                        e.target.value,
                                        index + 1
                                      )
                                    }
                                  />
                                )}
                              </div>
                              <div className="table-cols-content width-col-15">
                                {isDetail ? (
                                  <span>{value?.soLuong}</span>
                                ) : (
                                  <input
                                    type="number"
                                    className="input-table-border"
                                    placeholder="Nhập số"
                                    value={value?.soLuong}
                                    onChange={e =>
                                      handleChangeValueSelected(
                                        'soLuong',
                                        e.target.value,
                                        index + 1
                                      )
                                    }
                                    min={0}
                                  />
                                )}
                              </div>
                              <div
                                className={`table-cols-content ${
                                  isDetail ? 'width-col-35' : 'width-col-25'
                                }`}
                              >
                                {isDetail ? (
                                  <span>{value?.ghiChu}</span>
                                ) : (
                                  <input
                                    type="text"
                                    className="input-table-border"
                                    placeholder="Nhập ghi chú"
                                    value={value?.ghiChu}
                                    onChange={e =>
                                      handleChangeValueSelected(
                                        'ghiChu',
                                        e.target.value,
                                        index + 1
                                      )
                                    }
                                  />
                                )}
                              </div>
                              {!isDetail && (
                                <div className="table-cols-content width-col-10">
                                  <div className="group-action">
                                    <div
                                      className="action-icon"
                                      onClick={() => hanldeDeleteRow(index + 1)}
                                    >
                                      <span className="pt-icon icon-delete-v2"></span>
                                    </div>
                                  </div>
                                </div>
                              )}
                            </div>
                          ))}
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className="row">
          <div className="col-xs-5 col-sm-5 col-md-4">
            <div className="supplies-type-left">
              <div className="row">
                <div className="col-xs-6 col-sm-6 col-md-6 form-item pt-select">
                  <select
                    className="input"
                    onChange={e =>
                      handleChangeFilter('nhomVatTuId', e.target.value)
                    }
                    style={{
                      width: '100%',
                      marginRight: '10px',
                      border: '1px solid #d2d2d2',
                      borderRadius: '4px',
                      height: '39px',
                      backgroundColor: '#fff',
                      boxShadow: '0 2px 2px 0 rgba(0, 0, 0, 0.05)',
                    }}
                  >
                    <option key="default" value="">
                      Tất cả nhóm vật tư
                    </option>
                    {listNhomVatTu?.map((item, index) => (
                      <option key={index} value={item.value}>
                        {item.name}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="col-xs-6 col-sm-6 col-md-6 form-item">
                  <Input
                    className="cpc-input-search"
                    leftIconName="search"
                    onChange={value => handleChangeFilter('textSearch', value)}
                    placeholder="Tìm kiếm"
                  />
                </div>
              </div>
              {isEmpty(nhomVatTuMultilevel) ? (
                <div className="error-page-container">
                  <div className="error-content">
                    <p className="description-note">Không có dữ liệu</p>
                  </div>
                </div>
              ) : (
                <div className="supplies-type-list">
                  {nhomVatTuMultilevel?.map((item, index) => (
                    <div key={index} className="item-wrap">
                      {item?.expanded ? (
                        <i
                          className="icon-round-delete-button has-action"
                          onClick={() => handleToggleExpanded(item?.vatTuId)}
                        />
                      ) : (
                        <i
                          className="icon-rounded-add-button has-action"
                          onClick={() => handleToggleExpanded(item?.vatTuId)}
                        />
                      )}
                      <div className="supplies-list-group">
                        <div className="item-parent">
                          <Checkbox
                            checked={item?.dsVatTuCon
                              ?.map(i => i?.vatTuId)
                              ?.every(j =>
                                dsVatTuSelected
                                  ?.map(k => k?.vatTuId)
                                  .includes(j)
                              )}
                            onChange={e =>
                              handleCheckboxs(e.target.checked, item, true)
                            }
                            label={item?.ten}
                          />
                        </div>
                        {!!item?.expanded && (
                          <div className="list-child">
                            {item?.dsVatTuCon?.map((child, index) => (
                              <div key={index} className="item-child">
                                <Checkbox
                                  checked={dsVatTuSelected
                                    ?.map(i => i?.vatTuId)
                                    ?.includes(child?.vatTuId)}
                                  onChange={e =>
                                    handleCheckboxs(e.target.checked, child)
                                  }
                                  label={child?.ten}
                                />
                              </div>
                            ))}
                          </div>
                        )}
                      </div>
                    </div>
                  ))}
                </div>
              )}
            </div>
          </div>
          <div className="col-xs-7 col-sm-7 col-md-8">
            <div className="supplies-type-right">
              <div className="list-supplies">
                <div className="table-display">
                  <div className="table-display-container">
                    <HeadingTableSort data={headers.dsLoaiVatTuHauCan} />
                    <div className="table-body-container">
                      {isEmpty(dsVatTuSelected) ? (
                        <div className="error-page-container bb1">
                          <div className="error-content">
                            <img
                              src={
                                process.env.PUBLIC_URL +
                                '/images/search-result-not-found.png'
                              }
                              alt="search-result-not-found"
                              className="img-thumb-error"
                            />
                            <p className="description-note">
                              {isDetail ? 'Không có vật tư' : 'Hãy chọn vật tư'}
                            </p>
                          </div>
                        </div>
                      ) : (
                        <div
                          className="sortableList"
                          style={{
                            maxHeight: '400px',
                            overflowY: 'auto',
                          }}
                        >
                          {dsVatTuSelected.map((value, index) => (
                            <div className="table-rows" key={`item-${index}`}>
                              <div className="table-cols-content width-col-10">
                                <span className="order-content">
                                  {index + 1 < 10
                                    ? '0' + (index + 1)
                                    : index + 1}
                                </span>
                              </div>
                              <div className="table-cols-content width-col-25">
                                <span>{value?.tenVatTu}</span>
                              </div>
                              <div className="table-cols-content width-col-15">
                                <input
                                  type="text"
                                  className="input-table-border"
                                  placeholder="Nhập"
                                  value={value.donViTinh}
                                  onChange={e =>
                                    handleChangeValueSelected(
                                      'donViTinh',
                                      e.target.value,
                                      index + 1
                                    )
                                  }
                                />
                              </div>
                              <div className="table-cols-content width-col-15">
                                <input
                                  type="number"
                                  className="input-table-border"
                                  placeholder="Nhập số"
                                  value={value?.soLuong}
                                  onChange={e =>
                                    handleChangeValueSelected(
                                      'soLuong',
                                      e.target.value,
                                      index + 1
                                    )
                                  }
                                  min={1}
                                />
                              </div>
                              <div className="table-cols-content width-col-25">
                                <input
                                  type="text"
                                  className="input-table-border"
                                  placeholder="Nhập ghi chú"
                                  value={value?.ghiChu}
                                  onChange={e =>
                                    handleChangeValueSelected(
                                      'ghiChu',
                                      e.target.value,
                                      index + 1
                                    )
                                  }
                                />
                              </div>
                              <div className="table-cols-content width-col-10">
                                <div className="group-action">
                                  <div
                                    className="action-icon"
                                    onClick={() => hanldeDeleteRow(index + 1)}
                                  >
                                    <span className="pt-icon icon-delete-v2"></span>
                                  </div>
                                </div>
                              </div>
                            </div>
                          ))}
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  )
}

export default ChonLoaiVatTu
