import React from 'react'
import DatePicker from 'react-datepicker'
import { Button, Intent } from '@blueprintjs/core'
import { connect } from 'react-redux'
import { size, get, findIndex } from 'lodash'
import { bindActionCreators } from 'redux'

import { MasterLayout } from '../../../components/layout'
import { HeadingPage } from '../../../components/common'
import { datePickerConfig } from '../../../constants/config'
import { acceptMIMETypes } from '../../../constants/Api'
import allowFileTypes from '../../../constants/fileTypes'
import { fileType } from '../../../helpers/validation'

import {
  commonFetchUnitsRelative,
  commonAddToasterMessage,
  fileUpload,
  postTaiLieuHoSoCongViec,
} from '../../../actions'
import {
  validateMessage,
  TEXT_MESSAGE_UPLOAD_FAIL,
  ValidateForm,
} from '../../../constants/MessageForm'
import { Utils } from '../../../helpers'
import csx from 'classnames'

const maxMb = 25 * 2 ** 20
let fileId = 0

class TuTaiLieuPhongBanThemFile extends React.PureComponent {
  constructor() {
    super()
    this.state = {
      form: {
        soCongVan: '',
        ngayCongVan: null,
        coQuanBanHanh: '',
        ghiChu: '',
        tenTaiLieu: '',
        fileDinhKem: [
          {
            fileId: fileId++,
          },
        ],
        fileNoiDung: [
          {
            fileId: fileId++,
          },
        ],
      },
      unitList: [],
      submitLoading: false,
      uploadProgress: {
        dinhKem: 0,
        noiDung: 0,
      },
      validate: {},
    }
  }

  setFormValue = (name, value) => {
    this.setState(prev => ({
      form: {
        ...prev.form,
        [name]: value,
      },
    }))
  }

  changeDate = name => value => {
    this.setFormValue(name, value)
  }

  changeInput = e => {
    const { name, value } = e.target
    this.setFormValue(name, value)
  }

  changeFile = (name, id) => e => {
    const reader = new FileReader()
    const { files } = e.target
    const targetFile = files[0]
    let validateSuccess = true

    reader.onloadend = () => {
      if (targetFile) {
        const extension = Utils.getExtensionFile(targetFile.name)

        if (
          (name === 'fileDinhKem' && fileType(extension, allowFileTypes)) ||
          (name === 'fileNoiDung' &&
            fileType(extension, ['pdf', 'doc', 'docx']))
        ) {
          validateSuccess = false
          return this.setState(prev => ({
            validate: {
              ...prev.validate,
              [name]: {
                ...prev.validate[name],
                [id]: true,
              },
            },
          }))
        }

        this.setState(prev => ({
          form: {
            ...prev.form,
            [name]: prev.form[name].map(file =>
              file.fileId === id
                ? {
                    ...file,
                    data: targetFile,
                  }
                : file
            ),
          },
          validate: {
            ...prev.validate,
            [name]: {
              ...prev.validate[name],
              [id]: false,
            },
          },
        }))
      }
    }

    if (targetFile && validateSuccess) {
      reader.readAsDataURL(targetFile)
    }
  }

  addInputFile = name => () => {
    this.setState(prev => ({
      form: {
        ...prev.form,
        [name]: [
          ...prev.form[name],
          {
            fileId: fileId++,
          },
        ],
      },
    }))
  }

  removeInputFile = (name, id) => e => {
    e.preventDefault()
    this.setState(prev => ({
      form: {
        ...prev.form,
        [name]: prev.form[name].filter(file => file.fileId !== id),
      },
    }))
  }

  goBack = () => {
    this.props.history.goBack()
  }

  toggleLoading = () => {
    this.setState(prev => ({ submitLoading: !prev.submitLoading }))
  }

  showValidateMessage = (message, danger = false, success = false) => {
    const { showMessage } = this.props
    return showMessage({
      message,
      intent: success
        ? Intent.SUCCESS
        : danger
        ? Intent.DANGER
        : Intent.WARNING,
    })
  }

  validate = () => {
    const { form } = this.state

    if (!form.soCongVan) {
      return this.showValidateMessage(validateMessage.input('số công văn'))
    }
    if (!form.ngayCongVan) {
      return this.showValidateMessage(validateMessage.select('ngày công văn'))
    }
    if (!form.coQuanBanHanh) {
      return this.showValidateMessage(validateMessage.input('cơ quan ban hành'))
    }
    if (!form.tenTaiLieu) {
      return this.showValidateMessage(
        validateMessage.input('tên tài liệu/trích yếu')
      )
    }

    // validate file size
    if (!get(form.fileNoiDung[0], 'data')) {
      return this.showValidateMessage(validateMessage.select('file nội dung'))
    } else if (get(form.fileNoiDung[0], 'data.size') > maxMb) {
      return this.showValidateMessage(
        validateMessage.fileSize('File nội dung'),
        true
      )
    }
    const failFileIndex = findIndex(
      form.fileDinhKem,
      file => get(file, 'data.size', 0) > maxMb
    )
    if (failFileIndex > -1) {
      return this.showValidateMessage(
        validateMessage.fileSize(`File đính kèm ${failFileIndex + 1}`),
        true
      )
    }

    return false
  }

  changeUploadProgress = name => progress => {
    this.setState(prev => ({
      uploadProgress: {
        ...prev.uploadProgress,
        [name]: ~~((progress.loaded * 100) / progress.total),
      },
    }))
  }

  uploadFile = async () => {
    const { uploadFile } = this.props
    const { fileDinhKem, fileNoiDung } = this.state.form

    const responseND = await uploadFile(
      fileNoiDung.map(f => f.data),
      this.changeUploadProgress('noiDung')
    )

    let responseDK = {}
    if (size(fileDinhKem.filter(f => f.data))) {
      responseDK = await uploadFile(
        fileDinhKem.filter(f => f.data).map(f => f.data),
        this.changeUploadProgress('dinhKem')
      )
    }

    if (responseDK.error || responseND.error) {
      return this.showValidateMessage(TEXT_MESSAGE_UPLOAD_FAIL, true)
    }

    return [
      ...get(responseND, 'payload.data.result', []),
      ...get(responseDK, 'payload.data.result', []),
    ].map(file => Utils.convertFileResponse(file))
  }

  submit = async () => {
    await this.toggleLoading()
    const {
      postTaiLieu,
      match: { params },
    } = this.props
    const { form } = this.state

    const validate = this.validate()
    if (validate) {
      return this.toggleLoading()
    }
    const files = await this.uploadFile()
    if (!Array.isArray(files)) {
      return this.toggleLoading()
    }

    const response = await postTaiLieu(
      Utils.toDecamelizeKeys({
        ...form,
        ngayCongVan: form.ngayCongVan.format(),
        coQuanBanHanh: get(form, 'coQuanBanHanh'),
        fileDinhKem: [],
        fileDinhKemBenNgoai: files.slice(1),
        fileNoiDung: files[0],
        dsHoSoCongViecId: [params.id],
      })
    )
    if (response && response.error) {
      this.toggleLoading()
      return this.showValidateMessage('Lưu tài liệu không thành công', true)
    }
    this.showValidateMessage('Lưu tài liệu thành công', false, true)
    this.goBack()
  }

  getUnitList = async () => {
    const { fetchUnit, auth } = this.props
    const { mainUnitId } = auth
    if (mainUnitId) {
      const response = await fetchUnit(mainUnitId)
      const { code, result = {} } = get(response, 'payload.data', {})
      if (code === 200) {
        this.setState({ unitList: get(result, 'items', []) })
      }
    }
  }

  componentDidMount() {
    this.getUnitList()
  }

  componentDidUpdate(prevProps) {
    if (prevProps.auth.mainUnitId !== this.props.auth.mainUnitId) {
      this.getUnitList()
    }
  }

  render() {
    const { form, submitLoading, uploadProgress, validate } = this.state
    return (
      <MasterLayout typeSidebar="documentsCabinet">
        <HeadingPage namePage="Tủ tài liệu" iconPage="icon-Tu_Tai_Lieu" />
        <div className="page-list-container page-list-document-cabinet-container mb20">
          <div className="detail-container">
            <div className="tabs-detail-page">
              <div className="pt-tabs tab-cus-container pd-none">
                <ul className="pt-tab-list" role="tablist">
                  <li
                    className="pt-tab cursor-default pt-tab-color-cabinet"
                    role="tab"
                  >
                    TTL CÔNG TY
                  </li>
                  <li
                    className="pt-tab cursor-default"
                    role="tab"
                    aria-selected={true}
                  >
                    TTL PHÒNG BAN
                  </li>
                  <li
                    className="pt-tab cursor-default pt-tab-color-cabinet"
                    role="tab"
                  >
                    TTL CÁ NHÂN
                  </li>
                </ul>
              </div>
            </div>

            <div className="ttlpb__header">
              <div className="group-button">
                <div className="group-button--content">
                  <Button className="text-uppercase" onClick={this.goBack}>
                    <span className="icon icon-arrow-prev mr8"></span>
                    Quay lại
                  </Button>
                </div>
              </div>
              <h4 className="center-text text-uppercase">
                <strong>Thêm tài liệu</strong>
              </h4>
              <span></span>
            </div>

            <div className="panel-wrapper border-top">
              <div className="panel-body pt20 pr20 pl20 pb5">
                <div className="panel-body-content">
                  <div className="form-container">
                    <div className="pt-form-group">
                      <div className="row item-row-input">
                        <div className="col-md-6 col-xs-12 col-sm-6 flex-form">
                          <label className="pt-label label-inline-input mw-180 pt5">
                            Số công văn
                            <span className="required-input">*</span>
                          </label>
                          <div className="pt-form-content">
                            <input
                              name="soCongVan"
                              className="pt-input"
                              placeholder="Nhập số công văn"
                              type="text"
                              dir="auto"
                              value={form.soCongVan}
                              onChange={this.changeInput}
                              autoFocus
                            />
                          </div>
                        </div>
                        <div className="col-md-6 col-xs-12 col-sm-6 flex-form">
                          <label className="pt-label label-inline-input mw-180 pt5">
                            Ngày công văn
                            <span className="required-input">*</span>
                          </label>
                          <div className="pt-form-content dateinput-inline">
                            <DatePicker
                              {...datePickerConfig}
                              onChange={this.changeDate('ngayCongVan')}
                              value={form.ngayCongVan}
                              selected={form.ngayCongVan}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="pt-form-group">
                      <div className="row item-row-input">
                        <div className="col-md-6 col-xs-12 col-sm-6 flex-form">
                          <label className="pt-label label-inline-input mw-180 pt5">
                            Cơ quan ban hành
                            <span className="required-input">*</span>
                          </label>
                          <div className="pt-form-content">
                            <input
                              name="coQuanBanHanh"
                              className="pt-input"
                              placeholder="Nhập cơ quan ban hành"
                              type="text"
                              dir="auto"
                              value={form.coQuanBanHanh}
                              onChange={this.changeInput}
                            />
                          </div>
                        </div>
                        <div className="col-md-6 col-xs-12 col-sm-6 flex-form">
                          <label className="pt-label label-inline-input mw-180 pt5">
                            Tên tài liệu/Trích yếu
                            <span className="required-input">*</span>
                          </label>
                          <div className="pt-form-content">
                            <textarea
                              name="tenTaiLieu"
                              rows="1"
                              className="pt-input"
                              placeholder="Nhập tên tài liệu/trích yêu…"
                              dir="auto"
                              onChange={this.changeInput}
                              value={form.tenTaiLieu}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="row item-row-input">
                      <div className="col-md-6 col-xs-12 col-sm-6">
                        {form.fileNoiDung.map((file, index) => (
                          <div key={index} className="pt-form-group">
                            <div className="row item-row-input">
                              <div className="col-xs-12 flex-form">
                                <label className="pt-label label-inline-input mw-180 pt5">
                                  File nội dung
                                  <span className="required-input">*</span>
                                </label>
                                <div className="pt-form-content">
                                  <label className="pt-file-upload pt-input">
                                    <input
                                      type="file"
                                      name="fileNoiDung"
                                      onChange={this.changeFile(
                                        'fileNoiDung',
                                        file.fileId
                                      )}
                                      accept={acceptMIMETypes}
                                    />
                                    <span className="pt-file-upload-input">
                                      {file.data
                                        ? file.data.name
                                        : 'Chọn file nội dung'}
                                    </span>
                                  </label>
                                  {file.data && (
                                    <span>
                                      <p className="pt-form-helper-text pt-form-helper-infomation">
                                        Kích thước file đã upload:
                                        <strong>
                                          {' '}
                                          {Utils.getFileSize(file.data.size)}
                                        </strong>
                                      </p>
                                    </span>
                                  )}
                                  {get(
                                    validate,
                                    `fileNoiDung[${file.fileId}]`
                                  ) && (
                                    <div className="pt-form-helper-text">
                                      {ValidateForm.FILE_TYPE([
                                        'pdf',
                                        'doc',
                                        'docx',
                                      ])}
                                    </div>
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>
                        ))}
                        <div className="pt-form-group m0">
                          <div className="row item-row-input">
                            <div className="col-xs-12 flex-form">
                              {submitLoading && uploadProgress.noiDung > 0 && (
                                <div className="progress-upload-content pl-180">
                                  <span className="percent-content">
                                    {uploadProgress.noiDung}%
                                  </span>
                                  <div className="pt-progress-bar pt-intent-primary progress-bar-upload">
                                    <div
                                      className="pt-progress-meter"
                                      style={{
                                        width: uploadProgress.noiDung + '%',
                                      }}
                                    />
                                  </div>
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-6 col-xs-12 col-sm-6 flex-form">
                        <label className="pt-label label-inline-input mw-180 pt5">
                          Ghi chú
                        </label>
                        <div className="pt-form-content">
                          <textarea
                            name="ghiChu"
                            rows="1"
                            className="pt-input"
                            placeholder="Nhập ghi chú…"
                            onChange={this.changeInput}
                            value={form.ghiChu}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="row item-row-input">
                      <div className="col-md-6 col-xs-12 col-sm-6">
                        {form.fileDinhKem.map((file, i) => (
                          <div key={i} className="pt-form-group">
                            <div className="row item-row-input">
                              <div className="col-xs-12 flex-form">
                                {!i && (
                                  <label className="pt-label label-inline-input mw-180 pt5">
                                    File đính kèm từ bên ngoài
                                  </label>
                                )}
                                <div
                                  className={csx('pt-form-content', {
                                    'pl-180': !!i,
                                  })}
                                >
                                  <label className="pt-file-upload pt-input">
                                    <input
                                      type="file"
                                      name="fileDinhKem"
                                      onChange={this.changeFile(
                                        'fileDinhKem',
                                        file.fileId
                                      )}
                                      accept={acceptMIMETypes}
                                    />
                                    <span className="pt-file-upload-input">
                                      {file.data
                                        ? file.data.name
                                        : 'Chọn file đính kèm'}
                                    </span>
                                    {size(form.fileDinhKem) > 1 && (
                                      <span
                                        className="icon-bc icon-close icon-remove-file"
                                        onClick={this.removeInputFile(
                                          'fileDinhKem',
                                          file.fileId
                                        )}
                                      />
                                    )}
                                  </label>
                                  {file.data && (
                                    <span>
                                      <p className="pt-form-helper-text pt-form-helper-infomation">
                                        Kích thước file đã upload:
                                        <strong>
                                          {Utils.getFileSize(file.data.size)}
                                        </strong>
                                      </p>
                                    </span>
                                  )}
                                  {get(
                                    validate,
                                    `fileDinhKem[${file.fileId}]`
                                  ) && (
                                    <div className="pt-form-helper-text">
                                      {ValidateForm.FILE_TYPE(allowFileTypes)}
                                    </div>
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>
                        ))}
                        <div className="pt-form-group m0">
                          <div className="row item-row-input">
                            <div className="col-xs-12 form-inline-group">
                              {submitLoading && uploadProgress.dinhKem > 0 && (
                                <div className="progress-upload-content pl-180">
                                  <span className="percent-content">
                                    {uploadProgress.dinhKem}%
                                  </span>
                                  <div className="pt-progress-bar pt-intent-primary progress-bar-upload">
                                    <div
                                      className="pt-progress-meter"
                                      style={{
                                        width: uploadProgress.dinhKem + '%',
                                      }}
                                    />
                                  </div>
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                        <div className="pt-form-group">
                          <div className="row item-row-input">
                            <div className="col-xs-12 form-inline-group">
                              <div className="pl-180">
                                <Button
                                  type="button"
                                  className="pt-button ttlpb__more-button"
                                  onClick={this.addInputFile('fileDinhKem')}
                                  disabled={submitLoading}
                                >
                                  <span className="text-content">
                                    <strong>Thêm file</strong>
                                  </span>
                                  <span className="icon-bc icon-Plus" />
                                </Button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="buttton-action-footer">
              <Button
                className="pt-button btn-cancel"
                onClick={this.goBack}
                disabled={submitLoading}
              >
                <span className=" pt-icon icon-back"></span>
                <span className="text-content">Quay lại</span>
              </Button>
              <Button
                className="pt-button btn-blue-color"
                onClick={this.submit}
                loading={submitLoading}
                disabled={submitLoading}
              >
                <span className="text-content">Lưu</span>
                <span className="pt-icon icon-save"></span>
              </Button>
            </div>
          </div>
        </div>
      </MasterLayout>
    )
  }
}

const mapStateToProps = state => ({
  auth: state.auth,
})

const mapDispatchToProps = dispatch => ({
  fetchUnit: bindActionCreators(commonFetchUnitsRelative, dispatch),
  showMessage: bindActionCreators(commonAddToasterMessage, dispatch),
  uploadFile: bindActionCreators(fileUpload, dispatch),
  postTaiLieu: bindActionCreators(postTaiLieuHoSoCongViec, dispatch),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(TuTaiLieuPhongBanThemFile)
