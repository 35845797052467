import { Button, Intent, Checkbox, Collapse } from '@blueprintjs/core'
import React, { PureComponent } from 'react'
import { get, isEmpty, size } from 'lodash'
import { bindActionCreators } from 'redux'
import classnames from 'classnames'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'

import {
  commonAddToasterMessage,
  fileUpload,
  contactFetchDepartmentByUnit,
  commonFetchDepartments,
  commonFetchElectricUnits,
  getCommonFetchUnitItem,
} from '../../../actions'
import { Toast, ValidateForm } from '../../../constants/MessageForm'
import { fetchReadDepartment } from '../../../actions/procedure'
import {
  fileType,
  maxFileSize,
  required,
  maxLength,
  maxNumber,
  minNumber,
} from '../../../helpers/validation'
import { LoadingTable, ErrorLoadingTable } from '../../../components/common'
import GoBackButton from '../../../components/Button/GoBackButton'
import { Utils } from '../../../helpers'
import { allowDocumentFileTypes } from '../../../constants/fileTypes'
import { FILE_URL, PAGE } from '../../../constants/Api'
import PhongBanXDB from './PhongBanXDB'
import { decamelizeKeys } from 'humps'
import PhongBanListGroup from './PhongBanListGroup'
import DonViChuyenDen from './DonViChuyenDen'
import { PrevewFileTrinhKy } from '../../../components/SignNumber'

const EMPTY_FILE_INFO = {
  id: null,
  name: '',
  size: 0,
  type: null,
  url: null,
  esixt: false,
  isUploadingFile: false,
}

const DEFAULT_STATE = {
  formData: {
    procedureId: '',
    numberSign: '',
    abstract: '',
    contentFiles: [EMPTY_FILE_INFO],
    attachmentsFiles: [EMPTY_FILE_INFO],
    referencesFiles: [EMPTY_FILE_INFO],
    signStep: [],
    isPhatHanh: false,
    isSaveDraft: false,
    draftTitle: '',
    referenceSignatureDocumentId: [],
    departmentSelected: [],
    departmentOtherUnitSelected: [],
    departmentParentSelected: [],
  },
  error: {},
  isWaitingSubmit: false,
  isLoadingData: false,
  isLoadingDataError: false,
  isLoadingStep: true,
  isLoadingMember: false,
  memberList: [],
  memberGroupList: [],
  signatureUnitList: [],
  isEmptyProcedure: false,
  isAutoFinish: false,
  referenceProcedure: null,
  referenceSignatureDocument: [],
  showApplyDraftDialog: false,
  showSaveDraftDialog: false,
  helpDocumentId: null,
  allowUpdate: true,
  stepAllowUpdate: 0,
  loadingDepartment: false,
  loadingEmployeeRole: [],
  departmentList: [],
  electricUnitList: [],
  thanhPhanKy: 0,
  tenQuyTrinh: '',
  roleList: {},
  showPhongBanXDB: false,
  isShowDepartmentParent: false,
  isAllowShowDepartmentParent: false,
  isAllowShowDepartmentsOfOtherUnits: false,
  isChooseDepartmentOtherUnits: false,
  signedList: [],
  readDepartmentList: [],
  readDepartmentParentList: [],
  readElectricUnitList: [],
  selectedDepartmentOtherUnits: {}, // Luu phong ban theo donViId
  phongBanDonViKhac: [],
}

class SignatureDocumentForm extends PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      ...DEFAULT_STATE,
      isOpenDialog: false,
      fileTrinhKy: null,
      isLoadingPreview: false,
      referenceSignatureDocument: [],
    }
  }

  handleInputChange = e => {
    const { name, value } = e.target
    const { formData, error } = this.state
    delete error[name]
    this.setState({
      formData: {
        ...formData,
        [name]: name === 'signatureLocation' ? parseInt(value, 10) : value,
      },
    })
  }

  addInputFile = fileType => {
    const { formData } = this.state
    this.setState({
      formData: {
        ...formData,
        [fileType]: [...formData[fileType], EMPTY_FILE_INFO],
      },
    })
  }

  handleSelect = (val, index) => {
    let referenceSignatureDocument = [...this.state.referenceSignatureDocument]
    referenceSignatureDocument[0] = val
    this.setState({ referenceSignatureDocument: referenceSignatureDocument })
  }

  handleFileChange = async (e, index) => {
    if (!e) {
      return
    }
    const { name, value, files } = e.target
    const { error } = this.state
    !!error[name] && delete error[name][index]

    let errorMsg = {}
    const extension = Utils.getExtensionFile(value)
    const file = !!files && files[0]
    if (file) {
      const { size } = file
      if (error[name] && error[name].length) {
        errorMsg[name] = [...error[name]]
      } else {
        errorMsg[name] = []
      }
      if (name === 'contentFiles') {
        if (fileType(extension, ['pdf'])) {
          errorMsg[name][index] = ValidateForm.FILE_TYPE(['pdf'])
        }
      } else {
        if (fileType(extension, allowDocumentFileTypes)) {
          errorMsg[name][index] = ValidateForm.FILE_TYPE(allowDocumentFileTypes)
        }
      }
      if (maxFileSize(size, 25)) {
        errorMsg[name][index] = ValidateForm.MAX_FILE_SIZE(25)
      }
      if (!!errorMsg[name][index]) {
        this.setState({ error: errorMsg })
      } else {
        this.setFileData(
          name,
          {
            ...EMPTY_FILE_INFO,
            name: file.name,
            size,
            origin: file,
          },
          index
        )
        if (name === 'contentFiles') {
          this.setState({
            allowUpdate: true,
            stepAllowUpdate: 0,
            signedList: [],
          })
        }
      }
    }
  }

  removeFile = (name, index, allowRemove = false) => {
    const { formData, error } = this.state
    !!error[name] && delete error[name][index]
    if (!allowRemove && formData[name].length > 1) {
      this.setState({
        formData: {
          ...this.state.formData,
          [name]: formData[name].filter((item, i) => i !== index),
        },
      })
    } else {
      this.setFileData(name, EMPTY_FILE_INFO, index)
    }
  }

  uploadFile = async (name, file, index) => {
    const { uploadFile, showToast } = this.props
    await this.setFileData(name, { isUploadingFile: true }, index)
    return uploadFile(file)
      .then(res => {
        const status = !get(res, 'error') && get(res, 'payload.status') === 200
        const fileInfo = get(res, 'payload.data.result[0]', {})
        if (status) {
          this.setFileData(
            name,
            {
              id: fileInfo.id,
              name: fileInfo.originalName,
              size: fileInfo.length,
              type: fileInfo.extension,
              url: fileInfo.path,
              esixt: false,
            },
            index
          )
        } else {
          const errorMsg = {}
          errorMsg[name] = []
          errorMsg[name][index] = 'Tải lên thất bại'
          this.setState({
            error: errorMsg,
          })
          showToast({
            message: Toast.FAIL('Tải file lên'),
            intent: Intent.DANGER,
          })
        }
      })
      .finally(() => this.setFileData(name, { isUploadingFile: false }, index))
  }

  setFileData = (name, data, index) => {
    const { formData } = this.state
    this.setState({
      formData: {
        ...formData,
        [name]: [
          ...formData[name].map((item, i) =>
            i === index
              ? {
                  ...item,
                  ...data,
                }
              : item
          ),
        ],
      },
    })
  }

  // Kiểm tra dữ liệu form
  checkFormValidate = () => {
    this.setState({ error: {} })
    const {
      numberSign,
      abstract,
      contentFiles,
      signStep,
      isSaveDraft,
      draftTitle,
    } = this.state.formData
    let errorMsg = {}
    errorMsg.signStep = []
    if (maxLength(numberSign, 50)) {
      errorMsg.numberSign = ValidateForm.MAX_LENGTH(50)
    }
    if (required(numberSign)) {
      errorMsg.numberSign = ValidateForm.IS_REQUIRED
    }
    if (maxLength(abstract, 1000)) {
      errorMsg.abstract = ValidateForm.MAX_LENGTH(1000)
    }
    if (required(abstract)) {
      errorMsg.abstract = ValidateForm.IS_REQUIRED
    }
    if (required(contentFiles[0].name)) {
      errorMsg.contentFiles = []
      errorMsg.contentFiles[0] = ValidateForm.FILE_REQUIRE
    }
    if (
      this.state.referenceProcedure &&
      this.state.referenceSignatureDocument.length === 0
    ) {
      errorMsg.referenceSignatureDocument = []
      errorMsg.referenceSignatureDocument[0] =
        'Vui lòng nhập văn bản tham chiếu'
    }
    signStep.forEach((item, index) => {
      if (!item.memberList.length) {
        errorMsg.signStep[index] = { select: ValidateForm.IS_REQUIRED }
      }
      if (item.xacDinhVungKy === 2 && !item.trangKy) {
        errorMsg.signStep[index] = {
          ...errorMsg.signStep[index],
          location: ValidateForm.IS_REQUIRED,
        }
      }
      if (maxNumber(item.trangKy, 1000)) {
        errorMsg.signStep[index] = {
          ...errorMsg.signStep[index],
          location: ValidateForm.MAX_NUMBER(1000),
        }
      }
      if (minNumber(item.trangKy, 0)) {
        errorMsg.signStep[index] = {
          ...errorMsg.signStep[index],
          location: ValidateForm.MIN_NUMBER(0),
        }
      }
    })
    if (isSaveDraft && required(draftTitle)) {
      errorMsg.draftTitle = ValidateForm.IS_REQUIRED
    }
    if (!errorMsg.signStep.length) {
      delete errorMsg.signStep
    }

    if (!!size(errorMsg)) {
      this.setState({ error: { ...this.state.error, ...errorMsg } })
      return false
    } else {
      return true
    }
  }

  handleSubmit = async e => {
    const { showToast } = this.props
    e.preventDefault()
    await this.setState({
      isWaitingSubmit: true,
    })
    const { contentFiles, attachmentsFiles, referencesFiles } =
      this.state.formData
    const isCheckFormData = this.checkFormValidate()
    if (!isCheckFormData) {
      this.setState(
        {
          isWaitingSubmit: false,
        },
        () => {
          showToast({
            message: Toast.INCOMPLETE,
            intent: Intent.DANGER,
          })
        }
      )
    } else {
      for (const index in contentFiles) {
        if (!contentFiles[index].id && contentFiles[index].origin) {
          await this.uploadFile(
            'contentFiles',
            contentFiles[index].origin,
            parseInt(index, 10)
          )
        }
      }
      for (const index in attachmentsFiles) {
        if (!attachmentsFiles[index].id && attachmentsFiles[index].origin) {
          await this.uploadFile(
            'attachmentsFiles',
            attachmentsFiles[index].origin,
            parseInt(index, 10)
          )
        }
      }
      for (const index in referencesFiles) {
        if (!referencesFiles[index].id && referencesFiles[index].origin) {
          await this.uploadFile(
            'referencesFiles',
            referencesFiles[index].origin,
            parseInt(index, 10)
          )
        }
      }
    }
  }

  convertData = () => {
    const {
      id,
      procedureId,
      numberSign,
      abstract,
      contentFiles,
      attachmentsFiles,
      referencesFiles,
      signStep,
      isPhatHanh,
      isSaveDraft,
      draftTitle,
      departmentSelected,
      departmentParentSelected,
    } = this.state.formData
    const {
      departmentList,
      memberList,
      roleList,
      signatureUnitList,
      phongBanDonViKhac,
    } = this.state

    let ds_buoc = []
    ds_buoc = signStep.map((item, index) => {
      const { memberList: memberSelected, ...newItem } = item
      let danhSachKy = []
      switch (newItem.doiTuongKy) {
        case 0:
          danhSachKy = memberList.filter(i =>
            memberSelected.includes(i.chucDanhId)
          )
          break
        case 1:
          danhSachKy = departmentList.filter(i =>
            memberSelected.includes(i.phongBanId)
          )
          break
        case 2:
        case 3:
          danhSachKy = roleList[index].filter(i =>
            memberSelected.includes(i.chucDanhId)
          )
          break
        case 9:
          danhSachKy = signatureUnitList.filter(i =>
            memberSelected.includes(i.donViDtxdId)
          )
          break
        default:
      }
      danhSachKy = danhSachKy.map(item => this.convertMember(item))
      return {
        ...decamelizeKeys(newItem),
        ds_thanh_vien: danhSachKy,
      }
    })

    let referenceSignatureDocumentId = '',
      referenceSignatureDocumentFileId = ''
    if (
      this.state.referenceSignatureDocument &&
      this.state.referenceSignatureDocument[0]
    ) {
      referenceSignatureDocumentId =
        this.state.referenceSignatureDocument[0].value.vanBanKySoId
      referenceSignatureDocumentFileId =
        this.state.referenceSignatureDocument[0].value.fileId
    }

    return {
      id,
      quy_trinh_id: procedureId,
      so_ky_hieu: numberSign,
      trich_yeu: abstract,
      file_noi_dung: this.convertFile(contentFiles[0]),
      ds_file_dinh_kem: attachmentsFiles
        .filter(item => !!item.id)
        .map(item => this.convertFile(item)),
      ds_file_tham_khao: referencesFiles
        .filter(item => !!item.id)
        .map(item => this.convertFile(item)),
      ds_buoc,
      phat_hanh: isPhatHanh,
      reference_van_ban_id: referenceSignatureDocumentId,
      reference_file_id: referenceSignatureDocumentFileId,
      luu_template: isSaveDraft,
      ten_template: draftTitle,
      ds_phong_ban: departmentList
        .filter(i => departmentSelected.includes(i.phongBanId))
        .map(i => decamelizeKeys(i)),
      ds_phong_ban_don_vi_khac: phongBanDonViKhac,
      ds_phong_ban_cap_tren: departmentParentSelected,
    }
  }

  convertFile = file => ({
    file_id: file.id,
    ten_file: file.name,
    url: file.url,
    kieu_file: file.type,
    kich_thuoc: file.size,
    is_existed: file.esixt,
  })

  convertReverseFile = file => ({
    id: file.fileId,
    name: file.tenFile,
    url: file.url,
    type: file.kieuFile,
    size: file.kichThuoc,
    esixt: true,
    isUploadingFile: false,
  })

  convertMember = ({ id, ...member }) => ({
    ...decamelizeKeys(member),
    thanh_vien_id: id,
  })

  onChangeSignLocation = (e, stepIndex) => {
    const { value } = e.target
    const { formData } = this.state
    this.setState({
      formData: {
        ...formData,
        signStep: formData.signStep.map((item, i) =>
          i === stepIndex ? { ...item, trangKy: parseInt(value, 10) } : item
        ),
      },
    })
  }

  onSelectMember = (e, stepIndex) => {
    const { value } = e.target
    const { formData, error } = this.state
    if (error.signStep) {
      delete error.signStep[stepIndex]
    }
    this.setState({
      formData: {
        ...formData,
        signStep: formData.signStep.map((item, i) =>
          i === stepIndex ? { ...item, memberList: [value] } : item
        ),
      },
    })
  }

  onCheckMember = (e, stepIndex) => {
    const { value } = e.target
    const { formData, error } = this.state
    if (error.signStep) {
      delete error.signStep[stepIndex]
    }
    const existed = formData.signStep[stepIndex].memberList.includes(value)
    this.setState({
      formData: {
        ...formData,
        signStep: formData.signStep.map((item, i) =>
          i === stepIndex
            ? {
                ...item,
                memberList: existed
                  ? item.memberList.filter(item => item !== value)
                  : [...item.memberList, value],
              }
            : item
        ),
      },
    })
  }

  onCheckAll = (e, stepIndex) => {
    const { checked } = e.target
    const {
      formData,
      memberList,
      departmentList,
      roleList,
      error,
      thanhPhanKy,
      signedList,
      stepAllowUpdate,
    } = this.state
    let selectedList = []
    if (error.signStep) {
      delete error.signStep[stepIndex]
    }
    if (checked) {
      if (thanhPhanKy === 0) {
        selectedList = memberList.map(item => item.chucDanhId)
      } else if (formData.signStep[stepIndex].doiTuongKy === 1) {
        selectedList = departmentList.map(item => item.phongBanId)
      } else if (
        formData.signStep[stepIndex].doiTuongKy === 2 ||
        formData.signStep[stepIndex].doiTuongKy === 3
      ) {
        selectedList = roleList[stepIndex].map(item => item.chucDanhId)
      }
    } else if (stepIndex + 1 === stepAllowUpdate) {
      selectedList = signedList
    }
    this.setState({
      formData: {
        ...formData,
        signStep: formData.signStep.map((item, i) =>
          i === stepIndex
            ? {
                ...item,
                memberList: selectedList,
              }
            : item
        ),
      },
    })
  }

  fetchDepartment = async () => {
    const { fetchDepartmentOtherUnit, auth } = this.props
    const unitId = get(auth, 'mainUnitId')
    const departmentId = get(auth, 'mainDepartmentId')
    await this.setState({ loadingDepartment: true })
    fetchDepartmentOtherUnit(PAGE, unitId)
      .then(res => {
        const list = get(res, 'payload.data.result.items', [])
        this.setState({
          departmentList: list.filter(
            i => !!i.phongBanId && i.phongBanId !== departmentId
          ),
        })
      })
      .finally(() => this.setState({ loadingDepartment: false }))
  }

  fetchDepartmentParent = async () => {
    const { fetchDepartmentOtherUnit, auth } = this.props
    const parentUnitId = get(auth, 'parentUnitId')
    await this.setState({ loadingDepartmentParent: true })
    fetchDepartmentOtherUnit(PAGE, parentUnitId)
      .then(res => {
        const readDepartmentParentList = get(res, 'payload.data.result.items')
        this.setState({ readDepartmentParentList })
      })
      .finally(() => this.setState({ loadingDepartmentParent: false }))
  }

  fetchElectricUnits = async () => {
    const { fetchElectricUnit, auth } = this.props
    const unitId = get(auth, 'mainUnitId')
    await this.setState({ loadingElectricUnit: true })
    fetchElectricUnit(unitId)
      .then(res => {
        const list = get(res, 'payload.data.result.items', [])
        this.setState({
          electricUnitList: list.filter(
            i => !!i.donViId && i.donViId !== unitId
          ),
        })
      })
      .finally(() => this.setState({ loadingElectricUnit: false }))
  }

  fetchUnitInfo = async () => {
    const { fetchUnitItem, auth } = this.props
    const unitId = get(auth, 'mainUnitId')
    let isPc = false
    let isSendDocumentToExternalUnit = false
    let isElectricUnit = false
    fetchUnitItem(unitId).then(res => {
      if (res && res.payload && res.payload.data && res.payload.data.result) {
        isPc = res.payload.data.result.isPc
        isSendDocumentToExternalUnit =
          res.payload.data.result.guiCongVanNoiBoLienDonVi
        isElectricUnit = isSendDocumentToExternalUnit && !isPc
      }
      this.setState({
        isAllowShowDepartmentsOfOtherUnits: isPc,
        isAllowShowDepartmentParent: isElectricUnit,
      })
    })
  }

  fetchEmployeeInDepartment = async (role, index) => {
    const { fetchEmployeeInDepartment, auth } = this.props
    const departmentId = get(auth, 'mainDepartmentId')
    const id = parseInt(index, 10)
    await this.setState({
      loadingEmployeeRole: [...this.state.loadingEmployeeRole, id],
    })
    fetchEmployeeInDepartment(departmentId, role)
      .then(res => {
        const memberList = get(res, 'payload.data.result', [])
        this.setState({
          roleList: {
            ...this.state.roleList,
            [index]: memberList,
          },
        })
      })
      .finally(() =>
        this.setState({
          loadingEmployeeRole: this.state.loadingEmployeeRole.filter(
            i => i !== id
          ),
        })
      )
  }

  toggleSelectDepartment = e => {
    const { value } = e.target
    this.setState(prevState => ({
      formData: {
        ...prevState.formData,
        departmentSelected: prevState.formData.departmentSelected.includes(
          value
        )
          ? prevState.formData.departmentSelected.filter(id => id !== value)
          : [...prevState.formData.departmentSelected, value],
      },
    }))
  }

  toggleSelectAllDepartment = () => {
    this.setState(prevState => ({
      formData: {
        ...prevState.formData,
        departmentSelected:
          prevState.formData.departmentSelected.length ===
          prevState.readDepartmentList.length
            ? []
            : prevState.readDepartmentList
                .filter(i => !!i.phongBanId)
                .map(i => i.phongBanId),
      },
    }))
  }

  toggleSelectDepartmentParent = e => {
    const { value } = e.target
    this.setState(prevState => ({
      formData: {
        ...prevState.formData,
        departmentParentSelected:
          prevState.formData.departmentParentSelected.includes(value)
            ? prevState.formData.departmentParentSelected.filter(
                id => id !== value
              )
            : [...prevState.formData.departmentParentSelected, value],
      },
    }))
  }

  toggleSelectAllDepartmentParent = () => {
    this.setState(prevState => ({
      formData: {
        ...prevState.formData,
        departmentParentSelected:
          prevState.formData.departmentParentSelected.length ===
          prevState.readDepartmentParentList.length
            ? []
            : prevState.readDepartmentParentList
                .filter(i => !!i.phongBanId)
                .map(i => i.phongBanId),
      },
    }))
  }

  toggleSelectDepartmentOtherUnit = data => {
    const value = data.phongBanId
    this.setState(prevState => ({
      phongBanDonViKhac: prevState.phongBanDonViKhac.includes(value)
        ? prevState.phongBanDonViKhac.filter(id => id !== value)
        : [...prevState.phongBanDonViKhac, value],
    }))
  }

  toggleShowApplyDraftDialog = () => {
    const { showApplyDraftDialog } = this.state
    this.setState({
      showApplyDraftDialog: !showApplyDraftDialog,
    })
  }

  toggleSaveDraft = () => {
    const { formData, error } = this.state
    if (error.draftTitle) {
      delete error.draftTitle
    }
    this.setState({
      formData: {
        ...formData,
        isSaveDraft: !formData.isSaveDraft,
      },
    })
  }

  toggleAutoFinish = () => {
    const { formData } = this.state
    this.setState({
      formData: {
        ...formData,
        isPhatHanh: !formData.isPhatHanh,
      },
    })
  }

  setTemplate = (data, replace = true) => {
    let { formData, allowUpdate, stepAllowUpdate, signedList } = this.state
    allowUpdate = data.tinhTrang !== 6 && data.tinhTrang !== 7
    if (allowUpdate && data.tinhTrang === 2) {
      stepAllowUpdate = data.buoc
    }
    formData = {
      ...formData,
      id: replace ? data.vanBanKySoId : formData.id,
      procedureId: data.quyTrinhId,
      numberSign: data.soKyHieu,
      abstract: data.trichYeu,
      contentFiles: replace
        ? [
            data.fileNoiDung
              ? this.convertReverseFile(data.fileNoiDung)
              : EMPTY_FILE_INFO,
          ]
        : formData.contentFiles,
      attachmentsFiles: data.dsFileDinhKem.length
        ? data.dsFileDinhKem.map(i => this.convertReverseFile(i))
        : [EMPTY_FILE_INFO],
      referencesFiles: data.dsFileThamKhao.length
        ? data.dsFileThamKhao.map(i => this.convertReverseFile(i))
        : [EMPTY_FILE_INFO],
      departmentSelected: data.dsPhongBan
        ? data.dsPhongBan.map(i => i.phongBanId)
        : [],
      departmentParentSelected: data.dsPhongBanCapTren
        ? data.dsPhongBanCapTren.map(i => i.phongBanId)
        : [],
      departmentOtherUnitSelected: [],
      thanhPhanKy: data.xacDinhThanhPhanKyDuyet,
    }
    if (data.dsBuoc) {
      formData.signStep = data.dsBuoc.map(
        ({ dsThanhVien, ...item }, index) => ({
          ...item,
          memberList: this.dsThanhVienCheck(
            dsThanhVien,
            item.soLuongNguoiKy,
            item.doiTuongKy,
            index
          ),
        })
      )
      for (const item of data.dsBuoc) {
        if (item.buoc !== stepAllowUpdate) {
          continue
        }
        if (item.dsThanhVien) {
          signedList = item.dsThanhVien.filter(
            i => i.trangThai === 1 || i.trangThai === 2
          )
          if (item.soLuongNguoiKy === 1 && signedList.length) {
            stepAllowUpdate += 1
          }
          if (item.doiTuongKy === 1) {
            signedList = signedList.map(i => i.phongBanId)
          } else {
            signedList = signedList.map(i => i.chucDanhId)
          }
        }
      }
    }
    this.setState({
      isLoadingStep: false,
      formData,
      allowUpdate,
      stepAllowUpdate,
      signedList,
    })
  }

  dsThanhVienCheck = (dsThanhVien, soLuongNguoiKy, doiTuongKy, index) => {
    if (dsThanhVien && dsThanhVien.length) {
      if (doiTuongKy === 0) {
        return dsThanhVien.map(i => i.chucDanhId)
      }
      if (doiTuongKy === 1) {
        return dsThanhVien.map(i => i.phongBanId)
      }
      if (doiTuongKy === 2 || doiTuongKy === 3) {
        return dsThanhVien.map(i => i.chucDanhId)
      }
    }

    if (soLuongNguoiKy === 1 && this.state.memberList[0]) {
      if (doiTuongKy === 0) {
        return [this.state.memberList[0].chucDanhId]
      }
      if (doiTuongKy === 1 && this.state.departmentList[0]) {
        return [this.state.departmentList[0].phongBanId]
      }
      if (
        (doiTuongKy === 2 || doiTuongKy === 3) &&
        this.state.roleList[index]
      ) {
        return [this.state.roleList[index].chucDanhId]
      }
    }

    return []
  }

  checkAllowUpdate = (code, step = 1) => {
    switch (code) {
      case 0:
      case 1:
        return true
      case 2:
        return step
      case 6:
      case 7:
        return false
      default:
        return true
    }
  }

  toggleShowPhongBanXDB = () => {
    this.setState(prevState => ({
      showPhongBanXDB: !prevState.showPhongBanXDB,
    }))
  }

  toggleShowDepartmentParent = () => {
    this.setState(prevState => ({
      isShowDepartmentParent: !prevState.isShowDepartmentParent,
    }))
  }

  toggleShowDonViChuyenDen = () => {
    this.setState(prevState => ({
      isChooseDepartmentOtherUnits: !prevState.isChooseDepartmentOtherUnits,
    }))
  }

  fetchReadDepartment = async () => {
    try {
      const response = await this.props.fetchReadDepartment()
      const readDepartmentList = get(response, 'payload.data.result.items')
      this.setState({ readDepartmentList })
    } catch (err) {
      throw err
    }
  }

  async componentDidMount() {
    const { isEdit } = this.props
    this.fetchReadDepartment()
    this.fetchDepartmentParent()
    this.fetchElectricUnits()
    this.fetchUnitInfo()
    await this.fetchDepartment()
    if (isEdit) {
      await this.setState({ isLoadingData: true }).finally(() =>
        this.setState({ isLoadingData: false })
      )
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      this.state.thanhPhanKy === 0 &&
      this.state.memberList !== prevState.memberList
    ) {
      this.setState({
        formData: {
          ...this.state.formData,
          signStep: this.state.formData.signStep.map(i => {
            if (
              i.doiTuongKy === 0 &&
              i.soLuongNguoiKy === 1 &&
              this.state.memberList.length &&
              !i.memberList.length
            ) {
              return { ...i, memberList: [this.state.memberList[0].chucDanhId] }
            }
            return i
          }),
        },
      })
    }
    if (
      this.state.thanhPhanKy === 1 &&
      this.state.departmentList !== prevState.departmentList
    ) {
      this.setState({
        formData: {
          ...this.state.formData,
          signStep: this.state.formData.signStep.map(i => {
            if (
              i.doiTuongKy === 1 &&
              i.soLuongNguoiKy === 1 &&
              this.state.departmentList.length &&
              !i.memberList.length
            ) {
              return {
                ...i,
                memberList: [this.state.departmentList[0].phongBanId],
              }
            }
            return i
          }),
        },
      })
    }
    if (
      this.state.thanhPhanKy === 1 &&
      this.state.roleList !== prevState.roleList
    ) {
      this.setState({
        formData: {
          ...this.state.formData,
          signStep: this.state.formData.signStep.map((i, index) => {
            if (
              (i.doiTuongKy === 2 || i.doiTuongKy === 3) &&
              i.soLuongNguoiKy === 1 &&
              this.state.roleList[index] &&
              this.state.roleList[index].length &&
              !i.memberList.length
            ) {
              return {
                ...i,
                memberList: [this.state.roleList[index][0].chucDanhId],
              }
            }
            return i
          }),
        },
      })
    }
    if (this.state.thanhPhanKy !== prevState.thanhPhanKy) {
      this.setState({
        formData: {
          ...this.state.formData,
          signStep: this.state.formData.signStep.map((i, index) => {
            if (
              i.doiTuongKy === 1 &&
              i.soLuongNguoiKy === 1 &&
              this.state.departmentList.length &&
              !i.memberList.length
            ) {
              return {
                ...i,
                memberList: [this.state.departmentList[0].phongBanId],
              }
            }
            if (
              (i.doiTuongKy === 2 || i.doiTuongKy === 3) &&
              i.soLuongNguoiKy === 1 &&
              this.state.roleList[index] &&
              this.state.roleList[index].length &&
              !i.memberList.length
            ) {
              return {
                ...i,
                memberList: [this.state.roleList[index][0].chucDanhId],
              }
            }
            return i
          }),
        },
      })
    }
  }

  render() {
    const {
      formData,
      isLoadingData,
      isWaitingSubmit,
      error,
      isLoadingStep,
      isLoadingMember,
      memberList,
      memberGroupList,
      signatureUnitList,
      isLoadingDataError,
      isEmptyProcedure,
      isAutoFinish,
      helpDocumentId,
      allowUpdate,
      stepAllowUpdate,
      departmentList,
      electricUnitList,
      loadingDepartment,
      loadingDepartmentParent,
      loadingElectricUnit,
      thanhPhanKy,
      tenQuyTrinh,
      roleList,
      loadingEmployeeRole,
      showPhongBanXDB,
      isShowDepartmentParent,
      isChooseDepartmentOtherUnits,
      signedList,
      readDepartmentList,
      readDepartmentParentList,
      fileTrinhKy,
      isOpenDialog,
      isLoadingPreview,
    } = this.state
    const { auth } = this.props
    const {
      numberSign,
      abstract,
      contentFiles,
      attachmentsFiles,
      referencesFiles,
    } = formData
    const disableButton =
      isWaitingSubmit ||
      contentFiles[0].isUploadingFile ||
      attachmentsFiles.map(i => i.isUploadingFile).includes(true) ||
      referencesFiles.map(i => i.isUploadingFile).includes(true)
    const disableSubmit =
      isLoadingData ||
      isLoadingStep ||
      isLoadingMember ||
      loadingDepartment ||
      loadingElectricUnit ||
      loadingEmployeeRole.length ||
      isEmptyProcedure
    const token = get(auth, 'token.accessToken', '')
    let disableButtonPreview = true
    if (
      contentFiles[0] &&
      contentFiles[0].size !== 0 &&
      isEmpty(contentFiles[0].origin)
    ) {
      disableButtonPreview = false
    }

    return (
      <div className="form-container signature-document-form">
        {isLoadingDataError ? (
          <div className="error-page">
            <ErrorLoadingTable />
          </div>
        ) : (
          <form className="main-form systerm-form ">
            <div className="form-block">
              <div className="form-subblock no-max-width">
                <div className="form-row">
                  <div className="form-cols form-col-full">
                    <div className="pt-form-group">
                      <label className="pt-label">Quy Trình</label>
                      <div className="form-row procedure-block">
                        <div className="form-cols procedure-left">
                          <div
                            className={classnames('input-form pt-form-content')}
                          >
                            <input
                              type="text"
                              name="numberSign"
                              value={tenQuyTrinh}
                              className={classnames('pt-input', {
                                'loading-input': isLoadingData,
                              })}
                              disabled
                            />
                          </div>
                        </div>
                        <div className="form-cols procedure-right">
                          {!!helpDocumentId && !!token && (
                            <a
                              target="_blank"
                              rel="noopener noreferrer"
                              href={`${FILE_URL}/api/file/get/${helpDocumentId}?BearerToken=${token}`}
                            >
                              <Button className="help-document">
                                <i className="pt-icon icon-document" />
                                Tài liệu hướng dẫn
                              </Button>
                            </a>
                          )}
                          <Button
                            className="template"
                            onClick={this.toggleShowApplyDraftDialog}
                            disabled={disableButton || disableSubmit}
                          >
                            <i className="pt-icon icon-cloud" />
                            Tải từ mẫu có sẵn
                          </Button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="form-block">
              <div className="form-subblock no-max-width">
                <div className="form-row">
                  <div className="form-cols form-col5">
                    <div className="pt-form-group">
                      <label className="pt-label">
                        Số ký hiệu
                        <span className="pt-text-muted required-input">*</span>
                      </label>
                      <div className="input-form pt-form-content">
                        <input
                          type="text"
                          name="numberSign"
                          value={numberSign || ''}
                          onChange={this.handleInputChange}
                          className={`pt-input ${
                            isLoadingData ? 'loading-input' : ''
                          }`}
                          maxLength="50"
                          placeholder="Nhập số ký hiệu"
                          disabled={isLoadingData}
                        />
                        {!!error.numberSign && (
                          <div className="pt-form-helper-text">
                            {error.numberSign}
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="form-row">
                  <div className="form-cols form-col-full">
                    <div className="pt-form-group">
                      <label className="pt-label">
                        Trích yếu
                        <span className="pt-text-muted required-input">*</span>
                      </label>
                      <div className="input-form pt-form-content">
                        <textarea
                          className={classnames('pt-input input-area', {
                            'loading-input': isLoadingData,
                          })}
                          name="abstract"
                          onChange={this.handleInputChange}
                          value={abstract || ''}
                          placeholder="Nhập trích yếu"
                          maxLength="1000"
                          disabled={isLoadingData}
                        />
                        {!!error.abstract && (
                          <div className="pt-form-helper-text">
                            {error.abstract}
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="form-row">
                  <div className="form-cols form-col-full">
                    <div className="pt-form-group">
                      <label className="pt-label">
                        File nội dung trình ký
                        <span className="pt-text-muted required-input">*</span>
                      </label>
                      <div className="input-form pt-form-content">
                        <label className="pt-file-upload">
                          <input
                            type="file"
                            name="contentFiles"
                            onChange={e => this.handleFileChange(e, 0)}
                            onClick={e => {
                              e.target.value = null
                            }}
                            className="pt-input"
                            disabled={
                              isLoadingData ||
                              contentFiles[0].isUploadingFile ||
                              isWaitingSubmit
                            }
                          />
                          <span className="pt-file-upload-input">
                            {contentFiles[0].name ? (
                              <span className="has-file-input">
                                {contentFiles[0].name}
                              </span>
                            ) : (
                              'Chọn file đính kèm'
                            )}
                          </span>
                        </label>
                        {!!contentFiles[0].isUploadingFile && (
                          <div className="pt-progress-bar pt-intent-primary progress-bar-upload">
                            <div className="pt-progress-meter" />
                          </div>
                        )}
                        {!!contentFiles[0].size && (
                          <p className="pt-form-helper-text pt-form-helper-infomation">
                            Kích thước file:{' '}
                            <span className="size-file">
                              {Utils.getFileSize(contentFiles[0].size)}
                            </span>
                          </p>
                        )}
                        {!!error.contentFiles && !!error.contentFiles[0] && (
                          <div className="pt-form-helper-text">
                            {error.contentFiles[0]}
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="form-row">
                  <div className="form-cols form-col5">
                    <div className="pt-form-group">
                      <label className="pt-label">File đính kèm</label>
                      {attachmentsFiles.map((item, index) => (
                        <div className="pt-input-file" key={index}>
                          <label className="pt-file-upload">
                            <input
                              type="file"
                              name="attachmentsFiles"
                              onChange={e => this.handleFileChange(e, index)}
                              className="pt-input"
                              disabled={
                                item.isUploadingFile ||
                                isLoadingData ||
                                isWaitingSubmit
                              }
                              onClick={e => {
                                e.target.value = null
                              }}
                            />
                            <span className="pt-file-upload-input">
                              {item.name && !isWaitingSubmit ? (
                                <span className="has-file-input">
                                  {item.name}
                                </span>
                              ) : (
                                'Chọn file đính kèm'
                              )}
                            </span>
                          </label>
                          {(!!item.name || attachmentsFiles.length > 1) &&
                            !isWaitingSubmit &&
                            !item.isUploadingFile && (
                              <span
                                className="icon-bc icon-close icon-remove-file"
                                onClick={() =>
                                  this.removeFile(
                                    'attachmentsFiles',
                                    index,
                                    attachmentsFiles.length === 1
                                  )
                                }
                              />
                            )}
                          {!!item.isUploadingFile && (
                            <div className="pt-progress-bar pt-intent-primary progress-bar-upload">
                              <div className="pt-progress-meter" />
                            </div>
                          )}
                          {!!item.size && (
                            <p className="pt-form-helper-text pt-form-helper-infomation">
                              Kích thước file:{' '}
                              <span className="size-file">
                                {Utils.getFileSize(item.size)}
                              </span>
                            </p>
                          )}
                          {!!get(error, 'attachmentsFiles[index]') && (
                            <div className="pt-form-helper-text">
                              {error.attachmentsFiles[index]}
                            </div>
                          )}
                          {error.attachmentsFiles && (
                            <div className="pt-form-helper-text">
                              {error.attachmentsFiles[index]}
                            </div>
                          )}
                        </div>
                      ))}
                      <button
                        type="button"
                        className="pt-button btn-add-more"
                        onClick={() => this.addInputFile('attachmentsFiles')}
                      >
                        <span className="text-content">Thêm file đính kèm</span>
                        <span className="icon-bc icon-Plus" />
                      </button>
                    </div>
                  </div>
                  <div className="form-cols form-col5">
                    <div className="pt-form-group">
                      <label className="pt-label">File tham khảo</label>
                      {referencesFiles.map((item, index) => (
                        <div className="pt-input-file" key={index}>
                          <label className="pt-file-upload">
                            <input
                              type="file"
                              name="referencesFiles"
                              onChange={e => this.handleFileChange(e, index)}
                              className="pt-input"
                              disabled={
                                item.isUploadingFile ||
                                isLoadingData ||
                                isWaitingSubmit
                              }
                            />
                            <span className="pt-file-upload-input">
                              {item.name ? (
                                <span className="has-file-input">
                                  {item.name}
                                </span>
                              ) : (
                                'Chọn file đính kèm'
                              )}
                            </span>
                          </label>
                          {(!!item.name || referencesFiles.length > 1) &&
                            !isWaitingSubmit &&
                            !item.isUploadingFile && (
                              <span
                                className="icon-bc icon-close icon-remove-file"
                                onClick={() =>
                                  this.removeFile(
                                    'referencesFiles',
                                    index,
                                    referencesFiles.length === 1
                                  )
                                }
                              />
                            )}
                          {!!item.isUploadingFile && (
                            <div className="pt-progress-bar pt-intent-primary progress-bar-upload">
                              <div className="pt-progress-meter" />
                            </div>
                          )}
                          {!!item.size && (
                            <p className="pt-form-helper-text pt-form-helper-infomation">
                              Kích thước file:{' '}
                              <span className="size-file">
                                {Utils.getFileSize(item.size)}
                              </span>
                            </p>
                          )}
                          {error.referencesFiles && (
                            <div className="pt-form-helper-text">
                              {error.referencesFiles[index]}
                            </div>
                          )}
                        </div>
                      ))}
                      <button
                        type="button"
                        className="pt-button btn-add-more"
                        onClick={() => this.addInputFile('referencesFiles')}
                      >
                        <span className="text-content">
                          Thêm file tham khảo
                        </span>
                        <span className="icon-bc icon-Plus" />
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              {isAutoFinish && (
                <div className="form-row">
                  <div className="form-cols form-col5">
                    <div className="pt-form-group">
                      <label className="pt-label">
                        <Checkbox
                          name="auto_finish"
                          onChange={this.toggleAutoFinish}
                          checked={formData.isPhatHanh}
                          disabled={disableButton || disableSubmit}
                          className="m0"
                        >
                          Phát hành ngay sau khi trình (không thực hiện ký số)
                        </Checkbox>
                      </label>
                    </div>
                  </div>
                </div>
              )}
              {!isEmptyProcedure && (
                <div className="form-sub-step">
                  <div className="form-row form-sub-title">
                    <div className="form-cols">
                      <label className="pt-label">QUY TRÌNH KÝ DUYỆT</label>
                    </div>
                  </div>
                  {isLoadingStep ? (
                    <LoadingTable />
                  ) : (
                    <div className="form-row form-sub-body">
                      {formData.signStep.map((item, index) => {
                        let loading = isLoadingMember
                        if (item.doiTuongKy === 1) {
                          loading = loadingDepartment
                        } else if (
                          item.doiTuongKy === 2 ||
                          item.doiTuongKy === 3
                        ) {
                          loading = loadingEmployeeRole.includes(index)
                        }
                        if (item.soLuongNguoiKy === 1) {
                          if (item.doiTuongKy === 0) {
                            return (
                              <SingleMemberSelectGroup
                                key={item.buocId}
                                data={item}
                                isLoadingMember={loading}
                                memberGroupList={memberGroupList}
                                selectChange={e =>
                                  this.onSelectMember(e, index)
                                }
                                inputChange={e =>
                                  this.onChangeSignLocation(e, index)
                                }
                                disabled={
                                  !allowUpdate || index < stepAllowUpdate - 1
                                }
                                error={
                                  error.signStep ? error.signStep[index] : null
                                }
                                thanhPhanKy={thanhPhanKy}
                                isWaitingSubmit={isWaitingSubmit}
                              />
                            )
                          } else {
                            return (
                              <SingleMemberSelect
                                key={item.buocId}
                                data={item}
                                isLoadingMember={loading}
                                memberList={memberList}
                                signatureUnitList={signatureUnitList}
                                selectChange={e =>
                                  this.onSelectMember(e, index)
                                }
                                inputChange={e =>
                                  this.onChangeSignLocation(e, index)
                                }
                                disabled={
                                  !allowUpdate || index < stepAllowUpdate - 1
                                }
                                error={
                                  error.signStep ? error.signStep[index] : null
                                }
                                departmentList={departmentList}
                                thanhPhanKy={thanhPhanKy}
                                roleList={roleList[index]}
                                isWaitingSubmit={isWaitingSubmit}
                              />
                            )
                          }
                        }
                        if (item.soLuongNguoiKy === 2) {
                          if (item.doiTuongKy === 0) {
                            return (
                              <MultipleMemberSelectGroup
                                key={item.buocId}
                                data={item}
                                isLoadingMember={loading}
                                memberGroupList={memberGroupList}
                                onCheck={e => this.onCheckMember(e, index)}
                                onCheckAll={e => this.onCheckAll(e, index)}
                                inputChange={e =>
                                  this.onChangeSignLocation(e, index)
                                }
                                error={
                                  error.signStep ? error.signStep[index] : null
                                }
                                disabled={
                                  !allowUpdate || index < stepAllowUpdate - 1
                                }
                                thanhPhanKy={thanhPhanKy}
                                isWaitingSubmit={isWaitingSubmit}
                                signedList={
                                  index < stepAllowUpdate ? signedList : []
                                }
                              />
                            )
                          } else {
                            return (
                              <MultipleMemberSelect
                                key={item.buocId}
                                data={item}
                                isLoadingMember={loading}
                                memberList={memberList}
                                onCheck={e => this.onCheckMember(e, index)}
                                onCheckAll={e => this.onCheckAll(e, index)}
                                inputChange={e =>
                                  this.onChangeSignLocation(e, index)
                                }
                                error={
                                  error.signStep ? error.signStep[index] : null
                                }
                                disabled={
                                  !allowUpdate || index < stepAllowUpdate - 1
                                }
                                departmentList={departmentList}
                                thanhPhanKy={thanhPhanKy}
                                roleList={roleList[index]}
                                isWaitingSubmit={isWaitingSubmit}
                                signedList={
                                  index < stepAllowUpdate ? signedList : []
                                }
                              />
                            )
                          }
                        }
                        return null
                      })}
                      <PhongBanXDB
                        list={readDepartmentList}
                        selected={formData.departmentSelected}
                        toggleSelect={this.toggleSelectDepartment}
                        toggleSelectAll={this.toggleSelectAllDepartment}
                        loading={loadingDepartment}
                        showContent={showPhongBanXDB}
                        toggleShowContent={this.toggleShowPhongBanXDB}
                      />
                      {/* Chuyển đến phòng ban cấp trên (PC)*/}
                      {this.state.isAllowShowDepartmentParent === true && (
                        <PhongBanListGroup
                          title="Phòng ban cấp trên (PC) chuyển đến"
                          list={readDepartmentParentList}
                          selected={formData.departmentParentSelected}
                          toggleSelect={this.toggleSelectDepartmentParent}
                          toggleSelectAll={this.toggleSelectAllDepartmentParent}
                          loading={loadingDepartmentParent}
                          showContent={isShowDepartmentParent}
                          toggleShowContent={this.toggleShowDepartmentParent}
                        />
                      )}
                      {/* Chuyển đến phòng ban ở đơn vị khác */}
                      {this.state.isAllowShowDepartmentsOfOtherUnits ===
                        true && (
                        <div
                          className={classnames(
                            'form-container sign-step-new-style'
                          )}
                        >
                          <div className="form-row form-header">
                            <div className="form-cols form-col-full">
                              <div className="form-cols p0">
                                <label className="pt-label">
                                  Đơn vị chuyển đến
                                  <span
                                    className={classnames(
                                      'pt-icon  collapse-btn',
                                      {
                                        'pt-icon-chevron-up':
                                          isChooseDepartmentOtherUnits,
                                        'pt-icon-chevron-down':
                                          !isChooseDepartmentOtherUnits,
                                      }
                                    )}
                                    onClick={this.toggleShowDonViChuyenDen}
                                    style={{
                                      cursor: 'pointer',
                                      marginLeft: '5px',
                                    }}
                                  />
                                </label>
                              </div>
                            </div>
                          </div>

                          <Collapse
                            isOpen={this.state.isChooseDepartmentOtherUnits}
                          >
                            <DonViChuyenDen
                              data={electricUnitList}
                              requestApi={this.props.fetchDepartmentOtherUnit}
                              onCheckDepartment={
                                this.toggleSelectDepartmentOtherUnit
                              }
                              selectedList={this.state.phongBanDonViKhac}
                              selectedListObject={
                                this.state.selectedDepartmentOtherUnits
                              }
                              disabledAllList={false}
                              isShowUnitCode={false}
                              isCanShowCompositive={false}
                              isLoadingData={loadingElectricUnit}
                              onResetDepartmentOtherUnit={
                                this.handleResetDepartmentOtherUnit
                              }
                            />
                          </Collapse>
                        </div>
                      )}
                    </div>
                  )}
                </div>
              )}
              <div className="form-sub-step">
                <div className="form-row form-sub-title">
                  <div className="form-cols form-col-full save-draft-col">
                    <label className="pt-label">
                      <Checkbox
                        name="save_draft"
                        onChange={this.toggleSaveDraft}
                        checked={formData.isSaveDraft}
                        disabled={disableButton || disableSubmit}
                        className="m0"
                      >
                        LƯU MẪU
                      </Checkbox>
                    </label>
                    {formData.isSaveDraft && (
                      <div className="pt-form-group m0 form-col-full">
                        <input
                          type="text"
                          name="draftTitle"
                          className="pt-input"
                          placeholder="Nhập lưu mẫu"
                          maxLength="255"
                          value={formData.draftTitle}
                          onChange={this.handleInputChange}
                        />
                        {!!error.draftTitle && (
                          <div className="pt-form-helper-text">
                            {error.draftTitle}
                          </div>
                        )}
                      </div>
                    )}
                  </div>
                </div>
              </div>
              <div className="buttton-action-footer">
                <GoBackButton disabled={disableButton} />
                <Button
                  className="pt-button btn-blue-color"
                  onClick={this.handlePreview}
                  disabled={
                    disableButton || disableSubmit || disableButtonPreview
                  }
                  loading={isWaitingSubmit || isLoadingPreview}
                >
                  <span className="text-content">Preview</span>
                  <span className="pt-icon icon-Xemthuchien font-size-10"></span>
                </Button>
                <Button
                  type="button"
                  className="btn-blue-color"
                  loading={isWaitingSubmit}
                  onClick={this.handleSubmit}
                  disabled={disableButton || disableSubmit}
                >
                  <span className="text-content">Lưu</span>
                  <i className="pt-icon icon-save" />
                </Button>
              </div>
            </div>
          </form>
        )}
        <PrevewFileTrinhKy
          isOpenDialog={isOpenDialog}
          closeDialog={this.handlePreview}
          file={fileTrinhKy}
        />
      </div>
    )
  }
}

const SingleMemberSelect = props => (
  <div
    className={classnames('form-container sign-step-new-style', {
      disabled: props.disabled,
    })}
  >
    <div className="form-row form-body">
      <div className="form-cols form-col5">
        <div className="form-header">
          <label className="pt-label">
            {props.data.dinhNghia}
            {props.data.xacDinhVungKy === 0 && props.data.thongTinVungKy && (
              <span className="text-regular-label">
                {' '}
                (Trường ký là{' '}
                <span className="text-bold-label">
                  {props.data.thongTinVungKy}
                </span>
                )
              </span>
            )}
          </label>
        </div>
        <div className="pt-form-group">
          <div
            className={classnames('pt-select pt-form-content', {
              'loading-input': props.isLoadingMember,
            })}
          >
            <select
              name="member"
              value={props.data.memberList ? props.data.memberList[0] : '-1'}
              className="pt-input"
              disabled={props.isLoadingMember || props.isWaitingSubmit}
              onChange={props.selectChange}
            >
              {props.thanhPhanKy === 0 &&
                !!props.memberList &&
                props.memberList.map((item, index) => (
                  <option value={item.chucDanhId} key={index}>
                    {item.tenNhanVien}
                  </option>
                ))}
              {props.thanhPhanKy !== 0 &&
                props.data.doiTuongKy === 1 &&
                !!props.departmentList &&
                props.departmentList.map((item, index) => (
                  <option value={item.phongBanId} key={index}>
                    {item.maPhongBan}
                  </option>
                ))}
              {props.thanhPhanKy !== 0 &&
                (props.data.doiTuongKy === 2 || props.data.doiTuongKy === 3) &&
                !!props.roleList &&
                props.roleList.map((item, index) => (
                  <option value={item.chucDanhId} key={index}>
                    {item.tenNhanVien}
                  </option>
                ))}
              {props.thanhPhanKy !== 0 &&
                props.data.doiTuongKy === 9 &&
                !!props.signatureUnitList &&
                props.signatureUnitList.map((item, index) => (
                  <option value={item.donViDtxdId} key={index}>
                    {item.ten}
                  </option>
                ))}
            </select>
          </div>
          <div className="form-row">
            <div className="form-cols p0">
              {!!props.error && !!props.error.select && (
                <div className="pt-form-helper-text">{props.error.select}</div>
              )}
            </div>
          </div>
        </div>
      </div>
      {props.data.xacDinhVungKy === 2 && (
        <div className="form-cols form-col5">
          <div className="pt-form-group">
            <div className="input-form pt-form-content">
              <label className="pt-label">Vị trí hiển thị chữ ký</label>
              <input
                type="text"
                name="signatureLocation"
                onChange={props.inputChange}
                value={props.data.trangKy || ''}
                className={'pt-input'}
                maxLength="255"
                placeholder="Nhập số trang"
                disabled={props.isWaitingSubmit}
              />
              <div className="form-cols p0">
                {!!props.error && !!props.error.location && (
                  <div className="pt-form-helper-text">
                    {props.error.location}
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  </div>
)

const SingleMemberSelectGroup = props => (
  <div
    className={classnames('form-container sign-step-new-style', {
      disabled: props.disabled,
    })}
  >
    <div className="form-row form-body">
      <div className="form-cols form-col5">
        <div className="form-header">
          <label className="pt-label">
            {props.data.dinhNghia}
            {props.data.xacDinhVungKy === 0 && props.data.thongTinVungKy && (
              <span className="text-regular-label">
                {' '}
                (Trường ký là{' '}
                <span className="text-bold-label">
                  {props.data.thongTinVungKy}
                </span>
                )
              </span>
            )}
          </label>
        </div>
        <div className="pt-form-group">
          <div
            className={classnames('pt-select pt-form-content', {
              'loading-input': props.isLoadingMember,
            })}
          >
            <select
              name="member"
              value={props.data.memberList ? props.data.memberList[0] : '-1'}
              className="pt-input"
              disabled={props.isLoadingMember || props.isWaitingSubmit}
              onChange={props.selectChange}
            >
              {props.thanhPhanKy === 0 &&
                !!props.memberGroupList &&
                props.memberGroupList.map((group, i) => (
                  <optgroup label={group.tenVietTat} key={i}>
                    {group.dsThanhVien.map((item, index) => (
                      <option value={item.chucDanhId} key={index}>
                        {item.tenNhanVien} ({item.maPhongBan})
                      </option>
                    ))}
                  </optgroup>
                ))}
            </select>
          </div>
          <div className="form-row">
            <div className="form-cols p0">
              {!!props.error && !!props.error.select && (
                <div className="pt-form-helper-text">{props.error.select}</div>
              )}
            </div>
          </div>
        </div>
      </div>
      {props.data.xacDinhVungKy === 2 && (
        <div className="form-cols form-col5">
          <div className="pt-form-group">
            <div className="input-form pt-form-content">
              <label className="pt-label">Vị trí hiển thị chữ ký</label>
              <input
                type="text"
                name="signatureLocation"
                onChange={props.inputChange}
                value={props.data.trangKy || ''}
                className={'pt-input'}
                maxLength="255"
                placeholder="Nhập số trang"
                disabled={props.isWaitingSubmit}
              />
              <div className="form-cols p0">
                {!!props.error && !!props.error.location && (
                  <div className="pt-form-helper-text">
                    {props.error.location}
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  </div>
)

const MultipleMemberSelect = props => {
  let checkedAll = false

  if (
    props.data.doiTuongKy === 0 &&
    props.data.memberList &&
    props.memberList &&
    props.memberList
  ) {
    checkedAll = props.data.memberList.length === props.memberList.length
  }
  if (
    props.data.doiTuongKy === 1 &&
    props.data.memberList &&
    props.departmentList &&
    props.departmentList
  ) {
    checkedAll = props.data.memberList.length === props.departmentList.length
  }
  if (
    (props.data.doiTuongKy === 2 || props.data.doiTuongKy === 3) &&
    props.data.memberList &&
    props.roleList &&
    props.roleList.length
  ) {
    checkedAll = props.data.memberList.length === props.roleList.length
  }

  return (
    <div
      className={classnames('form-container sign-step-new-style', {
        disabled: props.disabled,
      })}
    >
      <div className="form-row form-header">
        <div className="form-cols form-col-full">
          <label className="pt-label">
            <span>{props.data.dinhNghia}</span>
            <Checkbox
              name="selectAll"
              checked={checkedAll}
              onChange={props.onCheckAll}
              disabled={props.isWaitingSubmit}
              className="select-all-checkbox"
            >
              Chọn tất cả
            </Checkbox>
          </label>
        </div>
      </div>
      <div className="form-row form-body">
        <div className="form-cols form-col-full">
          <div className="pt-form-group">
            <div className="select-group-container">
              {props.isLoadingMember ? (
                <LoadingTable style={{ padding: 0, width: '100%' }} />
              ) : props.thanhPhanKy === 0 ? (
                props.memberList &&
                props.memberList.map((item, index) => (
                  <div
                    name="member"
                    className={classnames('member-checkbox', {
                      disabled: props.signedList.includes(item.chucDanhId),
                    })}
                    key={index}
                  >
                    <Checkbox
                      checked={
                        props.data.memberList
                          ? props.data.memberList.includes(item.chucDanhId)
                          : false
                      }
                      value={item.chucDanhId}
                      onChange={props.onCheck}
                      disabled={
                        props.isWaitingSubmit ||
                        props.signedList.includes(item.chucDanhId)
                      }
                    >
                      {item.tenNhanVien}
                    </Checkbox>
                  </div>
                ))
              ) : props.data.doiTuongKy === 1 ? (
                props.departmentList &&
                props.departmentList.map((item, index) => (
                  <div
                    name="member"
                    className={classnames('member-checkbox', {
                      disabled: props.signedList.includes(item.phongBanId),
                    })}
                    key={index}
                  >
                    <Checkbox
                      checked={
                        props.data.memberList
                          ? props.data.memberList.includes(item.phongBanId)
                          : false
                      }
                      value={item.phongBanId}
                      onChange={props.onCheck}
                      disabled={
                        props.isWaitingSubmit ||
                        props.signedList.includes(item.phongBanId)
                      }
                    >
                      {item.maPhongBan}
                    </Checkbox>
                  </div>
                ))
              ) : (
                (props.data.doiTuongKy === 2 || props.data.doiTuongKy === 3) &&
                props.roleList &&
                props.roleList.map((item, index) => (
                  <div
                    name="member"
                    className={classnames('member-checkbox', {
                      disabled: props.signedList.includes(item.chucDanhId),
                    })}
                    key={index}
                  >
                    <Checkbox
                      checked={
                        props.data.memberList
                          ? props.data.memberList.includes(item.chucDanhId)
                          : false
                      }
                      value={item.chucDanhId}
                      onChange={props.onCheck}
                      disabled={
                        props.isWaitingSubmit ||
                        props.signedList.includes(item.chucDanhId)
                      }
                    >
                      {item.tenNhanVien}
                    </Checkbox>
                  </div>
                ))
              )}
            </div>
            <div className="form-row">
              <div className="form-cols p0">
                {!!props.error && !!props.error.select && (
                  <div className="pt-form-helper-text">
                    {props.error.select}
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="form-row form-body">
        {props.data.xacDinhVungKy === 2 && (
          <div className="form-cols form-col5">
            <div className="pt-form-group">
              <div className="input-form pt-form-content">
                <label className="pt-label">Vị trí hiển thị chữ ký</label>
                <input
                  type="text"
                  name="signatureLocation"
                  onChange={props.inputChange}
                  value={props.data.trangKy || ''}
                  className={'pt-input'}
                  maxLength="255"
                  placeholder="Nhập số trang"
                  disabled={props.isWaitingSubmit}
                />
                <div className="form-cols p0">
                  {!!props.error && !!props.error.location && (
                    <div className="pt-form-helper-text">
                      {props.error.location}
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  )
}

const MultipleMemberSelectGroup = props => {
  let checkedAll = false

  if (
    props.data.doiTuongKy === 0 &&
    props.data.memberGroupList &&
    props.memberGroupList
  ) {
    checkedAll =
      props.data.memberGroupList.length === props.memberGroupList.length
  }

  return (
    <div
      className={classnames('form-container sign-step-new-style', {
        disabled: props.disabled,
      })}
    >
      <div className="form-row form-header">
        <div className="form-cols form-col-full">
          <label className="pt-label">
            <span>{props.data.dinhNghia}</span>
            <Checkbox
              name="selectAll"
              checked={checkedAll}
              onChange={props.onCheckAll}
              disabled={props.isWaitingSubmit}
              className="select-all-checkbox"
            >
              Chọn tất cả
            </Checkbox>
          </label>
        </div>
      </div>
      <div className="form-row form-body">
        <div className="form-cols form-col-full">
          <div className="pt-form-group">
            {props.isLoadingMember ? (
              <LoadingTable style={{ padding: 0, width: '100%' }} />
            ) : (
              props.memberGroupList &&
              props.memberGroupList.map((group, i) => (
                <fieldset
                  className="select-group-container select-fieldset"
                  key={i}
                >
                  <legend>{group.tenVietTat}</legend>
                  {group.dsThanhVien.map((item, index) => (
                    <div
                      name="member"
                      className={classnames('member-checkbox', {
                        disabled: props.signedList.includes(item.chucDanhId),
                      })}
                      key={index}
                    >
                      <Checkbox
                        checked={
                          props.data.memberList
                            ? props.data.memberList.includes(item.chucDanhId)
                            : false
                        }
                        value={item.chucDanhId}
                        onChange={props.onCheck}
                        disabled={
                          props.isWaitingSubmit ||
                          props.signedList.includes(item.chucDanhId)
                        }
                      >
                        {item.tenNhanVien} ({item.maPhongBan})
                      </Checkbox>
                    </div>
                  ))}
                </fieldset>
              ))
            )}
            <div className="form-row">
              <div className="form-cols p0">
                {!!props.error && !!props.error.select && (
                  <div className="pt-form-helper-text">
                    {props.error.select}
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="form-row form-body">
        {props.data.xacDinhVungKy === 2 && (
          <div className="form-cols form-col5">
            <div className="pt-form-group">
              <div className="input-form pt-form-content">
                <label className="pt-label">Vị trí hiển thị chữ ký</label>
                <input
                  type="text"
                  name="signatureLocation"
                  onChange={props.inputChange}
                  value={props.data.trangKy || ''}
                  className={'pt-input'}
                  maxLength="255"
                  placeholder="Nhập số trang"
                  disabled={props.isWaitingSubmit}
                />
                <div className="form-cols p0">
                  {!!props.error && !!props.error.location && (
                    <div className="pt-form-helper-text">
                      {props.error.location}
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  )
}

const mapStateToProps = state => ({
  auth: state.auth,
})

const mapDispatchToProps = dispatch => ({
  showToast: bindActionCreators(commonAddToasterMessage, dispatch),
  uploadFile: bindActionCreators(fileUpload, dispatch),
  fetchDepartment: bindActionCreators(contactFetchDepartmentByUnit, dispatch),
  fetchDepartmentOtherUnit: bindActionCreators(
    commonFetchDepartments,
    dispatch
  ),
  fetchElectricUnit: bindActionCreators(commonFetchElectricUnits, dispatch),
  fetchUnitItem: bindActionCreators(getCommonFetchUnitItem, dispatch),
  fetchReadDepartment: bindActionCreators(fetchReadDepartment, dispatch),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(SignatureDocumentForm))
