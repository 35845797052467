import React, { useEffect, useState, useCallback } from 'react'
import { Button, Checkbox, Intent } from '@blueprintjs/core'
import { MasterLayout } from 'components/layout'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import * as Actions from 'actions'
import Title from 'components/ui/title'
import { SelectTree } from 'components/common'

const ThemMoiDiaDiemHopPage = props => {
  const id = props?.match?.params?.id
  const actions = props?.actions
  const mainUnitId = props?.auth.mainUnitId
  const units = props?.unit?.items
  const [formValues, setFormValues] = useState({
    donViId: mainUnitId,
    ten: '',
    moTa: '',
    isActive: true,
  })
  const [checkValidate, setCheckValidate] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [isFetchDetail, setIsFetchDetail] = useState(false)

  const handleGetDsDonViMultilevel = useCallback(() => {
    actions.getCategoryUnitMultiLevel(mainUnitId)
  }, [mainUnitId, actions])

  const handleChange = (name, value) => {
    setCheckValidate(false)
    setFormValues({
      ...formValues,
      [name]: value,
    })
  }
  const handleBack = () => {
    props.history.goBack()
  }

  const handleSubmit = async () => {
    setCheckValidate(true)
    if (!formValues.ten) {
      return
    }
    setIsLoading(true)
    const payload = {
      don_vi_id: formValues?.donViId,
      ten: formValues.ten?.trim(),
      mo_ta: formValues.moTa?.trim(),
      is_active: formValues.isActive,
    }
    if (id) {
      const response = await props.actions.putDiaDiemHop(id, payload)
      if (
        response?.payload?.data?.code === 200 ||
        response?.payload?.data?.code === 201
      ) {
        props.actions.commonAddToasterMessage({
          message: 'Lưu thành công',
          intent: Intent.SUCCESS,
        })
        handleBack()
      } else {
        props.actions.commonAddToasterMessage({
          message: 'Lưu không thành công',
          intent: Intent.DANGER,
        })
      }
    } else {
      const response = await props.actions.postDiaDiemHop(payload)
      if (
        response?.payload?.data?.code === 200 ||
        response?.payload?.data?.code === 201
      ) {
        props.actions.commonAddToasterMessage({
          message: 'Lưu thành công',
          intent: Intent.SUCCESS,
        })
        handleBack()
      } else {
        props.actions.commonAddToasterMessage({
          message: 'Lưu không thành công',
          intent: Intent.DANGER,
        })
      }
    }
    setIsLoading(false)
  }

  const handleGetChiTietDiaDiemHop = async () => {
    setIsFetchDetail(true)
    await props.actions.getChiTietDiaDiemHop(id).then(res => {
      setIsFetchDetail(false)
      if (res?.payload?.data?.result) {
        const dataDetail = res.payload.data.result
        setFormValues({
          donViId: dataDetail?.donViId,
          ten: dataDetail?.ten,
          moTa: dataDetail?.moTa,
          isActive: dataDetail?.isActive,
        })
      }
    })
  }

  useEffect(() => {
    handleGetDsDonViMultilevel()
  }, [handleGetDsDonViMultilevel])

  useEffect(() => {
    if (id) {
      handleGetChiTietDiaDiemHop()
    }
    // eslint-disable-next-line
  }, [id])

  const { ten, moTa, isActive } = formValues
  return (
    <MasterLayout typeSidebar="sidebarCalendar">
      <Title
        name={id ? 'Chỉnh sửa địa điểm họp' : 'Thêm mới địa điểm họp'}
        icon={id ? 'icon-edit-v2' : 'icon-s-plus'}
        isGoBack
        history={props.history}
      />
      <div className="form-wrapper mt10">
        <div className="form-container">
          <div className="form-padding-box">
            <div className="pt-form-group pt-intent-danger">
              <div className="row">
                <div className="col-xs-12 col-sm-6 col-md-6 form-item">
                  <label className="pt-label">Đơn vị</label>
                  <div className="pt-form-content">
                    <SelectTree
                      data={units}
                      type="Unit"
                      permission={false}
                      isDisabled={isFetchDetail}
                      value={formValues?.donViId ? formValues.donViId : ''}
                      placeholder="Chọn đơn vị"
                      handleChange={value => handleChange('donViId', value)}
                      className="tree-custom-meeting"
                    />
                  </div>
                </div>
                <div className="col-xs-12 col-sm-6 col-md-6 form-item">
                  <label className="pt-label">
                    Địa điểm họp
                    <span className="pt-text-muted required-input">*</span>
                  </label>
                  <div className="pt-form-content">
                    <input
                      name="ten"
                      value={ten}
                      className="pt-input"
                      placeholder="Nhập địa điểm họp"
                      type="text"
                      dir="auto"
                      onChange={e => {
                        handleChange('ten', e.target.value)
                      }}
                      autoFocus={true}
                      disabled={isFetchDetail}
                      maxLength={100}
                    />
                    {checkValidate && !ten && (
                      <div className="pt-form-helper-text">
                        Trường này không được bỏ trống
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div className="pt-form-group pt-intent-danger">
              <div className="row">
                <div className="col-xs-12 col-sm-12 col-md-12 form-item">
                  <label className="pt-label">Mô tả</label>
                  <div className="pt-form-content">
                    <textarea
                      name="moTa"
                      value={moTa}
                      rows="5"
                      className="pt-input pt-fill"
                      placeholder="Nhập mô tả"
                      dir="auto"
                      onChange={e => {
                        handleChange('moTa', e.target.value)
                      }}
                      disabled={isFetchDetail}
                      maxLength={500}
                    ></textarea>
                  </div>
                </div>
              </div>
            </div>
            <div className="form-checkbox">
              <label className="pt-label pt-label-inline" htmlFor="isActive">
                Sử dụng
              </label>
              <div className="input-form-inline pt-form-content mb10">
                <Checkbox
                  name="isActive"
                  className="system-checkbox checkbox-custom"
                  checked={isActive}
                  disabled={isFetchDetail}
                  onChange={e => {
                    handleChange('isActive', e.target.checked)
                  }}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="buttton-action-footer">
          <Button
            onClick={() => handleBack()}
            className="pt-button btn-cancel"
            disabled={false}
          >
            <span className=" pt-icon icon-back"></span>
            <span className="text-content">Quay lại</span>
          </Button>
          <Button
            className="pt-button btn-blue-color"
            onClick={() => handleSubmit()}
            loading={isLoading}
            disabled={false}
          >
            <span className="text-content">Lưu</span>
            <span className="pt-icon icon-save"></span>
          </Button>
        </div>
      </div>
    </MasterLayout>
  )
}

const mapStateToProps = state => ({
  auth: {
    ...state.auth,
  },
  unit: {
    ...state.category,
    items: state.category.categoryUnitMultiLevel,
  },
})

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(Actions, dispatch),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ThemMoiDiaDiemHopPage)
