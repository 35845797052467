import React, { Component } from 'react'
import moment from 'moment'
import { get } from 'lodash'
import { Scrollbars } from 'react-custom-scrollbars'
import classnames from 'classnames'
import { CHUA_CO_THONG_TIN_DUYET } from '../../constants/MessageForm'
import { LoadingItem } from '../../components/common'

class ThongTinDuyet extends Component {
  constructor(props) {
    super(props)
    this.state = {
      id: this.props.match.params.id,
      isLoading: false,
      isEmpty: false,
      duyetDS: [],
    }
  }

  componentDidMount() {
    this.getData()
  }

  getData = async () => {
    this.setState({
      isLoading: true,
    })
    try {
      await this.props.actions
        .getDanhSachThongTinDuyet(this.state.id)
        .then(res => {
          if (
            res &&
            res.payload &&
            res.payload.data &&
            res.payload.data.result &&
            res.payload.data.result.items &&
            res.payload.data.result.items.length !== 0
          ) {
            this.setState({
              isEmpty: false,
              duyetDS: res.payload.data.result.items,
            })
          } else {
            this.setState({
              isEmpty: true,
            })
          }
        })
    } catch (error) {
      this.setState({ isEmpty: true })
    } finally {
      this.setState({
        isLoading: false,
      })
    }
  }

  render() {
    const { duyetDS, isLoading, isEmpty } = this.state
    return (
      <div>
        {isLoading && <LoadingItem />}
        {!isLoading && !isEmpty && (
          <Scrollbars
            autoHideTimeout={100}
            autoHeight
            autoHeightMin={0}
            autoHeightMax={142}
            autoHideDuration={200}
          >
            <ul className="time-line-block">
              {duyetDS &&
                duyetDS.length !== 0 &&
                duyetDS.map((elm, index) => (
                  <li key={index} className="pl20 pb25 border-left">
                    <div className="name">
                      <h2>
                        {get(elm, 'nguoiThucHien.tenNhanVien', '')}
                        {' - '}
                        {get(elm, 'phongBan.maPhongBan', '')}
                      </h2>
                      <span>
                        {moment(elm.ngayTao).isValid()
                          ? moment(elm.ngayTao).format('HH:mm - DD/MM/YYYY')
                          : ''}
                      </span>
                    </div>
                    {get(elm, 'ghiChu') && (
                      <p
                        className={classnames('sumary mt10', {
                          red: elm.trangThai === 5,
                        })}
                      >
                        <span>Ghi chú:</span>
                        {get(elm, 'ghiChu', '')}
                      </p>
                    )}
                  </li>
                ))}
            </ul>
          </Scrollbars>
        )}
        {!isLoading && isEmpty && (
          <div className="error-page-container bg-white pt10 pb10">
            <div className="error-content">
              <img
                src={
                  process.env.PUBLIC_URL + '/images/search-result-not-found.png'
                }
                alt="search-result-not-found"
                className="img-thumb-error mb10"
              />
              <p className="description-note font-size-13">
                {CHUA_CO_THONG_TIN_DUYET}
              </p>
            </div>
          </div>
        )}
      </div>
    )
  }
}

export default ThongTinDuyet
