import React, { Component } from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import { Permission, Authorization } from '../auth'
import { DetailFileDinhKem } from '../common/'
const UNIT = 1
const DEPARTMENT = 2

class DetailBoxAction extends Component {
  constructor(props) {
    super(props)
    let permission = []
    switch (parseInt(props.type, 10)) {
      case UNIT:
        permission.push(Permission.TUTAILIEUDONVI_QUANLY)
        break
      case DEPARTMENT:
        permission.push(Permission.TUTAILIEUPHONGBAN)
        break
      default:
    }

    this.state = {
      permission,
      showBoxAction: false,
    }
  }

  static propTypes = {
    departments: PropTypes.array.isRequired,
    proposeAssignment: PropTypes.object,
  }

  showBoxAction(e) {
    e.preventDefault()
    this.setState({
      showBoxAction: !this.state.showBoxAction,
    })
  }

  render() {
    const { type } = this.props
    let isDisableEdit = false
    if (Number(type) === DEPARTMENT) {
      isDisableEdit = true
    }

    return (
      <div className="list-action">
        <p className="title">Thực Hiện</p>
        <span
          className="icon-More show-th-mobile"
          onClick={this.showBoxAction.bind(this)}
        ></span>
        {this.props.documentsCabinet &&
          this.props.documentsCabinet.fileDinhKem &&
          this.props.documentsCabinet.fileDinhKem.length > 0 && (
            <DetailFileDinhKem
              receiveDocument={{
                fileDinhKem: this.props.documentsCabinet.fileDinhKem,
              }}
              auth={this.props.auth}
              label={'File đính kèm từ công văn'}
            />
          )}
        <div
          className={classnames('box-action', {
            'box-action-open': this.state.showBoxAction,
          })}
        >
          <div className="close-mobile-menu-detail">
            <button
              className="click-item"
              onClick={this.showBoxAction.bind(this)}
            >
              <span className="pt-icon pt-icon-cross"></span>
            </button>
          </div>
          <div className="row list-all-action">
            <Authorization.Element permission={this.state.permission}>
              {this.props.phongBanId === this.props.auth.mainDepartmentId && (
                <div className="col-xs-4 col-sm-6 col-md-6 item">
                  <button
                    className="click-item"
                    disabled={isDisableEdit}
                    onClick={this.props.onClickEditDocumentsCabinet}
                  >
                    <span className="pt-icon pt-icon-annotation icon-cpc"></span>
                    <span className="action-name">Sửa tài liệu</span>
                  </button>
                </div>
              )}
            </Authorization.Element>
            <div className="col-xs-4 col-sm-6 col-md-6 item">
              <button
                className="click-item"
                onClick={this.props.onClickDetailInfo}
              >
                <i className="icon-Thong_Tin_Giao_Viec icon-cpc"></i>
                <span className="action-name">Thông tin tài liệu</span>
              </button>
            </div>
            <div className="col-xs-4 col-sm-6 col-md-6 item">
              <button className="click-item" onClick={this.props.sendToChat}>
                <i className="icon-speech-bubble icon-cpc"></i>
                <span className="action-name">Gửi tin nhắn</span>
              </button>
            </div>
            <div className="col-xs-4 col-sm-6 col-md-6 item">
              <button
                className="click-item"
                onClick={this.props.onClickSendMail}
              >
                <i className="icon-Gmail icon-cpc"></i>
                <span className="action-name">Gửi email</span>
              </button>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default DetailBoxAction
