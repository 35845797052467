import { useState, useEffect, useCallback } from 'react'
import { checkScroll } from 'helpers/Helper'

const UseUserViewed = ({ actions, id }) => {
  const [listData, setListData] = useState(null)
  const [isFetch, setIsFetch] = useState(false)
  const [isError, setIsError] = useState(false)

  const onFetchList = useCallback(
    (page = 1) => {
      if (!id) {
        return
      }

      setIsFetch(true)
      setIsError(false)
      actions.getDSNhanVienDaXemCongVan(id, { page, limit: 20 }).then(res => {
        setIsFetch(false)
        if (res?.error) {
          return setIsError(true)
        }

        const result = res?.payload?.data?.result
        if (page > 1) {
          return setListData(prev => ({
            ...prev,
            items: [...prev.items, ...result.items],
          }))
        }

        return setListData(result)
      })
    },
    [actions, id]
  )

  const onScrollFrame = useCallback(
    scroll => {
      if (isFetch) {
        return
      }

      if (checkScroll(scroll)) {
        if (
          !listData ||
          (listData && listData?.items?.length < listData?.total)
        ) {
          const page = listData?.page + 1
          onFetchList(page)
        }
      }
    },
    [isFetch, listData, onFetchList]
  )

  useEffect(() => {
    onFetchList()
  }, [onFetchList])

  return {
    isError,
    isFetch,
    listData,
    onScrollFrame,
  }
}

export default UseUserViewed
