import React, { useState, useMemo } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import moment from 'moment'
import { IMAGE_URL } from 'constants/Api'
import UIUser from 'components/ui/user'
import UIStatus from 'components/ui/status'
import DocumentFlowItemChild from './item'

const DocumentFlowItem = ({ dataSource, mission }) => {
  const [isToggle, setIsToggle] = useState(true)
  const children = useMemo(() => {
    return dataSource?.children || []
  }, [dataSource])

  const renderContentTitle = useMemo(() => {
    if (dataSource?.mission === mission.CHI_DAO) {
      return 'Nội dung chỉ đạo'
    }

    return 'Nội dung'
  }, [dataSource, mission])

  const renderProfileName = useMemo(() => {
    let textName = dataSource?.profile?.tenNhanVien
    let textMission = ''
    if (dataSource?.profile?.tenVietTat) {
      textName = dataSource.profile.tenVietTat
    } else if (dataSource?.profile?.maPhongBan) {
      textName = dataSource.profile.maPhongBan
    }

    if (dataSource?.mission === mission.CHI_DAO) {
      textMission = 'Theo dõi chỉ đạo'
    } else if (dataSource?.mission === mission.CHU_TRI) {
      textMission = 'Chủ trì'
    } else if (dataSource?.mission === mission.PHOI_HOP) {
      textMission = 'Phối hợp'
    } else if (dataSource?.mission === mission.XEM_BAO_CAO) {
      textMission = 'Xem báo cáo'
    } else if (dataSource?.mission === mission.XEM_DE_BIET) {
      textMission = 'Xem để biết'
    }

    return (
      <p className="m0">
        <span className="text-13-600-17">{textName}</span>{' '}
        {textMission ? (
          <span className="text-13-500-17 color-blue">({textMission})</span>
        ) : (
          ''
        )}
      </p>
    )
  }, [dataSource, mission])

  const renderStatus = useMemo(() => {
    const CHUA_THUC_HIEN = 0
    const DANG_THUC_HIEN = 1
    const DA_HOAN_THANH = 2
    let name = ''
    let color = ''
    if (dataSource?.status === DANG_THUC_HIEN) {
      name = 'Đang thực hiện'
      color = '#1677ff'
    } else if (dataSource?.status === CHUA_THUC_HIEN) {
      name = 'Chưa thực hiện'
      color = '#df8e0a'
    } else if (dataSource?.status === DA_HOAN_THANH) {
      name = 'Đã hoàn thành'
      color = '#2fafd0'
    }

    return {
      name,
      color,
    }
  }, [dataSource])

  const hasAvatar = useMemo(() => {
    if (dataSource?.profile?.tenNhanVien) {
      return true
    }

    return false
  }, [dataSource])

  return (
    <div className="document-flow-item">
      <div className="d-flex align-items-flex-start column-gap-5">
        <div
          className={classNames('document-flow-item__arrow', {
            mt5: hasAvatar,
          })}
        >
          {(children?.length > 0 ?? false) && (
            <span
              className={classNames('cursor-pointer', {
                'icon2-arrow-down': !isToggle,
                'icon2-arrow-up': isToggle,
              })}
              onClick={() => setIsToggle(prev => !prev)}
            />
          )}
        </div>
        <div>
          <UIUser
            hasAvatar={hasAvatar}
            name={dataSource?.profile?.tenNhanVien}
            htmlName={renderProfileName}
            images={
              dataSource?.profile?.anhDaiDien
                ? `${IMAGE_URL}${dataSource.profile.anhDaiDien}`
                : ''
            }
          />
          <div className="document-flow-item__content">
            {renderStatus?.name ? (
              <UIStatus
                className="mt5"
                name={renderStatus.name}
                color={renderStatus.color}
                theme="BACKGROUND"
              />
            ) : (
              ''
            )}
            <ul className="mt5">
              {dataSource?.date ? (
                <li>
                  <strong className="mr5">Ngày:</strong>
                  <span>
                    {moment(dataSource?.date).format('DD/MM/YYYY HH:mm')}
                  </span>
                </li>
              ) : (
                ''
              )}
              {dataSource?.chuyenChiDao ? (
                <li>
                  <strong className="mr5">Chuyển chỉ đạo:</strong>
                  <span>{dataSource.chuyenChiDao}</span>
                </li>
              ) : (
                ''
              )}
              {dataSource?.content ? (
                <li>
                  <strong className="mr5">{renderContentTitle}:</strong>
                  <span>{dataSource.content}</span>
                </li>
              ) : (
                ''
              )}
              {dataSource?.statusContent ? (
                <li>
                  <strong className="mr5">Thực hiện:</strong>
                  <span>{dataSource.statusContent}</span>
                </li>
              ) : (
                ''
              )}
            </ul>
          </div>
        </div>
      </div>
      {isToggle && (children?.length > 0 ?? false) && (
        <div
          className={classNames({
            'is-open': isToggle,
          })}
        >
          {children.map((element, index) => (
            <DocumentFlowItemChild
              key={index}
              dataSource={element}
              mission={mission}
            />
          ))}
        </div>
      )}
    </div>
  )
}

DocumentFlowItem.propTypes = {
  dataSource: PropTypes.object,
}

DocumentFlowItem.defaultProps = {
  dataSource: null,
}

export default DocumentFlowItem
