import React, { useEffect } from 'react'
import { useCookies } from 'react-cookie'
import classnames from 'classnames'
import { get } from 'lodash'
import {
  // Radio,
  Popover,
  Position,
  // RadioGroup,
  PopoverInteractionKind,
} from '@blueprintjs/core'

import { FOLDER_NAME } from 'constants/Api'

const BACKGROUND_LIST = [
  {
    isLight: false,
    url: 'bg1.jpg',
  },
  {
    isLight: false,
    url: 'bg2.jpg',
  },
  {
    isLight: true,
    url: 'bg3.jpg',
  },
  {
    isLight: true,
    url: 'bg4.jpg',
  },
  {
    isLight: true,
    url: 'bg5.jpg',
  },
  {
    isLight: true,
    url: 'bg6.jpg',
  },
  {
    isLight: false,
    url: 'bg7.jpg',
  },
  {
    isLight: false,
    url: 'bg8.jpg',
  },
  {
    isLight: true,
    url: 'bg9.jpg',
  },
  {
    isLight: false,
    url: 'bg10.jpg',
  },
  {
    isLight: false,
    url: 'bg11.jpg',
  },
  {
    isLight: true,
    url: 'bg12.jpg',
  },
]

const Background = ({ classNameIcon = '' }) => {
  const [cookies, setCookie] = useCookies(['home_background_image'])
  const [cookiesTheme, setCookiesTheme] = useCookies(['theme_menu'])

  if (!get(cookies, 'home_background_image')) {
    setCookie('home_background_image', {
      url: 'bg1.jpg',
      is_light: false,
    })
  }

  const changeBackground = (image, isLight) => {
    setCookie('home_background_image', {
      url: image,
      is_light: isLight,
    })
  }

  // const onSwitchTheme = e => {
  //   const value = e?.target?.value
  //   setCookie('theme_menu', value)
  // }

  useEffect(() => {
    if (!cookiesTheme?.theme_menu) {
      setCookiesTheme('theme_menu', 'HORIZONTAL')
    }
  }, [setCookiesTheme, cookiesTheme])

  return (
    <Popover
      popoverClassName="popover-background-list cpc-popover no-arrow mt5"
      position={Position.BOTTOM_RIGHT}
      interactionKind={PopoverInteractionKind.CLICK}
      content={
        <div>
          <div>
            {/* <div className="border-bottom pb5">
              <p className="font-size-14 line-height-reset mb10">
                Kiểu trình đơn
              </p>
              <RadioGroup
                className="cpc-radio cpc-radio-group"
                name="theme_menu"
                onChange={onSwitchTheme}
                selectedValue={cookiesTheme?.theme_menu}
              >
                <Radio
                  className="pt-large pt-text font-size-14 mr30"
                  label={'Ngang'}
                  value={'HORIZONTAL'}
                />
                <Radio
                  className="pt-large pt-text font-size-14"
                  label={'Dọc'}
                  value={'VERTICAL'}
                />
              </RadioGroup>
            </div> */}
            <p className="font-size-14 line-height-reset mt10 pv20">Hình nền</p>
          </div>
          <div className="background-list" style={{ marginTop: '-10px' }}>
            {BACKGROUND_LIST.map((elm, index) => {
              let background = FOLDER_NAME
                ? `/backgrounds_home/${FOLDER_NAME}/thumbnail-${elm.url}`
                : `/backgrounds_home/thumbnail-${elm.url}`
              return (
                <div key={index} className="background-list-item">
                  <span
                    style={{ backgroundImage: `url(${background})` }}
                    className={classnames({
                      active:
                        get(cookies, 'home_background_image.url', '') ===
                        elm.url,
                    })}
                    onClick={() => changeBackground(elm.url, elm.isLight)}
                  />
                </div>
              )
            })}
          </div>
        </div>
      }
    >
      <span className="icon-settings settings-background-icon"></span>
    </Popover>
  )
}

export default Background
