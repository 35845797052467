import React, { useEffect, useState, useCallback } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { bindActionCreators } from 'redux'
import { Cookies, withCookies } from 'react-cookie'
import Slider from 'react-slick'
import { Scrollbars } from 'react-custom-scrollbars'

import { Loading } from 'components/newCommon'
import { getDashboard } from 'actions'
import { getDanhSachSlider } from 'actions/task'
import { FILE_URL, FOLDER_NAME } from 'constants/Api'
import { MasterLayout } from 'components/layout'
import StatictisItem from './statictis-item'
import BarChart from './bar-chart'
import LineChart from './line-chart'
import TaskItem from './task-item'
import UIEmpty from 'components/ui/empty'

const cookies = new Cookies()
const settings = {
  arrows: false,
  centerPadding: '0px',
  dots: true,
  fade: false,
  speed: 1000,
  autoPlaySpeed: 3000,
  slidesToShow: 1,
  slidesToScroll: 1,
  autoplay: true,
  infinite: true,
}

const Index = ({ auth, actions, history, dashboard }) => {
  const bgImage = `${process.env.PUBLIC_URL}/images/bg1.jpg`
  const token = auth?.token?.accessToken
  const [isLoading, setIsLoading] = useState(true)
  const [slidersData, setSlidersData] = useState([])
  const [banLamViecData, setBanLamViecData] = useState([])
  const [congVanData, setCongVanData] = useState([])
  const [kySoData, setKySoData] = useState([])
  const [congViecData, setCongViecData] = useState([])
  const [congVanTuanData, setCongVanTuanData] = useState([])
  const [congViecTuanData, setCongViecTuanData] = useState([])

  const getDataSlider = async () => {
    let params = {
      page: 1,
      limit: 100,
      sort: 'id',
      isActive: true,
    }
    const response = await getDanhSachSlider(params)
    setSlidersData(response?.payload?.data?.result?.items || [])
  }

  const onLinkSlider = elm => {
    if (!!elm?.url) {
      window.open(elm?.url, 'eoffice-slider-url')
    }

    return
  }

  useEffect(() => {
    if (!dashboard) {
      return
    }

    setCongVanData([
      {
        name: 'Chưa',
        name2: 'xử lý',
        icon: 'icon2-list-check font-size-20 color-orange background-orange-opacity',
        value: dashboard?.canXuLy?.congVanChuaXuLy,
        path: '/cong-van/cong-van-den/danh-sach/chua-xu-ly',
      },
      {
        name: 'Đang',
        name2: 'xử lý',
        icon: 'icon2-hourglass font-size-20 color-blue background-blue-opacity',
        value: dashboard?.canXuLy?.congVanDangXuLy,
        path: '/cong-van/cong-van-den/danh-sach/dang-xu-ly',
      },
    ])
    setKySoData([
      {
        name: 'Ký số',
        name2: 'đi',
        icon: 'icon2-file-pen font-size-20 color-blue-light background-blue-light-opacity',
        value: dashboard?.canXuLy?.congVanDiCanKySo,
        path: '/ky-so/cong-van-di/danh-sach',
      },
    ])
    setCongViecData([
      {
        name: 'Chưa',
        name2: 'giao',
        icon: 'icon2-list-check font-size-20 color-orange background-orange-opacity',
        value: dashboard?.canXuLy?.congViecChuaGiao,
        path: '/cong-viec/phong-ban/danh-sach/chua-giao',
      },
      {
        name: 'Chưa',
        name2: 'thực hiện',
        icon: 'icon2-list-inside font-size-20 color-green background-green-opacity',
        value: dashboard?.canXuLy?.congViecCanThucHien,
        path: '/cong-viec/ca-nhan/danh-sach/chua-thuc-hien',
      },
      {
        name: 'Trễ',
        name2: 'hạn',
        icon: 'icon2-warning font-size-20 color-red background-red-opacity',
        value: dashboard?.canXuLy?.congViecTreHan,
        path: '/cong-viec/ca-nhan/danh-sach/chua-thuc-hien',
      },
    ])
    setCongVanTuanData(dashboard?.congVanTuan)
    setCongViecTuanData(dashboard?.congViecTuan)
    setBanLamViecData(dashboard?.banLamViec)
  }, [dashboard])

  const getDashboard = useCallback(async () => {
    const res = await actions.getDashboard()
    if (res) {
      setIsLoading(false)
    }
  }, [actions])

  useEffect(() => {
    getDashboard()
  }, [getDashboard])

  useEffect(() => {
    getDataSlider()
    return () => {
      setSlidersData([])
    }
  }, [])

  return (
    <MasterLayout typeSidebar="HomePage">
      <div
        className="dashboard-new-layout"
        style={{
          backgroundImage: `url(${
            cookies.get('home_background_image')?.url
              ? FOLDER_NAME
                ? `/backgrounds_home/${FOLDER_NAME}/${
                    cookies.get('home_background_image')?.url
                  }`
                : `/backgrounds_home/${
                    cookies.get('home_background_image')?.url
                  }`
              : bgImage
          })`,
        }}
      >
        <div className="dashboard-new-wrapper">
          {slidersData.length !== 0 && (
            <div className="slider-block mt5">
              <Slider {...settings}>
                {slidersData.map((elm, index) => {
                  let bgImageSlider = `${FILE_URL}/api/file/get/${elm?.file?.id}?BearerToken=${token}`
                  return (
                    <div key={index} className="pointer">
                      <div className="slider-item">
                        <div
                          className="slider-item-image"
                          style={{
                            backgroundImage: `url('${bgImageSlider}')`,
                          }}
                          onClick={() => onLinkSlider(elm)}
                        />
                      </div>
                    </div>
                  )
                })}
              </Slider>
            </div>
          )}
          <div className="dashboard-new-panel-list mt20">
            <div className="dashboard-new-panel">
              <div className="d-flex align-items-center gap-10">
                <span className="icon2-file color-blue"></span>
                <span className="text-16-700-22 color-text-title font2 text-uppercase">
                  Công văn
                </span>
              </div>
              <div className="d-flex align-items-center mt15 dashboard-new-statictis">
                {congVanData?.map((item, index) => (
                  <StatictisItem
                    key={index}
                    history={history}
                    dataSource={item}
                  />
                ))}
              </div>
            </div>
            <div className="dashboard-new-panel">
              <div className="d-flex align-items-center gap-10">
                <span className="icon2-file-protect color-blue"></span>
                <span className="text-16-700-22 color-text-title font2 text-uppercase">
                  Ký số
                </span>
              </div>
              <div className="d-flex align-items-center mt15 dashboard-new-statictis">
                {kySoData?.map((item, index) => (
                  <StatictisItem
                    key={index}
                    history={history}
                    dataSource={item}
                  />
                ))}
              </div>
            </div>
            <div className="dashboard-new-panel">
              <div className="d-flex align-items-center gap-10">
                <span className="icon2-work color-blue"></span>
                <span className="text-16-700-22 color-text-title font2 text-uppercase">
                  Công việc
                </span>
              </div>
              <div className="d-flex align-items-center mt15 dashboard-new-statictis">
                {congViecData?.map((item, index) => (
                  <StatictisItem
                    key={index}
                    history={history}
                    dataSource={item}
                  />
                ))}
              </div>
            </div>
          </div>
          <div className="dashboard-new-panel-footer">
            <div className="left">
              <div className="dashboard-new-panel mt20">
                <div className="d-flex align-items-center gap-10">
                  <span className="icon2-statictis color-blue"></span>
                  <span className="text-16-700-22 color-text-title font2 text-uppercase">
                    Thống kê công văn
                  </span>
                </div>
                <div className="dashboard-new-chart-wrapper">
                  <BarChart dataSource={congVanTuanData} />
                </div>
              </div>
              <div className="dashboard-new-panel mt20">
                <div className="d-flex align-items-center gap-10">
                  <span className="icon2-chart-top color-blue"></span>
                  <span className="text-16-700-22 color-text-title font2 text-uppercase">
                    Thống kê công việc
                  </span>
                </div>
                <div className="dashboard-new-chart-wrapper dashboard-new-chart-line-chart">
                  <LineChart dataSource={congViecTuanData} />
                </div>
              </div>
            </div>
            <div className="right">
              <div className="dashboard-new-panel mt20">
                <div className="d-flex align-items-center gap-10">
                  <span className="icon2-menu-dot color-blue"></span>
                  <span className="text-16-700-22 color-text-title font2 text-uppercase">
                    Bàn làm việc
                  </span>
                </div>
                <div className="dashboard-new-content-wrapper">
                  {isLoading && <Loading />}
                  {!isLoading &&
                    (!banLamViecData || banLamViecData?.length === 0) && (
                      <UIEmpty style={{ height: 380 }} />
                    )}
                  {!isLoading &&
                    banLamViecData &&
                    (banLamViecData?.length > 0 ?? false) && (
                      <Scrollbars
                        autoHideTimeout={100}
                        autoHeight
                        autoHeightMin={0}
                        autoHeightMax={380}
                      >
                        {banLamViecData?.map((elm, index) => (
                          <TaskItem
                            key={index}
                            history={history}
                            dataSource={elm}
                          />
                        ))}
                      </Scrollbars>
                    )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </MasterLayout>
  )
}

Index.defaultProps = {
  auth: null,
  dashboard: null,
}

Index.propTypes = {
  auth: PropTypes.object,
  dashboard: PropTypes.object,
}

const mapStateToProps = state => ({
  auth: state.auth,
  dashboard: state.dashboard?.dashboard,
})

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(
    {
      getDashboard,
    },
    dispatch
  ),
})

export default withCookies(connect(mapStateToProps, mapDispatchToProps)(Index))
