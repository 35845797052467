import React, { Component } from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import * as Types from '../../constants/Api'

class InformationSteeringItem extends Component {
  static propTypes = {
    informationSteering: PropTypes.object.isRequired,
  }

  state = {
    chuTri: '',
    dsPhoiHop: '',
    dsXemDeBiet: '',
    dsXemBaoCao: '',
  }

  componentDidMount() {
    if (this.props.informationSteering) {
      let chuTri = ''
      let dsPhoiHop = []
      let dsXemDeBiet = []
      let dsXemBaoCao = []

      if (this.props.informationSteering.chuTri) {
        chuTri = this.props.informationSteering.chuTri.maPhongBan
      }

      if (this.props.informationSteering.phoiHop) {
        this.props.informationSteering.phoiHop.forEach(item => {
          dsPhoiHop.push(item.maPhongBan)
        })
      }

      if (this.props.informationSteering.xemDeBiet) {
        this.props.informationSteering.xemDeBiet.forEach(item => {
          dsXemDeBiet.push(item.maPhongBan)
        })
      }

      if (this.props.informationSteering.donViChuTri) {
        chuTri = this.props.informationSteering.donViChuTri.tenVietTat
      }

      if (this.props.informationSteering.donViPhoiHop) {
        this.props.informationSteering.donViPhoiHop.forEach(item => {
          dsPhoiHop.push(item.tenVietTat)
        })
      }

      if (this.props.informationSteering?.dsNvPhoiHopInfo) {
        this.props.informationSteering.dsNvPhoiHopInfo.forEach(item => {
          dsPhoiHop.push(item?.tenNhanVien)
        })
      }

      if (this.props.informationSteering.dsXemBaoCao) {
        this.props.informationSteering.dsXemBaoCao.forEach(item => {
          dsXemBaoCao.push(item.tenNhanVien)
        })
      }

      if (this.props.informationSteering.donViXemDeBiet) {
        this.props.informationSteering.donViXemDeBiet.forEach(item => {
          dsXemDeBiet.push(item.tenVietTat)
        })
      }

      if (this.props.informationSteering?.dsNvXemDeBiet) {
        this.props.informationSteering.dsNvXemDeBiet.forEach(item => {
          dsXemDeBiet.push(item.tenNhanVien)
        })
      }

      this.setState({
        chuTri,
        dsPhoiHop: dsPhoiHop.join(', '),
        dsXemDeBiet: dsXemDeBiet.join(', '),
        dsXemBaoCao: dsXemBaoCao.join(', '),
      })
    }
  }

  xemDeBiet = () => {
    if (!this.state?.dsXemDeBiet) {
      return
    }

    return (
      <li className="item-info">
        <span className="style-icon icon-Xemdebiet"></span>
        <div className="content-detail">
          <span className="title-bold">Xem để biết: </span>
          {this.state.dsXemDeBiet}
        </div>
      </li>
    )
  }

  render() {
    return (
      <div className="item-content-history">
        <div className="time-create">
          {this.props.informationSteering.ngayTao &&
            moment(this.props.informationSteering.ngayTao).format(
              'DD/MM/YYYY HH:mm'
            )}
        </div>
        <div className="avatar-user">
          <span
            className="avatar"
            style={{
              backgroundImage: `url(${
                this.props.informationSteering.nguoiChiDao &&
                this.props.informationSteering.nguoiChiDao.anhDaiDien
                  ? Types.FILE_URL +
                    this.props.informationSteering.nguoiChiDao.anhDaiDien
                  : '/images/default_avatar.png'
              })`,
            }}
          ></span>
          <span className="name_user">
            {this.props.informationSteering.nguoiChiDao
              ? this.props.informationSteering.nguoiChiDao.tenNhanVien
              : ''}
          </span>
        </div>
        <div className="info-content">
          <ul className="list-info">
            <li className="item-info">
              <span className="style-icon icon2-file"></span>
              <div className="content-detail">
                <span className="title-bold">Nội dung chỉ đạo: </span>
                {this.props.informationSteering.noiDung && (
                  <span className="detail">
                    {this.props.informationSteering.noiDung}
                  </span>
                )}
              </div>
            </li>

            <li className="item-info">
              <span className="style-icon icon-Chidao"></span>
              <div className="content-detail">
                <span className="title-bold">Theo dõi chỉ đạo: </span>
                {this.props.informationSteering.nvChiDao && (
                  <span className="detail">
                    {this.props.informationSteering.nvChiDao.tenNhanVien}
                  </span>
                )}
              </div>
            </li>

            <li className="item-info">
              <span className="style-icon icon-setting"></span>
              <div className="content-detail">
                <span className="title-bold">Chủ trì: </span>
                {/* {this.props.informationSteering.chuTri &&
                  <span className="detail">
                    {this.props.informationSteering.chuTri ? this.props.informationSteering.chuTri.maPhongBan : ''}
                  </span>
                } */}
                {this.state.chuTri}
              </div>
            </li>

            <li className="item-info">
              <span className="style-icon icon-Phoihop"></span>
              <div className="content-detail">
                <span className="title-bold">Phối hợp: </span>
                {/* {this.props.informationSteering.phoiHop &&
                  <span className="detail">
                    {this.props.informationSteering.phoiHop.map((item, i) => (
                      <span key={i}>
                        {this.props.informationSteering.phoiHop.length === i + 1 ? item.maPhongBan : item.maPhongBan + ', '}
                      </span>
                    ))}
                  </span>
                } */}
                {this.state.dsPhoiHop}
              </div>
            </li>

            <li className="item-info">
              <span className="style-icon icon2-view-report"></span>
              <div className="content-detail">
                <span className="title-bold">Xem báo cáo: </span>
                {this.state.dsXemBaoCao}
              </div>
            </li>

            {this.xemDeBiet()}

            <li className="item-info">
              <span className="style-icon icon-calendar"></span>
              <div className="content-detail">
                <span className="title-bold">Hạn giải quyết: </span>
                {this.props.informationSteering.hanGiaiQuyet && (
                  <span className="detail">
                    {this.props.informationSteering.hanGiaiQuyet &&
                      moment(
                        this.props.informationSteering.hanGiaiQuyet
                      ).format('DD/MM/YYYY')}
                  </span>
                )}
              </div>
            </li>
          </ul>
        </div>
      </div>
    )
  }
}

export default InformationSteeringItem
