import React, { Component } from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import moment from 'moment'
import { Tooltip } from '@blueprintjs/core'
import UIStatus from 'components/ui/status'

const LINE_HEIGHT = 18

class ListItemAssigned extends Component {
  constructor(props) {
    super(props)
    this.handleEnabledChange = this.handleEnabledChange.bind(this)
    this.handleClick = this.handleClick.bind(this)
    this.handleClickPopup = this.handleClickPopup.bind(this)
    this.handleSubmitRemoveItem = this.handleSubmitRemoveItem.bind(this)
    this.handleClickShowDepartment = this.handleClickShowDepartment.bind(this)
    this.handleReadMore = this.handleReadMore.bind(this)
  }

  static propTypes = {
    item: PropTypes.object.isRequired,
    number: PropTypes.number.isRequired,
  }

  refHandlers = {
    toaster: (ref: Toaster) => (this.toaster = ref),
  }

  state = {
    isEnabled: false,
    isOpenPopup: false,
    dsPhoiHop: [],
    isShowCollapse: false,
    readMore: {
      giaoViec: false,
      trichYeu: false,
    },
  }

  handleEnabledChange = e => {
    this.setState({ isEnabled: !this.state.isEnabled })
  }

  handleClick = () => {
    if (!this.props.item) {
      return
    }

    const id =
      this.props.item.congViecPhongBanId || this.props.item.phieuGiaoViecId
    this.props.onClickDetail &&
      this.props.onClickDetail({
        congViecId: id,
        soCongVan: this.props.item.soCongVan,
        fileId: this.props.item.fileId,
        daXem: this.props.item?.daXem,
        congViecPhongBanId: this.props.item?.congViecPhongBanId,
      })
  }

  handleClickPopup = () => {
    this.setState({
      isOpenPopup: !this.state.isOpenPopup,
      error: '',
    })
  }

  handleSubmitRemoveItem = e => {
    e.preventDefault()
    this.setState({
      isOpenPopup: false,
    })
    this.props.onHandleSubmitRemoveItem(this.props.item.phieuGiaoViecId)
  }

  handleClickShowDepartment = () => {
    this.setState({ isShowCollapse: !this.state.isShowCollapse })
  }

  componentDidMount() {
    if (this.props.item && this.props.item.dsPhoiHop) {
      const dsPhoiHop = []
      this.props.item.dsPhoiHop.split(',').forEach(item => {
        dsPhoiHop.push(item.trim())
      })
      this.setState({ dsPhoiHop })
    }
    let newReadMore = { ...this.state.readMore }
    if (this.trichYeu && this.trichYeu.offsetHeight / LINE_HEIGHT > 5) {
      newReadMore = {
        ...newReadMore,
        trichYeu: true,
      }
    }
    if (this.giaoViec && this.giaoViec.offsetHeight / LINE_HEIGHT > 4) {
      newReadMore = {
        ...newReadMore,
        giaoViec: true,
      }
    }
    this.setState({
      readMore: newReadMore,
    })
  }

  handleReadMore(name) {
    let newReadMore = { ...this.state.readMore }
    if (newReadMore[name]) {
      newReadMore = {
        ...newReadMore,
        [name]: false,
      }
      this.setState({ readMore: newReadMore })
    } else {
      this.handleClick()
    }
  }

  hienThiIconNguonCongVan = () => {
    const nguonCongViec = this.props.item?.nguonCongViec
    let title = ''
    let icon = ''
    let color = ''
    if (nguonCongViec === 0) {
      title = 'Công việc tự tạo'
      icon = 'icon2-file-brick'
      color = 'color-blue-light'
    } else if (nguonCongViec === 1) {
      title = 'Công văn đi'
      icon = 'icon2-file-up'
      color = 'color-green'
    } else if (nguonCongViec === 2) {
      title = 'Công văn đến'
      icon = 'icon2-file-down'
      color = 'color-blue'
    } else if (nguonCongViec === 3) {
      title = 'Công văn đi nội bộ'
      icon = ''
    }

    return (
      <div className="ml5 tooltip-box-reset mb5">
        <Tooltip content={<span className="font-size-12">{title}</span>}>
          <span className={classnames('font-size-19', icon, color)}></span>
        </Tooltip>
      </div>
    )
  }

  render() {
    if (!this.props.item) {
      return
    }

    let chuTri = []
    if (this.props.item.pbChuTri) {
      chuTri.push(this.props.item.pbChuTri)
    }
    if (this.props.item.nguoiChuTri) {
      chuTri.push(this.props.item.nguoiChuTri)
    }

    const { item, number } = this.props
    return (
      <tr>
        <td style={{ padding: '0' }} onClick={this.handleClick}>
          {number > 9 ? number : '0' + number}
        </td>
        <td style={{ padding: '0' }} onClick={this.handleClick}>
          {item.daLuuTuTaiLieu && item.daLuuTuTaiLieu === true ? (
            <span className="icon-Save-DocumentCabinet style-icon-DocumentCabinet"></span>
          ) : (
            <span>&nbsp;</span>
          )}
        </td>
        <td>
          <div className="d-flex flex-direction-column">
            {this.hienThiIconNguonCongVan()}
            <span>{item.soCongVan}</span>
          </div>
        </td>
        <td onClick={this.handleClick}>
          <div ref={el => (this.trichYeu = el)}>
            <p>{item.tieuDe ? item.tieuDe : ''}</p>
          </div>
        </td>
        <td onClick={this.handleClick}>
          <p>
            {item.giaoViec.nguoiGiaoViec
              ? item.giaoViec.nguoiGiaoViec.tenNhanVien
              : null}
          </p>
          <p>
            {item.giaoViec
              ? moment(item.giaoViec.ngayGiaoViec).format('DD/MM/YYYY HH:mm')
              : null}
          </p>
          <div className="assign-content textarea-content">
            <div ref={el => (this.giaoViec = el)}>
              <p>
                {item && item.giaoViec && item.giaoViec.noiDung
                  ? item.giaoViec.noiDung
                  : ''}
              </p>
            </div>
          </div>
        </td>
        <td style={{ padding: '0' }} onClick={this.handleClick}>
          {item.hanGiaiQuyet
            ? moment(item.hanGiaiQuyet).format('DD/MM/YYYY')
            : null}
        </td>
        <td
          style={{ textAlign: 'center', padding: '0' }}
          onClick={this.handleClick}
        >
          {item.trangThai !== 2 && (
            <span
              className={classnames('deadline-date', {
                'style-qh': item.soNgayConLai < 0,
                'style-gqh': item.soNgayConLai === 0 || item.soNgayConLai === 1,
                'style-color': item.soNgayConLai > 1,
              })}
            >
              {item.soNgayConLai !== null ? item.soNgayConLai : ''}
            </span>
          )}
        </td>
        <td style={{ padding: '0' }} onClick={this.handleClick}>
          <UIStatus
            className="min-width-80 center"
            name={
              item.trangThai === 0
                ? 'Chưa xử lý'
                : item.trangThai === 1
                ? 'Đang xử lý'
                : item.trangThai === 2
                ? 'Đã xử lý'
                : ''
            }
            color={
              item.trangThai === 0
                ? '#df8e0a'
                : item.trangThai === 1
                ? '#1677ff'
                : item.trangThai === 2
                ? '#18a94d'
                : ''
            }
            theme="BACKGROUND"
          />
        </td>
        <td style={{ padding: '0' }} onClick={this.handleClick}>
          <span
            className={classnames({
              'color-blue': !!item.chuTri,
            })}
          >
            {item.chuTri ? 'Chủ trì' : 'Phối hợp'}
          </span>
        </td>
        <td style={{ padding: '0' }} onClick={this.handleClick}>
          {chuTri.join(', ')}
        </td>
        <td onClick={this.handleClick} className="text-center-col">
          {item.canBaoCao && <span className="icon-Bao_Cao"></span>}
        </td>
      </tr>
    )
  }
}

export default ListItemAssigned
