import React from 'react'
import { Button, Intent, Checkbox } from '@blueprintjs/core'
import * as MESSAGE from '../../constants/MessageForm'
import async from 'async'
import classnames from 'classnames'
import { SelectTree } from '../common/'
import { Utils } from '../../helpers'
import _ from 'lodash'
import { LoadingItem } from '../../components/common'

const PATH = '/ky-so-mo-rong/du-an/chinh-sua/:id'

class AddProjectCategory extends React.Component {
  constructor(props) {
    super(props)
    this.onAddBtnClick = this.onAddBtnClick.bind(this)
    this.handleChange = this.handleChange.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
    this.handleGoBack = this.handleGoBack.bind(this)
    this.handleRemoveItem = this.handleRemoveItem.bind(this)
    this.handleSelectTree = this.handleSelectTree.bind(this)
  }

  onAddBtnClick(event) {
    this.setState({ loadingAdd: true }, () => {
      if (this.props.match.path === PATH) {
        const donViId = this.state.donViId

        if (!this.state.isCheck) {
          this.props.actions
            .getCategoryDepartmentMultiLevel(this.state.donViId[0], false)
            .then(res => {
              if (res.error) {
                return this.setState({ loadingAdd: false })
              } else if (res.payload && res.payload.data.result) {
                const data = res.payload.data.result
                const { phongBan, phongBanId } = this.state

                let data_PhongBan

                for (let i = 0; i < data.length; i++) {
                  if (
                    this.state.phongBanId.indexOf(data[i].phongBanId) === -1
                  ) {
                    data_PhongBan = data[i].phongBanId
                    break
                  }
                }

                if (!data_PhongBan) {
                  const state = this.state
                  state.phongBan.push([])
                  state.phongBanId.push([])
                  state.nhanVien.push([])
                  state.danhSachNhanVien.push([])
                  return this.setState({ state, loadingAdd: false })
                }

                phongBan.push(data)
                phongBanId.push(data_PhongBan)
                this.setState(
                  {
                    phongBan,
                    phongBanId,
                  },
                  () => {
                    this.props.actions
                      .commonFetchStaffByDepartmentId(data_PhongBan)
                      .then(res => {
                        if (res.error) {
                          return this.setState({ loadingAdd: false })
                        }
                        if (
                          res.payload &&
                          res.payload.data &&
                          res.payload.data.result &&
                          res.payload.data.result.items
                        ) {
                          const data = res.payload.data.result.items
                          const state = this.state
                          state.nhanVien.push(data)
                          state.danhSachNhanVien.push([])
                          this.setState({ state, loadingAdd: false })
                        }
                      })
                  }
                )
              }
            })
        } else {
          const donViId = this.state.donViId
          const phongBanId = this.state.phongBanId
          const phongBan = this.state.phongBan
          const nhanVien = this.state.nhanVien
          const danhSachNhanVien = this.state.danhSachNhanVien

          let data_PhongBan

          for (let i = 0; i < this.state.phongBan[0].length; i++) {
            if (
              this.state.phongBanId.indexOf(
                this.state.phongBan[0][i].phongBanId
              ) === -1
            ) {
              data_PhongBan = this.state.phongBan[0][i].phongBanId
              break
            }
          }

          if (data_PhongBan) {
            this.props.actions
              .commonFetchStaffByDepartmentId(data_PhongBan)
              .then(res => {
                if (
                  res.payload &&
                  res.payload.data &&
                  res.payload.data.result &&
                  res.payload.data.result.items
                ) {
                  const data = res.payload.data.result.items

                  donViId.push(this.state.donViId[0])
                  phongBanId.push(data_PhongBan)
                  danhSachNhanVien.push([])
                  phongBan.push(this.state.phongBan[0])
                  nhanVien.push(data)

                  this.setState({
                    donViId,
                    phongBanId,
                    phongBan,
                    nhanVien,
                    danhSachNhanVien,
                    loadingAdd: false,
                    isAdd: this.state.isAdd.concat(this.state.isAdd.length + 1),
                  })
                }
              })
          } else {
            phongBanId.push([undefined])
            danhSachNhanVien.push([])
            phongBan.push([])
            nhanVien.push([])

            this.setState({
              phongBanId,
              danhSachNhanVien,
              phongBan,
              nhanVien,
              loadingAdd: false,
            })
          }
        }
        donViId.push(this.state.donViId[0])

        this.setState({
          donViId,
          isAdd: this.state.isAdd.concat(this.state.isAdd.length + 1),
        })
      } else {
        const donViId = this.state.donViId
        const phongBanId = this.state.phongBanId
        const phongBan = this.state.phongBan
        const nhanVien = this.state.nhanVien
        const danhSachNhanVien = this.state.danhSachNhanVien

        let data_PhongBan

        for (let i = 0; i < this.state.phongBan[0].length; i++) {
          if (
            this.state.phongBanId.indexOf(
              this.state.phongBan[0][i].phongBanId
            ) === -1
          ) {
            data_PhongBan = this.state.phongBan[0][i].phongBanId
            break
          }
        }

        if (data_PhongBan) {
          this.props.actions
            .commonFetchStaffByDepartmentId(data_PhongBan)
            .then(res => {
              if (
                res.payload &&
                res.payload.data &&
                res.payload.data.result &&
                res.payload.data.result.items
              ) {
                const data = res.payload.data.result.items

                donViId.push(this.state.donViId[0])
                phongBanId.push(data_PhongBan)
                danhSachNhanVien.push([])
                phongBan.push(this.state.phongBan[0])
                nhanVien.push(data)

                this.setState({
                  donViId,
                  phongBanId,
                  phongBan,
                  nhanVien,
                  danhSachNhanVien,
                  loadingAdd: false,
                  isAdd: this.state.isAdd.concat(this.state.isAdd.length + 1),
                })
              }
            })
        } else {
          donViId.push(this.state.donViId[0])
          phongBanId.push([undefined])
          danhSachNhanVien.push([])
          phongBan.push([])
          nhanVien.push([])

          this.setState({
            donViId,
            phongBanId,
            danhSachNhanVien,
            phongBan,
            nhanVien,
            loadingAdd: false,
            isAdd: this.state.isAdd.concat(this.state.isAdd.length + 1),
          })
        }
      }
    })
  }

  handleRemoveItem(i) {
    const isAdd = this.state.isAdd
    const donViId = this.state.donViId
    const phongBanId = this.state.phongBanId
    const phongBan = this.state.phongBan
    const nhanVien = this.state.nhanVien
    const danhSachNhanVien = this.state.danhSachNhanVien

    isAdd.splice(i, 1)
    donViId.splice(i, 1)
    phongBanId.splice(i, 1)
    phongBan.splice(i, 1)
    nhanVien.splice(i, 1)
    danhSachNhanVien.splice(i, 1)

    this.setState({
      isAdd,
      donViId,
      phongBanId,
      phongBan,
      danhSachNhanVien,
      nhanVien,
    })
  }

  state = {
    maDuAn: '',
    tenDuAn: '',
    suDung: true,
    donViId: [this.props.auth.mainUnitId],
    donVi: '',
    duAnId: '',
    phongBanId: [],
    danhSachNhanVien: [[]],
    phongBan: [],
    nhanVien: [],
    dsChucDanh: [],
    isAdd: [1],
    isLoading: false,
    loadingAdd: false,
    loadingNhanVien: {},
    error: {},
    errors: {},
    isOpenPopup: false,
    isUnit: undefined,
    isData: true,
    isCheck: false,
    isLoadingData: {
      maDuAn: true,
      tenDuAn: true,
      suDung: true,
      donViId: true,
      phongBanId: true,
      chucDanhId: true,
    },
  }

  componentDidMount() {
    let isUnit = Utils.getData('Project-Unit', {})

    this.setState({ donViId: [isUnit], donVi: isUnit })
  }

  componentWillReceiveProps = async nextProps => {
    if (nextProps.unit !== this.props.unit) {
      if (nextProps.unit.length === 0) {
        return
      }
    }

    if (
      nextProps.departments !== this.props.departments &&
      this.state.isAdd.length === 1 &&
      this.state.isData &&
      !this.props.project
    ) {
      const phongBanId = this.props.auth.mainDepartmentId
      const check =
        nextProps.departments.find(item => phongBanId === item.phongBanId) !==
        undefined
      const phongBan = nextProps.departments.map(item => item)
      await this.setState({
        phongBanId: [check ? phongBanId : phongBan[0].phongBanId],
        phongBan: [phongBan],
        isLoadingData: {
          ...this.state.isLoadingData,
          phongBanId: false,
        },
      })
    }

    if (
      nextProps.users !== this.props.users &&
      this.state.isData &&
      !this.props.project &&
      this.state.isAdd.length === 1
    ) {
      await this.setState({
        danhSachNhanVien: [[]],
        nhanVien: [nextProps.users],
        isLoadingData: {
          ...this.state.isLoadingData,
          chucDanhId: false,
        },
      })
    }

    if (this.props.match.path !== PATH) {
      await this.setState({
        isLoadingData: {
          ...this.state.isLoadingData,
          maDuAn: false,
          tenDuAn: false,
          suDung: false,
          donViId: false,
        },
      })
    }

    if (nextProps.project !== this.props.project) {
      const maDuAn = nextProps.project.maDuAn || ''
      const tenDuAn = nextProps.project.tenDuAn || ''
      const duAnId = nextProps.project.duAnId || ''
      const donVi = nextProps.project.donViId || ''
      const suDung = nextProps.project.isActive || false
      const donViId = []
      const phongBanId = []
      const phongBan = []
      const nhanVien = []
      const danhSachNhanVien = [[]]
      const isAdd = []

      let key = 0
      async.mapSeries(
        nextProps.project.dsThanhVien,
        (item, next) => {
          donViId.push(item.donViId)
          phongBanId.push(item.phongBanId)
          isAdd.push(1)

          item.danhSach.forEach(item => {
            if (!danhSachNhanVien[key]) {
              danhSachNhanVien[key] = []
            }
            return danhSachNhanVien[key].push({
              chuc_danh_id: item.chucDanhId,
              nhan_vien_id: item.nhanVienId,
            })
          })
          key++
          this.props.actions
            .getCategoryDepartmentMultiLevel(item.donViId)
            .then(res => {
              phongBan.push(res.payload.data.result)
            })
            .then(() => {
              this.props.actions
                .commonFetchStaffByDepartmentId(item.phongBanId)
                .then(res => {
                  nhanVien.push(res.payload.data.result.items)
                })
                .then(next)
            })
        },
        () => {
          if (this.props.match.path === PATH) {
            this.setState(
              {
                maDuAn,
                tenDuAn,
                suDung,
                donViId,
                donVi,
                danhSachNhanVien,
                phongBanId,
                phongBan,
                nhanVien,
                duAnId,
                isAdd,
              },
              () => {
                this.setState({
                  isLoadingData: {
                    ...this.state.isLoadingData,
                    maDuAn: false,
                    tenDuAn: false,
                    suDung: false,
                    donViId: false,
                    phongBanId: false,
                    chucDanhId: false,
                  },
                })
              }
            )
          }
        }
      )
    }
  }

  handleChange(e) {
    if (e.target.type === 'text') {
      this.setState({ [e.target.name]: e.target.value })
    }
    if (e.target.type === 'checkbox') {
      this.setState({ [e.target.name]: e.target.checked })
    }
  }

  handleCheckBox(i, chucDanh, nhanVien, e) {
    const value = e.target.checked
    const { danhSachNhanVien } = this.state

    if (value) {
      danhSachNhanVien[i].push({
        chuc_danh_id: chucDanh,
        nhan_vien_id: nhanVien,
      })
    } else {
      danhSachNhanVien[i].splice(
        _.findIndex(danhSachNhanVien[i], s => s.chuc_danh_id === chucDanh),
        1
      )
    }

    this.setState({ danhSachNhanVien })
  }

  handleSubmit = async e => {
    e.preventDefault()
    this.setState({ isLoading: true })
    const dsThanhVien = []
    const state = this.state
    let error = {}
    let errors = { donViId: [], phongBanId: [], chucDanhId: [] }
    for (let i = 0; i < state.isAdd.length; i++) {
      let chucDanh = {}
      chucDanh = {
        don_vi_id: state.donViId[i],
        phong_ban_id: state.phongBanId[i],
        danh_sach: state.danhSachNhanVien[i],
      }
      dsThanhVien.push(chucDanh)

      if (state.donViId && state.donViId[i] === undefined) {
        errors.donViId[i] = MESSAGE.TEXT_MESSAGE_DEFAULT
      } else {
        errors.donViId[i] = null
      }

      if (state.phongBanId && state.phongBanId[i] === undefined) {
        errors.phongBanId[i] = MESSAGE.TEXT_MESSAGE_DEFAULT
      } else {
        errors.phongBanId[i] = null
      }

      if (
        state.danhSachNhanVien &&
        state.danhSachNhanVien[i] &&
        state.danhSachNhanVien[i].length === 0
      ) {
        errors.chucDanhId[i] = MESSAGE.TEXT_MESSAGE_DEFAULT
      } else {
        errors.chucDanhId[i] = null
      }
    }
    await this.setState({ dsThanhVien })

    if (this.state.maDuAn.trim().length > 50) {
      error.maDuAn = MESSAGE.TEXT_MESSAGE_MAX_LENGHT
    }

    if (this.state.tenDuAn.trim().length > 1000) {
      error.tenDuAn = MESSAGE.TEXT_MESSAGE_MAX_LENGHT_NAME_PROJECT
    }

    if (this.state.tenDuAn.trim().length === 0) {
      error.tenDuAn = MESSAGE.TEXT_MESSAGE_DEFAULT
    }

    if (this.state.donVi.trim().length === 0) {
      error.donVi = MESSAGE.TEXT_MESSAGE_DEFAULT
    }

    this.setState({ error, errors })
    const error_donViId = _.compact(this.state.errors.error_donViId).length > 0
    const error_phongBanId =
      _.compact(this.state.errors.error_phongBanId).length > 0
    const error_chucDanhId = _.compact(this.state.errors.chucDanhId).length > 0
    if (
      Object.keys(error).length !== 0 ||
      error_donViId ||
      error_phongBanId ||
      error_chucDanhId
    ) {
      this.setState({ isLoading: false })
      return this.props.actions.commonAddToasterMessage({
        message: MESSAGE.TOATS_MESSAGE_WARNING,
        intent: Intent.WARNING,
      })
    }

    if (this.props.project) {
      this.props.actions
        .updateProjectItem(this.state.duAnId, {
          ma_du_an: this.state.maDuAn,
          ten_du_an: this.state.tenDuAn,
          is_active: this.state.suDung,
          don_vi_id: this.state.donVi,
          ds_thanh_vien: this.state.dsThanhVien,
        })
        .then(res => {
          if (
            res.payload &&
            res.payload.data.result &&
            res.payload.data.result === true
          ) {
            this.props.onSubmit && this.props.onSubmit(true)
            this.setState({ isLoading: false })
          } else if (
            res.error &&
            res.error.request &&
            res.error.request.status === 400
          ) {
            this.setState({ isLoading: false }, () => {
              this.props.actions.commonAddToasterMessage({
                message:
                  res.error.request.response &&
                  res.error.request.response.message,
                intent: Intent.DANGER,
              })
            })
          } else {
            this.setState({ isLoading: false }, () => {
              this.props.actions.commonAddToasterMessage({
                message: MESSAGE.TOATS_MESSAGE_FAIL,
                intent: Intent.DANGER,
              })
            })
          }
        })
    } else {
      this.props.actions
        .postCreateProject({
          ma_du_an: this.state.maDuAn,
          ten_du_an: this.state.tenDuAn,
          is_active: this.state.suDung,
          don_vi_id: this.state.donVi,
          ds_thanh_vien: this.state.dsThanhVien,
        })
        .then(res => {
          if (
            res.payload &&
            res.payload.data.result &&
            res.payload.data.result === true
          ) {
            this.props.onSubmit && this.props.onSubmit(true)
            this.setState({ isLoading: false })
          } else if (
            res.error &&
            res.error.request &&
            res.error.request.status === 400
          ) {
            this.setState({ isLoading: false }, () => {
              this.props.actions.commonAddToasterMessage({
                message:
                  res.error.request.response &&
                  res.error.request.response.message,
                intent: Intent.DANGER,
              })
            })
          } else {
            this.setState({ isLoading: false }, () => {
              this.props.actions.commonAddToasterMessage({
                message: MESSAGE.TOATS_MESSAGE_FAIL,
                intent: Intent.DANGER,
              })
            })
          }
        })
    }
  }

  handleGoBack() {
    this.props.onSubmit && this.props.onSubmit()
  }

  handleChangeSelect(type, i, e) {
    if (type === 'Department') {
      if (e !== undefined && this.state.phongBanId.indexOf(e) === -1) {
        this.setState({ loadingNhanVien: { [i]: true } }, () => {
          const state = this.state
          state.phongBanId[i] = e
          this.setState({ state, isData: false }, () => {
            this.props.actions.commonFetchStaffByDepartmentId(e).then(res => {
              if (res.error) {
                return this.setState({ loadingNhanVien: { [i]: false } })
              }
              if (
                res.payload &&
                res.payload.data &&
                res.payload.data.result &&
                res.payload.data.result.items
              ) {
                const data = res.payload.data.result.items
                const state = this.state
                state.nhanVien[i] = data
                state.danhSachNhanVien[i] = []
                this.setState({ state, loadingNhanVien: { [i]: false } })
              }
            })
          })
        })
      }
    }

    if (type === 'Unit') {
      if (e !== undefined) {
        this.setState({ loadingNhanVien: { [i]: true } }, () => {
          const state = this.state
          state.donViId[i] = e
          this.setState({ state, isData: false }, () => {
            this.props.actions
              .getCategoryDepartmentMultiLevel(this.state.donViId[i], false)
              .then(res => {
                if (res.error) {
                  return this.setState({ loadingNhanVien: { [i]: false } })
                } else if (res.payload && res.payload.data.result) {
                  const data = res.payload.data.result
                  const { phongBan, phongBanId } = this.state

                  let data_PhongBan

                  for (let i = 0; i < data.length; i++) {
                    if (
                      this.state.phongBanId.indexOf(data[i].phongBanId) === -1
                    ) {
                      data_PhongBan = data[i].phongBanId
                      break
                    }
                  }

                  if (!data_PhongBan) {
                    const state = this.state
                    state.phongBan[i] = []
                    state.phongBanId[i] = []
                    state.nhanVien[i] = []
                    state.danhSachNhanVien[i] = []
                    return this.setState({
                      state,
                      loadingNhanVien: { [i]: false },
                    })
                  }

                  phongBan[i] = data
                  phongBanId[i] = data_PhongBan
                  this.setState(
                    {
                      phongBan,
                      phongBanId,
                    },
                    () => {
                      this.props.actions
                        .commonFetchStaffByDepartmentId(data_PhongBan)
                        .then(res => {
                          if (res.error) {
                            return this.setState({
                              loadingNhanVien: { [i]: false },
                            })
                          }
                          if (
                            res.payload &&
                            res.payload.data &&
                            res.payload.data.result &&
                            res.payload.data.result.items
                          ) {
                            const data = res.payload.data.result.items
                            const state = this.state
                            state.nhanVien[i] = data
                            state.danhSachNhanVien[i] = []
                            this.setState({
                              state,
                              loadingNhanVien: { [i]: false },
                            })
                          }
                        })
                    }
                  )
                }
              })
          })
        })
      }
    }
  }

  handleSelectTree(e) {
    if (e !== undefined) {
      this.setState({ donVi: e })
    }
  }

  render() {
    return (
      <div className="systerm-main-content">
        <div className="form-container form-inline-container systerm-form-container">
          <form className="main-form systerm-form">
            <div className="form-block">
              <div className="form-subblock has-uline">
                <div className="form-row">
                  <div className="form-cols form-col5">
                    <div className="form-group-item pt-form-group pt-intent-danger">
                      <label
                        className="pt-label pt-label-inline"
                        htmlFor="maDuAn"
                      >
                        Mã quy trình
                      </label>
                      <div className="input-form-inline pt-form-content">
                        <input
                          type="text"
                          autoFocus={true}
                          value={this.state.maDuAn}
                          name="maDuAn"
                          onChange={this.handleChange}
                          className={classnames('pt-input', {
                            'loading-input':
                              Object.values(this.state.isLoadingData).find(
                                item => item === true
                              ) !== undefined,
                          })}
                          disabled={
                            Object.values(this.state.isLoadingData).find(
                              item => item === true
                            ) !== undefined
                          }
                          placeholder="Nhập mã quy trình"
                        />
                        {this.state.error.maDuAn !== undefined && (
                          <div className="pt-form-content pt-form-helper-text">
                            {this.state.error.maDuAn}
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="form-cols form-col5">
                    <div className="form-group-item pt-form-group pt-intent-danger">
                      <label
                        className="pt-label pt-label-inline"
                        htmlFor="tenDuAn"
                      >
                        Tên quy trình
                        <span className="pt-text-muted required-input">*</span>
                      </label>
                      <div className="input-form-inline pt-form-content">
                        <input
                          type="text"
                          value={this.state.tenDuAn}
                          name="tenDuAn"
                          onChange={this.handleChange}
                          className={classnames('pt-input', {
                            'loading-input':
                              Object.values(this.state.isLoadingData).find(
                                item => item === true
                              ) !== undefined,
                          })}
                          disabled={
                            Object.values(this.state.isLoadingData).find(
                              item => item === true
                            ) !== undefined
                          }
                          placeholder="Nhập tên quy trình"
                        />
                        {this.state.error.tenDuAn !== undefined && (
                          <div className="pt-form-content pt-form-helper-text">
                            {this.state.error.tenDuAn}
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="form-row">
                  <div className="form-cols form-col5">
                    <div className="form-group-item pt-form-group pt-intent-danger">
                      <label
                        className="pt-label pt-label-inline"
                        htmlFor="maDuAn"
                      >
                        Sử dụng
                      </label>
                      <div className="input-form-inline pt-form-content">
                        <Checkbox
                          name="suDung"
                          className="system-checkbox"
                          checked={this.state.suDung}
                          disabled={
                            Object.values(this.state.isLoadingData).find(
                              item => item === true
                            ) !== undefined
                          }
                          onChange={this.handleChange}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="form-cols form-col5">
                    <div className="form-group-item pt-form-group pt-intent-danger">
                      <label
                        className="pt-label pt-label-inline"
                        htmlFor="donvi"
                      >
                        Đơn vị
                        <span className="pt-text-muted required-input">*</span>
                      </label>
                      <div className="input-form-inline pt-form-content">
                        <SelectTree
                          className="select-tree"
                          name="donVi"
                          data={this.props.unit}
                          type="Unit"
                          permission={false}
                          value={
                            Object.values(this.state.isLoadingData).find(
                              item => item === true
                            ) === undefined
                              ? this.state.donVi
                              : ''
                          }
                          handleChange={this.handleSelectTree}
                          isLoading={
                            Object.values(this.state.isLoadingData).find(
                              item => item === true
                            ) !== undefined
                          }
                        />
                      </div>
                      {this.state.error.donVi !== undefined && (
                        <div className="pt-form-content pt-form-helper-text">
                          {this.state.error.donVi}
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {this.state.isAdd.map((item, i) => (
              <div className="form-block" key={i}>
                {i === 0 && <div className="title-content"> THÀNH VIÊN </div>}
                <div className="bg-grey">
                  {i > 0 && (
                    <span
                      className="pt-icon-close icon-close close-block-grey"
                      onClick={this.handleRemoveItem.bind(this, i)}
                    ></span>
                  )}
                  <div className="form-block form-block2">
                    <div className="form-subblock">
                      <div className="form-row">
                        <div className="form-cols form-col5">
                          <div className="form-group-item pt-form-group pt-intent-danger">
                            <label
                              className="pt-label pt-label-inline"
                              htmlFor="donvi"
                            >
                              Đơn vị
                              <span className="pt-text-muted required-input">
                                *
                              </span>
                            </label>
                            <div className="input-form-inline pt-form-content">
                              <SelectTree
                                className="select-tree"
                                name={`donViId-${i}`}
                                data={this.props.unit}
                                type="Unit"
                                permission={false}
                                value={
                                  Object.values(this.state.isLoadingData).find(
                                    item => item === true
                                  ) === undefined
                                    ? this.state.donViId[i]
                                    : ''
                                }
                                handleChange={this.handleChangeSelect.bind(
                                  this,
                                  'Unit',
                                  i
                                )}
                                isLoading={
                                  Object.values(this.state.isLoadingData).find(
                                    item => item === true
                                  ) !== undefined
                                }
                              />
                            </div>
                            {this.state.errors &&
                              this.state.errors.donViId &&
                              this.state.errors.donViId[i] !== undefined && (
                                <div className="pt-form-content pt-form-helper-text">
                                  {this.state.errors.donViId[i]}
                                </div>
                              )}
                          </div>
                        </div>
                        <div className="form-cols form-col5">
                          <div className="form-group-item pt-form-group pt-intent-danger">
                            <label
                              className="pt-label pt-label-inline"
                              htmlFor="phongban"
                            >
                              Phòng ban
                              <span className="pt-text-muted required-input">
                                *
                              </span>
                            </label>
                            <div className="input-form-inline pt-form-content">
                              <SelectTree
                                className="select-tree"
                                name={`phongBanId-${i}`}
                                data={
                                  this.state.phongBan && this.state.phongBan[i]
                                    ? this.state.phongBan[i]
                                    : []
                                }
                                type="Department"
                                permission={false}
                                disabled={this.state.phongBanId}
                                value={
                                  Object.values(this.state.isLoadingData).find(
                                    item => item === true
                                  ) === undefined
                                    ? this.state.phongBanId[i]
                                    : ''
                                }
                                handleChange={this.handleChangeSelect.bind(
                                  this,
                                  'Department',
                                  i
                                )}
                                isLoading={
                                  Object.values(this.state.isLoadingData).find(
                                    item => item === true
                                  ) !== undefined ||
                                  this.state.loadingNhanVien[i]
                                }
                              />
                            </div>
                            {this.state.errors &&
                              this.state.errors.phongBanId &&
                              this.state.errors.phongBanId[i] !== undefined && (
                                <div className="pt-form-content pt-form-helper-text">
                                  {this.state.errors.phongBanId[i]}
                                </div>
                              )}
                          </div>
                        </div>
                      </div>
                      <div className="form-row">
                        <div className="form-cols form-col-full">
                          <div className="form-group-item pt-form-group pt-intent-danger sectionbox-group-employees">
                            <label
                              className="pt-label pt-label-inline"
                              htmlFor="nhanVien"
                            >
                              Thành viên
                              <span className="pt-text-muted required-input">
                                *
                              </span>
                            </label>
                            <div className="input-form-inline pt-form-content">
                              <div className="selectbox-group">
                                {this.state.loadingNhanVien[i] && (
                                  <div className="loading-container">
                                    <LoadingItem />
                                  </div>
                                )}
                                {!this.state.loadingNhanVien[i] && (
                                  <div className="row checkboxgroup-container">
                                    {Object.values(
                                      this.state.isLoadingData
                                    ).find(item => item === true) ===
                                      undefined &&
                                    this.state.nhanVien &&
                                    this.state.nhanVien[i] &&
                                    this.state.nhanVien[i].length > 0 ? (
                                      this.state.nhanVien[i].map((item, k) => (
                                        <div
                                          className="col-xs-12 col-sm-6 col-md-4"
                                          key={k}
                                        >
                                          <div className="item-checkbox disabled-checkbox">
                                            <label className="pt-control pt-checkbox pt-inline">
                                              <input
                                                type="checkbox"
                                                className="pt-inline"
                                                name={`chucDanhId-${k}`}
                                                checked={
                                                  _.findIndex(
                                                    this.state.danhSachNhanVien[
                                                      i
                                                    ],
                                                    s =>
                                                      s.chuc_danh_id ===
                                                      item.chucDanhId
                                                  ) > -1
                                                }
                                                onChange={this.handleCheckBox.bind(
                                                  this,
                                                  i,
                                                  item.chucDanhId,
                                                  item.nhanVienId
                                                )}
                                              />
                                              <span className="pt-control-indicator" />
                                              <span className="name_checkbox">
                                                {item.tenNhanVien}
                                              </span>
                                            </label>
                                          </div>
                                        </div>
                                      ))
                                    ) : (
                                      <div className="col-xs-12 col-sm-12 col-md-12">
                                        <div className="item-checkbox disabled-checkbox container-messenger-not-data">
                                          <label className="pt-control pt-checkbox pt-inline">
                                            {Object.values(
                                              this.state.isLoadingData
                                            ).find(item => item === true) ===
                                              undefined && (
                                              <span className="name_checkbox messenger-not-data">
                                                Không có nhân viên trong phòng
                                                ban
                                              </span>
                                            )}
                                          </label>
                                        </div>
                                      </div>
                                    )}
                                  </div>
                                )}
                              </div>
                              {this.state.errors &&
                                this.state.errors.chucDanhId &&
                                this.state.errors.chucDanhId[i] !==
                                  undefined && (
                                  <div className="pt-form-content pt-form-helper-text">
                                    {this.state.errors.chucDanhId[i]}
                                  </div>
                                )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ))}
            <div className="form-block">
              <div className="form-subblock">
                <div className="form-group-item">
                  <Button
                    className="btn-add-more"
                    onClick={this.onAddBtnClick}
                    loading={this.state.loadingAdd}
                  >
                    <span className="text-content">Thêm thành viên</span>
                    <span className="icon-bc icon-Plus"></span>
                  </Button>
                </div>
              </div>
            </div>
            <div className="form-block bottom-form">
              <div className="buttton-action-footer">
                <Button
                  type="button"
                  className="pt-button pt-button btn-cancel"
                  onClick={this.handleGoBack}
                  disabled={this.state.isLoading}
                >
                  <span className=" pt-icon icon-back"></span>
                  <span className="text-content">Quay lại</span>
                </Button>
                <Button
                  type="button"
                  className="pt-button pt-button btn-blue-color"
                  disabled={
                    Object.values(this.state.isLoadingData).find(
                      item => item === true
                    ) !== undefined
                  }
                  loading={this.state.isLoading}
                  onClick={this.handleSubmit}
                >
                  <span className="text-content">Lưu</span>
                  <span className="pt-icon icon-save"></span>
                </Button>
              </div>
            </div>
          </form>
        </div>
      </div>
    )
  }
}

export default AddProjectCategory
