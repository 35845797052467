import React from 'react'
import { PopupConfirm } from '../../components/common'
import {
  SortableContainer,
  SortableHandle,
  SortableElement,
  arrayMove,
} from 'react-sortable-hoc'
import { Checkbox } from '@blueprintjs/core'
import { Popover, Position, PopoverInteractionKind } from '@blueprintjs/core'
import * as POPUP from '../../constants/Popup'
import { Link } from 'react-router-dom'
import { Intent } from '@blueprintjs/core'
import * as MESSAGER from '../../constants/MessageForm'
import { Utils } from '../../helpers'
import { HeadingTableSort } from '../../components/common'
import { headers } from '../../constants/Table'
import { MESSAGE_NOT_FOUND, MESSAGE_ERROR } from '../../constants/MessageForm'

const LINK_REDIRECT = '/he-thong/phong-ban/danh-muc'
const LINK_EDIT = '/he-thong/phong-ban/chinh-sua'
class DepartmentCategory extends React.Component {
  constructor(props) {
    super(props)
    this.handleClickPopup = this.handleClickPopup.bind(this)
    this.handleSubmitRemoveItem = this.handleSubmitRemoveItem.bind(this)
    this.handleEnabledChange = this.handleEnabledChange.bind(this)
    this.handleClick = this.handleClick.bind(this)
  }

  state = {
    isOpenPopup: false,
    isEnabled: false,
    phongBanId: null,
    maPhongBan: null,
    tenPhongBan: null,
    phongBanCha: null,
    donViId: null,
    phongBanLanhDao: undefined,
    maKySo: null,
    stt: null,
    isActive: undefined,
    items: [],
    isMove: false,
    isEmpty: false,
    isError: false,
  }

  handleClickPopup = id => {
    this.setState({
      isOpenPopup: !this.state.isOpenPopup,
      error: '',
      phongBanId: id,
    })
  }

  onSortEnd = ({ oldIndex, newIndex }) => {
    if (oldIndex !== newIndex) {
      this.setState(
        {
          items: arrayMove(this.state.items, oldIndex, newIndex),
          isMove: true,
        },
        () => {
          const array = []
          this.state.items.map(item => array.push(item.phongBanId))
          if (array.length > 0 && this.state.isMove === true) {
            this.props.actions
              .updateArrayCommonFetchDepartment(array)
              .then(res => {
                if (
                  res.payload &&
                  res.payload.data.result &&
                  res.payload.data.result === true
                ) {
                  if (this.props.match.params.id === undefined) {
                    this.props.actions
                      .commonFetchDepartments(1, this.props.donViId, true)
                      .then(res => {
                        if (
                          res.error ||
                          (res.payload && res.payload.status !== 200)
                        ) {
                          this.setState({ isError: true })
                        }
                      })
                  } else if (this.props.match.params.id !== undefined) {
                    this.props.actions
                      .getCategoryDepartmentChild(
                        this.props.match.params.id,
                        true
                      )
                      .then(res => {
                        if (
                          res.error ||
                          (res.payload && res.payload.status !== 200)
                        ) {
                          this.setState({ isError: true })
                        }
                      })
                  }
                  this.setState({ isMove: false })
                  return this.props.actions.commonAddToasterMessage({
                    message: MESSAGER.TOATS_MESSAGE_SUCCESS,
                    intent: Intent.SUCCESS,
                  })
                } else {
                  this.setState({
                    isMove: false,
                    items: arrayMove(this.state.items, newIndex, oldIndex),
                  })
                  return this.props.actions.commonAddToasterMessage({
                    message: MESSAGER.TOATS_MESSAGE_FAIL,
                    intent: Intent.DANGER,
                  })
                }
              })
          }
        }
      )
    }
  }

  handleEnabledChange = (value, e) => {
    this.setState({ isEnabled: false })
    if (e.target.type === 'checkbox') {
      this.setState({
        isActive: e.target.checked,
        phongBanCha: value.phongBanCha,
        donViId: value.donViId,
        phongBanId: value.phongBanId,
        maPhongBan: value.maPhongBan,
        tenPhongBan: value.tenPhongBan,
        stt: value.stt,
        phongBanLanhDao: value.phongBanLanhDao,
        maKySo: value.maKySo,
        isEnabled: true,
      })
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      prevState.isActive !== this.state.isActive ||
      prevState.phongBanId !== this.state.phongBanId
    ) {
      if (this.state.isEnabled === true) {
        this.props.actions
          .updateCommonFetchDepartment({
            phong_ban_id: this.state.phongBanId,
            ma_phong_ban: this.state.maPhongBan,
            ten_phong_ban: this.state.tenPhongBan,
            phong_ban_cha: this.state.phongBanCha,
            don_vi_id: this.state.donViId,
            phong_ban_lanh_dao: this.state.phongBanLanhDao,
            ma_ky_so: this.state.maKySo,
            stt: this.state.stt,
            is_active: this.state.isActive,
          })
          .then(res => {
            if (
              res.payload &&
              res.payload.data.result &&
              res.payload.data.result === true
            ) {
              if (this.props.match.params.id === undefined) {
                this.props.actions
                  .commonFetchDepartments(1, this.props.donViId, true)
                  .then(res => {
                    if (
                      res.error ||
                      (res.payload && res.payload.status !== 200)
                    ) {
                      this.setState({ isError: true })
                    }
                  })
              } else if (this.props.match.params.id !== undefined) {
                this.props.actions
                  .getCategoryDepartmentChild(this.props.match.params.id, true)
                  .then(res => {
                    if (
                      res.error ||
                      (res.payload && res.payload.status !== 200)
                    ) {
                      this.setState({ isError: true })
                    }
                  })
              }
              this.setState({ isEnabled: false })
              return this.props.actions.commonAddToasterMessage({
                message: MESSAGER.TOATS_MESSAGE_SUCCESS,
                intent: Intent.SUCCESS,
              })
            } else {
              this.setState({ isEnabled: false })
              return this.props.actions.commonAddToasterMessage({
                message: MESSAGER.TOATS_MESSAGE_FAIL,
                intent: Intent.DANGER,
              })
            }
          })
      }
    }
  }

  handleSubmitRemoveItem = () => {
    if (this.state.phongBanId) {
      this.props.actions
        .deleteCommonFetchDepartment(this.state.phongBanId)
        .then(res => {
          if (res.payload && res.payload.data.result === true) {
            if (this.props.match.params.id === undefined) {
              this.props.actions
                .commonFetchDepartments(1, this.props.donViId, true)
                .then(res => {
                  if (
                    res.error ||
                    (res.payload && res.payload.status !== 200)
                  ) {
                    this.setState({ isError: true })
                  }
                })
            } else if (this.props.match.params.id !== undefined) {
              this.props.actions
                .getCategoryDepartmentChild(this.props.match.params.id, true)
                .then(res => {
                  if (
                    res.error ||
                    (res.payload && res.payload.status !== 200)
                  ) {
                    this.setState({ isError: true })
                  }
                  let header = Utils.getData(
                    'DepartmentCategoryPage-Header',
                    []
                  )
                  if (res.payload && res.payload.data.result.length === 0) {
                    header.splice(header.length - 1)
                    this.props.saveHeader && this.props.saveHeader(header)
                    Utils.saveData('DepartmentCategoryPage-Header', header)
                    if (header.length === 1) {
                      this.props.history.push(`${LINK_REDIRECT}`)
                      return this.props.actions
                        .commonFetchDepartments(1, this.props.donViId, true)
                        .then(res => {
                          if (
                            res.error ||
                            (res.payload && res.payload.status !== 200)
                          ) {
                            this.setState({ isError: true })
                          }
                        })
                    }
                    this.props.history.push(
                      `${LINK_REDIRECT}/${header[header.length - 1].id}`
                    )
                  }
                })
            }
            return this.props.actions.commonAddToasterMessage({
              message: MESSAGER.TOATS_MESSAGE_DELETE_SUCCESS,
              intent: Intent.SUCCESS,
            })
          } else {
            this.props.actions.commonAddToasterMessage({
              message: MESSAGER.TOATS_MESSAGE_DELETE_FAIL,
              intent: Intent.DANGER,
            })
          }
        })
    }

    this.setState({
      isOpenPopup: false,
    })
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.items !== this.props.items) {
      this.setState({
        items: nextProps.items,
      })
      if (nextProps.items.length === 0) {
        this.setState({
          isEmpty: true,
        })
      }
      if (nextProps.items.length > 0) {
        this.setState({
          isEmpty: false,
        })
      }
    }
  }

  handleClick(id) {
    this.props.onSubmit && this.props.onSubmit(id)
  }

  render() {
    let ItemLoading = (
      <div className="loading-content">
        <span className="shape1"></span>
        <span className="shape2"></span>
        <span className="shape3"></span>
        <span className="shape4"></span>
        <span className="shape5"></span>
        <span className="shape6"></span>
        <span className="shape7"></span>
        <span className="shape8"></span>
        <span className="shape9"></span>
        <span className="shape10"></span>
        <span className="shape11"></span>
        <span className="shape12"></span>
        <span className="shape13"></span>
      </div>
    )
    //icon move
    const DragHandle = SortableHandle(() => (
      <span className="icon-move-drap"></span>
    ))
    //items move
    const SortableItem = SortableElement(({ i, value }) => {
      return (
        <div className="table-rows">
          <div className="table-cols-content width-col-5">
            <DragHandle />
          </div>
          <div className="table-cols-content width-col-5">
            <span className="order-content">{i < 10 ? '0' + i : i}</span>
          </div>
          <div className="table-cols-content width-col-30">
            {value.soPhongBanCon > 0 && (
              <Link
                className="text-tree-push-blue"
                to={`${LINK_REDIRECT}/${value.phongBanId}`}
                onClick={this.handleClick.bind(this, value.phongBanId)}
              >
                {value.maPhongBan}
              </Link>
            )}
            {value.soPhongBanCon === 0 && (
              <span className="text-tree-push">{value.maPhongBan}</span>
            )}
          </div>
          <div className="table-cols-content width-col-30">
            <span className="order-content">{value.tenPhongBan}</span>
          </div>
          <div className="table-cols-content width-col-20">
            <span className="text-content">
              <Checkbox
                className="checkbox-item"
                onChange={this.handleEnabledChange.bind(this, value)}
                defaultChecked={value.isActive}
              />
            </span>
          </div>
          <div className="table-cols-content width-col-10">
            <Popover
              popoverClassName="pt-popover-content-sizing pt-minimal"
              position={Position.BOTTOM_RIGHT}
              interactionKind={PopoverInteractionKind.HOVER}
              openOnTargetFocus={true}
              content={
                <div className="menu-popover">
                  <Link
                    className="item"
                    to={`${LINK_EDIT}/${value.phongBanId}`}
                  >
                    <span className="pt-icon pt-icon-annotation"></span>
                    <span className="name_action">Chỉnh sửa</span>
                  </Link>
                  <span
                    className="item"
                    onClick={this.handleClickPopup.bind(this, value.phongBanId)}
                  >
                    <span className="pt-icon pt-icon-trash"></span>
                    <span className="name_action">Xóa</span>
                  </span>
                </div>
              }
            >
              <span className="icon-More"></span>
            </Popover>
          </div>
        </div>
      )
    })
    //list foreach
    const SortableList = SortableContainer(({ items }) => {
      return (
        <div className="sortableList">
          {items.map((value, index) => (
            <SortableItem
              key={`item-${index}`}
              i={index + 1}
              index={index}
              value={value}
            />
          ))}
        </div>
      )
    })
    return (
      <div className="page-list-container page-list-role-container">
        <div className="table-container table-subcollap-container">
          <div className="table-scroll">
            <div className="collapse-panel-content">
              <div className="table-display">
                <div className="table-display-container">
                  {this.props.items.length > 0 &&
                    !this.props.isError &&
                    !this.state.isError && (
                      <HeadingTableSort data={headers.departmentCategory} />
                    )}
                  <div className="table-body-container">
                    {this.props.isLoading ? (
                      <div className="table-loading-system">
                        {ItemLoading}
                        {ItemLoading}
                        {ItemLoading}
                      </div>
                    ) : (
                      !this.props.isError &&
                      !this.state.isError && (
                        <SortableList
                          helperClass="SortableHelper"
                          items={this.state.items}
                          onSortEnd={this.onSortEnd}
                          useDragHandle={true}
                          lockAxis="y"
                        />
                      )
                    )}
                    {this.state.isEmpty &&
                      !this.props.isLoading &&
                      (!this.props.isError || !this.state.isError) && (
                        <div className="error-page-container">
                          <div className="error-content">
                            <img
                              src={
                                process.env.PUBLIC_URL +
                                '/images/search-result-not-found.png'
                              }
                              alt="search-result-not-found"
                              className="img-thumb-error"
                            />
                            <p className="description-note">
                              {MESSAGE_NOT_FOUND}
                            </p>
                          </div>
                        </div>
                      )}
                    {(this.props.isError || this.state.isError) &&
                      !this.props.isLoading && (
                        <div className="error-page-container">
                          <div className="error-content">
                            <img
                              src={
                                process.env.PUBLIC_URL + '/images/Server.png'
                              }
                              alt="error"
                              className="img-thumb-error"
                            />
                            <p className="description-note">{MESSAGE_ERROR}</p>
                          </div>
                        </div>
                      )}
                    {/* <ListFooter /> */}
                    <PopupConfirm
                      isOpen={this.state.isOpenPopup}
                      onClose={this.handleClickPopup}
                      title={POPUP.XOA_DANH_MUC}
                      text={POPUP.TEXT_BUTTON_SUBMIT}
                      onSubmit={this.handleSubmitRemoveItem}
                    >
                      {POPUP.TEXT_SUBMIT_BODY}
                    </PopupConfirm>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default DepartmentCategory
