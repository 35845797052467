import React, { memo } from 'react'
import classNames from 'classnames'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import UIButton from 'components/ui/button'
import PopupModal from 'components/common/PopupModal'
import ProfileEdit from './edit'
import useProfile from './use-profile'

const Profile = ({ auth, onClose }) => {
  const { avatar, profileInfos, isEdit, setIsEdit, onLink } = useProfile({
    auth,
    onClose,
  })
  return (
    <PopupModal
      isOpen={true}
      title="Thông tin cá nhân"
      contentClass="width-400"
      onClose={onClose}
    >
      {isEdit ? (
        <ProfileEdit onClose={() => setIsEdit(false)} />
      ) : (
        <div className="profile-wrapper">
          <div className="p20">
            <div className="d-flex flex-direction-column align-items-center gap-10">
              <div
                className="avatar"
                style={{ backgroundImage: `url(${avatar})` }}
              ></div>
              <p className="text-16-700-22">{auth.user.tenNhanVien}</p>
            </div>
            <ul className="d-flex flex-direction-column gap-15 pv10 mt30">
              {profileInfos?.map((elm, index) => {
                return (
                  <li key={index} className="d-flex align-items-center gap-5">
                    <span
                      className={classNames(
                        'icon text-center color-blue',
                        elm?.icon
                      )}
                      style={{ width: 18 }}
                    ></span>
                    <span className="text-14-600-21 white-space-nowrap">
                      {elm?.name}
                    </span>
                    <span
                      className={classNames(
                        'text-14-300-21 word-break-word flex-1',
                        {
                          'color-blue cursor-pointer': elm?.link,
                        }
                      )}
                      onClick={() => (elm?.link ? onLink(elm) : false)}
                    >
                      {elm?.value}
                    </span>
                  </li>
                )
              })}
            </ul>
          </div>
          <div
            className={
              'd-flex align-items-center justify-content-center gap-15 bg-white border-radius-4 border-top ph15 pv20'
            }
          >
            <UIButton
              icon="icon-Huy font-size-10"
              text="HỦY"
              theme="grey-border"
              color="text-grey"
              textSize={12}
              isReverse
              onClick={onClose}
            />
            <UIButton
              icon="icon-edit-v2 font-size-14"
              text="CHỈNH SỬA THÔNG TIN"
              color="blue"
              textSize={12}
              isDisabled={false}
              isLoading={false}
              onClick={() => setIsEdit(true)}
            />
          </div>
        </div>
      )}
    </PopupModal>
  )
}

Profile.defaultProps = {
  actions: null,
  auth: null,
  onClose: () => {},
}

Profile.propTypes = {
  actions: PropTypes.object,
  auth: PropTypes.object,
  onClose: PropTypes.func,
}

const mapStateToProps = state => {
  return {
    auth: state.auth,
  }
}

export default connect(mapStateToProps, null)(memo(Profile))
