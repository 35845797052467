import React from 'react'
import PropTypes from 'prop-types'
import { bindActionCreators } from 'redux'
import moment from 'moment'
import { Redirect } from 'react-router-dom'
import { connect } from 'react-redux'
import { Intent } from '@blueprintjs/core'

import { MasterLayout } from '../../components/layout'
import {
  ListFilterAssigned,
  ListItemAssigned,
} from '../../components/Work/DepartmentWork'
import { GridViewFilter } from '../../components/common'
import { headers } from '../../constants/Table'
import * as Actions from '../../actions'
import * as Types from '../../constants/Api'
import * as MESSAGE from '../../constants/MessageForm'
import * as Tool from '../../helpers'
import NoteFooter from './cong-viec/note-footer'
import Pagination from 'components/Pagination'

// const DA_GIAO = 1;
const REFRESH_FALSE = false
class DepartmentWorkAssigned extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      filter: {},
      filterColumn: {},
      sort: null,
      listStatus: [
        {
          name: 'Chưa xử lý',
          value: 0,
        },
        {
          name: 'Đang xử lý',
          value: 1,
        },
        {
          name: 'Đã xử lý',
          value: 2,
        },
      ],
      isEmpty: false,
      page: 1,
      isLoading: false,
      workExpiringSoon: 0,
      workExpired: 0,
      isRedirect: false,
      redirectTo: null,
      fileId: null,
      soCongVan: null,
      isError: false,
      styleFilter: null,
    }

    this.handleSubmitRemoveItem = this.handleSubmitRemoveItem.bind(this)
    this.clickPagination = this.clickPagination.bind(this)
    this.submitFilter = this.submitFilter.bind(this)
    this.handleSort = this.handleSort.bind(this)
    this.handleClickDetail = this.handleClickDetail.bind(this)
    this.onChangeGridViewFilter = this.onChangeGridViewFilter.bind(this)
    this.onChangeDateGridViewFilter = this.onChangeDateGridViewFilter.bind(this)
    this.clearFilterColumn = this.clearFilterColumn.bind(this)
    this.onRemoveText = this.onRemoveText.bind(this)
    this.handleDateChange = this.handleDateChange.bind(this)
  }

  clickPagination(page) {
    this.setState({ page }, () => {
      this.submitFilter({}, page, this.state.sort)
    })
  }

  componentWillReceiveProps(props) {
    if (props.departmentWorkTotals) {
      this.setState({
        workExpiringSoon: props.departmentWorkTotals.workExpiringSoon || 0,
        workExpired: props.departmentWorkTotals.workExpired || 0,
      })
    }
  }

  submitFilter(e = {}, page = 1, sort = null) {
    const { styleFilter } = this.state
    const filter = Object.assign({}, this.state.filter, e)
    let isCallRequestAgain = false
    const filterColumn = {
      ...this.state.filterColumn,
      hanGiaiQuyet: this.state.filterColumn.hanGiaiQuyet
        ? moment(this.state.filterColumn.hanGiaiQuyet).format('YYYY-MM-DD')
        : null,
      giaoViec:
        this.state.filterColumn.giaoViec &&
        this.state.filterColumn.giaoViec !== ''
          ? this.state.filterColumn.giaoViec
          : null,
      trangThai:
        this.state.filterColumn.trangThai &&
        this.state.filterColumn.trangThai !== ''
          ? this.state.filterColumn.trangThai
          : null,
      vaiTro: this.state.filterColumn.vaiTro,
      chuTri:
        this.state.filterColumn.chuTri && this.state.filterColumn.chuTri !== ''
          ? this.state.filterColumn.chuTri
          : null,
      canBaoCao:
        this.state.filterColumn.canBaoCao &&
        this.state.filterColumn.canBaoCao !== ''
          ? this.state.filterColumn.canBaoCao
          : null,
      trichYeu:
        this.state.filterColumn.trichYeu &&
        this.state.filterColumn.trichYeu !== ''
          ? this.state.filterColumn.trichYeu
          : null,
      soCongVan:
        this.state.filterColumn.soCongVan &&
        this.state.filterColumn.soCongVan !== ''
          ? this.state.filterColumn.soCongVan
          : null,
    }
    const filterColumn2 = {
      ...filterColumn,
      vaiTro:
        filterColumn.vaiTro === '1'
          ? true
          : filterColumn.vaiTro === '0'
          ? false
          : null,
    }

    this.setState({ filter, sort, isLoading: true, page }, () => {
      Tool.Utils.preData('DepartmentWorkAssigned', {
        filter,
        page,
        sort,
        filterColumn,
        styleFilter,
      })
      // tạm thời để refresh false
      this.props.actions
        .departmentWorkAssignedGet(
          { filter },
          page,
          sort,
          false,
          REFRESH_FALSE,
          filterColumn2
        )
        .then(res => {
          if (
            page > 1 &&
            res.payload &&
            res.payload.data &&
            res.payload.data.result &&
            res.payload.data.result.result &&
            res.payload.data.result.result.total === 0
          ) {
            isCallRequestAgain = true
            return this.submitFilter(e, page, sort)
          }

          let isEmpty = false
          let isError = false
          if (
            !res.error &&
            res.payload &&
            res.payload.data &&
            res.payload.data.result &&
            res.payload.data.result.result &&
            res.payload.data.result.result.total === 0
          ) {
            isEmpty = true
          }

          if (res.error || (res.payload && res.payload.status !== 200)) {
            isError = true
          }

          this.setState({ isEmpty, isError })
        })
        .then(() => {
          if (isCallRequestAgain === false) {
            this.setState({ isLoading: false })
          }

          this.props.actions.refreshPage(false)
        })
    })
  }

  handleSubmitRemoveItem = id => {
    this.props.actions.commonAddToasterMessage({
      message: MESSAGE.TOATS_MESSAGE_DELETE_SUCCESS,
      intent: Intent.SUCCESS,
    })
  }

  handleSort(sort) {
    this.submitFilter({}, this.state.page, sort)
  }

  handleClickDetail = data => {
    if (!data) {
      return
    }

    if (!data?.daXem && data.congViecPhongBanId) {
      this.props.actions.postXemCongViecPhongBan(data.congViecPhongBanId)
    }

    this.setState({
      isRedirect: true,
      redirectTo: `/cong-viec/phong-ban/chi-tiet/da-giao/${data.congViecId}`,
      fileId: data.fileId,
      soCongVan: data.soCongVan,
    })
  }

  componentDidMount() {
    this.props.actions.commonFetchStaffByDepartmentId(
      this.props.auth.mainDepartmentId
    )
    let preData = Tool.Utils.preData('DepartmentWorkAssigned')
    let state = this.state
    if (preData) {
      state.filter = preData.filter
      state.page = preData.page
      state.sort = preData.sort
      state.filterColumn = {
        ...preData.filterColumn,
        hanGiaiQuyet:
          preData.filterColumn && preData.filterColumn.hanGiaiQuyet
            ? moment(preData.filterColumn.hanGiaiQuyet)
            : null,
      }
      state.styleFilter = preData.styleFilter
    } else {
      state.filter.ngayGiaoViec = {
        from: moment().subtract(1, 'months').format('YYYY-MM-DD'),
        to: moment().format('YYYY-MM-DD'),
      }
    }

    this.setState(state, () => {
      this.clickPagination(this.state.page)
    })
  }

  onChangeGridViewFilter(e) {
    const value = e.target.value
    const name = e.target.name
    this.setState(
      {
        filterColumn: {
          ...this.state.filterColumn,
          [name]: value,
        },
        // Thay đổi màu xanh nếu chọn vai trò chủ trì
        styleFilter: {
          vaiTro: {
            color: name === 'vaiTro' && value === '1' ? '#195FFF' : '#000000',
          },
        },
      },
      () => {
        if (
          name === 'canBaoCao' ||
          name === 'chuTriId' ||
          name === 'trangThai' ||
          name === 'vaiTro'
        ) {
          this.submitFilter()
        }
      }
    )
  }

  onChangeDateGridViewFilter(name, e) {
    this.setState(
      {
        filterColumn: {
          ...this.state.filterColumn,
          [name]: e,
        },
      },
      () => {
        this.submitFilter()
      }
    )
  }

  handleDateChange(name, pos, e) {
    this.setState(
      {
        filter: {
          ...this.state.filter,
          [name]: {
            ...this.state.filter[name],
            [pos]: e ? moment(e).format('YYYY-MM-DD') : undefined,
          },
        },
      },
      () => {
        this.submitFilter()
      }
    )
  }

  async clearFilterColumn(filter, check) {
    if (check) {
      await this.setState({
        filterColumn: {},
        styleFilter: null,
      })
    }

    await this.submitFilter(filter)
  }

  onRemoveText(name) {
    this.setState(
      {
        filterColumn: {
          ...this.state.filterColumn,
          [name]: '',
        },
      },
      () => {
        this.submitFilter()
      }
    )
  }

  render() {
    const { styleFilter } = this.state
    if (this.state.isRedirect) {
      return (
        <Redirect
          push
          to={{
            pathname: this.state.redirectTo,
            state: {
              fileId: this.state.fileId,
              soCongVan: this.state.soCongVan,
              from: this.props.location.pathname,
            },
          }}
        />
      )
    }

    return (
      <MasterLayout typeSidebar="work">
        <div>
          {/* Heading */}
          <div className="heading-page">
            <div className="row">
              <div className="col-xs-12">
                <h3 className="text-heading-page">
                  <span className="icon-Da_Giao icon-heading"></span>
                  <span className="text-heading">Công việc đã giao</span>
                </h3>
              </div>
            </div>
          </div>
          {/* Table List */}
          <div className="page-list-container">
            <ListFilterAssigned
              filter={this.state.filter}
              staffs={this.props.staffs}
              status={this.state.listStatus}
              onSubmit={this.submitFilter}
              workExpired={this.state.workExpired}
              workExpiringSoon={this.state.workExpiringSoon}
              readOnly={this.props.readOnly}
              actions={this.props.actions}
              clearFilterColumn={this.clearFilterColumn}
              handleDateChange={this.handleDateChange}
            />
            <div
              className="table-container"
              style={{
                paddingBottom: 0,
                border: 0,
                background:
                  Math.ceil(
                    this.props.departmentWorksAssigned.list.total /
                      Types.PAGINATION_LIMIT
                  ) > 1
                    ? '#fff'
                    : '#eff3f5',
              }}
            >
              <GridViewFilter
                isLoading={this.state.isLoading}
                classNames={'pt-table fix-table-before'}
                headers={headers.listDepartmentWorkAssigned}
                itemShape={ListItemAssigned}
                data={this.props.departmentWorksAssigned.list.items}
                onClickDetail={this.handleClickDetail}
                onHandleSubmitRemoveItem={this.handleSubmitRemoveItem}
                onFilter={this.handleSort}
                page={this.state.page ? this.state.page : 1}
                isEmpty={this.state.isEmpty}
                auth={this.props.auth}
                isError={this.state.isError}
                onChange={this.onChangeGridViewFilter}
                onChangeDate={this.onChangeDateGridViewFilter}
                coQuanBanHanhId={this.state.listStatus}
                filterColumn={this.state.filterColumn}
                onSubmit={this.submitFilter}
                onRemoveText={this.onRemoveText}
                pageCount={Math.ceil(
                  this.props.departmentWorksAssigned.list.total /
                    Types.PAGINATION_LIMIT
                )}
                chuTriId={this.props.staffs}
                trangThai={this.state.listStatus}
                isType="CVPB"
                isCVPBDG={true}
                styleFilter={styleFilter}
                footerNote={
                  <NoteFooter
                    pageCount={Math.ceil(
                      this.props.departmentWorksAssigned.list.total /
                        Types.PAGINATION_LIMIT
                    )}
                    isCVPBCG={false}
                    isCVPBDG={true}
                  />
                }
              />
              {!this.state.isLoading &&
                !this.state.isEmpty &&
                !this.state.isError && (
                  <Pagination
                    current={this.state.page}
                    total={Math.ceil(
                      this.props.departmentWorksAssigned.list.total /
                        Types.PAGINATION_LIMIT
                    )}
                    onChange={this.clickPagination}
                  />
                )}
            </div>
          </div>
        </div>
      </MasterLayout>
    )
  }
}

DepartmentWorkAssigned.propTypes = {
  auth: PropTypes.object.isRequired,
  departmentWorksAssigned: PropTypes.object.isRequired,
}

const mapStateToProps = state => ({
  config: state.common.config,
  auth: {
    ...state.auth,
  },
  departmentWorksAssigned: {
    ...state.departmentWorksAssigned,
    list: {
      ...state.departmentWorksAssigned.list,
      items: state.departmentWorksAssigned.list.items.map(
        id => state.entities.departmentWorksAssigned[id]
      ),
    },
  },
  staffs: state.common.staffs,
  departmentWorkTotals: state.common.departmentWorkTotals,
  readOnly: state.common.enableReadOnly,
})

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(Actions, dispatch),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DepartmentWorkAssigned)
