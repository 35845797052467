import React from 'react'
import PropTypes from 'prop-types'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { Intent } from '@blueprintjs/core'
import moment from 'moment'
import classnames from 'classnames'
import { Redirect } from 'react-router-dom'
import { get } from 'lodash'

import { MasterLayout } from '../../components/layout'
import { ListFilter, ListItem } from '../../components/Work/PersonalWork'
import { GridViewFilter } from '../../components/common'
import { headers } from '../../constants/Table'
import * as Actions from '../../actions'
import * as Types from '../../constants/Api'
import * as MESSAGE from '../../constants/MessageForm'
import * as Tool from '../../helpers'
import NoteFooter from './cong-viec/note-footer'
import Pagination from 'components/Pagination'

const CHUA_XU_LY = 0
const DANG_XU_LY = 1
const CONG_VAN_NOI_BO = 2
const REFRESH_FALSE = false
class ListPersonalWorkPage extends React.Component {
  constructor(props) {
    super(props)
    let { status } = props.match.params
    status = this.getStatusNumber(status)
    this.state = {
      sort: undefined,
      filter: {
        trangThai: status,
      },
      filterColumn: {},
      list: [],
      page: 1,
      openFilter: undefined,
      isEmpty: false,
      isLoading: false,
      type: 'chua-thuc-hien',
      workExpired: 0,
      workExpiringSoon: 0,
      isRedirect: false,
      redirectTo: null,
      fileId: null,
      soCongVan: null,
      isError: false,
      showSection: 'noContent',
      styleFilter: null,
    }

    this.handleSubmitRemoveItem = this.handleSubmitRemoveItem.bind(this)
    this.clickPagination = this.clickPagination.bind(this)
    this.submitFilter = this.submitFilter.bind(this)
    this.handleSort = this.handleSort.bind(this)
    this.handleClickDetail = this.handleClickDetail.bind(this)
    this.onChangeGridViewFilter = this.onChangeGridViewFilter.bind(this)
    this.onChangeDateGridViewFilter = this.onChangeDateGridViewFilter.bind(this)
    this.clearFilterColumn = this.clearFilterColumn.bind(this)
    this.onRemoveText = this.onRemoveText.bind(this)
    this.handleResetCheck = this.handleResetCheck.bind(this)
    this.removeTextSearch = this.removeTextSearch.bind(this)
    this.handleDateChange = this.handleDateChange.bind(this)
  }

  componentDidMount() {
    let state = this.state
    this.props.actions.commonFetchDepartmentsLeader()
    this.props.actions.commonFetchUnitsRelative(this.props.auth.mainUnitId)
    let preData = Tool.Utils.preData(
      `ListPersonalWorkPage-${this.state.filter.trangThai}`
    )
    if (preData) {
      state.filter = preData.filter
      state.page =
        (preData?.page?.selected || preData?.page) > 0
          ? preData?.page?.selected || preData?.page
          : 1
      state.sort = preData.sort
      state.filterColumn = {
        ...preData.filterColumn,
        hanGiaiQuyet:
          preData.filterColumn && preData.filterColumn.hanGiaiQuyet
            ? moment(preData.filterColumn.hanGiaiQuyet)
            : null,
        coQuanBanHanhId:
          preData.filterColumn && preData.filterColumn.coQuanBanHanhId
            ? preData.filterColumn.coQuanBanHanhId
            : '',
        coQuanBanHanh:
          preData.filterColumn && preData.filterColumn.coQuanBanHanhId === '#'
            ? preData.filterColumn.coQuanBanHanh
            : null,
      }
      state.styleFilter = preData.styleFilter
    } else {
      if (
        state.filter.trangThai === CHUA_XU_LY ||
        state.filter.trangThai === DANG_XU_LY
      ) {
        state.filter.ngayHoanThanh = {
          from: '',
          to: '',
        }
      } else {
        state.filter.ngayHoanThanh = {
          from: moment().subtract(1, 'months').format('YYYY-MM-DD'),
          to: moment().format('YYYY-MM-DD'),
        }
      }
    }
    this.setState(state, () => {
      this.clickPagination(this.state.page)
    })
  }

  componentWillReceiveProps(props) {
    if (props.personalWorkTotals) {
      this.setState({
        workExpiringSoon: props.personalWorkTotals.workExpiringSoon || 0,
        workExpired: props.personalWorkTotals.workExpired || 0,
      })
    }

    if (props.match.params && props.match.params.status !== this.state.type) {
      this.setState({ type: props.match.params.status })
    }

    let { status } = props.match.params
    let styleFilter = get(this.state, 'styleFilter', {})
    status = this.getStatusNumber(status)
    const oldStatus = this.state.filter.trangThai
    if (status === oldStatus) {
      return this.setState({
        openFilter: undefined,
      })
    } else {
      // Nếu thay đổi page khác trạng thái thực hiện thì reset styleFilter
      styleFilter = {}
    }

    let ngayHoanThanh
    if (status === CHUA_XU_LY || status === DANG_XU_LY) {
      ngayHoanThanh = {
        from: '',
        to: '',
      }
    } else {
      ngayHoanThanh = {
        from: moment().subtract(1, 'months').format('YYYY-MM-DD'),
        to: moment().format('YYYY-MM-DD'),
      }
    }

    this.setState(
      {
        openFilter: false,
        page: 1,
        sort: undefined,
        filter: {
          ...this.state.filter,
          textSearch: '',
          ngayHoanThanh,
          trangThai: status,
        },
        filterColumn: {},
        styleFilter,
      },
      () => {
        this.submitFilter()
      }
    )
  }

  getStatusNumber(status) {
    switch (status) {
      case 'dang-thuc-hien':
        return Types.PERSONAL_WORK_PROCESSING
      case 'da-thuc-hien':
        return Types.PERSONAL_WORK_DONE
      case 'chua-thuc-hien':
        return Types.DOCUMENT_RECEIVE_NO_PROCESS
      default:
        return
    }
  }

  clickPagination(page = 1) {
    this.setState({ page }, () => {
      this.submitFilter(this.state.filter, this.state.page, this.state.sort)
    })
  }

  submitFilter(e = {}, page = 1, sort) {
    const { styleFilter } = this.state
    const filter = Object.assign({}, this.state.filter, e)
    let isCallRequestAgain = false

    // filterColumn để lưu ở localStorage
    const filterColumn = {
      ...this.state.filterColumn,
      coQuanBanHanhId: this.state.filterColumn.coQuanBanHanhId
        ? this.state.filterColumn.coQuanBanHanhId
        : null,
      coQuanBanHanh:
        this.state.filterColumn.coQuanBanHanhId &&
        this.state.filterColumn.coQuanBanHanhId === '#'
          ? this.state.filterColumn.coQuanBanHanh
          : null,
      hanGiaiQuyet: this.state.filterColumn.hanGiaiQuyet
        ? moment(this.state.filterColumn.hanGiaiQuyet).format('YYYY-MM-DD')
        : null,
      trichYeu:
        this.state.filterColumn.trichYeu &&
        this.state.filterColumn.trichYeu !== ''
          ? this.state.filterColumn.trichYeu
          : null,
      soCongVan:
        this.state.filterColumn.soCongVan &&
        this.state.filterColumn.soCongVan !== ''
          ? this.state.filterColumn.soCongVan
          : null,
      giaoViec:
        this.state.filterColumn.giaoViec &&
        this.state.filterColumn.giaoViec !== ''
          ? this.state.filterColumn.giaoViec
          : null,
      vaiTro:
        this.state.filterColumn.vaiTro && this.state.filterColumn.vaiTro !== ''
          ? this.state.filterColumn.vaiTro
          : null,
      canBaoCao:
        this.state.filterColumn.canBaoCao &&
        this.state.filterColumn.canBaoCao !== ''
          ? this.state.filterColumn.canBaoCao
          : null,
    }

    // filterColumn request API
    const filterColumn1 = {
      ...this.state.filterColumn,
      coQuanBanHanhId:
        this.state.filterColumn.coQuanBanHanhId &&
        this.state.filterColumn.coQuanBanHanhId !== '#'
          ? this.state.filterColumn.coQuanBanHanhId
          : null,
      coQuanBanHanh:
        this.state.filterColumn.coQuanBanHanhId &&
        this.state.filterColumn.coQuanBanHanhId === '#'
          ? !this.state.filterColumn.coQuanBanHanh
            ? ''
            : this.state.filterColumn.coQuanBanHanh
          : null,
      hanGiaiQuyet: this.state.filterColumn.hanGiaiQuyet
        ? moment(this.state.filterColumn.hanGiaiQuyet).format('YYYY-MM-DD')
        : null,
      trichYeu:
        this.state.filterColumn.trichYeu &&
        this.state.filterColumn.trichYeu !== ''
          ? this.state.filterColumn.trichYeu
          : null,
      soCongVan:
        this.state.filterColumn.soCongVan &&
        this.state.filterColumn.soCongVan !== ''
          ? this.state.filterColumn.soCongVan
          : null,
      giaoViec:
        this.state.filterColumn.giaoViec &&
        this.state.filterColumn.giaoViec !== ''
          ? this.state.filterColumn.giaoViec
          : null,
      vaiTro:
        this.state.filterColumn.vaiTro && this.state.filterColumn.vaiTro !== ''
          ? this.state.filterColumn.vaiTro
          : null,
      canBaoCao:
        this.state.filterColumn.canBaoCao &&
        this.state.filterColumn.canBaoCao !== ''
          ? this.state.filterColumn.canBaoCao
          : null,
    }

    this.setState({ filter, sort, isLoading: true, page }, () => {
      Tool.Utils.preData(
        `ListPersonalWorkPage-${this.state.filter.trangThai}`,
        { filter, page, sort, filterColumn, styleFilter }
      )
      // tạm thời để refresh bang false
      this.props.actions
        .personalWorkGet({ filter }, page, sort, REFRESH_FALSE, filterColumn1)
        .then(res => {
          if (
            page > 1 &&
            res.payload &&
            res.payload.data &&
            res.payload.data.result &&
            res.payload.data.result.result &&
            res.payload.data.result.result.total === 0
          ) {
            isCallRequestAgain = true
            return this.submitFilter(e, page - 1, sort)
          }
          let isEmpty = false
          let isError = false
          if (
            !res.error &&
            res.payload &&
            res.payload.data &&
            res.payload.data.result &&
            res.payload.data.result.result &&
            res.payload.data.result.result.total === 0
          ) {
            isEmpty = true
          }

          if (res.error || (res.payload && res.payload.status !== 200)) {
            isError = true
          }
          this.setState({ isEmpty, isError })
        })
        .then(res => {
          if (isCallRequestAgain === false) {
            this.setState({ isLoading: false })
          }
          this.props.actions.refreshPage(false)
        })
    })
  }

  handleSort(sort) {
    this.submitFilter({}, this.state.page, sort)
  }

  handleSubmitRemoveItem = id => {
    this.props.actions.commonAddToasterMessage({
      message: MESSAGE.TOATS_MESSAGE_DELETE_SUCCESS,
      intent: Intent.SUCCESS,
    })
  }

  handleCheck = (congViecPhongBan, id, type) => {
    if (!congViecPhongBan) {
      return
    }
    let list = this.state.list
    if (
      this.state.list.find(
        item =>
          item.cong_viec_phong_ban_id === congViecPhongBan.congViecPhongBanId
      )
    ) {
      list = list.filter(
        s => s.cong_viec_phong_ban_id !== congViecPhongBan.congViecPhongBanId
      )
    } else {
      list.push({
        cong_viec_phong_ban_id: congViecPhongBan.congViecPhongBanId,
        [type]: id,
        fileId: congViecPhongBan.fileId,
        trichYeu: congViecPhongBan.trichYeu,
      })
    }

    this.setState({ list })
  }

  handleClickComplete = id => {
    this.props.actions.workCompletePersonal(id).then(res => {
      if (res.error || res.payload.status !== 200) {
        return this.props.actions.commonAddToasterMessage({
          message: MESSAGE.TEXT_MESSAGE_KHONGHOANTHANHCONGVIEC,
          intent: Intent.DANGER,
        })
      }

      this.props.actions.getNumberWorkPage(true)
      this.props.actions.commonAddToasterMessage({
        message: MESSAGE.TEXT_MESSAGE_HOANTHANHCONGVIEC,
        intent: Intent.SUCCESS,
      })
      this.props.actions.refreshPage(true)
      this.submitFilter(this.state.filter, this.state.page, this.state.sort)
    })
  }

  saveToCabinetDocument = async () => {
    let listCabinetOfDocument = []
    if (this.state.list.length === 1) {
      if (this.state.list[0].cong_van_den_id) {
        await this.props.actions
          .getListCabinetOfDocument(this.state.list[0].cong_van_den_id)
          .then(res => {
            if (res.payload && res.payload.data && res.payload.data.result) {
              listCabinetOfDocument = res.payload.data.result
            }
          })
      }

      if (this.state.list[0].cong_van_noi_bo_id) {
        await this.props.actions
          .getListCabinetOfDocument(
            this.state.list[0].cong_van_noi_bo_id,
            CONG_VAN_NOI_BO
          )
          .then(res => {
            if (res.payload && res.payload.data && res.payload.data.result) {
              listCabinetOfDocument = get(res, 'payload.data.result')
            }
          })
      }
    }

    this.props.history.push({
      pathname: `/tien-ich/luu-tu-tai-lieu`,
      state: {
        dsFileCongVan: this.state.list.map(item => {
          return {
            file_id: item.fileId,
            ten_tai_lieu: item.trichYeu,
            cong_van_den_id: item.cong_van_den_id,
            cong_van_noi_bo_id: item.cong_van_noi_bo_id,
          }
        }),
        listCabinetOfDocument,
      },
    })
  }

  handleResetCheck(filter) {
    this.setState({ list: [], filterColumn: {}, styleFilter: null }, () => {
      this.submitFilter(filter)
    })
  }

  handleClickDetail = data => {
    if (!data) {
      return
    }

    if (!data?.daXem && data?.congViecCaNhanId) {
      this.props.actions.postXemCongViecCaNhan(data.congViecCaNhanId)
    }

    this.setState({
      isRedirect: true,
      redirectTo: `/cong-viec/ca-nhan/chi-tiet/${this.state.type}/${data.phieuGiaoViecId}`,
      fileId: data.fileId,
      soCongVan: data.soCongVan,
      showSection: data.showSection,
    })
  }

  onChangeGridViewFilter(e) {
    const value = e.target.value
    const name = e.target.name

    this.setState(
      {
        filterColumn: {
          ...this.state.filterColumn,
          [name]: value,
        },
        // Thay đổi màu xanh nếu chọn vai trò chủ trì của chủ trì
        styleFilter: {
          vaiTro: {
            color: name === 'vaiTro' && value === '2' ? '#195FFF' : '#000000',
          },
        },
      },
      () => {
        if (
          name === 'coQuanBanHanhId' ||
          name === 'vaiTro' ||
          name === 'canBaoCao'
        ) {
          this.submitFilter()
        }
      }
    )
  }

  onChangeDateGridViewFilter(name, e) {
    this.setState(
      {
        filterColumn: {
          ...this.state.filterColumn,
          [name]: e,
        },
      },
      () => {
        this.submitFilter()
      }
    )
  }

  clearFilterColumn(filter) {
    this.submitFilter(filter)
  }

  onRemoveText(name) {
    this.setState(
      {
        filterColumn: {
          ...this.state.filterColumn,
          [name]: '',
        },
      },
      () => {
        this.submitFilter()
      }
    )
  }

  removeTextSearch(name) {
    this.setState({
      filter: {
        ...this.state.filter,
        [name]: '',
      },
    })
  }

  handleDateChange(name, pos, e) {
    this.setState(
      {
        filter: {
          ...this.state.filter,
          [name]: {
            ...this.state.filter[name],
            [pos]: e ? moment(e).format('YYYY-MM-DD') : undefined,
          },
        },
      },
      () => {
        this.submitFilter()
      }
    )
  }

  render() {
    const location = this.props.match.params.status
    const { styleFilter } = this.state
    if (this.state.isRedirect) {
      return (
        <Redirect
          push
          to={{
            pathname: this.state.redirectTo,
            state: {
              fileId: this.state.fileId,
              soCongVan: this.state.soCongVan,
              showSection: this.state.showSection,
              from: this.props.location.pathname,
            },
          }}
        />
      )
    }
    return (
      <MasterLayout typeSidebar="work">
        <div>
          {/* Heading */}
          <div className="heading-page">
            <div className="row">
              <div className="col-xs-8">
                <h3 className="text-heading-page">
                  <span
                    className={classnames('icon-heading', {
                      'icon-Chua_Thuc_Hien': location === 'chua-thuc-hien',
                      'icon-Dang_Thuc_Hien': location === 'dang-thuc-hien',
                      'icon-Da_Thuc_Hien': location === 'da-thuc-hien',
                    })}
                  ></span>
                  <span className="text-heading">
                    {location === 'dang-thuc-hien' &&
                      'Công việc cá nhân đang thực hiện'}
                    {location === 'chua-thuc-hien' &&
                      'Công việc cá nhân chưa thực hiện'}
                    {location === 'da-thuc-hien' &&
                      'Công việc cá nhân đã thực hiện'}
                  </span>
                </h3>
              </div>
              {this.state.list && this.state.list.length > 0 && (
                <div className="col-xs-4">
                  <div className="button-gv pull-right">
                    <button
                      className="btn-action pull-left"
                      onClick={this.saveToCabinetDocument}
                    >
                      <span className="name-btn">Lưu tủ tài liệu</span>
                      <span className="pt-icon icon-Tu_Tai_Lieu"></span>
                    </button>
                  </div>
                </div>
              )}
            </div>
          </div>

          {/* Table List */}
          <div className="page-list-container">
            <ListFilter
              filter={this.state.filter}
              units={this.props.units}
              departments={this.props.departments}
              onSubmit={this.submitFilter}
              workExpired={this.state.workExpired}
              workExpiringSoon={this.state.workExpiringSoon}
              status={this.props.match.params.status}
              open={this.state.openFilter}
              isLoading={this.state.isLoading}
              readOnly={this.props.readOnly}
              actions={this.props.actions}
              clearFilterColumn={this.clearFilterColumn}
              handleResetCheck={this.handleResetCheck}
              removeTextSearch={this.removeTextSearch}
              handleDateChange={this.handleDateChange}
            />

            <div
              className="table-container"
              style={{
                paddingBottom: 0,
                border: 0,
                background:
                  Math.ceil(
                    this.props.personalWorks.list.total / Types.PAGINATION_LIMIT
                  ) > 1
                    ? '#fff'
                    : '#eff3f5',
              }}
            >
              <GridViewFilter
                isLoading={this.state.isLoading}
                classNames={'pt-table fix-table-before'}
                page={this.state.page ? this.state.page : 1}
                type={this.state.type}
                headers={
                  this.state.type === 'da-thuc-hien'
                    ? headers.listPersonalWorkPageDone
                    : this.state.type === 'chua-thuc-hien'
                    ? headers.listPersonalWorkPageUnfulfilled
                    : headers.listPersonalWorkPage
                }
                itemShape={ListItem}
                data={this.props.personalWorks.list.items}
                onHandleSubmitRemoveItem={this.handleSubmitRemoveItem}
                onClickDetail={this.handleClickDetail}
                onFilter={this.handleSort}
                onClickComplete={this.handleClickComplete}
                onCheck={this.handleCheck}
                isEmpty={this.state.isEmpty}
                history={this.props.history}
                auth={this.props.auth}
                isError={this.state.isError}
                onChange={this.onChangeGridViewFilter}
                onChangeDate={this.onChangeDateGridViewFilter}
                coQuanBanHanhId={this.props.units}
                phongBanTrinhId={this.props.departments}
                filterColumn={this.state.filterColumn}
                onSubmit={this.submitFilter}
                onRemoveText={this.onRemoveText}
                pageCount={Math.ceil(
                  this.props.personalWorks.list.total / Types.PAGINATION_LIMIT
                )}
                isType="CVPB"
                list={this.state.list}
                isCVCN={true}
                styleFilter={styleFilter}
                footerNote={
                  <NoteFooter
                    pageCount={Math.ceil(
                      this.props.personalWorks.list.total /
                        Types.PAGINATION_LIMIT
                    )}
                    isCVPBCG={false}
                    isCVPBDG={false}
                    isHienThiLoaiCongVan={false}
                  />
                }
              />

              {!this.state.isLoading &&
                !this.state.isEmpty &&
                !this.state.isError && (
                  <Pagination
                    current={this.state.page}
                    total={Math.ceil(
                      this.props.personalWorks.list.total /
                        Types.PAGINATION_LIMIT
                    )}
                    onChange={this.clickPagination}
                  />
                )}
            </div>
          </div>
        </div>
      </MasterLayout>
    )
  }
}

ListPersonalWorkPage.propTypes = {
  personalWorks: PropTypes.object.isRequired,
  units: PropTypes.array.isRequired,
  departments: PropTypes.array.isRequired,
}

const mapStateToProps = state => ({
  config: state.common.config,
  auth: {
    ...state.auth,
  },
  personalWorks: {
    ...state.personalWorks,
    list: {
      ...state.personalWorks.list,
      items: state.personalWorks.list.items.map(
        id => state.entities.personalWorks[id]
      ),
    },
  },
  units: state.common.relativeUnits,
  departments: state.common.leaderDepartments,
  personalWorkTotals: state.common.personalWorkTotals,
  readOnly: state.common.enableReadOnly,
})

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(Actions, dispatch),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ListPersonalWorkPage)
