import React, { useEffect, useState, useMemo } from 'react'
import { Button, Intent } from '@blueprintjs/core'
import { UploadMultiple } from 'components/common'
import { MasterLayout } from 'components/layout'
import moment from 'moment'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import * as Actions from 'actions'
import ModalDanhSachNguoiThamGia from './ModalDanhSachNguoiThamGia'
import * as MESSAGER from 'constants/MessageForm'
import Title from 'components/ui/title'

const ChiTietLichHopNguoiThamGiaPage = props => {
  const [detail, setDetail] = useState(null)

  const [dataFiles, setDataFiles] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const [isLoadingKhongThamGia, setIsLoadingKhongThamGia] = useState(false)
  const [showPopupThamGia, setShowPopupThamGia] = useState(false)

  const idDetail = props.match.params.id

  const handleRemoveFile = index => {
    const result = dataFiles?.filter((item, i) => index !== i)
    setDataFiles(result)
  }

  const handleBack = () => {
    props.history.goBack()
  }

  const handleGetChiTietLichHop = async () => {
    const res = await props.actions.getChiTietLichHop(idDetail)
    if (!res.error && res?.payload?.data?.result) {
      const dataDetail = res.payload.data.result
      setDetail(dataDetail)
      setDataFiles(dataDetail?.dsFileDinhKem ?? [])
    }
  }

  const handleSubmitXacNhanThamGia = thamGia => {
    const setLoading = thamGia === 1 ? setIsLoading : setIsLoadingKhongThamGia
    setLoading(true)
    props.actions.putXacNhanThamGia(detail?.cuocHopId, thamGia).then(res => {
      if (res.payload && res.payload.data.result === true) {
        setLoading(false)
        handleBack()
        props.actions.getMeetingCounter()
        return props.actions.commonAddToasterMessage({
          message:
            thamGia === 1
              ? MESSAGER.TOATS_CONFIRM_JOIN_MEETING
              : MESSAGER.TOATS_CONFIRM_CANCLE_MEETING,
          intent: Intent.SUCCESS,
        })
      } else {
        setLoading(false)
        props.actions.commonAddToasterMessage({
          message:
            res?.error?.response?.data?.message || MESSAGER.TOATS_ERROR_SYSTEM,
          intent: Intent.DANGER,
        })
      }
    })
  }

  useEffect(() => {
    if (idDetail) {
      handleGetChiTietLichHop()
    }
    // eslint-disable-next-line
  }, [idDetail])

  const dsNguoiThamGia = useMemo(() => {
    return detail?.cuocHopThanhPhan
      ?.filter(elm => elm?.nhanVien && !elm?.chuTri)
      ?.map(item => ({
        ...item?.nhanVien,
        thamGia: item.thamGia,
      }))
  }, [detail])

  const dsNguoiChuTri = useMemo(() => {
    return detail?.cuocHopThanhPhan
      ?.filter(elm => elm?.nhanVien && elm?.chuTri)
      ?.map(item => item?.nhanVien?.tenNhanVien)
  }, [detail])

  const isEnd = moment(detail?.batDau).isBefore(moment())
  const statusThamGia = detail?.cuocHopThanhPhan?.filter(
    item => item?.nhanVienId === props.auth?.user?.nhanVienId
  )?.[0]?.thamGia

  return (
    <MasterLayout typeSidebar="sidebarCalendar">
      <Title
        name="Chi tiết lịch họp"
        icon="icon-info-circle"
        isGoBack
        history={props.history}
      />
      <div className="form-wrapper form-calendar mt10">
        <div className="form-container">
          <h3 className="title-block-form">Thông tin cuộc họp</h3>
          <div className="form-padding-box">
            <div className="row">
              <div className="col-xs-12 col-sm-6 col-md-6 form-item-wrap">
                <label className="pt-label">Tiêu đề</label>
                <div className="pt-form-view">{detail?.tieuDe}</div>
              </div>
              <div className="col-xs-12 col-sm-6 col-md-6 form-item-wrap">
                <label className="pt-label">Địa điểm</label>
                <div className="pt-form-view">{detail?.diaDiem?.ten}</div>
              </div>
            </div>
            <div className="row">
              <div className="col-xs-12 col-sm-6 col-md-6 form-item-wrap">
                <label className="pt-label">Loại cuộc họp</label>
                <div className="pt-form-view">{detail?.theLoai?.ten}</div>
              </div>
              <div className="col-xs-12 col-sm-6 col-md-6 form-item-wrap">
                <label className="pt-label">Cấp độ cuộc họp</label>
                <div className="pt-form-view">{detail?.mucDo?.ten}</div>
              </div>
            </div>
            <div className="row">
              <div className="col-xs-12 col-sm-12 col-md-3 form-item-wrap">
                <label className="pt-label">Thời gian diễn ra</label>
                <div className="pt-form-view">
                  {detail?.ketThuc
                    ? moment(detail?.batDau).format('DD/MM/YYYY HH:mm') +
                      ' - ' +
                      moment(detail?.ketThuc).format('DD/MM/YYYY HH:mm')
                    : moment(detail?.batDau).format('DD/MM/YYYY HH:mm')}
                </div>
              </div>
            </div>
            <div className="row">
              {(dsNguoiChuTri?.length > 0 ?? false) && (
                <div className="col-xs-12 col-sm-12 col-md-3 form-item-wrap">
                  <label className="pt-label">Chủ trì</label>
                  <div className="pt-form-view">{dsNguoiChuTri.join(', ')}</div>
                </div>
              )}
              {(dsNguoiThamGia?.length > 0 ?? false) && (
                <div className="col-xs-4 col-sm-4 col-md-3 form-item-wrap">
                  <label className="pt-label">Danh sách người mời họp</label>
                  <div className="pt-form-content">
                    <span className="total-person">
                      <span className="total-person__number">
                        {dsNguoiThamGia?.length || 0}
                      </span>
                      {dsNguoiThamGia?.length > 0 && (
                        <span
                          className="total-person__more"
                          onClick={() => setShowPopupThamGia(true)}
                        >
                          Xem danh sách
                        </span>
                      )}
                    </span>
                  </div>
                </div>
              )}
            </div>
            <div className="row">
              <div className="col-xs-12 col-sm-12 col-md-12 form-item-wrap">
                <label className="pt-label">Nội dung</label>
                <div className="pt-form-view">{detail?.noiDung}</div>
              </div>
            </div>
            <div className="pt-form-group pt-intent-danger">
              <UploadMultiple
                isDetail
                dataFiles={dataFiles}
                handleRemoveFile={handleRemoveFile}
              />
            </div>
          </div>
        </div>
        <div className="buttton-action-footer">
          <Button
            onClick={() => handleBack()}
            className="pt-button btn-cancel"
            disabled={isLoading || isLoadingKhongThamGia}
          >
            <span className=" pt-icon icon-back"></span>
            <span className="text-content">Quay lại</span>
          </Button>
          {!isEnd && (
            <div>
              <Button
                className="pt-button btn-red-title"
                onClick={() => handleSubmitXacNhanThamGia(2)}
                loading={isLoadingKhongThamGia}
                disabled={statusThamGia === 2 || isLoading}
              >
                <span className="pt-icon icon2-stop"></span>
                <span className="text-content">Không tham gia</span>
              </Button>
              <Button
                className="pt-button btn-blue-color"
                onClick={() => handleSubmitXacNhanThamGia(1)}
                loading={isLoading}
                disabled={statusThamGia === 1 || isLoadingKhongThamGia}
              >
                <span className="text-content">Tham gia</span>
                <span className="pt-icon icon2-check"></span>
              </Button>
            </div>
          )}
        </div>
      </div>
      <ModalDanhSachNguoiThamGia
        data={dsNguoiThamGia}
        isOpen={showPopupThamGia}
        onClose={() => setShowPopupThamGia(false)}
      />
    </MasterLayout>
  )
}

const mapStateToProps = state => ({
  auth: {
    ...state.auth,
  },
})

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(Actions, dispatch),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ChiTietLichHopNguoiThamGiaPage)
