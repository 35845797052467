import React, { Component } from 'react'
import { Scrollbars } from 'react-custom-scrollbars'
import Feedback from './Feedback'
import PropTypes from 'prop-types'
import { Authorization, Permission } from '../auth'
import { Intent, Button } from '@blueprintjs/core'
import 'moment/locale/vi'
import moment from 'moment'
import { LoadingItem, PopupConfirm } from '../../components/common/'
import * as MESSAGE from '../../constants/MessageForm'
import DetailStatus from './DetailStatus'
import ListReceivedDepartments from './ListReceivedDepartments'
import UpdateHistory from './UpdateHistory'
import * as Tool from '../../helpers'
import * as Types from '../../constants/Api'
import DatePicker from 'react-datepicker'
import * as POPUP from '../../constants/Popup'
import { pullAll } from 'lodash'

const SECRETARY_FEEDBACK = 2
const VNPT = 1
const VIETTEL = 0
class DetailBoxAction extends Component {
  constructor(props) {
    super(props)
    this.sendFeedback = this.sendFeedback.bind(this)
    this.handleInputChange = this.handleInputChange.bind(this)
    this.handleClickAgree = this.handleClickAgree.bind(this)
    this.handleClickAgreeBySIM = this.handleClickAgreeBySIM.bind(this)
    this.handleClickAgreeByTOKEN = this.handleClickAgreeByTOKEN.bind(this)
    this.handleClickDisagree = this.handleClickDisagree.bind(this)
    this.handleDateChange = this.handleDateChange.bind(this)
    this.sendProgression = this.sendProgression.bind(this)
    this.sendProgressionBySIM = this.sendProgressionBySIM.bind(this)
    this.handleUpdateFeedback = this.handleUpdateFeedback.bind(this)
    this.updateFeedback = this.updateFeedback.bind(this)
    this.handleChangeRaw = this.handleChangeRaw.bind(this)
    this.focousOut = this.focousOut.bind(this)
    this.handleClickPopup = this.handleClickPopup.bind(this)
    this.handleClickPopupCapSoKhongXacThuc =
      this.handleClickPopupCapSoKhongXacThuc.bind(this)
    this.handleRedirectProgression = this.handleRedirectProgression.bind(this)
    this.handleRedirectProgressionCapSoKhongXacThuc =
      this.handleRedirectProgressionCapSoKhongXacThuc.bind(this)
    this.handleShowMessageCopySuccess =
      this.handleShowMessageCopySuccess.bind(this)
  }

  static propTypes = {
    auth: PropTypes.object.isRequired,
    feedbacks: PropTypes.array,
    signNumber: PropTypes.object,
    onSubmit: PropTypes.func.isRequired,
    actions: PropTypes.object.isRequired,
    type: PropTypes.string.isRequired,
    isLoadingData: PropTypes.object.isRequired,
    onRemoveFileInfo: PropTypes.func,
  }

  state = {
    isOpenPopup: false,
    isOpenPopupCapSoKhongXacThuc: false,
    typePopup: null,
    titlePopup: '',
    error: {},
    feedbackContent: '',
    feedbackForLeader: '',
    chucDanhId: null,
    phongBanId: null,
    maPhongBan: null,
    maKySo: null,
    signNumberId: null,
    isLeaderUnit: false, // lanh dao don vi
    isSecretary: false, // thu ky lanh dao don vi
    isCompositiveDepartment: false, //lanh dao phong ban phoi hop thuong
    isLeaderDepartment: false, // lanh dao phong ban chu tri,
    isParentLeaderUnit: false, // lanh dao don vi cap tren
    isParentSecretary: false, // thu ky lanh dao don vi cap tren
    isParentCompositiveDepartment: false, //lanh dao phong ban phoi hop cap tren
    isOtherSecretary: false, // thu ky lanh dao don vi khac cung ky
    isOtherLeaderUnit: false, // lanh dao don vi khac cung ky
    isLeaderCompositiveLeaderDepartment: false, // lanh dao phong ban phoi hop la lanh dao
    isLeaderCompositiveSecretary: false, // thu ky cua lanh dao phong ban phoi hop
    isCompositiveDepartmentElectricUnit: false, // lanh dao dien luc phoi hop
    isLoading: {
      agree: false,
      agreeBySIM: false,
      agreeBySIMVNPT: false,
      agreeByTOKEN: false,
      disagree: false,
      sendFeedback: false,
      progression: false,
      progressionByVIETTEL: false,
      progressionByVNPT: false,
      checkProgression: false,
    },
    isShowButton: false,
    soCongVan: '',
    ngayCongVan: moment(),
    feedbackId: null,
    hrefToken: null,
    isDisagree: false,
    userDisagree: null,
    userAgrees: [],
    isAgree: false,
    isSubmitFeedbackSuccess: null,
    ngayCongVanToString: moment()
      .format('YYYY-MM-DD')
      .toString()
      .replace(/-/g, ''),
    dateToToString: moment().format('DD/MM/YYYY'),
    secretaryId: null,
    parentSecretaryId: null,
    otherSecretaryId: null,
    leaderCompositiveDepartment: null,
  }

  handleClickPopup = () => {
    let isLoading = this.state.isLoading
    if (this.state.isOpenPopup) {
      isLoading = {
        ...isLoading,
        progression: false,
        progressionByVIETTEL: false,
        progressionByVNPT: false,
      }
    }
    this.setState({
      isOpenPopup: !this.state.isOpenPopup,
      isLoading,
    })
  }

  handleClickPopupCapSoKhongXacThuc = () => {
    let isLoading = this.state.isLoading
    if (this.state.isOpenPopupCapSoKhongXacThuc) {
      isLoading = {
        ...isLoading,
        progression: false,
      }
    }
    this.setState({
      isOpenPopupCapSoKhongXacThuc: !this.state.isOpenPopupCapSoKhongXacThuc,
      isLoading,
    })
  }

  handleRedirectProgression = () => {
    this.setState({ isOpenPopup: false })
    if (
      this.state.isLoading.progressionByVIETTEL ||
      this.state.isLoading.progressionByVNPT
    ) {
      return this.handleProgressionBySIM()
    } else {
      this.props.onRemoveFileInfo && this.props.onRemoveFileInfo()
      return (window.location.href = `eofficecpc:id_vb=${this.state.signNumberId};socv=${this.state.soCongVan};ngaycv=${this.state.ngayCongVanToString};id_nv=${this.state.chucDanhId}`)
    }
  }

  handleRedirectProgressionCapSoKhongXacThuc = () => {
    return this.fetchCapSoKhongCanXacThuc()
  }

  handleDateChange = e => {
    const error = this.state.error
    delete error.ngayCongVan

    let ngayCongVan = undefined
    let ngayCongVanToString = ''
    let dateToToString = ''

    if (e) {
      ngayCongVan = e
      ngayCongVanToString = moment(e)
        .format('YYYY-MM-DD')
        .toString()
        .replace(/-/g, '')
      dateToToString = moment(e).format('DD/MM/YYYY')
    }

    this.setState({ ngayCongVan, ngayCongVanToString, error, dateToToString })
  }

  handleChangeRaw(name, value) {
    this.setState({ [name]: value })
  }

  focousOut(name, nameToString, value) {
    if (Tool.Utils.isValidDate(value)) {
      return
    }

    this.setState({
      [nameToString]: '',
      [name]: undefined,
    })
  }

  handleInputChange = e => {
    const name = e.target.name
    let value = e.target.value
    const error = this.state.error
    delete error[name]

    this.setState({
      [e.target.name]: value,
      error,
    })
  }

  updateFeedback = params => {
    if (!params) {
      return
    }

    this.setState({
      isLoading: {
        ...this.state.isLoading,
        sendFeedback: true,
      },
    })
    if (this.props.type === 'kySoDi') {
      return this.props.actions
        .signNumberSendFeedbackUpdate(this.state.signNumberId, params.gopYId, {
          gop_y_id: params.gopYId,
          noi_dung: params.noiDung,
        })
        .then(res => {
          if (
            res.error ||
            !res.payload ||
            (res.payload &&
              res.payload.data &&
              res.payload.data.result === false)
          ) {
            this.setState({
              isLoading: {
                ...this.state.isLoading,
                sendFeedback: false,
              },
              feedbackId: null,
            })
            return (
              this.props.onSubmit &&
              this.props.onSubmit({
                title: 'GopY',
                isSuccess: false,
                message: MESSAGE.TOATS_MESSAGE_FAIL,
                intent: Intent.DANGER,
              })
            )
          }

          this.setState({
            feedbackId: null,
            isLoading: {
              ...this.state.isLoading,
              sendFeedback: false,
            },
          })
          return (
            this.props.onSubmit &&
            this.props.onSubmit({
              title: 'GopY',
              isSuccess: true,
              message: MESSAGE.TOATS_MESSAGE_SUCCESS,
              intent: Intent.SUCCESS,
            })
          )
        })
    }

    this.props.actions
      .signNumberInternalFeedbackUpdate(
        this.state.signNumberId,
        params.gopYId,
        {
          gop_y_id: params.gopYId,
          noi_dung: params.noiDung,
        }
      )
      .then(res => {
        if (
          res.error ||
          !res.payload ||
          (res.payload && res.payload.data && res.payload.data.result === false)
        ) {
          this.setState({
            feedbackId: null,
            isLoading: {
              ...this.state.isLoading,
              sendFeedback: false,
            },
          })
          return (
            this.props.onSubmit &&
            this.props.onSubmit({
              title: 'GopY',
              isSuccess: false,
              message: MESSAGE.TOATS_MESSAGE_FAIL,
              intent: Intent.DANGER,
            })
          )
        }

        this.setState({
          feedbackId: null,
          isLoading: {
            ...this.state.isLoading,
            sendFeedback: false,
          },
        })
        return (
          this.props.onSubmit &&
          this.props.onSubmit({
            title: 'GopY',
            isSuccess: true,
            message: MESSAGE.TOATS_MESSAGE_SUCCESS,
            intent: Intent.SUCCESS,
          })
        )
      })
  }

  sendFeedback(type = null) {
    this.setState({
      isLoading: {
        ...this.state.isLoading,
        sendFeedback: true,
      },
    })

    if (
      this.state.feedbackContent.length === 0 &&
      this.state.feedbackForLeader.length === 0
    ) {
      this.setState({
        isLoading: {
          ...this.state.isLoading,
          sendFeedback: false,
        },
        isSubmitFeedbackSuccess: false,
      })
      if (type !== 'NotShowMessage') {
        return (
          this.props.onSubmit &&
          this.props.onSubmit({
            title: 'GopY',
            isSuccess: false,
            message: MESSAGE.TEXT_MESSAGE_GOPY,
            intent: Intent.WARNING,
          })
        )
      }
    }

    this.setState({
      isLoading: {
        ...this.state.isLoading,
        sendFeedback: true,
      },
    })

    if (this.props.type === 'kySoDi') {
      return this.props.actions
        .signNumberFeedbackPost(this.state.signNumberId, {
          gop_y_chung: this.state.feedbackContent,
          gop_y_lanh_dao: this.state.feedbackForLeader,
        })
        .then(res => {
          if (
            res.error ||
            !res.payload ||
            (res.payload &&
              res.payload.data &&
              res.payload.data.result === false)
          ) {
            this.setState({
              isLoading: {
                ...this.state.isLoading,
                sendFeedback: false,
              },
              isSubmitFeedbackSuccess: false,
            })

            if (type !== 'NotShowMessage') {
              return (
                this.props.onSubmit &&
                this.props.onSubmit({
                  title: 'GopY',
                  isSuccess: false,
                  message: MESSAGE.TOATS_MESSAGE_FAIL,
                  intent: Intent.DANGER,
                })
              )
            }
          }

          this.setState({
            feedbackForLeader: '',
            feedbackContent: '',
            isLoading: {
              ...this.state.isLoading,
              sendFeedback: false,
            },
            isSubmitFeedbackSuccess: true,
          })

          if (type !== 'NotShowMessage') {
            return (
              this.props.onSubmit &&
              this.props.onSubmit({
                title: 'GopY',
                isSuccess: true,
                message: MESSAGE.TOATS_MESSAGE_SUCCESS,
                intent: Intent.SUCCESS,
              })
            )
          }
          return (
            this.props.onSubmit &&
            this.props.onSubmit({ title: 'GopY', isSuccess: true })
          )
        })
    }

    this.props.actions
      .signNumberInternalFeedbackPost(this.state.signNumberId, {
        gop_y_chung: this.state.feedbackContent,
        gop_y_lanh_dao: this.state.feedbackForLeader,
      })
      .then(res => {
        if (
          res.error ||
          !res.payload ||
          (res.payload && res.payload.data && res.payload.data.result === false)
        ) {
          this.setState({
            isLoading: {
              ...this.state.isLoading,
              sendFeedback: false,
            },
            isSubmitFeedbackSuccess: false,
          })
          if (type !== 'NotShowMessage') {
            return (
              this.props.onSubmit &&
              this.props.onSubmit({
                title: 'GopY',
                isSuccess: false,
                message: MESSAGE.TOATS_MESSAGE_FAIL,
                intent: Intent.DANGER,
              })
            )
          }
        }

        this.setState({
          feedbackForLeader: '',
          feedbackContent: '',
          isLoading: {
            ...this.state.isLoading,
            sendFeedback: false,
          },
          isSubmitFeedbackSuccess: true,
        })

        if (type !== 'NotShowMessage') {
          return (
            this.props.onSubmit &&
            this.props.onSubmit({
              title: 'GopY',
              isSuccess: true,
              message: MESSAGE.TOATS_MESSAGE_SUCCESS,
              intent: Intent.SUCCESS,
            })
          )
        }
        return (
          this.props.onSubmit &&
          this.props.onSubmit({ title: 'GopY', isSuccess: true })
        )
      })
  }

  fetchCapSoKhongCanXacThuc = async e => {
    this.setState({
      isLoading: {
        ...this.state.isLoading,
        progression: true,
      },
    })
    this.props.actions
      .postCapSoKhongXacThuc(
        this.state.signNumberId,
        this.state.soCongVan,
        this.state.ngayCongVan
      )
      .then(res => {
        this.setState({
          isLoading: {
            ...this.state.isLoading,
            progression: false,
          },
        })
        if (res?.error) {
          return this.props.actions.commonAddToasterMessage({
            message:
              res?.error?.response?.data?.message || MESSAGE.TOATS_ERROR_SYSTEM,
            intent: Intent.DANGER,
          })
        } else {
          this.setState({ isOpenPopupCapSoKhongXacThuc: false })
          this.props.actions.commonAddToasterMessage({
            message: MESSAGE.TOATS_MESSAGE_SUCCESS,
            intent: Intent.SUCCESS,
          })
          return (
            this.props.history &&
            this.props.history.push('/ky-so/cong-van-di/danh-sach')
          )
        }
      })
  }

  onCapSoKhongCanXacThuc = async () => {
    let error = {}
    if (
      !this.state.soCongVan ||
      this.state.soCongVan.toString().trim().length === 0
    ) {
      error.soCongVan = MESSAGE.TEXT_MESSAGE_DEFAULT
    }

    if (
      this.state.soCongVan &&
      (isNaN(Number(this.state.soCongVan)) || Number(this.state.soCongVan) <= 0)
    ) {
      error.soCongVan = MESSAGE.TEXT_GIATRI_LONHON_0
    }
    if (!this.state.ngayCongVan) {
      error.ngayCongVan = MESSAGE.TEXT_MESSAGE_DEFAULT
    }

    this.setState({ error })
    if (Object.keys(error).length !== 0) {
      return this.props.actions.commonAddToasterMessage({
        message: MESSAGE.TOATS_MESSAGE_WARNING,
        intent: Intent.WARNING,
      })
    }

    // Kiem tra so van ban da duoc cap chua
    let isValidProgression = false
    // Kiem tra goi api check cap so thanh cong hay khong?
    let isCallValidProgressionSuccess = true
    this.setState({
      isLoading: {
        ...this.state.isLoading,
        progression: true,
      },
    })
    await this.props.actions
      .signNumberCheckProgression(
        this.state.signNumberId,
        this.state.soCongVan,
        this.state.ngayCongVanToString
      )
      .then(res => {
        this.setState({
          isLoading: {
            ...this.state.isLoading,
            progression: false,
          },
        })
        if (
          !res ||
          !res.payload ||
          res.error ||
          res.payload.data === undefined
        ) {
          isCallValidProgressionSuccess = false
          return this.props.actions.commonAddToasterMessage({
            message: MESSAGE.TOATS_MESSAGE_FAIL,
            intent: Intent.DANGER,
          })
        }

        isValidProgression = res.payload.data
      })
      .catch(err => {
        this.setState({
          isLoading: {
            ...this.state.isLoading,
            progression: false,
          },
        })
        isCallValidProgressionSuccess = false
        return this.props.actions.commonAddToasterMessage({
          message: MESSAGE.TOATS_MESSAGE_FAIL,
          intent: Intent.DANGER,
        })
      })

    if (isCallValidProgressionSuccess === true) {
      if (isValidProgression === true) {
        return this.fetchCapSoKhongCanXacThuc()
      } else {
        this.handleClickPopupCapSoKhongXacThuc()
      }
    }

    if (isCallValidProgressionSuccess === false) {
      return this.props.actions.commonAddToasterMessage({
        message: MESSAGE.TOATS_MESSAGE_FAIL,
        intent: Intent.DANGER,
      })
    }
  }

  sendProgressionBySIM = async (type = VIETTEL) => {
    if (type === VIETTEL) {
      await this.setState({
        isLoading: {
          ...this.state.isLoading,
          progressionByVIETTEL: true,
        },
      })
    } else {
      await this.setState({
        isLoading: {
          ...this.state.isLoading,
          progressionByVNPT: true,
        },
      })
    }
    this.sendProgression()
  }

  handleProgressionBySIM = async e => {
    this.props.actions
      .progressionBySIM(
        this.state.signNumberId,
        this.state.soCongVan,
        this.state.ngayCongVan,
        this.state.isLoading.progressionByVIETTEL
      )
      .then(res => {
        if (
          !res ||
          (res && res.error) ||
          (res && !res.payload) ||
          (res &&
            res.payload &&
            res.payload.data &&
            res.payload.data.result === false)
        ) {
          this.setState({
            isLoading: {
              ...this.state.isLoading,
              progressionByVIETTEL: false,
              progressionByVNPT: false,
              progression: false,
            },
          })
          let error = MESSAGE.TOATS_MESSAGE_FAIL
          if (
            res &&
            res.error &&
            res.error.response &&
            res.error.response.data &&
            res.error.response.data.message
          ) {
            error = res.error.response.data.message
          }
          return (
            this.props.onSubmit &&
            this.props.onSubmit({
              title: 'DongYKySoBangSIM',
              isSuccess: false,
              message: error,
              intent: Intent.DANGER,
            })
          )
        }
        this.setState({
          isLoading: {
            ...this.state.isLoading,
            progressionByVIETTEL: false,
            progressionByVNPT: false,
            progression: false,
          },
        })
        return (
          this.props.onSubmit &&
          this.props.onSubmit({
            title: 'DongYKySoBangSIM',
            isSuccess: true,
            message: MESSAGE.TOATS_MESSAGE_SUCCESS,
            intent: Intent.SUCCESS,
          })
        )
      })
  }

  sendProgression = async e => {
    let error = {}
    this.setState({
      isLoading: {
        ...this.state.isLoading,
        progression: true,
      },
    })

    if (
      !this.state.soCongVan ||
      this.state.soCongVan.toString().trim().length === 0
    ) {
      error.soCongVan = MESSAGE.TEXT_MESSAGE_DEFAULT
    }

    if (
      this.state.soCongVan &&
      (isNaN(Number(this.state.soCongVan)) || Number(this.state.soCongVan) <= 0)
    ) {
      error.soCongVan = MESSAGE.TEXT_GIATRI_LONHON_0
    }
    if (!this.state.ngayCongVan) {
      error.ngayCongVan = MESSAGE.TEXT_MESSAGE_DEFAULT
    }

    this.setState({ error })
    if (Object.keys(error).length !== 0) {
      this.setState({
        isLoading: {
          ...this.state.isLoading,
          progression: false,
          progressionByVIETTEL: false,
          progressionByVNPT: false,
        },
      })
      return (
        this.props.onSubmit &&
        this.props.onSubmit({
          title: 'CapSo',
          isSuccess: false,
          message: MESSAGE.TOATS_MESSAGE_WARNING,
          intent: Intent.WARNING,
        })
      )
    }

    await this.setState({
      isLoading: {
        ...this.state.isLoading,
        progression: true,
        checkProgression: true,
      },
    })

    // Kiem tra so van ban da duoc cap chua
    let isValidProgression = false
    // Kiem tra goi api check cap so thanh cong hay khong?
    let isCallValidProgressionSuccess = true
    await this.props.actions
      .signNumberCheckProgression(
        this.state.signNumberId,
        this.state.soCongVan,
        this.state.ngayCongVanToString
      )
      .then(res => {
        if (
          res.error ||
          !res ||
          !res.payload ||
          res.payload.data === undefined
        ) {
          this.setState({
            isLoading: {
              ...this.state.isLoading,
              progression: false,
              progressionByVIETTEL: false,
              progressionByVNPT: false,
              checkProgression: false,
            },
          })
          isCallValidProgressionSuccess = false
          return (
            this.props.onSubmit &&
            this.props.onSubmit({
              title: 'CapSo',
              isSuccess: false,
              message: MESSAGE.TOATS_MESSAGE_FAIL,
              intent: Intent.DANGER,
            })
          )
        }
        isValidProgression = res.payload.data
      })
      .catch(err => {
        this.setState({
          isLoading: {
            ...this.state.isLoading,
            progression: false,
            progressionByVIETTEL: false,
            progressionByVNPT: false,
            checkProgression: false,
          },
        })
        isCallValidProgressionSuccess = false
        return (
          this.props.onSubmit &&
          this.props.onSubmit({
            title: 'CapSo',
            isSuccess: false,
            message: MESSAGE.TOATS_MESSAGE_FAIL,
            intent: Intent.DANGER,
          })
        )
      })

    await this.setState({
      isLoading: {
        ...this.state.isLoading,
        checkProgression: false,
      },
    })

    if (isValidProgression === true && isCallValidProgressionSuccess === true) {
      if (
        this.state.isLoading.progressionByVIETTEL ||
        this.state.isLoading.progressionByVNPT
      ) {
        return this.handleProgressionBySIM()
      } else {
        this.props.onRemoveFileInfo && this.props.onRemoveFileInfo()
        return (window.location.href = `eofficecpc:id_vb=${this.state.signNumberId};socv=${this.state.soCongVan};ngaycv=${this.state.ngayCongVanToString};id_nv=${this.state.chucDanhId}`)
      }
    }

    if (isCallValidProgressionSuccess === false) {
      return
    }

    this.handleClickPopup()
  }

  handleClickAgree = async () => {
    await this.setState({
      isLoading: {
        ...this.state.isLoading,
        agree: true,
      },
    })

    // Gui gop y:
    if (
      this.state.feedbackContent.length > 0 ||
      this.state.feedbackForLeader.length > 0
    ) {
      this.sendFeedback('NotShowMessage', () => {
        if (
          !this.state.isSubmitFeedbackSuccess ||
          this.state.isSubmitFeedbackSuccess === false
        ) {
          this.setState({
            isLoading: {
              ...this.state.isLoading,
              agree: false,
            },
          })
          return (
            this.props.onSubmit &&
            this.props.onSubmit({
              title: 'DongYKhongCanKySo',
              isSuccess: false,
              message: MESSAGE.TOATS_MESSAGE_FAIL,
              intent: Intent.DANGER,
            })
          )
        }
      })
    }

    if (this.props.type === 'kySoDi') {
      return this.props.actions
        .signNumberSendAgree(this.state.signNumberId, {
          nguoi_ky_id: this.state.chucDanhId,
          file_co_dau: null,
          file_khong_dau: null,
          loai_ky_duyet: Types.TYPE_SIGN_NUMBER_AGREE,
        })
        .then(res => {
          if (
            res.error ||
            !res.payload ||
            (res.payload &&
              res.payload.data &&
              res.payload.data.result === false)
          ) {
            this.setState({
              isLoading: {
                ...this.state.isLoading,
                agree: false,
              },
            })
            return (
              this.props.onSubmit &&
              this.props.onSubmit({
                title: 'DongYKhongCanKySo',
                isSuccess: false,
                message:
                  res?.error?.response?.data?.code !== 500
                    ? res?.error?.response?.data?.message
                    : MESSAGE.TOATS_MESSAGE_FAIL,
                intent: Intent.DANGER,
              })
            )
          }

          this.setState({
            isLoading: {
              ...this.state.isLoading,
              agree: false,
            },
          })
          return (
            this.props.onSubmit &&
            this.props.onSubmit({
              title: 'DongYKhongCanKySo',
              isSuccess: true,
              message: MESSAGE.TOATS_MESSAGE_SUCCESS,
              intent: Intent.SUCCESS,
            })
          )
        })
    }

    this.props.actions
      .signNumberInternalAgree(this.state.signNumberId, {
        nguoi_ky_id: this.state.chucDanhId,
        file_co_dau: null,
        file_khong_dau: null,
        loai_ky_duyet: Types.TYPE_SIGN_NUMBER_AGREE,
      })
      .then(res => {
        if (
          res.error ||
          !res.payload ||
          (res.payload && res.payload.data && res.payload.data.result === false)
        ) {
          this.setState({
            isLoading: {
              ...this.state.isLoading,
              agree: false,
            },
          })
          return (
            this.props.onSubmit &&
            this.props.onSubmit({
              title: 'DongYKhongCanKySo',
              isSuccess: false,
              message: MESSAGE.TOATS_MESSAGE_FAIL,
              intent: Intent.DANGER,
            })
          )
        }

        this.setState({
          isLoading: {
            ...this.state.isLoading,
            agree: false,
          },
        })
        return (
          this.props.onSubmit &&
          this.props.onSubmit({
            title: 'DongYKhongCanKySo',
            isSuccess: true,
            message: MESSAGE.TOATS_MESSAGE_SUCCESS,
            intent: Intent.SUCCESS,
          })
        )
      })
  }

  handleClickAgreeBySIM = async (type = VIETTEL) => {
    if (type === VIETTEL) {
      await this.setState({
        isLoading: {
          ...this.state.isLoading,
          agreeBySIM: true,
        },
      })
    } else {
      await this.setState({
        isLoading: {
          ...this.state.isLoading,
          agreeBySIMVNPT: true,
        },
      })
    }
    // Gui gop y:
    if (
      this.state.feedbackContent.length > 0 ||
      this.state.feedbackForLeader.length > 0
    ) {
      this.sendFeedback('NotShowMessage', () => {
        if (
          !this.state.isSubmitFeedbackSuccess ||
          this.state.isSubmitFeedbackSuccess === false
        ) {
          if (type === VIETTEL) {
            this.setState({
              isLoading: {
                ...this.state.isLoading,
                agreeBySIM: false,
              },
            })
          } else {
            this.setState({
              isLoading: {
                ...this.state.isLoading,
                agreeBySIMVNPT: false,
              },
            })
          }
          return (
            this.props.onSubmit &&
            this.props.onSubmit({
              title: 'DongYKySoBangSIM',
              isSuccess: false,
              message: MESSAGE.TOATS_MESSAGE_FAIL,
              intent: Intent.DANGER,
            })
          )
        }
      })
    }

    if (this.props.type === 'kySoDi') {
      return this.props.actions
        .signNumberSendAgreeBySIM(this.state.signNumberId, type)
        .then(res => {
          if (
            !res ||
            (res && res.error) ||
            (res && !res.payload) ||
            (res &&
              res.payload &&
              res.payload.data &&
              res.payload.data.result === false)
          ) {
            if (type === VIETTEL) {
              this.setState({
                isLoading: {
                  ...this.state.isLoading,
                  agreeBySIM: false,
                },
              })
            } else {
              this.setState({
                isLoading: {
                  ...this.state.isLoading,
                  agreeBySIMVNPT: false,
                },
              })
            }
            let error = MESSAGE.TOATS_MESSAGE_FAIL
            if (
              res &&
              res.error &&
              res.error.response &&
              res.error.response.data &&
              res.error.response.data.message
            ) {
              error = res.error.response.data.message
            }
            return (
              this.props.onSubmit &&
              this.props.onSubmit({
                title: 'DongYKySoBangSIM',
                isSuccess: false,
                message: error,
                intent: Intent.DANGER,
              })
            )
          }

          if (type === VIETTEL) {
            this.setState({
              isLoading: {
                ...this.state.isLoading,
                agreeBySIM: false,
              },
            })
          } else {
            this.setState({
              isLoading: {
                ...this.state.isLoading,
                agreeBySIMVNPT: false,
              },
            })
          }
          return (
            this.props.onSubmit &&
            this.props.onSubmit({
              title: 'DongYKySoBangSIM',
              isSuccess: true,
              message: MESSAGE.TOATS_MESSAGE_SUCCESS,
              intent: Intent.SUCCESS,
            })
          )
        })
    }

    this.props.actions
      .signNumberInternalAgreeBySIM(this.state.signNumberId, type)
      .then(res => {
        if (
          !res ||
          (res && res.error) ||
          (res && !res.payload) ||
          (res &&
            res.payload &&
            res.payload.data &&
            res.payload.data.result === false)
        ) {
          if (type === VIETTEL) {
            this.setState({
              isLoading: {
                ...this.state.isLoading,
                agreeBySIM: false,
              },
            })
          } else {
            this.setState({
              isLoading: {
                ...this.state.isLoading,
                agreeBySIMVNPT: false,
              },
            })
          }
          let error = MESSAGE.TOATS_MESSAGE_FAIL
          if (
            res &&
            res.error &&
            res.error.response &&
            res.error.response.data &&
            res.error.response.data.message
          ) {
            error = res.error.response.data.message
          }
          return (
            this.props.onSubmit &&
            this.props.onSubmit({
              title: 'DongYKySoBangSIM',
              isSuccess: false,
              message: error,
              intent: Intent.DANGER,
            })
          )
        }

        if (type === VIETTEL) {
          this.setState({
            isLoading: {
              ...this.state.isLoading,
              agreeBySIM: false,
            },
          })
        } else {
          this.setState({
            isLoading: {
              ...this.state.isLoading,
              agreeBySIMVNPT: false,
            },
          })
        }
        return (
          this.props.onSubmit &&
          this.props.onSubmit({
            title: 'DongYKySoBangSIM',
            isSuccess: true,
            message: MESSAGE.TOATS_MESSAGE_SUCCESS,
            intent: Intent.SUCCESS,
          })
        )
      })
  }

  handleClickAgreeByTOKEN = async () => {
    await this.setState({
      isLoading: {
        ...this.state.isLoading,
        agreeByTOKEN: true,
      },
    })

    // Gui gop y:
    if (
      this.state.feedbackContent.length > 0 ||
      this.state.feedbackForLeader.length > 0
    ) {
      this.sendFeedback('NotShowMessage', () => {
        if (
          !this.state.isSubmitFeedbackSuccess ||
          this.state.isSubmitFeedbackSuccess === false
        ) {
          this.setState({
            isLoading: {
              ...this.state.isLoading,
              agreeByTOKEN: false,
            },
          })
          return (
            this.props.onSubmit &&
            this.props.onSubmit({
              title: 'DongYKySoBangTOKEN',
              isSuccess: false,
              message: MESSAGE.TOATS_MESSAGE_FAIL,
              intent: Intent.DANGER,
            })
          )
        }
      })
    }

    this.setState(
      {
        isLoading: {
          ...this.state.isLoading,
          agreeByTOKEN: true,
        },
      },
      () => {
        this.props.onRemoveFileInfo && this.props.onRemoveFileInfo()
      }
    )
    window.location.href = this.state.hrefToken
  }

  handleClickDisagree = async () => {
    await this.setState({
      isLoading: {
        ...this.state.isLoading,
        disagree: true,
      },
    })

    if (
      this.state.feedbackContent.length === 0 &&
      this.state.feedbackForLeader.length === 0
    ) {
      this.setState({
        isLoading: {
          ...this.state.isLoading,
          disagree: false,
        },
      })
      return (
        this.props.onSubmit &&
        this.props.onSubmit({
          title: 'KhongDongY',
          isSuccess: false,
          message: MESSAGE.TEXT_MESSAGE_DISSAGREE_SIGNNUMBER,
          intent: Intent.WARNING,
        })
      )
    }

    // Gui gop y:
    this.sendFeedback('NotShowMessage', () => {
      if (
        !this.state.isSubmitFeedbackSuccess ||
        this.state.isSubmitFeedbackSuccess === false
      ) {
        this.setState({
          isLoading: {
            ...this.state.isLoading,
            disagree: false,
          },
        })
        return (
          this.props.onSubmit &&
          this.props.onSubmit({
            title: 'KhongDongY',
            isSuccess: false,
            message: MESSAGE.TOATS_MESSAGE_FAIL,
            intent: Intent.DANGER,
          })
        )
      }
    })

    if (this.props.type === 'kySoDi') {
      return this.props.actions
        .signNumberSendDisagree(this.state.signNumberId, {
          nguoi_ky_id: this.state.chucDanhId,
          file_co_dau: null,
          file_khong_dau: null,
        })
        .then(res => {
          if (
            res.error ||
            !res.payload ||
            (res.payload &&
              res.payload.data &&
              res.payload.data.result === false)
          ) {
            this.setState({
              isLoading: {
                ...this.state.isLoading,
                disagree: false,
              },
            })
            return (
              this.props.onSubmit &&
              this.props.onSubmit({
                title: 'KhongDongY',
                isSuccess: false,
                message: MESSAGE.TOATS_MESSAGE_FAIL,
                intent: Intent.DANGER,
              })
            )
          }

          this.setState({
            isLoading: {
              ...this.state.isLoading,
              disagree: false,
            },
          })
          return (
            this.props.onSubmit &&
            this.props.onSubmit({
              title: 'KhongDongY',
              isSuccess: true,
              message: MESSAGE.TOATS_MESSAGE_SUCCESS,
              intent: Intent.SUCCESS,
            })
          )
        })
    }

    this.props.actions
      .signNumberInternalDisagree(this.state.signNumberId, {
        nguoi_ky_id: this.state.chucDanhId,
        file_co_dau: null,
        file_khong_dau: null,
      })
      .then(res => {
        if (
          res.error ||
          !res.payload ||
          (res.payload && res.payload.data && res.payload.data.result === false)
        ) {
          this.setState({
            isLoading: {
              ...this.state.isLoading,
              disagree: false,
            },
          })
          return (
            this.props.onSubmit &&
            this.props.onSubmit({
              title: 'KhongDongY',
              isSuccess: false,
              message: MESSAGE.TOATS_MESSAGE_FAIL,
              intent: Intent.DANGER,
            })
          )
        }

        this.setState({
          isLoading: {
            ...this.state.isLoading,
            disagree: false,
          },
        })
        return (
          this.props.onSubmit &&
          this.props.onSubmit({
            title: 'KhongDongY',
            isSuccess: true,
            message: MESSAGE.TOATS_MESSAGE_SUCCESS,
            intent: Intent.SUCCESS,
          })
        )
      })
  }

  handleUpdateFeedback = (feedbackId = null) => {
    this.setState({ feedbackId: feedbackId })
  }

  componentDidMount() {
    let state = this.state
    state.chucDanhId = this.props.auth ? this.props.auth.roleId : null
    state.phongBanId =
      this.props.auth && this.props.auth.mainDepartmentId
        ? this.props.auth.mainDepartmentId
        : null
    state.maPhongBan = null
    state.maKySo = null
    if (
      this.props.auth &&
      this.props.auth.user &&
      this.props.auth.user.dsChucDanh &&
      state.chucDanhId
    ) {
      if (
        this.props.auth.user.dsChucDanh.find(
          item => item.id === state.chucDanhId
        ) !== undefined
      ) {
        const phongBan = this.props.auth.user.dsChucDanh.find(
          item => item.id === state.chucDanhId
        ).phongBan
        state.maPhongBan =
          phongBan && phongBan.maPhongBan && phongBan.maPhongBan.toLowerCase()
      }
    }
    state.ngayCongVan = moment()
    state.ngayCongVanToString = moment()
      .format('YYYY-MM-DD')
      .toString()
      .replace(/-/g, '')
    if (this.props.signNumber) {
      state.signNumberId = this.props.signNumber.kySoId
      if (
        this.props.signNumber.tinhTrang === 6 &&
        this.props.signNumber.soVanBan &&
        !isNaN(Number(this.props.signNumber.soVanBan)) &&
        Number(this.props.signNumber.soVanBan) > 0
      ) {
        state.soCongVan = this.props.signNumber.soVanBan
      }
      if (
        this.props.signNumber.tinhTrang === 6 &&
        this.props.signNumber.ngayCongVan &&
        Tool.Utils.isValidDate(
          moment(this.props.signNumber.ngayCongVan).format('DD/MM/YYYY')
        )
      ) {
        state.ngayCongVan = moment(this.props.signNumber.ngayCongVan)
        state.ngayCongVanToString = moment(this.props.signNumber.ngayCongVan)
          .format('YYYY-MM-DD')
          .toString()
          .replace(/-/g, '')
        state.dateToToString = moment(this.props.signNumber.ngayCongVan).format(
          'DD/MM/YYYY'
        )
      }
      // LD DV
      const lanhDaoDonViId = this.props.signNumber.lanhDaoDonVi
        ? this.props.signNumber.lanhDaoDonVi.chucDanhId
        : null
      if (
        this.props.signNumber.lanhDaoDonVi &&
        this.props.signNumber.lanhDaoDonVi.trangThai === Types.DISAGREE
      ) {
        state.userDisagree = this.props.signNumber.lanhDaoDonVi.chucDanhId
        state.hrefToken = `eofficecpc:id_vb=${state.signNumberId};id_nv=${state.chucDanhId};type=2`
        if (this.props.type === 'kySoNoiBo') {
          state.hrefToken = `eofficecpc:id_vb=${state.signNumberId};id_nv=${state.chucDanhId};type=0;noiky=chuky`
        }
      }
      if (
        this.props.signNumber.lanhDaoDonVi &&
        this.props.signNumber.lanhDaoDonVi.trangThai === Types.AGREED
      ) {
        state.userAgrees &&
          state.userAgrees.find(
            item => item === this.props.signNumber.lanhDaoDonVi.chucDanhId
          ) === undefined &&
          state.userAgrees.push(this.props.signNumber.lanhDaoDonVi.chucDanhId)
      }

      // LD DV cap tren
      const lanhDaoDonViCapTrenId = this.props.signNumber.lanhDaoDonViCapTren
        ? this.props.signNumber.lanhDaoDonViCapTren.chucDanhId
        : null
      if (
        this.props.signNumber.lanhDaoDonViCapTren &&
        this.props.signNumber.lanhDaoDonViCapTren.trangThai === Types.DISAGREE
      ) {
        state.userDisagree =
          this.props.signNumber.lanhDaoDonViCapTren.chucDanhId
        state.hrefToken = `eofficecpc:id_vb=${state.signNumberId};id_nv=${state.chucDanhId};type=0;noiky=ct_chuky`
      }
      if (
        this.props.signNumber.lanhDaoDonViCapTren &&
        this.props.signNumber.lanhDaoDonViCapTren.trangThai === Types.AGREED
      ) {
        state.userAgrees &&
          state.userAgrees.find(
            item =>
              item === this.props.signNumber.lanhDaoDonViCapTren.chucDanhId
          ) === undefined &&
          state.userAgrees.push(
            this.props.signNumber.lanhDaoDonViCapTren.chucDanhId
          )
      }

      // LD DV khac cung ky
      const lanhDaoDonViPhoiHopId = this.props.signNumber.lanhDaoDonViPhoiHop
        ? this.props.signNumber.lanhDaoDonViPhoiHop.chucDanhId
        : null
      if (
        this.props.signNumber.lanhDaoDonViPhoiHop &&
        this.props.signNumber.lanhDaoDonViPhoiHop.trangThai === Types.DISAGREE
      ) {
        state.userDisagree =
          this.props.signNumber.lanhDaoDonViPhoiHop.chucDanhId
        state.hrefToken = `eofficecpc:id_vb=${state.signNumberId};id_nv=${state.chucDanhId};type=3`
      }
      if (
        this.props.signNumber.lanhDaoDonViPhoiHop &&
        this.props.signNumber.lanhDaoDonViPhoiHop.trangThai === Types.AGREED
      ) {
        state.userAgrees &&
          state.userAgrees.find(
            item =>
              item === this.props.signNumber.lanhDaoDonViPhoiHop.chucDanhId
          ) === undefined &&
          state.userAgrees.push(
            this.props.signNumber.lanhDaoDonViPhoiHop.chucDanhId
          )
      }

      // LD PB chu tri
      const lanhDaoPhongBanChuTri = this.props.signNumber.lanhDaoPhongBanChuTri
        ? this.props.signNumber.lanhDaoPhongBanChuTri?.chucDanhId
        : null
      if (
        this.props.signNumber.lanhDaoPhongBanChuTri &&
        this.props.signNumber.lanhDaoPhongBanChuTri?.trangThai ===
          Types.DISAGREE
      ) {
        state.userDisagree =
          this.props.signNumber.lanhDaoPhongBanChuTri?.chucDanhId
        if (this.props.signNumber && this.props.signNumber.phongBanLap) {
          state.maKySo = this.props.signNumber.phongBanLap.maKySo
        }
        state.hrefToken = `eofficecpc:id_vb=${state.signNumberId};id_nv=${state.chucDanhId};type=1`
        if (this.props.type === 'kySoNoiBo') {
          state.hrefToken = `eofficecpc:id_vb=${state.signNumberId};id_nv=${state.chucDanhId};type=0;noiky=${state.maKySo}`
        }
      }
      if (
        this.props.signNumber.lanhDaoPhongBanChuTri &&
        this.props.signNumber.lanhDaoPhongBanChuTri?.trangThai === Types.AGREED
      ) {
        state.userAgrees &&
          state.userAgrees.find(
            item =>
              item === this.props.signNumber.lanhDaoPhongBanChuTri?.chucDanhId
          ) === undefined &&
          state.userAgrees.push(
            this.props.signNumber.lanhDaoPhongBanChuTri?.chucDanhId
          )
      }
      // TK DV
      let thuKyArr = null
      let dsThuKy = []
      if (this.props.signNumber.thuKy) {
        thuKyArr = [this.props.signNumber.thuKy.chucDanhId]
        state.secretaryId = thuKyArr
        if (this.props.signNumber.thuKy.trangThai === Types.DISAGREE) {
          state.userDisagree = this.props.signNumber.thuKy.chucDanhId
          state.hrefToken = `eofficecpc:id_vb=${state.signNumberId};id_nv=${state.chucDanhId};type=1`
          if (this.props.type === 'kySoNoiBo') {
            state.hrefToken = `eofficecpc:id_vb=${state.signNumberId};id_nv=${state.chucDanhId};type=0;noiky=kynhay`
          }
        }
        if (this.props.signNumber.thuKy.trangThai === Types.AGREED) {
          state.userAgrees &&
            state.userAgrees.find(
              item => item === this.props.signNumber.thuKy.chucDanhId
            ) === undefined &&
            state.userAgrees.push(this.props.signNumber.thuKy.chucDanhId)
        }
      } else {
        if (
          this.props.signNumber.banThuKy &&
          this.props.signNumber.banThuKy.dsThuKy &&
          this.props.signNumber.banThuKy.dsThuKy.length > 0
        ) {
          dsThuKy = this.props.signNumber.banThuKy.dsThuKy
          thuKyArr = dsThuKy.map(user => user.chucDanhId)
          state.secretaryId = thuKyArr
        }
        if (dsThuKy.length > 0) {
          let secretaryDisagree = dsThuKy.find(
            user => user.trangThai === Types.DISAGREE
          )
          if (secretaryDisagree) {
            state.userAgrees.push(
              ...pullAll(thuKyArr, [
                ...state.userAgrees,
                secretaryDisagree.chucDanhId,
              ]).map(user => user.chucDanhId)
            )
            state.userDisagree = secretaryDisagree.chucDanhId
            state.hrefToken = `eofficecpc:id_vb=${state.signNumberId};id_nv=${state.chucDanhId};type=1`
            if (this.props.type === 'kySoNoiBo') {
              state.hrefToken = `eofficecpc:id_vb=${state.signNumberId};id_nv=${state.chucDanhId};type=0;noiky=kynhay`
            }
          } else {
            if (
              dsThuKy.findIndex(user => user.trangThai === Types.AGREED) !== -1
            ) {
              state.userAgrees.push(
                ...pullAll(thuKyArr, state.userAgrees).map(
                  user => user.chucDanhId
                )
              )
            }
          }
        }
      }
      // TK DV cap tren
      const thuKyCapTren = this.props.signNumber.thuKyCapTren
        ? this.props.signNumber.thuKyCapTren.chucDanhId
        : null
      state.parentSecretaryId = thuKyCapTren
      if (
        this.props.signNumber.thuKyCapTren &&
        this.props.signNumber.thuKyCapTren.trangThai === Types.DISAGREE
      ) {
        state.userDisagree = this.props.signNumber.thuKyCapTren.chucDanhId
        state.hrefToken = `eofficecpc:id_vb=${state.signNumberId};id_nv=${state.chucDanhId};type=0;noiky=kynhay`
      }
      if (
        this.props.signNumber.thuKyCapTren &&
        this.props.signNumber.thuKyCapTren.trangThai === Types.AGREED
      ) {
        state.userAgrees &&
          state.userAgrees.find(
            item => item === this.props.signNumber.thuKyCapTren.chucDanhId
          ) === undefined &&
          state.userAgrees.push(this.props.signNumber.thuKyCapTren.chucDanhId)
      }

      // TK DV khac cung ky
      const thuKyPhoiHop = this.props.signNumber.thuKyPhoiHop
        ? this.props.signNumber.thuKyPhoiHop.chucDanhId
        : null
      state.otherSecretaryId = thuKyPhoiHop
      if (
        this.props.signNumber.thuKyPhoiHop &&
        this.props.signNumber.thuKyPhoiHop.trangThai === Types.DISAGREE
      ) {
        state.userDisagree = this.props.signNumber.thuKyPhoiHop.chucDanhId
        state.hrefToken = `eofficecpc:id_vb=${state.signNumberId};id_nv=${state.chucDanhId};type=1`
      }
      if (
        this.props.signNumber.thuKyPhoiHop &&
        this.props.signNumber.thuKyPhoiHop.trangThai === Types.AGREED
      ) {
        state.userAgrees &&
          state.userAgrees.find(
            item => item === this.props.signNumber.thuKyPhoiHop.chucDanhId
          ) === undefined &&
          state.userAgrees.push(this.props.signNumber.thuKyPhoiHop.chucDanhId)
      }

      // phong ban phoi hop thuong
      if (this.props.signNumber.phoiHop) {
        const department = this.props.signNumber.phoiHop.find(
          item => item.phongBanId === state.phongBanId
        )
        state.maKySo = department ? department.maKySo : state.maKySo
        if (
          department !== undefined &&
          department.trangThai === Types.NOT_YET_AGREE
        ) {
          state.isCompositiveDepartment = true
          state.hrefToken = `eofficecpc:id_vb=${state.signNumberId};id_nv=${state.chucDanhId};type=1`
          if (this.props.type === 'kySoNoiBo') {
            state.hrefToken = `eofficecpc:id_vb=${state.signNumberId};id_nv=${state.chucDanhId};type=0;noiky=${state.maKySo}`
          }
        }
        if (
          department !== undefined &&
          department.trangThai === Types.DISAGREE &&
          department.nguoiKy
        ) {
          state.userDisagree = department.nguoiKy.chucDanhId
          if (department.nguoiKy.chucDanhId === state.chucDanhId) {
            state.hrefToken = `eofficecpc:id_vb=${state.signNumberId};id_nv=${state.chucDanhId};type=1`
            if (this.props.type === 'kySoNoiBo') {
              state.hrefToken = `eofficecpc:id_vb=${state.signNumberId};id_nv=${state.chucDanhId};type=0;noiky=${state.maKySo}`
            }
          }
        }
        if (
          department !== undefined &&
          department.trangThai === Types.AGREED &&
          department.nguoiKy
        ) {
          state.userAgrees &&
            state.userAgrees.find(
              item => item === department.nguoiKy.chucDanhId
            ) === undefined &&
            state.userAgrees.push(department.nguoiKy.chucDanhId)
        }
      }

      // phong ban dien luc phoi hop
      if (this.props.signNumber.donViCungKy) {
        let unit = this.props.signNumber.donViCungKy.find(
          item => item.phongBan && item.phongBan.phongBanId === state.phongBanId
        )
        state.maKySo = unit
          ? unit.maKySo
            ? unit.maKySo
            : unit.maDonVi && unit.maDonVi.toLowerCase()
          : state.maKySo
        if (
          unit &&
          unit.nguoiKy &&
          unit.nguoiKy.chucDanhId === state.chucDanhId
        ) {
          state.isCompositiveDepartmentElectricUnit = true
        }
        if (unit !== undefined && unit.trangThai === Types.NOT_YET_AGREE) {
          state.isCompositiveDepartmentElectricUnit = true
          if (this.props.type === 'kySoNoiBo') {
            state.hrefToken = `eofficecpc:id_vb=${state.signNumberId};id_nv=${state.chucDanhId};type=0;noiky=${state.maKySo}`
          }
        }
        if (
          unit !== undefined &&
          unit.trangThai === Types.DISAGREE &&
          unit.nguoiKy
        ) {
          state.userDisagree = unit.nguoiKy.chucDanhId
          if (unit.nguoiKy.chucDanhId === state.chucDanhId) {
            if (this.props.type === 'kySoNoiBo') {
              state.hrefToken = `eofficecpc:id_vb=${state.signNumberId};id_nv=${state.chucDanhId};type=0;noiky=${state.maKySo}`
            }
          }
        }
        if (
          unit !== undefined &&
          unit.trangThai === Types.AGREED &&
          unit.nguoiKy
        ) {
          state.userAgrees &&
            state.userAgrees.find(item => item === unit.nguoiKy.chucDanhId) ===
              undefined &&
            state.userAgrees.push(unit.nguoiKy.chucDanhId)
        }
      }
      // phong ban lanh dao phoi hop
      if (this.props.signNumber.lanhDaoPhoiHop) {
        const leaderCompositiveDepartment =
          this.props.signNumber.lanhDaoPhoiHop.find(
            item =>
              item.phongBan && item.phongBan.phongBanId === state.phongBanId
          )
        if (
          leaderCompositiveDepartment &&
          leaderCompositiveDepartment.phongBan
        ) {
          state.maKySo = leaderCompositiveDepartment.phongBan.maKySo
        }
        state.leaderCompositiveDepartment = leaderCompositiveDepartment
        // la thu ky cua lanh dap phong ban phoi hop
        if (
          leaderCompositiveDepartment !== undefined &&
          leaderCompositiveDepartment.thuKy &&
          leaderCompositiveDepartment.thuKy.chucDanhId === state.chucDanhId
        ) {
          state.isLeaderCompositiveSecretary = true

          if (leaderCompositiveDepartment.thuKy.trangThai === Types.DISAGREE) {
            state.userDisagree = leaderCompositiveDepartment.thuKy.chucDanhId
            state.hrefToken = `eofficecpc:id_vb=${state.signNumberId};id_nv=${state.chucDanhId};type=0;noiky=kynhay`
          }

          if (leaderCompositiveDepartment.thuKy.trangThai === Types.AGREED) {
            state.userAgrees &&
              state.userAgrees.find(
                item => item === leaderCompositiveDepartment.thuKy.chucDanhId
              ) === undefined &&
              state.userAgrees.push(
                leaderCompositiveDepartment.thuKy.chucDanhId
              )
          }
        }

        // la lanh dao cua phong ban lanh dao
        if (
          leaderCompositiveDepartment !== undefined &&
          leaderCompositiveDepartment.lanhDao &&
          leaderCompositiveDepartment.lanhDao.chucDanhId === state.chucDanhId
        ) {
          // TODO: Cho phép lãnh đạo ký khi có quyền dù thư ký đã ký hay chưa
          // khong co thu ky
          // if (leaderCompositiveDepartment.lanhDao.trangThai === Types.NOT_YET_AGREE && !leaderCompositiveDepartment.thuKy) {
          //   state.isLeaderCompositiveLeaderDepartment = true;
          // }
          // co thu ky
          // if (
          //   leaderCompositiveDepartment.lanhDao.trangThai === Types.NOT_YET_AGREE &&
          //   leaderCompositiveDepartment.thuKy &&
          //   leaderCompositiveDepartment.thuKy.trangThai === Types.AGREED
          // ) {
          //   state.isLeaderCompositiveLeaderDepartment = true;
          // }

          // TODO: Cho phép lãnh đạo ký khi có quyền dù thư ký đã ký hay chưa
          if (
            leaderCompositiveDepartment.lanhDao.trangThai ===
            Types.NOT_YET_AGREE
          ) {
            state.isLeaderCompositiveLeaderDepartment = true
          }

          if (
            leaderCompositiveDepartment.lanhDao.trangThai === Types.DISAGREE
          ) {
            state.userDisagree = leaderCompositiveDepartment.lanhDao.chucDanhId
            state.hrefToken = `eofficecpc:id_vb=${state.signNumberId};id_nv=${state.chucDanhId};type=0;noiky=${state.maKySo}`
          }

          if (leaderCompositiveDepartment.lanhDao.trangThai === Types.AGREED) {
            state.userAgrees &&
              state.userAgrees.find(
                item => item === leaderCompositiveDepartment.lanhDao.chucDanhId
              ) === undefined &&
              state.userAgrees.push(
                leaderCompositiveDepartment.lanhDao.chucDanhId
              )
          }
        }
      }

      // LD PB PH cap tren
      if (this.props.signNumber.phoiHopCapTren) {
        const department = this.props.signNumber.phoiHopCapTren.find(
          item => item.phongBanId === state.phongBanId
        )
        state.maKySo = department ? department.maKySo : state.maKySo
        if (
          department !== undefined &&
          department.trangThai === Types.NOT_YET_AGREE
        ) {
          state.isParentCompositiveDepartment = true
          if (this.props.type === 'kySoNoiBo') {
            state.hrefToken = `eofficecpc:id_vb=${state.signNumberId};id_nv=${state.chucDanhId};type=0;noiky=ct_${state.maKySo}`
          }
        }
        if (
          department !== undefined &&
          department.trangThai === Types.DISAGREE &&
          department.nguoiKy
        ) {
          state.userDisagree = department.nguoiKy.chucDanhId
          state.hrefToken = `eofficecpc:id_vb=${state.signNumberId};id_nv=${state.chucDanhId};type=0;noiky=ct_${state.maKySo}`
        }
        if (
          department !== undefined &&
          department.trangThai === Types.AGREED &&
          department.nguoiKy
        ) {
          state.userAgrees &&
            state.userAgrees.find(
              item => item === department.nguoiKy.chucDanhId
            ) === undefined &&
            state.userAgrees.push(department.nguoiKy.chucDanhId)
        }
      }

      if (state.chucDanhId && state.chucDanhId === lanhDaoDonViId) {
        state.isLeaderUnit = true
      }

      if (state.chucDanhId && state.chucDanhId === lanhDaoDonViCapTrenId) {
        state.isParentLeaderUnit = true
      }

      if (state.chucDanhId && state.chucDanhId === lanhDaoDonViPhoiHopId) {
        state.isOtherLeaderUnit = true
      }

      if (state.chucDanhId && state.chucDanhId === lanhDaoPhongBanChuTri) {
        if (this.props.signNumber && this.props.signNumber.phongBanLap) {
          state.maKySo = this.props.signNumber.phongBanLap.maKySo
        }
        state.isLeaderDepartment = true
      }

      if (
        state.chucDanhId &&
        thuKyArr &&
        thuKyArr.findIndex(chucDanhId => chucDanhId === state.chucDanhId) !== -1
      ) {
        state.isSecretary = true
      }

      if (state.chucDanhId && state.chucDanhId === thuKyCapTren) {
        state.isParentSecretary = true
      }

      if (state.chucDanhId && state.chucDanhId === thuKyPhoiHop) {
        state.isOtherSecretary = true
      }

      // Khong duyet
      if (
        this.props.signNumber.tinhTrang === Types.DISAGREE &&
        state.chucDanhId === state.userDisagree
      ) {
        state.isShowButton = true
        state.isDisagree = true
      }
      //
      if (
        (this.props.signNumber.tinhTrang === Types.DISAGREE &&
          state.isSecretary &&
          state.secretaryId.findIndex(id => state.userDisagree === id) !==
            -1) ||
        (this.props.signNumber.tinhTrang === Types.SECRETARY &&
          state.isSecretary)
      ) {
        state.isShowButton = true
      }

      // Chua duyet
      if (
        this.props.signNumber.tinhTrang === Types.NOT_YET_AGREE &&
        state.isLeaderDepartment === true
      ) {
        state.isShowButton = true
        state.hrefToken = `eofficecpc:id_vb=${state.signNumberId};id_nv=${state.chucDanhId};type=1`
        if (this.props.type === 'kySoNoiBo') {
          state.hrefToken = `eofficecpc:id_vb=${state.signNumberId};id_nv=${state.chucDanhId};type=0;noiky=${state.maKySo}`
        }
        if (
          state.userAgrees &&
          state.userAgrees.find(item => item === state.chucDanhId) !== undefined
        ) {
          state.userAgrees = state.userAgrees.filter(
            item => item !== state.chucDanhId
          )
        }
      }

      // LDPBCT da duyet
      if (
        this.props.signNumber.tinhTrang === Types.LEADER_DEPARTMENT &&
        state.isCompositiveDepartment === true
      ) {
        state.isShowButton = true
        state.hrefToken = `eofficecpc:id_vb=${state.signNumberId};id_nv=${state.chucDanhId};type=1`
        if (this.props.type === 'kySoNoiBo') {
          state.hrefToken = `eofficecpc:id_vb=${state.signNumberId};id_nv=${state.chucDanhId};type=0;noiky=${state.maKySo}`
        }
      }

      // LDPB PH dang duyet
      if (
        this.props.signNumber.tinhTrang === Types.COMPOSITIVE_DEPARTMENT_SIGNING
      ) {
        // Chua dong y
        if (state.isCompositiveDepartment === true) {
          state.isShowButton = true
          state.hrefToken = `eofficecpc:id_vb=${state.signNumberId};id_nv=${state.chucDanhId};type=1`
          if (this.props.type === 'kySoNoiBo') {
            state.hrefToken = `eofficecpc:id_vb=${state.signNumberId};id_nv=${state.chucDanhId};type=0;noiky=${state.maKySo}`
          }
        }

        // Khong dong y
        if (state.chucDanhId === state.userDisagree) {
          state.isShowButton = true
          state.isDisagree = true
        }
      }

      // LDPBPH da duyet va khong co phong ban lanh dao
      if (
        this.props.signNumber.tinhTrang === Types.COMPOSITIVE_DEPARTMENT &&
        (!this.props.signNumber.lanhDaoPhoiHop ||
          this.props.signNumber.lanhDaoPhoiHop.length === 0)
      ) {
        if (this.props.type === 'kySoDi') {
          // tinhTrang = 3 && co Thu ky LD DV khac cung ky
          if (
            this.props.signNumber.thuKyPhoiHop &&
            state.isOtherSecretary === true
          ) {
            state.isShowButton = true
            state.hrefToken = `eofficecpc:id_vb=${state.signNumberId};id_nv=${state.chucDanhId};type=1`
            if (
              state.userAgrees &&
              state.userAgrees.find(item => item === state.chucDanhId) !==
                undefined
            ) {
              state.userAgrees = state.userAgrees.filter(
                item => item !== state.chucDanhId
              )
            }
          }
          // tinhTrang = 3 && khong co Thu ky LD DV khac cung ky && co LD DV khac cung ky
          if (
            // TODO: Cho phép lãnh đạo ký khi có quyền dù thư ký đã ký hay chưa
            // !this.props.signNumber.thuKyPhoiHop &&
            this.props.signNumber.lanhDaoDonViPhoiHop &&
            state.isOtherLeaderUnit === true
          ) {
            state.isShowButton = true
            state.hrefToken = `eofficecpc:id_vb=${state.signNumberId};id_nv=${state.chucDanhId};type=3`
            if (
              state.userAgrees &&
              state.userAgrees.find(item => item === state.chucDanhId) !==
                undefined
            ) {
              state.userAgrees = state.userAgrees.filter(
                item => item !== state.chucDanhId
              )
            }
          }
          // tinhTrang = 3 && khong co Thu ky LD DV khac cung ky && khong co LD DV khac cung ky
          if (
            !this.props.signNumber.thuKyPhoiHop &&
            !this.props.signNumber.lanhDaoDonViPhoiHop
            // state.isSecretary === true
          ) {
            if (state.isSecretary === true) {
              state.isShowButton = true
              state.hrefToken = `eofficecpc:id_vb=${state.signNumberId};id_nv=${state.chucDanhId};type=1`
              if (
                state.userAgrees &&
                state.userAgrees.find(item => item === state.chucDanhId) !==
                  undefined
              ) {
                state.userAgrees = state.userAgrees.filter(
                  item => item !== state.chucDanhId
                )
              }
            }

            // TODO: Cho phép lãnh đạo ký khi có quyền dù thư ký đã ký hay chưa
            if (state.isLeaderUnit === true) {
              state.isShowButton = true
              state.hrefToken = `eofficecpc:id_vb=${state.signNumberId};id_nv=${state.chucDanhId};type=2`
              if (
                state.userAgrees &&
                state.userAgrees.find(item => item === state.chucDanhId) !==
                  undefined
              ) {
                state.userAgrees = state.userAgrees.filter(
                  item => item !== state.chucDanhId
                )
              }
            }
          }
        } else if (this.props.type === 'kySoNoiBo') {
          if (state.isSecretary === true) {
            state.isShowButton = true
            state.hrefToken = `eofficecpc:id_vb=${state.signNumberId};id_nv=${state.chucDanhId};type=0;noiky=kynhay`
            if (
              state.userAgrees &&
              state.userAgrees.find(item => item === state.chucDanhId) !==
                undefined
            ) {
              state.userAgrees = state.userAgrees.filter(
                item => item !== state.chucDanhId
              )
            }
          }
          // TODO: Cho phép lãnh đạo ký khi có quyền dù thư ký đã ký hay chưa
          if (state.isLeaderUnit === true) {
            state.isShowButton = true
            state.hrefToken = `eofficecpc:id_vb=${state.signNumberId};id_nv=${state.chucDanhId};type=0;noiky=chuky`
            if (
              state.userAgrees &&
              state.userAgrees.find(item => item === state.chucDanhId) !==
                undefined
            ) {
              state.userAgrees = state.userAgrees.filter(
                item => item !== state.chucDanhId
              )
            }
          }
        }
      }

      // LDPBPH da duyet va co lanh dao dien luc phoi hop
      if (
        this.props.type === 'kySoNoiBo' &&
        this.props.signNumber.tinhTrang === Types.COMPOSITIVE_DEPARTMENT &&
        state.isCompositiveDepartmentElectricUnit === true
      ) {
        state.isShowButton = true
        state.hrefToken = `eofficecpc:id_vb=${state.signNumberId};id_nv=${state.chucDanhId};type=0;noiky=${state.maKySo}`
      }

      // LDPBPH da duyet va co phong ban lanh dao va khong co lanh dao dien luc phoi hop
      if (
        this.props.type === 'kySoNoiBo' &&
        this.props.signNumber.tinhTrang === Types.COMPOSITIVE_DEPARTMENT &&
        state.leaderCompositiveDepartment
      ) {
        // la thu ky cua lanh dao phong ban lanh dao phoi hop
        if (
          state.isLeaderCompositiveSecretary === true &&
          state.leaderCompositiveDepartment.thuKy
        ) {
          state.isShowButton = true
          state.hrefToken = `eofficecpc:id_vb=${state.signNumberId};id_nv=${state.chucDanhId};type=0;noiky=kynhay`
          if (
            state.userAgrees &&
            state.userAgrees.find(item => item === state.chucDanhId) !==
              undefined
          ) {
            state.userAgrees = state.userAgrees.filter(
              item => item !== state.chucDanhId
            )
          }
        }
        // la lanh dao phong ban phoi hop
        if (state.isLeaderCompositiveLeaderDepartment === true) {
          state.isShowButton = true
          state.hrefToken = `eofficecpc:id_vb=${state.signNumberId};id_nv=${state.chucDanhId};type=0;noiky=${state.maKySo}`
          if (
            state.userAgrees &&
            state.userAgrees.find(item => item === state.chucDanhId) !==
              undefined
          ) {
            state.userAgrees = state.userAgrees.filter(
              item => item !== state.chucDanhId
            )
          }
        }
      }

      // Lanh dao dien luc phoi hop dang duyet
      if (
        this.props.signNumber.tinhTrang ===
        Types.COMPOSITIVE_DEPARTMENT_ELECTRIC_UNIT_SIGNING
      ) {
        // Chua dong y
        if (state.isCompositiveDepartmentElectricUnit === true) {
          state.isShowButton = true
          state.hrefToken = `eofficecpc:id_vb=${state.signNumberId};id_nv=${state.chucDanhId};type=0;noiky=${state.maKySo}`
        }
        // Khong dong y
        if (state.chucDanhId === state.userDisagree) {
          state.isShowButton = true
          state.isDisagree = true
        }
      }

      // Lanh dao dien luc phoi hop da duyet
      if (
        this.props.type === 'kySoNoiBo' &&
        this.props.signNumber.tinhTrang ===
          Types.COMPOSITIVE_DEPARTMENT_ELECTRIC_UNIT &&
        state.leaderCompositiveDepartment
      ) {
        // la thu ky cua lanh dao phong ban lanh dao phoi hop
        if (
          state.isLeaderCompositiveSecretary === true &&
          state.leaderCompositiveDepartment.thuKy
        ) {
          state.isShowButton = true
          state.hrefToken = `eofficecpc:id_vb=${state.signNumberId};id_nv=${state.chucDanhId};type=0;noiky=kynhay`
          if (
            state.userAgrees &&
            state.userAgrees.find(item => item === state.chucDanhId) !==
              undefined
          ) {
            state.userAgrees = state.userAgrees.filter(
              item => item !== state.chucDanhId
            )
          }
        }
        // la lanh dao phong ban phoi hop
        if (state.isLeaderCompositiveLeaderDepartment === true) {
          state.isShowButton = true
          state.hrefToken = `eofficecpc:id_vb=${state.signNumberId};id_nv=${state.chucDanhId};type=0;noiky=${state.maKySo}`
          if (
            state.userAgrees &&
            state.userAgrees.find(item => item === state.chucDanhId) !==
              undefined
          ) {
            state.userAgrees = state.userAgrees.filter(
              item => item !== state.chucDanhId
            )
          }
        }
      }

      // LD phong ban lanh dao phoi hop da ky
      if (
        this.props.type === 'kySoNoiBo' &&
        this.props.signNumber.tinhTrang ===
          Types.COMPOSITIVE_LEADER_DEPARTMENT &&
        this.props.signNumber.lanhDaoPhoiHop
        // state.isSecretary === true
      ) {
        if (state.isSecretary === true) {
          state.isShowButton = true
          state.hrefToken = `eofficecpc:id_vb=${state.signNumberId};id_nv=${state.chucDanhId};type=0;noiky=kynhay`
          if (
            state.userAgrees &&
            state.userAgrees.find(item => item === state.chucDanhId) !==
              undefined
          ) {
            state.userAgrees = state.userAgrees.filter(
              item => item !== state.chucDanhId
            )
          }
        }

        // TODO: Cho phép lãnh đạo ký khi có quyền dù thư ký đã ký hay chưa
        if (state.isLeaderUnit === true) {
          state.isShowButton = true
          state.hrefToken = `eofficecpc:id_vb=${state.signNumberId};id_nv=${state.chucDanhId};type=0;noiky=chuky`
          if (
            state.userAgrees &&
            state.userAgrees.find(item => item === state.chucDanhId) !==
              undefined
          ) {
            state.userAgrees = state.userAgrees.filter(
              item => item !== state.chucDanhId
            )
          }
        }
      }

      // type = kySoDi && Thu ky LD DV khac cung ky da duyet
      if (
        this.props.type === 'kySoDi' &&
        this.props.signNumber.lanhDaoDonViPhoiHop &&
        this.props.signNumber.tinhTrang === Types.OTHER_SECRETARY &&
        state.isOtherLeaderUnit === true
      ) {
        state.isShowButton = true
        state.hrefToken = `eofficecpc:id_vb=${state.signNumberId};id_nv=${state.chucDanhId};type=3`
        if (
          state.userAgrees &&
          state.userAgrees.find(item => item === state.chucDanhId) !== undefined
        ) {
          state.userAgrees = state.userAgrees.filter(
            item => item !== state.chucDanhId
          )
        }
      }

      // type = kySoDi && LD DV khac cung ky da duyet
      if (
        this.props.type === 'kySoDi' &&
        this.props.signNumber.lanhDaoDonViPhoiHop &&
        this.props.signNumber.tinhTrang === Types.OTHER_LEADER_UNIT
        // state.isSecretary === true
      ) {
        if (state.isSecretary === true) {
          state.isShowButton = true
          state.hrefToken = `eofficecpc:id_vb=${state.signNumberId};id_nv=${state.chucDanhId};type=1`
          if (
            state.userAgrees &&
            state.userAgrees.find(item => item === state.chucDanhId) !==
              undefined
          ) {
            state.userAgrees = state.userAgrees.filter(
              item => item !== state.chucDanhId
            )
          }
        }
        // TODO: Cho phép lãnh đạo ký khi có quyền dù thư ký đã ký hay chưa
        if (state.isLeaderUnit === true) {
          state.isShowButton = true
          state.hrefToken = `eofficecpc:id_vb=${state.signNumberId};id_nv=${state.chucDanhId};type=2`
          if (
            state.userAgrees &&
            state.userAgrees.find(item => item === state.chucDanhId) !==
              undefined
          ) {
            state.userAgrees = state.userAgrees.filter(
              item => item !== state.chucDanhId
            )
          }
        }
      }

      // Thu ky LDDV da duyet
      if (
        this.props.signNumber.tinhTrang === Types.SECRETARY &&
        state.isLeaderUnit === true
      ) {
        state.isShowButton = true
        state.hrefToken = `eofficecpc:id_vb=${state.signNumberId};id_nv=${state.chucDanhId};type=2`
        if (this.props.type === 'kySoNoiBo') {
          state.hrefToken = `eofficecpc:id_vb=${state.signNumberId};id_nv=${state.chucDanhId};type=0;noiky=chuky`
        }
        if (
          state.userAgrees &&
          state.userAgrees.find(item => item === state.chucDanhId) !== undefined
        ) {
          state.userAgrees = state.userAgrees.filter(
            item => item !== state.chucDanhId
          )
        }
      }

      // LDDV da duyet
      if (
        this.props.signNumber.tinhTrang === Types.LEADER_UNIT &&
        state.isParentCompositiveDepartment === true
      ) {
        state.isShowButton = true
        if (this.props.type === 'kySoNoiBo') {
          state.hrefToken = `eofficecpc:id_vb=${state.signNumberId};id_nv=${state.chucDanhId};type=0;noiky=ct_${state.maKySo}`
        }
      }

      // LDPB PH CT dang duyet
      if (
        this.props.signNumber.tinhTrang ===
        Types.PARENT_COMPOSITIVE_DEPARTMENT_SIGNING
      ) {
        // Chua dong y
        if (state.isParentCompositiveDepartment === true) {
          state.isShowButton = true
          if (this.props.type === 'kySoNoiBo') {
            state.hrefToken = `eofficecpc:id_vb=${state.signNumberId};id_nv=${state.chucDanhId};type=0;noiky=ct_${state.maKySo}`
          }
        }

        // Khong dong y
        if (state.chucDanhId === state.userDisagree) {
          state.isShowButton = true
          state.isDisagree = true
        }
      }

      // type = kySoNoiBo && LDPBPH cap tren da duyet
      if (
        this.props.type === 'kySoNoiBo' &&
        this.props.signNumber.tinhTrang === Types.PARENT_COMPOSITIVE_DEPARTMENT
        // state.isParentSecretary === true
      ) {
        if (state.isParentSecretary === true) {
          state.isShowButton = true
          state.hrefToken = `eofficecpc:id_vb=${state.signNumberId};id_nv=${state.chucDanhId};type=0;noiky=kynhay`
          if (
            state.userAgrees &&
            state.userAgrees.find(item => item === state.chucDanhId) !==
              undefined
          ) {
            state.userAgrees = state.userAgrees.filter(
              item => item !== state.chucDanhId
            )
          }
        }
        // TODO: Cho phép lãnh đạo ký khi có quyền dù thư ký đã ký hay chưa
        if (state.isParentLeaderUnit === true) {
          state.isShowButton = true
          state.hrefToken = `eofficecpc:id_vb=${state.signNumberId};id_nv=${state.chucDanhId};type=0;noiky=ct_chuky`
          if (
            state.userAgrees &&
            state.userAgrees.find(item => item === state.chucDanhId) !==
              undefined
          ) {
            state.userAgrees = state.userAgrees.filter(
              item => item !== state.chucDanhId
            )
          }
        }
      }

      // type = kySoNoiBo && Thu ky LDDV cap tren da duyet
      if (
        this.props.type === 'kySoNoiBo' &&
        this.props.signNumber.tinhTrang === Types.PARENT_SECRETARY &&
        state.isParentLeaderUnit === true
      ) {
        state.isShowButton = true
        state.hrefToken = `eofficecpc:id_vb=${state.signNumberId};id_nv=${state.chucDanhId};type=0;noiky=ct_chuky`
        if (
          state.userAgrees &&
          state.userAgrees.find(item => item === state.chucDanhId) !== undefined
        ) {
          state.userAgrees = state.userAgrees.filter(
            item => item !== state.chucDanhId
          )
        }
      }

      if (
        state.userAgrees &&
        state.userAgrees.find(item => item === state.chucDanhId) !== undefined
      ) {
        state.isShowButton = true
        state.isAgree = true
      }
    }
    this.setState(state)
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      prevProps.signNumber !== this.props.signNumber &&
      this.props.signNumber
    ) {
      const state = this.state
      state.signNumberId = this.props.signNumber.kySoId
      state.isShowButton = false
      state.isLeaderUnit = false
      state.isSecretary = false
      state.isCompositiveDepartment = false
      state.isLeaderCompositiveSecretary = false
      state.isLeaderCompositiveLeaderDepartment = false
      state.isLeaderDepartment = false
      state.isParentSecretary = false
      state.isParentLeaderUnit = false
      state.isOtherSecretary = false
      state.isOtherLeaderUnit = false
      state.hrefToken = null
      state.isDisagree = false
      state.userDisagree = null
      state.isAgree = false
      state.userAgrees = []
      state.maKySo = null

      if (
        this.props.signNumber.tinhTrang === 6 &&
        this.props.signNumber.soVanBan &&
        !isNaN(Number(this.props.signNumber.soVanBan)) &&
        Number(this.props.signNumber.soVanBan) > 0
      ) {
        state.soCongVan = this.props.signNumber.soVanBan
      }
      if (
        this.props.signNumber.tinhTrang === 6 &&
        this.props.signNumber.ngayCongVan &&
        Tool.Utils.isValidDate(
          moment(this.props.signNumber.ngayCongVan).format('DD/MM/YYYY')
        )
      ) {
        state.ngayCongVan = moment(this.props.signNumber.ngayCongVan)
        state.ngayCongVanToString = moment(this.props.signNumber.ngayCongVan)
          .format('YYYY-MM-DD')
          .toString()
          .replace(/-/g, '')
        state.dateToToString = moment(this.props.signNumber.ngayCongVan).format(
          'DD/MM/YYYY'
        )
      }

      // LD DV
      const lanhDaoDonViId = this.props.signNumber.lanhDaoDonVi
        ? this.props.signNumber.lanhDaoDonVi.chucDanhId
        : null
      if (
        this.props.signNumber.lanhDaoDonVi &&
        this.props.signNumber.lanhDaoDonVi.trangThai === Types.DISAGREE
      ) {
        state.userDisagree = this.props.signNumber.lanhDaoDonVi.chucDanhId
        state.hrefToken = `eofficecpc:id_vb=${state.signNumberId};id_nv=${state.chucDanhId};type=2`
        if (this.props.type === 'kySoNoiBo') {
          state.hrefToken = `eofficecpc:id_vb=${state.signNumberId};id_nv=${state.chucDanhId};type=0;noiky=chuky`
        }
      }
      if (
        this.props.signNumber.lanhDaoDonVi &&
        this.props.signNumber.lanhDaoDonVi.trangThai === Types.AGREED
      ) {
        state.userAgrees &&
          state.userAgrees.find(
            item => item === this.props.signNumber.lanhDaoDonVi.chucDanhId
          ) === undefined &&
          state.userAgrees.push(this.props.signNumber.lanhDaoDonVi.chucDanhId)
      }

      // LD DV cap tren
      const lanhDaoDonViCapTrenId = this.props.signNumber.lanhDaoDonViCapTren
        ? this.props.signNumber.lanhDaoDonViCapTren.chucDanhId
        : null
      if (
        this.props.signNumber.lanhDaoDonViCapTren &&
        this.props.signNumber.lanhDaoDonViCapTren.trangThai === Types.DISAGREE
      ) {
        state.userDisagree =
          this.props.signNumber.lanhDaoDonViCapTren.chucDanhId
        state.hrefToken = `eofficecpc:id_vb=${state.signNumberId};id_nv=${state.chucDanhId};type=0;noiky=ct_chuky`
      }
      if (
        this.props.signNumber.lanhDaoDonViCapTren &&
        this.props.signNumber.lanhDaoDonViCapTren.trangThai === Types.AGREED
      ) {
        state.userAgrees &&
          state.userAgrees.find(
            item =>
              item === this.props.signNumber.lanhDaoDonViCapTren.chucDanhId
          ) === undefined &&
          state.userAgrees.push(
            this.props.signNumber.lanhDaoDonViCapTren.chucDanhId
          )
      }

      // LD DV khac cung ky
      const lanhDaoDonViPhoiHopId = this.props.signNumber.lanhDaoDonViPhoiHop
        ? this.props.signNumber.lanhDaoDonViPhoiHop.chucDanhId
        : null
      if (
        this.props.signNumber.lanhDaoDonViPhoiHop &&
        this.props.signNumber.lanhDaoDonViPhoiHop.trangThai === Types.DISAGREE
      ) {
        state.userDisagree =
          this.props.signNumber.lanhDaoDonViPhoiHop.chucDanhId
        state.hrefToken = `eofficecpc:id_vb=${state.signNumberId};id_nv=${state.chucDanhId};type=3`
      }
      if (
        this.props.signNumber.lanhDaoDonViPhoiHop &&
        this.props.signNumber.lanhDaoDonViPhoiHop.trangThai === Types.AGREED
      ) {
        state.userAgrees &&
          state.userAgrees.find(
            item =>
              item === this.props.signNumber.lanhDaoDonViPhoiHop.chucDanhId
          ) === undefined &&
          state.userAgrees.push(
            this.props.signNumber.lanhDaoDonViPhoiHop.chucDanhId
          )
      }

      // LD PB chu tri
      const lanhDaoPhongBanChuTri = this.props.signNumber.lanhDaoPhongBanChuTri
        ? this.props.signNumber.lanhDaoPhongBanChuTri?.chucDanhId
        : null
      if (
        this.props.signNumber.lanhDaoPhongBanChuTri &&
        this.props.signNumber.lanhDaoPhongBanChuTri?.trangThai ===
          Types.DISAGREE
      ) {
        if (this.props.signNumber && this.props.signNumber.phongBanLap) {
          state.maKySo = this.props.signNumber.phongBanLap.maKySo
        }
        state.userDisagree =
          this.props.signNumber.lanhDaoPhongBanChuTri?.chucDanhId
        state.hrefToken = `eofficecpc:id_vb=${state.signNumberId};id_nv=${state.chucDanhId};type=1`
        if (this.props.type === 'kySoNoiBo') {
          state.hrefToken = `eofficecpc:id_vb=${state.signNumberId};id_nv=${state.chucDanhId};type=0;noiky=${state.maKySo}`
        }
      }
      if (
        this.props.signNumber.lanhDaoPhongBanChuTri &&
        this.props.signNumber.lanhDaoPhongBanChuTri?.trangThai === Types.AGREED
      ) {
        state.userAgrees &&
          state.userAgrees.find(
            item =>
              item === this.props.signNumber.lanhDaoPhongBanChuTri?.chucDanhId
          ) === undefined &&
          state.userAgrees.push(
            this.props.signNumber.lanhDaoPhongBanChuTri?.chucDanhId
          )
      }

      // TK DV
      let thuKyArr = null
      let dsThuKy = []
      if (this.props.signNumber.thuKy) {
        thuKyArr = [this.props.signNumber.thuKy.chucDanhId]
        state.secretaryId = thuKyArr
        if (this.props.signNumber.thuKy.trangThai === Types.DISAGREE) {
          state.userDisagree = this.props.signNumber.thuKy.chucDanhId
          state.hrefToken = `eofficecpc:id_vb=${state.signNumberId};id_nv=${state.chucDanhId};type=1`
          if (this.props.type === 'kySoNoiBo') {
            state.hrefToken = `eofficecpc:id_vb=${state.signNumberId};id_nv=${state.chucDanhId};type=0;noiky=kynhay`
          }
        }
        if (this.props.signNumber.thuKy.trangThai === Types.AGREED) {
          state.userAgrees &&
            state.userAgrees.find(
              item => item === this.props.signNumber.thuKy.chucDanhId
            ) === undefined &&
            state.userAgrees.push(this.props.signNumber.thuKy.chucDanhId)
        }
      } else {
        if (
          this.props.signNumber.banThuKy &&
          this.props.signNumber.banThuKy.dsThuKy &&
          this.props.signNumber.banThuKy.dsThuKy.length > 0
        ) {
          dsThuKy = this.props.signNumber.banThuKy.dsThuKy
          thuKyArr = dsThuKy.map(user => user.chucDanhId)
          state.secretaryId = thuKyArr
        }
        if (dsThuKy.length > 0) {
          let secretaryDisagree = dsThuKy.find(
            user => user.trangThai === Types.DISAGREE
          )
          if (secretaryDisagree) {
            state.userAgrees.push(
              ...pullAll(thuKyArr, [
                ...state.userAgrees,
                secretaryDisagree.chucDanhId,
              ]).map(user => user.chucDanhId)
            )
            state.userDisagree = secretaryDisagree.chucDanhId
            state.hrefToken = `eofficecpc:id_vb=${state.signNumberId};id_nv=${state.chucDanhId};type=1`
            if (this.props.type === 'kySoNoiBo') {
              state.hrefToken = `eofficecpc:id_vb=${state.signNumberId};id_nv=${state.chucDanhId};type=0;noiky=kynhay`
            }
          } else {
            if (
              dsThuKy.findIndex(user => user.trangThai === Types.AGREED) !== -1
            ) {
              state.userAgrees.push(
                ...pullAll(thuKyArr, state.userAgrees).map(
                  user => user.chucDanhId
                )
              )
            }
          }
        }
      }

      // TK DV cap tren
      const thuKyCapTren = this.props.signNumber.thuKyCapTren
        ? this.props.signNumber.thuKyCapTren.chucDanhId
        : null
      state.parentSecretaryId = thuKyCapTren
      if (
        this.props.signNumber.thuKyCapTren &&
        this.props.signNumber.thuKyCapTren.trangThai === Types.DISAGREE
      ) {
        state.userDisagree = this.props.signNumber.thuKyCapTren.chucDanhId
        state.hrefToken = `eofficecpc:id_vb=${state.signNumberId};id_nv=${state.chucDanhId};type=0;noiky=kynhay`
      }
      if (
        this.props.signNumber.thuKyCapTren &&
        this.props.signNumber.thuKyCapTren.trangThai === Types.AGREED
      ) {
        state.userAgrees &&
          state.userAgrees.find(
            item => item === this.props.signNumber.thuKyCapTren.chucDanhId
          ) === undefined &&
          state.userAgrees.push(this.props.signNumber.thuKyCapTren.chucDanhId)
      }

      // TK DV khac cung ky
      const thuKyPhoiHop = this.props.signNumber.thuKyPhoiHop
        ? this.props.signNumber.thuKyPhoiHop.chucDanhId
        : null
      state.otherSecretaryId = thuKyPhoiHop
      if (
        this.props.signNumber.thuKyPhoiHop &&
        this.props.signNumber.thuKyPhoiHop.trangThai === Types.DISAGREE
      ) {
        state.userDisagree = this.props.signNumber.thuKyPhoiHop.chucDanhId
        state.hrefToken = `eofficecpc:id_vb=${state.signNumberId};id_nv=${state.chucDanhId};type=1`
      }
      if (
        this.props.signNumber.thuKyPhoiHop &&
        this.props.signNumber.thuKyPhoiHop.trangThai === Types.AGREED
      ) {
        state.userAgrees &&
          state.userAgrees.find(
            item => item === this.props.signNumber.thuKyPhoiHop.chucDanhId
          ) === undefined &&
          state.userAgrees.push(this.props.signNumber.thuKyPhoiHop.chucDanhId)
      }

      // phong ban phoi hop thuong
      if (this.props.signNumber.phoiHop) {
        const department = this.props.signNumber.phoiHop.find(
          item => item.phongBanId === state.phongBanId
        )
        state.maKySo = department ? department.maKySo : state.maKySo
        if (
          department !== undefined &&
          department.trangThai === Types.NOT_YET_AGREE
        ) {
          state.isCompositiveDepartment = true
          state.hrefToken = `eofficecpc:id_vb=${state.signNumberId};id_nv=${state.chucDanhId};type=1`
          if (this.props.type === 'kySoNoiBo') {
            state.hrefToken = `eofficecpc:id_vb=${state.signNumberId};id_nv=${state.chucDanhId};type=0;noiky=${state.maKySo}`
          }
        }
        if (
          department !== undefined &&
          department.trangThai === Types.DISAGREE &&
          department.nguoiKy
        ) {
          state.userDisagree = department.nguoiKy.chucDanhId
          if (department.nguoiKy.chucDanhId === state.chucDanhId) {
            state.hrefToken = `eofficecpc:id_vb=${state.signNumberId};id_nv=${state.chucDanhId};type=1`
            if (this.props.type === 'kySoNoiBo') {
              state.hrefToken = `eofficecpc:id_vb=${state.signNumberId};id_nv=${state.chucDanhId};type=0;noiky=${state.maKySo}`
            }
          }
        }
        if (
          department !== undefined &&
          department.trangThai === Types.AGREED &&
          department.nguoiKy
        ) {
          state.userAgrees &&
            state.userAgrees.find(
              item => item === department.nguoiKy.chucDanhId
            ) === undefined &&
            state.userAgrees.push(department.nguoiKy.chucDanhId)
        }
      }

      // phong ban dien luc phoi hop
      if (this.props.signNumber.donViCungKy) {
        let unit = this.props.signNumber.donViCungKy.find(
          item => item.phongBan && item.phongBan.phongBanId === state.phongBanId
        )
        state.maKySo = unit
          ? unit.maKySo
            ? unit.maKySo
            : unit.maDonVi && unit.maDonVi.toLowerCase()
          : state.maKySo
        if (
          unit &&
          unit.nguoiKy &&
          unit.nguoiKy.chucDanhId === state.chucDanhId
        ) {
          state.isCompositiveDepartmentElectricUnit = true
        }
        if (unit !== undefined && unit.trangThai === Types.NOT_YET_AGREE) {
          state.isCompositiveDepartmentElectricUnit = true
          if (this.props.type === 'kySoNoiBo') {
            state.hrefToken = `eofficecpc:id_vb=${state.signNumberId};id_nv=${state.chucDanhId};type=0;noiky=${state.maKySo}`
          }
        }
        if (
          unit !== undefined &&
          unit.trangThai === Types.DISAGREE &&
          unit.nguoiKy
        ) {
          state.userDisagree = unit.nguoiKy.chucDanhId
          if (unit.nguoiKy.chucDanhId === state.chucDanhId) {
            if (this.props.type === 'kySoNoiBo') {
              state.hrefToken = `eofficecpc:id_vb=${state.signNumberId};id_nv=${state.chucDanhId};type=0;noiky=${state.maKySo}`
            }
          }
        }
        if (
          unit !== undefined &&
          unit.trangThai === Types.AGREED &&
          unit.nguoiKy
        ) {
          state.userAgrees &&
            state.userAgrees.find(item => item === unit.nguoiKy.chucDanhId) ===
              undefined &&
            state.userAgrees.push(unit.nguoiKy.chucDanhId)
        }
      }
      // ky so di, danh sach lanh dao ky phoi hop
      if (
        this.props.type === 'kySoDi' &&
        this.props.signNumber.dsLanhDaoPhoiHop &&
        this.props.signNumber.dsLanhDaoPhoiHop.length > 0
      ) {
        const leaderCompositiveDepartment =
          this.props.signNumber.dsLanhDaoPhoiHop.find(
            item =>
              item.phongBan && item.phongBan.phongBanId === state.phongBanId
          )
        if (
          leaderCompositiveDepartment &&
          leaderCompositiveDepartment.phongBan
        ) {
          state.maKySo = leaderCompositiveDepartment.phongBan.maKySo
        }
        state.leaderCompositiveDepartment = leaderCompositiveDepartment
        // la thu ky cua lanh dap phong ban phoi hop
        if (
          leaderCompositiveDepartment !== undefined &&
          leaderCompositiveDepartment.thuKy &&
          leaderCompositiveDepartment.thuKy.chucDanhId === state.chucDanhId
        ) {
          state.isLeaderCompositiveSecretary = true

          if (leaderCompositiveDepartment.thuKy.trangThai === Types.DISAGREE) {
            state.userDisagree = leaderCompositiveDepartment.thuKy.chucDanhId
            state.hrefToken = `eofficecpc:id_vb=${state.signNumberId};id_nv=${state.chucDanhId};type=1`
          }

          if (leaderCompositiveDepartment.thuKy.trangThai === Types.AGREED) {
            state.userAgrees &&
              state.userAgrees.find(
                item => item === leaderCompositiveDepartment.thuKy.chucDanhId
              ) === undefined &&
              state.userAgrees.push(
                leaderCompositiveDepartment.thuKy.chucDanhId
              )
          }
        }

        // la lanh dao cua phong ban lanh dao
        if (
          leaderCompositiveDepartment !== undefined &&
          leaderCompositiveDepartment.lanhDao &&
          leaderCompositiveDepartment.lanhDao.chucDanhId === state.chucDanhId
        ) {
          if (
            leaderCompositiveDepartment.lanhDao.trangThai ===
            Types.NOT_YET_AGREE
          ) {
            state.isLeaderCompositiveLeaderDepartment = true
          }

          if (
            leaderCompositiveDepartment.lanhDao.trangThai === Types.DISAGREE
          ) {
            state.userDisagree = leaderCompositiveDepartment.lanhDao.chucDanhId
            state.hrefToken = `eofficecpc:id_vb=${state.signNumberId};id_nv=${state.chucDanhId};type=1`
          }

          if (leaderCompositiveDepartment.lanhDao.trangThai === Types.AGREED) {
            state.userAgrees &&
              state.userAgrees.find(
                item => item === leaderCompositiveDepartment.lanhDao.chucDanhId
              ) === undefined &&
              state.userAgrees.push(
                leaderCompositiveDepartment.lanhDao.chucDanhId
              )
          }
        }

        if (
          this.props.signNumber.tinhTrang ===
            Types.COMPOSITIVE_DEPARTMENT_ELECTRIC_UNIT_SIGNING &&
          state.leaderCompositiveDepartment
        ) {
          // la thu ky cua lanh dao phong ban lanh dao phoi hop
          if (
            state.isLeaderCompositiveSecretary === true &&
            state.leaderCompositiveDepartment.thuKy
          ) {
            state.isShowButton = true
            state.hrefToken = `eofficecpc:id_vb=${state.signNumberId};id_nv=${state.chucDanhId};type=1`
            //comment fix loi hien thi ki so voi thu ki khi dã kí duyệt
            // if (state.userAgrees && state.userAgrees.find(item => item === state.chucDanhId) !== undefined) {
            //   state.userAgrees = state.userAgrees.filter(item => item !== state.chucDanhId);
            // }
          }
          // la lanh dao phong ban phoi hop
          if (state.isLeaderCompositiveLeaderDepartment === true) {
            state.isShowButton = true
            state.hrefToken = `eofficecpc:id_vb=${state.signNumberId};id_nv=${state.chucDanhId};type=1`
            if (
              state.userAgrees &&
              state.userAgrees.find(item => item === state.chucDanhId) !==
                undefined
            ) {
              state.userAgrees = state.userAgrees.filter(
                item => item !== state.chucDanhId
              )
            }
          }
        }
      }

      // phong ban lanh dao phoi hop
      if (this.props.signNumber.lanhDaoPhoiHop) {
        const leaderCompositiveDepartment =
          this.props.signNumber.lanhDaoPhoiHop.find(
            item =>
              item.phongBan && item.phongBan.phongBanId === state.phongBanId
          )
        if (
          leaderCompositiveDepartment &&
          leaderCompositiveDepartment.phongBan
        ) {
          state.maKySo = leaderCompositiveDepartment.phongBan.maKySo
        }
        state.leaderCompositiveDepartment = leaderCompositiveDepartment
        // la thu ky cua lanh dap phong ban phoi hop
        if (
          leaderCompositiveDepartment !== undefined &&
          leaderCompositiveDepartment.thuKy &&
          leaderCompositiveDepartment.thuKy.chucDanhId === state.chucDanhId
        ) {
          state.isLeaderCompositiveSecretary = true

          if (leaderCompositiveDepartment.thuKy.trangThai === Types.DISAGREE) {
            state.userDisagree = leaderCompositiveDepartment.thuKy.chucDanhId
            state.hrefToken = `eofficecpc:id_vb=${state.signNumberId};id_nv=${state.chucDanhId};type=0;noiky=kynhay`
          }

          if (leaderCompositiveDepartment.thuKy.trangThai === Types.AGREED) {
            state.userAgrees &&
              state.userAgrees.find(
                item => item === leaderCompositiveDepartment.thuKy.chucDanhId
              ) === undefined &&
              state.userAgrees.push(
                leaderCompositiveDepartment.thuKy.chucDanhId
              )
          }
        }

        // la lanh dao cua phong ban lanh dao
        if (
          leaderCompositiveDepartment !== undefined &&
          leaderCompositiveDepartment.lanhDao &&
          leaderCompositiveDepartment.lanhDao.chucDanhId === state.chucDanhId
        ) {
          // TODO: Cho phép lãnh đạo ký khi có quyền dù thư ký đã ký hay chưa
          // khong co thu ky
          // if (leaderCompositiveDepartment.lanhDao.trangThai === Types.NOT_YET_AGREE && !leaderCompositiveDepartment.thuKy) {
          //   state.isLeaderCompositiveLeaderDepartment = true;
          // }
          // co thu ky
          // if (
          //   leaderCompositiveDepartment.lanhDao.trangThai === Types.NOT_YET_AGREE &&
          //   leaderCompositiveDepartment.thuKy &&
          //   leaderCompositiveDepartment.thuKy.trangThai === Types.AGREED
          // ) {
          //   state.isLeaderCompositiveLeaderDepartment = true;
          // }

          // TODO: Cho phép lãnh đạo ký khi có quyền dù thư ký đã ký hay chưa
          if (
            leaderCompositiveDepartment.lanhDao.trangThai ===
            Types.NOT_YET_AGREE
          ) {
            state.isLeaderCompositiveLeaderDepartment = true
          }

          if (
            leaderCompositiveDepartment.lanhDao.trangThai === Types.DISAGREE
          ) {
            state.userDisagree = leaderCompositiveDepartment.lanhDao.chucDanhId
            state.hrefToken = `eofficecpc:id_vb=${state.signNumberId};id_nv=${state.chucDanhId};type=0;noiky=${state.maKySo}`
          }

          if (leaderCompositiveDepartment.lanhDao.trangThai === Types.AGREED) {
            state.userAgrees &&
              state.userAgrees.find(
                item => item === leaderCompositiveDepartment.lanhDao.chucDanhId
              ) === undefined &&
              state.userAgrees.push(
                leaderCompositiveDepartment.lanhDao.chucDanhId
              )
          }
        }
      }

      // LD PB PH cap tren
      if (this.props.signNumber.phoiHopCapTren) {
        const department = this.props.signNumber.phoiHopCapTren.find(
          item => item.phongBanId === state.phongBanId
        )
        state.maKySo = department ? department.maKySo : state.maKySo
        if (
          department !== undefined &&
          department.trangThai === Types.NOT_YET_AGREE
        ) {
          state.isParentCompositiveDepartment = true
          if (this.props.type === 'kySoNoiBo') {
            state.hrefToken = `eofficecpc:id_vb=${state.signNumberId};id_nv=${state.chucDanhId};type=0;noiky=ct_${state.maKySo}`
          }
        }
        if (
          department !== undefined &&
          department.trangThai === Types.DISAGREE &&
          department.nguoiKy
        ) {
          state.userDisagree = department.nguoiKy.chucDanhId
          state.hrefToken = `eofficecpc:id_vb=${state.signNumberId};id_nv=${state.chucDanhId};type=0;noiky=ct_${state.maKySo}`
        }
        if (
          department !== undefined &&
          department.trangThai === Types.AGREED &&
          department.nguoiKy
        ) {
          state.userAgrees &&
            state.userAgrees.find(
              item => item === department.nguoiKy.chucDanhId
            ) === undefined &&
            state.userAgrees.push(department.nguoiKy.chucDanhId)
        }
      }

      if (state.chucDanhId && state.chucDanhId === lanhDaoDonViId) {
        state.isLeaderUnit = true
      }

      if (state.chucDanhId && state.chucDanhId === lanhDaoDonViCapTrenId) {
        state.isParentLeaderUnit = true
      }

      if (state.chucDanhId && state.chucDanhId === lanhDaoDonViPhoiHopId) {
        state.isOtherLeaderUnit = true
      }

      if (state.chucDanhId && state.chucDanhId === lanhDaoPhongBanChuTri) {
        if (this.props.signNumber && this.props.signNumber.phongBanLap) {
          state.maKySo = this.props.signNumber.phongBanLap.maKySo
        }
        state.isLeaderDepartment = true
      }

      if (
        state.chucDanhId &&
        thuKyArr &&
        thuKyArr.findIndex(chucDanhId => chucDanhId === state.chucDanhId) !== -1
      ) {
        state.isSecretary = true
      }

      if (state.chucDanhId && state.chucDanhId === thuKyCapTren) {
        state.isParentSecretary = true
      }

      if (state.chucDanhId && state.chucDanhId === thuKyPhoiHop) {
        state.isOtherSecretary = true
      }

      // Khong duyet
      if (
        this.props.signNumber.tinhTrang === Types.DISAGREE &&
        state.chucDanhId === state.userDisagree
      ) {
        state.isShowButton = true
        state.isDisagree = true
      }
      //
      if (
        (this.props.signNumber.tinhTrang === Types.DISAGREE &&
          state.isSecretary &&
          state.secretaryId.findIndex(id => state.userDisagree === id) !==
            -1) ||
        (this.props.signNumber.tinhTrang === Types.SECRETARY &&
          state.isSecretary)
      ) {
        state.isShowButton = true
      }

      // Chua duyet
      if (
        this.props.signNumber.tinhTrang === Types.NOT_YET_AGREE &&
        state.isLeaderDepartment === true
      ) {
        state.isShowButton = true
        state.hrefToken = `eofficecpc:id_vb=${state.signNumberId};id_nv=${state.chucDanhId};type=1`
        if (this.props.type === 'kySoNoiBo') {
          state.hrefToken = `eofficecpc:id_vb=${state.signNumberId};id_nv=${state.chucDanhId};type=0;noiky=${state.maKySo}`
        }
        if (
          state.userAgrees &&
          state.userAgrees.find(item => item === state.chucDanhId) !== undefined
        ) {
          state.userAgrees = state.userAgrees.filter(
            item => item !== state.chucDanhId
          )
        }
      }

      // LDPBCT da duyet
      if (
        this.props.signNumber.tinhTrang === Types.LEADER_DEPARTMENT &&
        state.isCompositiveDepartment === true
      ) {
        state.isShowButton = true
        state.hrefToken = `eofficecpc:id_vb=${state.signNumberId};id_nv=${state.chucDanhId};type=1`
        if (this.props.type === 'kySoNoiBo') {
          state.hrefToken = `eofficecpc:id_vb=${state.signNumberId};id_nv=${state.chucDanhId};type=0;noiky=${state.maKySo}`
        }
      }

      // LDPB PH dang duyet
      if (
        this.props.signNumber.tinhTrang === Types.COMPOSITIVE_DEPARTMENT_SIGNING
      ) {
        // Chua dong y
        if (state.isCompositiveDepartment === true) {
          state.isShowButton = true
          state.hrefToken = `eofficecpc:id_vb=${state.signNumberId};id_nv=${state.chucDanhId};type=1`
          if (this.props.type === 'kySoNoiBo') {
            state.hrefToken = `eofficecpc:id_vb=${state.signNumberId};id_nv=${state.chucDanhId};type=0;noiky=${state.maKySo}`
          }
        }

        // Khong dong y
        if (state.chucDanhId === state.userDisagree) {
          state.isShowButton = true
          state.isDisagree = true
        }
      }

      // LDPBPH da duyet va khong co phong ban lanh dao
      if (
        this.props.signNumber.tinhTrang === Types.COMPOSITIVE_DEPARTMENT &&
        (!this.props.signNumber.lanhDaoPhoiHop ||
          this.props.signNumber.lanhDaoPhoiHop.length === 0)
      ) {
        if (this.props.type === 'kySoDi') {
          // tinhTrang = 3 && co Thu ky LD DV khac cung ky
          if (
            this.props.signNumber.thuKyPhoiHop &&
            state.isOtherSecretary === true
          ) {
            state.isShowButton = true
            state.hrefToken = `eofficecpc:id_vb=${state.signNumberId};id_nv=${state.chucDanhId};type=1`
            if (
              state.userAgrees &&
              state.userAgrees.find(item => item === state.chucDanhId) !==
                undefined
            ) {
              state.userAgrees = state.userAgrees.filter(
                item => item !== state.chucDanhId
              )
            }
          }
          // tinhTrang = 3 && khong co Thu ky LD DV khac cung ky && co LD DV khac cung ky
          if (
            // TODO: Cho phép lãnh đạo ký khi có quyền dù thư ký đã ký hay chưa
            // !this.props.signNumber.thuKyPhoiHop &&
            this.props.signNumber.lanhDaoDonViPhoiHop &&
            state.isOtherLeaderUnit === true
          ) {
            state.isShowButton = true
            state.hrefToken = `eofficecpc:id_vb=${state.signNumberId};id_nv=${state.chucDanhId};type=3`
            if (
              state.userAgrees &&
              state.userAgrees.find(item => item === state.chucDanhId) !==
                undefined
            ) {
              state.userAgrees = state.userAgrees.filter(
                item => item !== state.chucDanhId
              )
            }
          }
          // tinhTrang = 3 && khong co Thu ky LD DV khac cung ky && khong co LD DV khac cung ky
          if (
            !this.props.signNumber.thuKyPhoiHop &&
            !this.props.signNumber.lanhDaoDonViPhoiHop
            // state.isSecretary === true
          ) {
            if (state.isSecretary === true) {
              state.isShowButton = true
              state.hrefToken = `eofficecpc:id_vb=${state.signNumberId};id_nv=${state.chucDanhId};type=1`
              if (
                state.userAgrees &&
                state.userAgrees.find(item => item === state.chucDanhId) !==
                  undefined
              ) {
                state.userAgrees = state.userAgrees.filter(
                  item => item !== state.chucDanhId
                )
              }
            }

            // TODO: Cho phép lãnh đạo ký khi có quyền dù thư ký đã ký hay chưa
            if (state.isLeaderUnit === true) {
              state.isShowButton = true
              state.hrefToken = `eofficecpc:id_vb=${state.signNumberId};id_nv=${state.chucDanhId};type=2`
              if (
                state.userAgrees &&
                state.userAgrees.find(item => item === state.chucDanhId) !==
                  undefined
              ) {
                state.userAgrees = state.userAgrees.filter(
                  item => item !== state.chucDanhId
                )
              }
            }
          }
        } else if (this.props.type === 'kySoNoiBo') {
          if (state.isSecretary === true) {
            state.isShowButton = true
            state.hrefToken = `eofficecpc:id_vb=${state.signNumberId};id_nv=${state.chucDanhId};type=0;noiky=kynhay`
            if (
              state.userAgrees &&
              state.userAgrees.find(item => item === state.chucDanhId) !==
                undefined
            ) {
              state.userAgrees = state.userAgrees.filter(
                item => item !== state.chucDanhId
              )
            }
          }
          // TODO: Cho phép lãnh đạo ký khi có quyền dù thư ký đã ký hay chưa
          if (state.isLeaderUnit === true) {
            state.isShowButton = true
            state.hrefToken = `eofficecpc:id_vb=${state.signNumberId};id_nv=${state.chucDanhId};type=0;noiky=chuky`
            if (
              state.userAgrees &&
              state.userAgrees.find(item => item === state.chucDanhId) !==
                undefined
            ) {
              state.userAgrees = state.userAgrees.filter(
                item => item !== state.chucDanhId
              )
            }
          }
        }
      }

      // LDPBPH da duyet va co lanh dao dien luc phoi hop
      if (
        this.props.type === 'kySoNoiBo' &&
        this.props.signNumber.tinhTrang === Types.COMPOSITIVE_DEPARTMENT &&
        state.isCompositiveDepartmentElectricUnit === true
      ) {
        state.isShowButton = true
        state.hrefToken = `eofficecpc:id_vb=${state.signNumberId};id_nv=${state.chucDanhId};type=0;noiky=${state.maKySo}`
      }

      // LDPBPH da duyet va co phong ban lanh dao va khong co lanh dao dien luc phoi hop
      if (
        this.props.type === 'kySoNoiBo' &&
        this.props.signNumber.tinhTrang === Types.COMPOSITIVE_DEPARTMENT &&
        state.leaderCompositiveDepartment
      ) {
        // la thu ky cua lanh dao phong ban lanh dao phoi hop
        if (
          state.isLeaderCompositiveSecretary === true &&
          state.leaderCompositiveDepartment.thuKy
        ) {
          state.isShowButton = true
          state.hrefToken = `eofficecpc:id_vb=${state.signNumberId};id_nv=${state.chucDanhId};type=0;noiky=kynhay`
          if (
            state.userAgrees &&
            state.userAgrees.find(item => item === state.chucDanhId) !==
              undefined
          ) {
            state.userAgrees = state.userAgrees.filter(
              item => item !== state.chucDanhId
            )
          }
        }
        // la lanh dao phong ban phoi hop
        if (state.isLeaderCompositiveLeaderDepartment === true) {
          state.isShowButton = true
          state.hrefToken = `eofficecpc:id_vb=${state.signNumberId};id_nv=${state.chucDanhId};type=0;noiky=${state.maKySo}`
          if (
            state.userAgrees &&
            state.userAgrees.find(item => item === state.chucDanhId) !==
              undefined
          ) {
            state.userAgrees = state.userAgrees.filter(
              item => item !== state.chucDanhId
            )
          }
        }
      }

      // Lanh dao dien luc phoi hop dang duyet
      if (
        this.props.signNumber.tinhTrang ===
        Types.COMPOSITIVE_DEPARTMENT_ELECTRIC_UNIT_SIGNING
      ) {
        // Chua dong y
        if (state.isCompositiveDepartmentElectricUnit === true) {
          state.isShowButton = true
          state.hrefToken = `eofficecpc:id_vb=${state.signNumberId};id_nv=${state.chucDanhId};type=0;noiky=${state.maKySo}`
        }
        // Khong dong y
        if (state.chucDanhId === state.userDisagree) {
          state.isShowButton = true
          state.isDisagree = true
        }
      }

      // Lanh dao dien luc phoi hop da duyet
      if (
        this.props.type === 'kySoNoiBo' &&
        this.props.signNumber.tinhTrang ===
          Types.COMPOSITIVE_DEPARTMENT_ELECTRIC_UNIT &&
        state.leaderCompositiveDepartment
      ) {
        // la thu ky cua lanh dao phong ban lanh dao phoi hop
        if (
          state.isLeaderCompositiveSecretary === true &&
          state.leaderCompositiveDepartment.thuKy
        ) {
          state.isShowButton = true
          state.hrefToken = `eofficecpc:id_vb=${state.signNumberId};id_nv=${state.chucDanhId};type=0;noiky=kynhay`
          if (
            state.userAgrees &&
            state.userAgrees.find(item => item === state.chucDanhId) !==
              undefined
          ) {
            state.userAgrees = state.userAgrees.filter(
              item => item !== state.chucDanhId
            )
          }
        }
        // la lanh dao phong ban phoi hop
        if (state.isLeaderCompositiveLeaderDepartment === true) {
          state.isShowButton = true
          state.hrefToken = `eofficecpc:id_vb=${state.signNumberId};id_nv=${state.chucDanhId};type=0;noiky=${state.maKySo}`
          if (
            state.userAgrees &&
            state.userAgrees.find(item => item === state.chucDanhId) !==
              undefined
          ) {
            state.userAgrees = state.userAgrees.filter(
              item => item !== state.chucDanhId
            )
          }
        }
      }

      // LD phong ban lanh dao phoi hop da ky
      if (
        this.props.type === 'kySoNoiBo' &&
        this.props.signNumber.tinhTrang ===
          Types.COMPOSITIVE_LEADER_DEPARTMENT &&
        this.props.signNumber.lanhDaoPhoiHop
        // state.isSecretary === true
      ) {
        if (state.isSecretary === true) {
          state.isShowButton = true
          state.hrefToken = `eofficecpc:id_vb=${state.signNumberId};id_nv=${state.chucDanhId};type=0;noiky=kynhay`
          if (
            state.userAgrees &&
            state.userAgrees.find(item => item === state.chucDanhId) !==
              undefined
          ) {
            state.userAgrees = state.userAgrees.filter(
              item => item !== state.chucDanhId
            )
          }
        }

        // TODO: Cho phép lãnh đạo ký khi có quyền dù thư ký đã ký hay chưa
        if (state.isLeaderUnit === true) {
          state.isShowButton = true
          state.hrefToken = `eofficecpc:id_vb=${state.signNumberId};id_nv=${state.chucDanhId};type=0;noiky=chuky`
          if (
            state.userAgrees &&
            state.userAgrees.find(item => item === state.chucDanhId) !==
              undefined
          ) {
            state.userAgrees = state.userAgrees.filter(
              item => item !== state.chucDanhId
            )
          }
        }
      }

      // type = kySoDi && Thu ky LD DV khac cung ky da duyet
      if (
        this.props.type === 'kySoDi' &&
        this.props.signNumber.lanhDaoDonViPhoiHop &&
        this.props.signNumber.tinhTrang === Types.OTHER_SECRETARY &&
        state.isOtherLeaderUnit === true
      ) {
        state.isShowButton = true
        state.hrefToken = `eofficecpc:id_vb=${state.signNumberId};id_nv=${state.chucDanhId};type=3`
        if (
          state.userAgrees &&
          state.userAgrees.find(item => item === state.chucDanhId) !== undefined
        ) {
          state.userAgrees = state.userAgrees.filter(
            item => item !== state.chucDanhId
          )
        }
      }

      // type = kySoDi && LD DV khac cung ky da duyet
      if (
        this.props.type === 'kySoDi' &&
        this.props.signNumber.lanhDaoDonViPhoiHop &&
        this.props.signNumber.tinhTrang === Types.OTHER_LEADER_UNIT
        // state.isSecretary === true
      ) {
        if (state.isSecretary === true) {
          state.isShowButton = true
          state.hrefToken = `eofficecpc:id_vb=${state.signNumberId};id_nv=${state.chucDanhId};type=1`
          if (
            state.userAgrees &&
            state.userAgrees.find(item => item === state.chucDanhId) !==
              undefined
          ) {
            state.userAgrees = state.userAgrees.filter(
              item => item !== state.chucDanhId
            )
          }
        }
        // TODO: Cho phép lãnh đạo ký khi có quyền dù thư ký đã ký hay chưa
        if (state.isLeaderUnit === true) {
          state.isShowButton = true
          state.hrefToken = `eofficecpc:id_vb=${state.signNumberId};id_nv=${state.chucDanhId};type=2`
          if (
            state.userAgrees &&
            state.userAgrees.find(item => item === state.chucDanhId) !==
              undefined
          ) {
            state.userAgrees = state.userAgrees.filter(
              item => item !== state.chucDanhId
            )
          }
        }
      }

      // Thu ky LDDV da duyet
      if (
        this.props.signNumber.tinhTrang === Types.SECRETARY &&
        state.isLeaderUnit === true
      ) {
        state.isShowButton = true
        state.hrefToken = `eofficecpc:id_vb=${state.signNumberId};id_nv=${state.chucDanhId};type=2`
        if (this.props.type === 'kySoNoiBo') {
          state.hrefToken = `eofficecpc:id_vb=${state.signNumberId};id_nv=${state.chucDanhId};type=0;noiky=chuky`
        }
        if (
          state.userAgrees &&
          state.userAgrees.find(item => item === state.chucDanhId) !== undefined
        ) {
          state.userAgrees = state.userAgrees.filter(
            item => item !== state.chucDanhId
          )
        }
      }

      // LDDV da duyet
      if (
        this.props.signNumber.tinhTrang === Types.LEADER_UNIT &&
        state.isParentCompositiveDepartment === true
      ) {
        state.isShowButton = true
        if (this.props.type === 'kySoNoiBo') {
          state.hrefToken = `eofficecpc:id_vb=${state.signNumberId};id_nv=${state.chucDanhId};type=0;noiky=ct_${state.maKySo}`
        }
      }

      // LDPB PH CT dang duyet
      if (
        this.props.signNumber.tinhTrang ===
        Types.PARENT_COMPOSITIVE_DEPARTMENT_SIGNING
      ) {
        // Chua dong y
        if (state.isParentCompositiveDepartment === true) {
          state.isShowButton = true
          if (this.props.type === 'kySoNoiBo') {
            state.hrefToken = `eofficecpc:id_vb=${state.signNumberId};id_nv=${state.chucDanhId};type=0;noiky=ct_${state.maKySo}`
          }
        }

        // Khong dong y
        if (state.chucDanhId === state.userDisagree) {
          state.isShowButton = true
          state.isDisagree = true
        }
      }

      // type = kySoNoiBo && LDPBPH cap tren da duyet
      if (
        this.props.type === 'kySoNoiBo' &&
        this.props.signNumber.tinhTrang === Types.PARENT_COMPOSITIVE_DEPARTMENT
        // state.isParentSecretary === true
      ) {
        if (state.isParentSecretary === true) {
          state.isShowButton = true
          state.hrefToken = `eofficecpc:id_vb=${state.signNumberId};id_nv=${state.chucDanhId};type=0;noiky=kynhay`
          if (
            state.userAgrees &&
            state.userAgrees.find(item => item === state.chucDanhId) !==
              undefined
          ) {
            state.userAgrees = state.userAgrees.filter(
              item => item !== state.chucDanhId
            )
          }
        }
        // TODO: Cho phép lãnh đạo ký khi có quyền dù thư ký đã ký hay chưa
        if (state.isParentLeaderUnit === true) {
          state.isShowButton = true
          state.hrefToken = `eofficecpc:id_vb=${state.signNumberId};id_nv=${state.chucDanhId};type=0;noiky=ct_chuky`
          if (
            state.userAgrees &&
            state.userAgrees.find(item => item === state.chucDanhId) !==
              undefined
          ) {
            state.userAgrees = state.userAgrees.filter(
              item => item !== state.chucDanhId
            )
          }
        }
      }

      // type = kySoNoiBo && Thu ky LDDV cap tren da duyet
      if (
        this.props.type === 'kySoNoiBo' &&
        this.props.signNumber.tinhTrang === Types.PARENT_SECRETARY &&
        state.isParentLeaderUnit === true
      ) {
        state.isShowButton = true
        state.hrefToken = `eofficecpc:id_vb=${state.signNumberId};id_nv=${state.chucDanhId};type=0;noiky=ct_chuky`
        if (
          state.userAgrees &&
          state.userAgrees.find(item => item === state.chucDanhId) !== undefined
        ) {
          state.userAgrees = state.userAgrees.filter(
            item => item !== state.chucDanhId
          )
        }
      }
      if (
        state.userAgrees &&
        state.userAgrees.find(item => item === state.chucDanhId) !== undefined
      ) {
        state.isShowButton = true
        state.isAgree = true
      }

      this.setState(state)
    }
  }

  componentWillUnmount() {
    this.setState({
      isOpenPopup: false,
      isOpenPopupCapSoKhongXacThuc: false,
      typePopup: null,
      titlePopup: '',
      error: {},
      feedbackContent: '',
      feedbackForLeader: '',
      chucDanhId: null,
      phongBanId: null,
      maPhongBan: null,
      signNumberId: null,
      isLeaderUnit: false,
      isSecretary: false,
      isCompositiveDepartment: false,
      isLeaderCompositiveSecretary: false,
      isLeaderCompositiveLeaderDepartment: false,
      isLeaderDepartment: false,
      isLoading: {
        agree: false,
        agreeBySIM: false,
        agreeBySIMVNPT: false,
        agreeByTOKEN: false,
        disagree: false,
        sendFeedback: false,
        progression: false,
      },
      isShowButton: false,
      soCongVan: '',
      ngayCongVan: null,
      feedbackId: null,
      hrefToken: null,
      isDisagree: false,
      userDisagree: null,
      userAgrees: [],
      isAgree: false,
      isSubmitFeedbackSuccess: null,
      ngayCongVanToString: null,
    })
  }

  handleShowMessageCopySuccess() {
    this.props.actions.commonAddToasterMessage({
      message: MESSAGE.MESSAGE_COPY_FEEDBACK_SUCCESS,
      intent: Intent.SUCCESS,
    })
  }

  render() {
    const token =
      this.props.auth &&
      this.props.auth.token &&
      this.props.auth.token.accessToken
        ? this.props.auth.token.accessToken
        : ''
    // const hrefProgression = `eofficecpc:id_vb=${this.state.signNumberId};socv=${this.state.soCongVan};ngaycv=${this.state.ngayCongVanToString};id_nv=${this.state.chucDanhId}`
    let feebackCommon = []
    let feedbackData = []
    let feedbackSecretary = []
    let feedbackParentSecretary = []
    let feedbackOtherSecretary = []
    let isShowFeedbackSecretary = false
    let isShowIconWarningForLeader = false
    let typeLeader = null

    this.props.feedbacks &&
      this.props.feedbacks.forEach(item => {
        if (item.loaiGopY === SECRETARY_FEEDBACK) {
          if (
            this.state.secretaryId &&
            this.state.secretaryId.length > 0 &&
            item.nguoiGopY &&
            this.state.secretaryId.findIndex(
              chucDanhId => chucDanhId === item.nguoiGopY.chucDanhId
            ) !== -1
          ) {
            feedbackSecretary.push(item)
          }

          if (
            this.state.parentSecretaryId &&
            item.nguoiGopY &&
            item.nguoiGopY.chucDanhId === this.state.parentSecretaryId
          ) {
            feedbackParentSecretary.push(item)
          }

          if (
            this.state.otherSecretaryId &&
            item.nguoiGopY &&
            item.nguoiGopY.chucDanhId === this.state.otherSecretaryId
          ) {
            feedbackOtherSecretary.push(item)
          }

          if (
            this.state.leaderCompositiveDepartment &&
            this.state.leaderCompositiveDepartment.thuKy &&
            item.nguoiGopY &&
            item.nguoiGopY.chucDanhId ===
              this.state.leaderCompositiveDepartment.thuKy.chucDanhId
          ) {
            feedbackOtherSecretary.push(item)
          }
        } else {
          feebackCommon.push(item)
        }
      })

    if (this.state.isLeaderUnit || this.state.isSecretary) {
      feedbackData = feedbackSecretary
      isShowFeedbackSecretary = true
    }
    if (this.state.isParentLeaderUnit || this.state.isParentSecretary) {
      feedbackData = feedbackParentSecretary
      isShowFeedbackSecretary = true
    }
    if (this.state.isOtherLeaderUnit || this.state.isOtherSecretary) {
      feedbackData = feedbackOtherSecretary
      isShowFeedbackSecretary = true
    }
    if (
      this.state.isLeaderCompositiveLeaderDepartment ||
      this.state.isLeaderCompositiveSecretary
    ) {
      feedbackData = feedbackOtherSecretary
      isShowFeedbackSecretary = true
    }

    // Kiểm tra show icon cảnh báo cho lãnh đạo
    if (
      this.state.isLeaderUnit &&
      feedbackData &&
      feedbackData.length > 0 &&
      this.props.signNumber &&
      this.props.signNumber.thuKy &&
      this.props.signNumber.thuKy.trangThai === Types.AGREED
    ) {
      isShowIconWarningForLeader = true
      typeLeader = Types.TYPE_LEADER_UNIT
    }
    if (
      this.state.isParentLeaderUnit &&
      feedbackData &&
      feedbackData.length > 0 &&
      this.props.signNumber &&
      this.props.signNumber.thuKyCapTren &&
      this.props.signNumber.thuKyCapTren.trangThai === Types.AGREED
    ) {
      isShowIconWarningForLeader = true
      typeLeader = Types.TYPE_PARENT_LEADER_UNIT
    }
    if (
      this.state.isOtherLeaderUnit &&
      feedbackData &&
      feedbackData.length > 0 &&
      this.props.signNumber &&
      this.props.signNumber.thuKyPhoiHop &&
      this.props.signNumber.thuKyPhoiHop.trangThai === Types.AGREED
    ) {
      isShowIconWarningForLeader = true
      typeLeader = Types.TYPE_OTHER_LEADER_UNIT
    }

    let extension = null
    const fileNoiDungKhongDau =
      this.props.signNumber && this.props.signNumber.fileNoiDungKhongDau
    if (fileNoiDungKhongDau && fileNoiDungKhongDau.fileName) {
      extension = fileNoiDungKhongDau.fileName
        .slice(
          ((this.props.signNumber.fileNoiDungKhongDau.fileName.lastIndexOf(
            '.'
          ) -
            1) >>>
            0) +
            2
        )
        .toLowerCase()
    }

    const isKyKhongCanXacThuc = this.props.auth?.permission?.some(
      p => p === Permission.KYSO_KHONGXACTHUC
    )

    return this.props.isLoadingData &&
      this.props.isLoadingData.signNumber === true ? (
      <LoadingItem />
    ) : (
      <div className="list-action list-action-sign-number">
        <div className="title-divid">
          <p className="title">Thực Hiện</p>
          <button className="guichat" onClick={this.props.sendToChat}>
            <i className="icon-bubble-cuoc-tro-chuyen"></i>
            <span className="action-name">Gửi tin nhắn</span>
          </button>
        </div>
        <span className="icon-More show-th-mobile" tabIndex="0"></span>
        <div className="detail-info-container section-box-info">
          <div className="list-items">
            {this.props.signNumber && this.props.signNumber.trichYeu && (
              <div className="row item-value">
                <div className="col-xs-12 left-content">
                  <span className="label-text">Trích yếu:</span>
                </div>
                <div className="col-xs-12 right-content">
                  <span className="content textarea-content">
                    {this.props.signNumber && this.props.signNumber.trichYeu}
                  </span>
                </div>
              </div>
            )}
            {this.props.signNumber && this.props.signNumber.noiDungTrinh && (
              <div className="row item-value">
                <div className="col-xs-12 left-content">
                  <span className="label-text">Nội dung trình:</span>
                </div>
                <div className="col-xs-12 right-content">
                  <span className="content textarea-content">
                    {this.props.signNumber &&
                      this.props.signNumber.noiDungTrinh}
                  </span>
                </div>
              </div>
            )}
            {this.props.signNumber &&
              this.props.signNumber.dsFileDinhKem &&
              this.props.signNumber.dsFileDinhKem.length > 0 && (
                <div className="row item-value">
                  <div className="col-xs-12 left-content">
                    <span className="label-text">
                      File đính kèm (
                      {this.props.signNumber.dsFileDinhKem.length}):
                    </span>
                  </div>
                  <div className="col-xs-12 right-content">
                    <Scrollbars
                      autoHeight
                      autoHeightMin={0}
                      autoHeightMax={200}
                    >
                      <span className="detail">
                        {this.props.signNumber.dsFileDinhKem.map(
                          (value, item) => {
                            let extensionTK = null
                            const fileDinhKem = value.fileName
                            if (fileDinhKem) {
                              extensionTK = fileDinhKem
                                .slice(
                                  ((value.fileName.lastIndexOf('.') - 1) >>>
                                    0) +
                                    2
                                )
                                .toLowerCase()
                            }
                            return (
                              <div className="info-file" key={item}>
                                <span className="info">
                                  {!value.migrated && (
                                    <a
                                      style={{
                                        backgroundImage: `url(${Tool.Utils.typeFile(
                                          extensionTK
                                        )})`,
                                      }}
                                      className="link-file"
                                      target="_blank"
                                      rel="noopener noreferrer"
                                      href={`${Types.FILE_URL}/api/file/get/${value.fileId}?BearerToken=${token}`}
                                    >
                                      {value.tenFile} -{' '}
                                      {Tool.Utils.getFileSize(value.kichThuoc)}
                                    </a>
                                  )}
                                </span>
                              </div>
                            )
                          }
                        )}
                      </span>
                    </Scrollbars>
                  </div>
                </div>
              )}
            {this.props.signNumber &&
              this.props.signNumber.fileThamKhao &&
              this.props.signNumber.fileThamKhao.length > 0 && (
                <div className="row item-value">
                  <div className="col-xs-12 left-content">
                    <span className="label-text">
                      File tham khảo (
                      {this.props.signNumber.fileThamKhao.length}):
                    </span>
                  </div>
                  <div className="col-xs-12 right-content">
                    <Scrollbars
                      autoHeight
                      autoHeightMin={0}
                      autoHeightMax={200}
                    >
                      <span className="detail">
                        {this.props.signNumber.fileThamKhao.map(
                          (value, item) => {
                            let extensionTK = null
                            const fileThamKhao = value.fileName
                            if (fileThamKhao) {
                              extensionTK = fileThamKhao
                                .slice(
                                  ((value.fileName.lastIndexOf('.') - 1) >>>
                                    0) +
                                    2
                                )
                                .toLowerCase()
                            }
                            return (
                              <div className="info-file" key={item}>
                                <span className="info">
                                  {!value.migrated && (
                                    <a
                                      style={{
                                        backgroundImage: `url(${Tool.Utils.typeFile(
                                          extensionTK
                                        )})`,
                                      }}
                                      className="link-file"
                                      target="_blank"
                                      rel="noopener noreferrer"
                                      href={`${Types.FILE_URL}/api/file/get/${value.fileId}?BearerToken=${token}`}
                                    >
                                      {value.tenFile} -{' '}
                                      {Tool.Utils.getFileSize(value.kichThuoc)}
                                    </a>
                                  )}
                                </span>
                              </div>
                            )
                          }
                        )}
                      </span>
                    </Scrollbars>
                  </div>
                </div>
              )}
          </div>
        </div>
        {/* Form gop y*/}
        {this.props.signNumber &&
          this.props.signNumber.tinhTrang !== Types.PROGRESSION &&
          this.props.signNumber.tinhTrang !== Types.RELEASED &&
          this.state.isShowButton &&
          !this.state.isAgree && (
            <Authorization.Element permission={[Permission.KYSO_KYDUYET_GOPY]}>
              <div className="form-container">
                <div className="box-signnumber">
                  <div className="form-input border-top">
                    <div className="pt-form-group">
                      <div className="row">
                        <label className="pt-label col-xs-12 pt0 mb10">
                          Góp ý/Ghi chú
                        </label>
                        <div className="pt-form-content col-xs-12">
                          <textarea
                            rows="4"
                            className="pt-input pt-fill"
                            placeholder="Nhập nội dung..."
                            dir="auto"
                            name="feedbackContent"
                            onChange={this.handleInputChange}
                            value={this.state.feedbackContent}
                          />
                        </div>
                      </div>
                    </div>

                    {(this.state.isSecretary ||
                      this.state.isParentSecretary ||
                      this.state.isLeaderCompositiveSecretary) &&
                      this.state.isShowButton &&
                      !this.state.isAgree && (
                        <div className="pt-form-group">
                          <div className="row">
                            <label className="pt-label col-xs-12 pt0 mb10">
                              Góp ý cho lãnh đạo
                            </label>
                            <div className="pt-form-content col-xs-12">
                              <textarea
                                name="feedbackForLeader"
                                rows="4"
                                className="pt-input pt-fill"
                                placeholder="Nhập nội dung..."
                                dir="auto"
                                value={this.state.feedbackForLeader}
                                onChange={this.handleInputChange}
                              />
                            </div>
                          </div>
                        </div>
                      )}
                  </div>
                </div>
              </div>
            </Authorization.Element>
          )}

        {/*Button ky duyet*/}
        <div className="box-actions-right">
          {this.state.isShowButton && (
            <Authorization.Element permission={[Permission.KYSO_KYDUYET_GOPY]}>
              <div className="box-action">
                <div className="group-button-action">
                  {(this.state.isLeaderUnit ||
                    this.state.isParentLeaderUnit ||
                    this.state.isOtherLeaderUnit ||
                    this.state.isCompositiveDepartmentElectricUnit) &&
                    isKyKhongCanXacThuc && (
                      <div className="button-content">
                        <div className="button-content-2">
                          <Button
                            className="pt-button btn-action-sign-number btn-action-blue display-flex"
                            onClick={this.handleClickAgree}
                            loading={this.state.isLoading.agree}
                            disabled={
                              Object.values(this.props.isLoadingData).find(
                                item => item === true
                              ) !== undefined ||
                              Object.values(this.state.isLoading).find(
                                item => item === true
                              ) !== undefined ||
                              this.state.isAgree === true
                            }
                          >
                            Đồng ý không xác thực
                          </Button>
                        </div>
                      </div>
                    )}

                  {(this.state.isLeaderUnit ||
                    this.state.isParentLeaderUnit ||
                    this.state.isOtherLeaderUnit ||
                    this.state.isCompositiveDepartmentElectricUnit) && (
                    <div className="button-content">
                      {this.props.auth &&
                        this.props.auth.user &&
                        this.props.auth.user.soDienThoaiKySo && (
                          <div className="button-content-2">
                            <Button
                              className="pt-button btn-action-sign-number btn-action-blue display-flex"
                              onClick={this.handleClickAgreeBySIM.bind(
                                this,
                                VIETTEL
                              )}
                              loading={this.state.isLoading.agreeBySIM}
                              disabled={
                                Object.values(this.props.isLoadingData).find(
                                  item => item === true
                                ) !== undefined ||
                                Object.values(this.state.isLoading).find(
                                  item => item === true
                                ) !== undefined ||
                                this.state.isAgree === true
                              }
                            >
                              {this.props.auth.user.soDienThoaiKySoVnpt ? (
                                <p>Đồng ý ký số VIETTEL</p>
                              ) : (
                                <p>Đồng ý ký số SIM</p>
                              )}
                            </Button>
                          </div>
                        )}
                      {this.props.auth &&
                        this.props.auth.user &&
                        this.props.auth.user.soDienThoaiKySoVnpt && (
                          <div className="button-content-2">
                            <Button
                              className="pt-button btn-action-sign-number btn-action-blue display-flex"
                              onClick={this.handleClickAgreeBySIM.bind(
                                this,
                                VNPT
                              )}
                              loading={this.state.isLoading.agreeBySIMVNPT}
                              disabled={
                                Object.values(this.props.isLoadingData).find(
                                  item => item === true
                                ) !== undefined ||
                                Object.values(this.state.isLoading).find(
                                  item => item === true
                                ) !== undefined ||
                                this.state.isAgree === true
                              }
                            >
                              {this.props.auth.user.soDienThoaiKySo ? (
                                <p>Đồng ý ký số VNPT</p>
                              ) : (
                                <p>Đồng ý ký số SIM</p>
                              )}
                            </Button>
                          </div>
                        )}
                    </div>
                  )}

                  {!this.state.isLeaderUnit &&
                    !this.state.isParentLeaderUnit &&
                    !this.state.isOtherLeaderUnit &&
                    !this.state.isCompositiveDepartmentElectricUnit && (
                      <div className="button-content">
                        <Button
                          className="pt-button btn-action-sign-number btn-action-blue display-flex"
                          onClick={this.handleClickAgree}
                          loading={this.state.isLoading.agree}
                          disabled={
                            Object.values(this.props.isLoadingData).find(
                              item => item === true
                            ) !== undefined ||
                            Object.values(this.state.isLoading).find(
                              item => item === true
                            ) !== undefined ||
                            this.state.isAgree === true
                          }
                        >
                          Đồng ý không cần ký số
                          <span className="pt-icon icon-Bao_Cao"></span>
                        </Button>
                      </div>
                    )}

                  <div className="button-content">
                    <Button
                      className="pt-button btn-action-sign-number btn-action-red display-flex"
                      onClick={this.handleClickDisagree}
                      loading={this.state.isLoading.disagree}
                      disabled={
                        Object.values(this.props.isLoadingData).find(
                          item => item === true
                        ) !== undefined ||
                        Object.values(this.state.isLoading).find(
                          item => item === true
                        ) !== undefined ||
                        this.state.isDisagree === true ||
                        this.state.isAgree === true
                      }
                    >
                      Không đồng ý
                      <span className="pt-icon pt-icon-large pt-icon-cross"></span>
                    </Button>
                  </div>
                </div>
              </div>
            </Authorization.Element>
          )}
        </div>

        {/* Cap so */}
        {this.props.type === 'kySoDi' &&
          this.props.signNumber &&
          (this.props.signNumber.tinhTrang === Types.LEADER_UNIT ||
            this.props.signNumber.tinhTrang === Types.PROGRESSION) && (
            <Authorization.Element permission={[Permission.KYSO_CAPSO]}>
              <div className="form-signnumber-submit">
                <div className="form-container box-signnumber">
                  <div className="form-input">
                    <div className="pt-form-group pt-intent-danger">
                      <div className="row">
                        <label className="pt-label col-md-12 col-xs-12 col-sm-12">
                          Số công văn
                          <span className="pt-text-muted required-input">
                            *
                          </span>
                        </label>
                        <div className="pt-form-content col-md-12 col-sm-12 col-xs-12">
                          <input
                            name="soCongVan"
                            value={this.state.soCongVan}
                            className="pt-input"
                            placeholder="Nhập số công văn"
                            type="text"
                            dir="auto"
                            onChange={this.handleInputChange}
                            autoFocus={true}
                          />
                          {this.state.error.soCongVan !== undefined && (
                            <div className="pt-form-helper-text">
                              {this.state.error.soCongVan}
                            </div>
                          )}
                        </div>
                      </div>
                    </div>

                    <div className="pt-form-group pt-intent-danger">
                      <div className="row">
                        <label className="pt-label col-md-12 col-xs-12 col-sm-12">
                          Ngày công văn
                          <span className="pt-text-muted required-input">
                            *
                          </span>
                        </label>
                        <div className="pt-form-content col-md-12 col-sm-12 col-xs-12">
                          <div className="pt-input-group">
                            <DatePicker
                              readOnly={this.props.readOnly}
                              selected={this.state.ngayCongVan}
                              onChange={this.handleDateChange}
                              value={this.state.dateToToString}
                              onChangeRaw={e =>
                                this.handleChangeRaw(
                                  'dateToToString',
                                  e.target.value
                                )
                              }
                              onBlur={e =>
                                this.focousOut(
                                  'ngayCongVan',
                                  'dateToToString',
                                  e.target.value
                                )
                              }
                              format="DD/MM/YYYY"
                              showMonthDropdown
                              showYearDropdown
                              dropdownMode="select"
                              className="input-datepicker input-icon-datepicker"
                              placeholderText="Chọn ngày..."
                              popperPlacement="auto"
                              popperModifiers={{
                                offset: {
                                  enabled: true,
                                  offset: '5px, 10px',
                                },
                                preventOverflow: {
                                  enabled: true,
                                  escapeWithReference: false,
                                  boundariesElement: 'viewport',
                                },
                              }}
                              disabled
                            />
                          </div>
                        </div>
                        {this.state.error.ngayCongVan !== undefined && (
                          <div className="col-xs-12 col-md-offset-3 col-md-9 pt-form-helper-content">
                            <p className="pt-form-helper-text">
                              {this.state.error.ngayCongVan}
                            </p>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="box-action">
                  <div className="group-button-action">
                    <div className="button-content">
                      <Button
                        disabled={this.state.isLoading.progression}
                        loading={
                          this.state.isLoading.progression &&
                          !this.state.isLoading.progressionByVIETTEL &&
                          !this.state.isLoading.progressionByVNPT
                        }
                        className="pt-button btn-action-sign-number btn-action-blue display-flex"
                        onClick={this.onCapSoKhongCanXacThuc}
                      >
                        <span className="text-content">
                          Cấp số không xác thực
                        </span>
                        <span className="pt-icon icon-DongYKysoToken"></span>
                      </Button>
                    </div>

                    {/* {this.props.auth?.user?.suDungUsbToken && (
                      <div className="button-content">
                        <Button
                          disabled={this.state.isLoading.progression}
                          loading={
                            this.state.isLoading.checkProgression &&
                            !this.state.isLoading.progressionByVNPT &&
                            !this.state.isLoading.progressionByVIETTEL
                          }
                          className="pt-button btn-action-sign-number btn-action-blue display-flex"
                          onClick={this.sendProgression}
                        >
                          <span style={{ display: 'none' }}>
                            {hrefProgression}
                          </span>
                          <span className="text-content">
                            Cấp số bằng Token
                          </span>
                          <span className="pt-icon icon-DongYKysoToken"></span>
                        </Button>
                      </div>
                    )} */}
                    {this.props.auth &&
                      this.props.auth.user &&
                      this.props.auth.user.soDienThoaiKySo && (
                        <div className="button-content">
                          <Button
                            disabled={this.state.isLoading.progression}
                            loading={this.state.isLoading.progressionByVIETTEL}
                            className="pt-button btn-action-sign-number btn-action-blue display-flex"
                            onClick={this.sendProgressionBySIM.bind(
                              this,
                              VIETTEL
                            )}
                          >
                            {this.props.auth.user.soDienThoaiKySoVnpt ? (
                              <span className="text-content">
                                Cấp số SIM VIETTEL
                              </span>
                            ) : (
                              <span className="text-content">
                                Cấp số bằng SIM
                              </span>
                            )}
                          </Button>
                        </div>
                      )}

                    {this.props.auth &&
                      this.props.auth.user &&
                      this.props.auth.user.soDienThoaiKySoVnpt && (
                        <div className="button-content">
                          <Button
                            disabled={this.state.isLoading.progression}
                            loading={this.state.isLoading.progressionByVNPT}
                            className="pt-button btn-action-sign-number btn-action-blue display-flex"
                            onClick={this.sendProgressionBySIM.bind(this, VNPT)}
                          >
                            {this.props.auth.user.soDienThoaiKySo ? (
                              <span className="text-content">
                                Cấp số SIM VNPT
                              </span>
                            ) : (
                              <span className="text-content">
                                Cấp số bằng SIM
                              </span>
                            )}
                          </Button>
                        </div>
                      )}
                  </div>
                </div>
                {this.props.signNumber.tinhTrang === Types.LEADER_UNIT && (
                  <div className="form-container">
                    <div className="box-signnumber">
                      <div className="form-input border-top">
                        <div className="pt-form-group">
                          <div className="row">
                            <label className="pt-label col-xs-12 pt0 mb10">
                              Góp ý/Ghi chú
                            </label>
                            <div className="pt-form-content col-xs-12">
                              <textarea
                                rows="4"
                                className="pt-input pt-fill"
                                placeholder="Nhập nội dung..."
                                dir="auto"
                                name="feedbackContent"
                                onChange={this.handleInputChange}
                                value={this.state.feedbackContent}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                )}

                {this.props.signNumber.tinhTrang === Types.LEADER_UNIT && (
                  <div className="box-action">
                    <div className="group-button-action">
                      <div className="button-content">
                        <Button
                          className="pt-button btn-action-sign-number btn-action-red display-flex"
                          onClick={this.handleClickDisagree}
                          loading={this.state.isLoading.disagree}
                          disabled={
                            Object.values(this.props.isLoadingData).find(
                              item => item === true
                            ) !== undefined ||
                            Object.values(this.state.isLoading).find(
                              item => item === true
                            ) !== undefined ||
                            this.state.isDisagree === true ||
                            this.state.isAgree === true
                          }
                        >
                          Không đồng ý
                          <span className="pt-icon pt-icon-large pt-icon-cross"></span>
                        </Button>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </Authorization.Element>
          )}

        {/* Download file khong dau */}
        {this.props.signNumber &&
          (this.props.signNumber.tinhTrang === Types.PROGRESSION ||
            this.props.signNumber.tinhTrang === Types.RELEASED) &&
          this.props.signNumber.fileNoiDungKhongDau && (
            <div className="detail-history-signnumber">
              <div className="row info-file">
                <div className="col-md-12 col-xs-12">
                  <span className="info">
                    {!this.props.signNumber.fileNoiDungKhongDau.migrated && (
                      <a
                        className="link-file"
                        style={{
                          backgroundImage: `url(${Tool.Utils.typeFile(
                            extension
                          )})`,
                        }}
                        target="_blank"
                        rel="noopener noreferrer"
                        href={`${Types.FILE_URL}/api/file/get/${this.props.signNumber.fileNoiDungKhongDau.fileId}?BearerToken=${token}`}
                      >
                        File không dấu dùng để in và đóng dấu gửi ra bên ngoài -{' '}
                        {Tool.Utils.getFileSize(
                          this.props.signNumber.fileNoiDungKhongDau.kichThuoc
                        )}
                      </a>
                    )}
                  </span>
                </div>
              </div>
            </div>
          )}

        {/* Trang thai */}
        {this.props.signNumber && (
          <DetailStatus
            item={this.props.signNumber}
            auth={this.props.auth}
            isShowIconWarningForLeader={isShowIconWarningForLeader}
            typeLeader={typeLeader}
          />
        )}

        {/* Danh sach gop y */}
        {this.props.isLoadingData &&
          this.props.isLoadingData.feedbacks === false &&
          isShowFeedbackSecretary === true &&
          feedbackData &&
          feedbackData.length > 0 && (
            <Feedback
              title="Góp ý của thư ký"
              list={feedbackData}
              auth={this.state.chucDanhId}
              isCanUpdate={
                this.props.signNumber &&
                this.props.signNumber.tinhTrang !== Types.PROGRESSION &&
                this.props.signNumber.tinhTrang !== Types.RELEASED
              }
              onSubmit={this.updateFeedback}
              loading={this.state.isLoading.sendFeedback}
              onChangeFeedback={this.handleUpdateFeedback}
              feedbackId={this.state.feedbackId}
              isLoading={this.state.isLoading}
              isLoadingData={this.props.isLoadingData}
              className="feedback-content-secretary"
              isOfSecretary
              onShowMessageCopySuccess={this.handleShowMessageCopySuccess}
            />
          )}

        {this.props.isLoadingData &&
          this.props.isLoadingData.feedbacks === false &&
          feebackCommon.length > 0 && (
            <Feedback
              title="Góp ý/Ghi chú"
              list={feebackCommon}
              auth={this.state.chucDanhId}
              isCanUpdate={
                this.props.signNumber &&
                this.props.signNumber.tinhTrang !== Types.PROGRESSION &&
                this.props.signNumber.tinhTrang !== Types.RELEASED
              }
              onSubmit={this.updateFeedback}
              loading={this.state.isLoading.sendFeedback}
              onChangeFeedback={this.handleUpdateFeedback}
              feedbackId={this.state.feedbackId}
              isLoading={this.state.isLoading}
              isLoadingData={this.props.isLoadingData}
            />
          )}

        {/* Phong ban chuyen den + phong ban chuyen den don vi khac */}
        {this.props.isLoadingData &&
          this.props.isLoadingData.signNumber === false &&
          this.props.type === 'kySoNoiBo' && (
            <ListReceivedDepartments signNumber={this.props.signNumber} />
          )}

        {/* Lich su gop y*/}
        {this.props.isLoadingData &&
          this.props.isLoadingData.signNumber === false &&
          this.props.isLoadingData.feedbacks === false &&
          this.props.isShowHistoryDetail === false && (
            <UpdateHistory
              list={this.props.listHistoryUpdate}
              onClickShowHistoryDetail={this.props.onClickShowHistoryDetail}
              onGetHistoryList={this.props.onGetHistoryList}
              isLoadingData={
                this.props.isLoadingData && this.props.isLoadingData.historyList
              }
              isOpenHistoryList={this.props.isOpenHistoryList}
            />
          )}

        {/* Popup Confirm cấp số */}
        <PopupConfirm
          isOpen={this.state.isOpenPopup}
          onClose={this.handleClickPopup}
          title={POPUP.CAP_SO}
          text={POPUP.TEXT_BUTTON_SUBMIT}
          onSubmit={this.handleRedirectProgression}
        >
          {POPUP.XAC_NHAN_SO_VAN_BAN_TRUNG}
        </PopupConfirm>
        <PopupConfirm
          isOpen={this.state.isOpenPopupCapSoKhongXacThuc}
          onClose={this.handleClickPopupCapSoKhongXacThuc}
          title={POPUP.CAP_SO}
          text={POPUP.TEXT_BUTTON_SUBMIT}
          onSubmit={this.handleRedirectProgressionCapSoKhongXacThuc}
          isDisabled={this.state.isLoading.progression}
          isLoading={this.state.isLoading.progression}
        >
          {POPUP.XAC_NHAN_SO_VAN_BAN_TRUNG}
        </PopupConfirm>
      </div>
    )
  }
}

export default DetailBoxAction
