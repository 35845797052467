import React, { Component } from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import moment from 'moment'
import { Intent, Button } from '@blueprintjs/core'
import DatePicker from 'react-datepicker'
import 'moment/locale/vi'

import * as MESSAGE from '../../constants/MessageForm'
import * as Types from '../../constants/Api'
import * as Tool from '../../helpers'
import { toDecamelizeKeys } from 'helpers/key'
import { ducumentsType, imagesType, compressedsType } from 'helpers/file'
import UploadFileInputSingle from 'components/ui/upload-file/input-single'

class InputForm extends Component {
  static propTypes = {
    objects: PropTypes.array.isRequired,
    securities: PropTypes.array.isRequired,
    priorities: PropTypes.array.isRequired,
    saveCodes: PropTypes.array.isRequired,
    relativeUnits: PropTypes.array.isRequired,
    leaderDepartments: PropTypes.array.isRequired,
    isShow: PropTypes.bool.isRequired,
    actions: PropTypes.object.isRequired,
    onSubmit: PropTypes.func.isRequired,
    digitalDocumentId: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
    ]),
    evnDocumentId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    receiveDocument: PropTypes.object,
    receiveDocumentId: PropTypes.string,
    isUpdate: PropTypes.bool.isRequired,
  }

  constructor(props) {
    super(props)
    // this.handleClickPopup = this.handleClickPopup.bind(this);
    this.handleChange = this.handleChange.bind(this)
    this.onSetFileDinhKem = this.onSetFileDinhKem.bind(this)
    this.onRemoveFileDinhKem = this.onRemoveFileDinhKem.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
    this.handleDateChange = this.handleDateChange.bind(this)
    this.handleFileChange = this.handleFileChange.bind(this)
    this.getUploadProgress = this.getUploadProgress.bind(this)
    this.handleChangeRaw = this.handleChangeRaw.bind(this)
    this.focousOut = this.focousOut.bind(this)
  }

  state = {
    isOpenPopup: false,
    error: {},
    congVanDenId: '',
    soCongVan: '',
    vanBanSoHoaId: undefined,
    vanBanEvnId: undefined,
    ngayCongVan: moment(),
    doiTuongCongVanId: '',
    soLuuId: this.props.soLuuId || '',
    soDen: '',
    doKhanId: '',
    doBaoMatId: '',
    coQuanBanHanhId: '',
    coQuanBanHanh: '',
    trichYeu: '',
    fileNoiDung: null,
    fileUpload: null,
    fileName: '',
    trinhLanhDaoId: '',
    isLoading: false,
    fileDefault: {
      file_id: '9a217faa-b823-4290-bce6-779ee83f2c2d',
      ten_file: 'SoHoaChuaCoFileNoiDung.docx',
      url: '/AttachmentFile/20171005083456899_9a217faa-b823-4290-bce6-779ee83f2c2d.docx',
      kieu_file:
        'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
      kich_thuoc: 4234,
    },
    sizeFile: null,
    progress: 0,
    dateToToString: moment().format('DD/MM/YYYY'),
    isFromOtherUnit: false,
    // CHANGED: Disabled soCongVan, ngayCongVan, coQuanBanHanh nếu công văn từ công văn đi
    isFromSendDocument: false,
    isHadLeaderUnit: false, // Da trinh lanh dao
    fileDinhKem: [],
  }

  getUploadProgress = () => progress => {
    this.setState({
      progress: Math.floor((progress.loaded * 100) / progress.total),
    })
  }

  onSetFileDinhKem = files => {
    this.setState({ fileDinhKem: [...this.state.fileDinhKem, ...files] })
  }

  onRemoveFileDinhKem = (file, index) => {
    this.setState({
      fileDinhKem: this.state.fileDinhKem?.filter((_, i) => i !== index),
    })
  }

  handleChange = e => {
    const name = e.target.name
    let value = e.target.value
    const error = this.state.error
    delete error[name]

    if (name === 'soDen' && (isNaN(Number(value)) || Number(value) < 1)) {
      value = ''
    }

    this.setState({
      [e.target.name]: value,
      error,
    })

    if (e.target.name === 'soLuuId') {
      this.props.saveCodes.forEach(item => {
        if (item.soLuuId === e.target.value) {
          this.setState({ soDen: item.soCuoi + 1 })
        }
      })
      delete error.soDen
    }

    if (e.target.name === 'coQuanBanHanhId') {
      this.props.relativeUnits.forEach(item => {
        if (item.donViId === e.target.value) {
          this.setState({ coQuanBanHanh: item.tenVietTat })
        }
        if (e.target.value === 'Khác') {
          this.setState({ coQuanBanHanh: '' })
        }
      })
    }
  }

  handleFileChange = e => {
    const error = this.state.error
    const fileAccepts = ['pdf', 'doc', 'docx']
    delete error.fileName

    const fileName = e.target.value
    const extension =
      fileName &&
      fileName.slice(((fileName.lastIndexOf('.') - 1) >>> 0) + 2).toLowerCase()
    let sizeFile = e.target.files && e.target.files[0] && e.target.files[0].size

    if (!fileName.trim().length && this.state.fileName.length === 0) {
      error.fileName = MESSAGE.TEXT_MESSAGE_DEFAULT
    } else if (extension && !fileAccepts?.includes(extension)) {
      error.fileName = MESSAGE.TEXT_TYPE_FILE_UPLOAD
    } else if (sizeFile > 1024 * 1024 * 50) {
      error.fileName = MESSAGE.ValidateForm.MAX_FILE_SIZE(50)
    }

    this.setState({ error })

    if (!error.fileName) {
      let reader = new FileReader()
      let file = e.target.files[0]

      reader.onloadend = () => {
        this.setState({
          fileUpload: file,
          fileName: file.name,
          fileNoiDung: null,
          sizeFile,
        })
      }

      if (file) {
        reader.readAsDataURL(file)
      }
    }
  }

  handleDateChange = (name, nameToString, e) => {
    const error = this.state.error
    delete error[name]
    let value = e ? e : undefined
    let valueToString = ''
    if (value) {
      valueToString = moment(e).format('DD/MM/YYYY')
    }

    this.setState({
      [name]: value,
      [nameToString]: valueToString,
      error,
    })
  }

  handleChangeRaw(name, value) {
    this.setState({ [name]: value })
  }

  focousOut(name, nameToString, value) {
    if (Tool.Utils.isValidDate(value)) {
      return
    }

    let oldValue = undefined
    let oldValueToString = ''
    if (this.props.receiveDocument && this.props.receiveDocument.ngayCongVan) {
      oldValue = moment(this.props.receiveDocument.ngayCongVan)
      oldValueToString = moment(this.props.receiveDocument.ngayCongVan).format(
        'DD/MM/YYYY'
      )
    }

    this.setState({
      [nameToString]: oldValueToString,
      [name]: oldValue,
    })
  }

  handleSubmit = async e => {
    e.preventDefault()
    this.setState({ error: {} })
    const uploadFileDinhKem = async () => {
      const fileUpload = this.state.fileDinhKem?.filter(e => e?.file)
      if (!fileUpload?.length > 0 ?? false) {
        return []
      }

      return await Promise.all([
        this.props.actions.fileUpload(
          fileUpload?.map(file => file?.file),
          () => {}
        ),
      ]).then(res => {
        const files = res[0]?.payload?.data?.result
        if (files && (files?.length > 0 ?? false)) {
          return files?.map(e => ({
            file_id: e?.id,
            ten_file: e?.originalName,
            url: e?.path,
            kieu_file: e?.type,
            kich_thuoc: e?.length,
          }))
        }

        return res
      })
    }

    const error = {}
    let sizeFile = this.state.sizeFile

    if (!this.state.soCongVan || this.state.soCongVan.trim().length === 0) {
      error.soCongVan = MESSAGE.TEXT_MESSAGE_DEFAULT
    }

    if (this.state.soCongVan && this.state.soCongVan.trim().length > 50) {
      error.soCongVan = MESSAGE.TEXT_MESSAGE_MAX_LENGHT
    }

    if (!this.state.ngayCongVan) {
      error.ngayCongVan = MESSAGE.TEXT_MESSAGE_DEFAULT
    }

    if (!this.state.soDen || this.state.soDen.toString().trim().length === 0) {
      error.soDen = MESSAGE.TEXT_MESSAGE_DEFAULT
    }

    if (
      Number(this.state.soDen) < 1 &&
      this.state.soDen &&
      this.state.soDen.trim().length > 0
    ) {
      error.soDen = MESSAGE.TEXT_GIATRI_LONHON_0
    }

    if (!this.state.trichYeu || this.state.trichYeu.trim().length === 0) {
      error.trichYeu = MESSAGE.TEXT_MESSAGE_DEFAULT
    }

    if (
      !this.state.doiTuongCongVanId ||
      this.state.doiTuongCongVanId.trim().length === 0
    ) {
      error.doiTuongCongVanId = MESSAGE.TEXT_MESSAGE_DEFAULT
    }

    if (!this.state.doKhanId || this.state.doKhanId.trim().length === 0) {
      error.doKhanId = MESSAGE.TEXT_MESSAGE_DEFAULT
    }

    if (!this.state.soLuuId || this.state.soLuuId.trim().length === 0) {
      error.soLuuId = MESSAGE.TEXT_MESSAGE_DEFAULT
    }

    if (
      !this.state.coQuanBanHanhId ||
      this.state.coQuanBanHanhId.trim().length === 0
    ) {
      error.coQuanBanHanhId = MESSAGE.TEXT_MESSAGE_DEFAULT
    }

    if (!this.state.fileName || this.state.fileName.trim().length === 0) {
      error.fileName = MESSAGE.TEXT_MESSAGE_DEFAULT
      sizeFile = null
    }

    if (
      !this.state.coQuanBanHanh ||
      this.state.coQuanBanHanh.trim().length === 0
    ) {
      error.coQuanBanHanh = MESSAGE.TEXT_MESSAGE_DEFAULT
    }

    if (
      !this.state.doiTuongCongVanId ||
      this.state.doiTuongCongVanId.trim().length === 0
    ) {
      error.doiTuongCongVanId = MESSAGE.TEXT_MESSAGE_DEFAULT
    }

    if (!this.state.doKhanId || this.state.doKhanId.trim().length === 0) {
      error.doKhanId = MESSAGE.TEXT_MESSAGE_DEFAULT
    }

    if (!this.state.soLuuId || this.state.soLuuId.trim().length === 0) {
      error.soLuuId = MESSAGE.TEXT_MESSAGE_DEFAULT
    }

    if (
      !this.state.coQuanBanHanhId ||
      this.state.coQuanBanHanhId.trim().length === 0
    ) {
      error.coQuanBanHanhId = MESSAGE.TEXT_MESSAGE_DEFAULT
    }

    this.setState({ error, sizeFile, isLoading: true })
    if (Object.keys(error).length !== 0) {
      this.setState({ isLoading: false })
      if (
        (!this.state.fileName || this.state.fileName.length < 0) &&
        this.props.isShow === true
      ) {
        return this.props.onSubmit(
          MESSAGE.TEXT_MESSAGE_REQUIRE_FILE,
          Intent.WARNING
        )
      }
      return this.props.onSubmit(MESSAGE.TOATS_MESSAGE_WARNING, Intent.WARNING)
    }
    this.setState({ isLoading: true })
    this.props.onLoadSubmit && this.props.onLoadSubmit()

    Promise.all([
      this.state.fileUpload
        ? this.props.actions.fileUpload(
            this.state.fileUpload,
            this.getUploadProgress()
          )
        : this.state.fileNoiDung
        ? {
            payload: {
              data: {
                result: [
                  {
                    id: this.state.fileNoiDung?.file_id,
                    originalName: this.state.fileNoiDung?.ten_file,
                    path: this.state.fileNoiDung?.url,
                    type: this.state.fileNoiDung?.kieu_file,
                    length: this.state.fileNoiDung?.kich_thuoc,
                  },
                ],
              },
            },
          }
        : true,
      uploadFileDinhKem(),
    ]).then(res => {
      if (res[0]?.error || res[1]?.[0]?.error) {
        this.setState({ error: {}, isLoading: false })
        if (res[0]?.error) {
          this.props.onSubmit(
            res[0]?.error?.message
              ? res[0].error.message
              : MESSAGE.ValidateForm.MAX_FILE_SIZE(50),
            Intent.WARNING
          )
        }

        if (res[1]?.[0]?.error) {
          this.props.onSubmit(
            res[1]?.[0]?.error?.message
              ? res[1][0].error.message
              : 'Dung lượng file đính kèm tối đa là 100MB',
            Intent.WARNING
          )
        }

        return true
      }

      const mainFile = res[0]?.payload?.data?.result?.[0]
      const fileDinhKemNotUpload =
        this.state.fileDinhKem
          ?.filter(e => !e?.file)
          ?.map(elm => toDecamelizeKeys(elm)) || []
      const dinhKemFiles = [...fileDinhKemNotUpload, ...res[1]]
      if (res[0] === true) {
        error.fileName = MESSAGE.TEXT_MESSAGE_DEFAULT
        this.setState({ error, isLoading: false })
        return this.props.onSubmit(
          MESSAGE.TOATS_MESSAGE_WARNING,
          Intent.WARNING
        )
      } else if (!mainFile) {
        error.fileName = MESSAGE.TEXT_MESSAGE_ERROR_UPLOAD
        return this.setState({ error, isLoading: false })
      }

      this.setState({ isLoading: true })
      let ngayCongVan = ''
      if (
        this.state.ngayCongVan &&
        this.state.ngayCongVan.toString().length > 0
      ) {
        ngayCongVan = moment(this.state.ngayCongVan)
          .set({ hour: 0, minute: 0, second: 0, millisecond: 0 })
          .format()
      }

      const fileNoiDung = {
        file_id: mainFile.id,
        ten_file: mainFile.originalName,
        url: mainFile.path,
        kieu_file: mainFile.type,
        kich_thuoc: mainFile.length,
      }

      let dataRequest = {
        so_cong_van: this.state.soCongVan,
        van_ban_so_hoa_id: this.state.vanBanSoHoaId,
        van_ban_evn_id: this.state.vanBanEvnId,
        ngay_cong_van: ngayCongVan,
        doi_tuong_cong_van_id: this.state.doiTuongCongVanId,
        so_den: Number(this.state.soDen),
        so_luu_id: this.state.soLuuId,
        do_khan_id: this.state.doKhanId,
        do_bao_mat_id:
          this.state.doBaoMatId === '' ? null : this.state.doBaoMatId,
        co_quan_ban_hanh_id:
          this.state.coQuanBanHanhId === 'Khác'
            ? null
            : this.state.coQuanBanHanhId,
        co_quan_ban_hanh: this.state.coQuanBanHanh,
        trich_yeu: this.state.trichYeu,
        file_noi_dung: fileNoiDung,
        phong_ban_trinh_id:
          this.state.trinhLanhDaoId === '' ? null : this.state.trinhLanhDaoId,
        file_evn_id: this.props.fileEvnId || null,
        ds_file_dinh_kem: dinhKemFiles,
      }

      if (!this.props.isUpdate) {
        this.props.actions.receiveDocumentAdd(dataRequest).then(res => {
          if (res?.payload?.status === 200) {
            if (dataRequest.phong_ban_trinh_id) {
              const phongBanTrinh = this.props.leaderDepartments.find(
                item => item.phongBanId === dataRequest.phong_ban_trinh_id
              )
              return this.props.onSubmit(
                `Công văn đã trình cho ${phongBanTrinh.tenPhongBan}`,
                Intent.SUCCESS,
                true,
                this.state.soLuuId
              )
            }

            this.props.actions.getNumberDocumentPage()
            return this.props.onSubmit(
              MESSAGE.TOATS_MESSAGE_SUCCESS,
              Intent.SUCCESS,
              true,
              this.state.soLuuId
            )
          }

          this.setState({ isLoading: false })
          this.props.onSubmit(
            MESSAGE.TOATS_MESSAGE_FAIL,
            Intent.DANGER,
            false,
            null
          )
        })
      } else {
        dataRequest = {
          ...dataRequest,
          cong_van_den_id: this.state.congVanDenId,
        }
        this.props.actions
          .receiveDocumentUpdate(this.state.congVanDenId, dataRequest)
          .then(res => {
            if (res.payload && res.payload.status === 200) {
              this.props.actions.getNumberDocumentPage()
              if (dataRequest.phong_ban_trinh_id) {
                const phongBanTrinh = this.props.leaderDepartments.find(
                  item => item.phongBanId === dataRequest.phong_ban_trinh_id
                )
                return this.props.onSubmit(
                  `Công văn đã trình cho ${phongBanTrinh.tenPhongBan}`,
                  Intent.SUCCESS,
                  true,
                  this.state.soLuuId
                )
              }

              return this.props.onSubmit(
                MESSAGE.TOATS_MESSAGE_SUCCESS,
                Intent.SUCCESS,
                true,
                this.state.soLuuId
              )
            }

            this.setState({ isLoading: false })
            this.props.onSubmit(
              MESSAGE.TOATS_MESSAGE_FAIL,
              Intent.DANGER,
              false,
              null
            )
          })
      }
    })
  }

  componentWillReceiveProps = async nextProps => {
    if (
      nextProps.objects !== this.props.objects &&
      nextProps.objects &&
      nextProps.objects.length
    ) {
      await this.setState({
        doiTuongCongVanId: nextProps.objects[0].doiTuongCongVanId,
      })
    }

    if (
      nextProps.priorities !== this.props.priorities &&
      nextProps.priorities &&
      nextProps.priorities.length
    ) {
      await this.setState({ doKhanId: nextProps.priorities[0].doKhanId })
    }

    if (
      nextProps.securities !== this.props.securities &&
      nextProps.securities &&
      nextProps.securities.length
    ) {
      await this.setState({ doBaoMatId: nextProps.securities[0].doBaoMatId })
    }

    if (
      nextProps.saveCodes !== this.props.saveCodes &&
      nextProps.saveCodes &&
      nextProps.saveCodes.length
    ) {
      let soDen = 1
      let soLuuId = this.state.soLuuId
      if (nextProps.saveCodes[nextProps.saveCodes.length - 1]) {
        soLuuId = nextProps.saveCodes[nextProps.saveCodes.length - 1].soLuuId
        soDen = nextProps.saveCodes[nextProps.saveCodes.length - 1].soCuoi + 1
      }
      await this.setState({ soLuuId, soDen })
    }

    if (
      nextProps.soLuuId &&
      nextProps.soLuuId !== this.props.soLuuId &&
      nextProps.soLuuId.length
    ) {
      if (
        this.props.saveCodes.find(item => item.soLuuId === nextProps.soLuuId)
      ) {
        let soDen =
          this.props.saveCodes.find(item => item.soLuuId === nextProps.soLuuId)
            .soCuoi + 1
        let soLuuId = nextProps.soLuuId
        await this.setState({ soLuuId, soDen })
      }
    }

    if (
      nextProps.relativeUnits !== this.props.relativeUnits &&
      nextProps.relativeUnits.length
    ) {
      await this.setState({
        coQuanBanHanhId: nextProps.relativeUnits[0].donViId,
        coQuanBanHanh: nextProps.relativeUnits[0].tenVietTat,
      })
    }

    if (
      nextProps.selectedFile &&
      nextProps.selectedFile !== this.props.selectedFile
    ) {
      this.setState({
        fileUpload: nextProps.selectedFile,
        fileName: nextProps.selectedFile.name,
      })
    }

    if (
      nextProps.digitalDocumentId &&
      nextProps.digitalDocumentId.toString().length > 0 &&
      this.props.digitalDocuments &&
      this.props.digitalDocuments[nextProps.digitalDocumentId] !== undefined &&
      nextProps.digitalDocumentId !== this.props.digitalDocumentId
    ) {
      const soCongVan =
        this.props.digitalDocuments[nextProps.digitalDocumentId].soCongVan
      let ngayCongVan = undefined
      let dateToToString = ''
      let trichYeu = ''
      if (
        this.props.digitalDocuments[nextProps.digitalDocumentId].ngayCongVan &&
        Tool.Utils.isValidDate(
          moment(
            this.props.digitalDocuments[nextProps.digitalDocumentId].ngayCongVan
          ).format('DD/MM/YYYY')
        )
      ) {
        ngayCongVan = moment(
          this.props.digitalDocuments[nextProps.digitalDocumentId].ngayCongVan
        )
        dateToToString = moment(
          this.props.digitalDocuments[nextProps.digitalDocumentId].ngayCongVan
        ).format('DD/MM/YYYY')
      }

      if (this.props.digitalDocuments[nextProps.digitalDocumentId].trichYeu) {
        trichYeu =
          this.props.digitalDocuments[nextProps.digitalDocumentId].trichYeu
      }
      this.setState({
        vanBanSoHoaId: nextProps.digitalDocumentId,
        soCongVan,
        ngayCongVan,
        dateToToString,
        trichYeu,
      })
    }

    if (
      nextProps.evnDocumentId &&
      nextProps.evnDocumentId.toString().length > 0 &&
      this.props.evnDocuments &&
      this.props.evnDocuments[nextProps.evnDocumentId] !== undefined &&
      nextProps.evnDocumentId !== this.props.evnDocumentId
    ) {
      const soCongVan =
        this.props.evnDocuments[nextProps.evnDocumentId].soCongVan
      let ngayCongVan = undefined
      let dateToToString = ''
      let trichYeu = ''
      if (
        this.props.evnDocuments[nextProps.evnDocumentId].ngayCongVan &&
        Tool.Utils.isValidDate(
          moment(
            this.props.evnDocuments[nextProps.evnDocumentId].ngayCongVan
          ).format('DD/MM/YYYY')
        )
      ) {
        ngayCongVan = moment(
          this.props.evnDocuments[nextProps.evnDocumentId].ngayCongVan
        )
        dateToToString = moment(
          this.props.evnDocuments[nextProps.evnDocumentId].ngayCongVan
        ).format('DD/MM/YYYY')
      }

      if (this.props.evnDocuments[nextProps.evnDocumentId].trichYeu) {
        trichYeu = this.props.evnDocuments[nextProps.evnDocumentId].trichYeu
      }
      this.setState({
        vanBanEvnId: nextProps.evnDocumentId,
        soCongVan,
        ngayCongVan,
        dateToToString,
        trichYeu,
      })
    }

    // CHANGED: Tao cong van thanh cong => reset form
    if (nextProps.isSubmitSuccess && nextProps.isSubmitSuccess === true) {
      let doiTuongCongVanId = this.state.doiTuongCongVanId
      let doKhanId = this.state.doKhanId
      let doBaoMatId = this.state.doBaoMatId
      let coQuanBanHanhId = this.state.coQuanBanHanhId
      let coQuanBanHanh = this.state.coQuanBanHanh

      if (this.props.objects && this.props.objects.length) {
        doiTuongCongVanId = this.props.objects[0].doiTuongCongVanId
      }

      if (this.props.priorities && this.props.priorities.length) {
        doKhanId = this.props.priorities[0].doKhanId
      }

      if (this.props.securities && this.props.securities.length) {
        doBaoMatId = this.props.securities[0].doBaoMatId
      }

      if (this.props.relativeUnits && this.props.relativeUnits.length) {
        coQuanBanHanhId = this.props.relativeUnits[0].donViId
        coQuanBanHanh = this.props.relativeUnits[0].tenVietTat
      }

      this.setState({
        error: {},
        soCongVan: '',
        vanBanSoHoaId: '',
        vanBanEvnId: '',
        ngayCongVan: moment(),
        doiTuongCongVanId,
        doKhanId,
        doBaoMatId,
        coQuanBanHanhId,
        coQuanBanHanh,
        trinhLanhDaoId: '',
        trichYeu: '',
        fileNoiDung: null,
        fileUpload: null,
        fileName: '',
        isLoading: false,
        sizeFile: null,
        progress: 0,
        dateToToString: moment().format('DD/MM/YYYY'),
        fileDinhKem: [],
      })
    }
  }

  componentDidMount() {
    this.setState({ error: {} })

    if (
      this.props.digitalDocumentId &&
      this.props.digitalDocumentId.toString().length > 0 &&
      this.props.digitalDocuments[this.props.digitalDocumentId] !== undefined
    ) {
      const soCongVan =
        this.props.digitalDocuments[this.props.digitalDocumentId].soCongVan
      let ngayCongVan = undefined
      let dateToToString = ''
      let trichYeu = ''
      if (
        this.props.digitalDocuments[this.props.digitalDocumentId].ngayCongVan &&
        Tool.Utils.isValidDate(
          moment(
            this.props.digitalDocuments[this.props.digitalDocumentId]
              .ngayCongVan
          ).format('DD/MM/YYYY')
        )
      ) {
        ngayCongVan = moment(
          this.props.digitalDocuments[this.props.digitalDocumentId].ngayCongVan
        )
        dateToToString = moment(
          this.props.digitalDocuments[this.props.digitalDocumentId].ngayCongVan
        ).format('DD/MM/YYYY')
      }

      if (this.props.digitalDocuments[this.props.digitalDocumentId].trichYeu) {
        trichYeu =
          this.props.digitalDocuments[this.props.digitalDocumentId].trichYeu
      }
      this.setState({
        vanBanSoHoaId: this.props.digitalDocumentId,
        soCongVan,
        ngayCongVan,
        dateToToString,
        trichYeu,
      })
    }

    if (
      this.props.evnDocumentId &&
      this.props.evnDocumentId.toString().length > 0 &&
      this.props.evnDocuments[this.props.evnDocumentId] !== undefined
    ) {
      const soCongVan =
        this.props.evnDocuments[this.props.evnDocumentId].soCongVan
      let ngayCongVan = undefined
      let dateToToString = ''
      let trichYeu = ''
      if (
        this.props.evnDocuments[this.props.evnDocumentId].ngayCongVan &&
        Tool.Utils.isValidDate(
          moment(
            this.props.evnDocuments[this.props.evnDocumentId].ngayCongVan
          ).format('DD/MM/YYYY')
        )
      ) {
        ngayCongVan = moment(
          this.props.evnDocuments[this.props.evnDocumentId].ngayCongVan
        )
        dateToToString = moment(
          this.props.evnDocuments[this.props.evnDocumentId].ngayCongVan
        ).format('DD/MM/YYYY')
      }

      if (this.props.evnDocuments[this.props.evnDocumentId].trichYeu) {
        trichYeu = this.props.evnDocuments[this.props.evnDocumentId].trichYeu
      }
      this.setState({
        vanBanEvnId: this.props.evnDocumentId,
        soCongVan,
        ngayCongVan,
        dateToToString,
        trichYeu,
      })
    }

    if (this.props.selectedFile) {
      this.setState({
        fileUpload: this.props.selectedFile,
        fileName: this.props.selectedFile.name,
      })
    }

    if (this.props.objects && this.props.objects.length) {
      this.setState({
        doiTuongCongVanId: this.props.objects[0].doiTuongCongVanId,
      })
    }

    if (this.props.priorities && this.props.priorities.length) {
      this.setState({ doKhanId: this.props.priorities[0].doKhanId })
    }

    if (this.props.securities && this.props.securities.length) {
      this.setState({ doBaoMatId: this.props.securities[0].doBaoMatId })
    }

    if (this.props.saveCodes && this.props.saveCodes.length) {
      let soDen = 1
      let soLuuId = this.state.soLuuId
      if (
        this.props.soLuuId &&
        this.props.soLuuId.length &&
        this.props.saveCodes.find(item => item.soLuuId === this.props.soLuuId)
      ) {
        soDen =
          this.props.saveCodes.find(item => item.soLuuId === this.props.soLuuId)
            .soCuoi + 1
        soLuuId = this.props.soLuuId
      } else {
        soLuuId = this.props.saveCodes[this.props.saveCodes.length - 1].soLuuId
        soDen = this.props.saveCodes[this.props.saveCodes.length - 1].soCuoi + 1
      }
      this.setState({ soLuuId, soDen })
    }

    if (this.props.relativeUnits && this.props.relativeUnits.length) {
      this.setState({
        coQuanBanHanhId: this.props.relativeUnits[0].donViId,
        coQuanBanHanh: this.props.relativeUnits[0].tenVietTat,
      })
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      prevProps.relativeUnits !== this.props.relativeUnits &&
      this.state.coQuanBanHanh === ''
    ) {
      this.setState({
        coQuanBanHanhId: 'Khác',
        coQuanBanHanh: '',
      })
    }

    if (
      this.props.isUpdate &&
      this.props.receiveDocument &&
      this.props.receiveDocument !== prevProps.receiveDocument
    ) {
      let coQuanBanHanhId = 'Khác'
      let soLuuId = this.state.soLuuId
      let soDen = this.state.soDen
      let trinhLanhDaoId = ''
      let doKhanId = this.state.doKhanId
      let doBaoMatId = ''
      let doiTuongCongVanId = this.state.doiTuongCongVanId
      let fileNoiDung = this.state.fileNoiDung
      let fileDinhKem = this.state.fileDinhKem
      let ngayCongVan = undefined
      let dateToToString = ''
      let isFromOtherUnit = false
      let isFromSendDocument = false
      let vanBanSoHoaId = undefined
      let vanBanEvnId = undefined
      let isHadLeaderUnit = false

      if (
        this.props.receiveDocument.referenceId &&
        this.props.receiveDocument.nguonGocVanBan ===
          Types.DOCUMENT_RECEIVE_DIGITAL
      ) {
        vanBanSoHoaId = this.props.receiveDocument.referenceId
      }
      if (
        this.props.receiveDocument.referenceId &&
        this.props.receiveDocument.nguonGocVanBan === Types.DOCUMENT_RECEIVE_EVN
      ) {
        vanBanEvnId = this.props.receiveDocument.referenceId
      }

      if (
        this.props.receiveDocument.congVanDiId ||
        this.props.receiveDocument.congVanCapTrenChiDaoId
      ) {
        isFromSendDocument = true
      }

      if (this.props.receiveDocument.coQuanBanHanhId !== null) {
        coQuanBanHanhId = this.props.receiveDocument.coQuanBanHanhId
      }
      if (this.props.receiveDocument.soLuu) {
        soLuuId = this.props.receiveDocument.soLuu.soLuuId
        soDen = this.props.receiveDocument.soDen
      } else {
        isFromOtherUnit = true
      }
      if (this.props.receiveDocument.trinhLanhDao) {
        trinhLanhDaoId = this.props.receiveDocument.trinhLanhDao.phongBanId
        isHadLeaderUnit = true
      }
      if (this.props.receiveDocument.doiTuongCongVan) {
        doiTuongCongVanId =
          this.props.receiveDocument.doiTuongCongVan.doiTuongCongVanId
      }
      if (this.props.receiveDocument.doKhan) {
        doKhanId = this.props.receiveDocument.doKhan.doKhanId
      }
      if (this.props.receiveDocument.doBaoMat) {
        doBaoMatId = this.props.receiveDocument.doBaoMat.doBaoMatId
      }

      if (this.props.receiveDocument.fileNoiDung) {
        fileNoiDung = {
          file_id: this.props.receiveDocument.fileNoiDung.fileId,
          ten_file: this.props.receiveDocument.fileNoiDung.tenFile,
          url: this.props.receiveDocument.fileNoiDung.url,
          kieu_file: this.props.receiveDocument.fileNoiDung.kieuFile,
          kich_thuoc: this.props.receiveDocument.fileNoiDung.kichThuoc,
        }
      }

      if (this.props.receiveDocument.fileDinhKem) {
        fileDinhKem = this.props.receiveDocument.fileDinhKem
      }

      if (
        this.props.receiveDocument.ngayCongVan &&
        Tool.Utils.isValidDate(
          moment(this.props.receiveDocument.ngayCongVan).format('DD/MM/YYYY')
        )
      ) {
        ngayCongVan = moment(this.props.receiveDocument.ngayCongVan)
        dateToToString = moment(this.props.receiveDocument.ngayCongVan).format(
          'DD/MM/YYYY'
        )
      }

      this.setState({
        congVanDenId: this.props.receiveDocument.congVanDenId,
        soCongVan: this.props.receiveDocument.soCongVan
          ? this.props.receiveDocument.soCongVan
          : '',
        soDen,
        dateToToString,
        ngayCongVan,
        doiTuongCongVanId,
        soLuuId,
        doKhanId,
        doBaoMatId,
        coQuanBanHanhId,
        coQuanBanHanh: this.props.receiveDocument.coQuanBanHanh,
        trichYeu: this.props.receiveDocument.trichYeu
          ? this.props.receiveDocument.trichYeu
          : '',
        trinhLanhDaoId,
        fileNoiDung,
        fileName: this.props.receiveDocument.fileNoiDung
          ? this.props.receiveDocument.fileNoiDung.tenFile
          : '',
        vanBanSoHoaId,
        vanBanEvnId,
        isFromOtherUnit,
        isFromSendDocument,
        isHadLeaderUnit,
        fileDinhKem,
      })
    }
  }

  render() {
    // CHANGED: Thêm option giả cho cơ quan ban hành không có trong danh sách
    let coQuanBanHanhNgoaiDs = null
    let coQuanBanHanhIdNgoaiDs = null
    if (
      this.props.isUpdate &&
      this.props.relativeUnits &&
      this.state.coQuanBanHanhId !== 'Khác' &&
      this.props.relativeUnits.find(
        item => item.donViId === this.state.coQuanBanHanhId
      ) === undefined
    ) {
      coQuanBanHanhNgoaiDs = this.state.coQuanBanHanh
      coQuanBanHanhIdNgoaiDs = this.state.coQuanBanHanhId
    }

    // Kiem tra co phai van ban nhap tay khong
    // let isManualDocument = true;
    // if (
    //   !this.props.isUpdate ||
    //   this.state.isFromOtherUnit === true ||
    //   (
    //     this.props.isUpdate &&
    //     this.props.receiveDocument &&
    //     this.props.receiveDocument.referenceId &&
    //     (
    //       this.props.receiveDocument.nguonGocVanBan === Types.DOCUMENT_RECEIVE_DIGITAL ||
    //       this.props.receiveDocument.nguonGocVanBan === Types.DOCUMENT_RECEIVE_EVN
    //     )
    //   )
    // ) {
    //   isManualDocument = false;
    // }

    const fileDinhKem = this.state.fileDinhKem
    const auth = this.props.auth
    return (
      <div>
        <div
          className={classnames({
            'add-receive-document': !this.props.isShow,
            'ad-digitizing': this.props.isShow,
          })}
        >
          <div className="form-container">
            <div className="form-padding-box">
              <div className="pt-form-group pt-intent-danger">
                <div className="row">
                  <div className="col-xs-6 col-sm-6 col-md-6 form-item">
                    <label className="pt-label">
                      Số công văn
                      <span className="pt-text-muted required-input">*</span>
                    </label>
                    <div className="pt-form-content">
                      <input
                        name="soCongVan"
                        value={this.state.soCongVan}
                        className={classnames('pt-input', {
                          'loading-input':
                            this.props.isLoadingData &&
                            this.props.isLoadingData.receiveDocument === true,
                        })}
                        placeholder="Nhập số công văn"
                        type="text"
                        dir="auto"
                        onChange={this.handleChange}
                        autoFocus={true}
                        disabled={
                          !this.props.isLoadingData ||
                          (this.props.isLoadingData &&
                            this.props.isLoadingData.receiveDocument ===
                              true) ||
                          this.state.isFromSendDocument === true ||
                          // isManualDocument ||
                          this.state.isFromOtherUnit === true //||
                          //this.props.isUpdate
                        }
                        maxLength="50"
                      />
                      {this.state.error.soCongVan !== undefined && (
                        <div className="pt-form-helper-text">
                          {this.state.error.soCongVan}
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="col-xs-6 col-sm-6 col-md-6 form-item">
                    <label className="pt-label">
                      Ngày công văn
                      <span className="pt-text-muted required-input">*</span>
                    </label>
                    <div className="pt-form-content">
                      <div className="pt-input-group">
                        <DatePicker
                          isClearable={
                            this.state.isFromSendDocument === true ||
                            !this.props.isLoadingData ||
                            (this.props.isLoadingData &&
                              this.props.isLoadingData.receiveDocument ===
                                true) ||
                            // isManualDocument ||
                            this.state.isFromOtherUnit === true //||
                              ? //this.props.isUpdate
                                false
                              : true
                          }
                          readOnly={this.props.readOnly}
                          selected={this.state.ngayCongVan}
                          onChange={this.handleDateChange.bind(
                            this,
                            'ngayCongVan',
                            'dateToToString'
                          )}
                          value={this.state.dateToToString}
                          onChangeRaw={e =>
                            this.handleChangeRaw(
                              'dateToToString',
                              e.target.value
                            )
                          }
                          onBlur={e =>
                            this.focousOut(
                              'ngayCongVan',
                              'dateToToString',
                              e.target.value
                            )
                          }
                          format="DD/MM/YYYY"
                          showMonthDropdown
                          showYearDropdown
                          dropdownMode="select"
                          className="input-datepicker input-icon-datepicker"
                          placeholderText="Chọn ngày..."
                          disabled={
                            this.state.isFromSendDocument === true ||
                            !this.props.isLoadingData ||
                            (this.props.isLoadingData &&
                              this.props.isLoadingData.receiveDocument ===
                                true) ||
                            // isManualDocument ||
                            this.state.isFromOtherUnit === true //||
                            //this.props.isUpdate
                          }
                          opperPlacement="auto"
                          popperModifiers={{
                            offset: {
                              enabled: true,
                              offset: '5px, 10px',
                            },
                            preventOverflow: {
                              enabled: true,
                              escapeWithReference: false,
                              boundariesElement: 'viewport',
                            },
                          }}
                        />
                      </div>
                    </div>
                    {this.state.error.ngayCongVan !== undefined && (
                      <div className="pt-form-helper-text">
                        {this.state.error.ngayCongVan}
                      </div>
                    )}
                  </div>
                </div>
              </div>

              <div className="pt-form-group pt-intent-danger">
                <div className="row">
                  <div className="col-xs-6 col-sm-6 col-md-6 form-item">
                    <label className="pt-label">
                      Mã sổ lưu
                      <span className="pt-text-muted required-input">*</span>
                    </label>
                    <div className="pt-form-content">
                      <div className="pt-select">
                        <select
                          name="soLuuId"
                          onChange={this.handleChange}
                          value={this.state.soLuuId}
                          className={classnames({
                            'loading-input':
                              this.props.isLoadingData &&
                              this.props.isLoadingData.soLuuId === true,
                          })}
                          disabled={
                            (this.props.isLoadingData &&
                              this.props.isLoadingData.soLuuId === true) ||
                            (this.props.isLoadingData &&
                              this.props.isLoadingData.receiveDocument === true)
                            // isManualDocument
                            // this.state.isFromOtherUnit === true
                          }
                        >
                          {this.props.saveCodes.map((item, i) => (
                            <option key={i} value={item.soLuuId}>
                              {item.tenSoLuu}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>
                    {this.state.error.soLuuId !== undefined && (
                      <div className="pt-form-helper-text">
                        {this.state.error.soLuuId}
                      </div>
                    )}
                  </div>
                  <div className="col-xs-6 col-sm-6 col-md-6 form-item">
                    <label className="pt-label">
                      Số đến
                      <span className="pt-text-muted required-input">*</span>
                    </label>
                    <div className="pt-form-content">
                      <input
                        name="soDen"
                        value={this.state.soDen}
                        className={classnames('pt-input', {
                          'loading-input':
                            this.props.isLoadingData &&
                            this.props.isLoadingData.receiveDocument === true,
                        })}
                        placeholder="Nhập số đến"
                        type="text"
                        dir="auto"
                        onChange={this.handleChange}
                        disabled={
                          this.props.isLoadingData &&
                          this.props.isLoadingData.receiveDocument === true
                          // isManualDocument
                          // this.state.isFromOtherUnit === true
                        }
                      />
                      {this.state.error.soDen !== undefined && (
                        <div className="pt-form-helper-text">
                          {this.state.error.soDen}
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>

              <div className="pt-form-group pt-intent-danger">
                <div className="row">
                  <div className="col-xs-6 col-sm-6 col-md-6 form-item">
                    <label className="pt-label">
                      Độ khẩn
                      <span className="pt-text-muted required-input">*</span>
                    </label>
                    <div className="pt-form-content">
                      <div className="pt-select">
                        <select
                          className={classnames({
                            'loading-input':
                              this.props.isLoadingData &&
                              this.props.isLoadingData.doKhanId === true,
                          })}
                          name="doKhanId"
                          onChange={this.handleChange}
                          value={this.state.doKhanId}
                          disabled={
                            // this.state.isFromSendDocument === true || Cv đến từ CV đi: Khi LĐ chưa chỉ đạo: số công văn, ngày công văn, file không được sửa, còn lại được sửa
                            (this.props.isLoadingData &&
                              this.props.isLoadingData.doKhanId === true) ||
                            (this.props.isLoadingData &&
                              this.props.isLoadingData.receiveDocument === true)
                            // isManualDocument ||
                            // this.state.isFromOtherUnit === true // Note: Cv đến từ CV đi: Khi LĐ chưa chỉ đạo: số công văn, ngày công văn, file không được sửa, còn lại được sửa
                          }
                        >
                          {this.props.priorities.map((item, i) => (
                            <option key={i} value={item.doKhanId}>
                              {item.tenDoKhan}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>
                    {this.state.error.doKhanId !== undefined && (
                      <div className="pt-form-helper-text">
                        {this.state.error.doKhanId}
                      </div>
                    )}
                  </div>
                  <div className="col-xs-6 col-sm-6 col-md-6 form-item">
                    <label className="pt-label">Độ bảo mật</label>
                    <div className="pt-form-content">
                      <div className="pt-select">
                        <select
                          className={classnames({
                            'loading-input':
                              this.props.isLoadingData &&
                              this.props.isLoadingData.doBaoMatId === true,
                          })}
                          name="doBaoMatId"
                          onChange={this.handleChange}
                          value={this.state.doBaoMatId}
                          disabled={
                            // this.state.isFromSendDocument === true || Cv đến từ CV đi: Khi LĐ chưa chỉ đạo: số công văn, ngày công văn, file không được sửa, còn lại được sửa
                            (this.props.isLoadingData &&
                              this.props.isLoadingData.doBaoMatId === true) ||
                            (this.props.isLoadingData &&
                              this.props.isLoadingData.receiveDocument === true)
                            // isManualDocument ||
                            // this.state.isFromOtherUnit === true // Note: Cv đến từ CV đi: Khi LĐ chưa chỉ đạo: số công văn, ngày công văn, file không được sửa, còn lại được sửa
                          }
                        >
                          <option></option>
                          {this.props.securities.map((item, i) => (
                            <option key={i} value={item.doBaoMatId}>
                              {item.tenDoBaoMat}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="pt-form-group pt-intent-danger">
                <div className="row">
                  <div className="col-xs-6 col-sm-6 col-md-6 form-item margin-375">
                    <label className="pt-label">
                      Loại công văn
                      <span className="pt-text-muted required-input">*</span>
                    </label>
                    <div className="pt-form-content">
                      <div className="pt-select">
                        <select
                          className={classnames({
                            'loading-input':
                              this.props.isLoadingData &&
                              this.props.isLoadingData.doiTuongCongVanId ===
                                true,
                          })}
                          name="doiTuongCongVanId"
                          onChange={this.handleChange}
                          value={this.state.doiTuongCongVanId}
                          disabled={
                            (this.props.isLoadingData &&
                              this.props.isLoadingData.doiTuongCongVanId ===
                                true) ||
                            (this.props.isLoadingData &&
                              this.props.isLoadingData.receiveDocument === true)
                            // isManualDocument
                            // this.state.isFromOtherUnit === true
                          }
                        >
                          {this.props.objects
                            .filter(item => !!item.isActive)
                            .map((item, i) => (
                              <option key={i} value={item.doiTuongCongVanId}>
                                {item.tenDoiTuongCongVan}
                              </option>
                            ))}
                        </select>
                      </div>
                    </div>
                    {this.state.error.doiTuongCongVanId !== undefined && (
                      <div className="pt-form-helper-text">
                        {this.state.error.doiTuongCongVanId}
                      </div>
                    )}
                  </div>

                  <div className="col-xs-6 col-sm-6 col-md-6 form-item">
                    <label className="pt-label">
                      Cơ quan ban hành
                      <span className="pt-text-muted required-input">*</span>
                    </label>
                    <div className="pt-form-content">
                      <div className="pt-select">
                        <select
                          className={classnames({
                            'loading-input':
                              this.props.isLoadingData &&
                              this.props.isLoadingData.coQuanBanHanhId === true,
                          })}
                          name="coQuanBanHanhId"
                          onChange={this.handleChange}
                          value={this.state.coQuanBanHanhId}
                          disabled={
                            // this.state.isFromSendDocument === true || Cv đến từ CV đi: Khi LĐ chưa chỉ đạo: số công văn, ngày công văn, file không được sửa, còn lại được sửa
                            (this.props.isLoadingData &&
                              this.props.isLoadingData.coQuanBanHanhId ===
                                true) ||
                            (this.props.isLoadingData &&
                              this.props.isLoadingData.receiveDocument === true)
                            // isManualDocument ||
                            //this.state.isFromOtherUnit === true // Note: Cv đến từ CV đi: Khi LĐ chưa chỉ đạo: số công văn, ngày công văn, file không được sửa, còn lại được sửa
                          }
                        >
                          {this.props.relativeUnits.map((item, i) => (
                            <option key={i} value={item.donViId}>
                              {item.tenVietTat}
                            </option>
                          ))}
                          <option value="Khác">Khác</option>
                          {coQuanBanHanhNgoaiDs && coQuanBanHanhIdNgoaiDs && (
                            <option value={coQuanBanHanhIdNgoaiDs}>
                              {coQuanBanHanhNgoaiDs}
                            </option>
                          )}
                        </select>
                      </div>
                      {this.state.coQuanBanHanhId === 'Khác' && (
                        <span className="note-input">
                          Nên chọn từ danh sách để thống nhất đơn vị
                        </span>
                      )}
                      {this.state.error.coQuanBanHanhId !== undefined && (
                        <div className="pt-form-helper-text">
                          {this.state.error.coQuanBanHanhId}
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>

              {this.state.coQuanBanHanhId === 'Khác' && (
                <div className="pt-form-group pt-intent-danger">
                  <div className="row end-xs">
                    <div className="col-xs-12 col-sm-12 col-md-6 form-item">
                      <div className="pt-form-content">
                        <input
                          name="coQuanBanHanh"
                          value={this.state.coQuanBanHanh}
                          className="pt-input"
                          placeholder="Nhập cơ quan ban hành khác"
                          type="text"
                          dir="auto"
                          onChange={this.handleChange}
                          disabled={
                            // this.state.isFromSendDocument === true || Cv đến từ CV đi: Khi LĐ chưa chỉ đạo: số công văn, ngày công văn, file không được sửa, còn lại được sửa
                            (this.props.isLoadingData &&
                              this.props.isLoadingData.coQuanBanHanhId ===
                                true) ||
                            (this.props.isLoadingData &&
                              this.props.isLoadingData.receiveDocument === true)
                            // isManualDocument ||
                            // this.state.isFromOtherUnit === true // Note: Cv đến từ CV đi: Khi LĐ chưa chỉ đạo: số công văn, ngày công văn, file không được sửa, còn lại được sửa
                          }
                        />
                        {this.state.error.coQuanBanHanh !== undefined && (
                          <div className="pt-form-helper-text">
                            {this.state.error.coQuanBanHanh}
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              )}

              <div className="pt-form-group pt-intent-danger">
                <div className="row">
                  <div className="col-xs-12 col-sm-12 col-md-12 form-item">
                    <label className="pt-label">
                      Trích yếu
                      <span className="pt-text-muted required-input">*</span>
                    </label>
                    <div className="pt-form-content">
                      <textarea
                        name="trichYeu"
                        value={this.state.trichYeu}
                        rows="3"
                        className={classnames('pt-input pt-fill font-20', {
                          'loading-input':
                            this.props.isLoadingData &&
                            this.props.isLoadingData.receiveDocument === true,
                        })}
                        //className="pt-input pt-fill"
                        placeholder="Nội dung"
                        dir="auto"
                        onChange={this.handleChange}
                        disabled={
                          // this.state.isFromSendDocument === true || Cv đến từ CV đi: Khi LĐ chưa chỉ đạo: số công văn, ngày công văn, file không được sửa, còn lại được sửa
                          !this.props.isLoadingData ||
                          (this.props.isLoadingData &&
                            this.props.isLoadingData.receiveDocument === true)
                          // isManualDocument ||
                          //this.state.isFromOtherUnit === true // Note: Cv đến từ CV đi: Khi LĐ chưa chỉ đạo: số công văn, ngày công văn, file không được sửa, còn lại được sửa
                        }
                      ></textarea>
                      {this.state.error.trichYeu !== undefined && (
                        <div className="pt-form-helper-text">
                          {this.state.error.trichYeu}
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>

              <div className="pt-form-group pt-intent-danger">
                <div className="row">
                  {!this.props.isShow && (
                    <div className="col-xs-6 col-sm-6 col-md-6 form-item file-box">
                      <label className="pt-label">
                        File nội dung
                        <span className="pt-text-muted required-input">*</span>
                      </label>
                      <div className="pt-form-content">
                        <label className="pt-file-upload">
                          <input
                            type="file"
                            name="fileName"
                            onChange={this.handleFileChange}
                            onClick={event => {
                              event.target.value = null
                            }}
                            disabled={
                              this.state.isLoading ||
                              (this.props.isLoadingData &&
                                this.props.isLoadingData.receiveDocument ===
                                  true) ||
                              this.state.isFromSendDocument === true ||
                              // isManualDocument ||
                              this.state.isFromOtherUnit === true ||
                              this.props.isUpdate
                            }
                            accept=".pdf, .doc, .docx"
                          />
                          <span className="pt-file-upload-input">
                            {this.state.fileName.length === 0
                              ? 'Chọn file đính kèm'
                              : this.state.fileName}
                          </span>
                        </label>
                        {this.state.sizeFile && (
                          <p className="pt-form-helper-text pt-form-helper-infomation">
                            Kích thước file đã upload:
                            <span className="size-file">
                              {' '}
                              {Tool.Utils.getFileSize(this.state.sizeFile)}
                            </span>
                          </p>
                        )}
                        {this.state.error.fileName !== undefined && (
                          <p className="pt-form-helper-text">
                            {this.state.error.fileName}
                          </p>
                        )}
                        {this.state.isLoading && this.state.progress > 0 && (
                          <div className="progress-upload-content">
                            <span className="percent-content">
                              {this.state.progress}%
                            </span>
                            <div className="pt-progress-bar pt-intent-primary progress-bar-upload">
                              <div
                                className="pt-progress-meter"
                                style={{ width: this.state.progress + '%' }}
                              ></div>
                            </div>
                          </div>
                        )}
                      </div>
                      <p className="text-13-300-i opacity-05 mt5">
                        Hỗ trợ định dạng: .pdf, .doc, .docx
                      </p>
                    </div>
                  )}
                  <div className="col-xs-6 col-sm-6 col-md-6 form-item">
                    <label className="pt-label">Trình lãnh đạo</label>
                    <div className="pt-form-content">
                      <div className="pt-select">
                        <select
                          className={classnames({
                            'loading-input':
                              this.props.isLoadingData &&
                              this.props.isLoadingData.trinhLanhDaoId === true,
                          })}
                          name="trinhLanhDaoId"
                          onChange={this.handleChange}
                          value={this.state.trinhLanhDaoId}
                          disabled={
                            (this.props.isLoadingData &&
                              this.props.isLoadingData.trinhLanhDaoId ===
                                true) ||
                            (this.props.isLoadingData &&
                              this.props.isLoadingData.receiveDocument === true)
                            // (this.state.isHadLeaderUnit === true && this.state.isFromOtherUnit === true) // Note: Cv đến từ CV đi: Khi LĐ chưa chỉ đạo: số công văn, ngày công văn, file không được sửa, còn lại được sửa
                          }
                        >
                          <option></option>
                          {this.props.leaderDepartments.map((item, i) => (
                            <option key={i} value={item.phongBanId}>
                              {item.maPhongBan}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-xs-6 col-sm-6 col-md-6 mt10">
                    <label className="pt-label">File đính kèm</label>
                    <UploadFileInputSingle
                      key={2}
                      className="mt5"
                      maxSize={50}
                      fileTypeAllow={[
                        ...ducumentsType(),
                        ...imagesType(),
                        ...compressedsType(),
                      ]}
                      fileTypeAllowShortcut={[
                        '.pdf',
                        '.docx',
                        '.doc',
                        '.xls',
                        '.xlsx',
                        '.jpeg',
                        '.jpg',
                        '.png',
                        '.ppt',
                        '.pptx',
                        '.zip',
                      ]}
                      textSumary={`Hỗ trợ định dạng: ${[
                        '.pdf',
                        '.docx',
                        '.doc',
                        '.xls',
                        '.xlsx',
                        '.jpeg',
                        '.jpg',
                        '.png',
                        '.ppt',
                        '.pptx',
                        '.zip',
                      ].join(', ')}`}
                      dataSource={fileDinhKem?.map(e => ({
                        name: e?.tenFile || e?.name,
                        size: e?.kichThuoc || e?.size,
                        ...(e?.nguoiTao && e?.nguoiTao !== auth?.user?.username
                          ? { showClose: false }
                          : {}),
                      }))}
                      onChange={this.onSetFileDinhKem}
                      onRemove={this.onRemoveFileDinhKem}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="buttton-action-footer">
            <Button
              onClick={this.props.onClickCancle}
              className="pt-button btn-cancel"
              disabled={
                Object.values(this.props.isLoadingData).find(
                  item => item === true
                ) !== undefined || this.state.isLoading
              }
            >
              <span className=" pt-icon icon-back"></span>
              <span className="text-content">Quay lại</span>
            </Button>
            <Button
              className="pt-button btn-blue-color"
              onClick={this.handleSubmit}
              loading={this.state.isLoading}
              disabled={
                Object.values(this.props.isLoadingData).find(
                  item => item === true
                ) !== undefined
              }
            >
              <span className="text-content">Lưu</span>
              <span className="pt-icon icon-save"></span>
            </Button>
          </div>
        </div>
        {/*<PopupConfirm
          isOpen={this.state.isOpenPopup}
          onClose={this.handleClickPopup}
          title={this.props.isUpdate ? POPUP.HUY_SUA_CONG_VAN_DEN : POPUP.HUY_TAO_CONG_VAN_DEN}
          text={POPUP.TEXT_BUTTON_SUBMIT}
          onSubmit={this.handleClickCancel}
        >
          {POPUP.TEXT_BODY}
        </PopupConfirm>*/}
      </div>
    )
  }
}

export default InputForm
