import React, { memo } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { withRouter } from 'react-router-dom'
import moment from 'moment'

import { FILE_URL } from 'constants/Api'
import { Utils } from 'helpers'
import { MasterLayout } from 'components/layout'
import Title from 'components/ui/title'
import { Loading } from 'components/newCommon'
import UIButton from 'components/ui/button'
import UIFile from 'components/ui/file'
import { fetchNotificationItem } from 'actions/notifications'

import useDetail from './hooks/use-detail'

const DetailGeneralNotifications = ({ auth, history, match, actions }) => {
  const token = auth?.token?.accessToken
  const id = match.params?.id
  const { isFetching, detail } = useDetail({
    id,
    actions,
  })

  return (
    <MasterLayout typeSidebar="notification">
      <div className="mt5">
        <Title
          name={'Chi tiết thông báo'}
          icon="icon2-bell"
          isGoBack
          history={history}
        />
        <div className="border mt10">
          <div className="bg-white p20">
            {isFetching ? (
              <Loading column={3} />
            ) : (
              <div>
                <p className="text-14-600-21">Tiêu đề</p>
                <div className="text-14-300-21">{detail?.tieuDe}</div>
                <p className="text-14-600-21 mt20">Thời gian tạo</p>
                <div className="text-14-300-21">
                  {detail?.ngayTao
                    ? moment(detail?.ngayTao).format('DD/MM/YYYY HH:mm')
                    : ''}
                </div>
                <p className="text-14-600-21 mt20">Nội dung</p>
                <div
                  className="text-14-300-21 content-editor"
                  dangerouslySetInnerHTML={{ __html: detail?.noiDung }}
                />
                <p className="text-14-600-21 mt20">File đính kèm</p>
                <div className="text-14-300-21">
                  {detail?.dsFileDinhKem?.map((item, index) => {
                    const size = Utils.getFileSize(item?.size)
                      ? ` - ${Utils.getFileSize(item?.size)}`
                      : ''
                    return (
                      <UIFile
                        key={index}
                        className="mt5"
                        name={`${item?.name}${size}`}
                        extension={item?.extension}
                        onClick={() =>
                          window.open(
                            `${FILE_URL}/api/file/get/${item?.id}?BearerToken=${token}`
                          )
                        }
                      />
                    )
                  })}
                </div>
              </div>
            )}
          </div>
          <div
            className={
              'd-flex align-items-center justify-content-center gap-15 bg-white border-radius-4 border-top ph15 pv20'
            }
          >
            <UIButton
              icon="icon-back"
              text="QUAY LẠI"
              theme="grey-border"
              color="text-grey"
              textSize={12}
              isReverse
              onClick={() => history.goBack()}
            />
            <UIButton
              icon="icon-edit-v2"
              text="CHỈNH SỬA"
              color="blue"
              textSize={12}
              onClick={() =>
                history.push(`/he-thong/thong-bao/chinh-sua/${id}`)
              }
            />
          </div>
        </div>
      </div>
    </MasterLayout>
  )
}

const mapStateToProps = state => {
  return {
    auth: state.auth,
  }
}

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(
    {
      fetchNotificationItem,
    },
    dispatch
  ),
})

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(memo(DetailGeneralNotifications))
)
