import React, { Component } from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import { Intent, Button, Collapse } from '@blueprintjs/core'
import difference from 'lodash/difference'
import uniq from 'lodash/uniq'
import { DepartmentPerform } from '../common/'
import * as MESSAGE from '../../constants/MessageForm'
import moment from 'moment'
import DatePicker from 'react-datepicker'
import * as Tool from '../../helpers'
import UICheckbox from 'components/ui/checkbox'
import UIDepartmentAndPersonalTree from 'components/ui/department-and-personal-tree'

class AdditionalAssignment extends Component {
  static propTypes = {
    onClickGoBack: PropTypes.func.isRequired,
    departments: PropTypes.array.isRequired,
    units: PropTypes.array.isRequired,
    informationSteering: PropTypes.object,
    actions: PropTypes.object.isRequired,
    onSubmit: PropTypes.func.isRequired,
  }

  constructor(props) {
    super(props)
    this.handleSubmit = this.handleSubmit.bind(this)
    this.handleClickArrow = this.handleClickArrow.bind(this)
    this.handleDateChange = this.handleDateChange.bind(this)
    this.handleGetValueGridCheckBox = this.handleGetValueGridCheckBox.bind(this)
    this.handleChangeRaw = this.handleChangeRaw.bind(this)
    this.focousOut = this.focousOut.bind(this)
  }

  state = {
    isOpen: false,
    isOpenPopup: false,
    congVanDenId: '',
    noiDung: '',
    dsPhoiHop: [],
    dsXemDeBiet: [],
    dsDonViPhoiHop: [],
    dsDonViXemDeBiet: [],
    hanGiaiQuyet: undefined,
    departmentSelections: [],
    receivingUnitSelections: [],
    // Phòng ban
    disabledListCompositiveDepartments: [],
    disabledListOnlySeeDepartments: [],
    selectedCompositiveDepartments: [],
    selectedOnlySeeDepartments: [],
    // Đơn vị
    disabledListCompositiveUnits: [],
    disabledListOnlySeeUnits: [],
    selectedCompositiveUnits: [],
    selectedOnlySeeUnits: [],
    chiDaoId: '',
    isLoading: false,
    dealineToString: '',

    isDonViCha: true,
    departmentAndPersonalTreeData: [],
    bSPhoiHopData: [],
    xemDeBietData: [],
    baoCaoData: [],
    dataOpen: [],
  }

  handleClickArrow() {
    this.setState({ isOpen: !this.state.isOpen })
  }

  handleDateChange = (name, nameToString, e) => {
    let value = e ? e : undefined
    let valueToString = this.state[nameToString]
    if (value) {
      valueToString = moment(e).format('DD/MM/YYYY')
    } else {
      valueToString = ''
    }
    this.setState({
      [name]: value,
      [nameToString]: valueToString,
    })
  }

  handleChangeRaw(name, value) {
    this.setState({ [name]: value })
  }

  focousOut(name, nameToString, value) {
    if (
      Tool.Utils.isValidDate(value, {
        after: {
          date: moment(moment().format('YYYY-MM-DD')),
        },
      })
    ) {
      return
    }

    this.setState({
      [nameToString]: '',
      [name]: undefined,
    })
  }

  handleSubmit = () => {
    if (
      (!this.state.noiDung || this.state.noiDung.trim().length === 0) &&
      this.state.bSPhoiHopData.length === 0 &&
      this.state.xemDeBietData.length === 0 &&
      this.state.baoCaoData.length === 0 &&
      this.state.dsDonViPhoiHop.length === 0 &&
      this.state.dsDonViXemDeBiet.length === 0
    ) {
      return this.props.onSubmit(
        'ChiDaoBoSung',
        MESSAGE.TEXT_MESSAGE_MIN_INPUT,
        Intent.WARNING,
        false,
        null
      )
    }

    this.setState({ isLoading: true })
    let hanGiaiQuyet = null
    if (
      this.state.hanGiaiQuyet &&
      this.state.hanGiaiQuyet.toString().length > 0
    ) {
      hanGiaiQuyet = moment(this.state.hanGiaiQuyet)
        .set({ hour: 0, minute: 0, second: 0, millisecond: 0 })
        .format()
    }
    const dataRequest = {
      cong_van_den_id: this.state.congVanDenId,
      noi_dung: this.state.noiDung,
      ds_phoi_hop: [
        ...(this.state?.dsPhoiHop || []),
        ...(this.state?.bSPhoiHopData || []),
      ],
      ds_xem_de_biet: [
        ...(this.state?.dsXemDeBiet || []),
        ...(this.state?.xemDeBietData || []),
      ],
      ds_don_vi_phoi_hop: this.state.dsDonViPhoiHop,
      ds_don_vi_xem_de_biet: this.state.dsDonViXemDeBiet,
      nv_xem_bao_cao: this.state?.baoCaoData || [],
      han_giai_quyet: hanGiaiQuyet,
    }
    this.props.actions
      .receiveDocumentSendAdditionalAssignment(
        this.props.congVanDenId,
        dataRequest
      )
      .then(res => {
        if (res.payload && res.payload.status === 200) {
          this.props.onSubmit(
            'ChiDaoBoSung',
            MESSAGE.TOATS_MESSAGE_SUCCESS,
            Intent.SUCCESS,
            true,
            null
          )
        } else {
          this.setState({ isLoading: false })
          this.props.onSubmit(
            'ChiDaoBoSung',
            MESSAGE.TOATS_MESSAGE_FAIL,
            Intent.DANGER,
            false,
            null
          )
        }
      })
  }

  handleInputChange = e => {
    if (e.target.type === 'checkbox') {
      this.setState({ [e.target.name]: e.target.checked })
    } else {
      this.setState({ [e.target.name]: e.target.value })
    }
  }

  handleGetValueGridCheckBox = (type, data) => {
    if (!data || !Object.keys(data).length) {
      return
    }

    switch (type) {
      case 'units':
        return this.setState({
          dsDonViPhoiHop: data.dsPhoiHop,
          dsDonViXemDeBiet: data.dsXemDeBiet,
        })

      default:
        return this.setState({
          dsPhoiHop: data.dsPhoiHop,
          dsXemDeBiet: data.dsXemDeBiet,
        })
    }
  }

  getAllChucDanhIds = data => {
    const chucDanhIds = []
    data.forEach(phongBan => {
      phongBan.dsNhanVien.forEach(nhanVien => {
        if (nhanVien.chucDanhId) {
          chucDanhIds.push(nhanVien.chucDanhId)
        }
      })
    })
    return chucDanhIds
  }

  onChangeCheckbox =
    (name, value, isCheckAll = false) =>
    record => {
      if (name === 'BSPH') {
        if (isCheckAll) {
          if (value) {
            this.setState({
              bSPhoiHopData: uniq(
                difference(
                  this.state.departmentAndPersonalTreeData?.map(
                    item => item?.phongBanId
                  ),
                  this.state?.disabledListCompositiveDepartments
                )
              ),
              xemDeBietData: [],
            })
          } else {
            this.setState({
              bSPhoiHopData: [],
            })
          }
        } else {
          const isPersonal = record?.chucDanhId ? true : false
          if (value) {
            if (isPersonal) {
              const phongBanPhoiHop =
                this.state.bSPhoiHopData?.filter(
                  elm =>
                    elm.phongBanId === record.phongBanId && !elm?.chucDanhId
                ) || []
              this.setState({
                bSPhoiHopData: [
                  ...this.state.bSPhoiHopData?.filter(
                    elm =>
                      !phongBanPhoiHop.some(
                        e => e?.phongBanId === elm?.phongBanId
                      )
                  ),
                  record,
                ],
              })

              let xemDeBietDataTemp = [...this.state.xemDeBietData]
              const phongBanXemDeBiet =
                this.state.xemDeBietData?.filter(
                  elm =>
                    elm.phongBanId === record.phongBanId && !elm?.chucDanhId
                ) || []
              if (phongBanXemDeBiet?.length > 0 ?? false) {
                xemDeBietDataTemp = xemDeBietDataTemp?.filter(
                  elm =>
                    !phongBanXemDeBiet.some(
                      e => e?.phongBanId === elm?.phongBanId
                    )
                )
              }
              const nhanVienXemDeBiet =
                this.state.xemDeBietData?.filter(
                  elm =>
                    elm.phongBanId === record.phongBanId &&
                    elm?.chucDanhId === record?.chucDanhId
                ) || []
              if (nhanVienXemDeBiet?.length > 0 ?? false) {
                xemDeBietDataTemp = xemDeBietDataTemp?.filter(
                  elm =>
                    !nhanVienXemDeBiet.some(
                      e => e?.chucDanhId === elm?.chucDanhId
                    )
                )
              }
              this.setState({
                xemDeBietData: xemDeBietDataTemp,
              })
            } else {
              const nhanVienPhongBanPhoiHop =
                this.state.bSPhoiHopData?.filter(
                  elm => elm.phongBanId === record.phongBanId && elm?.chucDanhId
                ) || []
              let phoiHopDataTemp = [...this.state.bSPhoiHopData]
              if (nhanVienPhongBanPhoiHop?.length > 0 ?? false) {
                phoiHopDataTemp = phoiHopDataTemp?.filter(
                  elm =>
                    !nhanVienPhongBanPhoiHop.some(
                      e => e?.chucDanhId === elm?.chucDanhId
                    )
                )
              }

              const phongBanXemDeBiet =
                this.state.xemDeBietData?.filter(
                  elm =>
                    elm.phongBanId === record.phongBanId && !elm?.chucDanhId
                ) || []
              let xemDeBietDataTemp = [...this.state.xemDeBietData]
              if (phongBanXemDeBiet?.length > 0 ?? false) {
                xemDeBietDataTemp = xemDeBietDataTemp?.filter(
                  elm =>
                    !phongBanXemDeBiet.some(
                      e => e?.phongBanId === elm?.phongBanId
                    )
                )
              }

              const nhanVienPhongBanXemDeBiet =
                this.state.xemDeBietData?.filter(
                  elm => elm.phongBanId === record.phongBanId && elm?.chucDanhId
                ) || []
              if (nhanVienPhongBanXemDeBiet?.length > 0 ?? false) {
                xemDeBietDataTemp = xemDeBietDataTemp?.filter(
                  elm =>
                    !nhanVienPhongBanXemDeBiet.some(
                      e => e?.chucDanhId === elm?.chucDanhId
                    )
                )
              }

              this.setState({
                bSPhoiHopData: [...phoiHopDataTemp, record],
                xemDeBietData: xemDeBietDataTemp,
              })
            }
          } else {
            this.setState({
              bSPhoiHopData: this.state.bSPhoiHopData?.filter(item =>
                isPersonal
                  ? item?.chucDanhId !== record?.chucDanhId
                  : item?.phongBanId !== record?.phongBanId
              ),
            })
          }
        }
      } else if (name === 'XDB') {
        if (isCheckAll) {
          if (value) {
            this.setState({
              bSPhoiHopData: [],
              xemDeBietData: uniq(
                difference(
                  this.state.departmentAndPersonalTreeData?.map(
                    item => item?.phongBanId
                  ),
                  this.state?.disabledListOnlySeeDepartments
                )
              ),
            })
          } else {
            this.setState({
              xemDeBietData: [],
            })
          }
        } else {
          const isPersonal = record?.chucDanhId ? true : false
          if (value) {
            if (isPersonal) {
              let phoiHopDataTemp = [...this.state.bSPhoiHopData]
              const phongBanPhoiHop =
                this.state.bSPhoiHopData?.filter(
                  elm =>
                    elm.phongBanId === record.phongBanId && !elm?.chucDanhId
                ) || []
              if (phongBanPhoiHop?.length > 0 ?? false) {
                phoiHopDataTemp = phoiHopDataTemp?.filter(
                  elm =>
                    !phongBanPhoiHop.some(
                      e => e?.phongBanId === elm?.phongBanId
                    )
                )
              }

              const nhanVienPhongBanPhoiHop =
                this.state.bSPhoiHopData?.filter(
                  elm =>
                    elm.phongBanId === record.phongBanId &&
                    elm?.chucDanhId === record?.chucDanhId
                ) || []
              if (nhanVienPhongBanPhoiHop?.length > 0 ?? false) {
                phoiHopDataTemp = phoiHopDataTemp?.filter(
                  elm =>
                    !nhanVienPhongBanPhoiHop.some(
                      e => e?.chucDanhId === elm?.chucDanhId
                    )
                )
              }

              const phongBanXemDeBiet =
                this.state.xemDeBietData?.filter(
                  elm =>
                    elm.phongBanId === record.phongBanId && !elm?.chucDanhId
                ) || []

              this.setState({
                bSPhoiHopData: phoiHopDataTemp,
                xemDeBietData: [
                  ...this.state.xemDeBietData?.filter(
                    elm =>
                      !phongBanXemDeBiet.some(
                        e => e?.phongBanId === elm?.phongBanId
                      )
                  ),
                  record,
                ],
              })
            } else {
              let phoiHopDataTemp = [...this.state.bSPhoiHopData]
              const phongBanPhoiHop =
                this.state.bSPhoiHopData?.filter(
                  elm =>
                    elm.phongBanId === record.phongBanId && !elm?.chucDanhId
                ) || []
              if (phongBanPhoiHop?.length > 0 ?? false) {
                phoiHopDataTemp = phoiHopDataTemp?.filter(
                  elm =>
                    !phongBanPhoiHop.some(
                      e => e?.phongBanId === elm?.phongBanId
                    )
                )
              }

              const nhanVienPhongBanPhoiHop =
                this.state.bSPhoiHopData?.filter(
                  elm => elm.phongBanId === record.phongBanId && elm?.chucDanhId
                ) || []
              if (nhanVienPhongBanPhoiHop?.length > 0 ?? false) {
                phoiHopDataTemp = phoiHopDataTemp?.filter(
                  elm =>
                    !nhanVienPhongBanPhoiHop.some(
                      e => e?.chucDanhId === elm?.chucDanhId
                    )
                )
              }

              let xemDeBietDataTemp = [...this.state.xemDeBietData]
              const nhanVienPhongBanXemDeBiet =
                this.state.xemDeBietData?.filter(
                  elm => elm.phongBanId === record.phongBanId && elm?.chucDanhId
                ) || []
              if (nhanVienPhongBanXemDeBiet?.length > 0 ?? false) {
                xemDeBietDataTemp = xemDeBietDataTemp?.filter(
                  elm =>
                    !nhanVienPhongBanXemDeBiet.some(
                      e => e?.chucDanhId === elm?.chucDanhId
                    )
                )
              }

              this.setState({
                bSPhoiHopData: phoiHopDataTemp,
                xemDeBietData: [...xemDeBietDataTemp, record],
              })
            }
          } else {
            this.setState({
              xemDeBietData: this.state.xemDeBietData?.filter(item =>
                isPersonal
                  ? item?.chucDanhId !== record?.chucDanhId
                  : item?.phongBanId !== record?.phongBanId
              ),
            })
          }
        }
      } else if (name === 'BC') {
        if (isCheckAll) {
          const chucDanhIds = this.getAllChucDanhIds(
            this.state.departmentAndPersonalTreeData?.filter(
              e => e?.phongBanLanhDao
            )
          )
          if (value) {
            this.setState({
              baoCaoData: uniq(
                difference(chucDanhIds, this.state?.disabledBaoCaoData)
              ),
            })
            // Kiểm tra dữ liệu và mở tree item
            const dsPhongBanCoNhanVienDuocChon = []
            this.state.departmentAndPersonalTreeData.forEach(e => {
              if (
                e?.dsNhanVien?.findIndex(f =>
                  chucDanhIds.some(s => s === f.chucDanhId)
                ) !== -1
              ) {
                dsPhongBanCoNhanVienDuocChon.push(e)
              }
            })
            if (dsPhongBanCoNhanVienDuocChon?.length > 0 ?? false) {
              this.setState({
                dataOpen: dsPhongBanCoNhanVienDuocChon.map(e => e.phongBanId),
              })
            }
          } else {
            this.setState({
              baoCaoData: [],
            })
          }
        } else {
          if (value) {
            this.setState({
              baoCaoData: [...this.state.baoCaoData, record.chucDanhId],
            })
          } else {
            this.setState({
              baoCaoData: this.state.baoCaoData?.filter(
                item => item !== record.chucDanhId
              ),
            })
          }
        }
      }
    }

  onClickArrow = phongBanId => {
    this.setState(prev => ({
      ...prev,
      dataOpen: prev?.dataOpen.includes(phongBanId)
        ? prev?.dataOpen.filter(item => item !== phongBanId)
        : [...prev?.dataOpen, phongBanId],
    }))
  }

  renderColumn = () => {
    return [
      {
        className: 'min-width-100 flex-basis-60',
        headerRender: () => {
          return <p className="text-12-500-16">Phòng ban</p>
        },
        bodyRender: elm => {
          const isOpen = this.state.dataOpen?.includes(elm.phongBanId)
          return (
            <div className="d-flex align-items-center text-13-300-16">
              <div className="width-20">
                {elm.maPhongBan && (
                  <span
                    onClick={() => this.onClickArrow(elm.phongBanId)}
                    className={classnames('arrow-icon', {
                      'pt-icon pt-icon-chevron-up': isOpen,
                      'pt-icon pt-icon-chevron-down': !isOpen,
                    })}
                  ></span>
                )}
              </div>
              {elm.maPhongBan || elm.tenNhanVien}
            </div>
          )
        },
      },
      {
        className: 'min-width-80',
        headerRender: () => {
          // Bổ sung phối hợp
          return (
            <div className="d-flex gap-2 align-items-center">
              <span className="text-12-500-16">BSPH</span>
            </div>
          )
        },
        bodyRender: record => {
          const isCheckBSPH = this.state.bSPhoiHopData?.includes(
            record?.phongBanId
          )
          const isDisabled =
            this.state?.disabledListCompositiveDepartments?.some(
              e => e === record?.phongBanId
            )
          const isOldChecked = this.state?.selectedCompositiveDepartments?.some(
            e => e === record?.phongBanId
          )
          return (
            <div onClick={e => e.stopPropagation()}>
              <UICheckbox
                className="mt0"
                checked={isCheckBSPH || isOldChecked}
                isDisabled={isDisabled}
                onChange={e =>
                  this.onChangeCheckbox('BSPH', e.target?.checked)(record)
                }
              />
            </div>
          )
        },
      },
      {
        className: 'min-width-60',
        headerRender: () => {
          return (
            <div className="d-flex gap-2 align-items-center">
              <span className="text-12-500-16">XĐB</span>
            </div>
          )
        },
        bodyRender: record => {
          const isCheckXDB = this.state.xemDeBietData?.includes(
            record?.phongBanId
          )
          const isDisabled = this.state?.disabledListOnlySeeDepartments?.some(
            e => e === record?.phongBanId
          )
          const isOldChecked = this.state?.selectedOnlySeeDepartments?.some(
            e => e === record?.phongBanId
          )
          return (
            <div onClick={e => e.stopPropagation()}>
              <UICheckbox
                checked={isCheckXDB || isOldChecked}
                isDisabled={isDisabled}
                onChange={e =>
                  this.onChangeCheckbox('XDB', e.target?.checked)(record)
                }
              />
            </div>
          )
        },
      },
      ...(this.state.isDonViCha
        ? [
            {
              headerRender: () => {
                const chucDanhIds = this.getAllChucDanhIds(
                  this.state.departmentAndPersonalTreeData?.filter(
                    e => e?.phongBanLanhDao
                  )
                )
                const isCheckAllBC = chucDanhIds?.every(chucDanhId =>
                  [
                    ...this.state.baoCaoData,
                    ...this.state?.disabledBaoCaoData,
                  ]?.includes(chucDanhId)
                )
                return (
                  <div className="d-flex gap-2 align-items-center">
                    <UICheckbox
                      checked={isCheckAllBC}
                      onChange={e =>
                        this.onChangeCheckbox('BC', e.target?.checked, true)()
                      }
                    />
                    <span className="text-12-500-16">BC</span>
                  </div>
                )
              },
              bodyRender: record => {
                const isCheckBC = this.state.baoCaoData?.includes(
                  record?.chucDanhId
                )
                const isDisabled = this.state?.disabledBaoCaoData?.some(
                  e => e === record?.chucDanhId
                )
                return record?.phongBanLanhDao && record?.nhanVienId ? (
                  <div onClick={e => e.stopPropagation()}>
                    <UICheckbox
                      checked={isCheckBC}
                      isDisabled={isDisabled}
                      onChange={e =>
                        this.onChangeCheckbox('BC', e.target?.checked)(record)
                      }
                    />
                  </div>
                ) : (
                  <></>
                )
              },
            },
          ]
        : []),
    ]
  }

  getUnitItem = () => {
    this.props.actions.getUnitItem(this.props.auth?.mainUnitId).then(res => {
      this.setState({ isDonViCha: !res?.payload?.data?.result?.donViCha })
    })
  }

  componentDidMount() {
    let dsPhongBanXemDeBiet = [] // Danh sach phong ban disbled cot xem de biet
    let dsPhongBanPhoiHop = [] // Danh sach phong ban disbled cot phoi hop
    let selectedCompositiveDepartments = []
    let selectedOnlySeeDepartments = []

    let dsDonViXemDeBiet = [] // Danh sach don vi disbled cot xem de biet
    let dsDonViPhoiHop = [] // Danh sach don vi disbled cot phoi hop
    let selectedCompositiveUnits = []
    let selectedOnlySeeUnits = []
    let isOpen = false
    let dsBSPhoiHopData = []
    let dsXemDeBietData = []
    let dsBaoCaoData = []
    if (this.props.informationSteering) {
      // Phòng ban
      if (
        this.props.informationSteering.chuTri &&
        this.props.informationSteering.chuTri.phongBanId
      ) {
        dsPhongBanXemDeBiet.push(
          this.props.informationSteering.chuTri.phongBanId
        )
        dsPhongBanPhoiHop.push(this.props.informationSteering.chuTri.phongBanId)
      }

      if (this.props.informationSteering.phoiHop) {
        this.props.informationSteering.phoiHop.forEach(item => {
          dsXemDeBietData.push(item.phongBanId)
          dsBSPhoiHopData.push(item.phongBanId)
          selectedCompositiveDepartments.push(item.phongBanId)
        })
      }

      if (this.props.informationSteering.xemDeBiet) {
        this.props.informationSteering.xemDeBiet.forEach(item => {
          dsXemDeBietData.push(item.phongBanId)
          selectedOnlySeeDepartments.push(item.phongBanId)
        })
      }

      // Đơn vị
      if (
        this.props.informationSteering.donViChuTri &&
        this.props.informationSteering.donViChuTri.donViId
      ) {
        dsDonViXemDeBiet.push(
          this.props.informationSteering.donViChuTri.donViId
        )
        dsDonViPhoiHop.push(this.props.informationSteering.donViChuTri.donViId)
        isOpen = true
      }

      if (this.props.informationSteering.donViPhoiHop) {
        this.props.informationSteering.donViPhoiHop.forEach(item => {
          dsDonViXemDeBiet.push(item.donViId)
          dsDonViPhoiHop.push(item.donViId)
          selectedCompositiveUnits.push(item.donViId)
        })
        if (this.props.informationSteering.donViPhoiHop.length > 0) {
          isOpen = true
        }
      }

      if (this.props.informationSteering.donViXemDeBiet) {
        this.props.informationSteering.donViXemDeBiet.forEach(item => {
          dsDonViXemDeBiet.push(item.donViId)
          selectedOnlySeeUnits.push(item.donViId)
        })
        if (this.props.informationSteering.donViXemDeBiet.length > 0) {
          isOpen = true
        }
      }

      if (this.props.informationSteering?.dsXemBaoCao) {
        this.props.informationSteering.dsXemBaoCao.forEach(item => {
          dsBaoCaoData.push(item.chucDanhId)
        })
      }
    }

    const departmentSelections = []
    this.props.departments.forEach((item, i) => {
      departmentSelections.push({
        content: item.maPhongBan,
        value: item.phongBanId,
      })
    })

    const receivingUnitSelections = []
    this.props.units.forEach(item => {
      receivingUnitSelections.push({
        content: item.tenVietTat,
        value: item.donViId,
      })
    })

    this.setState({
      disabledListOnlySeeDepartments: [
        ...dsPhongBanXemDeBiet,
        ...dsXemDeBietData,
      ],
      disabledListCompositiveDepartments: [
        ...dsPhongBanPhoiHop,
        ...dsBSPhoiHopData,
      ],
      selectedCompositiveDepartments,
      selectedOnlySeeDepartments,
      disabledListOnlySeeUnits: dsDonViXemDeBiet,
      disabledListCompositiveUnits: dsDonViPhoiHop,
      selectedCompositiveUnits,
      selectedOnlySeeUnits,
      congVanDenId: this.props.congVanDenId,
      departmentSelections,
      receivingUnitSelections,
      isOpen,
      disabledBaoCaoData: dsBaoCaoData,
    })

    // Kiểm tra thuộc đơn vị cha hay không?
    this.getUnitItem()
    this.setState({
      departmentAndPersonalTreeData: this.props?.departmentAndPersonalTree?.map(
        elm => ({
          ...elm,
          dsNhanVien: elm?.dsNhanVien?.map(nv => ({
            ...nv,
            phongBanId: elm?.phongBanId,
            phongBanLanhDao: elm?.phongBanLanhDao,
          })),
        })
      ),
    })
  }

  render() {
    return (
      <div className="container-form-action">
        <div className="heading">
          <span className="click-go-back" onClick={this.props.onClickGoBack}>
            <span className="pt-icon pt-icon-arrow-left pull-left"></span>
          </span>
          <h4 className="name-heading">Chỉ đạo bổ sung</h4>
        </div>

        <div className="form-container container-detail">
          <div className="pt-form-group">
            <div className="row full-row">
              <div className="col-xs-12 col-md-12 col-form-item">
                <label className="pt-label">Nội dung chỉ đạo bổ sung</label>
              </div>
              <div className="col-xs-12 col-md-12 col-form-item">
                <div className="pt-form-content">
                  <textarea
                    name="noiDung"
                    value={this.state.noiDung}
                    onChange={this.handleInputChange}
                    rows="1"
                    className="pt-input pt-fill"
                    dir="auto"
                    autoFocus={true}
                  ></textarea>
                </div>
              </div>
            </div>
          </div>

          <div className="pt-form-group">
            <label className="pt-label pt0 mb0">Phòng ban thực hiện</label>
            <div>
              <UIDepartmentAndPersonalTree
                keyChildren={'dsNhanVien'}
                header={this.renderColumn()}
                dataSource={this.state.departmentAndPersonalTreeData}
                dataOpen={this.state.dataOpen}
              />
            </div>
            {/* <DepartmentPerform
              options={this.state.departmentSelections}
              onSubmit={this.handleGetValueGridCheckBox.bind(
                this,
                'departments'
              )}
              dsPhoiHop={this.state.dsPhoiHop}
              dsXemDeBiet={this.state.dsXemDeBiet}
              chuTriId={this.state.chuTriId}
              disabledListCompositive={
                this.state.disabledListCompositiveDepartments
              }
              disabledListOnlySee={this.state.disabledListOnlySeeDepartments}
              selectedCompositiveDepartments={
                this.state.selectedCompositiveDepartments
              }
              selectedOnlySeeDepartments={this.state.selectedOnlySeeDepartments}
              titles={['CT', 'BSPH', 'XĐB']}
              isHasRadio={false}
              type="Phòng ban"
            /> */}
          </div>

          {/* Đơn vị nhận */}
          {this.state.receivingUnitSelections &&
            this.state.receivingUnitSelections.length > 0 && (
              <div className="selectbox-group mb0">
                <div className="d-flex gap-5 align-items-center disabled-checkbox">
                  <label className="pt-label p0 m0">
                    <span
                      className="pull-left name_group name_group_unit"
                      onClick={this.handleClickArrow}
                    >
                      Đơn vị nhận
                    </span>
                  </label>
                  <span
                    onClick={this.handleClickArrow}
                    className={classnames('name_group_unit', {
                      'pt-icon pt-icon-chevron-up': this.state.isOpen,
                      'pt-icon pt-icon-chevron-down': !this.state.isOpen,
                    })}
                  ></span>
                </div>
                <Collapse isOpen={this.state.isOpen} className="mt5">
                  <div className="pt-form-group">
                    <DepartmentPerform
                      options={this.state.receivingUnitSelections}
                      onSubmit={this.handleGetValueGridCheckBox.bind(
                        this,
                        'units'
                      )}
                      dsPhoiHop={this.state.dsDonViPhoiHop}
                      dsXemDeBiet={this.state.dsDonViXemDeBiet}
                      chuTriId={this.state.donViChuTriId}
                      disabledListCompositive={
                        this.state.disabledListCompositiveUnits
                      }
                      disabledListOnlySee={this.state.disabledListOnlySeeUnits}
                      selectedCompositiveDepartments={
                        this.state.selectedCompositiveUnits
                      }
                      selectedOnlySeeDepartments={
                        this.state.selectedOnlySeeUnits
                      }
                      titles={['CT', 'BSPH', 'XĐB']}
                      isHasRadio={false}
                      type="Đơn vị"
                    />
                  </div>
                </Collapse>
              </div>
            )}

          <div className="pt-form-group pt-inline resolve-date pt-intent-danger">
            <div className="row full-row">
              <div className="col-xs-12 col-sm-12">
                <label className="pt-label">Hạn giải quyết</label>
                <div className="pt-form-content w-100">
                  <div className="pt-input-group pt-large">
                    <DatePicker
                      isClearable={this.state.dealineToString ? true : false}
                      readOnly={this.props.readOnly}
                      selected={this.state.hanGiaiQuyet}
                      onChange={this.handleDateChange.bind(
                        this,
                        'hanGiaiQuyet',
                        'dealineToString'
                      )}
                      value={this.state.dealineToString}
                      onChangeRaw={e =>
                        this.handleChangeRaw('dealineToString', e.target.value)
                      }
                      onBlur={e =>
                        this.focousOut(
                          'hanGiaiQuyet',
                          'dealineToString',
                          e.target.value
                        )
                      }
                      format="DD/MM/YYYY"
                      showMonthDropdown
                      showYearDropdown
                      dropdownMode="select"
                      className="input-datepicker input-icon-datepicker"
                      placeholderText="Chọn ngày..."
                      minDate={moment()}
                      popperPlacement="auto"
                      popperModifiers={{
                        offset: {
                          enabled: true,
                          offset: '5px, 10px',
                        },
                        preventOverflow: {
                          enabled: true,
                          escapeWithReference: false,
                          boundariesElement: 'viewport',
                        },
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* Footer */}
        <div id="groupButton" className="buttton-action-footer">
          <Button
            onClick={this.props.onClickGoBack}
            className="pt-button btn-cancel"
            disabled={this.state.isLoading === true ? true : false}
          >
            <span className=" pt-icon icon-back"></span>
            <span className="text-content">Quay lại</span>
          </Button>
          <Button
            className="pt-button btn-blue-color"
            onClick={this.handleSubmit}
            loading={this.state.isLoading}
          >
            <span className="text-content">Lưu</span>
            <span className="pt-icon icon-save"></span>
          </Button>
        </div>

        <div className="buttton-actionfooter-fixtop buttton-action-footer">
          <Button
            className="pt-button btn-blue-color"
            onClick={this.handleSubmit}
            loading={this.state.isLoading}
          >
            <span className="pt-icon icon-save"></span>
            <span className="text-content">Lưu</span>
          </Button>
          <Button
            onClick={this.props.onClickGoBack}
            className="pt-button btn-cancel"
            disabled={this.state.isLoading === true ? true : false}
          >
            <span className=" pt-icon icon-back"></span>
            <span className="text-content">Quay lại</span>
          </Button>
        </div>
      </div>
    )
  }
}

export default AdditionalAssignment
