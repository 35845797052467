import React from 'react'
import { get, size, isEmpty } from 'lodash'
import { MasterLayout } from '../../components/layout'
import {
  SaveDocument,
  AssignmentInformation,
  DetailBoxActionSeeToKnow,
  ExecutingViewInternal,
} from '../../components/Work'

import {
  ExecutingView,
  InformationSteering,
  LoadingLine,
  LoadingItem,
  DetailFileContentV2,
} from 'components/common'

import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Intent } from '@blueprintjs/core'
import { bindActionCreators } from 'redux'
import { Permission, Authorization } from '../../components/auth'
import * as Actions from '../../actions'
import * as Tool from '../../helpers'
import * as Types from '../../constants/Api'
import * as MESSAGE from '../../constants/MessageForm'
import ReactDOM from 'react-dom'
import classnames from 'classnames'
import { SendMail } from '../../components/ReceiveDocument'
import { variables } from 'constants/variables'
import UserViewed from 'components/common/user-viewed'

const CONG_VAN_DEN = 'cong-van'
const CONG_VIEC_CA_NHAN = 'cong-viec'
const cabinetTypes = {
  [Types.CABINET_UNIT]: 'unit',
  [Types.CABINET_DEPARTMENT]: 'department',
  [Types.HOSO_CONGVIEC]: 'profile',
  [Types.CABINET_PERSONAL]: 'personal',
}
class ChiTietTheoDoiChiDao extends React.Component {
  static propTypes = {
    assignmentInformation: PropTypes.object.isRequired,
    reportWork: PropTypes.object.isRequired,
    archives: PropTypes.object.isRequired,
    staffs: PropTypes.array,
  }

  refHandlers = {
    detailFileContent: ref => (this.detailFileContent = ref),
  }

  constructor(props) {
    super(props)
    this.handleClickGoBack = this.handleClickGoBack.bind(this)
    this.handleClickSaveDocument = this.handleClickSaveDocument.bind(this)
    this.handleClickShowInfoMobile = this.handleClickShowInfoMobile.bind(this)
    this.closeModulesDetail = this.closeModulesDetail.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
    this.handleSelectSaveTo = this.handleSelectSaveTo.bind(this)
    this.handleSaveArchive = this.handleSaveArchive.bind(this)
    this.handleFinishLoadingMigratedFile =
      this.handleFinishLoadingMigratedFile.bind(this)
    this.handleClickInformationWork = this.handleClickInformationWork.bind(this)
    this.handleClickInformationAssign =
      this.handleClickInformationAssign.bind(this)
    this.handleScroll = this.handleScroll.bind(this)
  }

  state = {
    showListAction: true,
    isShowMobileAssignmentHistory: false,
    departmentWork: null,
    reportWorkPersonal: null,
    assign: null,
    workDepartmentAssignInformations: null,
    workPersonalAssignInformations: null,
    isUpdateAssignment: false,
    workDepartmentAssignId: '',
    workDepartmentAssignItem: null,
    showInformationSteering: false,
    saveTo: [
      ...(Authorization.vaild(this.props.auth.permission, [
        Permission.TUTAILIEUDONVI_QUANLY,
      ])
        ? [
            {
              id: Types.CABINET_UNIT,
              name: 'Tủ công ty',
              disabled: Authorization.vaild(this.props.auth.permission, [
                Permission.TUTAILIEUDONVI_QUANLY,
              ])
                ? false
                : true,
            },
          ]
        : []),
      {
        id: Types.CABINET_DEPARTMENT,
        name: 'Tủ phòng ban',
      },
      // {
      //   id: Types.HOSO_CONGVIEC,
      //   name: 'Hồ sơ công việc',
      //   disabled: !Authorization.vaild(this.props.auth.permission, [
      //     Permission.TUTAILIEUPHONGBAN,
      //   ]),
      // },
      {
        id: Types.CABINET_PERSONAL,
        name: 'Tủ cá nhân',
      },
    ],
    showAssignmentInformation: false,
    showSendEmail: false,
    isShowMobile: false,
    isLoading: false,
    isLoadingSubData: false,
    workId: this.props.match.params.id,
    personalWork: null,
    file: null,
    informationSteeringDepartment: null,
    informationSteeringPersonal: null,
    assignmentInformation: null,
    isLoadingData: {
      work: true,
    },
    seeToKnow: null,
    congVanDenId: undefined,
    isLoadingMigratedFile: true,
    boxMenuFixTop: false,
    filesThamKhao: [],
  }

  onMenuItem = name => {
    if (!name) {
      return
    }

    this.setState({
      showResults: true,
      showListAction: false,
      showSection: name,
    })
  }

  handleFinishLoadingMigratedFile = () => {
    this.setState({ isLoadingMigratedFile: !this.state.isLoadingMigratedFile })
  }

  handleClickGoBack = e => {
    e && e.preventDefault()
    this.setState({
      showResults: false,
      showListAction: true,
      showSection: 'noContent',
    })
  }

  handleClickSaveDocument = e => {
    e.preventDefault()
    ReactDOM.findDOMNode(this).scrollIntoView({ behavior: 'smooth' })
    this.setState({
      showResults: false,
      showListAction: false,
      isLoadingSubData: true,
      showSection: 'LuuTuTaiLieu',
    })

    let { match } = this.props
    let request

    if (this.props.match && this.props.match.params.loai === CONG_VAN_DEN) {
      request = this.props.actions.getListCabinetOfDocument(match.params.id)
    } else if (this.state.seeToKnow && this.state.seeToKnow.congVanDenId) {
      request = this.props.actions.getListCabinetOfDocument(
        this.state.seeToKnow.congVanDenId
      )
    } else {
      request = this.props.actions.getListCabinetOfDocument(
        this.state.seeToKnow && this.state.seeToKnow.congVanNoiBoId
      )
    }

    request.then(() => {
      this.setState({
        showResults: true,
        showListAction: false,
        isLoadingSubData: false,
      })
    })
  }

  getListDisable = async type => {
    const { actions } = this.props
    const { seeToKnow } = this.state
    const id = seeToKnow.congVanId || seeToKnow.congVanNoiBoId
    const docType = seeToKnow.congVanNoiBoId ? 2 : 1
    actions.getListCabinetOfDocument(id, docType)
  }

  handleClickInformationAssign = e => {
    e.preventDefault()
    ReactDOM.findDOMNode(this).scrollIntoView({ behavior: 'smooth' })
    this.setState({
      showResults: false,
      showListAction: false,
      isLoadingSubData: true,
      showSection: 'InformationAssign',
    })
    if (!this.state.informationSteeringPersonal) {
      if (this.state.seeToKnow && this.state.seeToKnow.congVanDenId) {
        return this.props.actions
          .InformationSteering(this.state.seeToKnow.congVanDenId)
          .then(res => {
            this.setState({
              informationSteeringPersonal: res.payload.data.result
                ? res.payload.data.result
                : null,
              showResults: true,
              isLoadingSubData: false,
            })
          })
      }
      this.props.actions
        .InformationSteering(this.props.match.params.id)
        .then(res => {
          this.setState({
            informationSteeringPersonal: res.payload.data.result
              ? res.payload.data.result
              : null,
            showResults: true,
            isLoadingSubData: false,
          })
        })
    } else {
      this.setState({
        showResults: true,
        isLoadingSubData: false,
      })
    }
  }

  handleClickInformationWork = e => {
    e.preventDefault()
    ReactDOM.findDOMNode(this).scrollIntoView({ behavior: 'smooth' })
    this.setState({
      showResults: false,
      showListAction: false,
      isLoadingSubData: true,
      showSection: 'InformationWork',
    })
    if (
      !this.state.workPersonalAssignInformations &&
      this.state.seeToKnow &&
      this.state.seeToKnow.phieuGiaoViecId
    ) {
      return this.props.actions
        .getInformationAssign(this.state.seeToKnow.phieuGiaoViecId)
        .then(res => {
          this.setState({
            workPersonalAssignInformations: res.payload.data.result
              ? res.payload.data.result
              : null,
            showResults: true,
            isLoadingSubData: false,
          })
        })
    }
    this.setState({
      showResults: true,
      isLoadingSubData: false,
    })
  }

  handleClickExecutingView = () => {
    ReactDOM.findDOMNode(this).scrollIntoView({ behavior: 'smooth' })
    this.setState({
      showResults: false,
      showListAction: false,
      isLoadingSubData: true,
      showSection: 'XemThucHien',
    })

    const { match, actions } = this.props
    const { seeToKnow } = this.state
    let state = this.state
    let request
    let executingViewAssign = null
    let isNewAssignDoc = false

    if (match && match.params.loai === CONG_VAN_DEN) {
      request = actions.getExecutingView(match.params.id)
      state.congVanDenId = true
    } else if (seeToKnow && seeToKnow.congVanDenId) {
      request = actions.getExecutingView(seeToKnow.congVanDenId)
      state.congVanDenId = true
    } else if (seeToKnow && seeToKnow.congVanNoiBoId) {
      request = actions.getExecutingViewInternalDocument(
        seeToKnow.congVanNoiBoId
      )
      state.congVanDenId = false
    } else {
      request = this.props.actions.getExecutingViewAssign(
        seeToKnow.phieuGiaoViecId,
        true
      )
      state.congVanDenId = false
      isNewAssignDoc = true
    }

    request.then(res => {
      if (
        res.payload &&
        res.payload.data &&
        res.payload.data.result &&
        isNewAssignDoc
      ) {
        executingViewAssign = [res.payload.data.result]
      }
      this.setState({
        showResults: true,
        showListAction: false,
        isLoadingSubData: false,
        state,
        executingViewAssign,
      })
    })
  }

  handleClickSendMail = e => {
    e.preventDefault()
    ReactDOM.findDOMNode(this).scrollIntoView({ behavior: 'smooth' })
    this.setState({
      showResults: true,
      showListAction: false,
      showSection: 'GuiEmail',
    })
  }

  handleClickShowInfoMobile = () => {
    this.setState({
      showDetailInfo: !this.state.showDetailInfo,
    })
  }

  showDetailInfo = () => {
    this.setState({
      showDetailInfo: !this.state.showDetailInfo,
    })
  }

  closeShowDetailInfo = () => {
    this.setState({
      showDetailInfo: false,
    })
  }

  closeModulesDetail = () => {
    this.setState({
      showListAction: true,
      showAssignmentInformation: false,
      showReportWork: false,
      showInformationSteering: false,
      showSendEmail: false,
    })
  }

  handleSubmit = (title, message, intent, isSuccess, id = 0) => {
    switch (title) {
      case 'GuiEmail':
        this.props.actions.commonAddToasterMessage({ message, intent: intent })
        if (isSuccess) {
          this.props.actions.refreshPage(true)
          this.goBack()
        }
        break
      default:
        this.props.actions.commonAddToasterMessage({ message, intent })
    }
  }

  componentDidMount() {
    window.addEventListener('scroll', this.handleScroll)

    if (this.props.match && this.props.match.params.loai === CONG_VAN_DEN) {
      this.props.actions
        .getItemReceiveDocument(this.props.match.params.id)
        .then(res => {
          if (res.payload && res.payload.data.result) {
            this.setState(
              {
                isLoadingData: {
                  ...this.state.isLoadingData,
                  work: false,
                },
                file: res.payload.data.result.fileNoiDung,
                seeToKnow: res.payload.data.result,
              },
              () => {
                // Gọi api get file tham khảo
                this.getFileThamKhao(this.state.seeToKnow).then(res => {
                  if (res && res.length !== 0) {
                    this.setState({
                      filesThamKhao: res,
                    })
                  }
                })
              }
            )
          }
        })
    }

    if (
      this.props.match &&
      this.props.match.params.loai === CONG_VIEC_CA_NHAN
    ) {
      this.props.actions
        .getPersonalWorkItem(this.props.match.params.id)
        .then(res => {
          if (res.payload && res.payload.data.result) {
            this.setState(
              {
                isLoadingData: {
                  ...this.state.isLoadingData,
                  work: false,
                },
                file: this.props.personalWorks.item.fileCongVan,
                seeToKnow: this.props.personalWorks.item,
              },
              () => {
                // Gọi api get file tham khảo
                this.getFileThamKhao(this.state.seeToKnow).then(res => {
                  if (res && res.length !== 0) {
                    this.setState({
                      filesThamKhao: res,
                    })
                  }
                })
              }
            )
          }
        })
    }
  }

  componentDidUpdate(prevProps, prevState) {
    let state = this.state

    if (
      prevProps.workAssignInformations !== this.props.workAssignInformations &&
      this.props.workAssignInformations.department
    ) {
      state.workDepartmentAssignInformations =
        this.props.workAssignInformations.department
      state.workDepartmentAssignId = this.props.workAssignInformations
        .department.phieuGiaoViecId
        ? this.props.workAssignInformations.department.phieuGiaoViecId
        : null

      if (this.state.workDepartmentAssignId) {
        this.props.actions
          .getInformationAssign(this.state.workDepartmentAssignId)
          .then(res => {
            if (res.payload && res.payload.status === 200) {
              this.setState({
                workDepartmentAssignItem: res.payload.data.result
                  ? res.payload.data.result
                  : null,
              })
            }
          })
      }
    }

    if (
      prevProps.workAssignInformations !== this.props.workAssignInformations &&
      this.props.workAssignInformations.personal &&
      this.props.workAssignInformations.personal
    ) {
      state.workPersonalAssignInformations =
        this.props.workAssignInformations.personal
    }

    if (
      prevState.isUpdateAssignment !== this.isUpdateAssignment &&
      this.isUpdateAssignment
    ) {
      this.props.actions.workFetchDepartmentAssignInformations(
        this.state.seeToKnow.phieuGiaoViecId
          ? this.state.seeToKnow.phieuGiaoViecId
          : this.state.workId
      )
    }

    if (
      prevProps.assignmentInformation !== this.props.assignmentInformation &&
      this.props.assignmentInformation &&
      this.props.assignmentInformation.items
    ) {
      state.assignmentInformation = this.props.assignmentInformation.items
    }

    if (
      prevProps.personalWorks !== this.props.personalWorks &&
      this.props.personalWorks &&
      this.props.personalWorks.item
    ) {
      state.personalWork = this.props.personalWorks.item
    }

    if (
      prevProps.departmentWorks !== this.props.departmentWorks &&
      this.props.departmentWorks &&
      this.props.departmentWorks.item
    ) {
      state.departmentWork = this.props.departmentWorks.item
    }

    if (state !== this.state) {
      this.setState(state)
    }
  }

  getFileThamKhao = async data => {
    let params = {
      loai: 1,
    }
    let id = -1
    if (data.congVanId) {
      params = {
        ...params,
        type: 'CONG_VAN',
      }
      id = data.congVanId
    } else {
      return []
    }

    let filesThamKhao = []
    await this.props.actions.getDanhSachFiles(id, params).then(res => {
      if (
        res &&
        res.payload &&
        res.payload.data &&
        res.payload.data.result &&
        res.payload.data.result.items &&
        res.payload.data.result.items.length !== 0
      ) {
        res.payload.data.result.items.map(elm =>
          filesThamKhao.push({
            ...elm,
            is_existed: true,
          })
        )
      }
    })

    return filesThamKhao
  }

  goBack() {
    this.props.history.goBack()
  }

  componentWillUnmount() {
    this.setState({
      showListAction: true,
      isShowMobileAssignmentHistory: false,
      departmentWork: null,
      reportWorkPersonal: null,
      assign: null,
      workDepartmentAssignInformations: {},
      workPersonalAssignInformations: {},
      isUpdateAssignment: false,
      workDepartmentAssignId: '',
      workDepartmentAssignItem: null,
      showInformationSteering: false,
      saveTo: [
        ...(Authorization.vaild(this.props.auth.permission, [
          Permission.TUTAILIEUDONVI_QUANLY,
        ])
          ? [
              {
                id: Types.CABINET_UNIT,
                name: 'Tủ công ty',
              },
            ]
          : []),
        {
          id: Types.CABINET_PERSONAL,
          name: 'Tủ cá nhân',
        },
        // {
        //   id: Types.HOSO_CONGVIEC,
        //   name: 'Hồ sơ công việc',
        //   disabled: !Authorization.vaild(this.props.auth.permission, [
        //     Permission.TUTAILIEUPHONGBAN,
        //   ]),
        // },
        {
          id: Types.CABINET_DEPARTMENT,
          name: 'Tủ phòng ban',
        },
      ],
      showAssignmentInformation: false,
      showSendEmail: false,
      isShowMobile: false,
      workId: this.props.match.params.id,
      personalWork: null,
      file: null,
      informationSteeringDepartment: null,
      informationSteeringPersonal: null,
      assignmentInformation: null,
      isLoadingData: {
        work: true,
      },
      executingViews: [],
    })
    window.removeEventListener('scroll', this.handleScroll)
  }

  // Upload file đính kèm
  uploadFile = async files => {
    const { fileUpload } = this.props.actions
    let response = {}
    if (size(files)) {
      response = await fileUpload(files, this.changeUploadProgress)
    }
    if (response.error) {
      return this.showValidateMessage(MESSAGE.TEXT_MESSAGE_UPLOAD_FAIL, true)
    }

    return get(response, 'payload.data.result', []).map(file =>
      Tool.Utils.convertFileResponse(file)
    )
  }

  // Lưu hồ sơ công việc
  handleSaveJobProject = async (err, params) => {
    const { actions } = this.props
    const { seeToKnow } = this.state
    const { commonAddToasterMessage, postTaiLieuHoSoCongViec } = actions
    if (err) {
      return commonAddToasterMessage({
        message: err,
        inten: Intent.DANGER,
      })
    }
    const files = await this.uploadFile(get(params, 'files', []))
    const data = Tool.Utils.toDecamelizeKeys({
      tenTaiLieu: seeToKnow.trichYeu,
      ghiChu: get(params, 'ghiChu'),
      congVanId: seeToKnow.congVanId,
      congVanDenId: seeToKnow.congVanDenId,
      congVanNoiBoId: seeToKnow.congVanNoiBoId,
      dsHoSoCongViecId: get(params, 'nganTuIds', []),
      soCongVan: seeToKnow.soCongVan,
      ngayCongVan: seeToKnow.ngayCongVan,
      fileNoiDung: seeToKnow.fileNoiDung,
      fileDinhKem: [
        ...(seeToKnow.fileDinhKem && seeToKnow.fileDinhKem.length !== 0
          ? seeToKnow.fileDinhKem.map(i => ({ ...i, is_existed: true }))
          : seeToKnow.fileDinhKemEvn && seeToKnow.fileDinhKemEvn.length !== 0
          ? seeToKnow.fileDinhKemEvn.map(i => ({ ...i, is_existed: true }))
          : []),
      ],
      fileDinhKemBenNgoai: [...files],
    })

    try {
      const response = await postTaiLieuHoSoCongViec(data)
      if (response.error) {
        return commonAddToasterMessage({
          message: MESSAGE.TOATS_MESSAGE_FAIL,
          inten: Intent.DANGER,
        })
      }

      if (get(response, 'payload.status') === 200) {
        commonAddToasterMessage({
          message: MESSAGE.TOATS_MESSAGE_SUCCESS,
          intent: Intent.SUCCESS,
        })
        ReactDOM.findDOMNode(this).scrollIntoView({ behavior: 'smooth' })
        this.setState({
          showResults: false,
          showListAction: true,
          showSection: 'noContent',
          isLoadedApi: {
            ...this.state.isLoadedApi,
            documentCabinetList: false,
          },
        })
      }
    } catch (error) {
      return commonAddToasterMessage({
        message: MESSAGE.TOATS_MESSAGE_FAIL,
        inten: Intent.DANGER,
      })
    }
  }

  handleSaveArchive = (err, params) => {
    if (err) {
      return this.props.actions.commonAddToasterMessage({
        message: err,
        intent: Intent.DANGER,
      })
    }

    if (params.categoryType === Types.HOSO_CONGVIEC) {
      return this.handleSaveJobProject(err, params)
    }

    const item = this.state.seeToKnow

    params = {
      ds_ngan_tu: params.nganTuIds,
      loai_tu_tai_lieu: parseInt(params.categoryType, 10),
      ghi_chu: params.ghiChu,
      ds_file_cong_van: [
        {
          file_id: item.fileThamKhao
            ? item.fileThamKhao.fileId
            : item.fileCongVan
            ? item.fileCongVan.fileId
            : item.fileNoiDung
            ? item.fileNoiDung.fileId
            : null,
          cong_van_den_id: item.congVanDenId,
          cong_van_noi_bo_id: item.congVanNoiBoId,
          ten_tai_lieu: params.tenTaiLieu,
        },
      ],
    }

    return this.props.actions
      .documentsCabinetSaveReceiveDocument(params)
      .then(res => {
        if (res.error) {
          return this.props.actions.commonAddToasterMessage({
            message: MESSAGE.TOATS_MESSAGE_FAIL,
            intent: Intent.DANGER,
          })
        }

        if (res.payload && res.payload.status === 200) {
          this.props.actions.commonAddToasterMessage({
            message: MESSAGE.TOATS_MESSAGE_SUCCESS,
            intent: Intent.SUCCESS,
          })
          ReactDOM.findDOMNode(this).scrollIntoView({ behavior: 'smooth' })
          this.setState({
            showResults: false,
            showListAction: true,
            showSection: 'noContent',
          })
        }
      })
  }

  handleSelectSaveTo(v) {
    // Get dung lượng tủ tài liệu
    // this.props.actions.getStorageCapacity(v);
    this.props.actions.setCabinetType(v)
    this.getListDisable(v)
    switch (v) {
      case Types.CABINET_UNIT:
        this.props.actions.documentsCabinetGetListCategoriesUnit()
        break
      case Types.CABINET_PERSONAL:
        this.props.actions.documentsCabinetGetListCategoriesPersonal()
        break
      case Types.CABINET_DEPARTMENT:
        this.props.actions.documentsCabinetGetListCategoriesDepartment()
        break
      case Types.HOSO_CONGVIEC:
        const params = {
          loai: 1,
          phanLoaiTu: 'PHONGBAN',
        }
        this.props.actions.getLoaiLuuTaiLieu(params)
        break
      default:
    }
  }

  handleClickDepartmentExecutingView = id => {
    this.props.actions.getMoreExecutingView(id)
  }

  handleScroll(event) {
    let scrollTop = window.pageYOffset
    if (scrollTop > 62) {
      this.setState({
        boxMenuFixTop: true,
      })
    } else {
      this.setState({
        boxMenuFixTop: false,
      })
    }
  }

  sendToChat = e => {
    e.preventDefault()
    let { seeToKnow } = this.state
    if (seeToKnow && seeToKnow.fileNoiDung) {
      let fileNoiDung = seeToKnow.fileNoiDung
        ? seeToKnow.fileNoiDung.fileId
        : ''
      // Truyền param id của công văn, công việc hoặc ký số. Có thêm kiểu của loại tài liệu
      let infoGetFileDinhKem = seeToKnow.congVanId
      let infoGetFileDinhKemType = 'CONG_VAN'
      if (!infoGetFileDinhKem) {
        infoGetFileDinhKem = seeToKnow.congVanNoiBoId
        infoGetFileDinhKemType = 'CONG_VAN_NOI_BO'
      }
      const url = `?fileId=${fileNoiDung}&infoGetFileDinhKem=${infoGetFileDinhKem}&infoGetFileDinhKemType=${infoGetFileDinhKemType}`
      return this.props.history.push(`${variables.CHAT_URL}${url}`)
    } else if (seeToKnow && seeToKnow.fileCongVan) {
      let fileNoiDung = seeToKnow.fileCongVan
        ? seeToKnow.fileCongVan.fileId
        : ''
      // Truyền param id của công văn, công việc hoặc ký số. Có thêm kiểu của loại tài liệu
      let infoGetFileDinhKem = seeToKnow.congVanId
      let infoGetFileDinhKemType = 'CONG_VAN'
      if (!infoGetFileDinhKem) {
        infoGetFileDinhKem = seeToKnow.congVanNoiBoId
        infoGetFileDinhKemType = 'CONG_VAN_NOI_BO'
      }
      const url = `?fileId=${fileNoiDung}&infoGetFileDinhKem=${infoGetFileDinhKem}&infoGetFileDinhKemType=${infoGetFileDinhKemType}`
      return this.props.history.push(`${variables.CHAT_URL}${url}`)
    }
  }

  render() {
    let isNewAssignDoc =
      this.state.seeToKnow &&
      this.state.seeToKnow.dsPhieuGiaoViec &&
      this.state.seeToKnow.dsPhieuGiaoViec.length > 0
        ? true
        : false
    let isLoadingAll = Object.values(this.state.isLoadingData).find(
      item => item === true
    )
    const fileContent = isEmpty(this.props.departmentWorks.item)
      ? this.props.personalWorks.item
      : this.props.departmentWorks.item
    let fileThamKhao = fileContent.fileThamKhao
      ? fileContent.fileThamKhao
      : fileContent.fileCongVan || {}
    fileThamKhao.kichThuocStr = Tool.Utils.getFileSize(fileThamKhao.kichThuoc)

    // let cabinetCategories = this.props.archives.type.personal;
    let documentNumberDetail =
      this.state.seeToKnow && this.state.seeToKnow.soCongVan
        ? this.state.seeToKnow.soCongVan
        : ''
    if (isNewAssignDoc) {
      documentNumberDetail = this.state.seeToKnow.tieuDe
    }

    const { archives, auth } = this.props
    const cabinetCategories = get(
      archives,
      `type[${
        cabinetTypes[get(auth, 'defaultCabinetType', Types.CABINET_PERSONAL)]
      }]`
    )
    // switch (this.props.auth.defaultCabinetType) {
    //   case Types.CABINET_UNIT:
    //     cabinetCategories = this.props.archives.type.unit;
    //     break;
    //   case Types.CABINET_DEPARTMENT:
    //     cabinetCategories = this.props.archives.type.department;
    //     break;
    //   default:
    // }

    return (
      <MasterLayout typeSidebar="work" collapseSideBar={true}>
        <div className="detail-receive-document">
          {isLoadingAll ? (
            <div className="row-detail-container clearfix loading-line-detail">
              <div className="col-md-4 col-xs-12">
                <LoadingLine />
              </div>
            </div>
          ) : (
            <div className="dispatch-code">
              <button
                onClick={this.goBack.bind(this)}
                type="button"
                className={classnames(
                  'pt-button pt-button-back button-back-fixed',
                  {
                    'fixed-button-back':
                      this.state.boxMenuFixTop &&
                      !this.props.enableScrollFixedMenu &&
                      window.innerWidth >= 1024,
                    'fixed-button-hasSidebar':
                      this.state.boxMenuFixTop &&
                      this.props.enableScrollFixedMenu &&
                      window.innerWidth >= 1024,
                  }
                )}
              >
                <span className="pt-icon pt-icon-chevron-left pt-align-left"></span>
                <span className="text-content">Quay lại</span>
              </button>
              <span className="number-code">
                <span className="name">
                  {isNewAssignDoc ? 'Tiêu đề:' : 'Công văn:'}
                </span>
                <span className="code">{documentNumberDetail}</span>
              </span>
            </div>
          )}

          {!isNewAssignDoc &&
            !this.state.isLoadingMigratedFile &&
            !isLoadingAll && (
              <span
                className="btn-view-file-detail"
                onClick={() => {
                  this.detailFileContent.handleFullScreen(true)
                }}
              >
                <span className="left-icon icon-Xem_thuc_hien"></span>
                <span className="text-content">Xem chi tiết công văn</span>
              </span>
            )}

          {!isNewAssignDoc &&
            this.state.file &&
            this.state.isLoadingMigratedFile &&
            !isLoadingAll && (
              <p className="btn-view-file-detail">Đang tải file ....</p>
            )}

          <div className="detail-container">
            <div className="row-detail-container clearfix">
              <div className="box-left">
                {isNewAssignDoc &&
                  this.state.seeToKnow.dsPhieuGiaoViec.map((item, index) => (
                    <div className="assign-info" key={index}>
                      <AssignmentInformation
                        auth={this.props.auth}
                        firstChild={index === 0 ? true : false}
                        assignmentInformation={item}
                        isNewAssignDoc={isNewAssignDoc}
                      />
                    </div>
                  ))}
                {!isNewAssignDoc && !isEmpty(this.state.file) && (
                  <DetailFileContentV2
                    auth={this.props.auth}
                    actions={this.props.actions}
                    file={this.state.file}
                  />
                )}
              </div>
              <div
                className={classnames('box-right', {
                  fixtopMenuRight:
                    this.state.boxMenuFixTop &&
                    !this.state.showResults &&
                    !this.props.enableScrollFixedMenu &&
                    window.innerWidth >= 1024,
                  fixtopMenuRightHasSideBar:
                    this.state.boxMenuFixTop &&
                    !this.state.showResults &&
                    this.props.enableScrollFixedMenu &&
                    window.innerWidth >= 1024,
                })}
              >
                <div className="full-box-content">
                  {this.state.showListAction && (
                    <DetailBoxActionSeeToKnow
                      isNewAssignDoc={isNewAssignDoc}
                      seeToKnow={this.state.seeToKnow}
                      auth={this.props.auth}
                      onHandleSubmit={this.handleSubmit}
                      isLoadingData={this.state.isLoadingData}
                      onClickExecutingView={this.handleClickExecutingView}
                      type="personal"
                      onClickSaveDocument={this.handleClickSaveDocument}
                      isDerpartment={
                        this.state.workDepartmentAssignInformations !== null
                      }
                      onClickInformationAssign={
                        this.handleClickInformationAssign
                      }
                      onClickInformationWork={this.handleClickInformationWork}
                      sendToChat={this.sendToChat}
                      onClickSendMail={this.handleClickSendMail}
                      filesThamKhao={this.state.filesThamKhao}
                      onMenuItem={this.onMenuItem}
                    />
                  )}

                  {this.state.isLoadingSubData && <LoadingItem />}

                  {this.state.showResults &&
                    this.state.showSection === 'LuuTuTaiLieu' && (
                      <SaveDocument
                        work={
                          isEmpty(this.state.departmentWork)
                            ? !isEmpty(this.state.personalWork)
                              ? this.state.personalWork
                              : this.state.seeToKnow
                            : this.state.departmentWork
                        }
                        file={this.state.file}
                        storage={this.props.archives.storage}
                        onClickGoBack={this.handleClickGoBack}
                        archives={cabinetCategories}
                        saveTo={this.state.saveTo}
                        onSelectSaveTo={this.handleSelectSaveTo}
                        onSubmit={this.handleSaveArchive}
                        defaultType={this.props.auth.defaultCabinetType}
                        listCabinetOfDocument={this.props.listCabinetOfDocument}
                        auth={this.props.auth}
                      />
                    )}

                  {this.state.showResults &&
                    this.state.showSection === 'XemThucHien' && (
                      <div>
                        {this.state.congVanDenId && !isNewAssignDoc && (
                          <ExecutingView
                            onClickGoBack={this.handleClickGoBack}
                            onClickDepartment={
                              this.handleClickDepartmentExecutingView
                            }
                            executingViews={
                              this.props.executingViews.items || []
                            }
                            auth={this.props.auth}
                            actions={this.props.actions}
                          />
                        )}
                        {!this.state.congVanDenId && !isNewAssignDoc && (
                          <ExecutingViewInternal
                            onClickGoBack={this.handleClickGoBack}
                            executingViews={
                              this.props.executingViewInternal
                                .items_internalDocument || []
                            }
                            auth={this.props.auth}
                            actions={this.props.actions}
                          />
                        )}
                        {isNewAssignDoc && (
                          <ExecutingView
                            onClickGoBack={this.handleClickGoBack}
                            executingViews={
                              this.state.executingViewAssign || []
                            }
                            auth={this.props.auth}
                            actions={this.props.actions}
                            isNewAssignDoc={true}
                          />
                        )}
                      </div>
                    )}

                  {this.state.showResults &&
                    this.state.showSection === 'InformationAssign' && (
                      <InformationSteering
                        isShowMobile={true}
                        onClickGoBack={this.handleClickGoBack}
                        closeModulesDetail={this.closeModulesDetail}
                        informationSteering={
                          this.state.informationSteeringPersonal
                        }
                      />
                    )}

                  {this.state.showResults &&
                    this.state.showSection === 'InformationWork' && (
                      <AssignmentInformation
                        assignmentInformation={
                          this.state.workPersonalAssignInformations
                        }
                        type="personal"
                        auth={this.props.auth}
                        isShowMobile={true}
                        isLeaderUnit={this.state.isLeaderUnit}
                        onClickGoBack={this.handleClickGoBack}
                      />
                    )}
                  {this.state.showResults &&
                    this.state.showSection === 'GuiEmail' && (
                      <div className="box-action-item">
                        <SendMail
                          auth={this.props.auth}
                          onClickGoBack={this.handleClickGoBack}
                          onSubmit={this.handleSubmit}
                          action={this.props.actions}
                          receiveDocument={
                            this.state.seeToKnow ? this.state.seeToKnow : {}
                          }
                        />
                      </div>
                    )}
                  {this.state.showResults &&
                    this.state.showSection === 'USER_VIEWED' && (
                      <UserViewed
                        id={this.state?.seeToKnow?.congVanId}
                        onGoBack={this.handleClickGoBack}
                      />
                    )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </MasterLayout>
    )
  }
}

const mapStateToProps = state => ({
  departmentWorks: {
    ...state.departmentWorks,
    item: state.departmentWorks.detailId
      ? state.entities.departmentWorks[state.departmentWorks.detailId]
      : {},
    informationSteering: state.receiveDocuments.item,
  },

  personalWorks: {
    ...state.personalWorks,
    item: state.personalWorks.detailId
      ? state.entities.personalWorks[state.personalWorks.detailId]
      : {},
    informationSteering: state.receiveDocuments.item,
  },

  assignmentInformation: {
    ...state.assignmentInformation,
    items: state.assignmentInformation.list.map(
      id => state.entities.assignmentInformation[id]
    ),
  },

  auth: {
    ...state.auth,
    user: state.auth,
  },

  archives: {
    ...state.documentsCabinet,
    categories: state.documentsCabinet.categories.map(
      id => state.entities.documentCabinetCategories[id]
    ),
    type: {
      unit: state.documentsCabinet.type.unit.map(
        id => state.entities.documentCabinetCategories[id]
      ),
      department: state.documentsCabinet.type.department.map(
        id => state.entities.documentCabinetCategories[id]
      ),
      personal: state.documentsCabinet.type.personal.map(
        id => state.entities.documentCabinetCategories[id]
      ),
      profile: state.documentsCabinet.type.profile.map(
        id => state.entities.documentCabinetCategories[id]
      ),
    },
    storage: {
      ...state.documentsCabinet.storage,
      used: Tool.Utils.getFileSize(state.documentsCabinet.storage.used),
    },
  },

  reportWork: {
    ...state.reportWork,
    items: state.reportWork.items.map(id => state.entities.reportWork[id]),
  },

  executingViews: {
    ...state.executingViews,
    items:
      state.executingViews.items &&
      state.executingViews.items.map(id => {
        let data = state.entities.executingViews[id]

        const getDSDonVi = dsDonViTrucThuocResponse => {
          return dsDonViTrucThuocResponse.map(item => {
            let subItem = state.entities.executingViews[item.chiDaoId]

            return {
              ...item,
              executingViews: [
                subItem
                  ? {
                      ...subItem,
                      dsDonViTrucThuocResponse: getDSDonVi(
                        subItem.dsDonViTrucThuocResponse
                      ),
                    }
                  : undefined,
              ],
            }
          })
        }

        return {
          ...data,
          dsDonViTrucThuocResponse: getDSDonVi(data.dsDonViTrucThuocResponse),
        }
      }),
  },

  executingViewInternal: {
    ...state.executingViews,
    items_internalDocument:
      state.executingViews.items_internalDocument !== undefined &&
      state.executingViews.items_internalDocument.map(
        id => state.entities.executingViews[id]
      ),
  },

  staffs: state.common.staffs,

  workAssignInformations: state.workAssignInformations,

  listCabinetOfDocument: state.common.listCabinetOfDocument.map(
    item => item.id
  ),

  enableScrollFixedMenu: state.common.enableScrollFixedMenu,
})

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(Actions, dispatch),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ChiTietTheoDoiChiDao)
