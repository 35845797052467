import React from 'react'
import { Button } from '@blueprintjs/core'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import moment from 'moment'

import { MasterLayout } from '../../components/layout'
import { ListFilter, ListItem } from '../../components/Utilities/GeneralWork'
import { GridView } from '../../components/common'
import * as Actions from '../../actions'
import { headers } from '../../constants/Table'
import FileDownload from 'file-saver'
import { Intent } from '@blueprintjs/core'
import * as Tool from '../../helpers'
import { Authorization, Permission } from '../../components/auth'
import HeadingPanel from 'components/common/HeadingPanel'
import { MESSAGE_DEFAULT_STATISTICS } from 'constants/MessageForm'

const CONG_VIEC = {
  XUAT_PHAT_TU_CV: 1,
  KHONG_XUAT_PHAT_TU_CV: 2,
}
class GeneralWorkPage extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      filter: {},
      sort: undefined,
      isEmpty: false,
      page: 1,
      isLoading: false,
      isError: false,
      isShowMessengStatistics: true,
    }

    this.submitFilter = this.submitFilter.bind(this)
    this.handleSort = this.handleSort.bind(this)
    this.handleExport = this.handleExport.bind(this)
    this.onSubmitUnit = this.onSubmitUnit.bind(this)
    this.onSubmit = this.onSubmit.bind(this)
    this.goBack = this.goBack.bind(this)
  }

  componentDidMount() {
    this.props.actions.commonFetchDepartments()
    let state = this.state
    let preData = Tool.Utils.preData(
      `ListWorkDocument`,
      null,
      'thong-ke-cong-viec'
    )
    if (preData) {
      state.filter = preData.filter
      state.page = preData.page
      state.sort = preData.sort
    } else if (this.props.match && !this.props.match.params.type) {
      state.filter = {
        start_date: moment().subtract(1, 'months').format('YYYY-MM-DD'),
        end_date: moment().format('YYYY-MM-DD'),
        phong_ban_id: this.props.auth.mainDepartmentId,
        qua_han: false,
        loai_cong_viec: CONG_VIEC.XUAT_PHAT_TU_CV,
      }
    }
    this.setState(state, () => {
      if (this.props.match && this.props.match.params.type === 'don-vi') {
        const filter = {
          start_date: this.state.filter.start_date,
          end_date: this.state.filter.end_date,
          don_vi_id: this.props.match.params.id,
          loai_cong_viec: this.state.filter.loai_cong_viec,
        }
        this.setState({ filter }, () => {
          this.onSubmitUnit()
        })
      } else if (
        this.props.match &&
        this.props.match.params.type === 'phong-ban'
      ) {
        const filter = {
          start_date: this.state.filter.start_date,
          end_date: this.state.filter.end_date,
          phong_ban_id: this.props.match.params.id,
          loai_cong_viec: this.state.filter.loai_cong_viec,
        }
        this.setState({ filter }, () => {
          this.onSubmit()
        })
      } else {
        let isSubmitStatisticWork = Tool.Utils.getData('isSubmitStatisticWork')
        if (isSubmitStatisticWork) {
          Tool.Utils.saveData('isSubmitStatisticWork', false)
          let filter
          if (
            Authorization.vaild(this.props.auth.permission, [Permission.ADMIN])
          ) {
            filter = {
              start_date: this.state.filter.start_date,
              end_date: this.state.filter.end_date,
              loai_cong_viec: this.state.filter.loai_cong_viec,
            }
          } else {
            filter = {
              start_date: this.state.filter.start_date,
              end_date: this.state.filter.end_date,
              phong_ban_id: this.state.filter.phong_ban_id,
              qua_han: this.state.filter.qua_han,
              loai_cong_viec: this.state.filter.loai_cong_viec,
            }
          }
          this.setState({ filter }, () => {
            this.submitFilter()
          })
        } else {
          let filter
          if (
            Authorization.vaild(this.props.auth.permission, [Permission.ADMIN])
          ) {
            filter = {
              start_date: this.state.filter.start_date,
              end_date: this.state.filter.end_date,
              loai_cong_viec: this.state.filter.loai_cong_viec,
            }
          } else {
            filter = {
              start_date: this.state.filter.start_date,
              end_date: this.state.filter.end_date,
              phong_ban_id: this.state.filter.phong_ban_id,
              qua_han: this.state.filter.qua_han,
              loai_cong_viec: this.state.filter.loai_cong_viec,
            }
          }
          this.setState({ filter })
        }
      }
    })
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.match !== this.props.match) {
      if (nextProps.match && nextProps.match.params.type === 'don-vi') {
        const filter = {
          start_date: this.state.filter.start_date,
          end_date: this.state.filter.end_date,
          don_vi_id: nextProps.match.params.id,
          loai_cong_viec: this.state.filter.loai_cong_viec,
        }
        this.setState({ filter }, () => {
          this.onSubmitUnit()
        })
      } else if (
        nextProps.match &&
        nextProps.match.params.type === 'phong-ban'
      ) {
        const filter = {
          start_date: this.state.filter.start_date,
          end_date: this.state.filter.end_date,
          qua_han: this.state.filter.qua_han,
          phong_ban_id: nextProps.match.params.id,
          loai_cong_viec: this.state.filter.loai_cong_viec,
        }
        this.setState({ filter }, () => {
          this.onSubmit()
        })
      }
    }
  }

  onSubmitUnit() {
    const filter = {
      start_date: this.state.filter.start_date,
      end_date: this.state.filter.end_date,
      don_vi_id: this.state.filter.don_vi_id,
      loai_cong_viec: this.state.filter.loai_cong_viec || undefined,
    }
    this.setState(
      { filter, isLoading: true, isShowMessengStatistics: false },
      () => {
        Tool.Utils.preData(`ListWorkDocument`, { filter }, 'thong-ke-cong-viec')
        this.props.actions
          .getGeneralWorkUnit(filter, this.props.config.refreshStatistic)
          .then(res => {
            let isEmpty = false
            let isError = false
            if (
              !res.error &&
              res.payload &&
              res.payload.data &&
              res.payload.data.result &&
              res.payload.data.result.total === 0
            ) {
              isEmpty = true
            }

            if (res.error || (res.payload && res.payload.status !== 200)) {
              isError = true
            }
            this.setState({ isEmpty, isError })
          })
          .then(() => {
            this.props.actions.refreshStatistic(false)
            this.setState({ isLoading: false })
          })
      }
    )
  }

  onSubmit() {
    const filter = {
      start_date: this.state.filter.start_date,
      end_date: this.state.filter.end_date,
      phong_ban_id: this.state.filter.phong_ban_id,
      qua_han: this.state.filter.qua_han,
      loai_cong_viec: this.state.filter.loai_cong_viec || undefined,
    }
    this.setState(
      { filter, isLoading: true, isShowMessengStatistics: false },
      () => {
        Tool.Utils.preData(`ListWorkDocument`, { filter }, 'thong-ke-cong-viec')
        this.props.actions
          .getGeneralWork(filter, this.props.config.refreshStatistic)
          .then(res => {
            let isEmpty = false
            let isError = false
            if (
              !res.error &&
              res.payload &&
              res.payload.data &&
              res.payload.data.result &&
              res.payload.data.result.total === 0
            ) {
              isEmpty = true
            }

            if (res.error || (res.payload && res.payload.status !== 200)) {
              isError = true
            }
            this.setState({ isEmpty, isError })
          })
          .then(() => {
            this.props.actions.refreshStatistic(false)
            this.setState({ isLoading: false })
          })
      }
    )
  }

  submitFilter(e = {}, page = 1, sort) {
    const filter = Object.assign({}, this.state.filter, e)
    this.setState(
      { filter, page, isLoading: true, isShowMessengStatistics: false },
      () => {
        if (
          (this.props.match && this.props.match.params.type === 'don-vi') ||
          (this.props.match &&
            this.props.match.params &&
            !this.props.match.params.type &&
            Authorization.vaild(this.props.auth.permission, [Permission.ADMIN]))
        ) {
          this.onSubmitUnit()
        } else {
          this.onSubmit()
        }
      }
    )
  }

  handleSort(sort) {
    this.submitFilter(this.state.filter, this.state.page, sort)
  }

  handleExport() {
    const { filter, page } = this.state
    let preData = Tool.Utils.getData(`ListWorkDocument`)
    this.setState({ exporting: true }, () => {
      if (this.props.match && this.props.match.params.type === 'phong-ban') {
        this.props.actions
          .exportGeneralWork(filter, page)
          .then(res => {
            if (res.error) {
              return this.props.actions.commonAddToasterMessage({
                message: 'Không xuất được file.',
                intent: Intent.DANGER,
              })
            }
            FileDownload.saveAs(
              res.payload.data,
              `thong-ke-cong-viec-${moment(preData.filter.start_date).format(
                'L'
              )}-${moment(preData.filter.end_date).format('L')}.xls`
            )
          })
          .then(() => {
            this.setState({ exporting: false })
          })
      } else if (
        this.props.match &&
        this.props.match.params.type === 'don-vi'
      ) {
        this.props.actions
          .exportGeneralWorkUnit(filter, page)
          .then(res => {
            if (res.error) {
              return this.props.actions.commonAddToasterMessage({
                message: 'Không xuất được file.',
                intent: Intent.DANGER,
              })
            }

            FileDownload.saveAs(
              res.payload.data,
              `thong-ke-cong-viec-${moment(preData.filter.start_date).format(
                'L'
              )}-${moment(preData.filter.end_date).format('L')}.xls`
            )
          })
          .then(() => {
            this.setState({ exporting: false })
          })
      } else if (!this.props.match.params.type) {
        if (
          Authorization.vaild(this.props.auth.permission, [Permission.ADMIN])
        ) {
          this.props.actions
            .exportGeneralWorkUnit(filter, page)
            .then(res => {
              if (res.error) {
                return this.props.actions.commonAddToasterMessage({
                  message: 'Không xuất được file.',
                  intent: Intent.DANGER,
                })
              }

              FileDownload.saveAs(
                res.payload.data,
                `thong-ke-cong-viec-${moment(preData.filter.start_date).format(
                  'L'
                )}-${moment(preData.filter.end_date).format('L')}.xls`
              )
            })
            .then(() => {
              this.setState({ exporting: false })
            })
        } else {
          this.props.actions
            .exportGeneralWork(filter, page)
            .then(res => {
              if (res.error) {
                return this.props.actions.commonAddToasterMessage({
                  message: 'Không xuất được file.',
                  intent: Intent.DANGER,
                })
              }

              FileDownload.saveAs(
                res.payload.data,
                `thong-ke-cong-viec-${moment(preData.filter.start_date).format(
                  'L'
                )}-${moment(preData.filter.end_date).format('L')}.xls`
              )
            })
            .then(() => {
              this.setState({ exporting: false })
            })
        }
      }
    })
  }

  goBack() {
    this.props.history.goBack()
    Tool.Utils.saveData('isSubmitStatisticWork', true)
  }

  render() {
    let check
    if (
      Authorization.vaild(this.props.auth.permission, [Permission.SUPERADMIN])
    ) {
      check = 'donVi'
      if (this.props.match && this.props.match.params.type === 'don-vi') {
        check = 'phongBan'
      } else if (
        this.props.match &&
        this.props.match.params.type === 'phong-ban'
      ) {
        check = 'caNhan'
      }
    } else if (
      !Authorization.vaild(this.props.auth.permission, [
        Permission.SUPERADMIN,
      ]) &&
      Authorization.vaild(this.props.auth.permission, [Permission.ADMIN])
    ) {
      check = 'phongBan'
      if (this.props.match && this.props.match.params.type === 'don-vi') {
        check = 'phongBan'
      } else if (
        this.props.match &&
        this.props.match.params.type === 'phong-ban'
      ) {
        check = 'caNhan'
      }
    } else if (
      !Authorization.vaild(this.props.auth.permission, [
        Permission.SUPERADMIN,
      ]) &&
      !Authorization.vaild(this.props.auth.permission, [Permission.ADMIN])
    ) {
      check = 'caNhan'
    }
    return (
      <MasterLayout typeSidebar={this.props.typeSidebar}>
        <div className="page-utilities-container">
          <HeadingPanel
            goBack={
              this.props.match &&
              this.props.match.params &&
              this.props.match.params.type
                ? this.goBack
                : null
            }
            leftTitle="Thống kê công việc"
            leftIcon="icon-Tong_Hop"
            rightElement={
              <Button
                rightIconName="pt-icon icon-In_So_Cong_Van"
                className="btn-action btn-top-heading mb10"
                text="Xuất excel"
                onClick={this.handleExport}
                loading={this.state.exporting}
                disabled={this.state.isShowMessengStatistics}
              />
            }
          />
          {/* Table List */}
          <div className="page-list-container">
            <ListFilter
              onSubmit={this.submitFilter}
              departments={this.props.departments.items}
              auth={this.props.auth}
              actions={this.props.actions}
              filter={this.state.filter}
              readOnly={this.props.readOnly}
              isLoading={this.state.isLoading}
              match={this.props.match}
            />

            <div className="table-container list-GeneralDocumentary">
              <GridView
                classNames={'pt-table'}
                page={this.state.page ? this.state.page : 1}
                headers={
                  check === 'donVi'
                    ? headers.generalWorkUnit
                    : check === 'phongBan'
                    ? headers.generalWorkDepartment
                    : headers.generalWork
                }
                itemShape={ListItem}
                data={this.props.generalWork.items}
                onFilter={this.handleSort}
                isEmpty={this.state.isEmpty}
                isLoading={this.state.isLoading}
                filter={this.state.filter}
                history={this.props.history}
                isError={this.state.isError}
                messageErrorText={MESSAGE_DEFAULT_STATISTICS}
                isShowMessengStatistics={this.state.isShowMessengStatistics}
              />
              {/* TODO: Bỏ pagination */}
              {/* {!this.state.isLoading &&
                <ListFooter
                  pageCount={Math.ceil(this.props.totalGerenalWork.item / Types.PAGINATION_LIMIT)}
                  onPageClick={this.clickPagination}
                  forcePage={this.state.page - 1}
                  isEmpty={this.state.isEmpty}
                />
              } */}
            </div>
          </div>
        </div>
      </MasterLayout>
    )
  }
}

const mapStateToProps = state => ({
  generalWork: {
    ...state.generalWork,
    items: state.common.generalWork,
  },

  totalGerenalWork: {
    ...state.totalGerenalWork,
    item: state.common.totalGerenalWork,
  },

  departments: {
    ...state.departments,
    items: state.common.departments,
  },

  auth: state.auth,

  typeSidebar: state.common.typeSidebar,

  readOnly: state.common.enableReadOnly,

  config: state.common.config,
})

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(Actions, dispatch),
})

export default connect(mapStateToProps, mapDispatchToProps)(GeneralWorkPage)
