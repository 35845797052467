import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { Intent } from '@blueprintjs/core'
import classnames from 'classnames'

import { MasterLayout } from '../../components/layout'
import { HeadingPage } from '../../components/common'
import { MoveDocumentsCabinet } from '../../components/DocumentCabinet'
import * as Actions from '../../actions'
import * as Tool from '../../helpers'
import * as Types from '../../constants/Api'

class MoveFileDocumentsCabinet extends React.Component {
  constructor(props) {
    super(props)
    let type
    switch (this.props.match.params.type) {
      case 'ca-nhan':
        type = Types.CABINET_PERSONAL
        break
      case 'phong-ban':
        type = Types.CABINET_DEPARTMENT
        break
      default:
        type = Types.CABINET_UNIT
    }

    this.state = {
      ids: this.props.location.state.listCheckIds || [],
      isCheckAll: this.props.location.state.isCheckAllData || false,
      nganTuId: this.props.location.state.nganTuId || false,
      type: type === Types.CABINET_DEPARTMENT ? Types.HOSO_CONGVIEC : type,
      defaultType: type,
      isRedirect: false,
      redirectTo: '/tu-tai-lieu/danh-sach',
    }
    this.handleChangeType = this.handleChangeType.bind(this)
    this.handleGoBack = this.handleGoBack.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
  }

  componentWillMount() {
    this.updateItem()
    this.updateCategories()
  }

  handleGoBack() {
    this.props.history.push({
      pathname: this.state.redirectTo,
      state: {
        type:
          this.state.type === Types.HOSO_CONGVIEC
            ? this.state.defaultType
            : this.state.type,
      },
    })
  }

  updateItem() {
    switch (this.state.type) {
      case Types.CABINET_PERSONAL:
        this.props.actions.documentsCabinetGetItemsPersonal(this.state.ids)
        break
      case Types.CABINET_DEPARTMENT:
        this.props.actions.documentsCabinetGetItemsDepartment(this.state.ids)
        break
      default:
        this.props.actions.documentsCabinetGetItemsUnit(this.state.ids)
    }
  }

  updateCategories() {
    switch (this.state.type) {
      case Types.CABINET_PERSONAL:
        this.props.actions.documentsCabinetGetListCategoriesPersonal()
        break
      case Types.CABINET_DEPARTMENT:
        this.props.actions.documentsCabinetGetListCategoriesDepartment()
        break
      case Types.HOSO_CONGVIEC:
        const params = {
          loai: 1,
          phanLoaiTu: 'PHONGBAN',
        }
        this.props.actions.getLoaiLuuTaiLieu(params)
        break
      default:
        this.props.actions.documentsCabinetGetListCategoriesUnit()
    }
  }

  handleChangeType(type) {
    this.setState({ type }, () => {
      this.updateCategories()
    })
  }

  handleSubmit(data) {
    if (!data.tuTaiLieuChuyenDen) {
      return this.props.actions.commonAddToasterMessage({
        message: 'Vui lòng chọn loại ngăn tủ',
        intent: Intent.DANGER,
      })
    }

    if (!data.dsNganTu || data.dsNganTu.length === 0) {
      return this.props.actions.commonAddToasterMessage({
        message: 'Vui lòng chọn ngăn tủ',
        intent: Intent.DANGER,
      })
    }

    if (!data.loaiDiChuyen) {
      return this.props.actions.commonAddToasterMessage({
        message: 'Vui lòng chọn loại di chuyển',
        intent: Intent.DANGER,
      })
    }

    let { dsNganTu } = data
    let request
    delete data.dsNganTu
    data = Tool.Utils.linkObjectKey(data)
    data.ds_tai_lieu_id = this.state.ids
    data.ds_tu_tai_lieu_id = dsNganTu

    if (this.state.isCheckAll) {
      data.moved_ngan_tu_id = this.state.nganTuId
      data.is_di_chuyen_ngan = true
    }

    switch (this.state.defaultType) {
      case Types.CABINET_PERSONAL:
        request = this.props.actions.documentsCabinetMoveItemsPersonal(data)
        break

      case Types.CABINET_DEPARTMENT:
        request = this.props.actions.documentsCabinetMoveItemsDepartment(data)
        break

      default:
        request = this.props.actions.documentsCabinetMoveItemsUnit(data)
    }

    return request
      .then(res => {
        if (res.error) {
          return this.props.actions.commonAddToasterMessage({
            message: 'Di chuyển tài liệu không thành công',
            intent: Intent.DANGER,
          })
        }

        // Refresh danh sach tai tieu
        this.props.actions.refreshDocumentCabinet(true)
        return this.props.actions.commonAddToasterMessage({
          message: 'Di chuyển tài liệu thành công',
          intent: Intent.SUCCESS,
        })
      })
      .then(this.handleGoBack)
  }

  render() {
    let { type, defaultType } = this.state
    let { items, categories } = this.props.documentsCabinet
    if (items) {
      items.map(item => {
        let fileNoiDung = item.fileNoiDung || {}
        item.fileNoiDung.kichThuocStr = Tool.Utils.getFileSize(
          fileNoiDung.kichThuoc
        )
        return item
      })
    }

    return (
      <MasterLayout typeSidebar="documentsCabinet">
        <div className="detail-receive-document page-DetailDocumentsCabinet">
          <HeadingPage namePage="Tủ tài liệu" iconPage="icon-Tu_Tai_Lieu" />
          <div className="detail-container">
            <div className="tabs-detail-page">
              <div className="pt-tabs tab-cus-container pd-none">
                <ul className="pt-tab-list" role="tablist">
                  <li
                    className={classnames('pt-tab cursor-default', {
                      'pt-tab-color-cabinet': type !== Types.CABINET_UNIT,
                    })}
                    role="tab"
                    aria-selected={type === Types.CABINET_UNIT}
                  >
                    TTL CÔNG TY
                  </li>
                  <li
                    className={classnames('pt-tab cursor-default', {
                      'pt-tab-color-cabinet': type !== Types.CABINET_DEPARTMENT,
                    })}
                    role="tab"
                    aria-selected={type === Types.CABINET_DEPARTMENT}
                  >
                    TTL PHÒNG BAN
                  </li>
                  <li
                    className={classnames('pt-tab cursor-default', {
                      'pt-tab-color-cabinet': type !== Types.CABINET_PERSONAL,
                    })}
                    role="tab"
                    aria-selected={type === Types.CABINET_PERSONAL}
                  >
                    TTL CÁ NHÂN
                  </li>
                </ul>
              </div>
            </div>
            <MoveDocumentsCabinet
              auth={this.props.auth}
              goBack={this.handleGoBack}
              type={type}
              items={items}
              categories={categories}
              onChangeType={this.handleChangeType}
              onSubmit={this.handleSubmit}
              defaultType={defaultType}
            />
          </div>
        </div>
      </MasterLayout>
    )
  }
}

MoveFileDocumentsCabinet.propTypes = {
  actions: PropTypes.object.isRequired,
}

const mapStateToProps = state => ({
  auth: state.auth,
  documentsCabinet: {
    ...state.documentsCabinet,
    categories: state.documentsCabinet.categories.map(
      id => state.entities.documentCabinetCategories[id]
    ),
    items: state.documentsCabinet.items.map(
      id => state.entities.documentsCabinet[id]
    ),
  },
})

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(Actions, dispatch),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MoveFileDocumentsCabinet)
