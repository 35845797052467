import React from 'react'
import { Intent, Checkbox, Button } from '@blueprintjs/core'
import classnames from 'classnames'
import * as MESSAGER from '../../constants/MessageForm'
import { Utils } from '../../helpers'
import { SelectTree } from '../common/'

const PATH = '/he-thong/phong-ban/chinh-sua/:id'

class FormDepartmentCategory extends React.Component {
  constructor(props) {
    super(props)

    this.handleChange = this.handleChange.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
    this.handleGoBack = this.handleGoBack.bind(this)
    this.handleDepartment = this.handleDepartment.bind(this)
    this.handleUnit = this.handleUnit.bind(this)
  }

  state = {
    phongBanId: '',
    maPhongBan: '',
    tenPhongBan: '',
    donViId: '',
    phongBanCha: '',
    isActive: true,
    isLoading: false,
    stt: null,
    phongBanLanhDao: false,
    maKySo: '',
    error: {},
    isOpenPopup: false,
    departments: null,
    unit: [],
    departmentSelect: [],
    disabledSelect: false,
    isLoadingData: {
      maPhongBan: true,
      tenPhongBan: true,
      phongBanCha: true,
      donViId: true,
      phongBanLanhDao: true,
      maKySo: true,
      isActive: true,
    },
  }

  componentDidMount() {
    let isUnit = Utils.getData('DepartmentCategoryPage', {})
    let header = Utils.getData('DepartmentCategoryPage-Header', [])

    this.setState({
      phongBanCha: header[header.length - 1].id,
      donViId: isUnit,
    })
  }

  handleChange(e) {
    if (e.target.type === 'text') {
      this.setState({ [e.target.name]: e.target.value })
    } else if (e.target.type === 'checkbox') {
      this.setState({ [e.target.name]: e.target.checked })
    }
  }

  handleDepartment(e) {
    if (e !== undefined && this.props.match.params.id !== e) {
      this.setState({
        phongBanCha: e,
      })
    }
  }

  handleUnit(e) {
    if (e !== undefined) {
      this.setState(
        {
          donViId: e,
        },
        () => {
          this.setState({ phongBanCha: '' })
          this.props.actions.getCategoryDepartmentMultiLevel(e)
        }
      )
    }
  }

  componentWillReceiveProps = async nextProps => {
    if (nextProps.departments !== this.props.departments) {
      if (!nextProps.departments) {
        return
      }
      this.setState(
        {
          phongBanId: nextProps.departments.phongBanId || '',
          maPhongBan: nextProps.departments.maPhongBan || '',
          tenPhongBan: nextProps.departments.tenPhongBan || '',
          isActive: nextProps.departments.isActive ? true : false,
          departments: nextProps.departments,
          donViId: nextProps.departments.donViId || '',
          phongBanCha: nextProps.departments.phongBanCha || '',
          phongBanLanhDao: nextProps.departments.phongBanLanhDao ? true : false,
          maKySo: nextProps.departments.maKySo || '',
          stt: nextProps.departments.stt,
        },
        () => {
          this.setState({
            isLoadingData: {
              ...this.state.isLoadingData,
              maPhongBan: false,
              tenPhongBan: false,
              phongBanCha: false,
              donViId: false,
              phongBanLanhDao: false,
              maKySo: false,
              isActive: false,
            },
          })
        }
      )
    }

    if (nextProps.unit !== this.props.unit) {
      await this.setState({
        unit: nextProps.unit,
        isLoadingData: {
          ...this.state.isLoadingData,
          donViId: false,
        },
      })
    }

    if (nextProps.departmentSelect !== this.props.departmentSelect) {
      const department = {
        maPhongBan: 'Không chọn phòng ban cha',
        phongBanId: '',
      }
      nextProps.departmentSelect.push(department)

      await this.setState({
        departmentSelect: nextProps.departmentSelect,
        isLoadingData: {
          ...this.state.isLoadingData,
          phongBanCha: false,
        },
      })
    }

    if (
      nextProps.departments === this.props.departments &&
      this.props.match.path !== PATH
    ) {
      await this.setState({
        isLoadingData: {
          ...this.state.isLoadingData,
          maPhongBan: false,
          tenPhongBan: false,
          phongBanLanhDao: false,
          maKySo: false,
          isActive: false,
        },
      })
    }
  }

  handleSubmit(e) {
    e.preventDefault()
    this.setState({ error: {} })
    const error = {}

    if (this.state.maPhongBan.trim().length === 0) {
      error.maPhongBan = MESSAGER.TEXT_MESSAGE_DEFAULT
    }

    if (this.state.tenPhongBan.trim().length === 0) {
      error.tenPhongBan = MESSAGER.TEXT_MESSAGE_DEFAULT
    }

    if (this.state.maKySo.trim().length === 0) {
      error.maKySo = MESSAGER.TEXT_MESSAGE_DEFAULT
    }

    this.setState({ error, isLoading: true })
    if (Object.keys(error).length !== 0) {
      this.setState({ isLoading: false })
      return this.props.actions.commonAddToasterMessage({
        message: MESSAGER.TOATS_MESSAGE_WARNING,
        intent: Intent.WARNING,
      })
    }

    this.setState({ isLoading: true })
    if (this.state.departments) {
      this.props.actions
        .updateCommonFetchDepartment({
          phong_ban_id: this.state.phongBanId,
          ma_phong_ban: this.state.maPhongBan,
          ten_phong_ban: this.state.tenPhongBan,
          is_active: this.state.isActive,
          don_vi_id: this.state.donViId,
          phong_ban_cha: this.state.phongBanCha,
          phong_ban_lanh_dao: this.state.phongBanLanhDao,
          ma_ky_so: this.state.maKySo,
          stt: this.state.stt,
        })
        .then(res => {
          if (
            res.payload &&
            res.payload.data.result &&
            res.payload.data.result === true
          ) {
            this.setState({ isLoading: false })
            this.props.onSubmit && this.props.onSubmit(true)
          } else {
            this.setState({ isLoading: false })
            this.props.actions.commonAddToasterMessage({
              message: MESSAGER.TOATS_MESSAGE_FAIL,
              intent: Intent.DANGER,
            })
          }
        })
    } else {
      this.props.actions
        .postCommonFetchDepartment({
          ma_phong_ban: this.state.maPhongBan,
          ten_phong_ban: this.state.tenPhongBan,
          is_active: this.state.isActive,
          don_vi_id: this.state.donViId,
          phong_ban_lanh_dao: this.state.phongBanLanhDao,
          ma_ky_so: this.state.maKySo,
          phong_ban_cha: this.state.phongBanCha,
        })
        .then(res => {
          if (
            res.payload &&
            res.payload.data.result &&
            res.payload.data.result === true
          ) {
            this.setState({ isLoading: false })
            this.props.onSubmit && this.props.onSubmit(true)
          } else {
            this.setState({ isLoading: false })
            this.props.actions.commonAddToasterMessage({
              message: MESSAGER.TOATS_MESSAGE_FAIL,
              intent: Intent.DANGER,
            })
          }
        })
    }
  }

  handleGoBack() {
    this.props.onSubmit && this.props.onSubmit()
  }

  render() {
    return (
      <div className="systerm-main-content">
        <div className="form-container form-inline-container systerm-form-container">
          <form className="main-form systerm-form">
            <div className="form-block">
              <div className="form-subblock">
                <div className="form-row">
                  <div className="form-cols form-col5">
                    <div className="form-group-item pt-form-group pt-intent-danger">
                      <label
                        className="pt-label pt-label-inline"
                        htmlFor="tenVietTat"
                      >
                        Tên viết tắt
                        <span className="pt-text-muted required-input">*</span>
                      </label>
                      <div className="input-form-inline pt-form-content">
                        <input
                          type="text"
                          value={this.state.maPhongBan}
                          autoFocus={true}
                          className={classnames('pt-input', {
                            'loading-input':
                              Object.values(this.state.isLoadingData).find(
                                item => item === true
                              ) !== undefined,
                          })}
                          disabled={
                            Object.values(this.state.isLoadingData).find(
                              item => item === true
                            ) !== undefined
                          }
                          name="maPhongBan"
                          placeholder="Nhập tên viết tắt"
                          onChange={this.handleChange}
                        />
                        {this.state.error.maPhongBan !== undefined && (
                          <div className="pt-form-content pt-form-helper-text">
                            {this.state.error.maPhongBan}
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="form-cols form-col5">
                    <div className="form-group-item pt-form-group pt-intent-danger">
                      <label
                        className="pt-label pt-label-inline"
                        htmlFor="tenPhongBan"
                      >
                        Tên phòng ban
                        <span className="pt-text-muted required-input">*</span>
                      </label>
                      <div className="input-form-inline pt-form-content">
                        <input
                          type="text"
                          value={this.state.tenPhongBan}
                          className={classnames('pt-input', {
                            'loading-input':
                              Object.values(this.state.isLoadingData).find(
                                item => item === true
                              ) !== undefined,
                          })}
                          disabled={
                            Object.values(this.state.isLoadingData).find(
                              item => item === true
                            ) !== undefined
                          }
                          name="tenPhongBan"
                          placeholder="Nhập tên phòng ban"
                          onChange={this.handleChange}
                        />
                        {this.state.error.tenPhongBan !== undefined && (
                          <div className="pt-form-content pt-form-helper-text">
                            {this.state.error.tenPhongBan}
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="form-row">
                  <div className="form-cols form-col5">
                    <div className="form-group-item pt-form-group pt-intent-danger">
                      <label
                        className="pt-label pt-label-inline"
                        htmlFor="tenVietTat"
                      >
                        Đơn vị
                        <span className="pt-text-muted required-input">*</span>
                      </label>
                      <div className="input-form-inline pt-form-content">
                        <SelectTree
                          className="select-tree"
                          data={this.state.unit}
                          type="Unit"
                          permission={false}
                          value={
                            Object.values(this.state.isLoadingData).find(
                              item => item === true
                            ) === undefined
                              ? this.state.donViId
                              : ''
                          }
                          handleChange={this.handleUnit}
                          isLoading={
                            Object.values(this.state.isLoadingData).find(
                              item => item === true
                            ) !== undefined
                          }
                        />
                      </div>
                    </div>
                  </div>
                  <div className="form-cols form-col5">
                    <div className="form-group-item pt-form-group pt-intent-danger">
                      <label
                        className="pt-label pt-label-inline"
                        htmlFor="tenPhongBan"
                      >
                        Mã ký số
                        <span className="pt-text-muted required-input">*</span>
                      </label>
                      <div className="input-form-inline pt-form-content">
                        <input
                          type="text"
                          value={this.state.maKySo}
                          className={classnames('pt-input', {
                            'loading-input':
                              Object.values(this.state.isLoadingData).find(
                                item => item === true
                              ) !== undefined,
                          })}
                          disabled={
                            Object.values(this.state.isLoadingData).find(
                              item => item === true
                            ) !== undefined
                          }
                          name="maKySo"
                          placeholder="Nhập Mã ký số"
                          onChange={this.handleChange}
                        />
                        {this.state.error.maKySo !== undefined && (
                          <div className="pt-form-content pt-form-helper-text">
                            {this.state.error.maKySo}
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="form-row">
                  <div className="form-cols form-col5">
                    <div className="form-group-item pt-form-group pt-intent-danger">
                      <label
                        className="pt-label pt-label-inline"
                        htmlFor="tenPhongBan"
                      >
                        Phòng ban cha
                      </label>
                      <div className="input-form-inline pt-form-content">
                        <SelectTree
                          className="select-tree"
                          data={this.state.departmentSelect}
                          type="Department"
                          permission={false}
                          value={
                            Object.values(this.state.isLoadingData).find(
                              item => item === true
                            ) === undefined
                              ? this.state.phongBanCha
                              : ''
                          }
                          handleChange={this.handleDepartment}
                          isLoading={
                            Object.values(this.state.isLoadingData).find(
                              item => item === true
                            ) !== undefined
                          }
                          match={this.props.match}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="form-cols form-col5">
                    <div className="form-group-item form-group-inline">
                      <label
                        className="pt-label pt-label-inline"
                        htmlFor="sudung"
                      >
                        Sử dụng
                      </label>
                      <div className="input-form-inline">
                        <Checkbox
                          name="isActive"
                          onChange={this.handleChange}
                          disabled={
                            Object.values(this.state.isLoadingData).find(
                              item => item === true
                            ) !== undefined
                          }
                          checked={this.state.isActive}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="form-row">
                  <div className="form-cols form-col5">
                    <div className="form-group-item form-group-inline">
                      <label
                        className="pt-label pt-label-inline"
                        htmlFor="sudung"
                      >
                        Phòng ban lãnh đạo
                      </label>
                      <div className="input-form-inline">
                        <Checkbox
                          name="phongBanLanhDao"
                          onChange={this.handleChange}
                          disabled={
                            Object.values(this.state.isLoadingData).find(
                              item => item === true
                            ) !== undefined
                          }
                          checked={this.state.phongBanLanhDao}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="form-block bottom-form">
              <div className="buttton-action-footer">
                <Button
                  type="button"
                  className="pt-button pt-button btn-cancel"
                  onClick={this.handleGoBack}
                  disabled={this.state.isLoading === true ? true : false}
                >
                  <span className=" pt-icon icon-back"></span>
                  <span className="text-content">Quay lại</span>
                </Button>
                <Button
                  type="button"
                  className="pt-button pt-button btn-blue-color"
                  loading={this.state.isLoading}
                  disabled={
                    Object.values(this.state.isLoadingData).find(
                      item => item === true
                    ) !== undefined
                  }
                  onClick={this.handleSubmit}
                >
                  <span className="text-content">Lưu</span>
                  <span className="pt-icon icon-save"></span>
                </Button>
              </div>
            </div>
          </form>
        </div>
      </div>
    )
  }
}

export default FormDepartmentCategory
